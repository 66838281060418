import React from "react";
import Creatable from "react-select/creatable";
import { useIntl } from "react-intl";

export interface SelectOption {
    label: string;
    value: string;
}

interface CreatableSelectProps {
    value?: SelectOption[];
    placeholder?: string;
    onChange?: any;
}

const CreatableSelect = ({ value, onChange }: CreatableSelectProps) => {
    const intl = useIntl();

    return (
        <Creatable
            placeholder={intl.formatMessage({ id: "multiselect__default_value" })}
            isClearable
            isMulti
            value={value}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onChange}
            styles={customStyles}
            autoFocus
        />
    );
};

const customStyles = {
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: () => ({
        display: "none",
    }),
    menu: () => ({
        display: "none",
    }),
};

export default CreatableSelect;
