import AdminPanelApi from "features/adminPanel/api";
import CommonApi from "features/common/api";
import ContactFormApi from "features/contactForm/api";
import EmployeeOffersApi from "features/employeeOffers/api";
import JobOffersApi from "features/jobOffers/api";
import MessagesApi from "features/messages/api";
import ModeratorPanelApi from "features/moderatorPanel/api";
import MyProfileApi from "features/myProfile/api";
import RatingApi from "features/rating/api";
import UserApi from "features/user/api";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import store from "store";

const Api = {
    ...CommonApi,
    ...UserApi,
    ...JobOffersApi,
    ...EmployeeOffersApi,
    ...MyProfileApi,
    ...ContactFormApi,
    ...MessagesApi,
    ...AdminPanelApi,
    ...ModeratorPanelApi,
    ...RatingApi,
};

const composeEnhancers = composeWithDevTools({
    trace: true,
});

export default () => createStore(store, composeEnhancers(applyMiddleware(thunk.withExtraArgument(Api))));
