import { screenSizeBreakpoints } from "features/common/constants";
import { DeviceClass } from "features/common/types";
import { useEffect, useState } from "react";

const useDeviceClass = () => {
    const getDeviceClass = (): DeviceClass => {
        const windowWidth = window.innerWidth;

        if (windowWidth < screenSizeBreakpoints.tablet) {
            return "smartphone";
        } else if (windowWidth >= screenSizeBreakpoints.tablet && windowWidth < screenSizeBreakpoints.desktop) {
            return "tablet";
        } else {
            return "desktop";
        }
    };

    const [deviceClass, setDeviceClass] = useState<DeviceClass>(getDeviceClass());

    useEffect(() => {
        function handleResize() {
            const newDeviceClass = getDeviceClass();
            newDeviceClass !== deviceClass && setDeviceClass(newDeviceClass);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [deviceClass]);

    return deviceClass;
};

export default useDeviceClass;
