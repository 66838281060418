import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import classNames from "classnames";
import useResize from "features/common/hooks/useResize";
import { numberToPixels } from "features/common/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";

const arrowCastDimension = 16 * Math.sqrt(2);
const arrowCornerOffset = 8;

type ArrowPosition = "bottom-right" | "bottom-center";

export interface Props {
    text: string;
    arrowPosition?: ArrowPosition;
    className?: string;
    ["data-testid"]?: string;
    hiddenIcon?: boolean;
    isOpen?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Tooltip = ({ text, arrowPosition = "bottom-center", className, "data-testid": testId, hiddenIcon, isOpen, setIsOpen }: Props) => {
    const [localisOpen, setLocalIsOpen] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);

    const setTextPosition = useCallback(() => {
        if (containerRef.current && textRef.current && arrowRef.current) {
            const containerBoundingRect = containerRef.current.getBoundingClientRect();
            const textBoundingRect = textRef.current.getBoundingClientRect();

            switch (arrowPosition) {
                case "bottom-right":
                    textRef.current.style.top = numberToPixels(
                        containerBoundingRect.top -
                        textRef.current.clientHeight -
                        arrowCastDimension / 2 +
                        window.scrollY + (hiddenIcon ? 20 : 0)
                    );
                    textRef.current.style.left = numberToPixels(
                        (containerBoundingRect.left -
                            textBoundingRect.width +
                            containerBoundingRect.width +
                            arrowCornerOffset > 0 ? containerBoundingRect.left -
                            textBoundingRect.width +
                            containerBoundingRect.width +
                        arrowCornerOffset : 10) - (hiddenIcon ? 20 : 0)
                    );
                    break;
                default:
                    textRef.current.style.top = numberToPixels(
                        containerBoundingRect.top -
                        textRef.current.clientHeight -
                        arrowCastDimension / 2 +
                        window.scrollY
                    );
                    textRef.current.style.left = numberToPixels(
                        containerBoundingRect.left - textBoundingRect.width / 2 + containerBoundingRect.width / 2
                    );
                    break;
            }

            if (arrowPosition.includes("bottom")) {
                arrowRef.current.style.top = numberToPixels(
                    containerBoundingRect.top - arrowCastDimension + window.scrollY
                );
            }
        }
    }, [arrowPosition, hiddenIcon])


    useEffect(() => {
        if (isOpen !== undefined && isOpen) {
            setTextPosition();
        } else if (localisOpen) {
            setTextPosition();
        }

    }, [localisOpen, isOpen, setTextPosition])

    useResize(() => {
        const setPositionToZero = () => {
            if (textRef.current) {
                textRef.current.style.top = "0px";
                textRef.current.style.left = "0px";
            }
        }

        if (isOpen !== undefined && !isOpen) {
            setPositionToZero()
        } else {
            if (!localisOpen) {
                setPositionToZero()
            }
        }

        setIsOpen && setIsOpen(false);
        setLocalIsOpen(false)
    });

    const showToolTip = () => {
        if (isOpen !== undefined) {
            return !isOpen
        } else {
            return !localisOpen
        }
    }

    return (
        <ClickAwayListener onClickAway={() => {
            !hiddenIcon && setIsOpen && setIsOpen(false);
            setLocalIsOpen(false)
        }}>
            <div data-testid={testId} className={classNames(styles["tooltip"], className)} ref={containerRef}>
                <InfoIcon
                    data-testid={testId ? `${testId}__toggle` : undefined}
                    className={classNames(styles["tooltip__toggle"], { [styles["tooltip__toggle--hidden"]]: hiddenIcon })}
                    onClick={() => {
                        if (setIsOpen !== undefined) {
                            setIsOpen(!isOpen)
                        } else {
                            setLocalIsOpen(!localisOpen)
                        }
                    }}
                />
                <div
                    data-testid={testId ? `${testId}__text` : undefined}
                    className={classNames(styles["tooltip__text"], { [styles["tooltip__text--hidden"]]: showToolTip() })}
                    ref={textRef}
                >
                    {text}
                </div>
                <div
                    data-testid={testId ? `${testId}__arrow` : undefined}
                    className={classNames(styles["tooltip__arrow"], { [styles["tooltip__arrow--hidden"]]: showToolTip() })}
                    ref={arrowRef}
                />
            </div>
        </ClickAwayListener>
    );
};

export default Tooltip;
