import { ReactComponent as ChevronDown } from "assets/icons/chevronDown.svg";
import classNames from "classnames";
import UserImageWithPlaceholder from "features/common/components/UserImageWithPlaceholder";
import useCurrentPath from "features/common/hooks/useCurrentPath";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { UserRole } from "features/common/types";
import Dropdown from "features/layout/components/Header/Dropdown";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import config from "config";
import styles from "./styles.module.scss";

const userNavUrls = [
    appRoutes.myProfile,
    appRoutes.myJobOffers,
    appRoutes.myEmployeeOffers,
    appRoutes.myMessages,
    appRoutes.adminPanel,
];
export interface Props {
    ["data-testid"]?: string;
    user?: User;
    isUserLoggedIn?: boolean;
    unreadMessagesCount?: number;
    logoutUserAsync: () => void;
}

const UserNav = ({ "data-testid": testId, user, isUserLoggedIn, unreadMessagesCount, logoutUserAsync }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const pathname = useCurrentPath();
    const [userNavHighlighted, setUserNavHighlighted] = useState(false);
    const isAdministrator = user?.role === UserRole.Administrator;
    const isModerator = user?.role === UserRole.Moderator;
    const deviceClass = useDeviceClass();

    const handleDropdown = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        const path = "/" + pathname.split("/")[1];

        if (userNavUrls.includes(path)) {
            if (path === appRoutes.myMessages && deviceClass === "desktop") {
                setUserNavHighlighted(false);
            } else {
                setUserNavHighlighted(true);
            }
        } else {
            setUserNavHighlighted(false);
        }
    }, [pathname, deviceClass]);

    return (
        <div
            className={classNames(styles["user-nav"], {
                [styles["user-nav--open"]]: dropdownOpen || userNavHighlighted,
            })}
            onClick={handleDropdown}
        >
            <UserImageWithPlaceholder
                imageUrl={`${config.IMAGE_URL}/${user?.imageUrl}`}
                className={styles["user-nav__user-image"]}
            />

            {isUserLoggedIn && user?.firstName && (
                <span
                    data-testid={`${testId}__user-full-name`}
                    className={styles["user-nav__username"]}
                >{`${user.firstName} ${user.lastName}`}</span>
            )}
            {isUserLoggedIn && !user && (
                <span data-testid={`${testId}__new-user-placeholder-name`} className={styles["user-nav__username"]}>
                    <FormattedMessage id="user-nav__new-user" />
                </span>
            )}
            {!isUserLoggedIn && (
                <span data-testid={`${testId}__sign-in-link`} className={styles["user-nav__login-info"]}>
                    <FormattedMessage id="common__sign-in" />
                </span>
            )}

            {dropdownOpen && (
                <div onClick={handleDropdown}>
                    <Dropdown
                        data-testid={`${testId}__dropdown`}
                        onClickAway={() => setDropdownOpen(false)}
                        userProfileExists={!!user?.firstName}
                        isUserLoggedIn={isUserLoggedIn}
                        isAdministrator={isAdministrator}
                        isModerator={isModerator}
                        unreadMessagesCount={unreadMessagesCount}
                        logoutUserAsync={logoutUserAsync}
                    />
                </div>
            )}
        </div>
    );
};

export default UserNav;
