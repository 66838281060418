import PageHeaderBox from "features/common/components/PageHeaderBox";
import ContactFormComponent from "features/contactForm/components/ContactForm";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const ContactForm = () => {
    return (
        <div className={styles["contact-form"]}>
            <PageHeaderBox>
                <FormattedMessage id="contact-form__header" />
            </PageHeaderBox>
            <ContactFormComponent />
        </div>
    );
};

export default ContactForm;
