import { ReactComponent as ExclamationCircleIcon } from "assets/icons/exclamation-circle.svg";
import { ReactComponent as TickCircle } from "assets/icons/greenTickCircle.svg";
import { ReactComponent as HiddenIcon } from "assets/icons/hidden.svg";
import { ReactComponent as ShownIcon } from "assets/icons/shown.svg";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import classNames from "classnames";
import FieldMessages from "features/common/components/FieldMessages";
import { InputType, ValidationProps } from "features/common/types";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import styles from "./styles.module.scss";
import { setCardNumberWithSpaces } from "utils";


export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["input"]> &
    ValidationProps & {
        ["data-testid"]?: string;
        fieldClassName?: string;
        containerClassName?: string;
        labelWrapperClassName?: string;
        wrapperClassName?: string;
        label?: string;
        helperMessages?: (string | React.ReactElement)[];
        clearIconVisible?: boolean;
        removeFocus?: boolean;
        disabled?: boolean;
        withShowHideButton?: boolean;
        isHidden?: boolean;
        type?: InputType;
        pattern?: string;
        spacelessMessages?: boolean;
        isRounded?: boolean;
        inputMask?: string;
        onClearValue?: () => void;
        toggleHideShow?: () => void;
        displayCorrectValidation?: boolean;
        touched?: boolean;
        maxLength?: number;
        onErrorClick?: () => void;
        isCardNumber?: boolean;
        passValue?: boolean;
    };

const Input = ({
    fieldClassName,
    containerClassName,
    labelWrapperClassName,
    wrapperClassName,
    name,
    label,
    placeholder,
    helperMessages,
    clearIconVisible,
    isInvalid,
    error,
    value,
    removeFocus,
    "data-testid": testId,
    withValidation,
    withShowHideButton,
    isHidden,
    disabled = false,
    type,
    pattern,
    spacelessMessages,
    isRounded,
    inputMask,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    onClearValue,
    toggleHideShow,
    touched,
    displayCorrectValidation,
    maxLength,
    onErrorClick,
    isCardNumber,
    passValue

}: Props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [localValue, setLocalValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLocalValue(event.currentTarget.value);
        onChange && onChange(event);

        if (isCardNumber) {
            setLocalValue(setCardNumberWithSpaces(event));
        }
    };

    useEffect(() => {
        if (removeFocus) {
            setIsFocused(false);
            inputRef.current?.blur();
        }
    }, [removeFocus]);

    const clearValue = () => {
        onClearValue && onClearValue();
        setLocalValue("");
    };

    return (
        <div
            data-testid={testId}
            className={classNames(styles["input"], wrapperClassName)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            <div
                data-testid={`${testId}__field-wrapper`}
                className={classNames(styles["input__field-wrapper"], containerClassName, {
                    [styles["input__field-wrapper--focused"]]: isFocused,
                    [styles["input__field-wrapper--invalid"]]: withValidation && isInvalid,
                    [styles["input__field-wrapper--valid"]]:
                        displayCorrectValidation && withValidation && touched && !error && !isInvalid,
                    [styles["input__field-wrapper--disabled"]]: disabled,
                    [styles["input__field-wrapper--rounded"]]: isRounded,
                })}
            >
                {(isFocused || !!localValue || !placeholder) && label !== undefined && (
                    <div
                        data-testid={`${testId}__label-wrapper`}
                        className={classNames(styles["input__label-wrapper"], labelWrapperClassName)}
                    >
                        <label data-testid={`${testId}__label`} className={styles["input__label"]}>
                            {label}
                        </label>
                    </div>
                )}
                <div className={styles["input__inner-field-container"]}>
                    {inputMask ? (
                        <InputMask
                            data-testid={`${testId}__field`}
                            name={name}
                            value={localValue !== undefined && localValue !== null ? localValue : ""}
                            className={classNames(styles["input__field"], fieldClassName)}
                            placeholder={!isFocused ? placeholder : undefined}
                            onChange={onInputChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            inputRef={inputRef}
                            disabled={disabled}
                            type={type}
                            mask={inputMask}
                        />
                    ) : (
                        <input
                            data-testid={`${testId}__field`}
                            name={name}
                            value={passValue ? value : (localValue !== undefined && localValue !== null ? localValue : "")}
                            className={classNames(styles["input__field"], fieldClassName)}
                            placeholder={!isFocused ? placeholder : undefined}
                            onChange={onInputChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            ref={inputRef}
                            disabled={disabled}
                            type={type}
                            maxLength={maxLength}
                        />
                    )}
                    {clearIconVisible && !!value && (
                        <TimesIcon onClick={clearValue} className={styles["input__clear-icon"]} />
                    )}
                    {withValidation && error && isInvalid && (
                        <ExclamationCircleIcon
                            data-testid={`${testId}__error-icon`}
                            className={classNames(styles["input__error-icon"], { [styles["input__error-icon--pointer"]]: !!onErrorClick })}
                            onClick={onErrorClick}
                        />
                    )}
                    {displayCorrectValidation && withValidation && touched && !error && !isInvalid && (
                        <TickCircle
                            data-testid={`${testId}__no-error-icon`}
                            className={styles["input__no-error-icon"]}
                        />
                    )}
                    {withShowHideButton &&
                        (isHidden ? (
                            <HiddenIcon onClick={toggleHideShow} className={styles["input__hide-show-icon"]} />
                        ) : (
                            <ShownIcon onClick={toggleHideShow} className={styles["input__hide-show-icon"]} />
                        ))}
                </div>
            </div>
            {withValidation && error && isInvalid && (
                <FieldMessages
                    data-testid={testId ? `${testId}__error-messages` : undefined}
                    messages={[error]}
                    mode="error"
                    spaceless={spacelessMessages}
                />
            )}
            {(!withValidation || !isInvalid) && helperMessages && (
                <FieldMessages
                    data-testid={testId ? `${testId}__helper-messages` : undefined}
                    messages={helperMessages}
                    mode="info"
                    spaceless={spacelessMessages}
                />
            )}
        </div>
    );
};

export default Input;
