import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.common;

export const selectBranches = createSelector(selectState, (state) => state.branches);
export const selectBranchesLoading = createSelector(selectState, (state) => state.branchesLoading);
export const selectBranchesLoadingError = createSelector(selectState, (state) => state.branchesLoadingError);

export const selectCurrencies = createSelector(selectState, (state) => state.currencies);
export const selectCurrenciesLoading = createSelector(selectState, (state) => state.currenciesLoading);
export const selectCurrenciesLoadingError = createSelector(selectState, (state) => state.currenciesLoadingError);

export const selectSearchTermSuggestions = createSelector(selectState, (state) => state.searchTermSuggestions);
export const selectLocationSuggestions = createSelector(selectState, (state) => state.locationSuggestions);

export const selectCityDetails = createSelector(selectState, (state) => state.cityDetails);
export const selectCityDetailsLoading = createSelector(selectState, (state) => state.cityDetailsLoading);

export const selectUserPrivateCards = createSelector(selectState, (state) => state.userPrivateCards);
export const selectUserCompanyCards = createSelector(selectState, (state) => state.userCompanyCards);
export const selectUserCardsLoading = createSelector(selectState, (state) => state.userCardsLoading);
export const selectUserCardsLoadingError = createSelector(selectState, (state) => state.userCardsLoadingError);

export const selectLocationsDetails = createSelector(selectState, (state) => state.locationsDetails);
export const selectLocationsDetailsLoading = createSelector(selectState, (state) => state.locationsDetailsLoading);
export const selectLocationsDetailsLoadingError = createSelector(
    selectState,
    (state) => state.locationsDetailsLoadingError
);

export const selectCountries = createSelector(selectState, (state) => state.countries);
export const selectCountriesLoading = createSelector(selectState, (state) => state.countriesLoading);
export const selectCountriesError = createSelector(selectState, (state) => state.countriesError);
