import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import { ApiError } from "api/utils";
import Spinner from "features/common/components/Spinner";
import { selectLoginUserLoading, selectLoginUserLoadingError } from "features/user/selectors";
import { LoginOauthParams } from "features/user/types";
import { loginUserOathAsync } from "features/user/thunks";
import styles from "./styles.module.scss";

const mapStateToProps = (state: RootState) => ({
    loginUserLoading: selectLoginUserLoading(state),
    loginUserLoadingError: selectLoginUserLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            loginUserOathAsync,
        },
        dispatch
    );

interface Props {
    loginUserLoading?: boolean;
    loginUserLoadingError?: ApiError;
    loginUserOathAsync: (params: LoginOauthParams) => void;
}

const OauthLogin = ({ loginUserLoadingError, loginUserOathAsync, loginUserLoading }: Props) => {
    const location = useLocation();

   const handleLoginOauth = () => {
       const { code } = queryString.parse(location.search);
       if (!loginUserLoading) {
           loginUserOathAsync({ code: code as string })
       }
   }

    useEffect(() => {
        handleLoginOauth();
    }, [])

    return (
        <div className={styles["login-oauth"]}>
            {loginUserLoadingError ? (
                <div className={styles["login-oauth__error"]}>
                    {loginUserLoadingError.response?.data || loginUserLoadingError.message}
                </div>
            ) : (
                <Spinner className={styles["login-oauth__spinner"]} />
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OauthLogin);
