import { ReactComponent as OfferBonusIcon } from "assets/getbonus/get-bonus.svg";
import { ReactComponent as AvailablePositionNumber } from "assets/icons/availablePositionNumber.svg";
import { ReactComponent as BagIcon } from "assets/icons/bag.svg";
// import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as GoToDetailsIcon } from "assets/icons/goto.svg";
import GroupOffer from "assets/icons/group-offer.svg";
import HeartIcon from "assets/icons/heart-icon-off.svg";
import HeartFilled from "assets/icons/HeartFilled.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/offersLocationMarker.svg";
import DefaultImgMobile from "assets/images/default-img-JobOffrs-list-mobile.png";
import DefaultImgTablet from "assets/images/default-img-JobOffrs-list-tablet.png";
import classNames from "classnames";
import config from "config";
import SignInMessage from "features/common/components/SignInMessage";
// import FromToText from "features/common/components/FromToText";
import useDateFormat from "features/common/hooks/useDateFormat";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { SalaryType } from "features/common/models";
import { Image } from "features/common/types";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { FinancialConditionsSlimDTO, LocationSlimDTO } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import useTitleSubstring from "hooks/useTitleSubstring";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import FavoriteAPI from "../../../favorite/api";
import styles from "./styles.module.scss";

interface Props {
    employeeOfferDetails: EmployeeOfferDetails;
    expirationDate?: Date;
    businessName?: string;
    availablePositionsNumber: number;
    mainImage?: string;
    id: number;
    title: string;
    branchDTO: { id?: number; name?: string };
    locationSlimDto?: LocationSlimDTO;
    workType?: string;
    bonus?: string;
    financialConditionsSlimDTO: FinancialConditionsSlimDTO;
    addedDate?: Date;
    jobStartDate?: any;
    jobEndDate?: Date;
    employeeName?: string;
    clientLogoUrl?: string;
    images?: Image[];
    isUserLoggedIn?: boolean;
}

const EmployeeOfferItem = ({
    expirationDate,
    businessName,
    availablePositionsNumber,
    employeeOfferDetails,
    id,
    title,
    branchDTO,
    locationSlimDto,
    workType,
    financialConditionsSlimDTO: { bonusCurrencyCode, bonusValue, paymentRateType, rewardCurrencyCode, rewardMinimumValue },
    employeeName,
    clientLogoUrl,
    images,
    mainImage,
    isUserLoggedIn
}: Props) => {
    const [isFilled, setIsFilled] = useState(employeeOfferDetails?.favorite)
    const history = useHistory();
    const deviceClass = useDeviceClass();
    const getFormattedDate = useDateFormat();
    const { changedTitle } = useTitleSubstring(title, 40, 57, 40, 57, 39);
    const isMatches = useMediaQuery('(min-width: 834px)');

    const shouldShowLogoOrEmployeeName = clientLogoUrl || employeeName;

    const DefaultImage = isMatches ? DefaultImgTablet : DefaultImgMobile;

    const posterPath = mainImage ? `${config.IMAGE_URL}/${mainImage}` :
        (images && images?.length > 0)
            ? `${config.IMAGE_URL}/${images[0]}`
            : DefaultImage;

    const openOfferDetails = useCallback(() => {
        history.push(`${appRoutes.employeeOffers}/${id}`);
    }, [history, id]);

    const locationText = useMemo(
        () =>
            `${locationSlimDto?.city ? `${locationSlimDto?.city}, ` : ""} ${locationSlimDto?.country}`,
        [locationSlimDto]
    );

    const locationBlock = useCallback(() => {
        if (deviceClass === "smartphone" && locationText.length > 21) {
            return <div>{`${locationText.substring(0, 21)}...`}</div>;
        } else if (deviceClass === "tablet" && locationText?.length > 40) {
            return <div>{`${locationText.substring(0, 132)}...`}</div>;
        } else {
            return <div>{locationText}</div>;
        }
    }, [deviceClass, locationText]);

    const onFavoriteHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (isUserLoggedIn) {
            e.stopPropagation();
            if (employeeOfferDetails?.id) {
                if (isFilled) {
                    await FavoriteAPI.removeEmployeeOfferFromFavorite(employeeOfferDetails.id);
                    setIsFilled(false);
                } else {
                    await FavoriteAPI.addEmployeeOfferToFavorite(employeeOfferDetails.id);
                    setIsFilled(true);
                }
            }
        }
        else {
            history.push(appRoutes.login);
        }
    }

    return (
        <div className={styles["employee-offer-item"]}>
            <ImageContainer onClick={openOfferDetails}>
                <StyledImage src={posterPath} />
            </ImageContainer>
            <div className={styles["employee-offer-item__offer-container"]}>
                <div className={styles["employee-offer-item__top-part"]}>
                    <div className={styles["employee-offer-item__header"]}>
                        <div
                            className={classNames(styles["employee-offer-item__title"], {
                                [styles["employee-offer-item__title--full-width"]]: !shouldShowLogoOrEmployeeName,
                            })}
                        >
                            <div className={styles["employee-offer-item__clear-text"]} onClick={isUserLoggedIn ? openOfferDetails : (() => history.push(appRoutes.login))}>
                                {isUserLoggedIn ? businessName : <SignInMessage />}
                            </div>
                        </div>
                        <div className={styles["employee-offer-item__available-position-number"]}>
                            <AvailablePositionNumber />
                            <div className={styles["employee-offer-item__details-text"]}>
                                {isMatches ?
                                    <FormattedMessage
                                        id="employee-offers-details__employees-amount"
                                        values={{ amount: availablePositionsNumber }}
                                    /> :
                                    <FormattedMessage
                                        id="employee-offers-details__employees-amount-mobile"
                                        values={{ amount: availablePositionsNumber }}
                                    />
                                }
                            </div>
                        </div>
                        <div className={styles["employee-offer-item__job-position"]} onClick={openOfferDetails}>
                            {changedTitle}
                        </div>
                        <StyledHeartBtn onClick={onFavoriteHandler} type="button">
                            <img src={isFilled ? HeartFilled : HeartIcon} alt="favorite" />
                        </StyledHeartBtn>
                    </div>
                    <div className={styles["employee-offer-item__details-container"]}>
                        <div className={styles["employee-offer-item__offer-details"]}>
                            <div className={styles["employee-offer-item__conditions-wrapper"]}>
                                <div className={styles["employee-offer-item__"]}>
                                    <div className={styles["employee-offer-item__details-item"]}>
                                        <StyledBranch className={styles["employee-offer-item__clear-text"]}>
                                            {branchDTO ? <FormattedMessage id={branchDTO.name} /> : ""}
                                        </StyledBranch>
                                    </div>
                                    <div className={styles["employee-offer-item__details-item"]}>
                                        <LocationMarkerIcon className={styles["employee-offer-item__width"]} />
                                        <div className={styles["employee-offer-item__details-text"]}>
                                            <div className={styles["employee-offer-item__clear-text"]}
                                                data-testid="employee-offer-item__job-location"
                                            >
                                                {locationSlimDto?.country ? locationBlock() : "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["employee-offer-item__details-item"]}>
                                        <CoinsIcon />
                                        <div className={styles["employee-offer-item__clear-text"]}>
                                            {rewardMinimumValue && paymentRateType ? (
                                                <>
                                                    <div className={styles["employee-offer-item__from-text"]}>
                                                        {isMatches && <><FormattedMessage id="common__salary" /> :&nbsp;</>}
                                                        <FormattedMessage id="common__from" />
                                                    </div>
                                                    <div data-testid="employee-offer-item__salary">
                                                        {rewardMinimumValue} {rewardCurrencyCode}/
                                                    </div>
                                                    <div data-testid="employee-offer-item__payment-type">
                                                        {paymentRateType === SalaryType.Monthly ? (
                                                            <FormattedMessage id="offers-item__salary-type-month" />
                                                        ) : (
                                                            <FormattedMessage id="offers-item__salary-type-hourly" />
                                                        )}
                                                    </div>
                                                </>
                                            ) :
                                                <div data-testid="employee-offer-item__salary">-</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["employee-offer-item__column"]}>
                                    <div className={styles["employee-offer-item__details-item-icon"]}>
                                        <BagIcon />
                                        <div className={styles["employee-offer-item__details-text"]}>
                                            {isMatches ? <div>
                                                <FormattedMessage id="common__work-type-tablet" />:
                                            </div> :
                                                <div>
                                                    <FormattedMessage id="common__work-type" />:
                                                </div>}
                                            <div data-testid="employee-offer-item__work-type">
                                                <FormattedMessage id="work-type" values={{ workType }} />
                                            </div>
                                        </div>
                                    </div>
                                    {bonusValue ?
                                        (<div className={styles["employee-offer-item__details-item-icon"]}>
                                            <OfferBonusIcon />
                                            <div className={styles["employee-offer-item__details-text"]}>
                                                <div>
                                                    <FormattedMessage id="common__bonus" />:
                                                </div>
                                                <div data-testid="employee-offer-item__bonus">
                                                    {`${bonusValue} ${bonusCurrencyCode}`}
                                                </div>
                                            </div>
                                        </div>) :
                                        <EmptyBonusContainer />
                                    }
                                    {availablePositionsNumber && availablePositionsNumber > 1 &&
                                        <GroupOfferContainer>
                                            <img src={GroupOffer} alt="group offer" />
                                            {isMatches ?
                                                <FormattedMessage id="employee-offers-details__group-offer" /> :
                                                <FormattedMessage id="employee-offers-details__group-offer-mobile" />
                                            }
                                        </ GroupOfferContainer>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles["employee-offer-item__offer-footer"]}>
                    <div>
                        <FormattedMessage
                            id="offers__offer-valid-until"
                            values={{ expirationDate: getFormattedDate(expirationDate) }}
                        />
                    </div>
                    <div className={styles["employee-offer-item__offer-footer-details"]} onClick={openOfferDetails}>
                        <FormattedMessage id="offers-item__go-to-offer" />
                        <GoToDetailsIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeOfferItem;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    margin-right: 0.75rem;
    cursor: pointer;
    display: flex;
    height: inherit;
    @media screen and (max-width: 833px) {
        max-width: 4.625rem;  
        min-width: 4.625rem;
        max-height: auto;
    }
    @media screen and (min-width: 834px) {
        width: 13.25rem;
        margin-right: 1.625rem;
    }
`;

interface IImageProps {
    companyLogo?: boolean;
}

const StyledImage = styled.img<IImageProps>`
    display: block;
    width: 100%;
    background-position: center;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    @media screen and (min-width: 834px) {
        width: 13.25rem;
        height: auto;
    }
    ${({ companyLogo }) => companyLogo && css`
        height: 3.75rem;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        border-bottom-left-radius: 0;
        @media screen and (min-width: 834px) {
            width: 4.188rem;
            height: 3.375rem;
            border-radius: 5px;
        }
    `}
`;

const StyledHeartBtn = styled.button`
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    display: grid;
    justify-content: end;
    align-items: center;

    @media screen and (min-width: 834px) {
        grid-column-start: 3;
        grid-row-end: 1;
    }
`;

const StyledBranch = styled.div`
    margin-bottom: 0.5rem;

    @media screen and (min-width: 834px) {
        line-height: 1;
    }
`;

const GroupOfferContainer = styled.div`
    width: 6.25rem;
    height: 1.688rem;
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 1.09;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05px;
    color: #FFFFFF;
    background: #002677;
    border-radius: 5px;
    padding: 0.45rem 0.5rem;
    gap: 0.25rem;

    @media screen and (min-width: 834px) {
        width: 12.625rem;
        height: 1.875rem;
        justify-content: center;
    }
`;

const EmptyBonusContainer = styled.div`
    box-sizing: border-box;
    background: transparent;
    height: 1rem;

    @media screen and (min-width: 834px) {
        height: 1.5rem;
    }
`;
