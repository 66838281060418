import React from "react";
import { FormattedMessage } from "react-intl";

export interface Props {
    ["data-testid"]?: string;
}

const FieldRequiredMessage = ({ "data-testid": testId }: Props) => (
    <>
        *<FormattedMessage data-testid={testId} id="form__field-required" />
    </>
);

export default FieldRequiredMessage;
