import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

type Mode = "info" | "error" | 'payment-details-error';

export interface Props {
    messages: (string | React.ReactElement)[];
    mode: Mode;
    spaceless?: boolean;
    className?: string;
    ["data-testid"]?: string;
}

const FieldMessages = ({ messages, mode, spaceless = true, className, "data-testid": testId }: Props) => (
    <div
        data-testid={testId}
        className={classNames(
            styles["field-messages"],
            {
                [styles["field-messages--error"]]: mode === "error",
                [styles["field-messages--payment-details-error"]]: mode === "payment-details-error",
                [styles["field-messages--spaceless"]]: spaceless,
            },
            className
        )}
    >
        {messages.map((message, index) => (
            <div key={`${message}__${index}`}>{message}</div>
        ))}
    </div>
);

export default FieldMessages;
