import { EmployeeOffersApi } from "features/employeeOffers/api";
import { JobOffersApi } from "features/jobOffers/api";
import { MyProfileApi } from "features/myProfile/api";
import { PhotoDto } from "features/user/dtos";
import React, { useEffect } from "react";
import { Image } from "../../common/types";

const useUploadPhotos = (
    photosField: Image[] | undefined,
    offersApi: JobOffersApi | EmployeeOffersApi | MyProfileApi,
    setUploadingImages: React.Dispatch<React.SetStateAction<boolean>>,
    initialPhotos: Image[],
    setDownloadPhotos: React.Dispatch<React.SetStateAction<PhotoDto[]>>,
    downloadPhotos: PhotoDto[],
) => {
    useEffect(() => {
        (async () => {
            if (photosField && !!photosField.length) {
                try {
                    setUploadingImages(true);
                    const imageForm = new FormData();
                    const allImages = [...photosField, ...initialPhotos];
                    allImages.forEach((photo) => !!photo.file && imageForm.append("images", photo.file));
                    const uploadedImages = allImages.length
                        ? await offersApi.uploadUserPhotos(imageForm)
                        : [];
                    setDownloadPhotos([...downloadPhotos, ...uploadedImages]);
                    setUploadingImages(false);

                } catch (error) {
                    if (error) {
                        setUploadingImages(false);
                    }
                    console.warn({ error });
                }
            }
        })();
    }, [photosField]);

};

export default useUploadPhotos;