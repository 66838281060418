import {
    selectAcceptArrivalLoading,
    selectAcceptArrivalSuccess,
    selectAcceptArrivalError,
} from "features/jobOffers/selectors";
import { acceptArrivalAsync } from "features/jobOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import AcceptArrivalModal from "./component";

const mapStateToProps = (state: RootState) => ({
    acceptArrivalLoading: selectAcceptArrivalLoading(state),
    acceptArrivalSuccess: selectAcceptArrivalSuccess(state),
    acceptArrivalError: selectAcceptArrivalError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            acceptArrivalAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AcceptArrivalModal);
