import classNames from "classnames";
import Button from "features/common/components/Button";
import usePrevious from "features/common/hooks/usePrevious";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    additionalInfo: string;
}

const AdditionalInfoPart = ({ "data-testid": testId, additionalInfo }: Props) => {
    // const [isExpanded, setExpanded] = useState(false);
    // const [prevScrollPosition, setPrevScrollPosition] = useState(0);
    // const prevIsExpanded = usePrevious(isExpanded, false);
    // const [isTextClipped, setIsTextClipped] = useState(false);

    const textRef = useRef<HTMLDivElement | null>(null);

    // useEffect(() => {
    //     setIsTextClipped(
    //         !!textRef.current &&
    //             Math.round(
    //                 textRef.current.offsetHeight / parseInt(getComputedStyle(textRef.current).lineHeight.slice(0, -2))
    //             ) > 2
    //     );
    // }, []);

    // const toggleExpansionState = () => {
    //     setExpanded((wasExpanded) => {
    //         setPrevScrollPosition(window.scrollY);

    //         return !wasExpanded;
    //     });
    // };

    // useEffect(() => {
    //     if (isExpanded !== prevIsExpanded) {
    //         window.scrollTo(0, prevScrollPosition);
    //     }
    // }, [isExpanded, prevIsExpanded, prevScrollPosition]);

    return (
        <div data-testid={testId} className={styles["additional-info-part"]}>
            <div
                data-testid={`${testId}__text`}
                className={classNames(styles["additional-info-part__text"], {
                    // [styles["additional-info-part__text--clipped"]]: !isExpanded,
                })}
            >
                <span ref={textRef} dangerouslySetInnerHTML={{ __html: additionalInfo }} />
            </div>
            {/* {(isTextClipped || isExpanded) && (
                <Button
                    data-testid={`${testId}__button`}
                    className={styles["additional-info-part__button"]}
                    onClick={toggleExpansionState}
                    variant="no-background"
                >
                    {isExpanded ? (
                        <FormattedMessage id="offer-details__collapse" />
                    ) : (
                        <FormattedMessage id="offer-details__expand" />
                    )}
                </Button>
            )} */}
        </div>
    );
};

export default AdditionalInfoPart;
