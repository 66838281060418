import Button, { ButtonVariant } from "features/common/components/Button";
import { JobOfferDetails } from "features/jobOffers/models";
import { offersQueryParamNames } from "features/offers/constants";
import { appRoutes } from "features/routing/routes";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Props {
    variant?: ButtonVariant;
    jobOfferDetails?: JobOfferDetails;
    ["data-testid"]?: string;
}

const SeeSimilarJobOffersButton = ({ variant, jobOfferDetails, "data-testid": testId }: Props) => {
    const history = useHistory();

    const onSeeSimilarOffersClick = () => {
        if (jobOfferDetails) {
            history.push(
                `${appRoutes.jobOffers}?branchId=${jobOfferDetails.branch.id}&workType=${jobOfferDetails.workType}&employerType=${jobOfferDetails.employerType}&city=${jobOfferDetails.location.city}`
            );
        }
    };

    return (
        <Button
            variant={variant || "secondary"}
            onClick={onSeeSimilarOffersClick}
            className={styles["see-similar-offers-button"]}
            data-testid={`${testId}__send-message-button`}
        >
            <FormattedMessage id="offer-details__see-similar-offers" />
        </Button>
    );
};

export default SeeSimilarJobOffersButton;
