import { ApiError } from "api/utils";
import {
    ApplicationDetailsDto,
    ApplicationsListDto,
    CreateJobOfferResponseDto,
    InterestedListDto,
    JobOfferCardDetailsResponseDto,
    JobOfferDetailsResponseDto,
    JobOfferResponseDto,
    MyJobOffersResponseDto,
} from "features/jobOffers/dtos";
import { FenigePaymentStatus } from "features/jobOffers/models";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const getJobOffers = createAsyncAction(
    "GET_JOB_OFFERS_REQUEST",
    "GET_JOB_OFFERS_SUCCESS",
    "GET_JOB_OFFERS_FAILURE"
)<void, JobOfferResponseDto, ApiError>();

export const amendJobOffers = createAsyncAction(
    "AMEND_JOB_OFFERS_REQUEST",
    "AMEND_JOB_OFFERS_SUCCESS",
    "AMEND_JOB_OFFERS_FAILURE"
)<void, JobOfferResponseDto, ApiError>();

export const getMyJobOffers = createAsyncAction(
    "GET_MY_JOB_OFFERS_REQUEST",
    "GET_MY_JOB_OFFERS_SUCCESS",
    "GET_MY_JOB_OFFERS_FAILURE",
    "GET_MY_JOB_OFFERS_CANCEL"
)<void, MyJobOffersResponseDto, ApiError>();

export const getInterested = createAsyncAction(
    "GET_INTERESTED_REQUEST",
    "GET_INTERESTED_SUCCESS",
    "GET_INTERESTED_FAILURE"
)<void, InterestedListDto, ApiError>();

export const getApplications = createAsyncAction(
    "GET_APPLICATIONS_REQUEST",
    "GET_APPLICATIONS_SUCCESS",
    "GET_APPLICATIONS_FAILURE"
)<void, ApplicationsListDto, ApiError>();

export const createJobOffer = createAsyncAction(
    "CREATE_JOB_OFFER_REQUEST",
    "CREATE_JOB_OFFER_SUCCESS",
    "CREATE_JOB_OFFER_FAILURE"
)<void, CreateJobOfferResponseDto, ApiError>();

export const getJobOfferDetails = createAsyncAction(
    "GET_JOB_OFFER_DETAILS_REQUEST",
    "GET_JOB_OFFER_DETAILS_SUCCESS",
    "GET_JOB_OFFER_DETAILS_FAILURE"
)<void, JobOfferDetailsResponseDto, ApiError>();

export const getJobOfferCardDetails = createAsyncAction(
    "GET_OFFER_CARD_DETAILS_REQUEST",
    "GET_OFFER_CARD_DETAILS_SUCCESS",
    "GET_OFFER_CARD_DETAILS_FAILURE"
)<void, JobOfferCardDetailsResponseDto, ApiError>();

export const sendJobOfferMessage = createAsyncAction(
    "SEND_JOB_OFFER_MESSAGE_REQUEST",
    "SEND_JOB_OFFER_MESSAGE_SUCCESS",
    "SEND_JOB_OFFER_MESSAGE_FAILURE"
)<void, void, ApiError>();

export const sendDedicationJobOffer = createAsyncAction(
    "SEND_DEDICATION_JOB_OFFER_REQUEST",
    "SEND_DEDICATION_JOB_OFFER_SUCCESS",
    "SEND_DEDICATION_JOB_OFFER_FAILURE"
)<void, void, ApiError>();

export const getApplicationDetails = createAsyncAction(
    "GET_APPLICATION_DETAILS_REQUEST",
    "GET_APPLICATION_DETAILS_SUCCESS",
    "GET_APPLICATION_DETAILS_FAILURE"
)<void, ApplicationDetailsDto, ApiError>();

export const acceptApplication = createAsyncAction(
    "ACCEPT_APPLICATION_REQUEST",
    "ACCEPT_APPLICATION_SUCCESS",
    "ACCEPT_APPLICATION_FAILURE"
)<void, void, ApiError>();

export const rejectApplication = createAsyncAction(
    "REJECT_APPLICATION_REQUEST",
    "REJECT_APPLICATION_SUCCESS",
    "REJECT_APPLICATION_FAILURE"
)<void, void, ApiError>();

export const acceptArrival = createAsyncAction(
    "ACCEPT_ARRIVAL_REQUEST",
    "ACCEPT_ARRIVAL_SUCCESS",
    "ACCEPT_ARRIVAL_FAILURE"
)<void, void, ApiError>();

export const giveBonus = createAsyncAction("GIVE_BONUS_REQUEST", "GIVE_BONUS_SUCCESS", "GIVE_BONUS_FAILURE")<
    void,
    void,
    ApiError
>();

export const endOffer = createAsyncAction("END_OFFER_REQUEST", "END_OFFER_SUCCESS", "END_OFFER_FAILURE")<
    void,
    void,
    ApiError
>();

export const startFenigePayment = createAsyncAction(
    "START_FENIGE_PAYMENT_REQUEST",
    "START_FENIGE_PAYMENT_SUCCESS",
    "START_FENIGE_PAYMENT_FAILURE"
)<void, FenigePaymentStatus, ApiError>();

export const resetJobOffers = createAction("RESET_JOB_OFFERS")<void>();

export const resetMyJobOffers = createAction("RESET_MY_JOB_OFFERS")<void>();

export const resetJobOfferDetails = createAction("RESET_JOB_OFFER_DETAILS")<void>();

export const resetCreateJobOfferState = createAction("RESET_CREATE_JOB_OFFER_STATE")<void>();

export const resetSendJobOfferMessageFlags = createAction("RESET_SEND_JOB_OFFER_MESSAGE_FLAGS")<void>();

export const resetJobOffersFilterMatchCounts = createAction("RESET_JOB_OFFERS_FILTER_MATCH_COUNTS")<void>();

export const resetFenigePayment = createAction("RESET_FENIGE_PAYMENT")<void>();

export const resetSendDedicationJobOfferState = createAction("RESET_SEND_DEDICATION_JOB_OFFER_STATE")<void>();

type JobOffersAction =
    | ActionType<typeof getJobOffers>
    | ActionType<typeof amendJobOffers>
    | ActionType<typeof getMyJobOffers>
    | ActionType<typeof getInterested>
    | ActionType<typeof getApplications>
    | ActionType<typeof createJobOffer>
    | ActionType<typeof getJobOfferDetails>
    | ActionType<typeof getJobOfferCardDetails>
    | ActionType<typeof sendJobOfferMessage>
    | ActionType<typeof sendDedicationJobOffer>
    | ActionType<typeof getApplicationDetails>
    | ActionType<typeof acceptApplication>
    | ActionType<typeof rejectApplication>
    | ActionType<typeof acceptArrival>
    | ActionType<typeof giveBonus>
    | ActionType<typeof endOffer>
    | ActionType<typeof startFenigePayment>
    | ActionType<typeof resetJobOffers>
    | ActionType<typeof resetMyJobOffers>
    | ActionType<typeof resetJobOfferDetails>
    | ActionType<typeof resetCreateJobOfferState>
    | ActionType<typeof resetSendJobOfferMessageFlags>
    | ActionType<typeof resetSendDedicationJobOfferState>
    | ActionType<typeof resetJobOffersFilterMatchCounts>
    | ActionType<typeof resetFenigePayment>;

export default JobOffersAction;
