import classNames from "classnames";
import Button from "features/common/components/Button";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]: string;
    toggleCalendarExpandState: (expanded: boolean) => void;
    onDateSelectApprove: () => void;
}

const ControlButtons = ({ "data-testid": testId, toggleCalendarExpandState, onDateSelectApprove }: Props) => {
    const deviceClass = useDeviceClass();

    return deviceClass !== "desktop" ? (
        <div
            data-testid={`${testId}__control-buttons`}
            className={classNames(styles["datepicker__control-buttons"], {
                [styles["datepicker__control-buttons--with-border"]]: true,
            })}
        >
            <Button data-testid={`${testId}__cancel-button`} onClick={() => toggleCalendarExpandState(false)}>
                <FormattedMessage id="common__cancel" />
            </Button>
            <Button data-testid={`${testId}__select-button`} onClick={onDateSelectApprove}>
                <FormattedMessage id="common__select" />
            </Button>
        </div>
    ) : null;
};

export default ControlButtons;
