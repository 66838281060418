import { ApiError } from "api/utils";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { RatePreviewResponse } from "../models";

export const rateEmployer = createAsyncAction(
    "RATE_EMPLOYER_REQUEST",
    "RATE_EMPLOYER_SUCCESS",
    "RATE_EMPLOYER_FAILURE"
)<void, void, ApiError>();

export const rateEmployee = createAsyncAction(
    "RATE_EMPLOYEE_REQUEST",
    "RATE_EMPLOYEE_SUCCESS",
    "RATE_EMPLOYEE_FAILURE"
)<void, void, ApiError>();

export const rateEmployerSummary = createAsyncAction(
    "RATE_EMPLOYER_PREVIEW_REQUEST",
    "RATE_EMPLOYER_PREVIEW_SUCCESS",
    "RATE_EMPLOYER_PREVIEW_FAILURE"
)<void, RatePreviewResponse, ApiError>();

export const rateEmployeeSummary = createAsyncAction(
    "RATE_EMPLOYEE_PREVIEW_REQUEST",
    "RATE_EMPLOYEE_PREVIEW_SUCCESS",
    "RATE_EMPLOYEE_PREVIEW_FAILURE"
)<void, RatePreviewResponse, ApiError>();

export const resetRateEmployerState = createAction("RESET_RATE_EMPLOYER")<void>();

export const resetRateEmployeeState = createAction("RESET_RATE_EMPLOYEE")<void>();

export const resetRateEmployerSummaryState = createAction("RESET_RATE_EMPLOYER_SUMMARY")<void>();

export const resetRateEmployeeSummaryState = createAction("RESET_RATE_EMPLOYEE_SUMMARY")<void>();

type RatingAction =
    | ActionType<typeof rateEmployer>
    | ActionType<typeof rateEmployee>
    | ActionType<typeof rateEmployerSummary>
    | ActionType<typeof rateEmployeeSummary>
    | ActionType<typeof resetRateEmployerState>
    | ActionType<typeof resetRateEmployerSummaryState>
    | ActionType<typeof resetRateEmployeeSummaryState>
    | ActionType<typeof resetRateEmployeeState>;

export default RatingAction;
