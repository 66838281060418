import { ApiError, CancelToken } from "api/utils";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getEmployeeOffersMessage, getHomeMessage } from "features/common/translationMessages";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { appRoutes } from "features/routing/routes";
import React, { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DetailsActions from "./DetailsActions";
import OfferDetails from "./OfferDetails";
import styles from "./styles.module.scss";

interface Props {
    id: number;
    offerDetails?: EmployeeOfferDetails;
    offerDetailsLoading?: boolean;
    offerDetailsLoadingError?: ApiError;
    isUserLoggedIn?: boolean;
    getEmployeeOfferDetailsAsync?: (id: number, cancelToken?: CancelToken) => void;
}

const EmployeeOfferDetailsComponent = ({
    id,
    offerDetails,
    offerDetailsLoading,
    offerDetailsLoadingError,
    isUserLoggedIn,
    getEmployeeOfferDetailsAsync,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();

    window.scrollTo(0, 0);

    useEffect(() => {
        getEmployeeOfferDetailsAsync && getEmployeeOfferDetailsAsync(id, createCancelToken());
    }, [createCancelToken, getEmployeeOfferDetailsAsync, id]);

    const homeMessage = getHomeMessage(intl);
    const employeeOffersMessage = getEmployeeOffersMessage(intl);

    const crumbs: BreadcrumbsItem[] = useMemo(
        () => [
            {
                displayName: homeMessage,
                path: appRoutes.dashboard,
            },
            {
                displayName: employeeOffersMessage,
                path: appRoutes.employeeOffers,
            },
            {
                displayName: offerDetails?.position ?? "",
                path: `${appRoutes.jobOffers}/${offerDetails?.id ?? ""}`,
            },
        ],
        [employeeOffersMessage, homeMessage, offerDetails]
    );

    return (
        <div className={styles["employee-offer-details"]}>
            {offerDetailsLoading && (
                <SpinnerWithMessage
                    className={styles["employee-offer-details__loading-offer-details-modal"]}
                    message={<FormattedMessage id="employee-offer-details__loading-offer-details" />}
                />
            )}
            {offerDetailsLoadingError ? (
                <div className={styles["employee-offer-details__error-container"]}>
                    <FormattedMessage id="employee-offer-details__fetch-failure" />
                </div>
            ) : (
                offerDetails && (
                    <div>
                        <div className={styles["employee-offer-details__breadcrumbs-container"]}>
                            <Breadcrumbs data-testid="employee-offer-details__breadcrumbs" crumbs={crumbs} />
                        </div>
                        <OfferDetails
                            isUserLoggedIn={isUserLoggedIn}
                            offerDetails={offerDetails}
                            offerDetailsLoading={!!offerDetailsLoading}
                            actionsBox={
                                <div>
                                    <DetailsActions
                                        isUserLoggedIn={!!isUserLoggedIn}
                                        offerDetails={offerDetails}
                                        data-testid="employee-offer-details"
                                        maxMessageLength={2000}
                                        offerId={id}
                                    />
                                </div>
                            }
                        />
                    </div>
                )
            )}
        </div>
    );
};

export default EmployeeOfferDetailsComponent;
