import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.user;

export const selectUser = createSelector(selectState, (state) => state.user);
export const selectUserLoading = createSelector(selectState, (state) => state.userLoading);
export const selectUserLoadingError = createSelector(selectState, (state) => state.userLoadingError);
export const selectUserLoadingSuccess = createSelector(selectState, (state) => state.userLoadingSuccess);

export const selectUserId = createSelector(selectState, (state) => state.userId);
export const selectLoginUserLoading = createSelector(selectState, (state) => state.loginUserLoading);
export const selectLoginUserLoadingError = createSelector(selectState, (state) => state.loginUserLoadingError);

export const selectRegisterUserLoading = createSelector(selectState, (state) => state.registerUserLoading);
export const selectRegisterUserLoadingError = createSelector(selectState, (state) => state.registerUserLoadingError);

export const selectUserProfileCreated = createSelector(selectState, (state) => state.userProfileCreated);
export const selectUserProfileCreating = createSelector(selectState, (state) => state.userProfileCreating);
export const selectUserProfileCreatingError = createSelector(selectState, (state) => state.userProfileCreatingError);

export const selectIsUserLoggedIn = createSelector(selectState, (state) => state.isUserLoggedIn);
export const selectIsUserLoggedInWithProfile = createSelector(
    selectState,
    (state) => state.user !== undefined && !!state.user.firstName && state.isUserLoggedIn
);

export const selectCancelTokenLoading = createSelector(selectState, (state) => state.cancelTokenLoading);
export const selectIsTokenCancelled = createSelector(selectState, (state) => state.isTokenCancelled);

export const selectUserTokenValidationLoading = createSelector(
    selectState,
    (state) => state.userTokenValidationLoading
);

export const selectVerifyUserLoading = createSelector(selectState, (state) => state.verifyUserLaoding);
export const selectVerifyUserLoadingSuccess = createSelector(selectState, (state) => state.verifyUserLoadingSuccess);
export const selectVerifyUserLoadingError = createSelector(selectState, (state) => state.verifyUserLoadingError);

export const selectUserProfile = createSelector(selectState, (state) => state.userProfile);
export const selectUserProfileLoading = createSelector(selectState, (state) => state.userProfileLoading);
export const selectUserProfileError = createSelector(selectState, (state) => state.userProfileError);


// FORGOT PASSWORD
export const selectForgotPasswordLoading = createSelector(selectState, (state) => state.forgottenPasswordLoading);
export const selectForgotPasswordLoadingSuccess = createSelector(selectState, (state) => state.forgottenPasswordLoadingSuccess);
export const selectForgotPasswordLoadingError = createSelector(selectState, (state) => state.forgottenPasswordLoadingError);

// RECOVER PASSWORD
export const selectRecoverPasswordLoading = createSelector(selectState, (state) => state.recoverPasswordLoading);
export const selectRecoverPasswordLoadingSuccess = createSelector(selectState, (state) => state.recoverPasswordLoadingSuccess);
export const selectRecoverPasswordLoadingError = createSelector(selectState, (state) => state.recoverPasswordLoadingError);