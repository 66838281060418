import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.moderatorPanel;

export const selectOffers = createSelector(selectState, (state) => state.offers);
export const selectOffersLoading = createSelector(selectState, (state) => state.offersLoading);
export const selectOffersLoadingError = createSelector(selectState, (state) => state.offersLoadingError);
export const selectTotalOffers = createSelector(selectState, (state) => state.totalOffers);


export const selectActionOfferId = createSelector(selectState, (state) => state.actionOfferRequest);
export const selectActionOfferLoading = createSelector(selectState, (state) => state.actionOfferLoading);
export const selectActionOfferLoadingError = createSelector(selectState, (state) => state.actionOfferLoadingError);


