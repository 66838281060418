import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    currentStepIndex: number;
    children: React.ReactElement[];
    stepNames?: string[];
    stepContainerClassName?: string;
}

const StepSwitch = ({ currentStepIndex, children, stepNames, stepContainerClassName }: Props) => (
    <div className={styles["step-switch"]}>
        {stepNames && <h2 className={styles["step-switch__header"]}>{stepNames[currentStepIndex]}</h2>}
        <div className={classNames(styles["step-switch__step-container"], stepContainerClassName)}>
            {currentStepIndex < children.length && children[currentStepIndex]}
        </div>
    </div>
);

export default StepSwitch;
