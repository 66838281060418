import { ReactComponent as VerticalThreeDotsIcon } from "assets/icons/verticalThreeDots.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { User } from "features/adminPanel/model";
import { GetUsersFetchParams } from "features/adminPanel/types";
import { TableColumn } from "features/common/components/DataTable";
import { SortDirection } from "features/common/types";
import { Locale } from "lang";
import React from "react";

export const getUsersTableColumns = (
    labels: string[],
    sortCallbacks: ((direction: SortDirection) => void)[],
    actionDesktopDisplay: string,
    actionOptionLabels: string[],
    actionOptionCallbacks: ((id: string) => void)[]
): TableColumn<User>[] => [
    {
        key: "id",
        hidden: true,
    },
    {
        key: "isBlocked",
        label: "",
        customRender: (value) => (!!value ? <LockIcon /> : <></>),
    },
    {
        key: "email",
        label: labels[0],
        withSort: true,
        sortCallback: sortCallbacks[0],
    },
    {
        key: "firstName",
        label: labels[1],
        withSort: true,
        sortCallback: sortCallbacks[1],
    },
    {
        key: "lastName",
        label: labels[2],
        withSort: true,
        sortCallback: sortCallbacks[2],
    },
    {
        actionColumn: true,
        actionDisplay: <VerticalThreeDotsIcon />,
        actionDesktopDisplay,
        actionOptions: actionOptionLabels.map((text, index) => ({ text, callback: actionOptionCallbacks[index] })),
    },
];

export const getInitialGetUsersFetchParams = (locale: Locale): GetUsersFetchParams => ({
    pageSize: 5,
    offset: 0,
});
