import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.contactForm;

export const selectContactFormMessageSending = createSelector(selectState, (state) => state.contactFormMessageSending);
export const selectContactFormMessageSendingSuccess = createSelector(
    selectState,
    (state) => state.contactFormMessageSendingSuccess
);
export const selectContactFormMessageError = createSelector(
    selectState,
    (state) => state.contactFormMessageSendingError
);
