import GetCallActions, {
    answerCallAction,
    leaveCallAction,
    makeCallAction,
    setStreamAction,
    setConection,
    setCallAction,
    setSocketAction,
} from "features/getCall/actions";
import produce from "immer";
import { getType } from "typesafe-actions";
import Peer from 'simple-peer';
import { Call } from "../types";
import { Socket } from "socket.io-client";

export interface GetCallState {
    stream: MediaStream | null
    connection: Peer.Instance | undefined
    call: Call
    callAccepted: boolean
    socket: Socket | undefined
}

const initialState: GetCallState = {
    socket: undefined,
    stream: null,
    connection: undefined,
    call: {
        from: '',
        name: '',
        signal: '',
        isReceivingCall: false
    },
    callAccepted: false,
};

const reducer = (state: GetCallState = initialState, action: GetCallActions) => {
    return produce(state, (draft: GetCallState) => {
        switch (action.type) {
            case getType(setSocketAction):
                draft.socket = action.payload.socket
                break;
            case getType(setStreamAction):
                draft.stream = action.payload.stream
                break;
            case getType(setConection):
                draft.connection = action.payload.connection
                break;
            case getType(setCallAction):
                draft.call = action.payload.call
                break;
            case getType(makeCallAction):
                break;
            case getType(answerCallAction):
                draft.callAccepted = true
                break;
        }
    });
};

export default reducer;
