import {useEffect, useState} from "react";
import {useMediaQuery} from "./useMediaQuery";

const useTitleSubstring = (title: string, mobile: number, small: number, tablet: number, middle: number, desk: number) => {
    const [changedTitle, setChangedTitle] = useState(title);
    const isMatchesMobile = useMediaQuery('(max-width: 499px)');
    const isMatchesSmall = useMediaQuery('(min-width: 500px) and (max-width: 833px)');
    const isMatchesSmallTab = useMediaQuery('(min-width: 834px) and (max-width: 899px)');
    const isMatchesMiddle = useMediaQuery('(min-width: 900px) and (max-width: 1439px)');
    const isMatchesDesktop = useMediaQuery('(min-width: 1440px)');

    useEffect(() => {
        if (isMatchesMobile && title.length >= mobile) {
            return setChangedTitle(`${title.substring(0, mobile)}...`);
        } else if (isMatchesSmall && title.length >= small) {
            return setChangedTitle(`${title.substring(0, small)}...`);
        }else if (isMatchesSmallTab && title.length >= tablet) {
            return setChangedTitle(`${title.substring(0, tablet)}...`);
        } else if (isMatchesMiddle && title.length >= middle) {
            return setChangedTitle(`${title.substring(0, middle)}...`);
        } else if (isMatchesDesktop && title.length >= desk) {
            return setChangedTitle(`${title.substring(0, desk)}...`);
        }else {
            return setChangedTitle(title);
        }
    }, [isMatchesMobile, isMatchesSmall, isMatchesSmallTab, isMatchesMiddle,  isMatchesDesktop, title]);
    return { changedTitle };
}

export default useTitleSubstring;