import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useField } from "formik";

import Input from "features/common/components/Input";
import { paymentFormFieldNames } from "features/jobOffers/constants";

import styles from "./styles.module.scss";

export interface Props {
    isEditing: boolean;
    cardData: any;
    disabled: boolean;
}

const DataSection = ({ isEditing, cardData, disabled }: Props) => {
    const [cardNumberField, cardNumberMeta] = useField(paymentFormFieldNames.cardNumber);
    const [expirationDateField, expirationDateMeta] = useField(paymentFormFieldNames.expirationDate);
    const [cardCvcField, cardCvcMeta] = useField(paymentFormFieldNames.cvc);

    return (
        <div className={styles["payment-card-section"]}>
            <div className={styles["payment-card-section__column"]}>
                <div className={styles["payment-card-section__row"]}>
                    <div
                        className={classNames(styles["payment-card-section__label"], {
                            [styles["payment-card-section__editing-label"]]: !!isEditing,
                        })}
                    >
                        <FormattedMessage id="my-data__card-number-label" />
                    </div>
                    {isEditing ? (
                        <Input
                            data-testid="payment-card-section__card-number"
                            wrapperClassName={styles["payment-card-section__input"]}
                            {...cardNumberField}
                            {...cardNumberMeta}
                            withValidation
                            isInvalid={!!cardNumberMeta.error && !!cardNumberMeta.touched}
                        />
                    ) : (
                        <div className={styles["payment-card-section__value"]}>{cardData?.cardNumber || "-"}</div>
                    )}
                </div>
                <div className={styles["payment-card-section__row"]}>
                    <div
                        className={classNames(styles["payment-card-section__label"], {
                            [styles["payment-card-section__editing-label"]]: !!isEditing,
                        })}
                    >
                        <FormattedMessage id="my-data__card-cvc-label" />
                    </div>
                    <Input
                        data-testid="payment-card-section__card-number"
                        wrapperClassName={styles["payment-card-section__input"]}
                        {...cardCvcField}
                        {...cardCvcMeta}
                        withValidation
                        isInvalid={!!cardCvcMeta.error && !!cardCvcMeta.touched}
                        inputMask="999"
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles["payment-card-section__column"]}>
                <div className={styles["payment-card-section__row"]}>
                    <div
                        className={classNames(styles["payment-card-section__label"], {
                            [styles["payment-card-section__editing-label"]]: !!isEditing,
                        })}
                    >
                        <FormattedMessage id="my-data__expiry-date-label" />
                    </div>
                    {isEditing ? (
                        <Input
                            data-testid="payment-card-section__expiry-date"
                            wrapperClassName={styles["payment-card-section__input"]}
                            {...expirationDateField}
                            {...expirationDateMeta}
                            withValidation
                            isInvalid={!!expirationDateMeta.error && !!expirationDateMeta.touched}
                            inputMask="99/99"
                        />
                    ) : (
                        <div className={styles["payment-card-section__value"]}>
                            {cardData?.cardNumber ? "**/**" : "-"}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DataSection;
