import EmployeeOfferDetailsComponent from "features/employeeOffers/components/EmployeeOfferDetails";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
    id?: string;
}

const EmployeeOfferDetails = () => {
    let { id } = useParams<Props>();

    return (
        <div className={styles["employee-offer-details"]}>
            <div className={styles["employee-offer-details__inner-container"]}>
                {id && <EmployeeOfferDetailsComponent id={parseInt(id!)} />}
            </div>
        </div>
    );
};

export default EmployeeOfferDetails;
