import { ReactComponent as ArrowRightIcon } from "assets/icons/arrowRight.svg";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import DefaultImage from "features/common/components/DefaultImage";
import { Card } from "features/common/types";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Props {
    card?: Card;
    user?: User;
    isEmployer?: boolean;
    isSelectedCard?: boolean;
    setSelectedCard: (userId: string) => void;
    ["data-testid"]?: string;
}

const SingleCard = ({ card, user, isEmployer, isSelectedCard, setSelectedCard, "data-testid": testId }: Props) => {
    const history = useHistory();

    const goToCardDetails = () => {
        history.push(`${appRoutes.card}/${card?.id}`);
    };

    const renderTitle = useMemo(() => {
        if (!!card?.companyName) {
            return card.companyName;
        }

        if (!!user?.firstName || !!user?.lastName) {
            return `${user?.firstName ?? ""} ${user?.lastName ?? ""}`;
        }

        return "";
    }, [card, user]);

    return (
        <div
            data-testid={testId}
            className={classNames(styles["application-single-card"], {
                [styles["application-single-card__selected"]]: !!isSelectedCard,
                [styles["application-single-card__employer-selected"]]: !!isEmployer && !!isSelectedCard,
            })}
            onClick={() => card?.id && setSelectedCard(card?.id)}
        >
            <div
                data-testid={`${testId}__image`}
                className={classNames(styles["application-single-card__image-container"], {
                    [styles["application-single-card__employer-image-container"]]: !!isEmployer,
                })}
            >
                {!card?.mainPhoto ? (
                    <DefaultImage />
                ) : (
                    <img
                        className={styles["application-single-card__image"]}
                        src={`${config.IMAGE_URL}/${card?.mainPhoto}`}
                        alt={renderTitle}
                    />
                )}
            </div>
            <div className={styles["application-single-card__logo-container"]}>
                {card?.logo ? (
                    <img
                        className={styles["application-single-card__logo"]}
                        src={`${config.IMAGE_URL}/${card?.logo}`}
                        alt={renderTitle}
                    />
                ) : (
                    <div className={styles["application-single-card__logo-default"]}>
                        <span>
                            <FormattedMessage id="application-single-card__logo-default" />
                        </span>
                    </div>
                )}
            </div>
            <div className={styles["application-single-card__data-container"]}>
                <div className={styles["application-single-card__title-wrapper"]}>
                    <div
                        data-testid={`${testId}__title`}
                        className={classNames(styles["application-single-card__title"], {
                            [styles["application-single-card__employer-title"]]: !!isEmployer,
                        })}
                    >
                        {renderTitle}
                    </div>
                </div>
                <div className={styles["application-single-card__subtitle-wrapper"]}>
                    <div className={styles["application-single-card__subtitle"]}>
                        {card?.city ? `${card?.city}, ` : ''}
                        {card?.country}
                    </div>
                </div>
                {/* <div className={styles["application-single-card__values-container"]}>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Doświadczenie zawodowe<span>nazwa firmy, stanowisko</span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Wykształcenie<span>średnie</span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Uprawnienia<span>-</span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Licencje i certyfikaty<span>ISO 9001, ISO 22000, ISO 14001</span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Prawo jazdy<span>C1+E</span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Języki obce<span>angielski, portugalski, niemiecki</span>
                        </div>
                        <Checkbox checked={false} className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Programy komputerowe
                            <span>
                                {
                                    card?.requirements?.find((item) => item.requirementType === "COMPUTER_AND_SOFTWARE")
                                        ?.value
                                }
                            </span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                    <div className={styles["application-single-card__value-container"]}>
                        <div className={styles["application-single-card__label"]}>
                            Inne wymagania<span>-</span>
                        </div>
                        <Checkbox checked className={styles["application-single-card__checkbox"]} />
                    </div>
                </div> */}
            </div>
            <div
                className={classNames(styles["application-single-card__action"], {
                    [styles["application-single-card__employer-action"]]: !!isEmployer,
                })}
            >
                <Button data-testid={`${testId}__button`} onClick={goToCardDetails} variant="no-background">
                    <span>
                        <FormattedMessage id="single-card__card-details" />
                    </span>
                    <ArrowRightIcon />
                </Button>
            </div>
        </div>
    );
};

export default SingleCard;
