import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import { defaultLocale } from "features/common/constants";
import { CardDto } from "features/common/dtos";
import { cookieNames } from "features/cookies/constants";
import { getCookie } from "features/cookies/utils";
import {
    CardDetailsDto,
    CardRelatedOffersDto,
    CreateNewCardDto,
    MyApplicationsDto,
    MyProfileCardsDto,
    MyProfileUserDetailsDto,
    NewCardResponseDto,
    OfferCreationCardsDto,
    PaymentCardDto,
} from "features/myProfile/dtos";
import { PaymentData } from "features/myProfile/models";
import { CardOwnerRole } from "features/myProfile/types";
import { PhotoDto, UserDto, UserProfileDto } from "features/user/dtos";

export class MyProfileApi extends Api {
    uploadUserPhotoAsync = (formData: Partial<UserProfileDto>, cancelToken?: CancelToken): Promise<void> => {
        return this.put<Partial<UserDto>, void>(`${config.apiRoutes.USER}/profile-picture`, formData, cancelToken);
    };

    updateMyProfileDetailsAsync = (
        userDetails: MyProfileUserDetailsDto,
        cancelToken?: CancelToken
    ): Promise<void> => {
        return this.put<MyProfileUserDetailsDto, void>(
            `${config.apiRoutes.USER}`,
            userDetails,
            cancelToken
        );
    };

    getOfferCreationCardsAsync = (cancelToken?: CancelToken): Promise<OfferCreationCardsDto> => {
        return this.get<OfferCreationCardsDto>(`${config.apiRoutes.GET_OFFER_CREATION_CARDS}`, cancelToken);
    };

    getMyProfileCardsAsync = (userId: string, cancelToken?: CancelToken): Promise<MyProfileCardsDto> => {
        return this.get<MyProfileCardsDto>(`${config.apiRoutes.GET_MY_PROFILE_CARDS}`, cancelToken);
    };

    createNewCardAsync = (newCard: CreateNewCardDto, cancelToken?: CancelToken): Promise<NewCardResponseDto> => {
        return this.post<CreateNewCardDto, NewCardResponseDto>(config.apiRoutes.BUSINESS_CARDS, newCard, cancelToken);
    };

    getCardDetailsAsync = (id: string, cancelToken?: CancelToken): Promise<CardDetailsDto> => {
        return this.get<CardDetailsDto>(`${config.apiRoutes.BUSINESS_CARDS}/${id}` + "?location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale, cancelToken);
    };

    updateMyProfileCardAsync = async (
        cardDetails: Partial<CardDto>,
        id: string,
        cancelToken?: CancelToken
    ): Promise<CardDetailsDto> => {
        return this.put(`${config.apiRoutes.BUSINESS_CARDS}/${id}`, cardDetails, cancelToken);
    };

    getRelatedOffersAsync = (
        cardUniqueId: string,
        cardKind: string,
        pageSize: number,
        offset: number,
        cancelToken?: CancelToken
    ): Promise<CardRelatedOffersDto> => {
        return this.get<CardRelatedOffersDto>(
            `${config.apiRoutes.BUSINESS_CARDS}/${cardUniqueId}/${cardKind === "EMPLOYER" ? "job-offer" : "employee-offer"
            }?size=${pageSize}&page=${offset - 1}&status=ACTIVE&sort_by=addedDate&sort=desc`,
            cancelToken
        );
    };

    getMyApplicationsAsync = (fetchParams?: any, cancelToken?: CancelToken): Promise<MyApplicationsDto> => {
        return this.get<MyApplicationsDto>(
            `${config.apiRoutes.APPLICATIONS}/${config.apiRoutes.MY_APPLICATIONS}?sortBy=applicationDate&sort=${fetchParams.sort || 'asc'}`,
            cancelToken
        );
    };

    uploadUserPhotos = (params: FormData, cancelToken?: CancelToken): Promise<PhotoDto[]> => {
        return this.post<FormData, PhotoDto[]>(config.apiRoutes.IMAGE_UPLOAD, params, cancelToken);
    };

    uploadImagesAsync = (params: FormData, cancelToken?: CancelToken): Promise<PhotoDto[]> => {
        return this.post<FormData, PhotoDto[]>(config.apiRoutes.IMAGE_UPLOAD, params, cancelToken);
    };

    createNewPaymentCardAsync = (params: PaymentData, cancelToken?: CancelToken): Promise<PaymentCardDto> => {
        return this.post<{ cardNumber: string; expirationDate: string; }, PaymentCardDto>(config.apiRoutes.FENIGE_CARDS, { cardNumber: params.cardNumber, expirationDate: params.expiryDate }, cancelToken);
    };

    getPaymentCardAsync = (cancelToken?: CancelToken): Promise<any> => {
        return this.get<any>(`${config.apiRoutes.FENIGE_CARDS}`, cancelToken);
    };

    deletePaymentCardAsync = (cancelToken?: CancelToken): Promise<any> => {
        return this.delete<any>(`${config.apiRoutes.FENIGE_CARDS}`, cancelToken);
    };

    getCardRatingAsync = (cardId: string, cardKind: CardOwnerRole, cancelToken?: CancelToken): Promise<any> => {
        if (cardKind === "EMPLOYEE") {
            return this.get<any>(`${config.apiRoutes.RATE_EMPLOYEE}/businessCard?businessCardId=${cardId}`, cancelToken);
        }

        return this.get<any>(`${config.apiRoutes.RATE_EMPLOYER}/businessCard?businessCardId=${cardId}`, cancelToken);
    };

    deleteCardAsync = (cardId: string, cancelToken?: CancelToken): Promise<any> => {
        return this.delete<any>(`${config.apiRoutes.DELETE_CARD_BY_ID_URL}/${cardId}`, cancelToken);
    };

    deleteMyAccountAsync = (params: any, cancelToken?: CancelToken): Promise<any> => {
        return this.post<any, any>(`${config.apiRoutes.DELETE_MY_ACCOUNT}`, params, cancelToken)
    }
}

export default new MyProfileApi();
