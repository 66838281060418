/* eslint-disable max-lines */
import { addDays, addMonths, toDate } from "date-fns";
import { monetaryAmountRegex } from "features/common/constants";
import mapUtcStringToLocalDate from "features/common/mappers/mapUtcStringToLocalDate";
import { Currency, SalaryType } from "features/common/models";
import { DictionaryItem, FilterMatchCount, WorkType } from "features/common/types";
import { isDateValid } from "features/common/utils";
import {
    EmployeeOfferDetailsResponseDto,
    EmployeeOffersFilterMatchCountsDto,
    EmployeeOffersResponseDto,
    MyEmployeeOffersResponseDto,
} from "features/employeeOffers/dtos";
import {
    CreateEmployeeOfferRequestObject,
    EmployeeOffer,
    MyEmployeeOfferCardDetails,
} from "features/employeeOffers/models";
import { CreateEmployeeOfferSchema } from "features/employeeOffers/schemas";
import {
    EmployeeOfferAmenityType,
    EmployeeOfferCapacityType,
    EmployeeOffersFetchParams,
    EmployeeOffersFilterMatchCounts,
    EmployeeOfferType,
} from "features/employeeOffers/types";
import { FavoriteEmployerResponseDto } from "features/favorite/dtos";
import { EducationType, LanguageLevelType, PayoutDayType } from "features/jobOffers/types";
import { mapOffersFetchParamsToQueryParameters, mapOffersQuerySearchToFetchParams } from "features/offers/mappers";
import { AmenityType, OfferStatus, OfferType } from "features/offers/types";

const capacityTypeFilterName = "byCapacityType";
const workDuringHolidaysFilterName = "byWorkDuringHolidays";
const amenitiesFilterName = "byAmenities";
const minimalRewardValueFilterName = "minimalRewardValue";
const minimalRewardCurrencyFilterName = "minimalRewardCurrencyCode";
const minimalBonusValueFilterName = "minimalBonusValue";
const minimalBonusCurrencyFilterName = "minimalBonusCurrencyCode";
const businessCardIdFilterName = "businessCardId";

export const mapEmployeeOffersDtoToEmployeeOffers = (dto: EmployeeOffersResponseDto): Array<EmployeeOffer> =>
    dto.data.page.content.map((offerDto) => ({
        ...offerDto,
        imageUrl: offerDto.mainImage,
        addedDate: mapUtcStringToLocalDate(offerDto.addedDate),
        jobEndDate: mapUtcStringToLocalDate(offerDto.jobEndDate),
        jobStartDate: mapUtcStringToLocalDate(offerDto?.jobStartDate),
        expirationDate: mapUtcStringToLocalDate(offerDto.expirationDate),
        status:
            offerDto.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : offerDto.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
        offerType: offerDto.offerType === "Group" ? OfferType.Group : OfferType.Single,
        amenities: (Object.keys(offerDto.amenities) as Array<keyof typeof offerDto.amenities>)
            .filter((amenity: keyof typeof AmenityType) => {
                return !!offerDto.amenities[amenity];
            })
            .map((amenityKey: keyof typeof AmenityType) => ({
                amenityType: amenityKey as AmenityType,
                value: `${offerDto.amenities[amenityKey]}`,
            })),
    }));

export const mapEmployeeOffersFilterMatchCountsDtoToEmployeeOffersFilterMatchCounts = (
    data: EmployeeOffersResponseDto | FavoriteEmployerResponseDto
): EmployeeOffersFilterMatchCounts => {
    const dto: EmployeeOffersFilterMatchCountsDto = {
        filterMatchCounts: {
            byBranch: Object.keys(data.data.branchesWithCount).map(
                (id: string): FilterMatchCount<number> => ({
                    code: +id,
                    matchCount: data.data.branchesWithCount[id],
                })
            ),
            byCapacityType: [
                {
                    code: "0",
                    matchCount: 10,
                },
                {
                    code: "1",
                    matchCount: 14,
                },
            ],
            byAmenities: [
                {
                    code: "0",
                    matchCount: 10,
                },
                {
                    code: "1",
                    matchCount: 14,
                },
            ],
            byWorkDuringHolidays: 12,
        },
    };
    if (data.data.workTypesWithCount) {
        dto.filterMatchCounts.byWorkType = (
            Object.keys(data.data.workTypesWithCount) as Array<
                keyof typeof data.data.workTypesWithCount
            >
        ).map(
            (workType: WorkType): FilterMatchCount<WorkType> => ({
                code: workType,
                matchCount: data.data.workTypesWithCount[workType],
            })
        );
    }
    const byCapacityType = dto.filterMatchCounts.byCapacityType.map((filterMatchCount) => ({
        ...filterMatchCount,
        code: filterMatchCount.code === "0" ? EmployeeOfferCapacityType.Single : EmployeeOfferCapacityType.Group,
    }));
    const byAmenities = dto.filterMatchCounts.byAmenities.map((filterMatchCount) => ({
        ...filterMatchCount,
        code:
            filterMatchCount.code === "0" ? EmployeeOfferAmenityType.Accommodation : EmployeeOfferAmenityType.Transport,
    }));
    const byWorkDuringHolidays = dto.filterMatchCounts.byWorkDuringHolidays;

    return { ...dto.filterMatchCounts, byCapacityType, byAmenities, byWorkDuringHolidays };
};

export const mapEmployeeOffersFetchParamsToQueryParameters = (
    params: EmployeeOffersFetchParams,
    withPaginationParameters: boolean = true,
    withFilterParameters: boolean = true
) => {
    let queryParams = mapOffersFetchParamsToQueryParameters(params, withPaginationParameters);

    if (withFilterParameters) {
        if (params.filters.minimalBonusValue && params.filters.minimalBonusCurrencyCode) {
            queryParams += `&${minimalBonusValueFilterName}=${params.filters.minimalBonusValue}&${minimalBonusCurrencyFilterName}=${params.filters.minimalBonusCurrencyCode}`;
        }
        if (params.filters.minimalSalaryValue && params.filters.minimalSalaryCurrencyCode) {
            queryParams += `&${minimalRewardValueFilterName}=${params.filters.minimalSalaryValue}&${minimalRewardCurrencyFilterName}=${params.filters.minimalSalaryCurrencyCode}`;
        }

        for (const employeeCapacity of params.filters.byCapacityType) {
            queryParams += `&${capacityTypeFilterName}=${employeeCapacity}`;
        }
        for (const preferredAmenity of params.filters.byPreferredAmenities) {
            queryParams += `&${amenitiesFilterName}=${preferredAmenity.toFixed()}`;
        }

        if (params.filters.businessCardId) {
            queryParams += `&${businessCardIdFilterName}=${params.filters.businessCardId}`;
        }

        if (params.filters.byWorkDuringHolidays) {
            queryParams += `&${workDuringHolidaysFilterName}=${true}`;
        }
    }

    if (queryParams && queryParams[0] !== "?") {
        queryParams = queryParams.replace("&", "?");
    }

    return queryParams;
};

export const mapEmployeeOffersQuerySearchToFetchParams = (
    querySearch: string,
    currentFetchParams: EmployeeOffersFetchParams,
    currencies: Currency[],
    branches: DictionaryItem[],
    ignoreOffset: boolean = false
): EmployeeOffersFetchParams => {
    const baseParams = mapOffersQuerySearchToFetchParams(querySearch, currentFetchParams, branches, ignoreOffset);
    const params: EmployeeOffersFetchParams = {
        ...baseParams,
        filters: {
            ...baseParams.filters,
            byCapacityType: [],
            byPreferredAmenities: [],
        },
    };

    const workDuringHolidaysTypeMatches = querySearch.match(new RegExp(`${workDuringHolidaysFilterName}=${true}`));
    params.filters.byWorkDuringHolidays = !!workDuringHolidaysTypeMatches;

    const minimalRewardValueMatch = querySearch.match(
        new RegExp(`${minimalRewardValueFilterName}=${monetaryAmountRegex}`)
    );
    params.filters.minimalSalaryValue = minimalRewardValueMatch
        ? parseFloat(minimalRewardValueMatch[0].split("=")[1])
        : undefined;

    const minimalRewardCurrencyMatch = querySearch.match(new RegExp(`${minimalRewardCurrencyFilterName}=[A-Z]+`));
    params.filters.minimalSalaryCurrencyCode = minimalRewardCurrencyMatch
        ? minimalRewardCurrencyMatch[0].split("=")[1]
        : undefined;

    const minimalBonusValueMatch = querySearch.match(new RegExp(`${minimalBonusValueFilterName}=[\\d]+`));
    params.filters.minimalBonusValue = minimalBonusValueMatch
        ? parseInt(minimalBonusValueMatch[0].split("=")[1])
        : undefined;

    const minimalBonusCurrencyMatch = querySearch.match(new RegExp(`${minimalBonusCurrencyFilterName}=[A-Z]+`));
    params.filters.minimalBonusCurrencyCode = minimalBonusCurrencyMatch
        ? minimalBonusCurrencyMatch[0].split("=")[1]
        : undefined;

    const companyNameMatch = querySearch.match(new RegExp(`${businessCardIdFilterName}=[\\S]+`));
    params.filters.businessCardId = companyNameMatch
        ? companyNameMatch[0].split("=")[1]
        : undefined;

    return params;
};

export const mapEmployeeOfferDetailsResponseDtoToOfferDetails = (dto: EmployeeOfferDetailsResponseDto) => {
    return {
        ...dto.data,
        addedDate: mapUtcStringToLocalDate(dto.data.addedDate),
        jobStartDate: mapUtcStringToLocalDate(dto.data.jobStartDate),
        jobEndDate: mapUtcStringToLocalDate(dto.data.jobEndDate),
        expirationDate: mapUtcStringToLocalDate(dto.data.expirationDate),
        /*languageRequirements: (dto.data.languageRequirements as any).map((language: any) => ({
            languageType: language.name,
            languageLevelType: language.level,
        })),*/
        status:
            dto.data.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : dto.data.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
        offerType: dto.data.offerType === "GROUP" ? OfferType.Group : OfferType.Single,
        amenities: (Object.keys(dto.data.amenities) as Array<keyof typeof dto.data.amenities>)
            .filter((amenity: keyof typeof AmenityType) => {
                return !!dto.data.amenities[amenity];
            })
            .map((amenityKey: keyof typeof AmenityType) => ({
                amenityType: amenityKey as AmenityType,
                value: `${dto.data.amenities[amenityKey]}`,
            })),
        imageUrl: dto.data.mainImage,
        businessCardData: {
            businessName:
                dto.businessCardDetails?.data.business_name ||
                `${dto.businessCardDetails?.data.firstname} ${dto.businessCardDetails?.data.lastname}`,
            logo: dto.businessCardDetails?.data.logo,
        },
    };
};

export const mapMyEmployeeOffersResponseDtoMyEmployeeOfferCardDetails = (
    dto: MyEmployeeOffersResponseDto
): MyEmployeeOfferCardDetails[] =>
    dto.data.content.map((offer) => ({
        ...offer,
        publishDate: new Date(offer.addedDate),
        imageUrl: offer.mainImage,
        expirationDate: mapUtcStringToLocalDate(offer.expirationDate),
        status:
            offer.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : offer.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
    }));

export const mapCreateEmployeeOfferSchemaToFormData = (
    values: CreateEmployeeOfferSchema,
    businessCardId: number,
    uploadedImages: { fileName?: string; }[] = [],
): CreateEmployeeOfferRequestObject => {
    const requirements = [];

    if (values.requirementsStep.certificates) {
        requirements.push({
            requirementType: "CERTIFICATES_AND_LICENSES",
            valueArray: values.requirementsStep.certificates.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.programs) {
        requirements.push({
            requirementType: "COMPUTER_AND_SOFTWARE",
            valueArray: values.requirementsStep.programs.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.other) {
        requirements.push({
            requirementType: "OTHER",
            valueArray: values.requirementsStep.other.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.permissions) {
        requirements.push({
            requirementType: "PERMISSIONS",
            valueArray: values.requirementsStep.permissions.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.experience) {
        requirements.push({
            requirementType: "EXPERIENCE",
            value: values.requirementsStep.experience,
        });
    }

    const uploaded = uploadedImages.filter((file) => file?.fileName).map((file) => file.fileName) || [];
    const prevPhoto = values.detailsStep.photos.filter(photo => photo.fileName).map(file => file.fileName) || [];


    return {
        employeeOfferDetails: {
            additionalInfo: values.additionalInfoStep.additionalInfo || "",
            amenities: {
                accommodation: !!values.amenitiesStep.accommodation,
                assistInLegalizingStay: !!values.amenitiesStep.assistanceInLegalizingStay,
                freeMedicalExam: !!values.amenitiesStep.freeMedicalExaminations,
                meals: !!values.amenitiesStep.catering,
                other: values.amenitiesStep.other ? values.amenitiesStep.otherAmenitiesText : undefined,
                transport: !!values.amenitiesStep.transport,
                workBoots: !!values.amenitiesStep.workBoots,
                workWear: !!values.amenitiesStep.workWear,
            },
            availablePositionsNumber:
                values.offerDetailsStep.offerType === EmployeeOfferType.Single
                    ? 1
                    : values.offerDetailsStep.positionsAvailable || 0,
            branchId: +values.offerDetailsStep.branchId,
            businessCardId,
            employerType: values.detailsStep.offerAs,
            enterBonusValue: !!values.financialStep.bonusValue,
            enterRewardValue: !!values.financialStep.salaryMinValue,
            expirationDate: toDate(addMonths(new Date(), 1)).toISOString(),
            financialConditions: {
                bonusCurrencyCode: values.financialStep.bonusCurrencyCode || "",
                bonusPayoutDateType: values.financialStep.bonusType || PayoutDayType.FirstDay,
                bonusValue: values.financialStep.bonusValue || 0,
                paymentRateType: values.financialStep.salaryType || SalaryType.Hourly,
                rewardCurrencyCode: values.financialStep.salaryCurrencyCode || "",
                rewardMinimumValue: values.financialStep.salaryMinValue || 1,
                rewardMaximumValue: values.financialStep.salaryMaxValue || null,
            },
            images: [...uploaded, ...prevPhoto],
            jobEndDate:
                values.offerDetailsStep.workType === WorkType.SeasonalTemporary
                    ? isDateValid(values.offerDetailsStep.jobEndDate)
                        ? toDate(values.offerDetailsStep.jobEndDate as Date).toISOString()
                        : new Date().toISOString()
                    : undefined,
            jobStartDate: isDateValid(values.offerDetailsStep.jobStartDate) && toDate(values.offerDetailsStep.jobStartDate as Date) > new Date()
                ? toDate(addDays(values.offerDetailsStep.jobStartDate as Date, 1)).toISOString()
                : new Date().toISOString(),
            languageRequirements:
                values.requirementsStep.languages
                    ?.filter((language) => !!language.isChecked)
                    .map((language) => ({
                        level: language.level || LanguageLevelType.Beginner,
                        name: language.id,
                    })) || [],
            mainImage: uploadedImages.length > 0 ? uploadedImages[0]?.fileName : prevPhoto.length > 0 ? prevPhoto[0] : '',
            placeId: values.offerDetailsStep.location.placeId,
            offerType: values.offerDetailsStep.offerType,
            position: values.offerDetailsStep.position,
            requirements: [
                ...requirements,
                ...values.requirementsStep.drivingLicenses.map((value) => ({
                    requirementType: "DRIVING_LICENSE",
                    value: value || "",
                })),
                ...values.requirementsStep.education
                    .filter((item) => item.isChecked)
                    .map((value) =>
                        value.id === EducationType.Other
                            ? {
                                requirementType: "EDUCATION_CUSTOM",
                                value: value.otherValue || "",
                            }
                            : {
                                requirementType: "EDUCATION",
                                value: value.id?.toUpperCase() || "",
                            }
                    ),
            ],
            title: values.offerDetailsStep.position,
            workDuringHolidays: values.offerDetailsStep.workDuringHolidays,
            workType: values.offerDetailsStep.workType,
            jobExperience: values.requirementsStep.jobExperience.map((item) => ({
                companyName: item.companyName,
                dateFrom: item.startDate,
                dateTo: item.endDate || new Date(),
                description: item.additionalInfo,
                position: item.position,
            })),
        },
        businessCard: {
            business_name: values.detailsStep.businessName,
            placeId: values.detailsStep.location.placeId,
            kraz_certificate_number: values.detailsStep.krazCertificateNumber,
            tax_identification_number: values.detailsStep.taxIdentificationNumber,
            phone_number: values.detailsStep.phoneNumber,
            phone_number_prefix: values.detailsStep.phonePrefix,
        },
        businessCardId,
    };
};
