import Box from "components/Box";
import React from "react";
import styled from "styled-components/macro";
import {height, HeightProps, padding, PaddingProps, width, WidthProps} from "styled-system";

type StylesProps = HeightProps & WidthProps & PaddingProps;

type Props = StylesProps & {
  children: React.ReactNode;
};

const Container = ({ children, ...styles }: Props) => <Wrapper {...styles}>{children}</Wrapper>;

const Wrapper = styled(Box) <StylesProps>`
  display: grid;
  grid-template-columns: auto;
  max-width: 90rem;
  padding: 2rem 1.75rem;
  position: relative;

  ${height};
  ${width};
  ${padding};
`;

export default React.forwardRef(Container);
