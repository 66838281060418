import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as CalendarCheckIcon } from "assets/icons/calendarCheck.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import { ReactComponent as CalendarWithNumberIcon } from "assets/icons/calendarWithNumber.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as CoinsBanknoteIcon } from "assets/icons/coinsBanknote.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "assets/icons/peopleWithHand.svg";
import classNames from "classnames";
import config from "config";
import { pl } from "date-fns/locale";
import Button from "features/common/components/Button";
import ModalWithContent from "features/common/components/ModalWithContent";
import ErrorHeader from "features/common/components/ModalWithContent/ErrorHeader";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDateFormat from "features/common/hooks/useDateFormat";
import {
    getBonusMessage,
    getPositionsNumberMessage,
    getPublishedDateMessage,
    getValidUntilMessage,
    getWorkLocationMessage,
} from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import { ApplicationDetails } from "features/jobOffers/models";
import { CardOwnerRole } from "features/myProfile/types";
import CardImageWithBadge from "features/offers/components/SingleOfferCard/CardImageWithBadge";
import DetailRow from "features/offers/components/SingleOfferCard/DetailRow";
import { getSalaryMessage, getWorkTypeWithDatesMessage } from "features/offers/translationMessages";
import RatingButtons from "features/rating/components/RatingButtons";
import { RateCategory, RateForm } from "features/rating/models";
import { appRoutes } from "features/routing/routes";
/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import { User } from "features/user/models";

interface Props {
    id: number;
    ratingType?: CardOwnerRole;
    employeeRatingLoading: boolean;
    employeeRatingSuccess?: boolean;
    employeeRatingError?: ApiError;
    rateEmployeeAsync: (rateForm: RateForm, cancelToken?: CancelToken) => void;
    resetRateEmployeeState: () => void;
    applicationDetails?: ApplicationDetails;
    applicationDetailsLoading: boolean;
    applicationDetailsError?: ApiError;
    getApplicationDetailsAsync: (id: number, isOfferOwner: boolean, cancelToken?: CancelToken) => void;
    user?: User;
}

const RateEmployee = ({
    id,
    employeeRatingLoading,
    employeeRatingSuccess,
    employeeRatingError,
    rateEmployeeAsync,
    resetRateEmployeeState,
    applicationDetails,
    applicationDetailsLoading,
    applicationDetailsError,
    getApplicationDetailsAsync,
    user
}: Props) => {
    const employeeOfferDetails = applicationDetails?.jobOffer;
    const createCancelToken = useCreateCancelToken();
    useEffect(() => {
        if (id) {
            getApplicationDetailsAsync(id, true, createCancelToken());
        }
    }, [createCancelToken, getApplicationDetailsAsync, id]);
    const intl = useIntl();
    const history = useHistory();
    const getFormattedDate = useDateFormat("d MMM yyyy", { locale: pl });

    const [successModalDisplayed, setSuccessModalDisplayed] = useState(false);
    const [errorModalDisplayed, setErrorModalDisplayed] = useState(false);

    const [overallRating, setOverallRating] = useState<number | undefined>();
    const [engagementRating, setEngagementRating] = useState<number | undefined>();
    const [oshComplianceRating, setOshComlianceRating] = useState<number | undefined>();
    const [punctualityRating, setPuntualityRating] = useState<number | undefined>();
    const [availabilityRating, setAvailabilityRating] = useState<number | undefined>();
    const [takingCareOfEquipmentRating, setTakingCareOfEquipmentRating] = useState<number | undefined>();
    const [personalHygieneRating, setPersonalHygieneRating] = useState<number | undefined>();
    const [complianceOfRealSkillsRating, setComplianceOfRealSkillsRating] = useState<number | undefined>();

    const handleEngagementClear = useCallback(() => setEngagementRating(undefined), []);
    const handleOshComplianceClear = useCallback(() => setOshComlianceRating(undefined), []);
    const handlePunctualityClear = useCallback(() => setPuntualityRating(undefined), []);
    const handleAvailabilityClear = useCallback(() => setAvailabilityRating(undefined), []);
    const handleTakingCareOfEquipmentClear = useCallback(() => setTakingCareOfEquipmentRating(undefined), []);
    const handlePersonalHygieneClear = useCallback(() => setPersonalHygieneRating(undefined), []);
    const handleComplianceOfRealSkillsClear = useCallback(() => setComplianceOfRealSkillsRating(undefined), []);

    let errorMessage;
    switch (employeeRatingError?.response?.status) {
        case 400:
            errorMessage = <FormattedMessage id="rating__error-no-application" />;
            break;
        case 403:
            errorMessage = <FormattedMessage id="rating__error-state" />;
            break;
        case 404:
            errorMessage = <FormattedMessage id="rating__error-not-found" />;
            break;
        default:
            errorMessage = <FormattedMessage id="rating__error-text" />;
    }

    useEffect(() => {
        if (employeeRatingSuccess) {
            setSuccessModalDisplayed(true);
        }
    }, [employeeRatingSuccess]);

    useEffect(() => {
        if (employeeRatingError) {
            setErrorModalDisplayed(true);
        }
    }, [employeeRatingError]);

    const salaryMessage = useMemo(
        () =>
            employeeOfferDetails?.financialConditions
                ? getSalaryMessage(intl, employeeOfferDetails?.financialConditions)
                : "",
        [intl, employeeOfferDetails]
    );
    const workLocationText = useMemo(
        () =>
            `${employeeOfferDetails?.location?.city ? `${employeeOfferDetails?.location?.city}, ` : ""}${intl.formatMessage({
                id: `common__country-${employeeOfferDetails?.location?.country}`,
                defaultMessage: employeeOfferDetails?.location?.country,
            })}`,
        [intl, employeeOfferDetails]
    );
    const workLocationMessage = getWorkLocationMessage(intl, workLocationText ?? undefined);
    const workTypeMessage = getWorkTypeWithDatesMessage(
        intl,
        employeeOfferDetails?.workType,
        getFormattedDate(employeeOfferDetails?.jobStartDate),
        getFormattedDate(employeeOfferDetails?.jobEndDate)
    );
    const bonusMessage = getBonusMessage(
        intl,
        `${employeeOfferDetails?.financialConditions.bonusValue} ${employeeOfferDetails?.financialConditions.bonusCurrencyCode}`
    );
    const positionsNumberMessage = getPositionsNumberMessage(intl, employeeOfferDetails?.availablePositionsNumber);
    const publishedDateMessage = getPublishedDateMessage(intl, getFormattedDate(employeeOfferDetails?.addedDate));
    const validUntilMessage = getValidUntilMessage(intl, getFormattedDate(employeeOfferDetails?.expirationDate));

    const handleDetailsClick = useCallback(() => {
        history.push(`${appRoutes.jobOffers}/${employeeOfferDetails?.id}`);
    }, [history, employeeOfferDetails]);

    const goToProfile = useCallback(
        () => history.push(`${appRoutes.profileCard}/${employeeOfferDetails?.businessCardId}`),
        [history, employeeOfferDetails]
    );

    const goToDashboard = useCallback(() => history.push(appRoutes.dashboard), [history]);

    const handleSubmit = useCallback(() => {
        const rates = [
            {
                category: RateCategory.overall,
                rate: overallRating as number,
            },
        ];

        if (engagementRating)
            rates.push({ category: RateCategory.engagement, rate: engagementRating });
        if (oshComplianceRating) rates.push({ category: RateCategory.ohs_compliance, rate: oshComplianceRating });
        if (punctualityRating) rates.push({ category: RateCategory.punctuality, rate: punctualityRating });
        if (availabilityRating) rates.push({ category: RateCategory.availability, rate: availabilityRating });
        if (takingCareOfEquipmentRating) rates.push({ category: RateCategory.taking_care_of_equipment, rate: takingCareOfEquipmentRating });
        if (personalHygieneRating) rates.push({ category: RateCategory.personal_hygiene, rate: personalHygieneRating });
        if (complianceOfRealSkillsRating) rates.push({ category: RateCategory.compliance_of_real_skills_with_declared, rate: complianceOfRealSkillsRating });

        rateEmployeeAsync(
            {
                assessedBusinessCardId: applicationDetails?.jobApplicant?.businessCardId,
                jobOfferId: String(applicationDetails?.jobOffer.id),
                isApplicant: applicationDetails?.jobApplicant?.userId === user?.id, 
                rates,
            },
            createCancelToken()
        );
    }, [
        takingCareOfEquipmentRating,
        oshComplianceRating,
        createCancelToken,
        personalHygieneRating,
        id,
        complianceOfRealSkillsRating,
        engagementRating,
        overallRating,
        rateEmployeeAsync,
        punctualityRating,
        availabilityRating,
    ]);

    const handleCloseSuccessModal = useCallback(() => {
        setSuccessModalDisplayed(false);
        resetRateEmployeeState();
        goToDashboard();
    }, [goToDashboard, resetRateEmployeeState]);

    const handleCloseErrorModal = useCallback(() => setErrorModalDisplayed(false), []);

    if (applicationDetailsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "rating__loading-data" })} />;
    }

    if (employeeRatingLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "rating__sending-data" })} />;
    }

    return (
        <div className={styles["rate-employee"]}>
            {applicationDetailsError ? (
                <div
                    className={classNames(styles["rate-employee__container"], styles["rate-employee__error-container"])}
                >
                    <FormattedMessage id="rating__loading-error" />
                </div>
            ) : (
                <>
                    <div className={styles["rate-employee__container"]}>
                        <div className={styles["rate-employee__title"]}>
                            <FormattedMessage id="rating__employee-title" />
                        </div>
                        <div className={styles["rate-employee__employer-container"]}>
                            {!!employeeOfferDetails?.clientLogoUrl && (
                                <img
                                    src={`${config.IMAGE_URL}/${employeeOfferDetails.clientLogoUrl}`}
                                    alt={`logo`}
                                    // alt={`${employeeOfferDetails.employeeName} logo`}
                                    className={styles["rate-employee__logo"]}
                                />
                            )}
                            {/* {!!employeeOfferDetails?.employeeName && (
                                <span className={styles["rate-employee__business-name"]}>
                                    {employeeOfferDetails?.employeeName}
                                </span>
                            )} */}
                            <Button
                                variant="no-background"
                                onClick={goToProfile}
                                className={styles["rate-employee__profile-button"]}
                            >
                                <FormattedMessage id="rating__go-to-profile" />
                            </Button>
                        </div>
                    </div>
                    <div className={styles["rate-employee__data-container"]}>
                        <div className={styles["rate-employee__offer-container"]}>
                            <div className={styles["rate-employee__offer-container-title"]}>
                                <FormattedMessage id="rating__offer-title" />
                            </div>
                            <CardImageWithBadge
                                data-testid="rate-employer__card-image-with-badge"
                                photoBadge="status"
                                status={employeeOfferDetails?.status}
                                imageUrl={`${config.IMAGE_URL}/${employeeOfferDetails?.mainImage}`}
                                offerType={OfferType.Job}
                                className={styles["rate-employee__offer-image"]}
                            />
                            <div className={styles["rate-employee__offer-details-container"]}>
                                {/* <div className={styles["rate-employee__offer-business-name"]}> */}
                                {/* {'SOMETHING'} */}
                                {/* {employeeOfferDetails?.employeeName} */}
                                {/* </div> */}
                                <div className={styles["rate-employee__offer-position"]}>
                                    {employeeOfferDetails?.position}
                                </div>
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__location"
                                    icon={<LocationMarkerIcon />}
                                    message={workLocationMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__work-type"
                                    isEllipsis={false}
                                    icon={<CalendarIcon />}
                                    message={workTypeMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__positions-number"
                                    icon={<PeopleWithHandIcon />}
                                    message={positionsNumberMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__salary"
                                    icon={<CoinsIcon />}
                                    message={salaryMessage}
                                />
                                {bonusMessage && (
                                    <DetailRow
                                        className={styles["rate-employee__offer-detail-row"]}
                                        iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                        data-testid="rate-employer__bonus"
                                        icon={<CoinsBanknoteIcon />}
                                        message={bonusMessage}
                                    />
                                )}
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__added-date"
                                    icon={<CalendarCheckIcon />}
                                    message={publishedDateMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__expiration-date"
                                    icon={<CalendarWithNumberIcon />}
                                    message={validUntilMessage}
                                />
                            </div>
                            <div className={styles["rate-employee__details-button"]} onClick={handleDetailsClick}>
                                {intl.formatMessage({ id: "rating__offer-details" })}
                                <ChevronRight />
                            </div>
                        </div>
                        <div className={styles["rate-employee__rating-form"]}>
                            <div className={styles["rate-employee__rating-title"]}>
                                <FormattedMessage id="rating__form-title" />
                            </div>
                            <div className={styles["rate-employee__rating-subtitle"]}>
                                <FormattedMessage id="rating__form-employee-subtitle" />
                            </div>
                            <div className={styles["rate-employee__rating-subtext"]}>
                                <FormattedMessage id="rating__form-subtext" />
                            </div>
                            <div className={styles["rate-employee__section"]}>
                                <div className={styles["rate-employee__section-title"]}>
                                    <FormattedMessage id="rating__overall-employee-section-title" />
                                </div>
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__overall-employee-section-text" })}
                                    isRequired
                                    value={overallRating}
                                    onChange={setOverallRating}
                                />
                            </div>
                            <div className={styles["rate-employee__section"]}>
                                <div className={styles["rate-employee__section-title"]}>
                                    <FormattedMessage id="rating__detailed-section-title" />
                                </div>
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__involvement-section-text" })}
                                    isClearable
                                    value={engagementRating}
                                    onChange={setEngagementRating}
                                    onClear={handleEngagementClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__employee-compliance-section-text" })}
                                    isClearable
                                    value={oshComplianceRating}
                                    onChange={setOshComlianceRating}
                                    onClear={handleOshComplianceClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__employee-timeliness-section-text" })}
                                    isClearable
                                    value={punctualityRating}
                                    onChange={setPuntualityRating}
                                    onClear={handlePunctualityClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__availability-section-text" })}
                                    isClearable
                                    value={availabilityRating}
                                    onChange={setAvailabilityRating}
                                    onClear={handleAvailabilityClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__equipment-care-section-text" })}
                                    isClearable
                                    value={takingCareOfEquipmentRating}
                                    onChange={setTakingCareOfEquipmentRating}
                                    onClear={handleTakingCareOfEquipmentClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__relations-section-text" })}
                                    isClearable
                                    value={personalHygieneRating}
                                    onChange={setPersonalHygieneRating}
                                    onClear={handlePersonalHygieneClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__compatibility-section-text" })}
                                    isClearable
                                    value={complianceOfRealSkillsRating}
                                    onChange={setComplianceOfRealSkillsRating}
                                    onClear={handleComplianceOfRealSkillsClear}
                                />
                            </div>
                            <div className={styles["rate-employee__buttons-container"]}>
                                <Button
                                    variant="tertiary"
                                    className={styles["rate-employee__button"]}
                                    onClick={goToDashboard}
                                >
                                    <FormattedMessage id="common__cancel" />
                                </Button>
                                <Button
                                    className={styles["rate-employee__button"]}
                                    onClick={handleSubmit}
                                    disabled={!overallRating}
                                >
                                    <FormattedMessage id="common__send" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {errorModalDisplayed && (
                <ModalWithContent
                    className={styles["rate-employee__modal"]}
                    header={
                        <ErrorHeader>
                            <FormattedMessage id="rating__error-title" />
                        </ErrorHeader>
                    }
                    content={errorMessage}
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseErrorModal}
                    primaryAction={handleCloseErrorModal}
                />
            )}
            {successModalDisplayed && (
                <ModalWithContent
                    className={styles["rate-employee__modal"]}
                    header={
                        <SuccessHeader>
                            <FormattedMessage id="rating__success-title" />
                        </SuccessHeader>
                    }
                    content={
                        <span>
                            <FormattedMessage id="rating__employee-success-text-line-1" />
                            <br />
                            <FormattedMessage id="rating__employee-success-text-line-2" />
                        </span>
                    }
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseSuccessModal}
                    primaryAction={handleCloseSuccessModal}
                />
            )}
        </div>
    );
};

export default RateEmployee;
