import classNames from "classnames";
import { ReactComponent as FacebookLogoIcon } from "assets/icons/facebookLogo.svg";
import config from "config";
import Button from "features/common/components/Button";
import React from "react";
import styles from "./styles.module.scss";


export function FacebookLoginButton(loginUserLoading: boolean, facebookLoginMessage: string) {
    const style = classNames(styles["login-form__facebook-login-button"]);

    return <Button
        data-testid="login-form__facebook-login-button"
        as="a"
        className={style}
        href={config.FACEBOOK_LOGIN_URL}
        disabled={loginUserLoading}
    >
        <FacebookLogoIcon />
        {facebookLoginMessage}
    </Button>;
}
