import React from 'react'

interface StreamProps {
    stream?: boolean
}

function CallIcon({ stream }: StreamProps) {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="url(#paint0_linear_3579_208304)" />
            <path d="M16.6725 15.0571C16.9 15.0572 17.1234 15.1182 17.3194 15.2337C17.5154 15.3492 17.6769 15.515 17.7873 15.714L19.3478 18.5238C19.4484 18.7051 19.5033 18.9081 19.5077 19.1153C19.5121 19.3226 19.466 19.5278 19.3733 19.7132L17.8702 22.7194C17.8702 22.7194 18.3057 24.9585 20.1284 26.7817C21.9516 28.6044 24.183 29.0329 24.183 29.0329L27.1893 27.5298C27.3748 27.437 27.5801 27.3908 27.7874 27.3952C27.9948 27.3997 28.1979 27.4546 28.3793 27.5553L31.198 29.1228C31.3966 29.2334 31.5621 29.3951 31.6772 29.5911C31.7924 29.7871 31.853 30.0103 31.853 30.2376V33.4728C31.853 35.1207 30.3224 36.3107 28.7613 35.7839C25.5548 34.7023 20.578 32.6418 17.4225 29.4876C14.2683 26.3328 12.2084 21.3553 11.1262 18.1488C10.5994 16.5877 11.7894 15.0571 13.4373 15.0571H16.6725V15.0571Z" stroke="white" strokeWidth="1.3" strokeLinejoin="round" />
            <path d="M32.3773 18.3773H24.7246V12H32.3773V13.9132L35.566 12.6377V17.7395L32.3773 16.4641V18.3773Z" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_3579_208304" x1="23" y1="0" x2="23" y2="46.0001" gradientUnits="userSpaceOnUse">
                    <stop stopColor={stream ? "#002677" : "#C2C5CC"} />
                    <stop offset="1" stopColor={stream ? "#2662E3" : "#C2C5CC"} />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default CallIcon