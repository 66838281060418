import NotificationsPage from 'features/notifications'
import React from 'react'

const Notifications = () => (
    <div style={{ width: "100%" }}>
        <NotificationsPage logoutUserAsync={() => console.log()} />
    </div>
)


export default Notifications
