import React from "react";
import { useField } from "formik";
import CreatableRequirement from "features/jobOffers/components/CreateJobOffer/RequirementsStep/CreatableRequirement";
export interface Props {
    name: string;
    header: string;
    label: string;
}

const CardCreatableRequirement = ({ name, header, label }: Props) => {
    const [fieldProps, metaProps, helperProps] = useField<string>(name);

    return (
        <CreatableRequirement
            data-testid={`requirements-step__${name}`}
            header={header}
            label={label}
            valueInputProps={fieldProps}
            metaProps={metaProps}
            helperProps={helperProps}
        />
    );
};

export default CardCreatableRequirement;