import { LocationsDetailsDto, LocationTypeDto } from "features/common/dtos";
import { SectionName, Tag } from "features/common/types";

export const mapLocationsDetailsDtoToLocationsTags = (dto: LocationsDetailsDto): Tag[] => {
    return dto.locationsDetails.map((current) => {
        let section: SectionName = SectionName.Undefined;

        switch (current.locationType) {
            case LocationTypeDto.City:
                section = SectionName.Cities;
                break;
            case LocationTypeDto.Region:
                section = SectionName.Countries;
                break;
        }
        const result: Tag = { ...current, isWithoutDetails: true, section: section };
        return result;
    });
};
