import { resetUserProfileCreated } from "features/user/actions";
import CreateProfileForm from "features/user/components/CreateProfile/CreateProfileForm/component";
import {
    selectIsUserLoggedIn,
    selectUser,
    selectUserId,
    selectUserProfileCreated,
    selectUserProfileCreating,
    selectUserProfileCreatingError,
} from "features/user/selectors";
import { createUserProfileAsync, getUserAsync } from "features/user/thunks";
import {
    updateProfilePictureAsync,
} from "features/myProfile/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    userProfileCreated: selectUserProfileCreated(state),
    userProfileCreating: selectUserProfileCreating(state),
    userProfileCreatingError: selectUserProfileCreatingError(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
    isUserProfileCreated: !!selectUser(state),
    userId: selectUserId(state),
    user: selectUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getUserAsync,
            createUserProfileAsync,
            updateProfilePictureAsync,
            resetUserProfileCreated: () => resetUserProfileCreated(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfileForm);
