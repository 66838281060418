import Button from "features/common/components/Button";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    offersLoading?: boolean;
    offersCount?: number;
    totalOffers?: number;
    ["data-testid"]?: string;
    collapse?: () => void;
    onClearFiltersClick: () => void;
    filtersSum?: number;
}

const ControlButtons = ({
    offersLoading,
    offersCount,
    totalOffers,
    "data-testid": testId,
    collapse,
    onClearFiltersClick,
    filtersSum,
}: Props) => {
    const getResultsMessage = useCallback(() => {
        return <FormattedMessage id="filters-view__show-results" values={{ amount: totalOffers ?? 0 }} />;
    }, [totalOffers]);

    return (
        <div data-testid={testId} className={styles["control-buttons"]}>
            {!offersLoading && offersCount === 0 && (
                <div
                    data-testid={testId ? `${testId}__no-offers` : undefined}
                    className={styles["control-buttons__no-offers"]}
                >
                    <FormattedMessage id="offers-list__no-offers" />
                </div>
            )}
            {(filtersSum !== undefined && filtersSum > 0) && (<Button
                data-testid={testId ? `${testId}__clear-filters-button` : undefined}
                className={styles["control-buttons__clear-filters"]}
                onClick={onClearFiltersClick}
            >
                <FormattedMessage id="filters-view__clear-filters" />
            </Button>)}
            <Button
                data-testid={testId ? `${testId}__show-results-button` : undefined}
                className={styles["control-buttons__show-results"]}
                disabled={(offersCount ?? 0) === 0}
                onClick={() => collapse && collapse()}
            >
                {getResultsMessage()}
            </Button>
        </div>
    );
};

export default ControlButtons;
