import React from 'react'

interface TriggerProps {
    videoTrigger: boolean
}

const Video = ({ videoTrigger }: TriggerProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 12V16.4C16 16.4788 15.9845 16.5568 15.9543 16.6296C15.9242 16.7024 15.88 16.7685 15.8243 16.8243C15.7685 16.88 15.7024 16.9242 15.6296 16.9543C15.5568 16.9845 15.4788 17 15.4 17H4.6C4.52121 17 4.44319 16.9845 4.37039 16.9543C4.29759 16.9242 4.23145 16.88 4.17574 16.8243C4.12002 16.7685 4.07583 16.7024 4.04567 16.6296C4.01552 16.5568 4 16.4788 4 16.4V7.6C4 7.44087 4.06321 7.28826 4.17574 7.17574C4.28826 7.06321 4.44087 7 4.6 7H15.4C15.5591 7 15.7117 7.06321 15.8243 7.17574C15.9368 7.28826 16 7.44087 16 7.6V12ZM16 12L21.016 7.82C21.1036 7.74702 21.2102 7.70052 21.3233 7.68595C21.4364 7.67137 21.5513 7.68932 21.6545 7.7377C21.7578 7.78607 21.8451 7.86287 21.9063 7.95909C21.9675 8.05531 22 8.16697 22 8.281V15.719C21.9998 15.8329 21.9672 15.9445 21.9059 16.0405C21.8447 16.1366 21.7573 16.2133 21.6541 16.2615C21.5509 16.3098 21.4361 16.3276 21.3231 16.313C21.2101 16.2984 21.1035 16.2519 21.016 16.179L16 12Z" stroke="#002677" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 1L24.5 22.5" stroke="#D32A2A" stroke-width={videoTrigger ? "0" : "2"} stroke-linecap="round" className="redline" />
        </svg>
    )
}

export default Video