import { format } from "date-fns";
import { UserRole } from "features/common/types";
import { isDateValid } from "features/common/utils";
import { mapCardDtoToCard } from "features/myProfile/mappers";
import { RegisterUserDto, UserDetailsDto, UserDto, UserProfileDto } from "features/user/dtos";
import { User } from "features/user/models";

export const mapUserDtoToUser = (dto: UserDetailsDto): Partial<User> => ({
    id: dto.data.id,
    firstName: dto.data.firstname,
    lastName: dto.data.lastname,
    businessName: dto.data.business_name,
    email: dto.data.email,
    phoneNumber: dto.data.phone_number,
    phonePrefix: dto.data.phone_number_prefix,
    imageUrl: dto.data.user_picture,
    role: dto.data.is_administrator ? UserRole.Administrator : dto.data.is_moderator ? UserRole.Moderator : UserRole.User,
    businessCards: dto.data.businessCards?.map((item) => mapCardDtoToCard(item)),
    birthDate: dto.data.birthDate,
    gender: dto.data.gender,
});

export interface CreateProfileFormValues {
    firstName: string;
    lastName: string;
    gender?: string;
    phoneNumber: string;
    birthDate?: Date;
    profilePicture?: {
        file: File;
        fileUrl: string;
    };
    phonePrefix?: string;
}

export const mapCreateUserProfileFormValuesToDto = (formValues: CreateProfileFormValues): Partial<UserProfileDto> => {
    return {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phoneNumber: formValues.phoneNumber,
        phoneNumberPrefix: formValues.phonePrefix,
        birthDate: isDateValid(formValues.birthDate) ? format(formValues.birthDate as Date, "yyyy-MM-dd") : undefined,
        gender: formValues.gender,
    };
};

export const mapRegisterDtoToUser = (dto: RegisterUserDto): Partial<User> => ({
    email: dto.email,
});
