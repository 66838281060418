import { LocationSuggestionDto } from "features/common/dtos";
import { LocationSuggestion, SectionName } from "features/common/types";

const mapLocationSuggestionDtoToLocationSuggestion = (dto: LocationSuggestionDto): LocationSuggestion => ({
    locationSuggestions: [
        {
            section: { display: SectionName.Cities, value: SectionName.Cities },
            options: (dto.cities ?? []).map((city) => ({
                display: city.name,
                value: city.locationId,
                additionalData: `${city.country}` ?? "",
            })),
        },
        {
            section: { display: SectionName.Regions, value: SectionName.Regions },
            options: (dto.regions ?? []).map((region) => ({
                display: region.name,
                value: region.locationId,
                additionalData: region.country ?? "",
            })),
        },
        {
            section: { display: SectionName.Countries, value: SectionName.Countries },
            options: (dto.countries ?? []).map((country) => ({
                display: country,
                value: `${country}`,
            })),
        },
    ].filter((suggestion) => suggestion.options.length > 0),
});

export default mapLocationSuggestionDtoToLocationSuggestion;
