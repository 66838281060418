import classNames from "classnames";
import FieldMessages from "features/common/components/FieldMessages";
import { RadioButtonOption, ValidationProps } from "features/common/types";
import React from "react";
import { v4 as uuid } from "uuid";
import styles from "./styles.module.scss";

export type Props<T> = ValidationProps & {
    options: RadioButtonOption<T>[];
    wrapperClassName?: string;
    titleClassName?: string;
    inputsContainerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    title?: string;
    value?: T;
    ["data-testid"]?: string;
    disabled?: boolean;
    onValueChange?: (newValue: T) => void;
    spacelessMessages?: boolean;
};

const RadioButtons = <T extends string | number>({
    options,
    wrapperClassName,
    titleClassName,
    inputsContainerClassName,
    inputClassName,
    labelClassName,
    title,
    value,
    "data-testid": testId,
    disabled,
    onValueChange,
    withValidation,
    isInvalid,
    error,
    spacelessMessages,
}: Props<T>) => {
    const newId = uuid();

    return (
        <div data-testid={testId} className={classNames(styles["radio-buttons"], wrapperClassName)}>
            {title && (
                <h2
                    data-testid={testId ? `${testId}__title` : undefined}
                    className={classNames(styles["radio-buttons__title"], titleClassName)}
                >
                    {title}
                </h2>
            )}

            <div
                data-testid={testId ? `${testId}__inputs-container` : undefined}
                className={classNames(styles["radio-buttons__inputs-container"], inputsContainerClassName)}
            >
                {options.map((option) => (
                    <div
                        data-testid={testId ? `${testId}-${option.value}__input` : undefined}
                        key={option.label + newId}
                        className={classNames(styles["radio-buttons__input"], inputClassName)}
                    >
                        <input
                            data-testid={testId ? `${testId}-${option.value}__button` : undefined}
                            type="radio"
                            id={option.label + newId}
                            value={option.value}
                            disabled={disabled}
                            checked={value === option.value}
                            onChange={(e) => onValueChange && onValueChange(e.target.value as T)}
                        />

                        <label
                            data-testid={testId ? `${testId}__label` : undefined}
                            htmlFor={option.label + newId}
                            className={classNames(styles["radio-buttons__label"], labelClassName, {
                                [styles["radio-buttons__label--disabled"]]: disabled,
                            })}
                        >
                            <div
                                className={classNames(styles["radio-buttons__icon"], {
                                    [styles["radio-buttons__icon--disabled"]]: disabled,
                                })}
                            >
                                {value === option.value && (
                                    <div data-testid={testId ? `${testId}__icon--checked` : undefined} />
                                )}
                            </div>
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
            {withValidation && error && isInvalid && (
                <FieldMessages
                    data-testid={testId ? `${testId}__error-messages` : undefined}
                    messages={[error]}
                    mode="error"
                    spaceless={spacelessMessages}
                />
            )}
        </div>
    );
};

export default RadioButtons;
