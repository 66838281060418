import { OfferType } from "features/common/types";
import { resetCreateEmployeeOfferState } from "features/employeeOffers/actions";
import {
    selectEmployeeOfferCreating,
    selectEmployeeOfferCreatingErrorsArray,
    selectEmployeeOfferCreatingSuccess,
} from "features/employeeOffers/selectors";
import { publishEmployeeOfferAsync } from "features/employeeOffers/thunks";
import CreateOffer from "features/offers/components/CreateEmployeeOffer";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    offerType: OfferType.Employee,
    user: selectUser(state),
    offerCreating: selectEmployeeOfferCreating(state),
    offerCreatingSuccess: selectEmployeeOfferCreatingSuccess(state),
    offerApiErrorsArray: selectEmployeeOfferCreatingErrorsArray(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            publishEmployeeOfferAsync,
            resetCreateOfferState: () => resetCreateEmployeeOfferState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer);
