import mapLocalDateToUtcString from "features/common/mappers/mapLocalDateToUtcString";
import mapTodayDateWithoutTimeOffsetToDateWithActualTime from "features/common/mappers/mapTodayDateWithoutTimeOffsetToDateWithActualTime";
import mapUtcStringToLocalDate from "features/common/mappers/mapUtcStringToLocalDate";
import { SalaryType } from "features/common/models";
import { DictionaryItem, SectionName, WorkType } from "features/common/types";
import { extractOffsetFromQuerySearch } from "features/common/utils";
import { JobOfferDetailsResponseDto } from "features/jobOffers/dtos";
import { JobOfferDetails } from "features/jobOffers/models";
import { deepCopyJobOffersSearchParams } from "features/jobOffers/utils";
import { offersQueryParamNames } from "features/offers/constants";
import { DetailsStepSchema, FinancialConditionsStepSchema } from "features/offers/schemas";
import {
    AmenityType,
    CreateOfferApiError,
    CreateOfferComponentError,
    OffersFetchParams,
    OfferStatus,
} from "features/offers/types";

export const mapDetailsStepSchemaToFormData = (schema: DetailsStepSchema) => {
    const formData = new FormData();

    const startDate = mapLocalDateToUtcString(mapTodayDateWithoutTimeOffsetToDateWithActualTime(schema.startDate));
    const endDate = mapLocalDateToUtcString(mapTodayDateWithoutTimeOffsetToDateWithActualTime(schema.endDate));

    startDate && formData.append("StartDate", startDate);
    endDate && formData.append("EndDate", endDate);
    schema.position && formData.append("Position", schema.position);
    schema.branchCode && formData.append("BranchCode", schema.branchCode);
    schema.availablePositionsNumber &&
        formData.append("AvailablePositionsNumber", `${schema.availablePositionsNumber}`);
    schema.workType && formData.append("WorkTypeCode", schema.workType);
    schema.location && formData.append("LocationId", schema.location.option.value);
    schema.zipCode && formData.append("LocationZipCode", schema.zipCode);

    return formData;
};

export const mapFinancialConditionsStepSchemaToFormData = (schema: FinancialConditionsStepSchema) => {
    const formData = new FormData();

    !!schema.salaryType && formData.append("PaymentRateType", schema.salaryType);
    !!schema.salaryMinValue && formData.append("Reward.MinValue", schema.salaryMinValue);
    !!schema.salaryMaxValue && formData.append("Reward.MaxValue", schema.salaryMaxValue);
    !!schema.salaryUnit?.id && formData.append("Reward.CurrencyId", `${schema.salaryUnit.id}`);
    !!schema.bonusValue && formData.append("Bonus.Value", schema.bonusValue);
    !!schema.bonusUnit?.id && formData.append("Bonus.CurrencyId", `${schema.bonusUnit.id}`);

    return formData;
};

export const mapCreateOfferApiErrorsToCreateOfferComponentErrors = (
    apiErrors?: CreateOfferApiError[]
): CreateOfferComponentError[] => {
    let stepsErrors: CreateOfferComponentError[] = [
        { errors: [] },
        { errors: [] },
        { errors: [] },
        { errors: [] },
        { errors: [] },
        { errors: [] },
    ];

    apiErrors?.length &&
        apiErrors.forEach((current: CreateOfferApiError) => {
            if (current.value === "CardUniqueId") {
                stepsErrors[0].errors = ["id"];
            } else if (current.value === "BranchCode") {
                stepsErrors[1].errors = [...stepsErrors[1].errors, "branchId"];
            } else if (current.value === "WorkTypeCode") {
                stepsErrors[1].errors = [...stepsErrors[1].errors, "workType"];
            } else if (current.value === "RewardCurrencyId") {
                stepsErrors[2].errors = [...stepsErrors[2].errors, "salaryUnit"];
            } else if (current.value === "BonusCurrencyId") {
                stepsErrors[2].errors = [...stepsErrors[2].errors, "bonusUnit"];
            }
        });

    return stepsErrors;
};

export const mapOffersFetchParamsToQueryParameters = (
    params: OffersFetchParams,
    withPaginationParameters: boolean = true,
    isUrlMapper: boolean = true,
) => {
    let queryParams = withPaginationParameters
        ? `&${offersQueryParamNames.pageLimit}=${params.pageLimit}&${offersQueryParamNames.offset}=${params.offset - 1}&${offersQueryParamNames.isFavorite}=${params.isFavorite}`
        : "";

    for (const branchCode of params.filters.branchId) {
        queryParams += `&${offersQueryParamNames.branchId}=${branchCode}`;
    }
    for (const workTypeCode of params.filters.workType) {
        queryParams += `&${offersQueryParamNames.workType}=${workTypeCode}`;
    }

    if ([SalaryType.Hourly, SalaryType.Monthly].includes(params.filters.paymentRateType as SalaryType)) {
        queryParams += `&${offersQueryParamNames.paymentRateType}=${params.filters.paymentRateType}`;
    }

    const searchQueryParams = mapOffersSearchParamsToQueryParameters(params, false, isUrlMapper);

    if (searchQueryParams.length > 0) {
        queryParams += `&${searchQueryParams}`;
    }


    // if (queryParams) {
    //     queryParams = queryParams.replace("&", "?").replace("locationId", "city");
    // }

    return queryParams;
};

export const mapOffersQuerySearchToFetchParams = (
    querySearch: string,
    currentFetchParams: OffersFetchParams,
    branches: DictionaryItem[],
    ignoreOffset: boolean = false
): OffersFetchParams => {
    const params: OffersFetchParams = {
        ...currentFetchParams,
        filters: { branchId: [], workType: [], employerType: [] },
    };

    const branchMatches = querySearch.match(new RegExp(`${offersQueryParamNames.branchId}=[a-zA-Z0-9_]+`, "g")) ?? [];
    for (const match of branchMatches) {
        params.filters.branchId.push(+match.split("=")[1]);
    }

    const workTypeMatches = querySearch.match(new RegExp(`${offersQueryParamNames.workType}=[a-zA-Z_]+`, "g")) ?? [];
    for (const match of workTypeMatches) {
        params.filters.workType.push(match.split("=")[1] as WorkType);
    }

    const employerTypeMatches = querySearch.match(new RegExp(`${offersQueryParamNames.employerType}=[a-zA-Z_]+`, "g")) ?? [];
    for (const match of employerTypeMatches) {
        params.filters.employerType.push(match.split("=")[1] as string);
    }

    const rewardRateTypeMatch = querySearch.match(
        new RegExp(`${offersQueryParamNames.paymentRateType}=[a-zA-Z_]+`, "g")
    );
    params.filters.paymentRateType = rewardRateTypeMatch
        ? (rewardRateTypeMatch[0].split("=")[1] as SalaryType)
        : undefined;

    const textSearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.text}=[^&]+`));
    if (textSearchMatch) {
        const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
        params.search = {
            ...searchCopy,
            term: { ...searchCopy.term!, rawInputText: decodeURIComponent(textSearchMatch[0].split("=")[1]) },
        };
    }

    // const locationMatches = querySearch.match(new RegExp(`${offersQueryParamNames.location}=[^&]+`, "g")) ?? [];
    // if (locationMatches) {
    //     const locationsTags: Tag[] = locationMatches.map((current) => ({
    //         id: current.split("=")[1],
    //         isWithoutDetails: true,
    //         name: "",
    //         section: "",
    //     }));

    //     const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
    //     params.search = {
    //         ...searchCopy,
    //         location: locationsTags,
    //     };
    // }

    // ["city=Warszawa"]
    const CityMatch = querySearch.match(new RegExp(`${offersQueryParamNames.city}=[^&]+`, "g")) ?? [];
    if (CityMatch && CityMatch.length) {
        const cityValue = CityMatch[0]!.split("=")[1];
        const searchCopy = deepCopyJobOffersSearchParams(params.search, true);

        params.search = {
            ...searchCopy,
            location: [{
                id: decodeURI(cityValue),
                name: decodeURI(cityValue),
                section: "cities"
            }],
        };
    }

    const placeIdSearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.placeId}=[^&]+`));
    if (placeIdSearchMatch) {
        const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
        params.search = {
            ...searchCopy,
            placeId: placeIdSearchMatch[0].split("=")[1],
        };
    }

    const distanceSearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.distance}=[^&]+`));
    if (distanceSearchMatch) {
        const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
        params.search = {
            ...searchCopy,
            distance: parseInt(distanceSearchMatch[0].split("=")[1]),
        };
    }

    const pageLimitMatch = querySearch.match(new RegExp(`${offersQueryParamNames.pageLimit}=[\\d]+`));
    if (pageLimitMatch) {
        params.pageLimit = parseInt(pageLimitMatch[0].split("=")[1]);
    }

    if (!ignoreOffset) {
        const offset = extractOffsetFromQuerySearch(querySearch);
        if (offset) {
            params.offset = offset;
        }
    }

    const positionSearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.position}=[^&]+`));
    const branchesSearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.industry}=[^&]+`));
    // const companyNameSearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.companyName}=[^&]+`));
    const fuzzySearchMatch = querySearch.match(new RegExp(`${offersQueryParamNames.fuzzySearch}=[^&]+`));
    const fuzzySearch = fuzzySearchMatch ? (fuzzySearchMatch[0].split("=")[1] === "true" ? true : false) : undefined;

    if (positionSearchMatch) {
        const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
        const positionValue = decodeURIComponent(positionSearchMatch[0].split("=")[1]);
        params.search = {
            ...searchCopy,
            term: {
                section: SectionName.Positions,
                selectedSuggestionValue: positionValue,
                rawInputText: positionValue,
                fuzzySearch,
            },
        };
    } else if (branchesSearchMatch) {
        const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
        const branchValue = decodeURIComponent(branchesSearchMatch[0].split("=")[1]);
        params.search = {
            ...searchCopy,
            term: {
                section: SectionName.Branches,
                selectedSuggestionValue: branchValue,
                rawInputText: branches.find((branch) => branch.id === branchValue)?.name ?? "",
                fuzzySearch,
            },
        };
        // } else if (companyNameSearchMatch) {
        //     const searchCopy = deepCopyJobOffersSearchParams(params.search, true);
        //     const companyNameValue = decodeURIComponent(companyNameSearchMatch[0].split("=")[1]);
        //     params.search = {
        //         ...searchCopy,
        //         term: {
        //             section: SectionName.Company,
        //             selectedSuggestionValue: companyNameValue,
        //             rawInputText: "",
        //             fuzzySearch,
        //         },
        //     };
    }

    return params;
};

export const mapOffersSearchParamsToQueryParameters = (
    params: OffersFetchParams,
    asStandaloneQueryString: boolean = true,
    isUrlMapper: boolean = true,
) => {
    let queryParams = "";

    if (params.search?.term?.fuzzySearch !== undefined) {
        queryParams += `&${offersQueryParamNames.fuzzySearch}=${params.search.term.fuzzySearch}`;
    }

    if (params.search?.term) {
        if (!params.search?.term.section) {
            queryParams += `&${offersQueryParamNames.text}=${encodeURIComponent(params.search?.term.rawInputText)}`;
        } else {
            switch (params.search?.term.section) {
                case SectionName.Positions:
                    queryParams += `&${offersQueryParamNames.position}=${encodeURIComponent(
                        params.search?.term.selectedSuggestionValue
                    )}`;
                    break;
                case SectionName.Company:
                    queryParams += `&${offersQueryParamNames.companyName}=${encodeURIComponent(
                        params.search?.term.selectedSuggestionValue
                    )}`;
                    break;
                case SectionName.Branches:
                    queryParams += `&${offersQueryParamNames.industry}=${encodeURIComponent(
                        params.search?.term.selectedSuggestionValue
                    )}`;
                    break;
            }
        }
    }

    if (isUrlMapper) {
        for (const location of params.search?.location ?? []) {
            queryParams += `&${offersQueryParamNames.city}=${location.name}`;
        }
    }

    if (params.search?.placeId) {
        queryParams += `&${offersQueryParamNames.placeId}=${params.search?.placeId}`;
    }

    if (params.search?.distance) {
        queryParams += `&${offersQueryParamNames.distance}=${params.search.distance}`;
    }

    if (queryParams.length > 0) {
        queryParams = queryParams.slice(1, queryParams.length);

        if (asStandaloneQueryString) {
            queryParams = `?${queryParams}`;
        }
    }

    return queryParams;
};

export const mapLanguageStringToEnum = (languageName: string) => {
    switch (languageName) {
        case "English":
            return 0;
        case "Polski":
            return 8;
        default:
            return 11;
    }
};

export const mapLanguageLevelStringToEnum = (languageLevel: string) => {
    switch (languageLevel) {
        case "BEGINNER":
            return 0;
        case "INTERMEDIATE":
            return 1;
        case "ADVANCED":
            return 2;
    }
};

export const mapJobOfferDetailsDtoToOfferDetails = (dto: JobOfferDetailsResponseDto): JobOfferDetails => {
    return {
        ...dto.data,
        addedDate: mapUtcStringToLocalDate(dto.data.addedDate),
        expirationDate: mapUtcStringToLocalDate(dto.data.expirationDate)!,
        startDate: mapUtcStringToLocalDate(dto.data.jobStartDate)!,
        endDate: dto.data.jobEndDate ? mapUtcStringToLocalDate(dto.data.jobEndDate) : undefined,
        photoUrls: dto.data.images,
        languageRequirements: (dto.data.languageRequirements as any).map((language: any) => ({
            languageType: mapLanguageStringToEnum(language.name),
            languageLevelType: mapLanguageLevelStringToEnum(language.level),
        })),
        status:
            dto.data.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : dto.data.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
        amenities: (Object.keys(dto.data.amenities) as Array<keyof typeof dto.data.amenities>)
            .filter((amenity: keyof typeof AmenityType) => {
                return !!dto.data.amenities[amenity];
            })
            .map((amenityKey: keyof typeof AmenityType) => ({
                amenityType: amenityKey as AmenityType,
                value: `${dto.data.amenities[amenityKey]}`,
            })),
    };
};
