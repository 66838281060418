import {
    selectActionOfferId,
    selectActionOfferLoading,
    selectActionOfferLoadingError,
    selectOffers,
    selectOffersLoading,
    selectOffersLoadingError,
    selectTotalOffers,
} from "features/moderatorPanel/selectors";
import { amendOffersAsync, getOffersAsync, putOfferAsync, endOfferAsync } from "features/moderatorPanel/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import OfferSearchComponent from "./OfferSearchComponent";

const mapStateToProps = (state: RootState) => ({
    offers: selectOffers(state),
    offersLoading: selectOffersLoading(state),
    offersLoadingError: selectOffersLoadingError(state),
    totalOffers: selectTotalOffers(state),
    actionOfferRequest: selectActionOfferId(state),
    actionOfferLoading: selectActionOfferLoading(state),
    actionOfferLoadingError: selectActionOfferLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        getOffersAsync,
        amendOffersAsync,
        putOfferAsync,
        endOfferAsync
    },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(OfferSearchComponent);
