import { useEffect, useState } from "react";

const START_COUNTER = 10;

export const useCloseWindow = () => {
    const [counter, setCounter] = useState(START_COUNTER);

    useEffect(() => {
        if (counter === 0) {
            window.close();
        }
        if (counter > 0) {
            setTimeout(() => setCounter((prev) => prev - 1), 1000);
        }
    }, [counter]);

    return { counter };
};
