import { Currency } from "features/common/models";
import { Card, CardType, DropdownOption } from "features/common/types";
import { YourCardStepSchema } from "features/offers/schemas";
import { CreateOfferStepName, OffersFetchParams } from "features/offers/types";

export const getCurrenciesDropdownOptions = (currencies?: Currency[]): DropdownOption<string>[] =>
    currencies?.map((currency) => ({
        value: currency.code,
        display: currency.name,
    })) ?? [];

export const countOffersFiltersApplied = (fetchParams: OffersFetchParams) => {
    let filtersCount = 0;

    filtersCount += fetchParams.filters.branchId.length;
    filtersCount += fetchParams.filters.workType.length;

    if (fetchParams.filters.paymentRateType !== undefined) {
        filtersCount += 1;
    }

    return filtersCount;
};

export const getYourCardStepInitialData = (type: CardType): YourCardStepSchema => ({
    type,
    location: {
        input: "",
        option: {
            value: "",
        },
        section: "",
    },
});
