import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as MyOffersEmptyStateIcon } from "assets/icons/myOffersEmptyState.svg";
import classNames from "classnames";
import Spinner from "features/common/components/Spinner";
import TabNavigation from "features/common/components/TabNavigation";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import { OfferType } from "features/common/types";
import { MyEmployeeOfferCardDetails } from "features/employeeOffers/models";
import {
    getCreateMyEmployeeOffersEmptyHeader,
    getCreateMyEmployeeOffersEmptyMessage,
} from "features/employeeOffers/translationMessages";
import CreateJobOfferModal from "features/jobOffers/components/CreateJobOfferModal";
import { MyJobOffersCardDetails } from "features/jobOffers/models";
import {
    getCreateMyJobOffersEmptyHeader,
    getCreateMyJobOffersEmptyMessage,
} from "features/jobOffers/translationMessages";
import { MyJobOffersFetchParams } from "features/jobOffers/types";
import SingleOfferCard from "features/offers/components/SingleOfferCard";
import { MyOffersCategory, OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import AddOfferButton from "./AddOfferButton";
import EndOfferModal from "./EndOfferModal";
import styles from "./styles.module.scss";
import useDebounce from "../../../common/hooks/useDebounce";

interface Props {
    offerType: OfferType;
    myOffersLoading: boolean;
    myOffers?: MyJobOffersCardDetails[] | MyEmployeeOfferCardDetails[];
    myOffersLoadingError?: ApiError;
    getMyOffersAsync: (params: MyJobOffersFetchParams, cancelToken?: CancelToken) => void;
    resetMyOffers: () => void;
}

const MyOffers = ({
    offerType,
    myOffersLoading,
    myOffers,
    myOffersLoadingError,
    getMyOffersAsync,
    resetMyOffers,
}: Props) => {
    useScrollToTopOnMount();

    const intl = useIntl();
    const history = useHistory();
    const deviceClass = useDeviceClass();
    const createCancelToken = useCreateCancelToken();
    const bottomRef = useRef<HTMLDivElement | null>(null);

    const [activeTab, setActiveTab] = useState(MyOffersCategory.All);
    const [wasComponentInitiallyLoaded, setComponentInitiallyLoaded] = useState(false);
    const [isEndOfferModalOpen, setIsEndOfferModalOpen] = useState(false);
    const [endOfferId, setEndOfferId] = useState<number>();
    const [createOfferModalVisible, setCreateOfferModalVisible] = useState(false);

    const getMyJobOffersEmptyHeader = getCreateMyJobOffersEmptyHeader(intl);
    const getMyJobOffersEmptyMessage = getCreateMyJobOffersEmptyMessage(intl);
    const getMyEmployeeOffersEmptyHeader = getCreateMyEmployeeOffersEmptyHeader(intl);
    const getMyEmployeeOffersEmptyMessage = getCreateMyEmployeeOffersEmptyMessage(intl);

    const isLoading = useDebounce(!wasComponentInitiallyLoaded || myOffersLoading, 250);

    const offersToDisplay =
        (() => {
            switch (activeTab) {
                case MyOffersCategory.All:
                    return myOffers;
                case MyOffersCategory.Active:
                    return myOffers?.filter((offer) => offer.status === OfferStatus.Active);
                case MyOffersCategory.Inactive:
                    return myOffers?.filter((offer) => offer.status === OfferStatus.Inactive);
                case MyOffersCategory.Ended:
                    return myOffers?.filter((offer) => offer.status === OfferStatus.Ended);
            }
        })() ?? [];

    const isListEmpty = !isLoading && !offersToDisplay.length && !myOffersLoadingError;
    useEffect(() => {
        if (myOffers?.length === 0 && !myOffersLoading && !myOffersLoadingError) {
            getMyOffersAsync({}, createCancelToken());
        }
    }, []);

    useEffect(() => setComponentInitiallyLoaded(true), [myOffersLoading]);

    useEffect(() => () => resetMyOffers(), [resetMyOffers]);

    useEffect(() => {
        if (myOffersLoading || myOffers?.length === 0) return;
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                getMyOffersAsync({}, createCancelToken());
            }
        });
        observer.observe(bottomRef.current!);

        return () => {
            observer.disconnect();
        };
    }, [myOffersLoading]);

    const handleCreateOfferClick = useCallback(() => {
        setCreateOfferModalVisible(true);
    }, []);

    return (
        <>
            <div className={styles["my-offers"]}>
                <div className={styles["my-offers__header-container"]}>
                    <div className={styles["my-offers__inner-header-container"]}>
                        <h2>
                            {offerType === OfferType.Job ? (
                                <FormattedMessage id="common__my-job-offers" />
                            ) : (
                                <FormattedMessage id="common__my-employee-offers" />
                            )}
                        </h2>
                        <TabNavigation
                            className={styles["my-offers__tab-navigation"]}
                            tabs={[
                                <FormattedMessage id="my-offers__all" />,
                                <FormattedMessage id="my-offers__active" />,
                                <FormattedMessage id="my-offers__inactive" />,
                                <FormattedMessage id="my-offers__ended" />,
                            ]}
                            activeTabIndex={activeTab}
                            onTabSelect={(index: MyOffersCategory) => setActiveTab(index)}
                        />
                    </div>
                    {deviceClass === "desktop" && (
                        <AddOfferButton offerType={offerType} onClick={handleCreateOfferClick} />
                    )}
                </div>
                <div
                    className={classNames(styles["my-offers__offers-container"], {
                        [styles["my-offers__offers-container--empty"]]: isListEmpty || !!myOffersLoadingError,
                    })}
                >
                    {isListEmpty && (
                        <>
                            <MyOffersEmptyStateIcon />
                            {offerType === OfferType.Job ? (
                                <>
                                    <span>{getMyJobOffersEmptyHeader(activeTab)}</span>
                                    <span>{getMyJobOffersEmptyMessage(activeTab)}</span>
                                </>
                            ) : (
                                <>
                                    <span>{getMyEmployeeOffersEmptyHeader(activeTab)}</span>
                                    <span>{getMyEmployeeOffersEmptyMessage(activeTab)}</span>
                                </>
                            )}
                        </>
                    )}
                    {myOffers &&
                        myOffers.length !== 0 &&
                        offersToDisplay.map((offer) => (
                            <SingleOfferCard
                                key={`${offer.id}-${offer.status}`}
                                className={styles["my-offers__offer-card"]}
                                {...offer}
                                publishedDate={offer.publishDate}
                                offerType={offerType}
                                title={offer.title}
                                previewMode="minimum"
                                financialConditions={offer.financialConditionsSlimDTO}
                                onEndOffer={() => {
                                    setEndOfferId(offer.id);
                                    setIsEndOfferModalOpen(true);
                                }}
                                onTitleImageClick={() =>
                                    offer.status !== OfferStatus.Inactive &&
                                    history.push(
                                        `${
                                            offerType === OfferType.Job
                                                ? appRoutes.myJobOffers
                                                : appRoutes.myEmployeeOffers
                                        }/${offer.id}`
                                    )
                                }
                                hideActionButtons={offerType === OfferType.Employee}
                            />
                        ))}
                    {!myOffersLoadingError && isLoading && (
                        <>
                            <div />
                            <div className={styles.spinner}>
                                <Spinner thickness="thin" />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div ref={bottomRef} />

            {deviceClass !== "desktop" && <AddOfferButton offerType={offerType} onClick={handleCreateOfferClick} />}
            {isEndOfferModalOpen && (
                <EndOfferModal
                    offerId={endOfferId}
                    offerType={offerType}
                    onCancel={() => setIsEndOfferModalOpen(false)}
                    onConfirm={() => {
                        resetMyOffers();
                        setIsEndOfferModalOpen(false);
                        window.scrollTo(0, 0);
                    }}
                />
            )}
            <CreateJobOfferModal
                isVisible={createOfferModalVisible}
                handleCloseModal={() => setCreateOfferModalVisible(false)}
                asJobOffersList={offerType === OfferType.Job}
            />
        </>
    );
};

export default MyOffers;
