import { IntlShape } from "react-intl";

export const getSearchForUserMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "admin-panel__search-for-user",
    });

export const getMailMessage = (intl: IntlShape) => intl.formatMessage({ id: "common__mail" });

export const getReviewMessage = (intl: IntlShape) => intl.formatMessage({ id: "common__review" });

export const getUserProfileMessage = (intl: IntlShape) => intl.formatMessage({ id: "admin-panel__user-profile" });

export const getSingleEmployeeOffersMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "admin-panel__single-employee-offers" });
