import { ReactComponent as DeFlag } from "assets/icons/flags/deFlag.svg";
import { ReactComponent as EnFlag } from "assets/icons/flags/enFlag.svg";
import { ReactComponent as FrFlag } from "assets/icons/flags/frFlag.svg";
import { ReactComponent as NldFlag } from "assets/icons/flags/nldFlag.svg";
import { ReactComponent as PlFlag } from "assets/icons/flags/plFlag.svg";
import { ReactComponent as RuFlag } from "assets/icons/flags/ruFlag.svg";
import { ReactComponent as UaFlag } from "assets/icons/flags/uaFlag.svg";
import classNames from "classnames";
import Dropdown from "features/common/components/Dropdown";
import { DropdownOption } from "features/common/types";
import { Locale } from "lang";
import React from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    locale: Locale;
    className?: string;
    ["data-testid"]?: string;
    setLocale: (locale: Locale) => void;
    variant?: 'mobile' | 'desktop' | 'footer';
    footer?: boolean;
}

interface LocaleDisplayComponent {
    value: Locale;
    display: React.ReactNode;
}

const LanguageSwitch = ({ footer, variant, locale, className, "data-testid": testId, setLocale }: Props) => {
    const intl = useIntl();

    const onSelect = (locale: Locale) => {
        if (locale !== (intl.locale as Locale)) {
            setLocale(locale);
        }
    };

    const getLocaleDisplayComponent = (icon: React.SVGProps<SVGSVGElement>, value: Locale, displayName: string): LocaleDisplayComponent => ({
        value,
        display: (
            <>
                <span className={styles["language-switch__icon"]}>
                    {icon}
                </span>
                <div className={styles["language-name"]}>{displayName}</div>
            </>
        ),
    });

    const dropdownOptions: DropdownOption<Locale>[] = [
        getLocaleDisplayComponent(<PlFlag />, "pl", "Polish"),
        getLocaleDisplayComponent(<EnFlag />, "en", "English"),
        getLocaleDisplayComponent(<UaFlag />, "uk", "Ukrainian"),
        getLocaleDisplayComponent(<DeFlag />, "de", "Deutsch"),
        getLocaleDisplayComponent(<FrFlag />, "fr", "Français"),
        getLocaleDisplayComponent(<RuFlag />, "ru", "Russian"),
        getLocaleDisplayComponent(<NldFlag />, "nl", "Netherlands"),
    ];
    return (
        <>
            {variant === "desktop" && <Dropdown
                id="language-switch-dropdown"
                className={classNames(styles["language-switch"], {
                    [styles["language-switch-footer"]]: footer
                }, className)}
                optionClassName={styles["language-switch__option"]}
                toggleClassName={styles["language-switch__toggle"]}
                options={dropdownOptions}
                selected={dropdownOptions.find((option) => option.value === locale)}
                onSelect={onSelect}
            />}
            {variant === "mobile" && <Dropdown
                id="language-switch-dropdown"
                className={classNames(styles["language-switch-mobile"], className)}
                optionClassName={styles["language-switch-mobile__option"]}
                optionsClassName="dropdown__mobile"
                toggleClassName={styles["language-switch-mobile__toggle"]}
                options={dropdownOptions}
                selected={dropdownOptions.find((option) => option.value === locale)}
                onSelect={onSelect}
            />}
        </>
    );
};

export default LanguageSwitch;
