import Api from "api";
import config from "config";
import { PreAuth, IndicatorRequest, IndicatorResult, Auth, Payment } from "./types";
import { threeDSRequest } from "./helpers/threeDSRequest";

class FenigeApiClass extends Api {
    PreAuth: PreAuth = (data) => this.post(config.apiRoutes.FENIGE_API_PRE_AUTH, data);
    IndicatorRequest: IndicatorRequest = async (data) => threeDSRequest(data);
    IndicatorResult: IndicatorResult = (data) => this.post(config.apiRoutes.FENIGE_API_INDICATOR_REQUEST, data);
    Auth: Auth = (data) => this.post(config.apiRoutes.FENIGE_API_AUTH, data);
    Payment: Payment = (data) => this.post(config.apiRoutes.FENIGE_API_PAYMENT, data);
}

export const FenigeApi = new FenigeApiClass();
