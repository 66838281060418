import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as PenIcon } from "assets/icons/orangePen.svg";
import Button from "features/common/components/Button";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    label: string;
    value?: JSX.Element | null;
    isCleanable: boolean;
    ["data-testid"]?: string;
    onAdd?: () => void;
    onSave?: () => boolean | void;
    onEdit?: () => void;
    onClean?: () => void;
    onCancel?: () => void;
    customPrimaryContent?: string;
    editorContent?: JSX.Element | React.ReactNode;
}

const RequirementBlock = ({
    label,
    value,
    isCleanable,
    "data-testid": testId,
    onAdd,
    onSave,
    onEdit,
    onClean,
    onCancel,
    customPrimaryContent,
    editorContent,
}: Props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div data-testid={testId} className={styles["requirement-block"]}>
            <div className={styles["requirement-block__header"]}>
                <div
                    data-testid={testId ? `${testId}__header-label` : undefined}
                    className={styles["requirement-block__header-label"]}
                >
                    {label}
                </div>

                {value ? (
                    expanded ? (
                        <div
                            data-testid={testId ? `${testId}__add-button` : undefined}
                            className={styles["requirement-block__header-add-link"]}
                            onClick={() => {
                                onCancel?.();
                                setExpanded(false);
                            }}
                        >
                            {" - "}
                            <FormattedMessage id="common__collapse" />
                        </div>
                    ) : (
                        <div
                            data-testid={testId ? `${testId}__edit-button` : undefined}
                            className={styles["requirement-block__header-add-link"]}
                            onClick={() => setExpanded(true)}
                        >
                            <PenIcon className={styles["requirement-block__header--edit-icon"]} />
                            <FormattedMessage id="common__change" />
                        </div>
                    )
                ) : expanded ? (
                    <div
                        data-testid={testId ? `${testId}__add-button` : undefined}
                        className={styles["requirement-block__header-add-link"]}
                        onClick={() => {
                            onCancel?.();
                            setExpanded(false);
                        }}
                    >
                        {" - "}
                        <FormattedMessage id="common__collapse" />
                    </div>
                ) : (
                    <div
                        data-testid={testId ? `${testId}__add-button` : undefined}
                        className={styles["requirement-block__header-add-link"]}
                        onClick={() => setExpanded(true)}
                    >
                        {" + "}
                        <FormattedMessage id="common__add" />
                    </div>
                )}
            </div>
            {expanded && (
                <div className={styles["requirement-block__editor"]}>
                    {editorContent}
                    <div className={styles["requirement-block__editor-buttons"]}>
                        <Button
                            variant="tertiary"
                            data-testid={`${testId}__cancel-button`}
                            onClick={() => {
                                onCancel?.();
                                setExpanded(false);
                            }}
                        >
                            <FormattedMessage id="common__cancel" />
                        </Button>
                        <Button
                            type="submit"
                            data-testid={`${testId}__action-button`}
                            onClick={() => {
                                const didSave = onSave?.();
                                if (didSave || !onSave) {
                                    setExpanded(false);
                                }
                            }}
                        >
                            {customPrimaryContent ? customPrimaryContent : <FormattedMessage id="common__select" />}
                        </Button>
                    </div>
                </div>
            )}
            {value && !expanded && (
                <div
                    data-testid={testId ? `${testId}__content` : undefined}
                    className={styles["requirement-block__content"]}
                >
                    {value}
                    {isCleanable && (
                        <div
                            data-testid={testId ? `${testId}__clear-button` : undefined}
                            className={styles["requirement-block__content--bin-container"]}
                            onClick={onClean}
                        >
                            <BinIcon />
                            <FormattedMessage id="create-offer__requirements-clear" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default RequirementBlock;
