import { CancelToken } from "api/utils";
import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import Button from "features/common/components/Button";
import { CardDto } from "features/common/dtos";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import usePrevious from "features/common/hooks/usePrevious";
import mapCardTypeToCardOwnerType from "features/common/mappers/mapCardTypeToCardOwnerType";
import { getCountryTranslation } from "features/common/translationMessages";
import { Card, DictionaryItem } from "features/common/types";
import { getLocalizedYup } from "features/common/validators";
import { mapCardDetailsFormValuesToDto } from "features/myProfile/mappers";
import { CardDetailsSchema } from "features/myProfile/schemas";
import { CardOwnerType } from "features/myProfile/types";
import { createCardDetailsFormValidationSchema } from "features/myProfile/validators";
import { Form, Formik } from "formik";
import flatten from "lodash/flatten";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormattedMessage, useIntl } from "react-intl";
import DetailsForm from "./DetailsForm";
import styles from "./styles.module.scss";
import { ReactComponent as PersonalInfoIcon } from "assets/icons/personalInfoIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phoneIcon.svg";
import { ReactComponent as LocationIcon } from "assets/icons/locationIcon.svg";

interface Props {
    cardDetails?: Card;
    branches?: DictionaryItem[];
    sectionBeingEdited?: boolean;
    myProfileCardDetailsUpdated?: boolean;
    displayOnly?: boolean;
    ["data-testid"]?: string;
    updateMyProfileCardAsync: (cardDetails: Partial<CardDto>, id: string, cancelToken?: CancelToken) => void;
    setSectionBeingEdited?: (isBeingEdited: boolean) => void;
}

const DetailsSection = ({
    cardDetails,
    branches,
    sectionBeingEdited,
    displayOnly,
    "data-testid": testId = "details-section",
    updateMyProfileCardAsync,
    setSectionBeingEdited,
}: Props) => {
    const intl = useIntl();
    const [isEditMode, setIsEditMode] = useState(false);
    const branchesFlattened = flatten(branches?.map(branchItem => branchItem?.branches as any)) || [];

    const [initialValues, setInitialValues] = useState<CardDetailsSchema>({
        companyName: cardDetails?.companyName ?? "",
        firstName: cardDetails?.firstName ?? "",
        lastName: cardDetails?.lastName ?? "",
        branch: cardDetails?.branchCode,
        nip: cardDetails?.taxIdentificationNumber ?? "",
        krazCert: cardDetails?.krazCert ?? "",
        location: {
            ...cardDetails?.locationDTO,
        },
        phoneNumber: cardDetails?.phoneNumber ?? "",
        phonePrefix: cardDetails?.phonePrefix ?? "",
        email: cardDetails?.email ?? "",
        isDirty: false,
    });

    const createCancelToken = useCreateCancelToken();
    const previousCardDetails = usePrevious(cardDetails);

    const cardType: CardOwnerType | undefined = cardDetails && mapCardTypeToCardOwnerType(cardDetails.type);

    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return createCardDetailsFormValidationSchema(localYup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const formikRef = useRef<any>();

    const onSubmit = (values: CardDetailsSchema) => {
        if (formikRef.current.dirty && cardDetails?.id) {
            const mapped = mapCardDetailsFormValuesToDto(values);
            updateMyProfileCardAsync(
                {
                    ...mapped,
                    isPersonal: cardType === "private",
                    personal: cardType === "private",
                },
                cardDetails?.id,
                createCancelToken()
            );
        }
        setIsEditMode(false);
        setSectionBeingEdited && setSectionBeingEdited(false);
    };

    useEffect(() => {
        if (!!cardDetails && cardDetails !== previousCardDetails) {
            setInitialValues({
                companyName: cardDetails?.companyName ?? "",
                firstName: cardDetails?.firstName ?? "",
                lastName: cardDetails?.lastName ?? "",
                branch: cardDetails?.branchCode,
                nip: cardDetails?.taxIdentificationNumber ?? "",
                krazCert: cardDetails?.krazCert ?? "",
                location: cardDetails?.locationDTO,
                phoneNumber: cardDetails?.phoneNumber ?? "",
                phonePrefix: cardDetails?.phonePrefix ?? "",
                email: cardDetails?.email ?? "",
                isDirty: false,
            });
        }
    }, [cardDetails, previousCardDetails]);

    useEffect(() => {
        setTimeout(() => formikRef.current?.validateForm());
    }, []);

    const {
        placePredictions,
        getPlacePredictions,
        placesService
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500
    });

    return (
        <div data-testid={testId} className={styles["details-section"]}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnMount
                innerRef={formikRef}
            >
                {({ values, dirty, resetForm }) => (
                    <Form>
                        {!displayOnly && (
                            <div className={styles["details-section__info"]}>
                                <FormattedMessage id="card-details-section__info" />
                            </div>
                        )}
                        {!isEditMode || displayOnly ? (
                            <div className={styles["details-section__card-details"]}>
                                <div className={styles["details-section__data-container"]}>
                                    {cardType === "company" ? (
                                        <div className={styles["details-section__company-data"]}>
                                            <div className={styles["details-section__general-data-title-container"]}>
                                                <PersonalInfoIcon />
                                                <h3 className={styles["details-section__company-data-subtitle"]}>
                                                    <FormattedMessage id="card-details-section__company-data" />
                                                </h3>
                                            </div>
                                            <div>
                                                <span>{cardDetails?.companyName ?? ""}</span>
                                            </div>
                                            <div>
                                                {
                                                    cardDetails?.branchCode && (
                                                        <FormattedMessage
                                                            id="card-details-section__branch"
                                                            values={{
                                                                branch:
                                                                    cardDetails?.branchText ||
                                                                    intl.formatMessage({
                                                                        id:
                                                                            branchesFlattened?.find(
                                                                                (item) => item.id === cardDetails?.branchCode
                                                                            )?.name
                                                                    }
                                                                    )
                                                                    ||
                                                                    "-",
                                                            }}
                                                        />
                                                    )
                                                }

                                            </div>
                                            <div>
                                                <FormattedMessage
                                                    id="card-details-section__tax-identification-number"
                                                    values={{ tin: cardDetails?.taxIdentificationNumber ?? "-" }}
                                                />
                                            </div>
                                            <div className={styles["details-section__kraz-cert"]}>
                                                <FormattedMessage
                                                    id="card-details-section__company-certificate"
                                                    values={{ krazCert: cardDetails?.krazCert ?? "-" }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles["details-section__personal-data"]}>
                                            <div className={styles["details-section__general-data-title-container"]}>
                                                <PersonalInfoIcon />
                                                <h3 className={styles["details-section__personal-data-subtitle"]}>
                                                    <FormattedMessage id="card-details-section__personal-data" />
                                                </h3>
                                            </div>
                                            <div>
                                                <span>
                                                    {cardDetails
                                                        ? `${cardDetails?.firstName} ${cardDetails?.lastName}`
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles["details-section__contact-data"]}>
                                        <div className={styles["details-section__general-data-title-container"]}>
                                            <PhoneIcon />
                                            <h3 className={styles["details-section__contact-data-subtitle"]}>
                                                <FormattedMessage id="card-details-section__contact-data" />
                                            </h3>
                                        </div>
                                        <div className={styles["details-section__contact-data-subtitle-container"]}>
                                            <LocationIcon />
                                            <div className={styles["details-section__contact-data-subtitle"]}>
                                                {cardDetails?.street ? `${cardDetails.street}, ` : ''}{cardDetails?.city ? `${cardDetails?.city}, ` : ''}{getCountryTranslation(intl, cardDetails?.country, cardDetails?.country)}
                                            </div>
                                        </div>
                                        <div>
                                            {cardDetails?.phoneNumber ? (
                                                <>
                                                    <span className={styles["details-section__label"]}>
                                                        <FormattedMessage id="card-details-section__phone-number-label" />
                                                    </span>
                                                    {cardDetails?.phoneNumber}
                                                </>
                                            ) : (
                                                <FormattedMessage
                                                    id="card-details-section__phone-number"
                                                    values={{ phoneNumber: cardDetails?.phoneNumber ?? "-" }}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            {cardType === "company" &&
                                                (cardDetails?.email ? (
                                                    <>
                                                        <span className={styles["details-section__label"]}>
                                                            <FormattedMessage id="card-details-section__email-label" />
                                                        </span>
                                                        {cardDetails?.email}
                                                    </>
                                                ) : (
                                                    <FormattedMessage id="card-details-section__email" />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <DetailsForm
                                data-testid={`${testId}__edit-form`}
                                cardDetails={cardDetails}
                                placePredictions={placePredictions}
                                getPlacePredictions={getPlacePredictions}
                                placesService={placesService}
                            />
                        )}
                        <div className={styles["details-section__action-buttons"]}>
                            {!isEditMode && !sectionBeingEdited && !displayOnly && (
                                <Button
                                    data-testid={`${testId}__change`}
                                    variant="no-background"
                                    disabled={sectionBeingEdited}
                                    onClick={() => {
                                        setIsEditMode(true);
                                        setSectionBeingEdited && setSectionBeingEdited(true);
                                    }}
                                >
                                    <PenIcon className={styles["details-section__action-button-icon"]} />
                                    <FormattedMessage id="common__change-data" />
                                </Button>
                            )}

                            {isEditMode && (
                                <div className={styles["details-section__edit-mode-buttons"]}>
                                    <Button
                                        data-testid={`${testId}__cancel`}
                                        variant="no-background"
                                        type="button"
                                        onClick={() => {
                                            setIsEditMode(false);
                                            if (dirty) {
                                                resetForm();
                                            }
                                            setSectionBeingEdited && setSectionBeingEdited(false);
                                        }}
                                    >
                                        <FormattedMessage id="common__cancel" />
                                    </Button>
                                    <Button
                                        data-testid={`${testId}__save`}
                                        variant="no-background"
                                        className={styles["details-section__save-button"]}
                                        type="submit"
                                        onClick={(() => onSubmit(values))}
                                    >
                                        <FormattedMessage id="common__save" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default DetailsSection;
