import { DropdownOption, RadioButtonOption } from "features/common/types";
import { getLocalizedYup } from "features/common/validators";
import { ContactFormSchema } from "features/contactForm/schemas";
import {
    getBonusTopicOptionMessage,
    getComplaintTopicOptionMessage,
    getEmployeeMessage,
    getEmployerMessage,
    getHowToTopicOptionMessage,
    getOthersTopicOptionMessage,
    getPlatformTopicOptionMessage,
    getSideEffectsTopicOptionMessage,
} from "features/contactForm/translationMessages";
import { RoleType, TopicType } from "features/contactForm/types";
import { contactFormValidationSchema } from "features/contactForm/validators";
import { Locale } from "lang";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";

export const useRoleRadioButtonOptionsData = (locale: Locale) => {
    const employeeLabel = getEmployeeMessage(locale);
    const employerLabel = getEmployerMessage(locale);

    return [
        { id: RoleType.Employee, name: employeeLabel },
        { id: RoleType.Employer, name: employerLabel },
    ];
};

export const useRoleRadioButtonOptions = (): RadioButtonOption<number>[] => {
    const intl = useIntl();
    const optionsData = useRoleRadioButtonOptionsData(intl.locale as Locale);

    return optionsData.map((option) => ({ label: option.name, value: option.id }));
};

export const useTopicDropdownOptionsData = (locale: Locale) => {
    const platformOptionText = getPlatformTopicOptionMessage(locale);
    const bonusOptionText = getBonusTopicOptionMessage(locale);
    const complaintOptionText = getComplaintTopicOptionMessage(locale);
    const sideEffectsOptionText = getSideEffectsTopicOptionMessage(locale);
    const howToOptionText = getHowToTopicOptionMessage(locale);
    const othersOptionText = getOthersTopicOptionMessage(locale);

    return [
        { id: TopicType.Platform, name: platformOptionText },
        { id: TopicType.Bonus, name: bonusOptionText },
        { id: TopicType.Complaint, name: complaintOptionText },
        { id: TopicType.SideEffects, name: sideEffectsOptionText },
        { id: TopicType.HowTo, name: howToOptionText },
        { id: TopicType.Others, name: othersOptionText },
    ];
};

export const useTopicDropdownOptions = (role: RoleType): DropdownOption<number>[] => {
    const intl = useIntl();
    const optionsData = useTopicDropdownOptionsData(intl.locale as Locale);
    const options = optionsData.map((option) => ({ display: option.name, value: option.id }));

    if (role === RoleType.Employer) {
        return options.filter((option) => option.value !== TopicType.Bonus);
    }

    return options;
};

export const useContactFormValidation = (role: RoleType, message: string, topic?: DropdownOption<number>) => {
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [errors, setErrors] = useState<ContactFormSchema>({});
    const intl = useIntl();

    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return contactFormValidationSchema(localYup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const handleValidation = useCallback(() => {
        const validateOptions: Yup.ValidateOptions = { abortEarly: false };

        validationSchema
            .validate(
                {
                    role,
                    topic: topic?.value,
                    message,
                },
                validateOptions
            )
            .then((_) => {
                setErrors({});
                setIsFormValid(true);
            })
            .catch((validationErrors: Yup.ValidationError) => {
                let errors: ContactFormSchema = {};

                validationErrors.inner.forEach((item) => {
                    errors = { ...errors, [item.path]: item.message };
                });

                setErrors(errors);
                setIsFormValid(false);
            });
    }, [message, role, topic, validationSchema]);

    useEffect(() => {
        handleValidation();
    }, [role, topic, message, handleValidation]);

    return [isFormValid, errors];
};
