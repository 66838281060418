import { selectCountries, selectCountriesError, selectCountriesLoading } from "features/common/selectors";
import { getCountriesAsync } from "features/common/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import PaymentDataSectionComponent from "./component";
import { resetCountries } from "../../../../common/actions";

const mapStateToProps = (state: RootState) => ({
    countries: selectCountries(state),
    countriesLoading: selectCountriesLoading(state),
    countriesError: selectCountriesError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCountriesAsync,
            resetCountries: () => resetCountries(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDataSectionComponent);
