import { ApiError } from "api/utils";
import Spinner from "features/common/components/Spinner";
import { localStorageItems } from "features/common/constants";
import useDebounce from "features/common/hooks/useDebounce";
import { UserRole } from "features/common/types";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthStorageService from "services/authStorageService";
import RefreshTimeoutService from "services/refreshTimeoutService";
import SignOutTimeoutService from "services/signOutTimeoutService";
import decode from "jwt-decode";
import styles from "./styles.module.scss";

export interface Props {
    user?: User;
    userId?: string;
    userLoading?: boolean;
    userLoadingError?: ApiError;
    userLoadingSuccess?: boolean;
    cancelTokenLoading?: boolean;
    isTokenCanceled?: boolean;
    isUserLoggedIn?: boolean;
    isUserLoggedInWithProfile?: boolean;
    children: (isUserLoggedInWithProfile?: boolean, role?: UserRole) => React.ReactNode;
    getUserAsync: (userId: string) => void;
    setUserAsLoggedIn: () => void;
    setUserAsUnauthenticated: () => void;
}

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const AuthenticationGateway = ({
    children,
    user,
    userId,
    userLoading,
    userLoadingError,
    userLoadingSuccess,
    cancelTokenLoading,
    isTokenCanceled,
    isUserLoggedIn,
    isUserLoggedInWithProfile,
    getUserAsync,
    setUserAsLoggedIn,
    setUserAsUnauthenticated,
}: Props) => {
    const history = useHistory();
    const query = useQuery();
    const messageId = query.get('messageId')

    const [loading, setLoading] = useState<boolean>(true);
    const debouncedLoading = useDebounce(loading, 250);

    useEffect(() => {
        function handleStorageChange(this: Window, event: StorageEvent) {
            if (
                (event.key === localStorageItems.TOKENS && !event.newValue) ||
                (event.key === localStorageItems.CHECK_TOKEN_DATE && !event.newValue)
            ) {
                history.go(0);
            }
        }

        window.addEventListener("storage", handleStorageChange);
    }, [history]);

    useEffect(() => {
        SignOutTimeoutService.setTimeoutSignOut();
        RefreshTimeoutService.setTimeoutRefresh();
    }, []);

    useEffect(() => {
        if (cancelTokenLoading !== undefined) {
            setLoading(cancelTokenLoading);
        }
    }, [cancelTokenLoading, history, isTokenCanceled]);

    useEffect(() => {
        const execute = async () => {
            const tokens = AuthStorageService.getTokens();

            if (!user && !userLoading && !userLoadingError) {
                if (tokens && tokens.accessToken) {
                    setLoading(true);
                    const decodedToken: { sub: string } = decode(tokens.accessToken);

                    getUserAsync(decodedToken.sub);
                    setUserAsLoggedIn();
                } else {
                    setLoading(false);
                }
            }
        };

        execute();
    }, [
        getUserAsync,
        history,
        setUserAsLoggedIn,
        setUserAsUnauthenticated,
        user,
        userId,
        userLoading,
        userLoadingError,
    ]);

    useEffect(() => {
        // When not logged in user redirected from the messages route and logged in - should redirect to the message came from
        if ((user?.id || userId) && messageId) {
            history.push(`${appRoutes.messages}/${messageId}`);
        } else if ((user?.id || userId) && !user?.firstName && !userLoading && !loading && !messageId) {
            history.push(appRoutes.createProfile);
        }
    }, [history, loading, user, userId, userLoading, userLoadingError, messageId]);

    useEffect(() => {
        const unlistenOnHistory = history.listen(() => {
            if (user) {
                RefreshTimeoutService.setTimeoutRefresh();
            }

            if (
                isUserLoggedIn &&
                !user &&
                history.location.pathname !== appRoutes.createProfile &&
                history.location.pathname !== appRoutes.signOut &&
                !messageId
            ) {
                history.push(appRoutes.createProfile);
            }
        });

        return () => {
            unlistenOnHistory();
        };
    }, [history, isUserLoggedIn, user]);

    useEffect(() => {
        if (userLoadingSuccess !== undefined) {
            setLoading(false);
        }
    }, [userLoadingSuccess]);

    return (
        <>
            {debouncedLoading ? (
                <div className={styles["authentication-gateway"]}>
                    <Spinner data-testid="authentication-gateway__spinner" />
                </div>
            ) : (
                children(isUserLoggedInWithProfile, user?.role)
            )}
        </>
    );
};

export default AuthenticationGateway;
