import {
    selectApplicationDetails,
    selectApplicationDetailsLoading,
    selectApplicationDetailsError,
    selectAcceptApplicationSuccess,
    selectRejectApplicationSuccess,
    selectAcceptArrivalSuccess,
    selectGiveBonusSuccess,
} from "features/jobOffers/selectors";
import { selectUser } from "features/user/selectors";
import { getApplicationDetailsAsync } from "features/jobOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import ApplicationDetails from "./component";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    applicationDetails: selectApplicationDetails(state),
    applicationDetailsLoading: selectApplicationDetailsLoading(state),
    applicationDetailsError: selectApplicationDetailsError(state),
    acceptApplicationSuccess: selectAcceptApplicationSuccess(state),
    rejectApplicationSuccess: selectRejectApplicationSuccess(state),
    acceptArrivalSuccess: selectAcceptArrivalSuccess(state),
    giveBonusSuccess: selectGiveBonusSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getApplicationDetailsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails);
