import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import { mapEmployeeOffersFetchParamsToQueryParameters } from "features/employeeOffers/mappers";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import { mapJobOffersFetchParamsToQueryParameters } from "features/jobOffers/mappers";
import { JobOffersFetchParams } from "features/jobOffers/types";
import { AddToRemoveFromFavoriteDTO, FavoriteEmployeeResponseDto, FavoriteEmployerResponseDto } from "../dtos";

export class FavoriteAPI extends Api {
    // favorite employee
    getFavoriteEmployeeAsync = (
        params: EmployeeOffersFetchParams,
        cancelToken?: CancelToken
    ): Promise<FavoriteEmployeeResponseDto> => {
        return this.get<FavoriteEmployeeResponseDto>(
            `${config.apiRoutes.BUSINESS_CARDS}${config.apiRoutes.FAVORITE_EMPLOYEE}${mapEmployeeOffersFetchParamsToQueryParameters(params)}&isActive=true&sort_by=addedDate&sort=desc`,
            cancelToken
        );
    };
    addEmployeeToFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.put(`${config.apiRoutes.BUSINESS_CARDS}${config.apiRoutes.FAVORITE_EMPLOYEE}/${id}`, cancelToken);
    };
    removeEmployeeFromFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.delete(`${config.apiRoutes.BUSINESS_CARDS}${config.apiRoutes.FAVORITE_EMPLOYEE}/${id}`, cancelToken);
    };

    // favorite employer
    getFavoriteEmployerAsync = (
        params: JobOffersFetchParams,
        cancelToken?: CancelToken
    ): Promise<FavoriteEmployerResponseDto> => {
        return this.get<FavoriteEmployerResponseDto>(
            `${config.apiRoutes.BUSINESS_CARDS}${config.apiRoutes.FAVORITE_EMPLOYER}${mapJobOffersFetchParamsToQueryParameters(params, true, true, false)}&isActive=true&sort_by=addedDate&sort=desc`,
            cancelToken
        );
    };
    addEmployerToFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.put(`${config.apiRoutes.BUSINESS_CARDS}${config.apiRoutes.FAVORITE_EMPLOYER}/${id}`, cancelToken);
    };
    removeEmployerFromFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.delete(`${config.apiRoutes.BUSINESS_CARDS}${config.apiRoutes.FAVORITE_EMPLOYER}/${id}`, cancelToken);
    };

    // favorite employee offer
    addEmployeeOfferToFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.put(`${config.apiRoutes.EMPLOYEE_OFFERS}${config.apiRoutes.FAVORITE}/${id}`, cancelToken);
    };
    removeEmployeeOfferFromFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.delete(`${config.apiRoutes.EMPLOYEE_OFFERS}${config.apiRoutes.FAVORITE}/${id}`, cancelToken);
    };

    // favorite job offer
    addJobOfferToFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.put(`${config.apiRoutes.OFFERS}${config.apiRoutes.FAVORITE}/${id}`, cancelToken);
    };
    removeJobOfferFromFavorite = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<AddToRemoveFromFavoriteDTO> => {
        return this.delete(`${config.apiRoutes.OFFERS}${config.apiRoutes.FAVORITE}/${id}`, cancelToken);
    };
    translateText = (translateBody: any, cancelToken?: CancelToken): Promise<any> => {
        return this.post<any, void>(`${config.apiRoutes.TRANSLATE}`, translateBody, cancelToken);
    };
}

export default new FavoriteAPI(); 
