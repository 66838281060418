import classNames from "classnames";
import PhotoBrowser from "features/common/components/PhotoBrowser";
import PhotoModal from "features/common/components/PhotoModal/PhotoModal";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import AdditionalInfoPart from "features/offers/components/OfferDetails/AdditionalInfoPart";
import AmenitiesPart from "features/offers/components/OfferDetails/AmenitiesPart";
import DetailsActionsContainer from "features/offers/components/OfferDetails/DetailsActionsContainer";
import GeneralInfoPart from "features/offers/components/OfferDetails/GeneralInfoPart";
import { MapWithInfo } from "features/offers/components/OfferDetails/MapMarker";
import PaymentsModalPart from "features/offers/components/OfferDetails/PaymentModalPart";
import RequirementsPart from "features/offers/components/OfferDetails/RequirementsPart";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import EmployeeInfoPart from "./EmployeeInfoPart";
import EmploymentConditionsPart from "./EmploymentConditionsPart";
import styles from "./styles.module.scss";
import { useTranslate } from "../../../../../hooks/useTranslate";

export interface Props {
    offerDetails: EmployeeOfferDetails;
    isUserLoggedIn?: boolean;
    offerDetailsLoading: boolean;
    actionsBox?: React.ReactElement;
    myOfferActionBox?: React.ReactElement;
    ["data-testid"]?: string;
}

const OfferDetails = ({
    offerDetails,
    isUserLoggedIn,
    offerDetailsLoading,
    actionsBox,
    myOfferActionBox,
    "data-testid": testId,
}: Props) => {
    const deviceClass = useDeviceClass();
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const toggleModal = () => setShowPhotoModal(!showPhotoModal);
    const isMatches = useMediaQuery('(max-width: 1439px)');
    const hideRequirementsSection = () => offerDetails?.requirements && offerDetails.requirements.every((el) => el.value === null || el.valueArray?.length === 0);
    const urlParams = new URLSearchParams(window.location.search);
    const [fenigeId] = useState(urlParams.get('fenige_id'));
    const { translatedText, handleTranslate, translationIcon } = useTranslate(offerDetails.additionalInfo);
    const additionalInfo = translatedText || offerDetails.additionalInfo;

    if (!offerDetails) {
        return null;
    }

    return (
        <div data-testid={`${testId}__offer-details`}>

            {(deviceClass == 'desktop' && isMatches) && (
                <div className={styles["employee-offer-details__gallery-display"]}>
                    <div className={styles["employee-offer-details__photo-browser-container"]}>
                        {!showPhotoModal ?
                            <PhotoBrowser offerDetails={offerDetails} photoUrls={offerDetails.images || []} onClick={toggleModal} />
                            :
                            <PhotoModal
                                onClick={toggleModal}
                                photoUrls={offerDetails.images || []}
                                data-testid="main-section__main-photo-modal"
                            />}
                    </div>
                </div>
            )}
            <div className={styles["employee-offer-details__general-info-container"]}>
                <GeneralInfoPart
                    offerDetails={offerDetails}
                    isUserLoggedIn={!!isUserLoggedIn}
                />
            </div>
            <div
                className={classNames(
                    styles["employee-offer-details__container"],
                    styles["employee-offer-details__top-container"]
                )}
            >
                <div className={styles["employee-offer-details__amentities-and-employee-container"]}>
                    {!!offerDetails.amenities?.length && (
                        <div className={styles["employee-offer-details__amenities"]}>
                            <h3 className={styles["employee-offer-details__title"]}>
                                <FormattedMessage id="employee-offer-details__amenities" />
                            </h3>
                            <AmenitiesPart amenities={offerDetails.amenities} />
                        </div>
                    )}
                    <EmployeeInfoPart offerDetails={offerDetails} isUserLoggedIn={isUserLoggedIn} />
                </div>
                <div className={styles["employee-offer-details__actions"]}>
                    <DetailsActionsContainer
                        detailsLoading={offerDetailsLoading}
                        jobOfferDetails={offerDetails}
                        isUserLoggedInWithProfile={!!isUserLoggedIn}
                        isMyJobOfferActionContainer={!!myOfferActionBox}
                        isEmployeeOffer
                    >
                        {myOfferActionBox ? myOfferActionBox : actionsBox ? actionsBox : <></>}
                    </DetailsActionsContainer>
                </div>
            </div>

            <div className={styles["employee-offer-details__employment-conditions"]}>
                <EmploymentConditionsPart offerDetails={offerDetails} />
            </div>
            {((offerDetails.jobExperience && !!offerDetails.jobExperience?.length) || (offerDetails.languageRequirements && !!offerDetails.languageRequirements?.length) || !hideRequirementsSection()) && (
                <div
                    data-testid={`${testId}__requirements`}
                    className={styles["employee-offer-details__requirements-container"]}
                >
                    <h2 className={styles["employee-offer-details__title"]}>
                        <FormattedMessage id="employee-offer-details__additional-employee-information" />
                    </h2>
                    <RequirementsPart
                        requirements={offerDetails.requirements}
                        requirementLanguages={offerDetails.languageRequirements}
                        jobExperience={offerDetails.jobExperience}
                    />
                </div>
            )}
            <div className={styles["employee-offer-details__additional-info-and-localization"]}>
                {!!offerDetails.additionalInfo && (
                    <div className={styles["employee-offer-details__additional-info"]}>
                        <div className={styles["employee-offer-details__additional-info-inner"]}>
                            <h3 className={styles["employee-offer-details__title"]}>
                                <FormattedMessage id={"employee-offer-details__additional-info"}/>
                                <div onClick={handleTranslate}>
                                    {translationIcon}
                                </div>
                            </h3>
                            <AdditionalInfoPart additionalInfo={additionalInfo} />
                        </div>
                    </div>
                )}
                <div className={styles["employee-offer-details__localization"]}>
                    <MapWithInfo
                        location={offerDetails.location}
                    />
                </div>
            </div>
            <p className={styles['employee-offer-details__offer-number-text']}><FormattedMessage id='common__nr-offer' /> {offerDetails.id}</p>
            {fenigeId && (
                <PaymentsModalPart fenigeId={fenigeId} />
            )}
        </div>
    );
};

export default OfferDetails;
