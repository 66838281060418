import React from "react";
import { useField } from "formik";

import CheckBoxRequirementComponent from "features/jobOffers/components/CreateJobOffer/RequirementsStep/CheckBoxRequirement";
import { RequirementListOption } from "features/jobOffers/types";

export interface Props {
    name: string;
    header: string;
    options: { [id: string]: RequirementListOption };
    hasColumns: boolean;
    singleValueOnly?: boolean;
}

const CheckBoxRequirement = ({ name, header, options, hasColumns, singleValueOnly }: Props) => {
    const [fieldProps, , helperProps] = useField<string[]>(name);

    return (
        <CheckBoxRequirementComponent
            data-testid={`requirements-step__${name}`}
            header={header}
            valueInputProps={fieldProps}
            helperProps={helperProps}
            options={options}
            hasColumns={hasColumns}
            singleValueOnly={singleValueOnly}
        />
    );
};

export default CheckBoxRequirement;
