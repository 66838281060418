import { CardDto } from "features/common/dtos";
import mapUtcStringToLocalDate from "features/common/mappers/mapUtcStringToLocalDate";
import { Card, CardType, DictionaryItem, Image } from "features/common/types";
import { RequirementsStepSchema } from "features/jobOffers/schemas";
import { EducationType, LanguageLevelType } from "features/jobOffers/types";
import {
    CardDetailsDto,
    CardRelatedOffersDto,
    CreateNewCardDto,
    MyApplicationsDto,
    MyProfileCardsDto,
    OfferCreationCardsDto,
} from "features/myProfile/dtos";
import { Application, MyOfferCreationCards, MyProfileCards } from "features/myProfile/models";
import { CardDetailsSchema } from "features/myProfile/schemas";
import { CardOwnerRole, CardOwnerType, CardRelatedOffer } from "features/myProfile/types";
import { OfferStatus } from "features/offers/types";
import { PhotoDto } from "features/user/dtos";

export const mapFileToFormData = (images: Image[]): FormData => {
    const formData = new FormData();
    formData.append("images", images.length === 1 && images[0].file ? images[0].file : "");

    return formData;
};

export const mapOfferCreationCardsDtoToMyOfferCreationCards = (
    offerCreationCards: OfferCreationCardsDto
): MyOfferCreationCards => {
    return offerCreationCards;
};

export const mapMyProfileCardsDtoToMyOfferCreationCards = (myProfileCards: MyProfileCardsDto): MyProfileCards => ({
    employer: myProfileCards.data
        .filter((card: CardDto) => card.type === "EMPLOYER")
        .map((card: CardDto) => mapCardDtoToCard(card)),
    employee: myProfileCards.data
        .filter((card: CardDto) => card.type === "EMPLOYEE")
        .map((card: CardDto) => mapCardDtoToCard(card)),
});

export const mapCardDtoToCard = (card: CardDto, branches?: DictionaryItem[]): Card => ({
    branchCode: card.branchId,
    branchText: branches?.find((branch) => branch.id === card.branchId)?.name,
    companyName: card.business_name,
    firstName: card.firstname,
    lastName: card.lastname,
    id: `${card.id}`,
    krazCert: card.kraz_certificate_number,
    phoneNumber: card.phone_number,
    phonePrefix: card.phone_number_prefix,
    logo: card.logo,
    mainPhoto: card.main_photo,
    photos: card.images,
    locationDTO: card.locationDTO,
    street: card.locationDTO?.street,
    number: card.locationDTO?.number,
    city: card.locationDTO?.city,
    country: card.locationDTO?.country,
    taxIdentificationNumber: card.tax_identification_number,
    type: mapToCardType(card.isPersonal ? "private" : "company", card.type),
    languageRequirements: card.languageRequirements?.map((item) => ({
        languageType: item.name,
        languageLevelType: item.level,
        value: null,
    })),
    jobExperience: card.jobExperience?.map((item) => ({
        id: item.id,
        companyName: item.companyName,
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
        description: item.description,
        position: item.position,
        isActive: item.isActive
    })),
    requirements: card.requirements,
    description: card.description,
    email: card.email,
    isFavoriteEmployee: card.isFavoriteEmployee,
    isFavoriteEmployer: card.isFavoriteEmployer
});

export const mapToCardType = (cardOwnerType: CardOwnerType, cardOwnerRole: CardOwnerRole): CardType => {
    if (cardOwnerType === "company" && cardOwnerRole === "EMPLOYEE") {
        return CardType.EmployeeCompany;
    }

    if (cardOwnerType === "company" && cardOwnerRole === "EMPLOYER") {
        return CardType.EmployerCompany;
    }

    if (cardOwnerType === "private" && cardOwnerRole === "EMPLOYER") {
        return CardType.EmployerPrivate;
    }

    return CardType.EmployeePrivate;
};

export const mapCardDetailsDtoToCard = (dto: CardDetailsDto): Card | undefined => mapCardDtoToCard(dto.data);

export const mapCardDetailsFormValuesToDto = (
    formValues: CardDetailsSchema,
): Partial<CardDto> => ({
    branchId: formValues.branch,
    business_name: formValues.companyName,
    kraz_certificate_number: formValues.krazCert,
    phone_number: formValues.phoneNumber,
    phone_number_prefix: formValues.phonePrefix,
    placeId: formValues?.location?.placeId,
    street_name: formValues.street,
    street_number: formValues.streetNumber,
    tax_identification_number: formValues.nip,
    email: formValues.email,
    firstname: formValues.firstName,
    lastname: formValues.lastName,
});

export const mapRelatedOffersDtoToRelatedOffers = (dto: CardRelatedOffersDto): CardRelatedOffer[] =>
    dto.data.content.map((offer) => ({
        id: offer.id,
        unreadMessagesCount: 0,
        unreadApplicationsCount: 0,
        status:
            offer.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : offer.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
        position: offer.position,
        imageUrl: offer.mainImage,
        location: offer.location,
        publishDate: mapUtcStringToLocalDate(offer.addedDate),
        expirationDate: mapUtcStringToLocalDate(offer.expirationDate),
        financialConditions: offer.financialConditions,
    }));

export interface CreateNewCardValues {
    personal?: CardOwnerType;
    type?: CardOwnerRole;
    businessName?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    phonePrefix?: string;
    city?: {
        tags: any[],
        input: string,
    };
}

export const mapCreateNewCardValuesToNewCardDto = (
    dto: CreateNewCardValues,
    placeId: string
): CreateNewCardDto => ({
    personal: dto.personal === "private",
    isPersonal: dto.personal === "private",
    type: dto.type as CardOwnerRole,
    business_name: dto.personal === "private" ? undefined : dto.businessName,
    firstname: dto.personal === "private" ? dto.firstName : undefined,
    lastname: dto.personal === "private" ? dto.lastName : undefined,
    placeId,
    phone_number: dto.personal === "private" ? dto.phoneNumber : undefined,
    phone_number_prefix: dto.personal === "private" ? dto.phonePrefix : undefined,
});

export const mapMyApplicationsDtoToApplicationsList = (dto: MyApplicationsDto): Application[] =>
    dto.data.map((applicationDto) => ({
        ...applicationDto,
        applicationDate: new Date(applicationDto.applicationDate),
        offerOwnerName: `${applicationDto.offerOwner.firstname} ${applicationDto.offerOwner.lastname}`,
    }));

export const mapPhotoDtosToImagesList = (dto: PhotoDto[]): string[] => dto.map((photo) => photo.fileName);

export const mapCardRequirementsToCardDtoPartial = (values: RequirementsStepSchema): Partial<CardDto> => {
    const requirements = [];

    if (values.certificates.length) {
        requirements.push({
            requirementType: "CERTIFICATES_AND_LICENSES",
            valueArray: values.certificates.map((item: any) => item.value),
        });
    }

    if (values.programs.length) {
        requirements.push({
            requirementType: "COMPUTER_AND_SOFTWARE",
            valueArray: values.programs.map((item: any) => item.value),
        });
    }

    if (values.other.length) {
        requirements.push({
            requirementType: "OTHER",
            valueArray: values.other.map((item: any) => item.value),
        });
    }

    if (values.permissions.length) {
        requirements.push({
            requirementType: "PERMISSIONS",
            valueArray: values.permissions.map((item: any) => item.value),
        });
    }

    return {
        jobExperience: values.jobExperience?.map((item) => ({
            id: item.id,
            companyName: item.companyName,
            dateFrom: item.startDate,
            dateTo: item.endDate || new Date(),
            description: item.additionalInfo,
            position: item.position,
        })),
        languageRequirements:
            values.languages
                ?.filter((language) => !!language.isChecked)
                .map((language) => ({
                    level: language.level || LanguageLevelType.Beginner,
                    name: language.id,
                })) || [],
        requirements: [
            ...requirements,
            ...values.experience.map((value) => ({
                requirementType: "EXPERIENCE",
                value: value || "",
            })),
            ...values.drivingLicenses.map((value) => ({
                requirementType: "DRIVING_LICENSE",
                value: value || "",
            })),
            ...values.education
                .filter((item) => !!item.isChecked)
                .map((value) =>
                    value.id === EducationType.Other
                        ? {
                            requirementType: "EDUCATION_CUSTOM",
                            value: value.otherValue || "",
                        }
                        : {
                            requirementType: "EDUCATION",
                            value: value.id?.toUpperCase() || "",
                        }
                ),
        ],
    };
};


export const mapCardsAfterDelete = (myProfileCards: MyProfileCards | undefined, cardId: string): MyProfileCards | undefined => {
    if (myProfileCards === undefined) {
        return undefined;
    }
    return {
        employer: myProfileCards.employer
            .filter((card: Card) => card.id !== cardId),
        employee: myProfileCards.employee
            .filter((card: Card) => card.id !== cardId)
    }
};