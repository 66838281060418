import classNames from "classnames";
import { Direction } from "features/common/components/DropdownSelectionProvider";
import { DropdownOption, TranslatedSectionDropdownOption } from "features/common/types";
import React, { useEffect } from "react";
import styles from "./styles.module.scss";

export type Props<T> = {
    id: string;
    sections: TranslatedSectionDropdownOption<T>[];
    searchTerm?: string;
    shouldEmphasizeSearchTerm?: boolean;
    lastDirection: Direction;
    selectedOption?: DropdownOption<T>;
    fieldOptionsClassName?: string;
    onSelectOption: (option: DropdownOption<T>, section: string) => void;
    ["data-testid"]?: string;
};

const DropdownOptionsWithSection = <T extends any>({
    id,
    sections,
    searchTerm,
    shouldEmphasizeSearchTerm,
    selectedOption,
    lastDirection,
    fieldOptionsClassName,
    onSelectOption,
    "data-testid": testId,
}: Props<T>) => {
    const containerRef = React.createRef<HTMLDivElement>();
    const selectedOptionRef = React.createRef<HTMLDivElement>();

    const onOptionClick = (section: string, option: DropdownOption<T>) => {
        onSelectOption && onSelectOption(option, section);
    };

    const renderSearchTerm = (option: DropdownOption<T>) => {
        if (!searchTerm) return;

        let term: string = searchTerm.toLowerCase().replace(/\s{2,}/g, " ");

        if (option.display) {
            const displayOption = option.display.toString();
            const indexOfTerm = displayOption.toLowerCase().indexOf(term);
            if (indexOfTerm < 0) {
                return (
                    <>
                        <span>{option.display}</span>
                        {option.additionalData && (
                            <span className={styles["search-input__option-additional-data"]}>
                                {option.additionalData}
                            </span>
                        )}
                    </>
                );
            }

            const before = displayOption.slice(0, indexOfTerm);
            const actual = displayOption.slice(indexOfTerm, indexOfTerm + term.length);
            const after = displayOption.slice(indexOfTerm + term.length);

            return (
                <>
                    <span>{before}</span>
                    <span className={styles["search-input__option-bold-term"]}>{actual}</span>
                    <span>{after}</span>
                    {option.additionalData && (
                        <span className={styles["search-input__option-additional-data"]}>{option.additionalData}</span>
                    )}
                </>
            );
        }
    };

    useEffect(() => {
        if (!selectedOptionRef.current || !containerRef.current) {
            return;
        }

        const containerScroll = containerRef.current?.scrollTop;
        const containerHeight = containerRef.current?.offsetHeight;
        const optionTop = selectedOptionRef.current?.offsetTop;
        const optionHeight = selectedOptionRef.current?.offsetHeight;

        const optionBottom = optionTop + optionHeight;
        if (optionBottom > containerHeight + containerScroll || optionTop < containerScroll) {
            if (lastDirection === "down") {
                containerRef.current?.scrollTo(0, optionBottom - containerHeight);
            } else if (lastDirection === "up") {
                containerRef.current?.scrollTo(0, optionTop);
            }
        }
    }, [containerRef, lastDirection, selectedOptionRef]);

    if (sections.length === 0) {
        return <></>;
    }

    return (
        <div
            data-testid={`${testId}__options`}
            id={id}
            ref={containerRef}
            className={classNames(
                styles["search-input__options"],
                styles["search-input__field-options"],
                fieldOptionsClassName
            )}
        >
            {sections.map(({ section, options }, i) => (
                <React.Fragment key={i}>
                    <div className={styles["search-input__section"]}>
                        <label data-testid={`${testId}__label`}>{section.display ?? section.value}</label>
                    </div>
                    {options.map((option, j) => (
                        <div
                            data-testid={`${testId}__option`}
                            className={classNames(
                                styles["search-input__option"],
                                styles["search-input__field-option"],
                                {
                                    [styles["search-input__option--keyboard-hover"]]: option === selectedOption,
                                }
                            )}
                            ref={option === selectedOption ? selectedOptionRef : undefined}
                            key={j}
                            onClick={() => onOptionClick(section.value, option)}
                        >
                            {shouldEmphasizeSearchTerm
                                ? renderSearchTerm(option)
                                : option.display ?? (option.value as any)}
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
};

export default DropdownOptionsWithSection;
