/* eslint-disable max-lines */
import { SalaryType } from "features/common/models";
import { FileType, SectionName, WorkType } from "features/common/types";
import { IntlShape } from "react-intl";

export const getMultipleFilesErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "dropzone-file-uploader__multiple-files-error",
    });

export const getFileTooBigErrorMessage = (intl: IntlShape, maxSize?: number) =>
    maxSize
        ? intl.formatMessage(
            {
                id: "dropzone-file-uploader__file-too-big-error",
            },
            {
                size: Math.floor(maxSize / 1000000),
            }
        )
        : "";

export const getWrongFileTypeErrorMessage = (intl: IntlShape, allowedTypes?: FileType[]) =>
    allowedTypes
        ? intl.formatMessage(
            {
                id: "dropzone-file-uploader__wrong-file-type-error",
            },
            {
                extensions: allowedTypes.join(", "),
            }
        )
        : "";

export const getFileAmountMessage = (intl: IntlShape, amount: number) =>
    amount
        ? intl.formatMessage(
            {
                id: "dropzone-file-uploader__files-amount",
            },
            {
                amount: amount,
            }
        )
        : "";

export const getPositionIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "search__positions",
    });

export const getCompanyIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "search__companies",
    });

export const getIndustryIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "search__branches",
    });

export const getCityIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "search__city",
    });

export const getRegionIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "search__region",
    });

export const getCancelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__cancel",
    });

export const getSaveMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__save",
    });

export const getSendMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__send",
    });

export const getSelectMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__select",
    });

export const getOkMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__ok",
    });

export const getRequiredMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "form__field-required",
    });

export const getMaxLengthMessage = (intl: IntlShape, maxLength: number) =>
    intl.formatMessage(
        {
            id: "form__field-max-length",
        },
        {
            maxLength,
        }
    );

export const getSectionDisplayNameLookup = (intl: IntlShape) => {
    const city = getCityIntlMessage(intl);
    const region = getRegionIntlMessage(intl);
    const country = getCountryMessage(intl);
    const position = getPositionIntlMessage(intl);
    const company = getCompanyIntlMessage(intl);
    const industry = getIndustryIntlMessage(intl);

    return (sectionName: SectionName) => {
        switch (sectionName) {
            case SectionName.Cities:
                return city;
            case SectionName.Regions:
                return region;
            case SectionName.Countries:
                return country;
            case SectionName.Positions:
                return position;
            case SectionName.Company:
                return company;
            case SectionName.Branches:
                return industry;
            default:
                return sectionName;
        }
    };
};

export const getCurrencyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__currency",
    });

export const getDatepickerErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "datepicker__end-before-start-error",
    });

export const getStringLengthWarningMessage = (intl: IntlShape, maxSize?: number) =>
    maxSize
        ? intl.formatMessage(
            {
                id: "textarea__maximum-size",
            },
            { maxSize: maxSize }
        )
        : "";

export const getCancelWarningMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__cancel-warning",
    });

export const getNavChangeWarningMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__nav-change-warning",
    });

export const getDataLossWarningMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__data-loss-warning",
    });

export const getHomeMessage = (intl: IntlShape) => {
    return intl.formatMessage({
        id: "common__home",
    });
};

export const getMyJobOffersMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__my-job-offers",
    });

export const getMyEmployeeOffersMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__my-employee-offers",
    });

export const getMyMessagesMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__my-messages",
    });

export const getFirstNameMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__first-name",
    });

export const getGenderMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__gender",
    });

export const getBirthdateMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__birthdate",
    });

export const getLastNameMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__last-name",
    });

export const getCountryMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__country",
    });

export const getMyProfileMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__my-profile",
    });

export const getFieldIsRequiredMessage = (intl: IntlShape) => intl.formatMessage({ id: "common__field-is-required" });

export const getJobOffersMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__job-offers",
    });

export const getEmployeeOffersMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__employee-offers",
    });

export const getSearchKeywordMessage = (intl: IntlShape, positionFirm?: boolean) =>
    positionFirm
        ?
        intl.formatMessage({ id: "dashboard__search-input-placeholder-desktop" })
        :
        intl.formatMessage({ id: "dashboard__search-input-placeholder" })
    ;

export const getLocationMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__location"
    });
export const getValueNoLongerAvailable = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__value-no-longer-available-error",
    });

export const getCreateShortDayOfWeekMessage = (intl: IntlShape) => (dayOfWeek: number, short?: boolean) => {
    const mondayMessage = intl.formatMessage({ id: "calendar__monday-short" });
    const tuesdayMessage = intl.formatMessage({ id: "calendar__tuesday-short" });
    const wednesdayMessage = intl.formatMessage({ id: "calendar__wednesday-short" });
    const thursdayMessage = intl.formatMessage({ id: "calendar__thursday-short" });
    const fridayMessage = intl.formatMessage({ id: "calendar__friday-short" });
    const saturdayMessage = intl.formatMessage({ id: "calendar__saturday-short" });
    const sundayMessage = intl.formatMessage({ id: "calendar__sunday-short" });

    let translatedMessage = "";

    switch (dayOfWeek) {
        case 0:
            translatedMessage = mondayMessage;
            break;
        case 1:
            translatedMessage = tuesdayMessage;
            break;
        case 2:
            translatedMessage = wednesdayMessage;
            break;
        case 3:
            translatedMessage = thursdayMessage;
            break;
        case 4:
            translatedMessage = fridayMessage;
            break;
        case 5:
            translatedMessage = saturdayMessage;
            break;
        default:
            translatedMessage = sundayMessage;
            break;
    }

    return short ? translatedMessage.substr(0, 3) : translatedMessage;
};

export const getYesMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__yes",
    });

export const getNoMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__no",
    });

export const getWorkTypeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__work-type",
    });

export const getLocalityMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__locality",
    });

export const getTodayMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__today",
    });

export const getYesterdayMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__yesterday",
    });

export const getOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__offer",
    });

export const getRelatedOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__related-offer",
    });

export const getBranchMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__branch",
    });

export const getCompanyNameMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__company-name",
    });

export const getCompanyCertificateMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__company-certificate",
    });

export const getNipCodeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__nip-code",
    });

export const getChangeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__change",
    });

export const getZipCodeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__zip-code",
    });

export const getPhoneNumberMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__phone-number",
    });

export const getStreetMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__street",
    });

export const getNumberMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__number",
    });

export const getPrivatePersonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__private-person",
    });

export const getWorkLocationMessage = (intl: IntlShape, workLocation: string = "--") => {
    return intl.formatMessage(
        {
            id: "single-offer-card__work-location",
        },
        { workLocation }
    );
};

export const getWorkTypeWithDatesMessage = (
    intl: IntlShape,
    workType?: WorkType,
    startDate?: string,
    endDate?: string
) => {
    return workType
        ? intl.formatMessage(
            {
                id: "single-offer-card__work-type",
                defaultMessage:
                    "{workType, select, workTypes_seasonalTemporary {Seasonal (temporary) work:\nfrom {startDate} to {endDate}} workTypes_steady {Steady work:\nfrom {startDate}} workTypes_odd {Odd work:\nfrom {startDate}}}",
            },
            { workType, startDate, endDate }
        )
        : "";
};

export const getPositionsNumberMessage = (intl: IntlShape, positionsNumber?: number) => {
    return positionsNumber
        ? intl.formatMessage(
            {
                id: "single-offer-card__positions-number",
            },
            { positionsNumber }
        )
        : intl.formatMessage(
            {
                id: "single-offer-card__positions-number",
            },
            { positionsNumber: 0 }
        );
};

export const getSalaryMessage = (intl: IntlShape, salary?: string, salaryType?: SalaryType) => {
    return salary && salaryType
        ? intl.formatMessage(
            {
                id: "single-offer-card__salary",
                defaultMessage: "Salary: {salary}/{salaryType, select, 0 {hr} 1 {mo}}",
            },
            { salary, salaryType }
        )
        : "";
};

export const getBonusMessage = (intl: IntlShape, bonus?: string) => {
    return intl.formatMessage(
        {
            id: "single-offer-card__bonus",
        },
        { bonus }
    );
};

export const getPublishedDateMessage = (intl: IntlShape, publishedDate: string = "--") => {
    return intl.formatMessage(
        {
            id: "offers__published-date",
        },
        { publishedDate }
    );
};

export const getStartWorkDateMessage = (intl: IntlShape, startJobDate: string = "--") => {
    return intl.formatMessage(
        {
            id: "offers__start-job-date",
        },
        { startJobDate }
    );
};

export const getValidUntilMessage = (intl: IntlShape, expirationDate?: string) => {
    return intl.formatMessage(
        {
            id: "offers__offer-valid-until",
        },
        { expirationDate }
    );
};
export const getEndedDateMessage = (intl: IntlShape, expirationDate?: string) => {
    return intl.formatMessage(
        {
            id: "offers__ended-date",
        },
        { expirationDate }
    );
};

export const getNewCardMessage = (intl: IntlShape) => {
    return intl.formatMessage({
        id: "common__adding-new-card",
    });
};

export const getCountryTranslation = (intl: IntlShape, countryCode?: string, defaultMessage?: string) => {
    return intl.formatMessage({
        id: `common__country-${countryCode}`,
        defaultMessage,
    });
};
