/* eslint-disable max-lines */
import { ApiError, CancelToken, createManuallyHandledCancelToken, ManuallyHandledCancelToken } from "api/utils";
import config from "config";
import { emptyGetOffersFetchParams, workTypes } from "features/common/constants";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDebounce from "features/common/hooks/useDebounce";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { Currency } from "features/common/models";
import { DictionaryItem } from "features/common/types";
import { isPageLoaded, replaceCurrentUrl } from "features/common/utils";
import { cookieNames } from "features/cookies/constants";
import { getCookie, setCookie } from "features/cookies/utils";
import { useOffersWithParams } from "features/dashboard/useOffers";
import {
    mapEmployeeOffersFetchParamsToQueryParameters,
    mapEmployeeOffersQuerySearchToFetchParams,
} from "features/employeeOffers/mappers";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import FavoriteHeaderNavigation from "features/favorite/components/FavoriteHeaderNavigation";
import {
    mapJobOffersFetchParamsToQueryParameters,
    mapJobOffersQuerySearchToFetchParams,
} from "features/jobOffers/mappers";
import { JobOffersFetchParams } from "features/jobOffers/types";
import SearchBox from "features/offers/components/SearchBox";
import { FinancialConditionsSlimDTO, LocationSlimDTO } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import OffersList from "./List";
import styles from "./styles.module.scss";

export type Params = {
    userId?: string;
    businessCardId?: string;
};

export type Props = {
    asFavoriteOffersList?: boolean;
    asJobOffersList?: boolean;
    jobOfferDetails?: any;
    branches?: DictionaryItem[];
    branchesLoading?: boolean;
    branchesLoadingError?: ApiError;
    isUserLoggedIn?: boolean;
    currencies?: Currency[];
    currenciesLoading?: boolean;
    currenciesLoadingError?: ApiError;
    getBranchesAsync: (cancelToken?: CancelToken) => void;
    getCurrenciesAsync: (cancelToken?: CancelToken) => void;
    financialConditionsSlimDTO?: FinancialConditionsSlimDTO;
    locationSlimDto?: LocationSlimDTO;
    branchDTO?: { id?: number; name?: string };
};

interface HistoryLocationState {
    cameBack?: boolean;
}

const Offers = ({
    asFavoriteOffersList,
    asJobOffersList,
    branches,
    branchesLoading,
    branchesLoadingError,
    isUserLoggedIn,
    currencies,
    currenciesLoading,
    currenciesLoadingError,
    getBranchesAsync,
    getCurrenciesAsync,
}: Props) => {
    const deviceClass = useDeviceClass();
    const history = useHistory();
    const [offersOperationsPending, setOffersOperationsPending] = useState(true);
    const debouncedOffersLoading = useDebounce(offersOperationsPending, 0);
    const [active, setActive] = useState(asFavoriteOffersList ? '1' : null);
    const createCancelToken = useCreateCancelToken();
    const getJobOffersCancelToken = useRef<ManuallyHandledCancelToken>();
    const getEmployeeOffersCancelToken = useRef<ManuallyHandledCancelToken>();
    const [cancelToken, setCancelToken] = useState<CancelToken>();
    const currentPageCookie: string = getCookie(cookieNames.CURRENT_PAGE);
    const historyLocationState = history.location.state as HistoryLocationState
    const cameBack = historyLocationState?.cameBack
    const [currentPage, setCurrentPage] = useState(currentPageCookie ? +currentPageCookie : 1);
    const initialParams = {
        ...emptyGetOffersFetchParams,
        offset: (deviceClass === 'desktop') ? currentPage : 1,
        pageLimit: (deviceClass === 'desktop') ? config.offersResultsPerPage : (config.offersResultsPerPage * currentPage),
        isFavorite: !!asFavoriteOffersList
    }
    const [fetchParams, setFetchParams] = useState<JobOffersFetchParams | EmployeeOffersFetchParams | null>(null);
    const [fetchParamsChangedFlag, setFetchParamsChangedFlag] = useState(false);
    const [lastQuerySearch, setLastQuerySearch] = useState<string>('');

    // get offers related data
    const useOffersWithParamsSecondParameter = useMemo(() => {
        if (asFavoriteOffersList) {
            if (active === '1') {
                return true;
            } else if (active === '2') {
                return false;
            }
        } else {
            return asJobOffersList;
        }
    }, [active, asFavoriteOffersList, asJobOffersList])
    const { offers = [], totalOffers = undefined, loading, error = undefined, filterMatchCounts = undefined, resetState } = useOffersWithParams(fetchParams, useOffersWithParamsSecondParameter, active, cancelToken);
    useEffect(() => {
        setCookie(cookieNames.CURRENT_PAGE, currentPage, { path: '/' })
    }, [currentPage])

    useEffect(() => {
        const currentPageCookie: string = getCookie(cookieNames.CURRENT_PAGE);
        if (currentPageCookie && cameBack) {
            setCurrentPage(+currentPageCookie)
        }
    }, [cameBack, currentPage]);

    const mapOffersFetchParamsToQueryParameters = useCallback(
        (params: JobOffersFetchParams | EmployeeOffersFetchParams, withPaginationParams?: boolean): string => {
            return asJobOffersList
                ? mapJobOffersFetchParamsToQueryParameters(params as JobOffersFetchParams, withPaginationParams)
                : mapEmployeeOffersFetchParamsToQueryParameters(
                    params as EmployeeOffersFetchParams,
                    withPaginationParams
                );
        },
        [asJobOffersList]
    );

    useEffect(() => {
        if (fetchParams) {
            setLastQuerySearch(mapOffersFetchParamsToQueryParameters(fetchParams, false))

        }
    }, [fetchParams, mapOffersFetchParamsToQueryParameters])

    const executeApiCallConditionallyBasedOnMode = useCallback(
        <TJobOffersParams extends any, TEmployeeOffersParams extends any>(
            jobOffersFunctionParams: TJobOffersParams,
            employeeOffersFunctionParams: TEmployeeOffersParams,
            jobOffersFunction?: (params: TJobOffersParams, cancelToken?: CancelToken) => unknown,
            employeeOffersFunction?: (params: TEmployeeOffersParams, cancelToken?: CancelToken) => unknown
        ) => {
            if (asJobOffersList && jobOffersFunction) {
                jobOffersFunction(jobOffersFunctionParams, createCancelToken());
            } else if (!asJobOffersList && employeeOffersFunction) {
                employeeOffersFunction(employeeOffersFunctionParams, createCancelToken());
            }
        },
        [asJobOffersList, createCancelToken]
    );

    const resetPaginationState = useCallback(() => {
        setCurrentPage(1);
    }, []);

    const setCurrentOffersPage = useCallback(
        (newPage: number) => {
            currentPage !== newPage && setCurrentPage(newPage);
            if (fetchParams && newPage !== fetchParams.offset) {
                replaceCurrentUrl(
                    `${asJobOffersList ? appRoutes.jobOffers : appRoutes.employeeOffers
                    }${mapOffersFetchParamsToQueryParameters({ ...fetchParams, offset: (deviceClass === 'smartphone' || deviceClass === 'tablet') ? 1 : newPage, pageLimit: (deviceClass === 'smartphone' || deviceClass === 'tablet') ? newPage * config.offersResultsPerPage : fetchParams.pageLimit }, false)}`
                );
            }

            deviceClass === "desktop" && window.scrollTo(0, 0);
        },
        [asJobOffersList, currentPage, deviceClass, fetchParams, mapOffersFetchParamsToQueryParameters]
    );

    const onFetchParamsChange = useCallback(
        (params: JobOffersFetchParams | EmployeeOffersFetchParams) => {
            setFetchParams(params);
            !fetchParamsChangedFlag && setFetchParamsChangedFlag(true);
            replaceCurrentUrl(
                `${asJobOffersList ? appRoutes.jobOffers : appRoutes.employeeOffers}${asJobOffersList
                    ? mapJobOffersFetchParamsToQueryParameters(params as JobOffersFetchParams, false)
                    : mapEmployeeOffersFetchParamsToQueryParameters(params as EmployeeOffersFetchParams, false)
                }`
            );
        },
        [asJobOffersList, fetchParamsChangedFlag, currentPage]
    );
    useEffect(() => {
        if (branches === undefined && !branchesLoading && !branchesLoadingError) {
            getBranchesAsync(createCancelToken());
        }
    }, [branches, branchesLoading, branchesLoadingError, createCancelToken, getBranchesAsync]);

    useEffect(() => {
        if (currencies === undefined && !currenciesLoading && !currenciesLoadingError) {
            getCurrenciesAsync(createCancelToken());
        }
    }, [createCancelToken, currencies, currenciesLoading, currenciesLoadingError, getCurrenciesAsync]);

    useEffect(() => {
        setOffersOperationsPending(loading || !fetchParamsChangedFlag);
    }, [fetchParamsChangedFlag, loading]);

    useEffect(() => {
        const unlisten = history.listen((listener) => {
            if (
                !listener.search &&
                listener.pathname === (asJobOffersList ? appRoutes.jobOffers : appRoutes.employeeOffers)
            ) {
                onFetchParamsChange({
                    ...emptyGetOffersFetchParams,
                    offset: initialParams?.offset,
                    pageLimit: initialParams?.pageLimit,
                    isFavorite: !!asFavoriteOffersList
                });
                window.scrollTo(0, 0);
            }
        });

        return () => unlisten();
    }, [asFavoriteOffersList, asJobOffersList, history, onFetchParamsChange, currentPage]);

    useEffect(() => {
        setFetchParams((prevState) => {
            if (prevState) {
                return { ...prevState, offset: deviceClass === 'desktop' ? currentPage : 1, pageLimit: (deviceClass === 'smartphone' || deviceClass === 'tablet') ? currentPage * config.offersResultsPerPage : initialParams.pageLimit }
            } else {
                return { ...initialParams, offset: deviceClass === 'desktop' ? currentPage : 1 }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageCookie, currentPage]);

    useEffect(() => {
        const querySearch = history.location.search;
        if (querySearch && currencies && branches) {
            onFetchParamsChange({
                ...initialParams,
                ...(asJobOffersList
                    ? mapJobOffersQuerySearchToFetchParams(
                        querySearch,
                        fetchParams as JobOffersFetchParams,
                        currencies,
                        branches,
                        deviceClass !== "desktop"
                    )
                    : mapEmployeeOffersQuerySearchToFetchParams(
                        querySearch,
                        fetchParams as EmployeeOffersFetchParams,
                        currencies,
                        branches,
                        deviceClass !== "desktop"
                    )),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies, branches, currentPage]);

    useEffect(() => {
        if (filterMatchCounts === undefined) {
            executeApiCallConditionallyBasedOnMode(
                fetchParams as JobOffersFetchParams,
                fetchParams as EmployeeOffersFetchParams,
            );
        }
    }, [asJobOffersList, executeApiCallConditionallyBasedOnMode, fetchParams, filterMatchCounts]);

    useEffect(() => {
        const currentQuerySearch = fetchParams ? (asJobOffersList
            ? mapJobOffersFetchParamsToQueryParameters(fetchParams as JobOffersFetchParams, false)
            : mapEmployeeOffersFetchParamsToQueryParameters(fetchParams as EmployeeOffersFetchParams, false)) : '';
        const filtersOrSearchChanged = lastQuerySearch !== currentQuerySearch;

        const areOffersAtOffsetLoaded = fetchParams ? isPageLoaded(fetchParams.pageLimit, fetchParams.offset, offers) : false;
        setLastQuerySearch(currentQuerySearch);
        if (
            !error &&
            (!areOffersAtOffsetLoaded || filtersOrSearchChanged) &&
            (fetchParamsChangedFlag || !history.location.search)
        ) {
            if (loading) {
                if (asJobOffersList) {
                    getJobOffersCancelToken.current?.cancel();
                } else {
                    getEmployeeOffersCancelToken.current?.cancel();
                }
            }
            const cancelToken = createManuallyHandledCancelToken();
            if (asJobOffersList) {
                getJobOffersCancelToken.current = cancelToken;
            } else {
                getEmployeeOffersCancelToken.current = cancelToken;
            }

            setCancelToken(cancelToken.token);
            !fetchParamsChangedFlag && setFetchParamsChangedFlag(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        asJobOffersList,
        fetchParams,
        error,
        fetchParamsChangedFlag,
    ]);

    return (
        <>
            {(asFavoriteOffersList && active) ? (
                <FavoriteHeaderNavigation onClick={resetState} setActive={setActive} active={active} />
            ) : (
                <div className={styles["offers__search-box"]}>
                    {fetchParams && (
                        <SearchBox
                            asJobOffersSearch={asJobOffersList}
                            fetchParams={fetchParams}
                            onFetchParamsChange={onFetchParamsChange}
                            resetPaginationState={resetPaginationState}
                        />
                    )}
                </div>
            )}
            {/*@ts-ignore*/}
            <OffersList
                asFavoriteEmployeeOrEmployer={active === '3' || active === '4'}
                asJobOffersList={asJobOffersList || active === '1'}
                asFavoriteOffersList={asFavoriteOffersList}
                fetchParams={fetchParams}
                offers={offers}
                totalOffers={totalOffers ?? 0}
                offersLoading={debouncedOffersLoading}
                offersLoadingError={(error && offers.length === 0 && !debouncedOffersLoading)}
                branches={branches}
                branchesLoading={branchesLoading}
                workTypes={workTypes}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
                filterMatchCounts={filterMatchCounts}
                isFiltersLoadingError={
                    !!branchesLoadingError || !!currenciesLoadingError
                }
                isUserLoggedIn={isUserLoggedIn}
                onFetchParamsChange={onFetchParamsChange}
                currentPage={currentPage}
                setCurrentPage={setCurrentOffersPage}
                resetPaginationState={resetPaginationState}
            />
        </>
    );
};

export default Offers;
