import { ApiError } from "api/utils";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import DetailsActionsBox from "features/jobOffers/components/MyJobOfferDetails/DetailsActionsBox";
import { JobOfferDetails } from "features/jobOffers/models";
import OfferDetails from "features/offers/components/OfferDetails";
import React from "react";

interface Props {
    id: number;
    jobOfferDetails: JobOfferDetails;
    jobOfferDetailsLoading: boolean;
    jobOfferDetailsLoadingError?: ApiError;
    isUserLoggedInWithProfile?: boolean;
    resetOfferDetails?: () => void;
}

const MyJobOfferDetailsPreview = ({
    id,
    jobOfferDetails,
    jobOfferDetailsLoading,
    isUserLoggedInWithProfile,
    resetOfferDetails,
}: Props) => {
    const deviceClass = useDeviceClass();

    return (
        <OfferDetails
            id={id}
            offerDetailsLoading={jobOfferDetailsLoading}
            isUserLoggedInWithProfile={isUserLoggedInWithProfile}
            jobOfferDetails={jobOfferDetails}
            hideGeneralInfoPartHeader={deviceClass !== "desktop"}
            actionsBox={
                <DetailsActionsBox jobOfferDetails={jobOfferDetails} data-testid="offer-details" offerId={id} resetOfferDetails={resetOfferDetails} />
            }
            myOfferActionBox={
                <DetailsActionsBox
                    jobOfferDetails={jobOfferDetails}
                    data-testid="offer-details"
                    offerId={id}
                    isTopBox
                />
            }
        />
    );
};

export default MyJobOfferDetailsPreview;
