import { getTranslatedMessages, Locale } from "lang";
import { createIntl, createIntlCache, IntlShape } from "react-intl";

const cache = createIntlCache();

export const getEmployeeMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale: locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__role-employee",
    });
};

export const getEmployerMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale: locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__role-employer",
    });
};

export const getTopicMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "contact-form__topic-placeholder",
    });

export const getPlatformTopicOptionMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__topic-option-platform",
    });
};

export const getBonusTopicOptionMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__topic-option-bonus",
    });
};

export const getComplaintTopicOptionMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__topic-option-complaint",
    });
};

export const getSideEffectsTopicOptionMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__topic-option-sideEffects",
    });
};

export const getHowToTopicOptionMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__topic-option-howTo",
    });
};

export const getOthersTopicOptionMessage = (locale: Locale) => {
    const intl = createIntl(
        {
            locale,
            messages: getTranslatedMessages(locale),
        },
        cache
    );
    return intl.formatMessage({
        id: "contact-form__topic-option-others",
    });
};

export const getMessageTextMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__message",
    });

export const getSendConfirmationHeaderMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "contact-form__send-confirmation-header",
    });

export const getSendConfirmationBodyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "contact-form__send-confirmation-body",
    });
