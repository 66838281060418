import React from "react";
import styles from "./styles.module.scss";
import { Success } from "./components/Success";
import { Failure } from "./components/Failure";
import { useQuery } from "../../../../hooks/useQuery";

const PaymentRedirect = () => {
    const params = useQuery();
    const status = params.get("authenticationStatus");

    const isSuccess = status === "Y" || status === "A";

    return (
        <div className={styles["payment-status"]}>
            {isSuccess && <Success />}
            {!isSuccess && <Failure />}
        </div>
    );
};

export default PaymentRedirect;
