import React, { useCallback, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import classNames from "classnames";

import { CancelToken } from "api/utils";
import Button from "features/common/components/Button";
import { CardDto } from "features/common/dtos";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { drivingOptions, createExperienceOptions, Educations, Languages } from "features/jobOffers/constants";
import { EducationType, Requirement, RequirementLanguage, RequirementType, JobExperience} from "features/jobOffers/types";
import { RequirementsStepSchema } from "features/jobOffers/schemas";
import {
    getRequirementStepDrivingLicenseHeader,
    getRequirementStepEducationHeader,
    getRequirementStepExperienceHeader,
    getRequirementStepExperienceLabel,
    getRequirementStepLanguagesHeader,
    getRequirementStepLanguagesLabel,
    getRequirementStepLicensesAndCertificatesHeader,
    getRequirementStepLicensesAndCertificatesLabel,
    getRequirementStepOtherHeader,
    getRequirementStepOtherLabel,
    getRequirementStepPermissionsHeader,
    getRequirementStepPermissionsLabel,
    getRequirementStepProgramsHeader,
    getRequirementStepProgramsLabel,
} from "features/jobOffers/translationMessages";
import { createRequirementsStepValidationSchema } from "features/jobOffers/validators";
import { mapCardRequirementsToCardDtoPartial } from "features/myProfile/mappers";
import { CardOwnerRole, CardOwnerType } from "features/myProfile/types";

import styles from "./styles.module.scss";
import EducationRequirement from "./EducationRequirement";
import LanguageRequirement from "./LanguageRequirement";
import CheckBoxRequirement from "./CheckBoxRequirement";
import EmployeeExperienceRequirement from "./EmployeeExperienceRequirement";
import CardCreatableRequirement from "./CreatableRequirement";
import { getLocalizedYup } from "features/common/validators";
import { uuid } from "uuidv4";

export interface Props {
    cardKind: CardOwnerRole;
    cardType?: CardOwnerType;
    languageRequirements?: RequirementLanguage[];
    requirements?: Requirement[];
    jobExperienceRequirements?: JobExperience[];
    cardId: string;
    updateMyProfileCardAsync: (cardDetails: Partial<CardDto>, cardId: string, cancelToken?: CancelToken) => void;
}

const RequirementsSection = ({
    cardKind,
    cardType,
    languageRequirements,
    requirements,
    jobExperienceRequirements,
    cardId,
    updateMyProfileCardAsync,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();

    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return createRequirementsStepValidationSchema(localYup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const licenseHeader = getRequirementStepLicensesAndCertificatesHeader(intl);
    const licenseLabel = getRequirementStepLicensesAndCertificatesLabel(intl);
    const programsHeader = getRequirementStepProgramsHeader(intl);
    const programsLabel = getRequirementStepProgramsLabel(intl);
    const otherHeader = getRequirementStepOtherHeader(intl, cardKind);
    const otherLabel = getRequirementStepOtherLabel(intl, cardKind);
    const experienceHeader = getRequirementStepExperienceHeader(intl);
    const experienceLabel = getRequirementStepExperienceLabel(intl);
    const permissionsHeader = getRequirementStepPermissionsHeader(intl);
    const permissionsLabel = getRequirementStepPermissionsLabel(intl);
    const educationHeader = getRequirementStepEducationHeader(intl);
    const drivingLicenseHeader = getRequirementStepDrivingLicenseHeader(intl);
    const languagesHeader = getRequirementStepLanguagesHeader(intl);
    const languagesLabel = getRequirementStepLanguagesLabel(intl);
    const experienceOptions = createExperienceOptions(intl);

    const initialValues = useMemo(() => {
        let initialValues: RequirementsStepSchema = {
            languages: Languages,
            programs: [],
            other: [],
            education: Educations,
            experience: [],
            jobExperience: [],
            permissions: [],
            drivingLicenses: [],
            certificates: [],
        };

        for (let item in RequirementType) {
            const requirementTypeNumber = Number(item);
            if (!isNaN(requirementTypeNumber)) {
                switch (requirementTypeNumber) {
                    case RequirementType.experience:
                        initialValues.experience = requirements
                            ?.filter((r) => r.requirementType === "EXPERIENCE" && !!r.value)
                            .map((r) => r.value || "") || [];
                        break;
                    case RequirementType.education:
                        const selectedEducations = requirements?.filter(
                            (r) =>
                                (r.requirementType === "EDUCATION" || r.requirementType === "EDUCATION_CUSTOM") &&
                                !!r.value
                        );

                        initialValues.education = initialValues.education.map((item) => ({
                            id: item.id,
                            isChecked: !!selectedEducations?.find(
                                (checkedItem) =>
                                    checkedItem.value?.toLowerCase() === item.id?.toLowerCase() ||
                                    (checkedItem.requirementType === "EDUCATION_CUSTOM" &&
                                        item.id === EducationType.Other)
                            ),
                            otherValue:
                                (item.id === EducationType.Other &&
                                    selectedEducations?.find(
                                        (checkedItem) =>
                                            checkedItem.value === item.id ||
                                            (checkedItem.requirementType === "EDUCATION_CUSTOM" &&
                                                item.id === EducationType.Other)
                                    )?.value) ||
                                undefined,
                        }));

                        break;
                    case RequirementType.permissions:
                        const permissionsRequirement = requirements?.find((r) => r.requirementType === "PERMISSIONS");

                        if (permissionsRequirement?.valueArray) {
                            initialValues.permissions = permissionsRequirement.valueArray.map((value) => ({ value, label: value }));
                        }

                        break;
                    case RequirementType.certificates:
                        const certificatesRequirement = requirements?.find(
                            (r) => r.requirementType === "CERTIFICATES_AND_LICENSES"
                        );

                        if (certificatesRequirement?.valueArray) {
                            initialValues.certificates = certificatesRequirement.valueArray.map((value) => ({ value, label: value }));
                        }

                        break;
                    case RequirementType.drivingLicenses:
                        initialValues.drivingLicenses =
                            requirements
                                ?.filter((r) => r.requirementType === "DRIVING_LICENSE" && !!r.value)
                                .map((r) => r.value || "") || [];

                        break;
                    case RequirementType.programs:
                        const programsRequirement = requirements?.find(
                            (r) => r.requirementType === "COMPUTER_AND_SOFTWARE"
                        );

                        if (programsRequirement?.valueArray) {
                            initialValues.programs = programsRequirement.valueArray.map((value) => ({ value, label: value }));
                        }

                        break;
                    case RequirementType.other:
                        const otherRequirement = requirements?.find((r) => r.requirementType === "OTHER");

                        if (otherRequirement?.valueArray) {
                            initialValues.other = otherRequirement.valueArray.map((value) => ({ value, label: value }));
                        }

                        break;
                }
            }
        }

        initialValues.languages = initialValues.languages?.map((language) => ({
            id: language.id,
            isChecked: !!languageRequirements?.find((item) => item.languageType === language.id),
            level: languageRequirements?.find((item) => item.languageType === language.id)?.languageLevelType,
        }));

        initialValues.jobExperience = jobExperienceRequirements?.map((experience) => ({
            id: uuid(),
            companyName: experience.companyName,
            startDate: new Date(experience.dateFrom),
            endDate: new Date(experience.dateTo),
            additionalInfo: experience.description,
            position: experience.position,
            isActive: false,
        }));

        return initialValues;
    }, [languageRequirements, requirements, jobExperienceRequirements]);

    

    const handleSubmitExperience = useCallback(
        (values) => {
            updateMyProfileCardAsync(
                {
                    ...mapCardRequirementsToCardDtoPartial(values),
                    isPersonal: cardType === "private",
                    personal: cardType === "private",
                },
                cardId,
                createCancelToken()
            );
        },
        [cardId, cardType, createCancelToken, updateMyProfileCardAsync]
    );

    return (
        <div className={styles["requirements-section"]}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitExperience}
                validationSchema={validationSchema}
                validateOnMount
            >
                {({ handleSubmit, dirty }) => (
                    <div
                        className={classNames(styles["requirements-section__input-container"], {
                            [styles["requirements-section__dirty-input-container"]]: dirty,
                        })}
                    >
                        <div className={styles["requirements-section__input-left-container"]}>
                            {cardKind === "EMPLOYER" ? (
                                <CheckBoxRequirement
                                    data-testid="requirements-step__experience"
                                    header={experienceHeader}
                                    options={experienceOptions}
                                    hasColumns={false}
                                    name="experience"
                                    singleValueOnly
                                />
                            ) : (
                                <EmployeeExperienceRequirement
                                    data-testid="requirements-step__experience"
                                    header={experienceHeader}
                                    label={experienceLabel}
                                    name="jobExperience"
                                />
                            )}
                            <EducationRequirement
                                data-testid="requirements-step__education"
                                header={educationHeader}
                                name="education"
                            />
                            <CardCreatableRequirement
                                data-testid="requirements-step__permissions"
                                header={permissionsHeader}
                                label={permissionsLabel}
                                name="permissions"
                            />
                            <CardCreatableRequirement
                                data-testid="requirements-step__certificates"
                                header={licenseHeader}
                                label={licenseLabel}
                                name="certificates"
                            />
                        </div>
                        <div className={styles["requirements-section__input-right-container"]}>
                            <CheckBoxRequirement
                                data-testid="requirements-step__driving-license"
                                header={drivingLicenseHeader}
                                options={drivingOptions}
                                hasColumns={true}
                                name="drivingLicenses"
                            />
                            <LanguageRequirement
                                data-testid="requirements-step__languages"
                                header={languagesHeader}
                                label={languagesLabel}
                                name="languages"
                            />
                            <CardCreatableRequirement
                                data-testid="requirements-step__programs"
                                header={programsHeader}
                                label={programsLabel}
                                name="programs"
                            />
                            <CardCreatableRequirement
                                data-testid="requirements-step__other"
                                header={otherHeader}
                                label={otherLabel}
                                name="other"
                            />
                        </div>
                        {dirty && (
                            <Button
                                variant="no-background"
                                className={styles["requirements-section__button"]}
                                onClick={() => handleSubmit()}
                            >
                                <FormattedMessage id="common__save" />
                            </Button>
                        )}
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default RequirementsSection;
