import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as ApplicationAccepted } from "assets/icons/applicationAccepted.svg";
import { ReactComponent as ApplicationDenied } from "assets/icons/applicationDenied.svg";
import { ReactComponent as ApplicationSent } from "assets/icons/applicationSent.svg";
import { ReactComponent as ArrivalAccepted } from "assets/icons/arrivalAccepted.svg";
import { ReactComponent as BonusGiven } from "assets/icons/bonusGiven.svg";
import { ReactComponent as NoApplications } from "assets/icons/noApplications.svg";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import DataTable from "features/common/components/DataTable";
import Pagination from "features/common/components/Pagination";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import ApplicationModal from "features/jobOffers/components/ApplicationModal";
import FilterSelector from "features/messages/components/MyMessages/FilterSelector";
import { getOldestFirstMessage, getRecentFirstMessage } from "features/messages/translationMessages";
import { ApplicationStatus } from "features/messages/types";
import { Application } from "features/myProfile/models";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

const myApplicationsPageSize = 5;

const parseApplicationStatusTranslationId = (status: ApplicationStatus) => {
    switch (status) {
        case ApplicationStatus.ApplicationSent:
            return "common__application-status--sent";
        case ApplicationStatus.ApplicationAccepted:
            return "common__application-status--accepted";
        case ApplicationStatus.ApplicationDenied:
            return "common__application-status--denied";
        case ApplicationStatus.ArrivalAccepted:
            return "common__application-status--arrival-accepted";
        case ApplicationStatus.BonusGiven:
            return "common__application-status--bonus-given";
    }
};

const parseApplicationStatusIcon = (status: ApplicationStatus) => {
    switch (status) {
        case ApplicationStatus.ApplicationSent:
            return <ApplicationSent />;
        case ApplicationStatus.ApplicationAccepted:
            return <ApplicationAccepted />;
        case ApplicationStatus.ApplicationDenied:
            return <ApplicationDenied />;
        case ApplicationStatus.ArrivalAccepted:
            return <ArrivalAccepted />;
        case ApplicationStatus.BonusGiven:
            return <BonusGiven />;
    }
};

export interface Props {
    acceptApplicationSuccess?: boolean;
    rejectApplicationSuccess?: boolean;
    acceptArrivalSuccess?: boolean;
    giveBonusSuccess?: boolean;
    myApplications?: Application[];
    myApplicationsLoading: boolean;
    myApplicationsError?: ApiError;
    getMyApplicationsAsync: (fetchParams: any, cancelToken?: CancelToken) => void;
}

const MyApplications = ({
    acceptApplicationSuccess,
    rejectApplicationSuccess,
    acceptArrivalSuccess,
    giveBonusSuccess,
    myApplications,
    myApplicationsLoading,
    myApplicationsError,
    getMyApplicationsAsync,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const [selectedApplicationId, setSelectedApplicationId] = useState<number | undefined>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [fetchParams, setFetchParams] = useState({ sort: 'desc' });

    const recentFirstMessage = getRecentFirstMessage(intl);
    const oldestFirstMessage = getOldestFirstMessage(intl);

    const [filterList] = useState([
        {
            display: recentFirstMessage,
            value: "recentFirst",
        },
        {
            display: oldestFirstMessage,
            value: "oldestFirst",
        },
    ]);

    const [selectedFilter, setSelectedFilter] = useState(filterList[0]);

    useEffect(() => {
        if (acceptApplicationSuccess || rejectApplicationSuccess || acceptArrivalSuccess || giveBonusSuccess) {
            getMyApplicationsAsync(fetchParams);
        }
    }, [acceptApplicationSuccess, acceptArrivalSuccess, fetchParams, getMyApplicationsAsync, giveBonusSuccess, rejectApplicationSuccess]);

    useEffect(() => {
        getMyApplicationsAsync(fetchParams);
    }, [fetchParams, getMyApplicationsAsync]);

    const goToOfferDetails = useCallback(
        (id) => {
            const clickedApplication = myApplications?.find((application) => application.id === +id);
            if (clickedApplication) {
                history.push(`${appRoutes.jobOffers}/${clickedApplication.jobOfferId}`);
            }
        },
        [history, myApplications]
    );

    const goToApplicationDetails = useCallback(
        (id) => {
            setSelectedApplicationId(id);
        },
        [setSelectedApplicationId]
    );

    const handleCloseModal = useCallback(() => {
        setSelectedApplicationId(undefined);
    }, [setSelectedApplicationId]);

    const handlePageChange = useCallback(
        (page) => {
            setCurrentPage(page);
        },
        [setCurrentPage]
    );

    const handleFilterSelect = useCallback(
        (filterType: string) => {
            const filterMap = {
                "oldestFirst": "asc",
                "recentFirst": "desc"
            } as { [key: string]: string };

            const currentFilter = filterList.find(filter => filter.value === filterType);
            currentFilter && setSelectedFilter(currentFilter);
            setFetchParams({ ...fetchParams, sort: filterMap[filterType] })
        },
        [fetchParams, filterList],
    );

    return (
        <div className={styles["my-applications"]}>
            {myApplicationsLoading ? (
                <SpinnerWithMessage message={<FormattedMessage id="my-applications__loading-applications-list" />} />
            ) : myApplicationsError ? (
                <div className={styles["my-applications__error-container"]}>
                    <FormattedMessage id="my-applications__fetch-failure" />
                </div>
            ) : (
                <>
                    <div className={styles["my-applications__title-container"]}>
                        <FormattedMessage id="my-applications__title" />
                    </div>
                    <div className={styles["my-applications__container"]}>
                        {!!myApplications?.length ? (
                            <>
                                <div className={styles["my-applications__applications-amount"]}>
                                    {intl.formatMessage(
                                        {
                                            id: "my-applications__applications-amount",
                                        },
                                        {
                                            applicationsAmount: myApplications?.length,
                                        }
                                    )}
                                    <FilterSelector
                                        messageFilterOptions={filterList}
                                        selectedFilter={selectedFilter}
                                        handleFilterSelect={handleFilterSelect}
                                    />
                                </div>

                                <DataTable<Application>
                                    className={styles["my-applications__applications-table"]}
                                    data={myApplications.slice(
                                        (currentPage - 1) * myApplicationsPageSize,
                                        currentPage * myApplicationsPageSize
                                    )}
                                    columns={[
                                        {
                                            key: "offerTitle",
                                            label: intl.formatMessage({
                                                id: "my-applications__offer-title",
                                            }),
                                            customRender: (field) => (
                                                <div className={styles["my-applications__offer-title"]}>{field}</div>
                                            ),
                                        },
                                        {
                                            key: "offerOwnerName",
                                            label: intl.formatMessage({
                                                id: "my-applications__offer-owner",
                                            }),
                                            customRender: (field) => (
                                                <div className={styles["my-applications__offer-owner"]}>{field}</div>
                                            ),
                                        },
                                        {
                                            key: "applicationStatus",
                                            label: intl.formatMessage({
                                                id: "my-applications__application-status",
                                            }),
                                            customRender: (field) => (
                                                <div className={styles["my-applications__application-status"]}>
                                                    {parseApplicationStatusIcon(field)}
                                                    {intl.formatMessage({
                                                        id: parseApplicationStatusTranslationId(field),
                                                    })}
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "id",
                                            label: intl.formatMessage({
                                                id: "my-applications__application-number",
                                            }),
                                        },
                                        {
                                            key: "applicationDate",
                                            label: intl.formatMessage({
                                                id: "my-applications__application-date",
                                            }),
                                            customRender: (field) =>
                                                format(field, "d MMM yyyy", { locale: pl }).toUpperCase(),
                                        },
                                        {
                                            actionColumn: true,
                                            actionDisplay: <FormattedMessage id="my-applications__see-application" />,
                                            actionOptions: [
                                                {
                                                    text: intl.formatMessage({ id: "my-applications__offer-details" }),
                                                    callback: goToOfferDetails,
                                                },
                                                {
                                                    text: intl.formatMessage({
                                                        id: "my-applications__application-details",
                                                    }),
                                                    callback: goToApplicationDetails,
                                                },
                                            ],
                                        },
                                    ]}
                                    idColumn="id"
                                />
                                <Pagination
                                    loadedItemsCount={myApplications?.length}
                                    currentPage={currentPage}
                                    totalItemsCount={myApplications?.length}
                                    pageSize={myApplicationsPageSize}
                                    onPageChange={handlePageChange}
                                />
                            </>
                        ) : (
                            <div className={styles["my-applications__applications-empty"]}>
                                <NoApplications />
                                <span className={styles["my-applications__applications-empty-title"]}>
                                    <FormattedMessage id="my-applications__applications-empty-title" />
                                </span>
                                <span className={styles["my-applications__applications-empty-text"]}>
                                    <FormattedMessage id="my-applications__applications-empty-text" />
                                </span>
                            </div>
                        )}
                    </div>
                </>
            )}
            {selectedApplicationId && (
                <ApplicationModal id={selectedApplicationId} handleCloseModal={handleCloseModal} isOfferOwner={false} />
            )}
        </div>
    );
};

export default MyApplications;
