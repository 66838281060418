import FavoriteOffers from "features/favorite/components/FavoriteOffers";
import React from "react";
import styles from "./styles.module.scss";

const Favorite = () => {
    return (
        <div className={styles["favorite"]}>
            <FavoriteOffers />
        </div>
    );
};

export default Favorite;
