import { ReactComponent as NoApplicationsColor } from "assets/icons/noApplicationsColor.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import { RatingDto } from "features/myProfile/models";
import { CardOwnerRole } from "features/myProfile/types";
import { RateCategory } from "features/rating/models";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const mapRateCategoryToString = (category: RateCategory, cardKind: CardOwnerRole): string => {
    if (cardKind === "EMPLOYEE") {
        switch (category) {
            case RateCategory.engagement:
                return "engagement";
            case RateCategory.ohs_compliance:
                return "ohs_compliance";
            case RateCategory.punctuality:
                return "punctuality";
            case RateCategory.availability:
                return "availability";
            case RateCategory.taking_care_of_equipment:
                return "taking_care_of_equipment";
            case RateCategory.personal_hygiene:
                return "personal_hygiene";
            case RateCategory.compliance_of_real_skills_with_declared:
                return "compliance_of_real_skills_with_declared";
        }
    }
    if (cardKind === "EMPLOYER") {
        switch (category) {
            case RateCategory.onboardOrganisation:
                return "onboard-organisation";
            case RateCategory.compliance:
                return "compliance";
            case RateCategory.timeliness:
                return "timeliness";
            case RateCategory.atmosphere:
                return "atmosphere";
            case RateCategory.transport:
                return "transport";
            case RateCategory.accomodation:
                return "accomodation";
            case RateCategory.difficulty:
                return "difficulty";
            case RateCategory.management:
                return "management";
        }
    }

    return "";
};

export interface Props {
    rating?: RatingDto[];
    cardId: string;
    cardKind: CardOwnerRole;
    displayOnly?: boolean;
}

const RatingSection = ({ rating, cardId, cardKind, displayOnly }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const overallRating = useMemo(() => rating?.find((item) => item.category === RateCategory.overall), [rating]);
    const detailedRatings = useMemo(() => rating?.filter((item) => item.category !== RateCategory.overall), [rating]);

    const toggleExpand = useCallback(() => setIsExpanded((expanded) => !expanded), []);

    console.log(detailedRatings)
    return (
        <div className={styles["rating-section"]}>
            {!!rating?.length && overallRating ? (
                <div className={styles["rating-section__rating"]}>
                    <div className={styles["rating-section__collapsed"]}>
                        <div
                            className={classNames(styles["rating-section__average-overall"], {
                                [styles["rating-section__employee-average-overall"]]: cardKind === "EMPLOYEE",
                            })}
                        >
                            {overallRating.average?.toFixed(1).replace(".", ",")}
                        </div>
                        <div className={styles["rating-section__overall-container"]}>
                            <div className={styles["rating-section__overall-title"]}>
                                {cardKind === "EMPLOYER" ? (
                                    <FormattedMessage id="card-details__employer-rating-overall-title" />
                                ) : (
                                    <FormattedMessage id="card-details__employee-rating-overall-title" />
                                )}
                            </div>
                            <div className={styles["rating-section__overall-subtext"]}>
                                <FormattedMessage
                                    id="card-details__ratings-amount-text"
                                    values={{ amount: overallRating.ratingsAmount }}
                                />
                            </div>
                        </div>
                    </div>
                    {isExpanded && (
                        <div className={styles["rating-section__expanded-container"]}>
                            <div className={styles["rating-section__expanded-title"]}>
                                <FormattedMessage id="card-details__expanded-rating-title" />
                            </div>
                            <div className={styles["rating-section__expanded-ratings"]}>
                                {detailedRatings?.map((item) => (
                                    <div className={styles["rating-section__expanded-item"]}>
                                        <div className={styles["rating-section__expanded-item-value"]}>
                                            <span className={styles["rating-section__expanded-item-rating"]}>
                                                {item.average.toFixed(1)}
                                            </span>
                                            <span className={styles["rating-section__expanded-item-rating-text"]}>
                                                / 10
                                            </span>
                                            <span className={styles["rating-section__expanded-item-amount"]}>
                                                <FormattedMessage
                                                    id="card-details__ratings-amount-text"
                                                    values={{ amount: item.ratingsAmount }}
                                                />
                                            </span>
                                        </div>
                                        <div className={styles["rating-section__expanded-item-text"]}>
                                            <FormattedMessage
                                                id={`card-details__${cardKind.toLowerCase()}-rating-${mapRateCategoryToString(
                                                    item.category,
                                                    cardKind
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <Button
                        variant="no-background"
                        className={styles["rating-section__expand-button"]}
                        onClick={toggleExpand}
                    >
                        <FormattedMessage id={isExpanded ? "common__collapse" : "common__expand"} />
                    </Button>
                </div>
            ) : (
                <div className={styles["rating-section__empty-rating"]}>
                    <NoApplicationsColor />
                    <div className={styles["rating-section__empty-rating-title"]}>
                        <FormattedMessage
                            id={
                                displayOnly
                                    ? "card-details__empty-rating-display-only-title"
                                    : "card-details__empty-rating-title"
                            }
                            values={{ cardKind }}
                        />
                    </div>
                    <div className={styles["rating-section__empty-rating-text"]}>
                        <FormattedMessage
                            id={
                                displayOnly
                                    ? "card-details__empty-rating-display-only-text"
                                    : "card-details__empty-rating-text"
                            }
                            values={{ cardKind }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default RatingSection;
