import { ApiError } from "api/utils";
import {
    EmployeeOfferDetailsResponseDto,
    EmployeeOffersResponseDto,
    MyEmployeeOffersResponseDto,
    CreateEmployeeOfferResponseDto,
} from "features/employeeOffers/dtos";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const getEmployeeOffers = createAsyncAction(
    "GET_EMPLOYEE_OFFERS_REQUEST",
    "GET_EMPLOYEE_OFFERS_SUCCESS",
    "GET_EMPLOYEE_OFFERS_FAILURE"
)<void, EmployeeOffersResponseDto, ApiError>();

export const getMyEmployeeOffers = createAsyncAction(
    "GET_MY_EMPLOYEE_OFFERS_REQUEST",
    "GET_MY_EMPLOYEE_OFFERS_SUCCESS",
    "GET_MY_EMPLOYEE_OFFERS_FAILURE",
    "GET_MY_EMPLOYEE_OFFERS_CANCEL"
)<void, MyEmployeeOffersResponseDto, ApiError>();

export const getEmployeeOfferDetails = createAsyncAction(
    "GET_EMPLOYEE_OFFER_DETAILS_REQUEST",
    "GET_EMPLOYEE_OFFER_DETAILS_SUCCESS",
    "GET_EMPLOYEE_OFFER_DETAILS_FAILURE"
)<void, EmployeeOfferDetailsResponseDto, ApiError>();

export const amendEmployeeOffers = createAsyncAction(
    "AMEND_EMPLOYEE_OFFERS_REQUEST",
    "AMEND_EMPLOYEE_OFFERS_SUCCESS",
    "AMEND_EMPLOYEE_OFFERS_FAILURE"
)<void, EmployeeOffersResponseDto, ApiError>();

export const sendEmployeeOfferMessage = createAsyncAction(
    "SEND_EMPLOYEE_OFFER_MESSAGE_REQUEST",
    "SEND_EMPLOYEE_OFFER_MESSAGE_SUCCESS",
    "SEND_EMPLOYEE_OFFER_MESSAGE_FAILURE"
)<void, void, ApiError>();

export const createEmployeeOffer = createAsyncAction(
    "CREATE_EMPLOYEE_OFFER_REQUEST",
    "CREATE_EMPLOYEE_OFFER_SUCCESS",
    "CREATE_EMPLOYEE_OFFER_FAILURE"
)<void, CreateEmployeeOfferResponseDto, ApiError>();

export const saveEmployeeOffer = createAsyncAction(
    "SAVE_EMPLOYEE_OFFER_REQUEST",
    "SAVE_EMPLOYEE_OFFER_SUCCESS",
    "SAVE_EMPLOYEE_OFFER_FAILURE"
)<void, void, ApiError>();

export const resetEmployeeOffers = createAction("RESET_EMPLOYEE_OFFERS")<void>();

export const resetMyEmployeeOffers = createAction("RESET_MY_EMPLOYEE_OFFERS")<void>();

export const resetEmployeeSendMessageFlags = createAction("RESET_EMPLOYEE_SEND_MESSAGE_FLAGS")<void>();

export const resetCreateEmployeeOfferState = createAction("RESET_CREATE_EMPLOYEE_OFFER_STATE")<void>();

export const resetEmployeeOffersFilterMatchCounts = createAction("RESET_EMPLOYEE_OFFERS_FILTER_MATCH_COUNTS")<void>();

export const resetEmployeeOfferDetails = createAction("RESET_EMPLOYEE_OFFER_DETAILS")<void>();

type EmployeeOffersAction =
    | ActionType<typeof getEmployeeOffers>
    | ActionType<typeof getMyEmployeeOffers>
    | ActionType<typeof amendEmployeeOffers>
    | ActionType<typeof getEmployeeOfferDetails>
    | ActionType<typeof sendEmployeeOfferMessage>
    | ActionType<typeof createEmployeeOffer>
    | ActionType<typeof saveEmployeeOffer>
    | ActionType<typeof resetEmployeeOffers>
    | ActionType<typeof resetMyEmployeeOffers>
    | ActionType<typeof resetEmployeeSendMessageFlags>
    | ActionType<typeof resetCreateEmployeeOfferState>
    | ActionType<typeof resetEmployeeOffersFilterMatchCounts>
    | ActionType<typeof resetEmployeeOfferDetails>;

export default EmployeeOffersAction;
