import {ReactComponent as DrivingLicenseAIcon} from "assets/icons/drivingLicenseA.svg";
import {ReactComponent as DrivingLicenseA1Icon} from "assets/icons/drivingLicenseA1.svg";
import {ReactComponent as DrivingLicenseA2Icon} from "assets/icons/drivingLicenseA2.svg";
import {ReactComponent as DrivingLicenseAMIcon} from "assets/icons/drivingLicenseAM.svg";
import {ReactComponent as DrivingLicenseBIcon} from "assets/icons/drivingLicenseB.svg";
import {ReactComponent as DrivingLicenseB1Icon} from "assets/icons/drivingLicenseB1.svg";
import {ReactComponent as DrivingLicenseBEIcon} from "assets/icons/drivingLicenseBE.svg";
import {ReactComponent as DrivingLicenseCIcon} from "assets/icons/drivingLicenseC.svg";
import {ReactComponent as DrivingLicenseC1Icon} from "assets/icons/drivingLicenseC1.svg";
import {ReactComponent as DrivingLicenseC1EIcon} from "assets/icons/drivingLicenseC1E.svg";
import {ReactComponent as DrivingLicenseCEIcon} from "assets/icons/drivingLicenseCE.svg";
import {ReactComponent as DrivingLicenseDIcon} from "assets/icons/drivingLicenseD.svg";
import {ReactComponent as DrivingLicenseD1Icon} from "assets/icons/drivingLicenseD1.svg";
import {ReactComponent as DrivingLicenseD1EIcon} from "assets/icons/drivingLicenseD1E.svg";
import {ReactComponent as DrivingLicenseDEIcon} from "assets/icons/drivingLicenseDE.svg";
import {ReactComponent as DrivingLicenseTIcon} from "assets/icons/drivingLicenseT.svg";
import {JobOfferApplicationSubmissionSchema} from "features/jobOffers/schemas";
import {
    Education,
    EducationType,
    EmployerType,
    JobExperienceType,
    Language,
    LanguageType,
    RequirementListOption,
} from "features/jobOffers/types";
import {createOfferDetailsStepFieldNames} from "features/offers/constants";
import React from "react";
import {IntlShape} from "react-intl";

export const Languages: Language[] = [
    { id: LanguageType.English, isChecked: false },
    { id: LanguageType.Arabic, isChecked: false },
    { id: LanguageType.Czech, isChecked: false },
    { id: LanguageType.French, isChecked: false },
    { id: LanguageType.Hindi, isChecked: false },
    { id: LanguageType.Spanish, isChecked: false },
    { id: LanguageType.Dutch, isChecked: false },
    { id: LanguageType.German, isChecked: false },
    { id: LanguageType.Polish, isChecked: false },
    { id: LanguageType.Russian, isChecked: false },
    { id: LanguageType.Romanian, isChecked: false },
    { id: LanguageType.Ukrainian, isChecked: false },
    { id: LanguageType.Italian, isChecked: false },
];

export const Educations: Education[] = [
    { id: EducationType.None, isChecked: false },
    { id: EducationType.Primary, isChecked: false },
    { id: EducationType.Vocational, isChecked: false },
    { id: EducationType.Secondary, isChecked: false },
    { id: EducationType.SecondaryTechnical, isChecked: false },
    { id: EducationType.Higher, isChecked: false },
    { id: EducationType.Other, isChecked: false },
];

export const employerTypes: EmployerType[] = [EmployerType.Company, EmployerType.Private];

export const initialJobOfferApplicationSubmissionFormValues: JobOfferApplicationSubmissionSchema = {
    qualificationDescriptionStep: {
        description: "",
    },
};

export const drivingOptions: { [k: string]: RequirementListOption } = {
    AM: { label: "AM", icon: <DrivingLicenseAMIcon /> },
    A1: { label: "A1", icon: <DrivingLicenseA1Icon /> },
    A2: { label: "A2", icon: <DrivingLicenseA2Icon /> },
    A: { label: "A", icon: <DrivingLicenseAIcon /> },
    B1: { label: "B1", icon: <DrivingLicenseB1Icon /> },
    B: { label: "B", icon: <DrivingLicenseBIcon /> },
    B_E: { label: "B+E", icon: <DrivingLicenseBEIcon /> },
    C: { label: "C", icon: <DrivingLicenseCIcon /> },
    C1: { label: "C1", icon: <DrivingLicenseC1Icon /> },
    C1_E: { label: "C1+E", icon: <DrivingLicenseC1EIcon /> },
    C_E: { label: "C+E", icon: <DrivingLicenseCEIcon /> },
    D: { label: "D", icon: <DrivingLicenseDIcon /> },
    D1: { label: "D1", icon: <DrivingLicenseD1Icon /> },
    D1_E: { label: "D1+E", icon: <DrivingLicenseD1EIcon /> },
    D_E: { label: "D+E", icon: <DrivingLicenseDEIcon /> },
    T: { label: "T", icon: <DrivingLicenseTIcon /> },
};

export const createExperienceOptions = (intl: IntlShape): { [k: string]: RequirementListOption } => ({
    [JobExperienceType.noExperience]: { label: intl.formatMessage({ id: "job-experience__noExperience" }) },
    [JobExperienceType.toThreeMonths]: { label: intl.formatMessage({ id: "job-experience__toThreeMonths" }) },
    [JobExperienceType.toSixMonths]: { label: intl.formatMessage({ id: "job-experience__toSixMonths" }) },
    [JobExperienceType.oneYear]: { label: intl.formatMessage({ id: "job-experience__oneYear" }) },
    [JobExperienceType.twoYearsAndMore]: { label: intl.formatMessage({ id: "job-experience__twoYearsAndMore" }) },
});

export const createJobOfferFieldNames = {
    detailsStep: {
        ...createOfferDetailsStepFieldNames,
        street: "street",
        streetNumber: "streetNumber",
    },
    financialConditionsStep: {
        salaryType: "salaryType",
        salaryMinValue: "salaryMinValue",
        salaryMaxValue: "salaryMaxValue",
        salaryUnit: "salaryUnit",
        bonusValue: "bonusValue",
        bonusUnit: "bonusUnit",
        bonusDate: "bonusDate",
    },
    requirementsStep: {
        languages: "languages",
        experience: "experience",
        permissions: "permissions",
        drivingLicenses: "drivingLicenses",
        education: "education",
        certificates: "certificates",
        programs: "programs",
        other: "other",
    },
    additionalInfoStep: {
        additionalInfo: "additionalInfo",
        mainImage: "mainImage",
        additionalImages: "additionalImages",
    },
};

export const paymentFormFieldNames = {
    employerType: "employerType",
    companyName: "companyName",
    taxIdentificationNumber: "taxIdentificationNumber",
    firstName: "firstName",
    lastName: "lastName",
    location: "location",
    phoneNumber: "phoneNumber",
    email: "email",
    country: "country",
    city: "city",
    street: "street",
    postalCode: "postalCode",
    attachInvoice: "attachInvoice",
    cardNumber: "cardNumber",
    expirationDate: "expirationDate",
    cvc: "cvc2",
};
