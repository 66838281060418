import ModalWithContent from "./component";
import { connect } from "react-redux";
import { RootState } from "store";
import { selectGetCallState } from "features/getCall/selectors";

const mapStateToProps = (state: RootState) => ({
    ...selectGetCallState(state),
});

export default connect(mapStateToProps)(ModalWithContent);
