import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import SignInMessage from "features/common/components/SignInMessage";
import {EmployeeOfferDetails} from "features/employeeOffers/models";
import {getLoginCustomMessage} from "features/employeeOffers/translationMessages";
import {offersQueryParamNames} from "features/offers/constants";
import {appRoutes} from "features/routing/routes";
import {useMediaQuery} from "hooks/useMediaQuery";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import styles from "./styles.module.scss";

export interface Props {
    offerDetails: EmployeeOfferDetails;
    isUserLoggedIn?: boolean;
    ["data-testid"]?: string;
}

const EmployeeInfoPart = ({ offerDetails, isUserLoggedIn, "data-testid": testId = "employer-part" }: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const isTabletMatches = useMediaQuery('(min-width: 834px)');

    const loginCustomMessage = getLoginCustomMessage(intl);

    const onSeeOtherOffersClick = useCallback(
        () =>
            history.push(
                `${appRoutes.employeeOffers}?${offersQueryParamNames.companyName}=${encodeURIComponent(
                    offerDetails.businessCardId ?? ""
                )}`
            ),
        [history, offerDetails.businessCardId]
    );

    const goToProfile = useCallback(() => {
        history.push(`${appRoutes.profileCard}/${offerDetails.businessCardId}`);
    }, [history, offerDetails.businessCardId]);

    return (
        <div className={styles["employee-info-part"]}>
            {isUserLoggedIn ? (
                <>
                    <div className={styles["employee-info-part__title"]}>
                        <FormattedMessage id="common__employee" />
                    </div>
                    <div
                        data-testid={`${testId}__employer-details`}
                        className={classNames(styles["employee-info-part__employer-details"], {
                            [styles["employee-info-part__employer-details--with-logo"]]:
                                !!offerDetails.businessCardData?.logo,
                        })}
                    >
                        {offerDetails.businessCardData?.logo ? (
                            <img
                                data-testid={`${testId}__logo`}
                                className={styles["employee-info-part__logo"]}
                                src={`${config.IMAGE_URL}/${offerDetails.businessCardData?.logo}`}
                                alt="logo"
                            />
                        ) : !isTabletMatches && <StyledNoLogo/>}
                        <span
                            data-testid={`${testId}__name`}
                            title={offerDetails.businessCardData?.businessName}
                            className={styles['employee-info-part__name']}
                        >
                            {offerDetails.businessCardData?.businessName}
                        </span>

                        <StyledButton
                            first
                            data-testid={`${testId}__see-profile-button`}
                            variant="no-background"
                            onClick={goToProfile}
                        >
                            <FormattedMessage id="offer-details__see-profile" />
                        </StyledButton>
                        <StyledButton
                            data-testid={`${testId}__see-other-offers-button`}
                            variant="no-background"
                            onClick={onSeeOtherOffersClick}
                        >
                            <FormattedMessage id="offer-details__see-other-offers" />
                        </StyledButton>
                    </div>

                </>
            ) : (
                <SignInMessage
                    data-testid={`${testId}__sign-in-message`}
                    className={styles["employee-info-part__sign-in-message"]}
                    customMessage={loginCustomMessage}
                />
            )}
        </div>
    );
};

const StyledButton = styled(Button) <{ first?: boolean }>`
    white-space: nowrap;
    padding: 0;
    justify-content: start;

    @media screen and (min-width: 834px){
        ${({ first }) => first && `
        margin-right: 1rem;
        margin-left: auto;
    `};
    }
`;

const StyledNoLogo = styled.div`
    width: 4rem;
`;

export default EmployeeInfoPart;
