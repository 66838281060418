const getcallUrl: string = process.env.REACT_APP_GETCALL_URL ?? "_";
const backendBaseUrl: string = process.env.REACT_APP_BACKEND_URL ?? "_";
const webSocketUrl: string = backendBaseUrl + "/getmen";
const imageUrl: string = backendBaseUrl + "/static/images";
const apiUrl: string = backendBaseUrl + "/api";
const facebookLoginUrl: string = backendBaseUrl + "/api/login/facebook";
const googleLoginUrl: string = backendBaseUrl + "/api/login/google";
const appleLoginUrl: string = backendBaseUrl + "/api/login/apple";

const config = {
    EXAMPLE_API_URL: "https://reqres.in",
    GETBONUS_URL: "https://getbonus.getmen.com",
    INVEST_URL: "https://invest.getmen.com",
    GETCALL_URL: getcallUrl,
    WEBSOCKET_URL: webSocketUrl,
    API_URL: apiUrl,
    IMAGE_URL: imageUrl,
    FACEBOOK_LOGIN_URL: facebookLoginUrl,
    GOOGLE_LOGIN_URL: googleLoginUrl,
    APPLE_LOGIN_URL: appleLoginUrl,
    apiRoutes: {
        GETBONUS_STATUS: "/applications/paid-bonus-status",
        FENIGE_CARDS: "/payments/fenige/cards",
        FENIGE_PAYMENT: "/fenige/payments",
        BRANCHES: "/branches",
        COUNTRIES: "/countries",
        CREATE_PARTIAL_OFFER: "/offers/partial-offer",
        CURRENCIES: "/currencies",
        CARDS: "/cards",
        EMPLOYEE_OFFERS: "/employee-offers",
        USER_EMPLOYEE_OFFERS: "/employee-offers/my-employee-offers",
        CONVERSATIONS: "/conversations",
        BY_MESSAGE: "/by-message",
        MESSAGE: "/message",
        MESSAGES: "/messages",
        MESSAGES_THREAD: "/messages/thread",
        MESSAGES_SEND_OFFER: "/messages/send-offer",
        MESSAGES_SEND_CONTACT_FORM: "/support",
        OFFERS_SEND_INDIVIDUAL_APPLICATION: "/applications/individual-offer",
        OFFERS_SEND_GROUP_APPLICATION: "/applications/group-offer",
        APPLICATIONS: "/applications",
        MY_APPLICATIONS: "my-applications",
        GET_TOKENS: "/user/tokens",
        GET_OFFERS_TO_MODERATE: "/offers/to-moderate",
        PUT_OFFER: "/offers",
        END_OFFER: "/job-offers",
        OFFERS: "/job-offers",
        SEND_DEDICATION: "/job-offers/send-dedication",
        RECOMMENDED_JOB_OFFERS: "/job-offers/getRecommendedOffers",
        OFFERS_FILTER_MATCH_COUNTS: "/offers/filter-match-counts",
        USER_JOB_OFFERS: "/job-offers/my-job-offers",
        END_JOB_OFFER: "end-job-offer",
        END_EMPLOYEE_OFFER: "end-employee-offer",
        JOB_OFFER_CARD_DETAILS: "/offers/card-details",
        EMPLOYEE_OFFERS_FILTER_MATCH_COUNTS: "/employee-offers/filter-match-counts",
        REFRESH_TOKEN: "/login/refresh",
        DEACTIVATE_TOKEN: "/user/deactivateToken",
        UNREAD_MESSAGES_COUNT: "/messages/count-unread",
        USER: "/v2/users",
        ADMIN_PANEL_USERS: "/v2/users/all",
        ADMIN_PANEL_STATISTICS: "/admin/statistics",
        WORK_TYPES: "/work-types",
        GET_OFFER_CREATION_CARDS: "/cards/offer-creation-cards",
        GET_MY_PROFILE_CARDS: "/v2/users/business-card",
        LOCATIONS: "/locations",
        BUSINESS_CARDS: "/business-cards",
        MY_PROFILE_UPDATE_CARD: "/cards/my-profile-update-card",
        CARD_RELATED_OFFERS: "/cards/related-offers",
        COUNT_CARD_RELATED_OFFERS: "/cards/count-related-offers",
        RATE_EMPLOYER: "/rates/employer",
        RATE_EMPLOYEE: "/rates/employee",
        RATE_OFFER_OWNER: "/rates/business-card/offerOwner/employer",
        RATE_APPLICANT_EMPLOYER: "/rates/business-card/applicant/employer",
        RATE_APPLICANT_EMPLOYEE: "/rates/business-card/applicant/employee",
        EMPLOYER_RATE_PREVIEW: "/rates/employer",
        EMPLOYEE_RATE_PREVIEW: "/rates/employee",
        LOGIN: "login",
        FORGOTTEN_PASSWORD: "forgotten-password",
        RECOVER_PASSWORD: "forgotten-password/change",
        LOGIN_OAUTH: "login/oauth2",
        LOGOUT: "logout",
        REGISTER: "register",
        VERIFY: "verify",
        IMAGE_UPLOAD: "files/images",
        UPLOAD_FILE: "files",
        FILE: "file",
        BLOCK_USER: "admin/user/block",
        UNBLOCK_USER: "admin/user/unblock",
        NOTIFICATIONS: "notifications",
        LOCATION_AUTOCOMPLETE: "job-offers/autocomplete-location",
        FENIGE_AUTHENTICATION: "fenige/authentication",
        FENIGE_SEND_MONEY: "fenige/send-money",
        PRICE_CALCULATE: "price/calculate",
        FAVORITE: "/favorite",
        FAVORITE_EMPLOYEE: "/favorite-employee",
        FAVORITE_EMPLOYER: "/favorite-employer",
        DELETE_CARD_BY_ID_URL: "/business-cards",
        TRANSLATE: "/translate",
        DELETE_MY_ACCOUNT: "/v2/users/delete-my-account",
        FENIGE_API_PRE_AUTH: "fenige/authentication/pre-auth",
        FENIGE_API_INDICATOR_REQUEST: "fenige/authentication/indicator-request",
        FENIGE_API_AUTH: "fenige/authentication/auth",
        FENIGE_API_PAYMENT: "fenige/payment/make-payment",
    },
    offersResultsPerPage: 15,
    messagesResultsPerPage: 20,
    // TODO: IWGM-173 Add actual social media links
    socialMediaLinks: {
        FACEBOOK: "https://www.facebook.com/Getmencom/",
        INSTAGRAM: "https://instagram.com/getmencom",
        TWITTER: "#",
        LINKED_IN: "https://www.linkedin.com/company/getmen",
    },
    contactEmail: "contact@getmen.com",
    minimalAppHeight: 500,
};

export default config;
