import {
    selectBranches,
    selectBranchesLoading,
    selectBranchesLoadingError,
    selectCountries,
} from "features/common/selectors";
import { getBranchesAsync } from "features/common/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import OfferDetailsStepComponent from "./component";

const mapStateToProps = (state: RootState) => ({
    branches: selectBranches(state),
    branchesLoading: selectBranchesLoading(state),
    branchesError: selectBranchesLoadingError(state),
    countries: selectCountries(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getBranchesAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsStepComponent);
