import Checkbox from "features/common/components/Checkbox";
import ModalWithContent from "features/common/components/ModalWithContent";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { getCancelMessage, getSelectMessage } from "features/common/translationMessages";
import RequirementBlock from "features/jobOffers/components/CreateJobOffer/RequirementsStep/RequirementBlock";
import { RequirementListOption } from "features/jobOffers/types";
import { FieldHelperProps, FieldInputProps } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    options: { [id: string]: RequirementListOption };
    header: string;
    hasColumns?: boolean;
    valueInputProps: FieldInputProps<string[]>;
    helperProps: FieldHelperProps<string[]>;
    singleValueOnly?: boolean;
    ["data-testid"]?: string;
}

const CheckBoxRequirement = ({
    options,
    header,
    hasColumns,
    valueInputProps,
    helperProps,
    singleValueOnly,
    "data-testid": testId,
}: Props) => {
    const deviceClass = useDeviceClass();

    const [prevValues, setPreviousValues] = useState<string[]>(valueInputProps.value || []);

    const onCancel = () => {
        helperProps.setValue(prevValues);
    };

    const onAdd = () => {
        const cloned = Object.assign([], valueInputProps.value);
        setPreviousValues(cloned);
    };

    const onEdit = () => {
        const cloned = Object.assign([], valueInputProps.value);
        setPreviousValues(cloned);
    };

    const onClean = () => {
        helperProps.setValue([]);
        setPreviousValues([]);
    };

    const getValueArea = useCallback(() => {
        if (valueInputProps.value?.length > 0) {
            return (
                <div className={styles["checkbox-requirement__value-area"]}>
                    {valueInputProps.value.map((option, index) =>
                        options[option].icon ? (
                            <div
                                data-testid={testId ? `${testId}__value-area` : undefined}
                                key={"value-area_" + option}
                                className={styles["checkbox-requirement__value-area--no-comma"]}
                            >
                                <div className={styles["checkbox-requirement__value-area-icon"]} key={"icon_" + option}>
                                    {options[option].icon}
                                </div>
                                <div key={"label_" + option}>{options[option].label}</div>
                            </div>
                        ) : (
                            <div
                                data-testid={testId ? `${testId}__value-area` : undefined}
                                className={
                                    index !== valueInputProps.value.length - 1
                                        ? styles["checkbox-requirement__value-area--with-comma"]
                                        : ""
                                }
                                key={"label_" + option}
                            >
                                {options[option].label}
                            </div>
                        )
                    )}
                </div>
            );
        }

        return null;
    }, [options, testId, valueInputProps]);

    const onCheckboxToggle = useCallback(
        (checked: boolean, option: string) => {
            let valuesPrevState: string[] = singleValueOnly ? [] : Object.assign([], valueInputProps.value);

            if (checked) {
                valuesPrevState.push(option);
            } else {
                const index = valuesPrevState.indexOf(option, 0);
                if (index > -1) {
                    valuesPrevState.splice(index, 1);
                }
            }

            helperProps.setValue(valuesPrevState);
        },
        [helperProps, singleValueOnly, valueInputProps.value]
    );

    const editorContent = useMemo(() => {
        return (
            <div
                className={
                    hasColumns && deviceClass === "desktop"
                        ? styles["checkbox-requirement--column-list"]
                        : styles["checkbox-requirement--list"]
                }
            >
                {Object.keys(options).map((id) => (
                    <Checkbox
                        data-testid={testId ? `${testId}__checkbox` : undefined}
                        key={id}
                        className={styles["checkbox-requirement__checkbox"]}
                        label={
                            <div className={styles["checkbox-requirement__checkbox-label"]}>
                                <div>{options[id].label}</div>
                                <div className={styles["checkbox-requirement__checkbox-icon"]}>{options[id].icon}</div>
                            </div>
                        }
                        checked={valueInputProps.value?.indexOf(id) >= 0}
                        onToggle={(checked) => onCheckboxToggle(checked, id)}
                    />
                ))}
            </div>
        );
    }, [deviceClass, hasColumns, onCheckboxToggle, options, testId, valueInputProps.value]);

    return (
        <div>
            <RequirementBlock
                data-testid={testId ? `${testId}__requirement-block` : undefined}
                label={header}
                onAdd={onAdd}
                value={getValueArea()}
                onEdit={onEdit}
                onClean={onClean}
                onCancel={onCancel}
                isCleanable={true}
                editorContent={editorContent}
            />
        </div>
    );
};

export default CheckBoxRequirement;
