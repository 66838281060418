import { resetMessages } from "features/messages/actions";
import { ConversationsDto } from "features/messages/dtos";
import { Conversation } from "features/messages/models";
import {
    selectConversations,
    selectConversationsLoading,
    selectConversationsLoadingError,
    selectTotalConversations,
} from "features/messages/selectors";
import { amendConversationsAsync, getConversationsAsync } from "features/messages/thunks";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import MyMessages from "./component";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    conversations: selectConversations(state),
    conversationsLoading: selectConversationsLoading(state),
    conversationsLoadingError: selectConversationsLoadingError(state),
    totalConversations: selectTotalConversations(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getConversationsAsync,
            amendConversationsAsync,
            resetMessages: () => resetMessages(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyMessages);