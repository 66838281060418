import { makeApiRequest } from "api/makeApiRequest";
import { ApiError, CancelToken } from "api/utils";
import { EmployeeOffersApi } from "features/employeeOffers/api";
import { JobOffersApi } from "features/jobOffers/api";
import MessagesAction, {
    amendConversations,
    getConversationDetails,
    getConversations,
    sendMessage,
} from "features/messages/actions";
import { MessagesApi } from "features/messages/api";
import { GetConversationsFetchParams } from "features/messages/types";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";
import { ConversationDto } from "../dtos";

export const getConversationsAsync = (
    fetchParams: GetConversationsFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MessagesApi, "getConversationsAsync">, MessagesAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getConversations.request());
        const {
            user: { user, userId },
        } = getState();

        await makeApiRequest(
            api.getConversationsAsync,
            [fetchParams, (userId || user?.id) as string, cancelToken],
            getConversations.success,
            getConversations.failure,
            dispatch
        );
    };
};

export const amendConversationsAsync = (
    fetchParams: GetConversationsFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MessagesApi, "getConversationsAsync">, MessagesAction> => {
    return async (dispatch, getState, api) => {
        dispatch(amendConversations.request());
        const {
            user: { user, userId },
        } = getState();

        await makeApiRequest(
            api.getConversationsAsync,
            [fetchParams, (userId || user?.id) as string, cancelToken],
            amendConversations.success,
            amendConversations.failure,
            dispatch
        );
    };
};

export const getConversationDetailsAsync = (
    conversationId: string,
    cancelToken?: CancelToken
): ThunkAction<
    void,
    RootState,
    Pick<MessagesApi, "getConversationDetailsAsync"> & Pick<JobOffersApi, "getJobOfferDetailsAsync"> & Pick<EmployeeOffersApi, "getEmployeeOfferDetailsAsync">,
    MessagesAction
> => {
    return async (dispatch, getState, api) => {
        try {
            dispatch(getConversationDetails.request());
            const {
                user: { user, userId },
            } = getState();

            const messagesDto = await api.getConversationDetailsAsync(conversationId, cancelToken)
            const offerDto = messagesDto.genusOffer === "EMPLOYEE" ? await api.getEmployeeOfferDetailsAsync(+messagesDto.offer.offerId) : await api.getJobOfferDetailsAsync(messagesDto.offer.offerId); return dispatch(
                getConversationDetails.success({ messagesDto, offerDto, currentUserId: user?.id || (userId as string) })
            );
        } catch (error) {
            dispatch(getConversationDetails.failure(error as ApiError));
        }
    };
};

export const sendMessageAsync = (
    id: string,
    message: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MessagesApi, "sendMessageAsync">, MessagesAction> => {
    return async (dispatch, getState, api) => {
        dispatch(sendMessage.request());

        await makeApiRequest(
            api.sendMessageAsync,
            [id, message, null, cancelToken],
            sendMessage.success,
            sendMessage.failure,
            dispatch
        );
    };
};