/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { pl } from "date-fns/locale";
import { ApiError, CancelToken } from "api/utils";
import config from "config";
import Button from "features/common/components/Button";
import ModalWithContent from "features/common/components/ModalWithContent";
import ErrorHeader from "features/common/components/ModalWithContent/ErrorHeader";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDateFormat from "features/common/hooks/useDateFormat";
import {
    getBonusMessage,
    getPositionsNumberMessage,
    getPublishedDateMessage,
    getValidUntilMessage,
    getWorkLocationMessage,
} from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import {  JobOfferDetails } from "features/jobOffers/models";
import { CardOwnerRole } from "features/myProfile/types";
import CardImageWithBadge from "features/offers/components/SingleOfferCard/CardImageWithBadge";
import { getSalaryMessage, getWorkTypeWithDatesMessage } from "features/offers/translationMessages";
import DetailRow from "features/offers/components/SingleOfferCard/DetailRow";
import RatingButtons from "features/rating/components/RatingButtons";
import { RateCategory } from "features/rating/models";
import { appRoutes } from "features/routing/routes";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import { ReactComponent as CalendarCheckIcon } from "assets/icons/calendarCheck.svg";
import { ReactComponent as CalendarWithNumberIcon } from "assets/icons/calendarWithNumber.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as CoinsBanknoteIcon } from "assets/icons/coinsBanknote.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "assets/icons/peopleWithHand.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import styles from "./styles.module.scss";
import { User } from "features/user/models";

interface Props {
    id: number;
    ratingType?: CardOwnerRole;
    employeeRatingSummaryLoading: boolean;
    employeeRatingSummarySuccess?: boolean;
    employeeRatingSummaryError?: ApiError;
    getEmployeeRateAsync: (formId: number, cancelToken?: CancelToken) => void;
    jobOfferDetails?: JobOfferDetails;
    jobOfferDetailsLoading: boolean;
    jobOfferDetailsError?: ApiError;
    getJobOfferDetailsAsync: (id: number, cancelToken?: CancelToken) => void;
    user?: User;
    employeeRatingSummaryData: any;
}

const RateEmployeeSummary = ({
                                 id,
                                 employeeRatingSummaryLoading,
                                 employeeRatingSummarySuccess,
                                 employeeRatingSummaryError,
                                 getEmployeeRateAsync,
                                 jobOfferDetails,
                                 jobOfferDetailsLoading,
                                 jobOfferDetailsError,
                                 getJobOfferDetailsAsync,
                                 employeeRatingSummaryData,
                              }: Props) => {
    const createCancelToken = useCreateCancelToken();

    useEffect(() => {
        if (id) {
            getEmployeeRateAsync(id, createCancelToken());
        }
    }, [createCancelToken, getEmployeeRateAsync, id]);

    useEffect(() => {
        if (employeeRatingSummaryData?.offerId) {
            getJobOfferDetailsAsync(employeeRatingSummaryData.offerId, createCancelToken());
        }
    }, [createCancelToken, employeeRatingSummaryData, id]);

    const intl = useIntl();
    const history = useHistory();
    const getFormattedDate = useDateFormat("d MMM yyyy", { locale: pl });

    const [errorModalDisplayed, setErrorModalDisplayed] = useState(false);

    const [employeeRating, setEmployeeRating] = useState<any>(null);

    let errorMessage;
    switch (employeeRatingSummaryError?.response?.status) {
        case 400:
            errorMessage = <FormattedMessage id="rating__error-no-application" />;
            break;
        case 403:
            errorMessage = <FormattedMessage id="rating__error-state" />;
            break;
        case 404:
            errorMessage = <FormattedMessage id="rating__error-not-found" />;
            break;
        default:
            errorMessage = <FormattedMessage id="rating__error-text" />;
    }

    useEffect(() => {
        if (employeeRatingSummaryData?.rates) {
            const mapped = employeeRatingSummaryData?.rates.map((item: any) => ({ [item.category]: item.rate }) );
            const rateObject = Object.assign({}, ...mapped );
            setEmployeeRating(rateObject);
        }
    }, [employeeRatingSummaryData]);

    useEffect(() => {
        if (employeeRatingSummaryError) {
            setErrorModalDisplayed(true);
        }
    }, [employeeRatingSummaryError]);

    const salaryMessage = useMemo(
        () =>
            jobOfferDetails?.financialConditions ? getSalaryMessage(intl, jobOfferDetails?.financialConditions) : "",
        [intl, jobOfferDetails]
    );
    const workLocationText = useMemo(
        () =>
            `${jobOfferDetails?.location?.city ? `${jobOfferDetails?.location?.city}, ` : ""}${intl.formatMessage({
                id: `common__country-${jobOfferDetails?.location?.country}`,
                defaultMessage: jobOfferDetails?.location?.country,
            })}`,
        [intl, jobOfferDetails]
    );
    const workLocationMessage = getWorkLocationMessage(intl, workLocationText ?? undefined);
    const workTypeMessage = getWorkTypeWithDatesMessage(
        intl,
        jobOfferDetails?.workType,
        getFormattedDate(jobOfferDetails?.startDate),
        getFormattedDate(jobOfferDetails?.endDate)
    );
    const bonusMessage = getBonusMessage(
        intl,
        `${jobOfferDetails?.financialConditions.bonusValue} ${jobOfferDetails?.financialConditions.bonusCurrencyCode}`
    );
    const positionsNumberMessage = getPositionsNumberMessage(intl, jobOfferDetails?.availablePositionsNumber);
    const publishedDateMessage = getPublishedDateMessage(intl, getFormattedDate(jobOfferDetails?.addedDate));
    const validUntilMessage = getValidUntilMessage(intl, getFormattedDate(jobOfferDetails?.expirationDate));

    const handleDetailsClick = useCallback(() => {
        history.push(`${appRoutes.jobOffers}/${jobOfferDetails?.id}`);
    }, [history, jobOfferDetails]);

    const goToProfile = useCallback(
        () => history.push(`${appRoutes.profileCard}/${jobOfferDetails?.businessCardId}`),
        [history, jobOfferDetails]
    );

    const handleCloseErrorModal = useCallback(() => setErrorModalDisplayed(false), []);

    if (jobOfferDetailsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "rating__loading-data" })} />;
    }

    if (employeeRatingSummaryLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "rating__sending-data" })} />;
    }
    return (
        <div className={styles["rate-employee"]}>
            {jobOfferDetailsError ? (
                <div
                    className={classNames(styles["rate-employee__container"], styles["rate-employee__error-container"])}
                >
                    <FormattedMessage id="rating__loading-error" />
                </div>
            ) : (
                <>
                    <div className={styles["rate-employee__container"]}>
                        <div className={styles["rate-employee__title"]}>
                            <FormattedMessage id="rating__overall-employee-section-title" />
                        </div>
                        <div className={styles["rate-employee__employer-container"]}>
                            {!!jobOfferDetails?.clientLogoUrl && (
                                <img
                                    src={`${config.IMAGE_URL}/${jobOfferDetails.clientLogoUrl}`}
                                    alt={`logo`}
                                    // alt={`${employeeOfferDetails.employeeName} logo`}
                                    className={styles["rate-employee__logo"]}
                                />
                            )}
                            {!!employeeRatingSummaryData?.evaluatorFirstname && (
                                <span className={styles["rate-employer__business-name"]}>
                                    <FormattedMessage id="rating__overall-evaluator" />
                                    {": "} {employeeRatingSummaryData?.evaluatorFirstname} {employeeRatingSummaryData?.evaluatorLastname}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={styles["rate-employee__data-container"]}>
                        <div className={styles["rate-employee__offer-container"]}>
                            <div className={styles["rate-employee__offer-container-title"]}>
                                <FormattedMessage id="rating__offer-title" />
                            </div>
                            <CardImageWithBadge
                                data-testid="rate-employer__card-image-with-badge"
                                photoBadge="status"
                                status={jobOfferDetails?.status}
                                imageUrl={`${config.IMAGE_URL}/${jobOfferDetails?.mainImage}`}
                                offerType={OfferType.Job}
                                className={styles["rate-employee__offer-image"]}
                            />
                            <div className={styles["rate-employee__offer-details-container"]}>
                                {/* <div className={styles["rate-employee__offer-business-name"]}> */}
                                {/* {'SOMETHING'} */}
                                {/* {employeeOfferDetails?.employeeName} */}
                                {/* </div> */}
                                <div className={styles["rate-employee__offer-position"]}>
                                    {jobOfferDetails?.position}
                                </div>
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__location"
                                    icon={<LocationMarkerIcon />}
                                    message={workLocationMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__work-type"
                                    isEllipsis={false}
                                    icon={<CalendarIcon />}
                                    message={workTypeMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__positions-number"
                                    icon={<PeopleWithHandIcon />}
                                    message={positionsNumberMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__salary"
                                    icon={<CoinsIcon />}
                                    message={salaryMessage}
                                />
                                {bonusMessage && (
                                    <DetailRow
                                        className={styles["rate-employee__offer-detail-row"]}
                                        iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                        data-testid="rate-employer__bonus"
                                        icon={<CoinsBanknoteIcon />}
                                        message={bonusMessage}
                                    />
                                )}
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__added-date"
                                    icon={<CalendarCheckIcon />}
                                    message={publishedDateMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employee__offer-detail-row"]}
                                    iconClassName={styles["rate-employee__offer-detail-row-icon"]}
                                    data-testid="rate-employer__expiration-date"
                                    icon={<CalendarWithNumberIcon />}
                                    message={validUntilMessage}
                                />
                            </div>
                            <div className={styles["rate-employee__details-button"]} onClick={handleDetailsClick}>
                                {intl.formatMessage({ id: "rating__offer-details" })}
                                <ChevronRight />
                            </div>
                        </div>
                        <div className={styles["rate-employee__rating-form"]}>
                            <div className={styles["rate-employee__section"]}>
                                <div className={styles["rate-employee__section-title"]}>
                                    <FormattedMessage id="rating__overall-employee-section-title" />
                                </div>
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__overall-employee-section-text" })}
                                    value={employeeRating?.[RateCategory.overall]}
                                    displayOnly
                                />
                            </div>
                            <div className={styles["rate-employee__section"]}>
                                <div className={styles["rate-employee__section-title"]}>
                                    <FormattedMessage id="rating__detailed-section-title" />
                                </div>
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__involvement-section-text" })}
                                    value={employeeRating?.[RateCategory.engagement]}
                                    displayOnly
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__employee-compliance-section-text" })}
                                    value={employeeRating?.[RateCategory.ohs_compliance]}
                                    displayOnly
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__employee-timeliness-section-text" })}
                                    value={employeeRating?.[RateCategory.punctuality]}
                                    displayOnly
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__availability-section-text" })}
                                    value={employeeRating?.[RateCategory.availability]}
                                    displayOnly
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__equipment-care-section-text" })}
                                    value={employeeRating?.[RateCategory.taking_care_of_equipment]}
                                    displayOnly
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__relations-section-text" })}
                                    value={employeeRating?.[RateCategory.personal_hygiene]}
                                    displayOnly
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__compatibility-section-text" })}
                                    value={employeeRating?.[RateCategory.compliance_of_real_skills_with_declared]}
                                    displayOnly
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {errorModalDisplayed && (
                <ModalWithContent
                    className={styles["rate-employee__modal"]}
                    header={
                        <ErrorHeader>
                            <FormattedMessage id="rating__error-title" />
                        </ErrorHeader>
                    }
                    content={errorMessage}
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseErrorModal}
                    primaryAction={handleCloseErrorModal}
                />
            )}
        </div>
    );
};

export default RateEmployeeSummary;
