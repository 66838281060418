import React from "react";
import MyApplicationsComponent from "features/myProfile/components/MyApplications";
import styles from "./styles.module.scss";

const MyApplications = () => {
    return (
        <div className={styles["my-applications"]}>
            <MyApplicationsComponent />
        </div>
    );
};

export default MyApplications;
