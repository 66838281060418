import { localStorageItems } from "features/common/constants";
import { ChangeEvent } from "react";
import AuthStorageService from "services/authStorageService";

export const setTimeToSignOutInLocalStorage = () => {
    const tokens = AuthStorageService.getTokens();

    if (!tokens || !tokens.refreshTokenExpiredDate) {
        return;
    }

    localStorage.setItem(localStorageItems.CHECK_TOKEN_DATE, tokens.refreshTokenExpiredDate.toString());
};

export const setCardNumberWithSpaces = (event: ChangeEvent<HTMLInputElement>) => {
    const inputVal = event.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");
    if (inputNumbersOnly.length > 16) {
        inputNumbersOnly = inputNumbersOnly.substring(0, 16);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
        spacedNumber = splits.join(" ");
    }

    return spacedNumber;
}

export const locationFormatter = (location: { city: string, street: string, country: string }) => `${location?.street ? `${location?.street}, ` : ''}${location?.city ? `${location?.city}, ` : ''}${location?.country ? `${location?.country}, ` : ''}`

export const GTMPageView = (url: string) => {
    interface PageEventProps {
        event: string;
        page: string;
        title: string;
    }

    const pageEvent: PageEventProps = {
        event: 'pageview',
        page: url,
        title: url
    };
    //@ts-ignore
    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
};
