import { FenigeApi } from "features/jobOffers/api/FenigeApi";
import { appRoutes } from "features/routing/routes";
import { IndicatorResultData } from "./indicatorResult";

export const getOffset = () => {
    const date = new Date();
    const timeZone = Intl?.DateTimeFormat?.()?.resolvedOptions?.().timeZone;
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

const browserScreenColorDepthValues = [1, 4, 8, 15, 16, 24, 32, 48];
const browserScreenColorDepth = browserScreenColorDepthValues.find((value) => window.screen.colorDepth > value) || 24;

export const auth = (data: IndicatorResultData) => {
    return FenigeApi.Auth({
        authenticationId: data.authenticationId,
        challengeRedirectUrl: `${window.location.origin}${appRoutes.paymentRedirect}`,
        challengeWindowSize: "S_390X400",
        browserLanguage: navigator.language,
        browserScreenWidth: window.innerWidth,
        browserScreenHeight: window.innerHeight,
        browserTimeZone: getOffset(),
        browserScreenColorDepth,
        browserJavaScriptStatus: "ENABLED",
        browserJavaStatus: "DISABLED",
    });
};
