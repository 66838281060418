import FormDropdown from "features/common/components/FormDropdown";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";

const PHONE_NUMBER_PREFIXES = [
  {
    display: "+48",
    value: "+48",
  },
  {
    display: "+93",
    value: "+93",
  },
  {
    display: "+358",
    value: "+358",
  },
  {
    display: "+355",
    value: "+355",
  },
  {
    display: "+213",
    value: "+213",
  },
  {
    display: "+1684",
    value: "+1684",
  },
  {
    display: "+376",
    value: "+376",
  },
  {
    display: "+244",
    value: "+244",
  },
  {
    display: "+1264",
    value: "+1264",
  },
  {
    display: "+672",
    value: "+672",
  },
  {
    display: "+1268",
    value: "+1268",
  },
  {
    display: "+54",
    value: "+54",
  },
  {
    display: "+374",
    value: "+374",
  },
  {
    display: "+297",
    value: "+297",
  },
  {
    display: "+61",
    value: "+61",
  },
  {
    display: "+43",
    value: "+43",
  },
  {
    display: "+994",
    value: "+994",
  },
  {
    display: "+1242",
    value: "+1242",
  },
  {
    display: "+973",
    value: "+973",
  },
  {
    display: "+880",
    value: "+880",
  },
  {
    display: "+1246",
    value: "+1246",
  },
  {
    display: "+375",
    value: "+375",
  },
  {
    display: "+32",
    value: "+32",
  },
  {
    display: "+501",
    value: "+501",
  },
  {
    display: "+229",
    value: "+229",
  },
  {
    display: "+1441",
    value: "+1441",
  },
  {
    display: "+975",
    value: "+975",
  },
  {
    display: "+591",
    value: "+591",
  },
  {
    display: "+387",
    value: "+387",
  },
  {
    display: "+267",
    value: "+267",
  },
  {
    display: "+55",
    value: "+55",
  },
  {
    display: "+246",
    value: "+246",
  },
  {
    display: "+673",
    value: "+673",
  },
  {
    display: "+359",
    value: "+359",
  },
  {
    display: "+226",
    value: "+226",
  },
  {
    display: "+257",
    value: "+257",
  },
  {
    display: "+855",
    value: "+855",
  },
  {
    display: "+237",
    value: "+237",
  },
  {
    display: "+1",
    value: "+1",
  },
  {
    display: "+238",
    value: "+238",
  },
  {
    display: "+ 345",
    value: "+ 345",
  },
  {
    display: "+236",
    value: "+236",
  },
  {
    display: "+235",
    value: "+235",
  },
  {
    display: "+56",
    value: "+56",
  },
  {
    display: "+86",
    value: "+86",
  },
  {
    display: "+61",
    value: "+61",
  },
  {
    display: "+61",
    value: "+61",
  },
  {
    display: "+57",
    value: "+57",
  },
  {
    display: "+269",
    value: "+269",
  },
  {
    display: "+242",
    value: "+242",
  },
  {
    display: "+243",
    value: "+243",
  },
  {
    display: "+682",
    value: "+682",
  },
  {
    display: "+506",
    value: "+506",
  },
  {
    display: "+225",
    value: "+225",
  },
  {
    display: "+385",
    value: "+385",
  },
  {
    display: "+53",
    value: "+53",
  },
  {
    display: "+357",
    value: "+357",
  },
  {
    display: "+420",
    value: "+420",
  },
  {
    display: "+45",
    value: "+45",
  },
  {
    display: "+253",
    value: "+253",
  },
  {
    display: "+1767",
    value: "+1767",
  },
  {
    display: "+1849",
    value: "+1849",
  },
  {
    display: "+593",
    value: "+593",
  },
  {
    display: "+20",
    value: "+20",
  },
  {
    display: "+503",
    value: "+503",
  },
  {
    display: "+240",
    value: "+240",
  },
  {
    display: "+291",
    value: "+291",
  },
  {
    display: "+372",
    value: "+372",
  },
  {
    display: "+251",
    value: "+251",
  },
  {
    display: "+500",
    value: "+500",
  },
  {
    display: "+298",
    value: "+298",
  },
  {
    display: "+679",
    value: "+679",
  },
  {
    display: "+358",
    value: "+358",
  },
  {
    display: "+33",
    value: "+33",
  },
  {
    display: "+594",
    value: "+594",
  },
  {
    display: "+689",
    value: "+689",
  },
  {
    display: "+241",
    value: "+241",
  },
  {
    display: "+220",
    value: "+220",
  },
  {
    display: "+995",
    value: "+995",
  },
  {
    display: "+49",
    value: "+49",
  },
  {
    display: "+233",
    value: "+233",
  },
  {
    display: "+350",
    value: "+350",
  },
  {
    display: "+30",
    value: "+30",
  },
  {
    display: "+299",
    value: "+299",
  },
  {
    display: "+1473",
    value: "+1473",
  },
  {
    display: "+590",
    value: "+590",
  },
  {
    display: "+1671",
    value: "+1671",
  },
  {
    display: "+502",
    value: "+502",
  },
  {
    display: "+44",
    value: "+44",
  },
  {
    display: "+224",
    value: "+224",
  },
  {
    display: "+245",
    value: "+245",
  },
  {
    display: "+595",
    value: "+595",
  },
  {
    display: "+509",
    value: "+509",
  },
  {
    display: "+379",
    value: "+379",
  },
  {
    display: "+504",
    value: "+504",
  },
  {
    display: "+852",
    value: "+852",
  },
  {
    display: "+36",
    value: "+36",
  },
  {
    display: "+354",
    value: "+354",
  },
  {
    display: "+91",
    value: "+91",
  },
  {
    display: "+62",
    value: "+62",
  },
  {
    display: "+98",
    value: "+98",
  },
  {
    display: "+964",
    value: "+964",
  },
  {
    display: "+353",
    value: "+353",
  },
  {
    display: "+44",
    value: "+44",
  },
  {
    display: "+972",
    value: "+972",
  },
  {
    display: "+39",
    value: "+39",
  },
  {
    display: "+1876",
    value: "+1876",
  },
  {
    display: "+81",
    value: "+81",
  },
  {
    display: "+44",
    value: "+44",
  },
  {
    display: "+962",
    value: "+962",
  },
  {
    display: "+77",
    value: "+77",
  },
  {
    display: "+254",
    value: "+254",
  },
  {
    display: "+686",
    value: "+686",
  },
  {
    display: "+850",
    value: "+850",
  },
  {
    display: "+82",
    value: "+82",
  },
  {
    display: "+965",
    value: "+965",
  },
  {
    display: "+996",
    value: "+996",
  },
  {
    display: "+856",
    value: "+856",
  },
  {
    display: "+371",
    value: "+371",
  },
  {
    display: "+961",
    value: "+961",
  },
  {
    display: "+266",
    value: "+266",
  },
  {
    display: "+231",
    value: "+231",
  },
  {
    display: "+218",
    value: "+218",
  },
  {
    display: "+423",
    value: "+423",
  },
  {
    display: "+370",
    value: "+370",
  },
  {
    display: "+352",
    value: "+352",
  },
  {
    display: "+853",
    value: "+853",
  },
  {
    display: "+389",
    value: "+389",
  },
  {
    display: "+261",
    value: "+261",
  },
  {
    display: "+265",
    value: "+265",
  },
  {
    display: "+60",
    value: "+60",
  },
  {
    display: "+960",
    value: "+960",
  },
  {
    display: "+223",
    value: "+223",
  },
  {
    display: "+356",
    value: "+356",
  },
  {
    display: "+692",
    value: "+692",
  },
  {
    display: "+596",
    value: "+596",
  },
  {
    display: "+222",
    value: "+222",
  },
  {
    display: "+230",
    value: "+230",
  },
  {
    display: "+262",
    value: "+262",
  },
  {
    display: "+52",
    value: "+52",
  },
  {
    display: "+691",
    value: "+691",
  },
  {
    display: "+373",
    value: "+373",
  },
  {
    display: "+377",
    value: "+377",
  },
  {
    display: "+976",
    value: "+976",
  },
  {
    display: "+382",
    value: "+382",
  },
  {
    display: "+1664",
    value: "+1664",
  },
  {
    display: "+212",
    value: "+212",
  },
  {
    display: "+258",
    value: "+258",
  },
  {
    display: "+95",
    value: "+95",
  },
  {
    display: "+264",
    value: "+264",
  },
  {
    display: "+674",
    value: "+674",
  },
  {
    display: "+977",
    value: "+977",
  },
  {
    display: "+31",
    value: "+31",
  },
  {
    display: "+599",
    value: "+599",
  },
  {
    display: "+687",
    value: "+687",
  },
  {
    display: "+64",
    value: "+64",
  },
  {
    display: "+505",
    value: "+505",
  },
  {
    display: "+227",
    value: "+227",
  },
  {
    display: "+234",
    value: "+234",
  },
  {
    display: "+683",
    value: "+683",
  },
  {
    display: "+672",
    value: "+672",
  },
  {
    display: "+1670",
    value: "+1670",
  },
  {
    display: "+47",
    value: "+47",
  },
  {
    display: "+968",
    value: "+968",
  },
  {
    display: "+92",
    value: "+92",
  },
  {
    display: "+680",
    value: "+680",
  },
  {
    display: "+970",
    value: "+970",
  },
  {
    display: "+507",
    value: "+507",
  },
  {
    display: "+675",
    value: "+675",
  },
  {
    display: "+595",
    value: "+595",
  },
  {
    display: "+51",
    value: "+51",
  },
  {
    display: "+63",
    value: "+63",
  },
  {
    display: "+872",
    value: "+872",
  },

  {
    display: "+351",
    value: "+351",
  },
  {
    display: "+1939",
    value: "+1939",
  },
  {
    display: "+974",
    value: "+974",
  },
  {
    display: "+40",
    value: "+40",
  },
  {
    display: "+7",
    value: "+7",
  },
  {
    display: "+250",
    value: "+250",
  },
  {
    display: "+262",
    value: "+262",
  },
  {
    display: "+590",
    value: "+590",
  },
  {
    display: "+290",
    value: "+290",
  },
  {
    display: "+1869",
    value: "+1869",
  },
  {
    display: "+1758",
    value: "+1758",
  },
  {
    display: "+590",
    value: "+590",
  },
  {
    display: "+508",
    value: "+508",
  },
  {
    display: "+1784",
    value: "+1784",
  },
  {
    display: "+685",
    value: "+685",
  },
  {
    display: "+378",
    value: "+378",
  },
  {
    display: "+239",
    value: "+239",
  },
  {
    display: "+966",
    value: "+966",
  },
  {
    display: "+221",
    value: "+221",
  },
  {
    display: "+381",
    value: "+381",
  },
  {
    display: "+248",
    value: "+248",
  },
  {
    display: "+232",
    value: "+232",
  },
  {
    display: "+65",
    value: "+65",
  },
  {
    display: "+421",
    value: "+421",
  },
  {
    display: "+386",
    value: "+386",
  },
  {
    display: "+677",
    value: "+677",
  },
  {
    display: "+252",
    value: "+252",
  },
  {
    display: "+27",
    value: "+27",
  },
  {
    display: "+211",
    value: "+211",
  },
  {
    display: "+500",
    value: "+500",
  },
  {
    display: "+34",
    value: "+34",
  },
  {
    display: "+94",
    value: "+94",
  },
  {
    display: "+249",
    value: "+249",
  },
  {
    display: "+597",
    value: "+597",
  },
  {
    display: "+47",
    value: "+47",
  },
  {
    display: "+268",
    value: "+268",
  },
  {
    display: "+46",
    value: "+46",
  },
  {
    display: "+41",
    value: "+41",
  },
  {
    display: "+963",
    value: "+963",
  },
  {
    display: "+886",
    value: "+886",
  },
  {
    display: "+992",
    value: "+992",
  },
  {
    display: "+255",
    value: "+255",
  },
  {
    display: "+66",
    value: "+66",
  },
  {
    display: "+670",
    value: "+670",
  },
  {
    display: "+228",
    value: "+228",
  },
  {
    display: "+690",
    value: "+690",
  },
  {
    display: "+676",
    value: "+676",
  },
  {
    display: "+1868",
    value: "+1868",
  },
  {
    display: "+216",
    value: "+216",
  },
  {
    display: "+90",
    value: "+90",
  },
  {
    display: "+993",
    value: "+993",
  },
  {
    display: "+1649",
    value: "+1649",
  },
  {
    display: "+688",
    value: "+688",
  },
  {
    display: "+256",
    value: "+256",
  },
  {
    display: "+380",
    value: "+380",
  },
  {
    display: "+971",
    value: "+971",
  },
  {
    display: "+44",
    value: "+44",
  },
  {
    display: "+1",
    value: "+1",
  },
  {
    display: "+598",
    value: "+598",
  },
  {
    display: "+998",
    value: "+998",
  },
  {
    display: "+678",
    value: "+678",
  },
  {
    display: "+58",
    value: "+58",
  },
  {
    display: "+84",
    value: "+84",
  },
  {
    display: "+1284",
    value: "+1284",
  },
  {
    display: "+1340",
    value: "+1340",
  },
  {
    display: "+681",
    value: "+681",
  },
  {
    display: "+967",
    value: "+967",
  },
  {
    display: "+260",
    value: "+260",
  },
  {
    display: "+263",
    value: "+263",
  }
];

interface Props {
  disabled?: boolean;
  value: string;
  onChange: (newValue: string) => void;
}

const PhonePrefixSelector: React.FC<Props> = ({ onChange, children, disabled, value }) => {
  const initialValue = PHONE_NUMBER_PREFIXES[0];
  const [selectedPrefix, setSelectedPrefix] = useState(value ? (PHONE_NUMBER_PREFIXES.find((el) => el.value === value) || initialValue) : initialValue);

  const handleSelect = useCallback(
      (value) => {
        const newValue = PHONE_NUMBER_PREFIXES.find(p => p.value === value);

        if (!newValue) return;

        setSelectedPrefix(newValue);
        onChange && onChange(newValue.value);
      },
      [selectedPrefix],
  );

  useEffect(() => {
    onChange && onChange(selectedPrefix.value);
  }, []);

  return (
      <div className={styles["phone-selector-wrapper"]}>
        <FormDropdown
            id="phone-prefix-selector"
            className={styles["phone-selector-wrapper__selector"]}
            options={PHONE_NUMBER_PREFIXES}
            selected={selectedPrefix}
            onSelect={handleSelect}
            disabled={disabled}
        />
        {children}
      </div>

  )
};

export default PhonePrefixSelector;
