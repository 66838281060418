// import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import {ReactComponent as CalendarIcon} from "assets/icons/calendarIconOrange.svg";
import {ReactComponent as CoinsIcon} from "assets/icons/coins.svg";
import {ReactComponent as BonusIcon} from "assets/icons/getBonusIcon.svg";
import {ReactComponent as WorkTypeIcon} from "assets/icons/orangeBag.svg";
import {ReactComponent as TickCircleIcon} from "assets/icons/orangeTickBox.svg";
import classNames from "classnames";
import useDateFormat from "features/common/hooks/useDateFormat";
import {WorkType} from "features/common/types";
import {EmployeeOfferDetails} from "features/employeeOffers/models";
import {getCreateSalaryTypeMessage} from "features/jobOffers/translationMessages";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    offerDetails: EmployeeOfferDetails;
    ["data-testid"]?: string;
}

const GeneralInfoPart = ({
    offerDetails,
    "data-testid": testId = "employment-conditions-part__employee-offer-details",
}: Props) => {
    const intl = useIntl();
    const getFormattedDate = useDateFormat();
    const createSalaryTypeMessage = getCreateSalaryTypeMessage(intl);

    const {financialConditions: {rewardMinimumValue, rewardMaximumValue, rewardCurrencyCode, paymentRateType, bonusValue, bonusCurrencyCode}} = offerDetails;
    const {workType, jobStartDate, jobEndDate, workDuringHolidays} = offerDetails;

    return (
        <div data-testid={testId} className={styles["employment-conditions-part"]}>
            <h3 className={styles["employment-conditions-part__title"]}>
                <FormattedMessage id="employee-offer-details__employment-conditions" />
            </h3>
            <div className={styles["employment-conditions-part__inner-container"]}>

                <div className={classNames(styles["employment-conditions-part__column"], styles['employment-conditions-part__column--left'])}>
                    <div className={styles["employment-conditions-part__entry-with-icon"]}>
                        <WorkTypeIcon width={32} height={32} className={styles["employment-conditions-part__entry-icon"]} />
                        <div className={styles["employment-conditions-part__entry-text"]}>
                            <FormattedMessage
                                id="employee-offer-details__work-type"
                                values={{ workType: workType }}
                            />
                        </div>
                    </div>
                    <div className={styles["employment-conditions-part__entry-with-icon"]}>
                        <CalendarIcon width={32} height={32} className={styles["employment-conditions-part__entry-icon"]} />
                        <div className={styles["employment-conditions-part__entry-text"]}>
                            {workType === WorkType.Steady ? (
                                <FormattedMessage
                                    id="employee-offer-details__work-start-term"
                                    values={{ jobStartDate: getFormattedDate(jobStartDate) }}
                                />
                            ) : (
                                <FormattedMessage
                                    id="employee-offer-details__work-term"
                                    values={{
                                        jobStartDate: getFormattedDate(jobStartDate),
                                        jobEndDate: getFormattedDate(jobEndDate),
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {workDuringHolidays && (
                        <div className={styles["employment-conditions-part__entry-with-icon"]}>
                            <TickCircleIcon width={32} height={32} className={styles["employment-conditions-part__entry-icon"]} />
                            <div className={styles["employment-conditions-part__entry-text"]}>
                                <FormattedMessage id="employee-offer-details__work-during-holidays" />
                            </div>
                        </div>
                    )}
                </div>
                <div className={classNames(styles["employment-conditions-part__column"], styles['employment-conditions-part__column--right'])}>
                    <div className={styles["employment-conditions-part__entry-with-icon-start"]}>
                        <CoinsIcon width={32} height={32} className={styles["employment-conditions-part__entry-icon"]} />
                        <div className={styles["employment-conditions-part__entry-text-flex"]}>
                            <FormattedMessage id="common__salary" />:&nbsp; 
                            <div>
                                <div>
                                    <span>{intl.formatMessage({ id: 'common__from' })} {rewardMinimumValue}</span>
                                    <span>
                                        {rewardCurrencyCode}/{createSalaryTypeMessage(paymentRateType)}
                                    </span>
                                </div>
                                {rewardMaximumValue && rewardMaximumValue > 0 && <div>
                                    <span>{intl.formatMessage({ id: 'common__to' })} {rewardMaximumValue}</span>
                                    <span>
                                        {rewardCurrencyCode}/{createSalaryTypeMessage(paymentRateType)}
                                    </span>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={styles["employment-conditions-part__entry-with-icon"]}>
                        <BonusIcon width={32} height={32} className={styles["employment-conditions-part__entry-icon"]} />
                        <div className={styles["employment-conditions-part__entry-text"]}>
                            <FormattedMessage id="common__bonus" />:{" "}
                            {bonusValue
                                ? `${bonusValue} ${bonusCurrencyCode}`
                                : "-"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralInfoPart;