import RateEmployee from "features/rating/components/RateEmployee";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
    id?: string;
}

const RateEmployeePage = () => {
    const { id } = useParams<Props>();

    return (
        <div className={styles["rate-employee"]}>
            <div className={styles["rate-employee__inner-container"]}>{id && <RateEmployee id={parseInt(id)} />}</div>
        </div>
    );
};

export default RateEmployeePage;
