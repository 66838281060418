import { selectRecoverPasswordLoading, selectRecoverPasswordLoadingError, selectRecoverPasswordLoadingSuccess } from "features/user/selectors";
import { recoverPasswordAsync } from "features/user/thunks";
import RecoverPassword from "./component";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    recoverPasswordLoading: selectRecoverPasswordLoading(state),
    recoverPasswordLoadingError: selectRecoverPasswordLoadingError(state),
    recoverPasswordLoadingSuccess: selectRecoverPasswordLoadingSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            recoverPasswordAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
