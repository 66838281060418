import { ReactComponent as ExclamationCircleIcon } from "assets/icons/exclamation-circle.svg";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import classNames from "classnames";
import DropdownOptionsWithSection from "features/common/components/DropdownOptionsWithSection";
import DropdownSelectionProvider from "features/common/components/DropdownSelectionProvider";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useResize from "features/common/hooks/useResize";
import { getSectionDisplayNameLookup } from "features/common/translationMessages";
import {
    DropdownOption,
    SectionName,
    Tag,
    TranslatedSectionDropdownOption,
} from "features/common/types";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import FieldMessages from "../FieldMessages";
import styles from "./styles.module.scss";

export type Props<T> = {
    label?: string;
    className?: string;
    placeholder?: string;
    sections: any;
    value: { tags: Tag[]; input: string };
    shouldEmphasizeSearchTerm?: boolean;
    onChange: (tags: Tag[], input: string) => void;
    error?: string | boolean;
    ["data-testid"]?: string;
    disabled?: boolean;
};

const TagInput = <T extends any>({
    label,
    className,
    placeholder,
    sections,
    value,
    shouldEmphasizeSearchTerm,
    onChange,
    error,
    disabled,
    "data-testid": testId,
}: Props<T>) => {
    const intl = useIntl();
    const sectionLookup = getSectionDisplayNameLookup(intl);
    const [expanded, setExpanded] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isFocused, internalSetFocused] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const tagSpanRef = useRef<HTMLDivElement>(null);

    const scrollTagSpanTo = useCallback((offset: "top" | "bottom") => {
        if (tagSpanRef.current) {
            if (offset === "top") {
                tagSpanRef.current.scrollTop = 0;
            } else {
                tagSpanRef.current.scrollTop = tagSpanRef.current.scrollHeight;
            }
        }
    }, []);

    useEffect(() => {
        setIsEmpty(value.tags?.length < 1 && !value.input);
        if (isActive) {
            scrollTagSpanTo("bottom");
        }
    }, [isActive, scrollTagSpanTo, value]);

    useEffect(() => {
        if (isActive) {
            scrollTagSpanTo("bottom");
        } else {
            scrollTagSpanTo("top");
        }
    }, [isActive, scrollTagSpanTo]);

    useResize(() => scrollTagSpanTo("top"), [tagSpanRef]);

    const onClickAway = () => {
        if (expanded) {
            setExpanded(false);
        }
        if (isActive) {
            setIsActive(false);
        }

        scrollTagSpanTo("top");
    };

    const setIsFocused = (focused: boolean) => {
        if (focused) {
            inputRef.current?.focus();
        }
        internalSetFocused(focused);
    };

    const onOptionClick = (option: DropdownOption<T>, section?: string | undefined) => {
        if (!value.tags.find((tag) => tag.id === option.value)) {
            const newTags = [
                ...value.tags,
                { name: option.display as string, id: option.value as string, section: section ?? "" },
            ];
            onChange(newTags, "");
            setIsFocused(true);
        }
        setExpanded(false);
    };

    const onExpandToggle = (event: React.ChangeEvent<HTMLInputElement> & React.MouseEvent<HTMLInputElement>) => {
        setExpanded(event.target.value ? true : false);
        onChange([...value.tags], event.target.value);
    };

    const onRemove = (tagId: string) => {
        if (!value.tags.find((tag) => tag.id === tagId)) {
            return;
        }

        const newTags = value.tags.filter((tag) => tag.id !== tagId);
        onChange(newTags, "");
    };

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setExpanded(event.target.value ? true : false);
        onChange([...value.tags], event.target.value);
    };

    const onTagInputClick = () => {
        setIsFocused(true);
        setIsActive(true);
    };

    const getSections = (): TranslatedSectionDropdownOption<T>[] => {
        return (
            sections ||
            [].map((s: any) => ({
                section: { display: sectionLookup(s.section.value as SectionName), value: s.section.value },
                options: [...s.options],
            }))
        );
    };

    const mappedSections = getSections();

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Backspace" || value.tags.length < 1 || value.input) {
            return;
        }

        value.tags.pop();
        onChange([...value.tags], "");
    };

    return (
        <DropdownSelectionProvider expanded={expanded} onOptionSelected={onOptionClick} sections={mappedSections}>
            {({ selectedOption, onKeyDown: selectionKeyDown, resetSelectedIndex, lastDirection }) => (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div className={className} data-testid={testId}>
                        <div
                            className={classNames(styles["tag-input"], {
                                [styles["tag-input--is-error"]]: error,
                            })}
                            onClick={onTagInputClick}
                        >
                            {(isFocused || !isEmpty || !placeholder) && label && (
                                <div className={styles["tag-input__label-wrapper"]}>
                                    <label className={styles["tag-input__label"]}>{label}</label>
                                </div>
                            )}
                            <div
                                ref={tagSpanRef}
                                className={classNames(
                                    styles["tag-input__span"],
                                    {
                                        [styles["tag-input__span--focused"]]: isFocused || isActive,
                                    },
                                    {
                                        [styles["tag-input__span--block-display"]]: value.tags?.length > 0,
                                    }
                                )}
                            >
                                <div
                                    className={classNames({
                                        [styles["tag-input__tags-container"]]: value.tags?.length > 0 && !isActive,
                                    })}
                                >
                                    {value.tags?.length > 0 &&
                                        value.tags?.map((tag, i) => (
                                            <span className={styles["tag-input__tag"]} key={i}>
                                                {tag.name}
                                                <div
                                                    className={styles["tag-input__remove"]}
                                                    onClick={() => onRemove(tag.id)}
                                                >
                                                    <TimesIcon className={styles["tag-input__remove-icon"]} />
                                                </div>
                                            </span>
                                        ))}
                                </div>
                                <input
                                    type="text"
                                    className={styles["tag-input__input"]}
                                    placeholder={value.tags?.length > 0 || isFocused ? undefined : placeholder}
                                    onClick={onExpandToggle}
                                    onChange={(e) => {
                                        resetSelectedIndex();
                                        onInputChange(e);
                                    }}
                                    value={value.input}
                                    onKeyDown={(e) => {
                                        selectionKeyDown(e);
                                        onKeyDown(e);
                                    }}
                                    onBlur={() => setIsFocused(false)}
                                    ref={inputRef}
                                    size={5}
                                    disabled={disabled}
                                />
                                {error && (
                                    <>

                                        <ExclamationCircleIcon
                                            data-testid={`${testId}__error-icon`}
                                            className={classNames(styles["tag-input__error-icon"], { [styles["input__error-icon--pointer"]]: null })}
                                        />
                                    </>
                                )}
                            </div>
                            {expanded && (
                                <DropdownOptionsWithSection
                                    id="tag-input-id"
                                    onSelectOption={onOptionClick}
                                    sections={mappedSections}
                                    selectedOption={selectedOption}
                                    searchTerm={value.input}
                                    shouldEmphasizeSearchTerm={shouldEmphasizeSearchTerm}
                                    lastDirection={lastDirection}
                                />
                            )}
                        </div>
                        {error && (
                            <>
                                <FieldMessages
                                    data-testid={testId ? `${testId}__error-messages` : undefined}
                                    messages={[error.toString()]}
                                    mode="error"
                                    spaceless={false}
                                />

                            </>
                        )}


                    </div>
                </ClickAwayListener>
            )}
        </DropdownSelectionProvider>
    );
};

export default TagInput;
