import { OfferType } from "features/common/types";
import MyEmployeeOffersComponent from "features/employeeOffers/components/MyEmployeeOffers";
import React from "react";
import styles from "./styles.module.scss";

const MyEmployeeOffers = () => (
    <div className={styles["my-employee-offers"]}>
        <div className={styles["my-employee-offers__inner-container"]}>
            <MyEmployeeOffersComponent offerType={OfferType.Employee} />
        </div>
    </div>
);

export default MyEmployeeOffers;
