import React from 'react'
import styles from "./styles.module.scss";
import { ReactComponent as DotsMenuIcon } from "assets/icons/dotsMenu.svg";
import { FormattedMessage } from "react-intl";

type Props = {
    onEditClick: () => void,
    onDeleteClick: () => void,
    shouldAllowEditAction: boolean,
    shouldAllowDeleteAction: boolean,
    showThreeDotsButton: boolean,
    ["data-testid"]?: string;
}

const ThreeDotsButton = ({ onEditClick, onDeleteClick, shouldAllowEditAction, shouldAllowDeleteAction, "data-testid": testId, showThreeDotsButton }: Props) => {
    const [isListExpanded, toggleExpandActionList] = React.useState<boolean>(false)

    return (
        <>
            {showThreeDotsButton ? (
                <div
                    data-testid={`${testId}__context-menu`}
                    className={styles["single-card__context-menu"]}
                    onClick={() => toggleExpandActionList(!isListExpanded)}
                >
                    <div className={styles["single-card__context-menu-svg"]}>
                        <DotsMenuIcon />
                    </div>
                    {isListExpanded && (
                        <div className={styles["single-card__dropdown-container"]}>
                            <ul className={styles["single-card__dropdown"]}>
                                {shouldAllowEditAction && (
                                    <li
                                        data-testid={`${testId}__edit`}
                                        className={styles["single-card__dropdown-item"]}
                                        onClick={onEditClick}
                                    >
                                        <div>
                                            <FormattedMessage id="single-offer-card__edit" />
                                        </div>
                                    </li>
                                )}
                                {shouldAllowDeleteAction && (
                                    <li
                                        data-testid={`${testId}__delete`}
                                        className={styles["single-card__dropdown-item"]}
                                        onClick={onDeleteClick}
                                    >
                                        <div>
                                            <FormattedMessage id="single-offer-card__delete" />
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            ) : undefined}
        </>
    )
}

export default ThreeDotsButton;