import callAdMobile from "assets/images/advertisement/GetMen_Inwestorzy_Baner_Mobilka.png";
import callAdTablet from "assets/images/advertisement/GetMen_Inwestorzy_Baner_Tablet.png";
import callAdWeb from "assets/images/advertisement/GetMen_Targi_Desktop.png";
import React from "react";
import Advertisement from "../../Advertisement";
import config from "../../../../config";

const bonusAddress = config.GETBONUS_URL;
const investAddress = config.INVEST_URL + "/pl";

const CallAdvertisement = () => {

    const bonusRouteChange = () => {
        window.open(bonusAddress, '_blank', 'noopener,noreferrer');
    }
    const investRouteChange = () => {
        window.open(investAddress, '_blank', 'noopener,noreferrer');
    }

    return (
        <Advertisement web={callAdWeb} tablet={callAdTablet} mobile={callAdMobile} onClickBonus={bonusRouteChange} onClickInvest={investRouteChange} />
    )
}

export default CallAdvertisement
