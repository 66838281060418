import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

import { resetUsers } from "features/adminPanel/actions";
import UsersComponent from "features/adminPanel/components/Users/component";
import {
    selectTotalUsers,
    selectUsers,
    selectUsersLoading,
    selectUsersLoadingError,
    selectBlockUserSuccess,
    selectBlockUserLoading,
    selectBlockUserError,
    selectUnblockUserSuccess,
    selectUnblockUserLoading,
    selectUnblockUserError,
    selectDeleteUserSuccess,
    selectDeleteUserLoading,
    selectDeleteUserError,
    selectDownloadUsersListSuccess,
    selectDownloadUsersListLoading,
    selectDownloadUsersListError,
} from "features/adminPanel/selectors";
import { amendUsersAsync, getUsersAsync, blockUserAsync, deleteUserAsync, downloadUsersListAsync, unblockUserAsync } from "features/adminPanel/thunks";
import { selectUser } from "features/user/selectors";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    users: selectUsers(state),
    usersLoading: selectUsersLoading(state),
    usersLoadingError: selectUsersLoadingError(state),
    totalUsers: selectTotalUsers(state),
    blockUserSuccess: selectBlockUserSuccess(state),
    blockUserLoading: selectBlockUserLoading(state),
    blockUserError: selectBlockUserError(state),
    unblockUserSuccess: selectUnblockUserSuccess(state),
    unblockUserLoading: selectUnblockUserLoading(state),
    unblockUserError: selectUnblockUserError(state),
    deleteUserSuccess: selectDeleteUserSuccess(state),
    deleteUserLoading: selectDeleteUserLoading(state),
    deleteUserError: selectDeleteUserError(state),
    downloadUsersListSuccess: selectDownloadUsersListSuccess(state),
    downloadUsersListLoading: selectDownloadUsersListLoading(state),
    downloadUsersListError: selectDownloadUsersListError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        { getUsersAsync, amendUsersAsync, blockUserAsync, unblockUserAsync, deleteUserAsync, downloadUsersListAsync, resetUsers: () => resetUsers() },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
