import { ReactComponent as ChevronDown } from "assets/icons/chevronDown.svg";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    totalPages: number;
    currentPage: number;
    ["data-testid"]?: string;
    onPageChange: (pageNumber: number) => void;
}

const Pagination = ({ totalPages, currentPage, "data-testid": testId = "pagination", onPageChange }: Props) => (
    <div data-testid={`${testId}__container`} className={classNames(styles["pagination"])}>
        <div className={styles["pagination__page-toggle-row"]}>
            <div
                data-testid={`${testId}__prev-page-button`}
                className={styles["pagination__page-toggle"]}
                onClick={() => onPageChange(currentPage - 1)}
            >
                <ChevronDown />
            </div>
            <div data-testid={`${testId}__page-summary`} className={styles["pagination__page-counter"]}>
                <span>
                    <FormattedMessage id="common__page" />
                </span>
                <span data-testid={`${testId}__page-number`}>{currentPage}</span>
                <span>
                    <FormattedMessage id="common__out-of" />
                </span>
                <span data-testid={`${testId}__total-pages-amount`}>{totalPages}</span>
            </div>
            <div
                data-testid={`${testId}__next-page-button`}
                className={styles["pagination__page-toggle"]}
                onClick={() => onPageChange(currentPage + 1)}
            >
                <ChevronDown />
            </div>
        </div>
    </div>
);

export default Pagination;
