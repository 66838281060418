import useDeviceClass from "features/common/hooks/useDeviceClass";
import useElementWidth from "features/common/hooks/useElementWidth";
import usePrevious from "features/common/hooks/usePrevious";
import {
    generateYearsArray,
    getMonthsAbsoluteDifference,
    getNextMonthWithYear,
} from "features/common/utils";
import { ValidationProps } from "features/common/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import CalendarsContainer from "./CalendarsContainer";
import DateDisplays from "./DateDisplays";
import DesktopCalendars from "./DesktopCalendars";
import MobileCalendars from "./MobileCalendars";

const baseDate = new Date();
const yearPickerRowCount = 10;
const yearPickerRowsBackToDisplay = 1;
const yearsArray: Array<Array<number>> = generateYearsArray(baseDate, yearPickerRowsBackToDisplay, yearPickerRowCount);
const infiniteScrollDetectionOffset = 1200;
const infiniteScrollLoadBatchSize = 4;

export type Props = ValidationProps & {
    id: string;
    date?: Date;
    selectedStartDate?: Date;
    label?: string;
    placeholder?: string;
    error?: string;
    helperMessages?: (string | React.ReactElement)[];
    endDateHelperMessages?: (string | React.ReactElement)[];
    addForwardYears?: boolean;
    className?: string;
    ["data-testid"]?: string;
    onDateSelect?: (date?: Date, endDate?: Date) => void;
    onBlur?: () => void;
    startFromCurrentDate?: boolean;
    startFromPickedDate?: boolean;
    endOnCurrentDate?: boolean;
} & (
        | {
            withRangePicker: true;
            endDate?: Date;
            endDateLabel?: string;
            endDatePlaceholder?: string;
            endDateError?: string;
            endDateHelperMessages?: (string | React.ReactElement)[];
        }
        | {
            withRangePicker?: false;
            endDate?: never;
            endDateLabel?: never;
            endDatePlaceholder?: never;
            endDateError?: never;
            endDateHelperMessages?: never;
        }
    );

const Datepicker = ({
    "data-testid": testId = "datepicker",
    id,
    date,
    endDate,
    selectedStartDate,
    label,
    endDateLabel,
    error,
    endDateError,
    placeholder,
    endDatePlaceholder,
    withRangePicker,
    helperMessages,
    endDateHelperMessages,
    addForwardYears,
    className,
    onDateSelect,
    onBlur,
    startFromCurrentDate,
    startFromPickedDate,
    endOnCurrentDate,
}: Props) => {
    const displayId = `${id}__display`;

    const [baseCurrentEndMonth, baseCurrentEndYear] = getNextMonthWithYear(baseDate.getMonth(), baseDate.getFullYear());
    const [calendarExpanded, setCalendarExpanded] = useState(false);
    const [yearSelectorExpanded, setYearSelectorExpanded] = useState(false);
    const prevCalendarExpanded = usePrevious(calendarExpanded);
    const [selectedDate, setSelectedDate] = useState(date);
    // const [selectedInitialDate, setInitialDate] = useState<Date | undefined>(date);
    const [selectedEndDate, setSelectedEndDate] = useState(endDate);
    const [hoveredDate, setHoveredDate] = useState<Date>();
    const [currentMonth, setCurrentMonth] = useState(date?.getMonth() ?? baseDate.getMonth());
    const [currentEndMonth, setCurrentEndMonth] = useState(endDate?.getMonth() ?? baseCurrentEndMonth);
    const [currentYear, setCurrentYear] = useState(date?.getFullYear() ?? baseDate.getFullYear());
    const [currentEndYear, setCurrentEndYear] = useState(endDate?.getFullYear() ?? baseCurrentEndYear);
    const [monthsToLoadForward, setMonthsToLoadForward] = useState(infiniteScrollLoadBatchSize);
    const [monthsToLoadBack, setMonthsToLoadBack] = useState(infiniteScrollLoadBatchSize);
    const deviceClass = useDeviceClass();
    const toggleWidth = useElementWidth(displayId);
    const calendarsContainerRef = useRef<HTMLDivElement>(null);
    const currentDateCalendarId = `${id}__calendars-container`;

    const onLocalDateSelect = useCallback(
        (year: number, month: number, day?: number, closeAfterChange: boolean = true) => {
            if (!day) {
                return;
            }
            
            const newDate = new Date(year, month, day);
            const isSelectedEndDateBeforeStartDate = selectedDate! >= newDate;

            const isSettingEndDate =
                withRangePicker &&
                !!selectedDate &&
                !selectedEndDate &&
                !(
                    year <= selectedDate.getFullYear() &&
                    month === selectedDate.getMonth() &&
                    day <= selectedDate.getDate()
                ) &&
                !isSelectedEndDateBeforeStartDate;

            if (isSettingEndDate) {
                setSelectedEndDate(newDate);

                if (deviceClass === "desktop") {
                    if (closeAfterChange) {
                        setCalendarExpanded(false);
                    }
                }
            } else {
                setSelectedDate(newDate);
                setSelectedEndDate(undefined);
            }

            if (deviceClass === "desktop") {
                onDateSelect &&
                    onDateSelect(isSettingEndDate ? selectedDate : newDate, isSettingEndDate ? newDate : undefined);
                !withRangePicker && closeAfterChange && setCalendarExpanded(false);
            }
        },
        [deviceClass, onDateSelect, selectedDate, selectedEndDate, withRangePicker]
    );

    // const onNextMonthSelectClick = useCallback(() => {
    //     const [month, year] = getNextMonthWithYear(currentMonth, currentYear);
    //     setCurrentMonth(month);
    //     setCurrentYear(year);
    // }, [currentMonth, currentYear]);
    //
    // const onPrevMonthSelectClick = useCallback(() => {
    //     const [month, year] = getPrevMonthWithYear(currentMonth, currentYear);
    //     setCurrentMonth(month);
    //     setCurrentYear(year);
    // }, [currentMonth, currentYear]);
    //
    // const onNextEndMonthSelectClick = () => {
    //     const [month, year] = getNextMonthWithYear(currentEndMonth, currentEndYear);
    //     setCurrentEndMonth(month);
    //     setCurrentEndYear(year);
    // };
    //
    // const onPrevEndMonthSelectClick = () => {
    //     const [month, year] = getPrevMonthWithYear(currentEndMonth, currentEndYear);
    //     setCurrentEndMonth(month);
    //     setCurrentEndYear(year);
    // };

    const onMonthSelect = (month: number) => {
        setCurrentMonth(month);
        const day = selectedDate?.getDate();
        onLocalDateSelect(currentYear, month, day, false);
    }

    const onYearSelect = (year: number) => {
        setCurrentYear(year);
        const day = selectedDate?.getDate();

        if (year > currentEndYear) {
            const [newMonth, newYear] = getNextMonthWithYear(currentMonth, year);
            setCurrentEndMonth(newMonth);
            setCurrentEndYear(newYear);

            if (selectedDate) {
                onLocalDateSelect(newYear, newMonth, day, false);
                setYearSelectorExpanded(false);
                return;
            }
        }

        if (selectedDate) {
            onLocalDateSelect(year, currentMonth, day, false);
        }

        setYearSelectorExpanded(false);
    };

    const onDateSelectApprove = () => {
        onDateSelect && onDateSelect(selectedDate, selectedEndDate);
        setCalendarExpanded(false);
    };

    const onCalendarsContainerScroll = useCallback(() => {
        const calendarsContainerScrollTop = calendarsContainerRef.current?.scrollTop ?? 0;
        const calendarsContainerHeight =
            (calendarsContainerRef.current?.scrollHeight ?? 0) - (calendarsContainerRef.current?.clientHeight ?? 0);

        if (calendarsContainerHeight - calendarsContainerScrollTop < infiniteScrollDetectionOffset) {
            setMonthsToLoadForward((prev) => prev + infiniteScrollLoadBatchSize);
        } else if (calendarsContainerScrollTop < infiniteScrollDetectionOffset) {
            setMonthsToLoadBack((prev) => prev + infiniteScrollLoadBatchSize);
        }
    }, []);

    useEffect(() => {
        if (!calendarExpanded && prevCalendarExpanded) {
            if (selectedDate && selectedEndDate) {
                setMonthsToLoadForward(
                    getMonthsAbsoluteDifference(selectedDate, selectedEndDate) + infiniteScrollLoadBatchSize
                );
            } else {
                setMonthsToLoadForward(infiniteScrollLoadBatchSize);
            }

            if (selectedDate) {
                setCurrentMonth(selectedDate.getMonth());
                setCurrentYear(selectedDate.getFullYear());
            }

            if (selectedEndDate) {
                setCurrentEndMonth(selectedEndDate.getMonth());
                setCurrentEndYear(selectedEndDate.getFullYear());
            }

            setMonthsToLoadBack(infiniteScrollLoadBatchSize);
            setSelectedDate(date);
            setSelectedEndDate(endDate);
            setCurrentMonth(baseDate.getMonth());
            setCurrentYear(baseDate.getFullYear());
            onBlur && onBlur();
        } else if (calendarExpanded && !prevCalendarExpanded) {
            setSelectedDate(date);

            if (date) {
                setCurrentMonth(date.getMonth());
                setCurrentYear(date.getFullYear());

                if (endDate && date.getTime() < endDate.getTime()) {
                    setSelectedEndDate(endDate);

                    const [endMonth, endYear] =
                        date.getTime() < endDate.getTime()
                            ? [endDate.getMonth(), endDate.getFullYear()]
                            : getNextMonthWithYear(date.getMonth(), date.getFullYear());

                    setCurrentEndMonth(endMonth);
                    setCurrentEndYear(endYear);
                }
            }
        }
    }, [calendarExpanded, date, endDate, onBlur, prevCalendarExpanded, selectedDate, selectedEndDate]);

    useEffect(() => {
        if (currentMonth === currentEndMonth && currentYear === currentEndYear) {
            const [nextMonth, nextYear] = getNextMonthWithYear(currentEndMonth, currentEndYear);
            setCurrentEndMonth(nextMonth);
            setCurrentEndYear(nextYear);
        }
    }, [currentMonth, currentEndMonth, currentYear, currentEndYear]);
   
    return (
        <ClickAwayListener onClickAway={() => setCalendarExpanded(false)}>
            <div className={className}>
                <DateDisplays
                    data-testid={`${testId}__displays`}
                    id={displayId}
                    calendarExpanded={calendarExpanded}
                    deviceClass={deviceClass}
                    date={date}
                    endDate={endDate}
                    label={label}
                    endDateLabel={endDateLabel}
                    placeholder={placeholder}
                    endDatePlaceholder={endDatePlaceholder}
                    error={error}
                    endDateError={endDateError}
                    helperMessages={helperMessages}
                    endDateHelperMessages={endDateHelperMessages}
                    withRangePicker={withRangePicker}
                    onClick={() => {

                        if (!!startFromPickedDate) {
                            if (startFromPickedDate && selectedStartDate) {
                            setCalendarExpanded((prev) => !prev)
                            } else {
                                console.log("wpisz datę") 
                            }
                        } else if (!startFromPickedDate && (startFromCurrentDate || endOnCurrentDate)) {
                            setCalendarExpanded((prev) => !prev)
                        }
                    }}
                />
                {calendarExpanded && (
                    <CalendarsContainer
                        data-testid={testId}
                        deviceClass={deviceClass}
                        calendarsContainerRef={calendarsContainerRef}
                        withRangePicker={withRangePicker}
                        toggleWidth={toggleWidth}
                        setCalendarExpanded={setCalendarExpanded}
                        onCalendarsContainerScroll={onCalendarsContainerScroll}
                        onDateSelectApprove={onDateSelectApprove}
                    >
                        {deviceClass !== "desktop" ? (
                            <MobileCalendars
                                data-testid={`${testId}__mobile-calendars`}
                                withRangePicker={withRangePicker}
                                date={selectedDate}
                                startFromCurrentDate={startFromCurrentDate}
                                endOnCurrentDate={endOnCurrentDate}
                                endDate={selectedEndDate}
                                baseDate={baseDate}
                                selectedDate={selectedStartDate}
                                startFromPickedDate = {startFromPickedDate}
                                currentMonth={currentMonth}
                                currentYear={currentYear}
                                monthsToLoadBack={monthsToLoadBack}
                                monthsToLoadForward={monthsToLoadForward}
                                yearsArray={yearsArray}
                                yearSelectorExpanded={yearSelectorExpanded}
                                currentDateCalendarId={currentDateCalendarId}
                                containerRef={calendarsContainerRef}
                                onLocalDateSelect={onLocalDateSelect}
                                onMonthSelect={onMonthSelect}
                                onYearSelect={onYearSelect}
                                onEndMonthSelect={setCurrentEndMonth}
                                onEndYearSelect={setCurrentEndYear}
                                setYearSelectionExpandState={setYearSelectorExpanded}
                                addForwardYears={addForwardYears}
                            />
                        ) : (
                            <DesktopCalendars
                                data-testid={testId}
                                id={id}
                                startFromCurrentDate={startFromCurrentDate}
                                endOnCurrentDate={endOnCurrentDate}
                                currentMonth={currentMonth}
                                currentEndMonth={currentEndMonth}
                                currentYear={currentYear}
                                currentEndYear={currentEndYear}
                                yearsArray={yearsArray}
                                yearSelectorExpanded={yearSelectorExpanded}
                                baseDate={baseDate}
                                date={selectedDate}
                                endDate={selectedEndDate}
                                selectedDate={selectedStartDate}   
                                hoveredDate={hoveredDate}
                                startFromPickedDate = {startFromPickedDate}
                                withRangePicker={withRangePicker}
                                setYearSelectorExpanded={setYearSelectorExpanded}
                                setHoveredDate={setHoveredDate}
                                onMonthSelect={onMonthSelect}
                                onYearSelect={onYearSelect}
                                onEndMonthSelect={setCurrentEndMonth}
                                onEndYearSelect={setCurrentEndYear}
                                onLocalDateSelect={onLocalDateSelect}
                                addForwardYears={addForwardYears}
                                
                            />
                        )}
                    </CalendarsContainer>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default Datepicker;
