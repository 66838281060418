const mapStringToArrayBuffer = (source: string) => {
    const buffer = new ArrayBuffer(source.length * 2);
    const bufferView = new Uint16Array(buffer);

    for (let i = 0; i < source.length; i++) {
        bufferView[i] = source.charCodeAt(i);
    }

    return bufferView;
};

export default mapStringToArrayBuffer;
