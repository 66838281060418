import classNames from "classnames";
import { appRoutes } from "features/routing/routes";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    className?: string;
    customMessage?: string;
}

const SignInMessage = ({ className, "data-testid": testId, customMessage }: Props) => {
    const history = useHistory();

    const handleLoginClick = useCallback(
        (event) => {
            event.preventDefault();
            history.push(appRoutes.login);
        },
        [history]
    );

    return (
        <div data-testid={testId} className={classNames(styles["sign-in-message"], className)}>
            <a
                data-testid={testId ? `${testId}__link` : undefined}
                className={styles["sign-in-message__link"]}
                onClick={handleLoginClick}
                href="/"
            >
                <FormattedMessage id="offer-item__login-container-sign-in" />
            </a>
            <span>{customMessage || <FormattedMessage id="offer-item__login-container-message" />}</span>
        </div>
    );
};

export default SignInMessage;
