import { selectCityDetails, selectCountries, selectBranches } from "features/common/selectors";
import { getCityDetailsAsync } from "features/common/thunks";
import { selectMyProfileCardDetailsUpdated } from "features/myProfile/selectors";
import { updateMyProfileCardAsync } from "features/myProfile/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import DetailsSection from "./component";

const mapStateToProps = (state: RootState) => ({
    cityDetails: selectCityDetails(state),
    countries: selectCountries(state),
    branches: selectBranches(state),
    myProfileCardDetailsUpdated: selectMyProfileCardDetailsUpdated(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            updateMyProfileCardAsync,
            getCityDetailsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSection);
