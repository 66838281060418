import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./styles.module.scss";

interface OrderData {
    name: string;
    amount: number;
    total: number;
    currency: string;
}

export interface Props {
    order: OrderData[];
}

const OrderSection = ({ order }: Props) => {
    return (
        <div className={styles["payment-order-section"]}>
            <div className={styles["payment-order-section__header"]}>
                <div className={styles["payment-order-section__header-cell"]}>
                    <FormattedMessage id="payment__package-name" />
                </div>
                <div className={styles["payment-order-section__mobile-row"]}>
                    <div className={styles["payment-order-section__header-cell"]}>
                        <FormattedMessage id="payment__amount" />
                    </div>
                    <div className={styles["payment-order-section__header-cell"]}>
                        <FormattedMessage id="payment__total" />
                    </div>
                </div>
            </div>
            {order.map((item) => (
                <div className={styles["payment-order-section__row"]}>
                    <div className={styles["payment-order-section__cell"]}>{item.name}</div>
                    <div className={styles["payment-order-section__mobile-row"]}>
                        <div className={styles["payment-order-section__cell"]}>{item.amount}</div>
                        <div className={styles["payment-order-section__cell"]}>{item.total} {item.currency}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrderSection;
