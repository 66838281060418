import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

import {
    selectConversations,
    selectConversationsLoading,
    selectConversationsLoadingError,
} from "features/messages/selectors";
import { getConversationsAsync } from "features/messages/thunks";
import {
    selectMyApplications,
    selectMyApplicationsError,
    selectMyApplicationsLoading,
} from "features/myProfile/selectors";
import { getMyApplicationsAsync } from "features/myProfile/thunks";
import { selectUserId } from "features/user/selectors";

import DetailsActionsBox from "./component";
import { selectSendDedicationLoading, selectSendDedicationSuccess, selectSendDedicationError } from "../../../../jobOffers/selectors";
import { resetSendDedicationJobOfferState } from "features/jobOffers/actions";

const mapStateToProps = (state: RootState) => ({
    userId: selectUserId(state),
    conversations: selectConversations(state),
    conversationsLoading: selectConversationsLoading(state),
    conversationsLoadingError: selectConversationsLoadingError(state),
    myApplications: selectMyApplications(state),
    myApplicationsLoading: selectMyApplicationsLoading(state),
    myApplicationsError: selectMyApplicationsError(state),
    sendDedicationLoading: selectSendDedicationLoading(state),
    sendDedicationError: selectSendDedicationError(state),
    sendDedicationSuccess: selectSendDedicationSuccess(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getConversationsAsync,
            getMyApplicationsAsync,
            resetSendDedicationJobOfferState: () => resetSendDedicationJobOfferState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DetailsActionsBox);
