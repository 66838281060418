import { ApiError } from "api/utils";
import { ConversationDetailsResponseDto, ConversationsDto } from "features/messages/dtos";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const getConversations = createAsyncAction(
    "GET_CONVERSATIONS_REQUEST",
    "GET_CONVERSATIONS_SUCCESS",
    "GET_CONVERSATIONS_FAILURE"
)<void, ConversationsDto, ApiError>();

export const amendConversations = createAsyncAction(
    "AMEND_CONVERSATIONS_REQUEST",
    "AMEND_CONVERSATIONS_SUCCESS",
    "AMEND_CONVERSATIONS_FAILURE"
)<void, ConversationsDto, ApiError>();

export const getConversationDetails = createAsyncAction(
    "GET_CONVERSATION_DETAILS_REQUEST",
    "GET_CONVERSATION_DETAILS_SUCCESS",
    "GET_CONVERSATION_DETAILS_FAILURE"
)<void, ConversationDetailsResponseDto, ApiError>();

export const sendMessage = createAsyncAction("SEND_MESSAGE_REQUEST", "SEND_MESSAGE_SUCCESS", "SEND_MESSAGE_FAILURE")<
    void,
    void,
    ApiError
>();

export const resetMessages = createAction("RESET_MESSAGES")<void>();
export const resetMessagesThread = createAction("RESET_MESSAGES_THREAD")<void>();

type MessagesAction =
    | ActionType<typeof getConversations>
    | ActionType<typeof amendConversations>
    | ActionType<typeof getConversationDetails>
    | ActionType<typeof sendMessage>
    | ActionType<typeof resetMessages>
    | ActionType<typeof resetMessagesThread>;

export default MessagesAction;
