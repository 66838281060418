import { ApiError } from "api/utils";
import { amendPageToState } from "features/common/utils";
import ModeratorPanelAction, {
    amendOffers,
    findOffersToModerate,
    putActionOfferRequest,
} from "features/moderatorPanel/action";
import produce from "immer";
import { getType } from "typesafe-actions";
import { OfferDto, OfferRequestDto } from "../dtos";
import { mapOffersDtoToOffersList } from "../mappers";

export interface ModeratorPanelState {
    offers?: (OfferDto | undefined)[];
    offersLoading: boolean;
    offersLoadingError?: ApiError;
    totalOffers?: number,
    actionOfferRequest?: OfferRequestDto,
    actionOfferLoading: boolean,
    actionOfferLoadingError?: ApiError

}

const initialState: ModeratorPanelState = {
    offers: undefined,
    offersLoading: false,
    offersLoadingError: undefined,
    totalOffers: 0,
    actionOfferRequest: undefined,
    actionOfferLoading: true,
    actionOfferLoadingError: undefined
}

const reducer = (
    state = initialState,
    action: ModeratorPanelAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(findOffersToModerate.request):
                draft.offers = undefined;
                draft.offersLoading = true;
                draft.offersLoadingError = undefined;
                draft.totalOffers = undefined;
                break;
            case getType(findOffersToModerate.success):
                draft.offers = mapOffersDtoToOffersList(action.payload);
                draft.offersLoading = false;
                draft.offersLoadingError = undefined;
                draft.totalOffers = action.payload.data.totalPages;
                break;
            case getType(findOffersToModerate.failure || amendOffers.failure):
                draft.offers = undefined;
                draft.offersLoading = false;
                draft.offersLoadingError = action.payload;
                draft.totalOffers = undefined;
                break;
            case getType(amendOffers.success):
                draft.offers = amendPageToState(
                    mapOffersDtoToOffersList(action.payload),
                    action.payload.data.pageable.size,
                    action.payload.data.pageable.pageNumber,
                    state.offers
                );
                draft.offersLoading = false;
                draft.offersLoadingError = undefined;
                draft.totalOffers = action.payload.data.totalPages;
                break;
            case getType(putActionOfferRequest.request):
                draft.actionOfferRequest = undefined;
                draft.actionOfferLoading = true;
                draft.actionOfferLoadingError = undefined;
                break;
            case getType(putActionOfferRequest.success):
                draft.actionOfferRequest = action.payload;
                draft.actionOfferLoading = false;
                draft.actionOfferLoadingError = undefined;
                break;
            case getType(putActionOfferRequest.failure):
                draft.actionOfferRequest = undefined;
                draft.actionOfferLoading = false;
                draft.actionOfferLoadingError = action.payload;
                break;
        }
    });
};

export default reducer;
