import { LocationSuggestionDto } from "features/common/dtos";
import { LocationOption, SectionName } from "features/common/types";

const mapLocationSuggestionDtoToCityDetails = (dto: LocationSuggestionDto): LocationOption<string> | undefined => {
    if (dto.cities) {
        const fetchedLocation = dto.cities[0];

        return {
            input: fetchedLocation.name,
            option: {
                additionalData: fetchedLocation.country,
                display: fetchedLocation.name,
                value: fetchedLocation.locationId,
            },
            section: SectionName.Cities,
        };
    }
};

export default mapLocationSuggestionDtoToCityDetails;
