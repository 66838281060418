import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import { OfferRequestDto, OffersDto } from "features/moderatorPanel/dtos";
import { mapGetOffersFetchParamsToQueryParameters } from "../mappers";
import { GetOfferFetchParams } from "../types";

export class ModeratorPanelApi extends Api {
    getOffersAsync = (fetchParams: GetOfferFetchParams, cancelToken?: CancelToken): Promise<OffersDto> => {
        console.log(`${config.apiRoutes.GET_OFFERS_TO_MODERATE}${mapGetOffersFetchParamsToQueryParameters(fetchParams)}`)
        return this.get<OffersDto>(`${config.apiRoutes.GET_OFFERS_TO_MODERATE}${mapGetOffersFetchParamsToQueryParameters(fetchParams)}`,
            cancelToken);

    };

    putActionOfferRequestAsync = (offerId: number, body: OfferRequestDto, cancelToken?: CancelToken): Promise<OfferRequestDto> => {
        return this.put<OfferRequestDto, OfferRequestDto>(`${config.apiRoutes.PUT_OFFER}/${offerId}`, body, cancelToken);
    };

    endActionOfferRequestAsync = (offerId: number, cancelToken?: CancelToken): Promise<void> => {
        return this.put<any, void>(`${config.apiRoutes.END_OFFER}/${offerId}/end-job-offer`,  cancelToken);
    };
}
export default new ModeratorPanelApi();


