import { RequirementListOption } from "features/jobOffers/types";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    values: (RequirementListOption | undefined)[];
    ["data-testid"]?: string;
}

const ListRequirementArea = ({ values, "data-testid": testId = "requirement-list" }: Props) => {
    return (
        <div className={styles["list-requirement-area__container"]}>
            {values.map(
                (value, index) =>
                    value &&
                    (value.icon ? (
                        <div
                            data-testid={`${testId}__container`}
                            key={"container_" + value.label}
                            className={styles["list-requirement-area__container-no-comma"]}
                        >
                            <div className={styles["list-requirement-area__container-icon"]}>{value.icon}</div>
                            <div>{value.label}</div>
                        </div>
                    ) : (
                        <div
                            data-testid={`${testId}__container`}
                            className={
                                index !== values.length - 1 ? styles["list-requirement-area__container-with-comma"] : ""
                            }
                            key={"label_" + value.label}
                        >
                            {value.label}
                        </div>
                    ))
            )}
        </div>
    );
};

export default ListRequirementArea;
