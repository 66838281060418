import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

type Chunk = boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
const strongValue = { strong: (chunks: Chunk) => <strong>{chunks}</strong> }

const Regulations = () => {

    return (
        <div className={styles["regulations"]}>

            <h1><FormattedMessage id="regulation" /></h1>

            <h2><FormattedMessage id="regulation_sections" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_definitions" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions" /></li>
                <li><FormattedMessage id="regulation_general_terms" /></li>
                <li><FormattedMessage id="regulation_technical_conditions" /></li>
                <li><FormattedMessage id="regulation_account_and_business_card" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements" /></li>
                <li><FormattedMessage id="regulation_main_services" /></li>
                <li><FormattedMessage id="regulation_bonus_service" /></li>
                <li><FormattedMessage id="regulation_responsibility" /></li>
                <li><FormattedMessage id="regulation_complaints" /></li>
                <li><FormattedMessage id="regulation_feedback" /></li>
                <li><FormattedMessage id="regulation_final_provisions" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_appendices" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_privacy_policy" /></li>
                <li><FormattedMessage id="regulation_cookies_policy" /></li>
                <li><FormattedMessage id="regulation_price_list" /></li>
                <li><FormattedMessage id="regulation_fenige_rules" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_definitions" /></h2>
            <ol>
                <li>
                    <FormattedMessage id="regulation_definitions_content" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_general_terms_def" values={strongValue} /></li>
                        <li><FormattedMessage id="regulation_portal_owner_def" values={strongValue} /></li>
                        <li><FormattedMessage id="regulation_portal_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_account_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_business_card_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_guest_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_user_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_employer_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_employee_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_coordinator_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_registration_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_authentication_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_job_offer_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_reply_job_offer_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_employee_announcement_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_reply_employee_announcement_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_category_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_contract_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_bonus_payment_operator_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_services_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_basic_services_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_bonus_services_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_bonus_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_messages_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_getcall_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_price_list_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_payment_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_privacy_policy_def" values={strongValue} />
                        </li>
                        <li><FormattedMessage id="regulation_cookie_policy_def" values={strongValue} />
                        </li>
                    </ol>
                </li>
                <li><FormattedMessage id="regulation_notes_on_terms" />
                </li>
            </ol>

            <h2><FormattedMessage id="regulation_introductory_provisions" /></h2>

            <ol>
                <li><FormattedMessage id="regulation_introductory_provisions_content" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_1" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_2" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_3" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_4" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_5" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_6" /></li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_7" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_introductory_provisions_content_7.1" /> </li>
                        <li><FormattedMessage id="regulation_introductory_provisions_content_7.2" /> </li>
                        <li><FormattedMessage id="regulation_introductory_provisions_content_7.3" /> </li>
                    </ol>
                </li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_8" /> </li>
                <li><FormattedMessage id="regulation_introductory_provisions_content_9" /> </li>

            </ol>

            <h2><FormattedMessage id="regulation_general_terms" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_general_terms_content" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_1" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_2" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_3" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_4" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_5" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_6" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_7" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_8" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_9" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_10" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_11" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_12" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_13" /></li>
                <li><FormattedMessage id="regulation_general_terms_content_14" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_technical_conditions" /></h2>
            <ol>
                <li>
                    <FormattedMessage id="regulation_technical_conditions_content" />
                    <ol type='a'>

                        <li><FormattedMessage id="regulation_technical_conditions_content_1" /></li>
                        <li>
                            <FormattedMessage id="regulation_technical_conditions_content_2" />
                            <ol type='i'>
                                <li><FormattedMessage id="regulation_technical_conditions_content_3" /></li>
                                <li><FormattedMessage id="regulation_technical_conditions_content_4" /></li>
                                <li><FormattedMessage id="regulation_technical_conditions_content_5" /></li>
                                <li><FormattedMessage id="regulation_technical_conditions_content_6" /></li>
                            </ol>
                        </li>
                        <li><FormattedMessage id="regulation_technical_conditions_content_7" /></li>
                        <li><FormattedMessage id="regulation_technical_conditions_content_8" /></li>
                    </ol>
                </li>

                <li><FormattedMessage id="regulation_technical_conditions_content_9" /></li>
                <li><FormattedMessage id="regulation_technical_conditions_content_10" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_account_and_business_card" /></h2>
            <ol>
                <li>
                    <h3><FormattedMessage id="regulation_account_and_business_card_context" /></h3>
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_1" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_2" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_3" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_4" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_5" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_6" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_7" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_8" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_9" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_10" /></li>
                    </ol>
                </li>
                <li>
                    <h3><FormattedMessage id="regulation_account_and_business_card_context_11" /></h3>
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_12" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_13" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_14" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_15" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_16" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_17" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_18" /></li>
                        <li><FormattedMessage id="regulation_account_and_business_card_context_19" /></li>
                    </ol>
                </li>
            </ol>

            <h2><FormattedMessage id="regulation_job_offers_and_employee_announcements" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_1" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_2" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_3" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_4" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_5" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_6" /></li>

                <li>
                    <FormattedMessage id="regulation_job_offers_and_employee_announcements_context_7" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_8" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_9" /></li>
                    </ol>
                </li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_10" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_11" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_12" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_13" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_14" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_15" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_16" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_17" /></li>
                    </ol>
                </li>
                <li>
                    <FormattedMessage id="regulation_job_offers_and_employee_announcements_context_18" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_19" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_20" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_21" /></li>
                        <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_22" /></li>
                    </ol>
                </li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_23" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_24" /></li>
                <li><FormattedMessage id="regulation_job_offers_and_employee_announcements_context_25" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_1" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_2" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_3" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_4" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_5" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_6" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_7" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_8" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_9" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_10" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_11" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_12" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_13" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_14" /></li>
                <li><FormattedMessage id="regulation_rules_for_posting_offers_and_announcements_context_15" /></li>

            </ol>

            <h2><FormattedMessage id="regulation_main_services" /></h2>
            <ol>
                <li>
                    <FormattedMessage id="regulation_main_services_context" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_main_services_context_1" /></li>
                        <li><FormattedMessage id="regulation_main_services_context_2" /></li>
                        <li><FormattedMessage id="regulation_main_services_context_3" /></li>
                        <li><FormattedMessage id="regulation_main_services_context_4" /></li>
                        <li><FormattedMessage id="regulation_main_services_context_5" /></li>
                        <li><FormattedMessage id="regulation_main_services_context_6" /></li>
                    </ol>
                </li>
                <li><FormattedMessage id="regulation_main_services_context_7" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_bonus_service" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_bonus_service_context_1" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_2" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_3" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_4" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_5" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_6" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_7" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_8" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_9" /></li>
                <li><FormattedMessage id="regulation_bonus_service_context_10" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_responsibility" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_responsibility_context" /></li>
                <li><FormattedMessage id="regulation_responsibility_context_1" /></li>
                <li><FormattedMessage id="regulation_responsibility_context_2" /></li>
                <li><FormattedMessage id="regulation_responsibility_context_3" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_complaints" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_complaints_context" /></li>
                <li><FormattedMessage id="regulation_complaints_context_1" /></li>
                <li><FormattedMessage id="regulation_complaints_context_2" /></li>
                <li><FormattedMessage id="regulation_complaints_context_3" />
                    <ol type='a'>
                        <li><FormattedMessage id="regulation_complaints_context_4" /></li>
                        <li><FormattedMessage id="regulation_complaints_context_5" /></li>
                        <li><FormattedMessage id="regulation_complaints_context_6" /></li>
                    </ol></li>
                <li><FormattedMessage id="regulation_complaints_context_7" /></li>
                <li><FormattedMessage id="regulation_complaints_context_8" /></li>
                <li><FormattedMessage id="regulation_complaints_context_9" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_feedback" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_feedback_context" /></li>
                <li><FormattedMessage id="regulation_feedback_context_1" /></li>
                <li><FormattedMessage id="regulation_feedback_context_2" /></li>
                <li><FormattedMessage id="regulation_feedback_context_3" /></li>
                <li><FormattedMessage id="regulation_feedback_context_4" /></li>
                <li><FormattedMessage id="regulation_feedback_context_5" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_final_provisions" /></h2>
            <ol>
                <li><FormattedMessage id="regulation_final_provisions_context_1" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_2" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_3" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_4" /></li>
                <ol type='a'>
                    <li><FormattedMessage id="regulation_final_provisions_context_4.1" /></li>
                    <li><FormattedMessage id="regulation_final_provisions_context_4.2" /></li>
                    <li><FormattedMessage id="regulation_final_provisions_context_4.3" /></li>
                    <li><FormattedMessage id="regulation_final_provisions_context_4.4" /></li>
                    <li><FormattedMessage id="regulation_final_provisions_context_4.5" /></li>
                    <li><FormattedMessage id="regulation_final_provisions_context_4.6" /></li>
                </ol>
                <li><FormattedMessage id="regulation_final_provisions_context_5" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_6" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_7" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_8" /></li>
                <li><FormattedMessage id="regulation_final_provisions_context_9" /></li>
            </ol>

            <h2><FormattedMessage id="regulation_price_list" /></h2>
            <FormattedMessage id="regulation_price_list_aggregate" />
        </div>

    );
};



export default Regulations;
