import { JobExperience, Requirement, RequirementLanguage } from "features/jobOffers/types";

export type FileType = ".png" | ".jpg" | ".jpeg" | ".heic";

export interface ValidationProps {
    isInvalid?: boolean;
    error?: string | React.ReactElement;
    withValidation?: boolean;
}

export type DeviceClass = "smartphone" | "tablet" | "desktop";

export type ComponentBreakpoint = {
    search: number;
    tag: number;
};

export const ScrollDropdownBreakpoint = {
    smartphone: {
        search: 130,
        tag: 190,
    },
    tablet: {
        search: 130,
        tag: 130,
    },
    desktop: {
        search: 190,
        tag: 190,
    },
} as Record<DeviceClass, ComponentBreakpoint>;

export interface DictionaryItem {
    id: number | string;
    name: string;
    category_name?: any;
    branches?: any;
}

export interface DropdownOption<T> {
    display?: string | React.ReactNode;
    value: T;
    additionalData?: string;
    section?: Section;
    disabled?: boolean;
    code?: string;
}

export type TranslatedSectionDropdownOption<T extends any> = {
    section: Section;
    options: DropdownOption<T>[];
};

export interface Section {
    value: string;
    display?: string;
}

export interface RadioButtonOption<T> {
    label: string;
    value: T;
}

export interface Tag {
    id: string;
    name: string;
    section: string;
    isWithoutDetails?: boolean;
}

export enum SectionName {
    Positions = "positions",
    Branches = "branches",
    Company = "companies",
    Cities = "cities",
    Regions = "regions",
    Countries = "countries",
    Undefined = "Undefined",
}

export enum YesNoType {
    No = 0,
    Yes,
}

export enum OfferType {
    Job = 0,
    Employee,
    Promoted = 2,
    All,
}

export enum MessageType {
    JobOffer,
    EmployeeOffer,
    JobApplication,
}

export type Filter = DictionaryItem;

export type SearchForm = {
    city?: string;
    term: { input: string; option: DropdownOption<string>; section: string; };
    location: { tags: Tag[]; input: string; };
    placeId?: string;
    distance: DropdownOption<number>;
};

export interface SectionDropdownOption<T> {
    section: string;
    options: DropdownOption<T>[];
}

export interface FilterMatchCount<T> {
    code: T;
    matchCount: number;
}

export interface SearchTermSuggestion {
    searchTermSuggestions: TranslatedSectionDropdownOption<string>[];
}

export interface LocationSuggestion {
    locationSuggestions: TranslatedSectionDropdownOption<string>[];
}

export interface Image {
    file?: File;
    fileUrl: string;
    fileName?: string;
}

export interface LocationOption<T> {
    input: string;
    option: DropdownOption<T>;
    section: string;
}

export interface Card {
    branchCode?: number;
    branchText?: string;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    id: string;
    krazCert?: string;
    phoneNumber?: string;
    phonePrefix?: string;
    logo?: string;
    mainPhoto?: string;
    photos?: string[];
    locationDTO: {
        id?: number;
        city?: string;
        country?: string;
        location?: {
            latitude?: number;
            longitude?: number;
        };
        northeast?: {
            latitude?: number;
            longitude?: number;
        };
        southwest?: {
            latitude?: number;
            longitude?: number;
        };
        regionLevel1?: string;
        regionLevel2?: string;
        regionLevel3?: string;
        street?: string;
        number?: string;
    };
    placeId?: string;
    street?: string;
    number?: string;
    city?: string;
    country?: string;
    taxIdentificationNumber?: string;
    type: CardType;
    description?: string;
    email?: string;
    jobExperience: JobExperience[];
    languageRequirements?: RequirementLanguage[];
    requirements?: Requirement[];
    isFavoriteEmployee: boolean;
    isFavoriteEmployer: boolean;
}

export interface LocationOption<T> {
    input: string;
    option: DropdownOption<T>;
    section: string;
}

export enum WorkType {
    Steady = "STEADY",
    SeasonalTemporary = "SEASONAL_TEMPORARY",
    Odd = "ODD",
}

export enum CardType {
    EmployeeCompany = 0,
    EmployeePrivate,
    EmployerCompany,
    EmployerPrivate,
}

export enum UserRole {
    Administrator,
    User,
    Moderator
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export type InputType = "text" | "number" | "integer" | "password";

export type FormStepperStep = {
    name: string;
    errors: string[];
};

export enum SortDirection {
    Ascending,
    Descending,
}
