import React from "react";
import { useParams } from "react-router-dom";

import CreateEmployeeOfferComponent from "features/employeeOffers/components/CreateEmployeeOffer";

import styles from "./styles.module.scss";

interface QueryParams {
    id: string;
}

const CreateEmployeeOffer = () => {
    const queryParams = useParams<QueryParams>();

    return (
        <div className={styles["create-employee-offer"]}>
            <div className={styles["create-employee-offer__inner-container"]}>
                <CreateEmployeeOfferComponent offerId={queryParams.id} />
            </div>
        </div>
    );
};

export default CreateEmployeeOffer;
