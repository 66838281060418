import { ApiError } from "api/utils";
import RatingAction, {
    rateEmployee,
    rateEmployer,
    resetRateEmployeeState,
    resetRateEmployerState,
    rateEmployerSummary,
    rateEmployeeSummary,
} from "features/rating/actions";
import produce from "immer";
import { getType } from "typesafe-actions";
import { RatePreviewResponse } from "../models";

export interface RatingState {
    employerRatingLoading: boolean;
    rateEmployerSuccess?: boolean;
    ratingEmployerError?: ApiError;
    employeeRatingLoading: boolean;
    rateEmployeeSuccess?: boolean;
    ratingEmployeeError?: ApiError;
    employeeRatingSummaryLoading: boolean;
    employeeRatingSummarySuccess?: boolean;
    employeeRatingSummaryError?: ApiError;
    employerRatingSummaryLoading: boolean;
    employerRatingSummarySuccess?: boolean;
    employerRatingSummaryError?: ApiError;
    employerRatingSummaryData?: RatePreviewResponse;
    employeeRatingSummaryData?: RatePreviewResponse;
}

const initialState: RatingState = {
    employerRatingLoading: false,
    employeeRatingLoading: false,
    employeeRatingSummaryLoading: false,
    employerRatingSummaryLoading: false,
    employerRatingSummaryData: undefined,
    employeeRatingSummaryData: undefined,
};

const reducer = (state: RatingState = initialState, action: RatingAction) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(rateEmployer.request):
                draft.employerRatingLoading = true;
                draft.rateEmployerSuccess = false;
                draft.ratingEmployerError = undefined;
                break;
            case getType(rateEmployer.success):
                draft.employerRatingLoading = false;
                draft.rateEmployerSuccess = true;
                draft.ratingEmployerError = undefined;
                break;
            case getType(rateEmployer.failure):
                draft.employerRatingLoading = false;
                draft.rateEmployerSuccess = false;
                draft.ratingEmployerError = action.payload;
                break;
            case getType(rateEmployee.request):
                draft.employeeRatingLoading = true;
                draft.rateEmployeeSuccess = false;
                draft.ratingEmployeeError = undefined;
                break;
            case getType(rateEmployee.success):
                draft.employeeRatingLoading = false;
                draft.rateEmployeeSuccess = true;
                draft.ratingEmployeeError = undefined;
                break;
            case getType(rateEmployee.failure):
                draft.employeeRatingLoading = false;
                draft.rateEmployeeSuccess = false;
                draft.ratingEmployeeError = action.payload;
                break;
            case getType(rateEmployerSummary.request):
                draft.employerRatingSummaryLoading = false;
                draft.employerRatingSummarySuccess = false;
                draft.employerRatingSummaryError = undefined;
                break;
            case getType(rateEmployerSummary.success):
                draft.employerRatingSummaryLoading = false;
                draft.employerRatingSummarySuccess = true;
                draft.employerRatingSummaryError = undefined;
                draft.employerRatingSummaryData = action.payload;
                break;
            case getType(rateEmployerSummary.failure):
                draft.employerRatingSummaryLoading = false;
                draft.employerRatingSummarySuccess = false;
                draft.employerRatingSummaryError = action.payload;
                break;
            case getType(rateEmployeeSummary.request):
                draft.employeeRatingSummaryLoading = false;
                draft.employeeRatingSummarySuccess = false;
                draft.employeeRatingSummaryError = undefined;
                break;
            case getType(rateEmployeeSummary.success):
                draft.employeeRatingSummaryLoading = false;
                draft.employeeRatingSummarySuccess = true;
                draft.employeeRatingSummaryError = undefined;
                draft.employeeRatingSummaryData = action.payload;
                break;
            case getType(rateEmployeeSummary.failure):
                draft.employeeRatingSummaryLoading = false;
                draft.employeeRatingSummarySuccess = false;
                draft.employeeRatingSummaryError = action.payload;
                break;
            case getType(resetRateEmployerState):
                draft.employerRatingLoading = false;
                draft.rateEmployerSuccess = undefined;
                draft.ratingEmployerError = undefined;
                break;
            case getType(resetRateEmployeeState):
                draft.employeeRatingLoading = false;
                draft.rateEmployeeSuccess = undefined;
                draft.ratingEmployeeError = undefined;
                break;
        }
    });
};

export default reducer;
