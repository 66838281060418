import {
    selectCountRelatedOffers,
    selectRelatedOffers,
    selectRelatedOffersLoading,
    selectRelatedOffersLoadingError,
} from "features/myProfile/selectors";
import { getRelatedOffersAsync } from "features/myProfile/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import RelatedOffersSection from "./component";

const mapStateToProps = (state: RootState) => ({
    countRelatedOffers: selectCountRelatedOffers(state),
    relatedOffers: selectRelatedOffers(state),
    relatedOffersLoading: selectRelatedOffersLoading(state),
    relatedOffersLoadingError: selectRelatedOffersLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getRelatedOffersAsync }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RelatedOffersSection);
