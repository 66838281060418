import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import config from "config";
import ModalWithContent from "features/common/components/ModalWithContent";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TextArea from "features/common/components/TextArea";
import { emailRegex, phoneNumberRegex } from "features/common/constants";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import mapObjectToFormData from "features/common/mappers/mapObjectToFormData";
import {
    getCancelMessage,
    getMaxLengthMessage,
    getOkMessage,
    getRequiredMessage,
    getSendMessage,
} from "features/common/translationMessages";
import { MessageType } from "features/common/types";
import JobOffersApi from "features/jobOffers/api";
import {
    getAskAboutMessage,
    getMessagePlaceholderMessage,
    getMessageSentSuccessMessage,
    getNoEmailErrorMessage,
    getNoPhoneNumberErrorMessage,
    getSendMessageInformationMessage,
    getSendMessageToEmployeeLabelMessage,
    getSendMessageToEmployerLabelMessage,
} from "features/offers/translationMessages";
import { OfferMessageParams } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    ["data-testid"]?: string;
    askAboutPositionTitle?: string;
    askAboutPositionMainImage?: string;
    messageType?: MessageType;
    isVisible?: boolean;
    maxMessageLength: number;
    offerId: number;
    onClose: () => void;
    onSuccess: () => void;
    messageTarget?: "employee" | "employer";
}

const SendMessage = ({
    "data-testid": testId,
    askAboutPositionTitle,
    askAboutPositionMainImage,
    isVisible,
    maxMessageLength,
    offerId,
    messageType,
    onClose,
    onSuccess,
    messageTarget = "employer"
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();

    const sendButtonText = getSendMessage(intl);
    const cancelButtonText = getCancelMessage(intl);
    const okButtonText = getOkMessage(intl);
    const messagePlaceholder = getMessagePlaceholderMessage(intl);
    const messageSentSuccess = getMessageSentSuccessMessage(
        intl,
        <a
            className={styles["offer-details__my-messages-link"]}
            href={`${appRoutes.myMessages}`}
            data-testid={`${testId}__my-messages-link`}
        >
            <FormattedMessage id="common__my-messages" />
        </a>
    );
    const header = getAskAboutMessage(intl);
    const [sendMessageSuccessModalVisible, setSendMessageSuccessModalVisible] = useState(false);
    const [messageSending, setMessageSending] = useState(false);
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [messageTouched, setMessageTouched] = useState(false);

    const requiredMessage = getRequiredMessage(intl);
    const maxLengthError = getMaxLengthMessage(intl, maxMessageLength);
    const noPhoneNumberErrorMeesage = getNoPhoneNumberErrorMessage(intl);
    const noEmailErrorMessage = getNoEmailErrorMessage(intl);
    const sendMessageInformation = getSendMessageInformationMessage(intl, maxMessageLength);
    const sendMessageLabelMessage = messageTarget === "employee" ? getSendMessageToEmployeeLabelMessage(intl) : getSendMessageToEmployerLabelMessage(intl);
    const isMatches = useMediaQuery('(max-width: 500px)');

    const validateMessage = useCallback(() => {
        setMessageError("");

        if (message.length === 0) {
            setMessageError(requiredMessage);
        } else if (message.match(phoneNumberRegex)) {
            setMessageError(noPhoneNumberErrorMeesage);
        } else if (message.match(emailRegex)) {
            setMessageError(noEmailErrorMessage);
        } else if (message.length > maxMessageLength) {
            setMessageError(maxLengthError);
        }
    }, [maxLengthError, maxMessageLength, message, noEmailErrorMessage, noPhoneNumberErrorMeesage, requiredMessage]);

    const resetMessageState = () => {
        setMessage("");
        setMessageError("");
        setMessageTouched(false);
    };

    const onCancelSendMessage = () => {
        onClose && onClose();
        resetMessageState();
    };

    const onSendMessage = async () => {
        onClose && onClose();
        setMessageSending(true);

        try {
            const response = await JobOffersApi.createConversationAsync(offerId, createCancelToken());
            await JobOffersApi.sendJobOfferMessageAsync(response.conversationId, message, createCancelToken());
            setSendMessageSuccessModalVisible(true);
            onSuccess();
        } finally {
            setMessageSending(false);
            resetMessageState();
        }
    };

    const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value.trimLeft();
        setMessage(newValue);
        if (!messageTouched) {
            setMessageTouched(true);
        }
    };

    const onMessageBlur = () => {
        setMessageTouched(true);
    };

    useEffect(() => {
        validateMessage();
    }, [validateMessage, message, messageTouched]);

    const renderSendMessageSuccessHeader = () => {
        return (
            <div className={styles["send-offer-message__send-message-success-header"]}>
                <TickIcon data-testid={`${testId}__complete-icon`} />
                <FormattedMessage id="offer-details__send-message-success-header" />
            </div>
        );
    };

    const jobTitleBlock = useCallback(() => {
        if (isMatches && askAboutPositionTitle!.length >= 25) {
            return <div>{`${askAboutPositionTitle?.substring(0, 25)}...`}</div>;
        } else if (askAboutPositionTitle!.length >= 34) {
            return <div>{`${askAboutPositionTitle?.substring(0, 34)}...`}</div>
        } else {
            return <div>{askAboutPositionTitle}</div>;
        }
    }, [isMatches, askAboutPositionTitle]);

    const MessageHeader = useMemo(
        () => (
            <div className={styles["send-offer-message__header-container"]}>
                <div className={styles["send-offer-message__header"]}>
                    {askAboutPositionMainImage && (
                        <img
                            src={`${config.IMAGE_URL}/${askAboutPositionMainImage}`}
                            alt={askAboutPositionTitle}
                            className={styles["send-offer-message__header--image"]}
                        />
                    )}
                    <div className={styles["send-offer-message__header--text-container"]}>
                        <div className={styles["send-offer-message__header--text"]}>{header}</div>
                        <div className={styles["send-offer-message__header--position"]}>
                            {jobTitleBlock()}
                        </div>
                    </div>
                </div>
                <div className={styles["send-offer-message__label"]}>{sendMessageLabelMessage}</div>
            </div>
        ),
        [askAboutPositionMainImage, askAboutPositionTitle, header, sendMessageLabelMessage, isMatches]
    );

    return (
        <>
            {messageSending && (
                <SpinnerWithMessage message={<FormattedMessage id="offer-details__sending-message" />} />
            )}
            {sendMessageSuccessModalVisible && (
                <ModalWithContent
                    header={renderSendMessageSuccessHeader()}
                    content={messageSentSuccess}
                    primaryButtonContent={okButtonText}
                    primaryAction={() => setSendMessageSuccessModalVisible(false)}
                    onClose={() => setSendMessageSuccessModalVisible(false)}
                    data-testid={`${testId}__success-modal`}
                />
            )}
            {isVisible && (
                <ModalWithContent
                    header={MessageHeader}
                    content={
                        <TextArea
                            maxSize={maxMessageLength}
                            placeholder={`${messagePlaceholder}*`}
                            value={message}
                            error={messageError}
                            onChange={onMessageChange}
                            onBlur={onMessageBlur}
                            data-testid={`${testId}__message-modal`}
                            className={styles["send-offer-message__text-area"]}
                            customWarningMessage={sendMessageInformation}
                        />
                    }
                    secondaryButtonContent={cancelButtonText}
                    primaryButtonContent={sendButtonText}
                    onClose={onCancelSendMessage}
                    primaryAction={onSendMessage}
                    secondaryAction={onCancelSendMessage}
                    secondaryButtonVariant={"dark"}
                    primaryButtonDisabled={!!messageError || !messageTouched}
                    data-testid={`${testId}__message-modal`}
                    className={styles["send-offer-message__send-message-modal"]}
                    isScrollable
                />
            )}
        </>
    );
};

export default SendMessage;
