import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const CookiePolicy = () => {
    return (
        <div className={styles["cookie-policy"]}>

            <h1><FormattedMessage id="cookie_policy" /></h1>

            <p><FormattedMessage id="cookie_policy_policy" /></p>

            <p><strong><FormattedMessage id="cookie_policy_what_is_cookie"/></strong></p>

            <p><FormattedMessage id="cookie_policy_cookie_is" /></p>

            <p><strong><FormattedMessage id="cookie_policy_who_is_admin"/></strong>
            </p>

            <p><FormattedMessage id="cookie_policy_admin_it" /></p>

            <p><strong><FormattedMessage id="cookie_policy_how_to_accept_cookie" /></strong></p>

            <p><FormattedMessage id="cookie_policy_apply_cookie" /></p>

            <p><strong><FormattedMessage id="cookie_policy_what_cookie" /></strong></p>

            <p><FormattedMessage id="cookie_policy_use" /></p>
            <ul>
                <li><FormattedMessage id="cookie_policy_permanent" />
                </li>
                <li><FormattedMessage id="cookie_policy_session" />
                </li>
                <li><FormattedMessage id="cookie_policy_third_part" />
                </li>
            </ul>

            <p><strong><FormattedMessage id="cookie_policy_what_personal_data_save" /></strong></p>

            <p><FormattedMessage id="cookie_policy_save_next_date" /></p>
            <ul>
                <li><FormattedMessage id="cookie_policy_date_about_device" />
                </li>
                <li><FormattedMessage id="cookie_policy_date_about_time" /></li>
                <li><FormattedMessage id="cookie_policy_date_about_search" />
                </li>
                <li><FormattedMessage id="cookie_policy_date_for_login" /></li>
            </ul>

            <p><FormattedMessage id="cookie_policy_auto_save_at_device" /></p>

            <p><strong><FormattedMessage id="cookie_policy_how_manage" /></strong></p>

            <p><FormattedMessage id="cookie_policy_can_manage" /> </p>

            <p><FormattedMessage id="cookie_policy_need_remember" /></p>

            <p><strong><FormattedMessage id="cookie_policy_how_used" /></strong></p>

            <p><FormattedMessage id="cookie_policy_used_for" /></p>
            <ul>
                <li><FormattedMessage id="cookie_policy_compliance_with_the_content" /></li>
                <li><FormattedMessage id="cookie_policy_maintain_active_session" />
                </li>
                <li><FormattedMessage id="cookie_policy_create_statistics" />
                </li>
                <li><FormattedMessage id="cookie_policy_for_marketing" /></li>
            </ul>

            <p><strong><FormattedMessage id="cookie_policy_how_off" /></strong></p>

            <p><FormattedMessage id="cookie_policy_what_do_to_off" /></p>

            <p><strong><FormattedMessage id="cookie_policy_where_find_more" /></strong></p>

            <p><FormattedMessage id="cookie_policy_find_more_at" /></p>

            <p><strong><FormattedMessage id="cookie_policy_where_current_version" /></strong></p>

            <p><FormattedMessage id="cookie_policy_" /></p>
        </div>
    );
};

export default CookiePolicy;
