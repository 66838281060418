import { selectIsUserLoggedInWithProfile } from "features/user/selectors";
import { connect } from "react-redux";
import { RootState } from "store";
import Footer from "./component";

const mapStateToProps = (state: RootState) => ({
    isUserLoggedInWithProfile: selectIsUserLoggedInWithProfile(state),
});

export default connect(mapStateToProps)(Footer);
