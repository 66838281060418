import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.employeeOffers;

export const selectEmployeeOffers = createSelector(selectState, (state) => state.employeeOffers);
export const selectEmployeeOffersLoading = createSelector(selectState, (state) => state.employeeOffersLoading);
export const selectEmployeeOffersLoadingError = createSelector(
    selectState,
    (state) => state.employeeOffersLoadingError
);

export const selectMyEmployeeOffers = createSelector(selectState, (state) =>
    Array.from(state.myEmployeeOffers.values())
);
export const selectMyEmployeeOffersLoading = createSelector(selectState, (state) => state.myEmployeeOffersLoading);
export const selectMyEmployeeOffersLoadingError = createSelector(
    selectState,
    (state) => state.myEmployeeOffersLoadingError
);

export const selectEmployeeOffersFilterMatchCounts = createSelector(selectState, (state) => state.filterMatchCounts);
export const selectEmployeeOffersFilterMatchCountsLoading = createSelector(
    selectState,
    (state) => state.filterMatchCountsLoading
);
export const selectEmployeeOffersFilterMatchCountsLoadingError = createSelector(
    selectState,
    (state) => state.filterMatchCountsLoadingError
);

export const selectTotalEmployeeOffers = createSelector(selectState, (state) => state.totalEmployeeOffers);

export const selectEmployeeOfferDetails = createSelector(selectState, (state) => state.employeeOfferDetails);
export const selectEmployeeOfferDetailsLoading = createSelector(
    selectState,
    (state) => state.employeeOfferDetailsLoading
);
export const selectEmployeeOfferDetailsLoadingError = createSelector(
    selectState,
    (state) => state.employeeOfferDetailsLoadingError
);

export const selectEmployeeOfferMessageSending = createSelector(
    selectState,
    (state) => state.employeeOfferMessageSending
);
export const selectEmployeeOfferMessageSendingSuccess = createSelector(
    selectState,
    (state) => state.employeeOfferMessageSendingSuccess
);

export const selectEmployeeOfferCreating = createSelector(selectState, (state) => state.employeeOfferCreating);
export const selectEmployeeOfferCreatingSuccess = createSelector(
    selectState,
    (state) => state.employeeOfferCreatingSuccess
);
export const selectEmployeeOfferCreatingError = createSelector(
    selectState,
    (state) => state.employeeOfferCreatingError
);
export const selectEmployeeOfferCreatingErrorsArray = createSelector(
    selectState,
    (state) => state.employeeOfferCreatingErrorsArray
);
