import { updateMyProfileCardAsync } from "features/myProfile/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import RequirementsSection from "./component";

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            updateMyProfileCardAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RequirementsSection);
