/* eslint-disable no-template-curly-in-string */
import { phoneNumberRegex } from "features/common/constants";
import { IntlShape } from "react-intl";
import * as Yup from "yup";

export const yupLocales = {
    en: {
        mixed: {
            required: "Field is required",
            notType: "Entered data has an incorrect format",
            default: "Entered data has an incorrect format",
        },
        string: {
            min: "Field needs to have at least ${min} characters",
            max: "Field cannot have more than ${max} characters",
            matches: "Entered data has an incorrect format",
            email: "Please enter a valid email address",
        },
        number: {
            min: "Field must be greater than or equal to ${min}",
            max: "Field must be smaller than or equal to ${min}",
            integer: "Entered data has an incorrect format",
            moreThan: 'Field must be greater than the minimum',
        },
    },
    pl: {
        mixed: {
            required: "Pole jest wymagane",
            notType: "Wprowadzona wartość ma niepoprawny format",
            default: "Wprowadzona wartość ma niepoprawny format",
        },
        string: {
            min: "Minimalna liczba znaków w tym polu to: ${min}",
            max: "Maksymalna liczba znaków w tym polu to: ${max}",
            matches: "Wprowadzona wartość ma niepoprawny format",
            email: "Wprowadzony adres email ma niepoprawny format",
        },
        number: {
            min: "Wartość pola musi być większa bądź równa ${min}",
            max: "Wartość pola musi być mniejsza bądź równa ${max}",
            integer: "Wprowadzona wartość ma niepoprawny format",
            moreThan: "Wartość pola musi być większa od kwoty minimalnej",
        },
    },
    de: {
        mixed: {
            required: "Dieses Feld ist erforderlich",
            notType: "Der eingegebene Wert hat ein ungültiges Format",
            default: "Der eingegebene Wert hat ein ungültiges Format",
        },
        string: {
            min: "Die Mindestanzahl von Zeichen für dieses Feld ist: ${min}",
            max: "Die maximale Zeichenanzahl für dieses Feld beträgt: ${max}",
            matches: "Der eingegebene Wert hat ein ungültiges Format",
            email: "Die eingegebenee E-Mail-Adresse hat ein falsches Format",
        },
        number: {
            min: "Der Feldwert muss größer oder gleich sein ${min}",
            max: "Der Feldwert muss kleiner oder gleich sein ${max}",
            integer: "Der eingegebene Wert hat ein ungültiges Format",
            moreThan: "Der Feldwert muss größer als der Mindestbetrag sein",
        },
    },

    fr: {
        mixed: {
            required: "Ce champ est requis",
            notType: "La valeur saisie a un format invalide",
            default: "La valeur saisie a un format invalide",
        },
        string: {
            min: "Le nombre minimum de caractères pour ce champ est: ${min}",
            max: "Le nombre maximum de caractères pour ce champ est: ${min}",
            matches: "La valeur saisie a un format invalide",
            email: "L'adresse e-mail saisie a un format incorrect",
        },
        number: {
            min: "La valeur du champ doit être supérieure ou égale à ${min}",
            max: "La valeur du champ doit être inférieure ou égale à ${min}",
            integer: "La valeur saisie a un format invalide",
            moreThan: "La valeur du champ doit être supérieure au montant minimum",
        },
    },

    nld: {
        mixed: {
            required: "Dit veld is verplicht",
            notType: "De ingevoerde waarde heeft een ongeldig formaat ",
            default: "De ingevoerde waarde heeft een ongeldig formaat ",
        },
        string: {
            min: "Het minimum aantal karakters voor dit veld is: ${min}",
            max: "Het maximum aantal karakters voor dit veld is: ${min}",
            matches: "De ingevoerde waarde heeft een ongeldig formaat",
            email: "Het ingevoerde e-mailadres heeft een onjuist formaat",
        },
        number: {
            min: "De veldwaarde moet groter zijn dan of gelijk zijn aan ",
            max: "De veldwaarde moet kleiner zijn dan of gelijk zijn aan ",
            integer: "De ingevoerde waarde heeft een ongeldig formaat ",
            moreThan: "De veldwaarde moet groter zijn dan het minimumbedrag ",
        },
    },

    ru: {
        mixed: {
            required: "Это поле обязательное",
            notType: "Введенное значение имеет недопустимый формат",
            default: "Введенное значение имеет недопустимый формат",
        },
        string: {
            min: "Минимальное количество символов для этого поля: ${min}",
            max: "Максимальное количество символов для этого поля: ${min}",
            matches: "Введенное значение имеет недопустимый формат",
            email: "Введенный адрес электронной почты имеет неверный формат",
        },
        number: {
            min: "Значение поля должно быть больше или равно ${min}",
            max: "Значение поля должно быть меньше или равно ${min}",
            integer: "Введенное значение имеет недопустимый формат",
            moreThan: "Значение поля должно быть больше минимальной суммы",
        },
    },

    ua: {
        mixed: {
            required: "Це поле обов'язкове",
            notType: "Введене значення має недійсний формат",
            default: "Введене значення має недійсний формат",
        },
        string: {
            min: "Мінімальна кількість символів для цього поля: ${min}",
            max: "Максимальна кількість символів для цього поля: ${min}",
            matches: "Введене значення має недійсний формат",
            email: "Введена адреса електронної пошти має неправильний формат",
        },
        number: {
            min: "Значення поля має бути більше або дорівнювати ${min}",
            max: "Значення поля має бути менше або дорівнювати ${min}",
            integer: "Введене значення має недійсний формат",
            moreThan: "Значення поля має перевищувати мінімальну суму",
        },
    },
};

export const getLocalizedYup = (intl: IntlShape) => {
    const localYup = Yup;
    let yupLocale;

    switch (intl.locale) {
        case "en":
            yupLocale = yupLocales.en
            break;
        case "de":
            yupLocale = yupLocales.de
            break;
        case "fr":
            yupLocale = yupLocales.fr
            break;
        case "nld":
            yupLocale = yupLocales.nld
            break;
        case "ru":
            yupLocale = yupLocales.ru
            break;
        case "ua":
            yupLocale = yupLocales.ua
            break;
        default:
            yupLocale = yupLocales.pl
            break;
    }
    localYup.setLocale(yupLocale);
    return localYup;
}

export const phoneNumberValidator = (yup: typeof Yup, excludeEmptyString: boolean = true) =>
    yup.string().matches(phoneNumberRegex, { excludeEmptyString }).min(8).max(20).trim();

export const isValidNIP = (yup: typeof Yup) =>
    yup.string().test('nip', "Wprowadzony numer NIP ma niepoprawny format", (value) => {
        if (value === null) return true;
        if (typeof value !== 'string') return false;

        const nip = value.replace(/[\ \-]/gi, '')
        const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }
        return sum % 11 === controlNumber;
    })
