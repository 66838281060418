import { EnumDictionary } from "features/common/types";
import {
    getRequirementStepEducationHigher,
    getRequirementStepEducationNone,
    getRequirementStepEducationPrimary,
    getRequirementStepEducationSecondary,
    getRequirementStepEducationSecondaryTechnical,
    getRequirementStepEducationVocational,
    getRequirementStepEducationOther,
    getRequirementStepExperience35months,
    getRequirementStepExperience624months,
    getRequirementStepExperienceMoerThanTwoYears,
    getRequirementStepExperienceNone,
    getRequirementStepLanguagesArabic,
    getRequirementStepLanguagesCzech,
    getRequirementStepLanguagesDutch,
    getRequirementStepLanguagesEnglish,
    getRequirementStepLanguagesFrench,
    getRequirementStepLanguagesGerman,
    getRequirementStepLanguagesHindi,
    getRequirementStepLanguagesItalian,
    getRequirementStepLanguagesLevelAdvanced,
    getRequirementStepLanguagesLevelBasic,
    getRequirementStepLanguagesLevelMedium,
    getRequirementStepLanguagesLevelNative,
    getRequirementStepLanguagesOther,
    getRequirementStepLanguagesPolish,
    getRequirementStepLanguagesRomanian,
    getRequirementStepLanguagesRussian,
    getRequirementStepLanguagesSpanish,
    getRequirementStepLanguagesUkrainian,
} from "features/jobOffers/translationMessages";
import {
    CreateJobOfferStepName,
    EducationType,
    LanguageLevelType,
    LanguageType,
    RequirementListOption,
} from "features/jobOffers/types";
import { useField } from "formik";
import { IntlShape } from "react-intl";

export const useCreateJobOfferField = <T>(stepName: CreateJobOfferStepName, fieldName: string) =>
    useField<T>(`${stepName}.${fieldName}`);

export const getExperienceLabels = (intl: IntlShape) => {

    const options: { [k: string]: RequirementListOption } = {
        NoExperience: {
            label: getRequirementStepExperienceNone(intl),
        },
        ThreeToFiveMonths: {
            label: getRequirementStepExperience35months(intl),
        },
        SixToTwentyFourMonths: {
            label: getRequirementStepExperience624months(intl),
        },
        MoreThanTwoYears: {
            label: getRequirementStepExperienceMoerThanTwoYears(intl),
        },
    };

    return options;
};

export const getLanguageLevelsLabels = (intl: IntlShape) => {

    const options: EnumDictionary<LanguageLevelType, string> = {
        [LanguageLevelType.Beginner]: getRequirementStepLanguagesLevelBasic(intl),
        [LanguageLevelType.Intermediate]: getRequirementStepLanguagesLevelMedium(intl),
        [LanguageLevelType.Advanced]: getRequirementStepLanguagesLevelAdvanced(intl),
        [LanguageLevelType.Native]: getRequirementStepLanguagesLevelNative(intl),
    };

    return options;
};

export const getLanguagesLabels = (intl: IntlShape) => {

    const options: EnumDictionary<LanguageType, string> = {
        [LanguageType.English]: getRequirementStepLanguagesEnglish(intl),
        [LanguageType.Arabic]: getRequirementStepLanguagesArabic(intl),
        [LanguageType.Czech]: getRequirementStepLanguagesCzech(intl),
        [LanguageType.French]: getRequirementStepLanguagesFrench(intl),
        [LanguageType.Hindi]: getRequirementStepLanguagesHindi(intl),
        [LanguageType.Spanish]: getRequirementStepLanguagesSpanish(intl),
        [LanguageType.Dutch]: getRequirementStepLanguagesDutch(intl),
        [LanguageType.German]: getRequirementStepLanguagesGerman(intl),
        [LanguageType.Polish]: getRequirementStepLanguagesPolish(intl),
        [LanguageType.Russian]: getRequirementStepLanguagesRussian(intl),
        [LanguageType.Romanian]: getRequirementStepLanguagesRomanian(intl),
        [LanguageType.Ukrainian]: getRequirementStepLanguagesUkrainian(intl),
        [LanguageType.Italian]: getRequirementStepLanguagesItalian(intl),
    };

    return options;
};

export const getEducationLabels = (intl: IntlShape) => {

    const options: EnumDictionary<EducationType, string> = {
        [EducationType.None]: getRequirementStepEducationNone(intl),
        [EducationType.Primary]: getRequirementStepEducationPrimary(intl),
        [EducationType.Vocational]: getRequirementStepEducationVocational(intl),
        [EducationType.Secondary]: getRequirementStepEducationSecondary(intl),
        [EducationType.SecondaryTechnical]: getRequirementStepEducationSecondaryTechnical(intl),
        [EducationType.Higher]: getRequirementStepEducationHigher(intl),
        [EducationType.Other]: getRequirementStepEducationOther(intl),
    };

    return options;
};
