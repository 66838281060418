import MyMessagesComponent from "features/messages/components/MyMessages";
import React from "react";
import styles from "./styles.module.scss";

const MyMessages = () => {
    return (
        <div className={styles["my-messages"]}>
            <div className={styles["my-messages__inner-container"]}>
                <MyMessagesComponent />
            </div>
        </div>
    );
};

export default MyMessages;
