import { creditCardRegex, expiryDateRegex, phoneNumberRegex } from "features/common/constants";
import { phoneNumberValidator, isValidNIP } from "features/common/validators";
import { CreateNewCardValues } from "features/myProfile/mappers";
import { EmployeeExperienceRequirement, PaymentData } from "features/myProfile/models";
import { CardDetailsSchema } from "features/myProfile/schemas";
import { LocationType, NewCard } from "features/myProfile/types";
import * as Yup from "yup";

export const isFirstNameValid = (firstName: string) => {
    const textLength = firstName.trim().length;

    return textLength >= 2 && textLength <= 50;
};

export const isLastNameValid = (lastName: string) => {
    const textLength = lastName.trim().length;

    return textLength >= 2 && textLength <= 50;
};

export const isPhoneNumberLengthValid = (phoneNumber: string) => {
    const phoneRawText = phoneNumber.trim();
    const isMinLengthValid = phoneRawText.length >= 8;
    const isMaxLengthValid = phoneRawText.length <= 20;

    return isMinLengthValid && isMaxLengthValid;
};

export const isPhoneNumberCharactersValid = (phoneNumber: string) => {
    const phoneRawText = phoneNumber.trim();
    const regexResult = phoneRawText.match(new RegExp(phoneNumberRegex));

    return regexResult ? regexResult?.length > 0 : false;
};

export const isPhoneNumberValid = (phoneNumber: string) => {
    return (
        phoneNumber.length === 0 || (isPhoneNumberCharactersValid(phoneNumber) && isPhoneNumberLengthValid(phoneNumber))
    );
};

export const isUserDetailsValid = (firstName: string, lastName: string, phoneNumber: string) => {
    return isFirstNameValid(firstName) && isLastNameValid(lastName) && isPhoneNumberValid(phoneNumber);
};

export const isCompanyNameValid = (companyName: string) => {
    return companyName.trim().length > 0;
};

export const isNewCardDetailsValid = (newCard: NewCard) => {
    const isLocationValid = newCard.locationId.length > 0;

    if (newCard.ownerType === "company") {
        return isLocationValid && newCard.companyName.trim().length > 0;
    }

    return isLocationValid;
};

export const isLocationFieldValid = (location?: LocationType) => !!location?.option?.value && !!location?.input;

export const createCardDetailsFormValidationSchema = (yup: typeof Yup) =>
    yup.object<CardDetailsSchema>({
        companyName: yup.string().trim().required(),
        phoneNumber: phoneNumberValidator(yup),
        location: yup.object({
            placeId: yup.string().required(),
        }),
        email: yup.string().email(),
        isDirty: yup.boolean(),
        nip: isValidNIP(yup),
        krazCert: yup.number()
        
    } as Yup.ObjectSchemaDefinition<CardDetailsSchema>);

export const createNewCardValidationSchema = (yup: typeof Yup) =>
    yup.object<CreateNewCardValues>({
        personal: yup.string().oneOf(["private", "company"]).required(),
        type: yup.string().oneOf(["EMPLOYER", "EMPLOYEE"]).required(),
        businessName: yup.string().when("personal", {
            is: "company",
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        firstName: yup.string().when("personal", {
            is: "private",
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        lastName: yup.string().when("personal", {
            is: "private",
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        phoneNumber: yup.string().when("personal", {
            is: "private",
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
    } as Yup.ObjectSchemaDefinition<CreateNewCardValues>);

export const employeeExperienceValidationSchema = (yup: typeof Yup) =>
    yup.object<EmployeeExperienceRequirement>({
        id: yup.string(),
        companyName: yup.string().required(),
        position: yup.string().required(),
        isActive: yup.boolean(),
        startDate: yup.date().required(),
        endDate: yup.date().when("isActive", {
            is: true,
            then: yup.date(),
            otherwise: yup.date().min(yup.ref("startDate")).required(),
        }),
        additionalInfo: yup.string().max(250).required(),
    } as Yup.ObjectSchemaDefinition<EmployeeExperienceRequirement>);

export const createPaymentDataValidationSchema = (yup: typeof Yup) =>
    yup.object<PaymentData>({
        cardNumber: yup.string().matches(creditCardRegex).required(),
        expiryDate: yup.string().matches(expiryDateRegex).required(),
    });
