import * as messages_de from "lang/de.json";
import * as messages_en from "lang/en.json";
import * as messages_fr from "lang/fr.json";
import * as messages_nld from "lang/nld.json";
import * as messages_pl from "lang/pl.json";
import * as messages_ru from "lang/ru.json";
import * as messages_ua from "lang/ua.json";

export type Locale = "en" | "pl" | "de" | "uk" | "ru" | "fr" | "nl";

export const getTranslatedMessages = (locale: Locale) => {
    switch (locale) {
        case "pl":
            return (messages_pl as any).default;
        case "de":
            return (messages_de as any).default;
        case "uk":
            return (messages_ua as any).default;
        case "ru":
            return (messages_ru as any).default;
        case "fr":
            return (messages_fr as any).default;
        case "nl":
            return (messages_nld as any).default;
        default:
            return (messages_en as any).default;
    }
};
