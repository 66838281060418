import { selectUser } from "features/user/selectors";
import { getMyProfileCardsAsync } from "features/myProfile/thunks";
import {
    selectMyProfileCardLoadingError,
    selectMyProfileCards,
    selectMyProfileCardsLoading,
} from "features/myProfile/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import CardStep from "./component";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    myProfileCards: selectMyProfileCards(state),
    areMyProfileCardsLoading: selectMyProfileCardsLoading(state),
    myProfileCardsLoadingError: selectMyProfileCardLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getMyProfileCardsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardStep);
