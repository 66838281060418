import { CustomCancelToken } from "api/utils";
import { useCallback, useEffect, useRef } from "react";

const useCreateCancelToken = (): (() => CustomCancelToken) => {
    const cancelToken = useRef<CustomCancelToken>();

    useEffect(() => {
        return () => cancelToken.current?.cancel();
    }, [cancelToken]);

    const createCancelToken = useCallback((): CustomCancelToken => {
        if (!cancelToken.current) {
            cancelToken.current = new CustomCancelToken();
        }

        return cancelToken.current;
    }, []);

    return createCancelToken;
};

export default useCreateCancelToken;
