import React from 'react'
import styles from "./styles.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import YoutubeEmbed from './youtubeEmbed/youtubeEmbed';


const Faq = () => {

    const intl = useIntl();
    const link = `${intl.formatMessage({ id: "faq_getmen_link" })}`;
    console.log(link);

    return (
        <div className={styles["faq"]}>
            <h1><FormattedMessage id="faq" /></h1>
            <h2><FormattedMessage id="faq_what_is_getmen" /></h2>
            <div className={styles["faq__embed"]}>
                <YoutubeEmbed embedId={link} />
            </div>
            <h2><FormattedMessage id="faq_what_is_getbonus" /></h2>
            <p><FormattedMessage id="faq_getbonus_is_innovative_system" /></p>
            <h2><FormattedMessage id="faq_who_and_when_pays" /></h2>
            <p><FormattedMessage id="faq_getbonus_is_paid" /></p>
            <h2><FormattedMessage id="faq_what_currency" /></h2>
            <p><FormattedMessage id="faq_the_payout" /></p>
            <h2><FormattedMessage id="faq_why_cannot_i_apply" /></h2>
            <p><FormattedMessage id="faq_to_apply" /></p>
            <h2><FormattedMessage id="faq_is_adding_offers_paid" /></h2>
            <p><FormattedMessage id="faq_adding_all_offers" /></p>
            <h2><FormattedMessage id="faq_how_many_offers" /></h2>
            <p><FormattedMessage id="faq_there_is_no_limit" /></p>
        </div >
    )
}

export default Faq;