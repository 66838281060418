import React from "react";
import ForgottenPasswordComponent from "features/user/components/ForgottenPassword";
import styles from "./styles.module.scss";

const ForgottenPassword = () => {
    return (
        <div className={styles["login"]}>
            <ForgottenPasswordComponent />
        </div>
    );
};

export default ForgottenPassword;
