import {selectIsUserLoggedIn, selectIsUserLoggedInWithProfile, selectUser} from "features/user/selectors";
import {getUserAsync, logoutUserAsync} from "features/user/thunks";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "store";
import Header from "./component";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
    isUserLoggedInWithProfile: selectIsUserLoggedInWithProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getUserAsync, logoutUserAsync }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
