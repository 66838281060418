import { FilterMatchCount } from "features/common/types";
import { OffersFetchParams, OffersFilterMatchCounts, OffersFilters } from "features/offers/types";

export enum EmployeeOfferAmenityType {
    Accommodation = 0,
    Transport,
}

export enum EmployeeOfferCapacityType {
    Single = 0,
    Group,
}

export enum EmployeeOfferType {
    Single = "INDIVIDUAL",
    Group = "GROUP",
}

export interface ApplicationFormValues {
    businessCardId: number;
    content?: string;
    applicantsNumber?: number;
    applicationType?: ApplicationTypes;
}

export enum ApplicationTypes {
    Group = "GROUP",
    Individual = "INDIVIDUAL",
}

export interface EmployeeOffersFilters extends OffersFilters {
    byCapacityType: EmployeeOfferCapacityType[];
    byPreferredAmenities: EmployeeOfferAmenityType[];
    byWorkDuringHolidays?: boolean;
    minimalSalaryValue?: number;
    minimalSalaryCurrencyCode?: string;
    minimalBonusValue?: number;
    minimalBonusCurrencyCode?: string;
}

export interface EmployeeOffersFetchParams extends OffersFetchParams {
    filters: EmployeeOffersFilters;
}

export interface EmployeeOffersFilterMatchCounts extends OffersFilterMatchCounts {
    byCapacityType: Array<FilterMatchCount<EmployeeOfferCapacityType>>;
    byAmenities: Array<FilterMatchCount<EmployeeOfferAmenityType>>;
    byWorkDuringHolidays: number;
}
