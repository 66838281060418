import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as VerticalThreeDotsIcon } from "assets/icons/verticalThreeDots.svg";
import { TableColumn } from "features/common/components/DataTable";
import { SortDirection } from "features/common/types";
import { GetOfferFetchParams } from "features/moderatorPanel/types";
import React from "react";
import { OfferDto } from "../dtos";

export const getOffersTableColumns = (
    labels: string[],
    sortCallbacks: ((direction: SortDirection) => void)[],
    actionDesktopDisplay: string,
    actionOptionLabels: string[],
    actionOptionCallbacks: ((id: any) => void)[]
): TableColumn<OfferDto>[] => [
        {
            key: "id",
            hidden: true,
        },
        {
            key: "offer_owner_mail",
            label: labels[0],
            withSort: true,
            sortCallback: sortCallbacks[1],
        },
        {
            key: "offer_title",
            label: labels[1],
            withSort: true,
            sortCallback: sortCallbacks[1],
        },
        {
            key: "added_date",
            label: labels[2],
            withSort: true,
            sortCallback: sortCallbacks[2],
        },
        {
            actionColumn: true,
            actionDisplay: <VerticalThreeDotsIcon />,
            actionDesktopDisplay,
            actionOptions: actionOptionLabels.map((text, index) => ({ text, callback: actionOptionCallbacks[index] })),
        },

    ];

export const getInitialGetOfferFetchParams = (): GetOfferFetchParams => ({
    filterValue: '',
    pageSize: 5,
    offset: 0,
});
