import classNames from "classnames";
import { drivingOptions } from "features/jobOffers/constants";
import { getEducationLabels, getExperienceLabels } from "features/jobOffers/hooks";
import {
    getRequirementStepDrivingLicenseHeader,
    getRequirementStepEducationHeader,
    getRequirementStepExperienceHeader,
    getRequirementStepLanguagesHeader,
    getRequirementStepLicensesAndCertificatesHeader,
    getRequirementStepOtherHeader,
    getRequirementStepPermissionsHeader,
    getRequirementStepProgramsHeader,
} from "features/jobOffers/translationMessages";
import {
    EducationType,
    JobExperience,
    Requirement,
    RequirementLanguage,
    RequirementListOption,
    RequirementType,
} from "features/jobOffers/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import JobExperienceArea from "./JobExperienceArea";
import LanguageRequirementArea from "./LanguageRequirementArea";
import ListRequirementArea from "./ListRequirementArea";
import RequirementBlock from "./RequirementBlock";
import styles from "./styles.module.scss";

export interface Props {
    requirements?: Requirement[];
    requirementLanguages?: RequirementLanguage[];
    jobExperience?: JobExperience[];
    ["data-testid"]?: string;
}

const RequirementsPart = ({ requirements, requirementLanguages, jobExperience, "data-testid": testId }: Props) => {
    const intl = useIntl();

    const [educationRequirements, setEducationRequirements] = useState<(RequirementListOption | undefined)[]>();
    const [permissionsRequirements, setPermissionsRequirements] = useState<Requirement>();
    const [drivingLicensesRequirements, setDrivingLicensesRequirements] =
        useState<(RequirementListOption | undefined)[]>();
    const [experienceRequirements, setExperienceRequirement] = useState<Requirement>();
    const [certificatesRequirement, setCertificatesRequirement] = useState<Requirement>();
    const [programRequirement, setProgramRequirement] = useState<Requirement>();
    const [otherRequirement, setOtherRequirement] = useState<Requirement>();
    const [educationOptions, setEducationOptions] = useState(getEducationLabels(intl));
    const [experienceOptions, setExperienceOptions] = useState(getExperienceLabels(intl));
    useEffect(() => {
        setEducationOptions(getEducationLabels(intl));
        setExperienceOptions(getExperienceLabels(intl))
    }, [intl])

    const licenseHeader = getRequirementStepLicensesAndCertificatesHeader(intl);
    const programsHeader = getRequirementStepProgramsHeader(intl);
    const otherHeader = getRequirementStepOtherHeader(intl);
    const experienceHeader = getRequirementStepExperienceHeader(intl);
    const permissionsHeader = getRequirementStepPermissionsHeader(intl);
    const educationHeader = getRequirementStepEducationHeader(intl);
    const drivingLicenseHeader = getRequirementStepDrivingLicenseHeader(intl);
    const languagesHeader = getRequirementStepLanguagesHeader(intl);

    useEffect(() => {
        for (let item in RequirementType) {
            const requirementTypeNumber = Number(item);
            if (!isNaN(requirementTypeNumber)) {
                switch (requirementTypeNumber) {
                    case RequirementType.experience:
                        setExperienceRequirement(requirements?.find((r) => r.requirementType === "EXPERIENCE"));
                        break;
                    case RequirementType.education:
                        setEducationRequirements(
                            requirements
                                ?.filter(
                                    (r) =>
                                        (r.requirementType === "EDUCATION" ||
                                            r.requirementType === "EDUCATION_CUSTOM") &&
                                        !!r.value
                                )
                                .map((r) => {
                                    return {
                                        requirementType: RequirementType.education,
                                        label:
                                            r.requirementType === "EDUCATION"
                                                ? educationOptions[r.value!.toLowerCase() as EducationType]
                                                : r.value!,
                                    };
                                })
                        );
                        break;
                    case RequirementType.permissions:
                        setPermissionsRequirements(requirements?.find((r) => r.requirementType === "PERMISSIONS"));
                        break;
                    case RequirementType.certificates:
                        setCertificatesRequirement(
                            requirements?.find((r) => r.requirementType === "CERTIFICATES_AND_LICENSES")
                        );
                        break;
                    case RequirementType.drivingLicenses:
                        setDrivingLicensesRequirements(
                            requirements
                                ?.filter((r) => r.requirementType === "DRIVING_LICENSE" && !!r.value)
                                .sort((a, b) =>
                                    Object.keys(drivingOptions).indexOf(a.value!) >
                                        Object.keys(drivingOptions).indexOf(b.value!)
                                        ? 1
                                        : -1
                                )
                                .map((r) => {
                                    return drivingOptions[r.value!];
                                })
                        );
                        break;
                    case RequirementType.programs:
                        setProgramRequirement(requirements?.find((r) => r.requirementType === "COMPUTER_AND_SOFTWARE"));
                        break;
                    case RequirementType.other:
                        setOtherRequirement(requirements?.find((r) => r.requirementType === "OTHER"));
                        break;
                }
            }
        }
    }, [educationOptions, experienceOptions, requirements]);

    const getTextValueArea = useCallback((value: string) => {
        return <div className={classNames(styles["requirements-part__text"])}>{intl.formatMessage({ id: `job-experience__${value}`, defaultMessage: value })}</div>;
    }, [intl]);

    const getListArea = useCallback((values: (RequirementListOption | undefined)[]) => {
        return <ListRequirementArea values={values}></ListRequirementArea>;
    }, []);

    const getJobRequirements = (values: any) => {
        return <JobExperienceArea values={values} />
    }

    const requirementsList = useMemo(() => {
        const list = [];

        if (!!experienceRequirements?.value) {
            list.push({
                label: experienceHeader,
                value: getTextValueArea(experienceRequirements?.value),
                isTranslatable: false,
            });
        } else if (!!jobExperience?.length) {
            list.push({
                label: experienceHeader,
                value: <JobExperienceArea values={jobExperience} />,
                isTranslatable: false,
            });
        }

        if (!!drivingLicensesRequirements?.length) {
            list.push({
                label: drivingLicenseHeader,
                value: getListArea(drivingLicensesRequirements),
                isTranslatable: false,
            });
        }

        if (!!educationRequirements?.length) {
            list.push({
                label: educationHeader,
                value: getListArea(educationRequirements),
                isTranslatable: false,
            });
        }

        if (!!requirementLanguages?.length) {
            list.push({
                label: languagesHeader,
                value: <LanguageRequirementArea requirementLanguages={requirementLanguages} />,
                isTranslatable: false,
            });
        }

        if (!!permissionsRequirements?.valueArray?.length) {
            list.push({
                label: permissionsHeader,
                value: getTextValueArea(permissionsRequirements?.valueArray.join(", ")),
                isTranslatable: false,
            });
        }

        if (!!programRequirement?.valueArray?.length) {
            list.push({
                label: programsHeader,
                value: getTextValueArea(programRequirement?.valueArray.join(", ")),
                isTranslatable: false,
            });
        }

        if (!!certificatesRequirement?.valueArray?.length) {
            list.push({
                label: licenseHeader,
                value: getTextValueArea(certificatesRequirement?.valueArray.join(", ")),
                isTranslatable: false,
            });
        }

        if (!!otherRequirement?.valueArray?.length) {
            list.push({
                label: otherHeader,
                value: getTextValueArea(otherRequirement?.valueArray.join(", ")),
                isTranslatable: false,
            });
        }



        return list;
    }, [
        certificatesRequirement,
        drivingLicenseHeader,
        drivingLicensesRequirements,
        educationHeader,
        educationRequirements,
        experienceHeader,
        experienceRequirements,
        getListArea,
        getTextValueArea,
        languagesHeader,
        licenseHeader,
        otherHeader,
        otherRequirement,
        permissionsHeader,
        permissionsRequirements,
        programRequirement,
        programsHeader,
        jobExperience,
        requirementLanguages,
    ]);

    return (
        <div className={styles["requirements-part__list"]}>
            <div className={styles["requirements-part__column"]}>
                {requirementsList[0]?.value && (
                    <RequirementBlock label={requirementsList[0].label} value={requirementsList[0].value} isTranslatable={requirementsList[0].isTranslatable} />
                )}
                {requirementsList[2]?.value && (
                    <RequirementBlock label={requirementsList[2].label} value={requirementsList[2].value} isTranslatable={requirementsList[2].isTranslatable} />
                )}
                {requirementsList[4]?.value && (
                    <RequirementBlock label={requirementsList[4].label} value={requirementsList[4].value} isTranslatable={requirementsList[4].isTranslatable} />
                )}
                {requirementsList[6]?.value && (
                    <RequirementBlock label={requirementsList[6].label} value={requirementsList[6].value} isTranslatable={requirementsList[6].isTranslatable} />
                )}
                {requirementsList[8]?.value && (
                    <RequirementBlock label={requirementsList[8].label} value={requirementsList[8].value} isTranslatable={requirementsList[8].isTranslatable} />
                )}
            </div>
            <div className={styles["requirements-part__separator"]} />
            <div className={styles["requirements-part__column"]}>
                {!!requirementsList[1]?.value && (
                    <RequirementBlock label={requirementsList[1].label} value={requirementsList[1].value} isTranslatable={requirementsList[1].isTranslatable} />
                )}
                {!!requirementsList[3]?.value && (
                    <RequirementBlock label={requirementsList[3].label} value={requirementsList[3].value} isTranslatable={requirementsList[3].isTranslatable} />
                )}
                {!!requirementsList[5]?.value && (
                    <RequirementBlock label={requirementsList[5].label} value={requirementsList[5].value} isTranslatable={requirementsList[5].isTranslatable} />
                )}
                {!!requirementsList[7]?.value && (
                    <RequirementBlock label={requirementsList[7].label} value={requirementsList[7].value} isTranslatable={requirementsList[7].isTranslatable} />
                )}
            </div>
        </div>
    );
};

export default RequirementsPart;
