import { ApiError, CancelToken } from "api/utils";
import Button from "features/common/components/Button";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { MessageType } from "features/common/types";
import OfferSubmissionModal from "features/employeeOffers/components/EmployeeOfferDetails/OfferSubmissionModal";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { Conversation } from "features/messages/models";
import { GetConversationsFetchParams } from "features/messages/types";
import SendMessage from "features/offers/components/SendMessage";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import ModalWithContent from "../../../../common/components/ModalWithContent";
import ErrorHeader from "../../../../common/components/ModalWithContent/ErrorHeader";
import SuccessHeader from "../../../../common/components/ModalWithContent/SuccessHeader";

export interface Props {
    ["data-testid"]?: string;
    offerDetails?: EmployeeOfferDetails;
    isUserLoggedIn?: boolean;
    maxMessageLength: number;
    offerId: number;
    userId?: string;
    conversations?: Conversation[];
    conversationsLoading: boolean;
    conversationsLoadingError?: ApiError;
    getConversationsAsync: (fetchParams: GetConversationsFetchParams, cancelToken?: CancelToken) => void;
    sendDedicationSuccess?: { data: string; message: string };
    sendDedicationLoading?: boolean;
    sendDedicationError?: ApiError;
    resetSendDedicationJobOfferState: () => void;
}

const DetailsActions = ({
    "data-testid": testId,
    offerDetails,
    isUserLoggedIn,
    maxMessageLength,
    offerId,
    userId,
    conversations,
    conversationsLoading,
    conversationsLoadingError,
    getConversationsAsync,
    sendDedicationSuccess,
    sendDedicationError,
    resetSendDedicationJobOfferState
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();
    const [sendMessageModalVisible, setSendMessageModalVisible] = useState(false);
    const [conversationsRequested, setConversationsRequested] = useState(false);
    const [offerSubmissionModalVisible, setOfferSubmissionModalVisible] = useState(false);
    const [sendDedicationSuccessModal, setSendDedicationSuccessModal] = useState(false);
    const [sendDedicationErrorModal, setSendDedicationErrorModal] = useState(false);

    useEffect(() => {
        resetSendDedicationJobOfferState();
    }, []);

    useEffect(() => {
        if (sendDedicationSuccess) {
            setSendDedicationSuccessModal(true)
        }

        if (sendDedicationError) {
            setSendDedicationErrorModal(true)
        }
    }, [sendDedicationSuccess, sendDedicationError]);

    const onClickSendMessage = () => {
        setSendMessageModalVisible(true);
    };

    const onSendMessageClose = () => {
        setSendMessageModalVisible(false);
    };

    const openOfferSubmissionModal = useCallback(() => setOfferSubmissionModalVisible(true), []);
    const closeOfferSubmissionModal = useCallback(() => setOfferSubmissionModalVisible(false), []);

    const handleLoginButton = useCallback(() => history.push(appRoutes.login), [history]);

    useEffect(() => {
        if (!conversationsRequested) {
            getConversationsAsync({ pageSize: 999, pageNumber: 0 }, createCancelToken());
            setConversationsRequested(true);
        }
    }, [
        conversations,
        conversationsLoading,
        conversationsLoadingError,
        conversationsRequested,
        createCancelToken,
        getConversationsAsync,
    ]);

    const handleSendMessageSuccess = () =>
        getConversationsAsync({ pageSize: 999, pageNumber: 0 }, createCancelToken());

    const hasConversation = useMemo(
        () => conversations?.find((item) => item.offerId === `${offerId}`),
        [conversations, offerId]
    );

    const goToConversationDetails = () => {
        history.push(`${appRoutes.messages}/${hasConversation?.id}`);
    };

    if (conversationsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "offer-details__loading-offer-details" })} />;
    }

    return (
        <div>
            <div className={styles["offer-details__buttons-container"]}>
                {isUserLoggedIn ? (
                    !!hasConversation ? (
                        <Button
                            onClick={goToConversationDetails}
                            variant="secondary"
                            className={styles["offer-details__send-message-button"]}
                            data-testid={`${testId}__send-message-button`}
                            disabled={offerDetails?.offerOwner?.userId === userId}
                        >
                            <FormattedMessage id="common__go-to-conversation" />
                        </Button>
                    ) : (
                        <Button
                            onClick={onClickSendMessage}
                            variant="secondary"
                            className={styles["offer-details__send-message-button"]}
                            data-testid={`${testId}__send-message-button`}
                            disabled={offerDetails?.offerOwner?.userId === userId}
                        >
                            <FormattedMessage id="common__send-message" />
                        </Button>
                    )
                ) : (
                    <Button
                        className={styles["offer-details__send-message-button"]}
                        as="a"
                        onClick={handleLoginButton}
                        data-testid={`${testId}__send-message-login-button`}
                    >
                        <FormattedMessage id="common__send-message" />
                    </Button>
                )}
                {isUserLoggedIn ? (
                    <Button
                        onClick={openOfferSubmissionModal}
                        className={styles["offer-details__apply-button"]}
                        data-testid={`${testId}__apply-button`}
                        disabled={offerDetails?.offerOwner?.userId === userId}
                    >
                        <FormattedMessage id="employee-offer-details__apply" />
                    </Button>
                ) : (
                    <Button
                        className={styles["offer-details__apply-button"]}
                        onClick={handleLoginButton}
                        data-testid={`${testId}__apply-login-button`}
                    >
                        <FormattedMessage id="employee-offer-details__apply" />
                    </Button>
                )}
                {offerDetails?.offerOwner?.userId === userId && (
                    <div className={styles["offer-details__info-text"]}>
                        <FormattedMessage id="offer-details__your-offer" values={{ offerType: "EMPLOYEE" }} />
                    </div>
                )}
                <SendMessage
                    isVisible={sendMessageModalVisible}
                    maxMessageLength={maxMessageLength}
                    onClose={onSendMessageClose}
                    offerId={offerId}
                    messageType={MessageType.EmployeeOffer}
                    askAboutPositionTitle={offerDetails?.title}
                    data-testid={`${testId}__send-message`}
                    onSuccess={handleSendMessageSuccess}
                    messageTarget="employee"
                />
                {!!offerDetails?.offerOwner?.userId && offerSubmissionModalVisible && (
                    <OfferSubmissionModal
                        onClose={closeOfferSubmissionModal}
                        dedicatedUserId={offerDetails?.offerOwner?.userId}
                        dedicatedBusinessCardId={Number(offerDetails.businessCardId)}
                    />
                )}
                {sendDedicationSuccessModal && (
                    <ModalWithContent
                        header={<SuccessHeader><FormattedMessage id="employee-offer-submit__message" values={{ offerId }}/></SuccessHeader>}
                        content={''}
                        primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                        primaryAction={() => setSendDedicationSuccessModal(false)}
                        onClose={() => setSendDedicationSuccessModal(false)}
                        cancelButtonHidden
                    />
                )}
                {sendDedicationErrorModal && (
                    <ModalWithContent
                        header={<ErrorHeader><FormattedMessage id="employee-offer-submit__error-message" values={{ offerId }}/></ErrorHeader>}
                        content={''}
                        primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                        primaryAction={() => setSendDedicationErrorModal(false)}
                        onClose={() => setSendDedicationErrorModal(false)}
                        cancelButtonHidden
                    />
                )}
            </div>
        </div>
    );
};

export default DetailsActions;
