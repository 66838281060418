import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import classNames from "classnames";
import { DeviceClass } from "features/common/types";
import React, { MutableRefObject } from "react";
import { FormattedMessage } from "react-intl";
import ControlButtons from "./ControlButtons";
import MobileBackdrop from "./MobileBackdrop";
import styles from "./styles.module.scss";

interface Props {
    children: React.ReactElement;
    deviceClass: DeviceClass;
    calendarsContainerRef: MutableRefObject<HTMLDivElement | null>;
    withRangePicker?: boolean;
    toggleWidth?: number;
    ["data-testid"]: string;
    setCalendarExpanded: (state: boolean) => void;
    onCalendarsContainerScroll: () => void;
    onDateSelectApprove: () => void;
}

const CalendarsContainer = ({
    "data-testid": testId,
    children,
    deviceClass,
    calendarsContainerRef,
    withRangePicker,
    toggleWidth,
    setCalendarExpanded,
    onCalendarsContainerScroll,
    onDateSelectApprove,
}: Props) => {
    return (
        <MobileBackdrop
            data-testid={`${testId}__mobile-backdrop`}
            className={styles["calendars-container__calendar-backdrop"]}
            deviceClass={deviceClass}
        >
            <div className={styles["calendars-container__outer-container"]}>
                {deviceClass !== "desktop" && (
                    <h1
                        data-testid={`${testId}__header`}
                        className={classNames(styles["calendars-container__calendar-header"], {
                            [styles["calendars-container__calendar-header--with-bottom-border"]]: withRangePicker,
                        })}
                    >
                        <FormattedMessage id="datepicker__select-date" />
                        <div>
                            <TimesIcon
                                data-testid={`${testId}__close-icon`}
                                onClick={() => setCalendarExpanded(false)}
                            />
                        </div>
                    </h1>
                )}
                <div
                    onScroll={onCalendarsContainerScroll}
                    ref={calendarsContainerRef}
                    className={classNames(
                        styles["calendars-container__calendar-container"],
                        {
                            [styles["calendars-container__calendar-container--desktop-range-picker"]]:
                                withRangePicker && deviceClass === "desktop",
                        },
                        {
                            [styles["calendars-container__calendar-container--mobile-range-picker"]]:
                                withRangePicker && deviceClass !== "desktop",
                        }
                    )}
                // style={deviceClass === "desktop" && !withRangePicker ? { width: toggleWidth } : {}}
                >
                    {children}
                </div>
                <ControlButtons
                    data-testid={testId}
                    toggleCalendarExpandState={setCalendarExpanded}
                    onDateSelectApprove={onDateSelectApprove}
                />
            </div>
        </MobileBackdrop>
    );
};

export default CalendarsContainer;
