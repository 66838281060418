import { ApiError } from "api/utils";
import CommonAction, {
    getBranches,
    getCityDetails,
    getCountries,
    getCurrencies,
    getLocationsDetails,
    getLocationSuggestions,
    getSearchTermsSuggestions,
    resetCityDetails,
    resetLocationSuggestions,
    resetSearchTermsSuggestions,
    resetUserCards,
    resetCountries,
} from "features/common/actions";
import mapBranchesDtoToDictionaryItems from "features/common/mappers/mapBranchesDtoToDictionaryItems";
import mapCurrenciesDtoToCurrencies from "features/common/mappers/mapCurrenciesDtoToCurrencies";
import { mapLocationsDetailsDtoToLocationsTags } from "features/common/mappers/mapLocationsDetailsDtoToLocationsTags";
import mapLocationSuggestionDtoToCityDetails from "features/common/mappers/mapLocationSuggestionDtoToCityDetails";
import mapLocationSuggestionDtoToLocationSuggestion from "features/common/mappers/mapLocationSuggestionDtoToLocationSuggestion";
import mapSearchTermSuggestionDtoToSearchFilter from "features/common/mappers/mapSearchTermSuggestionDtoToSearchFilter";
import { Currency } from "features/common/models";
import {
    Card,
    DictionaryItem,
    LocationOption,
    LocationSuggestion,
    SearchTermSuggestion,
    Tag,
} from "features/common/types";
import produce from "immer";
import { getType } from "typesafe-actions";
import { CountriesDto } from "../dtos";

export interface CommonState {
    [x: string]: any;
    branches?: DictionaryItem[];
    branchesLoading?: boolean;
    branchesLoadingError?: ApiError;
    workTypes?: DictionaryItem[];
    workTypesLoading?: boolean;
    workTypesLoadingError?: ApiError;
    currencies?: Currency[];
    currenciesLoading?: boolean;
    currenciesLoadingError?: ApiError;
    userCompanyCards?: Card[];
    userPrivateCards?: Card[];
    userCardsLoading: boolean;
    userCardsLoadingError?: ApiError;
    searchTermSuggestions?: SearchTermSuggestion;
    locationSuggestions?: LocationSuggestion;
    cityDetails?: LocationOption<string>;
    cityDetailsLoading: boolean;
    locationsDetails?: Tag[];
    locationsDetailsLoading: boolean;
    locationsDetailsLoadingError?: ApiError;
    countries?: CountriesDto[];
    countriesLoading?: boolean;
    countriesError?: ApiError;
}

const reducer = (
    state: CommonState = { userCardsLoading: false, cityDetailsLoading: false, locationsDetailsLoading: false },
    action: CommonAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getBranches.request):
                draft.branchesLoading = true;
                draft.branches = undefined;
                draft.branchesLoadingError = undefined;
                break;
            case getType(getBranches.success):
                draft.branchesLoading = false;
                draft.branches = mapBranchesDtoToDictionaryItems(action.payload);
                draft.branchesLoadingError = undefined;
                break;
            case getType(getBranches.failure):
                draft.branchesLoading = false;
                draft.branches = undefined;
                draft.branchesLoadingError = action.payload;
                break;
            case getType(getSearchTermsSuggestions.request):
                draft.searchTermSuggestions = undefined;
                break;
            case getType(getSearchTermsSuggestions.success):
                draft.searchTermSuggestions = mapSearchTermSuggestionDtoToSearchFilter(action.payload);
                break;
            case getType(getSearchTermsSuggestions.failure):
                draft.searchTermSuggestions = undefined;
                break;
            case getType(resetSearchTermsSuggestions):
                draft.searchTermSuggestions = undefined;
                break;
            case getType(getCurrencies.request):
                draft.currenciesLoading = true;
                draft.currencies = undefined;
                draft.currenciesLoadingError = undefined;
                break;
            case getType(getCurrencies.success):
                draft.currenciesLoading = false;
                draft.currencies = mapCurrenciesDtoToCurrencies(action.payload);
                draft.currenciesLoadingError = undefined;
                break;
            case getType(getCurrencies.failure):
                draft.currenciesLoading = false;
                draft.currencies = undefined;
                draft.currenciesLoadingError = action.payload;
                break;
            case getType(getLocationSuggestions.request):
                draft.searchTermSuggestions = undefined;
                break;
            case getType(getLocationSuggestions.success):
                draft.locationSuggestions = mapLocationSuggestionDtoToLocationSuggestion(action.payload);
                break;
            case getType(getLocationSuggestions.failure):
                draft.locationSuggestions = undefined;
                break;
            case getType(resetLocationSuggestions):
                draft.locationSuggestions = undefined;
                break;
            case getType(getCityDetails.request):
                draft.cityDetails = undefined;
                draft.cityDetailsLoading = true;
                break;
            case getType(getCityDetails.success):
                draft.cityDetails = mapLocationSuggestionDtoToCityDetails(action.payload);
                draft.cityDetailsLoading = false;
                break;
            case getType(getCityDetails.failure):
                draft.cityDetails = undefined;
                draft.cityDetailsLoading = false;
                break;
            case getType(resetCityDetails):
                draft.cityDetails = undefined;
                break;
            case getType(resetUserCards):
                draft.userCompanyCards = undefined;
                draft.userPrivateCards = undefined;
                draft.userCardsLoading = false;
                draft.userCardsLoadingError = undefined;
                break;
            case getType(getLocationsDetails.request):
                draft.locationsDetailsLoading = true;
                draft.locationsDetails = undefined;
                draft.locationsDetailsLoadingError = undefined;
                break;
            case getType(getLocationsDetails.success):
                draft.locationsDetailsLoading = false;
                draft.locationsDetails = mapLocationsDetailsDtoToLocationsTags(action.payload);
                draft.locationsDetailsLoadingError = undefined;
                break;
            case getType(getLocationsDetails.failure):
                draft.locationsDetailsLoading = false;
                draft.locationsDetails = undefined;
                draft.locationsDetailsLoadingError = action.payload;
                break;
            case getType(getCountries.request):
                draft.countriesLoading = true;
                draft.countries = undefined;
                draft.countriesError = undefined;
                break;
            case getType(getCountries.success):
                draft.countriesLoading = false;
                draft.countries = action.payload;
                draft.countriesError = undefined;
                break;
            case getType(getCountries.failure):
                draft.countriesLoading = false;
                draft.countries = undefined;
                draft.countriesError = action.payload;
            case getType(resetCountries):
                draft.countries = undefined;
                break;
        }
    });
};

export default reducer;
