import JobImage from "assets/images/lookingforajob.svg";
import WorkImage from "assets/images/lookingforaworker.svg";
import Box from "components/Box";
import Button from "components/Button";
import Tile from "components/Tile";
import {appRoutes} from "features/routing/routes";
import React from "react";
import {useIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import styled from "styled-components/macro";
import {RootState} from "../../store";
import {selectIsUserLoggedIn, selectUser} from "../user/selectors";
import {getUserAsync, logoutUserAsync} from "../user/thunks";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getUserAsync, logoutUserAsync }, dispatch);

const Heroes = ({ isUserLoggedIn }: { isUserLoggedIn?: boolean }) => {
    const intl = useIntl();

    return (
        <Wrapper>
            <Item>
                <Link to={isUserLoggedIn ? appRoutes.jobOffers : appRoutes.register}>
                    <Box gridTemplateColumns="1fr 1fr" position="relative">
                        <Image src={JobImage} />
                        <TextBox>
                            <h3>{intl.formatMessage({ id: "dashboard__looking-for-work" })}</h3>
                            {!isUserLoggedIn && <p>{intl.formatMessage({ id: "dashboard__create-account-info" })}</p>}
                            <StyledButton variant="darkblue">
                                {isUserLoggedIn
                                    ? intl.formatMessage({ id: "dashboard__check-job-offers" })
                                    : intl.formatMessage({ id: "dashboard__register" })}
                            </StyledButton>
                        </TextBox>
                    </Box>
                </Link>
            </Item>
            <Item>
                <Link to={isUserLoggedIn ? appRoutes.employeeOffers : appRoutes.register}>
                    <Box gridTemplateColumns="1fr 1fr" position="relative">
                        <Image src={WorkImage} />
                        <TextBox>
                            <h3>{intl.formatMessage({ id: "dashboard__looking-for-workers" })}</h3>
                            {!isUserLoggedIn && <p>{intl.formatMessage({ id: "dashboard__create-account-info" })}</p>}
                            <StyledButton variant="darkblue">
                                {isUserLoggedIn
                                    ? intl.formatMessage({ id: "dashboard__check-employee-offers" })
                                    : intl.formatMessage({ id: "dashboard__register" })}
                            </StyledButton>
                        </TextBox>
                    </Box>
                </Link>
            </Item>
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    gap: 48px;
    @media (max-width: 1000px) {
     gap: 24px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
       }
    h3,
    p {
        color: #002677;
        padding: 0 0 18px 0;
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    h3 {
        font-size: 26px;
        @media (max-width: 996px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            font-size: 26px;
        }
    }
    p{
        font-size: 16px;
        @media (max-width: 996px) {
            font-size: 12px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

`;

const Item = styled(Tile)`
    width: 100%;
    padding: 24px;
    @media (max-width: 996px) {
        padding: 16px;
    }
    @media (max-width: 767px) {
        padding: 30px 30px 40px 30px;
    }
    a {
        @media (max-width: 500px) {
        max-height: 370px;
        }
        height: 100%;

        div {
            height: 100%;
        }
    }

    @media (max-width: 767px) {
        a > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
`;

const TextBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
    padding: 48px 0;
    @media (max-width: 1100px) {
        padding-bottom: 24px;
    }
    @media (max-width: 1000px) {
        justify-content: flex-end;
        padding-bottom: 16px;
    }
    @media (max-width: 767px) {
        padding-top: 32px;
        justify-content: center;
        align-items: center;
        width: 50%;
    }
    @media (max-width: 600px) {
        width: auto;
    }
`;

const StyledButton = styled(Button)`
    padding: 0 6%;
    max-width: 260px;
    min-width: 200px;
    font-size: 16px;
    @media (max-width: 1000px) {
        padding: 0 5%;
    }
    @media (max-width: 767px) {
        padding: 0 10%;
        width: 80%;
    }
`;

const Image = styled.img`
    margin: 16px;
    display: block;
    height: auto;
    width: 70%;
    align-self: center;
    @media (max-width: 996px) {
        width: 90%;
        margin: 0;
       }
    @media (max-width: 767px) {
        width: 50%;
    }
    @media (max-width: 600px) {
        width: 80%;
    }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Heroes);
