import {Subheadline} from "components/Text";
import React from "react";
import styled from "styled-components/macro";
import {isTouchDevice} from "tests/helpers";

type Props = {
    subheadline: string;
    children: React.ReactNodeArray;
    style?: React.CSSProperties;
    offersTitle?: boolean;
};

const Offers = ({ subheadline, children, style, offersTitle=false }: Props) => {
    return (
        <>
            <Subheadline offersTitle style={style}>{subheadline}</Subheadline>
            <OverFlowWrapper>
                <Wrapper overflow={isTouchDevice()}>
                    <CarouselWrapper />
                    {children}
                    <CarouselWrapper />
                </Wrapper>
            </OverFlowWrapper>
        </>
    );
};

const CarouselWrapper = styled.div`
    width: 1.1rem;

    @media screen and (min-width: 834px) {
        width: 0;
        margin-right: -0.35rem;
    }

    @media screen and (min-width: 1440px) {
        width: 1.3rem;
    }
`;

const OverFlowWrapper = styled.div`
    min-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar{
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0 auto;
    
    @media screen  and (max-width: 1439px) {
        max-width: 100vw;
    }

    @media screen and (min-width: 1440px) {
        min-width: 100%;
        margin: 0;
        overflow-x: visible;
    }
    `;

const Wrapper = styled.div<{ overflow?: boolean }>`
width: max-content;
    display: flex;
    gap: 1.25rem;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.875rem;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    @media screen and (min-width: 834px) {
        gap: 2rem;
        padding-top: 1rem;
    }

    @media screen and (min-width: 1440px) {
        gap: 2.625rem;
        overflow-x: visible;
    }
`;

export default Offers;
