import { DropdownOption, TranslatedSectionDropdownOption } from "features/common/types";
import React, { useState } from "react";

export type Direction = "up" | "down" | undefined;

export interface ChildrenProps<T> {
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    resetSelectedIndex: () => void;
    selectedOption?: DropdownOption<T>;
    lastDirection: Direction;
}

export interface Props<T> {
    sections: TranslatedSectionDropdownOption<T>[];
    expanded: boolean;
    children: (props: ChildrenProps<T>) => JSX.Element;
    onOptionSelected: (option: DropdownOption<T>, section?: string) => void;
}

const DropdownSelectionProvider = <T extends any>({ sections, expanded, children, onOptionSelected }: Props<T>) => {
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
    const [lastDirection, setLastDirection] = useState<Direction>(undefined);

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (expanded && event.key === "ArrowDown") {
            setSelectedIndex(selectedIndex === undefined ? 0 : selectedIndex + 1);
            setLastDirection("down");
        }
        if (expanded && event.key === "ArrowUp") {
            setSelectedIndex(selectedIndex === undefined ? -1 : selectedIndex - 1);
            setLastDirection("up");
        }
        if (expanded && event.key === "Enter" && selectedOption !== undefined) {
            onOptionSelected(selectedOption, selectedOption.section?.value);
            event.preventDefault();
        }
    };

    const resetSelectedIndex = () => {
        setSelectedIndex(undefined);
    };

    const selectedOption = (() => {
        if (selectedIndex !== undefined) {
            let processedOptions = 0;
            const normalizedIndex =
                selectedIndex % sections.reduce((current, next) => current + next.options.length, 0);

            for (const section of sections) {
                const index = normalizedIndex - processedOptions;

                if (section.options.length < index + 1) {
                    processedOptions += section.options.length;
                } else {
                    return section.options[index];
                }
            }
        }
    })();

    return children({ onKeyDown, selectedOption, resetSelectedIndex, lastDirection });
};

export default DropdownSelectionProvider;
