import useDeviceClass from "features/common/hooks/useDeviceClass";
import React from "react";
import { FormattedMessage } from "react-intl";
import OfferSearchComponent from "../OfferSearch";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
}
const ModeratorPanel = ({ "data-testid": testId = "moderator-panel" }: Props) => {
    const isNotDesktop = useDeviceClass() !== "desktop";
    return (
        <div data-testid={testId} className={styles["moderator-panel"]}>
            <div className={styles["moderator-panel__section"]}>
                <h2>
                    <FormattedMessage id="common__moderator-panel" />
                </h2>
            </div>

            <div className={styles["moderator-panel__section"]}>
                {isNotDesktop && <div className={styles["offers-panel__line-breaker"]} />}
                <OfferSearchComponent />
            </div>

        </div>
    );
};

export default ModeratorPanel;
