import React from "react";
import { FormattedMessage } from "react-intl";

const mapMonthNumberToName = (monthNumber: number) => {
    switch (monthNumber) {
        case 0:
            return <FormattedMessage id="calendar__january" />;
        case 1:
            return <FormattedMessage id="calendar__february" />;
        case 2:
            return <FormattedMessage id="calendar__march" />;
        case 3:
            return <FormattedMessage id="calendar__april" />;
        case 4:
            return <FormattedMessage id="calendar__may" />;
        case 5:
            return <FormattedMessage id="calendar__june" />;
        case 6:
            return <FormattedMessage id="calendar__july" />;
        case 7:
            return <FormattedMessage id="calendar__august" />;
        case 8:
            return <FormattedMessage id="calendar__september" />;
        case 9:
            return <FormattedMessage id="calendar__october" />;
        case 10:
            return <FormattedMessage id="calendar__november" />;
        case 11:
            return <FormattedMessage id="calendar__december" />;
    }
};

export default mapMonthNumberToName;
