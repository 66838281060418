import {
    selectMyApplications,
    selectMyApplicationsLoading,
    selectMyApplicationsError,
} from "features/myProfile/selectors";
import {
    selectAcceptApplicationSuccess,
    selectRejectApplicationSuccess,
    selectAcceptArrivalSuccess,
    selectGiveBonusSuccess,
} from "features/jobOffers/selectors";
import { getMyApplicationsAsync } from "features/myProfile/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import MyApplications from "./component";

const mapStateToProps = (state: RootState) => ({
    myApplications: selectMyApplications(state),
    myApplicationsLoading: selectMyApplicationsLoading(state),
    myApplicationsError: selectMyApplicationsError(state),
    acceptApplicationSuccess: selectAcceptApplicationSuccess(state),
    rejectApplicationSuccess: selectRejectApplicationSuccess(state),
    acceptArrivalSuccess: selectAcceptArrivalSuccess(state),
    giveBonusSuccess: selectGiveBonusSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getMyApplicationsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyApplications);
