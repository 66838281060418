import classNames from "classnames";
import DropzoneFileUploader from "features/common/components/DropzoneFileUploader";
import Input from "features/common/components/Input";
import RadioButtons from "features/common/components/RadioButtons";
import TagInput from "features/common/components/TagInput";
import { allowedImageTypes, maxImageSize } from "features/common/constants";
import { getLocationMessage } from "features/common/translationMessages";
import { Image } from "features/common/types";
import EmployeeOffersApi from "features/employeeOffers/api";
import { EmployeeOfferDetailsStepSchema } from "features/employeeOffers/schemas";
import { EmployerType } from "features/jobOffers/types";
import PhonePrefixSelector from "features/myProfile/components/PhonePrefixSelector";
import { createOfferFieldNames } from "features/offers/constants";
import { useCreateOfferField } from "features/offers/hooks";
import useUploadPhotos from "features/offers/hooks/useUploadPhotos";
import { PhotoDto } from "features/user/dtos";
import React, { useEffect, useMemo, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { locationFormatter } from "utils";
import styles from "./styles.module.scss";

const createOfferAsOptions = (intl: IntlShape) => [
    {
        label: intl.formatMessage({ id: "common__company" }),
        value: EmployerType.Company,
    },
    {
        label: intl.formatMessage({ id: "common__private-person" }),
        value: EmployerType.Private,
    },
];

export interface Props {
    values: EmployeeOfferDetailsStepSchema;
    isCopyOrIsEdit: boolean;
    initialPhotos: Image[];
    setDownloadPhotos: React.Dispatch<React.SetStateAction<PhotoDto[]>>;
    setUploadingImages: React.Dispatch<React.SetStateAction<boolean>>;
    uploadingImages: boolean;
    changer: any;
}

const DetailsStep = ({ values, isCopyOrIsEdit, setDownloadPhotos, initialPhotos, setUploadingImages, uploadingImages, changer }: Props) => {
    const intl = useIntl();
    const locationIntlMessage = getLocationMessage(intl);
    const [location, setLocation] = useState("");
    const [selectedTag, setSelectedTag] = useState<any>();
    const [locationOptions, setLocationOptions] = useState<any>([]);

    const offerAsOptions = useMemo(() => createOfferAsOptions(intl), [intl]);

    const [offerAsField, , offerAsHandlers] = useCreateOfferField<EmployerType | undefined>(
        "detailsStep",
        createOfferFieldNames.detailsStep.offerAs
    );

    const [businessNameField, businessNameMeta] = useCreateOfferField<string | undefined>(
        "detailsStep",
        createOfferFieldNames.detailsStep.businessName
    );

    const [krezCertField, krezCertMeta] = useCreateOfferField<string | undefined>(
        "detailsStep",
        createOfferFieldNames.detailsStep.krazCertificateNumber
    );

    const [taxIdField, taxIdMeta] = useCreateOfferField<string | undefined>(
        "detailsStep",
        createOfferFieldNames.detailsStep.taxIdentificationNumber
    );

    const [phoneNumberField, phoneNumberMeta] = useCreateOfferField<string | undefined>(
        "detailsStep",
        createOfferFieldNames.detailsStep.phoneNumber
    );

    const [locationField, locationMeta, locationHandlers] = useCreateOfferField<any>(
        "detailsStep",
        createOfferFieldNames.detailsStep.location
    );

    const [photosField, , photosHandlers] = useCreateOfferField<Image[] | undefined>(
        "detailsStep",
        createOfferFieldNames.detailsStep.photos
    );
    const [photoPrefixField, , phonePrefixHandlers] = useCreateOfferField(
        "detailsStep",
        createOfferFieldNames.detailsStep.phonePrefix
    );

    const {
        placePredictions,
        getPlacePredictions,
        placesService
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500
    });

    const [clearPhotos, setClearPhotos] = useState<boolean>(false)

    // upload image logic
    useUploadPhotos(photosField.value, EmployeeOffersApi, setUploadingImages, initialPhotos, setDownloadPhotos, []);

    useEffect(() => {
        changer(clearPhotos)
        if (clearPhotos) {
            setDownloadPhotos([])
        }
    }, [clearPhotos])

    useEffect(() => {
        getPlacePredictions({ input: location, types: ['(regions)'] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id,
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [location, placePredictions]);

    useEffect(() => {
        if (locationField.value.placeId) {
            placesService?.getDetails({ placeId: locationField.value.placeId }, (placeDetails) => {
                const detailedAdress = {
                    placeId: locationField.value.placeId,
                    input: placeDetails?.formatted_address || "",
                };
                locationHandlers.setValue(detailedAdress);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placesService]);

    useEffect(() => {
        if (!selectedTag) return;

        const detailedAdress = {
            placeId: selectedTag.id,
            input: selectedTag.name,
        };

        locationHandlers.setValue(detailedAdress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTag]);

    const isPrivate = offerAsField.value === "PRIVATE_PERSON";

    const locationToShow = locationFormatter(locationField.value);

    const cityError = locationMeta.error as any;

    return (
        <div className={styles["details-step"]} id={"detailsStep"}>
            <RadioButtons<EmployerType>
                data-testid="details-step__offer-as"
                inputClassName={styles["details-step__offer-as-input"]}
                options={offerAsOptions}
                wrapperClassName={styles["details-step__offer-as-wrapper"]}
                titleClassName={styles["details-step__offer-as-title"]}
                inputsContainerClassName={styles["details-step__offer-as-inputs-container"]}
                title={intl.formatMessage({ id: "create-offer__employee-offer-as" })}
                value={offerAsField.value}
                onValueChange={(value: EmployerType) => offerAsHandlers.setValue(value)}
                disabled={isCopyOrIsEdit}
            />
            <div className={styles["details-step__title"]}>
                <FormattedMessage id="create-offer__your-data" />
            </div>
            <div className={styles["details-step__name-container"]}>
                <div className={styles["details-step__name-container--field"]}>
                    <div className={styles["details-step__name-container--label"]}>
                        <FormattedMessage id="common__first-name" />
                    </div>
                    <div className={styles["details-step__name-container--value"]}>{values.firstName}</div>
                </div>
                <div className={styles["details-step__name-container--field"]}>
                    <div className={styles["details-step__name-container--label"]}>
                        <FormattedMessage id="common__last-name" />
                    </div>
                    <div className={styles["details-step__name-container--value"]}>{values.lastName}</div>
                </div>
            </div>
            <div className={styles["details-step__data-section"]} style={{ columnGap: isPrivate ? "0" : "5.75rem" }}>
                <div className={styles["details-step__data-column"]} style={{ flex: isPrivate ? "0" : " 1 1" }}>
                    {offerAsField.value !== "PRIVATE_PERSON" && (
                        <>
                            <Input
                                label={`${intl.formatMessage({ id: "common__company-name" })}*`}
                                placeholder={`${intl.formatMessage({ id: "common__company-name" })}*`}
                                {...{ ...businessNameField, ...businessNameMeta }}
                                withValidation
                                isInvalid={!!businessNameMeta.error && !!businessNameMeta.touched}
                                wrapperClassName={styles["details-step__input"]}
                                data-testid="details-step__business-name-input"
                                disabled={isCopyOrIsEdit}
                            />
                            <Input
                                label={intl.formatMessage({ id: "common__nip-code" })}
                                placeholder={intl.formatMessage({ id: "common__nip-code" })}
                                {...{ ...taxIdField, ...taxIdMeta }}
                                withValidation
                                isInvalid={!!taxIdMeta.error && !!taxIdMeta.touched}
                                wrapperClassName={styles["details-step__input"]}
                                data-testid="details-step__tax-id-input"
                                disabled={isCopyOrIsEdit}
                            />
                            <Input
                                label={intl.formatMessage({ id: "common__company-certificate" })}
                                placeholder={intl.formatMessage({ id: "common__company-certificate" })}
                                {...{ ...krezCertField, ...krezCertMeta }}
                                withValidation
                                isInvalid={!!krezCertMeta.error && !!krezCertMeta.touched}
                                wrapperClassName={styles["details-step__input"]}
                                data-testid="details-step__krez-cert-input"
                                disabled={isCopyOrIsEdit}
                            />
                        </>
                    )}
                </div>
                <div className={classNames(styles["details-step__data-column"],
                    { [styles["details-step__data-column--is-private"]]: isPrivate }
                )}>
                    <div className={classNames(styles["details-step__input"],
                        { [styles["details-step__input--is-private"]]: isPrivate }
                    )}>
                        <TagInput
                            value={{ input: typeof locationField.value.input === 'string' ? locationField.value.input : locationToShow, tags: [] }}
                            onChange={(tags, input) => {
                                locationHandlers.setTouched(true);
                                setLocation(input);
                                if (tags.length) {
                                    const [newValue] = tags;
                                    setSelectedTag(newValue);
                                    return locationHandlers.setValue({ input: newValue.name, tags });
                                }
                                return locationHandlers.setValue({ tags, input });
                            }}
                            sections={locationOptions}
                            label={`${locationIntlMessage}*`}
                            disabled={isCopyOrIsEdit}
                            placeholder={`${locationIntlMessage}*`}
                            error={locationMeta.touched && (cityError?.country || cityError?.latitude || cityError?.longitude)}
                        />
                    </div>
                    <PhonePrefixSelector
                        value={photoPrefixField.value as string}
                        disabled={isCopyOrIsEdit}
                        onChange={(newValue) => phonePrefixHandlers.setValue(newValue)}>
                        <Input
                            label={`${intl.formatMessage({ id: "common__phone-number-short" })}*`}
                            placeholder={`${intl.formatMessage({ id: "common__phone-number-short" })}*`}
                            {...{ ...phoneNumberField, ...phoneNumberMeta }}
                            withValidation
                            isInvalid={!!phoneNumberMeta.error && !!phoneNumberMeta.touched}
                            wrapperClassName={classNames(styles["details-step__input"],
                                { [styles["details-step__input--is-private"]]: isPrivate }
                            )}
                            data-testid="details-step__phone-number-input"
                            disabled={isCopyOrIsEdit}
                        />
                    </PhonePrefixSelector>
                </div>
            </div>
            <div className={styles["details-step__title"]}>
                <FormattedMessage id="create-offer__photos" />
            </div>
            <DropzoneFileUploader
                files={photosField.value}
                maxSize={maxImageSize}
                allowedTypes={allowedImageTypes}
                data-testid="create-offer__photos"
                onUpload={(images) => photosHandlers.setValue(images)}
                allowMultipleFiles
                multipleFilesAmount={40}
                textAsPlaceholder
                buttonDisabled={uploadingImages}
                buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-files" />}
                setClearPhotos={setClearPhotos}
            />
        </div>
    );
};

export default DetailsStep;
