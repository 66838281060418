import Api from "api";
import config from 'config';
import { RateForm, RatePreviewResponse } from "features/rating/models";

export class RatingApi extends Api {
    rateEmployerAsync = async (rateForm: RateForm): Promise<void> => {
        if (rateForm.isApplicant) {
            return this.post<RateForm, void>(config.apiRoutes.RATE_OFFER_OWNER, rateForm)
        } else return this.post<RateForm, void>(config.apiRoutes.RATE_APPLICANT_EMPLOYER, rateForm)
    };

    rateEmployeeAsync = async (rateForm: RateForm): Promise<void> => {
        return this.post<RateForm, void>(config.apiRoutes.RATE_APPLICANT_EMPLOYEE, rateForm)
    };

    getEmployeeRateAsync = async (formId: number): Promise<RatePreviewResponse> => {
        return this.get<RatePreviewResponse>(`${config.apiRoutes.EMPLOYEE_RATE_PREVIEW}/${formId}`)
    };

    getEmployerRateAsync = async (formId: number): Promise<RatePreviewResponse> => {
        return this.get<RatePreviewResponse>(`${config.apiRoutes.EMPLOYER_RATE_PREVIEW}/${formId}`)
    };
}

export default new RatingApi();
