/* eslint-disable max-lines */
import { ApiError } from "api/utils";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import Pagination from "features/common/components/Pagination";
import Spinner from "features/common/components/Spinner";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { Currency } from "features/common/models";
import { DictionaryItem, SectionName } from "features/common/types";
import { cookieNames } from "features/cookies/constants";
import { getCookie, setCookie } from "features/cookies/utils";
import EmployeeOfferItem from "features/employeeOffers/components/EmployeeOfferItem";
import { EmployeeOfferDetailsDto } from "features/employeeOffers/dtos";
import { mapEmployeeOfferDetailsResponseDtoToOfferDetails } from "features/employeeOffers/mappers";
import { EmployeeOffersFetchParams, EmployeeOffersFilterMatchCounts } from "features/employeeOffers/types";
import FavoriteItem from "features/favorite/components/FavoriteItem";
import { FavoriteEmployeeOrEmployer } from "features/favorite/models";
import CreateJobOfferModal from "features/jobOffers/components/CreateJobOfferModal";
import JobOfferItem from "features/jobOffers/components/JobOfferItem";
import { JobOfferDetailsDto } from "features/jobOffers/dtos";
import { mapJobOfferDetailsDtoToJobOfferDetails } from "features/jobOffers/mappers";
import { JobOffersFetchParams, JobOffersFilterMatchCounts } from "features/jobOffers/types";
import Filters from "features/offers/components/Filters";
import { FinancialConditionsSlimDTO, LocationSlimDTO } from "features/offers/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
    asFavoriteOffersList?: boolean;
    asJobOffersList?: boolean;
    offers: any[];
    asFavoriteEmployeeOrEmployer?: boolean;
    fetchParams: JobOffersFetchParams | EmployeeOffersFetchParams | null;
    locationSlimDto: LocationSlimDTO;
    financialConditionsSlimDTO: FinancialConditionsSlimDTO;
    branchDTO: { id?: number; name?: string };
    totalOffers: number;
    offersLoading?: boolean;
    offersLoadingError?: ApiError;
    branches?: DictionaryItem[];
    branchesLoading?: boolean;
    workTypes?: DictionaryItem[];
    currencies?: Currency[];
    currenciesLoading?: boolean;
    filterMatchCounts?: JobOffersFilterMatchCounts | EmployeeOffersFilterMatchCounts;
    filterMatchCountsLoading?: boolean;
    isUserLoggedIn?: boolean;
    isFiltersLoadingError: boolean;
    onFetchParamsChange: (params: JobOffersFetchParams | EmployeeOffersFetchParams) => void;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    resetPaginationState: () => void;
}

interface HistoryLocationState {
    cameBack?: boolean;
}

const OffersList = ({
    asFavoriteOffersList,
    asJobOffersList,
    fetchParams,
    offers,
    totalOffers,
    offersLoading,
    offersLoadingError,
    branches,
    branchDTO,
    financialConditionsSlimDTO,
    locationSlimDto,
    branchesLoading,
    workTypes,
    currencies,
    currenciesLoading,
    filterMatchCounts,
    filterMatchCountsLoading,
    isUserLoggedIn,
    isFiltersLoadingError,
    onFetchParamsChange,
    currentPage,
    setCurrentPage,
    resetPaginationState,
    asFavoriteEmployeeOrEmployer
}: Props) => {
    const history = useHistory();
    const deviceClass = useDeviceClass();
    const filtersDataLoading = !!branchesLoading || !!filterMatchCountsLoading || !!currenciesLoading;
    const [filtersExpanded] = useState(false);
    const [shouldRenderList] = useState(!filtersExpanded || deviceClass === "desktop");
    const [createJobOfferModalVisible, setCreateJobOfferModalVisible] = useState(false);
    const historyLocationState = history.location.state as HistoryLocationState
    const cameBack = historyLocationState?.cameBack
    const scrollY: string = getCookie(cookieNames.SCROLL_POSITION);
    const [recordScroll, setRecordScroll] = useState(false)

    useEffect(() => {
        if (cameBack && scrollY && deviceClass === 'desktop') {
            setTimeout(() => {
                window.scrollTo(0, +scrollY)
            }, 100)
        }
    }, [cameBack])

    useEffect(() => {
        if (scrollY && (deviceClass === 'smartphone' || deviceClass === 'tablet') && offers.length > 0) {
            setRecordScroll(prevState => (cameBack ? true : !prevState))
            window.scrollTo(0, +scrollY)
        }
    }, [offers])

    useEffect(() => {
        if (deviceClass === 'smartphone' || deviceClass === 'tablet') setRecordScroll(prevState => !prevState)
    }, [currentPage])

    useEffect(() => {
        const unsub = () => {
            if (window.scrollY > 0 && recordScroll && (deviceClass === 'desktop' ? true : offers.length > 0)) {
                setCookie(cookieNames.SCROLL_POSITION, window.scrollY, { path: '/' });
            }
        }
        window.addEventListener('scroll', unsub);

        return () => window.removeEventListener('scroll', unsub);
    }, [history.location.pathname, currentPage, recordScroll, offers])

    const onAddOfferButtonClick = useCallback(() => {
        setCreateJobOfferModalVisible(true);
    }, [setCreateJobOfferModalVisible]);

    const filters = useMemo(
        () => fetchParams && (
            <Filters
                asFavoriteEmployeeOrEmployer={asFavoriteEmployeeOrEmployer}
                asJobOffersList={asJobOffersList}
                className={styles["offers-list__filters"]}
                fetchParams={fetchParams}
                totalOffers={totalOffers ?? 0}
                offersCount={offers?.length ?? 0}
                branches={branches}
                workTypes={workTypes}
                currencies={currencies}
                filterMatchCounts={filterMatchCounts}
                isLoading={filtersDataLoading}
                isLoadingError={isFiltersLoadingError}
                offersLoading={offersLoading}
                deviceClass={deviceClass}
                onFetchParamsChange={onFetchParamsChange}
                resetPaginationState={resetPaginationState}
            />
        ),
        [asJobOffersList, branches, currencies, deviceClass, fetchParams, filterMatchCounts, filtersDataLoading, isFiltersLoadingError, offers, offersLoading, onFetchParamsChange, resetPaginationState, totalOffers, workTypes, asFavoriteEmployeeOrEmployer]
    );

    const offerLabel = useMemo(
        () => (
            <div>
                <FormattedMessage id="offers-list__offers" values={{ offersAmount: totalOffers ?? 0 }} />
            </div>
        ),
        [totalOffers]
    );

    const renderItems = useCallback(() => {
        const filterByBusinessName = (businessName: string = "") => {
            if (fetchParams) {
                const clonedFetchParams = { ...fetchParams };
                clonedFetchParams.search = {
                    term: {
                        rawInputText: businessName,
                        section: SectionName.Company,
                        selectedSuggestionValue: businessName,
                    },
                    distance: 0,
                    location: [],
                };
                clonedFetchParams.amendOffers = false;
                clonedFetchParams.offset = (deviceClass === 'desktop') ? currentPage : 1;
                onFetchParamsChange(clonedFetchParams);
                resetPaginationState();
                window.scrollTo(0, 0);
            }
        };

        if (asFavoriteEmployeeOrEmployer) {
            return (
                <div className={styles["offers-list__offers-container"]}>
                    {offers.map((item: FavoriteEmployeeOrEmployer) => (
                        <FavoriteItem
                            item={item}
                        />
                    ))}
                </div>
            );
        } else {
            return (
                <div className={styles["offers-list__offers-container"]}>
                    {asJobOffersList
                        ? (offers).map(
                            (item: JobOfferDetailsDto) =>
                                item && (
                                    <JobOfferItem
                                        jobOfferDetails={mapJobOfferDetailsDtoToJobOfferDetails({ data: item })}
                                        key={item.id}
                                        {...item}
                                        isUserLoggedIn={isUserLoggedIn}
                                        filterByCompanyName={filterByBusinessName}
                                    />
                                )
                        )
                        : (offers).map((item: EmployeeOfferDetailsDto) => item && <EmployeeOfferItem employeeOfferDetails={mapEmployeeOfferDetailsResponseDtoToOfferDetails({ data: item })} key={item.id} {...item} isUserLoggedIn={isUserLoggedIn} />)}
                </div>
            );
        }
    }, [asFavoriteEmployeeOrEmployer, asJobOffersList, fetchParams, isUserLoggedIn, offers, onFetchParamsChange, resetPaginationState])

    return (
        <div className={styles["offers-list"]}>
            {!asFavoriteOffersList && (
                <div className={styles["offers-list__header"]}>
                    <div
                        className={classNames({
                            [styles["offers-list__number-of-offers--transparent"]]: !totalOffers,
                        })}
                    >
                        {offerLabel}
                    </div>
                    {isUserLoggedIn && (
                        <Button
                            as="div"
                            className={styles["offers-list__add-offer-button"]}
                            onClick={onAddOfferButtonClick}
                        >
                            <PlusIcon />
                            {asJobOffersList ? (
                                <FormattedMessage id="offers-list__add-job-offer" />
                            ) : (
                                <FormattedMessage id="offers__add-employee-offer" />
                            )}
                        </Button>
                    )}
                </div>
            )}
            <div className={styles["offers-list__offers-and-filters-container"]}>
                {filtersDataLoading && offersLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {deviceClass === "desktop" && filters}
                        {(!offersLoading || deviceClass !== "desktop") &&
                            !offersLoadingError &&
                            ((offers?.length ?? 0) > 0
                                ? shouldRenderList && (
                                    <div className={styles["offers-list__offers"]}>
                                        {renderItems()}
                                        <Pagination
                                            onPageChange={setCurrentPage}
                                            loadedItemsCount={currentPage * config.offersResultsPerPage ?? 0}
                                            pageSize={config.offersResultsPerPage}
                                            totalItemsCount={totalOffers}
                                            currentPage={currentPage}
                                        />
                                    </div>
                                )
                                : !offersLoading && (
                                    <div className={styles["offers-list__no-offers"]}>
                                        <FormattedMessage id="offers-list__no-offers" />
                                    </div>
                                ))}
                        {shouldRenderList && offersLoading && <Spinner />}
                        {offersLoadingError && (
                            <div className={styles["offers-list__fetch-failure"]}>
                                <FormattedMessage id="offers-list__fetch-failure" />
                            </div>
                        )}
                        {deviceClass !== "desktop" && filters}
                    </>
                )}
                <CreateJobOfferModal
                    isVisible={createJobOfferModalVisible}
                    handleCloseModal={() => setCreateJobOfferModalVisible(false)}
                    asJobOffersList={asJobOffersList}
                />
            </div>
        </div>
    );
};

export default OffersList;