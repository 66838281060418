import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import {
    LoginUserDto,
    PhotoDto,
    RegisterUserDto,
    TokensDto,
    UserDetailsDto,
    UserDto,
    UserProfileDto,
} from "features/user/dtos";
import {
    ForgottenPasswordParams,
    LoginOauthParams,
    LoginUserParams,
    LogoutUserParams,
    RecoverPasswordParams,
    RefreshTokenParams,
    ResendActivationEmailParams,
} from "features/user/types";

export class UserApi extends Api {
    getUserAsync = (userId: string, cancelToken?: CancelToken): Promise<UserDetailsDto> => {
        return this.get<UserDetailsDto>(`${config.apiRoutes.USER}`, cancelToken);
    };

    createUserProfileAsync = (formData: Partial<UserProfileDto>, cancelToken?: CancelToken): Promise<void> => {
        return this.put<Partial<UserDto>, void>(`${config.apiRoutes.USER}`, formData, cancelToken);
    };

    refreshTokenAsync = (refreshToken: string, cancelToken?: CancelToken): Promise<TokensDto> => {
        return this.post<RefreshTokenParams, TokensDto>(config.apiRoutes.REFRESH_TOKEN, { refreshToken }, cancelToken);
    };

    deactivateTokenAsync = (cancelToken?: CancelToken): Promise<void> => {
        return this.post<void, void>(`${config.apiRoutes.DEACTIVATE_TOKEN}`, undefined, cancelToken);
    };

    loginUserAsync = (params: LoginUserParams, cancelToken?: CancelToken): Promise<LoginUserDto> => {
        return this.post<LoginUserParams, LoginUserDto>(config.apiRoutes.LOGIN, params, cancelToken);
    };

    forgottenPasswordAsync = (params: ForgottenPasswordParams, cancelToken?: CancelToken): Promise<LoginUserDto> => {
        return this.post<ForgottenPasswordParams, LoginUserDto>(
            config.apiRoutes.FORGOTTEN_PASSWORD,
            params,
            cancelToken
        );
    };

    recoverPasswordAsync = (params: RecoverPasswordParams, cancelToken?: CancelToken): Promise<LoginUserDto> => {
        return this.post<RecoverPasswordParams, LoginUserDto>(
            config.apiRoutes.RECOVER_PASSWORD,
            params,
            cancelToken
        );
    };

    loginOathAsync = (params: LoginOauthParams, cancelToken?: CancelToken): Promise<LoginUserDto> => {
        return this.get<LoginUserDto>(`${config.apiRoutes.LOGIN_OAUTH}?code=${params.code}`, cancelToken);
    };

    registerUserAsync = (params: LoginUserParams, cancelToken?: CancelToken): Promise<RegisterUserDto> => {
        return this.post<LoginUserParams, RegisterUserDto>(config.apiRoutes.REGISTER, params, cancelToken);
    };

    resendActivationEmailAsync = (params: ResendActivationEmailParams, cancelToken?: CancelToken): Promise<void> => {
        return this.post<ResendActivationEmailParams, void>(config.apiRoutes.VERIFY, params, cancelToken);
    };

    logoutUserAsync = (params: LogoutUserParams, cancelToken?: CancelToken): Promise<void> => {
        return this.post<LogoutUserParams, void>(config.apiRoutes.LOGOUT, params, cancelToken);
    };

    uploadUserPhotos = (params: FormData, cancelToken?: CancelToken): Promise<PhotoDto[]> => {
        return this.post<FormData, PhotoDto[]>(config.apiRoutes.IMAGE_UPLOAD, params, cancelToken);
    };

    verifyUserAsync = (token: string, cancelToken?: CancelToken): Promise<void> => {
        return this.patch<void, void>(`${config.apiRoutes.VERIFY}/${token}`, undefined, cancelToken);
    };

    getUserProfileAsync = (userId: string, cancelToken?: CancelToken): Promise<UserDetailsDto> => {
        return this.get<UserDetailsDto>(`${config.apiRoutes.USER}/${userId}`, cancelToken);
    };
}

export default new UserApi();
