import Checkbox from "features/common/components/Checkbox";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import Input from "features/common/components/Input";
import { getRequirementStepEducationHeader } from "features/jobOffers/translationMessages";
import { Education, EducationType, RequirementEducationTouched } from "features/jobOffers/types";
import React from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    education: Education;
    educationsLabels: { [educationId: string]: string };
    educationsTouched: {
        [id: string]: RequirementEducationTouched;
    };
    values: Education[];
    index: number;
    error: any;
    ["data-testid"]?: string;
    onCheckboxToggle: (checked: boolean, id: EducationType) => void;
    onOtherEducationChange: (value: string) => void;
}

const LanguageOptionBlock = ({
    education,
    educationsLabels,
    educationsTouched,
    values,
    index,
    error,
    "data-testid": testId,
    onCheckboxToggle,
    onOtherEducationChange,
}: Props) => {
    const intl = useIntl();

    const educationLabel = getRequirementStepEducationHeader(intl);

    const errors = error ? (error[index] ? (error[index] as Object as Education) : null) : null;

    return (
        <div data-testid={testId} key={education.id} className={styles["education-option-block"]}>
            <Checkbox
                data-testid={testId ? `${testId}__checkbox` : undefined}
                className={styles["education-option-block__checkbox-label"]}
                key={"checkbox_" + education.id}
                label={educationsLabels[education.id]}
                checked={education.isChecked}
                onToggle={(checked) => onCheckboxToggle(checked, education.id)}
            />

            {education.isChecked && (
                <div key={"isChecked_" + education.id} className={styles["education-option-block__other"]}>
                    {education.id === EducationType.Other && (
                        <Input
                            data-testid={testId ? `${testId}__other-education-input` : undefined}
                            containerClassName={styles["education-option-block__other-input"]}
                            key={"input_" + education.id}
                            label={educationLabel}
                            onChange={(e) => onOtherEducationChange(e.target.value)}
                            value={values[index].otherValue}
                            withValidation
                            isInvalid={
                                educationsTouched[EducationType.Other].isOtherValueTouched &&
                                (errors?.otherValue === undefined ? false : true)
                            }
                            placeholder={educationLabel}
                            helperMessages={[<FieldRequiredMessage />]}
                            error={errors?.otherValue}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default LanguageOptionBlock;
