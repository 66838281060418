import { ReactComponent as HeartIcon } from "assets/icons/heart-empty.svg";
import RedDotCounter from "features/common/components/RedDotCounter";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss";

export interface Props {
    favoriteCount?: number;
    ["data-testid"]?: string;
    isMobile?: boolean;
}

const FavoriteNotification = ({ favoriteCount, "data-testid": testId, isMobile }: Props) => {
    return (
        <Link to='/favorite'
            data-testid={testId}
            className={classNames(styles["favorite-notification-icon"],
                { [styles["favorite-notification-icon--mobile"]]: isMobile }
            )}>
            <HeartIcon />
            <RedDotCounter
                className={styles["favorite-notification-icon__unread-counter"]}
                count={favoriteCount}
            />
        </Link>
    );
};

export default FavoriteNotification;
