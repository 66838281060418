import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { ReactComponent as LetterImage } from "assets/images/letterImage.svg";

import { ResendActivationEmailParams } from "features/user/types";
import {
    getActivationInfoTitleMessage,
    getActivationInfoTextMessage,
    getActivationInfoResendTextMessage,
    getActivationInfoResendButtonMessage,
    getActivationInfoResendConfirmation,
} from "features/user/translationMessages";
import { appRoutes } from "features/routing/routes";
import styles from "./styles.module.scss";
import { useEmailResend } from "./useEmailResend";

export type Props = {
    isLoggedIn?: boolean;
    resendActivationEmailAsync: (params: ResendActivationEmailParams) => void;
};

const ActivationInfo = ({ isLoggedIn, resendActivationEmailAsync }: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const email = useMemo(() => queryString.parse(history.location.search)?.email, [history.location.search]);
    const { resetEmailDate, isAfterCurrentDate } = useEmailResend(email as string);

    useEffect(() => {
        if (isLoggedIn || !email) {
            history.push(appRoutes.dashboard);
        }
    });

    const titleMessage = getActivationInfoTitleMessage(intl);
    const textMessage = getActivationInfoTextMessage(intl, <span>{email}</span>);
    const resendTextMessage = getActivationInfoResendTextMessage(intl);
    const resendButtonMessage = getActivationInfoResendButtonMessage(intl);
    const resendConfirmationMessage = getActivationInfoResendConfirmation(intl);

    const resendActivationLink = useCallback(() => {
        if (email && typeof email === "string" && !isAfterCurrentDate) {
            resendActivationEmailAsync({ email });
            resetEmailDate();
        } else return;
    }, [email, resendActivationEmailAsync, isAfterCurrentDate]);

    return (
        <div className={styles["activation-info"]}>
            <div className={styles["activation-info__title"]}>{titleMessage}</div>
            <div className={styles["activation-info__text"]}>{textMessage}</div>
            <div className={styles["activation-info__resend-container"]}>
                <div className={styles["activation-info__resend-text-message"]}>{resendTextMessage} </div>
                <div className={styles["activation-info__resend-button"]} onClick={resendActivationLink}>
                    {isAfterCurrentDate ? resendConfirmationMessage : resendButtonMessage}
                </div>
            </div>
            <LetterImage className={styles["activation-info__letter-image"]} />
        </div>
    );
};

export default ActivationInfo;
