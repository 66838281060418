import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as NoMessagesIcon } from "assets/icons/noMessages.svg";
import Pagination from "features/common/components/Pagination";
import Spinner from "features/common/components/Spinner";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import usePrevious from "features/common/hooks/usePrevious";
import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import { listContainsGaps } from "features/common/utils";
import SingleMessage from "features/messages/components/SingleMessage";
import { emptyGetConversationsFetchParams } from "features/messages/constants";
import { Conversation } from "features/messages/models";
import { getOldestFirstMessage, getRecentFirstMessage } from "features/messages/translationMessages";
import { GetConversationsFetchParams } from "features/messages/types";
import { getMessageFiltersDropdownOptions } from "features/messages/utils";
import { User } from "features/user/models";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FilterSelector from "./FilterSelector";
import styles from "./styles.module.scss";

export interface Props {
    conversationsLoading: boolean;
    conversations?: Conversation[];
    conversationsLoadingError?: ApiError;
    totalConversations?: number;
    user?: User;
    getConversationsAsync: (fetchParams: GetConversationsFetchParams, cancelToken?: CancelToken) => void;
    amendConversationsAsync: (fetchParams: GetConversationsFetchParams, cancelToken?: CancelToken) => void;
    resetMessages: () => void;
}


const MyMessages = ({
    user,
    conversationsLoading,
    conversations,
    conversationsLoadingError,
    totalConversations,
    getConversationsAsync,
    resetMessages,
}: Props) => {
    useScrollToTopOnMount();
    const intl = useIntl();
    const deviceClass = useDeviceClass();
    const createCancelToken = useCreateCancelToken();

    const recentFirstMessage = getRecentFirstMessage(intl);
    const oldestFirstMessage = getOldestFirstMessage(intl);

    const messageFilterOptions = getMessageFiltersDropdownOptions(
        recentFirstMessage,
        oldestFirstMessage,
    );

    const [currentPage, setCurrentPage] = useState(1);
    const [wasConversationsFetchTriggered, setWasConversationsFetchTriggered] = useState(conversations !== undefined);
    const [fetchParams, setFetchParams] = useState<GetConversationsFetchParams>({
        ...emptyGetConversationsFetchParams,
    });
    const [selectedFilter, setSelectedFilter] = useState(messageFilterOptions[0]);

    const prevDeviceClass = usePrevious(deviceClass);

    const onSetCurrentPage = useCallback(
        (currentPage: number) => {
            setCurrentPage(currentPage);
            deviceClass === "desktop" && window.scrollTo(0, 0);
        },
        [deviceClass]
    );

    useEffect(() => {
        if (!wasConversationsFetchTriggered && !conversationsLoadingError) {
            setWasConversationsFetchTriggered(true);
            getConversationsAsync(fetchParams, createCancelToken());
        }
    }, [
        conversationsLoadingError,
        createCancelToken,
        fetchParams,
        getConversationsAsync,
        wasConversationsFetchTriggered,
    ]);

    useEffect(() => {
        getConversationsAsync(fetchParams, createCancelToken());
    }, [createCancelToken, fetchParams, getConversationsAsync]);

    useEffect(() => {
        if (
            prevDeviceClass !== undefined &&
            prevDeviceClass === "desktop" &&
            deviceClass !== "desktop" &&
            listContainsGaps(conversations)
        ) {
            setCurrentPage(1);
        }
    }, [createCancelToken, deviceClass, fetchParams, getConversationsAsync, conversations, prevDeviceClass]);

    useEffect(() => {
        return () => resetMessages();
    }, [resetMessages]);

    useScrollToTopOnMount();

    const handleFilterSelect = useCallback(
        (filterType: string) => {
            const filterMap = {
                "oldestFirst": "asc",
                "recentFirst": "desc"
            } as { [key: string]: string };

            const currentFilter = messageFilterOptions.find(filter => filter.value === filterType);
            currentFilter && setSelectedFilter(currentFilter);
            setFetchParams({ ...fetchParams, sort: filterMap[filterType] })
        },
        [fetchParams, messageFilterOptions],
    );

    return (
        <div className={styles["my-messages"]}>
            <div className={styles["my-messages__header-container"]}>
                <h2>
                    <FormattedMessage id="messages__my-messages" />
                </h2>
            </div>
            <div className={styles["my-messages__list-container"]}>
                {!conversationsLoading &&
                    conversations !== undefined &&
                    !!totalConversations &&
                    wasConversationsFetchTriggered && (
                        <>
                            <div className={styles["my-messages__list-header"]}>
                                <div>
                                    {/* <span>
                                    <FormattedMessage
                                        id="messages__unread-messages"
                                        values={{ amount: unreadMessagesCount ?? 0 }}
                                    />
                                </span> */}
                                </div>
                                <FilterSelector
                                    messageFilterOptions={messageFilterOptions}
                                    selectedFilter={selectedFilter}
                                    handleFilterSelect={handleFilterSelect}
                                />
                            </div>
                            <div className={styles["my-messages__list"]}>
                                {conversations?.map((conversation) => (
                                    <SingleMessage {
                                        ...conversation} />
                                ))}
                            </div>
                            <div className={styles["my-messages__pagination-container"]}>
                                <Pagination
                                    className={styles["my-messages__pagination"]}
                                    currentPage={currentPage}
                                    onPageChange={onSetCurrentPage}
                                    loadedItemsCount={fetchParams.pageSize * currentPage}
                                    totalItemsCount={totalConversations ?? 0}
                                    pageSize={fetchParams.pageSize}
                                />
                            </div>
                        </>
                    )}
                {!conversationsLoading &&
                    conversations !== undefined &&
                    !totalConversations &&
                    wasConversationsFetchTriggered && (
                        <div className={styles["my-messages__list"]}>
                            <div className={styles["my-messages__no-messages-container"]}>
                                <NoMessagesIcon />
                                <span>
                                    <FormattedMessage id="my-messages__no-messages-header" />
                                </span>
                                <span>
                                    <FormattedMessage id="my-messages__no-messages-text" />
                                </span>
                            </div>
                        </div>
                    )}

                {conversationsLoading && <Spinner className={styles["my-messages__list-spinner"]} />}
            </div>
        </div>
    );
};

export default MyMessages;
