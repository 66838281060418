import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

import {
    selectMyJobOffers,
    selectMyJobOffersLoading,
    selectMyJobOffersLoadingError,
} from "features/jobOffers/selectors";
import { getMyJobOffersAsync, sendDedicationAsync } from "features/jobOffers/thunks";
import OfferSubmissionModal from "./component";

const mapStateToProps = (state: RootState) => ({
    myJobOffers: selectMyJobOffers(state),
    myJobOffersLoading: selectMyJobOffersLoading(state),
    myJobOffersLoadingError: selectMyJobOffersLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getMyJobOffersAsync,
            sendDedicationAsync
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(OfferSubmissionModal);
