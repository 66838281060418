import classNames from "classnames";
import PhotoModal from "features/common/components/PhotoModal/PhotoModal";
import { JobOfferDetails } from "features/jobOffers/models";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PhotoBrowser from "../../../common/components/PhotoBrowser";
import AdditionalInfoPart from "./AdditionalInfoPart";
import AmenitiesPart from "./AmenitiesPart";
import DetailsActionsContainer from "./DetailsActionsContainer";
import EmployerPart from "./EmployerPart";
import GeneralInfoPart from "./GeneralInfoPart";
import { MapWithInfo } from "./MapMarker";
import PaymentsModalPart from "./PaymentModalPart";
import RequirementsPart from "./RequirementsPart";
import styles from "./styles.module.scss";
import { useTranslate } from "../../../../hooks/useTranslate";

export interface Props {
    id: number;
    jobOfferDetails: JobOfferDetails;
    isUserLoggedInWithProfile?: boolean;
    offerDetailsLoading: boolean;
    actionsBox?: React.ReactElement;
    myOfferActionBox?: React.ReactElement;
    hideGeneralInfoPartHeader?: boolean;
    ["data-testid"]?: string;
}

const OfferDetails = ({
    id,
    jobOfferDetails,
    isUserLoggedInWithProfile,
    offerDetailsLoading,
    actionsBox,
    myOfferActionBox,
    hideGeneralInfoPartHeader,
    "data-testid": testId,
}: Props) => {
    const isMatches = useMediaQuery('(max-width: 1439px)');
    const isMatchesEndOfMobile = useMediaQuery('(min-width: 834px)');
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const toggleModal = () => setShowPhotoModal(!showPhotoModal);
    const urlParams = new URLSearchParams(window.location.search);
    const [fenigeId] = useState(urlParams.get('fenige_id'));

    const { translatedText, handleTranslate, translationIcon } = useTranslate(jobOfferDetails.additionalInfo!)
    const additionalInfo = translatedText || jobOfferDetails.additionalInfo

    return (
        jobOfferDetails && (
            <div data-testid={`${testId}__offer-details`}>
                {(isMatchesEndOfMobile && isMatches) && (
                    <div className={styles["offer-details__gallery-display"]}>
                        <div className={styles["offer-details__photo-browser-container"]}>
                            {!showPhotoModal ?
                                <PhotoBrowser offerDetails={jobOfferDetails} photoUrls={jobOfferDetails.photoUrls} onClick={toggleModal} />
                                :
                                <PhotoModal
                                    onClick={toggleModal}
                                    photoUrls={jobOfferDetails.photoUrls}
                                    data-testid="main-section__main-photo-modal"
                                />}
                        </div>
                    </div>
                )}
                <div className={styles["offer-details__general-info-container"]}>
                    <GeneralInfoPart
                        offerDetails={jobOfferDetails}
                        isUserLoggedIn={!!isUserLoggedInWithProfile}
                        hideHeader={hideGeneralInfoPartHeader}
                        isJobOffer
                    />
                </div>
                <div className={styles["offer-details__details"]}>
                    <div className={styles["offer-details__amentities-and-employer-container"]}>
                        {!!jobOfferDetails.amenities?.length && (
                            <div
                                data-testid={`${testId}__amenities`}
                                className={styles["offer-details__amenities-container"]}
                            >
                                <h3 className={styles["offer-details__header"]}>
                                    <FormattedMessage id="offer-details__we-provide" />
                                </h3>
                                <AmenitiesPart amenities={jobOfferDetails.amenities} />
                            </div>
                        )}
                        <div className={styles["offer-details__employer-container"]}>
                            <h2
                                className={classNames(
                                    styles["offer-details__header"],
                                    styles["offer-details__header--employer"]
                                )}
                            >
                                <FormattedMessage id="offer-details__employer" />
                            </h2>
                            <EmployerPart offerDetails={jobOfferDetails} isUserLoggedIn={!!isUserLoggedInWithProfile} />
                        </div>
                    </div>
                    <div className={styles["offer-details__actions"]}>
                        <DetailsActionsContainer
                            detailsLoading={offerDetailsLoading}
                            jobOfferDetails={jobOfferDetails}
                            isUserLoggedInWithProfile={!!isUserLoggedInWithProfile}
                            isMyJobOfferActionContainer
                        >
                            {myOfferActionBox ? myOfferActionBox : actionsBox ? actionsBox : <></>}
                        </DetailsActionsContainer>
                    </div>
                </div>
                {((jobOfferDetails.requirements && jobOfferDetails.requirements?.length > 0) ||
                    (jobOfferDetails.languageRequirements &&
                        jobOfferDetails.languageRequirements?.length > 0)) && (
                        <div
                            data-testid={`${testId}__requirements`}
                            className={styles["offer-details__requirements-container"]}
                        >
                            <h2 className={styles["offer-details__header"]}>
                                <FormattedMessage id="offer-details__requirements" />
                            </h2>
                            <RequirementsPart
                                requirements={jobOfferDetails.requirements}
                                requirementLanguages={jobOfferDetails.languageRequirements}
                            />
                        </div>
                    )}
                <div className={classNames(styles["offer-details__actions-containers-wrapper"], { [styles["offer-details__actions-containers-wrapper-no-gap"]]: !jobOfferDetails.additionalInfo?.length })}>
                    <div className={styles["offer-details__content-offer-conteiner"]}>
                        {jobOfferDetails.additionalInfo && (
                            <div
                                data-testid={`${testId}__additional-info`}
                                className={styles["offer-details__additional-info-container"]}
                            >
                                <h2 className={styles["offer-details__header"]}>
                                    <FormattedMessage id="offer-details__additional-info" />
                                    <div className={styles["general-info-part__translation"]} onClick={handleTranslate}>
                                        { translationIcon }
                                    </div>
                                </h2>
                                <AdditionalInfoPart additionalInfo={additionalInfo as string} />
                            </div>
                        )}
                    </div>
                    <MapWithInfo
                        location={jobOfferDetails.location}
                    />
                </div>
                <p className={styles['offer-details__offer-number-text']}><FormattedMessage id='common__nr-offer' /> {jobOfferDetails.id}</p>
                {fenigeId && (
                    <PaymentsModalPart fenigeId={fenigeId} />
                )}
            </div>
        )
    );
};

export default OfferDetails;


