import React from "react";
import { useCloseWindow } from "../useCloseWindow";
import { useFenigeAuthProducer } from "../useFenigeAuthProducer";
import styles from "./styles.module.scss";
import { FormattedMessage } from "react-intl";

export const Failure = () => {
    useFenigeAuthProducer({ authorized: false });
    const { counter } = useCloseWindow();

    return (
        <div className={styles.result__container}>
            <span className={styles.result__failure}>
                <FormattedMessage id="checkout-redirect__failure" />
            </span>
            <br />
            <span className={styles.result__description}>
                <FormattedMessage id="checkout-redirect__info" values={{ counter }} />
            </span>
        </div>
    );
};
