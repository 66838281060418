import { useEffect, useState } from "react";

const useLoaded = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    useEffect(() => {
        if (document.readyState === "complete") {
            setLoaded(true)
        } else {
            window.addEventListener('load', () => setLoaded(true));
            return () => window.addEventListener('load', () => setLoaded(true));
        }

    }, []);
    return { loaded };
};

export default useLoaded;
