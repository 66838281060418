import { selectEndingOffer, selectEndingOfferError, selectEndOfferSuccess } from "features/jobOffers/selectors";
import { endOfferAsync } from "features/jobOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import EndOfferModal from "./component";

// TODO: IWGM-723 Extract this file into "jobOffers" and create a new one for "employeeOffers" when implementing ending employee offer.
const mapStateToProps = (state: RootState) => ({
    endingOffer: selectEndingOffer(state),
    endOfferSuccess: selectEndOfferSuccess(state),
    endingOfferError: selectEndingOfferError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ endOfferAsync }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EndOfferModal);
