import { selectIsTokenCancelled, selectIsUserLoggedIn } from "features/user/selectors";
import { deactivateTokenAsync } from "features/user/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import SignOut from "./component";

const mapStateToProps = (state: RootState) => ({
    isUserLoggedIn: selectIsUserLoggedIn(state),
    isTokenCancelled: selectIsTokenCancelled(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deactivateTokenAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
