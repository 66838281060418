import { City, Currency, Region } from "features/common/models";
import { DictionaryItem, Image, SectionName } from "features/common/types";
import { LanguageLevelType, LanguageType, Requirement } from "features/jobOffers/types";
import { CardOwnerRole } from "features/myProfile/types";

export type DictionaryItemDto = DictionaryItem;

export interface TermSuggestionDto {
    items: SearchSectionDto[];
}

export interface SearchSectionDto {
    name: SectionName;
    suggestions: SuggestionDto[];
}

export interface BranchesDto {
    data: DictionaryItemDto[];
}

export interface WorkTypesDto {
    workTypes: DictionaryItemDto[];
}

export interface SuggestionDto {
    name?: string;
    code?: string;
}

export interface LocationSuggestionDto {
    cities?: City[];
    regions?: Region[];
    countries?: string[];
}

export interface CurrenciesDto {
    data: Array<Currency>;
}

export type CardDto = {
    id: number;
    branchId?: number;
    business_name?: string;
    firstname?: string;
    lastname?: string;
    phone_number?: string;
    email?: string;
    images: string[];
    description?: string;
    description_text?: string;
    kraz_certificate_number?: string;
    tax_identification_number?: string;
    languageRequirements?: {
        level: LanguageLevelType;
        name: LanguageType;
    }[];
    requirements?: Requirement[];
    locationDTO: {
        id?: number;
        city?: string;
        country?: string;
        location?: {
            latitude?: number;
            longitude?: number;
        };
        northeast?: {
            latitude?: number;
            longitude?: number;
        };
        southwest?: {
            latitude?: number;
            longitude?: number;
        };
        number?: string;
        regionLevel1?: string;
        regionLevel2?: string;
        regionLevel3?: string;
        street?: string;
        placeId?: string;
    };
    placeId?: string;
    street_name?: string;
    street_number?: string;
    logo?: string;
    main_photo?: string;
    personal: boolean;
    isPersonal: boolean;
    type: CardOwnerRole;
    jobExperience: {
        id: string;
        companyName: string;
        dateFrom: Date;
        dateTo: Date;
        description: string;
        position: string;
        isActive?: boolean;
    }[];
    isFavoriteEmployee: boolean;
    isFavoriteEmployer: boolean;
    phone_number_prefix?: string;
};

export interface CardsDto {
    private: CardDto[];
    companies: CardDto[];
}

export interface LocationsDetailsDto {
    locationsDetails: LocationDetailDto[];
}

export enum LocationTypeDto {
    Undefined,
    City,
    Region,
}

export interface LocationDetailDto {
    id: string;
    name: string;
    locationType: LocationTypeDto;
}

export interface CountriesDto {
    name: string;
    code: string;
}
