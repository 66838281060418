import { FenigeApi } from "features/jobOffers/api/FenigeApi";
import { IndicatorRequestData } from "./indicatorRequest";

export interface IndicatorResultData {
    authenticationId: string;
}

export const indicatorResult = async (data: IndicatorRequestData): Promise<IndicatorResultData> => {
    if (data.durationInMillis) {
        await FenigeApi.IndicatorResult({
            durationInMillis: data.durationInMillis,
            authenticationId: data.authenticationId,
        });
    }

    return Promise.resolve({ authenticationId: data.authenticationId });
};
