import usePortal from "features/common/hooks/usePortal";
import React from "react";

export interface Props {
    children: React.ReactElement | React.ReactElement[];
    parentId: string;
    ["data-testid"]?: string;
}

const Portal = ({ children, parentId, "data-testid": testId }: Props) =>
    usePortal(parentId, <div data-testid={`${testId}`}>{children}</div>);

export default Portal;
