import classNames from "classnames";
import SignInMessage from "features/common/components/SignInMessage";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useElementPositionY from "features/common/hooks/useElementPositionY";
import useViewportSize from "features/common/hooks/useViewportSize";
import useWindowScrollPosition from "features/common/hooks/useWindowScrollPosition";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { getLoginCustomMessage } from "features/employeeOffers/translationMessages";
import { JobOfferDetails } from "features/jobOffers/models";
import { footerId } from "features/layout/components/Footer/component";
import { OfferStatus } from "features/offers/types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    isEmployeeOffer?: boolean;
    isMyJobOfferActionContainer?: boolean;
    jobOfferDetails: JobOfferDetails | EmployeeOfferDetails;
    detailsLoading: boolean;
    isUserLoggedInWithProfile: boolean;
    children: React.ReactElement;
}

const DetailsActionsContainer = ({
    isEmployeeOffer,
    isMyJobOfferActionContainer,
    jobOfferDetails,
    detailsLoading,
    isUserLoggedInWithProfile,
    children,
}: Props) => {
    const intl = useIntl();
    const deviceClass = "desktop"; //useDeviceClass();
    const footerPosition = useElementPositionY(footerId, [deviceClass, detailsLoading]);
    const scrollPosition = useWindowScrollPosition();
    const viewportSize = useViewportSize();
    // @ts-ignore
    const footerPositionOffset = deviceClass === "smartphone" ? 42 : 50;
    const stickExpandBannerToParent = scrollPosition >= footerPosition - viewportSize.height - footerPositionOffset;

    const loginCustomMessage = getLoginCustomMessage(intl);

    return (
        <div
            className={classNames(styles["details-actions-container__box"], {
                [styles["details-actions-container__box-mobile"]]: deviceClass !== "desktop",
                [styles["details-actions-container__box-mobile--static"]]:
                    deviceClass !== "desktop" && stickExpandBannerToParent,
            })}
        >
            <>
                {isMyJobOfferActionContainer && (
                    <span className={styles["details-actions-container__my-offer-message"]}>
                        <FormattedMessage id="offer-details__my-offer-message" />
                    </span>
                )}
                {jobOfferDetails?.status === OfferStatus.Ended && !isMyJobOfferActionContainer && (
                    <span className={styles["details-actions-container__offer-ended-message"]}>
                        <FormattedMessage id="offer-details__offer-ended-message" />
                    </span>
                )}
                {deviceClass === "desktop" &&
                    jobOfferDetails?.status !== OfferStatus.Ended &&
                    !isMyJobOfferActionContainer && (
                        <>
                            {isUserLoggedInWithProfile ? (
                                <div className={styles["details-actions-container__business-name"]}>
                                    {/* {jobOfferDetails?.businessName || jobOfferDetails?.employeeName} */}
                                </div>
                            ) : (
                                <SignInMessage
                                    className={styles["details-actions-container__login-container"]}
                                    customMessage={!!isEmployeeOffer ? loginCustomMessage : undefined}
                                />
                            )}
                            <div className={styles["details-actions-container__position"]}>
                                {jobOfferDetails?.position}
                            </div>
                        </>
                    )}
                {children}
                {deviceClass !== "desktop" && !stickExpandBannerToParent && (
                    <div className={styles["details-actions-container__placeholder"]} />
                )}
            </>
        </div>
    );
};
export default DetailsActionsContainer;
