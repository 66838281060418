import classNames from "classnames";
import { headerHeight } from "features/common/constants";
import usePrevious from "features/common/hooks/usePrevious";
import useWindowScrollPosition from "features/common/hooks/useWindowScrollPosition";
import { numberToPixels } from "features/common/utils";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

interface Props {
    pinStart?: number;
    className?: string;
    visibleClassName?: string;
    ["data-testid"]?: string;
    children: (isVisible: boolean) => React.ReactElement;
    onPositionChange?: (bottomPosition: number) => void;
}

const Headroom = ({
    pinStart = 0,
    className,
    "data-testid": testId = "headroom",
    children,
    onPositionChange,
}: Props) => {
    const scrollPosition = useWindowScrollPosition();
    const prevScrollPosition = usePrevious(scrollPosition, 0);
    const scrollPositionDiff = prevScrollPosition! - scrollPosition;

    const headroomRef = useRef<HTMLDivElement | null>(null);

    const [headroomTop, setHeadroomTop] = useState(0);
    const [isVisible, setVisible] = useState(false);
    const [wasPositionInitiallySet, setPositionInitiallySet] = useState(false);

    useEffect(() => {
        if (pinStart >= scrollPosition) {
            setHeadroomTop(0);
        } else if (scrollPositionDiff > 0 && headroomRef.current) {
            setHeadroomTop((prev) =>
                Math.min(prev + scrollPositionDiff, headroomRef.current!.getBoundingClientRect().height)
            );
        } else if (scrollPositionDiff < 0) {
            setHeadroomTop((prev) => Math.max(0, prev + scrollPositionDiff));
        }
    }, [pinStart, scrollPosition, scrollPositionDiff]);

    useEffect(() => {
        if (headroomTop === 0) {
            setVisible(false);
        } else {
            setVisible(true);
        }

        if (headroomRef.current) {
            headroomRef.current.style.top = numberToPixels(
                headerHeight - headroomRef.current.getBoundingClientRect().height + headroomTop
            );
        }
        setPositionInitiallySet(true);
    }, [headroomTop]);

    useEffect(() => {
        if (headroomRef.current && onPositionChange && wasPositionInitiallySet) {
            onPositionChange(headroomTop + headerHeight);
        }
    }, [onPositionChange, headroomTop, wasPositionInitiallySet]);

    return (
        <div
            data-testid={testId}
            ref={headroomRef}
            className={classNames(
                styles["headroom"],
                { [styles["headroom--hidden"]]: !wasPositionInitiallySet },
                className,
                { visibleClassName: isVisible }
            )}
        >
            {children(isVisible)}
        </div>
    );
};

export default Headroom;
