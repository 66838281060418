import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.myProfile;

export const selectUserDetailsUpdating = createSelector(selectState, (state) => state.userDetailsUpdating);
export const selectUserDetailsUpdated = createSelector(selectState, (state) => state.userDetailsUpdated);
export const selectUserDetailsUpdatingError = createSelector(selectState, (state) => state.userDetailsUpdatingError);

export const selectProfilePictureUploading = createSelector(selectState, (state) => state.userProfilePictureUploading);
export const selectProfilePictureUploaded = createSelector(selectState, (state) => state.userProfilePictureUploaded);
export const selectProfilePictureUploadingError = createSelector(
    selectState,
    (state) => state.userProfilePictureUploadingError
);

export const selectCreateNewCardLoading = createSelector(selectState, (state) => state.createNewCardLoading);
export const selectCreateNewCardSuccess = createSelector(selectState, (state) => state.createNewCardSuccess);
export const selectCreateNewCardError = createSelector(selectState, (state) => state.createNewCardError);

export const selectOfferCreationCards = createSelector(selectState, (state) => state.offerCreationCards);
export const selectOfferCreationCardsLoading = createSelector(selectState, (state) => state.offerCreationCardsLoading);
export const selectOfferCreationCardLoadingError = createSelector(
    selectState,
    (state) => state.offerCreationCardsLoadingError
);

export const selectMyProfileCards = createSelector(selectState, (state) => state.myProfileCards);
export const selectMyProfileCardsLoading = createSelector(selectState, (state) => state.myProfileCardsLoading);
export const selectMyProfileCardLoadingError = createSelector(selectState, (state) => state.myProfileCardsLoadingError);

export const selectCardDetails = createSelector(selectState, (state) => state.cardDetails);
export const selectCardDetailsLoading = createSelector(selectState, (state) => state.cardDetailsLoading);
export const selectCardDetailsLoadingError = createSelector(selectState, (state) => state.cardDetailsLoadingError);
/* @CHECK */
export const selectDeleteCardError = createSelector(selectState, (state) => state.deleteCardError)
export const selectDeleteMyAccountError = createSelector(selectState, (state) => state.deleteMyAccountError);
export const selectMyProfileCardDetailsUpdating = createSelector(
    selectState,
    (state) => state.myProfileCardDetailsUpdating
);
export const selectMyProfileCardDetailsUpdated = createSelector(
    selectState,
    (state) => state.myProfileCardDetailsUpdated
);
export const selectMyProfileCardDetailsUpdatingError = createSelector(
    selectState,
    (state) => state.myProfileCardDetailsUpdatingError
);

export const selectRelatedOffers = createSelector(selectState, (state) => state.relatedOffers);
export const selectRelatedOffersLoading = createSelector(selectState, (state) => state.relatedOffersLoading);
export const selectRelatedOffersLoadingError = createSelector(selectState, (state) => state.relatedOffersLoadingError);

export const selectCountRelatedOffers = createSelector(selectState, (state) => state.countRelatedOffers);

export const selectMyApplications = createSelector(selectState, (state) => state.myApplications);
export const selectMyApplicationsLoading = createSelector(selectState, (state) => state.myApplicationsLoading);
export const selectMyApplicationsError = createSelector(selectState, (state) => state.myApplicationsError);

export const selectPaymentCardData = createSelector(selectState, (state) => state.paymentCardData);
export const selectPaymentCardDataLoading = createSelector(selectState, (state) => state.paymentCardDataLoading);
export const selectPaymentCardDataError = createSelector(selectState, (state) => state.paymentCardDataError);
