import { selectIsUserLoggedIn } from "features/user/selectors";
import { resendActivationEmailAsync } from "features/user/thunks";
import ActivationInfo from "./component";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            resendActivationEmailAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ActivationInfo);
