import { ReactComponent as HangUp } from "assets/getCall/hangUp.svg";
import { ReactComponent as Logo } from "assets/getCall/logo.svg";
import Micro from "assets/getCall/Micro";
import Video from "assets/getCall/Video";
import { WithGetCallHOCComponentProps } from "features/getCall/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const GetCallContainer = styled.div`
    position: fixed;
    background-color: black;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
`;

const StyledUserVideoContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const StyledUserVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledMyVideoContainer = styled.div`
    position: absolute;
    right: 1rem;
    bottom: 7rem;
    width: 25%;
    min-width: 100px;
    max-width: 400px;
    z-index: 101;
    @media(min-width: 600px) {
       bottom: 1.9rem;
       right: 2rem;
    }
`;

const StyledMyVideo = styled.video`
    border-radius: 1.3rem;
`;

const StyledLogo = styled.div`
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 101;
`

const ControllersContainer = styled.div`
    position: absolute;
    bottom: 2rem;
    display: flex;
    left: 50%;
    transform: translate(-50%);
    align-items: center;
    background-color: white;
    border-radius: 1rem;
    padding: 0.8rem 1rem;
    svg {
        cursor: pointer;
        margin: 0 .5rem;
    }

    @media(min-width: 900px) {
    padding: 1rem 2rem;
        svg { 
            margin: 0 1rem;
        }
    }
`;

const StyledSvgWrapper = styled.div`
    svg {
        height: 2rem;
        width: 2rem;
        @media(min-width: 900px) {
            height: 2.5rem;
            width: 2.5rem;
 }
    }

    .redline { 
    transition: all 0.2s ease-in;
} 
`
const HungUpWrapper = styled.div`
svg{
    width: 2rem;
    height: 2rem;
    @media(min-width: 900px) {
            height: 3rem;
            width: 3rem;
 
}
    }
`

const VideoPlayer: React.FC<WithGetCallHOCComponentProps> = ({ callAccepted, stream, leaveCall, toggleStrangerVideo, toggleStrangerMicro }) => {
    const [audioEnabled, setAudioEnabled] = useState<boolean>(true);
    const [videoEnabled, setVideoEnabled] = useState<boolean>(true);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, []);

    useEffect(() => {
        if (stream) {
            const myVideo = document.getElementById('myVideo') as HTMLVideoElement;
            // example how to mute/unmute. enable/disable video
            // stream.getAudioTracks()[0].enabled = audioEnabled;
            stream.getVideoTracks()[0].enabled = videoEnabled;
            if (myVideo) {
                myVideo.srcObject = stream;
            }
        }
    }, [stream, audioEnabled, videoEnabled])


    return (
        <GetCallContainer>
            <StyledLogo>
                <Logo />
            </StyledLogo>

            {stream && (
                <StyledMyVideoContainer>
                    <StyledMyVideo id='myVideo' playsInline muted autoPlay />
                </StyledMyVideoContainer>
            )}

            {callAccepted && (
                <StyledUserVideoContainer>
                    {/* its test for mic */}
                    <StyledUserVideo id='userVideo' playsInline autoPlay />
                </StyledUserVideoContainer>
            )}

            <ControllersContainer>
                <StyledSvgWrapper onClick={() => { setAudioEnabled((prevState: boolean) => !prevState); toggleStrangerMicro() }} >
                    <Micro audioTrigger={audioEnabled} />
                </StyledSvgWrapper>
                <StyledSvgWrapper onClick={() => { setVideoEnabled((prevState: boolean) => !prevState); toggleStrangerVideo() }}>
                    <Video videoTrigger={videoEnabled} />
                </StyledSvgWrapper>
                <HungUpWrapper>
                    <HangUp onClick={leaveCall} />
                </HungUpWrapper>
            </ControllersContainer>
        </GetCallContainer>
    )
}

export default VideoPlayer;