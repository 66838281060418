import {ApiError} from "api/utils";
import {StatisticsDto, UserOffersDto, UsersDto} from "features/adminPanel/dtos";
import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

export const getStatistics = createAsyncAction(
    "GET_STATISTICS_REQUEST",
    "GET_STATISTICS_SUCCESS",
    "GET_STATISTICS_FAILURE"
)<void, StatisticsDto, ApiError>();

export const getUsers = createAsyncAction("GET_USERS_REQUEST", "GET_USERS_SUCCESS", "GET_USERS_FAILURE")<
    void,
    UsersDto,
    ApiError
>();

export const amendUsers = createAsyncAction("AMEND_USERS_REQUEST", "AMEND_USERS_SUCCESS", "AMEND_USERS_FAILURE")<
    void,
    UsersDto,
    ApiError
>();

export const blockUser = createAsyncAction("BLOCK_USER_REQUEST", "BLOCK_USER_SUCCESS", "BLOCK_USER_FAILURE")<
    void,
    void,
    ApiError
>();

export const unblockUser = createAsyncAction("UNBLOCK_USER_REQUEST", "UNBLOCK_USER_SUCCESS", "UNBLOCK_USER_FAILURE")<
    void,
    void,
    ApiError
    >();

export const deleteUser = createAsyncAction("DELETE_USER_REQUEST", "DELETE_USER_SUCCESS", "DELETE_USER_FAILURE")<
    void,
    void,
    ApiError
>();

export const downloadUsersList = createAsyncAction(
    "DOWNLOAD_USERS_LIST_REQUEST",
    "DOWNLOAD_USERS_LIST_SUCCESS",
    "DOWNLOAD_USERS_LIST_FAILURE"
)<void, void, ApiError>();

export const getUserOffers = createAsyncAction(
    "GET_USER_OFFERS_REQUEST",
    "GET_USER_OFFERS_SUCCESS",
    "GET_USER_OFFERS_FAILURE"
)<void, UserOffersDto, ApiError>();

export const deleteUserOffer = createAsyncAction(
    "DELETE_USER_OFFER_REQUEST",
    "DELETE_USER_OFFER_SUCCESS",
    "DELETE_USER_OFFER_FAILURE"
)<void, void, ApiError>();

export const resetUsers = createAction("RESET_USERS")<void>();

type AdminPanelAction =
    | ActionType<typeof getStatistics>
    | ActionType<typeof getUsers>
    | ActionType<typeof getUserOffers>
    | ActionType<typeof amendUsers>
    | ActionType<typeof blockUser>
    | ActionType<typeof unblockUser>
    | ActionType<typeof deleteUser>
    | ActionType<typeof downloadUsersList>
    | ActionType<typeof deleteUserOffer>
    | ActionType<typeof resetUsers>;

export default AdminPanelAction;
