import classNames from "classnames";
import Spinner from "features/common/components/Spinner";
import React, { SyntheticEvent } from "react";
import styles from "./styles.module.scss";

export type ButtonVariant = "primary" | "filter-mobile" | "secondary" | "tertiary" | "no-background" | "dark" | "mobile" | "login" | "landing" | "search" | 'no-background-green' | 'no-background-red';
type ButtonTagName = "a" | "div" | "input";
type ButtonType = "submit" | "button";

export interface Props {
    children: string | React.ReactNode;
    className?: string;
    variant?: ButtonVariant;
    as?: ButtonTagName;
    href?: string;
    type?: ButtonType;
    disabled?: boolean;
    isLoading?: boolean;
    ["data-testid"]?: string;
    onClick?: (event: SyntheticEvent<HTMLElement>) => void;
}

const Button = ({
    children,
    className,
    variant,
    as,
    href,
    type,
    disabled,
    isLoading,
    "data-testid": testId,
    onClick,
}: Props) =>
    !as ? (
        <button
            type={type}
            data-testid={testId}
            disabled={disabled}
            className={classNames(
                styles["button"],
                className,
                {
                    [styles["button--filter-mobile"]]: variant === "filter-mobile",
                },
                {
                    [styles["button--secondary"]]: variant === "secondary",
                },
                {
                    [styles["button--tertiary"]]: variant === "tertiary",
                },
                {
                    [styles["button--mobile"]]: variant === "mobile",
                },

                {
                    [styles["button--no-background"]]: variant === "no-background",
                },
                {
                    [styles["button--no-background-green"]]: variant === 'no-background-green',
                },
                {
                    [styles["button--no-background-red"]]: variant === 'no-background-red',
                },
                {
                    [styles["button--dark"]]: variant === "dark",
                },

                {
                    [styles["button--login"]]: variant === "login",
                },

                {
                    [styles["button--landing"]]: variant === "landing",
                },
                {
                    [styles["button--search"]]: variant === "search",
                }
            )}
            onClick={(event) => onClick && onClick(event)}
        >
            {children}
            {isLoading && (
                <Spinner
                    data-testid={`${testId}__spinner`}
                    className={classNames(styles["button__spinner"], {
                        [styles["button__spinner--orange"]]: variant === "no-background",
                    })}
                    thickness="thin"
                />
            )}
        </button>
    ) : (
        <>
            {as === ("a" as ButtonTagName) ? (
                <a
                    data-testid={testId}
                    className={classNames(
                        styles["button"],
                        className,
                        {
                            [styles["button--filter-mobile"]]: variant === "filter-mobile",
                        },
                        {
                            [styles["button--secondary"]]: variant === "secondary",
                        },
                        {
                            [styles["button--no-background"]]: variant === "no-background",
                        },
                        {
                            [styles["button--dark"]]: variant === "dark",
                        }
                    )}
                    onClick={(event) => onClick && onClick(event)}
                    href={href}
                >
                    {children}
                </a>
            ) : (
                <div
                    data-testid={testId}
                    className={classNames(
                        styles["button"],
                        className,
                        {
                            [styles["button--secondary"]]: variant === "secondary",
                        },
                        {
                            [styles["button--no-background"]]: variant === "no-background",
                        },
                        {
                            [styles["button--dark"]]: variant === "dark",
                        }
                    )}
                    onClick={(event) => onClick && onClick(event)}
                >
                    {children}
                </div>
            )}
        </>
    );

export default Button;
