import { JobOffersFetchParams } from "features/jobOffers/types";
import { OffersSearchParams } from "features/offers/types";
import { countOffersFiltersApplied } from "features/offers/utils";

export const countJobOffersFiltersApplied = (fetchParams: JobOffersFetchParams) => {
    let filtersCount = countOffersFiltersApplied(fetchParams);

    filtersCount += fetchParams.filters.employerType.length;

    if (fetchParams.filters.minimalBonusCurrencyCode && fetchParams.filters.minimalBonusValue) {
        filtersCount += 1;
    }

    if (fetchParams.filters.minimalSalaryCurrencyCode && fetchParams.filters.minimalSalaryValue) {
        filtersCount += 1;
    }

    return filtersCount;
};

export const deepCopyJobOffersSearchParams = (
    searchParams?: OffersSearchParams,
    createTermObject?: boolean
): OffersSearchParams => {
    if (searchParams) {
        return {
            ...searchParams,
            term: searchParams?.term ? { ...searchParams?.term } : undefined,
            location: [...searchParams.location],
        };
    } else {
        return {
            term: createTermObject ? { rawInputText: "", section: "", selectedSuggestionValue: "" } : undefined,
            location: [],
            distance: 0,
        };
    }
};

export const getApplicationSubmissionStepNameByIndex = (index: number) => {
    switch (index) {
        case 0:
            return "dataStep";
        case 1:
            return "qualificationDescriptionStep";
        default:
            return "dataStep";
    }
};
