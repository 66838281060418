import ModalWithContent from "features/common/components/ModalWithContent";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import { getOkMessage } from "features/common/translationMessages";
import { DropdownOption } from "features/common/types";
import {
    useContactFormValidation,
    useRoleRadioButtonOptions,
    useTopicDropdownOptions,
} from "features/contactForm/hooks";
import { mapDataToSupportRequest } from "features/contactForm/mappers";
import { SupportRequestData } from "features/contactForm/models";
import { ContactFormSchema } from "features/contactForm/schemas";
import {
    getSendConfirmationBodyMessage,
    getSendConfirmationHeaderMessage,
} from "features/contactForm/translationMessages";
import { RoleType, TopicType } from "features/contactForm/types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Form from "./Form";
import styles from "./styles.module.scss";

export interface Props {
    contactFormMessageSending?: boolean;
    contactFormMessageSendingSuccess?: boolean;
    sendContactFormMessageAsync: (formData: SupportRequestData) => void;
    closeContactFormConfirmationDialog: () => void;
    ["data-testid"]?: string;
}

const ContactForm = ({
    contactFormMessageSending,
    contactFormMessageSendingSuccess,
    sendContactFormMessageAsync,
    closeContactFormConfirmationDialog,
    "data-testid": testId,
}: Props) => {
    const intl = useIntl();

    const [role, setRole] = useState<RoleType>(RoleType.Employee);
    const [topic, setTopic] = useState<DropdownOption<TopicType>>();
    const [message, setMessage] = useState("");
    const [topicTouched, setTopicTouched] = useState(false);
    const [messageTouched, setMessageTouched] = useState(false);

    const sendConfirmationHeader = getSendConfirmationHeaderMessage(intl);
    const sendConfirmationBody = getSendConfirmationBodyMessage(intl);
    const okMessage = getOkMessage(intl);

    const roleRadioButtonOptions = useRoleRadioButtonOptions();
    const topicDropdownOptions = useTopicDropdownOptions(role);

    const [isFormValid, errors] = useContactFormValidation(role, message, topic);

    const resetContactForm = () => {
        setRole(RoleType.Employee);
        setTopic(undefined);
        setMessage("");
        setTopicTouched(false);
        setMessageTouched(false);
    };

    const onRoleChange = (value: RoleType) => {
        const parsedValue = parseInt(value.toString());
        setRole(parsedValue);

        if (parsedValue === RoleType.Employer && topic?.value === TopicType.Bonus) {
            setTopic(undefined);
        }
    };

    const onTopicSelect = (value: number) => {
        const selectedOption = topicDropdownOptions.find((item) => item.value === value);
        setTopic(selectedOption);
    };

    const onTopicToggle = (expanded: boolean) => {
        if (!expanded) {
            setTopicTouched(true);
        }
    };

    const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value.trimLeft());
    };

    const onMessageBlur = () => {
        setMessageTouched(true);
    };

    const onContactFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        sendContactFormMessageAsync(mapDataToSupportRequest(role, topic?.value as TopicType, message));
    };

    const onConfirmationDialogClose = () => {
        closeContactFormConfirmationDialog();
        resetContactForm();
    };

    useEffect(() => {
        return () => {
            closeContactFormConfirmationDialog();
        };
    }, [closeContactFormConfirmationDialog]);

    return (
        <>
            <div className={styles["contact-form"]}>
                <Form
                    data-testid={testId}
                    role={role}
                    topic={topic}
                    message={message}
                    topicTouched={topicTouched}
                    messageTouched={messageTouched}
                    radioButtonOptions={roleRadioButtonOptions}
                    topicDropdownOptions={topicDropdownOptions}
                    isFormValid={isFormValid as boolean}
                    errors={errors as ContactFormSchema}
                    contactFormMessageSending={contactFormMessageSending}
                    onRoleChange={onRoleChange}
                    onTopicSelect={onTopicSelect}
                    onTopicToggle={onTopicToggle}
                    onMessageChange={onMessageChange}
                    onMessageBlur={onMessageBlur}
                    onContactFormSubmit={onContactFormSubmit}
                />
            </div>
            {contactFormMessageSendingSuccess && (
                <ModalWithContent
                    data-testid={`${testId}`}
                    className={styles["contact-form__modal"]}
                    header={<SuccessHeader>{sendConfirmationHeader}</SuccessHeader>}
                    content={sendConfirmationBody}
                    primaryButtonContent={okMessage}
                    cancelButtonHidden
                    onClose={onConfirmationDialogClose}
                    primaryAction={onConfirmationDialogClose}
                />
            )}
        </>
    );
};

export default ContactForm;
