import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ApiError, CancelToken } from "api/utils";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getHomeMessage, getMyEmployeeOffersMessage } from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import BadgeStatus from "features/offers/components/BadgeStatus";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { appRoutes } from "features/routing/routes";

import MyEmployeeOfferDetailsPreview from "./MyEmployeeOfferDetailsPreview";
import styles from "./styles.module.scss";

interface Props {
    id: number;
    employeeOfferDetails?: EmployeeOfferDetails;
    employeeOfferDetailsLoading?: boolean;
    employeeOfferDetailsLoadingError?: ApiError;
    resetEmployeeOfferDetails: () => void;
    getEmployeeOfferDetailsAsync: (id: number, cancelToken?: CancelToken) => void;
}

const MyEmployeeOfferDetails = ({
    id,
    employeeOfferDetails,
    employeeOfferDetailsLoading,
    employeeOfferDetailsLoadingError,
    resetEmployeeOfferDetails,
    getEmployeeOfferDetailsAsync,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();

    const homeMessage = getHomeMessage(intl);
    const myJobOffersMessage = getMyEmployeeOffersMessage(intl);

    const crumbs: BreadcrumbsItem[] = [
        {
            displayName: homeMessage,
            path: appRoutes.dashboard,
        },
        {
            displayName: myJobOffersMessage,
            path: appRoutes.myJobOffers,
        },
        {
            displayName: employeeOfferDetails?.position ?? "",
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);

        return () => resetEmployeeOfferDetails();
    }, [resetEmployeeOfferDetails]);

    useEffect(() => {
        getEmployeeOfferDetailsAsync && getEmployeeOfferDetailsAsync(id, createCancelToken());
    }, [createCancelToken, getEmployeeOfferDetailsAsync, id, intl.locale]);

    const resetOfferDetails = () => {
        getEmployeeOfferDetailsAsync && getEmployeeOfferDetailsAsync(id, createCancelToken());
    }

    if (employeeOfferDetailsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "my-employee-offer-details__loading" })} />;
    }

    return (
        <div className={styles["my-employee-offer-details"]}>
            {employeeOfferDetailsLoadingError ? (
                <div className={styles["my-employee-offer-details__error-container"]}>
                    <FormattedMessage id="my-employee-offer-details__fetch-failure" />
                </div>
            ) : (
                <div>
                    <div className={styles["my-employee-offer-details__breadcrumbs-container"]}>
                        <Breadcrumbs data-testid="my-employee-offer-details__breadcrumbs" crumbs={crumbs} />
                    </div>
                    <div className={styles["my-employee-offer-details__header-container"]}>
                        <div className={styles["my-employee-offer-details__inner-header-container"]}>
                            <div className={styles["my-employee-offer-details__inner-header-with-badge-container"]}>
                                <h2>{employeeOfferDetails?.position}</h2>
                                <BadgeStatus
                                    data-testid={`${id}__status`}
                                    status={employeeOfferDetails?.status}
                                    className={styles["my-employee-offer-details__badge-container"]}
                                    offerType={OfferType.Employee}
                                />
                            </div>
                        </div>
                    </div>
                    {!!employeeOfferDetails && (
                        <div className={styles["my-offer-details__details-container"]}>
                            <MyEmployeeOfferDetailsPreview
                                employeeOfferDetails={employeeOfferDetails}
                                id={id}
                                employeeOfferDetailsLoading={employeeOfferDetailsLoading}
                                employeeOfferDetailsLoadingError={employeeOfferDetailsLoadingError}
                                resetOfferDetails={resetOfferDetails}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MyEmployeeOfferDetails;
