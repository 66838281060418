import React from "react";
import { useField } from "formik";

import { Education } from "features/jobOffers/types";
import EducationRequirementComponent from "features/jobOffers/components/CreateJobOffer/RequirementsStep/EducationRequirement";

export interface Props {
    name: string;
    header: string;
}

const EducationRequirement = ({ name, header }: Props) => {
    const [fieldProps, metaProps, helperProps] = useField<Education[]>(name);

    return (
        <EducationRequirementComponent
            data-testid={`requirements-step__${name}`}
            header={header}
            educationsValueInputProps={fieldProps}
            educationsFieldMeta={metaProps}
            educationsHelperProps={helperProps}
        />
    );
};

export default EducationRequirement;
