import Button from "features/common/components/Button";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { appRoutes } from "features/routing/routes";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Props {
    employeeOfferDetails?: EmployeeOfferDetails;
    ["data-testid"]?: string;
}

const SeeSimilarEmployeeOffersButton = ({ employeeOfferDetails, "data-testid": testId }: Props) => {
    const history = useHistory();

    const onSeeSimilarOffersClick = () => {
        if (employeeOfferDetails) {
            history.push(
                `${appRoutes.employeeOffers}?branchId=${employeeOfferDetails.branch.id}&workType=${employeeOfferDetails.workType}&city=${employeeOfferDetails.location.city}`
            );
        }
    };

    return (
        <Button
            variant="secondary"
            onClick={onSeeSimilarOffersClick}
            className={styles["see-similar-offers-button"]}
            data-testid={`${testId}__send-message-button`}
        >
            <FormattedMessage id="employee-offer-details__see-similar-offers" />
        </Button>
    );
};

export default SeeSimilarEmployeeOffersButton;
