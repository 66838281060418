import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

interface Props {
    ["data-testid"]?: string;
    className?: string;
    thickness?: "thin" | "normal";
}

const Spinner = ({ "data-testid": testId, className, thickness = "normal" }: Props) => {
    return (
        <div
            data-testid={testId}
            className={classNames(styles["spinner"], { [styles["spinner--thin"]]: thickness === "thin" }, className)}
        />
    );
};

export default Spinner;
