import useCurrentPath from "features/common/hooks/useCurrentPath";
import { appRoutes } from "features/routing/routes";
import { useHistory } from "react-router-dom";

export const useSignOut = () => {
    const history = useHistory();
    const pathname = useCurrentPath();

    return (redirectUrl?: string) => {
        history.push(`${appRoutes.signOut}?redirectPath=${encodeURIComponent(redirectUrl ?? pathname)}`);
    };
};
