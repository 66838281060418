import { makeApiRequest } from "api/makeApiRequest";
import { CancelToken } from "api/utils";
import CommonAction, {
    getBranches,
    getCityDetails,
    getCountries,
    getCurrencies,
    getLocationsDetails,
    getLocationSuggestions,
    getSearchTermsSuggestions,
} from "features/common/actions";
import { CommonApi } from "features/common/api";
import { OfferType } from "features/common/types";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";

export const getBranchesAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getBranchesAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getBranches.request());

        await makeApiRequest(api.getBranchesAsync, [cancelToken], getBranches.success, getBranches.failure, dispatch);
    };
};

export const getSearchTermsSuggestionsAsync = (
    offerType: OfferType,
    phrase: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getSearchTermsSuggestionsAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getSearchTermsSuggestions.request());

        await makeApiRequest(
            api.getSearchTermsSuggestionsAsync,
            [offerType, phrase, cancelToken],
            getSearchTermsSuggestions.success,
            getSearchTermsSuggestions.failure,
            dispatch
        );
    };
};

export const getLocationSuggestionsAsync = (
    phrase: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getLocationSuggestionsAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getLocationSuggestions.request());

        await makeApiRequest(
            api.getLocationSuggestionsAsync,
            [phrase, cancelToken],
            getLocationSuggestions.success,
            getLocationSuggestions.failure,
            dispatch
        );
    };
};

export const getCityDetailsAsync = (
    locationId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getLocationDetailsAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getCityDetails.request());

        await makeApiRequest(
            api.getLocationDetailsAsync,
            [locationId, cancelToken],
            getCityDetails.success,
            getCityDetails.failure,
            dispatch
        );
    };
};

export const getCurrenciesAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getCurrenciesAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getCurrencies.request());

        await makeApiRequest(
            api.getCurrenciesAsync,
            [cancelToken],
            getCurrencies.success,
            getCurrencies.failure,
            dispatch
        );
    };
};

export const getLocationsDetailsAsync = (
    locationsIds: string[],
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getLocationsDetailsAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getLocationsDetails.request());

        await makeApiRequest(
            api.getLocationsDetailsAsync,
            [locationsIds, cancelToken],
            getLocationsDetails.success,
            getLocationsDetails.failure,
            dispatch
        );
    };
};

export const getCountriesAsync = (
    lang: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<CommonApi, "getCountriesAsync">, CommonAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getCountries.request());

        await makeApiRequest(
            api.getCountriesAsync,
            [lang, cancelToken],
            getCountries.success,
            getCountries.failure,
            dispatch
        );
    };
};
