import Calendar from "features/common/components/Datepicker/Calendar";
import Spinner from "features/common/components/Spinner";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import {
    generateDaysOfMonthArray,
    getMonthAndYearWithSubtractedMonths,
    getNextMonthWithYear,
} from "features/common/utils";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";

const initialCalendarContainerScrollOffset = -84;

interface Props {
    baseDate: Date;
    currentMonth: number;
    currentYear: number;
    monthsToLoadForward: number;
    monthsToLoadBack: number;
    yearsArray: Array<Array<number>>;
    yearSelectorExpanded: boolean;
    currentDateCalendarId: string;
    containerRef: RefObject<HTMLDivElement>;
    date?: Date;
    endDate?: Date;
    selectedDate?: Date;
    withRangePicker?: boolean;
    addForwardYears?: boolean;
    ["data-testid"]: string;
    onLocalDateSelect: (year: number, month: number, day?: number) => void;
    onMonthSelect: (month: number) => void;
    onYearSelect: (year: number) => void;
    onEndMonthSelect?: (month: number) => void;
    onEndYearSelect?: (year: number) => void;
    setYearSelectionExpandState: (state: boolean) => void;
    startFromCurrentDate?: boolean;
    startFromPickedDate?: boolean;
    endOnCurrentDate?: boolean;
}

const MobileCalendars = ({
    baseDate,
    currentMonth,
    currentYear,
    monthsToLoadForward,
    monthsToLoadBack,
    yearsArray,
    yearSelectorExpanded,
    currentDateCalendarId,
    containerRef,
    date,
    endDate,
    selectedDate,
    withRangePicker,
    addForwardYears,
    "data-testid": testId,
    onLocalDateSelect,
    onMonthSelect,
    onYearSelect,
    onEndMonthSelect,
    onEndYearSelect,
    setYearSelectionExpandState,
    startFromCurrentDate,
    startFromPickedDate,
    endOnCurrentDate
}: Props) => {
    const centralDate = date ?? baseDate;

    const centralDaysOfMonth = useMemo(
        () => generateDaysOfMonthArray(currentYear, currentMonth),
        [currentMonth, currentYear]
    );

    const monthsLoadedBack = useRef(0);
    const monthsLoadedForward = useRef(0);

    const [prevMonthsWithYears, setPrevMonthsWithYears] = useState<Array<Array<number>>>([]);
    const [nextMonthsWithYears, setNextMonthsWithYears] = useState<Array<Array<number>>>([]);
    const [prevDaysOfMonth, setPrevDaysOfMonth] = useState<Array<Array<Array<number | undefined>>>>([]);
    const [nextDaysOfMonth, setNextDaysOfMonth] = useState<Array<Array<Array<number | undefined>>>>([]);

    const deviceClass = useDeviceClass();

    const [calendarsInitiallyLoaded, setCalendarsInitiallyLoaded] = useState(false);

    const isCalendarsStateValid = useMemo(
        () =>
            prevDaysOfMonth.length === prevMonthsWithYears.length &&
            nextDaysOfMonth.length === nextMonthsWithYears.length,
        [nextDaysOfMonth.length, nextMonthsWithYears.length, prevDaysOfMonth.length, prevMonthsWithYears.length]
    );

    useEffect(() => {
        if (calendarsInitiallyLoaded && containerRef.current?.scrollTo) {
            const currentDateCalendar = document.getElementById(currentDateCalendarId);
            containerRef.current.scrollTo(
                0,
                (currentDateCalendar?.offsetTop ?? 0) + initialCalendarContainerScrollOffset
            );
        }
    }, [containerRef, currentDateCalendarId, calendarsInitiallyLoaded]);

    useEffect(() => {
        let [month, year] = getMonthAndYearWithSubtractedMonths(
            centralDate.getMonth(),
            centralDate.getFullYear(),
            Math.min(monthsToLoadBack)
        );

        const daysOfMonthArraysToGenerate = monthsToLoadBack - monthsLoadedBack.current;
        const daysOfMonthArrays: Array<Array<Array<number | undefined>>> = [];
        const monthsWithYears: Array<Array<number>> = [];

        if (daysOfMonthArraysToGenerate > 0) {
            for (let i = 0; i < daysOfMonthArraysToGenerate; i++) {
                daysOfMonthArrays.push(generateDaysOfMonthArray(year, month));
                monthsWithYears.push([month, year]);
                [month, year] = getNextMonthWithYear(month, year);
            }

            setPrevDaysOfMonth((prev) => [...daysOfMonthArrays, ...prev]);
            setPrevMonthsWithYears((prev) => [...monthsWithYears, ...prev]);
            setCalendarsInitiallyLoaded(true);
        }

        monthsLoadedBack.current = monthsToLoadBack;
    }, [centralDate, monthsToLoadBack]);

    useEffect(() => {
        let [month, year] = getNextMonthWithYear(centralDate.getMonth(), centralDate.getFullYear());

        const daysOfMonthArrays: Array<Array<Array<number | undefined>>> = [];
        const monthsWithYears: Array<Array<number>> = [];

        for (let i = 0; i < monthsToLoadForward; i++) {
            if (i >= monthsLoadedForward.current) {
                daysOfMonthArrays.push(generateDaysOfMonthArray(year, month));
                monthsWithYears.push([month, year]);
            }

            [month, year] = getNextMonthWithYear(month, year);
        }

        setNextDaysOfMonth((prev) => [...prev, ...daysOfMonthArrays]);
        setNextMonthsWithYears((prev) => [...prev, ...monthsWithYears]);
        setCalendarsInitiallyLoaded(true);

        monthsLoadedForward.current = monthsToLoadForward;
    }, [centralDate, monthsToLoadForward]);

    return (
        <>
            {calendarsInitiallyLoaded ? (
                <>
                    {withRangePicker && (
                        <Spinner className={styles["mobile-calendars__scroll-spinner"]} thickness="thin" />
                    )}
                    {withRangePicker &&
                        isCalendarsStateValid &&
                        prevDaysOfMonth.map((array, index) => (
                            <Calendar
                                data-testid={`${testId}__prev-mobile-calendar`}
                                key={`${prevMonthsWithYears[index][0]}${prevMonthsWithYears[index][1]}`}
                                deviceClass={deviceClass}
                                withRangePicker
                                date={date}
                                startFromCurrentDate={startFromCurrentDate}
                                endOnCurrentDate={endOnCurrentDate}
                                endDate={endDate}
                                baseDate={baseDate}
                                currentMonth={prevMonthsWithYears[index][0]}
                                currentYear={prevMonthsWithYears[index][1]}
                                yearSelectorExpanded={false}
                                daysOfMonthArray={array}
                                addForwardYears={addForwardYears}
                                onLocalDateSelect={(day?: number) =>
                                    onLocalDateSelect(prevMonthsWithYears[index][1], prevMonthsWithYears[index][0], day)
                                }
                            />
                        ))}
                    <Calendar
                        data-testid={`${testId}__curr-mobile-calendar`}
                        deviceClass={deviceClass}
                        withRangePicker={withRangePicker}
                        id={currentDateCalendarId}
                        date={date}
                        endDate={endDate}
                        startFromCurrentDate={startFromCurrentDate}
                        endOnCurrentDate={endOnCurrentDate}
                        startFromPickedDate = {startFromPickedDate}
                        baseDate={baseDate}
                        selectedDate={selectedDate}
                        currentMonth={currentMonth}
                        currentYear={currentYear}
                        yearsArray={yearsArray}
                        yearSelectorExpanded={yearSelectorExpanded}
                        daysOfMonthArray={centralDaysOfMonth}
                        addForwardYears={addForwardYears}
                        onLocalDateSelect={(day?: number) => onLocalDateSelect(currentYear, currentMonth, day)}
                        onMonthSelect={onMonthSelect}
                        onYearSelect={onYearSelect}
                        setYearSelectionExpandState={setYearSelectionExpandState}
                    />
                    {withRangePicker &&
                        isCalendarsStateValid &&
                        nextDaysOfMonth.map((array, index) => (
                            <Calendar
                                data-testid={`${testId}__next-mobile-calendar`}
                                key={`${nextMonthsWithYears[index][0]}${nextMonthsWithYears[index][1]}`}
                                deviceClass={deviceClass}
                                withRangePicker
                                date={date}
                                endDate={endDate}
                                startFromCurrentDate={startFromCurrentDate}
                                endOnCurrentDate={endOnCurrentDate}
                                baseDate={baseDate}
                                currentMonth={nextMonthsWithYears[index][0]}
                                currentYear={nextMonthsWithYears[index][1]}
                                yearSelectorExpanded={false}
                                daysOfMonthArray={array}
                                addForwardYears={addForwardYears}
                                onLocalDateSelect={(day?: number) =>
                                    onLocalDateSelect(nextMonthsWithYears[index][1], nextMonthsWithYears[index][0], day)
                                }
                                onMonthSelect={onEndMonthSelect}
                                onYearSelect={onEndYearSelect}
                            />
                        ))}
                    {withRangePicker && (
                        <Spinner className={styles["mobile-calendars__scroll-spinner"]} thickness="thin" />
                    )}
                </>
            ) : (
                <Spinner className={styles["mobile-calendars__main-spinner"]} />
            )}
        </>
    );
};

export default MobileCalendars;
