import React from "react";
import {FormattedMessage} from "react-intl";

import StatisticsComponent from "features/adminPanel/components/Statistics";
import UsersComponent from "features/adminPanel/components/Users";
import useDeviceClass from "features/common/hooks/useDeviceClass";

import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
}

const AdminPanel = ({ "data-testid": testId = "admin-panel" }: Props) => {
    const isNotDesktop = useDeviceClass() !== "desktop";

    return (
        <div data-testid={testId} className={styles["admin-panel"]}>
            <div className={styles["admin-panel__section"]}>
                <h2>
                    <FormattedMessage id="common__admin-panel" />
                </h2>
            </div>
            <div className={styles["admin-panel__row"]}>
                <div className={styles["admin-panel__section"]}>
                    {isNotDesktop && <div className={styles["admin-panel__line-breaker"]} />}
                    <StatisticsComponent />
                </div>
            </div>
            <div className={styles["admin-panel__section"]}>
                {isNotDesktop && <div className={styles["admin-panel__line-breaker"]} />}
                <UsersComponent />
            </div>
        </div>
    );
};

export default AdminPanel;
