import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import { defaultLocale } from "features/common/constants";
import { CardDto } from "features/common/dtos";
import { cookieNames } from "features/cookies/constants";
import { getCookie } from "features/cookies/utils";
import {
    CreateEmployeeOfferResponseDto,
    EmployeeOfferDetailsResponseDto,
    EmployeeOffersResponseDto,
    MyEmployeeOffersResponseDto,
} from "features/employeeOffers/dtos";
import { mapEmployeeOffersFetchParamsToQueryParameters } from "features/employeeOffers/mappers";
import { CreateEmployeeOfferRequestObject } from "features/employeeOffers/models";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import { mapMyJobOffersFetchParamsToQueryParameters } from "features/jobOffers/mappers";
import { ApplicationFormValues, MyJobOffersFetchParams } from "features/jobOffers/types";
import { CardDetailsDto } from "features/myProfile/dtos";
import { PhotoDto } from "features/user/dtos";

export class EmployeeOffersApi extends Api {
    getEmployeeOffersAsync = (
        params: EmployeeOffersFetchParams,
        cancelToken?: CancelToken
    ): Promise<EmployeeOffersResponseDto> => {
        return this.get<EmployeeOffersResponseDto>(
            `${config.apiRoutes.EMPLOYEE_OFFERS}${mapEmployeeOffersFetchParamsToQueryParameters(params)}&isActive=true&sort_by=addedDate&sort=desc` + "&location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale,
            cancelToken
        );
    };

    getMyEmployeeOffersAsync = (
        params: MyJobOffersFetchParams,
        cancelToken?: CancelToken
    ): Promise<MyEmployeeOffersResponseDto> => {
        return this.get<MyEmployeeOffersResponseDto>(
            `${config.apiRoutes.USER_EMPLOYEE_OFFERS}${mapMyJobOffersFetchParamsToQueryParameters(params)}&sort_by=addedDate&sort=desc`,
            cancelToken
        );
    };

    getEmployeeOfferDetailsAsync = (
        id: number,
        cancelToken?: CancelToken
    ): Promise<EmployeeOfferDetailsResponseDto> => {
        return this.get<EmployeeOfferDetailsResponseDto>(`${config.apiRoutes.EMPLOYEE_OFFERS}/${id}` + "?location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale, cancelToken);
    };

    sendEmployeeOfferMessageAsync = async (formData: FormData, cancelToken?: CancelToken): Promise<void> => {
        return this.post<FormData, void>(config.apiRoutes.MESSAGES_SEND_OFFER, formData, cancelToken);
    };

    createEmployeeOfferAsync = (
        values: CreateEmployeeOfferRequestObject["employeeOfferDetails"] & { businessCard: CreateEmployeeOfferRequestObject["businessCard"] },
        cancelToken?: CancelToken
    ): Promise<CreateEmployeeOfferResponseDto> => {
        return this.post<CreateEmployeeOfferRequestObject["employeeOfferDetails"] & { businessCard: CreateEmployeeOfferRequestObject["businessCard"] }, CreateEmployeeOfferResponseDto>(
            config.apiRoutes.EMPLOYEE_OFFERS,
            values,
            cancelToken
        );
    };

    saveEmployeeOfferAsync = (formData: FormData, cancelToken?: CancelToken): Promise<void> => {
        return this.post<FormData, void>(`${config.apiRoutes.EMPLOYEE_OFFERS}/partial-offer`, formData, cancelToken);
    };

    getCardDetailsAsync = (id: string, cancelToken?: CancelToken): Promise<CardDetailsDto> => {
        return this.get<CardDetailsDto>(`${config.apiRoutes.BUSINESS_CARDS}/${id}` + "?location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale, cancelToken);
    };

    updateMyProfileCardAsync = (
        cardDetails: Partial<CardDto>,
        id: number,
        cancelToken?: CancelToken
    ): Promise<CardDetailsDto> => {
        return this.put(`${config.apiRoutes.BUSINESS_CARDS}/${id}`, cardDetails, cancelToken);
    };

    uploadUserPhotos = (params: FormData, cancelToken?: CancelToken): Promise<PhotoDto[]> => {
        return this.post<FormData, PhotoDto[]>(config.apiRoutes.IMAGE_UPLOAD, params, cancelToken);
    };
}

export default new EmployeeOffersApi();
