import { CancelToken } from "api/utils";
import acceptIcon from "assets/icons/Dropdown/acceptIcon.svg";
import { ReactComponent as MagnifierIcon } from "assets/icons/magnifier.svg";
import classNames from "classnames";
import { getMailMessage } from "features/adminPanel/translationMessages";
import Button from "features/common/components/Button";
import DataTable, { TableColumn } from "features/common/components/DataTable";
import Headroom from "features/common/components/Headroom";
import Input from "features/common/components/Input";
import ModalWithContent from "features/common/components/ModalWithContent";
import Pagination from "features/common/components/Pagination";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import usePrevious from "features/common/hooks/usePrevious";
import { SortDirection } from "features/common/types";
import { pageExists } from "features/common/utils";
import { OfferDto, OfferRequestDto } from "features/moderatorPanel/dtos";
import {
    getApproveOfferMessage,
    getBlockOfferMessage,
    getJobOfferMessage,
    getManageMessage,
    getOfferDateMessage,
    getSearchForOfferMessage,
    getWorkTitleMessage,
} from "features/moderatorPanel/translationMessages";
import { GetOfferFetchParams, OfferSortType } from "features/moderatorPanel/types";
import { getInitialGetOfferFetchParams } from "features/moderatorPanel/utils";
import { getOffersTableColumns } from "features/moderatorPanel/utils/index";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ModeratorPanelState } from "../../reducers/index";
import styles from "./styles.module.scss";

export interface Props extends ModeratorPanelState {
    getOffersAsync: (fetchParams: GetOfferFetchParams, cancelToken?: CancelToken) => void,
    amendOffersAsync: (fetchParams: GetOfferFetchParams, cancelToken?: CancelToken) => void,
    putOfferAsync: (offerId: number, body: OfferRequestDto, cancelToken?: CancelToken) => void,
    endOfferAsync: (offerId: number, cancelToken?: CancelToken) => void,
}

const OfferSearchComponent: React.FC<Props> = ({
    offers,
    offersLoading,
    offersLoadingError,
    totalOffers,
    getOffersAsync,
    amendOffersAsync,
    putOfferAsync,
    endOfferAsync
}) => {
    const history = useHistory();
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();
    const searchBoxRef = useRef<HTMLDivElement | null>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [fetchParams, setFetchParams] = useState<GetOfferFetchParams>(getInitialGetOfferFetchParams());
    const [searchHeadroomBottomPosition, setSearchHeadroomBottomPosition] = useState(0);
    const deviceClass = useDeviceClass();
    const titleMessage = getWorkTitleMessage(intl);
    const dateMessage = getOfferDateMessage(intl);
    const mailMessage = getMailMessage(intl);
    const manageMessage = getManageMessage(intl);
    const searchForOfferPlaceholder = getSearchForOfferMessage(intl);
    const jobOfferMessage = getJobOfferMessage(intl);
    const approveOfferMessage = getApproveOfferMessage(intl);
    const blockOfferMessage = getBlockOfferMessage(intl);
    const prevFetchParams = usePrevious(fetchParams, fetchParams);
    const [approveOfferModal, setApproveOfferModal] = useState<boolean>();
    const [blockOfferModal, setBlockOfferModal] = useState<boolean>();
    const [showBlockNotification, setShowBlockNotification] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("")
    const [offerIdToBlock, setOfferIdToBlock] = useState<number | null>(null)

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);
    const handleBlockMessage = (event: React.ChangeEvent<HTMLInputElement>) => setMessage(event.target.value);

    const onSearch = () => {
        setFetchParams((value) => ({ ...value, globalParam: searchValue }));
    };

    const displayedOffers = useMemo(
        () =>
            (deviceClass === "desktop"
                ? offers?.slice(
                    fetchParams.pageSize * fetchParams.offset,
                    fetchParams.pageSize * fetchParams.offset + fetchParams.pageSize
                )
                : offers
            )?.filter((offer) => !!offer) as OfferDto[],
        [deviceClass, fetchParams.offset, fetchParams.pageSize, offers]
    );

    const onMobileSearchClick = () => {
        if (searchBoxRef.current) {
            const searchBoxCurrent = searchBoxRef.current.getBoundingClientRect();
            const headerAndButtonContainerHeight = 150;
            const distance = window.scrollY + searchBoxCurrent.top - headerAndButtonContainerHeight;

            window.scrollTo(0, distance);
        }
    };

    const onOfferActionClick = useCallback(
        (offerId: string) => {
            history.push(`${appRoutes.jobOffers}/${offerId}`);
        },
        [history]
    );

    const onApproveOfferClick = useCallback((offerId) => {
        if (offerId) { setApproveOfferModal(true) }
        putOfferAsync(
            offerId,
            {
                isBlocked: false,
                message
            })
    }, []);

    const closeApproveOfferModal = useCallback(() => {
        setApproveOfferModal(false)
        getOffersAsync(fetchParams)
    }, []);

    const onBlockOfferClick = useCallback((offerId) => {
        setOfferIdToBlock(offerId)
        setBlockOfferModal(true)
    }, []);

    const onBlockOffer = () => {
        if (offerIdToBlock) {
            endOfferAsync(offerIdToBlock)
        }
    }

    const closeBlockOfferModal = useCallback(() => {
        setBlockOfferModal(false)
        setOfferIdToBlock(null)
        getOffersAsync(fetchParams)
    }, []);

    const onSortClick = (sortBy: OfferSortType, sortDirection: SortDirection) => {
        const newColumns = [...columns];
        newColumns.splice(sortBy + 1, 1, {
            ...newColumns[sortBy + 1],
            currentSortDirection: sortDirection,
        } as TableColumn<OfferDto>);

        setColumns(newColumns);
        setFetchParams({
            ...fetchParams,
            offset: 0,
            sortBy:
                sortBy === OfferSortType.Date ? 'date' : sortBy === OfferSortType.Title
                    ? "title"
                    : "email"
            ,
            sortDirection: sortDirection === SortDirection.Ascending ? "asc" : "desc",
        });
    };

    const tableColumnLabels = [mailMessage, titleMessage, dateMessage];

    const [columns, setColumns] = useState<TableColumn<OfferDto>[]>(
        getOffersTableColumns(
            tableColumnLabels,
            [
                (direction) => onSortClick(OfferSortType.Email, direction),
                (direction) => onSortClick(OfferSortType.Title, direction),
                (direction) => onSortClick(OfferSortType.Date, direction),
            ],
            manageMessage,
            [jobOfferMessage, approveOfferMessage, blockOfferMessage],
            [
                onOfferActionClick,
                onApproveOfferClick,
                onBlockOfferClick
            ]
        )
    );

    useEffect(() => {
        if (displayedOffers) {
            setColumns(
                getOffersTableColumns(
                    tableColumnLabels,
                    [
                        (direction) => onSortClick(OfferSortType.Email, direction),
                        (direction) => onSortClick(OfferSortType.Title, direction),
                        (direction) => onSortClick(OfferSortType.Date, direction),
                    ],
                    manageMessage,
                    [
                        jobOfferMessage,
                        approveOfferMessage,
                        blockOfferMessage,
                    ],
                    [
                        onOfferActionClick,
                        onApproveOfferClick,
                        onBlockOfferClick
                    ]
                )
            );
        }

    }, [displayedOffers]);

    useEffect(() => {
        const sortStatusChanged =
            fetchParams.sortBy !== prevFetchParams?.sortBy ||
            fetchParams.sortDirection !== prevFetchParams?.sortDirection;
        const filterStatusChanged = fetchParams.globalParam !== prevFetchParams?.globalParam;
        if (
            !offersLoadingError &&
            !sortStatusChanged &&
            !filterStatusChanged &&
            ((!offers && !offersLoading)
                || !pageExists(fetchParams.pageSize, fetchParams.offset, offers, totalOffers) && fetchParams.offset !== 0
            )
        ) {
            amendOffersAsync(fetchParams, createCancelToken());
        }
        else if (sortStatusChanged || filterStatusChanged) {
            getOffersAsync(fetchParams, createCancelToken());
        }
        // when we are on want to search we have to reset to the page 0, otherwise search won't work
        else if (!offers) {
            if (fetchParams.offset !== 0) {
                setFetchParams((prevState) => ({ ...prevState, offset: 0 }));
                return
            }
            getOffersAsync(fetchParams, createCancelToken());
        }
    }, [fetchParams, prevFetchParams, totalOffers, offers, offersLoading, offersLoadingError]);

    useEffect(() => {
        if (fetchParams.offset !== prevFetchParams?.offset && deviceClass === "desktop") {
            window.scrollTo(0, 0);
        }
    }, [deviceClass, fetchParams.offset, prevFetchParams]);


    return (
        <div className={styles["offers"]}>
            <div className={styles["offers__header"]}>
                <h2>
                    <FormattedMessage id="moderator-panel__offers" />
                </h2>
            </div>
            <div className={styles["offers__search-box"]}>
                <div className={styles["offers__search-box-input"]} ref={searchBoxRef}>
                    <Input placeholder={searchForOfferPlaceholder} label={searchForOfferPlaceholder} value={searchValue} onChange={handleSearchChange} />
                </div>
                <div className={styles["offers__search-box-button"]}>
                    <Button
                        className={styles["offers__search-button"]}
                        type="submit"
                        variant="tertiary"
                        onClick={onSearch}
                    >
                        <MagnifierIcon />
                        <FormattedMessage id="common__search" />
                    </Button>
                </div>
                {deviceClass !== "desktop" && (
                    <Headroom pinStart={450} onPositionChange={setSearchHeadroomBottomPosition}>
                        {(isVisible) => (
                            <div
                                className={classNames(styles["offers__smartphone-headroom-mobile-box"], {
                                    [styles["offers__smartphone-headroom-mobile-box--no-shadow"]]: !isVisible,
                                })}
                                id="headroom-search-button"
                            >
                                <Button
                                    as="a"
                                    variant="secondary"
                                    onClick={onMobileSearchClick}
                                    className={classNames(
                                        styles["offers__search-button"],
                                        styles["offers__search-button-pinned"]
                                    )}
                                >
                                    <MagnifierIcon />
                                    <FormattedMessage id="common__search" />
                                </Button>
                            </div>
                        )}
                    </Headroom>
                )}

            </div>
            <div className={styles["offers__table-container"]}>
                <DataTable
                    columnsClassName={styles["offers__table-column"]}
                    data={displayedOffers ?? []}
                    columns={columns}
                    idColumn="id"
                    isDataLoading={offersLoading}
                    withTopTransition
                    withOnSortScroll
                    headerStickTop={searchHeadroomBottomPosition}
                />
            </div>
            {offers && totalOffers !== undefined && (
                <Pagination
                    loadedItemsCount={offers.length}
                    totalItemsCount={totalOffers}
                    currentPage={fetchParams.offset + 1}
                    pageSize={fetchParams.pageSize}
                    onPageChange={(pageIndex) => setFetchParams((prev) => ({ ...prev, offset: pageIndex - 1 }))}
                />
            )}
            {approveOfferModal && (
                <ModalWithContent
                    className={styles["offers__block-offer-modal"]}
                    header={
                        <span className={styles["offers__block-offer-title"]}>
                            <img src={acceptIcon} alt="acceptIcon" className={styles["offers__block-offer-title__accept-icon"]} />
                            <FormattedMessage id="moderator-panel__offer-approved" />
                        </span>
                    }
                    content={intl.formatMessage({ id: 'moderator-panel__approved-employee-offer' })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    cancelButtonHidden
                    onClose={closeApproveOfferModal}
                    primaryAction={closeApproveOfferModal}
                />

            )}
            {blockOfferModal && (
                <ModalWithContent
                    className={styles["offers__block-offer-modal"]}
                    header={
                        <span className={styles["offers__block-offer-title"]}>
                            <FormattedMessage id="moderator-panel__block-offer-reason" />
                        </span>
                    }
                    content={<input className={styles["offers__block-offer-input"]} placeholder={intl.formatMessage({ id: 'moderator-panel__block-offer-reason-placeholder' })} onChange={handleBlockMessage} />}
                    primaryButtonContent={intl.formatMessage({ id: "common__send" })}
                    secondaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={closeBlockOfferModal}
                    primaryAction={() => {
                        onBlockOffer()
                        closeBlockOfferModal()
                        setShowBlockNotification(true)
                    }}
                    secondaryAction={closeBlockOfferModal}
                />
            )}
            {showBlockNotification && (
                <ModalWithContent
                    className={styles["offers__block-offer-modal"]}
                    header={
                        <span className={styles["offers__block-offer-title"]}>
                            <img src={acceptIcon} alt="acceptIcon" className={styles["offers__block-offer-title__accept-icon"]} />
                            <FormattedMessage id="moderator-panel__block-notification" />
                        </span>
                    }
                    content={""}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    cancelButtonHidden
                    onClose={() => setShowBlockNotification(false)}
                    primaryAction={() => setShowBlockNotification(false)}
                />

            )}

        </div>

    );
};



export default OfferSearchComponent


