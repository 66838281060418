import { ReactComponent as ChevronUp } from "assets/icons/chevronUp.svg";
import classNames from "classnames";
import useResize from "features/common/hooks/useResize";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    children: React.ReactElement[];
    accordionClassName?: string;
    headerContainerClassName?: string;
    headerContentClassName?: string;
    headerButtonClassName?: string;
    bodyContainerClassName?: string;
    bodyContentClassName?: string;
    openOnHeaderClick?: boolean;
    openOnStart?: boolean;
}

const Accordion = ({
    "data-testid": testId,
    children,
    accordionClassName,
    headerContainerClassName,
    headerContentClassName,
    headerButtonClassName,
    bodyContainerClassName,
    bodyContentClassName,
    openOnHeaderClick,
    openOnStart,
}: Props) => {
    const [accordionOpen, setAccordionOpen] = useState(false);
    const bodyRef = useRef<HTMLDivElement>(null);
    const bodyContainerRef = useRef<HTMLDivElement>(null);

    const toggleAccordion = () => {
        const bodyHeight = bodyRef.current?.offsetHeight;
        if (!bodyContainerRef.current) {
            return;
        }

        if (bodyContainerRef.current.offsetHeight === 0) {
            bodyContainerRef.current.style.height = bodyHeight + "px";
        } else {
            bodyContainerRef.current.style.height = "0px";
        }

        setAccordionOpen((prevState) => !prevState);
    };

    useEffect(() => {
        if (openOnStart) {
            toggleAccordion();
        }
    }, [openOnStart]);

    useResize(() => {
        const bodyHeight = bodyRef.current?.offsetHeight;
        const bodyContainerHeight = bodyContainerRef?.current?.style.height;

        if (bodyContainerHeight && bodyContainerRef.current && bodyContainerRef.current.style.height[0] !== "0") {
            bodyContainerRef.current.style.height = bodyHeight + "px";
        }
    }, []);

    return (
        <div className={classNames(styles["accordion"], accordionClassName)} data-testid={testId}>
            <div
                className={classNames(styles["accordion__header-container"], headerContainerClassName)}
                data-testid={`${testId}__header-container`}
                onClick={openOnHeaderClick ? toggleAccordion : undefined}
            >
                <div
                    className={classNames(styles["accordion__header-content"], headerContentClassName, {
                        [styles["accordion__header-content--clickable"]]: openOnHeaderClick,
                    })}
                    data-testid={`${testId}__header-content`}
                >
                    {children[0]}
                </div>

                <div
                    className={classNames(styles["accordion__header-button"], headerButtonClassName, {
                        [styles["accordion__header-button--rotated"]]: !accordionOpen,
                    })}
                    data-testid={`${testId}__header-button`}
                    onClick={() => toggleAccordion()}
                >
                    <ChevronUp />
                </div>
            </div>

            <div
                className={classNames(styles["accordion__body-container"], bodyContainerClassName)}
                ref={bodyContainerRef}
                data-testid={`${testId}__body-container`}
                aria-hidden={!accordionOpen}
            >
                <div
                    className={classNames(styles["accordion__body-content"], bodyContentClassName)}
                    ref={bodyRef}
                    data-testid={`${testId}__body-content`}
                >
                    {children[1]}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
