import { ApiError } from "api/utils";
import {
    CardDetailsDto,
    CardRelatedOffersDto,
    DeleteCardDto,
    MyApplicationsDto,
    MyProfileCardsDto,
    NewCardResponseDto,
    OfferCreationCardsDto,
    PaymentCardDto,
} from "features/myProfile/dtos";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const getOfferCreationCards = createAsyncAction(
    "GET_OFFER_CREATION_CARDS_REQUEST",
    "GET_OFFER_CREATION_CARDS_SUCCESS",
    "GET_OFFER_CREATION_CARDS_FAILURE"
)<void, OfferCreationCardsDto, ApiError>();

export const getMyProfileCards = createAsyncAction(
    "GET_MY_PROFILE_CARDS_REQUEST",
    "GET_MY_PROFILE_CARDS_SUCCESS",
    "GET_MY_PROFILE_CARDS_FAILURE"
)<void, MyProfileCardsDto, ApiError>();

export const updateUserDetails = createAsyncAction(
    "MY_PROFILE_UPDATE_DETAILS_REQUEST",
    "MY_PROFILE_UPDATE_DETAILS_SUCCESS",
    "MY_PROFILE_UPDATE_DETAILS_FAILURE"
)<void, void, ApiError>();

export const updateUserPicture = createAsyncAction(
    "MY_PROFILE_UPDATE_PICTURE_REQUEST",
    "MY_PROFILE_UPDATE_PICTURE_SUCCESS",
    "MY_PROFILE_UPDATE_PICTURE_FAILURE"
)<void, void, ApiError>();

export const createNewCard = createAsyncAction(
    "MY_PROFILE_CREATE_NEW_CARD_REQUEST",
    "MY_PROFILE_CREATE_NEW_CARD_SUCCESS",
    "MY_PROFILE_CREATE_NEW_CARD_FAILURE"
)<void, NewCardResponseDto, ApiError>();

export const getCardDetails = createAsyncAction(
    "GET_CARD_DETAILS_REQUEST",
    "GET_CARD_DETAILS_SUCCESS",
    "GET_CARD_DETAILS_FAILURE"
)<string, CardDetailsDto, ApiError>();

export const updateMyProfileCard = createAsyncAction(
    "MY_PROFILE_UPDATE_CARD_REQUEST",
    "MY_PROFILE_UPDATE_CARD_SUCCESS",
    "MY_PROFILE_UPDATE_CARD_FAILURE"
)<void, CardDetailsDto, ApiError>();

export const getRelatedOffers = createAsyncAction(
    "GET_RELATED_OFFERS_REQUEST",
    "GET_RELATED_OFFERS_SUCCESS",
    "GET_RELATED_OFFERS_FAILURE"
)<void, CardRelatedOffersDto, ApiError>();

export const getMyApplications = createAsyncAction(
    "GET_MY_APPLICATIONS_REQUEST",
    "GET_MY_APPLICATIONS_SUCCESS",
    "GET_MY_APPLICATIONS_FAILURE"
)<void, MyApplicationsDto, ApiError>();

export const getPaymentCardData = createAsyncAction(
    "GET_PAYMENT_CARD_DATA_REQUEST",
    "GET_PAYMENT_CARD_DATA_SUCCESS",
    "GET_PAYMENT_CARD_DATA_FAILURE"
)<void, PaymentCardDto, ApiError>();

export const deleteCard = createAsyncAction(
    "DELETE_CARD_REQUEST",
    "DELETE_CARD_SUCCESS",
    "DELETE_CARD_FAILURE"
)<void, DeleteCardDto, ApiError>();

export const deleteMyAccount = createAsyncAction(
    "DELETE_MY_ACCOUNT_REQUEST",
    "DELETE_MY_ACCOUNT_SUCCESS",
    "DELETE_MY_ACCOUNT_FAILURE"
)<void, any, ApiError>();

export const refreshCardDetails = createAction("REFRESH_CARD_DETAILS")<CardDetailsDto>();

export const resetMyProfileCardCreated = createAction("RESET_MY_PROFILE_CARD_CREATED")<void>();

export const resetCountRelatedOffers = createAction("RESET_COUNT_RELATED_OFFERS")<void>();

type MyProfileAction =
    | ActionType<typeof getOfferCreationCards>
    | ActionType<typeof getMyProfileCards>
    | ActionType<typeof updateUserDetails>
    | ActionType<typeof createNewCard>
    | ActionType<typeof updateUserPicture>
    | ActionType<typeof getCardDetails>
    | ActionType<typeof updateMyProfileCard>
    | ActionType<typeof getRelatedOffers>
    | ActionType<typeof getMyApplications>
    | ActionType<typeof getPaymentCardData>
    | ActionType<typeof deleteCard>
    | ActionType<typeof deleteMyAccount>
    | ActionType<typeof refreshCardDetails>
    | ActionType<typeof resetMyProfileCardCreated>
    | ActionType<typeof resetCountRelatedOffers>;

export default MyProfileAction;
