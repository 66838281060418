import { CancelToken } from "api/utils";
import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import cn from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import DropzonePhotoSectionUploader from "features/common/components/DropzonePhotoSectionUploader";
import PhotoBrowser from "features/common/components/PhotoBrowser";
import Spinner from "features/common/components/Spinner";
import { allowedImageTypes, maxImageSize } from "features/common/constants";
import { CardDto } from "features/common/dtos";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { Card, Image } from "features/common/types";
import MyProfileApi from "features/myProfile/api";
import { CardOwnerType } from "features/myProfile/types";
import useUploadPhotos from "features/offers/hooks/useUploadPhotos";
import { PhotoDto } from "features/user/dtos";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    cardId: string;
    cardType?: CardOwnerType;
    displayOnly?: boolean;
    businessCarView?: boolean;
    updateMyProfileCardAsync: (cardDetails: Partial<CardDto>, id: string, cancelToken: CancelToken) => void;
    cardDetails?: Card;
}

const PhotosSection = ({ cardId, cardType, displayOnly, updateMyProfileCardAsync, cardDetails }: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();
    const [uploadingMainPhotoError, setUploadingMainPhotoError] = useState<string | undefined>();
    const [isEditingPhotos, setIsEditingPhotos] = useState(false);
    const [downloadPhotos, setDownloadPhotos] = useState<PhotoDto[]>([]);
    const [uploadingImages, setUploadingImages] = useState(false);

    useEffect(() => {
        setDownloadPhotos(!!cardDetails?.photos?.length ? cardDetails?.photos?.map((img: string) => ({ fileName: img, fileUrl: `${config.IMAGE_URL}/${img}` })) : []);
    }, [cardDetails]);

    const handleRemoveElement = useCallback(
        async (url: string) => {
            const filteredList = downloadPhotos.filter((image) => image.fileName !== url);
            setDownloadPhotos(filteredList);
        },
        [downloadPhotos]
    );

    const handleSavePhotos = useCallback(() => {
        try {
            updateMyProfileCardAsync(
                {
                    images: downloadPhotos.map((el: PhotoDto) => el.fileName) || [],
                    isPersonal: cardType === "private",
                    personal: cardType === "private",
                },
                cardId,
                createCancelToken()
            );
            setIsEditingPhotos(true);

        } catch (error) {
            setUploadingMainPhotoError(intl.formatMessage({ id: "card-details__uploading-photos-error" }));
        }
    }, [cardId, cardType, createCancelToken, intl, updateMyProfileCardAsync, isEditingPhotos, downloadPhotos]);

    const startEditingPhotos = useCallback(() => setIsEditingPhotos(true), [isEditingPhotos]);

    const [photosField, setPhotosField] = useState<Image[] | undefined>([]);
    useUploadPhotos(photosField, MyProfileApi, setUploadingImages, [], setDownloadPhotos, downloadPhotos);
    
    return (
        <div className={styles["photos-section"]}>
            {(!!downloadPhotos.length && !isEditingPhotos) || displayOnly ? (
                <>
                    <PhotoBrowser data-testid="photos-section__photos-browser"
                        photoUrls={downloadPhotos.map((el: PhotoDto) => el.fileName) || []}
                        businessCardView
                    />
                    {!displayOnly && (
                        <div className={styles["photos-section__button-wrapper"]}>
                            <Button
                                variant="no-background"
                                className={styles["photos-section__button"]}
                                onClick={startEditingPhotos}
                            >
                                <PenIcon />
                                <FormattedMessage id="card-details__edit-photos" />
                            </Button>
                        </div>

                    )}
                </>
            ) : (
                <>
                    <h2 className={styles["photos-section__title"]}>
                        <FormattedMessage id="card-details__photos" />
                    </h2>
                    <DropzonePhotoSectionUploader
                        className={styles["photos-section__dropzone-container"]}
                        dropzoneClassName={styles["photos-section__dropzone"]}
                        photoUrls={downloadPhotos.map((el: PhotoDto) => el) || []}
                        maxSize={maxImageSize}
                        allowedTypes={allowedImageTypes}
                        onUpload={(images) => setPhotosField(images)}
                        allowMultipleFiles
                        multipleFilesAmount={40}
                        textAsPlaceholder
                        uploadError={uploadingMainPhotoError}
                        buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-files" />}
                        handleRemoveElement={handleRemoveElement}
                        buttonDisabled={uploadingImages}
                    />
                    {uploadingImages && <p className={styles["photos-section__loading"]} ><FormattedMessage id="create-offer__adding-images" /><Spinner/></p>}
                    <div className={styles["photos-section__button-wrapper"]}>
                        <Button
                            variant="no-background"
                            className={cn(styles["photos-section__button"], uploadingImages && styles["disable"])}
                            onClick={() => { !uploadingImages && handleSavePhotos(); }}
                        >
                            <FormattedMessage id="common__save" />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PhotosSection;