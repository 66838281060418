import Dashboard from "features/dashboard";
import ActivationInfo from "features/routing/pages/ActivationInfo";
import AdminPanel from "features/routing/pages/AdminPanel";
import CardDetailsPage from "features/routing/pages/Card";
import ContactFormComponent from "features/routing/pages/ContactForm";
import ConversationDetails from "features/routing/pages/ConversationDetails";
import CookiePolicy from "features/routing/pages/CookiePolicy";
import CopyEmployeeOffer from "features/routing/pages/CopyEmployeeOffer";
import CopyJobOffer from "features/routing/pages/CopyJobOffer";
import CreateEmployeeOffer from "features/routing/pages/CreateEmployeeOffer";
import CreateEmployeeOfferCardDetails from "features/routing/pages/CreateEmployeeOfferCardDetails";
import CreateEmployeeOfferNewCardPage from "features/routing/pages/CreateEmployeeOfferNewCardPage";
import CreateJobOffer from "features/routing/pages/CreateJobOffer";
import CreateJobOfferCardDetails from "features/routing/pages/CreateJobOfferCardDetails";
import CreateJobOfferNewCardPage from "features/routing/pages/CreateJobOfferNewCardPage";
import CreateProfile from "features/routing/pages/CreateProfile";
import EmployeeOfferDetails from "features/routing/pages/EmployeeOfferDetails";
import EmployeeOffers from "features/routing/pages/EmployeeOffers";
import Example from "features/routing/pages/Example";
import Faq from "features/routing/pages/Faq";
import Favorite from "features/routing/pages/Favorite";
import ForgottenPassword from "features/routing/pages/ForgottenPassword";
import GetBonus from "features/routing/pages/GetBonus";
import HowItWorks from "features/routing/pages/HowItWorks";
import JobOfferDetails from "features/routing/pages/JobOfferDetails";
import JobOffers from "features/routing/pages/JobOffers";
import Login from "features/routing/pages/Login";
import ModeratorPanel from "features/routing/pages/ModeratorPanel";
import MyApplications from "features/routing/pages/MyApplications";
import MyEmployeeOfferDetails from "features/routing/pages/MyEmployeeOfferDetails";
import MyEmployeeOffers from "features/routing/pages/MyEmployeeOffers";
import MyJobOfferDetails from "features/routing/pages/MyJobOfferDetails";
import MyJobOffers from "features/routing/pages/MyJobOffers";
import MyMessages from "features/routing/pages/MyMessages";
import MyProfile from "features/routing/pages/MyProfile";
import NewCardPage from "features/routing/pages/NewCard";
import NotFound from "features/routing/pages/NotFound";
import OauthLogin from "features/routing/pages/OauthLogin";
import Payment from "features/routing/pages/Payment";
import PrivacyPolicy from "features/routing/pages/PrivacyPolicy";
import ProfileCardDetailsPage from "features/routing/pages/ProfileCard";
import RateEmployeePage from "features/routing/pages/RateEmployee";
import RateEmployerPage from "features/routing/pages/RateEmployer";
import RateEmployerSummary from "features/routing/pages/RateEmployerSummary";
import RateEmployeeSummary from "features/routing/pages/RateEmployeeSummary";
import RecoverPassword from "features/routing/pages/RecoverPassword";
import Register from "features/routing/pages/Register";
import Regulations from "features/routing/pages/Regulations";
import SignOut from "features/routing/pages/SignOut";
import UserEmployeeOffers from "features/routing/pages/UserEmployeeOffers";
import UserJobOffers from "features/routing/pages/UserJobOffers";
import UserProfile from "features/routing/pages/UserProfile";
import Verify from "features/routing/pages/Verify";
import {appRoutes} from "features/routing/routes";
import Notifications from "./pages/Notifications";
import PaymentRedirect from "./pages/PaymentRedirect";
import config from "../../config";
import RatedEmployeeSummary from "../rating/components/RateEmployeeSummary";

export interface RouteConfiguration {
    id: string;
    name?: string;
    exact: boolean;
    path: string;
    Component: React.ComponentType;
    withoutNavigation?: boolean;
    isPublic?: boolean;
    requiresAdmin?: boolean;
    requiresModerator?: boolean;
}

const routes: RouteConfiguration[] = [
    {
        id: "dashboard",
        name: "Dashboard",
        exact: true,
        path: appRoutes.dashboard,
        isPublic: true,
        Component: Dashboard,
    },
    {
        id: "employee-offers",
        name: "Employee offers",
        exact: true,
        path: appRoutes.employeeOffers,
        isPublic: true,
        Component: EmployeeOffers,
    },
    {
        id: "user-employee-offers",
        name: "User employee offers",
        exact: true,
        path: `${appRoutes.userEmployeeOffers}/:userId`,
        requiresAdmin: true,
        Component: UserEmployeeOffers,
    },
    {
        id: "card-employee-offers",
        name: "Card employee offers",
        exact: true,
        path: `${appRoutes.cardEmployeeOffers}/:businessCardId`,
        Component: EmployeeOffers,
    },
    {
        id: "employee-offer-details",
        name: "Employee offer details",
        exact: true,
        path: `${appRoutes.employeeOffers}/:id`,
        isPublic: true,
        Component: EmployeeOfferDetails,
    },
    {
        id: "example",
        name: "Example",
        exact: true,
        path: appRoutes.example,
        isPublic: true,
        Component: Example,
    },
    {
        id: "create-employee-offer",
        name: "Create Employee Offer",
        exact: true,
        path: `${appRoutes.createEmployeeOffer}/:businessCardId/:offerLength`,
        Component: CreateEmployeeOffer,
    },
    {
        id: "create-job-offer",
        name: "Create Job Offer",
        exact: true,
        path: `${appRoutes.createJobOffer}/:businessCardId/:offerLength`,
        Component: CreateJobOffer,
    },
    {
        id: "payment-screen",
        name: "Payment",
        exact: true,
        path: `${appRoutes.payment}/:offerId`,
        Component: Payment,
    },
    {
        id: "create-dedicated-job-offer",
        name: "Create Dedicated Job Offer",
        exact: true,
        path: `${appRoutes.createDedicatedJobOffer}/:dedicatedUserId/:businessCardId`,
        Component: CreateJobOffer,
    },
    {
        id: "create-profile",
        name: "CreateProfile",
        exact: true,
        path: appRoutes.createProfile,
        isPublic: true,
        Component: CreateProfile,
    },
    {
        id: "job-offers",
        name: "Job Offers",
        exact: true,
        path: appRoutes.jobOffers,
        isPublic: true,
        Component: JobOffers,
    },
    {
        id: "user-job-offers",
        name: "User Job Offers",
        exact: true,
        path: `${appRoutes.userJobOffers}/:userId`,
        // requiresAdmin: true,
        Component: UserJobOffers,
    },
    {
        id: "card-job-offers",
        name: "Card job offers",
        exact: true,
        path: `${appRoutes.cardJobOffers}/:businessCardId`,
        Component: JobOffers,
    },
    {
        id: "job-offer-details",
        name: "Job offer details",
        exact: true,
        path: `${appRoutes.jobOffers}/:id`,
        isPublic: true,
        Component: JobOfferDetails,
    },
    {
        id: "my-messages",
        name: "My massages",
        exact: true,
        path: appRoutes.myMessages,
        isPublic: false,
        Component: MyMessages,
    },
    {
        id: "notifications",
        name: "Notifications",
        exact: true,
        path: appRoutes.notifications,
        isPublic: false,
        Component: Notifications,
    },
    {
        id: "favorite",
        name: "Favorite",
        exact: true,
        path: appRoutes.favorite,
        isPublic: false,
        Component: Favorite,
    },
    {
        id: "my-applications",
        name: "My Applications",
        exact: true,
        path: appRoutes.myApplications,
        isPublic: false,
        Component: MyApplications,
    },
    {
        id: "messages",
        name: "Messages",
        exact: true,
        path: `${appRoutes.messages}/:id`,
        isPublic: true,
        Component: ConversationDetails,
    },
    {
        id: "my-job-offers",
        name: "My job offers",
        exact: true,
        path: appRoutes.myJobOffers,
        isPublic: false,
        Component: MyJobOffers,
    },
    {
        id: "my-job-offers-edit",
        name: "My job offers edit",
        exact: true,
        path: `${appRoutes.myJobOffers}/edit/:id`,
        isPublic: false,
        Component: CreateJobOffer,
    },
    {
        id: "my-job-offers-copy",
        name: "My job offers edit",
        exact: true,
        path: `${appRoutes.myJobOffers}/copy/:id`,
        isPublic: false,
        Component: CopyJobOffer,
    },
    {
        id: "my-job-offer-details",
        name: "My employee offers details",
        exact: true,
        path: `${appRoutes.myJobOffers}/:id/:activeTab?`,
        isPublic: false,
        Component: MyJobOfferDetails,
    },
    {
        id: "my-employee-offers",
        name: "My employee offers",
        exact: true,
        path: appRoutes.myEmployeeOffers,
        isPublic: false,
        Component: MyEmployeeOffers,
    },
    {
        id: "my-employee-offers-edit",
        name: "My employee offers edit",
        exact: true,
        path: `${appRoutes.myEmployeeOffers}/edit/:id`,
        isPublic: false,
        Component: CreateEmployeeOffer,
    },
    {
        id: "my-employee-offers-copy",
        name: "My employee Offer Copy",
        exact: true,
        path: `${appRoutes.myEmployeeOffers}/copy/:id`,
        isPublic: false,
        Component: CopyEmployeeOffer,
    },
    {
        id: "my-employee-offer-details",
        name: "My employee Offer Details",
        exact: true,
        path: `${appRoutes.myEmployeeOffers}/:id`,
        isPublic: false,
        Component: MyEmployeeOfferDetails,
    },
    {
        id: "cookie-policy",
        name: "Cookie policy",
        exact: true,
        path: appRoutes.cookiePolicy,
        isPublic: true,
        Component: CookiePolicy,
    },
    {
        id: "privacy-policy",
        name: "Privacy policy",
        exact: true,
        path: appRoutes.privacyPolicy,
        isPublic: true,
        Component: PrivacyPolicy,
    },
    {
        id: "howItWorks",
        name: "How it workds",
        exact: true,
        path: appRoutes.howItWorks,
        isPublic: true,
        Component: HowItWorks,
    },
    {
        id: "faq",
        name: "FAQ",
        exact: true,
        path: appRoutes.faq,
        isPublic: true,
        Component: Faq,
    },
    {
        id: "regulations",
        name: "Regulations",
        exact: true,
        path: appRoutes.regulations,
        isPublic: true,
        Component: Regulations,
    },
    {
        id: "sign-out",
        name: "Sign out",
        exact: false,
        path: `${appRoutes.signOut}/:redirectPath?`,
        isPublic: true,
        Component: SignOut,
    },
    {
        id: "my-profile",
        name: "My Profile",
        exact: true,
        path: appRoutes.myProfile,
        Component: MyProfile,
    },
    {
        id: "user-profile",
        name: "User profile",
        exact: true,
        // requiredAdmin: true,
        path: `${appRoutes.userProfile}/:userId`,
        Component: UserProfile,
    },
    {
        id: "card-details",
        name: "Card Details",
        exact: true,
        path: `${appRoutes.card}/:uniqueId`,
        Component: CardDetailsPage,
    },
    {
        id: "create-job-offer-card-details",
        name: "Create Employee Offer Card Details",
        exact: true,
        path: `${appRoutes.createJobOfferCard}/:uniqueId/:offerLength`,
        Component: CreateJobOfferCardDetails,
    },
    {
        id: "create-employee-offer-card-details",
        name: "Create Employee Offer Card Details",
        exact: true,
        path: `${appRoutes.createEmployeeOfferCard}/:uniqueId/:offerLength`,
        Component: CreateEmployeeOfferCardDetails,
    },
    {
        id: "profile-card-details",
        name: "Profile Card Details",
        exact: true,
        path: `${appRoutes.profileCard}/:uniqueId`,
        Component: ProfileCardDetailsPage,
    },
    {
        id: "new-card",
        name: "New card",
        exact: true,
        path: appRoutes.newCard,
        Component: NewCardPage,
    },
    {
        id: "job-offer-new-card",
        name: "Job offer new card",
        exact: true,
        path: `${appRoutes.createJobOfferNewCard}/:offerLength`,
        Component: CreateJobOfferNewCardPage,
    },
    {
        id: "employee-offer-new-card",
        name: "Employee-offer new card",
        exact: true,
        path: `${appRoutes.createEmployeeOfferNewCard}/:offerLength`,
        Component: CreateEmployeeOfferNewCardPage,
    },
    {
        id: "rate-employer",
        name: "Rate employer",
        exact: true,
        path: `${appRoutes.employer}/:id/rate`,
        Component: RateEmployerPage,
    },
    {
        id: "rate-employee",
        name: "Rate employee",
        exact: true,
        path: `${appRoutes.employee}/:id/rate`,
        Component: RateEmployeePage,
    },
    {
        id: "rate-employee-summary",
        name: "Rated Employee",
        exact: true,
        path: `${appRoutes.employee}/:id/rate-summary`,
        Component: RateEmployeeSummary,
    },
    {
        id: "rate-employer-summary",
        name: "Rated Employer",
        exact: true,
        path: `${appRoutes.employer}/:id/rate-summary`,
        Component: RateEmployerSummary,
    },
    {
        id: "contact-form",
        name: "contactForm",
        exact: true,
        path: appRoutes.contactForm,
        Component: ContactFormComponent,
    },
    {
        id: "admin-panel",
        name: "Admin Panel",
        exact: true,
        requiresAdmin: true,
        path: appRoutes.adminPanel,
        Component: AdminPanel,
    },
    {
        id: "moderator-panel",
        name: "Moderator panel",
        exact: true,
        requiresAdmin: false,
        requiresModerator: true,
        path: appRoutes.moderatorPanel,
        Component: ModeratorPanel,
    },
    {
        id: "login",
        name: "Login",
        exact: true,
        path: appRoutes.login,
        isPublic: true,
        Component: Login,
    },
    {
        id: "login",
        name: "Forgotten Password",
        exact: true,
        path: appRoutes.forgottenPassword,
        isPublic: true,
        Component: ForgottenPassword,
    },
    {
        id: "recover-pass",
        name: "Recover password",
        exact: true,
        path: appRoutes.recoverPassword,
        isPublic: true,
        Component: RecoverPassword,
    },
    {
        id: "oauth-login",
        name: "Login",
        exact: true,
        path: appRoutes.oauthLogin,
        isPublic: true,
        Component: OauthLogin,
    },
    {
        id: "register",
        name: "Register",
        exact: true,
        path: appRoutes.register,
        isPublic: true,
        Component: Register,
    },
    {
        id: "verify",
        name: "Verify",
        exact: true,
        path: `${appRoutes.verify}/:token`,
        isPublic: true,
        Component: Verify,
    },
    {
        id: "activation-info",
        name: "activation-info",
        exact: true,
        path: appRoutes.activationInfo,
        isPublic: true,
        Component: ActivationInfo,
    },
    {
        id: "checkout-redirect",
        name: "Checkout Redirect",
        exact: false,
        path: appRoutes.paymentRedirect,
        isPublic: true,
        Component: PaymentRedirect,
    },
    {
        id: "not-found",
        name: "Not Found",
        exact: false,
        path: appRoutes.notFound,
        isPublic: true,
        Component: NotFound,
    },
    {
        id: "get-bonus",
        name: "Get Bonus",
        exact: false,
        path: config.GETBONUS_URL,
        isPublic: true,
        Component: GetBonus,
    },
];

export default routes;
