import classNames from "classnames";
import Button from "features/common/components/Button";
import { getGenderMessage } from "features/common/translationMessages";
import React, { useCallback, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import styled from "styled-components";
import styles from "./styles.module.scss";

interface DropdownItemsProps {
    toggled: boolean;
}

const DropdownItems = styled.div<DropdownItemsProps>`
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
width: 135px;
position: absolute;
margin-top: 5px;
display: flex;
flex-direction: column;
border: 1px solid black;
padding: 8px 30px;
border-radius: 4px;
background-color: white;
display: ${props => props.toggled ? "block" : "none"};
`

export interface Props {
    text: string;
    isRequired?: boolean;
    isClearable?: boolean;
    value?: number;
    onChange?: (value: number) => void;
    onClear?: () => void;
    displayOnly?: boolean;
}

const RatingButtons = ({ text, isRequired, isClearable, value, onChange, onClear, displayOnly = false }: Props) => {
    const handleNumberButtonClick = useCallback(
        (event) => {
            if (displayOnly) return;
            const number = event.target.dataset.number;
            handleSetDropdownValue(event.target.dataset.number)
            setDropdownState(false)
            onChange && onChange(+number);
        },
        [onChange]
    );
    const [width, SetWidth] = useState(window.innerWidth);

    const intl = useIntl();

    const createGenderDropdownOptions = (intl: IntlShape) => [
        {
            value: "FEMALE",
            display: intl.formatMessage({ id: "common__female" }),
        },
        {
            value: "MALE",
            display: intl.formatMessage({ id: "common__male" }),
        },
        {
            value: "UNKNOWN",
            display: intl.formatMessage({ id: "common__prefer-not-to-specify" }),
        },
    ];
    const genderDropdownOptions = createGenderDropdownOptions(intl);
    const genderIntlMessage = getGenderMessage(intl);

    const [dropdownState, setDropdownState] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("");

    const handleDropdownClick = () => {
        setDropdownState(!dropdownState);
    };
    const handleSetDropdownValue = (value: string) => {
        setDropdownValue(value);
        setDropdownState(!dropdownState);
    };

    return (
        <div className={styles["rating-buttons"]}>
            <div className={styles["rating-buttons__title"]}>
                {text}
                {!!isRequired && "* "}
                <span className={styles["rating-buttons__required-message"]}>
                    {!!isRequired && <FormattedMessage id="rating-buttons__answer-required" />}
                </span>
            </div>
            {width > 1140 &&
                <div className={styles["rating-buttons__buttons-container"]}>
                    <div className={styles["rating-buttons__number-buttons-container"]}>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 1,
                                [styles["rating-buttons__display-only"]]: displayOnly,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={1}
                        >
                            1
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 2,
                                [styles["rating-buttons__display-only"]]: displayOnly,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={2}
                        >
                            2
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 3,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={3}
                        >
                            3
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 4,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={4}
                        >
                            4
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 5,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={5}
                        >
                            5
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 6,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={6}
                        >
                            6
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 7,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={7}
                        >
                            7
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 8,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={8}
                        >
                            8
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 9,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={9}
                        >
                            9
                        </div>
                        <div
                            className={classNames(styles["rating-buttons__number-button"], {
                                [styles["rating-buttons__selected-button"]]: value === 10,
                                [styles["rating-buttons__display-only"]]: displayOnly === true,
                            })}
                            onClick={handleNumberButtonClick}
                            data-number={10}
                        >
                            10
                        </div>
                    </div>
                    {!!isClearable && (
                        <Button
                            variant="no-background"
                            onClick={onClear}
                            className={styles["rating-buttons__reset-button"]}
                        >
                            <FormattedMessage id="common__clear" />
                        </Button>
                    )}
                </div>
            }
            {width <= 1140 &&
                <div className={styles["rating-buttons__container"]}>
                    <div className={styles["rating-buttons__dropdown"]}>
                        <button onClick={handleDropdownClick} className={styles["rating-buttons__dropdown-btn"]}>
                            {dropdownValue === "" ? <FormattedMessage id='application-details__rate-single' /> : dropdownValue}
                        </button>
                        <DropdownItems toggled={dropdownState}>
                            <div className={styles["rating-buttons__dropdown-buttons-container"]}>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 1,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={1}
                                >
                                    1
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 2,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={2}
                                >
                                    2
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 3,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={3}
                                >
                                    3
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 4,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={4}
                                >
                                    4
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 5,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={5}
                                >
                                    5
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 6,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={6}
                                >
                                    6
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 7,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={7}
                                >
                                    7
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 8,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={8}
                                >
                                    8
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 9,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={9}
                                >
                                    9
                                </div>
                                <div
                                    className={classNames(styles["rating-buttons__dropdown-number-button"], {
                                        [styles["rating-buttons__selected-button"]]: value === 10,
                                    })}
                                    onClick={handleNumberButtonClick}
                                    data-number={10}
                                >
                                    10
                                </div>
                            </div>
                        </DropdownItems>
                    </div>
                </div>
            }
        </div>
    );
};

export default RatingButtons;
