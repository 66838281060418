import { format } from 'date-fns';
import { EmployeeExperienceRequirement } from 'features/myProfile/models';
import React from 'react'
import { useIntl } from 'react-intl';
import { pl } from "date-fns/locale";
import styles from "./styles.module.scss";
import { JobExperience } from 'features/jobOffers/types';

interface JobExperienceAreaProps {
    values: JobExperience[];
}


const JobExperienceArea = ({ values }: JobExperienceAreaProps) => {
    const intl = useIntl();

    return (
        <div>
            {values?.map((experience) => {
                const startDate = new Date(experience.dateFrom);
                const endDate = new Date(experience.dateTo);

                return (
                    <div className={styles["employee-experience-requirement__item"]}>
                        <div className={styles["employee-experience-requirement__item--dot"]} />
                        <div className={styles["employee-experience-requirement__item--container"]}>
                            <div className={styles["employee-experience-requirement__item--header"]}>
                                <span>
                                    {experience.companyName} / {experience.position}
                                </span>
                            </div>
                            <div className={styles["employee-experience-requirement__item--dates"]}>
                                {format(startDate as Date, "LLLL yyyy", { locale: pl })}
                                {" - "}
                                {experience.isActive
                                    ? intl.formatMessage({ id: "card-details__experience-active" })
                                    : format(endDate as Date, "LLLL yyyy", { locale: pl })}
                            </div>
                            <div className={styles["employee-experience-requirement__item--info"]}>
                                {experience.description}
                            </div>
                        </div>
                    </div>
                )
            }
            )}
        </div>
    )
}

export default JobExperienceArea
