import {
    selectCurrencies,
    selectCurrenciesLoading,
    selectCurrenciesLoadingError,
} from "features/common/selectors";
import { getCurrenciesAsync } from "features/common/thunks";
import { resetJobOfferDetails, resetCreateJobOfferState } from "features/jobOffers/actions";
import {
    selectJobOfferCreating,
    selectJobOfferCreatingError,
    selectJobOfferCreatingSuccess,
    selectJobOfferDetails,
    selectJobOfferDetailsLoading,
} from "features/jobOffers/selectors";
import { publishJobOfferAsync } from "features/jobOffers/thunks";
import {
    selectCardDetails,
    selectCardDetailsLoading,
    selectCardDetailsLoadingError,
} from "features/myProfile/selectors";
import { getCardDetailsAsync } from "features/myProfile/thunks";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import CreateOffer from "./component";

const mapStateToProps = (state: RootState) => ({
    jobOfferDetails: selectJobOfferDetails(state),
    jobOfferDetailsLoading: selectJobOfferDetailsLoading(state),
    user: selectUser(state),
    cardDetails: selectCardDetails(state),
    cardDetailsLoading: selectCardDetailsLoading(state),
    cardDetailsError: selectCardDetailsLoadingError(state),
    jobOfferCreating: selectJobOfferCreating(state),
    jobOfferCreatingSuccess: selectJobOfferCreatingSuccess(state),
    jobOfferCreatingError: selectJobOfferCreatingError(state),
    currencies: selectCurrencies(state),
    currenciesLoading: selectCurrenciesLoading(state),
    currenciesError: selectCurrenciesLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCardDetailsAsync,
            publishJobOfferAsync,
            getCurrenciesAsync,
            resetJobOfferDetails: () => resetJobOfferDetails(),
            resetCreateJobOfferState: () => resetCreateJobOfferState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer);
