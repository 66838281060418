import createAxiosInstance from "api/axios";
import { CancelToken, createCancelToken, CustomCancelToken, RequestCancelToken } from "api/utils";
import { AxiosResponse, CancelToken as AxiosCancelToken } from "axios";
import config from "config";

class Api {
    static axios = createAxiosInstance(config.API_URL);

    createRequestCancelToken = (token?: CancelToken): RequestCancelToken => {
        const cancelToken = (() => {
            if (!token) {
                return undefined;
            } else if ((token as CustomCancelToken).isCustomCancelToken) {
                return createCancelToken(token as CustomCancelToken);
            } else {
                return token as AxiosCancelToken;
            }
        })();

        return { cancelToken };
    };

    handleResponsePromise = <TResult>(response: AxiosResponse<TResult | undefined>) => {
        if (response) {
            return response.data as TResult;
        }
    };

    get = async <TResult>(url: string, cancelToken?: CancelToken): Promise<TResult> => {
        return Api.axios.get(url, this.createRequestCancelToken(cancelToken)).then(this.handleResponsePromise);
    };

    post = async <TData, TResult>(url: string, data: TData, cancelToken?: CancelToken): Promise<TResult> => {
        return Api.axios
            .post(url, data, {
                headers: { ...this.createRequestCancelToken(cancelToken) },
            })
            .then(this.handleResponsePromise);
    };

    patch = async <TData, TResult>(url: string, data: TData, cancelToken?: CancelToken): Promise<TResult> => {
        return Api.axios
            .patch(url, data, {
                headers: { ...this.createRequestCancelToken(cancelToken) },
            })
            .then(this.handleResponsePromise);
    };

    put = async <TData, TResult>(url: string, data: TData, cancelToken?: CancelToken): Promise<TResult> => {
        return Api.axios
            .put(url, data, {
                headers: { ...this.createRequestCancelToken(cancelToken) },
            })
            .then(this.handleResponsePromise);
    };

    delete = async <TResult>(url: string, cancelToken?: CancelToken): Promise<TResult> => {
        return Api.axios.delete(url, this.createRequestCancelToken(cancelToken)).then(this.handleResponsePromise);
    };
}

export default new Api();
