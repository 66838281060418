import React from "react";

import PaymentComponent from "features/jobOffers/components/Payment";

import styles from "./styles.module.scss";

const Payment = () => {
    return (
        <div className={styles["payment"]}>
            <PaymentComponent />
        </div>
    );
};

export default Payment;
