import Checkbox from "features/common/components/Checkbox";
import Input from "features/common/components/Input";
import RadioButtons from "features/common/components/RadioButtons";
import { paymentFormFieldNames } from "features/jobOffers/constants";
import { EmployerType, InvoiceType } from "features/jobOffers/types";
import { useField } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormattedMessage, useIntl } from "react-intl";
import { locationFormatter } from "utils";
import styles from "./styles.module.scss";
import FormDropdown from "../../../../common/components/FormDropdown";
import { CountriesDto } from "../../../../common/dtos";
import { getCountriesOptions } from "../../../utils/countries";
import { CancelToken, createCancelToken } from "../../../../../api/utils";

export interface Props {
    disabled?: boolean;
    selectedCard?: any;
    setInvoiceData: any;
    countries?: CountriesDto[];
    countriesLoading?: boolean;
    getCountriesAsync: (lang: string, cancelToken?: CancelToken) => void;
    resetCountries: () => void;
}

const DataSection = ({ disabled, selectedCard, setInvoiceData, countries, countriesLoading, getCountriesAsync, resetCountries }: Props) => {
    const intl = useIntl();
    const countryOptions = getCountriesOptions(countries);
    const [didFindInitialLocation, setDidFindInitialLocation] = useState(false);

    const [location, setLocation] = useState("");
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [selectedTag, setSelectedTag] = useState<any>();

    const employerTypeOptions = [
        {
            label: intl.formatMessage({ id: "payment__company-type" }),
            value: EmployerType.Company,
        },
        {
            label: intl.formatMessage({ id: "payment__private-person-type" }),
            value: EmployerType.Private,
        },
    ];

    const [employerTypeField, , employerTypeHandlers] = useField(paymentFormFieldNames.employerType);
    const [companyNameField, companyNameMeta] = useField(paymentFormFieldNames.companyName);
    const [taxIdentificationNumberField, taxIdentificationNumberMeta] = useField(
        paymentFormFieldNames.taxIdentificationNumber
    );
    const [firstNameField, firstNameMeta] = useField(paymentFormFieldNames.firstName);
    const [lastNameField, lastNameMeta] = useField(paymentFormFieldNames.lastName);
    const [locationField, locationMeta, locationHandlers] = useField<any>(paymentFormFieldNames.location);
    const [phoneNumberField, phoneNumberMeta] = useField(paymentFormFieldNames.phoneNumber);
    const [emailField, emailMeta] = useField(paymentFormFieldNames.email);
    const [countryField, countryMeta, countryFieldHandler] = useField(paymentFormFieldNames.country);
    const [cityField, cityMeta] = useField(paymentFormFieldNames.city);
    const [streetField, streetMeta] = useField(paymentFormFieldNames.street);
    const [postalCodeField, postalCodeMeta] = useField(paymentFormFieldNames.postalCode);
    const [attachInvoiceField, attachInvoiceMeta, attachInvoiceHandlers] = useField(
        paymentFormFieldNames.attachInvoice
    );

    const invoiceTypeHandler = useCallback(() => {
        if (employerTypeField.value === EmployerType.Company) return InvoiceType.VAT
        if (employerTypeField.value === EmployerType.Private) {
            return attachInvoiceField.value ? InvoiceType.VAT : InvoiceType.RECEIPT;
        }
    }, [employerTypeField, attachInvoiceField]);

    //object with invoice data, designed to be sent to backend on payment
    const InvoiceData = {
        recipientType: employerTypeField.value,
        firstName: firstNameField.value,
        lastName: lastNameField.value,
        phoneNumber: phoneNumberField.value,
        email: emailField.value,
        countryCode: countryField.value,
        city: cityField.value,
        street: streetField.value,
        postalCode: postalCodeField.value,
        companyName: companyNameField.value,
        taxIdentificationNumber: taxIdentificationNumberField.value,
        invoiceType: invoiceTypeHandler(),
    }

    useEffect(() => {
        setInvoiceData.current = InvoiceData;
    }, [InvoiceData]);

    useEffect(() => {
        if (countries) {
            resetCountries();
        }
    }, [intl.locale])

    useEffect(() => {
        if (!countries && !countriesLoading) {
            getCountriesAsync(intl.locale, createCancelToken());
        }
    }, [countries, countriesLoading, getCountriesAsync, createCancelToken]);

    const {
        placePredictions,
        getPlacePredictions,
        placesService
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500
    });

    useEffect(() => {
        setDidFindInitialLocation(false);
    }, [selectedCard]);

    useEffect(() => {
        if (!didFindInitialLocation) {
            const location = { lat: locationField?.value?.latitude, lng: locationField?.value?.longitude };
            placesService?.nearbySearch({ location, radius: 1, name: locationFormatter(locationField.value) }, (places) => {
                if (places?.[0]?.place_id) {
                    placesService?.getDetails({ placeId: places[0].place_id }, (placeDetails) => {
                        const detailedAdress = {
                            placeId: selectedTag.id,
                            input: selectedTag.name,
                        }
                        locationHandlers.setValue(detailedAdress);
                        setDidFindInitialLocation(true);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placesService]);

    useEffect(() => {
        getPlacePredictions({ input: location, types: ['(regions)'] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id,
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [location, placePredictions]);

    useEffect(() => {
        if (!selectedTag) return;

        placesService?.getDetails({ placeId: selectedTag.id }, (placeDetails) => {
            const detailedAdress = {
                placeId: placeDetails?.place_id,
                input: placeDetails?.formatted_address || "",
            }
            locationHandlers.setValue(detailedAdress);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placesService, selectedTag]);

    const handleChangeCountry = useCallback((value: string) => {
        countryFieldHandler.setValue(value);
    }, [countryField]);


    const selectedCountry = useMemo(
        () => countryOptions?.find((item: any) => countryField.value == item.value),
        [countryField.value, countryOptions]
    );

    return (
        <div className={styles["payment-data-section"]}>
            <RadioButtons<EmployerType>
                disabled={disabled}
                wrapperClassName={styles["payment-data-section__employer-type"]}
                titleClassName={styles["payment-data-section__employer-type--title"]}
                inputsContainerClassName={styles["payment-data-section__employer-type--inputs-container"]}
                title={intl.formatMessage({ id: "payment__employer-type" })}
                options={employerTypeOptions}
                value={employerTypeField.value}
                onValueChange={(value: EmployerType) => employerTypeHandlers.setValue(value)}
            />
            <span className={styles["payment-data-section__content-title"]}>
                <FormattedMessage id="payment__data-title" />
            </span>
            <div className={styles["payment-data-section__data-form"]}>
                {employerTypeField.value === EmployerType.Company && (
                    <>
                        <Input
                            disabled={disabled}
                            label={`${intl.formatMessage({ id: "payment__company-name" })}*`}
                            placeholder={`${intl.formatMessage({ id: "payment__company-name" })}*`}
                            {...{ ...companyNameField, ...companyNameMeta }}
                            withValidation
                            isInvalid={!!companyNameMeta.error && !!companyNameMeta.touched}
                            wrapperClassName={styles["payment-data-section__input"]}
                            data-testid="payment-data-section__company-name-input"
                        />
                    </>
                )}
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__first-name" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__first-name" })}*`}
                    {...{ ...firstNameField, ...firstNameMeta }}
                    withValidation
                    isInvalid={!!firstNameMeta.error && !!firstNameMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__first-name-input"
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__last-name" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__last-name" })}*`}
                    {...{ ...lastNameField, ...lastNameMeta }}
                    withValidation
                    isInvalid={!!lastNameMeta.error && !!lastNameMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__last-name-input"
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__tax-identification-number" })}`}
                    placeholder={`${intl.formatMessage({ id: "payment__tax-identification-number" })}`}
                    {...{ ...taxIdentificationNumberField, ...taxIdentificationNumberMeta }}
                    withValidation
                    isInvalid={!!taxIdentificationNumberMeta.error && !!taxIdentificationNumberMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__tax-identification-number-input"
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__phone-number" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__phone-number" })}*`}
                    {...{ ...phoneNumberField, ...phoneNumberMeta }}
                    withValidation
                    isInvalid={!!phoneNumberMeta.error && !!phoneNumberMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__phone-number-input"
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__email" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__email" })}*`}
                    {...{ ...emailField, ...emailMeta }}
                    withValidation
                    isInvalid={!!emailMeta.error && !!emailMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__email-input"
                />
                <FormDropdown<string>
                    data-testid="create-job-offer-modal__offer-length-dropdown"
                    id="create-job-offer-modal__offer-length-dropdown"
                    className={styles["create-job-offer-modal__offer-length-dropdown"]}
                    optionsClassName={styles["create-job-offer-modal__offer-length-dropdown-options"]}
                    options={countryOptions}
                    selected={selectedCountry}
                    onSelect={handleChangeCountry}
                    label={`${intl.formatMessage({ id: "payment__country" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__country" })}*`}
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__city" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__city" })}*`}
                    {...{ ...cityField, ...cityMeta }}
                    withValidation
                    isInvalid={!!cityMeta.error && !!cityMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__phone-number-input"
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__street-name" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__street-name" })}*`}
                    {...{ ...streetField, ...streetMeta }}
                    withValidation
                    isInvalid={!!streetMeta.error && !!streetMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                    data-testid="payment-data-section__phone-number-input"
                />
                <Input
                    disabled={disabled}
                    label={`${intl.formatMessage({ id: "payment__postal-code" })}*`}
                    placeholder={`${intl.formatMessage({ id: "payment__postal-code" })}*`}
                    {...{ ...postalCodeField, ...postalCodeMeta }}
                    withValidation
                    isInvalid={!!postalCodeMeta.error && !!postalCodeMeta.touched}
                    wrapperClassName={styles["payment-data-section__input"]}
                />
                {
                    employerTypeField.value === EmployerType.Private ? (
                        <Checkbox
                            className={styles["payment-data-section__attach-invoice"]}
                            label={`${intl.formatMessage({ id: "payment__attach-invoice" })}*`}
                            checked={attachInvoiceField.value}
                            onToggle={(checked: boolean) => attachInvoiceHandlers.setValue(checked)}
                        />)
                        :
                        null
                }
            </div>
        </div>
    );
};

export default DataSection;
