import {ApiError} from "api/utils";
import AdminPanelAction, {
    amendUsers,
    blockUser,
    deleteUser,
    deleteUserOffer,
    getStatistics,
    getUserOffers,
    getUsers,
    resetUsers,
    unblockUser,
} from "features/adminPanel/actions";
import {mapUserOffersDtoToUserOfferList, mapUsersDtoToUsersList} from "features/adminPanel/mappers";
import {Statistics, User, UserOffer} from "features/adminPanel/model";
import {amendPageToState} from "features/common/utils";
import produce from "immer";
import {getType} from "typesafe-actions";

export interface AdminPanelState {
    statistics?: Statistics;
    statisticsLoading: boolean;
    statisticsLoadingError?: ApiError;
    users?: (User | undefined)[];
    usersLoading: boolean;
    usersLoadingError?: ApiError;
    blockUserSuccess?: boolean;
    blockUserLoading?: boolean;
    blockUserError?: ApiError;
    unblockUserSuccess?: boolean;
    unblockUserLoading?: boolean;
    unblockUserError?: ApiError;
    deleteUserSuccess?: boolean;
    deleteUserLoading?: boolean;
    deleteUserError?: ApiError;
    downloadUsersListSuccess?: boolean;
    downloadUsersListLoading?: boolean;
    downloadUsersListError?: ApiError;
    userOffers?: UserOffer[];
    userOffersLoading?: boolean;
    userOffersError?: ApiError;
    totalUserOffers?: number;
    totalUsers?: number;
    deleteUserOfferSuccess?: boolean;
    deleteUserOfferLoading?: boolean;
    deleteUserOfferError?: ApiError;
}

const reducer = (
    state: AdminPanelState = {
        statisticsLoading: false,
        usersLoading: false,
    },
    action: AdminPanelAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getStatistics.request):
                draft.statistics = undefined;
                draft.statisticsLoading = true;
                draft.statisticsLoadingError = undefined;
                break;
            case getType(getStatistics.success):
                draft.statistics = action.payload;
                draft.statisticsLoading = false;
                draft.statisticsLoadingError = undefined;
                break;
            case getType(getStatistics.failure):
                draft.statistics = undefined;
                draft.statisticsLoading = false;
                draft.statisticsLoadingError = action.payload;
                break;
            case getType(getUsers.request):
                draft.users = undefined;
                draft.usersLoading = true;
                draft.usersLoadingError = undefined;
                draft.totalUsers = undefined;
                draft.blockUserSuccess = undefined;
                draft.deleteUserSuccess = undefined;
                draft.blockUserError = undefined;
                draft.deleteUserError = undefined;
                break;
            case getType(getUsers.success):
                draft.users = mapUsersDtoToUsersList(action.payload);
                draft.usersLoading = false;
                draft.usersLoadingError = undefined;
                draft.totalUsers = action.payload.data.total;
                break;
            case getType(getUsers.failure):
                draft.users = undefined;
                draft.usersLoading = false;
                draft.usersLoadingError = action.payload;
                draft.totalUsers = undefined;
                break;
            case getType(amendUsers.request):
                draft.usersLoading = true;
                draft.usersLoadingError = undefined;
                draft.totalUsers = undefined;
                break;
            case getType(amendUsers.success):
                draft.users = amendPageToState(
                    mapUsersDtoToUsersList(action.payload),
                    action.payload.data.pageable.size,
                    action.payload.data.pageable.pageNumber,
                    state.users
                );
                draft.usersLoading = false;
                draft.usersLoadingError = undefined;
                draft.totalUsers = action.payload.data.total;
                break;
            case getType(amendUsers.failure):
                draft.users = undefined;
                draft.usersLoading = false;
                draft.usersLoadingError = action.payload;
                draft.totalUsers = undefined;
                break;
            case getType(blockUser.request):
                draft.blockUserLoading = true;
                draft.blockUserSuccess = undefined;
                draft.blockUserError = undefined;
                break;
            case getType(blockUser.success):
                draft.blockUserLoading = false;
                draft.blockUserSuccess = true;
                draft.blockUserError = undefined;
                break;
            case getType(blockUser.failure):
                draft.blockUserLoading = false;
                draft.blockUserSuccess = undefined;
                draft.blockUserError = action.payload;
                break;
            case getType(unblockUser.request):
                draft.unblockUserLoading = true;
                draft.unblockUserSuccess = undefined;
                draft.unblockUserError = undefined;
                break;
            case getType(unblockUser.success):
                draft.unblockUserLoading = false;
                draft.unblockUserSuccess = true;
                draft.unblockUserError = undefined;
                break;
            case getType(unblockUser.failure):
                draft.unblockUserLoading = false;
                draft.unblockUserSuccess = undefined;
                draft.unblockUserError = action.payload;
                break;
            case getType(deleteUser.request):
                draft.deleteUserLoading = true;
                draft.deleteUserSuccess = undefined;
                draft.deleteUserError = undefined;
                break;
            case getType(deleteUser.success):
                draft.deleteUserLoading = false;
                draft.deleteUserSuccess = true;
                draft.deleteUserError = undefined;
                break;
            case getType(deleteUser.failure):
                draft.deleteUserLoading = false;
                draft.deleteUserSuccess = undefined;
                draft.deleteUserError = action.payload;
                break;
            case getType(getUserOffers.request):
                draft.userOffersLoading = true;
                draft.userOffers = undefined;
                draft.userOffersError = undefined;
                draft.totalUserOffers = 0;
                draft.deleteUserOfferSuccess = undefined;
                draft.deleteUserOfferError = undefined;
                break;
            case getType(getUserOffers.success):
                draft.userOffersLoading = false;
                draft.userOffers = mapUserOffersDtoToUserOfferList(action.payload);
                draft.userOffersError = undefined;
                draft.totalUserOffers = action.payload.data.total;
                break;
            case getType(getUserOffers.failure):
                draft.userOffersLoading = false;
                draft.userOffers = undefined;
                draft.userOffersError = action.payload;
                draft.totalUserOffers = 0;
                break;
            case getType(deleteUserOffer.request):
                draft.deleteUserOfferLoading = true;
                draft.deleteUserOfferSuccess = undefined;
                draft.deleteUserOfferError = undefined;
                break;
            case getType(deleteUserOffer.success):
                draft.deleteUserOfferLoading = false;
                draft.deleteUserOfferSuccess = true;
                draft.deleteUserOfferError = undefined;
                break;
            case getType(deleteUserOffer.failure):
                draft.deleteUserOfferLoading = false;
                draft.deleteUserOfferSuccess = undefined;
                draft.deleteUserOfferError = action.payload;
                break;
            case getType(resetUsers):
                draft.users = undefined;
                draft.usersLoading = false;
                draft.usersLoadingError = undefined;
                draft.totalUsers = undefined;
                break;
        }
    });
};

export default reducer;
