import {ReactComponent as CloseIcon} from "assets/icons/close.svg";
import {ReactComponent as NoNotifications} from "assets/icons/noNotifications.svg";
import config from "config";
import {format} from "date-fns";
import UserImageWithPlaceholder from "features/common/components/UserImageWithPlaceholder";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {Link, useHistory} from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";
import {MessagesApi} from "features/messages/api";
import classNames from "classnames";

const api = new MessagesApi();

interface Props {
    userProfileExists: boolean;
    isUserLoggedIn?: boolean;
    unreadMessagesCount?: number;
    ["data-testid"]?: string;
    onClickAway: () => void;
    logoutUserAsync: () => void;
    items: any;
    handleNotificationAcknowledged: (notificationId: any) => void;
    setSelectedApplicationId: React.Dispatch<React.SetStateAction<number | null>>
    selectedApplicationId: number | null;
    setClickedNotification: (id: string) => void;
    isMobile?: boolean;
}

const Dropdown = (props: Props) => {
    const {
        userProfileExists,
        isUserLoggedIn,
        "data-testid": testId = "header-dropdown",
        onClickAway,
        logoutUserAsync,
        items: notifications,
        setSelectedApplicationId,
        setClickedNotification,
        isMobile
    } = props;

    const isUserLoggedInWithoutProfile = !!isUserLoggedIn && !userProfileExists;
    return (
        <>
            <ClickAwayListener onClickAway={onClickAway}>
                <ul data-testid={testId}
                    className={classNames(styles["dropdown"], {
                        [styles["dropdown--mobile"]]: isMobile
                    })}
                    onClick={onClickAway}
                >
                    {!isUserLoggedIn && <p></p>}
                    {isUserLoggedInWithoutProfile && (
                        <li data-testid={`${testId}__sign-out-button`} onClick={() => logoutUserAsync()}>
                            <Link to={"#"} className={styles["dropdown__item"]}>
                                <FormattedMessage id="dropdown__sign-out" />
                            </Link>
                        </li>
                    )}
                    {!isUserLoggedInWithoutProfile && (
                        <>
                            {!isMobile && (
                                <li className={styles["dropdown__header"]}>
                                    <div className={styles["my-messages__header-container"]}>
                                        <h2><FormattedMessage id="common__notifications" /></h2>
                                    </div>
                                    <CloseIcon />
                                </li>
                            )}

                            {/* {!!unacknowledgedNotifications.length && (
                            <li className={styles["dropdown__subheader"]}>
                                <CheckedIcon />
                                <div className={styles["my-messages__header-container"]}>
                                    Oznacz wszystkie jako przeczytane
                                </div>
                            </li>
                        )} */}

                            <div
                                className={styles["dropdown__content"]}

                            >
                                {!!notifications.length ? (
                                    notifications.map((notification: any) => (
                                        <DropdownItem
                                            setSelectedApplicationId={setSelectedApplicationId}
                                            handleAcknowledged={props.handleNotificationAcknowledged}
                                            data={notification}
                                            setClickedNotification={setClickedNotification}
                                        />
                                    ))
                                ) : (
                                    <div className={styles["dropdown__content-empty"]}>
                                        <NoNotifications />
                                        <p className={styles["dropdown__content-empty-title"]}>
                                            <FormattedMessage id="common__no_notifications" />
                                        </p>
                                        <p className={styles["dropdown__content-empty-subtitle"]}>
                                            <FormattedMessage id="common__notifications_will_apear_here" />
                                        </p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </ul>
            </ClickAwayListener>
        </>
    );
};

type DropdownItemProps = {
    data: any;
    handleAcknowledged?: (notificationId: string) => void;
    setSelectedApplicationId: React.Dispatch<React.SetStateAction<number | null>>
    setClickedNotification: (id: string) => void;
};

const DropdownItem = ({ data, handleAcknowledged, setSelectedApplicationId, setClickedNotification }: DropdownItemProps) => {
    const history = useHistory();

    const handleOnClick = useCallback(
        async (e) => {
            e.preventDefault();
            setClickedNotification(data.notificationId);

            if (!data.acknowledged && handleAcknowledged) {
                handleAcknowledged(data.notificationId);
            }

            if (data.notificationType === 'NEW_MESSAGE') {
                const res = await api.getConversationId(data.relatedResourceId);
                return history.push(`/messages/${res}`)
            }

            if (data.notificationType === 'RATE_EMPLOYEE_INVITATION') {
                return history.push(`/employee/${data.relatedResourceId}/rate`)
            }

            if (data.notificationType === 'RATE_EMPLOYER_INVITATION') {
                return history.push(`/employer/${data.relatedResourceId}/rate`)
            }

            if (data.notificationType === 'EMPLOYER_RATED') {
                return history.push(`/employer/${data.relatedResourceId}/rate-summary`)
            }

            if (data.notificationType === 'EMPLOYEE_RATED') {
                return history.push(`/employee/${data.relatedResourceId}/rate-summary`)
            }

            if (data.notificationType === 'APPLICATION_REJECTED' || data.notificationType === 'APPLICATION_ACCEPTED') {
                return setSelectedApplicationId(data.relatedResourceId);
            }

            history.push(data.route);
        },
        [data.acknowledged, data.notificationId, data.notificationType, data.relatedResourceId, data.route, handleAcknowledged, history, setClickedNotification, setSelectedApplicationId]
    );

    return (
        <li>
            <div
                onClick={handleOnClick}
                className={styles[`${data.acknowledged ? "dropdown__item" : "dropdown__item-active"}`]}
            >
                <UserImageWithPlaceholder imageUrl={`${config.IMAGE_URL}`} className={styles["dropdown__item-image"]} />
                <div className={styles["dropdown__item-description"]}>{data.message}</div>
                {data.timestamp && <div>{format(new Date(data.timestamp), "dd.MM.yyyy")}</div>}
            </div>
        </li>
    );
};

export default Dropdown;

export { DropdownItem };
