import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    tabs: (string | React.ReactElement)[];
    activeTabIndex: number | string;
    className?: string;
    ["data-testid"]?: string;
    onTabSelect: (index: number) => void;
}

const TabNavigation = ({
    tabs,
    activeTabIndex,
    className,
    "data-testid": testId = "tab-navigation",
    onTabSelect,
}: Props) => {
    const onTabClick = (event: React.MouseEvent, index: number) => {
        onTabSelect(index);
        event.currentTarget.scrollIntoView({ block: "nearest" });
    };

    return (
        <div data-testid={testId} className={classNames(styles["tab-navigation"], className)}>
            {tabs.map((tab, index) => (
                <div
                    key={typeof tab === "string" ? tab : index}
                    data-testid={`${testId}__tab`}
                    className={classNames(styles["tab-navigation__tab"], {
                        [styles["tab-navigation__tab--active"]]: activeTabIndex === index,
                    })}
                    onClick={(event) => {
                        onTabClick(event, index);
                    }}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default TabNavigation;
