/* eslint-disable max-lines */
import Api from "api/api";
import { ApiError, CancelToken } from "api/utils";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import FormDropdown from "features/common/components/FormDropdown";
import Spinner from "features/common/components/Spinner";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { Currency, SalaryType } from "features/common/models";
import { CardType, OfferType, WorkType } from "features/common/types";
import { getLocalizedYup } from "features/common/validators";
import JobOffersApi from "features/jobOffers/api";
import RequirementsStep from "features/jobOffers/components/CreateJobOffer/RequirementsStep";
import { mapCreateJobOfferSchemaToFormData } from "features/jobOffers/mappers";
import { CreateJobOfferRequestObject } from "features/jobOffers/models";
import { CreateJobOfferSchema } from "features/jobOffers/schemas";
import { EducationType, EmployerType, LanguageType } from "features/jobOffers/types";
import { createJobOfferFormValidationSchema } from "features/jobOffers/validators";
import { CardDetails } from "features/myProfile/models";
import AmenitiesStep from "features/offers/components/CreateOffer/AmenitiesStep";
import DetailsStep from "features/offers/components/CreateOffer/DetailsStep";
import FinancialStep from "features/offers/components/CreateOffer/FinancialStep";
import OfferDetailsStep from "features/offers/components/CreateOffer/OfferDetailsStep";
import TextEditor from "features/offers/components/CreateOffer/TextEditor";
import { appRoutes } from "features/routing/routes";
import { PhotoDto } from "features/user/dtos";
import { User } from "features/user/models";
import { Formik, FormikProps } from "formik";
import { useQuery } from "hooks/useQuery";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Cropper } from "react-cropper";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styles from "./styles.module.scss";

const EDITOR_CHARACTER_LIMIT = 2000;

const createOfferLengthDropdownOptions = (intl: IntlShape) => [
    {
        value: "7",
        display: intl.formatMessage({ id: "create-offer__7-days-length" }),
    },
    {
        value: "14",
        display: intl.formatMessage({ id: "create-offer__14-days-length" }),
    },
    {
        value: "30",
        display: intl.formatMessage({ id: "create-offer__30-days-length" }),
    },
    {
        value: "60",
        display: intl.formatMessage({ id: "create-offer__60-days-length" }),
    },
    {
        value: "90",
        display: intl.formatMessage({ id: "create-offer__90-days-length" }),
    },
];

export interface Props {
    isCopying?: boolean;
    id?: number;
    user?: User;
    cardDetails?: CardDetails;
    cardDetailsLoading: boolean;
    cardDetailsError?: ApiError;
    jobOfferCreating?: boolean;
    jobOfferCreatingSuccess?: number;
    jobOfferCreatingError?: ApiError;
    countries?: string[];
    countriesLoading?: boolean;
    countriesError?: ApiError;
    currencies?: Currency[];
    currenciesLoading?: boolean;
    currenciesLoadingError?: ApiError;
    getCardDetailsAsync: (id: string, cancelToken?: CancelToken) => void;
    publishJobOfferAsync: (values: CreateJobOfferRequestObject, cancelToken?: CancelToken) => void;
    getCountriesAsync: (cancelToken?: CancelToken) => void;
    getCurrenciesAsync: (cancelToken?: CancelToken) => void;
    resetJobOfferDetails: () => void;
    resetCreateJobOfferState: () => void;
}

export interface Params {
    businessCardId: string;
    offerLength: string;
    dedicatedUserId?: string;
}

const createInitialCreateJobOfferValues = (
    cardDetails?: CardDetails,
    currencies?: Currency[],
    user?: User,
    jobOfferDetails?: any,
    isCopying?: boolean
): CreateJobOfferSchema => {
    let location: any = "";
    if (jobOfferDetails?.location && isCopying) {
        location = { ...jobOfferDetails?.location };
        delete location?.id;
    } else if (jobOfferDetails?.location) {
        location = { ...jobOfferDetails?.location };
    }
    return {
        offerLength: undefined,
        detailsStep: {
            offerAs:
                cardDetails?.type === CardType.EmployeeCompany || cardDetails?.type === CardType.EmployerCompany
                    ? EmployerType.Company
                    : EmployerType.Private,
            firstName: cardDetails?.firstName || user?.firstName || "",
            lastName: cardDetails?.lastName || user?.lastName || "",
            businessName: cardDetails?.companyName || "",
            country: cardDetails?.country || "",
            location: cardDetails?.locationDTO || "",
            city: cardDetails?.city || "",
            streetName: cardDetails?.street || "",
            streetNumber: cardDetails?.number || "",
            krazCertificateNumber: cardDetails?.krazCert || "",
            taxIdentificationNumber: cardDetails?.taxIdentificationNumber || "",
            phoneNumber: cardDetails?.phoneNumber || "",
            phonePrefix: cardDetails?.phonePrefix || "",
            photos:
                jobOfferDetails?.images?.map((img: string) => ({
                    fileName: img,
                    fileUrl: `${config.IMAGE_URL}/${img}`,
                })) || [],
        },
        offerDetailsStep: {
            position: jobOfferDetails?.position || "",
            branchId: jobOfferDetails?.branch?.id.toString() || "",
            positionsAvailable: jobOfferDetails?.availablePositionsNumber || undefined,
            workType: jobOfferDetails?.workType || WorkType.Steady,
            jobStartDate: jobOfferDetails?.startDate || new Date(),
            jobEndDate: jobOfferDetails?.expirationDate || new Date(),
            location,
        },
        financialStep: {
            salaryType: jobOfferDetails?.financialConditions?.paymentRateType || SalaryType.Monthly,
            salaryMinValue: jobOfferDetails?.financialConditions?.rewardMinimumValue || undefined,
            salaryMaxValue: jobOfferDetails?.financialConditions?.rewardMaximumValue || undefined,
            salaryCurrencyCode: jobOfferDetails?.financialConditions?.rewardCurrencyCode || currencies?.[1]?.code || "",
            bonusValue: jobOfferDetails?.financialConditions?.bonusValue || undefined,
            bonusCurrencyCode: jobOfferDetails?.financialConditions?.bonusCurrencyCode || currencies?.[1]?.code || "",
            bonusType: jobOfferDetails?.financialConditions?.bonusPayoutDateType || undefined,
            attachBonus: !!jobOfferDetails?.financialConditions?.bonusValue,
        },
        additionalInfoStep: {
            additionalInfo: jobOfferDetails?.additionalInfo || "",
        },
        requirementsStep: {
            languages: [
                {
                    id: LanguageType.English,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.English
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.English
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Arabic,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Arabic
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Arabic
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Czech,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Czech
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Czech
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.French,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.French
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.French
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Hindi,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Hindi
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Hindi
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Spanish,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Spanish
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Spanish
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Dutch,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Dutch
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Dutch
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.German,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.German
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.German
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Polish,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Polish
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Polish
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Russian,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Russian
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Russian
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Romanian,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Romanian
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Romanian
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Ukrainian,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Ukrainian
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Ukrainian
                    )?.languageLevelType,
                },
                {
                    id: LanguageType.Italian,
                    isChecked: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Italian
                    )
                        ? true
                        : false,
                    level: jobOfferDetails?.languageRequirements.find(
                        (item: any) => item.languageType === LanguageType.Italian
                    )?.languageLevelType,
                },
            ],
            programs:
                jobOfferDetails?.requirements
                    .find((item: any) => item.requirementType === "COMPUTER_AND_SOFTWARE")
                    ?.valueArray.map((item: any) => ({
                        label: item,
                        value: item,
                    })) || [],
            other:
                jobOfferDetails?.requirements
                    .find((item: any) => item.requirementType === "OTHER")
                    ?.valueArray.map((item: any) => ({
                        label: item,
                        value: item,
                    })) || [],
            education: [
                {
                    id: EducationType.None,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.None.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Primary,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Primary.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Vocational,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Vocational.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Secondary,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Secondary.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.SecondaryTechnical,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.SecondaryTechnical.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Higher,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Higher.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Other,
                    isChecked: jobOfferDetails?.requirements
                        .filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Other.toUpperCase())
                        ? true
                        : false,
                },
            ],
            experience:
                jobOfferDetails?.requirements
                    .filter((item: any) => item.requirementType === "EXPERIENCE")
                    .map((item: any) => item.value) || [],
            permissions:
                jobOfferDetails?.requirements
                    .find((item: any) => item.requirementType === "PERMISSIONS")
                    ?.valueArray.map((item: any) => ({
                        label: item,
                        value: item,
                    })) || [],
            certificates:
                jobOfferDetails?.requirements
                    .find((item: any) => item.requirementType === "CERTIFICATES_AND_LICENSES")
                    ?.valueArray.map((item: any) => ({
                        label: item,
                        value: item,
                    })) || [],
            drivingLicenses:
                jobOfferDetails?.requirements
                    .filter((item: any) => item.requirementType === "DRIVING_LICENSE")
                    .map((item: any) => item.value) || [],

            jobExperience: [],
        },
        amenitiesStep: jobOfferDetails
            ? {
                  accommodation:
                      jobOfferDetails.amenities.find((item: any) => item.amenityType === "accommodation")?.value ||
                      false,
                  assistanceInLegalizingStay: jobOfferDetails.amenities.find(
                      (item: any) => item.amenityType === "assistInLegalizingStay"
                  )?.value
                      ? true
                      : false,
                  catering: jobOfferDetails.amenities.find((item: any) => item.amenityType === "meals")?.value
                      ? true
                      : false,
                  freeMedicalExaminations:
                      jobOfferDetails.amenities.find((item: any) => item.amenityType === "freeMedicalExam")?.value ||
                      false,
                  otherAmenitiesText:
                      jobOfferDetails.amenities.find((item: any) => item.amenityType === "other")?.value || undefined,
                  other: !!jobOfferDetails.amenities.find((item: any) => item.amenityType === "other")?.value,
                  transport: jobOfferDetails.amenities.find((item: any) => item.amenityType === "transport")?.value
                      ? true
                      : false,
                  workBoots:
                      jobOfferDetails.amenities.find((item: any) => item.amenityType === "workBoots")?.value || false,
                  workWear:
                      jobOfferDetails.amenities.find((item: any) => item.amenityType === "workWear")?.value || false,
              }
            : {},
    };
};

const CreateOffer = ({
    isCopying,
    offerId,
    user,
    cardDetails,
    cardDetailsLoading,
    cardDetailsError,
    getCardDetailsAsync,
    jobOfferCreating,
    jobOfferCreatingError,
    jobOfferCreatingSuccess,
    publishJobOfferAsync,
    currencies,
    currenciesLoading,
    getCurrenciesAsync,
    getJobOfferDetailsAsync,
    jobOfferDetails,
    resetJobOfferDetails,
    resetCreateJobOfferState,
}: any) => {
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();
    const { businessCardId, offerLength, dedicatedUserId } = useParams<Params>();
    const [downloadPhotos, setDownloadPhotos] = useState<PhotoDto[]>([]);
    const [didResetCreateJobOfferState, setDidResetCreateJobOfferState] = useState(false);
    const [didCreateOfferWithBonus, setDidCreateOfferWithBonus] = useState(false);
    const [uploadingImages, setUploadingImages] = useState(false);
    const [isDropdownEmpty, setIsDropdownEmpty] = useState<boolean>();
    const [clearedPhotos, setClearedPhotos] = useState<boolean>();
    const formikRef = useRef<FormikProps<CreateJobOfferSchema>>(null);
    const query = useQuery();
    const createdJobOfferId = query.get("createdJobOfferId");
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl);
        return createJobOfferFormValidationSchema(localYup, isCopying);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const initialValues = useMemo(
        () => createInitialCreateJobOfferValues(cardDetails, currencies, user, jobOfferDetails, isCopying),
        [cardDetails, currencies, jobOfferDetails, user, isCopying]
    );

    const changer = (data: boolean) => {
        setClearedPhotos(data);
    };

    useEffect(() => {
        if (getJobOfferDetailsAsync && createdJobOfferId) {
            getJobOfferDetailsAsync(createdJobOfferId, createCancelToken());
        }
    }, [createCancelToken, getJobOfferDetailsAsync]);

    useEffect(() => {
        if (jobOfferCreatingSuccess && didResetCreateJobOfferState) {
            if (didCreateOfferWithBonus) {
                return history.push(`${appRoutes.payment}/${jobOfferCreatingSuccess}`);
            }

            return history.push(`${appRoutes.jobOffers}/${jobOfferCreatingSuccess}`);
        }
    });

    useEffect(() => {
        resetCreateJobOfferState();
    }, [resetCreateJobOfferState]);

    useEffect(() => {
        if (!jobOfferCreatingSuccess) {
            setDidResetCreateJobOfferState(true);
            setDidCreateOfferWithBonus(false);
        }
    }, [jobOfferCreatingSuccess]);

    useEffect(() => {
        if (!offerId) {
            resetJobOfferDetails();
        }
    }, [offerId, resetJobOfferDetails]);

    useEffect(() => {
        if (!offerId || !getJobOfferDetailsAsync) {
            return;
        }

        getJobOfferDetailsAsync(offerId, createCancelToken());
    }, [createCancelToken, getJobOfferDetailsAsync, intl.locale, offerId]);

    useEffect(() => {
        if (businessCardId || jobOfferDetails?.businessCardId) {
            getCardDetailsAsync(businessCardId || jobOfferDetails?.businessCardId, createCancelToken());
        }
    }, [businessCardId, createCancelToken, getCardDetailsAsync, jobOfferDetails]);

    useEffect(() => {
        if (!currencies && !currenciesLoading) {
            getCurrenciesAsync(createCancelToken());
        }
    }, [currencies, createCancelToken, currenciesLoading, getCurrenciesAsync]);

    const handleCancel = useCallback(() => {
        history.push(`${appRoutes.myJobOffers}`);
    }, [history]);

    const handleSubmit = useCallback(
        async (values: CreateJobOfferSchema, formikHelpers) => {
            try {
                const formData = mapCreateJobOfferSchemaToFormData(
                    values,
                    (offerLength || values.offerLength) as string,
                    +businessCardId || jobOfferDetails?.businessCardId,
                    downloadPhotos,
                    dedicatedUserId
                );
                if (clearedPhotos) {
                    formData.jobOfferDetails.images = [];
                    formData.jobOfferDetails.mainImage = "";
                }
                publishJobOfferAsync(formData);
                if (formData.jobOfferDetails.financialConditions.bonusValue) {
                    setDidCreateOfferWithBonus(true);
                }
            } catch (error) {
                console.warn({ error });
            }
        },
        [businessCardId, dedicatedUserId, jobOfferDetails, offerLength, publishJobOfferAsync, downloadPhotos]
    );

    const handleEditJobOffer = useCallback(
        async (values: CreateJobOfferSchema) => {
            try {
                const imageForm = new FormData();
                setUploadingImages(true);
                values.detailsStep.photos.forEach((photo) => !!photo.file && imageForm.append("images", photo.file));
                const uploadedImages = values.detailsStep.photos.some((el) => el.file)
                    ? await JobOffersApi.uploadUserPhotos(imageForm)
                    : [];

                const formData = mapCreateJobOfferSchemaToFormData(
                    values,
                    offerLength,
                    +jobOfferDetails?.businessCardId,
                    [...uploadedImages]
                );

                await Api.put(
                    `${config.apiRoutes.OFFERS}/${offerId ? offerId : createdJobOfferId}`,
                    formData.jobOfferDetails
                );
                if (!jobOfferDetails?.isPaid && formData.jobOfferDetails.financialConditions.bonusValue) {
                    return history.push(`${appRoutes.payment}/${offerId ? offerId : createdJobOfferId}`);
                }
                return history.push(`${appRoutes.jobOffers}/${offerId ? offerId : createdJobOfferId}`);
            } catch (error) {
                console.warn({ error });
            }
        },
        [history, jobOfferDetails, offerId, offerLength]
    );

    const offerLengthDropdownOptions = useMemo(() => createOfferLengthDropdownOptions(intl), [intl]);

    if (cardDetailsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "create-job-offer__loading-card-details" })} />;
    }

    return (
        <div className={styles["create-job-offer"]}>
            {jobOfferCreating && (
                <SpinnerWithMessage className={styles["create-job-offer__loading-offer-details-modal"]} />
            )}
            {cardDetailsError ? (
                <div className={styles["create-job-offer__error-container"]}>
                    <FormattedMessage id="create-job-offer__card-details-loading-error" />
                </div>
            ) : (
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnMount
                    enableReinitialize
                >
                    {({ values, errors, touched, isValid, setFieldValue, handleSubmit, isSubmitting, submitCount }) => (
                        <>
                            {(() => {
                                if (!!Object.keys(errors).length && !isValid && isSubmitting) {
                                    document
                                        .getElementById(Object.keys(errors)[0])
                                        ?.scrollIntoView({ behavior: "smooth", block: "end" });
                                }
                            })()}
                            <div className={styles["create-job-offer__section"]} id="offerLength">
                                <div className={styles["create-job-offer__header"]}>
                                    {isCopying ? (
                                        <FormattedMessage id="create-job-offer-modal__copy-header" />
                                    ) : (
                                        <FormattedMessage
                                            id="create-job-offer-modal__header"
                                            values={{ stepNumber: 2 }}
                                        />
                                    )}
                                </div>
                                {!!isCopying && (
                                    <FormDropdown<string>
                                        data-testid="create-job-offer__offer-length-dropdown"
                                        id="create-job-offer__offer-length-dropdown"
                                        className={styles["create-job-offer__offer-length-dropdown"]}
                                        optionsClassName={styles["create-job-offer__offer-length-dropdown-options"]}
                                        options={offerLengthDropdownOptions}
                                        selected={offerLengthDropdownOptions.find(
                                            (item) => item.value === values.offerLength
                                        )}
                                        onSelect={(value: string) => setFieldValue("offerLength", value)}
                                        label={intl.formatMessage({ id: "create-offer__offer-length" })}
                                        placeholder={intl.formatMessage({ id: "create-offer__offer-length" })}
                                        error={errors.offerLength}
                                        isInvalid={submitCount > 0}
                                        withValidation
                                    />
                                )}
                                <div className={styles["create-job-offer__title"]}>
                                    <FormattedMessage id="create-job-offer__details-section-title" />
                                </div>
                                <DetailsStep
                                    uploadingImages={uploadingImages}
                                    setUploadingImages={setUploadingImages}
                                    initialPhotos={initialValues.detailsStep.photos}
                                    setDownloadPhotos={setDownloadPhotos}
                                    isCopyOrIsEdit={!!offerId || !!isCopying}
                                    values={values.detailsStep}
                                    setIsDropdownEmpty={setIsDropdownEmpty}
                                    changer={changer}
                                />
                                {uploadingImages && (
                                    <p className={styles["create-job-offer__loading"]}>
                                        <FormattedMessage id="create-offer__adding-images" />
                                        <Spinner />
                                    </p>
                                )}
                            </div>

                            <div className={styles["create-job-offer__section"]}>
                                <div className={styles["create-job-offer__title"]}>
                                    <FormattedMessage id="create-job-offer__offer-details-section-title" />
                                </div>
                                <OfferDetailsStep values={values.offerDetailsStep} />
                            </div>
                            <div className={styles["create-job-offer__section"]}>
                                <div className={styles["create-job-offer__title"]}>
                                    <FormattedMessage id="create-job-offer__finance-section-title" />
                                </div>
                                <FinancialStep
                                    values={values.financialStep}
                                    disableBonus={
                                        (jobOfferDetails?.isPaid && !!initialValues.financialStep.bonusValue) ||
                                        (jobOfferDetails?.offerStatus === "ACTIVE" && !isCopying)
                                    }
                                />
                            </div>
                            <div className={styles["create-job-offer__section"]}>
                                <div className={styles["create-job-offer__title"]}>
                                    <FormattedMessage id="create-job-offer__requirements-section-title" />
                                </div>
                                <RequirementsStep offerType={OfferType.Job} />
                            </div>
                            <div className={styles["create-job-offer__section"]}>
                                <div className={styles["create-job-offer__title"]}>
                                    <FormattedMessage id="create-job-offer__amenities-section-title" />
                                </div>
                                <AmenitiesStep />
                            </div>
                            <div className={styles["create-job-offer__section"]}>
                                <div className={styles["create-job-offer__title"]}>
                                    <FormattedMessage id="create-job-offer__offer-content-title" />
                                </div>
                                <TextEditor
                                    initialText={initialValues.additionalInfoStep.additionalInfo}
                                    handleChange={(value: string) => {
                                        setFieldValue("additionalInfoStep.additionalInfo", value);
                                    }}
                                    maxLength={EDITOR_CHARACTER_LIMIT}
                                    maxLengthTextComponent={
                                        <FieldMessages
                                            messages={[
                                                intl.formatMessage(
                                                    { id: "create-offer__maximum-characters" },
                                                    { amount: EDITOR_CHARACTER_LIMIT }
                                                ),
                                            ]}
                                            mode="error"
                                        />
                                    }
                                    additionalInfoTextComponent={
                                        <FieldMessages
                                            messages={[
                                                intl.formatMessage(
                                                    { id: "create-offer__maximum-characters" },
                                                    { amount: EDITOR_CHARACTER_LIMIT }
                                                ),
                                            ]}
                                            mode="info"
                                        />
                                    }
                                />
                            </div>
                            <div
                                className={classNames(
                                    styles["create-job-offer__section"],
                                    styles["create-job-offer__small-padding-section"]
                                )}
                            >
                                <div className={styles["create-job-offer__buttons-section"]}>
                                    <Button
                                        variant="no-background"
                                        data-testid="create-job-offer__cancel-button"
                                        onClick={handleCancel}
                                        disabled={jobOfferCreating}
                                    >
                                        <FormattedMessage id="common__cancel" />
                                    </Button>

                                    {offerId && !isCopying ? (
                                        <Button
                                            type="submit"
                                            data-testid="create-job-offer__next-button"
                                            onClick={() => handleEditJobOffer(values)}
                                            disabled={jobOfferCreating || uploadingImages}
                                            isLoading={jobOfferCreating || uploadingImages}
                                        >
                                            <FormattedMessage id="common__save" />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            data-testid="create-job-offer__next-button"
                                            onClick={
                                                !!createdJobOfferId
                                                    ? () => handleEditJobOffer(values)
                                                    : () => handleSubmit()
                                            }
                                            disabled={jobOfferCreating || uploadingImages}
                                            isLoading={jobOfferCreating || uploadingImages}
                                        >
                                            <FormattedMessage id="common__next" />
                                        </Button>
                                    )}
                                </div>
                                {jobOfferCreatingError && (
                                    <FieldMessages
                                        className={styles["create-job-offer__error-messages"]}
                                        messages={[
                                            jobOfferCreatingError?.response?.data?.message ===
                                            "specified location was not found!"
                                                ? intl.formatMessage({ id: "create-offer__location-error" })
                                                : intl.formatMessage({ id: "create-offer__unknown-error" }),
                                        ]}
                                        mode="error"
                                    />
                                )}
                            </div>
                        </>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default CreateOffer;
