import Datepicker from "features/common/components/Datepicker";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import React, { useState } from "react";
import styles from "./styles.module.scss";

const Example = () => {
    const [date, setDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [datepickerDate, setDatepickerDate] = useState<Date>();

    const onDateSelect = (startDate?: Date, endDate?: Date) => {
        setDate(startDate);
        setEndDate(endDate);
    };

    const onDatepickerDateSelect = (date?: Date) => {
        setDatepickerDate(date);
    };

    return (
        <div className={styles["example"]}>
            <Datepicker
                id="rangepicker"
                className={styles["example__rangepicker"]}
                withRangePicker
                date={date}
                endDate={endDate}
                label="Data początkowa*"
                endDateLabel="Data końcowa*"
                placeholder="Data początkowa*"
                endDatePlaceholder="Data końcowa*"
                error={!date ? "Data początkowa jest wymagana" : undefined}
                endDateError={!endDate ? "Data końcowa jest wymagana" : undefined}
                helperMessages={["Wiadomość pomocnicza", <FieldRequiredMessage />]}
                endDateHelperMessages={["Wiadomość pomocnicza", <FieldRequiredMessage />]}
                onDateSelect={onDateSelect}
            />
            <Datepicker
                id="datepicker"
                className={styles["example__datepicker"]}
                date={datepickerDate}
                label="Data początkowa*"
                placeholder="Data początkowa*"
                error={!datepickerDate ? "Data początkowa jest wymagana" : undefined}
                helperMessages={["Wiadomość pomocnicza", <FieldRequiredMessage />]}
                onDateSelect={onDatepickerDateSelect}
            />
        </div>
    );
};

export default Example;
