import { CardType, DropdownOption, LocationOption } from "features/common/types";
import { phoneNumberValidator } from "features/common/validators";
import { AmenitiesStepSchema, DetailsStepSchema } from "features/offers/schemas";
import * as Yup from "yup";

export const getYourCardStepValidationSchema = (yup: any) =>
    yup.object({
        type: yup.string().required(),
        selectedCompany: yup.object().when("type", {
            is: (val: CardType) => val === CardType.EmployeeCompany || val === CardType.EmployerCompany,
            then: yup.object().required(),
        }),
        companyName: yup
            .string()
            .nullable()
            .when("selectedCompany", {
                is: (val: DropdownOption<string>) => val?.value === "-1",
                then: yup.string().trim().required(),
            }),
        location: yup.object().shape({
            input: yup.string().required(),
            option: yup.object().when("input", {
                is: (val: string) => val && val.length,
                then: yup.object().shape({
                    display: yup.string(),
                    value: yup.string().required(),
                }),
            }),
        }),
        street: yup.string().when("streetNumber", {
            is: (val: string) => !!val,
            then: yup.string().trim().required(),
        }),
        phoneNumber: phoneNumberValidator(yup).required(),
    });

export const getAmenitiesStepValidationSchema = (yup: typeof Yup) =>
    yup.object<AmenitiesStepSchema>({
        // transport: yup.boolean(),
        // catering: yup.boolean(),
        // accommodation: yup.boolean(),
        // workingClothing: yup.boolean(),
        // workFootwear: yup.boolean(),
        // freeMedicalExaminations: yup.boolean(),
        // assistanceInLegalizingStay: yup.boolean(),
        // otherAmenities: yup.boolean().default(false),
        // otherAmenitiesText: yup
        //     .string()
        //     .default("")
        //     .when("otherAmenities", {
        //         is: true,
        //         then: yup.string().required().max(200),
        //     }),
    }) as Yup.Schema<AmenitiesStepSchema>;

export const getCommonDetailsStepValidationSchema = (yup: typeof Yup) =>
    ({
        position: yup.string().trim().required(),
        branchCode: yup.string().required(),
        workType: yup.string().required(),
        location: yup.object<LocationOption<string>>({
            input: yup.string().required().min(1),
            option: yup.mixed<DropdownOption<string>>().when("input", {
                is: (val: string) => val && val.length,
                then: yup.object().shape({
                    display: yup.string(),
                    value: yup.string().required(),
                }),
            }),
            section: yup.string(),
        }),
    } as Yup.ObjectSchemaDefinition<DetailsStepSchema>);
