import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import classNames from "classnames";
import Button, { ButtonVariant } from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import Modal from "features/common/components/Modal";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    messageSetter?: string,
    header?: string | JSX.Element;
    content: JSX.Element | React.ReactNode;
    tertiaryButtonContent?: string | JSX.Element;
    secondaryButtonContent?: string | JSX.Element;
    primaryButtonContent?: string | JSX.Element;
    parentId?: string;
    className?: string;
    contentClassName?: string;
    modalContainerClassName?: string;
    label?: string;
    isScrollable?: boolean;
    primaryButtonLoading?: boolean;
    primaryButtonDisabled?: boolean;
    cancelButtonHidden?: boolean;
    error?: string;
    ["data-testid"]?: string;
    secondaryAction?: () => void;
    primaryAction?: () => void;
    onClose: () => void;
    secondaryButtonVariant?: ButtonVariant;
    primaryButtonVariant?: ButtonVariant;
    buttonsCentered?: boolean;
    stream?: MediaStream | null;
    noButtons?: boolean;
}

const ModalWithContent = ({
    parentId,
    className,
    contentClassName,
    modalContainerClassName,
    header,
    label,
    content,
    tertiaryButtonContent,
    secondaryButtonContent,
    primaryButtonContent,
    isScrollable,
    primaryButtonLoading,
    primaryButtonDisabled,
    cancelButtonHidden,
    error,
    "data-testid": testId = "modal-with-content",
    secondaryAction,
    primaryAction,
    onClose,
    secondaryButtonVariant,
    primaryButtonVariant,
    buttonsCentered,
    stream,
    noButtons
}: Props) => {
    const deviceClass = useDeviceClass();
    return (
        <Modal
            parentId={parentId}
            className={classNames(styles["modal-with-content__modal"], className)}
            containerClassName={modalContainerClassName}
            data-testid={testId}
        >
            <>
                <div className={classNames(styles["modal-with-content__header"], { [styles["modal-with-content__header--withMarginTop"]]: !!header })}>
                    <div className={styles["modal-with-content__header-title"]}>
                        {header}
                    </div>
                    <div
                        className={styles["modal-with-content__header-close-button"]}
                        data-testid={`${testId}__close-icon`}
                        onClick={onClose}
                    >
                        {!noButtons && <TimesIcon />}
                    </div>
                </div>
                <div
                    data-testid={`${testId}__content-container`}
                    className={classNames(styles["modal-with-content__container"], {
                        [styles["modal-with-content__container--scrollable-content"]]: isScrollable,
                    })}
                >
                    {label && <div className={styles["modal-with-content__container-label"]}>{label}</div>}
                    <div className={contentClassName}>{content}</div>
                </div>
                {isScrollable && (
                    <div
                        data-testid={`${testId}__scroll-border`}
                        className={styles["modal-with-content__scroll-border"]}
                    />
                )}
                <div className={classNames(styles["modal-with-content__control-buttons-container"], { [styles['modal-with-content__control-buttons-container--centered']]: buttonsCentered })}>
                    <>
                        {!!tertiaryButtonContent && tertiaryButtonContent}
                        {!cancelButtonHidden && !noButtons && (
                            <Button
                                variant={secondaryButtonVariant ? secondaryButtonVariant : deviceClass === "desktop" ? "tertiary" : "no-background"}
                                data-testid={`${testId}__cancel-button`}
                                onClick={secondaryAction ?? onClose}
                                disabled={!stream}
                            >
                                {secondaryButtonContent}
                            </Button>
                        )}
                        {!noButtons && (
                            <Button
                                type="submit"
                                variant={primaryButtonVariant ? primaryButtonVariant : deviceClass === "desktop" ? undefined : "no-background"}
                                data-testid={`${testId}__action-button`}
                                onClick={primaryAction}
                                isLoading={primaryButtonLoading}
                                disabled={primaryButtonDisabled}
                            >
                                {primaryButtonContent}
                            </Button>
                        )}

                    </>
                </div>
                {!!error && (<div className={styles["modal-with-content__error-message"]}>
                    <FieldMessages messages={[error]} mode="error" />
                </div>)}
            </>
        </Modal>
    );
};
export default ModalWithContent;
