import { useEffect, useRef } from "react";

const usePrevious = <T extends any>(value: T, initialValue?: T) => {
    const ref = useRef<T | undefined>(initialValue);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default usePrevious;
