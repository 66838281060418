import React from "react";
import { useField } from "formik";

import { Language } from "features/jobOffers/types";
import LanguageRequirementComponent from "features/jobOffers/components/CreateJobOffer/RequirementsStep/LanguageRequirement";

export interface Props {
    name: string;
    header: string;
    label: string;
}

const LanguageRequirement = ({ name, header, label }: Props) => {
    const [fieldProps, metaProps, helperProps] = useField<Language[]>(name);

    return (
        <LanguageRequirementComponent
            data-testid={`requirements-step__${name}`}
            header={header}
            label={label}
            languagesValueInputProps={fieldProps}
            languagesFieldMeta={metaProps}
            languagesHelperProps={helperProps}
        />
    );
};

export default LanguageRequirement;
