import { ApiError, CancelToken } from "api/utils";
import ModalWithContent from "features/common/components/ModalWithContent";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { OfferType } from "features/common/types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    endingOffer: boolean;
    endOfferSuccess?: boolean;
    endingOfferError?: ApiError;
    offerId?: number;
    offerType: OfferType;
    ["data-testid"]?: string;
    onCancel: () => void;
    onConfirm: () => void;
    endOfferAsync: (id: number, offerType: OfferType, cancelToken?: CancelToken) => void;
}

const EndOfferModal = ({
    endingOffer,
    endOfferSuccess,
    endingOfferError,
    offerId,
    offerType,
    "data-testid": testId = "end-offer",
    onCancel,
    onConfirm,
    endOfferAsync,
}: Props) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

    const createCancelToken = useCreateCancelToken();

    const onEndOfferConfirm = () => {
        offerId && endOfferAsync(offerId, offerType, createCancelToken());
    };

    const onConfirmSuccess = () => {
        setIsSuccessModalOpen(false);
        onConfirm();
    };

    useEffect(() => {
        if (endOfferSuccess) {
            setIsSuccessModalOpen(true);
        }
    }, [endOfferSuccess]);

    useEffect(() => {
        if (endingOfferError) {
            onCancel();
        }
    }, [endingOfferError, onCancel]);

    return !isSuccessModalOpen ? (
        <div className={styles["end-offer-modal"]}>
            <ModalWithContent
                className={styles["end-offer-modal__container"]}
                data-testid={testId}
                contentClassName={styles["end-offer-modal__content"]}
                header={<FormattedMessage id="offers__end-offer-header" />}
                content={<FormattedMessage id="offers__end-offer-info" />}
                primaryButtonContent={<FormattedMessage id="offers__end-offer-confirm" />}
                secondaryButtonContent={<FormattedMessage id="common__cancel" />}
                onClose={onCancel}
                primaryAction={onEndOfferConfirm}
                primaryButtonDisabled={endingOffer}
                primaryButtonLoading={endingOffer}
            />
        </div>
    ) : (
        <div className={styles["end-offer-modal"]}>
            <ModalWithContent
                className={styles["end-offer-modal__container"]}
                data-testid={`${testId}__success`}
                contentClassName={styles["end-offer-modal__content"]}
                header={
                    <SuccessHeader>
                        <FormattedMessage id="offers__end-offer-success-header" />
                    </SuccessHeader>
                }
                content={<FormattedMessage id="offers__end-offer-success-info" />}
                primaryButtonContent={<FormattedMessage id="common__ok" />}
                onClose={onConfirmSuccess}
                primaryAction={onConfirmSuccess}
                cancelButtonHidden
            />
        </div>
    );
};

export default EndOfferModal;
