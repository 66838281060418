import Spinner from "features/common/components/Spinner";
import { appRoutes } from "features/routing/routes";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const UnauthorizedAccess = () => {
    const history = useHistory();

    useEffect(() => {
        history.push(appRoutes.dashboard);
    }, [history]);

    return <Spinner data-testid="unauthorized-access-page" />;
};

export default UnauthorizedAccess;
