import { DeviceClass } from "features/common/types";
import React from "react";

interface Props {
    children: React.ReactElement;
    deviceClass: DeviceClass;
    className: string;
    ["data-testid"]: string;
}

const MobileBackdrop = ({ children, deviceClass, className, "data-testid": testId }: Props) =>
    deviceClass !== "desktop" ? (
        <div data-testid={testId} className={className}>
            {children}
        </div>
    ) : (
        children
    );

export default MobileBackdrop;
