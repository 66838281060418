import { closeContactFormConfirmationDialog } from "features/contactForm/actions";
import {
    selectContactFormMessageSending,
    selectContactFormMessageSendingSuccess,
} from "features/contactForm/selectors";
import { sendContactFormMessageAsync } from "features/contactForm/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import ContactForm from "./component";

const mapStateToProps = (state: RootState) => ({
    contactFormMessageSending: selectContactFormMessageSending(state),
    contactFormMessageSendingSuccess: selectContactFormMessageSendingSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            sendContactFormMessageAsync,
            closeContactFormConfirmationDialog: () => closeContactFormConfirmationDialog(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
