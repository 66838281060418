import classNames from "classnames";
import FieldMessages from "features/common/components/FieldMessages";
import FormDropdown from "features/common/components/FormDropdown";
import Input from "features/common/components/Input";
import { DropdownOption, InputType } from "features/common/types";
import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";

export interface Props {
    title?: string;
    id: string;
    valueInputLabel: string;
    unitInputLabel: string;
    dropdownOptions: DropdownOption<string>[];
    className?: string;
    titleClassName?: string;
    inputsContainerClassName?: string;
    valueFieldValue?: string;
    valueFieldError?: string;
    valueFieldTouched?: boolean;
    valueFieldPattern?: string;
    unitFieldValue?: string;
    unitFieldError?: string;
    unitFieldTouched?: boolean;
    withValidation?: boolean;
    helperMessages?: (string | React.ReactElement)[];
    ["data-testid"]?: string;
    disabled?: boolean;
    inputType?: InputType;
    valueFieldSetValue: (value: string) => void;
    unitFieldSetValue: (value: string) => void;
    valueFieldSetTouched?: (touched: boolean) => void;
    unitFieldSetTouched?: (touched: boolean) => void;
    onUnitValueChanged?: () => void;
    onToggle?: (expanded: boolean) => void;
    inputContainerClassName?: string;
    formDropdownClassName?: string;
    noCurrency?: boolean;
}

const JoinedInput = ({
    title,
    id,
    valueInputLabel,
    unitInputLabel,
    dropdownOptions,
    className,
    titleClassName,
    inputsContainerClassName,
    valueFieldValue,
    valueFieldError,
    valueFieldTouched,
    valueFieldPattern,
    unitFieldValue,
    unitFieldError,
    unitFieldTouched,
    inputType,
    withValidation,
    disabled,
    helperMessages,
    "data-testid": testId,
    valueFieldSetTouched,
    valueFieldSetValue,
    unitFieldSetTouched,
    unitFieldSetValue,
    onToggle,
    inputContainerClassName,
    formDropdownClassName
}: Props) => {
    const shouldShowValueError = !!withValidation && !!valueFieldTouched && !!valueFieldError;
    const shouldShowUnitError = !!withValidation && !!unitFieldTouched && !!unitFieldError;
    const [isWrong, setWrong] = useState<boolean>(false)
    const [rememberCurrency, setRememberCurrency] = useState<string>("")

    const errorMessages = useMemo<string[]>(
        () =>
            [shouldShowValueError ? valueFieldError! : "", shouldShowUnitError ? unitFieldError! : ""].filter(
                (error) => !!error
            ),
        [shouldShowUnitError, shouldShowValueError, unitFieldError, valueFieldError]
    );

    return (
        <div className={classNames(styles["joined-input"], className)}>
            {title && <h2 className={classNames(styles["joined-input__title"], titleClassName)}>{title}</h2>}
            <div className={classNames(styles["joined-input__inputs-container"], inputsContainerClassName)}>
                <Input
                    className={styles["joined-input__inputs-container-input"]}
                    data-testid={testId ? `${testId}__input` : undefined}
                    label={valueInputLabel}
                    placeholder={valueInputLabel}
                    onKeyDown={() => {
                        valueFieldSetTouched && valueFieldSetTouched(true);
                    }}
                    onBlur={() => {
                        valueFieldSetTouched && valueFieldSetTouched(true);
                        unitFieldSetTouched && unitFieldSetTouched(true);
                    }}
                    value={valueFieldValue}
                    onChange={(e) => {
                        valueFieldSetValue(e.currentTarget.value);
                        if (e.target.value !== "" && unitFieldValue === undefined) {
                            setWrong(true)
                        }
                        else if (e.target.value === "" && unitFieldValue === undefined) {
                            setWrong(false)
                        }
                        else if (unitFieldValue !== undefined) {
                            setWrong(false)
                        }
                    }}
                    type={inputType}
                    withValidation={withValidation}
                    isInvalid={!!valueFieldTouched && !!valueFieldError}
                    pattern={valueFieldPattern}
                    containerClassName={classNames(
                        styles["joined-input__input-field-wrapper"],
                        inputContainerClassName
                    )}
                    error={valueFieldError}
                    disabled={disabled}
                />
                <FormDropdown
                    data-testid={testId ? `${testId}__dropdown` : undefined}
                    label={unitInputLabel}
                    selected={unitFieldValue ? { value: unitFieldValue } : undefined}
                    onSelect={(e) => {
                        unitFieldSetValue && unitFieldSetValue(e)
                        console.log(valueFieldValue)
                        if (e !== undefined) {
                            setWrong(false)
                        }
                        if (rememberCurrency === e && valueFieldValue !== undefined) {
                            setWrong(true)
                            setRememberCurrency("")
                        }
                        else if (rememberCurrency !== e) {
                            setWrong(false)
                            setRememberCurrency(e)
                        }
                    }}
                    placeholder={unitInputLabel}
                    id={id}
                    options={dropdownOptions}
                    onToggle={(expanded) => {
                        onToggle && onToggle(expanded);
                        if (!expanded) {
                            valueFieldSetTouched && valueFieldSetTouched(true);
                            unitFieldSetTouched && unitFieldSetTouched(true);
                        }
                    }}
                    className={styles["joined-input__unit-input"]}
                    withValidation={withValidation}
                    noCurrency={isWrong}
                    isInvalid={!!unitFieldTouched && !!unitFieldError}
                    formDropdownClassName={formDropdownClassName}
                    error={unitFieldError}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default JoinedInput;
