/* eslint-disable max-lines */
import { ReactComponent as GoToDetailsIcon } from "assets/icons/goto.svg";
import HeartIcon from "assets/icons/heart-icon-off.svg";
import HeartFilled from "assets/icons/HeartFilled.svg";
import { ReactComponent as OffersLocationMarkerIcon } from "assets/icons/offersLocationMarker.svg";
import DefaultImgMobile from "assets/images/default-img-JobOffrs-list-mobile.png";
import DefaultImgTablet from "assets/images/default-img-JobOffrs-list-tablet.png";
import config from "config";
import useDateFormat from "features/common/hooks/useDateFormat";
import { FavoriteEmployeeOrEmployer } from "features/favorite/models";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import FavoriteAPI from "../../../favorite/api";
import styles from "./styles.module.scss";

interface Props {
    item: FavoriteEmployeeOrEmployer
}

const FavoriteItem = ({
    item
}: Props) => {
    const { main_photo, id, business_name, locationDTO: location, firstname, lastname, logo, type, isPersonal, averageRates, addedDate, numberRates } = item;
    const [isFilled, setIsFilled] = useState(true)
    const intl = useIntl();
    const history = useHistory();
    const getFormattedDate = useDateFormat();
    const isMatches = useMediaQuery('(min-width: 834px)');
    const DefaultImage = isMatches ? DefaultImgTablet : DefaultImgMobile;
    const posterPath = item.main_photo ? `${config.IMAGE_URL}/${main_photo}` : DefaultImage;
    const openOfferDetails = () => history.push(`${appRoutes.profileCard}/${id}`);

    const formattedLocation = `${location.city ? `${location.city}, ` : ''}${intl.formatMessage({
        id: `common__country-${location.country}`,
        defaultMessage: location.country,
    })}`

    // Change api to delete and add to favorite for the empoyee and employer
    const onFavoriteHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (id) {
            if (isFilled) {
                type === 'EMPLOYEE' ? await FavoriteAPI.removeEmployeeFromFavorite(id) : await FavoriteAPI.removeEmployerFromFavorite(id);
                setIsFilled(false);
            } else {
                type === 'EMPLOYEE' ? await FavoriteAPI.addEmployeeToFavorite(id) : await FavoriteAPI.addEmployerToFavorite(id);
                setIsFilled(true);
            }
        }
    }

    return (
        <div className={styles["offer-item"]}>
            <ImageContainer>
                <Image src={posterPath} alt='poster' />
                {logo && <Image companyLogo src={`${config.IMAGE_URL}/${logo}`} />}
            </ImageContainer>
            <MainContent>
                <InnerContainer>
                    <CompanyHeader>
                        <p className={styles['offer-item__name']} onClick={openOfferDetails}>
                            {isPersonal ? `${firstname} ${lastname}` : business_name}
                        </p>
                        <ReviewsContainer>
                            <AverageReview>
                                <span>{averageRates}</span>
                            </AverageReview>

                            <NumberOfReviews>
                                <FormattedMessage id='common__rating' values={{ rating: numberRates }} />
                            </NumberOfReviews>

                            <StyledHeartBtn onClick={onFavoriteHandler} type="button">
                                <img src={isFilled ? HeartFilled : HeartIcon} alt="favorite" />
                            </StyledHeartBtn>
                        </ReviewsContainer>
                    </CompanyHeader>

                    <DetailsContainer>
                        <FavoriteDetails>
                            {formattedLocation && (
                                <DetailsEntry>
                                    <OffersLocationMarkerIcon />
                                    <LocationDescr>{formattedLocation}</LocationDescr>
                                </DetailsEntry>
                            )}
                        </FavoriteDetails>
                    </DetailsContainer>
                </InnerContainer>
                <div className={styles["offer-item__offer-footer"]}>
                    <div>
                        <FormattedMessage
                            id="offers-item__offer-added"
                        />
                        <span>{getFormattedDate(new Date(addedDate))}</span>
                    </div>
                    <div onClick={openOfferDetails}>
                        <FormattedMessage id="offers-item__go-to-offer" />
                        <StyledGoToDetailsIcon />
                    </div>
                </div>
            </MainContent>
        </div>
    );
};

export default FavoriteItem;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.2em;
    height: 100%;
`;

const CompanyHeader = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1rem;

    @media screen and (min-width: 834px){
        align-items: center;
        height: 2rem;
    }
`;

const ReviewsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const AverageReview = styled.div`
    background-color: #002677;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 0.4rem;
    height: 1.4rem;
    width: 1.4rem;
    font-size: 0.6rem;
`;

const NumberOfReviews = styled.p`
    color: #002677;
    font-size: 0.6rem;
    margin-right: 0.4rem;
`;

const DetailsContainer = styled.div`
    padding-bottom: 0.5em;
    width: 100%;
    margin-top: auto
`;
const FavoriteDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 1.5em;
`;

const DetailsEntry = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1rem;

    svg {
        width: 1em;
        height: 1em;
        margin-left: -3px;
        margin-right: 0.3em;
    }
`;

const ImageContainer = styled.div`
position: relative;
    width: 4.625rem;  
    height: 10.625rem;  
    margin-right: 1rem;
    cursor: pointer;
    display: flex;
    
    @media screen and (min-width: 834px) {
        width: 13.25rem;
        margin-right: 1.625rem;
        height: unset; 
    }
`;
interface IImageProps {
    companyLogo?: boolean;
}

const Image = styled.img<IImageProps>`
    display: block;
    width: 100%;
    background-position: center;
    height: 10.625rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    @media screen and (min-width: 834px) {
        width: 13.25rem;
        height: auto;
    }
    ${({ companyLogo }) => companyLogo && css`
        height: 3.75rem;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        border-bottom-left-radius: 0;
        @media screen and (min-width: 834px) {
            width: 4.188rem;
            height: 3.375rem;
            border-radius: 5px;
        }
    `}
`;

const StyledHeartBtn = styled.button`
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
`;

const LocationDescr = styled.span`
    font-size: 0.75rem;
    line-height: 2;
    letter-spacing: 0.1px;
    color: #002677;
`;

const StyledGoToDetailsIcon = styled(GoToDetailsIcon)`
    margin-right: 0;
`;