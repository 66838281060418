import classNames from "classnames";
import FieldMessages from "features/common/components/FieldMessages";
import { getStringLengthWarningMessage } from "features/common/translationMessages";
import { ValidationProps } from "features/common/types";
import React, { ChangeEvent } from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["textarea"]> &
    ValidationProps & {
        ["data-testid"]?: string;
        className?: string;
        value?: string;
        placeholder?: string;
        rows?: number;
        label?: string;
        id?: string;
        maxSize?: number;
        helperMessages?: (string | React.ReactElement)[];
        customWarningMessage?: string;
    };

const TextArea = ({
    "data-testid": testId,
    className,
    value,
    placeholder,
    rows,
    label,
    id,
    maxSize,
    name,
    error,
    isInvalid,
    withValidation,
    helperMessages,
    customWarningMessage,
    onChange,
    onBlur,
}: Props) => {
    const intl = useIntl();

    const warningMessage = getStringLengthWarningMessage(intl, maxSize);

    const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange && onChange(event);
    };

    return (
        <div className={classNames(styles["textarea"], className)} data-testid={testId}>
            {label && (
                <div className={styles["textarea__label"]} data-testid={`${testId}__label`}>
                    {label}
                </div>
            )}
            <textarea
                id={id}
                name={name}
                className={classNames(styles["textarea__input"], {
                    [styles["textarea__input--error"]]: withValidation && error && isInvalid,
                })}
                value={value}
                rows={rows}
                placeholder={placeholder}
                onChange={onInputChange}
                onBlur={onBlur}
                data-testid={`${testId}__field`}
            />

            {withValidation && error && isInvalid && (
                <FieldMessages
                    data-testid={testId ? `${testId}__error-messages` : undefined}
                    messages={[error]}
                    mode="error"
                />
            )}
            {(!withValidation || !isInvalid) && (
                <FieldMessages
                    className={styles["textarea__helper-messages"]}
                    data-testid={testId ? `${testId}__helper-messages` : undefined}
                    messages={[customWarningMessage ? customWarningMessage : warningMessage, ...(helperMessages ?? [])]}
                    mode="info"
                />
            )}
        </div>
    );
};

export default TextArea;
