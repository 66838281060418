import { SalaryType } from "features/common/models";
import { DropdownOption } from "features/common/types";
import { EmployeeOffersApi } from "features/employeeOffers/api";
import { JobOffersApi } from "features/jobOffers/api";
import { getHourlySalaryFilterMessage, getMonthlySalaryFilterMessage } from "features/offers/translationMessages";
import { CreateOfferStepName } from "features/offers/types";
import { useField } from "formik";
import { useIntl } from "react-intl";

export const useSalaryTypeDropdownOptions = (): DropdownOption<SalaryType>[] => {
    const intl = useIntl();

    const hourlySalaryFilterMessage = getHourlySalaryFilterMessage(intl);
    const monthlySalaryFilterMessage = getMonthlySalaryFilterMessage(intl);

    return [
        {
            display: hourlySalaryFilterMessage,
            value: SalaryType.Hourly,
        },
        {
            display: monthlySalaryFilterMessage,
            value: SalaryType.Monthly,
        },
    ];
};

export const useCreateOfferField = <T>(stepName: CreateOfferStepName, fieldName: string) =>
    useField<T>(`${stepName}.${fieldName}`);    