import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components/macro";
import MansCommentMobile from "../../../../assets/images/man-comment-mobile.png";
import MansCommentTablet from "../../../../assets/images/man-comment-tablet.png";
import MansComment from "../../../../assets/images/man-comment.png";
import WomansCommentMobile from "../../../../assets/images/woman-comment-mobile.png";
import WomansCommentTablet from "../../../../assets/images/woman-comment-tablet.png";
import WomansComment from "../../../../assets/images/woman-comment.png";

const HeroCommentsContainer = () => {
  const intl = useIntl();
  const locale = intl.locale;

  const renderWomanPaddingDesrMobile = useMemo(() => {
    if (locale === "de" || locale === "uk" || locale === 'nl') {
      return "0.75rem";
    } else if (locale === "ru") {
      return "1.2rem";
    } else {
      return "1.75rem";
    }
  },
    [],
  );

  const renderDesrPaddingTablet = useMemo(() => {
    if (locale === "de" || locale === 'nl') {
      return "2.8rem";
    } else if (locale === "uk") {
      return "0.95rem";
    } else if (locale === "ru") {
      return "1.2rem";
    } else if (locale === "fr") {
      return "1.75rem";
    } else {
      return "2.5rem";
    }
  },
    [],
  );

  const renderWomanDesrFontTablet = useMemo(() => {
    if (locale === "uk") {
      return "1.5rem";
    } else if (locale === "de" || locale === 'nl') {
      return "1.35rem";
    } else {
      return "1.65rem";
    }
  },
    [],
  );

  const renderFontDesktop = useMemo(() => {
    if (locale === "uk") {
      return "1.7rem";
    } else if (locale === "ru") {
      return "1.8rem";
    } else if (locale === "de" || locale === 'nl' || locale === 'fr') {
      return "1.5rem";
    } else {
      return "1.85rem";
    }
  },
    [],
  );

  const renderPaddingDesktop = useMemo(() => {
    if (locale === "uk" || locale === "ru") {
      return "1.7rem";
    } else if (locale === "de") {
      return "3.4rem";
    } else {
      return "3rem";
    }
  },
    [],
  );

  const CommentContainer = styled.div`
        display: flex;
        height: 7.25rem;
      
        @media screen and (max-width: 340px) {
          margin-top: 1rem;
        }
      
        @media screen and (min-width: 341px) {
          margin: 0.75rem 1rem 1rem 0.5rem;
        }
      
        @media screen and (min-width: 375px) {
          margin-top: 0.9rem;
          margin-left: auto;
          margin-right: 2.75rem;
        }
      
        @media screen and (min-width: 391px) {
          margin-right: 3.75rem;
        }
      
        @media screen and (min-width: 415px) {
          margin-top: 7.5rem;
          margin-left: auto;
          margin-right: 10rem;
        }
      
        @media screen and (min-width: 834px) {
          margin-right: 7.75rem;
          margin-top: 3.65rem;
          height: 13.5rem;
        }
      
        @media screen and (min-width: 845px) {
          margin-right: 8.75rem;
        }
      
        @media screen and (min-width: 862px) {
          margin-right: 4.75rem;
          margin-top: 1.65rem;
          height: 13.5rem;
        }
      
        @media screen and (min-width: 1441px) {
          position: absolute;
          right: 0;
        }
      `;

  const ManCommentSection = styled.div`
          width: 9.75rem;
          background-image: url(${MansCommentMobile});
          margin-right: 0.25rem;
          background-repeat: no-repeat;
      
          @media screen and (min-width: 415px) {
            margin-right: 5.5rem;
        }
      
        @media screen and (min-width: 834px) {
          margin-right: 1.6rem;
          width: 15.5rem;
          background-image: url(${MansCommentTablet});
        }
      
        @media screen and (min-width: 862px) {
          margin-right: 0.5rem;
          width: 17.7rem;
          background-image: url(${MansComment});
        }
      `;

  const WomanCommentSection = styled.div`
        width: 9.75rem;
        background-image: url(${WomansCommentMobile});
        background-repeat: no-repeat;
        margin-top: -0.25rem;
      
        @media screen and (min-width: 834px) {
          width: 15.5rem;
          background-image: url(${WomansCommentTablet});
        }
      
        @media screen and (min-width: 862px) {
          min-width: 17.7rem;
          background-image: url(${WomansComment});
          margin-top: 0.25rem;
        }
      `;

  const ManCommentDescr = styled.p`
        font-size: 0.75rem;
        letter-spacing: 0.01rem;
        line-height: 0.75rem;
        font-weight: bold;
        color: #002677;
        width: 8.5rem;
        padding-top: 2.75rem;
        margin-left: 0.75rem;
      
        @media screen and (min-width: 834px) {
          font-size: 1.1rem;
          line-height: 1.25rem;
          width: 12.5rem;
          padding-top: 4.75rem;
          margin-left: 1.4rem;
        }
      
        @media screen and (min-width: 862px) {
          font-size: 1.25rem;
          line-height: 1.4rem;
          width: 14.5rem;
          padding-top: 5.62rem;
          margin-left: 35px;
        }
        `;

  const WomanCommentDescr = styled.p<{ mobilePadding: string, tabletFont: string, tabletPadding: string, desktopFont: string, desktopPadding: string }>`
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: 0.01rem;
        color: #002677;
        padding-top: ${({ mobilePadding }) => mobilePadding};
        margin-left: 1.25rem;
        
        @media screen and (min-width: 834px) {
          font-size: ${({ tabletFont }) => tabletFont};
          line-height: 2rem;
          padding-top: ${({ tabletPadding }) => tabletPadding};
          margin-left: 1.4rem;
        }
        
        @media screen and (min-width: 862px) {
          font-size: ${({ desktopFont }) => desktopFont};
          line-height: 1.2;
          padding-top: ${({ desktopPadding }) => desktopPadding};
          margin-left: 1.625rem;
          width: ${locale === "ru" ? "83%" : "unset"};
        }
      `;

  return (
    <CommentContainer>
      <ManCommentSection>
        <ManCommentDescr>
          <FormattedMessage id="hero__man-comment" />
        </ManCommentDescr>
      </ManCommentSection>
      <WomanCommentSection>
        <WomanCommentDescr desktopFont={renderFontDesktop} desktopPadding={renderPaddingDesktop} mobilePadding={renderWomanPaddingDesrMobile} tabletFont={renderWomanDesrFontTablet} tabletPadding={renderDesrPaddingTablet}>
          <FormattedMessage id="hero__woman-comment-1" />
          <br /><span style={{ color: "#FF6900" }}>
            <FormattedMessage id="hero__woman-comment-2" />
          </span>
        </WomanCommentDescr>
      </WomanCommentSection>
    </CommentContainer>
  );
};

export default HeroCommentsContainer;