import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import Button from "features/common/components/Button";
import { OfferType } from "features/common/types";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import SeeSimilarEmployeeOffersButton from "features/employeeOffers/components/SeeSimilarEmployeeOffersButton";
import EndOfferModal from "features/offers/components/MyOffers/EndOfferModal";
import { OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";

import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    employeeOfferDetails?: EmployeeOfferDetails;
    offerId: number;
    isTopBox?: boolean;
    resetOfferDetails?: () => void;
}

const DetailsActionsBox = ({ "data-testid": testId, employeeOfferDetails, isTopBox, resetOfferDetails }: Props) => {
    const history = useHistory();
    const [isEndOfferModalOpen, setIsEndOfferModalOpen] = useState(false);
    const [endOfferId, setEndOfferId] = useState<number>();

    return (
        <div className={styles["details-actions-box__actions-container"]}>
            <div className={styles["details-actions-box__buttons-container"]}>
                {isTopBox ? (
                    <>
                        {employeeOfferDetails?.status !== OfferStatus.Ended && (
                            <Button
                                className={styles["details-actions-box__button"]}
                                data-testid={`${testId}__edit-button`}
                                onClick={() => history.push(`${appRoutes.myEmployeeOffers}/edit/${employeeOfferDetails?.id}`)}
                            >
                                <FormattedMessage id="common__edit" />
                            </Button>
                        )}
                        <Button
                            className={styles["details-actions-box__button"]}
                            data-testid={`${testId}__copy-button`}
                            onClick={() => history.push(`${appRoutes.myEmployeeOffers}/copy/${employeeOfferDetails?.id}`)}
                        >
                            <FormattedMessage id="common__copy" />
                        </Button>
                    </>
                ) : (
                    <>
                        <SeeSimilarEmployeeOffersButton employeeOfferDetails={employeeOfferDetails} />
                        {employeeOfferDetails?.status !== OfferStatus.Ended && (
                            <Button
                                className={styles["details-actions-box__button"]}
                                data-testid={`${testId}__finish-button`}
                                onClick={() => {
                                    setEndOfferId(employeeOfferDetails?.id);
                                    setIsEndOfferModalOpen(true);
                                }}
                            >
                                <FormattedMessage id="common__finish" />
                            </Button>
                        )}
                    </>
                )}
            </div>
            {isEndOfferModalOpen && (
                <EndOfferModal
                    offerId={endOfferId}
                    offerType={OfferType.Employee}
                    onCancel={() => setIsEndOfferModalOpen(false)}
                    onConfirm={() => {
                        resetOfferDetails?.();
                        setIsEndOfferModalOpen(false);
                        window.scrollTo(0, 0);
                    }}
                />
            )}
        </div>
    );
}

export default DetailsActionsBox;
