import { OfferType } from "features/common/types";
import { resetCreateJobOfferState } from "features/jobOffers/actions";
import {
    selectJobOfferApiErrorsArray,
    selectJobOfferCreating,
    selectJobOfferCreatingSuccess,
} from "features/jobOffers/selectors/index";
import { getJobOfferDetailsAsync } from "features/jobOffers/thunks";
import { publishJobOfferAsync, saveJobOfferAsync } from "features/jobOffers/thunks/index";
import CreateOffer from "features/offers/components/CreateOffer";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    offerType: OfferType.Job,
    user: selectUser(state),
    offerCreating: selectJobOfferCreating(state),
    offerCreatingSuccess: selectJobOfferCreatingSuccess(state),
    offerApiErrorsArray: selectJobOfferApiErrorsArray(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            publishJobOfferAsync,
            saveJobOfferAsync,
            getJobOfferDetailsAsync,
            resetCreateOfferState: () => resetCreateJobOfferState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer);
