import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import { ConversationDetailsDto, ConversationsDto, FileMessageUploadResponseDto } from "features/messages/dtos";
import { mapGetConversationsFetchParamsToQueryParams } from "features/messages/mappers";
import { GetConversationsFetchParams, GetNotificationsFetchParams } from "features/messages/types";

export class MessagesApi extends Api {
    getConversationsAsync = async (
        fetchParams: GetConversationsFetchParams,
        userId: string,
        cancelToken?: CancelToken
    ): Promise<ConversationsDto> => ({
        ...(await this.get<ConversationsDto>(
            `${config.apiRoutes.CONVERSATIONS}${mapGetConversationsFetchParamsToQueryParams(fetchParams)}`,
            cancelToken
        )),
        currentUserId: userId,
    });

    getNotificationsAsync = async (fetchParams: GetNotificationsFetchParams, cancelToken?: CancelToken): Promise<any> =>
        this.get<any>(
            `${config.apiRoutes.NOTIFICATIONS}?size=999&unacknowledged_only=false`, // TODO: Change to load more on scroll instead of 999
            cancelToken
        );

    setNotificationAcknowledged = async (notificationId: string, cancelToken?: CancelToken): Promise<any> =>
        this.patch<any, any>(
            `${config.apiRoutes.NOTIFICATIONS}`,
            { notificationId },
            cancelToken
        );

    getConversationDetailsAsync = (id: string, cancelToken?: CancelToken): Promise<ConversationDetailsDto> =>
        this.get<ConversationDetailsDto>(`${config.apiRoutes.CONVERSATIONS}/${id}`, cancelToken);

    getConversationId = (id: string, cancelToken?: CancelToken): Promise<ConversationDetailsDto> =>
        this.get<ConversationDetailsDto>(`${config.apiRoutes.CONVERSATIONS}${config.apiRoutes.BY_MESSAGE}/${id}`, cancelToken);

    sendMessageAsync = (conversationId: string, message: string, files: string[] | null, cancelToken?: CancelToken): Promise<void> => {
        const bodyToSend: {
            conversationId: string;
            message: string,
            files?: string[]
        } = {
            conversationId,
            message
        }
        if (files) {
            bodyToSend.files = files
        }
        return this.post<typeof bodyToSend, void>(
            `${config.apiRoutes.MESSAGES}`,
            bodyToSend,
            cancelToken
        );
    }

    acknowledgeConversationAsync = (id: string, cancelToken?: CancelToken): Promise<void> =>
        this.patch<void, void>(
            `${config.apiRoutes.CONVERSATIONS}/${id}/acknowledge`,
            undefined,
            cancelToken,
        );

    acknowledgeConversationNotificationsAsync = (id: string, cancelToken?: CancelToken): Promise<void> =>
        this.patch<void, void>(
            `${config.apiRoutes.NOTIFICATIONS}/${id}/acknowledge`,
            undefined,
            cancelToken,
        );

    uploadFile = async (
        selectedFiles: FileList,
        cancelToken?: CancelToken
    ): Promise<FileMessageUploadResponseDto> => {
        const fileForm = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            fileForm.append('file', selectedFiles[i]);
        }
        return this.post<typeof fileForm, FileMessageUploadResponseDto>(
            `${config.apiRoutes.UPLOAD_FILE}${config.apiRoutes.MESSAGE}`,
            fileForm,
            cancelToken
        );
    };
}

export default new MessagesApi();
