import { ReactComponent as ArrowRightIcon } from "assets/icons/arrowRight.svg";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import DefaultImage from "features/common/components/DefaultImage";
import { Card, CardType } from "features/common/types";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import ThreeDotsButton from "./ThreeDotsButton"

export interface Props {
    card?: Card;
    user?: User;
    isEmployer?: boolean;
    displayMode?: boolean;
    ["data-testid"]?: string;
    deleteCardAsync?: any;
}

const SingleCard = ({ card, isEmployer, displayMode, "data-testid": testId, deleteCardAsync }: Props) => {
    const intl = useIntl();
    const history = useHistory();

    const goToCardDetails = () => {
        history.push(`${displayMode ? appRoutes.profileCard : appRoutes.card}/${card?.id}`);
    };

    const deleteCard = () => {
        deleteCardAsync(card?.id);
    }

    const renderTitle = useMemo(() => {
        if (card?.type === CardType.EmployeeCompany || card?.type === CardType.EmployerCompany) {
            return card.companyName || "";
        }

        if (card?.type === CardType.EmployeePrivate || card?.type === CardType.EmployerPrivate) {
            return `${card?.firstName} ${card?.lastName}`;
        }

        return "";
    }, [card]);

    const renderLocation = useMemo(() => {
        return `${card?.city ? `${card.city}, ` : ""
            }${card?.country}`;
    }, [card, intl]);

    return (
        <div data-testid={testId} className={styles["single-card"]}>

            <ThreeDotsButton
                onEditClick={goToCardDetails}
                onDeleteClick={deleteCard}
                shouldAllowEditAction
                shouldAllowDeleteAction
                data-testid={`${testId}__threedots`}
                showThreeDotsButton={true} />
            <div
                data-testid={`${testId}__image`}
                className={classNames(styles["single-card__image-container"], {
                    [styles["single-card__employer-image-container"]]: !!isEmployer,
                })}
                onClick={goToCardDetails}
            >
                {!card?.mainPhoto ? (
                    <DefaultImage />
                ) : (
                    <img
                        className={styles["single-card__image"]}
                        src={`${config.IMAGE_URL}/${card?.mainPhoto}`}
                        alt={renderTitle}
                    />
                )}
            </div>
            <div className={styles["single-card__logo-container"]}>
                {card?.logo ? (
                    <img
                        className={styles["single-card__logo"]}
                        src={`${config.IMAGE_URL}/${card?.logo}`}
                        alt={renderTitle}
                    />
                ) : (
                    <div className={styles["single-card__logo-default"]}>
                        <span>
                            <FormattedMessage
                                id="single-card__logo-default"
                                values={{
                                    offerType:
                                        card?.type === CardType.EmployeeCompany ||
                                            card?.type === CardType.EmployeePrivate
                                            ? "private"
                                            : "company",
                                }}
                            />
                        </span>
                    </div>
                )}
            </div>
            <div className={styles["single-card__data-container"]}>
                <div className={styles["single-card__title-wrapper"]}>
                    <div
                        data-testid={`${testId}__title`}
                        className={classNames(styles["single-card__title"], {
                            [styles["single-card__employer-title"]]: !!isEmployer,
                        })}
                        onClick={goToCardDetails}
                    >
                        {renderTitle}
                    </div>
                </div>
                <div className={styles["single-card__subtitle-wrapper"]}>
                    <div className={styles["single-card__subtitle"]}>{renderLocation}</div>
                </div>
            </div>
            <div className={styles["single-card__action"]}>
                <Button data-testid={`${testId}__button`} onClick={goToCardDetails} variant="no-background">
                    <span>
                        <FormattedMessage id="single-card__card-details" />
                    </span>
                    <ArrowRightIcon />
                </Button>
            </div>
        </div>
    );
};

export default SingleCard;
