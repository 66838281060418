import { SortDirection } from "features/common/types";

export interface GetUsersFetchParams {
    pageSize: number;
    offset: number;
    sortBy?: string;
    sortDirection?: string;
    globalParam?: string;
}

export enum UsersSortType {
    Email,
    FirstName,
    LastName,
}

export enum UserOffersCategory {
    All,
    Active,
    Inactive,
    Ended,
}
