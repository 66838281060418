import { ApiError, CancelToken } from "api/utils";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getHomeMessage, getJobOffersMessage } from "features/common/translationMessages";
import { JobOfferDetails as JobOfferDetailsModel } from "features/jobOffers/models";
import { PaymentCardDto } from "features/myProfile/dtos";
import OfferDetails from "features/offers/components/OfferDetails";
import { appRoutes } from "features/routing/routes";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DetailsActionsBox from "./DetailsActionsBox";
import styles from "./styles.module.scss";

interface Props {
    id: number;
    jobOfferDetails?: JobOfferDetailsModel;
    jobOfferDetailsLoading: boolean;
    jobOfferDetailsLoadingError?: ApiError;
    paymentCardData?: PaymentCardDto;
    paymentCardDataLoading: boolean;
    paymentCardDataError?: ApiError;
    isUserLoggedInWithProfile?: boolean;
    resetJobOfferDetails: () => void;
    getJobOfferDetailsAsync?: (id: number, cancelToken?: CancelToken) => void;
    getPaymentCardDataAsync: () => void;
}

const JobOfferDetails = ({
    id,
    jobOfferDetails,
    jobOfferDetailsLoading,
    jobOfferDetailsLoadingError,
    paymentCardData,
    paymentCardDataLoading,
    paymentCardDataError,
    isUserLoggedInWithProfile,
    resetJobOfferDetails,
    getJobOfferDetailsAsync,
    getPaymentCardDataAsync,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();

    const homeMessage = getHomeMessage(intl);
    const jobOffersMessage = getJobOffersMessage(intl);

    const crumbs: BreadcrumbsItem[] = [
        {
            displayName: homeMessage,
            path: appRoutes.dashboard,
        },
        {
            displayName: jobOffersMessage,
            path: appRoutes.jobOffers,
        },
        {
            displayName: jobOfferDetails?.position ?? "",
            path: `${appRoutes.jobOffers}/${jobOfferDetails?.id ?? ""}`,
        },
    ];

    useEffect(() => {
        if (isUserLoggedInWithProfile) {
            getPaymentCardDataAsync();
        }
    }, [getPaymentCardDataAsync, isUserLoggedInWithProfile]);

    useEffect(() => {
        window.scrollTo(0, 0);

        return () => resetJobOfferDetails();
    }, [resetJobOfferDetails]);

    useEffect(() => {
        getJobOfferDetailsAsync && getJobOfferDetailsAsync(id, createCancelToken());
    }, [createCancelToken, getJobOfferDetailsAsync, id]);

    return (
        <div className={styles["offer-details"]}>
            {(jobOfferDetailsLoading || paymentCardDataLoading) && (
                <SpinnerWithMessage message={<FormattedMessage id="offer-details__loading-offer-details" />} />
            )}
            {jobOfferDetailsLoadingError ? (
                <div className={styles["offer-details__error-container"]}>
                    <FormattedMessage id="offer-details__fetch-failure" />
                </div>
            ) : (
                jobOfferDetails && (
                    <div>
                        <div className={styles["offer-details__breadcrumbs-container"]}>
                            <Breadcrumbs data-testid="offer-details__breadcrumbs" crumbs={crumbs} />
                        </div>
                        <OfferDetails
                            id={id}
                            offerDetailsLoading={jobOfferDetailsLoading}
                            isUserLoggedInWithProfile={isUserLoggedInWithProfile}
                            jobOfferDetails={jobOfferDetails}
                            actionsBox={
                                <div>
                                    <DetailsActionsBox
                                        paymentCardData={paymentCardData}
                                        isUserLoggedInWithProfile={isUserLoggedInWithProfile}
                                        jobOfferDetails={jobOfferDetails}
                                        data-testid="offer-details"
                                        offerId={id}
                                    />
                                </div>
                            }
                        />
                    </div>
                )
            )}
        </div>
    );
};

export default JobOfferDetails;
