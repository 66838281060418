import { ReactComponent as CalendarIcon } from "assets/icons/calendarIconOrange.svg";
import { ReactComponent as CalendarValid } from "assets/icons/calenderValidOrange.svg";
import { ReactComponent as EmployeesAmountIcon } from "assets/icons/employeesAmountIcon.svg";
/* eslint-disable max-lines */
import { ReactComponent as FavouriteIcon } from "assets/icons/favouriteOffer.svg";
import { ReactComponent as GetBonusLogo } from "assets/icons/getBonusLogoClear.svg";
import { ReactComponent as EmptyHeart } from "assets/icons/grayedHeart.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/locationMarkerOrange.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/offerCoins.svg";
import { ReactComponent as ShareIcon } from "assets/icons/shareFilled.svg";
import successTick from "assets/icons/success.svg";
import { ReactComponent as UsersOnHandIcon } from "assets/icons/usersOnHandOrange.svg";
import classNames from "classnames";
import FromToText from "features/common/components/FromToText";
import PhotoBrowser from "features/common/components/PhotoBrowser";
import PhotoModal from "features/common/components/PhotoModal/PhotoModal";
import ShareTooltip from "features/common/components/ShareTooltip";
import SignInMessage from "features/common/components/SignInMessage";
import useDateFormat from "features/common/hooks/useDateFormat";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { JobOfferDetails } from "features/jobOffers/models";
import { getCreateSalaryTypeMessage } from "features/jobOffers/translationMessages";
import { OfferType } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslate } from "../../../../../hooks/useTranslate";
import FavoriteAPI from "../../../../favorite/api/index";
import styles from "./styles.module.scss";

export interface Props {
    offerDetails: JobOfferDetails | EmployeeOfferDetails;
    isUserLoggedIn: boolean;
    hideHeader?: boolean;
    isJobOffer?: boolean
    ["data-testid"]?: string;
}

const GeneralInfoPart = ({
    offerDetails,
    "data-testid": testId = "general-info-part",
    isUserLoggedIn,
    hideHeader,
    isJobOffer = false
}: Props) => {
    const [isShareTooltip, setIsShareTooltip] = React.useState(false);
    const [isFavorite, setIsFavorite] = useState(offerDetails?.favorite)
    const intl = useIntl();
    const deviceClass = useDeviceClass();
    const isMatches = useMediaQuery('(min-width: 1440px)');
    const isMatchesStartOfTablet = useMediaQuery('(min-width: 834px)');
    const isMatchesEndOfMobile = useMediaQuery('(max-width: 833px)');
    const getFormattedDate = useDateFormat("d.MM.yyyy");
    const [showCopied, setShowCopied] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const createSalaryTypeMessage = getCreateSalaryTypeMessage(intl);

    const salaryMinAmount = offerDetails.financialConditions.rewardMinimumValue;
    const salaryMaxAmount = offerDetails.financialConditions.rewardMaximumValue;
    const salaryCurrency = offerDetails.financialConditions.rewardCurrencyCode;

    const bonusAmount = offerDetails.financialConditions.bonusValue;
    const bonusCurrency = offerDetails.financialConditions.bonusCurrencyCode;
    const { country, city, street } = offerDetails.location;

    const workLocation = useMemo(
        () =>
            `${street ? (street + ', ') : ''}${city ? `${city}, ` : ''}${country}`,
        [city, country, street]
    );

    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const toggleModal = () => {
        setShowPhotoModal(!showPhotoModal);
    }

    const mapPayoutDayToTranslation = (day: string): string | undefined => {
        let translationId
        switch (day) {
            case "FIRST_DAY":
                translationId = "offers__offer-first-day";
                break;
            case "THIRD_DAY":
                translationId = "offers__offer-third-day";
                break;
            case "FIFTH_DAY":
                translationId = "offers__offer-fifth-day";
                break;
            case "ONE_WEEK":
                translationId = "offers__offer-one-week";
                break;
            case "TWO_WEEKS":
                translationId = "offers__offer-two-weeks";
                break;
            case "ONE_MONTH":
                translationId = "offers__offer-one-month";
                break;
            case "TWO_MONTHS":
                translationId = "offers__offer-two-months";
                break;
            case "THREE_MONTHS":
                translationId = "offers__offer-three-months";
                break;
        }
        return translationId
    }
    const history = useHistory();
    const onFavoriteHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (isUserLoggedIn) {
            if (isFavorite) {
                if (isJobOffer) {
                    await FavoriteAPI.removeJobOfferFromFavorite(offerDetails.id)
                } else {
                    await FavoriteAPI.removeEmployeeOfferFromFavorite(offerDetails.id)
                }
                setIsFavorite(false);
            } else {
                if (isJobOffer) {
                    await FavoriteAPI.addJobOfferToFavorite(offerDetails.id);
                } else {
                    await FavoriteAPI.addEmployeeOfferToFavorite(offerDetails.id)
                }
                setIsFavorite(true);
            }
        }
        else {
            history.push(appRoutes.login);
        }
    }
    useEffect(() => {
        setIsFavorite(!!offerDetails.favorite)
    }, [offerDetails])

    const photoUrls = useMemo(() => {
        if ('photoUrls' in offerDetails && offerDetails.photoUrls.length > 0) {
            return offerDetails.photoUrls
        } else if ('images' in offerDetails && offerDetails.images && offerDetails.images.length > 0) {
            return offerDetails.images
        } else {
            return []
        }
    }, [offerDetails])

    useEffect(() => {
        if (isClicked && deviceClass === 'smartphone') {
            setShowCopied(true);
            setIsClicked(false)
            setTimeout(() => {
                setShowCopied(false);
            }, 1500)
        }
    }, [isClicked])

    const renderGetBonusText = () => bonusAmount > 0 && isJobOffer && (
        <p>
            <FormattedMessage id={"offers__offer-bonus-per-person"} />
            <FormattedMessage id={mapPayoutDayToTranslation(offerDetails.financialConditions.bonusPayoutDateType)} />
            <FormattedMessage id={"offers__offer-bonus-after-job-start"} />
        </p>
    )

    const { translatedText, handleTranslate, translationIcon } = useTranslate(offerDetails.position)

    return (
        <div data-testid={testId} className={styles["general-info-part"]}>
            <span className={styles["general-info-part__add-date-info-text"]} ><FormattedMessage id='offers-item__offer-added' /> {getFormattedDate(offerDetails.addedDate)} </span>
            <div className={styles["general-info-part__details-container"]}>
                {offerDetails?.businessName && isJobOffer && isUserLoggedIn && !hideHeader ? (
                    <div
                        data-testid={`${testId}__business-name`}
                        className={styles["general-info-part__business-name"]}
                    >
                        {offerDetails?.businessName}
                    </div>
                ) : (deviceClass === "desktop" && !isUserLoggedIn) ? (
                    <SignInMessage
                        data-testid={`${testId}__sign-in-message`}
                        className={styles["general-info-part__sign-in-message"]}
                    />
                ) : null}
                {!hideHeader && (
                    <div className={styles["general-info-part__job"]}>
                        <div className={styles["general-info-part__job-info"]}>
                            <h2 data-testid={`${testId}__position`} className={styles["general-info-part__job--position"]}>
                                {translatedText ? translatedText : offerDetails.position.length >= 58 ? offerDetails.position.substring(0, 58) + "..." : offerDetails.position}
                                <div className={styles["general-info-part__translation"]} onClick={handleTranslate}>
                                    {translationIcon}
                                </div>
                            </h2>
                            <span className={styles["general-info-part__job--branch"]}>
                                <FormattedMessage id={offerDetails.branch.name} />
                            </span>
                        </div>
                        {isMatchesEndOfMobile && (
                            <div className={styles["general-info-part__job-react"]}>
                                {isShareTooltip && <ShareTooltip setIsShareTooltip={setIsShareTooltip} isClicked={isClicked} setIsClicked={setIsClicked} />}
                                <ShareIcon onClick={() => setIsShareTooltip(prev => !prev)} />
                                <StyledHeartBtn onClick={onFavoriteHandler}>
                                    {isFavorite ? <FavouriteIcon /> : <EmptyHeart />}
                                </StyledHeartBtn>
                            </div>
                        )}
                    </div>
                )}
                <div className={styles["general-info-part__entry-with-icon"]}>
                    <LocationMarkerIcon width={32} height={32} />
                    <div className={styles["general-info-part__entry-text--with-separation"]}>
                        <div data-testid={`${testId}__job-location`}>{workLocation}</div>
                    </div>
                </div>
                {isJobOffer && (
                    <div className={styles["general-info-part__entry-with-icon"]}>
                        <CalendarIcon width={32} height={32} />
                        <div className={styles["general-info-part__entry-text--with-separation"]}>
                            <div data-testid={`${testId}__work-type`}>
                                <FormattedMessage
                                    id="job-offer-details__work-type"
                                    values={{ workType: offerDetails.workType }}
                                />
                                :
                            </div>
                            <div data-testid={`${testId}__job-start-end-date-range`}>
                                <FromToText from={'startDate' in offerDetails ? offerDetails.startDate : offerDetails.jobStartDate} format={"d.MM.yyyy"} />
                            </div>
                        </div>
                    </div>
                )}
                <div className={classNames(styles["general-info-part__entry-with-icon"], styles["general-info-part__expiration-date"])}>
                    <CalendarValid width={32} height={32} />
                    <div className={styles["general-info-part__entry-text"]}>
                        <div data-testid={`${testId}__available-positions-number`}>
                            <FormattedMessage id="offers__offer-valid-until" values={{ "expirationDate": getFormattedDate(offerDetails.expirationDate) }} />
                        </div>
                    </div>
                </div>
                {bonusAmount > 0 && deviceClass === 'desktop' && isJobOffer && (
                    <div className={styles["general-info-part__bonus-container"]}>
                        <GetBonusLogo />
                        <span>
                            {bonusAmount}
                        </span>
                        <span>
                            {bonusCurrency}
                        </span>
                    </div>
                )}
                {'offerType' in offerDetails && offerDetails.offerType === OfferType.Group && deviceClass === 'desktop' && (
                    <div className={styles["general-info-part__group-offer-container"]}>
                        <div className={styles["general-info-part__offer-type-container"]}>
                            <EmployeesAmountIcon className={styles["general-info-part__offer-type-icon"]} />
                            <FormattedMessage id="employee-offers-details__group-offer" />
                        </div>
                    </div>
                )}
                {isMatchesEndOfMobile && (
                    <>
                        <div className={styles["general-info-part__entry-with-icon"]}>
                            <UsersOnHandIcon width={32} height={32} />
                            <div className={styles["general-info-part__entry-text"]}>
                                <div data-testid={`${testId}__available-positions-number`}>
                                    <FormattedMessage id="offers__number-of-positions" />:{" "}
                                    {offerDetails.availablePositionsNumber}
                                </div>
                            </div>
                        </div>
                        {isJobOffer && (
                            <div className={classNames(styles["general-info-part__entry-with-icon"], styles["general-info-part__salary-container"])}>
                                <CoinsIcon />
                                <div className={styles["general-info-part__entry-text"]}>
                                    <h2
                                        data-testid={`${testId}__salary`}
                                        className={classNames(styles["general-info-part__salary"], { [styles["general-info-part__salary--no-bonus"]]: bonusAmount <= 0 })}
                                    >
                                        <div className={styles["general-info-part__salary_from"]}>
                                            <span>{intl.formatMessage({ id: 'common__from' })} {salaryMinAmount}</span>
                                            <span>
                                                {salaryCurrency}/{createSalaryTypeMessage(offerDetails.financialConditions.paymentRateType)}
                                            </span>
                                        </div>
                                        {salaryMaxAmount && salaryMaxAmount > 0 && (
                                            <div className={styles['general-info-part__salary_to']}>
                                                <span>{intl.formatMessage({ id: 'common__to' })} {salaryMaxAmount}</span>
                                                <span>
                                                    {salaryCurrency}/{createSalaryTypeMessage(offerDetails.financialConditions.paymentRateType)}
                                                </span>
                                            </div>
                                        )}
                                    </h2>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            {isMatchesStartOfTablet && (
                <>
                    <div className={styles["general-info-part__big-screen-info"]}>
                        {isJobOffer && (
                            <div className={classNames(styles["general-info-part__entry-with-icon"], styles["general-info-part__salary-container"])}>
                                <CoinsIcon />
                                <div className={styles["general-info-part__entry-text"]}>
                                    <h2
                                        data-testid={`${testId}__salary`}
                                        className={classNames(styles["general-info-part__salary"], { [styles["general-info-part__salary--no-bonus"]]: bonusAmount <= 0 })}
                                    >
                                        <div className={styles["general-info-part__salary_from"]}>
                                            <span>{intl.formatMessage({ id: 'common__from' })} {salaryMinAmount}</span>
                                            <span>
                                                {salaryCurrency}/{createSalaryTypeMessage(offerDetails.financialConditions.paymentRateType)}
                                            </span>
                                        </div>
                                        {salaryMaxAmount && salaryMaxAmount > 0 && (
                                            <div className={styles['general-info-part__salary_to']}>
                                                <span>{intl.formatMessage({ id: 'common__to' })} {salaryMaxAmount}</span>
                                                <span>
                                                    {salaryCurrency}/{createSalaryTypeMessage(offerDetails.financialConditions.paymentRateType)}
                                                </span>
                                            </div>
                                        )}
                                    </h2>
                                </div>
                            </div>
                        )}
                        <div className={classNames(styles["general-info-part__entry-with-icon"], styles["general-info-part__number-employee-container"])}>
                            <UsersOnHandIcon width={32} height={32} />
                            <div className={styles["general-info-part__entry-text"]}>
                                <div data-testid={`${testId}__available-positions-number`}>
                                    <FormattedMessage id="offers__number-of-positions" />:{" "}
                                    {offerDetails.availablePositionsNumber}
                                </div>
                            </div>

                        </div>
                        <div className={styles["general-info-part__big-screen-info__side-info"]}>
                            {renderGetBonusText()}
                            <div className={styles["general-info-part__job-react"]}>
                                {isShareTooltip && <ShareTooltip setIsShareTooltip={setIsShareTooltip} isClicked={isClicked} setIsClicked={setIsClicked} />}
                                <ShareIcon onClick={() => setIsShareTooltip(prev => !prev)} />
                                <StyledHeartBtn onClick={onFavoriteHandler}>
                                    {isFavorite ? <FavouriteIcon /> : <EmptyHeart />}
                                </StyledHeartBtn>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {bonusAmount > 0 && deviceClass !== 'desktop' && isJobOffer && (
                <div className={classNames(styles["general-info-part__salary-bonus-container"], {
                    [styles["general-info-part__salary-bonus-container--no-bonus"]]: bonusAmount <= 0,
                })}>
                    <div className={styles["general-info-part__bonus-container-info"]}>
                        <div className={styles["general-info-part__bonus-container"]}>
                            <GetBonusLogo />
                            <span>
                                {bonusAmount}
                            </span>
                            <span>
                                {bonusCurrency}
                            </span>
                        </div>
                        {renderGetBonusText()}
                    </div>
                </div>
            )}
            {'offerType' in offerDetails && offerDetails.offerType === OfferType.Group && deviceClass !== 'desktop' && (
                <div className={styles['general-info-part__salary-bonus-container']}>
                    <div className={styles["general-info-part__offer-type-container"]}>
                        <EmployeesAmountIcon className={styles["general-info-part__offer-type-icon"]} />
                        <FormattedMessage id="employee-offers-details__group-offer" />
                    </div>
                </div>
            )}
            {
                (isMatches || isMatchesEndOfMobile) && (
                    <>
                        <div className={styles["general-info-part__photo-browser-container"]}>
                            {!showPhotoModal ?
                                <PhotoBrowser data-testid={`${testId}__photo-browser`} photoUrls={photoUrls} onClick={toggleModal} />
                                :
                                <PhotoModal
                                    onClick={toggleModal}
                                    photoUrls={photoUrls}
                                    data-testid="main-section__main-photo-modal"
                                />}
                        </div>
                    </>
                )
            }
            {showCopied && deviceClass === 'smartphone' && (
                <CopiedMessage>
                    <img width={25} height={25} src={successTick} alt="success" />
                    <FormattedMessage id="share-link__copied" />
                </CopiedMessage>
            )}
        </div>
    );
};

const CopiedMessage = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    top: 40%;
    left: 50%; 
    transform: translateX(-50%) translateY(-50%);
    min-width: 100px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 4px 4px 15px rgba(0,34,120, 0.25);
    padding: 1rem;
    width: max-content;
    z-index: 20;
    color: #118146;
    font-size: 16px;
    & > img {
        margin-right: 10px;
    }
`
const StyledHeartBtn = styled.button`
    background: transparent;
    outline: none;
    border: none;
    display: flex;
`;

export default GeneralInfoPart;
