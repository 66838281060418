import React from "react";
import { IntlShape, useIntl, MessageDescriptor } from "react-intl";

export const getCompanyNameIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-profile-form__company-name",
    });

export const getConfirmActionMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-profile-form__confirm-action",
    });

export const getCancelCreateProfileMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-profile-form__cancel-create-profile-message",
    });

export const getBackMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-profile-form__back",
    });

export const getConfirmMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-profile-form__confirm",
    });

export const getLoginNoAccountMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__no-account",
    });

export const getLoginRegisterMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__register",
    });

export const getLoginTitleMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__title",
    });

export const getLoginOrSocialMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__or-social",
    });

export const getEmailLabelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__email-label",
    });

export const getPasswordLabelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__password-label",
    });

export const getLoginButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__login-button",
    });

export const getForgottenPasswordButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-forgotten-password__forgotten-password-button",
    });

export const getForgottenPasswordMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__forgotten-password",
    });

export const getGoogleLoginButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__google-login-button",
    });

export const getFacebookLoginButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__facebook-login-button",
    });

export const getAppleLoginButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-login__apple-login-button",
    });

export const getRegisterHaveAccountMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__have-account",
    });

export const getRegisterSignInMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__sign-in",
    });

export const getRegisterTitleMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__title",
    });

export const getRegisterButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__register-button",
    });

export const getRegisterOrSocialMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__or-social-message",
    });

export const getRegisterRulesMessage = (
    intl: IntlShape,
    rulesButton: React.ReactElement,
    privacyPolicyButton: React.ReactElement
) =>
    intl.formatMessage(
        {
            id: "user-register__rules",
        },
        {
            rulesButton,
            privacyPolicyButton,
        }
    );

export const getRegisterRulesButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__rules-button",
    });

export const getRegisterOrMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__or",
    });

export const getRegisterPrivacyPolicyButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "user-register__privacy-policy-button",
    });

export const getActivationInfoTitleMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "activation-info__title",
    });

export const getActivationInfoTextMessage = (intl: IntlShape, email: JSX.Element) =>
    intl.formatMessage(
        {
            id: "activation-info__text",
        },
        {
            email,
        }
    );

export const getActivationInfoResendTextMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "activation-info__resend-text",
    });

export const getActivationInfoResendButtonMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "activation-info__resend-button",
    });
export const getActivationInfoResendConfirmation = (intl: IntlShape) =>
    intl.formatMessage({
        id: "activation-info__resend-confirmation",
    });

export const useTranslationById = () => {
    const intl = useIntl();
    return (id: string) => {
        return intl.formatMessage({id})
    }
}

export const getFunctionalityTemporarilyUnavailable = (intl: IntlShape) =>
    intl.formatMessage({
        id: "functionality-temporarily-unavailable",
    });
