import { CancelToken } from "api/utils";
import config from "config";
import { emptyGetOffersFetchParams } from "features/common/constants";
import EmployeeOffersApi from "features/employeeOffers/api";
import { EmployeeOfferDto } from "features/employeeOffers/dtos";
import { mapEmployeeOffersFilterMatchCountsDtoToEmployeeOffersFilterMatchCounts } from "features/employeeOffers/mappers";
import { EmployeeOffersFilterMatchCounts } from "features/employeeOffers/types";
import FavoriteAPI from "features/favorite/api";
import { FavoriteEmployeeOrEmployer } from "features/favorite/models";
import JobOffersApi from "features/jobOffers/api";
import { JobOfferDto } from "features/jobOffers/dtos";
import { mapJobOffersResponseDtoToJobOffersFilterMatchCounts } from "features/jobOffers/mappers";
import { JobOffersFilterMatchCounts } from "features/jobOffers/types";
import { useEffect, useState } from "react";

const useOffers = () => {
    const [featuredOffers, setFeaturedOffers] = useState<JobOfferDto[]>([]);
    const [newestOffers, setNewestOffers] = useState<JobOfferDto[]>([]);
    const [allEmployeesOffers, setAllEmployeesOffers] = useState<EmployeeOfferDto[]>([]);

    useEffect(() => {
        (async () => {
            const featuredJobOffers = await JobOffersApi.getFeaturedAsync({ ...emptyGetOffersFetchParams, offset: 1, pageLimit: config.offersResultsPerPage, isFavorite: false });
            const jobOffers = await JobOffersApi.getJobOffersAsync({ ...emptyGetOffersFetchParams, offset: 1, pageLimit: config.offersResultsPerPage, isFavorite: false });
            const employeeOffers = await EmployeeOffersApi.getEmployeeOffersAsync({ ...emptyGetOffersFetchParams, offset: 1, pageLimit: config.offersResultsPerPage, isFavorite: false });

            const jobsData = jobOffers.data.page.content || [];
            const employeeData = employeeOffers.data.page.content || [];
            const featuredJobsData = featuredJobOffers.data.page.content || [];

            setNewestOffers(jobsData.filter((offer) => !offer.financialConditionsSlimDTO.bonusValue).slice(0, 4));
            setAllEmployeesOffers(employeeData.slice(0, 4));
            setFeaturedOffers(featuredJobsData.filter((offer) => offer.financialConditionsSlimDTO.bonusValue).slice(0, 4));

        })();
    }, []);
    return { featuredOffers, newestOffers, allEmployeesOffers };
};

export const useOffersWithParams = (params: any, asJobOffersList: boolean | undefined, runRequest: string | null, cancelToken?: CancelToken) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState();
    const [offers, setOffers] = useState<JobOfferDto[] | EmployeeOfferDto[] | FavoriteEmployeeOrEmployer[]>([]);
    const [totalOffers, setTotalOffers] = useState<number>(0);
    const [filterMatchCounts, setFilterMatchCounts] = useState<JobOffersFilterMatchCounts | EmployeeOffersFilterMatchCounts | undefined>(undefined);

    const resetState = () => {
        setOffers([]);
        setFilterMatchCounts(undefined);
        setOffers([]);
        setLoading(true);
    }
    useEffect(() => {
        resetState();
        const setFavoriteEmployee = async () => {
            setLoading(true);
            try {
                const res = await FavoriteAPI.getFavoriteEmployeeAsync(params, cancelToken);
                const data = res.data.page.content || [];
                setOffers(data)
                const filterMatchJobOffersCount = mapJobOffersResponseDtoToJobOffersFilterMatchCounts(res);
                setFilterMatchCounts(filterMatchJobOffersCount);
                setLoading(false);
            } catch (e: any) {
                setLoading(false);
                setError(e)
                console.log(e)
            }
        }
        const setFavoriteEmployer = async () => {
            setLoading(true);
            try {
                const res = await FavoriteAPI.getFavoriteEmployerAsync(params, cancelToken);
                const data = res.data.page.content || [];
                setOffers(data)
                const filterMatchJobOffersCount = mapEmployeeOffersFilterMatchCountsDtoToEmployeeOffersFilterMatchCounts(res);
                setFilterMatchCounts(filterMatchJobOffersCount);
                setLoading(false);

            } catch (e: any) {
                setLoading(false)
                setError(e)
                console.log(e)

            }
        }

        const getOffers = async () => {
            try {
                setLoading(true);
                if (asJobOffersList) {
                    const jobOffers = await JobOffersApi.getJobOffersAsync(params, cancelToken);
                    const jobsData = jobOffers.data.page.content || [];
                    const filterMatchJobOffersCount = mapJobOffersResponseDtoToJobOffersFilterMatchCounts(jobOffers);
                    setFilterMatchCounts(filterMatchJobOffersCount)
                    const jobOffersTotal = jobOffers.data.page.total
                    setTotalOffers(jobOffersTotal ?? 0)
                    // TODO it has to be back after, this break pagination
                    // if (deviceClass === 'smartphone') {
                    // setJobOffers(prevState => ([...prevState, ...jobsData.filter((el) => !prevState.map((el2) => el2.id).includes(el.id))]))
                    // } else {
                    setOffers(jobsData);
                    // }
                } else {
                    const employeeOffers = await EmployeeOffersApi.getEmployeeOffersAsync(params, cancelToken);
                    const employeeData = employeeOffers.data.page.content || [];
                    const filterMatchEmployeeOffersCount = mapEmployeeOffersFilterMatchCountsDtoToEmployeeOffersFilterMatchCounts(employeeOffers);
                    setFilterMatchCounts(filterMatchEmployeeOffersCount)
                    const employeeOffersTotal = employeeOffers.data.page.total
                    setTotalOffers(employeeOffersTotal)
                    //It will be fixed both for employeeOffers and jobOffers with pagination
                    // if (deviceClass === 'smartphone') {
                    //     setEmployeeOffers(prevState => ([...prevState, ...employeeData.filter((el) => !prevState.map((el2) => el2.id).includes(el.id))]))
                    // } else {
                    setOffers(employeeData);
                    // }
                }
                setLoading(false)
            } catch (e: any) {
                setLoading(false)
                setError(e)
            }
        }
        if (params && (runRequest === null || runRequest === '1' || runRequest === '2')) {
            getOffers();
        }

        if (runRequest === '3') {
            setFavoriteEmployee();
        }

        if (runRequest === '4') {
            setFavoriteEmployer();
        }

    }, [asJobOffersList, cancelToken, params, runRequest]);

    return { offers, totalOffers, filterMatchCounts, loading, error, resetState }
};

export default useOffers;
