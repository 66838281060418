import {
    appendAuthHeaderInterceptor,
    handleCancelErrorInterceptor,
    refreshTokenInterceptor,
} from "api/axios/interceptors";
import Axios from "axios";
import { defaultLocale } from "features/common/constants";
import { cookieNames } from "features/cookies/constants";
import { getCookie } from "features/cookies/utils";

const createAxiosInstance = (url: string) => {
    const instance = Axios.create({
        baseURL: url,
        // withCredentials: true,
        headers: {
            "Access-Control-Allow-Origin": url,
            "Language-Code": getCookie(cookieNames.LOCALE) ?? defaultLocale,
        },
    });

    instance.interceptors.request.use(appendAuthHeaderInterceptor);
    instance.interceptors.response.use((response) => response, handleCancelErrorInterceptor);
    instance.interceptors.response.use.apply(instance.interceptors.response, refreshTokenInterceptor(instance));

    return instance;
};

export default createAxiosInstance;
