import Api from "api";
import config from "config";
import { SupportRequestData } from "features/contactForm/models";

export class ContactFormApi extends Api {
    sendContactFormMessageAsync = async (data: SupportRequestData): Promise<void> => {
        return this.post<SupportRequestData, void>(config.apiRoutes.MESSAGES_SEND_CONTACT_FORM, data);
    };
}

export default new ContactFormApi();
