/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { ApiError, CancelToken } from "api/utils";
import { User } from "features/adminPanel/model";
import {
    getMailMessage,
    getReviewMessage,
    getSearchForUserMessage,
    getSingleEmployeeOffersMessage,
    getUserProfileMessage,
} from "features/adminPanel/translationMessages";
import { GetUsersFetchParams, UsersSortType } from "features/adminPanel/types";
import { getInitialGetUsersFetchParams, getUsersTableColumns } from "features/adminPanel/utils";
import Button from "features/common/components/Button";
import DataTable, { TableColumn } from "features/common/components/DataTable";
import Headroom from "features/common/components/Headroom";
import Input from "features/common/components/Input";
import ModalWithContent from "features/common/components/ModalWithContent";
import Pagination from "features/common/components/Pagination";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import usePrevious from "features/common/hooks/usePrevious";
import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import { User as CurrentUser } from "features/user/models";
import { getFirstNameMessage, getLastNameMessage } from "features/common/translationMessages";
import { SortDirection } from "features/common/types";
import { listContainsGaps, pageExists } from "features/common/utils";
import { appRoutes } from "features/routing/routes";
import { Locale } from "lang";

import styles from "./styles.module.scss";
import { ReactComponent as MagnifierIcon } from "assets/icons/magnifier.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

export interface Props {
    user?: CurrentUser;
    usersLoading: boolean;
    users?: (User | undefined)[];
    usersLoadingError?: ApiError;
    totalUsers?: number;
    blockUserSuccess?: boolean;
    blockUserLoading?: boolean;
    blockUserError?: ApiError;
    unblockUserSuccess?: boolean;
    unblockUserLoading?: boolean;
    unblockUserError?: ApiError;
    deleteUserSuccess?: boolean;
    deleteUserLoading?: boolean;
    deleteUserError?: ApiError;
    downloadUsersListSuccess?: boolean;
    downloadUsersListLoading?: boolean;
    downloadUsersListError?: ApiError;
    ["data-testid"]?: string;
    getUsersAsync: (fetchParams: GetUsersFetchParams, cancelToken?: CancelToken) => void;
    amendUsersAsync: (fetchParams: GetUsersFetchParams, cancelToken?: CancelToken) => void;
    blockUserAsync: (userId: string, cancelToken?: CancelToken) => void;
    unblockUserAsync: (userId: string, cancelToken?: CancelToken) => void;
    deleteUserAsync: (userId: string, cancelToken?: CancelToken) => void;
    downloadUsersListAsync: (totalUsers: number, cancelToken?: CancelToken) => void;
    resetUsers: () => void;
}

const UsersComponent = ({
    user,
    users,
    usersLoading,
    usersLoadingError,
    totalUsers,
    blockUserSuccess,
    blockUserLoading,
    blockUserError,
    unblockUserSuccess,
    unblockUserLoading,
    unblockUserError,
    deleteUserSuccess,
    deleteUserLoading,
    deleteUserError,
    downloadUsersListLoading,
    downloadUsersListError,
    "data-testid": testId = "admin-panel-users",
    getUsersAsync,
    amendUsersAsync,
    blockUserAsync,
    unblockUserAsync,
    deleteUserAsync,
    downloadUsersListAsync,
    resetUsers,
}: Props) => {
    useScrollToTopOnMount();

    const intl = useIntl();
    const history = useHistory();
    const deviceClass = useDeviceClass();
    const createCancelToken = useCreateCancelToken();
    const searchBoxRef = useRef<HTMLDivElement | null>(null);
    const [blockUserModalDisplayed, setBlockUserModalDisplayed] = useState<string | undefined>();
    const [blockUserSuccessModalDisplayed, setBlockUserSuccessModalDisplayed] = useState(false);
    const [blockUserErrorModalDisplayed, setBlockUserErrorModalDisplayed] = useState(false);
    const [unblockUserModalDisplayed, setUnblockUserModalDisplayed] = useState<string | undefined>();
    const [unblockUserSuccessModalDisplayed, setUnblockUserSuccessModalDisplayed] = useState(false);
    const [unblockUserErrorModalDisplayed, setUnblockUserErrorModalDisplayed] = useState(false);
    const [userUnblockedModalDisplayed, setUserUnblockedModalDisplayed] = useState(false);
    const [userBlockedModalDisplayed, setUserBlockedModalDisplayed] = useState(false);
    const [deleteUserModalDisplayed, setDeleteUserModalDisplayed] = useState<string | undefined>();
    const [deleteUserSuccessModalDisplayed, setDeleteUserSuccessModalDisplayed] = useState(false);
    const [deleteUserErrorModalDisplayed, setDeleteUserErrorModalDisplayed] = useState(false);
    const [downloadUsersModalDisplayed, setDownloadUsersModalDisplayed] = useState(false);

    const firstNameMessage = getFirstNameMessage(intl);
    const jobOffersMessage = intl.formatMessage({ id: "admin-panel__see-job-offers" });
    const lastNameMessage = getLastNameMessage(intl);
    const mailMessage = getMailMessage(intl);
    const reviewMessage = getReviewMessage(intl);
    const userProfileMessage = getUserProfileMessage(intl);
    const searchForUserPlaceholder = getSearchForUserMessage(intl);
    const singleEmployeeOffersMessage = getSingleEmployeeOffersMessage(intl);
    const blockUserMessage = useMemo(() => intl.formatMessage({ id: "admin-panel__block-user" }), [intl]);
    const unblockUserMessage = useMemo(() => intl.formatMessage({ id: "admin-panel__unblock-user" }), [intl]);
    const deleteUserMessage = useMemo(() => intl.formatMessage({ id: "admin-panel__delete-user" }), [intl]);

    const [searchHeadroomBottomPosition, setSearchHeadroomBottomPosition] = useState(0);
    const [fetchParams, setFetchParams] = useState<GetUsersFetchParams>(
        getInitialGetUsersFetchParams(intl.locale as Locale)
    );
    const [searchValue, setSearchValue] = useState<string>("");

    const prevDeviceClass = usePrevious(deviceClass, deviceClass);
    const prevFetchParams = usePrevious(fetchParams, fetchParams);

    const tableColumnLabels = [mailMessage, firstNameMessage, lastNameMessage];
    const displayedUsers = useMemo(
        () =>
            (deviceClass === "desktop"
                ? users?.slice(
                    fetchParams.pageSize * fetchParams.offset,
                    fetchParams.pageSize * fetchParams.offset + fetchParams.pageSize
                )
                : users
            )?.filter((user) => !!user) as User[],
        [deviceClass, fetchParams.offset, fetchParams.pageSize, users]
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

    const onSearch = () => {
        setFetchParams((value) => ({ ...value, globalParam: searchValue }));
    };

    const onMobileSearchClick = () => {
        if (searchBoxRef.current) {
            const searchBoxCurrent = searchBoxRef.current.getBoundingClientRect();
            const headerAndButtonContainerHeight = 150;
            const distance = window.scrollY + searchBoxCurrent.top - headerAndButtonContainerHeight;

            window.scrollTo(0, distance);
        }
    };

    const onUserProfileActionClick = useCallback(
        (userId: string) => {
            history.push(`${appRoutes.userProfile}/${userId}`);
        },
        [history]
    );

    const onUserJobOffersActionClick = useCallback(
        (userId: string) => {
            history.push(`${appRoutes.userJobOffers}/${userId}`);
        },
        [history]
    );

    const onUserEmployeeOffersActionClick = useCallback(
        (userId: string) => {
            history.push(`${appRoutes.userEmployeeOffers}/${userId}`);
        },
        [history]
    );

    const onSortClick = (sortBy: UsersSortType, sortDirection: SortDirection) => {
        const newColumns = [...columns];
        newColumns.splice(sortBy + 1, 1, {
            ...newColumns[sortBy + 1],
            currentSortDirection: sortDirection,
        } as TableColumn<User>);

        setColumns(newColumns);
        setFetchParams({
            ...fetchParams,
            offset: 0,
            sortBy:
                sortBy === UsersSortType.Email
                    ? "email"
                    : sortBy === UsersSortType.FirstName
                        ? "firstname"
                        : "lastname",
            sortDirection: sortDirection === SortDirection.Ascending ? "asc" : "desc",
        });
    };

    const openBlockUserModal = useCallback(
        (userId: string) => {
            const selectedUser = displayedUsers?.find((item) => item?.id === userId) as User;

            if (selectedUser?.isBlocked) {
                return setUserBlockedModalDisplayed(true);
            }

            setBlockUserModalDisplayed(userId);
        },
        [displayedUsers]
    );

    const openUnblockUserModal = useCallback(
        (userId: string) => {
            const selectedUser = displayedUsers?.find((item) => item?.id === userId) as User;

            if (!selectedUser?.isBlocked) {
                return setUserUnblockedModalDisplayed(true);
            }

            setUnblockUserModalDisplayed(userId);
        },
        [displayedUsers]
    );

    const closeBlockUserModal = useCallback(() => setBlockUserModalDisplayed(undefined), []);
    const closeUnblockUserModal = useCallback(() => setUnblockUserModalDisplayed(undefined), []);
    const closeUserBlockedModal = useCallback(() => setUserBlockedModalDisplayed(false), []);
    const closeUserUnblockedModal = useCallback(() => setUserUnblockedModalDisplayed(false), []);

    const handleBlockUser = useCallback(() => {
        if (blockUserModalDisplayed) {
            blockUserAsync(blockUserModalDisplayed);
        }
    }, [blockUserAsync, blockUserModalDisplayed]);

    const handleUnblockUser = useCallback(() => {
        if (unblockUserModalDisplayed) {
            unblockUserAsync(unblockUserModalDisplayed);
        }
    }, [unblockUserAsync, unblockUserModalDisplayed]);

    const handleDeleteUser = useCallback(() => {
        if (deleteUserModalDisplayed) {
            deleteUserAsync(deleteUserModalDisplayed);
        }
    }, [deleteUserAsync, deleteUserModalDisplayed]);
    const closeBlockUserSuccessModal = useCallback(() => setBlockUserSuccessModalDisplayed(false), []);
    const closeBlockUserErrorModal = useCallback(() => setBlockUserErrorModalDisplayed(false), []);
    const closeUnblockUserSuccessModal = useCallback(() => setUnblockUserSuccessModalDisplayed(false), []);
    const closeUnblockUserErrorModal = useCallback(() => setUnblockUserErrorModalDisplayed(false), []);
    const closeDeleteUserSuccessModal = useCallback(() => setDeleteUserSuccessModalDisplayed(false), []);
    const closeDeleteUserErrorModal = useCallback(() => setDeleteUserErrorModalDisplayed(false), []);

    const openDeleteUserModal = useCallback((userId: string) => setDeleteUserModalDisplayed(userId), []);
    const closeDeleteUserModal = useCallback(() => setDeleteUserModalDisplayed(undefined), []);

    const handleDownloadUsersClick = useCallback(() => {
        if (totalUsers) {
            downloadUsersListAsync(totalUsers);
        }
    }, [downloadUsersListAsync, totalUsers]);

    const [columns, setColumns] = useState<TableColumn<User>[]>(
        getUsersTableColumns(
            tableColumnLabels,
            [
                (direction) => onSortClick(UsersSortType.Email, direction),
                (direction) => onSortClick(UsersSortType.FirstName, direction),
                (direction) => onSortClick(UsersSortType.LastName, direction),
            ],
            reviewMessage,
            [userProfileMessage, jobOffersMessage, singleEmployeeOffersMessage, blockUserMessage, unblockUserMessage, deleteUserMessage],
            // TODO: IWGM-733 Implement missing actions.
            [
                onUserProfileActionClick,
                onUserJobOffersActionClick,
                onUserEmployeeOffersActionClick,
                openBlockUserModal,
                openUnblockUserModal,
                openDeleteUserModal,
            ]
        )
    );

    useEffect(() => {
        if (blockUserSuccess) {
            setBlockUserModalDisplayed(undefined);
            setBlockUserSuccessModalDisplayed(true);
        }
    }, [blockUserSuccess]);

    useEffect(() => {
        if (blockUserError) {
            setBlockUserModalDisplayed(undefined);
            setBlockUserErrorModalDisplayed(true);
        }
    }, [blockUserError]);

    useEffect(() => {
        if (unblockUserSuccess) {
            setUnblockUserModalDisplayed(undefined);
            setUnblockUserSuccessModalDisplayed(true);
            getUsersAsync(fetchParams, createCancelToken());
        }
    }, [unblockUserSuccess]);

    useEffect(() => {
        if (unblockUserError) {
            setUnblockUserModalDisplayed(undefined);
            setUnblockUserErrorModalDisplayed(true);
        }
    }, [unblockUserError]);

    useEffect(() => {
        if (deleteUserSuccess) {
            setDeleteUserModalDisplayed(undefined);
            setDeleteUserSuccessModalDisplayed(true);
        }
    }, [deleteUserSuccess]);

    useEffect(() => {
        if (deleteUserError) {
            setDeleteUserModalDisplayed(undefined);
            setDeleteUserErrorModalDisplayed(true);
        }
    }, [deleteUserError]);

    useEffect(() => {
        if (downloadUsersListError) {
            setDownloadUsersModalDisplayed(true);
        }
    }, [downloadUsersListError]);

    useEffect(() => {
        if (displayedUsers) {
            setColumns(
                getUsersTableColumns(
                    tableColumnLabels,
                    [
                        (direction) => onSortClick(UsersSortType.Email, direction),
                        (direction) => onSortClick(UsersSortType.FirstName, direction),
                        (direction) => onSortClick(UsersSortType.LastName, direction),
                    ],
                    reviewMessage,
                    [
                        userProfileMessage,
                        jobOffersMessage,
                        singleEmployeeOffersMessage,
                        blockUserMessage,
                        unblockUserMessage,
                        deleteUserMessage,
                    ],
                    // TODO: IWGM-733 Implement missing actions.
                    [
                        onUserProfileActionClick,
                        onUserJobOffersActionClick,
                        onUserEmployeeOffersActionClick,
                        openBlockUserModal,
                        openUnblockUserModal,
                        openDeleteUserModal,
                    ]
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedUsers]);

    useEffect(() => {
        const sortStatusChanged =
            fetchParams.sortBy !== prevFetchParams?.sortBy ||
            fetchParams.sortDirection !== prevFetchParams?.sortDirection;
        const filterStatusChanged = fetchParams.globalParam !== prevFetchParams?.globalParam;

        if (
            !usersLoadingError &&
            !sortStatusChanged &&
            !filterStatusChanged &&
            ((!users && !usersLoading) || !pageExists(fetchParams.pageSize, fetchParams.offset, users, totalUsers)) && fetchParams.offset !== 0
        ) {
            amendUsersAsync(fetchParams, createCancelToken());
        } else if (sortStatusChanged || filterStatusChanged || blockUserSuccess || deleteUserSuccess) {
            getUsersAsync(fetchParams, createCancelToken());
            // when we are on want to search we have to reset to the page 0, otherwise search won't work
        } else if (!users) {
            if (fetchParams.offset !== 0) {
                setFetchParams((prevState) => ({ ...prevState, offset: 0 }));
                return
            }
            getUsersAsync(fetchParams, createCancelToken());
        }
    }, [amendUsersAsync, blockUserSuccess, createCancelToken, deleteUserSuccess, deviceClass, fetchParams, getUsersAsync, prevFetchParams, totalUsers, users, usersLoading, usersLoadingError]);

    useEffect(() => {
        if (fetchParams.offset !== prevFetchParams?.offset && deviceClass === "desktop") {
            window.scrollTo(0, 0);
        }
    }, [deviceClass, fetchParams.offset, prevFetchParams]);

    useEffect(() => {
        if (prevDeviceClass !== "desktop" && deviceClass === "desktop") {
            setSearchHeadroomBottomPosition(0);
        }
    }, [deviceClass, prevDeviceClass]);

    useEffect(() => {
        if (listContainsGaps(users) && deviceClass !== "desktop" && prevDeviceClass === "desktop") {
            resetUsers();
            setFetchParams(getInitialGetUsersFetchParams(intl.locale as Locale));
        }
    }, [deviceClass, intl.locale, prevDeviceClass, resetUsers, users]);

    useEffect(() => () => resetUsers(), [resetUsers]);

    return (
        <div className={styles["users"]}>
            <div className={styles["users__header"]}>
                <h2>
                    <FormattedMessage id="admin-panel__users" />
                </h2>
            </div>
            <Button
                className={styles["users__download-users-button"]}
                onClick={handleDownloadUsersClick}
                disabled={downloadUsersListLoading}
            >
                <DownloadIcon />
                <FormattedMessage id="admin-panel__download-users" />
            </Button>
            <div className={styles["users__search-box"]}>
                <div className={styles["users__search-box-input"]} ref={searchBoxRef}>
                    <Input placeholder={searchForUserPlaceholder} label={searchForUserPlaceholder} value={searchValue} onChange={handleSearchChange} />
                </div>
                <div className={styles["users__search-box-button"]}>
                    <Button
                        data-testid={`${testId}__cancel-button`}
                        className={styles["users__search-button"]}
                        type="submit"
                        variant="tertiary"
                        onClick={onSearch}
                    >
                        <MagnifierIcon />
                        <FormattedMessage id="common__search" />
                    </Button>
                </div>
                {deviceClass !== "desktop" && (
                    <Headroom pinStart={450} onPositionChange={setSearchHeadroomBottomPosition}>
                        {(isVisible) => (
                            <div
                                className={classNames(styles["users__smartphone-headroom-mobile-box"], {
                                    [styles["users__smartphone-headroom-mobile-box--no-shadow"]]: !isVisible,
                                })}
                                id="headroom-search-button"
                            >
                                <Button
                                    as="a"
                                    variant="secondary"
                                    onClick={onMobileSearchClick}
                                    className={classNames(
                                        styles["users__search-button"],
                                        styles["users__search-button-pinned"]
                                    )}
                                >
                                    <MagnifierIcon />
                                    <FormattedMessage id="common__search" />
                                </Button>
                            </div>
                        )}
                    </Headroom>
                )}
            </div>
            <div className={styles["users__table-container"]}>
                <DataTable
                    columnsClassName={styles["users__table-column"]}
                    data={displayedUsers ?? []}
                    columns={columns}
                    idColumn="id"
                    isDataLoading={usersLoading}
                    withTopTransition
                    withOnSortScroll
                    headerStickTop={searchHeadroomBottomPosition}
                />
            </div>
            {users && totalUsers !== undefined && (
                <Pagination
                    loadedItemsCount={users.length}
                    totalItemsCount={totalUsers}
                    currentPage={fetchParams.offset + 1}
                    pageSize={fetchParams.pageSize}
                    onPageChange={(pageIndex) => setFetchParams((prev) => ({ ...prev, offset: pageIndex - 1 }))}
                />
            )}
            {blockUserModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__block-user-title" })}
                        </span>
                    }
                    content={
                        user?.id === blockUserModalDisplayed
                            ? intl.formatMessage({ id: "admin-panel__cannot-block-yourself" })
                            : intl.formatMessage({ id: "admin-panel__block-user-text" })
                    }
                    primaryButtonContent={intl.formatMessage({ id: "common__yes" })}
                    secondaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={closeBlockUserModal}
                    primaryAction={handleBlockUser}
                    secondaryAction={closeBlockUserModal}
                    primaryButtonDisabled={user?.id === blockUserModalDisplayed}
                    primaryButtonLoading={blockUserLoading}
                />
            )}
            {unblockUserModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__unblock-user-title" })}
                        </span>
                    }
                    content={
                        user?.id === blockUserModalDisplayed
                            ? intl.formatMessage({ id: "admin-panel__cannot-unblock-yourself" })
                            : intl.formatMessage({ id: "admin-panel__unblock-user-text" })
                    }
                    primaryButtonContent={intl.formatMessage({ id: "common__yes" })}
                    secondaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={closeUnblockUserModal}
                    primaryAction={handleUnblockUser}
                    secondaryAction={closeUnblockUserModal}
                    primaryButtonDisabled={user?.id === unblockUserModalDisplayed}
                    primaryButtonLoading={unblockUserLoading}
                />
            )}
            {deleteUserModalDisplayed && (
                <ModalWithContent
                    className={styles["users__delete-user-modal"]}
                    header={
                        <span className={styles["users__delete-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__delete-user-title" })}
                        </span>
                    }
                    content={
                        user?.id === deleteUserModalDisplayed
                            ? intl.formatMessage({ id: "admin-panel__cannot-delete-yourself" })
                            : intl.formatMessage({ id: "admin-panel__delete-user-text" })
                    }
                    primaryButtonContent={intl.formatMessage({ id: "common__yes" })}
                    secondaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={closeDeleteUserModal}
                    primaryAction={handleDeleteUser}
                    secondaryAction={closeDeleteUserModal}
                    primaryButtonDisabled={user?.id === blockUserModalDisplayed}
                    primaryButtonLoading={deleteUserLoading}
                />
            )}
            {userBlockedModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__user-blocked-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__user-blocked-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeUserBlockedModal}
                    primaryAction={closeUserBlockedModal}
                    cancelButtonHidden
                />
            )}
            {userUnblockedModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__user-unblocked-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__user-unblocked-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeUserUnblockedModal}
                    primaryAction={closeUserUnblockedModal}
                    cancelButtonHidden
                />
            )}
            {blockUserSuccessModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__block-user-success-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__block-user-success-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeBlockUserSuccessModal}
                    primaryAction={closeBlockUserSuccessModal}
                    cancelButtonHidden
                />
            )}
            {unblockUserSuccessModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__unblock-user-success-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__unblock-user-success-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeUnblockUserSuccessModal}
                    primaryAction={closeUnblockUserSuccessModal}
                    cancelButtonHidden
                />
            )}
            {blockUserErrorModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__block-user-error-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__block-user-error-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeBlockUserErrorModal}
                    primaryAction={closeBlockUserErrorModal}
                    cancelButtonHidden
                />
            )}
            {unblockUserErrorModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__block-user-error-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__unblock-user-error-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeUnblockUserErrorModal}
                    primaryAction={closeUnblockUserErrorModal}
                    cancelButtonHidden
                />
            )}
            {deleteUserSuccessModalDisplayed && (
                <ModalWithContent
                    className={styles["users__delete-user-modal"]}
                    header={
                        <span className={styles["users__delete-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__delete-user-success-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__delete-user-success-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeDeleteUserSuccessModal}
                    primaryAction={closeDeleteUserSuccessModal}
                    cancelButtonHidden
                />
            )}
            {deleteUserErrorModalDisplayed && (
                <ModalWithContent
                    className={styles["users__delete-user-modal"]}
                    header={
                        <span className={styles["users__delete-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__delete-user-error-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__delete-user-error-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeDeleteUserErrorModal}
                    primaryAction={closeDeleteUserErrorModal}
                    cancelButtonHidden
                />
            )}
            {downloadUsersModalDisplayed && (
                <ModalWithContent
                    className={styles["users__block-user-modal"]}
                    header={
                        <span className={styles["users__block-user-title"]}>
                            {intl.formatMessage({ id: "admin-panel__download-users-list-error-title" })}
                        </span>
                    }
                    content={intl.formatMessage({ id: "admin-panel__download-users-list-error-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeBlockUserErrorModal}
                    primaryAction={closeBlockUserErrorModal}
                    cancelButtonHidden
                />
            )}
        </div>
    );
};

export default UsersComponent;
