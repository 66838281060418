import { ReactComponent as NextIcon } from "assets/icons/next.svg";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";
import styles from "./styles.module.scss";

export interface Props {
    loadedItemsCount: number;
    totalItemsCount: number;
    currentPage: number;
    pageSize: number;
    className?: string;
    ["data-testid"]?: string;
    onPageChange: (pageIndex: number) => void;
}

const Pagination = ({
    loadedItemsCount: loadedResults,
    currentPage,
    totalItemsCount: total = 0,
    pageSize,
    className,
    "data-testid": testId,
    onPageChange,
}: Props) => {
    const deviceClass = useDeviceClass();
    const numberOfPages = Math.ceil(total / pageSize);
    const onLocalPageChange = (selectedPage: number) => {
        selectedPage !== currentPage && onPageChange(selectedPage);
    };


    return numberOfPages === 1 ||
        (pageSize * currentPage >= total && deviceClass !== "desktop") ? null : deviceClass === "desktop" ? (
            <div data-testid={`${testId}__desktop`} className={classNames(styles["pagination"], className)}>
                <ReactPaginate
                    pageCount={numberOfPages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    containerClassName={styles["pagination__button-list"]}
                    pageClassName={styles["pagination__button"]}
                    activeClassName={styles["pagination__button--active"]}
                    previousLabel={<PreviousIcon />}
                    nextLabel={<NextIcon />}
                    onPageChange={(item) => onLocalPageChange(item.selected + 1)}
                    initialPage={currentPage - 1}
                    nextClassName={styles["pagination__prev-next-buttons"]}
                    previousClassName={styles["pagination__prev-next-buttons"]}
                />
            </div>
        ) : (
        <div data-testid={`${testId}__smartphone`} className={classNames(styles["pagination"], className)}>
            <div className={styles["pagination__indicator"]}>
                <span>
                    {loadedResults}
                    <FormattedMessage id="pagination__from" />
                    {total}
                    <FormattedMessage id="pagination__offers" />
                </span>
                <div>
                    <span style={{ transform: `translateX(${(loadedResults / total) * 100 - 100}%)` }} />
                </div>
            </div>
            {loadedResults !== total && (
                <Button onClick={() => onLocalPageChange(currentPage + 1)}>
                    <FormattedMessage id="pagination__load-more" />
                </Button>
            )}
        </div>
    );
};

export default Pagination;
