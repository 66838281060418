import { OfferDto, OffersDto } from "../dtos";
import { GetOfferFetchParams } from "../types";

export const mapGetOffersFetchParamsToQueryParameters = (params: GetOfferFetchParams) => {
    let queryParams = `?size=${params.pageSize}&page=${params.offset}`;

    if (params.sortBy !== undefined) {
        queryParams += `&sort_by=${params.sortBy}`;

        params.sortDirection !== undefined && (queryParams += `&sort_order=${params.sortDirection}`);
    }

    if (params.globalParam) {
        queryParams += `&filterValue=${params.globalParam}`;
    }

    return queryParams;
};

export const mapOffersDtoToOffersList = (dto: OffersDto): OfferDto[] =>
    dto.data.content.map((item) => ({
        added_date: item.added_date,
        id: item.id,
        offer_owner_mail: item.offer_owner_mail,
        offer_title: item.offer_title,
    }));