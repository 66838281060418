import { DeviceClass } from "features/common/types";
import React from "react";
import DateDisplay from "./DateDisplay";
import styles from "./styles.module.scss";

interface Props {
    id: string;
    calendarExpanded: boolean;
    deviceClass: DeviceClass;
    date?: Date;
    endDate?: Date;
    label?: string;
    endDateLabel?: string;
    placeholder?: string;
    endDatePlaceholder?: string;
    error?: string;
    endDateError?: string;
    helperMessages?: (string | React.ReactElement)[];
    endDateHelperMessages?: (string | React.ReactElement)[];
    withRangePicker?: boolean;
    onClick: () => void;
    ["data-testid"]: string;
}

const DateDisplays = ({
    "data-testid": testId,
    id,
    calendarExpanded,
    deviceClass,
    date,
    endDate,
    label,
    endDateLabel,
    placeholder,
    endDatePlaceholder,
    error,
    endDateError,
    helperMessages,
    endDateHelperMessages,
    withRangePicker,
    onClick,
}: Props) => {
    return (
        <div className={styles["date-displays"]}>
            <DateDisplay
                data-testid={testId}
                id={id}
                label={label}
                placeholder={placeholder}
                deviceClass={deviceClass}
                date={date}
                calendarExpanded={calendarExpanded}
                error={error}
                helperMessages={helperMessages}
                onClick={onClick}
            />
            {withRangePicker && (
                <DateDisplay
                    className={styles["date-displays__end-date-display"]}
                    data-testid={testId}
                    id={`${id}__end-display`}
                    label={endDateLabel}
                    placeholder={endDatePlaceholder}
                    deviceClass={deviceClass}
                    date={endDate}
                    calendarExpanded={calendarExpanded}
                    onClick={onClick}
                    error={endDateError}
                    helperMessages={endDateHelperMessages}
                />
            )}
        </div>
    );
};

export default DateDisplays;
