import classNames from "classnames";
import Portal from "features/common/components/Portal";
import {modalsDivId} from "features/common/constants";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
    containerClassName?: string;
    parentId?: string;
    ["data-testid"]?: string;
}

const Modal = ({ children, className, containerClassName, parentId = modalsDivId, "data-testid": testId }: Props) => (
    <Portal parentId={parentId} data-testid={`${testId}__portal`}>
        <div data-testid={testId} className={classNames(styles["modal"], containerClassName)}>
            <div className={classNames(styles["modal__content"], className)}>{children}</div>
        </div>
    </Portal>
);

export default Modal;

// import classNames from "classnames";
// import Portal from "features/common/components/Portal";
// import {modalsDivId} from "features/common/constants";
// import React, {useEffect, useState} from "react";
// import ReactDOM from "react-dom";
// import styles from "./styles.module.scss";

// export interface Props {
//     show?: boolean;
//     onClose: () => void;
//     children: React.ReactElement | React.ReactElement[];
//     className?: string;
//     containerClassName?: string;
//     parentId?: string;
//     ["data-testid"]?: string;
// }

// const Modal = ({ show, onClose, children, className, containerClassName, parentId = modalsDivId, "data-testid": testId }: Props) => {

//     const [isBrowser, setIsBrowser] = useState(false);
//     useEffect(() => {
//         setIsBrowser( true );
        
//         window.addEventListener('keydown', onImagesClick)
    
//         return () => {
//           window.addEventListener('keydown', onImagesClick)
//         }
//       }, [] );
    
//       const onImagesClick = (e: KeyboardEvent) => {
//         if (e.code === 'Escape') {
//           onClose()
//         }
//       }
      
//     const modalContent = show && (
//     <Portal parentId={parentId} data-testid={`${testId}__portal`}>
//         <div data-testid={testId} className={classNames(styles["modal"], containerClassName)}>
//             <div className={classNames(styles["modal__content"], className)}>{children}</div>
//         </div>
//     </Portal>)

//     if (isBrowser) {
//         return ReactDOM.createPortal(
//         modalContent,
//         document.getElementById("modal-root")!
//         );
//     } else {
//         return null;
//   }
// };

// export default Modal;