import React from "react";
import LoginComponent from "features/user/components/Login";
import styles from "./styles.module.scss";

const Login = () => {
    return (
        <div className={styles["login"]}>
            <LoginComponent />
        </div>
    );
};

export default Login;
