import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

import { selectUserProfile, selectUserProfileLoading, selectUserProfileError } from "features/user/selectors";
import { getUserProfileAsync } from "features/user/thunks";

import UserProfileComponent from "./component";

const mapStateToProps = (state: RootState) => ({
    userProfile: selectUserProfile(state),
    userProfileLoading: selectUserProfileLoading(state),
    userProfileError: selectUserProfileError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getUserProfileAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);
