import { answerCallAction, leaveCallAction, makeCallAction } from "features/getCall/actions";
import { withGetCall } from "features/getCall/HOC/withGetCall";
import { selectGetCallState } from "features/getCall/selectors";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import VideoPlayer from "./component";

const mapStateToProps = (state: RootState) => ({
    ...selectGetCallState(state),
    user: selectUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            answerCallAction: () => answerCallAction(),
            leaveCallAction: () => leaveCallAction(),
            makeCallAction: () => makeCallAction(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withGetCall(VideoPlayer));
