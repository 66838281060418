import {
    selectBranches,
    selectBranchesLoading,
    selectCurrencies,
    selectCurrenciesLoading,
    selectCurrenciesLoadingError,
} from "features/common/selectors";
import { getBranchesAsync, getCurrenciesAsync } from "features/common/thunks";
import {
    selectJobOffersLoadingError,
} from "features/jobOffers/selectors";
import OffersList from "features/offers/components/OffersList";
import { selectIsUserLoggedIn } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => {
    return ({
        asFavoriteOffersList: true,
        branches: selectBranches(state),
        branchesLoading: selectBranchesLoading(state),
        branchesLoadingError: selectJobOffersLoadingError(state),
        isUserLoggedIn: selectIsUserLoggedIn(state),
        currencies: selectCurrencies(state),
        currenciesLoading: selectCurrenciesLoading(state),
        currenciesLoadingError: selectCurrenciesLoadingError(state),
    })
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getBranchesAsync,
            getCurrenciesAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
