import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ApiError, CancelToken } from "api/utils";
import Button from "features/common/components/Button";
import Modal from "features/common/components/Modal";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TextArea from "features/common/components/TextArea";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getSendMessageInformationMessage } from "features/offers/translationMessages";

import styles from "./styles.module.scss";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";

const maxMessageLength = 250;

export interface Props {
    id: number;
    rejectApplicationLoading: boolean;
    rejectApplicationSuccess?: boolean;
    rejectApplicationError?: ApiError;
    rejectApplicationAsync: (id: number, message?: string, cancelToken?: CancelToken) => void;
    handleCloseModal: () => void;
    isEmployee?: boolean;
}

const RejectApplicationModal = ({
    id,
    rejectApplicationLoading,
    rejectApplicationSuccess,
    rejectApplicationError,
    rejectApplicationAsync,
    handleCloseModal,
    isEmployee,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();
    const [messageValue, setMessageValue] = useState("");

    const onMessageChange = useCallback((event) => setMessageValue(event.currentTarget.value), [setMessageValue]);
    const handleSubmit = useCallback(() => {
        rejectApplicationAsync(id, messageValue, createCancelToken());
    }, [rejectApplicationAsync, createCancelToken, id, messageValue]);

    const sendMessageInformation = getSendMessageInformationMessage(intl, maxMessageLength);

    useEffect(() => {
        if (rejectApplicationSuccess) {
            handleCloseModal();
        }
    }, [rejectApplicationSuccess, handleCloseModal]);

    return (
        <Modal className={styles["reject-application-modal"]} parentId="reject-application-modal">
            {rejectApplicationLoading ? (
                <SpinnerWithMessage
                    message={<FormattedMessage id="reject-application-modal__lodaing-reject-application" />}
                />
            ) : (
                <div className={styles["reject-application-modal__container"]}>
                    <div className={styles["reject-application-modal__close-button"]} onClick={handleCloseModal}>
                        <TimesIcon />
                    </div>
                    <div className={styles["reject-application-modal__title"]}>
                        <FormattedMessage id="reject-application-modal__title" />
                    </div>
                    <div className={styles["reject-application-modal__subtitle"]}>
                        <FormattedMessage id="reject-application-modal__subtitle" values={{ userType: isEmployee ? "EMPLOYEE" : "EMPLOYER" }} />
                    </div>
                    <TextArea
                        maxSize={maxMessageLength}
                        placeholder={intl.formatMessage({ id: "reject-application-modal__message-placeholder" }, { userType: isEmployee ? "EMPLOYEE" : "EMPLOYER" })}
                        value={messageValue}
                        error={
                            !!rejectApplicationError
                                ? intl.formatMessage({ id: "reject-application-modal__acceptation-error" })
                                : ""
                        }
                        onChange={onMessageChange}
                        withValidation
                        isInvalid={!!rejectApplicationError}
                        data-testid="reject-application-modal__message-modal"
                        className={styles["reject-application-modal__text-area"]}
                        customWarningMessage={sendMessageInformation}
                    />
                    <div className={styles["reject-application-modal__buttons-container"]}>
                        <Button
                            data-testid="reject-application-modal__button"
                            className={styles["reject-application-modal__button"]}
                            type="submit"
                            variant="tertiary"
                            onClick={handleCloseModal}
                        >
                            <FormattedMessage id="reject-application-modal__cancel" />
                        </Button>
                        <Button
                            data-testid="reject-application-modal__button"
                            className={styles["reject-application-modal__button"]}
                            type="submit"
                            onClick={handleSubmit}
                        >
                            <FormattedMessage id="reject-application-modal__accept" />
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default RejectApplicationModal;
