import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "store";
import {
    selectEmployeeRatingSummaryLoading,
    selectEmployeeRatingSummarySuccess,
    selectEmployeeRatingSummaryError, selectEmployeeRatingSummaryData,
} from "features/rating/selectors";
import { getEmployeeRateAsync } from "features/rating/thunks";

import {
    selectJobOfferDetails, selectJobOfferDetailsLoading, selectJobOffersLoadingError,
} from "features/jobOffers/selectors";
import { getApplicationDetailsAsync, getJobOfferDetailsAsync } from "features/jobOffers/thunks";
import { selectUser } from 'features/user/selectors';
import RateEmployeeSummary from "./component";

const mapStateToProps = (state: RootState) => ({
    jobOfferDetails: selectJobOfferDetails(state),
    jobOfferDetailsLoading: selectJobOfferDetailsLoading(state),
    jobOfferDetailsError: selectJobOffersLoadingError(state),
    employeeRatingSummaryLoading: selectEmployeeRatingSummaryLoading(state),
    employeeRatingSummarySuccess: selectEmployeeRatingSummarySuccess(state),
    employeeRatingSummaryError: selectEmployeeRatingSummaryError(state),
    user: selectUser(state),
    employeeRatingSummaryData: selectEmployeeRatingSummaryData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getJobOfferDetailsAsync,
            getApplicationDetailsAsync,
            getEmployeeRateAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RateEmployeeSummary);
