import { useEffect } from "react";

const useResize = (callback: () => void, refs: any[] = []) => {
    useEffect(() => {
        window.addEventListener("resize", callback);

        return () => {
            window.removeEventListener("resize", callback);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...refs]);
};

export default useResize;
