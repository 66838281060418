import React from "react";
import { useParams } from "react-router-dom";
import { OfferType } from "features/common/types";
import CardDetails from "features/myProfile/components/CardDetails";
import styles from "./styles.module.scss";

interface Props {
    uniqueId?: string;
}

const CreateJobOfferCardDetails = () => {
    const { uniqueId } = useParams<Props>();

    return (
        <div className={styles["card"]}>
            <div className={styles["card__inner-container"]}>
                <CardDetails uniqueId={uniqueId} creatingOffer={OfferType.Job} />
            </div>
        </div>
    );
};

export default CreateJobOfferCardDetails;
