import { ApiError, CancelToken } from "api/utils";
import { Statistics } from "features/adminPanel/model";
import Spinner from "features/common/components/Spinner";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    statistics?: Statistics;
    statisticsLoading?: boolean;
    statisticsLoadingError: ApiError;
    getStatisticsAsync: (cancelToken?: CancelToken) => void;
}

const StatisticsComponent = ({
    "data-testid": testId = "statistics",
    statistics,
    statisticsLoading,
    statisticsLoadingError,
    getStatisticsAsync,
}: Props) => {
    const showStatistics = statistics && !statisticsLoading;
    const cancelTokenCreator = useCreateCancelToken();

    useEffect(() => {
        getStatisticsAsync(cancelTokenCreator());
    }, [cancelTokenCreator, getStatisticsAsync]);

    return (
        <div className={styles["statistics"]}>
            {statisticsLoading && <Spinner thickness="thin" />}
            {statisticsLoadingError && (
                <div>
                    <FormattedMessage id="admin-panel__statistics-fetch-error" />
                </div>
            )}
            {showStatistics && (
                <>
                    <div className={styles["statistics__header"]}>
                        <h2>
                            <FormattedMessage id="admin-panel__statistics" />
                        </h2>
                    </div>
                    <div className={styles["statistics__content"]}>
                        <div className={styles["statistics__column-header"]}>
                            <FormattedMessage id="common__job-offers" />
                        </div>
                        <div className={styles["statistics__row"]}>
                            <div className={styles["statistics__field-name"]}>
                                <span>
                                    <FormattedMessage id="admin-panel__number-of-all-job-offers" />
                                </span>
                            </div>
                            <div className={styles["statistics__field-value"]}>
                                <span>{statistics?.allJobOffers}</span>
                            </div>
                        </div>
                        <div className={styles["statistics__row"]}>
                            <div className={styles["statistics__field-name"]}>
                                <span>
                                    <FormattedMessage id="admin-panel__number-of-active-job-offers" />
                                </span>
                            </div>
                            <div className={styles["statistics__field-value"]}>
                                <span>{statistics?.allActiveJobOffers}</span>
                            </div>
                        </div>
                        <div className={styles["statistics__column-header"]}>
                            <span>
                                <FormattedMessage id="common__employee-offers" />
                            </span>
                        </div>
                        <div className={styles["statistics__row"]}>
                            <div className={styles["statistics__field-name"]}>
                                <span>
                                    <FormattedMessage id="admin-panel__number-of-all-offers" />
                                </span>
                            </div>
                            <div className={styles["statistics__field-value"]}>
                                <span>{statistics?.allEmployeeOffers}</span>
                            </div>
                        </div>
                        <div className={styles["statistics__row"]}>
                            <div className={styles["statistics__field-name"]}>
                                <span>
                                    <FormattedMessage id="admin-panel__number-of-active-offers" />
                                </span>
                            </div>
                            <div className={styles["statistics__field-value"]}>
                                <span>{statistics?.allActiveEmployeeOffers}</span>
                            </div>
                        </div>
                        <div className={styles["statistics__column-header"]}>
                            <span>
                                <FormattedMessage id="admin-panel__users" />
                            </span>
                        </div>
                        <div className={styles["statistics__row"]}>
                            <div className={styles["statistics__field-name"]}>
                                <span>
                                    <FormattedMessage id="admin-panel__number-of-users" />
                                </span>
                            </div>
                            <div className={styles["statistics__field-value"]}>
                                <span>{statistics?.userCount}</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default StatisticsComponent;
