import React from "react";
import styles from "./styles.module.scss";
import { useTranslate } from "../../../../../../hooks/useTranslate";

export interface Props {
    label: string;
    value?: JSX.Element | null;
    ["data-testid"]?: string;
    isTranslatable: boolean;
}

const RequirementBlock = ({ label, value, isTranslatable, "data-testid": testId = "requirement-block" }: Props) => {
    const { translatedText, handleTranslate, translationIcon } = useTranslate(label);
    return (
        <div data-testid={testId} className={styles["requirement-block"]}>
            <div className={styles["requirement-block__header"]}>
                <div
                    data-testid={testId ? `${testId}__header-label` : undefined}
                    className={styles["requirement-block__header-label"]}
                >
                    {translatedText || label}
                    { isTranslatable &&
                        <div className={styles["general-info-part__translation"]} onClick={handleTranslate}>
                        { translationIcon }
                        </div>
                    }
                </div>
            </div>
            {value && (
                <div data-testid={`${testId}__content`} className={styles["requirement-block__content"]}>
                    {value}
                </div>
            )}
        </div>
    );
};

export default RequirementBlock;
