import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as EmptyCardsIcon } from "assets/icons/emptyCards.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { AxiosError } from "axios";
import Accordion from "features/common/components/Accordion";
import Button from "features/common/components/Button";
import Spinner from "features/common/components/Spinner";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { MyProfileCards } from "features/myProfile/models";
import { getMyProfileMyCardsIntlMessage } from "features/myProfile/translationMessages";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ModalWithContent from "../../../common/components/ModalWithContent";
import ErrorHeader from "../../../common/components/ModalWithContent/ErrorHeader";
import SingleCard from "./SingleCard";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    user?: User;
    myProfileCards?: MyProfileCards;
    areMyProfileCardsLoading: boolean;
    myProfileCardsLoadingError?: ApiError;
    isUserLoading?: boolean;
    userLoadingError?: ApiError;
    getMyProfileCardsAsync: (cancelToken?: CancelToken) => void;
    deleteCardAsync: (cardID: string, cancelToken?: CancelToken) => void;
    deleteCardError?: AxiosError;
}

const MyCardsComponent = ({
    "data-testid": testId,
    user,
    myProfileCards,
    areMyProfileCardsLoading,
    myProfileCardsLoadingError,
    isUserLoading,
    userLoadingError,
    getMyProfileCardsAsync,
    deleteCardAsync,
    deleteCardError
}: Props) => {
    const [deleteError, setDeleteError] = useState<boolean>(false)
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();

    const myCardsDescription = getMyProfileMyCardsIntlMessage(intl);

    const isFormLoading = isUserLoading || areMyProfileCardsLoading;
    const showFormData = (user || myProfileCards) && !isFormLoading;

    const onAddNewCardButtonClicked = () => {
        history.push(appRoutes.newCard);
    };

    useEffect(() => {
        getMyProfileCardsAsync(createCancelToken());
    }, [createCancelToken, getMyProfileCardsAsync]);

    useEffect(() => {
        if (deleteCardError) {
            setDeleteError(true)
        }
    }, [deleteCardError])

    useEffect(() => {
        setDeleteError(false)
    }, [])

    const handleCloseErrorModal = () => setDeleteError(false);
    // @ts-ignore
    const deleteMessage = (deleteCardError?.response?.status === 500) ? 'login-form__error-500' : deleteCardError?.response?.data.message

    return (
        <div className={styles["my-cards"]}>
            {deleteError && (
                <ModalWithContent
                    className={styles["my-cards__modal"]}
                    header={
                        <ErrorHeader>
                            <FormattedMessage id="my-cards__delete-error-title" />
                        </ErrorHeader>
                    }
                    content={intl.formatMessage({ id: deleteMessage })}
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseErrorModal}
                    primaryAction={handleCloseErrorModal}
                />
            )}
            {isFormLoading && <Spinner thickness="thin" />}
            {userLoadingError && (
                <div>
                    <FormattedMessage id="my-data__user-fetch-failed" />
                </div>
            )}
            {myProfileCardsLoadingError && (
                <div>
                    <FormattedMessage id="my-data__user-cards-fetch-failed" />
                </div>
            )}
            {showFormData && (
                <>
                    <div className={styles["my-cards__header"]}>
                        <h2 className={styles["my-cards__main-header"]}>
                            <FormattedMessage id="my-cards__header" />
                        </h2>
                    </div>
                    <div className={styles["my-cards__description-container"]}>
                        <span>{myCardsDescription}</span>
                    </div>
                    <div className={styles["my-cards__button-container"]}>
                        <Button
                            as="div"
                            className={styles["my-cards__add-card-button"]}
                            onClick={onAddNewCardButtonClicked}
                        >
                            <PlusIcon />
                            <FormattedMessage id="my-cards__add-card" />
                        </Button>
                    </div>
                    <div className={styles["my-cards__cards-list"]}>
                        {myProfileCards?.employee.length === 0 && myProfileCards.employer.length === 0 && (
                            <>
                                <div className={styles["my-cards__empty-icon"]}>
                                    <EmptyCardsIcon />
                                </div>
                                <div className={styles["my-cards__primary-footer"]}>
                                    <FormattedMessage id="my-cards__you-have-no-cards" />
                                </div>
                                <div className={styles["my-cards__secondary-footer"]}>
                                    <FormattedMessage id="my-cards__add-your-first-card" />
                                </div>
                            </>
                        )}
                        {myProfileCards?.employer.length !== 0 && (
                            <Accordion
                                data-testid={`${testId}__companies-accordion`}
                                accordionClassName={styles["my-cards__accordion"]}
                                headerContentClassName={styles["my-cards__header-content"]}
                                headerButtonClassName={styles["my-cards__header-button"]}
                                bodyContentClassName={styles["my-cards__body-content"]}
                                openOnHeaderClick
                                openOnStart
                            >
                                <div className={styles["my-cards__group-header"]}>
                                    <div className={styles["my-cards__group-title-container"]}>
                                        <h2>
                                            <FormattedMessage id="my-profile__my-cards-employer-card" />
                                        </h2>
                                    </div>
                                </div>
                                <div className={styles["my-cards__single-cards-container"]}>
                                    {myProfileCards?.employer.map(
                                        (card) =>
                                            card && (
                                                <SingleCard
                                                    card={card}
                                                    user={user}
                                                    key={card.id}
                                                    data-testid={`${testId}__single-card`}
                                                    isEmployer
                                                    deleteCardAsync={deleteCardAsync}
                                                />
                                            )
                                    )}
                                </div>
                            </Accordion>
                        )}
                        {myProfileCards?.employee.length !== 0 && (
                            <Accordion
                                data-testid={`${testId}__privates-accordion`}
                                accordionClassName={styles["my-cards__accordion"]}
                                headerContentClassName={styles["my-cards__header-content"]}
                                headerButtonClassName={styles["my-cards__header-button"]}
                                bodyContentClassName={styles["my-cards__body-content"]}
                                openOnHeaderClick
                                openOnStart
                            >
                                <div className={styles["my-cards__group-header"]}>
                                    <div className={styles["my-cards__group-title-container"]}>
                                        <h2>
                                            <FormattedMessage id="my-profile__my-cards-employee-card" />
                                        </h2>
                                    </div>
                                </div>
                                <div className={styles["my-cards__single-cards-container"]}>
                                    {myProfileCards?.employee.map(
                                        (card) =>
                                            card && (
                                                <SingleCard
                                                    card={card}
                                                    user={user}
                                                    key={card.id}
                                                    data-testid={`${testId}__single-card`}
                                                    deleteCardAsync={deleteCardAsync}
                                                />
                                            )
                                    )}
                                </div>
                            </Accordion>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default MyCardsComponent;
