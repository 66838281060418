import { ReactComponent as RotateIcon } from "assets/icons/screenRotation.svg";
import config from "config";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useViewportSize from "features/common/hooks/useViewportSize";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    children: string | React.ReactNode;
}

const OrientationLocksScreen = ({ children }: Props) => {
    const { height } = useViewportSize();
    const deviceClass = useDeviceClass();

    const orientation = window.screen?.orientation?.type ?? "";
    const lockOrientation =
        orientation.includes("landscape") && height <= config.minimalAppHeight && deviceClass === "smartphone";

    return lockOrientation ? (
        <div className={styles["orientation-lock-screen"]}>
            <RotateIcon />
            <span className={styles["orientation-lock-screen__info-primary"]}>
                <FormattedMessage id="orientation-lock-screen__info-primary" />
            </span>
            <span className={styles["orientation-lock-screen__info-secondary"]}>
                <FormattedMessage id="orientation-lock-screen__info-secondary" />
            </span>
        </div>
    ) : (
        <>{children}</>
    );
};

export default OrientationLocksScreen;
