import {createSelector} from "reselect";
import {RootState} from "store";

const selectState = (state: RootState) => state.adminPanel;

export const selectStatistics = createSelector(selectState, (state) => state.statistics);
export const selectStatisticsLoading = createSelector(selectState, (state) => state.statisticsLoading);
export const selectStatisticsLoadingError = createSelector(selectState, (state) => state.statisticsLoadingError);

export const selectUsers = createSelector(selectState, (state) => state.users);
export const selectUsersLoading = createSelector(selectState, (state) => state.usersLoading);
export const selectUsersLoadingError = createSelector(selectState, (state) => state.usersLoadingError);
export const selectTotalUsers = createSelector(selectState, (state) => state.totalUsers);

export const selectBlockUserSuccess = createSelector(selectState, (state) => state.blockUserSuccess);
export const selectBlockUserLoading = createSelector(selectState, (state) => state.blockUserLoading);
export const selectBlockUserError = createSelector(selectState, (state) => state.blockUserError);

export const selectUnblockUserSuccess = createSelector(selectState, (state) => state.unblockUserSuccess);
export const selectUnblockUserLoading = createSelector(selectState, (state) => state.unblockUserLoading);
export const selectUnblockUserError = createSelector(selectState, (state) => state.unblockUserError);

export const selectDeleteUserSuccess = createSelector(selectState, (state) => state.deleteUserSuccess);
export const selectDeleteUserLoading = createSelector(selectState, (state) => state.deleteUserLoading);
export const selectDeleteUserError = createSelector(selectState, (state) => state.deleteUserError);

export const selectDownloadUsersListSuccess = createSelector(selectState, (state) => state.downloadUsersListSuccess);
export const selectDownloadUsersListLoading = createSelector(selectState, (state) => state.downloadUsersListLoading);
export const selectDownloadUsersListError = createSelector(selectState, (state) => state.downloadUsersListError);

export const selectUserOffers = createSelector(selectState, (state) => state.userOffers);
export const selectUserOffersLoading = createSelector(selectState, (state) => state.userOffersLoading);
export const selectUserOffersError = createSelector(selectState, (state) => state.userOffersError);
export const selectTotalUserOffers = createSelector(selectState, (state) => state.totalUserOffers);

export const selectDeleteUserOfferSuccess = createSelector(selectState, (state) => state.deleteUserOfferSuccess);
export const selectDeleteUserOfferLoading = createSelector(selectState, (state) => state.deleteUserOfferLoading);
export const selectDeleteUserOfferError = createSelector(selectState, (state) => state.deleteUserOfferError);
