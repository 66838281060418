import { FileType } from "features/common/types";

export const generateYearsArray = (baseDate: Date, rowsBackToGenerate: number, totalRowsCount: number) => {
    const yearsArray: Array<Array<number>> = [];
    let year = baseDate.getFullYear() - rowsBackToGenerate * 3 - 1;

    for (let i = 0; i < totalRowsCount; i++) {
        yearsArray[i] = [];
        for (let j = 0; j < 3; j++) {
            yearsArray[i][j] = year++;
        }
    }

    return yearsArray;
};

export const generateDaysOfMonthArray = (year: number, month: number) => {
    const baseDate = new Date(year, month);

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfWeek = (((baseDate.getDay() - 1) % 7) + 7) % 7;
    const weeksInMonth = Math.ceil((daysInMonth + firstDayOfWeek + 1) / 7);

    let daysArray: Array<Array<number | undefined>> = [];
    let counter = -firstDayOfWeek;

    for (let i = 0; i < weeksInMonth; i++) {
        daysArray[i] = [];
        for (let j = 0; j < 7; j++) {
            daysArray[i][j] = counter >= 0 && counter < daysInMonth ? counter + 1 : undefined;
            counter++;
        }
    }

    daysArray = daysArray.filter((row) => row.reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0) !== 0);

    return daysArray;
};

export const getNextMonthWithYear = (month: number, year: number) => {
    if (month === 11) {
        return [0, year + 1];
    } else {
        return [month + 1, year];
    }
};

export const getPrevMonthWithYear = (month: number, year: number) => {
    if (month === 0) {
        return [11, year - 1];
    } else {
        return [month - 1, year];
    }
};

export const getMonthAndYearWithSubtractedMonths = (month: number, year: number, monthsToSubtract: number) => {
    for (let i = 0; i < monthsToSubtract; i++) {
        [month, year] = getPrevMonthWithYear(month, year);
    }

    return [month, year];
};

export const getMonthsAbsoluteDifference = (a: Date, b: Date) => {
    if (a.getFullYear() === b.getFullYear()) {
        return Math.abs(a.getMonth() - b.getMonth());
    } else if (a.getFullYear() < b.getFullYear()) {
        return 11 - a.getMonth() + b.getMonth() + (Math.abs(a.getFullYear() - b.getFullYear()) - 1) * 12;
    } else {
        return 11 - b.getMonth() + a.getMonth() + (Math.abs(a.getFullYear() - b.getFullYear()) - 1) * 12;
    }
};

export const isFileExtensionValid = (files: File[], allowedTypes: FileType[]) => {
    let isValid = true;

    files.forEach((current) => {
        const file = current.name.split(".");
        const fileExtension = ".".concat(file[file.length - 1]).toLowerCase() as FileType;
        const fileType = current.type;

        if (!fileType.includes("image/") || !allowedTypes.includes(fileExtension)) {
            isValid = false;
        }
    });

    return isValid;
};

export const isFileSizeValid = (files: File[], maxSize: number) => {
    let isValid = true;

    files.forEach((current) => {
        const fileSize = current.size;

        if (fileSize > maxSize) {
            isValid = false;
        }
    });

    return isValid;
};

export const extractOffsetFromQuerySearch = (querySearch: string) => {
    const offsetMatch = querySearch.match(new RegExp("offset=[\\d]+"));
    if (offsetMatch) {
        return parseInt(offsetMatch[0].split("=")[1]);
    }
};

export const replaceCurrentUrl = (newUrl: string) => window.history.replaceState(null, document.title, newUrl);

export const parseInteger = (input: string, maxValue: number = 999999999) => {
    if (input === "") {
        return;
    } else {
        const value = parseInt(input);
        return value !== Number.NaN && value < maxValue ? value : null;
    }
};

export const range = (range: number, startIndex?: number) =>
    Array(range)
        .fill(0)
        .map((_, index) => (startIndex ?? 0) + index);

export const isPageLoaded = (pageLimit: number, offset: number, objects?: any[]) =>
    objects &&
    objects
        .slice(pageLimit * offset, pageLimit * offset + 1)
        .map((object) => !!object)
        .reduce((prev: number, curr: boolean) => prev + (curr ? 1 : 0), 0) > 0;

export const amendPageToState = <T extends unknown>(
    objects: T[],
    pageLimit: number,
    offset: number,
    state?: (T | undefined)[]
): (T | undefined)[] => {
    const indexOfFirstAddedElement = pageLimit * (offset);

    if (!state) {
        return new Array(indexOfFirstAddedElement).fill(undefined).concat(objects);
    }

    if (indexOfFirstAddedElement >= state.length) {
        return state.concat(new Array(indexOfFirstAddedElement - state.length).fill(undefined)).concat(objects);
    } else {
        const newState = [...state];
        newState.splice(indexOfFirstAddedElement, pageLimit, ...objects);

        return newState;
    }
};

export const pageExists = <T extends unknown>(
    pageSize: number,
    offset: number,
    objects?: (T | undefined)[],
    totalObjects: number = 0
): boolean => {
    const nonUndefinedPageMembersLength =
        objects?.slice(pageSize * offset, pageSize * (offset + 1))?.filter((object) => !!object)?.length ?? 0;

    return (
        nonUndefinedPageMembersLength === pageSize ||
        (pageSize * (offset + 1) > totalObjects && nonUndefinedPageMembersLength === totalObjects % pageSize)
    );
};

// TODO: IWGM-782 Use this function to handle device class change when gaps are present (similarly to what happens on messages list) on offers lists.
export const listContainsGaps = <T extends unknown>(objects?: T[]) =>
    objects?.length !== objects?.filter((message) => message !== undefined).length;

export const numberToPixels = (value: number) => `${value}px`;

export const getValueFromEnum = <T extends object>(enumObject: T, attribute: string) => {
    let value: string = "";

    (Object.keys(enumObject) as (string & keyof T)[]).forEach((key) => {
        if ((enumObject[key] as unknown as string) === attribute) {
            value = key;
        }
    });
    return value;
};

export const mergeFormDataObjects = (...objects: FormData[]) => {
    const formData = new FormData();

    for (const object of objects) {
        for (const pair of object.entries()) {
            formData.set(...pair);
        }
    }

    return formData;
};

export const isDateValid = (date?: Date) => !!date && date?.getFullYear?.() > 1970;

export const getSpecificLocation = (streetKey?: string) => {
    if (!streetKey) return;
    const streetAddress = streetKey.split("<span class=\"street-address\">")[1]?.split("</span>")[0] || "";
    const postalCode = streetKey.split("<span class=\"postal-code\">")[1]?.split("</span>")[0] || "";
    const city = streetKey.split("<span class=\"locality\">")[1]?.split("</span>")[0] || "";
    const country = streetKey.split("<span class=\"country-name\">")[1]?.split("</span>")[0] || "";
    return {
        streetAddress,
        postalCode,
        city,
        country,
    };
}