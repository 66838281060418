import { useEffect, useState } from "react";

const useManageBodyOverflow = (disableScroll: boolean) => {
    const [disableBodyScroll, setDisableBodyScroll] = useState(disableScroll);

    useEffect(() => {
        if (disableBodyScroll) {
            window.scrollTo(0, 0);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [disableBodyScroll]);

    return setDisableBodyScroll;
};

export default useManageBodyOverflow;
