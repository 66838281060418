import React from "react";

import { OfferType } from "features/common/types";
import UserOffers from "features/adminPanel/components/UserOffers";

import styles from "./styles.module.scss";

const UserEmployeeOffers = () => (
    <div className={styles["user-employee-offers"]}>
        <div className={styles["user-employee-offers__inner-container"]}>
            <UserOffers offersType={OfferType.Employee} />
        </div>
    </div>
);

export default UserEmployeeOffers;
