import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import Dropdown from "features/common/components/Dropdown";
import React from "react";
import styles from "./styles.module.scss";

export default function FilterSelector(props: any) {
  return (
    <div className={styles["my-messages__filters-container"]}>
      <span>{props.selectedFilter.display ?? ""}</span>
      <Dropdown id="my-messages__filter-dropdown" optionClassName={styles["my-messages__filter-dropdown-option"]} optionsClassName={styles["my-messages__filter-dropdown-options"]} selectedOptionClassName={styles["my-messages__filter-dropdown-selected-option"]} options={props.messageFilterOptions} toggle={<SortIcon />} selected={props.selectedFilter} onSelect={props.handleFilterSelect} />
    </div>);
}
