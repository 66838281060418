export interface GetConversationsFetchParams {
    pageSize: number;
    pageNumber: number;
    sort?: string;
}

export interface GetNotificationsFetchParams {
    pageSize: number;
    unacknowledgedOnly?: boolean;
}

export type MessagesFilterOption = "recentFirst" | "oldestFirst" | "onlyUnread";

export type MessageStatus = "Inbox" | "Outbox";

export enum ApplicationStatus {
    ApplicationSent = "SENT",
    ApplicationAccepted = "ACCEPTED",
    ApplicationDenied = "REJECTED",
    ArrivalAccepted = "ARRIVAL_ACCEPTED",
    OfferSent = "OFFER_SENT",
    BonusGiven = "BONUS_GIVEN",
}

export interface SendMessageParams {
    conversationId: string;
    message: string;
}
