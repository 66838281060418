import config from "config";
import Spinner from "features/common/components/Spinner";
import { appRoutes } from "features/routing/routes";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthStorageService from "services/authStorageService";
import RefreshTimeoutService from "services/refreshTimeoutService";
import SignOutTimeoutService from "services/signOutTimeoutService";
import styles from "./styles.module.scss";

export interface Props {
    isTokenCancelled?: boolean;
    isUserLoggedIn?: boolean;
    deactivateTokenAsync: () => void;
}

const SignOut = ({ isTokenCancelled, isUserLoggedIn, deactivateTokenAsync }: Props) => {
    const history = useHistory();

    useEffect(() => {
        if (isUserLoggedIn) {
            deactivateTokenAsync();
        } else {
            history.push(appRoutes.dashboard);
        }
    }, [deactivateTokenAsync, history, isUserLoggedIn]);

    useEffect(() => {
        const logout = appRoutes.signOut;

        if (isTokenCancelled) {
            AuthStorageService.clearAuthStorage();
            SignOutTimeoutService.removeSignOutTimeout();
            RefreshTimeoutService.removeRefreshTimeout();

            window.location.href = logout;
        }

        //TODO: check history if needed
    }, [history, isTokenCancelled]);

    return (
        <div className={styles["sign-out"]}>
            <Spinner data-testid="sign-out__spinner" />
        </div>
    );
};

export default SignOut;
