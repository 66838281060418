import { ReactComponent as CalendarCanceledIcon } from "assets/icons/calendarCanceled.svg";
import { ReactComponent as CalendarCheckIcon } from "assets/icons/calendarCheck.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import { ReactComponent as CalendarWithNumberIcon } from "assets/icons/calendarWithNumber.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as CoinsBanknoteIcon } from "assets/icons/coinsBanknote.svg";
import { ReactComponent as DotsMenuIcon } from "assets/icons/dotsMenu.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "assets/icons/peopleWithHand.svg";
import classNames from "classnames";
import config from "config";
import useDateFormat from "features/common/hooks/useDateFormat";
import {
    getBonusMessage,
    getEndedDateMessage,
    getPositionsNumberMessage,
    getPublishedDateMessage,
    getValidUntilMessage,
    getWorkLocationMessage,
} from "features/common/translationMessages";
import { OfferType, WorkType } from "features/common/types";
import { getSalaryMessage, getWorkTypeWithDatesMessage } from "features/offers/translationMessages";
import { FinancialConditions, LocationSlimDTO, OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ActionButtons from "./ActionButtons";
import CardImageWithBadge from "./CardImageWithBadge";
import DetailRow from "./DetailRow";
import styles from "./styles.module.scss";

interface Props {
    ["data-testid"]?: string;
    className?: string;
    previewMode?: "minimum" | "basic" | "extended" | "recommended";
    photoBadge?: "status" | "bonus";
    id?: number;
    status?: OfferStatus;
    offerType?: OfferType;
    locationSlimDto?: LocationSlimDTO;
    location?: LocationSlimDTO;
    workLocation?: string;
    title?: string;
    businessName?: string;
    positionsNumber?: number;
    position?: string;
    bonus?: string;
    financialConditions?: FinancialConditions;
    publishedDate?: Date;
    addedDate?: Date;
    jobStartDate?: Date;
    jobEndDate?: Date;
    expirationDate?: Date;
    workType?: WorkType;
    imageUrl?: string;
    applicationsCount?: number;
    messagesCount?: number;
    unreadApplicationsCount?: number;
    unreadMessagesCount?: number;
    showTopRightButton?: boolean;
    hideActionButtons?: boolean;
    displayReviewButton?: boolean;
    displayCopyButton?: boolean;
    displayEditButton?: boolean;
    displayDeleteButton?: boolean;
    displayEndButton?: boolean;
    onDeleteOfferClick?: () => void;
    onEndOffer?: () => void;
    onTitleImageClick?: () => void;
    directRoute?: string;
}

const SingleOfferCard = ({
    "data-testid": testId = "offer-card",
    className,
    previewMode = "basic",
    photoBadge = "status",
    id,
    publishedDate,
    status = OfferStatus.Active,
    offerType = OfferType.Job,
    locationSlimDto,
    workLocation,
    title,
    businessName,
    positionsNumber,
    bonus,
    financialConditions,
    jobStartDate,
    jobEndDate,
    workType,
    expirationDate,
    imageUrl,
    applicationsCount,
    messagesCount,
    // unreadMessagesCount,
    // unreadApplicationsCount,
    hideActionButtons,
    displayReviewButton = true,
    displayCopyButton = true,
    displayEditButton = true,
    displayDeleteButton,
    displayEndButton = true,
    onDeleteOfferClick,
    onEndOffer,
    onTitleImageClick,
    showTopRightButton = true,
    directRoute,
    location,
    position
}: Props) => {
    const getFormattedDate = useDateFormat();
    const intl = useIntl();

    const [actionListExpanded, setActionListExpanded] = useState(false);
    const shouldAllowReviewAction =
        (status === OfferStatus.Active || status === OfferStatus.Ended) && displayReviewButton;
    const shouldAllowCopyAction = (status === OfferStatus.Active || status === OfferStatus.Ended) && displayCopyButton;
    const shouldAllowEndAction = status === OfferStatus.Active && displayEndButton;
    const shouldAllowEditAction =
        ((status === OfferStatus.Active && (applicationsCount === 0 || offerType === OfferType.Employee)) ||
            status === OfferStatus.Inactive) &&
        displayEditButton;
    const shouldAllowDeleteAction = displayDeleteButton;

    const toggleExpandActionList = () => setActionListExpanded((prevState) => !prevState);

    const onEndOfferClick = () => onEndOffer && onEndOffer();

    const routeToMyOffer = offerType === OfferType.Job ? appRoutes.myJobOffers : appRoutes.myEmployeeOffers;

    const workLocationText = useMemo(
        () =>
            workLocation ||
                locationSlimDto ?
                `${locationSlimDto?.city ? `${locationSlimDto?.city}, ` : ''}${locationSlimDto?.country}`
                :
                `${location?.city ? `${location?.city}, ` : ''}${location?.country}`,
        [intl, locationSlimDto, workLocation, location]
    );

    const workLocationMessage = getWorkLocationMessage(intl, workLocationText ?? undefined);
    const workTypeMessage = getWorkTypeWithDatesMessage(
        intl,
        workType,
        getFormattedDate(jobStartDate),
        getFormattedDate(jobEndDate)
    );
    const positionsNumberMessage = getPositionsNumberMessage(intl, positionsNumber);
    let salaryMessage;
    if (financialConditions) {
        salaryMessage = getSalaryMessage(intl, financialConditions);
    }
    const bonusMessage = getBonusMessage(intl, bonus);
    const publishedDateMessage = getPublishedDateMessage(intl, getFormattedDate(publishedDate));
    const validUntilMessage = getValidUntilMessage(intl, getFormattedDate(expirationDate));
    const endedDateMessage = getEndedDateMessage(intl, getFormattedDate(expirationDate));

    return (
        <div
            data-testid={testId}
            className={classNames(
                styles["single-offer-card"],
                { [styles["single-offer-card--with-hover-shadow"]]: previewMode !== "extended" },
                className
            )}
        >
            {previewMode === "extended" && (
                <div className={styles["single-offer-card__box-header"]}>
                    <FormattedMessage id="single-offer-card__applies-to-offer" />
                </div>
            )}
            <CardImageWithBadge
                data-testid={`${testId}__card-image-with-badge`}
                photoBadge={photoBadge}
                status={status}
                bonus={bonus}
                financialConditions={financialConditions}
                businessName={businessName}
                imageUrl={`${config.IMAGE_URL}/${imageUrl}`}
                onImageClick={onTitleImageClick}
                offerType={offerType}
                topRightButton={
                    showTopRightButton ? (
                        <div
                            data-testid={`${testId}__context-menu`}
                            className={styles["single-offer-card__context-menu"]}
                            onClick={toggleExpandActionList}
                        >
                            <DotsMenuIcon />
                            {actionListExpanded && (
                                <div className={styles["single-offer-card__dropdown-container"]}>
                                    <ul className={styles["single-offer-card__dropdown"]}>
                                        {shouldAllowReviewAction && (
                                            <li
                                                data-testid={`${testId}__review`}
                                                className={styles["single-offer-card__dropdown-item"]}
                                            >
                                                <Link to={`${routeToMyOffer}/${id}`}>
                                                    <FormattedMessage id="common__review" />
                                                </Link>
                                            </li>
                                        )}
                                        {shouldAllowEditAction && (
                                            <li
                                                data-testid={`${testId}__edit`}
                                                className={styles["single-offer-card__dropdown-item"]}
                                            >
                                                <Link to={`${routeToMyOffer}/edit/${id}`}>
                                                    <FormattedMessage id="single-offer-card__edit" />
                                                </Link>
                                            </li>
                                        )}
                                        {shouldAllowCopyAction && (
                                            <li
                                                data-testid={`${testId}__copy`}
                                                className={styles["single-offer-card__dropdown-item"]}
                                            >
                                                <Link to={`${routeToMyOffer}/copy/${id}`}>
                                                    <FormattedMessage id="common__copy" />
                                                </Link>
                                            </li>
                                        )}
                                        {shouldAllowEndAction && (
                                            <li
                                                data-testid={`${testId}__end`}
                                                className={styles["single-offer-card__dropdown-item"]}
                                                onClick={onEndOfferClick}
                                            >
                                                <div>
                                                    <FormattedMessage id="single-offer-card__end-offer" />
                                                </div>
                                            </li>
                                        )}
                                        {shouldAllowDeleteAction && (
                                            <li
                                                data-testid={`${testId}__delete`}
                                                className={styles["single-offer-card__dropdown-item"]}
                                                onClick={onDeleteOfferClick}
                                            >
                                                <div>
                                                    <FormattedMessage id="single-offer-card__delete" />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : undefined
                }
            />
            <div className={styles["single-offer-card__data-action-container"]}>
                {previewMode === "extended" && (
                    <div
                        data-testid={`${testId}__business-name`}
                        className={styles["single-offer-card__employer"]}
                        title={businessName}
                    >
                        {businessName}
                    </div>
                )}
                <div>
                    <div
                        data-testid={`${testId}__title`}
                        className={classNames(styles["single-offer-card__title"], {
                            [styles["single-offer-card__title--no-pointer"]]: status === OfferStatus.Inactive,
                        })}
                        title={title ? title : position}
                        onClick={() => onTitleImageClick && onTitleImageClick()}
                    >
                        {title ? title : position}
                    </div>
                    <div className={styles["single-offer-card__data-container"]}>
                        <DetailRow
                            data-testid={`${testId}__location`}
                            icon={<LocationMarkerIcon />}
                            message={workLocationMessage}
                        />
                        {/* TODO: When using card with bonus badge, check how it renders on tablets and mobiles for seasonal (temporary) work */}
                        {workType && (
                            <DetailRow
                                data-testid={`${testId}__work-type`}
                                isEllipsis={false}
                                icon={<CalendarIcon />}
                                message={workTypeMessage}
                            />
                        )}
                        {positionsNumber && (
                            <DetailRow
                                data-testid={`${testId}__positions-number`}
                                icon={<PeopleWithHandIcon />}
                                message={positionsNumberMessage}
                            />
                        )}
                        {financialConditions && (
                            <DetailRow data-testid={`${testId}__salary`} icon={<CoinsIcon />} message={salaryMessage} />
                        )}
                        {photoBadge !== "bonus" && bonus && (
                            <DetailRow
                                data-testid={`${testId}__bonus`}
                                icon={<CoinsBanknoteIcon />}
                                message={bonusMessage}
                            />
                        )}
                        {(previewMode === "extended" ||
                            previewMode === "recommended" ||
                            (previewMode === "minimum" && status !== OfferStatus.Ended)) && (
                                <DetailRow
                                    data-testid={`${testId}__published-date`}
                                    icon={<CalendarCheckIcon />}
                                    message={publishedDateMessage}
                                />
                            )}
                        {previewMode === "extended" && status !== OfferStatus.Ended && (
                            <DetailRow
                                data-testid={`${testId}__offer-valid-until`}
                                icon={<CalendarWithNumberIcon />}
                                message={validUntilMessage}
                            />
                        )}
                        {previewMode !== "basic" && status === OfferStatus.Ended && (
                            <DetailRow
                                data-testid={`${testId}__ended-date`}
                                icon={
                                    <CalendarCanceledIcon className={styles["single-offer-card__calendar-canceled"]} />
                                }
                                message={endedDateMessage}
                            />
                        )}
                    </div>
                </div>

                {!hideActionButtons && (
                    <ActionButtons
                        data-testid={`${testId}__action-buttons`}
                        actionsMode={previewMode === "minimum" ? "extended" : "basic"}
                        offerStatus={status}
                        // TODO: IWGM-244 Distinguish between unread applications and all applications
                        applicationsCount={applicationsCount}
                        messagesCount={messagesCount}
                        onEndOffer={onEndOffer}
                        offerId={id}
                        offerType={offerType}
                        directRoute={directRoute}
                    />
                )}
            </div>
        </div>
    );
};

export default SingleOfferCard;
