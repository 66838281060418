import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Route } from "react-router-dom";
import config from "../../../../config";

const GetBonus = () => {
    useScrollToTopOnMount();
    return (
        <Route path='/getbonus' component={() => {
            window.location.href = config.GETBONUS_URL;
            return null;
        }} />
    );
};

export default GetBonus;