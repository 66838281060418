export const offersQueryParamNames = {
    city: "city",
    pageLimit: "size",
    offset: "page",
    text: "title",
    position: "position",
    companyName: "businessCardId",
    industry: "industry",
    location: "locationId",
    distance: "distance",
    placeId: "placeId",
    paymentRateType: "paymentRateType",
    branchId: "branchId",
    workType: "workType",
    fuzzySearch: "title",
    employerType: "employerType",
    isFavorite: "isFavorite"
};

export const createOfferYourCardStepFieldNames = {
    type: "type",
    selectedCompany: "selectedCompany",
    id: "id",
    companyName: "companyName",
    location: "location",
    zipCode: "zipCode",
    street: "street",
    streetNumber: "streetNumber",
    companyCertificate: "companyCertificate",
    companyNip: "companyNip",
    phoneNumber: "phoneNumber",
    logo: "logo",
};

export const createOfferDetailsStepFieldNames = {
    availablePositionsNumber: "availablePositionsNumber",
    position: "position",
    branchCode: "branchCode",
    workType: "workType",
    startDate: "startDate",
    endDate: "endDate",
    location: "location",
    zipCode: "zipCode",
};

export const createOfferFieldNames = {
    detailsStep: {
        offerAs: "offerAs",
        businessName: "businessName",
        country: "country",
        city: "city",
        postalCode: "postalCode",
        streetName: "streetName",
        streetNumber: "streetNumber",
        krazCertificateNumber: "krazCertificateNumber",
        taxIdentificationNumber: "taxIdentificationNumber",
        phoneNumber: "phoneNumber",
        phonePrefix: "phonePrefix",
        photos: "photos",
        location: "location"
    },
    offerDetailsStep: {
        position: "position",
        branchId: "branchId",
        employeeOfferType: "offerType",
        positionsAvailable: "positionsAvailable",
        workType: "workType",
        jobStartDate: "jobStartDate",
        jobEndDate: "jobEndDate",
        workDuringHolidays: "workDuringHolidays",
        country: "country",
        city: "city",
        region: "region",
        postalCode: "postalCode",
        location: "location"
    },
    financialStep: {
        salaryType: "salaryType",
        salaryMinValue: "salaryMinValue",
        salaryMaxValue: "salaryMaxValue",
        salaryCurrencyCode: "salaryCurrencyCode",
        bonusValue: "bonusValue",
        bonusCurrencyCode: "bonusCurrencyCode",
        bonusType: "bonusType",
        attachBonus: "attachBonus",
    },
    amenitiesStep: {
        transport: "transport",
        catering: "catering",
        accommodation: "accommodation",
        workWear: "workWear",
        workBoots: "workBoots",
        freeMedicalExaminations: "freeMedicalExaminations",
        assistanceInLegalizingStay: "assistanceInLegalizingStay",
        other: "other",
        otherAmenitiesText: "otherAmenitiesText",
    },
};
