import { makeApiRequest } from "api/makeApiRequest";
import { CancelToken } from "api/utils";
import UserAction, {
    cancelAuthTokens,
    createUserProfile,
    forgottenPassword,
    getUser,
    getUserProfile,
    loginUser,
    logoutUser,
    recoverPassword,
    refreshUserProfile,
    registerUser,
    resendActivationEmail,
    verifyUser,
} from "features/user/actions";
import { UserApi } from "features/user/api";
import { UserDto } from "features/user/dtos";
import {
    ForgottenPasswordParams,
    LoginOauthParams,
    LoginUserParams,
    RecoverPasswordParams,
    ResendActivationEmailParams,
} from "features/user/types";
import { ThunkAction } from "redux-thunk";
import AuthStorageService from "services/authStorageService";
import { RootState } from "store";

export const getUserAsync = (
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "getUserAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getUser.request());

        await makeApiRequest(api.getUserAsync, [userId, cancelToken], getUser.success, getUser.failure, dispatch);
    };
};

export const loginUserAsync = (
    params: LoginUserParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "loginUserAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(loginUser.request());

        await makeApiRequest(api.loginUserAsync, [params, cancelToken], loginUser.success, loginUser.failure, dispatch);
    };
};

export const forgottenPasswordAsync = (
    params: ForgottenPasswordParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "forgottenPasswordAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(forgottenPassword.request());

        await makeApiRequest(
            api.forgottenPasswordAsync,
            [params, cancelToken],
            forgottenPassword.success,
            forgottenPassword.failure,
            dispatch
        );
    };
};

export const recoverPasswordAsync = (
    params: RecoverPasswordParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "recoverPasswordAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(recoverPassword.request());

        await makeApiRequest(
            api.recoverPasswordAsync,
            [params, cancelToken],
            recoverPassword.success,
            recoverPassword.failure,
            dispatch
        );
    };
};

export const loginUserOathAsync = (
    params: LoginOauthParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "loginOathAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(loginUser.request());

        await makeApiRequest(api.loginOathAsync, [params, cancelToken], loginUser.success, loginUser.failure, dispatch);
    };
};

export const registerUserAsync = (
    params: LoginUserParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "registerUserAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(registerUser.request());

        await makeApiRequest(
            api.registerUserAsync,
            [params, cancelToken],
            registerUser.success,
            registerUser.failure,
            dispatch
        );
    };
};

export const resendActivationEmailAsync = (
    params: ResendActivationEmailParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "resendActivationEmailAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(resendActivationEmail.request());

        await makeApiRequest(
            api.resendActivationEmailAsync,
            [params, cancelToken],
            resendActivationEmail.success,
            resendActivationEmail.failure,
            dispatch
        );
    };
};

export const refreshUserProfileAsync = (
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "getUserAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        try {
            const response = await api.getUserAsync(userId, cancelToken);
            dispatch(refreshUserProfile(response));
        } catch (apiError) { }
    };
};

export const deactivateTokenAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "deactivateTokenAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(cancelAuthTokens.request());

        try {
            const response = await api.deactivateTokenAsync(cancelToken);
            dispatch(cancelAuthTokens.success(response));
        } catch (apiError) {
            dispatch(cancelAuthTokens.failure(apiError));
        }
    };
};

export const createUserProfileAsync = (
    formData: Partial<UserDto>,
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "createUserProfileAsync" | "uploadUserPhotos">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(createUserProfile.request());

        await makeApiRequest(
            api.createUserProfileAsync,
            [{ ...formData }, cancelToken],
            createUserProfile.success,
            createUserProfile.failure,
            dispatch
        );
    };
};

export const logoutUserAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "logoutUserAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        const tokens = AuthStorageService.getTokens();

        if (tokens?.refreshToken) {
            await makeApiRequest(
                api.logoutUserAsync,
                [{ refreshToken: tokens.refreshToken }, cancelToken],
                logoutUser.success,
                logoutUser.failure,
                dispatch
            );
            localStorage.clear()
        }
        dispatch(logoutUser.request());
    };
};

export const verifyUserAsync = (
    token: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "verifyUserAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(verifyUser.request());

        await makeApiRequest(
            api.verifyUserAsync,
            [token, cancelToken],
            verifyUser.success,
            verifyUser.failure,
            dispatch
        );
    };
};

export const getUserProfileAsync = (
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<UserApi, "getUserProfileAsync">, UserAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getUserProfile.request());

        await makeApiRequest(
            api.getUserProfileAsync,
            [userId, cancelToken],
            getUserProfile.success,
            getUserProfile.failure,
            dispatch
        );
    };
};
