import { useEffect, useState } from "react";

const useElementWidth = (id: string) => {
    const [elementWidth, setElementWidth] = useState<number>(document.getElementById(id)?.clientWidth ?? 0);

    useEffect(() => {
        function handleResize() {
            setElementWidth(document.getElementById(id)?.clientWidth ?? 0);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [id]);

    return elementWidth;
};

export default useElementWidth;
