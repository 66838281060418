import { ReactComponent as Logo } from "assets/icons/logoOnLight.svg";
import { ReactComponent as GetBonusLogo } from "assets/images/getbonuslogo.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import useCurrentPath from "features/common/hooks/useCurrentPath";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { UserRole } from "features/common/types";
import { cookieNames } from "features/cookies/constants";
import Drawer from "features/layout/components/Header/Drawer";
import Dropdown from "features/layout/components/Header/Dropdown";
import LanguageSwitch from "features/layout/components/Header/LanguageSwitch";
import Notifications from "features/layout/components/Header/Notifications";
import UserNav from "features/layout/components/Header/UserNav";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import { useMediaQuery } from "hooks/useMediaQuery";
import { Locale } from "lang";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import MessagesNotificationIcon from "./MessagesNotificationIcon";
import FavoriteNotification from "./FavoriteNotification";
import styles from "./styles.module.scss";
import config from "../../../../config";

const cookies = new Cookies();

const userNavUrls = [
    appRoutes.myProfile,
    appRoutes.myJobOffers,
    appRoutes.myEmployeeOffers,
    appRoutes.myMessages,
    appRoutes.adminPanel,
];
interface Props {
    ["data-testid"]?: string;
    user?: User;
    locale: Locale;
    setLocale: (locale: Locale) => void;
    isUserLoggedIn?: boolean;
    isUserLoggedInWithProfile?: boolean;
    unreadMessagesCount?: number;
    logoutUserAsync: () => void;
}

const Header = ({
    "data-testid": testId,
    user,
    locale,
    isUserLoggedIn,
    isUserLoggedInWithProfile,
    unreadMessagesCount,
    setLocale,
    logoutUserAsync,
}: Props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const toggleDrawer = useCallback(
        () => setDrawerOpen((prevState) => !prevState),
        [setDrawerOpen]
    );
    const onLoginClick = useCallback(
        () => history.push(appRoutes.login),
        [history]
    );

    const [userNavHighlighted, setUserNavHighlighted] = useState(false);
    const isAdministrator = user?.role === UserRole.Administrator;
    const isModerator = user?.role === UserRole.Administrator;
    const pathname = useCurrentPath();

    const isMatches = useMediaQuery("(max-width: 833px)");
    const handleDropdown = () => setDrawerOpen((prevState) => !prevState);
    const deviceClass = useDeviceClass();

    const isUserLoggedInWithoutProfile =
        isUserLoggedIn && !isUserLoggedInWithProfile;

    const onRemoveCookiesOnNavigation = () => {
        cookies.remove(cookieNames.CURRENT_PAGE);
        cookies.remove(cookieNames.SCROLL_POSITION);
        cookies.remove(cookieNames.RESULTS_PER_PAGE);
    };

    useEffect(() => {
        setDrawerOpen(false);
    }, [location]);

    useEffect(() => {
        const path = "/" + pathname.split("/")[1];

        if (userNavUrls.includes(path)) {
            if (path === appRoutes.myMessages && deviceClass === "desktop") {
                setUserNavHighlighted(false);
            } else {
                setUserNavHighlighted(true);
            }
        } else {
            setUserNavHighlighted(false);
        }
    }, [pathname, deviceClass]);

    return (
        <>
            <header className={styles["header"]}>
                {/* <div className={styles["mobile-notifications-wrapper"]}>
                    {isUserLoggedIn && <Notifications />}
                </div> */}
                <div
                    data-testid="header__brand"
                    className={classNames(styles["header__brand"], {
                        [styles["header__brand--no-hover-pointer"]]:
                            isUserLoggedInWithoutProfile,
                    })}
                >
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>
                <div
                    className={classNames(styles["header__hamburger"], {
                        [styles["header__hamburger--logged-out"]]: !isUserLoggedIn
                    })}
                    onClick={toggleDrawer}
                >
                    <span />
                    <span />
                    <span />
                </div>
                {!isUserLoggedInWithoutProfile && (
                    <nav className={styles["header__nav"]}>
                        <ul className={styles["main-nav"]}>
                            <li
                                data-testid="header__job-offers-link"
                                className={styles["main-nav__nav-item"]}
                            >
                                <NavLink to={appRoutes.jobOffers}>
                                    <p
                                        onClick={onRemoveCookiesOnNavigation}
                                        className={styles["main-nav__item-description"]}
                                    >
                                        <FormattedMessage id="common__job-offers" />
                                    </p>
                                </NavLink>
                            </li>
                            <li
                                data-testid="header__employee-offers-link"
                                className={styles["main-nav__nav-item"]}
                            >
                                <NavLink to={appRoutes.employeeOffers}>
                                    <p
                                        onClick={onRemoveCookiesOnNavigation}
                                        className={styles["main-nav__item-description"]}
                                    >
                                        <FormattedMessage id="common__employee-offers" />
                                    </p>
                                </NavLink>
                            </li>
                            <li
                                data-testid="header__get-bonus-link"
                                className={styles["main-nav__nav-item-get-bonus"]}
                            >
                                <a  href={config.GETBONUS_URL}>
                                    <p
                                        onClick={onRemoveCookiesOnNavigation}
                                        className={styles["main-nav__item-description-get-bonus"]}
                                    >
                                        <GetBonusLogo />
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                )}
                <div className={styles["secondary-nav-container"]}>
                    {isUserLoggedIn && (
                        <NavLink
                            to={appRoutes.myMessages}
                            className={styles["header__notification-icons-container"]}
                        >
                            <MessagesNotificationIcon />
                        </NavLink>
                    )}
                </div>

                <div className={styles["desktop-notifications-wrapper"]}>
                    {isUserLoggedIn && (
                        <>
                            <Notifications />
                            <FavoriteNotification />
                        </>
                    )}
                </div>

                <div className={styles["user-header-wrapper"]}>
                    {isUserLoggedIn ? (
                        <UserNav />
                    ) : (
                        <>
                            <LanguageSwitch
                                className={styles["header__language-switch"]}
                                locale={locale}
                                setLocale={setLocale}
                                variant="desktop"
                            />
                            <Button
                                variant="secondary"
                                as="a"
                                onClick={onLoginClick}
                                className={styles["header__login-button"]}
                            >
                                <p
                                    className={
                                        styles["main-nav__item-description-login"]
                                    }
                                >
                                    <FormattedMessage id="common__sign-in" />
                                </p>
                            </Button>
                        </>
                    )}
                </div>
            </header>
            {/* {isUserLoggedIn && isMatches ? ( */}
            {false ? (
                <div
                // className={classNames(styles["user-nav"], {
                //     [styles["user-nav--open"]]: drawerOpen || userNavHighlighted,
                // })}
                // onClick={handleDropdown}
                >
                    {/* <UserImageWithPlaceholder
                        imageUrl={`${config.IMAGE_URL}/${user?.imageUrl}`}
                        className={styles["user-nav__user-image"]}
                    /> */}

                    {/* {isUserLoggedIn && user?.firstName && (
                        <span
                            data-testid={`${testId}__user-full-name`}
                            className={styles["user-nav__username"]}
                        >{`${user.firstName} ${user.lastName}`}</span>
                    )}
                    {isUserLoggedIn && !user && (
                        <span data-testid={`${testId}__new-user-placeholder-name`} className={styles["user-nav__username"]}>
                            <FormattedMessage id="user-nav__new-user" />
                        </span>
                    )} */}
                    {/* {!isUserLoggedIn && (
                        <span data-testid={`${testId}__sign-in-link`} className={styles["user-nav__login-info"]}>
                            <FormattedMessage id="common__sign-in" />
                        </span>
                    )} */}

                    {drawerOpen && (
                        <Dropdown
                            data-testid={`${testId}__dropdown`}
                            onClickAway={() => toggleDrawer}
                            userProfileExists={!!user?.firstName}
                            isUserLoggedIn={isUserLoggedIn}
                            isAdministrator={isAdministrator}
                            isModerator={isModerator}
                            unreadMessagesCount={unreadMessagesCount}
                            logoutUserAsync={logoutUserAsync}
                        />
                    )}
                </div>
            ) : (
                <Drawer
                    locale={locale}
                    setLocale={setLocale}
                    closeDrawer={toggleDrawer}
                    drawerOpen={drawerOpen}
                    isUserLoggedIn={isUserLoggedIn}
                    unreadMessagesCount={unreadMessagesCount}
                />
            )}
        </>
    );
};

export default Header;