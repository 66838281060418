import { ReactComponent as DefaultImageIcon } from "assets/icons/defaultImage.svg";
import React from "react";
import styles from "./styles.module.scss";
import Default from "assets/images/default-image.png";
interface Props {
    ["data-testid"]?: string;
    realImage?: boolean;
}

const DefaultImage = ({ "data-testid": testId = "default-image", realImage }: Props) => (
    <div data-testid={testId} className={styles["default-image"]}>
        {realImage ? (
            <img src={Default} alt='default' />
        ) : (
            <DefaultImageIcon />
        )}
    </div>
);

export default DefaultImage;
