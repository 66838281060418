import useScroll from "features/common/hooks/useScroll";
import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";

export interface TableActionOption {
    text: string;
    callback: (id: string) => void;
}

export interface Props {
    id: string;
    display: string | React.ReactElement;
    shouldShowOptions?: boolean;
    actionOptions?: TableActionOption[];
    ["data-testid"]?: string;
    actionCallback?: (id: string) => void;
    onActionOptionsToggle?: (id?: string) => void;
}

const ActionCell = ({
    id,
    display,
    shouldShowOptions,
    actionOptions,
    "data-testid": testId,
    actionCallback,
    onActionOptionsToggle,
}: Props) => {
    useScroll(() => onActionOptionsToggle && onActionOptionsToggle(undefined));

    const onActionClick = (actionOptionsExist: boolean) => {
        if (actionOptionsExist) {
            onActionOptionsToggle && onActionOptionsToggle(id);
        } else {
            actionCallback && actionCallback(id);
        }
    };

    const onActionOptionClick = (callback: () => void) => {
        callback();
        onActionOptionsToggle && onActionOptionsToggle(undefined);
    };

    return (
        <div className={styles["action-cell"]}>
            <div
                data-testid={`${testId}__main-button`}
                className={styles["action-cell__action-button"]}
                onClick={() => onActionClick(!!actionOptions?.length)}
            >
                {display}
            </div>
            {shouldShowOptions && (
                <ClickAwayListener onClickAway={() => onActionOptionsToggle && onActionOptionsToggle(undefined)}>
                    <div className={styles["action-cell__action-options-wrapper"]}>
                        <div
                            data-testid={`${testId}__action-options-container`}
                            className={styles["action-cell__action-options"]}
                        >
                            {actionOptions?.map((option) => (
                                <div
                                    data-testid={`${testId}__option-button`}
                                    key={option.text}
                                    className={styles["action-cell__action-option"]}
                                    onClick={() => onActionOptionClick(() => option.callback(id))}
                                >
                                    {option.text}
                                </div>
                            ))}
                        </div>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default ActionCell;
