import { ReactComponent as BootsCheckIcon } from "assets/icons/bootsCheck.svg";
import { ReactComponent as BusCheckIcon } from "assets/icons/busCheck.svg";
import { ReactComponent as BusinessContractCheckIcon } from "assets/icons/businessContractCheck.svg";
import { ReactComponent as HouseCheckIcon } from "assets/icons/houseCheck.svg";
import { ReactComponent as ListCheckIcon } from "assets/icons/listCheck.svg";
import { ReactComponent as MealBowlCheckIcon } from "assets/icons/mealBowlCheck.svg";
import { ReactComponent as MedicalFileCheckIcon } from "assets/icons/medicalFileCheck.svg";
import { ReactComponent as WorkClothesCheckIcon } from "assets/icons/workClothesCheck.svg";
import TextArea from "features/common/components/TextArea";
import ToggleButton from "features/common/components/ToggleButton";
import { createOfferFieldNames } from "features/offers/constants";
import { useCreateOfferField } from "features/offers/hooks";
import { getOtherAmenitiesPlaceholder } from "features/offers/translationMessages";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

const AmenitiesStep = () => {
    const intl = useIntl();

    const otherAmenitiesPlaceholder = getOtherAmenitiesPlaceholder(intl);

    const [transportField, , transportFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.transport
    );
    const [cateringField, , cateringFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.catering
    );
    const [accommodationField, , accommodationFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.accommodation
    );
    const [workingClothingField, , workingClothingFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.workWear
    );
    const [workFootwearField, , workFootwearFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.workBoots
    );
    const [freeMedicalExaminationsField, , freeMedicalExaminationsFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.freeMedicalExaminations
    );
    const [assistanceInLegalizingStayField, , assistanceInLegalizingStayFieldHelper] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.assistanceInLegalizingStay
    );
    const [otherAmenitiesField, , otherAmenitiesFieldHelpers] = useCreateOfferField<boolean>(
        "amenitiesStep",
        createOfferFieldNames.amenitiesStep.other
    );
    const [otherAmenitiesTextField, otherAmenitiesTextFieldMeta, otherAmenitiesTextFieldHelpers] =
        useCreateOfferField<string>("amenitiesStep", createOfferFieldNames.amenitiesStep.otherAmenitiesText);

    return (
        <div className={styles["amenities-step"]}>
            <div className={styles["amenities-step__buttons-container"]}>
                <ToggleButton
                    data-testid="amenities-step__transport-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => transportFieldHelper.setValue(!transportField.value)}
                    value={transportField.name}
                    isSelected={transportField.value}
                >
                    <BusCheckIcon />
                    <FormattedMessage id="common__transportation-to-workplace" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__catering-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => cateringFieldHelper.setValue(!cateringField.value)}
                    value={cateringField.name}
                    isSelected={cateringField.value}
                >
                    <MealBowlCheckIcon />
                    <FormattedMessage id="create-offer__amenities-catering" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__accommodation-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => accommodationFieldHelper.setValue(!accommodationField.value)}
                    value={accommodationField.name}
                    isSelected={accommodationField.value}
                >
                    <HouseCheckIcon />
                    <FormattedMessage id="common__accommodation" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__work-clothing-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => workingClothingFieldHelper.setValue(!workingClothingField.value)}
                    value={workingClothingField.name}
                    isSelected={workingClothingField.value}
                >
                    <WorkClothesCheckIcon />
                    <FormattedMessage id="create-offer__amenities-working-clothing" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__work-footwear-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => workFootwearFieldHelper.setValue(!workFootwearField.value)}
                    value={workFootwearField.name}
                    isSelected={workFootwearField.value}
                >
                    <BootsCheckIcon />
                    <FormattedMessage id="create-offer__amenities-work-footwear" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__free-medical-examinations-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => freeMedicalExaminationsFieldHelper.setValue(!freeMedicalExaminationsField.value)}
                    value={freeMedicalExaminationsField.name}
                    isSelected={freeMedicalExaminationsField.value}
                >
                    <MedicalFileCheckIcon />
                    <FormattedMessage id="create-offer__amenities-free-medical-examinations" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__assistance-in-legalizing-stay-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() =>
                        assistanceInLegalizingStayFieldHelper.setValue(!assistanceInLegalizingStayField.value)
                    }
                    value={assistanceInLegalizingStayField.name}
                    isSelected={assistanceInLegalizingStayField.value}
                >
                    <BusinessContractCheckIcon />
                    <FormattedMessage id="create-offer__amenities-assistance-in-legalizing-stay" />
                </ToggleButton>
                <ToggleButton
                    data-testid="amenities-step__other-amenities-button"
                    variant="secondary"
                    className={styles["amenities-step__button"]}
                    selectedClassName={styles["amenities-step__button--picked"]}
                    onClick={() => {
                        otherAmenitiesTextFieldHelpers.setTouched(false);
                        otherAmenitiesFieldHelpers.setValue(!otherAmenitiesField.value);
                    }}
                    value={otherAmenitiesField.name}
                    isSelected={otherAmenitiesField.value}
                >
                    <ListCheckIcon />
                    <FormattedMessage id="create-offer__amenities-other-amenities" />
                </ToggleButton>
                {otherAmenitiesField.value && (
                    <TextArea
                        data-testid="amenities-step__other-amenities-input"
                        className={styles["amenities-step__textarea"]}
                        placeholder={`${otherAmenitiesPlaceholder}*`}
                        rows={6}
                        maxSize={200}
                        {...{ ...otherAmenitiesTextField, ...otherAmenitiesTextFieldMeta }}
                        withValidation
                        isInvalid={!!otherAmenitiesTextFieldMeta.error && !!otherAmenitiesTextFieldMeta.touched}
                    />
                )}
            </div>
        </div>
    );
};

export default AmenitiesStep;
