import "quill/dist/quill.snow.css";
// import "react-quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import styles from "./styles.module.scss";

const modules = {
  toolbar: [
    [
      'bold', 'italic', 'underline', 'strike',
      { align: [] },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      { header: [1, 2, 3, 4, 5, 6, false] },
      { size: ['small', false, 'large', 'huge'] },
      'clean',
    ],
  ],
};

const TextEditor = ({
  initialText,
  handleChange,
  maxLength,
  maxLengthTextComponent,
  additionalInfoTextComponent
}: any) => {
  const { quill, quillRef } = useQuill({ modules });
  const [showMaxTextLength, setShowMaxTextLength] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {

        const HTMLValue = quill?.root.innerHTML;

        if (quill.getLength() >= maxLength) {
          setShowMaxTextLength(true)
          quill.deleteText(maxLength, quill.getLength());
        } else {
          setCurrentValue(HTMLValue);
          showMaxTextLength && setShowMaxTextLength(false);
        }
      });
    }
  }, [initialText, maxLength, quill, quillRef, showMaxTextLength]);


  useEffect(() => {
    if (initialText && quill) {
      quill.clipboard.dangerouslyPasteHTML(initialText);
    }
  }, [initialText, quill])

  useEffect(() => {
    if (handleChange) {
      handleChange(currentValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div className={styles["text-editor-wrapper"]}>
      <div ref={quillRef} className={styles["text-editor-input"]} />
      <div className={styles["text-editor-text"]}>
        {!showMaxTextLength && additionalInfoTextComponent}
        {showMaxTextLength && maxLengthTextComponent}
      </div>
    </div>
  );
};


export default TextEditor;