import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.offers;

export const selectJobOffers = createSelector(selectState, (state) => state.jobOffers);
export const selectJobOffersLoading = createSelector(selectState, (state) => state.jobOffersLoading);
export const selectJobOffersLoadingError = createSelector(selectState, (state) => state.jobOffersLoadingError);
export const selectTotalJobOffers = createSelector(selectState, (state) => state.totalJobOffers);

export const selectMyJobOffers = createSelector(selectState, (state) => Array.from(state.myJobOffers.values()));
export const selectMyJobOffersLoading = createSelector(selectState, (state) => state.myJobOffersLoading);
export const selectMyJobOffersLoadingError = createSelector(selectState, (state) => state.myJobOffersLoadingError);

export const selectInterested = createSelector(selectState, (state) => state.interested);
export const selectInterestedLoading = createSelector(selectState, (state) => state.interestedLoading);
export const selectInterestedError = createSelector(selectState, (state) => state.interestedError);

export const selectApplications = createSelector(selectState, (state) => state.applications);
export const selectApplicationsLoading = createSelector(selectState, (state) => state.applicationsLoading);
export const selectApplicationsError = createSelector(selectState, (state) => state.applicationsError);

export const selectJobOffersFilterMatchCounts = createSelector(
    selectState,
    (state) => state.jobOffersFilterMatchCounts
);
export const selectJobOffersFilterMatchCountsLoading = createSelector(
    selectState,
    (state) => state.jobOffersFilterMatchCountsLoading
);
export const selectJobOffersFilterMatchCountsLoadingError = createSelector(
    selectState,
    (state) => state.jobOffersFilterMatchCountsLoadingError
);

export const selectJobOfferCreating = createSelector(selectState, (state) => state.jobOfferCreating);
export const selectJobOfferCreatingSuccess = createSelector(selectState, (state) => state.jobOfferCreatingSuccess);
export const selectJobOfferCreatingError = createSelector(selectState, (state) => state.jobOfferCreatingError);
export const selectJobOfferApiErrorsArray = createSelector(selectState, (state) => state.jobOfferCreatingErrorsArray);

export const selectJobOfferDetails = createSelector(selectState, (state) => state.jobOfferDetails);
export const selectJobOfferDetailsLoading = createSelector(selectState, (state) => state.jobOfferDetailsLoading);
export const selectJobOfferDetailsLoadingError = createSelector(
    selectState,
    (state) => state.jobOfferDetailsLoadingError
);

export const selectEndOfferSuccess = createSelector(selectState, (state) => state.endOfferSuccess);
export const selectEndingOffer = createSelector(selectState, (state) => state.endingOffer);
export const selectEndingOfferError = createSelector(selectState, (state) => state.endingOfferError);

export const selectOfferCardDetails = createSelector(selectState, (state) => state.jobOfferCardDetails);
export const selectOfferCardDetailsLoading = createSelector(selectState, (state) => state.jobOfferCardDetailsLoading);
export const selectOfferCardDetailsLoadingError = createSelector(
    selectState,
    (state) => state.jobOfferCardDetailsLoadingError
);

export const selectApplicationDetails = createSelector(selectState, (state) => state.applicationDetails);
export const selectApplicationDetailsLoading = createSelector(selectState, (state) => state.applicationDetailsLoading);
export const selectApplicationDetailsError = createSelector(selectState, (state) => state.applicationDetailsError);

export const selectAcceptApplicationLoading = createSelector(selectState, (state) => state.acceptApplicationLoading);
export const selectAcceptApplicationSuccess = createSelector(selectState, (state) => state.acceptApplicationSuccess);
export const selectAcceptApplicationError = createSelector(selectState, (state) => state.acceptApplicationError);

export const selectRejectApplicationLoading = createSelector(selectState, (state) => state.rejectApplicationLoading);
export const selectRejectApplicationSuccess = createSelector(selectState, (state) => state.rejectApplicationSuccess);
export const selectRejectApplicationError = createSelector(selectState, (state) => state.rejectApplicationError);

export const selectAcceptArrivalLoading = createSelector(selectState, (state) => state.acceptArrivalLoading);
export const selectAcceptArrivalSuccess = createSelector(selectState, (state) => state.acceptArrivalSuccess);
export const selectAcceptArrivalError = createSelector(selectState, (state) => state.acceptArrivalError);

export const selectGiveBonusLoading = createSelector(selectState, (state) => state.giveBonusLoading);
export const selectGiveBonusSuccess = createSelector(selectState, (state) => state.giveBonusSuccess);
export const selectGiveBonusError = createSelector(selectState, (state) => state.giveBonusError);

export const selectSendDedicationLoading = createSelector(selectState, (state) => state.sendDedicationLoading);
export const selectSendDedicationSuccess = createSelector(selectState, (state) => state.sendDedicationSuccess);
export const selectSendDedicationError = createSelector(selectState, (state) => state.sendDedicationError);

export const selectFenigePaymentStarted = createSelector(selectState, (state) => state.fenigePaymentStarted);
export const selectFenigePaymentSuccess = createSelector(selectState, (state) => state.fenigePaymentSuccess);
export const selectFenigePaymentError = createSelector(selectState, (state) => state.fenigePaymentError);
