import { selectIsUserLoggedIn, selectLoginUserLoading, selectLoginUserLoadingError } from "features/user/selectors";
import { loginUserAsync } from "features/user/thunks";
import LoginComponent from "./component";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: selectIsUserLoggedIn(state),
    loginUserLoading: selectLoginUserLoading(state),
    loginUserLoadingError: selectLoginUserLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            loginUserAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
