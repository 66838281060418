/* eslint-disable max-lines */
import config from "config";
import CheckboxFiltersGroup, { CheckboxFiltersGrouper } from "features/common/components/CheckboxFiltersGroup";
import FormDropdown from "features/common/components/FormDropdown";
import JoinedInput from "features/common/components/JoinedInput";
import { monetaryAmountRegex } from "features/common/constants";
import useDeviceClass from 'features/common/hooks/useDeviceClass';
import { Currency, SalaryType } from "features/common/models";
import { getCurrencyMessage } from "features/common/translationMessages";
import { Filter, WorkType } from "features/common/types";
import { parseInteger } from "features/common/utils";
import { cookieNames } from 'features/cookies/constants';
import { getCookie } from 'features/cookies/utils';
import { mapEmployeeOffersFetchParamsToQueryParameters } from "features/employeeOffers/mappers";
import { getMinimumAmountMessage } from "features/employeeOffers/translationMessages";
import { EmployeeOffersFetchParams, EmployeeOffersFilterMatchCounts } from "features/employeeOffers/types";
import Filters from "features/offers/components/Filters";
import ControlButtons from "features/offers/components/Filters/ControlButtons";
import { useSalaryTypeDropdownOptions } from "features/offers/hooks";
import { getPaymentTypeMessage } from "features/offers/translationMessages";
import { getCurrenciesDropdownOptions } from "features/offers/utils";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    asFavoriteEmployeeOrEmployer?: boolean;
    offersCount?: number;
    totalOffers?: number;
    branches?: Filter[];
    workTypes?: Filter[];
    currencies?: Currency[];
    filterMatchCounts?: EmployeeOffersFilterMatchCounts;
    isLoading: boolean;
    isLoadingError: boolean;
    offersLoading?: boolean;
    fetchParams: EmployeeOffersFetchParams;
    collapse?: () => void;
    onFiltersStateChange: (params: EmployeeOffersFetchParams) => void;
    resetPaginationState: () => void;
}

const FiltersView = ({
    offersCount,
    totalOffers,
    branches,
    workTypes,
    currencies,
    filterMatchCounts,
    fetchParams,
    isLoadingError,
    offersLoading,
    collapse,
    onFiltersStateChange,
    resetPaginationState,
    asFavoriteEmployeeOrEmployer
}: Props) => {
    const intl = useIntl();
    const paymentTypeMessage = getPaymentTypeMessage(intl);
    const currencyMessage = getCurrencyMessage(intl);
    const minimumAmountMessage = getMinimumAmountMessage(intl);
    const currentPageCookie: string = getCookie(cookieNames.CURRENT_PAGE);
    const currenciesOptions = getCurrenciesDropdownOptions(currencies);
    const salaryTypeDropdownOptions = useSalaryTypeDropdownOptions();
    const deviceClass = useDeviceClass();
    const [lastDropdownOpen, setLastDropdownOpen] = useState(false);
    const [bottomPadding, setBottomPadding] = useState(0);

    let filtersSum = fetchParams.filters.branchId.length + fetchParams.filters.employerType.length + fetchParams.filters.workType.length

    const setMorePaddingHelperTest = useCallback(
        (expanded: boolean, lastDropdownClicked: boolean) => {
            const multiplier = lastDropdownClicked ? 4 : 2;

            const bottomPadding = currencies ? currencies?.length * multiplier : 2;

            setBottomPadding(bottomPadding);
            setLastDropdownOpen(expanded);
        },
        [setBottomPadding, setLastDropdownOpen, currencies]
    );

    const changeFilters = useCallback(
        (params: EmployeeOffersFetchParams) => {
            const paramsWithResetOffset = {
                ...params,
                pageLimit: (deviceClass === 'desktop') ? config.offersResultsPerPage : (config.offersResultsPerPage * (currentPageCookie ? +currentPageCookie : 1)),
                offset: (deviceClass === 'desktop') ? (currentPageCookie ? +currentPageCookie : 1) : 1,
                amendOffers: false,
            };
            resetPaginationState();
            onFiltersStateChange(paramsWithResetOffset);
        },
        [resetPaginationState, onFiltersStateChange]
    );

    const getSelectedSalaryTypeOption = useCallback(() => {
        for (const option of salaryTypeDropdownOptions) {
            if (fetchParams.filters.paymentRateType === option.value) {
                return option;
            }
        }
    }, [fetchParams.filters.paymentRateType, salaryTypeDropdownOptions]);

    const getUpdatedFilter = useCallback(<T extends unknown>(checked: boolean, code: T, filter: T[]) => {
        if (checked) {
            filter.push(code);
        } else {
            const indexOfFilterToRemove = filter.indexOf(code);
            if (indexOfFilterToRemove >= 0) {
                filter.splice(indexOfFilterToRemove, 1);
            }
        }
        return filter;
    }, []);

    const onBranchCheckboxToggle = useCallback(
        (checked: boolean, code: number) => {
            const params = { ...fetchParams };
            params.filters.branchId = getUpdatedFilter(checked, code, params.filters.branchId);
            changeFilters(params);
        },
        [changeFilters, fetchParams, getUpdatedFilter]
    );

    const onWorkTypeCheckboxToggle = useCallback(
        (checked: boolean, code: WorkType) => {
            const params = { ...fetchParams };
            params.filters.workType = getUpdatedFilter(checked, code, params.filters.workType);
            changeFilters(params);
        },
        [fetchParams, getUpdatedFilter, changeFilters]
    );


    const onSalaryTypeSelect = useCallback(
        (workTypeId: SalaryType) => {
            const params = { ...fetchParams };
            params.filters.paymentRateType = workTypeId;
            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onClearFiltersClick = useCallback(() => {
        resetPaginationState();
        onFiltersStateChange({
            ...fetchParams,
            filters: {
                branchId: [],
                workType: [],
                byCapacityType: [],
                byPreferredAmenities: [],
                employerType: [],
            },
            amendOffers: false,
            offset: 1,
        });
    }, [fetchParams, onFiltersStateChange, resetPaginationState]);

    const onPreferredRewardAmountChange = useCallback(
        (rawValue: string) => {
            const value = parseFloat(rawValue);

            const params = { ...fetchParams };
            params.filters.minimalSalaryValue = value;
            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onPreferredBonusAmountChange = useCallback(
        (rawValue: string) => {
            const value = parseInteger(rawValue);

            if (value !== null) {
                const params = { ...fetchParams };
                params.filters.minimalBonusValue = value;

                changeFilters(params);
            }
        },
        [fetchParams, changeFilters]
    );

    const onPreferredRewardCurrencySelect = useCallback(
        (currencyName: string) => {
            const params = { ...fetchParams };

            if (currencyName === params.filters.minimalSalaryCurrencyCode) {
                params.filters.minimalSalaryCurrencyCode = undefined;
            } else {
                params.filters.minimalSalaryCurrencyCode = currencyName;
            }

            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onPreferredBonusCurrencySelect = useCallback(
        (currencyName: string) => {
            const params = { ...fetchParams };

            if (currencyName === params.filters.minimalBonusCurrencyCode) {
                params.filters.minimalBonusCurrencyCode = undefined;
            } else {
                params.filters.minimalBonusCurrencyCode = currencyName;
            }

            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const mappedBranches = branches?.map(branchItem => branchItem?.branches as any);

    return (
        <div className={styles["filters-view"]}>
            {isLoadingError ? (
                <div className={styles["filters-view__loading-error"]}>
                    <FormattedMessage id="common__loading-error" />
                </div>
            ) : (
                <div
                    className={styles["filters-view--filters-container"]}
                    style={lastDropdownOpen ? { paddingBottom: `${bottomPadding}em` } : {}}
                >
                    <CheckboxFiltersGrouper
                        header={<FormattedMessage id="filters__branch" />}
                        items={mappedBranches!}
                        matchCounts={filterMatchCounts?.byBranch ?? []}
                        isFilterChecked={(id) => fetchParams.filters?.branchId?.indexOf(+id) >= 0}
                        onToggle={(checked, id) => onBranchCheckboxToggle(checked, +id)}
                    />
                    {!asFavoriteEmployeeOrEmployer && (
                        <>
                            <CheckboxFiltersGroup
                                data-testid="filters-view__work-types-filters"
                                header={<FormattedMessage id="common__work-type" />}
                                filters={workTypes?.map((workType) => workType.id as WorkType) ?? []}
                                filterLabels={
                                    workTypes?.map((workType) =>
                                        intl.formatMessage({ id: `work-type__${workType.id}` })
                                    ) ?? []
                                }
                                matchCounts={filterMatchCounts?.byWorkType ?? []}
                                className={styles["filters-view__group"]}
                                isFilterChecked={(id: WorkType) => fetchParams.filters?.workType?.indexOf(id) >= 0}
                                onToggle={(checked, id) => onWorkTypeCheckboxToggle(checked, id)}
                            />
                            <div className={styles["filters-view__group"]}>
                                <h3 className={styles["filters-view__non-checkbox-group-header"]}>
                                    <FormattedMessage id="filters-view__preferred-salary" />
                                </h3>
                                <FormDropdown
                                    data-testid="filters-view__salary-type-dropdown"
                                    id="filters-view__salary-type-dropdown"
                                    options={salaryTypeDropdownOptions}
                                    selected={getSelectedSalaryTypeOption()}
                                    placeholder={paymentTypeMessage}
                                    label={paymentTypeMessage}
                                    onSelect={onSalaryTypeSelect}
                                />
                                <JoinedInput
                                    data-testid="filters-view__preferred-salary-input"
                                    id="filters-view__preferred-salary-input"
                                    inputType="number"
                                    className={styles["filters-view__salary-input"]}
                                    inputsContainerClassName={styles["filters-view__joined-input-container"]}
                                    valueInputLabel={minimumAmountMessage}
                                    unitInputLabel={currencyMessage}
                                    dropdownOptions={currenciesOptions}
                                    valueFieldValue={fetchParams.filters.minimalSalaryValue?.toString()}
                                    unitFieldValue={fetchParams.filters.minimalSalaryCurrencyCode}
                                    valueFieldSetValue={onPreferredRewardAmountChange}
                                    unitFieldSetValue={onPreferredRewardCurrencySelect}
                                    onToggle={(expanded: boolean) => setMorePaddingHelperTest(expanded, false)}
                                    valueFieldPattern={`^${monetaryAmountRegex}$`}
                                    helperMessages={[<FormattedMessage id="filters-view__amount-help-message" />]}
                                    inputContainerClassName={styles["filters-view__minimum-salary-input-container"]}
                                    formDropdownClassName={styles["filters-view__minimum-salary-input-dropdown"]}
                                />
                            </div>
                            <div className={styles["filters-view__group"]}>
                                <h3 className={styles["filters-view__non-checkbox-group-header"]}>
                                    <FormattedMessage id="filters-view__preferred-bonus" />
                                </h3>
                                <JoinedInput
                                    data-testid="filters-view__preferred-bonus-input"
                                    id="filters-view__preferred-bonus-input"
                                    inputType="number"
                                    inputsContainerClassName={styles["filters-view__joined-input-container"]}
                                    valueInputLabel={minimumAmountMessage}
                                    unitInputLabel={currencyMessage}
                                    dropdownOptions={currenciesOptions}
                                    valueFieldValue={fetchParams.filters.minimalBonusValue?.toString()}
                                    unitFieldValue={fetchParams.filters.minimalBonusCurrencyCode}
                                    valueFieldSetValue={onPreferredBonusAmountChange}
                                    unitFieldSetValue={onPreferredBonusCurrencySelect}
                                    onToggle={(expanded: boolean) => setMorePaddingHelperTest(expanded, true)}
                                    helperMessages={[<FormattedMessage id="filters-view__amount-help-message" />]}
                                    inputContainerClassName={styles["filters-view__minimum-bonus-input-container"]}
                                    formDropdownClassName={styles["filters-view__minimum-bonus-input-dropdown"]}

                                />
                            </div>
                            <ControlButtons
                                data-testid="filters-view__control-buttons"
                                offersLoading={offersLoading}
                                offersCount={offersCount}
                                totalOffers={totalOffers}
                                collapse={collapse}
                                onClearFiltersClick={onClearFiltersClick}
                                filtersSum={filtersSum}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default FiltersView;
