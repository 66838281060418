import styled, { css } from "styled-components/macro";
import { height, HeightProps, padding, PaddingProps, width, WidthProps } from "styled-system";

type StylesProps = WidthProps & HeightProps & PaddingProps;

type Variant = "orange" | "darkblue"

interface ButtonProps {
  variant: Variant;
  mobileWidth?: boolean;
}

const variants: { [key: string]: any; } = {
  orange: css`
      color: white;
      background: #ff6900;
    `,
  darkblue: css`
      color: white;
      background: #002677;
    `,
};

const Button = styled.button<StylesProps & ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  border: none;
  border-radius: 5px;
  color: #002677;
  background: #ff6900;
  text-decoration: none;
  cursor: pointer;

  ${({ mobileWidth }) => mobileWidth &&
    ` @media screen and (max-width: 833px){
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `
  }

  ${props => variants[props.variant]};
  
  ${width};
  ${height};
  ${padding};

`;

export default Button;
