import MyDataComponent from "features/myProfile/components/MyData/component";
import {
    selectDeleteMyAccountError,
    selectProfilePictureUploaded,
    selectProfilePictureUploading,
    selectProfilePictureUploadingError,
    selectUserDetailsUpdated,
    selectUserDetailsUpdating,
    selectUserDetailsUpdatingError,
} from "features/myProfile/selectors";
import {
    deleteMyAccountAsync,
    updateMyProfileDetailsAsync,
    updateProfilePictureAsync,
} from "features/myProfile/thunks";
import { selectUser, selectUserId, selectUserLoading, selectUserLoadingError } from "features/user/selectors";
import { refreshUserProfileAsync } from "features/user/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    userId: selectUserId(state),
    isUserLoading: selectUserLoading(state),
    isUserLoadingError: selectUserLoadingError(state),
    profilePictureUploading: selectProfilePictureUploading(state),
    profilePictureUploaded: selectProfilePictureUploaded(state),
    profilePictureUploadingError: selectProfilePictureUploadingError(state),
    userDetailsUpdating: selectUserDetailsUpdating(state),
    userDetailsUpdated: selectUserDetailsUpdated(state),
    userDetailsUpdatingError: selectUserDetailsUpdatingError(state),
    deleteMyAccountError: selectDeleteMyAccountError(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            updateMyProfileDetailsAsync,
            updateProfilePictureAsync,
            refreshUserProfileAsync,
            deleteMyAccountAsync
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyDataComponent);
