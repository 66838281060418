import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import { ReactComponent as HeartOff } from "assets/icons/grayedHeart.svg";
import { ReactComponent as HeartFilled } from "assets/icons/heart-icon-on.svg";
import classNames from "classnames";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import Button from "features/common/components/Button";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import mapCardTypeToCardOwnerType from "features/common/mappers/mapCardTypeToCardOwnerType";
import { getHomeMessage, getMyProfileMessage } from "features/common/translationMessages";
import { Card, CardType, DictionaryItem, OfferType } from "features/common/types";
import MyProfileApi from "features/myProfile/api";
import { RatingDto } from "features/myProfile/models";
import { getEmployeeCardMessage, getEmployerCardMessage } from "features/myProfile/translationMessages";
import { CardOwnerRole, CardOwnerType } from "features/myProfile/types";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import FavoriteAPI from "../../../favorite/api";
import DetailsSection from "./DetailsSection";
import MainSection from "./MainSection";
import PhotosSection from "./PhotosSection";
import RatingSection from "./RatingSection";
import RelatedOffersSection from "./RelatedOffersSection";
import RequirementsSection from "./RequirementsSection";
import styles from "./styles.module.scss";

interface Params {
    offerLength: string;
}

interface Props {
    user?: User;
    cardDetailsLoading: boolean;
    cardDetails?: Card;
    cardDetailsLoadingError?: ApiError;
    cardDetailsUpdating: boolean;
    branches?: DictionaryItem[];
    branchesLoading?: boolean;
    branchesError?: ApiError;
    countRelatedOffers?: number;
    uniqueId?: string;
    displayOnly?: boolean;
    hideDetails?: boolean;
    creatingOffer?: OfferType;
    getCardDetailsAsync: (uniqueId: string, cancelToken?: CancelToken) => void;
    getBranchesAsync: (cancelToken?: CancelToken) => void;
}

const CardDetails = ({
    user,
    cardDetailsLoading,
    cardDetails,
    cardDetailsLoadingError,
    cardDetailsUpdating,
    branches,
    branchesLoading,
    countRelatedOffers,
    uniqueId,
    displayOnly,
    hideDetails,
    creatingOffer,
    getCardDetailsAsync,
    getBranchesAsync,
}: Props) => {
    useScrollToTopOnMount();

    const intl = useIntl();
    const deviceClass = useDeviceClass();
    const createCancelToken = useCreateCancelToken();
    const history = useHistory();
    const { offerLength } = useParams<Params>();
    const isMatchesTablet = useMediaQuery('(min-width: 834px)');

    const [sectionBeingEdited, setSectionBeingEdited] = useState(false);
    const [totalOffers, setTotalOffers] = useState<number>(countRelatedOffers || 0);

    const homeMessage = getHomeMessage(intl);
    const myProfileMessage = getMyProfileMessage(intl);
    const employerCardMessage = getEmployerCardMessage(intl);
    const employeeCardMessage = getEmployeeCardMessage(intl);

    const [ratingData, setRatingData] = useState<any>();
    const [isFavoriteEmployee, setIsFavoriteEmployee] = useState<boolean>(false);
    const [isFavoriteEmployer, setIsFavoriteEmployer] = useState<boolean>(false);
    const [photoChanged, setPhotoChanged] = useState<boolean>(false)

    const cardType: CardOwnerType | undefined = cardDetails && mapCardTypeToCardOwnerType(cardDetails.type);

    const cardKind: CardOwnerRole = useMemo(
        () =>
            cardDetails?.type === CardType.EmployeeCompany || cardDetails?.type === CardType.EmployeePrivate
                ? "EMPLOYEE"
                : "EMPLOYER",
        [cardDetails]
    );

    const onFavoriteHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (cardDetails?.id) {
            const cardId = parseInt(cardDetails.id);
            if (cardKind === "EMPLOYEE") {
                if (isFavoriteEmployee) {
                    await FavoriteAPI.removeEmployeeFromFavorite(cardId);
                } else {
                    await FavoriteAPI.addEmployeeToFavorite(cardId);
                }
                setIsFavoriteEmployee(!isFavoriteEmployee);
            } else {
                if (isFavoriteEmployer) {
                    await FavoriteAPI.removeEmployerFromFavorite(cardId);
                } else {
                    await FavoriteAPI.addEmployerToFavorite(cardId);
                }
                setIsFavoriteEmployer(!isFavoriteEmployer);
            }
        }
    };

    useEffect(() => {
        setIsFavoriteEmployee(!!cardDetails?.isFavoriteEmployee);
        setIsFavoriteEmployer(!!cardDetails?.isFavoriteEmployer);
    }, [cardDetails]);

    const crumbs: BreadcrumbsItem[] = useMemo(
        () => [
            {
                displayName: homeMessage,
                path: appRoutes.dashboard,
            },
            {
                displayName: myProfileMessage,
                path: appRoutes.myProfile,
            },
            {
                displayName: cardDetails && isMatchesTablet
                    ? `${cardKind === "EMPLOYEE" ? employeeCardMessage : employerCardMessage}: ${cardType === "company"
                        ? cardDetails.companyName
                        : `${cardDetails.firstName} ${cardDetails.lastName}`
                    }`
                    : `${cardKind === "EMPLOYEE" ? employeeCardMessage : employerCardMessage}`,
            },
        ],
        [cardDetails, isMatchesTablet, cardKind, cardType, employeeCardMessage, employerCardMessage, homeMessage, myProfileMessage]
    );

    const cardLocationText = useMemo(
        () =>
            `${cardDetails?.city ? `${cardDetails?.city}, ` : ""
            }${intl.formatMessage({
                id: `common__country-${cardDetails?.country}`,
                defaultMessage: cardDetails?.country,
            })}`,
        [cardDetails, intl]
    );

    const handleGoBack = useCallback(() => {
        if (creatingOffer === OfferType.Job) {
            history.push(`${appRoutes.createJobOffer}/${uniqueId}/${offerLength}`);
        } else if (creatingOffer === OfferType.Employee) {
            history.push(`${appRoutes.createEmployeeOffer}/${uniqueId}/${offerLength}`);
        } else {
            history.goBack();
        }
    }, [creatingOffer, history, offerLength, uniqueId]);

    useEffect(() => {
        if (!branches && !branchesLoading) {
            getBranchesAsync(createCancelToken());
        }
    }, [branches, branchesLoading, createCancelToken, getBranchesAsync]);

    useEffect(() => {
        if (uniqueId) {
            getCardDetailsAsync(uniqueId, createCancelToken());
            setPhotoChanged(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueId, photoChanged]);

    useEffect(() => {
        setTotalOffers(countRelatedOffers || 0);
    }, [countRelatedOffers]);

    useEffect(() => {
        if (uniqueId) {
            MyProfileApi.getCardRatingAsync(uniqueId, cardKind, createCancelToken()).then((response) => {
                setRatingData(response?.averageRatesPerBusinessCard?.[uniqueId]?.map((item: any) => ({
                    ...item,
                    ratingsAmount: response?.numberOfRates,
                })) || [] as RatingDto[]);
            });
        }
    }, [cardKind, createCancelToken, uniqueId]);

    const isMyCard = !!user?.businessCards?.find((item) => item.id === cardDetails?.id);

    if (!uniqueId) {
        return null;
    }

    return (
        <div className={styles["card-details"]}>
            {cardDetailsLoading || cardDetailsUpdating ? (
                <SpinnerWithMessage message={<FormattedMessage id="card-details__loading-card" />} />
            ) : cardDetailsLoadingError || !cardDetails ? (
                <div className={classNames(styles["card-details__container"], styles["card-details__error-container"])}>
                    <span>
                        <FormattedMessage id="card-details__fetch-error" />
                    </span>
                </div>
            ) : (
                <>
                    <div
                        className={classNames(
                            styles["card-details__container"],
                            styles["card-details__breadcrumbs-container"]
                        )}
                    >
                        {!!displayOnly ? (
                            <Button
                                variant="no-background"
                                onClick={handleGoBack}
                                className={styles["card-details__back-button"]}
                            >
                                <ChevronRight />
                                <FormattedMessage id="common__back" />
                            </Button>
                        ) : (
                            <Breadcrumbs crumbs={crumbs} />
                        )}
                    </div>
                    <div className={styles["card-details__wrapper"]}>
                        <div
                            className={classNames(
                                styles["card-details__container"],
                                styles["card-details__company-name-container"]
                            )}
                        >
                            <h2>
                                {intl.formatMessage(
                                    { id: "card-details__title" },
                                    {
                                        name:
                                            cardType === "company"
                                                ? cardDetails.companyName
                                                : `${cardDetails.firstName} ${cardDetails.lastName}`,
                                        cardKind,
                                    }
                                )}
                            </h2>
                            {cardKind === "EMPLOYEE" ? (
                                <StyledHeartBtn onClick={onFavoriteHandler}>
                                    {isFavoriteEmployee ? <HeartFilled /> : <HeartOff />}
                                </StyledHeartBtn>
                            ) : (
                                <StyledHeartBtn onClick={onFavoriteHandler}>
                                    {isFavoriteEmployer ? <HeartFilled /> : <HeartOff />}
                                </StyledHeartBtn>
                            )}
                        </div>
                        <div
                            className={classNames(styles["card-details__container"], styles["card-details__main-section"])}
                        >
                            <MainSection
                                cardId={uniqueId}
                                cardKind={cardKind}
                                mainPhoto={cardDetails.mainPhoto}
                                logo={cardDetails.logo}
                                cardName={
                                    cardType === "private"
                                        ? `${cardDetails.firstName} ${cardDetails.lastName}`
                                        : `${cardDetails.companyName}`
                                }
                                location={cardLocationText}
                                description={cardDetails.description}
                                cardType={cardType}
                                displayOnly={displayOnly}
                                setPhotoChanged={setPhotoChanged}
                            />
                        </div>
                        {!displayOnly && (
                            <div
                                className={classNames(styles["card-details__container"], {
                                    [styles["card-details__container--mobile"]]: deviceClass !== "desktop",
                                })}
                            >
                                <h2>
                                    <FormattedMessage id="card-details__details" />
                                </h2>
                                <DetailsSection
                                    cardDetails={cardDetails}
                                    sectionBeingEdited={sectionBeingEdited}
                                    setSectionBeingEdited={setSectionBeingEdited}
                                    displayOnly={displayOnly}
                                />
                            </div>
                        )}
                        {!displayOnly && cardDetails.type !== 2 && cardDetails.type !== 3 && (
                            <div
                                className={classNames(styles["card-details__container"], {
                                    [styles["card-details__container--mobile"]]: deviceClass !== "desktop",
                                })}
                            >
                                <h2>
                                    <FormattedMessage id="card-details__requirements" values={{ cardKind }} />
                                </h2>
                                <RequirementsSection
                                    cardKind={cardKind}
                                    cardId={uniqueId}
                                    requirements={cardDetails.requirements}
                                    languageRequirements={cardDetails.languageRequirements}
                                    jobExperienceRequirements={cardDetails.jobExperience}
                                    cardType={cardType}
                                />
                            </div>
                        )}
                        {(!displayOnly || !!cardDetails.photos?.length) && (
                            <div
                                className={classNames(styles["card-details__container--desktop"], {
                                    [styles["card-details__container--mobile"]]: deviceClass !== "desktop",
                                })}
                            >
                                <PhotosSection
                                    cardDetails={cardDetails}
                                    cardId={uniqueId}
                                    cardType={cardType}
                                    displayOnly={displayOnly}
                                />
                            </div>
                        )}
                        {displayOnly && !hideDetails && (
                            <div
                                className={classNames(styles["card-details__container"], {
                                    [styles["card-details__container--mobile"]]: deviceClass !== "desktop",
                                })}
                            >
                                <h2>
                                    <FormattedMessage id="card-details__details" />
                                </h2>
                                <DetailsSection
                                    cardDetails={cardDetails}
                                    sectionBeingEdited={sectionBeingEdited}
                                    setSectionBeingEdited={setSectionBeingEdited}
                                    displayOnly={displayOnly}
                                />
                            </div>
                        )}
                        <div
                            className={classNames(styles["card-details__container"], {
                                [styles["card-details__container--mobile"]]: deviceClass !== "desktop",
                            })}
                        >
                            <h2>
                                {cardKind === "EMPLOYER" ? (
                                    <FormattedMessage id="card-details__employer-rating" />
                                ) : (
                                    <FormattedMessage id="card-details__employee-rating" />
                                )}
                            </h2>
                            <RatingSection
                                rating={ratingData}
                                cardId={uniqueId}
                                cardKind={cardKind}
                                displayOnly={displayOnly}
                            />
                        </div>
                        <div
                            className={classNames(
                                styles["card-details__container"],
                                styles["card-details__related-offers-container"]
                            )}
                        >
                            <h2>
                                {displayOnly ? (
                                    <FormattedMessage
                                        id="card-details__aassociated-display-only-offers"
                                        values={{ cardKind }}
                                    />
                                ) : cardKind === "EMPLOYER" ? (
                                    <FormattedMessage id="card-details__associated-job-offers" />
                                ) : (
                                    <FormattedMessage id="card-details__associated-employee-offers" />
                                )}
                                {totalOffers > 0 && <span>({totalOffers})</span>}
                            </h2>
                            <RelatedOffersSection cardId={uniqueId} cardKind={cardKind} displayOnly={displayOnly} isMyCard={isMyCard} />
                        </div>
                        {typeof creatingOffer === "number" && (
                            <div className={styles["card-details__buttons-container"]}>
                                <Button
                                    variant="tertiary"
                                    onClick={handleGoBack}
                                    className={styles["card-details__button"]}
                                >
                                    <FormattedMessage id="card-details__go-back" values={{ offerType: creatingOffer }} />
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const StyledHeartBtn = styled.button`
    background: transparent;
    outline: none;
    border: none;
`;

export default CardDetails;