import appStore from "assets/icons/appStore.png";
import appStoreSoon from "assets/icons/appStoreSoon.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevronDownWhite.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebookColor.svg";
import googlePlaySoon from "assets/icons/googlePlaySoon.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedInColor.svg";
import { ReactComponent as Logo } from "assets/icons/logoOnDark.svg";
import { ReactComponent as GetMenLogo } from "assets/icons/logoOnLight.svg";
import { ReactComponent as MastercardLogo } from "assets/icons/mastercardLogo.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitterColor.svg";
import { ReactComponent as VisaLogo } from "assets/icons/visaLogo.svg";
import { ReactComponent as GetBonusLogo } from "assets/images/getbonuslogo.svg";
import { ReactComponent as GetInvestLogo } from "assets/images/GetInvestLogo.svg";
import config from "config";
import Button from "features/common/components/Button";
import CookieBanner from "features/cookies/components/CookieBanner";
import { cookieNames } from "features/cookies/constants";
import { getCookie, setCookie } from "features/cookies/utils";
import { appRoutes } from "features/routing/routes";
import { Locale } from "lang";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import googlePlay from "../../../../assets/icons/googlePlay.png";
import GetMenFooterDesktop from "../../../../assets/images/getmenfooter-desktop.svg";
import GetMenFooterMobile from "../../../../assets/images/getmenfooter-mobile.svg";
import GetMenFooterTablet from "../../../../assets/images/getmenfooter-tablet.svg";
import Box from "../../../../components/Box";
import useDeviceClass from "../../../common/hooks/useDeviceClass";
import LanguageSwitch from "../Header/LanguageSwitch";
import styles from "./styles.module.scss";

export const footerId = "footer";

export interface Props {
    isUserLoggedInWithProfile?: boolean;
    ["data-testid"]?: string;
    locale: Locale;
    setLocale: (locale: Locale) => void;
}

const Image = styled(Box)`
    height: calc(768px + 128px + 32px);
    background-image: url(${GetMenFooterMobile});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: -34px;

    @media (max-width: 768px) {
        height: 480px;
    }
  @media screen and (min-width: 415px) and (max-width: 861px) {
    height: 38.5rem;
    background-image: url(${GetMenFooterTablet});
  }

  @media screen and (min-width: 862px) {
    height: 45.5rem;
    //margin-top: 2rem;
    background-image: url(${GetMenFooterTablet});
  }

  @media screen and (min-width: 1440px) {
    height: 38.375rem;
    max-width: 125rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    //margin-top: 2rem;
    background-image: url(${GetMenFooterDesktop});
  }
`;

// TODO: IWGM-173 Extend the component by adding missing social media urls and page links
const Footer = ({ "data-testid": testId = "footer-testid", isUserLoggedInWithProfile, locale, setLocale }: Props) => {
    const currentYear = new Date();
    const deviceClass = useDeviceClass();
    const [areAppsHovered, setAreAppsHovered] = useState(false);
    const [enableGoogleAnalytics, setEnableGoogleAnalytics] = useState<boolean>(false);
    const [enableGoogleAds, setEnableGoogleAds] = useState<boolean>(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(getCookie<boolean>(cookieNames.COOKIES_ACCEPTED));
    const [showAdvancedCookiesSettings, setShowAdvancedCookiesSettings] = useState<boolean>(false)

    let lg = locale
    if (lg !== "nl" && lg !== "de" && lg !== "pl") {
        lg = "en"
    }

    const disableAllCookies = () => {
        window.location.reload();
    }
    const onAcceptClick = () => {
        setEnableGoogleAds(true)
        setEnableGoogleAnalytics(true)
        setCookie(cookieNames.COOKIES_ACCEPTED, true);
        setCookiesAccepted(true);
        turnOnGoogleCookies(true);
    };
    const acceptAdvancedCookies = () => {
        setCookie(cookieNames.COOKIES_ACCEPTED, true);
        setCookiesAccepted(true);
        turnOnGoogleCookies(false);
        setShowAdvancedCookiesSettings(false)
    }
    function activateGtag(w: any, d: any, s: string, l: string, i: string) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    }
    const declineAdvancedCookies = () => {
        setShowAdvancedCookiesSettings(false)
        setEnableGoogleAnalytics(false);
        setEnableGoogleAds(false);
    }
    function turnOnGoogleCookies(allAccepted: boolean) {
        if (enableGoogleAds === false && !allAccepted) {
            // Disable Google Ads
            // @ts-ignore
            gtag('set', { 'allow_ad_personalization_signals': false });
        }
        if (enableGoogleAnalytics === false && !allAccepted) {
            // Disable Google Analytics
            // @ts-ignore
            window['ga-disable-UA-225030999-1'] = true;
        }
        activateGtag(window, document, 'script', 'dataLayer', 'GTM-K8WMV9K');
    }
    const handleInputChange = (e: Event | any) => {
        // For Google analytics things
        // Checkbox for Google Analytics checked
        if (e.target.value === "ga") {
            setEnableGoogleAnalytics(e.target.checked);
        } // Checkbox for Google Ads checked
        else if (e.target.value === "gads") {
            setEnableGoogleAds(e.target.checked)
        }
    }
    const advancedSettingsModal = () => {
        return (
            <div className={styles["cookie-banner__advancedSettings"]}>
                <div className={styles["cookie-banner__insideScroll"]}>
                    <h1 className={styles["cookie-banner__header"]} style={{ color: "#fff" }}><FormattedMessage id="cookie_adv_header" /></h1>
                    <p className={styles["ner__textContent"]} style={{ fontSize: '13px' }}><FormattedMessage id="cookie_adv_paragraph" /></p>
                    <ul>
                        {cookiesModalInfo.map((element: any, index: number) => {
                            return <li key={index} className={styles["cookie-banner__cookieOption"]} onClick={(e) => toggleExpand(e)}>
                                <ChevronDown />
                                <div className={styles["cookie-banner__content"]}>
                                    <FormattedMessage id={element.title} />
                                    <p className={styles["cookie-banner__paragraph"]}><FormattedMessage id={element.content} /></p>
                                </div><input className={styles["cookie-banner__checkbox"]} type="checkbox" onChange={(e) => handleInputChange(e)} value={element.value ? element.value : ""} />
                            </li>
                        })}
                    </ul>
                </div>
                <div className={styles["cookie-banner__advancedSettingsButtons"]}>
                    <Button
                        data-testid="cookie-banner__confirm-button"
                        className={styles["cookie-banner__confirm-button"]}
                        onClick={() => declineAdvancedCookies()}>
                        <FormattedMessage id="common__cancel" />
                    </Button>
                    <Button
                        data-testid="cookie-banner__confirm-button"
                        className={styles["cookie-banner__confirm-button"]}
                        onClick={() => acceptAdvancedCookies()}>
                        <FormattedMessage id="common__save" />
                    </Button>
                </div>
            </div>
        )
    }
    const toggleExpand = (e: Event | any) => {
        if (e.target.tagName === "svg" || e.target.tagName === "DIV") {
            if (e.target.parentNode.style.minHeight === "140px") {
                e.target.parentNode.style.minHeight = '18px'
                e.target.parentNode.querySelector('p').style.display = "none"
                e.target.parentNode.querySelector('svg').style.display = "block"
            }
            else {
                e.target.parentNode.style.minHeight = '140px';
                e.target.parentNode.querySelector('p').style.display = "block"
                e.target.parentNode.querySelector('svg').style.display = "none"
            }
        } else if (e.target.tagName === "LI") {
            if (e.target.style.minHeight === "140px") {
                e.target.style.minHeight = '18px'
                e.target.querySelector('p').style.display = "none"
                e.target.querySelector('svg').style.display = "block"
            } else {
                e.target.style.minHeight = '140px';
                e.target.querySelector('p').style.display = "block"
                e.target.querySelector('svg').style.display = "none"
            }
        }
    }
    const cookiesModalInfo = [{ title: 'cookie_adv_1h', content: 'cookie_adv_1p' },
    { title: 'cookie_adv_2h', content: 'cookie_adv_2p', value: "ga" },
    { title: 'cookie_adv_3h', content: 'cookie_adv_3p' },
    { title: 'cookie_adv_4h', content: 'cookie_adv_4p', value: "gads" },
    { title: 'cookie_adv_5h', content: 'cookie_adv_5p' },
    { title: 'cookie_adv_6h', content: 'cookie_adv_6p' }]

    const [showCookiesCustomizationModal, setShowCookiesCustomizationModal] = useState<boolean>(false);
    const customizationModal = () => {
        return (<div className={styles["footer__cookies-customization"]}>
            <div className={styles["footer__cookies-customization__logo"]}>
                <Logo />
            </div>
            <div>
                <FormattedMessage id="footer__cookies-info" />
                <h4><FormattedMessage id="footer__do-you-accept-cookies" /></h4>
            </div>
            <div className={styles["footer__cookies-customization__buttons"]}>
                <Button className={styles["discount-codes__header-button"]} onClick={() => disableAllCookies()}> <FormattedMessage id="cookie_decline" /></Button>
                <Button onClick={() => { setShowCookiesCustomizationModal(false); setShowAdvancedCookiesSettings(true) }}><FormattedMessage id="cookie_accept_chosen" /></Button>
                <Button onClick={() => { onAcceptClick(); setShowCookiesCustomizationModal(false) }}> <FormattedMessage id="cookie_accept_all" /> </Button>
            </div>
        </div>)
    }
    return (
        <footer className={styles["footer"]} id={footerId}>
            <Image />
            <div className={styles["footer__top-part"]}>
                <div className={styles["footer__logo-and-social-wrapper"]}>
                    <div className={styles["footer__logo-wrapper"]}>
                        <Link to="/">
                            <GetMenLogo />
                        </Link>
                    </div>
                </div>
                <div className={styles["footer__sections-wrapper"]}>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-header"]}>
                            <FormattedMessage id="footer__about-us" />
                        </div>

                        <Link data-testid={`${testId}__route-link`} to={appRoutes.howItWorks}>
                            <FormattedMessage id="footer__how-getmen-works" />
                        </Link>
                        <Link data-testid={`${testId}__route-link`} to={appRoutes.faq}>
                            <FormattedMessage id="footer__faq" />
                        </Link>
                        {
                            deviceClass === "desktop" && (
                                <LanguageSwitch
                                    className={styles["footer__section-language-switch"]}
                                    locale={locale}
                                    setLocale={setLocale}
                                    variant="desktop"
                                    footer
                                />
                            )
                        }
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-header"]}>
                            <FormattedMessage id="footer__work" />
                        </div>

                        <Link data-testid={`${testId}__route-link`} to={appRoutes.jobOffers}>
                            <FormattedMessage id="common__job-offers" />
                        </Link>
                        <Link data-testid={`${testId}__route-link`} to={appRoutes.employeeOffers}>
                            <FormattedMessage id="footer__for-employers" />
                        </Link>
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-header"]}>
                            <FormattedMessage id="footer__my-account" />
                        </div>

                        <Link
                            data-testid={`${testId}__route-link`}
                            to={isUserLoggedInWithProfile ? appRoutes.myProfile : appRoutes.register}
                        >
                            <FormattedMessage id="common__my-profile" />
                        </Link>
                        <Link
                            data-testid={`${testId}__route-link`}
                            to={isUserLoggedInWithProfile ? appRoutes.myMessages : appRoutes.register}
                        >
                            <FormattedMessage id="footer__messages" />
                        </Link>
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-header"]}>
                            <FormattedMessage id="footer__information" />
                        </div>

                        <Link data-testid={`${testId}__route-link`} to={appRoutes.regulations}>
                            <FormattedMessage id="footer__regulations" />
                        </Link>
                        <Link data-testid={`${testId}__route-link`} to={appRoutes.privacyPolicy}>
                            <FormattedMessage id="footer__privacy-policy" />
                        </Link>
                        <Link data-testid={`${testId}__route-link`} to={appRoutes.cookiePolicy}>
                            <FormattedMessage id="footer__cookie-policy" />
                        </Link>
                        <Link data-testid={`${testId}__route-link`} to={'#'} onClick={() => setShowCookiesCustomizationModal(true)}>
                            <FormattedMessage id="footer__cookie-customization" />
                        </Link>
                        {showCookiesCustomizationModal ? customizationModal() : null}
                        {showAdvancedCookiesSettings ? advancedSettingsModal() : null}
                        {
                            deviceClass === "smartphone" && (
                                <LanguageSwitch
                                    className={styles["footer__section-language-switch"]}
                                    locale={locale}
                                    setLocale={setLocale}
                                    variant="desktop"
                                    footer
                                />
                            )
                        }
                        {
                            deviceClass === "desktop" && (
                                <div className={styles["footer__section-card-logos"]}>
                                    <MastercardLogo className={styles["footer__mastercard-logo"]} />
                                    <VisaLogo className={styles["footer__visa-logo"]} />
                                </div>
                            )
                        }
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-header"]}>
                            <FormattedMessage id="footer__contact" />
                        </div>
                        {isUserLoggedInWithProfile && (
                            <Link data-testid={`${testId}__route-link`} to={appRoutes.contactForm}>
                                <FormattedMessage id="contact-form__header" />
                            </Link>
                        )}
                        <span className={styles["footer__section-contact-link"]}>
                            <a href={`mailto:${config.contactEmail}`}>{config.contactEmail}</a>
                        </span>
                        {
                            deviceClass === "tablet" && (
                                <LanguageSwitch
                                    className={styles["footer__section-language-switch"]}
                                    locale={locale}
                                    setLocale={setLocale}
                                    variant="desktop"
                                    footer
                                />
                            )
                        }
                        {
                            deviceClass === "smartphone" && (
                                <div className={styles["footer__section-card-logos"]}>
                                    <MastercardLogo className={styles["footer__mastercard-logo"]} />
                                    <VisaLogo className={styles["footer__visa-logo"]} />
                                </div>
                            )
                        }
                        <div className={styles["footer__social-media-links-desktop"]}>
                            <a href={config.socialMediaLinks.FACEBOOK} target="_blank" rel="noopener noreferrer">
                                <FacebookIcon />
                            </a>
                            <a href={config.socialMediaLinks.INSTAGRAM} target="_blank" rel="noopener noreferrer">
                                <InstagramIcon />
                            </a>
                            <a href={config.socialMediaLinks.TWITTER} target="_blank" rel="noopener noreferrer">
                                <TwitterIcon />
                            </a>
                            <a href={config.socialMediaLinks.LINKED_IN} target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon />
                            </a>
                        </div>
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-getbonus"]}>
                            <a href={`${config.INVEST_URL}/${lg}`}><GetInvestLogo />
                            </a>
                            <span>&nbsp;-&nbsp;</span>
                        </div>
                        <div className={styles["footer__section-header-getbonus"]}>
                            <FormattedMessage id="footer__revolution" />
                        </div>
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__section-header"]}>
                            <FormattedMessage id="footer_apps" />
                        </div>
                        <div className={styles["footer__section-apps"]}>
                            <a className={styles["footer__section-app-link"]} href="#" onMouseEnter={() => setAreAppsHovered(true)} onMouseLeave={() => setAreAppsHovered(false)}>
                                <img alt='footer-getmen' className={styles["footer__section-app-img"]} src={areAppsHovered ? appStoreSoon : appStore} />
                            </a>
                            <a className={styles["footer__section-app-link"]} href="#" onMouseEnter={() => setAreAppsHovered(true)} onMouseLeave={() => setAreAppsHovered(false)}>
                                <img alt='footer-getmen' className={styles["footer__section-app-img"]} src={areAppsHovered ? googlePlaySoon : googlePlay} />
                            </a>
                        </div>
                    </div>
                    <div className={styles["footer__section"]}>
                        <div className={styles["footer__social-media-links"]}>
                            <a href={config.socialMediaLinks.FACEBOOK}>
                                <FacebookIcon />
                            </a>
                            <a href={config.socialMediaLinks.INSTAGRAM}>
                                <InstagramIcon />
                            </a>
                            <a href={config.socialMediaLinks.TWITTER}>
                                <TwitterIcon />
                            </a>
                            <a href={config.socialMediaLinks.LINKED_IN}>
                                <LinkedInIcon />
                            </a>
                        </div>
                        {
                            deviceClass === "tablet" && (
                                <div className={styles["footer__section-card-logos"]}>
                                    <MastercardLogo className={styles["footer__mastercard-logo"]} />
                                    <VisaLogo className={styles["footer__visa-logo"]} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={styles["footer__bottom-part"]}>
                <span>{`© ${currentYear.getFullYear()} Getmen.`}</span>
                <span>
                    <FormattedMessage id="footer__all-rights-reserved" />
                </span>
            </div>
        </footer>
    );
};

export default Footer;
