import {format} from "date-fns";
import {isDateValid} from "features/common/utils";

const useDateFormat = (customDateFormat?: string, customParams?: any) => {
    return (date?: Date) => {
        const dateObj = date ? new Date(date) : date
        if (!isDateValid(dateObj)) return;

        return dateObj ? format(dateObj, customDateFormat || "d.MM.yyyy", customParams) : undefined;
    };
};

export default useDateFormat;
