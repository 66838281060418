import StatisticsComponent from "features/adminPanel/components/Statistics/component";
import { selectStatistics, selectStatisticsLoading, selectStatisticsLoadingError } from "features/adminPanel/selectors";
import { getStatisticsAsync } from "features/adminPanel/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    statistics: selectStatistics(state),
    statisticsLoading: selectStatisticsLoading(state),
    statisticsLoadingError: selectStatisticsLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getStatisticsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsComponent);
