import { ReactComponent as ChevronDown } from "assets/icons/chevronDown.svg";
import classNames from "classnames";
import Spinner from "features/common/components/Spinner";
import { headerHeight } from "features/common/constants";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { SortDirection } from "features/common/types";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import ActionCell, { TableActionOption } from "./ActionCell";
import HeaderCell, { TableFilterOption } from "./HeaderCell";
import styles from "./styles.module.scss";

const onSortScrollTopCorrectionOffset = -5;

type HiddenType = { hidden?: never; label: string } | { hidden: true; label?: never };

type SortFilterType =
    | {
        withSort?: true;
        currentSortDirection?: SortDirection;
        sortCallback?: (sortDirection: SortDirection) => void;
        withFilter?: never;
        filterOptions?: never;
    }
    | {
        withSort?: never;
        currentSortDirection?: never;
        sortCallback?: never;
        withFilter?: true;
        filterOptions: TableFilterOption[];
    };

type ActionType =
    | { actionCallback?: never; actionOptions: TableActionOption[] }
    | { actionCallback: (id: string) => void; actionOptions?: never };

export type TableColumn<T extends object> =
    | (SortFilterType &
        HiddenType & {
            actionColumn?: never;
            actionDisplay?: never;
            actionTabletDisplay?: never;
            actionDesktopDisplay?: never;
            key: string & keyof T;
            customRender?: (field: any) => string | React.ReactElement;
        })
    | (ActionType & {
        actionColumn: true;
        actionDisplay: string | React.ReactElement;
        actionTabletDisplay?: string | React.ReactElement;
        actionDesktopDisplay?: string | React.ReactElement;
        key?: never;
        customRender?: never;
        label?: never;
        hidden?: never;
        withSort?: never;
        withFilter?: never;
        filterOptions?: never;
    });

export interface Props<T extends object> {
    data: T[];
    columns: TableColumn<T>[];
    idColumn: string & keyof T;
    highlightedRowIds?: string[];
    columnsVisibleOnTabletCount?: number;
    columnsVisibleOnSmartphoneCount?: number;
    headerStickTop?: number;
    withTopTransition?: boolean;
    withOnSortScroll?: boolean;
    noDataMessage?: string;
    isDataLoading?: boolean;
    columnsClassName?: string;
    className?: string;
    ["data-testid"]?: string;
}

const DataTable = <T extends object>({
    data,
    columns,
    idColumn,
    highlightedRowIds,
    columnsVisibleOnTabletCount = 2,
    columnsVisibleOnSmartphoneCount = 1,
    headerStickTop,
    withTopTransition,
    withOnSortScroll,
    noDataMessage,
    isDataLoading,
    columnsClassName,
    className,
    "data-testid": testId = "data-table",
}: Props<T>) => {
    const deviceClass = useDeviceClass();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [rowWithShownActionOptionsId, setRowWithShownActionOptionsId] = useState<string>();
    const [columnVisibilityOffset, setColumnVisibilityOffset] = useState(0);

    const nonHiddenColumns = columns.filter((column) => !column.hidden);
    const actionColumn = columns.find((column) => column.actionColumn);
    const nonFirstColumnsToDisplayCount =
        deviceClass === "desktop"
            ? nonHiddenColumns.length
            : deviceClass === "tablet"
                ? columnsVisibleOnTabletCount
                : columnsVisibleOnSmartphoneCount;

    const currentlyVisibleColumns = (() => {
        const visibleColumns = [
            nonHiddenColumns[0],
            ...nonHiddenColumns
                .slice(columnVisibilityOffset + 1, nonFirstColumnsToDisplayCount + columnVisibilityOffset + 1)
                .filter((column) => !column.actionColumn),
        ];

        return !!actionColumn ? [...visibleColumns, actionColumn] : visibleColumns;
    })();

    const getActionDisplay = (actionColumn: TableColumn<T>) => {
        switch (deviceClass) {
            case "smartphone":
                return actionColumn.actionDisplay!;
            case "tablet":
                return actionColumn.actionTabletDisplay ?? actionColumn.actionDisplay!;
            case "desktop":
                return actionColumn.actionDesktopDisplay ?? actionColumn.actionDisplay!;
        }
    };

    const onLocalSort = (column: TableColumn<T>) => {
        if (!column.withSort) {
            return;
        }

        const direction =
            column.currentSortDirection !== undefined ? (column.currentSortDirection + 1) % 2 : SortDirection.Ascending;

        withOnSortScroll &&
            window.scrollTo(
                0,
                window.scrollY +
                (containerRef.current?.getBoundingClientRect()?.top ?? 0) -
                headerHeight +
                onSortScrollTopCorrectionOffset
            );

        column.sortCallback && column.sortCallback(direction);
    };

    useEffect(() => {
        setColumnVisibilityOffset(0);
    }, [deviceClass]);

    return (
        <div className={classNames(styles["data-table__container"], className)} ref={containerRef}>
            <div data-testid={testId} className={styles["data-table"]}>
                {currentlyVisibleColumns.map((column, columnIndex) => (
                    <div
                        data-testid={`${testId}__column`}
                        key={column.key ?? "action-column"}
                        className={classNames(
                            styles["data-table__column"],
                            {
                                [styles["data-table__column--action"]]: column.actionColumn,
                            },
                            columnsClassName
                        )}
                    >
                        <HeaderCell
                            data-testid={`${testId}__header-cell`}
                            label={column.actionColumn ? getActionDisplay(column) : column.label!}
                            isActionColumn={!!column.actionColumn}
                            withSort={column.withSort}
                            withFilter={column.withFilter}
                            isSortActive={column.withSort && column.currentSortDirection !== undefined}
                            isSortAscending={column.withSort && column.currentSortDirection === SortDirection.Ascending}
                            filterOptions={column.filterOptions}
                            top={headerStickTop}
                            withTopTransition={withTopTransition}
                            onSort={() => onLocalSort(column)}
                        />
                        {data.length > 0 &&
                            data.map((record, index) => (
                                <div
                                    data-testid={`${testId}__data-cell-${column.key}`}
                                    key={`${index}-${record[idColumn]}`}
                                    className={classNames(
                                        styles["data-table__cell"],
                                        {
                                            [styles["data-table__cell--data"]]: !column.actionColumn,
                                        },
                                        {
                                            [styles["data-table__cell--highlighted"]]: highlightedRowIds?.includes(
                                                `${record[idColumn]}`
                                            ),
                                        },
                                        {
                                            [styles["data-table__cell--no-bottom-border"]]:
                                                index === data.length - 1 &&
                                                currentlyVisibleColumns.length <
                                                nonHiddenColumns.length - (!!actionColumn ? 1 : 0),
                                        }
                                    )}
                                >
                                    {column.actionColumn ? (
                                        <ActionCell
                                            data-testid={`${testId}__action-cell`}
                                            id={`${record[idColumn]}`}
                                            display={getActionDisplay(column)}
                                            shouldShowOptions={
                                                rowWithShownActionOptionsId === `${record[idColumn]}` &&
                                                !!column.actionOptions
                                            }
                                            actionOptions={column.actionOptions}
                                            actionCallback={column.actionCallback}
                                            onActionOptionsToggle={setRowWithShownActionOptionsId}
                                        />
                                    ) : (
                                        <>
                                            {columnIndex === 0 &&
                                                highlightedRowIds?.includes(`${record[idColumn]}`) && (
                                                    <div
                                                        data-testid={`${testId}__red-dot-${column.key}`}
                                                        className={styles["data-table__red-dot"]}
                                                    />
                                                )}
                                            <span className={styles["data-table__cell-display"]}>
                                                {column.customRender
                                                    ? column.customRender(record[column.key])
                                                    : record[column.key]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            ))}
                    </div>
                ))}
            </div>
            {isDataLoading && <Spinner data-testid={`${testId}__spinner`} className={styles["data-table__spinner"]} />}
            {!isDataLoading && data.length === 0 && (
                <div data-testid={`${testId}__no-data-message`} className={styles["data-table__no-data-message"]}>
                    {noDataMessage ?? <FormattedMessage id="data-table__no-data" />}
                </div>
            )}
            {deviceClass !== "desktop" && nonHiddenColumns.length !== currentlyVisibleColumns.length && (
                <div data-testid={`${testId}__column-toggles`} className={styles["data-table__column-toggles"]}>
                    {columnVisibilityOffset > 0 ? (
                        <div
                            data-testid={`${testId}__prev-column-toggle`}
                            className={styles["data-table__column-toggle"]}
                            onClick={() => setColumnVisibilityOffset((prev) => prev - 1)}
                        >
                            <ChevronDown />
                        </div>
                    ) : (
                        <div />
                    )}
                    {columnVisibilityOffset <
                        nonHiddenColumns.length - (!!actionColumn ? 1 : 0) - nonFirstColumnsToDisplayCount - 1 ? (
                        <div
                            data-testid={`${testId}__next-column-toggle`}
                            className={styles["data-table__column-toggle"]}
                            onClick={() => setColumnVisibilityOffset((prev) => prev + 1)}
                        >
                            <ChevronDown />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
};

export default DataTable;
