import { ReactComponent as EnvelopeIcon } from "assets/icons/envelope.svg";
import RedDotCounter from "features/common/components/RedDotCounter";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    unreadMessagesCount?: number;
    ["data-testid"]?: string;
}

const MessagesNotificationIcon = ({ unreadMessagesCount, "data-testid": testId }: Props) => {
    return (
        <div data-testid={testId} className={styles["messages-notification-icon"]}>
            <EnvelopeIcon />
            <RedDotCounter
                className={styles["messages-notification-icon__unread-counter"]}
                count={unreadMessagesCount}
            />
        </div>
    );
};

export default MessagesNotificationIcon;
