import { ApiError } from "api/utils";
import Checkbox from "features/common/components/Checkbox";
import FormDropdown from "features/common/components/FormDropdown";
import JoinedInput from "features/common/components/JoinedInput";
import RadioButtons from "features/common/components/RadioButtons";
import { Currency, SalaryType } from "features/common/models";
import { JobOfferFinancialStepSchema } from "features/jobOffers/schemas";
import { getCreatePayoutDayMessage } from "features/jobOffers/translationMessages";
import { PayoutDayType } from "features/jobOffers/types";
import { createOfferFieldNames } from "features/offers/constants";
import { useCreateOfferField } from "features/offers/hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import styles from "./styles.module.scss";

const createSalaryTypeOptions = (intl: IntlShape) => [
    {
        label: intl.formatMessage({ id: "common__salary-type-monthly" }),
        value: SalaryType.Monthly,
    },
    {
        label: intl.formatMessage({ id: "common__salary-type-hourly" }),
        value: SalaryType.Hourly,
    },
];

const createPayoutDayTypeOptions = (intl: IntlShape) => {
    const translationMessage = getCreatePayoutDayMessage(intl);
    return [
        {
            display: translationMessage(PayoutDayType.FirstDay),
            value: PayoutDayType.FirstDay,
        },
        {
            display: translationMessage(PayoutDayType.ThirdDay),
            value: PayoutDayType.ThirdDay,
        },
        {
            display: translationMessage(PayoutDayType.FifthDay),
            value: PayoutDayType.FifthDay,
        },
        {
            display: translationMessage(PayoutDayType.OneWeek),
            value: PayoutDayType.OneWeek,
        },
        {
            display: translationMessage(PayoutDayType.TwoWeeks),
            value: PayoutDayType.TwoWeeks,
        },
        {
            display: translationMessage(PayoutDayType.OneMonth),
            value: PayoutDayType.OneMonth,
        },
        {
            display: translationMessage(PayoutDayType.TwoMonths),
            value: PayoutDayType.TwoMonths,
        },
        {
            display: translationMessage(PayoutDayType.ThreeMonths),
            value: PayoutDayType.ThreeMonths,
        },
    ];
};

const mapCurrenciesToDropdownOptions = (items: Currency[] = []) =>
    items.map((item) => ({
        value: `${item.code}`,
        display: item.name,
    }));

export interface Props {
    values: JobOfferFinancialStepSchema;
    isEmployeeOffer?: boolean;
    currencies?: Currency[];
    currenciesLoading?: boolean;
    currenciesLoadingError?: ApiError;
    getCurrenciesAsync: () => void;
    disableBonus?: boolean
}

const OfferDetailsStep = ({ values, isEmployeeOffer, currencies, currenciesLoading, getCurrenciesAsync, disableBonus }: Props) => {
    const intl = useIntl();

    const [isGetBonusDisabled, setIsGetBonusDisabled] = useState(false);

    useEffect(() => {
        isGetBonusEditable()

        if (!currencies && !currenciesLoading) {
            getCurrenciesAsync();
        }
    }, [currencies, currenciesLoading, getCurrenciesAsync]);

    const salaryTypeOptions = useMemo(() => createSalaryTypeOptions(intl), [intl]);
    const currenciesOptions = useMemo(() => mapCurrenciesToDropdownOptions(currencies), [currencies]);
    const bonusTypeOptions = useMemo(() => createPayoutDayTypeOptions(intl), [intl]);

    const [salaryTypeField, salaryTypeMeta, salaryTypeHandlers] = useCreateOfferField<SalaryType | undefined>(
        "financialStep",
        createOfferFieldNames.financialStep.salaryType
    );

    const [salaryValueField, salaryValueMeta, salaryValueHandlers] = useCreateOfferField<number | undefined>(
        "financialStep",
        createOfferFieldNames.financialStep.salaryMinValue
    );

    const [salaryCurrencyCodeField, salaryCurrencyCodeMeta, salaryCurrencyCodeHandlers] = useCreateOfferField<
        string | undefined
    >("financialStep", createOfferFieldNames.financialStep.salaryCurrencyCode);

    const [salaryMaxValueField, salaryMaxValueMeta, salaryMaxValueHandlers] = useCreateOfferField<number | undefined>(
        "financialStep",
        createOfferFieldNames.financialStep.salaryMaxValue
    );

    const [bonusValueField, bonusValueMeta, bonusValueHandlers] = useCreateOfferField<number | undefined>(
        "financialStep",
        createOfferFieldNames.financialStep.bonusValue
    );

    const [bonusCurrencyCodeField, bonusCurrencyCodeMeta, bonusCurrencyCodeHandlers] = useCreateOfferField<
        string | undefined
    >("financialStep", createOfferFieldNames.financialStep.bonusCurrencyCode);

    const [bonusTypeField, bonusTypeMeta, bonusTypeHandlers] = useCreateOfferField<string | undefined>(
        "financialStep",
        createOfferFieldNames.financialStep.bonusType
    );

    const [attachBonusField, , attachBonusHandlers] = useCreateOfferField<boolean>(
        "financialStep",
        createOfferFieldNames.financialStep.attachBonus
    );

    const handleBonusTypeChange = useCallback(
        (value) => {
            if (!bonusTypeMeta.touched) {
                bonusTypeHandlers.setTouched(true);
            }
            bonusTypeHandlers.setValue(value);
        },
        [bonusTypeHandlers, bonusTypeMeta.touched]
    );

    const selectedBonusType = useMemo(
        () => bonusTypeOptions.find((item) => bonusTypeField.value === item.value),
        [bonusTypeField.value, bonusTypeOptions]
    );

    const isGetBonusEditable = () => {
        const path = window.location.pathname.split("/").slice(0, -1).join("/") + "/";
        if (path === "/my-job-offers/edit/") {
            setIsGetBonusDisabled(true);
        };
    }

    return (
        <div className={styles["financial-step"]} id="financialStep">
            <div className={styles["financial-step__column"]}>
                <div className={styles["financial-step__column-title"]}>
                    <FormattedMessage id="create-job-offer__payment-information" />
                </div>
                <RadioButtons<SalaryType>
                    data-testid="financial-step__salary-type"
                    inputClassName={styles["financial-step__salary-type-input"]}
                    options={salaryTypeOptions}
                    wrapperClassName={styles["financial-step__salary-type-wrapper"]}
                    inputsContainerClassName={styles["financial-step__salary-type-inputs-container"]}
                    value={salaryTypeField.value}
                    onValueChange={(value: SalaryType) => salaryTypeHandlers.setValue(value)}
                    isInvalid={!!salaryTypeMeta.error && !!salaryTypeMeta.touched}
                    error={salaryTypeMeta.error}
                    withValidation
                />
                <div className={styles["financial-step__salary-bonus-container-wrapper"]}>
                    <div className={styles["financial-step__salary-container"]}>
                        <JoinedInput
                            data-testid="financial-step__salary-input"
                            id="financial-step__salary-input"
                            inputType="number"
                            className={styles["financial-step__salary-input"]}
                            inputsContainerClassName={styles["financial-step__joined-input-container"]}
                            valueInputLabel={`${intl.formatMessage({ id: "filters-view__minimum-amount" })}${"*"}`}
                            unitInputLabel={`${intl.formatMessage({ id: "common__currency" })}${"*"}`}
                            dropdownOptions={currenciesOptions}
                            valueFieldValue={salaryValueField.value?.toString()}
                            unitFieldValue={salaryCurrencyCodeField.value}
                            valueFieldSetValue={(value) => {
                                if (!salaryValueMeta.touched) {
                                    salaryValueHandlers.setTouched(true);
                                }
                                salaryValueHandlers.setValue(+value);
                            }}
                            unitFieldSetValue={(value) => {
                                if (!salaryCurrencyCodeMeta.touched) {
                                    salaryCurrencyCodeHandlers.setTouched(true);
                                }
                                salaryCurrencyCodeHandlers.setValue(value);
                            }}
                            valueFieldError={salaryValueMeta.error}
                            valueFieldTouched={salaryValueMeta.touched}
                            unitFieldError={salaryCurrencyCodeMeta.error}
                            unitFieldTouched={salaryCurrencyCodeMeta.touched}
                            inputContainerClassName={styles["financial-step__salary-input-container"]}
                            formDropdownClassName={styles["financial-step__salary-input-dropdown"]}
                            withValidation
                        />
                    </div>
                    <div className={styles["financial-step__salary-container"]}>
                        <JoinedInput
                            data-testid="financial-step__salary-max-input"
                            id="financial-step__salary-max-input"
                            inputType="number"
                            className={styles["financial-step__salary-input"]}
                            inputsContainerClassName={styles["financial-step__joined-input-container"]}
                            valueInputLabel={`${intl.formatMessage({ id: "filters-view__maximum-amount" })}`}
                            unitInputLabel={`${intl.formatMessage({ id: "common__currency" })}`}
                            dropdownOptions={currenciesOptions}
                            valueFieldValue={salaryMaxValueField.value?.toString()}
                            unitFieldValue={salaryCurrencyCodeField.value}
                            valueFieldSetValue={(value) => {
                                if (!salaryMaxValueMeta.touched) {
                                    salaryMaxValueHandlers.setTouched(true);
                                }
                                salaryMaxValueHandlers.setValue(value ? +value : undefined);
                            }}
                            unitFieldSetValue={(value) => {
                                if (!salaryCurrencyCodeMeta.touched) {
                                    salaryCurrencyCodeHandlers.setTouched(true);
                                }
                                salaryCurrencyCodeHandlers.setValue(value);
                            }}
                            valueFieldError={salaryMaxValueMeta.error}
                            valueFieldTouched={salaryMaxValueMeta.touched}
                            unitFieldError={salaryCurrencyCodeMeta.error}
                            unitFieldTouched={salaryCurrencyCodeMeta.touched}
                            inputContainerClassName={styles["financial-step__salary-input-container"]}
                            formDropdownClassName={styles["financial-step__salary-input-dropdown"]}
                            withValidation
                        />
                    </div>
                </div>
            </div>
            {process.env.REACT_APP_GETBONUS_ENABLED === "true" ? (
                <div className={styles["financial-step__bonus"]}>
                    <div className={styles["financial-step__column-title"]}>
                        <FormattedMessage id="create-job-offer__bonus-information" />
                    </div>
                    <Checkbox
                        className={styles["financial-step__checkbox"]}
                        checked={attachBonusField.value}
                        disabled={disableBonus}
                        label={intl.formatMessage({ id: "create-offer__attach-bonus-label" })}
                        onToggle={(checked: boolean) => {
                            bonusValueHandlers.setValue(undefined)
                            bonusTypeHandlers.setValue(undefined)
                            attachBonusHandlers.setValue(checked);
                        }}
                    />
                    <div className={styles["financial-step__salary-bonus-container-wrapper"]}>
                        <JoinedInput
                            data-testid="financial-step__bonus-input"
                            id="financial-step__bonus-input"
                            inputType="number"
                            inputsContainerClassName={styles["financial-step__joined-input-container"]}
                            valueInputLabel={intl.formatMessage({ id: "create-offer__bonus-amount-label" })}
                            unitInputLabel={intl.formatMessage({ id: "common__currency" })}
                            dropdownOptions={currenciesOptions}
                            valueFieldValue={bonusValueField.value?.toString()}
                            unitFieldValue={bonusCurrencyCodeField.value}
                            valueFieldError={bonusValueMeta.error}
                            valueFieldTouched={bonusValueMeta.touched}
                            unitFieldError={bonusCurrencyCodeMeta.error}
                            unitFieldTouched={bonusCurrencyCodeMeta.touched}
                            valueFieldSetValue={(value) => {
                                if (!bonusValueMeta.touched) {
                                    bonusValueHandlers.setTouched(true);
                                }
                                bonusValueHandlers.setValue(+value);
                            }}
                            unitFieldSetValue={(value) => {
                                if (!bonusCurrencyCodeMeta.touched) {
                                    bonusCurrencyCodeHandlers.setTouched(true);
                                }
                                bonusCurrencyCodeHandlers.setValue(value);
                            }}
                            inputContainerClassName={styles["financial-step__bonus-input-container"]}
                            formDropdownClassName={styles["financial-step__bonus-input-dropdown"]}
                            withValidation
                            disabled={!attachBonusField.value || disableBonus}
                        />
                        <FormDropdown<PayoutDayType>
                            data-testid="financial-step__bonus-type-input"
                            id="financial-step__bonus-type-input"
                            className={styles["financial-step__input"]}
                            label={intl.formatMessage({ id: "common__bonus-payout-day" })}
                            placeholder={intl.formatMessage({ id: "common__bonus-payout-day" })}
                            options={bonusTypeOptions}
                            selected={selectedBonusType}
                            onSelect={handleBonusTypeChange}
                            isInvalid={!!bonusTypeMeta.error && !!bonusTypeMeta.touched}
                            error={bonusTypeMeta.error}
                            withValidation
                            helperMessages={[intl.formatMessage({ id: "create-offer__job-start" })]}
                            disabled={!attachBonusField.value || disableBonus}
                        />
                    </div>
                </div>
            ): null}
        </div>
    );
};

export default OfferDetailsStep;
