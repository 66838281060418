import Calendar from "features/common/components/Datepicker/Calendar";
import useDaysOfMonthArray from "features/common/hooks/useDaysOfMonthArray";
import React, { useCallback } from "react";

interface Props {
    id: string;
    baseDate: Date;
    currentMonth: number;
    currentEndMonth: number;
    currentYear: number;
    currentEndYear: number;
    yearsArray: Array<Array<number>>;
    yearSelectorExpanded: boolean;
    date?: Date;
    endDate?: Date;
    selectedDate?: Date;
    hoveredDate?: Date;
    withRangePicker?: boolean;
    addForwardYears?: boolean;
    ["data-testid"]: string;
    setYearSelectorExpanded: (state: boolean) => void;
    setHoveredDate: (date?: Date) => void;
    onMonthSelect: (month: number) => void;
    onYearSelect: (year: number) => void;
    onEndMonthSelect?: (month: number) => void;
    onEndYearSelect?: (year: number) => void;
    onLocalDateSelect: (year: number, month: number, day?: number) => void;
    startFromCurrentDate?: boolean;
    startFromPickedDate?: boolean;
    endOnCurrentDate?: boolean;
}

const DesktopCalendars = ({
    id,
    currentMonth,
    currentEndMonth,
    currentYear,
    currentEndYear,
    yearsArray,
    yearSelectorExpanded,
    baseDate,
    date,
    endDate,
    selectedDate,
    hoveredDate,
    withRangePicker,
    addForwardYears,
    "data-testid": testId,
    setYearSelectorExpanded,
    setHoveredDate,
    onMonthSelect,
    onYearSelect,
    onEndMonthSelect,
    onEndYearSelect,
    onLocalDateSelect,
    startFromCurrentDate,
    startFromPickedDate,
    endOnCurrentDate
}: Props) => {
    const daysOfMonthArray = useDaysOfMonthArray(currentMonth, currentYear);
    const endDaysOfMonthArray = useDaysOfMonthArray(currentEndMonth, currentEndYear);

    const areCurrentRangeMonthsAdjacent = useCallback(
        () =>
            (currentYear === currentEndYear && currentMonth === currentEndMonth - 1) ||
            (currentYear === currentEndYear - 1 && currentMonth === 11 && currentEndMonth === 0),
        [currentEndMonth, currentEndYear, currentMonth, currentYear]
    );
    
    return (
        <>
            <Calendar
                id={id}
                startFromCurrentDate={startFromCurrentDate}
                endOnCurrentDate={endOnCurrentDate}
                startFromPickedDate = {startFromPickedDate}
                data-testid={testId}
                deviceClass="desktop"
                currentMonth={currentMonth}
                currentYear={currentYear}
                yearSelectorExpanded={yearSelectorExpanded}
                yearsArray={yearsArray}
                daysOfMonthArray={daysOfMonthArray}
                date={date}
                endDate={endDate}
                selectedDate={selectedDate}
                hoveredDate={hoveredDate}
                baseDate={baseDate}
                withRangePicker={withRangePicker}
                addForwardYears={addForwardYears}
                shouldBlockPrevMonthSelect={false}
                shouldBlockNextMonthSelect={withRangePicker ? areCurrentRangeMonthsAdjacent() : false}
                setYearSelectionExpandState={setYearSelectorExpanded}
                onMonthSelect={onMonthSelect}
                onYearSelect={onYearSelect}
                onLocalDateSelect={(day?: number) => onLocalDateSelect(currentYear, currentMonth, day)}
                onHover={(date) => setHoveredDate(date)}
            />
            {withRangePicker && (
                <Calendar
                    startFromCurrentDate={startFromCurrentDate}
                    endOnCurrentDate={endOnCurrentDate}
                    id={`${id}__next`}
                    data-testid={`${testId}__next`}
                    deviceClass="desktop"
                    currentMonth={currentEndMonth}
                    currentYear={currentEndYear}
                    yearSelectorExpanded={false}
                    daysOfMonthArray={endDaysOfMonthArray}
                    date={date}
                    endDate={endDate}
                    hoveredDate={hoveredDate}
                    baseDate={baseDate}
                    withRangePicker={withRangePicker}
                    addForwardYears={addForwardYears}
                    shouldBlockPrevMonthSelect={areCurrentRangeMonthsAdjacent()}
                    shouldBlockNextMonthSelect={false}
                    onLocalDateSelect={(day?: number) => onLocalDateSelect(currentEndYear, currentEndMonth, day)}
                    onHover={(date) => setHoveredDate(date)}
                    onMonthSelect={onEndMonthSelect}
                    onYearSelect={onEndYearSelect}
                />
            )}
        </>
    );
};

export default DesktopCalendars;
