import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const createRootElement = (id: string) => {
    const rootContainer = document.createElement("div");
    rootContainer.setAttribute("id", id);

    return rootContainer;
};

const addRootElement = (rootElement: HTMLElement | Element) => {
    document.body.insertBefore(rootElement, document.body.lastElementChild!.nextElementSibling);
};

const usePortal = (id: string, children: React.ReactNode) => {
    const rootElementRef = useRef<HTMLElement>();

    useEffect(() => {
        const existingParent = document.querySelector(`#${id}`);
        const parentElement = existingParent || createRootElement(id);

        if (!existingParent) {
            addRootElement(parentElement);
        }

        parentElement.appendChild(rootElementRef.current!);

        return () => {
            rootElementRef.current?.remove();
            if (!parentElement.childElementCount) {
                parentElement.remove();
            }
        };
    }, [id]);

    const getRootElement = () => {
        if (!rootElementRef.current) {
            rootElementRef.current = document.createElement("div");
        }
        return rootElementRef.current;
    };

    return createPortal(children, getRootElement());
};

export default usePortal;
