import {makeApiRequest} from "api/makeApiRequest";
import {CancelToken} from "api/utils";
import AdminPanelAction, {
    amendUsers,
    blockUser,
    deleteUser,
    deleteUserOffer,
    downloadUsersList,
    getStatistics,
    getUserOffers,
    getUsers,
    unblockUser,
} from "features/adminPanel/actions";
import {AdminPanelApi} from "features/adminPanel/api";
import {GetUsersFetchParams} from "features/adminPanel/types";
import {OfferType} from "features/common/types";
import {ThunkAction} from "redux-thunk";
import {RootState} from "store";

export const getStatisticsAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "getStatisticsAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getStatistics.request());

        await makeApiRequest(
            api.getStatisticsAsync,
            [cancelToken],
            getStatistics.success,
            getStatistics.failure,
            dispatch
        );
    };
};

export const getUsersAsync = (
    fetchParams: GetUsersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "getUsersAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getUsers.request());

        await makeApiRequest(
            api.getUsersAsync,
            [fetchParams, cancelToken],
            getUsers.success,
            getUsers.failure,
            dispatch
        );
    };
};

export const amendUsersAsync = (
    fetchParams: GetUsersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "getUsersAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(amendUsers.request());

        await makeApiRequest(
            api.getUsersAsync,
            [fetchParams, cancelToken],
            amendUsers.success,
            amendUsers.failure,
            dispatch
        );
    };
};

export const blockUserAsync = (
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "blockUserAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(blockUser.request());

        await makeApiRequest(api.blockUserAsync, [userId, cancelToken], blockUser.success, blockUser.failure, dispatch);
    };
};

export const unblockUserAsync = (
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "unblockUserAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(unblockUser.request());

        await makeApiRequest(api.unblockUserAsync, [userId, cancelToken], unblockUser.success, unblockUser.failure, dispatch);
    };
};

export const deleteUserAsync = (
    userId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "deleteUserAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(deleteUser.request());

        await makeApiRequest(api.deleteUserAsync, [userId, cancelToken], deleteUser.success, deleteUser.failure, dispatch);
    };
};

export const downloadUsersListAsync = (
    totalUsers: number,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<AdminPanelApi, "getUsersAsync">, AdminPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(downloadUsersList.request());

        try {
            const usersData = await api.getUsersAsync({ pageSize: totalUsers, offset: 0 }, cancelToken);
            let csv = "Id,Imię,Nazwisko,Email\n";
            usersData.data.content.forEach(
                (item) => (csv += `${item.id},${item.firstname},${item.lastname},${item.email}\n`)
            );
            const hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = "Lista Użytkowników.csv";
            hiddenElement.click();
            dispatch(downloadUsersList.success());
        } catch (error) {
            dispatch(downloadUsersList.failure(error));
        }
    };
};

export const getUserOffersAsync = (
    userId: string,
    offersType: OfferType,
    pageNumber: number,
    cancelToken?: CancelToken
): ThunkAction<
    void,
    RootState,
    Pick<AdminPanelApi, "getUserJobOffersAsync" | "getUserEmployeeOffersAsync">,
    AdminPanelAction
> => {
    return async (dispatch, getState, api) => {
        dispatch(getUserOffers.request());

        if (offersType === OfferType.Job) {
            await makeApiRequest(
                api.getUserJobOffersAsync,
                [userId, pageNumber, cancelToken],
                getUserOffers.success,
                getUserOffers.failure,
                dispatch
            );
        } else {
            await makeApiRequest(
                api.getUserEmployeeOffersAsync,
                [userId, pageNumber, cancelToken],
                getUserOffers.success,
                getUserOffers.failure,
                dispatch
            );
        }
    };
};

export const deleteUserOfferAsync = (
    offerId: number,
    offerType: OfferType,
    cancelToken?: CancelToken
): ThunkAction<
    void,
    RootState,
    Pick<AdminPanelApi, "deleteUserJobOfferAsync" | "deleteUserEmployeeOfferAsync">,
    AdminPanelAction
> => {
    return async (dispatch, getState, api) => {
        dispatch(deleteUserOffer.request());

        if (offerType === OfferType.Job) {
            await makeApiRequest(
                api.deleteUserJobOfferAsync,
                [offerId, cancelToken],
                deleteUserOffer.success,
                deleteUserOffer.failure,
                dispatch
            );
        } else {
            await makeApiRequest(
                api.deleteUserEmployeeOfferAsync,
                [offerId, cancelToken],
                deleteUserOffer.success,
                deleteUserOffer.failure,
                dispatch
            );
        }
    };
};
