import classNames from "classnames";
import React, { SyntheticEvent } from "react";
import styles from "./styles.module.scss";

type ButtonVariant = "secondary";
type ButtonType = "button";

export interface Props {
    children: string | React.ReactNode;
    className?: string;
    selectedClassName?: string;
    variant?: ButtonVariant;
    type?: ButtonType;
    value: string;
    disabled?: boolean;
    isSelected?: boolean;
    ["data-testid"]?: string;
    onClick?: (event: SyntheticEvent<HTMLElement>) => void;
}

const ToggleButton = ({
    children,
    className,
    selectedClassName,
    variant,
    type,
    value,
    disabled,
    isSelected,
    "data-testid": testId,
    onClick,
}: Props) => (
    <button
        type={type}
        data-testid={testId}
        disabled={disabled}
        className={classNames(styles["toggle-button"], className, isSelected ? selectedClassName : "", {
            [styles["toggle-button--secondary"]]: variant === "secondary",
        })}
        onClick={(event) => onClick && onClick(event)}
        value={value}
    >
        {children}
    </button>
);

export default ToggleButton;
