import {
    selectRejectApplicationLoading,
    selectRejectApplicationSuccess,
    selectRejectApplicationError,
} from "features/jobOffers/selectors";
import { rejectApplicationAsync } from "features/jobOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import RejectApplicationModal from "./component";

const mapStateToProps = (state: RootState) => ({
    rejectApplicationLoading: selectRejectApplicationLoading(state),
    rejectApplicationSuccess: selectRejectApplicationSuccess(state),
    rejectApplicationError: selectRejectApplicationError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            rejectApplicationAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RejectApplicationModal);
