import { OfferType } from "features/common/types";
import { resetMyJobOffers } from "features/jobOffers/actions";
import {
    selectMyJobOffers,
    selectMyJobOffersLoading,
    selectMyJobOffersLoadingError,
} from "features/jobOffers/selectors";
import { getMyJobOffersAsync } from "features/jobOffers/thunks";
import MyOffers from "features/offers/components/MyOffers";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

interface OwnProps {
    offerType: OfferType;
}

const mapStateToProps = (state: RootState, { offerType }: OwnProps) => ({
    offerType,
    myOffers: selectMyJobOffers(state),
    myOffersLoading: selectMyJobOffersLoading(state),
    myOffersLoadingError: selectMyJobOffersLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ getMyOffersAsync: getMyJobOffersAsync, resetMyOffers: () => resetMyJobOffers() }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyOffers);
