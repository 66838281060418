/* eslint-disable max-lines */
import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as ApplicationAccepted } from "assets/icons/applicationAccepted.svg";
import { ReactComponent as ApplicationDenied } from "assets/icons/applicationDenied.svg";
import { ReactComponent as ApplicationSent } from "assets/icons/applicationSent.svg";
import { ReactComponent as ArrivalAccepted } from "assets/icons/arrivalAccepted.svg";
import { ReactComponent as BonusGiven } from "assets/icons/bonusGiven.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevronRight.svg";
import classNames from "classnames";
import { pl } from "date-fns/locale";
import Button from "features/common/components/Button";
import Modal from "features/common/components/Modal";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDateFormat from "features/common/hooks/useDateFormat";
import { ApplicationDetails } from "features/jobOffers/models";
import {
    getDetailsAcceptedApplicantsNumberMessage,
    getDetailsApplicantsNumberMessage,
    getDetailsApplicationDateMessage,
    getDetailsJobApplicantNameMessage,
    getDetailsJobApplicantUserMessage,
    getDetailsOfferOwnerNameMessage,
} from "features/jobOffers/translationMessages";
import { ApplicationStatus } from "features/messages/types";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import AcceptApplicationModal from "./AcceptApplicationModal";
import AcceptArrivalModal from "./AcceptArrivalModal";
import GiveBonusModal from "./GiveBonusModal";
import RejectApplicationModal from "./RejectApplicationModal";
import styles from "./styles.module.scss";

const getApplicationStatusTranslationText = (intl: IntlShape, applicationStatus: ApplicationStatus | undefined) => {
    switch (applicationStatus) {
        case ApplicationStatus.ApplicationSent:
            return intl.formatMessage({ id: "common__application-status--sent" });
        case ApplicationStatus.ApplicationAccepted:
            return intl.formatMessage({ id: "common__application-status--accepted" });
        case ApplicationStatus.ApplicationDenied:
            return intl.formatMessage({ id: "common__application-status--denied" });
        case ApplicationStatus.ArrivalAccepted:
            return intl.formatMessage({ id: "common__application-status--arrival-accepted" });
        case ApplicationStatus.BonusGiven:
            return intl.formatMessage({ id: "common__application-status--bonus-given" });
        default:
            return null;
    }
};

const getApplicationStatusIcon = (applicationStatus?: ApplicationStatus) => {
    switch (applicationStatus) {
        case ApplicationStatus.ApplicationSent:
            return <ApplicationSent />;
        case ApplicationStatus.ApplicationAccepted:
            return <ApplicationAccepted />;
        case ApplicationStatus.ApplicationDenied:
            return <ApplicationDenied />;
        case ApplicationStatus.ArrivalAccepted:
            return <ArrivalAccepted />;
        case ApplicationStatus.BonusGiven:
            return <BonusGiven />;
        default:
            return null;
    }
};

export interface Props {
    id: number;
    user?: User;
    applicationDetails?: ApplicationDetails;
    applicationDetailsLoading: boolean;
    applicationDetailsError?: ApiError;
    acceptApplicationSuccess?: boolean;
    rejectApplicationSuccess?: boolean;
    acceptArrivalSuccess?: boolean;
    giveBonusSuccess?: boolean;
    isOfferOwner?: boolean;
    getApplicationDetailsAsync: (id: number, isOfferOwner: boolean, cancelToken?: CancelToken) => void;
    handleCloseModal: () => void;
    setSelectedApplicationId?: React.Dispatch<React.SetStateAction<number | null>>
}

const ApplicationDetailsModal = ({
    id,
    user,
    applicationDetails,
    applicationDetailsLoading,
    applicationDetailsError,
    acceptApplicationSuccess,
    rejectApplicationSuccess,
    acceptArrivalSuccess,
    giveBonusSuccess,
    isOfferOwner,
    getApplicationDetailsAsync,
    handleCloseModal,
    setSelectedApplicationId
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();
    const getFormattedDate = useDateFormat("d MMM yyyy", { locale: pl });

    const [displayAcceptApplicatonModal, setDisplayAcceptApplicationModal] = useState(false);
    const [displayRejectApplicatonModal, setDisplayRejectApplicatonModal] = useState(false);
    const [displayAcceptArrivalModal, setDisplayAcceptArrivalModal] = useState(false);
    const [displayGiveBonusModal, setDisplayGiveBonusModal] = useState(false);

    useEffect(() => {
        if (id) {
            getApplicationDetailsAsync(id, !!isOfferOwner, createCancelToken());
        }
    }, [createCancelToken, getApplicationDetailsAsync, id, isOfferOwner]);

    useEffect(() => {
        if (acceptApplicationSuccess || rejectApplicationSuccess || acceptArrivalSuccess) {
            getApplicationDetailsAsync(id, !!isOfferOwner, createCancelToken());
            setDisplayAcceptApplicationModal(false);
            setDisplayRejectApplicatonModal(false);
            setDisplayAcceptArrivalModal(false);
        }
    }, [
        acceptApplicationSuccess,
        acceptArrivalSuccess,
        createCancelToken,
        getApplicationDetailsAsync,
        giveBonusSuccess,
        id,
        isOfferOwner,
        rejectApplicationSuccess,
    ]);

    useEffect(() => {
        if (applicationDetailsError) {
            setTimeout(() => {
                handleCloseModal();
            }, 5000);
        }
    }, [applicationDetailsError, handleCloseModal]);

    const applicationStatusIcon = useMemo(
        () => getApplicationStatusIcon(applicationDetails?.applicationStatus),
        [applicationDetails]
    );
    const applicationStatusText = useMemo(
        () => getApplicationStatusTranslationText(intl, applicationDetails?.applicationStatus),
        [intl, applicationDetails]
    );

    const applicationDateMessage = getDetailsApplicationDateMessage(
        intl,
        getFormattedDate(applicationDetails?.applicationDate)?.toUpperCase() || ""
    );
    const applicantsNumberMessage = getDetailsApplicantsNumberMessage(intl, applicationDetails?.applicantsNumber || 1);
    const acceptedApplicantsNumberMessage = getDetailsAcceptedApplicantsNumberMessage(
        intl,
        applicationDetails?.acceptedApplicantsNumber || 0
    );
    const jobApplicantMessage = getDetailsJobApplicantNameMessage(
        intl,
        `${applicationDetails?.jobApplicant?.firstName} ${applicationDetails?.jobApplicant?.lastName[0]}.`
    );
    const jobApplicantContactMessage = getDetailsJobApplicantUserMessage(
        intl,
        `${applicationDetails?.jobApplicant?.firstName} ${applicationDetails?.jobApplicant?.lastName[0]}.`
    );
    const offerOwnerBusinessCardMessage = getDetailsOfferOwnerNameMessage(
        intl,
        `${applicationDetails?.jobOfferBusinessName}.`
    );
    const offerOwnerContactMessage = getDetailsJobApplicantUserMessage(
        intl,
        `${applicationDetails?.offerOwner?.firstName} ${applicationDetails?.offerOwner?.lastName[0]}.`
    );

    const handleCloseAcceptApplicationModal = useCallback(
        () => setDisplayAcceptApplicationModal(false),
        [setDisplayAcceptApplicationModal]
    );
    const handleOpenAcceptApplicationModal = useCallback(
        () => setDisplayAcceptApplicationModal(true),
        [setDisplayAcceptApplicationModal]
    );

    const handleCloseRejectApplicationModal = useCallback(
        () => setDisplayRejectApplicatonModal(false),
        [setDisplayRejectApplicatonModal]
    );
    const handleOpenRejectApplicationModal = useCallback(
        () => setDisplayRejectApplicatonModal(true),
        [setDisplayRejectApplicatonModal]
    );

    const handleCloseAcceptArrivalModal = useCallback(
        () => setDisplayAcceptArrivalModal(false),
        [setDisplayAcceptArrivalModal]
    );
    const handleOpenAcceptArrivalModal = useCallback(
        () => setDisplayAcceptArrivalModal(true),
        [setDisplayAcceptArrivalModal]
    );
    const handleCloseGiveBonusModal = useCallback(() => setDisplayGiveBonusModal(false), [setDisplayGiveBonusModal]);
    const handleOpenGiveBonusModal = useCallback(() => setDisplayGiveBonusModal(true), [setDisplayGiveBonusModal]);
    const handleCloseModalAfterTransaction = useCallback(() => handleCloseModal(), [setDisplayGiveBonusModal]);
    const handleGoToContact = useCallback(
        () => {
            setSelectedApplicationId && setSelectedApplicationId(null);
            history.push(`${appRoutes.messages}/${applicationDetails?.conversationId}`)
        },
        [applicationDetails, history, setSelectedApplicationId]
    );

    const handleGoToProfile = useCallback(
        () => {
            setSelectedApplicationId && setSelectedApplicationId(null);
            history.push(`${appRoutes.profileCard}/${applicationDetails?.jobApplicant?.businessCardId || applicationDetails?.offerOwner?.businessCardId || ""}`)
        },
        [applicationDetails, history, setSelectedApplicationId]
    );

    const handleGoToRate = () => {
        setSelectedApplicationId && setSelectedApplicationId(null);
        history.push(`${applicationDetails?.offerOwner || applicationDetails?.jobApplicant?.type === 'EMPLOYER' ? appRoutes.employer : appRoutes.employee}/${applicationDetails?.id}${appRoutes.rate}`)
    }

    const renderApplicationButtons = useMemo(() => {
        if (
            (applicationDetails?.jobApplicant && user?.id === applicationDetails?.jobApplicant?.userId) ||
            (applicationDetails?.offerOwner && user?.id !== applicationDetails?.offerOwner?.userId)
        ) {
            switch (applicationDetails?.applicationStatus) {
                case ApplicationStatus.ApplicationAccepted:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__primary-button"]
                                    )}
                                    type="submit"
                                    onClick={handleOpenAcceptArrivalModal}
                                >
                                    <FormattedMessage id="application-details__accept-arrival" />
                                </Button>
                            </div>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__tertiary-button"]
                                    )}
                                    type="submit"
                                    variant="tertiary"
                                    onClick={handleCloseModal}
                                >
                                    <FormattedMessage id="application-details__go-back-to-list" />
                                </Button>
                            </div>
                        </div>
                    );
                case applicationDetails?.jobOffer.financialConditions.bonusValue && ApplicationStatus.ArrivalAccepted:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__tertiary-button"]
                                    )}
                                    type="submit"
                                    variant="tertiary"
                                    onClick={handleCloseModal}
                                >
                                    <FormattedMessage id="application-details__go-back-to-list" />
                                </Button>
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div />
                            <Button
                                data-testid="application-details__application-button"
                                className={classNames(
                                    styles["application-details__application-button"],
                                    styles["application-details__tertiary-button"]
                                )}
                                type="submit"
                                variant="tertiary"
                                onClick={handleCloseModal}
                            >
                                <FormattedMessage id="application-details__go-back-to-list" />
                            </Button>
                        </div>
                    );
            }
        } else {
            switch (applicationDetails?.applicationStatus) {
                case ApplicationStatus.ApplicationSent:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__no-background-button"]
                                    )}
                                    type="submit"
                                    variant="no-background"
                                    onClick={handleOpenRejectApplicationModal}
                                >
                                    <TimesIcon width="14" height="14" />
                                    <FormattedMessage id="application-details__deny" />
                                </Button>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__primary-button"]
                                    )}
                                    type="submit"
                                    onClick={handleOpenAcceptApplicationModal}
                                >
                                    <TickIcon width="17" height="14" />
                                    <FormattedMessage id="application-details__accept" />
                                </Button>
                            </div>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__tertiary-button"]
                                    )}
                                    type="submit"
                                    variant="tertiary"
                                    onClick={handleCloseModal}
                                >
                                    <ChevronRightIcon width="7" height="13" />
                                    <FormattedMessage id="application-details__go-back-to-list" />
                                </Button>
                            </div>
                        </div>
                    );
                case ApplicationStatus.ApplicationAccepted:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__tertiary-button"]
                                    )}
                                    type="submit"
                                    variant="tertiary"
                                    onClick={handleCloseModal}
                                >
                                    <ChevronRightIcon width="7" height="13" />
                                    <FormattedMessage id="application-details__go-back-to-list" />
                                </Button>
                            </div>
                        </div>
                    );
                case applicationDetails?.jobOffer.financialConditions.bonusValue && ApplicationStatus.ArrivalAccepted:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__primary-button"]
                                    )}
                                    type="submit"
                                    onClick={handleOpenGiveBonusModal}
                                >
                                    <FormattedMessage id="application-details__give-bonus" />
                                </Button>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__tertiary-button"]
                                    )}
                                    type="submit"
                                    variant="tertiary"
                                    onClick={handleCloseModal}
                                >
                                    <ChevronRightIcon width="7" height="13" />
                                    <FormattedMessage id="application-details__go-back-to-list" />
                                </Button>
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div className={styles["application-details__buttons-container"]}>
                            <div />
                            <div className={styles["application-details__buttons-wrapper"]}>
                                <Button
                                    data-testid="application-details__application-button"
                                    className={classNames(
                                        styles["application-details__application-button"],
                                        styles["application-details__tertiary-button"]
                                    )}
                                    type="submit"
                                    variant="tertiary"
                                    onClick={handleCloseModal}
                                >
                                    <ChevronRightIcon width="7" height="13" />
                                    <FormattedMessage id="application-details__go-back-to-list" />
                                </Button>
                            </div>
                        </div>
                    );
            }
        }
    }, [
        applicationDetails,
        handleCloseModal,
        handleOpenAcceptApplicationModal,
        handleOpenAcceptArrivalModal,
        handleOpenGiveBonusModal,
        handleOpenRejectApplicationModal,
        user,
    ]);

    if (displayAcceptApplicatonModal && applicationDetails) {
        return (
            <AcceptApplicationModal
                id={id}
                applicantsNumber={applicationDetails?.applicantsNumber}
                handleCloseModal={handleCloseAcceptApplicationModal}
            />
        );
    }

    if (displayRejectApplicatonModal && applicationDetails) {
        return <RejectApplicationModal id={id} handleCloseModal={handleCloseRejectApplicationModal} isEmployee={(applicationDetails?.jobApplicant && user?.id === applicationDetails?.jobApplicant?.userId) ||
            (applicationDetails?.offerOwner && user?.id !== applicationDetails?.offerOwner?.userId)} />;
    }

    if (displayAcceptArrivalModal && applicationDetails) {
        return <AcceptArrivalModal id={id} handleCloseModal={handleCloseAcceptArrivalModal} />;
    }

    if (displayGiveBonusModal && applicationDetails) {
        return <GiveBonusModal id={id} handleCloseModal={handleCloseGiveBonusModal} handleCloseModalAfterTransaction={handleCloseModalAfterTransaction} />;
    }

    return applicationDetailsLoading ? (
        <SpinnerWithMessage message={<FormattedMessage id="application-details__loading-application-details" />} />
    ) : (
        <Modal className={styles["application-details"]} parentId="application-modal">
            {applicationDetailsError ? (
                <div className={styles["application-details__details-container"]}>
                    <div
                        className={styles["application-details__close-button"]}
                        data-testid="application-details__close-button"
                        onClick={handleCloseModal}
                    >
                        <TimesIcon />
                    </div>
                    <div className={styles["application-details__error-container"]}>
                        <FormattedMessage id="application-details__fetch-failure" />
                    </div>
                </div>
            ) : (
                <div className={styles["application-details__details-container"]}>
                    <div className={styles["application-details__details-container-wrapper"]}>
                        <div
                            className={styles["application-details__close-button"]}
                            data-testid="application-details__close-button"
                            onClick={handleCloseModal}
                        >
                            <TimesIcon />
                        </div>
                        <div className={styles["application-details__title"]}>
                            <FormattedMessage id="application-details__application-title" values={{ id }} />
                        </div>
                        <div
                            className={classNames(styles["application-details__application-status"], {
                                [styles["application-details__positive"]]:
                                    applicationDetails?.applicationStatus === ApplicationStatus.ApplicationAccepted,
                                [styles["application-details__negative"]]:
                                    applicationDetails?.applicationStatus === ApplicationStatus.ApplicationDenied,
                                [styles["application-details__information"]]:
                                    applicationDetails?.applicationStatus === ApplicationStatus.ApplicationSent ||
                                    applicationDetails?.applicationStatus === ApplicationStatus.ArrivalAccepted ||
                                    applicationDetails?.applicationStatus === ApplicationStatus.BonusGiven,
                            })}
                        >
                            {applicationStatusIcon}
                            {applicationStatusText}
                        </div>
                        <div className={styles["application-details__general-info"]}>
                            <div className={styles["application-details__section-title"]}>
                                <FormattedMessage id="application-details__general-info" />
                            </div>
                            <div className={styles["application-details__field-value"]}>{applicationDateMessage}</div>
                            <div className={styles["application-details__field-value"]}>{applicantsNumberMessage}</div>
                            <div className={styles["application-details__field-value"]}>
                                {acceptedApplicantsNumberMessage}
                            </div>
                        </div>
                        {applicationDetails?.jobApplicant && (
                            <div className={styles["application-details__applicant-info"]}>
                                <div className={styles["application-details__section-title"]}>
                                    <FormattedMessage id="application-details__applicant-info" />
                                </div>
                                <div className={styles["application-details__field-value"]}>{jobApplicantMessage}</div>
                                {applicationDetails.isReadyToRate && applicationDetails.jobApplicant.type === "EMPLOYEE" && (
                                    <div className={styles["application-details__section-button"]} onClick={handleGoToRate}>
                                        <FormattedMessage id='application-details__rate-employee' />
                                    </div>
                                )}
                                {applicationDetails.isReadyToRate && applicationDetails.jobApplicant.type === "EMPLOYER" && (
                                    <div className={styles["application-details__section-button"]} onClick={handleGoToRate}>
                                        <FormattedMessage id='application-details__rate-employer' />
                                    </div>
                                )}
                                <div className={styles["application-details__section-button"]} onClick={handleGoToProfile}>
                                    <FormattedMessage id="application-details__see-profile" />
                                </div>
                            </div>
                        )}
                        {applicationDetails?.offerOwner && (
                            <div className={styles["application-details__applicant-info"]}>
                                <div className={styles["application-details__section-title"]}>
                                    <FormattedMessage id="application-details__employer-info" />
                                </div>
                                <div className={styles["application-details__field-value"]}>{offerOwnerBusinessCardMessage}</div>
                                {applicationDetails.isReadyToRate && (
                                    <div className={styles["application-details__section-button"]} onClick={handleGoToRate}>
                                        <FormattedMessage id='application-details__rate-employer' />
                                    </div>
                                )}
                                <div className={styles["application-details__section-button"]} onClick={handleGoToProfile}>
                                    <FormattedMessage id="application-details__see-profile" />
                                </div>
                            </div>
                        )}
                        {applicationDetails?.jobApplicant && (
                            <div className={styles["application-details__contact-info"]}>
                                <div className={styles["application-details__section-title"]}>
                                    <FormattedMessage id="application-details__contact-info" />
                                </div>
                                <div className={styles["application-details__field-value"]}>
                                    {jobApplicantContactMessage}
                                </div>
                                <div className={styles["application-details__section-button"]} onClick={handleGoToContact}>
                                    <FormattedMessage id="application-details__write-message" />
                                </div>
                            </div>
                        )}
                        {applicationDetails?.offerOwner && (
                            <div className={styles["application-details__contact-info"]}>
                                <div className={styles["application-details__section-title"]}>
                                    <FormattedMessage id="application-details__contact-info" />
                                </div>
                                <div className={styles["application-details__field-value"]}>{offerOwnerContactMessage}</div>
                                <div className={styles["application-details__section-button"]} onClick={handleGoToContact}>
                                    <FormattedMessage id="application-details__write-message" />
                                </div>
                            </div>
                        )}
                        <div className={styles["application-details__content-info"]}>
                            <div className={styles["application-details__section-title"]}>
                                <FormattedMessage id="application-details__content-info" />
                            </div>
                            <div className={styles["application-details__field-value"]}>{applicationDetails?.content}</div>
                            {/* TODO: Fix expanding Content message */}
                            {/* <div className={styles["application-details__section-button"]}>
                            <FormattedMessage id="application-details__expand" />
                        </div> */}
                        </div>
                        <div className={styles["application-details__history-info"]}>
                            <div className={styles["application-details__section-title"]}>
                                <FormattedMessage id="application-details__history-info" />
                            </div>
                            <div className={styles["application-details__table-header"]}>
                                <div className={styles["application-details__table-header-cell"]}>
                                    <FormattedMessage id="application-details__table-status" />
                                </div>
                                <div className={styles["application-details__table-header-cell"]}>
                                    <FormattedMessage id="application-details__table-date" />
                                </div>
                            </div>
                            {applicationDetails?.jobApplicationHistory.map((item) => (
                                <div className={styles["application-details__table-row"]}>
                                    <div className={styles["application-details__table-cell"]}>
                                        {getApplicationStatusIcon(item.applicationStatus)}
                                        <span>{getApplicationStatusTranslationText(intl, item.applicationStatus)}</span>
                                    </div>
                                    <div className={styles["application-details__table-cell"]}>
                                        {getFormattedDate(item.changeDate)?.toUpperCase()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {renderApplicationButtons}
                </div>
            )}
        </Modal>
    );
};

export default ApplicationDetailsModal;
