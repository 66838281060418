import { AuthResponse, PaymentResponse } from "features/jobOffers/api/types";
import { Dispatch, SetStateAction } from "react";
import { isPaymentApproved } from "../../../../api/helpers/isPaymentApproved";

export const challenge =
    (setIsSuccessPayment: Dispatch<SetStateAction<boolean>>, setIsErrorPayment: Dispatch<SetStateAction<boolean>>) =>
    async ({ data, paymentCallback }: { data: AuthResponse; paymentCallback: () => Promise<any> }) => {
        if (data.authenticationStatus === "Y" || data.authenticationStatus === "A") {
            return paymentCallback().then((paymentRes: PaymentResponse) => {
                if (isPaymentApproved(paymentRes.paymentStatus)) {
                    setIsSuccessPayment(true);
                } else {
                    throw new Error("Payment Error");
                }
            });
        }

        if (data.authenticationStatus === "C" && data.challengeHtmlFormBase64) {
            const winUrl = URL.createObjectURL(new Blob([atob(data.challengeHtmlFormBase64)], { type: "text/html" }));
            await window.open(winUrl);
            return;
        }

        setIsErrorPayment(true);
    };
