import React from "react";
import RecoverPasswordComponent from "features/user/components/RecoverPassword";

const RecoverPassword = () => {
    return (
        <RecoverPasswordComponent />
    );
};

export default RecoverPassword;
