import { localStorageItems } from "features/common/constants";
import { AuthToken, Tokens } from "features/user/types";

class AuthStorageService {
    public setTokens = (tokens: Tokens) => {
        const now = new Date();
        const authTokenItem = {
            accessToken: tokens.accessToken,
            idToken: tokens.idToken,
            refreshToken: tokens.refreshToken,
            accessTokenExpiredDate: now.getTime() + tokens.accessTokenExpiresIn * 1000,
            refreshTokenExpiredDate: now.getTime() + tokens.refreshTokenExpiresIn * 1000,
        } as AuthToken;

        const authItem = JSON.stringify(authTokenItem);
        localStorage.setItem(localStorageItems.TOKENS, authItem);
    };

    public getTokens = (): AuthToken | undefined => {
        const tokens = localStorage.getItem(localStorageItems.TOKENS);

        if (!tokens) {
            return;
        }

        return JSON.parse(tokens) as AuthToken;
    };

    public clearAuthStorage = () => {
        localStorage.removeItem(localStorageItems.TOKENS);
        localStorage.removeItem(localStorageItems.CHECK_TOKEN_DATE);
    };
}

export default new AuthStorageService();
