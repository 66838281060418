import {StatisticsDto, UserOffersDto, UsersDto} from "features/adminPanel/dtos";
import {Statistics, User, UserOffer} from "features/adminPanel/model";
import {GetUsersFetchParams} from "features/adminPanel/types";
import {OfferStatus} from "features/offers/types";

export const mapGetUsersFetchParamsToQueryParameters = (params: GetUsersFetchParams) => {
    let queryParams = `?size=${params.pageSize}&page=${params.offset}`;

    if (params.sortBy !== undefined) {
        queryParams += `&sort_by=${params.sortBy}`;

        params.sortDirection !== undefined && (queryParams += `&sort_order=${params.sortDirection}`);
    }

    if (params.globalParam) {
        queryParams += `&globalParam=${params.globalParam}`;
    }

    return queryParams;
};

export const mapStatisticsDtoToStatistics = (dto: StatisticsDto): Statistics => dto;

export const mapUsersDtoToUsersList = (dto: UsersDto): User[] =>
    dto.data.content.map((item) => ({
        firstName: item.firstname,
        lastName: item.lastname,
        email: item.email,
        id: item.id,
        isBlocked: item.status === "BLOCKED",
    }));

export const mapUserOffersDtoToUserOfferList = (dto: UserOffersDto): UserOffer[] =>
    dto.data.content.map((item) => ({
        id: item.id,
        title: item.title,
        publishDate: new Date(item.addedDate),
        messageNumber: item.conversationsWithUnacknowledgedMessages,
        applicationsNumber: item.applicationsWithUnacknowledgedChanges,
        financialConditionsSlimDTO: item.financialConditionsSlimDTO,
        location: item.locationSlimDto,
        mainImage: item.mainImage,
        position: item.position,
        jobStartDate: item.jobStartDate,
        expirationDate: item.expirationDate,
        workType: item.workType,
        status:
            item.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : item.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
    }));
