import { MyOffersCategory } from "features/offers/types";
import { IntlShape } from "react-intl";

export const getSingleEmployeeOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "employee-offers__single-employee-offer",
    });

export const getGroupOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "employee-offers__group-offer",
    });

export const getTransportationMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__transportation-to-workplace",
    });

export const getMaximumAmountMessage = (intl: IntlShape) => intl.formatMessage({ id: "filters-view__maximum-amount" });

export const getWillingnessToWorkDuringHolidaysMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__willingness-to-work-during-holidays",
    });

export const getDetailsStepEmployeesNumberLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__number-of-employees-label",
    });

export const getDetailsStepPositionLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__position-label",
    });

export const getAdditionalDescriptionLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__additional-description-label",
    });

export const getWorkDuringHolidaysMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__work-during-holidays-label",
    });

export const getOffersKindLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__offers-kind-label",
    });

export const getMinimumAmountMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__minimum-amount",
    });

export const getCreateMyEmployeeOffersEmptyHeader = (intl: IntlShape) => {
    const noOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-offers-header",
    });
    const noActiveOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-active-offers-header",
    });
    const noInactiveOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-inactive-offers-header",
    });
    const noEndedOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-ended-offers-header",
    });

    return (category: MyOffersCategory) => {
        switch (category) {
            case MyOffersCategory.All:
                return noOffersMessage;
            case MyOffersCategory.Active:
                return noActiveOffersMessage;
            case MyOffersCategory.Inactive:
                return noInactiveOffersMessage;
            case MyOffersCategory.Ended:
                return noEndedOffersMessage;
        }
    };
};

export const getCreateMyEmployeeOffersEmptyMessage = (intl: IntlShape) => {
    const noOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-offers-message",
    });
    const noActiveOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-active-offers-message",
    });
    const noInactiveOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-inactive-offers-message",
    });
    const noEndedOffersMessage = intl.formatMessage({
        id: "my-employee-offers__no-ended-offers-message",
    });

    return (category: MyOffersCategory) => {
        switch (category) {
            case MyOffersCategory.All:
                return noOffersMessage;
            case MyOffersCategory.Active:
                return noActiveOffersMessage;
            case MyOffersCategory.Inactive:
                return noInactiveOffersMessage;
            case MyOffersCategory.Ended:
                return noEndedOffersMessage;
        }
    };
};

export const getLoginCustomMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "employee-offer-details__login-custom-message",
    });
