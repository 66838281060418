import { selectCountries, selectCountriesError, selectCountriesLoading } from "features/common/selectors";
import { getCountriesAsync } from "features/common/thunks";
import NewCardComponent from "features/myProfile/components/NewCard/component";
import {
    selectCreateNewCardError,
    selectCreateNewCardLoading,
    selectCreateNewCardSuccess,
} from "features/myProfile/selectors";
import { createNewCardAsync } from "features/myProfile/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    createNewCardLoading: selectCreateNewCardLoading(state),
    createNewCardSuccess: selectCreateNewCardSuccess(state),
    createNewCardError: selectCreateNewCardError(state),
    countries: selectCountries(state),
    countriesLoading: selectCountriesLoading(state),
    countriesError: selectCountriesError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            createNewCardAsync,
            getCountriesAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NewCardComponent);
