import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import { countOffersFiltersApplied } from "features/offers/utils";

export const countEmployeeOffersFiltersApplied = (fetchParams: EmployeeOffersFetchParams) => {
    let filtersCount = countOffersFiltersApplied(fetchParams);

    filtersCount += fetchParams.filters.byCapacityType.length;
    filtersCount += fetchParams.filters.byPreferredAmenities.length;

    if (fetchParams.filters.minimalBonusCurrencyCode && fetchParams.filters.minimalBonusValue) {
        filtersCount += 1;
    }

    if (fetchParams.filters.minimalSalaryCurrencyCode && fetchParams.filters.minimalSalaryValue) {
        filtersCount += 1;
    }

    if (fetchParams.filters.byWorkDuringHolidays) {
        filtersCount += 1;
    }

    return filtersCount;
};
