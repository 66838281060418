import Checkbox from "features/common/components/Checkbox";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import FormDropdown from "features/common/components/FormDropdown";
import { DropdownOption, EnumDictionary } from "features/common/types";
import {
    getRequirementStepLanguageLabel,
    getRequirementStepLanguagesLevel,
} from "features/jobOffers/translationMessages";
import { Language, LanguageLevelType, LanguageType, RequirementLanguageTouched } from "features/jobOffers/types";
import React from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    language: Language;
    languagesLabels: { [languageId: string]: string };
    languageLevels: EnumDictionary<LanguageLevelType, string>;
    languagesTouched: {
        [id: string]: RequirementLanguageTouched;
    };
    values: Language[];
    index: number;
    error: any;
    ["data-testid"]?: string;
    onCheckboxToggle: (checked: boolean, id: LanguageType) => void;
    onLevelSelect: (level: LanguageLevelType, id: LanguageType) => void;
    onLevelToggle: (id: LanguageType, toggled: boolean) => void;
}

const LanguageOptionBlock = ({
    language,
    languagesLabels,
    languageLevels,
    languagesTouched,
    values,
    index,
    error,
    "data-testid": testId,
    onCheckboxToggle,
    onLevelSelect,
    onLevelToggle,
}: Props) => {
    const intl = useIntl();

    const levelLabel = getRequirementStepLanguagesLevel(intl);
    const languageLabel = getRequirementStepLanguageLabel(intl);

    const languageLevelOptions: DropdownOption<LanguageLevelType>[] = Object.keys(languageLevels).map((key) => {
        return {
            display: languageLevels[key as LanguageLevelType],
            value: key as LanguageLevelType,
        };
    });

    const errors = error ? (error[index] ? (error[index] as Object as Language) : null) : null;

    return (
        <div data-testid={testId} key={language.id} className={styles["language-option-block"]}>
            <Checkbox
                data-testid={testId ? `${testId}__checkbox` : undefined}
                className={styles["language-option-block__checkbox-label"]}
                key={"checkbox_" + language.id}
                label={languagesLabels[language.id]}
                checked={language.isChecked}
                onToggle={(checked) => onCheckboxToggle(checked, language.id)}
            />

            {language.isChecked && (
                <div key={"isChecked_" + language.id} className={styles["language-option-block__level"]}>

                    <FormDropdown
                        data-testid={testId ? `${testId}__language-dropdown` : undefined}
                        className={styles["language-option-block__level-dropdown"]}
                        label={levelLabel}
                        onSelect={(e) => onLevelSelect(e, language.id)}
                        id={"dropdown_" + language.id}
                        options={languageLevelOptions}
                        selected={languageLevelOptions.find((llo) => llo.value === language.level)}
                        key={"dropdown_" + language.id}
                        withValidation
                        isInvalid={
                            languagesTouched[language.id].isLevelTouched && (errors?.level === undefined ? false : true)
                        }
                        onToggle={(toggled) => onLevelToggle(language.id, toggled)}
                        placeholder={levelLabel}
                        helperMessages={[<FieldRequiredMessage />]}
                        error={errors?.level?.toString()}
                    />
                </div>
            )}
        </div>
    );
};

export default LanguageOptionBlock;
