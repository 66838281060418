import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/styles.scss";
import App from "app";
import history from "features/routing/history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router } from "react-router-dom";
import * as serviceWorker from "serviceWorker";
import createStore from "store/createStore";
import config from "./config";

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Provider store={createStore()}>
                {/* <GoogleReCaptchaProvider reCaptchaKey="6LfCEZgeAAAAAByd5GyyD18bAyiCugC3cnCbID2I"> */}
                <App />
                {/* </GoogleReCaptchaProvider> */}
            </Provider>
            <Route path='/getbonus' component={() => {
                window.location.href = config.GETBONUS_URL;
                return null;
            }} />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.register({
    // This code causes the service worker to update as soon as a new version is available and downloaded.
    // This feature is helpful during development, but is not suitable for production.
    // TODO: Implement notifications that allow users to manually decide whether or not to update the app.
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event) => {
                if ((event.target as any)?.state === "activated") {
                    window.location.reload();
                }
            });
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
    },
});
