import EmployeeOffersComponent from "features/employeeOffers/components/EmployeeOffers";
import React from "react";
import styles from "./styles.module.scss";

const EmployeeOffers = () => {
    return (
        <div className={styles["employee-offers"]}>
            <EmployeeOffersComponent />
        </div>
    );
};

export default EmployeeOffers;
