import { ApiError } from "api/utils";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import Input from "features/common/components/Input";
import Spinner from "features/common/components/Spinner";
import { getLocalizedYup } from "features/common/validators";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import {
    getAppleLoginButtonMessage,
    getEmailLabelMessage,
    getFacebookLoginButtonMessage,
    getFunctionalityTemporarilyUnavailable,
    getGoogleLoginButtonMessage,
    getPasswordLabelMessage,
    getRegisterButtonMessage,
    getRegisterHaveAccountMessage,
    getRegisterOrSocialMessage,
    getRegisterPrivacyPolicyButtonMessage,
    getRegisterRulesButtonMessage,
    getRegisterRulesMessage,
    getRegisterSignInMessage,
    getRegisterTitleMessage,
} from "features/user/translationMessages";
import { LoginUserParams } from "features/user/types";
import { registerFormValidationSchema } from "features/user/validators";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { FacebookLoginButton } from "../Login/FacebookLoginButton";
import { GoogleLoginButton } from "../Login/GoogleLoginButton";
import { AppleLoginButton } from "../Login/AppleLoginButton";
import styles from "./styles.module.scss";


export type Props = {
    user?: User;
    registerUserLoading?: boolean;
    registerUserLoadingError?: ApiError;
    registerUserAsync: (params: LoginUserParams) => void;
    isUserLoggedIn?: boolean;
};

const Register = ({
    user,
    registerUserLoading = false,
    registerUserLoadingError,
    registerUserAsync,
    isUserLoggedIn,
}: Props) => {
    const history = useHistory();
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const [registerSubmitted, setRegisterSubmitted] = useState(false);
    const intl = useIntl();
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return registerFormValidationSchema(localYup, intl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema,
        onSubmit: (values: LoginUserParams) => {
            registerUserAsync(values);
            setRegisterSubmitted(true);
        },
    });

    useEffect(() => {
        if (user?.email && registerSubmitted) {
            history.push(`${appRoutes.activationInfo}?email=${user.email}`);
        }
    }, [history, user]);

    const toggleShowHidePassword = useCallback(() => setIsPasswordHidden((current) => !current), [setIsPasswordHidden]);

    const haveAccountMessage = getRegisterHaveAccountMessage(intl);
    const signInMessage = getRegisterSignInMessage(intl);
    const titleMessage = getRegisterTitleMessage(intl);
    const emailLabelMessage = getEmailLabelMessage(intl);
    const passwordLabelMessage = getPasswordLabelMessage(intl);
    const registerButtonMessage = getRegisterButtonMessage(intl);
    const orSocialMessage = getRegisterOrSocialMessage(intl);
    const googleLoginMessage = getGoogleLoginButtonMessage(intl);
    const facebookLoginMessage = getFacebookLoginButtonMessage(intl);
    const appleLoginMessage = getAppleLoginButtonMessage(intl);
    const rulesButtonMessage = getRegisterRulesButtonMessage(intl);
    const privacyPolicyButtonMessage = getRegisterPrivacyPolicyButtonMessage(intl);
    // const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmitButton = useCallback(async () => {
        // const newToken = executeRecaptcha && await executeRecaptcha("MS_Pyme_DatosEmpresa");
        // if (newToken) {
        formik.handleSubmit();
        // } else {
        // setShowCaptchaError(true)
        // }
    }, [formik]);
    const handleLoginButton = useCallback(() => history.push(appRoutes.login), [history]);
    const handleRulesButton = useCallback(() => history.push(appRoutes.regulations), [history]);
    const handlePrivacyPolicyButton = useCallback(() => history.push(appRoutes.privacyPolicy), [history]);

    const rulesButton = useMemo(
        () => (
            <Button
                data-testid="register-form__register-rules-button"
                as="a"
                variant="no-background"
                className={styles["register-form__register-rules-button"]}
                onClick={handleRulesButton}
                disabled={registerUserLoading}
            >
                {rulesButtonMessage}
            </Button>
        ),
        [handleRulesButton, registerUserLoading, rulesButtonMessage]
    );

    const privacyPolicyButton = useMemo(
        () => (
            <Button
                data-testid="register-form__register-rules-button"
                as="a"
                variant="no-background"
                className={styles["register-form__register-rules-button"]}
                onClick={handlePrivacyPolicyButton}
                disabled={registerUserLoading}
            >
                {privacyPolicyButtonMessage}
            </Button>
        ),
        [handlePrivacyPolicyButton, registerUserLoading, privacyPolicyButtonMessage]
    );

    const rulesMessage = getRegisterRulesMessage(intl, rulesButton, privacyPolicyButton);

    return (
        <div className={styles["register-form"]}>
            <div className={styles["register-form__sign-in"]}>
                <span>{haveAccountMessage}</span>
                <Button
                    data-testid="register-form__sign-in-button"
                    as="a"
                    className={styles["register-form__sign-in-button"]}
                    variant="no-background"
                    onClick={handleLoginButton}
                >
                    {signInMessage}
                </Button>
            </div>
            <div className={styles["register-form__title"]}>{titleMessage}</div>
            <form onSubmit={handleSubmitButton}>
                <Input
                    wrapperClassName={styles["register-form__email-input"]}
                    data-testid="register-form__email-input"
                    label={emailLabelMessage}
                    placeholder={emailLabelMessage}
                    name="email"
                    onKeyDown={(event) => {
                        formik.handleBlur(event);

                        if (event.key === "Enter") {
                            handleSubmitButton();
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="text"
                    isInvalid={!!formik.errors.email}
                    error={!!formik.touched.email ? formik.errors.email : undefined}
                    touched={!!formik.touched.email}
                    withValidation
                    disabled={registerUserLoading}
                    displayCorrectValidation
                />
                <Input
                    wrapperClassName={styles["register-form__password-input"]}
                    data-testid="register-form__password-input"
                    label={passwordLabelMessage}
                    placeholder={passwordLabelMessage}
                    name="password"
                    onKeyDown={(event) => {
                        formik.handleBlur(event);

                        if (event.key === "Enter") {
                            handleSubmitButton();
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    type={isPasswordHidden ? "password" : "text"}
                    isInvalid={!!formik.errors.password}
                    error={!!formik.touched.password ? formik.errors.password : undefined}
                    touched={!!formik.touched.password}
                    withValidation
                    isHidden={isPasswordHidden}
                    toggleHideShow={toggleShowHidePassword}
                    withShowHideButton
                    disabled={registerUserLoading}
                    displayCorrectValidation
                />
                <Button
                    data-testid="register-form__register-button"
                    className={styles["register-form__register-button"]}
                    variant="login"
                    onClick={handleSubmitButton}
                    disabled={registerUserLoading}
                    type="button"
                >
                    {registerUserLoading ? (
                        <Spinner className={styles["register-form__spinner"]} thickness="thin" />
                    ) : (
                        registerButtonMessage
                    )}
                </Button>
                {!!registerUserLoadingError && (
                    <FieldMessages
                        className={styles["register-form__error-messages"]}
                        data-testid="register-form__error-messages"
                        messages={[
                            intl.formatMessage({
                                id: registerUserLoadingError.response?.status
                                    ? `login-form__error-${registerUserLoadingError.response?.status}`
                                    : "login-form__error",
                            }),
                        ]}
                        mode="error"
                    />
                )}
                <div className={styles["register-form__rules-text"]}>
                    <span>{rulesMessage}</span>
                </div>
                <div className={styles["register-form__or-social"]}>{orSocialMessage}</div>
                {GoogleLoginButton(registerUserLoading, googleLoginMessage)}
                {FacebookLoginButton(registerUserLoading, facebookLoginMessage)}
                {AppleLoginButton(registerUserLoading, appleLoginMessage)}
            </form>
        </div>
    );
};

export default Register;
