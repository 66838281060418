import Box from "components/Box";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components/macro";

type Props = {
  web?: string;
  tablet?: string;
  mobile?: string;
  onClickBonus?: React.MouseEventHandler<HTMLElement>;
  onClickInvest?: React.MouseEventHandler<HTMLElement>;
};



const Advertisement = ({ web, tablet, mobile, onClickBonus, onClickInvest }: Props) => {
  const intl = useIntl();
  return (
    <Box>
      <Title>{intl.formatMessage({ id: "dashboard__advertisement" })}</Title>
      <Web>
        <GetBonusButton onClick={onClickBonus} />
        <GetInvestButton onClick={onClickInvest} />
        <Image src={web}></Image>
      </Web>
      <Tablet>
        <GetBonusButton onClick={onClickBonus} />
        <GetInvestButton onClick={onClickInvest} />
        <Image src={tablet} />
      </Tablet>
      <Mobile>
        <GetBonusButton onClick={onClickBonus} />
        <GetInvestButton onClick={onClickInvest} />
        <Image src={mobile} />
      </Mobile>
    </Box >
  )
}

export default Advertisement

const GetBonusButton = styled.div`
    background-color: black;
    height: 30%;
    width: 25%;
    z-index: 4;
    position: relative;
    &:hover{
        cursor: pointer;
    }
    opacity: 0;
    @media (max-width: 1024px) {
      height: 20%;
      width: 28%;
    }
    @media (max-width: 500px) {
      height: 8%;
      width: 75%;
    }
`

const GetInvestButton = styled.div`
    background-color: black;
    height: 12.5%;
    width: 17%;
    z-index: 4;
    position: relative;
    &:hover{
        cursor: pointer;
    }
    transform: rotate(-1.2deg);
    opacity: 0;
    @media (max-width: 1024px) {
      height: 8%;
      width: 19.5%;
    }
    @media (max-width: 500px) {
      height: 4%;
      width: 56.5%;
    }
`

const Title = styled.div`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #484848;
    text-transform: uppercase;
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 5px; 
`;
const Web = styled.div` 
  div:nth-child(1) {
    position: absolute;
    top: 18%;
    left: 37.5%
  }
  
  div:nth-child(2) {
    position: absolute;
    top: 53.8%;
    left: 74.7%
  }

  div:nth-child(3) {
    position: absolute;
  }
    position: relative;
    @media (max-width: 1024px) {
    display:none;
    }
`;
const Tablet = styled.div`
position: relative;
    display: none;
    @media (max-width: 1024px) {
    display: flex;
    div:nth-child(1) {
        position: absolute;
        top: 30%;
        left: 36%
      }
      
      div:nth-child(2) {
        position: absolute;
        top: 52.3%;
        left: 75%
      }
    
      div:nth-child(3) {
        position: absolute;
      }
    }
    @media (max-width: 500px) {
        display: none;
    }
`
  ;
const Mobile = styled.div`
    display: none;
    position: relative;
    @media (max-width: 500px) {
    display: flex;
    div:nth-child(1) {
        position: absolute;
        top: 12.2%;
        left: 12.5%
      }
      
      div:nth-child(2) {
        position: absolute;
        top: 41.8%;
        left: 21.7%
      }
    
      div:nth-child(3) {
        position: absolute;
      }
    }
`;