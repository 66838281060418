const mapUtcStringToLocalDate = (dateString?: string) => {
    if (!dateString) {
        return;
    }

    const result = new Date(dateString);
    return !isNaN(result.getDate()) ? result : undefined;
};

export default mapUtcStringToLocalDate;
