import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    count?: number;
    className?: string;
    ["data-testid"]?: string;
}

const RedDotCounter = ({ count, className, "data-testid": testId }: Props) =>
    count ? (
        <div data-testid={testId} className={classNames(styles["red-dot-counter"], className)}>
            {count}
        </div>
    ) : null;

export default RedDotCounter;
