import { ApiError, CancelToken } from "api/utils";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import FormDropdown from "features/common/components/FormDropdown";
import Input from "features/common/components/Input";
import TagInput from "features/common/components/TagInput";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import mapCardTypeToCardOwnerType from "features/common/mappers/mapCardTypeToCardOwnerType";
import {
    getBranchMessage,
    getCompanyCertificateMessage,
    getCompanyNameMessage,
    getFirstNameMessage,
    getLastNameMessage,
    getLocationMessage,
    getNipCodeMessage,
    getPhoneNumberMessage,
} from "features/common/translationMessages";
import { Card, DictionaryItem } from "features/common/types";
import { getSpecificLocation } from "features/common/utils";
import PhonePrefixSelector from "features/myProfile/components/PhonePrefixSelector";
import { CardOwnerType } from "features/myProfile/types";
import { useField } from "formik";
import flatten from "lodash/flatten";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { locationFormatter } from "utils";
import styles from "./styles.module.scss";

const mapDictionaryItemsToDropdownOptions = (items: DictionaryItem[], intl: IntlShape) =>
    items.map((item) => ({
        value: `${item.id}`,
        display: intl.formatMessage({ id: item.name }),
    }));

interface Props {
    cardDetails?: Card;
    branches?: DictionaryItem[];
    branchesLoading?: boolean;
    branchesLoadingError?: ApiError;
    placePredictions: any;
    getPlacePredictions: any;
    placesService: any;
    ["data-testid"]?: string;
    getBranchesAsync?: (cancelToken?: CancelToken) => void;
}

const DetailsForm = ({
    cardDetails,
    branches,
    branchesLoading,
    branchesLoadingError,
    "data-testid": testId = "details-form",
    getBranchesAsync,
    placePredictions,
    getPlacePredictions,
    placesService
}: Props) => {
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [location, setLocation] = useState("");
    const [selectedTag, setSelectedTag] = useState<any>();
    const [companyNameField, companyNameMeta] = useField<string | undefined>("companyName");
    const [firstNameField, firstNameMeta] = useField<string | undefined>("firstName");
    const [lastNameField, lastNameMeta] = useField<string | undefined>("lastName");
    const [branchField, branchFieldMeta, branchFieldHandlers] = useField<string | undefined>("branch");
    const [nipField, nipFieldMeta] = useField<string | undefined>("nip");
    const [krazCertField, krazCertFieldMeta] = useField<string | undefined>("krazCert");
    const [phoneNumberField, phoneNumberFieldMeta] = useField<string | undefined>("phoneNumber");
    const [emailField, emailFieldMeta] = useField<string | undefined>("email");
    const [locationField, locationFieldMeta, locationFieldHandlers] = useField<any>("location");
    const [, , phonePrefixFieldHandlers] = useField("phonePrefix");

    const intl = useIntl();
    const locationIntlMessage = getLocationMessage(intl);
    const createCancelToken = useCreateCancelToken();

    const companyNameMessage = getCompanyNameMessage(intl);
    const firstNameMessage = getFirstNameMessage(intl);
    const lastNameMessage = getLastNameMessage(intl);
    const branchMessage = getBranchMessage(intl);
    const companyCertificateMessage = getCompanyCertificateMessage(intl);
    const nipCodeMessage = getNipCodeMessage(intl);
    const phoneNumberMessage = getPhoneNumberMessage(intl);
    const branchesFlattened = flatten(branches?.map(branchItem => branchItem?.branches as any)) || [];
    const branchesOptions = mapDictionaryItemsToDropdownOptions(branchesFlattened, intl);

    const cardType: CardOwnerType | undefined = cardDetails && mapCardTypeToCardOwnerType(cardDetails.type);

    useEffect(() => {
        if (!branches && !branchesLoading && !branchesLoadingError) {
            getBranchesAsync && getBranchesAsync(createCancelToken());
        }
    }, [branches, branchesLoading, branchesLoadingError, createCancelToken, getBranchesAsync]);

    const handleBranchChange = useCallback(
        (value) => {
            branchFieldHandlers.setValue(value);
        },
        [branchFieldHandlers]
    );

    const selectedBranch = useMemo(
        // eslint-disable-next-line eqeqeq
        () => branchesOptions?.find((item) => branchField.value == item.value),
        [branchField.value, branchesOptions]
    );

    useEffect(() => {
        console.warn({ locationField });
        if (locationField.value.placeId) {
            placesService?.getDetails({ placeId: locationField.value.placeId }, (placeDetails: any) => {
                const detailedAdress = {
                    placeId: placeDetails?.place_id,
                    input: placeDetails?.formatted_address || "",
                }
                locationFieldHandlers.setValue(detailedAdress);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placesService]);

    useEffect(() => {
        getPlacePredictions({ input: location, types: ['(regions)']  });
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [location]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [location, placePredictions]);


    useEffect(() => {
        if (!selectedTag) return;
        const detailedAdress = {
            placeId: selectedTag?.id,
            input: selectedTag?.name || "",
        }
        locationFieldHandlers.setValue(detailedAdress)
    }, [selectedTag])

    const locationToShow = locationFormatter(locationField.value);

    const locationError = locationFieldMeta.error as { country?: string, latitude?: string, longitude?: string };

    return (
        <div data-testid={testId} className={styles["details-form__card-details-edit"]}>
            <div className={styles["details-form__data-container-edit"]}>
                {cardType === "company" ? (
                    <div className={styles["details-form__company-data-edit"]}>
                        <h3>
                            <FormattedMessage id="card-details-section__company-data" />
                        </h3>
                        <div>
                            <Input
                                data-testid={`${testId}__company-name`}
                                label={`${companyNameMessage}*`}
                                placeholder={`${companyNameMessage}*`}
                                helperMessages={[<FieldRequiredMessage />]}
                                {...{ ...companyNameField, ...companyNameMeta }}
                                withValidation
                                isInvalid={!!companyNameMeta.error && !!companyNameMeta.touched}
                                spacelessMessages
                            />
                        </div>
                        <div>
                            <FormDropdown
                                data-testid={`${testId}__branch`}
                                id="card-details-section-form__branch-dropdown"
                                label={`${branchMessage}`}
                                placeholder={`${branchMessage}`}
                                onSelect={handleBranchChange}
                                options={branchesOptions ?? []}
                                selected={selectedBranch}
                                error={branchFieldMeta.error}
                                withValidation
                                isInvalid={!!branchFieldMeta.touched && !!branchFieldMeta.error}
                            />
                        </div>
                        <div>
                            <Input
                                data-testid={`${testId}__nip`}
                                label={`${nipCodeMessage}`}
                                placeholder={`${nipCodeMessage}`}
                                {...{ ...nipField, ...nipFieldMeta }}
                                withValidation
                                isInvalid={!!nipFieldMeta.error && !!nipFieldMeta.touched}
                                maxLength={20}
                            />
                        </div>
                        <div>
                            <Input
                                data-testid={`${testId}__kraz-cert`}
                                label={`${companyCertificateMessage}`}
                                placeholder={`${companyCertificateMessage}`}
                                {...{ ...krazCertField, ...krazCertFieldMeta }}
                                withValidation
                                isInvalid={!!krazCertFieldMeta.error && !!krazCertFieldMeta.touched}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles["details-form__personal-data"]}>
                        <h3>
                            <FormattedMessage id="card-details-section__personal-data" />
                        </h3>
                        <div>
                            <Input
                                data-testid={`${testId}__first-name`}
                                label={`${firstNameMessage}*`}
                                placeholder={`${firstNameMessage}*`}
                                helperMessages={[<FieldRequiredMessage />]}
                                {...{ ...firstNameField, ...firstNameMeta }}
                                withValidation
                                isInvalid={!!firstNameMeta.error && !!firstNameMeta.touched}
                                spacelessMessages
                            />
                        </div>
                        <div>
                            <Input
                                data-testid={`${testId}__last-name`}
                                label={`${lastNameMessage}*`}
                                placeholder={`${lastNameMessage}*`}
                                helperMessages={[<FieldRequiredMessage />]}
                                {...{ ...lastNameField, ...lastNameMeta }}
                                withValidation
                                isInvalid={!!lastNameMeta.error && !!lastNameMeta.touched}
                                spacelessMessages
                            />
                        </div>
                    </div>
                )}
                <div className={styles["details-form__contact-data-edit"]}>
                    <h3>
                        <FormattedMessage id="card-details-section__contact-data" />
                    </h3>
                    <div className={styles["details-form__address"]}>
                        <div style={{ position: "relative", margin: 0 }}>
                            <TagInput
                                value={{ input: typeof locationField.value.input === 'string' ? locationField.value.input : locationToShow, tags: [] }}
                                onChange={(tags, input) => {
                                    locationFieldHandlers.setTouched(true);
                                    setLocation(input)
                                    if (tags.length) {
                                        const [newValue] = tags;
                                        setSelectedTag(newValue);
                                        return locationFieldHandlers.setValue({ input: newValue.name, tags });
                                    }
                                    if (input !== locationField.value.input) {
                                        return locationFieldHandlers.setValue({ tags, input })
                                    }
                                }}
                                sections={locationOptions}
                                label={`${locationIntlMessage}*`}
                                placeholder={`${locationIntlMessage}*`}
                                error={locationFieldMeta.touched && (locationError?.country || locationError?.latitude || locationError?.longitude)}
                            />
                        </div>
                    </div>
                    <div>
                        <PhonePrefixSelector onChange={(newValue) => phonePrefixFieldHandlers.setValue(newValue)} value={cardDetails?.phonePrefix || ''}>
                            <Input
                                data-testid={`${testId}__phone-number`}
                                label={`${phoneNumberMessage}`}
                                placeholder={`${phoneNumberMessage}`}
                                {...{ ...phoneNumberField, ...phoneNumberFieldMeta }}
                                withValidation
                                isInvalid={!!phoneNumberFieldMeta.error && !!phoneNumberFieldMeta.touched}
                                maxLength={20}
                            />
                        </PhonePrefixSelector>
                    </div>
                    {cardType === "company" && (
                        <div>
                            <Input
                                data-testid={`${testId}__email`}
                                label={`${intl.formatMessage({ id: "common__email" })}`}
                                placeholder={`${intl.formatMessage({ id: "common__email" })}`}
                                {...{ ...emailField, ...emailFieldMeta }}
                                withValidation
                                isInvalid={!!emailFieldMeta.error && !!emailFieldMeta.touched}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetailsForm;
