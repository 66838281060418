import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import Input from "features/common/components/Input";
import Spinner from "features/common/components/Spinner";
import Tooltip from "features/common/components/Tooltip";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getLocalizedYup } from "features/common/validators";
import MyProfileApi from "features/myProfile/api";
import { PaymentCardDto } from "features/myProfile/dtos";
import { getTooltipErrorMessage } from "features/myProfile/translationMessages";
import { createPaymentDataValidationSchema } from "features/myProfile/validators";
import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    userId?: string;
    paymentCardData?: PaymentCardDto;
    paymentCardDataLoading: boolean;
    paymentCardDataError?: ApiError;
    getPaymentCardDataAsync: (cancelToken?: CancelToken) => void;
}

const PaymentDataSection = ({ userId, paymentCardData, paymentCardDataLoading, paymentCardDataError, getPaymentCardDataAsync }: Props) => {
    const intl = useIntl();
    const tooltipMessage = getTooltipErrorMessage(intl);
    const createCancelToken = useCreateCancelToken();
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return createPaymentDataValidationSchema(localYup);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cardError, setCardError] = useState<any>();
    const [isOpen, setIsOpen] = useState(false);
    const tooltip = useMemo(
        () => (
            <Tooltip
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                hiddenIcon
                text={tooltipMessage}
                arrowPosition='bottom-right'
            />
        ),
        [isOpen, tooltipMessage]
    );

    const downloadCardData = useCallback(() => getPaymentCardDataAsync(createCancelToken()), [createCancelToken, getPaymentCardDataAsync]);

    const startEditing = () => setIsEditing(true);
    const finishEditing = () => setIsEditing(false);

    const onSubmit = useCallback(async (values) => {
        setIsLoading(true);
        try {
            if (!!paymentCardData) {
                await MyProfileApi.deletePaymentCardAsync(createCancelToken());
            }
            await MyProfileApi.createNewPaymentCardAsync(values, createCancelToken());
            setCardError(false)
            downloadCardData();
            setIsLoading(false);
            finishEditing();
        } catch (error) {
            setIsLoading(false);
            console.warn({ error });
            setCardError(error?.response?.data || true);
        }
    }, [createCancelToken, downloadCardData, paymentCardData]);

    useEffect(() => {
        downloadCardData();
    }, [createCancelToken, downloadCardData]);

    const initialPaymentData = {
        cardNumber: "",
        expiryDate: "",
    };

    return (
        <Formik
            initialValues={initialPaymentData}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
        >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                <div className={styles["payment-data-section"]}>
                    <div className={styles["payment-data-section__header"]}>
                        <span>
                            <FormattedMessage id="my-data__card-title" />
                        </span>
                        {!isEditing && (
                            <div className={styles["payment-data-section__buttons-container"]}>
                                <Button
                                    className={styles["payment-data-section__button"]}
                                    onClick={startEditing}
                                    variant="no-background"
                                >
                                    <PenIcon />
                                    <FormattedMessage id="my-data__edit-card-data" />
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className={styles["payment-data-section__content"]}>
                        {
                            paymentCardDataLoading ? (
                                <Spinner className={styles["payment-data-section__data-loader"]} thickness="thin" />
                            ) : (
                                <>
                                    <div className={styles["payment-data-section__column"]}>
                                        <div className={styles["payment-data-section__row"]}>
                                            <div
                                                className={classNames(styles["payment-data-section__label"], {
                                                    [styles["payment-data-section__editing-label"]]: !!isEditing,
                                                })}
                                            >
                                                <FormattedMessage id="my-data__card-number-label" />
                                            </div>
                                            {isEditing ? (
                                                <>
                                                    <Input
                                                        data-testid="payment-data-section__card-number"
                                                        wrapperClassName={styles["payment-data-section__input"]}
                                                        name="cardNumber"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cardNumber}
                                                        isInvalid={!!touched.cardNumber && !!errors.cardNumber}
                                                        error={!!touched.cardNumber ? errors.cardNumber : undefined}
                                                        withValidation
                                                        disabled={isLoading}
                                                        onErrorClick={() => setIsOpen(prevState => !prevState)}
                                                        isCardNumber
                                                    />
                                                    {tooltip}
                                                </>
                                            ) : (
                                                <div className={styles["payment-data-section__value"]}>
                                                    {paymentCardData?.cardNumber || "-"}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles["payment-data-section__column"]}>
                                        <div className={styles["payment-data-section__row"]}>
                                            <div
                                                className={classNames(styles["payment-data-section__label"], {
                                                    [styles["payment-data-section__editing-label"]]: !!isEditing,
                                                })}
                                            >
                                                <FormattedMessage id="my-data__expiry-date-label" />
                                            </div>
                                            {isEditing ? (
                                                <Input
                                                    data-testid="payment-data-section__expiry-date"
                                                    wrapperClassName={styles["payment-data-section__input"]}
                                                    name="expiryDate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.expiryDate}
                                                    isInvalid={!!touched.expiryDate && !!errors.expiryDate}
                                                    error={!!touched.expiryDate ? errors.expiryDate : undefined}
                                                    withValidation
                                                    inputMask="99/99"
                                                    disabled={isLoading}
                                                />
                                            ) : (
                                                <div className={styles["payment-data-section__value"]}>
                                                    {paymentCardData?.cardNumber ? "**/**" : "-"}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    {!!isEditing && (
                        <div className={styles["payment-data-section__submit-buttons-container"]}>
                            <Button
                                className={styles["payment-data-section__cancel-button"]}
                                onClick={() => {
                                    resetForm();
                                    finishEditing();
                                }}
                                variant="tertiary"
                                disabled={isLoading}
                            >
                                <FormattedMessage id="common__cancel" />
                            </Button>
                            <Button
                                className={styles["payment-data-section__submit-button"]}
                                onClick={() => handleSubmit()}
                                disabled={isLoading}
                                isLoading={isLoading}
                            >
                                <FormattedMessage id="common__save" />
                            </Button>
                        </div>
                    )}
                    {!!cardError ? (
                        <div className={styles["payment-data-section__submit-error-messages"]}>
                            <FieldMessages messages={[intl.formatMessage({ id: "payment__card-save-error" }),
                            cardError && intl.formatMessage({ id: "payment__card-save-error-requirements" })]} mode="error" />
                        </div>
                    ) :
                        null}
                </div>
            )}
        </Formik>
    );
};

export default PaymentDataSection;
