import { ApiError } from "api/utils";
import Spinner from "features/common/components/Spinner";
import {
    selectVerifyUserLoading,
    selectVerifyUserLoadingError,
    selectVerifyUserLoadingSuccess,
} from "features/user/selectors";
import { verifyUserAsync } from "features/user/thunks";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import styles from "./styles.module.scss";

const mapStateToProps = (state: RootState) => ({
    verifyUserLoading: selectVerifyUserLoading(state),
    verifyUserLoadingSuccess: selectVerifyUserLoadingSuccess(state),
    verifyUserLoadingError: selectVerifyUserLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            verifyUserAsync,
        },
        dispatch
    );

interface Props {
    verifyUserLoading?: boolean;
    verifyUserLoadingSuccess?: boolean;
    verifyUserLoadingError?: ApiError;
    verifyUserAsync: (token: string) => void;
}

interface Params {
    token?: string;
}

const Verify = ({ verifyUserLoadingSuccess, verifyUserLoadingError, verifyUserAsync }: Props) => {
    const history = useHistory();
    const { token } = useParams<Params>();

    useEffect(() => {
        if (token) {
            verifyUserAsync(token);
        }
    }, [token, verifyUserAsync]);

    useEffect(() => {
        if (verifyUserLoadingSuccess) {
            history.push("/login");
        }
    }, [history, verifyUserLoadingSuccess]);


    const renderVerificationMessage = useCallback(
        () => {
            if (!verifyUserLoadingSuccess) {
                console.log('verifyUserLoadingSuccess', verifyUserLoadingSuccess);
                return <div>Konto zostało aktywowane. <br /><Link to="/">Przejdź do strony głównej</Link></div>
            }

            console.log('verifyUserLoadingSuccess', verifyUserLoadingError);
            return 'Wystąpił błąd, spróbuj ponownie.'
        },
        [verifyUserLoadingError, verifyUserLoadingSuccess],
    );

    return (
        <div className={styles["verify-user"]}>
            {verifyUserLoadingError ? (
                <div className={styles["verify-user__error"]}>
                    {renderVerificationMessage()}
                </div>
            ) : (
                <Spinner className={styles["verify-user__spinner"]} />
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
