import styled from "styled-components/macro";
import {
    borderRadius,
    BorderRadiusProps,
    boxShadow,
    BoxShadowProps,
    color,
    ColorProps,
    compose,
    flexbox,
    FlexboxProps,
    grid,
    GridProps,
    layout,
    LayoutProps,
    padding,
    position,
    PositionProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from "styled-system";

export type BoxProps = {
  orderOnMobile?: number
} & BorderRadiusProps &
  BoxShadowProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  TypographyProps

const styleProps = compose(
  borderRadius,
  color,
  flexbox,
  grid,
  layout,
  space,
  boxShadow,
  position,
  typography,
  padding
)

const Box = styled.div<BoxProps>`
  display: grid;
  ${styleProps};
`

export default Box

export const Flexbox = styled(Box) <BoxProps>`
  display: flex;
`
