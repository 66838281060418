import React from 'react'

interface TriggerProps {
    audioTrigger: boolean
}

const Micro = ({ audioTrigger }: TriggerProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5006 4C9.71484 4 9.71484 6.47619 9.71484 7.71429V9.57143C9.71484 10.8095 9.71484 13.2857 12.5006 13.2857C15.2863 13.2857 15.2863 10.8095 15.2863 9.57143V7.71429C15.2863 6.47619 15.2863 4 12.5006 4Z" stroke="#002677" stroke-width="1.85714" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 11.4287C6 11.4287 6 17.0001 12.5 17.01C19 17.0199 19 11.4287 19 11.4287M12.5 17.9287V19.4763V17.9287Z" stroke="#002677" stroke-width="1.85714" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 1L24.5 22.5" stroke="#D32A2A" stroke-width={audioTrigger ? "0" : "2"} stroke-linecap="round" className="redline" />
        </svg>
    )
}

export default Micro