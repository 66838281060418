import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as MyOffersEmptyStateIcon } from "assets/icons/myOffersEmptyState.svg";
import classNames from "classnames";
import { UserOffer } from "features/adminPanel/model";
import { UserOffersCategory } from "features/adminPanel/types";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import ModalWithContent from "features/common/components/ModalWithContent";
import ErrorHeader from "features/common/components/ModalWithContent/ErrorHeader";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import Pagination from "features/common/components/Pagination";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TabNavigation from "features/common/components/TabNavigation";
import TextArea from "features/common/components/TextArea";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { OfferType } from "features/common/types";
import SingleOfferCard from "features/offers/components/SingleOfferCard";
import { OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Params {
    userId: string;
}

export interface Props {
    offersType: OfferType;
    userOffers?: UserOffer[];
    userOffersLoading?: boolean;
    userOffersError?: ApiError;
    totalUserOffers?: number;
    deleteUserOfferSuccess?: boolean;
    deleteUserOfferLoading?: boolean;
    deleteUserOfferError?: ApiError;
    getUserOffersAsync: (userId: string, offersType: OfferType, pageNumber: number, cancelToken?: CancelToken) => void;
    deleteUserOfferAsync: (offerId: number, offerType: OfferType, cancelToken?: CancelToken) => void;
}

const UserOffers = ({
    offersType,
    userOffers,
    userOffersLoading,
    userOffersError,
    totalUserOffers,
    deleteUserOfferSuccess,
    deleteUserOfferLoading,
    deleteUserOfferError,
    getUserOffersAsync,
    deleteUserOfferAsync,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const { userId } = useParams<Params>();
    const createCancelToken = useCreateCancelToken();

    const [activeTab, setActiveTab] = useState(UserOffersCategory.All);
    const [activePage, setActivePage] = useState<number>(0);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteModalDisplayed, setDeleteModalDisplayed] = useState<number | undefined>();
    const [deleteSuccessModalDisplayed, setDeleteSuccessModalDisplayed] = useState(false);
    const [deleteErrorModalDisplayed, setDeleteErrorModalDisplayed] = useState(false);
    const [chosenOffer, setChosenOffer] = useState<number | undefined>()

    const offersToDisplay = useMemo(() => {
        switch (activeTab) {
            case UserOffersCategory.All:
                return userOffers;
            case UserOffersCategory.Active:
                return userOffers?.filter((offer) => offer.status === OfferStatus.Active);
            case UserOffersCategory.Inactive:
                return userOffers?.filter((offer) => offer.status === OfferStatus.Inactive);
            case UserOffersCategory.Ended:
                return userOffers?.filter((offer) => offer.status === OfferStatus.Ended);
        }
    }, [activeTab, userOffers]);

    const translationBase = useMemo(
        () => (offersType === OfferType.Job ? "user-job-offers" : "user-employee-offers"),
        [offersType]
    );

    useEffect(() => {
        getUserOffersAsync(userId, offersType, activePage, createCancelToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, offersType, activePage]);

    const goToOffer = useCallback((offerId) => history.push(`${appRoutes.jobOffers}/${offerId}`), [history]);

    const openDeleteModal = useCallback((offerId: number) => { setChosenOffer(offerId); setDeleteModalDisplayed(offerId) }, []);
    const closeDeleteModal = useCallback(() => setDeleteModalDisplayed(undefined), []);
    const handleDeleteOffer = useCallback((offerId: number) => {
        // Make request
        deleteUserOfferAsync(offerId, offersType, createCancelToken())
    }, []);

    const closeDeleteSuccessModal = useCallback(() => setDeleteSuccessModalDisplayed(false), []);
    const closeDeleteErrorModal = useCallback(() => setDeleteErrorModalDisplayed(false), []);

    const handleDeleteMessageChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => setDeleteMessage(event.target.value),
        []
    );

    const handleDeleteOfferClick = (offerId: number) => {
        openDeleteModal(offerId);
        setChosenOffer(offerId)
    }

    useEffect(() => {
        if (deleteUserOfferSuccess) {
            setDeleteSuccessModalDisplayed(true);
            setTimeout(() => {
                window.location.reload()
            }, 1500)
        }
    }, [deleteUserOfferSuccess]);

    useEffect(() => {
        if (deleteUserOfferError) {
            setDeleteErrorModalDisplayed(true);
        } else {
            setDeleteErrorModalDisplayed(false);
        }
    }, [deleteUserOfferError]);

    if (userOffersLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: `${translationBase}__loading-data` })} />;
    }

    return (
        <div className={styles["user-offers"]}>
            {userOffersError ? (
                <div className={classNames(styles["user-offers__container"], styles["user-offers__error-container"])}>
                    <FormattedMessage id={`${translationBase}__loading-error`} />
                </div>
            ) : (
                <>
                    <div
                        className={classNames(
                            styles["user-offers__container"],
                            styles["user-offers__header-container"]
                        )}
                    >
                        <div className={styles["user-offers__title"]}>
                            <FormattedMessage id={`${translationBase}__title`} />
                        </div>
                        <TabNavigation
                            className={styles["user-offers__tab-navigation"]}
                            tabs={[
                                <FormattedMessage id="user-offers__all" />,
                                <FormattedMessage id="user-offers__active" />,
                                <FormattedMessage id="user-offers__inactive" />,
                                <FormattedMessage id="user-offers__ended" />,
                            ]}
                            activeTabIndex={activeTab}
                            onTabSelect={setActiveTab}
                        />
                    </div>
                    <div
                        className={classNames(
                            styles["user-offers__container"],
                            styles["user-offers__offers-container"]
                        )}
                    >
                        {offersToDisplay?.length ? (
                            <>
                                <div className={styles["user-offers__offers-list"]}>
                                    {offersToDisplay.map((offer) => (
                                        <SingleOfferCard
                                            key={`${offer.id}-${offer.status}`}
                                            className={styles["user-offers__offer-card"]}
                                            {...offer}
                                            offerType={offersType}
                                            applicationsCount={offer.applicationsNumber}
                                            messagesCount={offer.messagesNumber}
                                            publishedDate={offer.publishDate}
                                            title={offer.title}
                                            previewMode="minimum"
                                            onDeleteOfferClick={() => handleDeleteOfferClick(offer.id)}
                                            onTitleImageClick={() => goToOffer(offer.id)}
                                            displayReviewButton={false}
                                            displayCopyButton={false}
                                            displayEndButton={false}
                                            displayDeleteButton
                                            hideActionButtons
                                            financialConditions={offer.financialConditionsSlimDTO}
                                        />
                                    ))}
                                </div>
                                <Pagination
                                    loadedItemsCount={userOffers?.length || 0}
                                    totalItemsCount={totalUserOffers || 0}
                                    currentPage={activePage + 1}
                                    pageSize={9}
                                    onPageChange={(pageIndex) => setActivePage(pageIndex - 1)}
                                />
                            </>
                        ) : (
                            <div className={styles["user-offers__empty-container"]}>
                                <MyOffersEmptyStateIcon />
                                <div className={styles["user-offers__empty-list-title"]}>
                                    <FormattedMessage id={`${translationBase}__empty-title`} />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
            {(!!deleteModalDisplayed || deleteModalDisplayed === 0) && (
                <ModalWithContent
                    className={styles["user-offers__delete-offer-modal"]}
                    header={
                        <span className={styles["user-offers__delete-offer-title"]}>
                            {intl.formatMessage({ id: `${translationBase}__delete-offer-title` })}
                        </span>
                    }
                    content={
                        <div className={styles["user-offers__delete-offer-textarea"]}>
                            <TextArea
                                value={deleteMessage}
                                onChange={handleDeleteMessageChange}
                                placeholder={`${intl.formatMessage({
                                    id: `${translationBase}__delete-offer-placeholder`,
                                })}*`}
                                helperMessages={[<FieldRequiredMessage />]}
                                rows={5}
                            />
                        </div>
                    }
                    primaryButtonContent={intl.formatMessage({ id: "common__yes" })}
                    secondaryButtonContent={intl.formatMessage({ id: "common__no" })}
                    onClose={closeDeleteModal}
                    primaryAction={() => handleDeleteOffer(chosenOffer as number)}
                    secondaryAction={closeDeleteModal}
                    primaryButtonDisabled={deleteUserOfferLoading || deleteMessage.length < 1}
                    primaryButtonLoading={deleteUserOfferLoading}
                />
            )}
            {deleteSuccessModalDisplayed && (
                <ModalWithContent
                    className={styles["users-offer__info-modal"]}
                    header={
                        <SuccessHeader>
                            {intl.formatMessage({ id: `${translationBase}__delete-offer-success-title` })}
                        </SuccessHeader>
                    }
                    content={intl.formatMessage({ id: `${translationBase}__delete-offer-success-text` })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeDeleteSuccessModal}
                    primaryAction={closeDeleteSuccessModal}
                    cancelButtonHidden
                />
            )}
            {deleteErrorModalDisplayed && (
                <ModalWithContent
                    className={styles["users-offer__info-modal"]}
                    header={
                        <ErrorHeader>
                            {intl.formatMessage({ id: `${translationBase}__delete-offer-error-title` })}
                        </ErrorHeader>
                    }
                    content={intl.formatMessage({ id: `${translationBase}__delete-offer-error-text` })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    onClose={closeDeleteErrorModal}
                    primaryAction={closeDeleteErrorModal}
                    cancelButtonHidden
                />
            )}
        </div>
    );
};

export default UserOffers;
