import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

type Chunk = boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
const strongValue = { strong: (chunks: Chunk) => <strong>{chunks}</strong> }

const PrivacyPolicy = () => {
    return (
        <div className={styles["privacy-policy"]}>
            <h1><FormattedMessage id="privacy_policy" /></h1>

            <p><FormattedMessage id="privacy_policy_about" /></p>

            <p><FormattedMessage id="privacy_policy_rules" /></p>

            <p><FormattedMessage id="privacy_policy_portal" /></p>

            <h2><FormattedMessage id="privacy_policy_vocabulary" /></h2>
            <ul>
                <li><FormattedMessage id="privacy_policy_administrator" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_portal_about" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_employee" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_employer" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_coordinator" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_user" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_guest" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_personal_data" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_statute" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_bonus" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_bonus_payments_operator" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_answer_advertisement" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_accept_answer_advertisement" values={strongValue} />
                </li>
                <li><FormattedMessage id="privacy_policy_RODO" values={strongValue} />
                </li>
            </ul>

            <p><strong><FormattedMessage id="privacy_policy_who_is_admin_personal_data" /></strong></p>
            <p><FormattedMessage id="privacy_policy_admin_personal_data" /></p>

            <p><strong><FormattedMessage id="privacy_policy_how_to_contact_whit_admin" /></strong></p>

            <p><FormattedMessage id="privacy_policy_contact_whit_admin" /></p>

            <p><strong><FormattedMessage id="privacy_policy_since_portal_collect_data" /></strong></p>

            <p><FormattedMessage id="privacy_policy_portal_collect_data" /></p>

            <p><strong><FormattedMessage id="privacy_policy_what_data_collected" /></strong></p>

            <p><FormattedMessage id="privacy_policy_portal_take_date" /></p>
            <ul>
                <li><FormattedMessage id="privacy_policy_name_surname" /></li>
                <li><FormattedMessage id="privacy_policy_other_identification_data" />
                </li>
                <li><FormattedMessage id="privacy_policy_information_education" />
                </li>
                <li><FormattedMessage id="privacy_policy_level_knowledge" />
                </li>
                <li><FormattedMessage id="privacy_policy_transfer_bonus" />
                </li>
                <li><FormattedMessage id="privacy_policy_if_employee_foreigner" />
                </li>
            </ul>

            <p><FormattedMessage id="privacy_policy_if_user_agree_than_save" /> </p>

            <p><FormattedMessage id="privacy_policy_for_self-registration_user" /></p>

            <p><FormattedMessage id="privacy_policy_used_other_web-site_for_registration" /></p>

            <p><FormattedMessage id="privacy_policy_if_registration_through_google" /></p>

            <p><FormattedMessage id="privacy_policy_if_registration_through_facebook" /></p>

            <p><FormattedMessage id="privacy_policy_after_activation" /></p>

            <p><FormattedMessage id="privacy_policy_other_personal_data" /></p>

            <p><FormattedMessage id="privacy_policy_personal_data_range" /></p>

            <p><FormattedMessage id="privacy_policy_administrator_data_process" /></p>

            <p><FormattedMessage id="privacy_policy_legal_foundation" /></p>

            <p><FormattedMessage id="privacy_policy_personal_data_need_to" /></p>

            <p><FormattedMessage id="privacy_policy_save_message_data" /></p>

            <p><FormattedMessage id="privacy_policy_need_include" /></p>

            <p><strong><FormattedMessage id="privacy_policy_for_what_portal_collect_data" /></strong></p>

            <p><FormattedMessage id="privacy_policy_portal_collect_data_1" /></p>

            <p><FormattedMessage id="privacy_policy_portal_collect_data_2" /></p>

            <p><FormattedMessage id="privacy_policy_portal_collect_data_3" /></p>

            <p><FormattedMessage id="privacy_policy_portal_collect_data_4" /></p>


            <p><strong><FormattedMessage id="privacy_policy_who_can_use_personal_data" /></strong></p>

            <p><FormattedMessage id="privacy_policy_can_use_personal_data_1" /></p>

            <p><FormattedMessage id="privacy_policy_can_use_personal_data_2" /></p>

            <p><FormattedMessage id="privacy_policy_can_use_personal_data_3" /></p>

            <p><FormattedMessage id="privacy_policy_can_use_personal_data_4" /></p>

            <p><FormattedMessage id="privacy_policy_can_use_personal_data_5" /></p>

            <p><strong><FormattedMessage id="privacy_policy_what_are_rights_have_user_who_send_personal_date" /></strong></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_1" /></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_2" /></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_3" /></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_4" /></p>
            <ol>
                <li><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_4_1" />
                </li>
                <li><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_4_2" />
                </li>
                <li><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_4_3" />
                </li>
                <li><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_4_4" />
                </li>
            </ol>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_5" /></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_6" /></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_7" /></p>

            <p><FormattedMessage id="privacy_policy_rights_have_user_who_send_personal_date_8" /></p>

            <p><strong><FormattedMessage id="privacy_policy_how_portal_protects_personal_data" /></strong></p>

            <p><FormattedMessage id="privacy_policy_portal_protects_personal_data_1" /></p>
            <ul>
                <li><FormattedMessage id="privacy_policy_portal_protects_personal_data_1_1" /></li>
                <li><FormattedMessage id="privacy_policy_portal_protects_personal_data_1_2" /></li>
                <li><FormattedMessage id="privacy_policy_portal_protects_personal_data_1_3" /></li>
                <li><FormattedMessage id="privacy_policy_portal_protects_personal_data_1_4" /></li>
            </ul>

            <p><FormattedMessage id="privacy_policy_portal_protects_personal_data_1_5" /></p>

            <p><strong><FormattedMessage id="privacy_policy_how_long_personal_data_collected" /></strong></p>

            <p><FormattedMessage id="privacy_policy_personal_data_collected_1" /></p>

            <p><FormattedMessage id="privacy_policy_personal_data_collected_2" /></p>

            <p><strong><FormattedMessage id="privacy_policy_where_to_submit_complaints_requests_inquiries" /></strong></p>

            <p><FormattedMessage id="privacy_policy_submit_complaints_requests_inquiries_1" /></p>

            <p><FormattedMessage id="privacy_policy_submit_complaints_requests_inquiries_2" /></p>

            <p><FormattedMessage id="privacy_policy_according_to_needs" /></p>

            <p><FormattedMessage id="privacy_policy_no_rights_limit" /></p>


        </div>

    );
};




export default PrivacyPolicy;
