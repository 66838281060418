import { ApiError } from "api/utils";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import Input from "features/common/components/Input";
import Spinner from "features/common/components/Spinner";
import { appRoutes } from "features/routing/routes";
import {
    getEmailLabelMessage,
    getFacebookLoginButtonMessage,
    getForgottenPasswordMessage,
    getFunctionalityTemporarilyUnavailable,
    getGoogleLoginButtonMessage,
    getAppleLoginButtonMessage,
    getLoginButtonMessage,
    getLoginNoAccountMessage,
    getLoginOrSocialMessage,
    getLoginRegisterMessage,
    getLoginTitleMessage,
    getPasswordLabelMessage,
} from "features/user/translationMessages";
import { LoginUserParams } from "features/user/types";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
// import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { FacebookLoginButton } from "./FacebookLoginButton";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { AppleLoginButton } from "./AppleLoginButton";
import styles from "./styles.module.scss";

export type Props = {
    isLoggedIn?: boolean;
    loginUserLoading?: boolean;
    loginUserLoadingError?: ApiError;
    loginUserAsync: (params: LoginUserParams) => void;
};

const Login = ({ isLoggedIn, loginUserLoading = false, loginUserLoadingError, loginUserAsync }: Props) => {
    const history = useHistory();
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values: LoginUserParams) => {
            loginUserAsync(values);
        },
    });

    const toggleShowHidePassword = useCallback(() => setIsPasswordHidden((current) => !current), [setIsPasswordHidden]);

    const noAccountMessage = getLoginNoAccountMessage(intl);
    const registerMessage = getLoginRegisterMessage(intl);
    const titleMessage = getLoginTitleMessage(intl);
    const emailLabelMessage = getEmailLabelMessage(intl);
    const passwordLabelMessage = getPasswordLabelMessage(intl);
    const orSocialMessage = getLoginOrSocialMessage(intl);
    const loginButtonMessage = getLoginButtonMessage(intl);
    const forgottenPasswordMessage = getForgottenPasswordMessage(intl);
    const googleLoginMessage = getGoogleLoginButtonMessage(intl);
    const facebookLoginMessage = getFacebookLoginButtonMessage(intl);
    const appleLoginMessage = getAppleLoginButtonMessage(intl);
    // const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmitButton = useCallback(async () => {
        // const newToken = executeRecaptcha && await executeRecaptcha("MS_Pyme_DatosEmpresa");
        // if (newToken) {
        formik.handleSubmit();
        // } else {
        // setShowCaptchaError(true)
        // }
    }, [formik]);
    const handleRegisterButton = useCallback(() => history.push(appRoutes.register), [history]);
    const handleForgottenPasswordButton = useCallback(() => history.push(appRoutes.forgottenPassword), [history]);

    useEffect(() => {
        if (isLoggedIn) {
            history.push(appRoutes.dashboard);
        }
    }, [history, isLoggedIn]);

    return (
        <div className={styles["login-form"]}>
            <div className={styles["login-form__register"]}>
                <span>{noAccountMessage}</span>
                <Button
                    data-testid="login-form__register-button"
                    as="a"
                    className={styles["login-form__register-button"]}
                    variant="no-background"
                    onClick={handleRegisterButton}
                >
                    {registerMessage}
                </Button>
            </div>
            <div className={styles["login-form__title"]}>{titleMessage}</div>
            <form onSubmit={formik.handleSubmit}>
                <Input
                    containerClassName={styles["login-form__email-input"]}
                    data-testid="login-form__email-input"
                    label={emailLabelMessage}
                    placeholder={emailLabelMessage}
                    name="email"
                    onKeyDown={(event) => {
                        formik.handleBlur(event);

                        if (event.key === "Enter") {
                            handleSubmitButton();
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="text"
                    isInvalid={!!formik.errors.email}
                    disabled={loginUserLoading}
                />
                <Input
                    containerClassName={styles["login-form__password-input"]}
                    data-testid="login-form__password-input"
                    label={passwordLabelMessage}
                    placeholder={passwordLabelMessage}
                    name="password"
                    onKeyDown={(event) => {
                        formik.handleBlur(event);

                        if (event.key === "Enter") {
                            handleSubmitButton();
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    type={isPasswordHidden ? "password" : "text"}
                    isInvalid={!!formik.errors.password}
                    isHidden={isPasswordHidden}
                    toggleHideShow={toggleShowHidePassword}
                    withShowHideButton
                    disabled={loginUserLoading}
                />
                {/* <div className={styles['login-form__captcha-wrapper']}>
                    <GoogleReCaptcha onVerify={() => setShowCaptchaError(false)} />
                </div> */}
                <Button
                    data-testid="login-form__login-button"
                    className={styles["login-form__login-button"]}
                    variant="login"
                    onClick={handleSubmitButton}
                    disabled={loginUserLoading}
                    type="submit"
                >
                    {loginUserLoading ? (
                        <Spinner className={styles["login-form__spinner"]} thickness="thin" />
                    ) : (
                        loginButtonMessage
                    )}
                </Button>
                {showCaptchaError && (
                    <FieldMessages
                        className={styles["login-form__error-messages"]}
                        data-testid="login-form__error-messages"
                        messages={[
                            intl.formatMessage({
                                id: 'common__captcha_error'
                            }),
                        ]}
                        mode="error"
                    />
                )}

                {!!loginUserLoadingError && (
                    <FieldMessages
                        className={styles["login-form__error-messages"]}
                        data-testid="login-form__error-messages"
                        messages={[
                            intl.formatMessage({
                                id: loginUserLoadingError.response?.status
                                    ? `login-form__error-${loginUserLoadingError.response?.status}`
                                    : "login-form__error",
                            }),
                        ]}
                        mode="error"
                    />
                )}
                <Button
                    data-testid="login-form__forgotten-password-button"
                    as="a"
                    className={styles["login-form__forgotten-password-button"]}
                    variant="no-background"
                    onClick={handleForgottenPasswordButton}
                    disabled={loginUserLoading}
                >
                    {forgottenPasswordMessage}
                </Button>
                <div className={styles["login-form__or-social"]}>{orSocialMessage}</div>
                {GoogleLoginButton(loginUserLoading, googleLoginMessage)}
                {FacebookLoginButton(loginUserLoading, facebookLoginMessage)}
                {AppleLoginButton(loginUserLoading, appleLoginMessage)}
            </form>
        </div>
    );
};

export default Login;
