import { localStorageItems } from "features/common/constants";

class SignOutTimeoutService {
    private validateUserTokenTimeout: NodeJS.Timeout | undefined;
    private validationCallbackFn: () => void = () => {};

    public setValidationCallback = (validationCallback: () => void) => (this.validationCallbackFn = validationCallback);

    public setTimeoutSignOut = (): void => {
        if (this.validateUserTokenTimeout) {
            this.removeSignOutTimeout();
        }

        const expirationTokenDate = localStorage.getItem(localStorageItems.CHECK_TOKEN_DATE);
        if (!expirationTokenDate) {
            return;
        }

        const isNan = Number.isNaN(Number.parseInt(expirationTokenDate));

        if (isNan) {
            return;
        }

        const now = new Date();
        let timeout = +expirationTokenDate - now.getTime();

        timeout = Math.max(0, timeout);

        this.validateUserTokenTimeout = setTimeout(this.validationCallbackFn, timeout);
    };

    public removeSignOutTimeout = (): void => {
        if (!this.validateUserTokenTimeout) {
            return;
        }

        clearTimeout(this.validateUserTokenTimeout);
        this.validateUserTokenTimeout = undefined;
    };
}

export default new SignOutTimeoutService();
