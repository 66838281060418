import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    value?: number;
    minValue?: number;
    className?: string;
    label?: string;
    onIncrease?: () => void;
    onDecrease?: () => void;
}

const NumberSpinnerInput = ({
    "data-testid": testId,
    value,
    minValue,
    className,
    label,
    onIncrease,
    onDecrease,
}: Props) => {
    return (
        <div data-testid={testId} className={classNames(styles["number-spinner-input"], className)}>
            {label && (
                <div data-testid={`${testId}__label_wrapper`} className={styles["number-spinner-input__label-wrapper"]}>
                    <label data-testid={`${testId}__label`}>{label}</label>
                </div>
            )}
            <div className={styles["number-spinner-input__field-container"]}>
                <Button
                    className={styles["number-spinner-input__decrease"]}
                    variant="no-background"
                    onClick={onDecrease}
                    disabled={minValue && value ? value <= minValue : false}
                    data-testid={`${testId}__decrease-button`}
                >
                    <MinusIcon />
                </Button>
                <input
                    className={styles["number-spinner-input__field"]}
                    type="text"
                    value={value}
                    readOnly
                    data-testid={`${testId}__field`}
                />
                <Button
                    className={styles["number-spinner-input__increase"]}
                    variant="no-background"
                    onClick={onIncrease}
                    data-testid={`${testId}__increase-button`}
                >
                    <PlusIcon />
                </Button>
            </div>
        </div>
    );
};

export default NumberSpinnerInput;
