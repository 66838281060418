import useDeviceClass from "features/common/hooks/useDeviceClass";
import { EnumDictionary } from "features/common/types";
import RequirementBlock from "features/jobOffers/components/CreateJobOffer/RequirementsStep/RequirementBlock";
import { getLanguageLevelsLabels, getLanguagesLabels } from "features/jobOffers/hooks";
import { Language, LanguageLevelType, LanguageType, RequirementLanguageTouched } from "features/jobOffers/types";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import LanguageOptionBlock from "./LanguageOptionBlock";
import styles from "./styles.module.scss";

export interface Props {
    header: string;
    label: string;
    languagesValueInputProps: FieldInputProps<Language[]>;
    languagesFieldMeta: FieldMetaProps<Language[]>;
    languagesHelperProps: FieldHelperProps<Language[]>;
    ["data-testid"]?: string;
}

const LanguageRequirement = ({
    header,
    label,
    languagesValueInputProps,
    languagesFieldMeta,
    languagesHelperProps,
    "data-testid": testId,
}: Props) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();

    const [prevLanguagesState, setPreviousLanguageValues] = useState<Language[]>(languagesValueInputProps.value);
    const [languagesTouched, setLanguagesTouched] = useState<EnumDictionary<LanguageType, RequirementLanguageTouched>>({
        [LanguageType.English]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Arabic]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Czech]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.French]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Hindi]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Spanish]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Dutch]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.German]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Polish]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Russian]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Romanian]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Ukrainian]: { isOtherValueTouched: false, isLevelTouched: false },
        [LanguageType.Italian]: { isOtherValueTouched: false, isLevelTouched: false },
    });

    const [languagesLabels, setLanguagesLabels] = useState(getLanguagesLabels(intl));
    const [languageLevels, setLanguageLevels] = useState(getLanguageLevelsLabels(intl));

    useEffect(() => {
        setLanguageLevels(getLanguageLevelsLabels(intl))
        setLanguagesLabels(getLanguagesLabels(intl))
    }, [intl])

    const onCancel = () => {
        languagesHelperProps.setValue(prevLanguagesState);
        cleanTouchedValues();
    };

    const onAdd = () => {
        const cloned = languagesValueInputProps.value?.map((x) => Object.assign({}, x));
        setPreviousLanguageValues(cloned);
    };

    const onEdit = () => {
        const cloned = languagesValueInputProps.value?.map((x) => Object.assign({}, x));
        setPreviousLanguageValues(cloned);
    };

    const onSave = () => {
        if (languagesFieldMeta.error) {
            const languagesTouchedPrevState = Object.assign({}, languagesTouched);
            languagesValueInputProps.value.forEach((language) => {
                if (language.isChecked) {
                    languagesTouchedPrevState[language.id].isLevelTouched = true;
                    languagesTouchedPrevState[language.id].isOtherValueTouched = true;
                }
            });

            setLanguagesTouched(languagesTouchedPrevState);
            return false;
        } else {
            cleanTouchedValues();
            return true;
        }
    };

    const onClean = () => {
        const newArray = languagesValueInputProps.value.map((current) => {
            return {
                id: current.id,
                level: undefined,
                isChecked: false,
                otherValue: undefined,
            };
        });

        languagesHelperProps.setValue(newArray);
        setPreviousLanguageValues(newArray);
        cleanTouchedValues();
    };

    const cleanTouchedValues = () => {
        const languagesTouchedPrevState = Object.assign({}, languagesTouched);
        Object.keys(languagesTouchedPrevState).forEach((key) => {
            const languageType = key as LanguageType;
            languagesTouched[languageType].isLevelTouched = false;
            languagesTouched[languageType].isOtherValueTouched = false;
        });
        setLanguagesTouched(languagesTouchedPrevState);
    };

    const onLevelSelect = useCallback(
        (level: LanguageLevelType, id: LanguageType) => {
            const newArray = languagesValueInputProps.value.map((x) => {
                const clonedLanguage = Object.assign({}, x);
                if (clonedLanguage.id === id) {
                    clonedLanguage.level = level;
                }

                return clonedLanguage;
            });
            const languagesTouchedPrevState = Object.assign({}, languagesTouched);
            languagesTouchedPrevState[id].isLevelTouched = true;

            setLanguagesTouched(languagesTouchedPrevState);
            languagesHelperProps.setValue(newArray);
        },
        [languagesHelperProps, languagesTouched, languagesValueInputProps.value]
    );

    const onLevelToggle = useCallback(
        (id: LanguageType, toggled: boolean) => {
            const languagesTouchedPrevState = Object.assign({}, languagesTouched);
            languagesTouchedPrevState[id].isLevelTouched = languagesTouched[id].isLevelTouched || !toggled;
            setLanguagesTouched(languagesTouchedPrevState);
        },
        [languagesTouched]
    );

    const onCheckboxToggle = useCallback(
        (checked: boolean, id: LanguageType) => {
            const languagesTouchedPrevState = Object.assign({}, languagesTouched);
            const newArray = languagesValueInputProps.value.map((x) => {
                const clonedLanguage = Object.assign({}, x);
                if (clonedLanguage.id === id) {
                    clonedLanguage.isChecked = checked;
                    if (!checked) {
                        clonedLanguage.level = undefined;
                        clonedLanguage.otherValue = undefined;
                        languagesTouchedPrevState[id].isLevelTouched = false;
                        languagesTouchedPrevState[id].isOtherValueTouched = false;
                    }
                }

                return clonedLanguage;
            });

            setLanguagesTouched(languagesTouchedPrevState);
            languagesHelperProps.setValue(newArray);
        },
        [languagesHelperProps, languagesTouched, languagesValueInputProps.value]
    );


    const getValueArea = useCallback(() => {
        const stateToShow = languagesValueInputProps.value?.filter((l) => l.isChecked);
        if (stateToShow?.length > 0) {
            return (
                <div className={styles["language-requirement__value-area"]}>
                    {stateToShow.map((option, index) => (
                        <div
                            data-testid={testId ? `${testId}__language-value` : undefined}
                            className={
                                index !== stateToShow.length - 1
                                    ? styles["language-requirement__value-area--with-comma"]
                                    : ""
                            }
                            key={"value_area" + option.id}
                        >
                            {`${languagesLabels[option.id]} (${option.level !== undefined && languageLevels[option.level]
                                })`}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    }, [languagesValueInputProps.value, languagesLabels, testId, languageLevels]);

    const optionContent = useCallback(
        (option: Language, index: number) => {
            return (
                <LanguageOptionBlock
                    data-testid={testId ? `${testId}__option-block` : undefined}
                    language={option}
                    languagesLabels={languagesLabels}
                    languageLevels={languageLevels}
                    languagesTouched={languagesTouched}
                    values={languagesValueInputProps.value}
                    index={index}
                    error={languagesFieldMeta.error}
                    onCheckboxToggle={onCheckboxToggle}
                    onLevelSelect={onLevelSelect}
                    onLevelToggle={onLevelToggle}
                    key={index}
                />
            );
        },
        [
            languageLevels,
            languagesFieldMeta.error,
            languagesLabels,
            languagesTouched,
            languagesValueInputProps.value,
            onCheckboxToggle,
            onLevelSelect,
            onLevelToggle,
            testId,
        ]
    );

    const editorContent = useMemo(() => {
        return (
            <div className={styles["language-requirement__options-container"]}>
                {deviceClass !== "desktop" ? (
                    <div className={styles["language-requirement__options-container-column"]}>
                        {languagesValueInputProps.value?.map((option, index) => optionContent(option, index))}
                    </div>
                ) : (
                    <>
                        <div className={styles["language-requirement__options-container-column"]}>
                            {languagesValueInputProps?.value
                                ?.slice(0, 7)
                                .map((option, index) => optionContent(option, index))}
                        </div>
                        <div className={styles["language-requirement__options-container-column"]}>
                            {languagesValueInputProps.value
                                ?.slice(7, 15)
                                .map((option, index) => optionContent(option, index + 7))}
                        </div>
                    </>
                )}
            </div>
        );
    }, [deviceClass, languagesValueInputProps, optionContent]);

    return (
        <div>
            <RequirementBlock
                data-testid={testId ? `${testId}__requirement-block` : undefined}
                label={header}
                onAdd={onAdd}
                onSave={onSave}
                value={getValueArea()}
                onEdit={onEdit}
                onClean={onClean}
                onCancel={onCancel}
                isCleanable={true}
                editorContent={editorContent}
            />
        </div>
    );
};

export default LanguageRequirement;
