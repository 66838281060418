import { ReactComponent as MagnifierIcon } from "assets/icons/magnifier.svg";
import Box from "components/Box";
import Button from "components/Button";
import Container from "components/Container";
import TagInput from "features/common/components/TagInput";
import { getLocationMessage, getSearchKeywordMessage } from "features/common/translationMessages";
import { DropdownOption } from "features/common/types";
import LocationRangePicker from "features/offers/components/SearchBox/SearchBoxForm/LocationRangePicker";
import { getKmIntlMessage } from "features/offers/translationMessages";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

type FormValues = {
    title: string;
    city: string;
    range: any;
    placeId: string;
};

const distanceLowerBound = 50;
const distanceUpperBound = 100;
const lowerStep = 5;
const upperStep = 25;

const Search = () => {
    const history = useHistory();
    const intl = useIntl();
    const isMatches = useMediaQuery('(min-width: 1440px)');
    const locationIntlMessage = getLocationMessage(intl);
    const KeywordSearchMsg = isMatches ? getSearchKeywordMessage(intl, true) : getSearchKeywordMessage(intl);
    const kmIntlMessage = getKmIntlMessage(intl);
    const [rangeDisabled, setRangeDisabled] = useState(true);

    const [formValues, setFormValues] = useState<FormValues>({
        title: "",
        city: "",
        range: null,
        placeId: "",
    });

    const [locationOptions, setLocationOptions] = useState<any>([]);

    const onFormChange = useCallback((key: string, value: string) => setFormValues((prevState) => ({ ...prevState, [key]: value })), []);

    const onSearchChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
        onFormChange("title", value);
    }

    const handleLocationRangeChange = useCallback(
        (fieldName: string, inputValue: any) => {
            onFormChange("range", inputValue);
        },
        [onFormChange],
    );

    const onSearch = (event: FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        history.push(`/job-offers?title=${formValues.title}&city=${formValues.city}&distance=${formValues.range?.value}&placeId=${formValues.placeId}`);
    };

    const generateDistanceOptions = (): DropdownOption<number>[] => {
        const distanceOptions: DropdownOption<number>[] = [];
        for (let i = 0; i < distanceLowerBound; i += lowerStep) {
            distanceOptions.push({
                value: i,
                display: `+ ${i} ${kmIntlMessage}`,
            });
        }

        for (let i = 50; i <= distanceUpperBound; i += upperStep) {
            distanceOptions.push({
                value: i,
                display: `+ ${i} ${kmIntlMessage}`,
            });
        }
        return distanceOptions;
    };

    const distanceOptions = generateDistanceOptions();

    const distancePlaceholder = (): string => {
        return `+ 0 km`;
    };

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500
    });

    const handleLocationChange = useCallback(
        (selectedTag: any, inputValue: any) => {
            if (selectedTag.length) {
                const [newValue] = selectedTag;

                placesService?.getDetails({ placeId: newValue.id }, (placeDetails) => {
                    onFormChange("placeId", `${placeDetails?.place_id}`);
                    setRangeDisabled(!!!placeDetails?.address_components?.find((item) => item?.types?.find((type) => type === 'locality')));
                });

                return onFormChange("city", newValue.name);
            }

            return onFormChange("city", inputValue);
        },
        [onFormChange, placesService],
    );

    useEffect(() => {
        getPlacePredictions({ input: formValues.city, types: ['(regions)']  });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.city]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id,
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [formValues.city, placePredictions]);

    return (
        <Wrapper width="100%">
            <Container /*paddingTop="0"*/ padding="0">
                {/* <StyledHeadline><FormattedMessage id="dashboard__headline" /></StyledHeadline> */}
                <Form as="form" gridGap="12px" /*gridTemplateColumns="354px 354px 105px 205px"*/>
                    <TextInput className="TextInput" placeholder={`${KeywordSearchMsg}`} onChange={onSearchChange} />
                    <LocationWrapper>
                        <TagInput
                            value={{ tags: [], input: formValues.city }}
                            onChange={handleLocationChange}
                            placeholder={`${locationIntlMessage}`}
                            sections={locationOptions}
                            shouldEmphasizeSearchTerm
                        />
                    </LocationWrapper>
                    <LocationRangePicker
                        distancePlaceholder={distancePlaceholder}
                        value={formValues.range}
                        setFieldValue={handleLocationRangeChange}
                        distanceOptions={distanceOptions}
                        distanceDisabled={!formValues.placeId || rangeDisabled}
                    />
                    <Button mobileWidth variant="orange" width="100%" onClick={onSearch} >
                        <StyledMagnifierIcon />
                        <FormattedMessage id="common__search" />
                    </Button>
                </Form>
                {/* <StyledLink to="/job-offers"><FormattedMessage id="dashboard__advanced-search" /></StyledLink> */}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    width: 100%;
    position: relative;
    bottom: 9.25rem;
    margin: 0 auto;
    padding: 1.375rem 1rem;
    border-radius: 5px;
    background-color: rgba(72, 72, 72, 0.65);
    box-shadow: 4px 4px 15px rgb(0 38 119 / 25%);

    @media screen and (min-width: 415px){
        bottom: 17rem;
    }

    @media screen and (min-width: 834px) {
        max-width: 49rem;
        margin: 0 auto;
        padding: 1.5rem 1.75rem;
    }

    @media screen and (min-width: 862px) and (max-width: 1440px){
        bottom: 24rem;
    }
    
    @media screen and (min-width: 1440px) {
        max-width: 73.75rem;
        padding: 1.625rem 4.125rem;
    }
    @media screen and (min-width: 1441px) {
        bottom: 17rem;
    }
`;

const LocationWrapper = styled.div`
    position: relative;
`;

const TextInput = styled.input`
::placeholder { 
    font-size: 1rem;
    color: #002677;
    opacity: 0.5;
}

&:focus::placeholder {
    color: transparent;
}

@media screen and (max-width: 833px){
    grid-column-start: 1;
    grid-column-end: 3;
  }
  -webkit-appearance: none;
  height: 48px;
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid rgba(72, 72, 72, 0.25);
  border-radius: 5px;
`;

const Form = styled(Box)`
grid-template-columns: 5fr 1fr;

    @media screen and (min-width: 834px) {
        grid-template-columns: 2fr 2fr 0.1fr 1.3fr;
    }

    @media screen and (min-width: 1440px) {
      grid-template-columns: 22.25rem 22.25rem 6.5rem 12.75rem;
    }
`;

const StyledMagnifierIcon = styled(MagnifierIcon)`
margin-right: 0.25rem;
`;

export default Search;
