import JobOfferDetailsComponent from "features/jobOffers/components/JobOfferDetails";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
    id?: string;
}

const JobOfferDetails = () => {
    let { id } = useParams<Props>();

    return (
        <div className={styles["offer-details"]}>
            <div className={styles["offer-details__inner-container"]}>
                {id && <JobOfferDetailsComponent id={parseInt(id!)} />}
            </div>
        </div>
    );
};

export default JobOfferDetails;
