import { selectForgotPasswordLoading, selectForgotPasswordLoadingSuccess, selectForgotPasswordLoadingError, selectLoginUserLoading, selectLoginUserLoadingError } from "features/user/selectors";
import { forgottenPasswordAsync } from "features/user/thunks";
import ForgottenPassword from "./component";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import { forgottenPasswordreset } from "features/user/actions";

const mapStateToProps = (state: RootState) => ({
    forgotPasswordLoading: selectForgotPasswordLoading(state),
    forgotPasswordLoadingSuccess: selectForgotPasswordLoadingSuccess(state),
    forgotPasswordLoadingError: selectForgotPasswordLoadingError(state),
    loginUserLoading: selectLoginUserLoading(state),
    loginUserLoadingError: selectLoginUserLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            forgottenPasswordAsync,
            forgottenPasswordreset: () => forgottenPasswordreset()
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
