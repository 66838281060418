import axios, { AxiosError, CancelToken as AxiosCancelToken, CancelTokenSource } from "axios";

export interface ApiException {
    messageCode: string;
    details: string;
    parameters: string[];
    response: {
        status: number;
    };
}

export interface ApiRequestError extends AxiosError<ApiException> {
    formattedMessage?: string;
}

export type CancelError = undefined;

export type ApiError = ApiRequestError | CancelError;

export type CancelToken = AxiosCancelToken | CustomCancelToken;

export type RequestCancelToken = { cancelToken?: any };

export type ManuallyHandledCancelToken = CancelTokenSource;

export class CustomCancelToken {
    private _callbacks: (() => void)[] = [];
    private _isCancelled = false;

    public isCustomCancelToken = true;

    public get isCancelled(): boolean {
        return this._isCancelled;
    }

    public cancel(): void {
        this._isCancelled = true;
        this._callbacks.forEach((callback) => callback());
    }

    public addCallback(callback: () => void): void {
        this._callbacks.push(callback);
    }
}

export const createCancelToken = (cancelToken?: CustomCancelToken): AxiosCancelToken | undefined => {
    if (cancelToken) {
        const axiosToken = axios.CancelToken.source();

        cancelToken.addCallback(() => {
            axiosToken.cancel();
        });

        return axiosToken.token;
    }
};

export const createManuallyHandledCancelToken = () => axios.CancelToken.source();
