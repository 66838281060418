import { PaymentFormSchema } from "../../../../schemas";
import MyProfileApi from "features/myProfile/api";
import { createCancelToken } from "api/utils";
import { PreAuthData } from "features/jobOffers/api/types";

export const getCardId = async ({
    values,
    cardData,
    invoiceData,
    jobOfferId,
}: {
    values: PaymentFormSchema;
    cardData: any;
    invoiceData: any;
    jobOfferId: number;
}): Promise<PreAuthData> => {
    let newCardId: number | undefined;

    if (values.isEditing && values.cardNumber && values.expirationDate) {
        if (!!cardData?.id) {
            await MyProfileApi.deletePaymentCardAsync(createCancelToken());
        }

        const response = await MyProfileApi.createNewPaymentCardAsync(
            { cardNumber: values.cardNumber, expiryDate: values.expirationDate },
            createCancelToken()
        );
        newCardId = response.id;
    }

    return { cardId: newCardId || cardData?.id, jobOfferId };
};
