import Api from "api/api";
import { ApiError, CancelToken } from "api/utils";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import Spinner from "features/common/components/Spinner";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { Currency, SalaryType } from "features/common/models";
import { CardType, OfferType, WorkType } from "features/common/types";
import { getLocalizedYup } from "features/common/validators";
import EmployeeOffersApi from "features/employeeOffers/api";
import { mapCreateEmployeeOfferSchemaToFormData } from "features/employeeOffers/mappers";
import { CreateEmployeeOfferRequestObject, EmployeeOfferDetails } from "features/employeeOffers/models";
import { CreateEmployeeOfferSchema } from "features/employeeOffers/schemas";
import { EmployeeOfferType } from "features/employeeOffers/types";
import { createEmployeeOfferFormValidationSchema } from "features/employeeOffers/validators";
import RequirementsStep from "features/jobOffers/components/CreateJobOffer/RequirementsStep";
import { EducationType, EmployerType, LanguageType, PayoutDayType } from "features/jobOffers/types";
import { CardDetails } from "features/myProfile/models";
import DetailsStep from "features/offers/components/CreateEmployeeOffer/DetailsStep";
import OfferDetailsStep from "features/offers/components/CreateEmployeeOffer/OfferDetailsStep";
import AmenitiesStep from "features/offers/components/CreateOffer/AmenitiesStep";
import FinancialStep from "features/offers/components/CreateOffer/FinancialStep";
import TextEditor from "features/offers/components/CreateOffer/TextEditor";
import { appRoutes } from "features/routing/routes";
import { PhotoDto } from "features/user/dtos";
import { User } from "features/user/models";
import { Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { uuid } from "uuidv4";
import styles from "./styles.module.scss";

export interface Props {
    isCopying?: boolean;
    user?: User;
    offerId?: string;
    employeeOfferDetails?: EmployeeOfferDetails;
    cardDetails?: CardDetails;
    cardDetailsLoading: boolean;
    cardDetailsError?: ApiError;
    employeeOfferCreating?: boolean;
    employeeOfferCreatingSuccess?: number;
    employeeOfferCreatingError?: any;
    currencies?: Currency[];
    currenciesLoading?: boolean;
    currenciesLoadingError?: ApiError;
    getCardDetailsAsync: (id: string, cancelToken?: CancelToken) => void;
    publishEmployeeOfferAsync: (values: CreateEmployeeOfferRequestObject, cancelToken?: CancelToken) => void;
    getCurrenciesAsync: (cancelToken?: CancelToken) => void;
    getEmployeeOfferDetailsAsync: (id: number, cancelToken?: CancelToken) => void;
    resetEmployeeOfferDetails: () => void;
}

export interface Params {
    businessCardId: string;
    offerLength: string;
    dedicatedUserId?: string;
}

const createInitialCreateEmployeeOfferValues = (
    cardDetails?: CardDetails,
    currencies?: Currency[],
    user?: User,
    employeeOfferDetails?: EmployeeOfferDetails,
    isCopying?: boolean
): CreateEmployeeOfferSchema => {
    let location: any = {};
    if (employeeOfferDetails?.location && isCopying) {
        location = { ...employeeOfferDetails?.location };
        delete location?.id;
    } else if (employeeOfferDetails?.location) {
        location = { ...employeeOfferDetails?.location };
    }
    return {
        detailsStep: {
            offerAs:
                cardDetails?.type === CardType.EmployeeCompany || cardDetails?.type === CardType.EmployerCompany
                    ? EmployerType.Company
                    : EmployerType.Private,
            firstName: cardDetails?.firstName || user?.firstName || "",
            lastName: cardDetails?.lastName || user?.lastName || "",
            businessName: cardDetails?.companyName || "",
            location: cardDetails?.locationDTO || "",
            krazCertificateNumber: cardDetails?.krazCert || "",
            taxIdentificationNumber: cardDetails?.taxIdentificationNumber || "",
            phoneNumber: cardDetails?.phoneNumber || "",
            phonePrefix: cardDetails?.phonePrefix || "",
            photos: employeeOfferDetails?.images?.map((img: string) => ({ fileName: img, fileUrl: `${config.IMAGE_URL}/${img}` })) || [],
        },
        offerDetailsStep: {
            position: employeeOfferDetails?.position || "",
            branchId: employeeOfferDetails?.branch.id.toString() || `${cardDetails?.branchCode}` || "",
            offerType: (employeeOfferDetails?.availablePositionsNumber || 0) > 1 ? EmployeeOfferType.Group : EmployeeOfferType.Single,
            positionsAvailable: employeeOfferDetails?.availablePositionsNumber || undefined,
            workType: employeeOfferDetails?.workType || WorkType.Steady,
            jobStartDate: employeeOfferDetails?.jobStartDate || new Date(),
            jobEndDate: employeeOfferDetails?.jobEndDate || new Date(),
            workDuringHolidays: employeeOfferDetails?.workDuringHolidays || false,
            location,
        },
        financialStep: {
            salaryType: employeeOfferDetails?.financialConditions.paymentRateType || SalaryType.Monthly,
            salaryMinValue: employeeOfferDetails?.financialConditions.rewardMinimumValue,
            salaryMaxValue: employeeOfferDetails?.financialConditions.rewardMaximumValue || undefined,
            salaryCurrencyCode: employeeOfferDetails?.financialConditions.rewardCurrencyCode || currencies?.[1].code || "",
            bonusValue: employeeOfferDetails?.financialConditions.bonusValue || undefined,
            bonusCurrencyCode: employeeOfferDetails?.financialConditions.bonusCurrencyCode || currencies?.[1].code || "",
            bonusType: (employeeOfferDetails?.financialConditions.bonusPayoutDateType || undefined) as PayoutDayType,
            attachBonus: !!employeeOfferDetails?.financialConditions.bonusValue,
        },
        additionalInfoStep: {
            additionalInfo: employeeOfferDetails?.additionalInfo || ''
        },
        requirementsStep: {
            languages: [
                {
                    id: LanguageType.English,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.English
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.English
                    )?.level,
                },
                {
                    id: LanguageType.Arabic,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Arabic
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Arabic
                    )?.level,
                },
                {
                    id: LanguageType.Czech,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Czech
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Czech
                    )?.level,
                },
                {
                    id: LanguageType.French,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.French
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.French
                    )?.level,
                },
                {
                    id: LanguageType.Hindi,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Hindi
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Hindi
                    )?.level,
                },
                {
                    id: LanguageType.Spanish,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Spanish
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Spanish
                    )?.level,
                },
                {
                    id: LanguageType.Dutch,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Dutch
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Dutch
                    )?.level,
                },
                {
                    id: LanguageType.German,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.German
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.German
                    )?.level,
                },
                {
                    id: LanguageType.Polish,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Polish
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Polish
                    )?.level,
                },
                {
                    id: LanguageType.Russian,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Russian
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Russian
                    )?.level,
                },
                {
                    id: LanguageType.Romanian,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Romanian
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Romanian
                    )?.level,
                },
                {
                    id: LanguageType.Ukrainian,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Ukrainian
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Ukrainian
                    )?.level,
                },
                {
                    id: LanguageType.Italian,
                    isChecked: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Italian
                    )
                        ? true
                        : false,
                    level: employeeOfferDetails?.languageRequirements?.find(
                        (item: any) => item.name === LanguageType.Italian
                    )?.level,
                },
            ],
            programs: employeeOfferDetails?.requirements?.find((item: any) => item.requirementType === "COMPUTER_AND_SOFTWARE")
                ?.valueArray?.map((item: any) => ({
                    label: item,
                    value: item,
                })) || [],
            other: employeeOfferDetails?.requirements?.find((item: any) => item.requirementType === "OTHER")?.valueArray?.map((item: any) => ({
                label: item,
                value: item,
            })) || [],
            education: [
                {
                    id: EducationType.None,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.None.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Primary,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Primary.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Vocational,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Vocational.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Secondary,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Secondary.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.SecondaryTechnical,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.SecondaryTechnical.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Higher,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Higher.toUpperCase())
                        ? true
                        : false,
                },
                {
                    id: EducationType.Other,
                    isChecked: employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "EDUCATION")
                        .find((item: any) => item.value === EducationType.Other.toUpperCase())
                        ? true
                        : false,
                },
            ],
            experience:
                employeeOfferDetails?.requirements?.find((item: any) => item.requirementType === "EXPERIENCE")?.value || "",
            permissions:
                employeeOfferDetails?.requirements?.find((item: any) => item.requirementType === "PERMISSIONS")?.valueArray?.map((item: any) => ({
                    label: item,
                    value: item,
                })) || [],
            drivingLicenses:
                employeeOfferDetails?.requirements?.filter((item: any) => item.requirementType === "DRIVING_LICENSE")
                    .map((item: any) => item.value) || [],
            certificates:
                employeeOfferDetails?.requirements?.find((item: any) => item.requirementType === "CERTIFICATES_AND_LICENSES")
                    ?.valueArray?.map((item: any) => ({
                        label: item,
                        value: item,
                    })) || [],
            jobExperience: employeeOfferDetails?.jobExperience.map((item: any) => ({
                ...item,
                startDate: new Date(item.dateFrom),
                endDate: new Date(item.dateTo),
                additionalInfo: item.description,
                id: uuid(),
            })) || [],
        },
        amenitiesStep: employeeOfferDetails
            ? {
                accommodation:
                    !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "accommodation")?.value,
                assistanceInLegalizingStay: !!employeeOfferDetails.amenities.find(
                    (item: any) => item.amenityType === "assistInLegalizingStay"
                )?.value,
                catering: !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "meals")?.value,
                freeMedicalExaminations: !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "freeMedicalExam")?.value,
                otherAmenitiesText: employeeOfferDetails.amenities.find((item: any) => item.amenityType === "other")?.value || undefined,
                // other: employeeOfferDetails.amenities.find((item: any) => item.amenityType === "other")?.value || undefined,
                other: !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "other")?.value,
                transport: !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "transport")?.value,
                workBoots:
                    !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "workBoots")?.value,
                workWear:
                    !!employeeOfferDetails.amenities.find((item: any) => item.amenityType === "workWear")?.value,
            }
            : {},
    };
};

const EDITOR_CHARACTER_LIMIT = 2000;

const CreateOffer = ({
    isCopying,
    user,
    offerId,
    cardDetails,
    cardDetailsLoading,
    cardDetailsError,
    getCardDetailsAsync,
    employeeOfferCreating,
    employeeOfferCreatingError,
    employeeOfferCreatingSuccess,
    publishEmployeeOfferAsync,
    currencies,
    currenciesLoading,
    currenciesLoadingError,
    getCurrenciesAsync,
    employeeOfferDetails,
    getEmployeeOfferDetailsAsync,
    resetEmployeeOfferDetails,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();
    const { businessCardId } = useParams<Params>();
    const [uploadingImages, setUploadingImages] = useState(false);
    const [downloadPhotos, setDownloadPhotos] = useState<PhotoDto[]>([]);
    const [clearedPhotos, setClearedPhotos] = useState<boolean>()
    const formikRef = useRef<FormikProps<CreateEmployeeOfferSchema>>(null);
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl);
        return createEmployeeOfferFormValidationSchema(localYup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);
    const initialValues = useMemo(
        () => createInitialCreateEmployeeOfferValues(cardDetails, currencies, user, employeeOfferDetails, isCopying),
        [cardDetails, currencies, employeeOfferDetails, user, isCopying]
    );

    const changer = (data: boolean) => {
        setClearedPhotos(data)
    }

    useEffect(() => {
        if (!offerId) {
            resetEmployeeOfferDetails();
        }
    }, [offerId, resetEmployeeOfferDetails]);

    useEffect(() => {
        if (!offerId || !getEmployeeOfferDetailsAsync) {
            return;
        }

        getEmployeeOfferDetailsAsync(+(offerId || 0), createCancelToken());
    }, [createCancelToken, getEmployeeOfferDetailsAsync, intl.locale, offerId]);

    useEffect(() => {
        if (businessCardId || employeeOfferDetails?.businessCardId) {
            getCardDetailsAsync(`${businessCardId || employeeOfferDetails?.businessCardId}`, createCancelToken());
        }
    }, [businessCardId, createCancelToken, employeeOfferDetails, getCardDetailsAsync]);

    useEffect(() => {
        if (!currencies && !currenciesLoading) {
            getCurrenciesAsync(createCancelToken());
        }
    }, [currencies, createCancelToken, currenciesLoading, getCurrenciesAsync]);

    const handleCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleSubmit = useCallback(
        async (values: CreateEmployeeOfferSchema) => {
            try {
                const formData = mapCreateEmployeeOfferSchemaToFormData(
                    values,
                    +(businessCardId || employeeOfferDetails?.businessCardId || 0),
                    downloadPhotos,
                );
                if (clearedPhotos) {
                    formData.employeeOfferDetails.images = [];
                    formData.employeeOfferDetails.mainImage = "";
                }
                publishEmployeeOfferAsync(formData);
            } catch (error) {
                console.warn({ error });
            }
        },
        [businessCardId, employeeOfferDetails, publishEmployeeOfferAsync, downloadPhotos]
    );

    const handleEditEmployeeOffer = useCallback(
        async (values: CreateEmployeeOfferSchema) => {
            try {
                const imageForm = new FormData();
                setUploadingImages(true);
                values.detailsStep.photos.forEach((photo) => !!photo.file && imageForm.append("images", photo.file));
                const uploadedImages = values.detailsStep.photos.some((el) => el.file)
                    ? await EmployeeOffersApi.uploadUserPhotos(imageForm)
                    : [];

                const formData = mapCreateEmployeeOfferSchemaToFormData(
                    values,
                    +(employeeOfferDetails?.businessCardId || 0),
                    [...uploadedImages]
                );
                await Api.put(`${config.apiRoutes.EMPLOYEE_OFFERS}/${offerId}`, formData.employeeOfferDetails);
                history.goBack();
            } catch (error) {
                console.warn({ error });
            }
        },
        [employeeOfferDetails, history, offerId]
    );

    useEffect(() => {
        if (employeeOfferCreatingSuccess) {
            history.push(`${appRoutes.employeeOffers}/${employeeOfferCreatingSuccess}`);
        }
    });

    if (cardDetailsLoading || currenciesLoading) {
        return (
            <SpinnerWithMessage message={intl.formatMessage({ id: "create-employee-offer__loading-card-details" })} />
        );
    }

    return (
        <div className={styles["create-employee-offer"]}>
            {employeeOfferCreating && <SpinnerWithMessage
                className={styles["create-employee-offer__loading-offer-details-modal"]}
            />}
            {cardDetailsError || currenciesLoadingError ? (
                <div className={styles["create-employee-offer__error-container"]}>
                    <FormattedMessage id="create-employee-offer__card-details-loading-error" />
                </div>
            ) : (
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnMount
                >
                    {({ values, errors, isValid, setFieldValue, handleSubmit, isSubmitting }) => (
                        <>
                            {(() => {
                                if (!!Object.keys(errors).length && !isValid && isSubmitting) {
                                    document
                                        .getElementById(Object.keys(errors)[0])
                                        ?.scrollIntoView({ behavior: "smooth", block: "end" });
                                }
                            })()}
                            <div className={styles["create-employee-offer__section"]}>
                                <div className={styles["create-employee-offer__header"]}>
                                    {isCopying ? (
                                        <FormattedMessage
                                            id="create-employee-offer-modal__copy-header"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="create-employee-offer-modal__header"
                                            values={{ stepNumber: 2 }}
                                        />
                                    )}
                                </div>
                                <div className={styles["create-employee-offer__title"]}>
                                    <FormattedMessage id="create-employee-offer__details-section-title" />
                                </div>
                                <DetailsStep
                                    uploadingImages={uploadingImages}
                                    setUploadingImages={setUploadingImages}
                                    initialPhotos={initialValues.detailsStep.photos}
                                    setDownloadPhotos={setDownloadPhotos}
                                    isCopyOrIsEdit={!!offerId || !!isCopying}
                                    values={values.detailsStep}
                                    changer={changer}
                                />
                                {uploadingImages && <p className={styles["create-employee-offer__loading"]} ><FormattedMessage id="create-offer__adding-images" /><Spinner /></p>}
                            </div>
                            <div className={styles["create-employee-offer__section"]}>
                                <div className={styles["create-employee-offer__title"]}>
                                    <FormattedMessage id="create-employee-offer__offer-details-section-title" />
                                </div>
                                <OfferDetailsStep values={values.offerDetailsStep} />
                            </div>
                            <div className={styles["create-employee-offer__section"]}>
                                <div className={styles["create-employee-offer__title"]}>
                                    <FormattedMessage id="create-employee-offer__finance-section-title" />
                                </div>
                                <FinancialStep values={values.financialStep} isEmployeeOffer disableBonus={!!initialValues.financialStep.bonusValue || (employeeOfferDetails?.offerStatus === 'ACTIVE' && !isCopying)} />
                            </div>
                            <div className={styles["create-employee-offer__section"]}>
                                <div className={styles["create-employee-offer__title"]}>
                                    <FormattedMessage id="create-employee-offer__requirements-section-title" />
                                </div>
                                <RequirementsStep offerType={OfferType.Employee} />
                            </div>
                            <div className={styles["create-employee-offer__section"]}>
                                <div className={styles["create-employee-offer__title"]}>
                                    <FormattedMessage id="create-employee-offer__amenities-section-title" />
                                </div>
                                <AmenitiesStep />
                            </div>
                            <div className={styles["create-employee-offer__section"]}>
                                <div className={styles["create-employee-offer__title"]}>
                                    <FormattedMessage id="create-employee-offer__offer-content-title" />
                                </div>
                                <TextEditor
                                    initialText={initialValues.additionalInfoStep.additionalInfo}
                                    handleChange={(value: string) => {
                                        setFieldValue("additionalInfoStep.additionalInfo", value);
                                    }}
                                    maxLength={EDITOR_CHARACTER_LIMIT}
                                    maxLengthTextComponent={
                                        <FieldMessages
                                            messages={[intl.formatMessage({ id: "create-offer__maximum-characters" }, { amount: EDITOR_CHARACTER_LIMIT })]}
                                            mode="error"
                                        />
                                    }
                                    additionalInfoTextComponent={
                                        <FieldMessages
                                            messages={[intl.formatMessage({ id: "create-offer__maximum-characters" }, { amount: EDITOR_CHARACTER_LIMIT })]}
                                            mode="info"
                                        />
                                    }
                                />
                            </div>
                            <div
                                className={classNames(styles["create-employee-offer__section"], styles["create-employee-offer__small-padding-section"])}
                            >
                                <div className={styles["create-employee-offer__buttons-section"]}>
                                    <Button
                                        variant="no-background"
                                        data-testid="create-employee-offer__cancel-button"
                                        onClick={handleCancel}
                                        disabled={employeeOfferCreating}
                                    >
                                        <FormattedMessage id="common__cancel" />
                                    </Button>

                                    {offerId && !isCopying ? (
                                        <Button
                                            type="submit"
                                            data-testid="create-employee-offer__next-button"
                                            onClick={() => handleEditEmployeeOffer(values)}
                                            disabled={employeeOfferCreating || uploadingImages}
                                            isLoading={employeeOfferCreating || uploadingImages}
                                        >
                                            <FormattedMessage id="common__save" />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            data-testid="create-employee-offer__next-button"
                                            onClick={() => handleSubmit()}
                                            disabled={employeeOfferCreating || uploadingImages}
                                            isLoading={employeeOfferCreating || uploadingImages}
                                        >
                                            <FormattedMessage id="common__next" />
                                        </Button>
                                    )}
                                </div>
                                {employeeOfferCreatingError && (
                                    <FieldMessages
                                        className={styles["create-employee-offer__error-messages"]}
                                        messages={[employeeOfferCreatingError?.response?.data?.message === "specified location was not found!"
                                            ? intl.formatMessage({ id: "create-offer__location-error" })
                                            : intl.formatMessage({ id: "create-employee-offer__unknown-error" })
                                        ]}
                                        mode="error"
                                    />
                                )}
                            </div>
                        </>
                    )}
                </Formik>
            )
            }
        </div >
    );
};

export default CreateOffer;
