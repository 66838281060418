import {selectCountries} from "features/common/selectors";
import {resetFenigePayment} from "features/jobOffers/actions";
import {
    selectFenigePaymentError,
    selectFenigePaymentStarted,
    selectFenigePaymentSuccess,
} from "features/jobOffers/selectors";
import {getFenigeIndicatorRequest, startFenigePaymentAsync,} from "features/jobOffers/thunks";
import {selectMyProfileCards, selectMyProfileCardsLoading} from "features/myProfile/selectors";
import {getMyProfileCardsAsync} from "features/myProfile/thunks";
import {selectUserId} from "features/user/selectors";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "store";
import PaymentComponent from "./component";

const mapStateToProps = (state: RootState) => ({
    userId: selectUserId(state),
    myProfileCards: selectMyProfileCards(state),
    myProfileCardsLoading: selectMyProfileCardsLoading(state),
    countries: selectCountries(state),
    fenigePaymentStarted: selectFenigePaymentStarted(state),
    fenigePaymentSuccess: selectFenigePaymentSuccess(state),
    fenigePaymentError: selectFenigePaymentError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            startFenigePaymentAsync,
            getMyProfileCardsAsync,
            getFenigeIndicatorRequest,
            resetFenigePayment: () => resetFenigePayment(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);
