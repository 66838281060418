import { ReactComponent as TrashIcon } from "assets/icons/bin.svg";
import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import { addMonths, format, isSameMonth, startOfMonth, subYears } from "date-fns";
import { pl } from "date-fns/locale";
import Button from "features/common/components/Button";
import Checkbox from "features/common/components/Checkbox";
import Input from "features/common/components/Input";
import TextArea from "features/common/components/TextArea";
import Datepicker from "features/common/components/Datepicker";
import { getLocalizedYup } from "features/common/validators";
import { EmployeeExperienceRequirement } from "features/myProfile/models";
import { employeeExperienceValidationSchema } from "features/myProfile/validators";
import { Formik, useField } from "formik";
/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { uuid } from "uuidv4";
import styles from "./styles.module.scss";

export interface Props {
    name: string;
    header?: string;
    label?: string;
}

const newItemValues: EmployeeExperienceRequirement = {
    id: "",
    companyName: "",
    position: "",
    startDate: undefined,
    endDate: undefined,
    date: undefined,
    isActive: false,
    additionalInfo: "",
};

const EmployeeExperienceRequirementComponent = ({ name }: Props) => {
    const intl = useIntl();
    const [isCreatingItem, setIsCreatingItem] = useState(false);
    const [isEditingItem, setIsEditingItem] = useState<string | undefined>();
    const [currentStartDate, setCurrentStartDate] = useState<Date | undefined>();
    const [currentEndDate, setCurrentEndDate] = useState<Date | undefined>();
    const [currentDate, setCurrentDate] = useState<Date | undefined>();
    const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>()


    const [fieldProps, , helperProps] = useField<EmployeeExperienceRequirement[]>(name);
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return employeeExperienceValidationSchema(localYup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const stopEditingItem = useCallback(() => {
        setIsEditingItem(undefined);
        setIsCreatingItem(false);
        setCurrentStartDate(undefined);
        setCurrentEndDate(undefined);
        setCurrentDate(undefined);
    }, [setIsEditingItem]);
    const createNewItem = useCallback(() => setIsCreatingItem(true), []);

    const handleSubmitItem = useCallback(
        (values) => {
            console.log(values)
            
            if (isCreatingItem) {
                helperProps.setValue([
                    ...(fieldProps.value || []),
                    {
                        ...values,
                        id: uuid(),
                    },
                ]);
                setIsCreatingItem(false);
            }

            if (isEditingItem) {
                const itemIndex = fieldProps.value.findIndex((item) => item.id === isEditingItem);

                if (itemIndex !== -1) {
                    const prevValue = [...fieldProps.value];
                    prevValue[itemIndex] = {
                        ...prevValue[itemIndex],
                        ...values,
                        id: isEditingItem,
                    };
                    helperProps.setValue(prevValue);
                    setIsEditingItem(undefined);
                }
            }

            setCurrentStartDate(undefined);
            setCurrentEndDate(undefined);
            setCurrentDate(undefined);
        },
        [fieldProps.value, helperProps, isCreatingItem, isEditingItem]
    );

    const handleDeleteItem = useCallback(
        (id: string) => {
            helperProps.setValue(fieldProps.value.filter((item) => item.id !== id));
        },
        [fieldProps.value, helperProps]
    );

    const handleEditItem = useCallback(
        (id: string) => {
            const values = fieldProps.value?.find((item) => item.id === id);
            setCurrentStartDate(values?.startDate);
            setCurrentEndDate(values?.endDate);
            setCurrentDate(values?.date);
            setIsEditingItem(id);
        },
        [fieldProps.value]
    );
 
    return (
        <div className={styles["employee-experience-requirement"]}>
            <div className={styles["employee-experience-requirement__header"]}>
                <span>
                    <FormattedMessage id="create-offer__experience-header" />
                </span>
                {isEditingItem || isCreatingItem ? (
                    <Button
                        variant="no-background"
                        className={styles["employee-experience-requirement__header-button"]}
                        onClick={stopEditingItem}
                    >
                        {" - "}
                        <FormattedMessage id="common__cancel" />
                    </Button>
                ) : (
                    <Button
                        variant="no-background"
                        className={styles["employee-experience-requirement__header-button"]}
                        onClick={createNewItem}
                    >
                        {" + "}
                        <FormattedMessage id="common__add-new" />
                    </Button>
                )}
            </div>
            {!!isEditingItem || !!isCreatingItem ? (
                <Formik
                    initialValues={
                        isEditingItem
                            ? fieldProps.value.find((item) => item.id === isEditingItem) || newItemValues
                            : newItemValues
                    }
                    validationSchema={validationSchema}
                    onSubmit={handleSubmitItem}
                    validateOnMount
                >
                    {({
                        values,
                        errors,
                        touched,
                        dirty,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <div className={styles["employee-experience-requirement__form"]}>
                            <div className={styles["employee-experience-requirement__field"]}>
                                <div className={styles["employee-experience-requirement__label"]}>
                                    <FormattedMessage id="card-details__experience-company-name" />
                                </div>
                                <Input
                                    data-testid="employee-experience-requirement__company-name"
                                    wrapperClassName={styles["employee-experience-requirement__input"]}
                                    name="companyName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.companyName}
                                    isInvalid={!!touched.companyName && !!errors.companyName}
                                    error={!!touched.companyName ? errors.companyName : undefined}
                                    withValidation
                                />
                            </div>
                            <div className={styles["employee-experience-requirement__field"]}>
                                <div className={styles["employee-experience-requirement__label"]}>
                                    <FormattedMessage id="card-details__experience-position" />
                                </div>
                                <Input
                                    data-testid="employee-experience-requirement__position"
                                    wrapperClassName={styles["employee-experience-requirement__input"]}
                                    name="position"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.position}
                                    isInvalid={!!touched.position && !!errors.position}
                                    error={!!touched.position ? errors.position : undefined}
                                    withValidation
                                />
                            </div>
                            <div className={styles["employee-experience-requirement__field"]}>
                                <div className={styles["employee-experience-requirement__label"]}>
                                    <FormattedMessage id="card-details__experience-period" />
                                </div>
                                <div className={styles["employee-experience-requirement__dates-container"]}>
                                <Datepicker
                                            startFromCurrentDate={false}
                                            endOnCurrentDate={true}
                                            id="employee-experience-requirement__start-date"
                                            data-testid="employee-experience-requirement__start-date"
                                            className={styles["employee-experience-requirement__date-container"]}
                                            date={values.date}
                                            onDateSelect={(date?) => {
                                                setFieldValue("date", date);
                                                setFieldValue("startDate", date);
                                                setCurrentDate(date);
                                                setCurrentStartDate(date);
                                                setSelectedStartDate(date);
                                            }}
                                            isInvalid={!!errors.startDate}
                                            error={errors.startDate}
                                            addForwardYears
                                        />
                                    <div className={styles["employee-experience-requirement__period-separator"]}>-</div>
                                    <div className={values.isActive ? styles["employee-experience-requirement__end-date-container-blocked"] : styles["employee-experience-requirement__end-date-container"]}>
                                        <Datepicker
                                            startFromCurrentDate={false}
                                            startFromPickedDate={true}
                                            selectedStartDate={selectedStartDate}
                                            id="employee-experience-requirement__end-date"
                                            data-testid="employee-experience-requirement__end-date"
                                            className={styles["employee-experience-requirement__date-container"]}
                                            date={values.endDate}
                                            onDateSelect={(endDate?) => {
                                                setFieldValue("endDate", endDate);
                                                setCurrentEndDate(endDate);
                                            }}
                                            
                                            isInvalid={!!errors.endDate}
                                            error={errors.endDate ? intl.formatMessage({ id: "card-details__employee-date-invalid" }) : undefined}
                                            addForwardYears
                                        />
                                    </div>
                                    <Checkbox
                                            data-testid="employee-experience-requirement__is-active-checkbox"
                                            className={styles["employee-experience-requirement__checkbox"]}
                                            label={
                                                <FormattedMessage id="card-details__employee-experience-is-active" />
                                            }
                                            checked={values.isActive}
                                            onToggle={(checked) => setFieldValue("isActive", checked)}
                                        />
                                </div>
                            </div>
                            <div className={styles["employee-experience-requirement__additional-info-field"]}>
                                <div className={styles["employee-experience-requirement__additional-info-label"]}>
                                    <FormattedMessage id="card-details__experience-additional-info" />
                                </div>
                                <TextArea
                                    data-testid="employee-experience-requirement__additional-info"
                                    className={styles["employee-experience-requirement__additional-info-input"]}
                                    name="additionalInfo"
                                    placeholder={intl.formatMessage({ id: "card-details__additional-info-label" })}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.additionalInfo}
                                    isInvalid={!!touched.additionalInfo && !!errors.additionalInfo}
                                    error={!!touched.additionalInfo ? errors.additionalInfo : undefined}
                                    withValidation
                                    maxSize={250}
                                    rows={10}
                                />
                            </div>
                            <div className={styles["employee-experience-requirement__buttons"]}>
                                <Button
                                    variant="tertiary"
                                    data-testid="employee-experience-requirement__cancel-button"
                                    onClick={stopEditingItem}
                                >
                                    <FormattedMessage id="common__cancel" />
                                </Button>
                                <Button
                                    type="submit"
                                    data-testid="employee-experience-requirement__save-button"
                                    onClick={() => handleSubmit()}
                                    disabled={!isValid}
                                >
                                    <FormattedMessage id="common__save" />
                                </Button>
                            </div>
                        </div>
                    )}
                </Formik>
            ) : (
                !!fieldProps.value?.length && (
                    <>
                        <div className={styles["employee-experience-requirement__separator"]} />
                        {fieldProps.value.map((experience) => (
                            <div className={styles["employee-experience-requirement__item"]}>
                                <div className={styles["employee-experience-requirement__item--dot"]} />
                                <div className={styles["employee-experience-requirement__item--container"]}>
                                    <div className={styles["employee-experience-requirement__item--header"]}>
                                        <span>
                                            {experience.companyName} / {experience.position}
                                        </span>
                                        <div className={styles["employee-experience-requirement__item--buttons"]}>
                                            <Button
                                                variant="no-background"
                                                className={
                                                    styles["employee-experience-requirement__item--delete-button"]
                                                }
                                                onClick={() => handleDeleteItem(experience.id)}
                                            >
                                                <TrashIcon />
                                            </Button>
                                            <Button
                                                variant="no-background"
                                                className={styles["employee-experience-requirement__item--edit-button"]}
                                                onClick={() => handleEditItem(experience.id)}
                                            >
                                                <PenIcon />
                                                <FormattedMessage id="common__change" />
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={styles["employee-experience-requirement__item--dates"]}>
                                        {format(experience.startDate as Date, "LLLL yyyy", { locale: pl })}
                                        {" - "}
                                        {experience.isActive
                                            ? intl.formatMessage({ id: "card-details__experience-active" })
                                            : format(experience.endDate as Date, "LLLL yyyy", { locale: pl })}
                                    </div>
                                    <div className={styles["employee-experience-requirement__item--info"]}>
                                        {experience.additionalInfo}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )
            )}
        </div>
    );
};

export default EmployeeExperienceRequirementComponent;
