import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ApiError, CancelToken } from "api/utils";
import Button from "features/common/components/Button";
import Modal from "features/common/components/Modal";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TextArea from "features/common/components/TextArea";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getSendMessageInformationMessage } from "features/offers/translationMessages";
import JobOffersApi from "features/jobOffers/api";

import styles from "./styles.module.scss";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import { ReactComponent as CloseIcon } from "assets/icons/closeIcon.svg";
import { ReactComponent as ApprovedCheckmark } from "assets/icons/approvedCheckmark.svg";
import { ReactComponent as DenyIcon } from "assets/icons/denyIcon.svg";

const maxMessageLength = 250;

export interface Props {
  id: number;
  giveBonusLoading: boolean;
  giveBonusSuccess?: boolean;
  giveBonusError?: ApiError;
  giveBonusAsync: (id: number, message?: string, cancelToken?: CancelToken) => void;
  handleCloseModal: () => void;
  handleCloseModalAfterTransaction: () => void;
}

const GiveBonusModal = ({
  id,
  giveBonusError,
  giveBonusAsync,
  handleCloseModal,
  handleCloseModalAfterTransaction
}: Props) => {
  const intl = useIntl();
  const createCancelToken = useCreateCancelToken();
  const [messageValue, setMessageValue] = useState("");
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [getBonusApproved, setGetBonusApproved] = useState(false);
  const [getBonusDenied, setGetBonusDenied] = useState(false);

  const onMessageChange = useCallback((event) => setMessageValue(event.currentTarget.value), [setMessageValue]);

  const getResponse = async () => {
    const response = await JobOffersApi.getPaidBonusStatus(id);

    if (response === "SUCCESSFUL") {
      setIsTransactionLoading(false);
      setGetBonusApproved(true);
    }

    if (response === 'LAST_TRANSACTION_FAILED') {
      setIsTransactionLoading(false);
      setGetBonusDenied(true)
    }
  };

  const handleSubmit = useCallback(() => {
    setIsTransactionLoading(true);
    giveBonusAsync(id, messageValue, createCancelToken());
    setIsTransactionLoading(true);
    getResponse()

    setInterval(() => {
      getResponse();
    }, 15000);
  }, [giveBonusAsync, createCancelToken, id, messageValue]);

  const sendMessageInformation = getSendMessageInformationMessage(intl, maxMessageLength);

  return (
    <Modal className={styles["give-bonus-modal"]} parentId="give-bonus-modal">
      <>
        <div className={styles["give-bonus-modal__container"]}>
          <div className={styles["give-bonus-modal__close-button"]} onClick={handleCloseModal}>
            <TimesIcon />
          </div>
          <div className={styles["give-bonus-modal__title"]}>
            <FormattedMessage id="give-bonus-modal__title" />
          </div>
          <div className={styles["give-bonus-modal__subtitle"]}>
            <FormattedMessage id="give-bonus-modal__subtitle" />
          </div>
          <TextArea
            maxSize={maxMessageLength}
            placeholder={intl.formatMessage({
              id: "give-bonus-modal__message-placeholder",
            })}
            value={messageValue}
            error={
              !!giveBonusError ? intl.formatMessage({ id: "give-bonus-modal__acceptation-error" }) : ""
            }
            onChange={onMessageChange}
            withValidation
            isInvalid={!!giveBonusError}
            data-testid="give-bonus-modal__message-modal"
            className={styles["give-bonus-modal__text-area"]}
            customWarningMessage={sendMessageInformation}
          />
          <div className={styles["give-bonus-modal__buttons-container"]}>
            <Button
              data-testid="give-bonus-modal__button"
              className={styles["give-bonus-modal__button"]}
              type="submit"
              variant="tertiary"
              onClick={handleCloseModal}
            >
              <FormattedMessage id="give-bonus-modal__cancel" />
            </Button>
            <Button
              data-testid="give-bonus-modal__button"
              className={styles["give-bonus-modal__button"]}
              type="submit"
              onClick={handleSubmit}
              disabled={isTransactionLoading}
            >
              <FormattedMessage id="give-bonus-modal__accept" />
            </Button>
          </div>
        </div>

        {getBonusApproved && (
          <div className={styles["give-bonus-modal__overlay-dark"]}>
            <div className={styles["give-bonus-modal-approved"]}>
              <div className={styles["give-bonus-modal-approved__container"]}>
                <CloseIcon
                  onClick={handleCloseModalAfterTransaction}
                  className={styles["give-bonus-modal-approved__close-button"]}
                />
                <div className={styles["give-bonus-modal-approved__title"]}>
                  <ApprovedCheckmark className={styles["give-bonus-modal-approved__checkmark"]} />
                  <FormattedMessage id="give-bonus-modal-approved__title" />
                </div>
              </div>
            </div>
          </div>
        )}

        {getBonusDenied && (
          <div className={styles["give-bonus-modal__overlay-dark"]}>
            <div className={styles["give-bonus-modal-denied"]}>
              <div className={styles["give-bonus-modal-denied__container"]}>
                <CloseIcon
                  onClick={handleCloseModalAfterTransaction}
                  className={styles["give-bonus-modal-denied__close-button"]}
                />
                <div className={styles["give-bonus-modal-denied__title"]}>
                  <DenyIcon className={styles["give-bonus-modal-denied__forbidden-symbol"]} />
                  <FormattedMessage id="give-bonus-modal-denied__title" />
                </div>
                <div className={styles["give-bonus-modal-denied__subtitle"]}>
                  <FormattedMessage id="give-bonus-modal-denied__subtitle" />
                </div>
              </div>
            </div>
          </div>
        )}

        {isTransactionLoading && (
          <div className={styles["give-bonus-modal__overlay-dark"]}>
            <SpinnerWithMessage message={intl.formatMessage({ id: 'give-bonus-modal__loading-give-bonus' })} className={styles["give-bonus-modal__spinner"]} />
          </div>
        )}
      </>
    </Modal>
  );
};

export default GiveBonusModal;
