import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import FavoriteNavElement from "../FavoriteNavElement";

interface FavoriteHeaderNavigationProps {
    setActive: React.Dispatch<React.SetStateAction<string | null>>
    active: string;
    onClick: () => void;
}

export default function FavoriteHeaderNavigation({ setActive, active, onClick }: FavoriteHeaderNavigationProps) {
    const intl = useIntl();

    return (
        <StyledWrapper>
            <StyledInner>
                <StyledHeader>
                    <FormattedMessage id='common__favourite' />
                </StyledHeader>
                <NavElementsWrapper>
                    <FavoriteNavElement title={intl.formatMessage({ id: 'common__job-offers' })} onClick={onClick} active={active === '1'} setActive={setActive} index='1' />
                    <FavoriteNavElement title={intl.formatMessage({ id: 'common__employee-offers' })} onClick={onClick} active={active === '2'} setActive={setActive} index='2' />
                    <FavoriteNavElement title={intl.formatMessage({ id: 'common__employer' })} onClick={onClick} active={active === '4'} setActive={setActive} index='4' />
                    <FavoriteNavElement title={intl.formatMessage({ id: 'common__employee' })} onClick={onClick} active={active === '3'} setActive={setActive} index='3' />
                </NavElementsWrapper>
            </StyledInner>
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    background-color: #fff;
    padding-top: 2rem;
    box-shadow: 4px 4px 15px rgb(0 38 119 / 25%);

    @media screen and (min-width: 1440px){
        margin-bottom: 50px;
    }
`;

const StyledInner = styled.div`
    width: 90%;
    margin: 0 auto;
`;

const StyledHeader = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;

    @media screen and (min-width: 834px){
      text-align: left;
    }
`;

const NavElementsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    margin: 3rem auto 0 0;

    @media screen and (min-width: 834px){
        width: 85%;
        flex-direction: row;
    }
`;
