import { IntlShape } from "react-intl";

export const getRecentFirstMessage = (intl: IntlShape) => intl.formatMessage({ id: "my-messages__recent-first" });

export const getOldestFirstMessage = (intl: IntlShape) => intl.formatMessage({ id: "my-messages__oldest-first" });

export const getMyMessagesTitle = (intl: IntlShape) => intl.formatMessage({ id: "common__my-messages" });

export const getTypeMessagePlaceholder = (intl: IntlShape) =>
    intl.formatMessage({ id: "my-messages-thread__type-message" });

export const getMeMessage = (intl: IntlShape) => intl.formatMessage({ id: "my-messages-thread__me" });

export const getOnlyUnreadMessage = (intl: IntlShape) => intl.formatMessage({ id: "my-messages__only-unread" });
