import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

interface Props {
    children: string | React.ReactNode;
    className?: string;
}

const PageHeaderBox = ({ children, className }: Props) => {
    return (
        <div className={classNames(styles["page-header-box"], className)}>
            <span className={styles["page-header-box__title"]}>{children}</span>
        </div>
    );
};

export default PageHeaderBox;
