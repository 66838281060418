import {
    selectAcceptApplicationLoading,
    selectAcceptApplicationSuccess,
    selectAcceptApplicationError,
} from "features/jobOffers/selectors";
import { acceptApplicationAsync } from "features/jobOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import AcceptApplicationModal from "./component";

const mapStateToProps = (state: RootState) => ({
    acceptApplicationLoading: selectAcceptApplicationLoading(state),
    acceptApplicationSuccess: selectAcceptApplicationSuccess(state),
    acceptApplicationError: selectAcceptApplicationError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            acceptApplicationAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AcceptApplicationModal);
