import adminPanel from "features/adminPanel/reducers";
import common from "features/common/reducers";
import contactForm from "features/contactForm/reducers";
import employeeOffers from "features/employeeOffers/reducers";
import getCall from "features/getCall/reducers";
import offers from "features/jobOffers/reducers";
import messages from "features/messages/reducers";
import moderatorPanel from "features/moderatorPanel/reducers";
import myProfile from "features/myProfile/reducers";
import rating from "features/rating/reducers";
import user from "features/user/reducers";
import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";

const rootReducer = combineReducers({
    common,
    user,
    offers,
    employeeOffers,
    myProfile,
    contactForm,
    messages,
    adminPanel,
    rating,
    moderatorPanel,
    getCall
});

export type RootState = StateType<typeof rootReducer>;
export default rootReducer;
