import styled from "styled-components/macro";

const Headline = styled.h1`
  margin: 1.5rem 0;
`;

interface ISubheadline {
  offersTitle?: boolean;
}

const Subheadline = styled.h2<ISubheadline>`
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;

  @media screen and (min-width: 834px) {
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
    
  ${({offersTitle}) => offersTitle && `
     margin-bottom: 0;

    @media screen and (min-width: 834px) {
      text-align: left;
      padding-left: 1.625rem;
      margin-top: 0;
      margin-bottom: 0;
    };

    @media screen and (min-width: 1440px) {
      padding-left: 3.5rem;
    };
  `}

`;

export { Headline, Subheadline };
