import { createSelector } from "reselect";
import { RootState } from "store";

const selectState = (state: RootState) => state.rating;

export const selectEmployerRatingLoading = createSelector(selectState, (state) => state.employerRatingLoading);
export const selectRateEmployerSuccess = createSelector(selectState, (state) => state.rateEmployerSuccess);
export const selectRatingEmployerError = createSelector(selectState, (state) => state.ratingEmployerError);

export const selectEmployeeRatingLoading = createSelector(selectState, (state) => state.employeeRatingLoading);
export const selectRateEmployeeSuccess = createSelector(selectState, (state) => state.rateEmployeeSuccess);
export const selectRatingEmployeeError = createSelector(selectState, (state) => state.ratingEmployeeError);


export const selectEmployeeRatingSummaryLoading = createSelector(selectState, (state) => state.employeeRatingSummaryLoading);
export const selectEmployeeRatingSummarySuccess = createSelector(selectState, (state) => state.employeeRatingSummarySuccess);
export const selectEmployeeRatingSummaryError = createSelector(selectState, (state) => state.employeeRatingSummaryError);
export const selectEmployeeRatingSummaryData = createSelector(selectState, (state) => state.employeeRatingSummaryData);

export const selectEmployerRatingSummaryLoading = createSelector(selectState, (state) => state.employerRatingSummaryLoading);
export const selectEmployerRatingSummarySuccess = createSelector(selectState, (state) => state.employerRatingSummarySuccess);
export const selectEmployerRatingSummaryError = createSelector(selectState, (state) => state.employerRatingSummaryError);
export const selectEmployerRatingSummaryData = createSelector(selectState, (state) => state.employerRatingSummaryData);
