import { ApiError } from "api/utils";
import { AxiosError } from "axios";
import { Card } from "features/common/types";
import MyProfileAction, {
    createNewCard,
    deleteCard,
    deleteMyAccount,
    getCardDetails,
    getMyApplications,
    getMyProfileCards,
    getOfferCreationCards,
    getPaymentCardData,
    getRelatedOffers,
    refreshCardDetails,
    resetMyProfileCardCreated,
    updateMyProfileCard,
    updateUserDetails,
    updateUserPicture,
} from "features/myProfile/actions";
import { PaymentCardDto } from "features/myProfile/dtos";
import {
    mapCardDetailsDtoToCard,
    mapCardsAfterDelete,
    mapMyApplicationsDtoToApplicationsList,
    mapMyProfileCardsDtoToMyOfferCreationCards,
    mapOfferCreationCardsDtoToMyOfferCreationCards,
    mapRelatedOffersDtoToRelatedOffers,
} from "features/myProfile/mappers";
import { Application, MyOfferCreationCards, MyProfileCards } from "features/myProfile/models";
import { CardRelatedOffer } from "features/myProfile/types";
import produce from "immer";
import { getType } from "typesafe-actions";

export interface MyProfileState {
    offerCreationCards?: MyOfferCreationCards;
    offerCreationCardsLoading: boolean;
    offerCreationCardsLoadingError?: ApiError;
    myProfileCards?: MyProfileCards;
    myProfileCardsLoading: boolean;
    myProfileCardsLoadingError?: ApiError;
    userDetailsUpdating: boolean;
    userDetailsUpdated: boolean;
    userDetailsUpdatingError?: ApiError;
    userProfilePictureUploading: boolean;
    userProfilePictureUploaded: boolean;
    userProfilePictureUploadingError?: ApiError;
    createNewCardLoading: boolean;
    createNewCardError?: ApiError;
    deleteCardError?: AxiosError;
    deleteMyAccountError?: any;//AxiosError;
    createNewCardSuccess?: string;
    cardDetails?: Card;
    cardDetailsLoading: boolean;
    cardDetailsLoadingError?: ApiError;
    myProfileCardDetailsUpdating: boolean;
    myProfileCardDetailsUpdated: boolean;
    myProfileCardDetailsUpdatingError?: ApiError;
    countRelatedOffers?: number;
    relatedOffers?: CardRelatedOffer[];
    relatedOffersLoading: boolean;
    relatedOffersLoadingError?: ApiError;
    myApplications?: Application[];
    myApplicationsLoading: boolean;
    myApplicationsError?: ApiError;
    paymentCardData?: PaymentCardDto;
    paymentCardDataLoading: boolean;
    paymentCardDataError?: ApiError;
}

export const initialMyProfileState = {
    // deleteMyAccount: false,
    offerCreationCardsLoading: false,
    myProfileCardsLoading: false,
    userDetailsUpdated: false,
    userDetailsUpdating: false,
    userProfilePictureUploaded: false,
    userProfilePictureUploading: false,
    createNewCardLoading: false,
    cardDetailsLoading: false,
    myProfileCardDetailsUpdating: false,
    myProfileCardDetailsUpdated: false,
    relatedOffersLoading: false,
    countRelatedOffersLoading: false,
    myApplicationsLoading: false,
    paymentCardDataLoading: false,
};

const reducer = (state: MyProfileState = initialMyProfileState, action: MyProfileAction) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getOfferCreationCards.request):
                draft.offerCreationCardsLoading = true;
                draft.offerCreationCardsLoadingError = undefined;
                draft.offerCreationCards = undefined;
                draft.createNewCardSuccess = undefined;
                break;
            case getType(getOfferCreationCards.success):
                draft.offerCreationCards = mapOfferCreationCardsDtoToMyOfferCreationCards(action.payload);
                draft.offerCreationCardsLoading = false;
                draft.offerCreationCardsLoadingError = undefined;
                break;
            case getType(getOfferCreationCards.failure):
                draft.offerCreationCards = undefined;
                draft.offerCreationCardsLoading = false;
                draft.offerCreationCardsLoadingError = action.payload;
                break;
            case getType(getMyProfileCards.request):
                draft.myProfileCardsLoading = true;
                draft.myProfileCardsLoadingError = undefined;
                draft.myProfileCards = undefined;
                draft.createNewCardError = undefined;
                draft.deleteCardError = undefined;
                break;
            case getType(getMyProfileCards.success):
                draft.myProfileCards = mapMyProfileCardsDtoToMyOfferCreationCards(action.payload);
                draft.myProfileCardsLoading = false;
                draft.myProfileCardsLoadingError = undefined;
                draft.createNewCardError = undefined;
                draft.deleteCardError = undefined;
                break;
            case getType(getMyProfileCards.failure):
                draft.myProfileCards = undefined;
                draft.myProfileCardsLoading = false;
                draft.myProfileCardsLoadingError = action.payload;
                draft.createNewCardError = undefined;
                break;
            case getType(updateUserDetails.request):
                draft.userDetailsUpdating = true;
                draft.userDetailsUpdated = false;
                draft.userDetailsUpdatingError = undefined;
                break;
            case getType(updateUserDetails.success):
                draft.userDetailsUpdating = false;
                draft.userDetailsUpdated = true;
                draft.userDetailsUpdatingError = undefined;
                break;
            case getType(updateUserDetails.failure):
                draft.userDetailsUpdating = false;
                draft.userDetailsUpdated = false;
                draft.userDetailsUpdatingError = action.payload;
                break;
            case getType(updateUserPicture.request):
                draft.userProfilePictureUploading = true;
                draft.userProfilePictureUploaded = false;
                draft.userProfilePictureUploadingError = undefined;
                break;
            case getType(updateUserPicture.success):
                draft.userProfilePictureUploading = false;
                draft.userProfilePictureUploaded = true;
                draft.userProfilePictureUploadingError = undefined;
                break;
            case getType(updateUserPicture.failure):
                draft.userProfilePictureUploading = false;
                draft.userProfilePictureUploaded = false;
                draft.userProfilePictureUploadingError = action.payload;
                break;
            case getType(createNewCard.request):
                draft.createNewCardLoading = true;
                draft.createNewCardSuccess = undefined;
                draft.createNewCardError = undefined;
                break;
            case getType(createNewCard.success):
                draft.createNewCardLoading = false;
                draft.createNewCardSuccess = action.payload?.data?.id;
                draft.createNewCardError = undefined;
                break;
            case getType(createNewCard.failure):
                draft.createNewCardLoading = false;
                draft.createNewCardSuccess = undefined;
                draft.createNewCardError = action.payload;
                break;
            case getType(getCardDetails.request):
                draft.cardDetails = undefined;
                draft.cardDetailsLoading = true;
                draft.cardDetailsLoadingError = undefined;
                draft.createNewCardSuccess = undefined;
                draft.createNewCardError = undefined;
                break;
            case getType(getCardDetails.success):
                draft.cardDetailsLoading = false;
                draft.cardDetails = mapCardDetailsDtoToCard(action.payload);
                draft.cardDetailsLoadingError = undefined;
                break;
            case getType(getCardDetails.failure):
                draft.cardDetailsLoading = false;
                draft.cardDetails = undefined;
                draft.cardDetailsLoadingError = action.payload;
                break;
            case getType(updateMyProfileCard.request):
                draft.myProfileCardDetailsUpdating = true;
                draft.myProfileCardDetailsUpdated = false;
                draft.myProfileCardDetailsUpdatingError = undefined;
                break;
            case getType(updateMyProfileCard.success):
                draft.myProfileCardDetailsUpdating = false;
                draft.myProfileCardDetailsUpdated = true;
                draft.cardDetails = mapCardDetailsDtoToCard(action.payload);
                draft.myProfileCardDetailsUpdatingError = undefined;
                break;
            case getType(updateMyProfileCard.failure):
                draft.myProfileCardDetailsUpdating = false;
                draft.myProfileCardDetailsUpdated = false;
                draft.myProfileCardDetailsUpdatingError = action.payload;
                break;
            case getType(refreshCardDetails):
                draft.cardDetails = { ...(draft.cardDetails as Card), ...mapCardDetailsDtoToCard(action.payload) };
                draft.myProfileCardDetailsUpdated = false;
                break;
            case getType(getRelatedOffers.request):
                draft.relatedOffers = undefined;
                draft.relatedOffersLoading = true;
                draft.relatedOffersLoadingError = undefined;
                break;
            case getType(getRelatedOffers.success):
                draft.relatedOffers = mapRelatedOffersDtoToRelatedOffers(action.payload);
                draft.countRelatedOffers = action.payload.data.total;
                draft.relatedOffersLoading = false;
                draft.relatedOffersLoadingError = undefined;
                break;
            case getType(getRelatedOffers.failure):
                draft.relatedOffers = undefined;
                draft.relatedOffersLoading = false;
                draft.relatedOffersLoadingError = action.payload;
                break;
            case getType(getMyApplications.request):
                draft.myApplicationsLoading = true;
                draft.myApplications = undefined;
                draft.myApplicationsError = undefined;
                break;
            case getType(getMyApplications.success):
                draft.myApplicationsLoading = false;
                draft.myApplications = mapMyApplicationsDtoToApplicationsList(action.payload);
                draft.myApplicationsError = undefined;
                break;
            case getType(getMyApplications.failure):
                draft.myApplicationsLoading = false;
                draft.myApplications = undefined;
                draft.myApplicationsError = action.payload;
                break;
            case getType(resetMyProfileCardCreated):
                draft.createNewCardLoading = false;
                draft.createNewCardSuccess = undefined;
                draft.createNewCardError = undefined;
                break;
            case getType(getPaymentCardData.request):
                draft.paymentCardData = undefined;
                draft.paymentCardDataLoading = true;
                draft.paymentCardDataError = undefined;
                break;
            case getType(getPaymentCardData.success):
                draft.paymentCardData = action.payload;
                draft.paymentCardDataLoading = false;
                draft.paymentCardDataError = undefined;
                break;
            case getType(getPaymentCardData.failure):
                draft.paymentCardData = undefined;
                draft.paymentCardDataLoading = false;
                draft.paymentCardDataError = action.payload;
                break;
            case getType(deleteCard.request):
                draft.myProfileCardsLoading = true;
                draft.myProfileCardsLoadingError = undefined;
                draft.deleteCardError = undefined;
                break;
            case getType(deleteCard.success):
                draft.deleteCardError = undefined;
                draft.myProfileCardsLoading = false;
                draft.myProfileCardsLoadingError = undefined;
                draft.myProfileCards = mapCardsAfterDelete(draft.myProfileCards, action.payload.objectIdToDelete)
                break;
            case getType(deleteCard.failure):
                draft.deleteCardError = action.payload;
                draft.myProfileCardsLoading = false;
                draft.myProfileCardsLoadingError = undefined;
                break;
            case getType(deleteMyAccount.success):
                draft.deleteMyAccountError = false;
                break;
            case getType(deleteMyAccount.failure):
                draft.deleteMyAccountError = true;
                break;

        }
    });
};

export default reducer;
