import { ApiError } from "api/utils";
import { UserDetailsDto, LoginUserDto, RegisterUserDto } from "features/user/dtos";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const getUser = createAsyncAction("GET_USER_REQUEST", "GET_USER_SUCCESS", "GET_USER_FAILURE")<
    void,
    UserDetailsDto,
    ApiError
>();

export const loginUser = createAsyncAction("LOGIN_USER_REQUEST", "LOGIN_USER_SUCCESS", "LOGIN_USER_FAILURE")<
    void,
    LoginUserDto,
    ApiError
>();

export const forgottenPassword = createAsyncAction(
    "FORGOTTEN_PASSWORD_REQUEST",
    "FORGOTTEN_PASSWORD_SUCCESS",
    "FORGOTTEN_PASSWORD_FAILURE"
)<void, any, ApiError>();

export const recoverPassword = createAsyncAction(
    "RECOVER_PASSWORD_REQUEST",
    "RECOVER_PASSWORD_SUCCESS",
    "RECOVER_PASSWORD_FAILURE"
)<void, any, ApiError>();

export const registerUser = createAsyncAction(
    "REGISTER_USER_REQUEST",
    "REGISTER_USER_SUCCESS",
    "REGISTER_USER_FAILURE"
)<void, RegisterUserDto, ApiError>();

export const resendActivationEmail = createAsyncAction(
    "RESEND_ACTIVATION_EMAIL_REQUEST",
    "RESEND_ACTIVATION_EMAIL_SUCCESS",
    "RESEND_ACTIVATION_EMAIL_FAILURE"
)<void, void, ApiError>();

export const cancelAuthTokens = createAsyncAction(
    "CANCEL_AUTH_TOKEN_REQUEST",
    "CANCEL_AUTH_TOKEN_SUCCESS",
    "CANCEL_AUTH_TOKEN_FAILURE"
)<void, void, ApiError>();

export const validateUserToken = createAsyncAction(
    "VALIDATE_USER_TOKEN_REQUEST",
    "VALIDATE_USER_TOKEN_SUCCESS",
    "VALIDATE_USER_TOKEN_FAILURE"
)<void, boolean, ApiError>();

export const createUserProfile = createAsyncAction(
    "CREATE_USER_PROFILE_REQUEST",
    "CREATE_USER_PROFILE_SUCCESS",
    "CREATE_USER_PROFILE_FAILURE"
)<void, void, ApiError>();

export const getUserProfile = createAsyncAction(
    "GET_USER_PROFILE_REQUEST",
    "GET_USER_PROFILE_SUCCESS",
    "GET_USER_PROFILE_FAILURE"
)<void, UserDetailsDto, ApiError>();

export const setUserLoggedInState = createAction("SET_USER_IS_LOGGED_IN")<boolean>();

export const resetUserProfileCreated = createAction("RESET_USER_PROFILE_CREATED")<void>();

export const forgottenPasswordreset = createAction("RESET_USER_PASSWORD")<void>();

export const refreshUserProfile = createAction("REFRESH_USER_PROFILE")<UserDetailsDto>();

export const logoutUser = createAsyncAction("LOGOUT_USER_REQUEST", "LOGOUT_USER_SUCCESS", "LOGOUT_USER_FAILURE")<
    void,
    void,
    ApiError
>();

export const verifyUser = createAsyncAction("VERIFY_USER_REQUEST", "VERIFY_USER_SUCCESS", "VERIFY_USER_FAILURE")<
    void,
    void,
    ApiError
>();

type UserAction =
    | ActionType<typeof validateUserToken>
    | ActionType<typeof forgottenPasswordreset>
    | ActionType<typeof getUser>
    | ActionType<typeof createUserProfile>
    | ActionType<typeof getUserProfile>
    | ActionType<typeof cancelAuthTokens>
    | ActionType<typeof setUserLoggedInState>
    | ActionType<typeof refreshUserProfile>
    | ActionType<typeof resetUserProfileCreated>
    | ActionType<typeof loginUser>
    | ActionType<typeof registerUser>
    | ActionType<typeof resendActivationEmail>
    | ActionType<typeof logoutUser>
    | ActionType<typeof verifyUser>
    | ActionType<typeof recoverPassword>
    | ActionType<typeof forgottenPassword>;

export default UserAction;
