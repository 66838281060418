import React from "react";
import { useIntl } from "react-intl";
import { OfferType } from "features/common/types";
import { createEmployeeOfferFieldNames } from "features/employeeOffers/constants";
import { createJobOfferFieldNames, drivingOptions, createExperienceOptions } from "features/jobOffers/constants";
import { useCreateJobOfferField } from "features/jobOffers/hooks";
import {
    getRequirementStepDrivingLicenseHeader,
    getRequirementStepEducationHeader,
    getRequirementStepExperienceHeader,
    getRequirementStepLanguagesHeader,
    getRequirementStepLanguagesLabel,
    getRequirementStepLicensesAndCertificatesHeader,
    getRequirementStepLicensesAndCertificatesLabel,
    getRequirementStepOtherHeader,
    getRequirementStepOtherLabel,
    getRequirementStepPermissionsHeader,
    getRequirementStepPermissionsLabel,
    getRequirementStepProgramsHeader,
    getRequirementStepProgramsLabel,
} from "features/jobOffers/translationMessages";
import { Education, Language } from "features/jobOffers/types";
import EmployeeExperienceRequirement from "features/myProfile/components/CardDetails/RequirementsSection/EmployeeExperienceRequirement";
import styles from "./styles.module.scss";
import CheckBoxRequirement from "./CheckBoxRequirement";
import LanguageRequirement from "./LanguageRequirement";
import EducationRequirement from "./EducationRequirement";
import CreatableRequirement from "./CreatableRequirement";

export interface Props {
    offerType: OfferType;
}

const RequirementsStep = ({ offerType }: Props) => {
    const intl = useIntl();

    const [experienceField, experienceMetaProps, experienceHelperProps] = useCreateJobOfferField<string[]>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.experience
    );

    const [languagesField, languagesFieldMeta, languagesHelperProps] = useCreateJobOfferField<Language[]>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.languages
    );

    const [educationField, educationFieldMeta, educationHelperProps] = useCreateJobOfferField<Education[]>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.education
    );

    const [permissionsField, permissionsMetaProps, permissionsHelperProps] = useCreateJobOfferField<string>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.permissions
    );

    const [certificatesField, certificateMetaProps, certificatesHelperProps] = useCreateJobOfferField<string>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.certificates
    );
    const [drivingLicenseField, , drivingLicenseHelperProps] = useCreateJobOfferField<string[]>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.drivingLicenses
    );

    const [programsField, programsMetaProps, programsHelperProps] = useCreateJobOfferField<string>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.programs
    );

    const [otherField, otherMetaProps, otherHelperProps] = useCreateJobOfferField<string>(
        "requirementsStep",
        createJobOfferFieldNames.requirementsStep.other
    );

    const licenseHeader = getRequirementStepLicensesAndCertificatesHeader(intl);
    const licenseLabel = getRequirementStepLicensesAndCertificatesLabel(intl);
    const programsHeader = getRequirementStepProgramsHeader(intl);
    const programsLabel = getRequirementStepProgramsLabel(intl);
    const otherHeader = getRequirementStepOtherHeader(intl);
    const otherLabel = getRequirementStepOtherLabel(intl);
    const experienceHeader = getRequirementStepExperienceHeader(intl);
    const experienceOptions = createExperienceOptions(intl);
    const permissionsHeader = getRequirementStepPermissionsHeader(intl);
    const permissionsLabel = getRequirementStepPermissionsLabel(intl);
    const educationHeader = getRequirementStepEducationHeader(intl);
    const drivingLicenseHeader = getRequirementStepDrivingLicenseHeader(intl);
    const languagesHeader = getRequirementStepLanguagesHeader(intl);
    const languagesLabel = getRequirementStepLanguagesLabel(intl);

    return (
        <div className={styles["requirements-step"]}>
            <div className={styles["requirements-step__input-container"]}>
                <div className={styles["requirements-step__input-left-container"]}>
                    {offerType === OfferType.Employee ? (
                        <EmployeeExperienceRequirement
                            name={`requirementsStep.${createEmployeeOfferFieldNames.requirementsStep.employeeExperience}`}
                        />
                    ) : (
                        <CheckBoxRequirement
                            data-testid="requirements-step__experience"
                            header={experienceHeader}
                            options={experienceOptions}
                            valueInputProps={experienceField}
                            helperProps={experienceHelperProps}
                            hasColumns={false}
                            singleValueOnly
                        />
                    )}
                    <EducationRequirement
                        data-testid="requirements-step__education"
                        header={educationHeader}
                        educationsValueInputProps={educationField}
                        educationsFieldMeta={educationFieldMeta}
                        educationsHelperProps={educationHelperProps}
                    />
                    <CreatableRequirement
                        header={permissionsHeader}
                        label={permissionsLabel}
                        valueInputProps={permissionsField}
                        metaProps={permissionsMetaProps}
                        helperProps={permissionsHelperProps}
                    />
                    <CreatableRequirement
                        data-testid="requirements-step__certificates"
                        header={licenseHeader}
                        label={licenseLabel}
                        valueInputProps={certificatesField}
                        metaProps={certificateMetaProps}
                        helperProps={certificatesHelperProps}
                    />
                </div>
                <div className={styles["requirements-step__input-right-container"]}>
                    <CheckBoxRequirement
                        data-testid="requirements-step__driving-license"
                        header={drivingLicenseHeader}
                        options={drivingOptions}
                        hasColumns={true}
                        valueInputProps={drivingLicenseField}
                        helperProps={drivingLicenseHelperProps}
                    />
                    <LanguageRequirement
                        data-testid="requirements-step__languages"
                        header={languagesHeader}
                        label={languagesLabel}
                        languagesValueInputProps={languagesField}
                        languagesFieldMeta={languagesFieldMeta}
                        languagesHelperProps={languagesHelperProps}
                    />
                    <CreatableRequirement
                        data-testid="requirements-step__programs"
                        header={programsHeader}
                        label={programsLabel}
                        valueInputProps={programsField}
                        metaProps={programsMetaProps}
                        helperProps={programsHelperProps}
                    />
                    <CreatableRequirement
                        data-testid="requirements-step__other"
                        header={otherHeader}
                        label={otherLabel}
                        valueInputProps={otherField}
                        metaProps={otherMetaProps}
                        helperProps={otherHelperProps}
                    />
                </div>
            </div>
        </div>
    );
};

export default RequirementsStep;
