import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useCurrentPath = () => {
    const [pathname, setPathname] = useState(`${window.location.pathname}${window.location.search}`);
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((listener) => {
            setPathname(`${listener.pathname}${listener.search}`);
        });

        return () => unlisten();
    }, [history]);

    return pathname;
};

export default useCurrentPath;
