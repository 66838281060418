import getBonusTitle from "assets/getbonus/get-bonus-title.png";
import getBonusIcon from "assets/getbonus/get-bonus.svg";
import HeartIcon from "assets/icons/heart-icon-off.svg";
import HeartFilled from "assets/icons/HeartFilled.svg";
import DefaultImage from "assets/images/default-image.png";
import { Subheadline } from "components/Text";
import Tile from "components/Tile";
import config from "config";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { isTouchDevice } from "tests/helpers";
import { safe } from "utils/safe";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrowRight.svg";
import { ReactComponent as CalendarCheckIcon } from "../../assets/icons/calendarCheck.svg";
import { ReactComponent as CoinsIcon } from "../../assets/icons/coins.svg";
import { ReactComponent as LocationMarkerIcon } from "../../assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "../../assets/icons/peopleWithHand.svg";
import useDateFormat from "../common/hooks/useDateFormat";
import { getPositionsNumberMessage, getStartWorkDateMessage } from "../common/translationMessages";
import { EmployeeOfferDto } from "../employeeOffers/dtos";
import FavoriteAPI from "../favorite/api";
import { JobOfferDto } from "../jobOffers/dtos";
import DetailRow from "../offers/components/SingleOfferCard/DetailRow";
import { getSalaryMessage } from "../offers/translationMessages";
import { OfferStatus } from "../offers/types";

type Props = {
    offer: (JobOfferDto | EmployeeOfferDto | any) & { images?: string[] };
    target: string;
    last: boolean;
    status?: OfferStatus;
    previewMode?: string;
};

const Offer = ({ offer, status, previewMode = "recommended", target, last }: Props) => {
    const [isFilled, setIsFilled] = useState(offer.favorite)
    const getFormattedDate = useDateFormat();
    const intl = useIntl();
    const { title } = offer;
    const jobTitle = title.length >= 23 ? `${title.substring(0, 23)}...` : title;

    const bonusValue = safe(() => offer?.financialConditionsSlimDTO?.bonusValue, null);
    const { id, mainBusinessCardPhoto, mainImage, financialConditionsSlimDTO } = offer;
    const bonusCurrencyCode = safe(() => offer?.financialConditionsSlimDTO?.bonusCurrencyCode, null);
    const location = offer?.locationSlimDto;

    const workLocationText = useMemo(
        () =>
            `${location?.city ? `${location?.city}, ` : ""}${location?.country}`,
        [intl, location]
    );

    const startDate = new Date(offer?.jobStartDate as string);
    const positionsNumberMessage = getPositionsNumberMessage(intl, offer.availablePositionsNumber ? offer.availablePositionsNumber : offer.avaliablePositionsNumber);

    let salaryMessage;
    if (financialConditionsSlimDTO) {
        salaryMessage = getSalaryMessage(intl, financialConditionsSlimDTO);
    }

    const posterPath = mainImage ? `${config.IMAGE_URL}/${mainImage}` : DefaultImage;//(offer.images?.length === 0 || offer.images![0] === '') ? DefaultImage : `${config.IMAGE_URL}/${offer.images![0]}`;

    const startJobDateMessage = getStartWorkDateMessage(intl, getFormattedDate(startDate));

    const [touchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice(isTouchDevice())
        const unsub = () => {
            setIsTouchDevice(isTouchDevice())
        }
        window.addEventListener('resize', unsub);

        return () => window.removeEventListener('resize', unsub);
    }, [setIsTouchDevice]);

    const onFavoriteHandler = async () => {
        if (target.includes('employee-offers')) {
            if (isFilled) {
                await FavoriteAPI.removeEmployeeOfferFromFavorite(id);
                setIsFilled(false);
            } else {
                await FavoriteAPI.addEmployeeOfferToFavorite(id);
                setIsFilled(true);
            }
        }

        if (target.includes('job-offers')) {
            if (isFilled) {
                await FavoriteAPI.removeJobOfferFromFavorite(id);
                setIsFilled(false);
            } else {
                await FavoriteAPI.addJobOfferToFavorite(id);
                setIsFilled(true);
            }
        }
    }

    return (
        <Wrapper last={last} overflow={touchDevice}>
            <ImageContainer empty={!mainImage}>
                {bonusValue && (
                    <Bonus>
                        <img src={getBonusIcon} alt="get-bonus" />
                        <img className="getBonusTitle" src={getBonusTitle} alt="get-bonus" />
                        <strong>
                            {bonusValue} {bonusCurrencyCode}
                        </strong>
                    </Bonus>
                )}
                <Image src={posterPath} />
                {mainBusinessCardPhoto && (
                    <Image companyLogo src={`${config.IMAGE_URL}/${mainBusinessCardPhoto}`} />
                )}
            </ImageContainer>
            <HeartContainer>
                <StyledSubheadline>{jobTitle}</StyledSubheadline>
                <BranchTitle>
                    {offer.branch ? <FormattedMessage id={`${offer.branch.name}`} /> : ""}
                </BranchTitle>
                <StyledHeartBtn onClick={onFavoriteHandler} type="button">
                    <img src={isFilled ? HeartFilled : HeartIcon} alt="favorite" />
                </StyledHeartBtn>
            </HeartContainer>
            <Content>
                <DetailRow redHatText message={salaryMessage} icon={<CoinsIcon />} />
                <DetailRow icon={<LocationMarkerIcon />} message={workLocationText} />
                <DetailRow isEllipsis={true} icon={<PeopleWithHandIcon />} message={positionsNumberMessage} />
                {(previewMode === "extended" ||
                    previewMode === "recommended" ||
                    (previewMode === "minimum" && status !== OfferStatus.Ended)) && (
                        <DetailRow isEllipsis={false} icon={<CalendarCheckIcon />} message={startJobDateMessage} />
                    )}
            </Content>
            <Footer>
                <FooterStyledLink to={target + id}>
                    {intl.formatMessage({ id: "dashboard__offer-details" })}
                    <ArrowRightIcon />
                </FooterStyledLink>
            </Footer>
        </Wrapper>
    );
};

const Wrapper = styled(Tile) <{ last?: boolean; overflow?: boolean }>`
    padding: 0;
    margin-bottom: 2.5rem;
    position: relative;
    max-width: 18.75rem;
    height: 29.5rem;
`;

const ImageContainer = styled.div<{ empty?: boolean }>`
    display: flex;
    max-height: 100%;
    height: 11.313rem;
    position: relative;
`;

interface IImageProps {
    companyLogo?: boolean;
}

const Image = styled.img<IImageProps>`
    display: block;
    max-width: 100%;
    width: 18.75rem;

    ${({ companyLogo }) => companyLogo && css`
        width: 55px;
        height: 55px;
        position: absolute;
        bottom: 0;
        left: 0;
        object-fit: cover;
    `}
`;

const Bonus = styled.div`
    background-color: #ff6900;
    display: flex;
    align-items: center;
    padding: 0.625rem;
    color: white;
    border-radius: 4px 4px 0 0;
    position: absolute;
    width: 100%;
    top: 0;

    img {
        left: 0;
        max-width: 50%;

      @media screen and (min-width: 834px) {
        max-width: 75%;

        &.getBonusTitle {
            margin-left: 0.5rem;
        }
      }
    }

    strong {
        font-family: Red Hat Text,serif;
        font-weight: bold;
        font-size: 1.25rem;
        padding-left: 0.5rem;
    }
`;

const HeartContainer = styled.div`
position: relative;
`;

const StyledHeartBtn = styled.button`
    position: absolute;
    top: 0.75rem;
    right: 1.25rem;
    background: transparent;
    outline: none;
    border: none;
`;

const StyledSubheadline = styled(Subheadline)`
    width: 14.5rem;
    margin-left: 16px;
    margin-top: 0.75rem;
    font-size: 20px;
    line-height: 1.05;
    text-transform: none;
    margin-bottom: 0;
    text-align: left;
`;

const BranchTitle = styled.p`
    font-size: 14px;
    margin: 0.25rem 0 1rem 16px;
    color: #002677; 
`;

const Content = styled.div`
    display: grid;
    grid-gap: 7px;
    padding: 16px;
    padding-top: 0;
    max-width: 100%;
    overflow: hidden;

    & * {
        overflow: hidden;
        word-wrap: normal;
    }
`;

const Footer = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.37;
    letter-spacing: -0.347873px;
    padding: 16px;
    display: flex;
    justify-content: end;

`;

const FooterStyledLink = styled(Link)`
    color: #FF6900;
    display: flex;
    align-items: center;
    grid-gap: 9px;
`;

export default Offer;