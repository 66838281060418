import {FileType, WorkType} from "features/common/types";

export const localStorageItems = {
    CHECK_TOKEN_DATE: "CHECK_TOKEN_DATE",
    TOKENS: "TOKENS",
};

export const fieldRequiredMessageCode = "FIELD_REQUIRED_MESSAGE_CODE";

export const modalsDivId = "modals";

export const screenSizeBreakpoints = {
    tablet: 600,
    desktop: 834,
};

export const localeDecimalSeparator = (1.1).toLocaleString()[1];

export const monetaryAmountRegex = "[0-9]+([\\.][0-9]{1,2}|[\\.])?";
export const floatRegex = "^[0-9]+([\\.]|([\\.][0-9]+))?$";
export const integerRegex = "^[0-9]+$";
export const phoneNumberRegex = /^[0-9+ -]+$/;
export const emailRegex = /([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})/gm;
export const passwordRegex = /(?=^.{4,}$)(?=.*[!@#$%^&*,._]+)(?=.*[0-9])(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
// export const phoneNumberRegex = /([+]?\d{1,3}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{3,4}/gm;
export const expiryDateRegex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
export const creditCardRegex = /^((4\d{3})|(5[1-5]\d{2})|(6011)|(34\d{1})|(37\d{1}))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7][\d\s-]{15}$/ ;

export const maxImageSize = 20 * 1024 * 1024;
export const allowedImageTypes: FileType[] = [".jpeg", ".png", ".jpg", ".heic"];
export const utcStringDateFormat = "[\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}.\\d{3}Z]+";

export const headerHeight = 78;

export const searchDebounceMs = 500;

export const emptyGetOffersFetchParams = {
    filters: {
        branchId: [],
        workType: [],
        employerType: [],
        byCapacityType: [],
        byPreferredAmenities: [],
    },
    amendOffers: false,
};

export const defaultLocale = "pl";

export const workTypes = [
    {
        id: WorkType.Steady,
        name: WorkType.Steady,
    },
    {
        id: WorkType.SeasonalTemporary,
        name: WorkType.SeasonalTemporary,
    },
    {
        id: WorkType.Odd,
        name: WorkType.Odd,
    },
];
