import CreateProfile from "features/user/components/CreateProfile/component";
import { selectIsUserLoggedIn, selectUser, selectUserLoading } from "features/user/selectors";
import { connect } from "react-redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    userLoading: selectUserLoading(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
});

export default connect(mapStateToProps)(CreateProfile);
