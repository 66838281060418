import { Dispatch, SetStateAction } from "react";
import { AuthResponse, InvoiceData, PaymentResponse } from "features/jobOffers/api/types";
import { payment } from "./payment";

export const producePaymentCallback =
    (
        setPaymentCallback: Dispatch<SetStateAction<() => Promise<PaymentResponse>>>,
        onFailure: () => unknown,
        cvc2: string,
        jobOfferId: number,
        invoiceData: InvoiceData
    ) =>
    (data: AuthResponse) => {
        const paymentCallback = () => {
            return payment({ authenticationId: data.authenticationId, cvc2, jobOfferId, invoiceData }).catch((e) => {
                onFailure();
                throw e;
            });
        };
        setPaymentCallback(() => paymentCallback);
        return Promise.resolve({ data, paymentCallback });
    };
