import { selectUserId } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import CreateJobOfferModal from "./component";

const mapStateToProps = (state: RootState) => ({
    userId: selectUserId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobOfferModal);
