import "cropperjs/dist/cropper.css";
import { CancelToken } from "api/utils";
import { ReactComponent as DefaultImage } from "assets/icons/defaultImage.svg";
import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import DropzoneFileUploader from "features/common/components/DropzoneFileUploader";
import FieldMessages from "features/common/components/FieldMessages";
import ModalWithContent from "features/common/components/ModalWithContent";
import { allowedImageTypes, maxImageSize } from "features/common/constants";
import { CardDto } from "features/common/dtos";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { Image } from "features/common/types";
import { Requirement, RequirementLanguage } from "features/jobOffers/types";
import MyProfileApi from "features/myProfile/api";
import { CardOwnerRole, CardOwnerType } from "features/myProfile/types";
import TextEditor from "features/offers/components/CreateOffer/TextEditor";
import { PhotoDto } from "features/user/dtos";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-cropper";
import { FormattedMessage, useIntl } from "react-intl";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { srcToFile } from "../../MyData/component";
import styles from "./styles.module.scss";

export interface Props {
    cardId: string;
    cardKind: CardOwnerRole;
    cardType?: CardOwnerType;
    mainPhoto?: string;
    logo?: string;
    cardName: string;
    location: string;
    description?: string;
    overallRating?: number;
    languageRequirements?: RequirementLanguage[];
    requirements?: Requirement[];
    displayOnly?: boolean;
    updateMyProfileCardAsync: (cardDetails: Partial<CardDto>, id: string, cancelToken?: CancelToken) => void;
    setPhotoChanged: (isChanged: boolean) => void;
}

const EDITOR_CHARACTER_LIMIT = 2000;

const MainSection = ({
    cardId,
    cardKind,
    cardType,
    mainPhoto,
    logo,
    cardName,
    location,
    description,
    overallRating,
    displayOnly,
    setPhotoChanged,
    updateMyProfileCardAsync,
}: Props) => {
    const intl = useIntl();
    const { translatedText, handleTranslate, translationIcon } = useTranslate(description as string)
    const createCancelToken = useCreateCancelToken();
    const [mainPhotoValue, setMainPhotoValue] = useState<Image | undefined>();
    const [uploadingMainPhoto, setUploadingMainPhoto] = useState(false);
    const [uploadingMainPhotoError, setUploadingMainPhotoError] = useState<string | undefined>();
    const [isEditingMainPhoto, setIsEditingMainPhoto] = useState(false);
    const [mainPhotoError, setMainPhotoError] = useState(false);

    const [logoValue, setLogoValue] = useState<Image | undefined>();
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [uploadingLogoError, setUploadingLogoError] = useState<string | undefined>();
    const [isEditingLogo, setIsEditingLogo] = useState(false);
    const [logoError, setLogoError] = useState(false);

    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [descriptionValue, setDescriptionValue] = useState(description as string);

    const [cropper, setCropper] = useState<any>();

    const onEditMainPhotoButtonClicked = useCallback(() => setIsEditingMainPhoto(true), []);

    useEffect(() => {
        if (translatedText !== '') {
            setDescriptionValue(translatedText);
        }
    }, [translatedText])

    const handleMainPhotoValueChange = useCallback((images: Image[]) => setMainPhotoValue(images[0]), [setMainPhotoValue]);
    const handleEditMainPhotoHide = useCallback(() => {
        if (mainPhotoValue) {
            setMainPhotoValue(undefined);
        } else {
            setIsEditingMainPhoto(false);
        }
    }, [mainPhotoValue]);

    const handleMainPhotoUpload = useCallback(async () => {
        setUploadingMainPhoto(true);
        const getCropData = () => {
            if (typeof cropper !== "undefined") {
                return cropper.getCroppedCanvas();
            }
        };

        const uploadAndReturnCreatedImage = () => {
            if (mainPhotoValue?.file) {
                const asd = getCropData();
                return srcToFile(asd.toDataURL('image/jpeg'), "avatar.jpeg", "image/jpeg").then((image: File) => {
                    const imageForm = new FormData();
                    imageForm.append("images", image);
                    return MyProfileApi.uploadImagesAsync(imageForm, createCancelToken());
                }
                );
            }
        };
        try {
            const uploadedImages: PhotoDto[] = await uploadAndReturnCreatedImage() || [];
            updateMyProfileCardAsync(
                {
                    main_photo: uploadedImages[0]?.fileName,
                    isPersonal: cardType === "private",
                    personal: cardType === "private",
                },
                cardId,
                createCancelToken()
            );
            setMainPhotoError(false);
            setUploadingMainPhoto(false);
        } catch (error) {
            setUploadingMainPhotoError(intl.formatMessage({ id: "card-details__uploading-photo-error" }));
            setUploadingMainPhoto(false);
        }
    },
        [cardId, cardType, createCancelToken, cropper, intl, mainPhotoValue, updateMyProfileCardAsync]
    );

    const handleMainPhotoError = useCallback(() => setMainPhotoError(true), [setMainPhotoError]);

    const handleLogoValueChange = useCallback((images: Image[]) => setLogoValue(images[0]), [setLogoValue]);

    const handleLogoUpload = useCallback(async () => {
        setUploadingLogo(true);
        const getCropData = () => {
            if (typeof cropper !== "undefined") {
                return cropper.getCroppedCanvas({
                    fillColor: '#fff'
                });
            }
        };

        const uploadAndReturnCreatedImage = () => {
            if (logoValue?.file) {
                const asd = getCropData();
                return srcToFile(asd.toDataURL('image/jpeg'), "avatar.jpeg", "image/jpeg").then((image: File) => {
                    const imageForm = new FormData();
                    imageForm.append("images", image);
                    return MyProfileApi.uploadImagesAsync(imageForm, createCancelToken());
                }
                );
            }
        };
        try {
            const uploadedImages: PhotoDto[] = await uploadAndReturnCreatedImage() || [];
            updateMyProfileCardAsync(
                {
                    logo: uploadedImages[0]?.fileName,
                    isPersonal: cardType === "private",
                    personal: cardType === "private",
                },
                cardId,
                createCancelToken()
            );
            setLogoError(false);
            setUploadingLogo(false);
        } catch (error) {
            setUploadingLogoError(intl.formatMessage({ id: "card-details__uploading-photo-error" }));
            setUploadingLogo(false);
        }
    }, [cardId, cardType, createCancelToken, cropper, intl, logoValue, updateMyProfileCardAsync]);

    const onEditLogoButtonClicked = useCallback(() => setIsEditingLogo(true), [setIsEditingLogo]);
    const handleEditLogoHide = useCallback(() => {
        if (!logoValue) {
            setIsEditingLogo(false);
        } else {
            setLogoValue(undefined);
        }
    }, [logoValue]);
    useEffect(() => {
        if (location === 'common__country-undefined') {
            setPhotoChanged(true)
        }
    }, [location])

    const handleEditDescriptionButtonClick = useCallback(
        () => setIsEditingDescription(true),
        [setIsEditingDescription]
    );

    const handleSaveDescriptionButtonClick = useCallback(() => {
        updateMyProfileCardAsync(
            {
                description: descriptionValue || "",
                isPersonal: cardType === "private",
                personal: cardType === "private",
            },
            cardId,
            createCancelToken()
        );
        setIsEditingDescription(false);
    }, [cardId, cardType, createCancelToken, descriptionValue, updateMyProfileCardAsync]);
    const stopEditingDescription = useCallback(() => {
        setIsEditingDescription(false);
        setDescriptionValue(description || '');
    }, [description]);

    const renderChangeMainPhotoModal = () => {
        if (mainPhotoValue) {
            return (
                <Cropper
                    style={{ width: 280, height: 280, marginLeft: "calc(50% - 140px)" }}
                    aspectRatio={1}
                    viewMode={2}
                    src={mainPhotoValue.fileUrl}
                    dragMode="move"
                    guides={false}
                    responsive
                    autoCropArea={1}
                    checkOrientation={false}
                    highlight={false}
                    center={false}
                    zoomOnWheel={false}
                    movable={false}
                    background
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                />
            );
        }
        return (
            <DropzoneFileUploader
                data-testid="main-section__logo-uploader"
                maxSize={maxImageSize}
                files={mainPhotoValue}
                allowedTypes={allowedImageTypes}
                onUpload={handleMainPhotoValueChange}
                customPlaceholderImage={<DefaultImage />}
                buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-main-photo" />}
            />
        );
    };




    const renderChangeLogoModal = () => {
        if (logoValue) {
            return (
                <Cropper
                    className="oval"
                    style={{ width: 280, height: 280, marginLeft: "calc(50% - 140px)" }}
                    aspectRatio={1}
                    viewMode={2}
                    src={logoValue.fileUrl}
                    dragMode="move"
                    guides={false}
                    responsive
                    autoCropArea={1}
                    checkOrientation={false}
                    highlight={false}
                    center={false}
                    zoomOnWheel={true}
                    zoomOnTouch={true}
                    movable={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                />
            );
        }
        return (
            <DropzoneFileUploader
                data-testid="main-section__logo-uploader"
                maxSize={maxImageSize}
                files={logoValue}
                allowedTypes={allowedImageTypes}
                onUpload={handleLogoValueChange}
                customPlaceholderImage={<DefaultImage />}
                buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-file" />}
            />
        );
    };

    return (
        <div className={styles["main-section"]}>
            <div className={styles["main-section__main-photo-container"]}>
                {(isEditingMainPhoto || mainPhotoValue) && (
                    <ModalWithContent
                        header={<FormattedMessage id="card-details__main-image-change-label" />}
                        content={renderChangeMainPhotoModal()}
                        primaryButtonContent={intl.formatMessage({ id: "common__save" })}
                        secondaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                        primaryAction={handleMainPhotoUpload}
                        onClose={handleEditMainPhotoHide}
                        primaryButtonDisabled={!mainPhotoValue || uploadingMainPhoto}
                        primaryButtonLoading={uploadingMainPhoto}
                        data-testid="main-section__main-photo-modal"
                    />)}

                {!isEditingMainPhoto && !mainPhotoValue && !mainPhoto && !displayOnly && (
                    <div
                        className={styles["main-section__main-photo-dropzone"]}
                    >
                        <DropzoneFileUploader
                            data-testid="main-section__main-photo-uploader"
                            maxSize={maxImageSize}
                            allowedTypes={allowedImageTypes}
                            onUpload={handleMainPhotoValueChange}
                            customPlaceholderImage={<DefaultImage />}
                            buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-main-photo" />}
                        />
                    </div>
                )}
                <div className={styles["main-section__main-photo"]}>
                    {mainPhoto && !mainPhotoError && (
                        <img
                            data-testid="main-section__main-photo"
                            className={styles["main-section__main-photo--img"]}
                            src={`${config.IMAGE_URL}/${mainPhoto}`}
                            alt="user"
                            onError={handleMainPhotoError}
                        />
                    )}
                    {!!overallRating && (
                        <div
                            className={classNames(styles["main-section__main-photo-overall-rating"], {
                                [styles["main-section__main-photo-overall-employer-rating"]]:
                                    cardKind === "EMPLOYER",
                            })}
                        >
                            {overallRating}
                        </div>
                    )}
                    {!displayOnly && mainPhoto && (
                        <Button
                            as="div"
                            className={styles["main-section__main-photo-button"]}
                            onClick={onEditMainPhotoButtonClicked}
                        >
                            <PenIcon />
                        </Button>
                    )}
                </div>

            </div>
            <div
                className={classNames(styles["main-section__separator"], {
                    [styles["main-section__separator-employer"]]: cardKind === "EMPLOYER",
                })}
            >
                <div className={styles["main-section__logo-container"]}>
                    <div className={styles["main-section__logo-box"]}>
                        {logo ? (
                            <img src={`${config.IMAGE_URL}/${logo}`} alt="card-logo" />
                        ) : (
                            <div className={styles["main-section__logo-placeholder"]}>
                                {intl.formatMessage({ id: "card-details__logo-placeholder" }, { cardKind })}
                            </div>
                        )}
                    </div>
                    {!displayOnly && (
                        <Button
                            as="div"
                            className={styles["main-section__logo-button"]}
                            onClick={onEditLogoButtonClicked}
                        >
                            <PenIcon />
                        </Button>
                    )}
                </div>
            </div>
            {isEditingLogo && !displayOnly && (
                <ModalWithContent
                    header={<FormattedMessage id="card-details__logo-change-label" />}
                    content={renderChangeLogoModal()}
                    primaryButtonContent={intl.formatMessage({ id: "common__save" })}
                    secondaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    primaryAction={handleLogoUpload}
                    onClose={handleEditLogoHide}
                    primaryButtonDisabled={!logoValue || uploadingLogo}
                    primaryButtonLoading={uploadingLogo}
                    data-testid="main-section__logo-modal"
                />
            )}
            <div className={styles["main-section__name"]}>{cardName}</div>
            <div className={styles["main-section__location"]}>{location}</div>
            <div className={styles["main-section__additional-info-section"]}>
                {(!displayOnly || description) && (
                    <div className={styles["main-section__additional-info-label"]}>
                        <div>
                            <FormattedMessage id='card-details__additional-info-label' />
                        </div>
                        <div className={styles["general-info-part__translation"]} onClick={handleTranslate}>
                            {translationIcon}
                        </div>
                    </div>
                )}
                {isEditingDescription ? (
                    <div className={styles["main-section__additional-info-edit-container"]}>
                        <TextEditor
                            initialText={description}
                            handleChange={(value: string) => {
                                setDescriptionValue(value)
                            }}
                            maxLength={EDITOR_CHARACTER_LIMIT}
                            maxLengthTextComponent={
                                <FieldMessages
                                    messages={[intl.formatMessage({ id: "create-offer__maximum-characters" }, { amount: EDITOR_CHARACTER_LIMIT })]}
                                    mode="error"
                                />
                            }
                            additionalInfoTextComponent={
                                <FieldMessages
                                    messages={[intl.formatMessage({ id: "create-offer__maximum-characters" }, { amount: EDITOR_CHARACTER_LIMIT })]}
                                    mode="info"
                                />
                            }
                        />
                        <div className={styles["main-section__additional-info-buttons"]}>
                            <Button
                                variant="tertiary"
                                className={styles["main-section__additional-info-save"]}
                                onClick={stopEditingDescription}
                            >
                                <FormattedMessage id="common__cancel" />
                            </Button>
                            <Button
                                className={styles["main-section__additional-info-save"]}
                                onClick={handleSaveDescriptionButtonClick}
                            >
                                <FormattedMessage id="common__save" />
                            </Button>
                        </div>
                    </div>
                ) : descriptionValue || displayOnly ? (
                    <>
                        <div
                            className={styles["main-section__additional-info"]}
                            dangerouslySetInnerHTML={{ __html: descriptionValue }}
                        />
                        {!displayOnly && (
                            <Button
                                as="div"
                                className={styles["main-section__additional-info-edit-button"]}
                                onClick={handleEditDescriptionButtonClick}
                            >
                                <PenIcon />
                                <FormattedMessage id="card-details__edit-additional-info" />
                            </Button>
                        )}
                    </>
                ) : (
                    <div className={styles["main-section__additional-info-box"]}>
                        <Button
                            variant="tertiary"
                            className={styles["main-section__additional-info-button"]}
                            onClick={handleEditDescriptionButtonClick}
                        >
                            <FormattedMessage id="card-details__add-additional-info" />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainSection;
