import { FilterMatchCount } from "features/common/types";
import { CreateOfferStepName, OffersFetchParams, OffersFilterMatchCounts, OffersFilters } from "features/offers/types";

export interface JobOffersFilters extends OffersFilters {
    employerType: EmployerType[];
    minimalSalaryValue?: number;
    minimalSalaryCurrencyCode?: string;
    minimalBonusValue?: number;
    minimalBonusCurrencyCode?: string;
}

export interface JobOffersFetchParams extends OffersFetchParams {
    filters: JobOffersFilters;
}

export interface JobOffersFilterMatchCounts extends OffersFilterMatchCounts {
    byEmployerType?: Array<FilterMatchCount<EmployerType>>;
}

export interface MyJobOffersFetchParams {
    page?: number;
    size?: number;
    sort?: string;
    sortBy?: string;
    language?: string;
    status?: string;
}

export interface ApplicationFormValues {
    businessCardId: number;
    content?: string;
    applicantsNumber?: number;
    applicationType?: ApplicationTypes;
}

export enum ApplicationTypes {
    Group = "GROUP",
    Individual = "INDIVIDUAL",
}

export type CreateJobOfferStepName = CreateOfferStepName | "requirementsStep" | "additionalInfoStep";

export enum RequirementType {
    experience = 0,
    education,
    permissions,
    certificates,
    drivingLicenses,
    programs,
    other,
}

export enum LanguageType {
    English = "English",
    Arabic = "Arabic",
    Czech = "Czech",
    French = "French",
    Hindi = "Hindi",
    Spanish = "Spanish",
    Dutch = "Dutch",
    German = "German",
    Polish = "Polish",
    Russian = "Russian",
    Romanian = "Romanian",
    Ukrainian = "Ukrainian",
    Italian = "Italian",
}

export enum JobExperienceType {
    noExperience = "noExperience",
    toThreeMonths = "toThreeMonths",
    toSixMonths = "toSixMonths",
    oneYear = "oneYear",
    twoYearsAndMore = "twoYearsAndMore",
}

export enum EducationType {
    None = "none",
    Primary = "primary",
    Vocational = "vocational",
    Secondary = "secondary",
    SecondaryTechnical = "technical_secondary",
    Higher = "higher",
    Other = "other",
}

export enum LanguageLevelType {
    Beginner = "BEGINNER",
    Intermediate = "INTERMEDIATE",
    Advanced = "ADVANCED",
    Native = "NATIVE",
}

export enum PayoutDayType {
    FifthDay = "FIFTH_DAY",
    FirstDay = "FIRST_DAY",
    OneMonth = "ONE_MONTH",
    OneWeek = "ONE_WEEK",
    ThreeMonths = "THREE_MONTHS",
    ThirdDay = "THIRD_DAY",
    TwoMonths = "TWO_MONTHS",
    TwoWeeks = "TWO_WEEKS",
}

export type Requirement = {
    requirementType: string;
    value?: string | null | any;
    valueArray?: string[];
};

export type RequirementLanguage = {
    languageType: LanguageType;
    languageLevelType: LanguageLevelType;
    value: null | string;
    name?: LanguageType;
    level?: LanguageLevelType;
};

export type RequirementLanguageTouched = {
    isOtherValueTouched: boolean;
    isLevelTouched: boolean;
};

export type RequirementEducationTouched = {
    isOtherValueTouched: boolean;
};

export type RequirementJobExperience = {
    languageType: LanguageType;
    languageLevelType: LanguageLevelType;
    value: null | string;
    name?: LanguageType;
    level?: LanguageLevelType;
};

export enum EmployerType {
    Company = "COMPANY",
    Private = "PRIVATE_PERSON",
}

export enum InvoiceType {
    VAT = "VAT",
    RECEIPT = "RECEIPT",
}

export interface RequirementListOption {
    label: string;
    icon?: React.ReactElement;
}

export interface Language {
    id: LanguageType;
    isChecked: boolean;
    level?: LanguageLevelType;
    otherValue?: string;
}

export interface LanguageLevel {
    label: string;
    value: string;
}

export interface Education {
    id: EducationType;
    isChecked: boolean;
    otherValue?: string;
}

export enum MyJobOfferDetailCategory {
    Preview,
    Interested,
    Applications,
}

export interface JobExperience {
    id: string;
    companyName: string;
    position: string;
    dateFrom: Date;
    dateTo: Date;
    description: string;
    isActive?: boolean;
}
