import Spinner from "features/common/components/Spinner";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import CreateProfileForm from "./CreateProfileForm";
import styles from "./styles.module.scss";

export interface Props {
    user?: User;
    userLoading?: boolean;
    isUserLoggedIn?: boolean;
}

const CreateProfile = ({ user, userLoading, isUserLoggedIn }: Props) => {
    const history = useHistory();

    useEffect(() => {
        if (!isUserLoggedIn || !!user?.firstName) {
            history.push(appRoutes.dashboard);
        }
    }, [history, isUserLoggedIn, user]);

    return isUserLoggedIn && !userLoading ? (
        <div data-testid="create-profile" className={styles["create-profile"]}>
            <h1 className={styles["create-profile__header"]}>
                <FormattedMessage id="create-profile__header" />
            </h1>
            <div className={styles["create-profile__description"]}>
                <FormattedMessage id="create-profile__description" />
            </div>
            <CreateProfileForm />
        </div>
    ) : (
        <Spinner data-testid="create-profile__spinner" />
    );
};

export default CreateProfile;
