import classNames from "classnames";
import Dropdown, { Props as DropdownProps } from "features/common/components/Dropdown";
import FieldMessages from "features/common/components/FieldMessages";
import { ValidationProps } from "features/common/types";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useState } from "react";
import styles from "./styles.module.scss";

export type Props<T> = DropdownProps<T> &
    ValidationProps & {
        label?: string;
        className?: string;
        overflowHelperClassName?: string;
        formDropdownClassName?: string;
        helperMessages?: Array<string | React.ReactElement>;
        noCurrency?: boolean;
    };

const FormDropdown = <T extends any>({
    label,
    className,
    overflowHelperClassName,
    formDropdownClassName,
    optionsClassName,
    isInvalid,
    "data-testid": testId,
    selected,
    withValidation,
    helperMessages,
    error,
    onToggle,
    noCurrency,
    ...props
}: Props<T>) => {
    const [expanded, setExpanded] = useState(false);
    const isMatches = useMediaQuery('(min-width: 834px)');

    const onFormDropdownToggle = (expanded: boolean) => {
        setExpanded(expanded);
        onToggle && onToggle(expanded);
    };

    return (
        <div className={classNames(className, expanded ? overflowHelperClassName : "")}>
            <div
                id={testId}
                data-testid={`${testId}__form-dropdown-wrapper`}
                className={classNames(
                    styles["form-dropdown"],
                    formDropdownClassName,
                    { [styles["form-dropdown--expanded"]]: expanded },
                    { [styles["form-dropdown--invalid"]]: (withValidation && isInvalid) || noCurrency }
                )}
            >
                {!!selected && label !== undefined && isMatches && (
                    <div className={styles["form-dropdown__label-wrapper"]}>
                        <label data-testid={`${testId}__label`} className={styles["form-dropdown__label"]}>
                            {label}
                        </label>
                    </div>
                )}
                <Dropdown
                    data-testid={testId}
                    selected={selected}
                    className={styles["form-dropdown__field"]}
                    toggleClassName={styles["form-dropdown__field-toggle"]}
                    optionsClassName={classNames(styles["form-dropdown__field-options"], optionsClassName)}
                    optionClassName={styles["form-dropdown__field-option"]}
                    placeholderToggleClassName={styles["form-dropdown__placeholder-field-toggle"]}
                    selectedOptionClassName={styles["form-dropdown__field-option--selected"]}
                    onToggle={onFormDropdownToggle}
                    isInvalid={isInvalid && withValidation}
                    {...props}
                />
            </div>
            {isInvalid && withValidation && !!error && (
                <FieldMessages
                    data-testid={testId ? `${testId}__error-messages` : undefined}
                    messages={[error]}
                    mode="payment-details-error"
                />
            )}
            {(!isInvalid || !withValidation) && helperMessages && (
                <FieldMessages
                    data-testid={testId ? `${testId}__helper-messages` : undefined}
                    messages={helperMessages}
                    mode="info"
                />
            )}
        </div>
    );
};

export default FormDropdown;
