import classNames from "classnames";
import { ReactComponent as AppleLogoIcon } from "assets/icons/appleLogo.svg";
import config from "config";
import Button from "features/common/components/Button";
import React from "react";
import styles from "./styles.module.scss";

export function AppleLoginButton(loginUserLoading: boolean, appleLoginMessage: string) {
    const style = classNames(styles["login-form__apple-login-button"]);

    return <Button
        data-testid="login-form__apple-login-button"
        as="a"
        className={style}
        href={config.APPLE_LOGIN_URL}
        disabled={loginUserLoading}
    >
        <AppleLogoIcon />
        {appleLoginMessage}
    </Button>;
}
