import { ApiError } from "api/utils";
import { OfferRequestDto, OffersDto } from "features/moderatorPanel/dtos";
import { ActionType, createAsyncAction } from "typesafe-actions";

export const findOffersToModerate = createAsyncAction(
    "GET_OFFERS_TO_MODERATE_REQUEST",
    "GET_OFFERS_TO_MODERATE_SUCCESS",
    "GET_OFFERS_TO_MODERATE_FAILURE"
)<void, OffersDto, ApiError>();

export const amendOffers = createAsyncAction("AMEND_OFFERS_REQUEST", "AMEND_OFFERS_SUCCESS", "AMEND_OFFERS_FAILURE")<
    void,
    OffersDto,
    ApiError
>();

export const putActionOfferRequest = createAsyncAction(
    "PUT_OFFER_REQUEST",
    "PUT_OFFER_SUCCESS",
    "PUT_OFFER_FAILURE"
)<void, OfferRequestDto, ApiError>();

type ModeratorPanelAction =
    | ActionType<typeof findOffersToModerate>
    | ActionType<typeof putActionOfferRequest>
    | ActionType<typeof amendOffers>

export default ModeratorPanelAction;