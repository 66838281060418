import { selectGiveBonusLoading, selectGiveBonusSuccess, selectGiveBonusError } from "features/jobOffers/selectors";
import { giveBonusAsync } from "features/jobOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import GiveBonusModal from "./component";

const mapStateToProps = (state: RootState) => ({
    giveBonusLoading: selectGiveBonusLoading(state),
    giveBonusSuccess: selectGiveBonusSuccess(state),
    giveBonusError: selectGiveBonusError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            giveBonusAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GiveBonusModal);
