import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

export interface Props {
    imageUrl?: string;
    className?: string;
    imageClassName?: string;
    placeholderClassName?: string;
    ["data-testid"]?: string;
}

const UserImageWithPlaceholder = ({
    imageUrl,
    className,
    imageClassName,
    placeholderClassName,
    "data-testid": testId,
}: Props) => {
    const [shouldDisplayPlaceholder, setShouldDisplayPlaceholder] = useState(false);

    useEffect(() => {
        if (imageUrl) {
            setShouldDisplayPlaceholder(false);
        }
    }, [imageUrl]);

    return (
        <div data-testid={testId} className={classNames(styles["user-image-with-placeholder"], className)}>
            {imageUrl && !shouldDisplayPlaceholder ? (
                <img
                    data-testid={testId ? `${testId}__image` : undefined}
                    className={classNames(styles["user-image-with-placeholder__image"], imageClassName)}
                    src={imageUrl}
                    alt="user"
                    onError={() => setShouldDisplayPlaceholder(true)}
                />
            ) : (
                <div
                    data-testid={testId ? `${testId}__placeholder` : undefined}
                    className={classNames(styles["user-image-with-placeholder__placeholder"], placeholderClassName)}
                >
                    <UserIcon />
                </div>
            )}
        </div>
    );
};

export default UserImageWithPlaceholder;
