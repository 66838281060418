import { ApiRequestError } from "api/utils";
import { SalaryType } from "features/common/models";
import { WorkType } from "features/common/types";
import {
    EmployeeOfferDetailsDto,
    EmployeeOfferDetailsResponseDto,
    EmployeeOffersFilterMatchCountsDto,
} from "features/employeeOffers/dtos";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import {
    JobOfferCardDetailsResponseDto,
    JobOfferDetailsDto,
    JobOfferDetailsResponseDto,
    JobOffersFilterMatchCountsResponseDto,
} from "features/jobOffers/dtos";
import { JobOfferDetails } from "features/jobOffers/models";
import { JobOffersFetchParams, PayoutDayType } from "features/jobOffers/types";
import { AmenityType, OfferStatus } from "features/offers/types";
import { ErrorOptions } from "tests/types";
import { act, fireEvent } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

export const actClickAsync = async (target: HTMLElement) =>
    await act(async () => {
        fireEvent.click(target);
    });

export const actTypeAsync = async (target: HTMLElement, text: string) =>
    await act(async () => {
        userEvent.type(target, text);
    });

export const actBlurAsync = async (target: HTMLElement) =>
    await act(async () => {
        fireEvent.blur(target);
    });

export const createApiError = (options: ErrorOptions = {}): ApiRequestError => {
    const {
        message = "test error message",
        status = 500,
        name = "test error name",
        formattedMessage,
        messageCode = "unexpected",
        details = "Unexpected exception occurred",
        parameters = [],
    } = options;

    return {
        toJSON: () => ({}),
        isAxiosError: true,
        message,
        name,
        formattedMessage,
        config: {},
        response: {
            status,
            data: { messageCode, details, parameters, response: { status: 500 } },
            config: {},
            statusText: `test ${status}`,
            headers: {},
        },
    };
};

export const mockGetElementById = (mock: jest.Mock) => {
    Object.defineProperty(document, "getElementById", {
        configurable: true,
        enumerable: true,
        value: (id: string) => mock(),
        writable: true,
    });
};

export const overrideImmutableProperty = (object: unknown, propertyName: string, value: unknown) => {
    Object.defineProperty(object, propertyName, {
        configurable: true,
        enumerable: true,
        value,
        writable: true,
    });
};

export const createEmptyJobOfferDetailsDto = (): JobOfferDetailsDto => ({
    id: 1,
    position: "",
    title: "",
    businessName: "",
    addedDate: "",
    location: { city: "", country: 'Poland', id: 0, regionLevel1: "" },
    financialConditions: {
        bonusCurrencyCode: "",
        bonusPayoutDateType: "",
        bonusValue: 0,
        id: 0,
        paymentRateType: SalaryType.Monthly,
        rewardCurrencyCode: "",
        rewardMinimumValue: 0,
        rewardMaximumValue: 0,
    },
    bonus: "",
    availablePositionsNumber: 1,
    branch: { id: 0, name: "" },
    bonusPayoutDay: PayoutDayType.FirstDay,
    images: [],
    jobStartDate: new Date(),
    expirationDate: "",
    offerStatus: "INACTIVE",
    workType: WorkType.Steady,
    amenities: {
        accommodation: true,
        transport: true,
        catering: true,
        workWear: true,
        workBoots: true,
        freeMedicalExaminations: true,
        assistanceInLegalizingStay: true,
        other: "",
    },
    mainImage: "",
    offerOwner: {
        userId: "",
        firstname: "",
        lastname: "",
    },
    isPaid: false,
    paid: false,
    branchDTO: { id: 1, name: "" },
    financialConditionsSlimDTO: { bonusCurrencyCode: undefined, bonusValue: 0, paymentRateType: {}, rewardCurrencyCode: "", rewardMinimumValue: 0 },
    locationSlimDto: { city: "", id: "", country: "", empty: true }
});
export const createEmptyJobOfferDetails = (): JobOfferDetails => ({
    id: 1,
    position: "",
    title: "",
    businessName: "",
    addedDate: new Date(),
    location: { city: "", country: 'Poland', id: 0, regionLevel1: "" },
    financialConditions: {
        bonusCurrencyCode: "",
        bonusPayoutDateType: "",
        bonusValue: 0,
        id: 0,
        paymentRateType: SalaryType.Monthly,
        rewardCurrencyCode: "",
        rewardMinimumValue: 0,
        rewardMaximumValue: 0,
    },
    bonus: "",
    availablePositionsNumber: 1,
    branch: { id: 0, name: "" },
    bonusPayoutDay: PayoutDayType.FirstDay,
    photoUrls: [],
    expirationDate: new Date(),
    startDate: new Date(),
    jobStartDate: new Date(),
    status: OfferStatus.Inactive,
    workType: WorkType.Steady,
    offerOwner: {
        userId: "",
        firstname: "",
        lastname: "",
    },
});

export const createEmptyJobOffersFetchParams = (): JobOffersFetchParams => ({
    pageLimit: 50,
    offset: 1,
    amendOffers: false,
    isFavorite: false,
    filters: {
        branchId: [],
        workType: [],
        employerType: [],
    },
});

export const createEmptyJobOffersFilterMatchCountsDto = (): JobOffersFilterMatchCountsResponseDto => ({
    jobOffersFilterMatchCounts: {
        byBranch: [],
        byWorkType: [],
        byEmployerType: [],
    },
});

export const createEmptyJobOfferDetailsResponseDto = (): JobOfferDetailsResponseDto => ({
    data: createEmptyJobOfferDetailsDto(),
});

export const createEmptyJobOfferCardDetailsResponseDto = (): JobOfferCardDetailsResponseDto => ({
    jobOfferCardDetails: createEmptyJobOfferDetailsDto(),
});

export const createEmptyEmployeeOffersFetchParams = (): EmployeeOffersFetchParams => ({
    pageLimit: 50,
    offset: 1,
    amendOffers: false,
    isFavorite: false,
    filters: {
        branchId: [],
        workType: [],
        byCapacityType: [],
        byPreferredAmenities: [],
        employerType: [],
    },
});

export const createEmptyEmployeeOffersFilterMatchCountsDto = (): EmployeeOffersFilterMatchCountsDto => ({
    filterMatchCounts: {
        byBranch: [],
        byWorkType: [],
        byCapacityType: [],
        byAmenities: [],
        byWorkDuringHolidays: 0,
    },
});

export const createEmptyEmployeeOfferDetails = (): EmployeeOfferDetails => ({
    id: 1,
    title: "",
    position: "",
    branch: { id: 0, name: "" },
    addedDate: new Date(),
    location: { city: "", country: 'Poland', id: 0, regionLevel1: "" },
    financialConditions: {
        bonusCurrencyCode: "",
        bonusPayoutDateType: "",
        bonusValue: 0,
        id: 0,
        paymentRateType: SalaryType.Monthly,
        rewardCurrencyCode: "",
        rewardMinimumValue: 0,
        rewardMaximumValue: 0,
    },
    status: 1,
    expirationDate: new Date(),
    jobStartDate: new Date(),
    availablePositionsNumber: 0,
    offerType: 1,
    workDuringHolidays: false,
    amenities: [
        {
            amenityType: AmenityType.accommodation,
            value: "true",
        },
    ],
    additionalInfo: "",
    jobExperience: [],
});

export const createEmptyEmployeeOfferDetailsDto = (): EmployeeOfferDetailsDto => ({
    id: 1,
    title: "",
    position: "",
    branch: { id: 0, name: "" },
    addedDate: "",
    location: { city: "", country: 'Poland', id: 0, regionLevel1: "" },
    financialConditions: {
        bonusCurrencyCode: "",
        bonusPayoutDateType: "",
        bonusValue: 0,
        id: 0,
        paymentRateType: SalaryType.Monthly,
        rewardCurrencyCode: "",
        rewardMinimumValue: 0,
        rewardMaximumValue: 0,
    },
    offerStatus: "INACTIVE",
    expirationDate: "",
    availablePositionsNumber: 0,
    offerType: "Group",
    workDuringHolidays: false,
    images: [],
    amenities: {
        accommodation: true,
        transport: false,
        catering: false,
        workWear: false,
        workBoots: false,
        freeMedicalExaminations: false,
        assistanceInLegalizingStay: false,
        other: "",
    },
    additionalInfo: "",
    jobExperience: [],
    branchDTO: { id: 1, name: "" },
    financialConditionsSlimDTO: { bonusCurrencyCode: undefined, bonusValue: 0, paymentRateType: {}, rewardCurrencyCode: "", rewardMinimumValue: 0 },
    locationSlimDto: { city: "", id: "", country: "", empty: true }
});

export const createEmptyEmployeeOfferDetailsResponseDto = (): EmployeeOfferDetailsResponseDto => ({
    data: createEmptyEmployeeOfferDetailsDto(),
});

export const createJobOffersFetchParams = (): JobOffersFetchParams => ({
    filters: {
        branchId: [],
        workType: [],
        employerType: [],
    },
    pageLimit: 50,
    offset: 1,
    isFavorite: false,
    amendOffers: false,
});


export const createTestDictionaryItem = (id?: number) => ({
    id: id ?? 1,
    code: "testCode",
    text: "testText",
});

export const isTouchDevice = () => {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
}