import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl, FormattedMessage, IntlShape } from "react-intl";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import classNames from "classnames";
import { CancelToken } from "api/utils";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import ModalWithContent from "features/common/components/ModalWithContent";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import FormDropdown from "features/common/components/FormDropdown";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { mapApplicationDtoToApplicationSubmission } from "features/jobOffers/mappers";
import OffersApi from "features/jobOffers/api";
import { initialJobOfferApplicationSubmissionFormValues } from "features/jobOffers/constants";
import { JobOfferApplicationSubmissionSchema } from "features/jobOffers/schemas";
import { ApplicationTypes } from "features/jobOffers/types";
import { createApplicationSubmissionValidationSchema } from "features/jobOffers/validators";
import StepSwitch from "features/offers/components/StepSwitch";
import { appRoutes } from "features/routing/routes";
import CardStep from "./CardStep";
import QualificationStep from "./QualificationStep";
import GroupApplicationForm from "./GroupApplicationForm";
import styles from "./styles.module.scss";
import { getLocalizedYup } from "features/common/validators";

const totalSteps = 2;

const createApplicationTypeDropdownOptions = (intl: IntlShape) => [
    {
        value: "0",
        display: intl.formatMessage({ id: "application-submission__existing-card" }),
    },
    {
        value: "1",
        display: intl.formatMessage({ id: "application-submission__new-card" }),
    },
];

interface Props {
    ["data-testid"]?: string;
    isVisible?: boolean;
    positionsAvailable?: number;
    offerId: number;
    userId?: string;
    onClose: () => void;
    onSuccess: () => void;
    refreshUserProfileAsync: (userId: string, cancelToken?: CancelToken) => void;
}

const ApplicationSubmission = ({
    "data-testid": testId,
    isVisible,
    positionsAvailable,
    offerId,
    onClose,
    onSuccess,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [applicationSending, setApplicationSending] = useState(false);
    const [applicationType, setApplicationType] = useState<ApplicationTypes>(ApplicationTypes.Individual);
    const [applicantsNumber, setApplicantsNumber] = useState(1);
    const [applicationSendSuccessModalVisible, setApplicationSendSuccessModalVisible] = useState(false);
    const [selectedCard, setSelectedCard] = useState<string | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

    const formikRef = useRef<any>();
    // const fileInputRef = useRef<HTMLInputElement>(null);

    const deviceClass = useDeviceClass();
    // const viewportSize = useViewportSize();
    const cancelToken = useCreateCancelToken();

    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return createApplicationSubmissionValidationSchema(localYup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const applicationTypeDropdownOptions = useMemo(() => createApplicationTypeDropdownOptions(intl), [intl]);

    const onPrevCancel = (resetForm: () => void) => {
        if (currentStepIndex + 1 === totalSteps) {
            setCurrentStepIndex(currentStepIndex - 1);
        } else {
            resetForm();
            onClose && onClose();
        }
    };

    const onCloseModal = (resetForm: () => void) => {
        resetForm();
        setCurrentStepIndex(0);
        onClose && onClose();
    };

    const onApply = (submitFn: () => void) => {
        if (currentStepIndex === 0) {
            setCurrentStepIndex(currentStepIndex + 1);
        } else {
            submitFn();
            setCurrentStepIndex(0);
            onClose && onClose();
        }
    };

    const onSubmit = async (values: JobOfferApplicationSubmissionSchema) => {
        setApplicationSending(true);

        try {
            if (selectedCard) {
                await OffersApi.sendJobOfferApplicationAsync(
                    mapApplicationDtoToApplicationSubmission(
                        selectedCard,
                        values.qualificationDescriptionStep.description,
                        applicationType,
                        applicantsNumber,
                        positionsAvailable
                    ),
                    selectedFile,
                    offerId,
                    cancelToken()
                );

                onSuccess();
                setApplicationSendSuccessModalVisible(true);
            }
        } finally {
            setApplicationSending(false);
        }
    };

    const getPreviousCancelButtonText = useCallback(() => {
        if (currentStepIndex === 0) {
            return <FormattedMessage id="common__cancel" />;
        } else {
            return <FormattedMessage id="common__back" />;
        }
    }, [currentStepIndex]);

    const getNextSubmitApplicationButtonText = useCallback(() => {
        if (currentStepIndex === 0) {
            return <FormattedMessage id="common__next" />;
        } else {
            return <FormattedMessage id="application-submission__submit-application" />;
        }
    }, [currentStepIndex]);

    const renderApplicationSentSuccessHeader = useCallback(() => {
        return (
            <div className={styles["application-submission__send-application-success-header"]}>
                <TickIcon data-testid={`${testId}__complete-icon`} />
                <FormattedMessage id="application-submission__send-application-success-header" />
            </div>
        );
    }, [testId]);

    const getApplicationSubmissionHeader = useCallback(() => {
        return (
            <div className={styles["application-submission__smartphone-modal-header"]}>
                <span>
                    <FormattedMessage id="offer-details__application-submission" />
                </span>
                {deviceClass === "smartphone" && <br />}
                <>[</>
                <span>
                    <FormattedMessage id="common__step" />
                </span>
                <span data-testid={testId ? `${testId}__current-step-number` : undefined}>{currentStepIndex + 1}</span>
                <span>
                    <FormattedMessage id="common__out-of" />
                </span>
                <span data-testid={testId ? `${testId}__total-steps-amount` : undefined}>{totalSteps}</span>
                <>]</>
            </div>
        );
    }, [currentStepIndex, deviceClass, testId]);

    useEffect(() => {
        setTimeout(() => formikRef.current?.validateForm());
    }, []);

    const handleChangeApplicationType = useCallback(
        (value: string) => value === "1" && history.push(appRoutes.newCard),
        [history]
    );

    const uploadFileButton = useMemo(
        () => (
            <div>
                {/*// <div className={styles["application-submission__upload-button-container"]}>*/}
                {/*<input*/}
                {/*    type="file"*/}
                {/*    onChange={(e) => setSelectedFile(e.currentTarget?.files?.[0])}*/}
                {/*    ref={fileInputRef}*/}
                {/*    hidden*/}
                {/*/>*/}
                {/*<Button*/}
                {/*    as="a"*/}
                {/*    variant="no-background"*/}
                {/*    data-testid={`${testId}__upload-file-button`}*/}
                {/*    onClick={() => fileInputRef?.current?.click()}*/}
                {/*    className={styles["application-submission__upload-file-button"]}*/}
                {/*>*/}
                {/*    {selectedFile ? selectedFile.name : "Dodaj załącznik"}*/}
                {/*</Button>*/}
            </div>
        ),
        [selectedFile, testId]
    );

    return (
        <>
            {isVisible && (
                <Formik
                    innerRef={formikRef}
                    initialValues={initialJobOfferApplicationSubmissionFormValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnMount
                >
                    {({ values, errors, resetForm, submitForm }) => (
                        <ModalWithContent
                            data-testid={`${testId}__apply-modal`}
                            className={classNames(styles["application-submission__modal"], {
                                [styles["application-submission__modal-second-step"]]: currentStepIndex === 1,
                            })}
                            tertiaryButtonContent={currentStepIndex === 1 ? uploadFileButton : undefined}
                            cancelButtonHidden
                            primaryButtonContent={getNextSubmitApplicationButtonText()}
                            onClose={() => onCloseModal(resetForm)}
                            secondaryAction={() => onPrevCancel(resetForm)}
                            primaryAction={() => onApply(submitForm)}
                            primaryButtonDisabled={
                                (currentStepIndex === 0 && !selectedCard)
                            }
                            isScrollable={true}
                            header={getApplicationSubmissionHeader()}
                            content={
                                <div className={styles["application-submission__modal-content"]}>
                                    {currentStepIndex === 0 && (positionsAvailable || 0) > 1 && (
                                        <GroupApplicationForm
                                            data-testid={`${testId}__group-application-form`}
                                            applicationType={applicationType}
                                            applicantsNumber={applicantsNumber}
                                            setApplicationType={setApplicationType}
                                            setApplicantsNumber={setApplicantsNumber}
                                            positionsAvailable={positionsAvailable}
                                        />
                                    )}
                                    {currentStepIndex === 0 && (
                                        <FormDropdown
                                            data-testid={`${testId}__application-type-dropdown`}
                                            id="application-submission__application-type-dropdown"
                                            options={applicationTypeDropdownOptions ?? []}
                                            selected={applicationTypeDropdownOptions[0]}
                                            onSelect={handleChangeApplicationType}
                                        />
                                    )}
                                    <StepSwitch
                                        currentStepIndex={currentStepIndex}
                                        stepContainerClassName={styles["application-submission__step-container"]}
                                    >
                                        <CardStep
                                            data-testid={`${testId}__data-step`}
                                            selectedCard={selectedCard}
                                            setSelectedCard={setSelectedCard}
                                        />
                                        <QualificationStep data-testid={`${testId}__qualification-step`} />
                                    </StepSwitch>
                                </div>
                            }
                        />
                    )}
                </Formik>
            )}
            {applicationSending && (
                <SpinnerWithMessage message={<FormattedMessage id="application-submission__sending" />} />
            )}
            {applicationSendSuccessModalVisible && (
                <ModalWithContent
                    header={renderApplicationSentSuccessHeader()}
                    content={
                        <span className={styles["application-submission__application-sent-successfully"]}>
                            <FormattedMessage id="application-submission__application-sent-successfully" />
                        </span>
                    }
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    primaryAction={() => setApplicationSendSuccessModalVisible(false)}
                    onClose={() => setApplicationSendSuccessModalVisible(false)}
                    cancelButtonHidden
                    data-testid={`${testId}__success-modal`}
                    className={styles["application-submission__application-sent-modal"]}
                />
            )}
        </>
    );
};

export default ApplicationSubmission;
