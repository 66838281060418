import OrientationLocksScreen from "features/common/components/OrientationLockScreen";
import { defaultLocale } from "features/common/constants";
import CookieBanner from "features/cookies/components/CookieBanner";
import { cookieNames } from "features/cookies/constants";
import { getCookie, setCookie } from "features/cookies/utils";
import Footer from "features/layout/components/Footer";
import Header from "features/layout/components/Header";
import Page from "features/layout/components/Page";
import routes from "features/routing";
import Router from "features/routing/components/Router";
import AuthenticationGateway from "features/user/components/AuthenticationGateway";
// import {logoutUserAsync} from "features/user/thunks";
import { useScrollTo } from "hooks/useScrollTo";
import React from "react";
import { IntlProvider } from "react-intl";
import { useLocation } from "react-router-dom";
import { getTranslatedMessages, Locale } from "../lang";
import { GTMPageView } from "../utils";

const App = () => {
    const [locale, setLocale] = React.useState<Locale>(getCookie(cookieNames.LOCALE) ?? defaultLocale);

    const location = useLocation();
    const { scrollUp } = useScrollTo();

    const setLocaleWithCookies = (locale: Locale) => {
        setCookie(cookieNames.LOCALE, locale);
        setLocale(locale);
    };

    React.useEffect(() => {
        if (!locale) {
            setCookie(cookieNames.LOCALE, defaultLocale);
        }
    }, [locale]);

    React.useEffect(() => {
        const handleRouteChange = (url: string) => GTMPageView(url);
        handleRouteChange(location.pathname)
    }, [location])

    React.useEffect(() => {
        scrollUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={getTranslatedMessages(locale)}>
            <OrientationLocksScreen>
                <AuthenticationGateway>
                    {(isUserLoggedInWithProfile, userRole) => (
                        <Page>
                            <Header locale={locale} setLocale={setLocaleWithCookies} />
                            <Page.Content>
                                <Router
                                    routes={routes}
                                    isUserLoggedInWithProfile={isUserLoggedInWithProfile}
                                    userRole={userRole}
                                    locale={locale}
                                />
                            </Page.Content>
                            <Footer locale={locale} setLocale={setLocaleWithCookies} />
                            <CookieBanner />
                        </Page>
                    )}
                </AuthenticationGateway>
            </OrientationLocksScreen>
        </IntlProvider>
    );
};

export default App;
