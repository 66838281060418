import { resetLocationSuggestions, resetSearchTermsSuggestions } from "features/common/actions";
import { selectLocationSuggestions, selectSearchTermSuggestions } from "features/common/selectors";
import { getLocationSuggestionsAsync, getSearchTermsSuggestionsAsync } from "features/common/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import SearchBoxForm from "./component";

const mapStateToProps = (state: RootState) => ({
    searchTermSuggestions: selectSearchTermSuggestions(state),
    locationSuggestions: selectLocationSuggestions(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getSearchTermsSuggestionsAsync,
            getLocationSuggestionsAsync,
            resetSearchTermsSuggestions: () => resetSearchTermsSuggestions(),
            resetLocationSuggestions: () => resetLocationSuggestions(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxForm);
