import { useEffect, useState } from "react";
import { isAfter } from "date-fns";

const key = "resendEmails";
const defaultValue = {};
const DELAY_VALUE = 60_000;

export const useEmailResend = (email: string) => {
    const produceStorageState = (prevValue: object, date: number) => JSON.stringify({ ...prevValue, [email]: date });

    const init = (): number => {
        try {
            const rawValue = window.localStorage.getItem(key);

            if (rawValue !== null) {
                const value = JSON.parse(rawValue);
                const emailValue = value[email];

                if (emailValue) {
                    return parseInt(emailValue);
                }

                const defaultEmailDate = Date.now() + DELAY_VALUE;
                window.localStorage.setItem(key, produceStorageState(value, defaultEmailDate));

                return defaultEmailDate;
            }
        } catch (error) {}

        const defaultEmailDate = Date.now() + DELAY_VALUE;
        window.localStorage.setItem(key, produceStorageState(defaultValue, defaultEmailDate));

        return defaultEmailDate;
    };

    const [emailDate, setEmailDate] = useState<number>(init);
    const [isAfterCurrentDate, setIsAfterCurrentDate] = useState<boolean>(isAfter(emailDate, new Date()));

    const resetEmailDate = () => {
        const date = Date.now() + DELAY_VALUE;
        try {
            const rawValue = window.localStorage.getItem(key);
            const value = JSON.parse(rawValue as string);

            window.localStorage.setItem(key, produceStorageState(value, date));
        } finally {
            setEmailDate(date);
            setIsAfterCurrentDate(true);
        }
    };

    useEffect(() => {
        if (!emailDate) return;
        const id = setTimeout(() => {
            setIsAfterCurrentDate(false);
        }, emailDate - Date.now());

        return () => clearTimeout(id);
    }, [emailDate]);

    return { isAfterCurrentDate, resetEmailDate };
};
