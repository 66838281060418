import classNames from "classnames";
import DefaultImage from "features/common/components/DefaultImage";
import { OfferType } from "features/common/types";
import BadgeStatus from "features/offers/components/BadgeStatus";
import { FinancialConditions, OfferStatus } from "features/offers/types";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    ["data-testid"]?: string;
    className?: string;
    photoBadge?: "status" | "bonus";
    bonus?: string;
    financialConditions?: FinancialConditions;
    businessName?: string;
    imageUrl?: string;
    status?: OfferStatus;
    offerType?: OfferType;
    topRightButton?: React.ReactElement | null;
    onImageClick?: () => void;
}

const CardImageWithBadge = ({
    "data-testid": testId,
    className,
    photoBadge,
    bonus,
    financialConditions,
    businessName,
    imageUrl,
    status,
    offerType,
    topRightButton,
    onImageClick,
}: Props) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (imageUrl) {
            setIsError(false);
        }
    }, [imageUrl]);

    const bonusMessage = useMemo(
        () =>
            financialConditions?.bonusValue
                ? `${financialConditions?.bonusValue} ${financialConditions?.bonusCurrencyCode}`
                : bonus,
        [bonus, financialConditions]
    );

    return (
        <div data-testid={testId} className={classNames(styles["card-image-with-badge"], className)}>
            {offerType === OfferType.Job ? (
                <div
                    data-testid={`${testId}__image`}
                    className={classNames(styles["card-image-with-badge__image-container"], {
                        [styles["card-image-with-badge__image-container--no-pointer"]]:
                            status === OfferStatus.Inactive || !onImageClick,
                    })}
                    onClick={() => onImageClick && onImageClick()}
                >
                    {!imageUrl || isError ? (
                        <DefaultImage />
                    ) : (
                        <img
                            className={styles["card-image-with-badge__image"]}
                            src={imageUrl}
                            alt={businessName}
                            onError={() => setIsError(true)}
                        />
                    )}
                </div>
            ) : (
                <div className={styles["card-image-with-badge__placeholder"]}></div>
            )}
            {photoBadge === "status" && (
                <BadgeStatus data-testid={`${testId}__status`} status={status} offerType={offerType} />
            )}
            {photoBadge === "bonus" && bonusMessage && (
                <div data-testid={`${testId}__bonus`} className={styles["card-image-with-badge__bonus-container"]}>
                    <span className={styles["card-image-with-badge__bonus-label"]}>
                        <FormattedMessage id="common__bonus" />
                    </span>
                    <span
                        data-testid={`${testId}__bonus-value`}
                        className={styles["card-image-with-badge__bonus-value"]}
                    >
                        {bonusMessage}
                    </span>
                </div>
            )}
            {!!topRightButton && (
                <div className={styles["card-image-with-badge__top-right-button"]}>{topRightButton}</div>
            )}
        </div>
    );
};

export default CardImageWithBadge;
