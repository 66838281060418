import React from "react";
import styled from "styled-components/macro";
import {margin, MarginProps, padding, PaddingProps} from "styled-system";
import Box from "../Box";

type StylesProps = MarginProps & PaddingProps

type Props = StylesProps & {
    children: React.ReactNode
}

const Tile = ({ children, ...styles }: Props) => {
    return <Wrapper {...styles}>{children}</Wrapper>
}

const Wrapper = styled(Box)`
  box-shadow: 4px 4px 15px rgb(0 38 119 / 25%);
  border-radius: 5px;
  padding: 16px;
  background-color: white;
  overflow: hidden;
  display: grid;
  
  &:hover {
    box-shadow: 4px 4px 15px rgb(0 38 119 / 50%);
  }
  
  ${margin};
  ${padding};
  
`

export default React.forwardRef(Tile)
