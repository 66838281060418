import React, { useEffect } from "react";

type TitleProps = {
    children: React.ReactNode,
    title: string | undefined
}

function TitleWrapper({ children, title }: TitleProps) {
    useEffect(() => {
        document.title = title || ""
    }, [])
    
    return <>{children}</>
}

export default TitleWrapper;