
export enum RateCategory {
    overall = "OVERALL",
    // Employer
    onboardOrganisation = "ONBOARD_ORGANISATION",
    compliance = "COMPLIANCE_OF_CONDITIONS_AND_OFFER",
    timeliness = "TIMELINESS_OF_PAYMENTS",
    atmosphere = "WORKPLACE_ATMOSPHERE",
    transport = "TRANSPORT_CONDITIONS",
    accomodation = "ACCOMMODATION_CONDITIONS",
    difficulty = "DIFFICULTY_OF_WORK",
    management = "MANAGEMENT_SATISFACTION",
    // Employee
    engagement = "ENGAGEMENT",
    ohs_compliance = "OHS_COMPLIANCE",
    punctuality = "PUNCTUALITY",
    availability = "AVAILABILITY",
    taking_care_of_equipment = "TAKING_CARE_OF_EQUIPMENT",
    personal_hygiene = "PERSONAL_HYGIENE",
    compliance_of_real_skills_with_declared = "COMPLIANCE_OF_REAL_SKILLS_WITH_DECLARED"
}

export interface RateDTO {
    category: RateCategory;
    rate: number;
}

export interface RateForm {
    assessedBusinessCardId?: string
    jobOfferId: string;
    isApplicant: boolean;
    rates: RateDTO[];
}

export interface RatePreviewResponse {
    id: number;
    employerFirstName: string;
    employerLastName: string;
    evaluatorUserId: string;
    evaluatorFirstname: string;
    evaluatorLastname: string;
    employeeFirstname: string;
    employeeLastname: string;
    assessedBusinessCardId: string;
    offerId: string;
    offerTitle: string;
    rates: Rate[];
}

export interface Rate {
    category: RateCategory,
    rate: number
}
