/* eslint-disable max-lines */
import { SalaryType } from "features/common/models";
import { PayoutDayType } from "features/jobOffers/types";
import { MyOffersCategory } from "features/offers/types";
import { CardOwnerRole } from "features/myProfile/types";
import { IntlShape } from "react-intl";

export const getStartDateMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__job-start-date",
    });

export const getEmployerCompanyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__employer-company",
    });

export const getDetailsStepPositionNameLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__position-name-label",
    });

export const getDetailsStepAvailablePositionsNumberLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__number-of-positions-to-be-filled-label",
    });

export const getRequirementStepPermissionsHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__permissions-header",
    });

export const getRequirementStepPermissionsLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__permissions-label",
    });

export const getRequirementStepLicensesAndCertificatesHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__licenses-and-certificates-header",
    });

export const getRequirementStepLicensesAndCertificatesLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__licenses-and-certificates-label",
    });

export const getRequirementStepProgramsHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__programs-header",
    });

export const getRequirementStepProgramsLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__programs-label",
    });

export const getRequirementStepExperienceHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__experience-header",
    });

export const getRequirementStepExperienceLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__experience-label",
    });

export const getRequirementStepExperienceNone = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offers__experience-none",
    });

export const getRequirementStepExperience35months = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offers__experience-3-5-months",
    });

export const getRequirementStepExperience624months = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offers__experience-6-24-months",
    });

export const getRequirementStepExperienceMoerThanTwoYears = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offers__experience-more-than-two-years",
    });

export const getRequirementStepOtherHeader = (intl: IntlShape, cardKind: CardOwnerRole = "EMPLOYER") =>
    intl.formatMessage({
        id: "create-offer__other-header",
    }, {
        cardKind,
    });

export const getRequirementStepOtherLabel = (intl: IntlShape, cardKind: CardOwnerRole = "EMPLOYER") =>
    intl.formatMessage({
        id: "create-offer__other-label",
    }, {
        cardKind,
    });

export const getRequirementStepEducationHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-header",
    });

export const getRequirementStepEducationLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-label",
    });

export const getRequirementStepEducationNone = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-none",
    });

export const getRequirementStepEducationPrimary = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-primary",
    });

export const getRequirementStepEducationVocational = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-vocational",
    });

export const getRequirementStepEducationSecondary = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-secondary",
    });

export const getRequirementStepEducationSecondaryTechnical = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-secondary-technical",
    });

export const getRequirementStepEducationHigher = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-higher",
    });

export const getRequirementStepEducationOther = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__education-other",
    });

export const getRequirementStepDrivingLicenseHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__driving-license-header",
    });

export const getRequirementStepDrivingLicenseLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__driving-license-label",
    });

export const getRequirementStepLanguagesHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-header",
    });

export const getRequirementStepLanguagesLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-label",
    });

export const getRequirementStepLanguagesEnglish = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-english",
    });

export const getRequirementStepLanguagesArabic = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-arabic",
    });

export const getRequirementStepLanguagesCzech = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-czech",
    });

export const getRequirementStepLanguagesFrench = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-french",
    });

export const getRequirementStepLanguagesHindi = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-hindi",
    });

export const getRequirementStepLanguagesSpanish = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-spanish",
    });

export const getRequirementStepLanguagesDutch = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-dutch",
    });

export const getRequirementStepLanguagesGerman = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-german",
    });

export const getRequirementStepLanguagesPolish = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-polish",
    });

export const getRequirementStepLanguagesRussian = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-russian",
    });

export const getRequirementStepLanguagesRomanian = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-romanian",
    });

export const getRequirementStepLanguagesUkrainian = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-ukrainian",
    });

export const getRequirementStepLanguagesItalian = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-italian",
    });

export const getRequirementStepLanguagesOther = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-other",
    });

export const getRequirementStepLanguagesLevel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-level",
    });

export const getRequirementStepLanguageLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__language-label",
    });

export const getRequirementStepLanguagesLevelBasic = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-level-basic",
    });

export const getRequirementStepLanguagesLevelMedium = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-level-medium",
    });

export const getRequirementStepLanguagesLevelAdvanced = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-level-advanced",
    });

export const getRequirementStepLanguagesLevelNative = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__languages-level-native",
    });

export const getRateMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__rate",
    });

export const getBonusDateLabelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__bonus-payout-day",
    });

export const getDayMessage = (intl: IntlShape, numberOfDays: number) =>
    intl.formatMessage(
        {
            id: "common__day",
        },
        { numberOfDays }
    );

export const getWeekMessage = (intl: IntlShape, numberOfWeeks: number) =>
    intl.formatMessage(
        {
            id: "common__week",
        },
        { numberOfWeeks }
    );

export const getMonthMessage = (intl: IntlShape, numberOfMonths: number) =>
    intl.formatMessage(
        {
            id: "common__month",
        },
        { numberOfMonths }
    );

export const getMinimumAmountMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__minimum-amount",
    });

export const getApplicantsNumberMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "application-submission__applicants-number",
    });

export const getIndividuallyMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "application-submission__as-individual" });

export const getDescriptionPlaceholderMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "application-submission__description" });

export const getCreatePayoutDayMessage = (intl: IntlShape) => {
    const oneDayMessage = getDayMessage(intl, 1);
    const threeDaysMessage = getDayMessage(intl, 3);
    const fiveDaysMessage = getDayMessage(intl, 5);
    const oneWeekMessage = getWeekMessage(intl, 1);
    const twoWeeksMessage = getWeekMessage(intl, 2);
    const oneMonthMessage = getMonthMessage(intl, 1);
    const twoMonthsMessage = getMonthMessage(intl, 2);
    const threeMonthsMessage = getMonthMessage(intl, 3);

    return (payoutDay: PayoutDayType | string) => {
        switch (payoutDay) {
            case PayoutDayType.FirstDay:
                return oneDayMessage;
            case PayoutDayType.ThirdDay:
                return threeDaysMessage;
            case PayoutDayType.FifthDay:
                return fiveDaysMessage;
            case PayoutDayType.OneWeek:
                return oneWeekMessage;
            case PayoutDayType.TwoWeeks:
                return twoWeeksMessage;
            case PayoutDayType.OneMonth:
                return oneMonthMessage;
            case PayoutDayType.TwoMonths:
                return twoMonthsMessage;
            case PayoutDayType.ThreeMonths:
                return threeMonthsMessage;
        }
    };
};

export const getCreateMyJobOffersEmptyHeader = (intl: IntlShape) => {
    const noOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-offers-header",
    });
    const noActiveOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-active-offers-header",
    });
    const noInactiveOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-inactive-offers-header",
    });
    const noEndedOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-ended-offers-header",
    });

    return (category: MyOffersCategory) => {
        switch (category) {
            case MyOffersCategory.All:
                return noOffersMessage;
            case MyOffersCategory.Active:
                return noActiveOffersMessage;
            case MyOffersCategory.Inactive:
                return noInactiveOffersMessage;
            case MyOffersCategory.Ended:
                return noEndedOffersMessage;
        }
    };
};

export const getCreateMyJobOffersEmptyMessage = (intl: IntlShape) => {
    const noOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-offers-message",
    });
    const noActiveOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-active-offers-message",
    });
    const noInactiveOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-inactive-offers-message",
    });
    const noEndedOffersMessage = intl.formatMessage({
        id: "my-job-offers__no-ended-offers-message",
    });

    return (category: MyOffersCategory) => {
        switch (category) {
            case MyOffersCategory.All:
                return noOffersMessage;
            case MyOffersCategory.Active:
                return noActiveOffersMessage;
            case MyOffersCategory.Inactive:
                return noInactiveOffersMessage;
            case MyOffersCategory.Ended:
                return noEndedOffersMessage;
        }
    };
};

export const getCreateSalaryTypeMessage = (intl: IntlShape) => (salaryType: SalaryType) =>
    intl.formatMessage(
        salaryType === SalaryType.Monthly
            ? { id: "offers-item__salary-type-month" }
            : { id: "offers-item__salary-type-hourly" }
    );

export const getDetailsApplicationDateMessage = (intl: IntlShape, applicationDate: string) =>
    intl.formatMessage(
        {
            id: "application-details__application-date",
        },
        {
            applicationDate,
        }
    );

export const getDetailsApplicantsNumberMessage = (intl: IntlShape, applicantsNumber: number) =>
    intl.formatMessage(
        {
            id: "application-details__applicants-number",
        },
        {
            applicantsNumber,
        }
    );

export const getDetailsAcceptedApplicantsNumberMessage = (intl: IntlShape, acceptedApplicantsNumber: number) =>
    intl.formatMessage(
        {
            id: "application-details__accepted-applicants-number",
        },
        {
            acceptedApplicantsNumber,
        }
    );

export const getDetailsJobApplicantNameMessage = (intl: IntlShape, applicantName: string) =>
    intl.formatMessage(
        {
            id: "application-details__job-applicant",
        },
        {
            applicantName,
        }
    );

export const getDetailsOfferOwnerNameMessage = (intl: IntlShape, applicantName: string) =>
    intl.formatMessage(
        {
            id: "application-details__offer-owner",
        },
        {
            applicantName,
        }
    );

export const getDetailsJobApplicantUserMessage = (intl: IntlShape, applicantName: string) =>
    intl.formatMessage(
        {
            id: "application-details__job-applicant-contact",
        },
        {
            applicantName,
        }
    );
