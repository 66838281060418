import Api from "api";
import { CancelToken } from "api/utils";
import config from "config";
import {
    BranchesDto,
    CardsDto,
    CountriesDto,
    CurrenciesDto,
    LocationsDetailsDto,
    LocationSuggestionDto,
    LocationTypeDto,
    TermSuggestionDto,
} from "features/common/dtos";
import { mapArrayToQueryParams } from "features/common/mappers/mapArrayToQueryParams";
import { OfferType } from "features/common/types";

export class CommonApi extends Api {
    getBranchesAsync = (cancelToken?: CancelToken): Promise<BranchesDto> => {
        return this.get<BranchesDto>(`${config.apiRoutes.BRANCHES}`, cancelToken);
    };

    getSearchTermsSuggestionsAsync = (
        offerType: OfferType,
        searchData: string,
        cancelToken?: CancelToken
    ): Promise<TermSuggestionDto> => {
        // return this.get<TermSuggestionDto>(
        //     `${
        //         offerType === OfferType.Job ? config.apiRoutes.OFFERS : config.apiRoutes.EMPLOYEE_OFFERS
        //     }/suggestions?title=${encodeURIComponent(searchData)}`,
        //     cancelToken
        // );
        return Promise.resolve<TermSuggestionDto>({ items: [] });
    };

    getCurrenciesAsync = (cancelToken?: CancelToken): Promise<CurrenciesDto> => {
        return this.get<CurrenciesDto>(`${config.apiRoutes.CURRENCIES}`, cancelToken);
    };

    getLocationSuggestionsAsync = (searchData: string, cancelToken?: CancelToken): Promise<LocationSuggestionDto> => {
        // return this.get<LocationSuggestionDto>(
        //     `${config.apiRoutes.LOCATIONS}/suggestions?searchPhrase=${encodeURIComponent(searchData)}`,
        //     cancelToken
        // );

        return Promise.resolve<LocationSuggestionDto>({
            cities: [
                { name: "Warszawa", region: "Mazowieckie", country: "Polska", locationId: "1" },
                { name: "Gdańsk", region: "Pomorskie", country: "Polska", locationId: "2" },
                { name: "Sopot", region: "Pomorskie", country: "Polska", locationId: "3" },
                { name: "Szczecin", region: "Zachodniopomorskie", country: "Polska", locationId: "4" },
                { name: "Kraków", region: "Małopolskie", country: "Polska", locationId: "5" },
            ],
            regions: [
                // { name: "Mazowieckie", country: "Polska", locationId: "2" },
                // { name: "Pomorskie", country: "Polska", locationId: "3" },
            ],
            countries: [],
        });
    };

    getLocationDetailsAsync = (locationId: string, cancelToken?: CancelToken): Promise<LocationSuggestionDto> => {
        // return this.get(`${config.apiRoutes.LOCATIONS}/${locationId}`, cancelToken);

        return Promise.resolve<LocationSuggestionDto>({
            cities: [
                { name: "Warszawa", region: "Mazowieckie", country: "Polska", locationId: "1" },
                { name: "Gdańsk", region: "Pomorskie", country: "Polska", locationId: "2" },
                { name: "Sopot", region: "Pomorskie", country: "Polska", locationId: "3" },
                { name: "Szczecin", region: "Zachodniopomorskie", country: "Polska", locationId: "4" },
                { name: "Kraków", region: "Małopolskie", country: "Polska", locationId: "5" },
            ],
            regions: [
                // { name: "Mazowieckie", country: "Polska", locationId: "2" },
                // { name: "Pomorskie", country: "Polska", locationId: "3" },
            ],
            countries: [],
        });
    };

    // TODO: IWGM-1157 Rename the thunk and all associated variables
    // as cards returned from this function are no longer used exclusively during offer creation.
    getOfferCreationCardsAsync = async (cancelToken?: CancelToken): Promise<CardsDto> => {
        return this.get(config.apiRoutes.GET_OFFER_CREATION_CARDS, cancelToken);
    };

    createCardAsync = async (formData: FormData, cancelToken?: CancelToken): Promise<void> => {
        return this.post(config.apiRoutes.CARDS, formData, cancelToken);
    };

    updateCardAsync = async (formData: FormData, cancelToken?: CancelToken): Promise<void> => {
        return this.put(config.apiRoutes.CARDS, formData, cancelToken);
    };

    getLocationsDetailsAsync = (locationsIds: string[], cancelToken?: CancelToken): Promise<LocationsDetailsDto> => {
        // const params = mapArrayToQueryParams("locationsIds", locationsIds);
        // return this.get(`${config.apiRoutes.LOCATIONS}?${params}`, cancelToken);
        return Promise.resolve<LocationsDetailsDto>({
            locationsDetails: [
                {
                    id: "1",
                    name: "Warszawa",
                    locationType: LocationTypeDto.City,
                },
                {
                    id: "2",
                    name: "Gdańsk",
                    locationType: LocationTypeDto.City,
                },
                {
                    id: "3",
                    name: "Sopot",
                    locationType: LocationTypeDto.City,
                },
                {
                    id: "4",
                    name: "Szczecin",
                    locationType: LocationTypeDto.City,
                },
                {
                    id: "5",
                    name: "Kraków",
                    locationType: LocationTypeDto.City,
                },
                // {
                //     id: "2",
                //     name: "Mazowieckie",
                //     locationType: LocationTypeDto.Region,
                // },
                // {
                //     id: "3",
                //     name: "Pomorskie",
                //     locationType: LocationTypeDto.Region,
                // },
                // {
                //     id: "4",
                //     name: "Polska",
                //     locationType: LocationTypeDto.Country,
                // },
            ],
        });
    };

    getCountriesAsync = (lang: string, cancelToken?: CancelToken, ): Promise<CountriesDto[]> => {
        return this.get(`${config.apiRoutes.COUNTRIES}?locale=${lang}`, cancelToken);
    };
}

export default new CommonApi();
