import { withGetCall } from "features/getCall/HOC/withGetCall";
import { selectGetCallState } from "features/getCall/selectors";
import {
    selectConversationDetails,
    selectConversationDetailsLoading,
    selectConversationDetailsError,
    selectSendMessageLoading,
    selectSendMessageSuccess,
    selectSendMessageError,
} from "features/messages/selectors";
import { getConversationDetailsAsync, sendMessageAsync } from "features/messages/thunks";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import ConversationDetails from "./component";
import { answerCallAction, leaveCallAction, makeCallAction, setStreamAction, setSocketAction, setCallAction } from "features/getCall/actions";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    conversationDetails: selectConversationDetails(state),
    conversationDetailsLoading: selectConversationDetailsLoading(state),
    conversationDetailsError: selectConversationDetailsError(state),
    sendMessageLoading: selectSendMessageLoading(state),
    sendMessageSuccess: selectSendMessageSuccess(state),
    sendMessageError: selectSendMessageError(state),
    ...selectGetCallState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getConversationDetailsAsync,
            sendMessageAsync,
            answerCallAction: () => answerCallAction(),
            leaveCallAction: () => leaveCallAction(),
            makeCallAction: () => makeCallAction(),
            setStreamAction,
            setSocketAction,
            setCallAction
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withGetCall(ConversationDetails));