import JobOffersComponent from "features/jobOffers/components/JobOffers";
import React from "react";
import styles from "./styles.module.scss";

const JobOffers = () => {
    return (
        <div className={styles["offers"]}>
            <JobOffersComponent />
        </div>
    );
};

export default JobOffers;
