import SliderImageFullHd from "assets/images/hero-bg-full-hd.png";
import SliderImgMobile from "assets/images/hero-bg-mobile.png";
import SliderImgTablet from "assets/images/hero-bg-tablet.png";
import SliderImage from "assets/images/hero-bg.png";
import Box from "components/Box";
import React from "react";
import styled from "styled-components/macro";
import HeroComments from "./components/HeroComments";

const Hero = () => (
  <Wrapper width="100%">
      <Image >
        <HeroComments />
      </Image>
  </Wrapper >
);

const Wrapper = styled( Box )`
  background-color: #EFF1F5;
`;

const Image = styled( Box )`
  height: 20.75rem;
  background-image: url(${SliderImgMobile});
  background-size: cover;
  background-position: right;

  @media screen and (min-width: 415px) {
    height: 38.5rem;
    margin-top: -6.5rem;
    margin-right: -9.5rem;
    background-image: url(${SliderImgTablet});
  }

  @media screen and (min-width: 685px) {
    margin-right: 0;
  }

  @media screen and (min-width: 834px){
    margin-top: -2.5rem;
  }

  @media screen and (min-width: 862px) {
    height: 45.5rem;
    margin-top: 2rem;
    background-image: url(${SliderImage});
  }

  @media screen and (min-width: 1441px) {
    height: 38.375rem;
    max-width: 125rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-image: url(${SliderImageFullHd});
    background-position: center;
  }
`;

export default Hero;