import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import classNames from "classnames";
import useTitleSubstring from "hooks/useTitleSubstring";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export interface BreadcrumbsItem {
    displayName: string;
    path?: string;
    hasCharsLimit?: boolean;
    charsLimit?: number;
}

export interface Props {
    crumbs: BreadcrumbsItem[];
    ["data-testid"]?: string;
}

const Breadcrumbs = ({ crumbs, "data-testid": testId = "breadcrumbs" }: Props) => {
    const history = useHistory();
    const { changedTitle } = useTitleSubstring(crumbs[crumbs.length - 1].displayName, 6, 24, 50, 60, 90);

    const onCrumbClick = useCallback(
        (path?: string) => {
            path && history.push({ pathname: path, state: { cameBack: true } });
        },
        [history]
    );

    return crumbs.length === 0 ? null : (
        <div data-testid={testId} className={styles["breadcrumbs"]}>
            <div data-testid={`${testId}__crumbs-container`} className={styles["breadcrumbs__crumbs-container"]}>
                {crumbs.map((crumb, index) => (
                    <div key={index} className={styles["breadcrumbs__nav-line"]}>
                        {index !== 0 && (
                            <ChevronRight
                                key={`chevron-${index}`}
                                data-testid={`${testId}__chevron-right`}
                                className={styles["breadcrumbs__chevron-right"]}
                            />
                        )}
                        <span
                            key={`crumb-${index}`}
                            data-testid={`${testId}__crumb`}
                            className={classNames(styles["breadcrumbs__crumb"], {
                                [styles["breadcrumbs__crumb--last"]]: index === crumbs.length - 1,
                            })}
                            title={crumb.displayName}
                            onClick={() => (index !== crumbs.length - 1 ? onCrumbClick(crumb.path) : undefined)}
                        >
                            {index === 2 && crumb.path && crumb.path !== '/my-profile' ? changedTitle : crumb.displayName}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Breadcrumbs;