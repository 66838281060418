import { IntlShape } from "react-intl";

export const getSearchForOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__search-for-offer",
    });

export const getWorkTitleMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__work-title",
    })

export const getManageMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__manage-message",
    })

export const getOfferDateMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__offer-date",
    })

export const getJobOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__see-job-offer",
    })

export const getApproveOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__approve-offer",
    })

export const getBlockOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "moderator-panel__block-offer",
    })
