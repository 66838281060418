import classNames from "classnames";
import DropdownOptionsWithSection from "features/common/components/DropdownOptionsWithSection";
import DropdownSelectionProvider from "features/common/components/DropdownSelectionProvider";
import Input from "features/common/components/Input";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useScroll from "features/common/hooks/useScroll";
import { getSectionDisplayNameLookup } from "features/common/translationMessages";
import {
    DropdownOption,
    ScrollDropdownBreakpoint,
    SectionName,
    TranslatedSectionDropdownOption,
    ValidationProps,
} from "features/common/types";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";

export type Props<T> = ValidationProps & {
    label?: string;
    className?: string;
    value?: { input: string; option: DropdownOption<T>; section: string };
    name?: string;
    placeholder: string;
    sections: TranslatedSectionDropdownOption<T>[];
    shouldEmphasizeSearchTerm?: boolean;
    helperMessages?: (string | React.ReactElement)[];
    fieldOptionsClassName?: string;
    doNotHideOptionsOnScroll?: boolean;
    spacelessMessages?: boolean;
    onChange: (input: string, option: DropdownOption<T>, section: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onClear?: () => void;
    ["data-testid"]?: string;
};

const SearchInput = <T extends any>({
    label,
    className,
    name,
    sections,
    placeholder,
    value,
    shouldEmphasizeSearchTerm,
    isInvalid,
    error,
    fieldOptionsClassName,
    helperMessages,
    doNotHideOptionsOnScroll,
    withValidation,
    spacelessMessages,
    onChange,
    onFocus,
    onBlur,
    onClear,
    "data-testid": testId,
}: Props<T>) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();

    const sectionLookup = getSectionDisplayNameLookup(intl);

    const [expanded, setExpanded] = useState(false);
    const [removeInputFocus, setRemoveInputFocus] = useState(false);

    const onClickAway = () => {
        if (expanded) {
            setExpanded(false);
        }
    };

    const onExpandToggle = (event: React.ChangeEvent<HTMLInputElement> & React.MouseEvent<HTMLInputElement> | any) => {
        if (onFocus) {
            onFocus();
        }
        setExpanded(event.target.value ? true : false);
    };

    const onOptionClick = (option: DropdownOption<T>, section?: string) => {
        setExpanded(false);

        onChange(option.display as string, option, section as string);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpanded(event.target.value ? true : false);
        onChange(event.target.value, { display: "", value: undefined as T }, "");
    };

    const clearValue = () => {
        setExpanded(false);
        onChange("", { display: "", value: undefined as T }, "");
        onClear && onClear();
    };

    const onScroll = () => {
        if (doNotHideOptionsOnScroll) {
            return;
        }

        const y = window.scrollY;
        if (y > ScrollDropdownBreakpoint[deviceClass].search) {
            setExpanded(false);
            setRemoveInputFocus(true);
        } else {
            setRemoveInputFocus(false);
        }
    };

    const getSections = useCallback(() => {
        return sections.map((s) => ({
            section: { display: sectionLookup(s.section.value as SectionName), value: s.section.value },
            options: [...s.options],
        }));
    }, [sectionLookup, sections]);

    const mappedSections = getSections();

    useScroll(onScroll, [deviceClass, expanded]);

    return (
        <div className={classNames(styles["search-input"], className)} data-testid={testId}>
            <DropdownSelectionProvider expanded={expanded} onOptionSelected={onOptionClick} sections={mappedSections}>
                {({ selectedOption, onKeyDown, resetSelectedIndex, lastDirection }) => (
                    <ClickAwayListener onClickAway={onClickAway}>
                        <div>
                            <Input
                                data-testid="search-input__input"
                                fieldClassName={styles["search-input__field"]}
                                name={name}
                                label={label}
                                placeholder={placeholder}
                                onChange={(e) => {
                                    resetSelectedIndex();
                                    handleChange(e);
                                }}
                                onFocus={onExpandToggle as any}
                                onKeyDown={onKeyDown}
                                value={
                                    value?.option?.additionalData
                                        ? `${value?.input}, ${value?.option.additionalData}`
                                        : value?.input
                                }
                                onClearValue={clearValue}
                                removeFocus={removeInputFocus}
                                clearIconVisible
                                helperMessages={!expanded ? helperMessages : undefined}
                                error={error}
                                isInvalid={!expanded && isInvalid}
                                withValidation={withValidation}
                                onBlur={onBlur}
                                spacelessMessages={spacelessMessages}
                            />
                            {expanded && (
                                <DropdownOptionsWithSection
                                    id="search-input-id"
                                    sections={mappedSections}
                                    selectedOption={selectedOption}
                                    onSelectOption={onOptionClick}
                                    shouldEmphasizeSearchTerm={shouldEmphasizeSearchTerm}
                                    searchTerm={value?.input}
                                    lastDirection={lastDirection}
                                    fieldOptionsClassName={fieldOptionsClassName}
                                />
                            )}
                        </div>
                    </ClickAwayListener>
                )}
            </DropdownSelectionProvider>
        </div>
    );
};

export default SearchInput;
