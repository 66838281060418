import Button from "features/common/components/Button";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import FormDropdown from "features/common/components/FormDropdown";
import RadioButtons from "features/common/components/RadioButtons";
import TextArea from "features/common/components/TextArea";
import { DropdownOption, RadioButtonOption } from "features/common/types";
import { ContactFormSchema } from "features/contactForm/schemas";
import { getMessageTextMessage, getTopicMessage } from "features/contactForm/translationMessages";
import { RoleType } from "features/contactForm/types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    role: RoleType;
    message: string;
    radioButtonOptions: RadioButtonOption<number>[];
    topicDropdownOptions: DropdownOption<number>[];
    topicTouched: boolean;
    messageTouched: boolean;
    isFormValid: boolean;
    errors: ContactFormSchema;
    onRoleChange: (value: number) => void;
    onTopicSelect: (value: number) => void;
    onTopicToggle: (expanded: boolean) => void;
    onMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onMessageBlur: () => void;
    onContactFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    ["data-testid"]?: string;
    topic?: DropdownOption<number>;
    contactFormMessageSending?: boolean;
}

const Form = ({
    role,
    topic,
    message,
    radioButtonOptions,
    topicDropdownOptions,
    topicTouched,
    messageTouched,
    isFormValid,
    errors,
    contactFormMessageSending,
    onRoleChange,
    onTopicSelect,
    onTopicToggle,
    onMessageChange,
    onMessageBlur,
    onContactFormSubmit,
    "data-testid": testId,
}: Props) => {
    const intl = useIntl();

    const topicPlaceholder = getTopicMessage(intl);
    const messagePlaceholder = getMessageTextMessage(intl);

    return (
        <form
            data-testid={`${testId}__form`}
            id="contactForm"
            className={styles["contact-form__form"]}
            onSubmit={onContactFormSubmit}
        >
            <div className={styles["contact-form__section"]}>
                <span>
                    <FormattedMessage id="contact-form__introduce" />
                </span>
                <RadioButtons
                    inputClassName={styles["contact-form__radio-button-input"]}
                    labelClassName={styles["contact-form__radio-button-label"]}
                    data-testid={`${testId}__radio-buttons`}
                    options={radioButtonOptions}
                    value={role}
                    onValueChange={(value) => onRoleChange(value)}
                />
            </div>

            <div className={styles["contact-form__section"]}>
                <span>
                    <FormattedMessage id="contact-form__topic-header" />
                </span>
                <div className={styles["contact-form__dropdown-container"]}>
                    <FormDropdown
                        id="contact-form__topic-dropdown"
                        data-testid={`${testId}__dropdown`}
                        placeholder={`${topicPlaceholder}*`}
                        label={`${topicPlaceholder}*`}
                        helperMessages={[<FieldRequiredMessage />]}
                        options={topicDropdownOptions}
                        selected={topic}
                        error={errors.topic}
                        isInvalid={topicTouched && !!errors.topic}
                        withValidation
                        onSelect={onTopicSelect}
                        onToggle={onTopicToggle}
                    />
                </div>
                <div className={styles["contact-form__textarea-container"]}>
                    <TextArea
                        data-testid={`${testId}__text-area`}
                        value={message}
                        rows={16}
                        placeholder={messagePlaceholder}
                        error={errors.message}
                        isInvalid={messageTouched && !!errors.message}
                        withValidation
                        maxSize={2000}
                        onChange={onMessageChange}
                        onBlur={onMessageBlur}
                    />
                </div>
            </div>
            <div className={styles["contact-form__control-button-container"]}>
                <Button
                    data-testid={`${testId}__button`}
                    disabled={!isFormValid || contactFormMessageSending}
                    isLoading={contactFormMessageSending}
                >
                    <FormattedMessage id="common__send" />
                </Button>
            </div>
        </form>
    );
};

export default Form;
