import { ApiError } from "api/utils";
import config from "config";
import { amendPageToState } from "features/common/utils";
import EmployeeOffersAction, {
    amendEmployeeOffers,
    getEmployeeOfferDetails,
    getEmployeeOffers,
    getMyEmployeeOffers,
    resetCreateEmployeeOfferState,
    resetEmployeeOffers,
    resetEmployeeOffersFilterMatchCounts,
    resetEmployeeSendMessageFlags,
    resetMyEmployeeOffers,
    saveEmployeeOffer,
    sendEmployeeOfferMessage,
    createEmployeeOffer,
    resetEmployeeOfferDetails,
} from "features/employeeOffers/actions";
import {
    mapEmployeeOfferDetailsResponseDtoToOfferDetails,
    mapEmployeeOffersDtoToEmployeeOffers,
    mapEmployeeOffersFilterMatchCountsDtoToEmployeeOffersFilterMatchCounts,
    mapMyEmployeeOffersResponseDtoMyEmployeeOfferCardDetails,
} from "features/employeeOffers/mappers";
import { EmployeeOffer, EmployeeOfferDetails, MyEmployeeOfferCardDetails } from "features/employeeOffers/models";
import { EmployeeOffersFilterMatchCounts } from "features/employeeOffers/types";
import { mapCreateOfferApiErrorsToCreateOfferComponentErrors } from "features/offers/mappers";
import { CreateOfferApiError, CreateOfferComponentError } from "features/offers/types";
import produce from "immer";
import { getType } from "typesafe-actions";

export interface EmployeeOffersState {
    employeeOffers?: (EmployeeOffer | undefined)[];
    employeeOffersLoading?: boolean;
    employeeOffersLoadingError?: ApiError;
    myEmployeeOffers: Map<Number, MyEmployeeOfferCardDetails>;
    myEmployeeOffersRequestedPages: Set<Number>;
    myEmployeeOffersPage: number;
    myEmployeeOffersTotalPages?: number;
    myEmployeeOffersLoading: boolean;
    myEmployeeOffersLoadingError?: ApiError;
    filterMatchCounts?: EmployeeOffersFilterMatchCounts;
    filterMatchCountsLoading?: boolean;
    filterMatchCountsLoadingError?: ApiError;
    totalEmployeeOffers?: number;
    employeeOfferMessageSending?: boolean;
    employeeOfferMessageSendingSuccess?: boolean;
    employeeOfferMessageSendingError?: ApiError;
    employeeOfferDetails?: EmployeeOfferDetails;
    employeeOfferDetailsLoading?: boolean;
    employeeOfferDetailsLoadingError?: ApiError;
    employeeOfferCreating?: boolean;
    employeeOfferCreatingSuccess?: number;
    employeeOfferCreatingError?: ApiError;
    employeeOfferCreatingErrorsArray?: CreateOfferComponentError[];
}

const reducer = (
    state: EmployeeOffersState = {
        myEmployeeOffersLoading: false,
        myEmployeeOffers: new Map<Number, MyEmployeeOfferCardDetails>(),
        myEmployeeOffersRequestedPages: new Set<Number>(),
        myEmployeeOffersPage: 0,
    },
    action: EmployeeOffersAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getEmployeeOffers.request):
                draft.employeeOffersLoading = true;
                draft.employeeOffersLoadingError = undefined;
                break;
            case getType(getEmployeeOffers.success):
                draft.employeeOffersLoading = false;
                draft.employeeOffers = mapEmployeeOffersDtoToEmployeeOffers(action.payload);
                draft.filterMatchCounts = mapEmployeeOffersFilterMatchCountsDtoToEmployeeOffersFilterMatchCounts(
                    action.payload
                );
                draft.employeeOffersLoadingError = undefined;
                draft.totalEmployeeOffers = action.payload.data.page.total;
                break;
            case getType(getEmployeeOffers.failure):
                draft.employeeOffersLoading = !action.payload || false;
                draft.employeeOffers = undefined;
                draft.totalEmployeeOffers = undefined;
                draft.employeeOffersLoadingError = action.payload;
                break;
            case getType(amendEmployeeOffers.request):
                draft.employeeOffersLoading = true;
                draft.employeeOffersLoadingError = undefined;
                break;
            case getType(amendEmployeeOffers.success):
                draft.employeeOffersLoading = false;
                draft.employeeOffers = amendPageToState(
                    mapEmployeeOffersDtoToEmployeeOffers(action.payload),
                    config.offersResultsPerPage,
                    action.payload.data.page.pageable.pageNumber,
                    state.employeeOffers
                );
                draft.employeeOffersLoadingError = undefined;
                draft.totalEmployeeOffers = action.payload.data.page.total;
                break;
            case getType(amendEmployeeOffers.failure):
                draft.employeeOffersLoading = false;
                draft.employeeOffers = undefined;
                draft.totalEmployeeOffers = undefined;
                draft.employeeOffersLoadingError = action.payload;
                break;
            case getType(getMyEmployeeOffers.request):
                const setTmp = new Set(state.myEmployeeOffersRequestedPages);
                setTmp.add(state.myEmployeeOffersPage);
                draft.myEmployeeOffersLoading = true;
                draft.myEmployeeOffersLoadingError = undefined;
                draft.myEmployeeOffersRequestedPages = setTmp;
                break;
            case getType(getMyEmployeeOffers.success):
                const tmpMap = new Map<Number, MyEmployeeOfferCardDetails>(state.myEmployeeOffers);
                mapMyEmployeeOffersResponseDtoMyEmployeeOfferCardDetails(action.payload).forEach((data) => {
                    tmpMap.set(data.id, data);
                });
                draft.myEmployeeOffers = tmpMap;
                if (state.myEmployeeOffersRequestedPages.has(state.myEmployeeOffersPage)) {
                    draft.myEmployeeOffersPage = state.myEmployeeOffersPage + 1;
                }
                draft.myEmployeeOffersTotalPages = action.payload.data.totalPages;
                draft.myEmployeeOffersLoading = false;
                draft.myEmployeeOffersLoadingError = undefined;
                break;
            case getType(getMyEmployeeOffers.failure):
                draft.myEmployeeOffersLoading = false;
                draft.myEmployeeOffersLoadingError = action.payload;
                break;
            case getType(getEmployeeOfferDetails.request):
                draft.employeeOfferDetails = undefined;
                draft.employeeOfferDetailsLoading = true;
                draft.employeeOfferDetailsLoadingError = undefined;
                draft.employeeOffersLoading = false;
                draft.employeeOffers = undefined;
                draft.totalEmployeeOffers = undefined;
                draft.employeeOffersLoadingError = undefined;
                draft.employeeOfferCreating = false;
                draft.employeeOfferCreatingSuccess = undefined;
                draft.employeeOfferCreatingError = undefined;
                break;
            case getType(getEmployeeOfferDetails.success):
                draft.employeeOfferDetails = mapEmployeeOfferDetailsResponseDtoToOfferDetails(action.payload);
                draft.employeeOfferDetailsLoading = false;
                draft.employeeOfferDetailsLoadingError = undefined;
                break;
            case getType(getEmployeeOfferDetails.failure):
                draft.employeeOfferDetails = undefined;
                draft.employeeOfferDetailsLoading = false;
                draft.employeeOfferDetailsLoadingError = action.payload;
                break;
            case getType(sendEmployeeOfferMessage.request):
                draft.employeeOfferMessageSending = true;
                draft.employeeOfferMessageSendingSuccess = false;
                draft.employeeOfferMessageSendingError = undefined;
                break;
            case getType(sendEmployeeOfferMessage.success):
                draft.employeeOfferMessageSending = false;
                draft.employeeOfferMessageSendingSuccess = true;
                draft.employeeOfferMessageSendingError = undefined;
                break;
            case getType(sendEmployeeOfferMessage.failure):
                draft.employeeOfferMessageSending = false;
                draft.employeeOfferMessageSendingSuccess = false;
                draft.employeeOfferMessageSendingError = action.payload;
                break;
            case getType(createEmployeeOffer.request):
                draft.employeeOfferCreating = true;
                draft.employeeOfferCreatingSuccess = undefined;
                draft.employeeOfferCreatingError = undefined;
                draft.employeeOfferCreatingErrorsArray = undefined;
                break;
            case getType(createEmployeeOffer.success):
                draft.employeeOfferCreating = false;
                draft.employeeOfferCreatingSuccess = action.payload.data.id;
                draft.employeeOfferCreatingError = undefined;
                draft.employeeOfferCreatingErrorsArray = undefined;
                break;
            case getType(createEmployeeOffer.failure):
                draft.employeeOfferCreating = false;
                draft.employeeOfferCreatingSuccess = undefined;
                draft.employeeOfferCreatingError = action.payload;
                draft.employeeOfferCreatingErrorsArray = mapCreateOfferApiErrorsToCreateOfferComponentErrors(
                    action.payload?.response?.data as unknown as CreateOfferApiError[]
                );
                break;
            case getType(saveEmployeeOffer.request):
                draft.employeeOfferCreating = true;
                draft.employeeOfferCreatingSuccess = undefined;
                draft.employeeOfferCreatingError = undefined;
                draft.employeeOfferCreatingErrorsArray = undefined;
                break;
            case getType(saveEmployeeOffer.success):
                draft.employeeOfferCreating = false;
                draft.employeeOfferCreatingSuccess = 1;
                draft.employeeOfferCreatingError = undefined;
                draft.employeeOfferCreatingErrorsArray = undefined;
                break;
            case getType(saveEmployeeOffer.failure):
                draft.employeeOfferCreating = false;
                draft.employeeOfferCreatingSuccess = undefined;
                draft.employeeOfferCreatingError = action.payload;
                draft.employeeOfferCreatingErrorsArray = mapCreateOfferApiErrorsToCreateOfferComponentErrors(
                    action.payload?.response?.data as unknown as CreateOfferApiError[]
                );
                break;
            case getType(resetEmployeeSendMessageFlags):
                draft.employeeOfferMessageSending = undefined;
                draft.employeeOfferMessageSendingSuccess = undefined;
                draft.employeeOfferMessageSendingError = undefined;
                break;
            case getType(resetEmployeeOffers):
                draft.employeeOffersLoading = false;
                draft.employeeOffers = undefined;
                draft.totalEmployeeOffers = undefined;
                draft.employeeOffersLoadingError = undefined;
                break;
            case getType(resetMyEmployeeOffers):
                draft.myEmployeeOffers = new Map();
                draft.myEmployeeOffersRequestedPages = new Set();
                draft.myEmployeeOffersTotalPages = undefined;
                draft.myEmployeeOffersPage = 0;
                draft.myEmployeeOffersLoading = false;
                draft.myEmployeeOffersLoadingError = undefined;
                break;
            case getType(getMyEmployeeOffers.cancel):
                break;
            case getType(resetEmployeeOffersFilterMatchCounts):
                draft.filterMatchCounts = undefined;
                draft.filterMatchCountsLoading = undefined;
                draft.filterMatchCountsLoadingError = undefined;
                break;
            case getType(resetCreateEmployeeOfferState):
                draft.employeeOfferCreating = false;
                draft.employeeOfferCreatingSuccess = undefined;
                draft.employeeOfferCreatingError = undefined;
                draft.employeeOfferCreatingErrorsArray = undefined;
                break;
            case getType(resetEmployeeOfferDetails):
                draft.employeeOfferDetails = undefined;
                draft.employeeOfferDetailsLoading = false;
                draft.employeeOfferDetailsLoadingError = undefined;
                break;
        }
    });
};

export default reducer;
