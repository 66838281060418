import {useState} from "react";
import {PaymentFormSchema} from "features/jobOffers/schemas";
import {
    challenge,
    failure,
    getCardId,
    indicatorRequest,
    indicatorResult,
    preAuth,
    producePaymentCallback,
} from "./steps";
import {auth} from "./steps/auth";
import {useFenigeAuthListener} from "./useFenigeAuthListener";
import {InvoiceData} from "../../../api/types";

export const useFenigePayment = () => {
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [isErrorPayment, setIsErrorPayment] = useState(false);
    const [isSuccessPayment, setIsSuccessPayment] = useState(false);
    const [paymentCallback, setPaymentCallback] = useState<() => Promise<any>>(() => Promise.resolve({}));

    const onFailure = failure(setIsLoadingPayment, setIsErrorPayment);

    const startPayment = async ({
        cardData,
        values,
        invoiceData,
        jobOfferId,
    }: {
        values: PaymentFormSchema;
        cardData: any;
        invoiceData: InvoiceData;
        jobOfferId: number;
    }) => {
        setIsLoadingPayment(true);

        getCardId({ values, cardData, invoiceData, jobOfferId })
            .then(preAuth)
            .then(indicatorRequest)
            .then(indicatorResult)
            .then(auth)
            .then(producePaymentCallback(setPaymentCallback, onFailure, String(values.cvc2), jobOfferId, invoiceData))
            .then(challenge(setIsSuccessPayment, setIsErrorPayment))
            .catch(onFailure);
    };

    useFenigeAuthListener({ onFailure, paymentCallback, setIsSuccessPayment });

    return { startPayment, isLoadingPayment, isSuccessPayment, isErrorPayment, setIsErrorPayment };
};
