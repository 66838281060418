import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "store";
import { resetRateEmployeeState } from "features/rating/actions";
import {
    selectEmployeeRatingLoading,
    selectRateEmployeeSuccess,
    selectRatingEmployeeError,
} from "features/rating/selectors";
import { rateEmployeeAsync } from "features/rating/thunks";

import RateEmployee from "./component";
import { selectApplicationDetails, selectApplicationDetailsError, selectApplicationDetailsLoading } from "features/jobOffers/selectors";
import { getApplicationDetailsAsync } from "features/jobOffers/thunks";
import { selectUser } from 'features/user/selectors';

const mapStateToProps = (state: RootState) => ({
    applicationDetails: selectApplicationDetails(state),
    applicationDetailsLoading: selectApplicationDetailsLoading(state),
    applicationDetailsError: selectApplicationDetailsError(state),
    employeeRatingLoading: selectEmployeeRatingLoading(state),
    employeeRatingSuccess: selectRateEmployeeSuccess(state),
    employeeRatingError: selectRatingEmployeeError(state),
    user: selectUser(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getApplicationDetailsAsync,
            rateEmployeeAsync,
            resetRateEmployeeState: () => resetRateEmployeeState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RateEmployee);
