import RateEmployerSummary from "features/rating/components/RateEmployerSummary";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
    id?: string;
}

const RateEmployerSummaryPage = () => {
    const { id } = useParams<Props>();

    return (
        <div className={styles["rate-employer"]}>
            <div className={styles["rate-employer__inner-container"]}>{id && <RateEmployerSummary id={parseInt(id)} />}</div>
        </div>
    );
};

export default RateEmployerSummaryPage;
