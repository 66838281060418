import { OfferType } from "features/common/types";
import MyJobOffersComponent from "features/jobOffers/components/MyJobOffers";
import React from "react";
import styles from "./styles.module.scss";

const MyJobOffers = () => (
    <div className={styles["my-job-offers"]}>
        <div className={styles["my-job-offers__inner-container"]}>
            <MyJobOffersComponent offerType={OfferType.Job} />
        </div>
    </div>
);

export default MyJobOffers;
