import { UserRole } from "features/common/types";
import { RouteConfiguration } from "features/routing";
import AdminRoute from "features/routing/components/AdminRoute";
import ModeratorRoute from "features/routing/components/ModeratorRoute";
import PrivateRoute from "features/routing/components/PrivateRoute";
import TitleWrapper from "features/routing/components/TitleWrapper";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Route, Switch } from "react-router-dom";

interface Props {
    routes: RouteConfiguration[];
    userRole?: UserRole;
    isUserLoggedInWithProfile?: boolean;
    locale: Locale | any;
}

const Router = ({ routes, userRole, isUserLoggedInWithProfile, locale }: Props) => {
    const [rerender, setRerender] = useState<boolean>(false)
    const intl = useIntl();

    React.useEffect(() => {
        setRerender(true)
        routes.forEach((element: any) => {
            element.name = intl.formatMessage({ id: `${element.id}__pagetitle` })
        })
        // Below is 0 because we want it to run as LAST line of code in this func
        setTimeout(() => setRerender(false), 0)
    }, [locale])
    return (
        <Switch>
            {!rerender ? routes.map((route) => {
                const { id, path, exact, isPublic, Component, name } = route;

                const RouteComponent = isPublic ? Route : route.requiresModerator ? ModeratorRoute : route.requiresAdmin ? AdminRoute : PrivateRoute;

                return (

                    <RouteComponent
                        key={id}
                        path={path}
                        exact={exact}
                        isAuthenticated={isUserLoggedInWithProfile}
                        userRole={userRole}
                    >
                        <TitleWrapper title={name}>
                            <Component />
                        </TitleWrapper>
                    </RouteComponent>
                );
            })
                :
                null}
        </Switch>
    );
};

export default Router;


