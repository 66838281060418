import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { GetCallState } from "../reducers";

export const setStreamAction = createAction("GETCALL_SET_STREAM")<Pick<GetCallState, 'stream'>>();
export const setConection = createAction("GETCALL_SET_CONNECTION")<Pick<GetCallState, 'connection'>>();
export const setCallAction = createAction("GETCALL_SET_CALL")<Pick<GetCallState, 'call'>>();
export const setSocketAction = createAction("GETCALL_SET_SOCKET")<Pick<GetCallState, 'socket'>>();

export const makeCallAction = createAction("GETCALL_MAKE_CALL")<void>();
export const answerCallAction = createAction("GETCALL_ANSWER_CALL")<void>();
export const leaveCallAction = createAction("GETCALL_LEAVE_CALL")<void>();

type GetCallActions =
    | ActionType<typeof setStreamAction>
    | ActionType<typeof setSocketAction>
    | ActionType<typeof setConection>
    | ActionType<typeof setCallAction>
    | ActionType<typeof makeCallAction>
    | ActionType<typeof answerCallAction>
    | ActionType<typeof leaveCallAction>

export default GetCallActions;
