import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    children: string | React.ReactNode;
}

const SuccessHeader = ({ children }: Props) => {
    return (
        <div className={styles["success-header"]}>
            <TickIcon />
            {children}
        </div>
    );
};

export default SuccessHeader;
