import PaymentDataSection from "features/myProfile/components/PaymentDataSection/component";
import {
    selectPaymentCardData,
    selectPaymentCardDataLoading,
    selectPaymentCardDataError,
} from "features/myProfile/selectors";
import {
    getPaymentCardDataAsync,
} from "features/myProfile/thunks";
import { selectUserId } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    userId: selectUserId(state),
    paymentCardData: selectPaymentCardData(state),
    paymentCardDataLoading: selectPaymentCardDataLoading(state),
    paymentCardDataError: selectPaymentCardDataError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    getPaymentCardDataAsync,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDataSection);
