import { selectLocationsDetails, selectSearchTermSuggestions } from "features/common/selectors";
import { getLocationsDetailsAsync } from "features/common/thunks";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import { JobOffersFetchParams } from "features/jobOffers/types";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import SearchBox from "./component";

interface OwnProps {
    asJobOffersSearch?: boolean;
    fetchParams: JobOffersFetchParams | EmployeeOffersFetchParams;
    onFetchParamsChange: (params: JobOffersFetchParams | EmployeeOffersFetchParams) => void;
}

const mapStateToProps = (state: RootState, { asJobOffersSearch, fetchParams, onFetchParamsChange }: OwnProps) => ({
    asJobOffersSearch,
    fetchParams,
    onFetchParamsChange,
    locationsDetails: selectLocationsDetails(state),
    searchTermSuggestions: selectSearchTermSuggestions(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getLocationsDetailsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
