import { ApiError } from "api/utils";
import ContactFormAction, {
    closeContactFormConfirmationDialog,
    sendContactFormMessage,
} from "features/contactForm/actions";
import produce from "immer";
import { getType } from "typesafe-actions";

export interface ContactFormState {
    contactFormMessageSending?: boolean;
    contactFormMessageSendingSuccess?: boolean;
    contactFormMessageSendingError?: ApiError;
}

const initialState: ContactFormState = {
    contactFormMessageSending: false,
    contactFormMessageSendingSuccess: false,
    contactFormMessageSendingError: undefined,
};

const reducer = (state: ContactFormState = initialState, action: ContactFormAction) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(sendContactFormMessage.request):
                draft.contactFormMessageSending = true;
                draft.contactFormMessageSendingSuccess = false;
                draft.contactFormMessageSendingError = undefined;
                break;
            case getType(sendContactFormMessage.success):
                draft.contactFormMessageSending = false;
                draft.contactFormMessageSendingSuccess = true;
                draft.contactFormMessageSendingError = undefined;
                break;
            case getType(sendContactFormMessage.failure):
                draft.contactFormMessageSending = false;
                draft.contactFormMessageSendingSuccess = false;
                draft.contactFormMessageSendingError = action.payload;
                break;
            case getType(closeContactFormConfirmationDialog):
                draft.contactFormMessageSending = false;
                draft.contactFormMessageSendingSuccess = false;
                draft.contactFormMessageSendingError = undefined;
                break;
        }
    });
};

export default reducer;
