import { SupportRequestData } from "features/contactForm/models";
import { RoleType, TopicType } from "features/contactForm/types";

export const mapTopicTypeToString = (topicType: TopicType): string => {
    switch (topicType) {
        case TopicType.Bonus:
            return "Bonus";
        case TopicType.Complaint:
            return "Reklamacja";
        case TopicType.HowTo:
            return "Jak to działa?";
        case TopicType.Platform:
            return "Problem z platformą";
        case TopicType.SideEffects:
            return "Działanie niepożądane";
        case TopicType.Others:
            return "Inne";
    }

    return "Inne";
};

export const mapDataToSupportRequest = (
    roleType: RoleType,
    topicType: TopicType,
    message: string
): SupportRequestData => ({
    bonusProblem: topicType === TopicType.Bonus,
    message,
    subject: mapTopicTypeToString(topicType),
    type: roleType === RoleType.Employer ? "COMPANY" : "PRIVATE_PERSON",
});
