import { ReactComponent as ArrowRightIcon } from "assets/icons/arrowRight.svg";
import { ReactComponent as EnvelopeIcon } from "assets/icons/envelope.svg";
import { ReactComponent as FolderWithDocumentsIcon } from "assets/icons/folderWithDocuments.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import RedDotCounter from "features/common/components/RedDotCounter";
import { OfferType } from "features/common/types";
import { MyJobOfferDetailCategory } from "features/jobOffers/types";
import { OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
    offerStatus: OfferStatus;
    ["data-testid"]?: string;
    className?: string;
    actionsMode?: "basic" | "extended";
    offerId?: number;
    applicationsCount?: number;
    messagesCount?: number;
    offerType?: OfferType;
    directRoute?: string;
    onEndOffer?: () => void;
}

const ActionButtons = ({
    "data-testid": testId,
    offerStatus,
    actionsMode,
    offerId,
    messagesCount,
    applicationsCount,
    offerType,
    onEndOffer,
    directRoute,
}: Props) => {
    const history = useHistory();

    const routeToOffer = () => {
        switch (offerType) {
            case OfferType.Job:
                return appRoutes.myJobOffers;
            case OfferType.Employee:
                return appRoutes.myEmployeeOffers;
            default:
                return appRoutes.jobOffers;
        }
    };

    const openOfferDetails = () => {
        if (directRoute) {
            return history.push(directRoute);
        }

        return history.push(`${routeToOffer()}/${offerId}`);
    };

    // TODO: IWGM-286 replace url to proper
    const openApplications = () =>
        offerStatus !== OfferStatus.Inactive &&
        history.push(`${routeToOffer()}/${offerId}/${MyJobOfferDetailCategory.Applications}`);

    // TODO: IWGM-286 replace url to proper
    const openMessages = () =>
        offerStatus !== OfferStatus.Inactive &&
        history.push(`${routeToOffer()}/${offerId}/${MyJobOfferDetailCategory.Interested}`);

    return (
        <>
            {actionsMode === "extended" && (
                <div data-testid={`${testId}__extended`} className={styles["action-buttons__container-extended"]}>
                    <div className={styles["action-buttons__applications-messages-container"]}>
                        {offerType === OfferType.Job && (
                            <div
                                data-testid={`${testId}__applications`}
                                className={classNames(styles["action-buttons__action"], {
                                    [styles["action-buttons__action--inactive"]]: offerStatus === OfferStatus.Inactive,
                                })}
                                onClick={openApplications}
                            >
                                <FolderWithDocumentsIcon />
                                <span>
                                    <FormattedMessage id="single-offer-card__applications" />
                                </span>
                                <RedDotCounter
                                    className={styles["action-buttons__counter"]}
                                    count={applicationsCount}
                                />
                            </div>
                        )}
                        <div
                            data-testid={`${testId}__messages`}
                            className={classNames(styles["action-buttons__action"], {
                                [styles["action-buttons__action--inactive"]]: offerStatus === OfferStatus.Inactive,
                            })}
                            onClick={openMessages}
                        >
                            <EnvelopeIcon className={styles["action-buttons__envelope"]} />
                            <span>
                                <FormattedMessage id="single-offer-card__messages" />
                            </span>
                            <RedDotCounter className={styles["action-buttons__counter"]} count={messagesCount} />
                        </div>
                    </div>
                </div>
            )}
            {actionsMode === "basic" && (
                <div
                    data-testid={`${testId}__offer-details-link`}
                    className={styles["action-buttons__container-basic"]}
                >
                    <Button
                        data-testid={`${testId}__offer-details-button`}
                        className={styles["action-buttons__button"]}
                        variant="no-background"
                        onClick={openOfferDetails}
                    >
                        <span>
                            <FormattedMessage id="single-offer-card__offer-details" />
                        </span>
                        <ArrowRightIcon />
                    </Button>
                </div>
            )}
        </>
    );
};

export default ActionButtons;
