import { SelectedOfferProps } from "features/jobOffers/models";
import React from "react";
import { useIntl } from "react-intl";
import { ReactComponent as CalendarCheckIcon } from "../../../../../../assets/icons/calendarCheck.svg";
import { ReactComponent as CalendarWithNumberIcon } from "../../../../../../assets/icons/calendarWithNumber.svg";
import { ReactComponent as ChevronRight } from "../../../../../../assets/icons/chevronRight.svg";
import { ReactComponent as CoinsIcon } from "../../../../../../assets/icons/coins.svg";
import { ReactComponent as CoinsBanknoteIcon } from "../../../../../../assets/icons/coinsBanknote.svg";
import { ReactComponent as LocationMarkerIcon } from "../../../../../../assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "../../../../../../assets/icons/peopleWithHand.svg";
import config from "../../../../../../config";
import useDateFormat from "../../../../../common/hooks/useDateFormat";
import {
    getBonusMessage,
    getPositionsNumberMessage,
    getPublishedDateMessage,
    getValidUntilMessage,
} from "../../../../../common/translationMessages";
import { OfferType } from "../../../../../common/types";
import styles from "../../../../../messages/components/ConversationDetails/styles.module.scss";
import { useLocaleInDateToUse } from "../../../../../messages/hooks";
import CardImageWithBadge from "../../../../../offers/components/SingleOfferCard/CardImageWithBadge";
import DetailRow from "../../../../../offers/components/SingleOfferCard/DetailRow";
import { getSalaryMessage } from "../../../../../offers/translationMessages";
import history from "../../../../../routing/history";
import { appRoutes } from "../../../../../routing/routes";
export interface Props {
    offer: SelectedOfferProps;
}

const SelectedOfferCard = ({ offer }: Props) => {
    const intl = useIntl();
    const { id, status, mainImage, businessName, position, locationSlimDto, availablePositionsNumber, addedDate, expirationDate, financialConditionsSlimDTO } = offer;
    const localDate = useLocaleInDateToUse(intl.locale)
    const getFormattedDate = useDateFormat("d MMM yyyy", { locale: localDate });
    const salaryConditions = { ...financialConditionsSlimDTO, rewardCurrencyCode: financialConditionsSlimDTO.rewardCurrencyCode || "EUR" }
    const positionsNumberMessage = getPositionsNumberMessage(intl, availablePositionsNumber);
    const publishedDateMessage = getPublishedDateMessage(intl, getFormattedDate(addedDate));
    const validUntilMessage = getValidUntilMessage(intl, getFormattedDate(expirationDate));
    const salaryMessage = getSalaryMessage(intl, salaryConditions)
    const bonusMessage = financialConditionsSlimDTO.bonusValue > 0 && getBonusMessage(intl, `${financialConditionsSlimDTO.bonusValue} ${financialConditionsSlimDTO.bonusCurrencyCode}`);

    const handleDetailsClick = () => {
        history.push(`${appRoutes.jobOffers}/${id}`)
    };

    return (
        <div className={styles["conversation-details__offer-container"]} style={{ width: '20rem' }}>
            <CardImageWithBadge
                data-testid="conversation-details__card-image-with-badge"
                photoBadge="status"
                status={status}
                imageUrl={`${config.IMAGE_URL}/${mainImage}`}
                offerType={OfferType.Job}
                className={styles["conversation-details__offer-image"]}
            />
            <div className={styles["conversation-details__offer-details-container"]}>
                <div className={styles["conversation-details__offer-business-name"]}>
                    {businessName}
                </div>
                <div className={styles["conversation-details__offer-position"]}>
                    {position}
                </div>
                <DetailRow
                    className={styles["conversation-details__offer-detail-row"]}
                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                    data-testid="conversation-details__location"
                    icon={<LocationMarkerIcon />}
                    message={`${locationSlimDto.city}, ${locationSlimDto.country}`}
                />
                <DetailRow
                    className={styles["conversation-details__offer-detail-row"]}
                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                    data-testid="conversation-details__positions-number"
                    icon={<PeopleWithHandIcon />}
                    message={positionsNumberMessage}
                />
                <DetailRow
                    className={styles["conversation-details__offer-detail-row"]}
                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                    data-testid="conversation-details__salary"
                    icon={<CoinsIcon />}
                    message={salaryMessage}
                />
                {bonusMessage && (
                    <DetailRow
                        className={styles["conversation-details__offer-detail-row"]}
                        iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                        data-testid="conversation-details__bonus"
                        icon={<CoinsBanknoteIcon />}
                        message={bonusMessage}
                    />
                )}
                <DetailRow
                    className={styles["conversation-details__offer-detail-row"]}
                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                    data-testid="conversation-details__added-date"
                    icon={<CalendarCheckIcon />}
                    message={publishedDateMessage}
                />
                <DetailRow
                    className={styles["conversation-details__offer-detail-row"]}
                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                    data-testid="conversation-details__expiration-date"
                    icon={<CalendarWithNumberIcon />}
                    message={validUntilMessage}
                />
            </div>
            <div
                className={styles["conversation-details__details-button"]}
                onClick={handleDetailsClick}
            >
                {intl.formatMessage({ id: "conversation-details__offer-details" })}
                <ChevronRight />
            </div>
        </div>
    );
};

export default SelectedOfferCard;