import React from "react";
import styled from "styled-components";

interface NavElementProps {
    title: string;
    active: boolean;
    index: string;
    setActive: React.Dispatch<React.SetStateAction<string | null>>
    onClick: () => void
}

const favoriteNavElement = ({ title, active, index, setActive, onClick }: NavElementProps) => {
    return (
        <StyledNavElement onClick={() => {
            setActive(index);
            onClick()
        }} active={active}>{title}</StyledNavElement>
    )
}

const StyledNavElement = styled.h3<{ active: boolean }>`
    position: relative;
    cursor: pointer;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 834px){
        padding: 0;
        padding-bottom: 2rem;
        margin-bottom: 0;
        box-shadow: none;
    }
    @media screen and (max-width: 834px){
        box-shadow: rgba(0, 0, 0, 0.01) 0px 2px 1px, rgba(0, 0, 0, 0.001) 0px 4px 2px, rgba(0, 0, 0, 0.001) 0px 8px 4px, rgba(0, 0, 0, 0.01) 0px 7px 4px, rgba(0, 0, 0, 0.09) 0px 7px 4px;
        padding: 10px;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        height: 2px;
        background-color: #002278;
        width: 100%;
        opacity: 0;
        transition: all 0.2s ease;
        @media screen and (max-width: 834px){
            height: 3px;
        }
    }

    ${({ active }) => active ? `
        color: #002278;
        &:after {
            opacity: 1;
        }
    ` : `
        color: #000;
    `}
`;

export default favoriteNavElement;
