import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "store";

import {
    selectApplicationDetails,
    selectApplicationDetailsError,
    selectApplicationDetailsLoading,
} from "features/jobOffers/selectors";
import { getApplicationDetailsAsync } from "features/jobOffers/thunks";
import { resetRateEmployerState } from "features/rating/actions";
import {
    selectEmployerRatingLoading,
    selectRateEmployerSuccess,
    selectRatingEmployerError,
} from "features/rating/selectors";
import { rateEmployerAsync } from "features/rating/thunks";

import RateEmployer from "./component";
import { selectUser } from 'features/user/selectors';

const mapStateToProps = (state: RootState) => ({
    applicationDetails: selectApplicationDetails(state),
    applicationDetailsLoading: selectApplicationDetailsLoading(state),
    applicationDetailsError: selectApplicationDetailsError(state),
    employerRatingLoading: selectEmployerRatingLoading(state),
    employerRatingSuccess: selectRateEmployerSuccess(state),
    employerRatingError: selectRatingEmployerError(state),
    user: selectUser(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getApplicationDetailsAsync,
            rateEmployerAsync,
            resetRateEmployerState: () => resetRateEmployerState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RateEmployer);
