import { WorkType } from "features/common/types";
import {
    CreateEmployeeOfferSchema,
    EmployeeOfferDetailsStepSchema,
    EmployeeOfferFinancialStepSchema,
    EmployeeOfferOfferDetailsStepSchema,
} from "features/employeeOffers/schemas";
import { EmployeeOfferType } from "features/employeeOffers/types";
import { getAmenitiesStepValidationSchema } from "features/offers/validators";
import * as Yup from "yup";
import { createRequirementsStepValidationSchema } from "../../jobOffers/validators";

const MIN_BONUS_VALUE = {
    PLN: 100,
    EUR: 30,
    USD: 30,
    GBP: 25
}

const createDetailsStepValidationSchema = (yup: typeof Yup) =>
    yup.object({
        offerAs: yup.string().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        businessName: yup.string().when("offerAs", {
            is: "COMPANY",
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        location: yup.object({
            placeId: yup.string().required(),
        }),
        krazCertificateNumber: yup.string(),
        taxIdentificationNumber: yup.string(),
        phoneNumber: yup.string().required(),
        phonePrefix: yup.string().required(),
        photos: yup.array().of(
            yup
                .object({
                    fileUrl: yup.string().required(),
                })
                .required()
        ),
    }) as Yup.Schema<EmployeeOfferDetailsStepSchema>;

const createOfferDetailsStepValidationSchema = (yup: typeof Yup) =>
    yup.object({
        position: yup.string().required(),
        branchId: yup.string().required(),
        offerType: yup.string().required(),
        positionsAvailable: yup.number().when("offerType", {
            is: EmployeeOfferType.Single,
            then: yup.number(),
            otherwise: yup.number().min(2).required(),
        }),
        workType: yup.string().required(),
        jobStartDate: yup.date().required(),
        jobEndDate: yup.mixed().when(["workType"], {
            is: WorkType.Odd,
            then: yup.date().required(),
            otherwise: yup.date().nullable(),
        }),
        workDuringHolidays: yup.boolean(),
        location: yup.object({
            placeId: yup.string().required(),
        }),
    }) as Yup.Schema<EmployeeOfferOfferDetailsStepSchema>;

const createFinancialStepValidationSchema = (yup: typeof Yup) =>
    yup.object({
        salaryType: yup.string(),
        salaryMinValue: yup.number().min(1).required(),
        salaryMaxValue: yup.number().min(1).moreThan(yup.ref("salaryMinValue")),
        salaryCurrencyCode: yup.string(),
        attachBonus: yup.boolean(),
        bonusValue: yup.number().when(["attachBonus"], {
            is: true,
            then: yup.number().when(["bonusCurrencyCode"], {
                is: "PLN",
                then: yup.number().min(MIN_BONUS_VALUE.PLN).required(),
                otherwise: yup.number().when(["bonusCurrencyCode"], {
                    is: "GBP",
                    then: yup.number().min(MIN_BONUS_VALUE.GBP).required(),
                    otherwise: yup.number().when(["bonusCurrencyCode"], {
                        is: "USD",
                        then: yup.number().min(MIN_BONUS_VALUE.USD).required(),
                        otherwise: yup.number().when(["bonusCurrencyCode"], {
                            is: "EUR",
                            then: yup.number().min(MIN_BONUS_VALUE.EUR).required(),
                        }),
                    }),
                }),

            }),
            otherwise: yup.number(),
        }),
        bonusCurrencyCode: yup.string().when(["attachBonus"], {
            is: true,
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        bonusType: yup.string().when(["attachBonus"], {
            is: true,
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
    }) as Yup.Schema<EmployeeOfferFinancialStepSchema>;

// export const createRequirementsStepValidationSchema = (yup: any) =>
//     yup.object({
//         languages: yup.array().of(
//             yup.object().shape({
//                 isChecked: yup.boolean().default(false),
//                 level: yup.string().when("isChecked", {
//                     is: true,
//                     then: yup.string().required(),
//                 }),
//                 otherValue: yup.string().when(["id", "isChecked"], {
//                     is: (id: LanguageType, isChecked: boolean) => {
//                         return id === LanguageType.Other && isChecked;
//                     },
//                     then: yup.string().required(),
//                 }),
//             })
//         ),
//         education: yup.array().of(
//             yup.object().shape({
//                 isChecked: yup.boolean().default(false),
//                 otherValue: yup.string().when(["id", "isChecked"], {
//                     is: (id: EducationType, isChecked: boolean) => {
//                         return id === EducationType.Other && isChecked;
//                     },
//                     then: yup.string().required(),
//                 }),
//             })
//         ),
//         programs: yup.string().max(250),
//         other: yup.string().max(250),
//         permissions: yup.string().max(250),
//         certificates: yup.string().max(250),
//         experience: yup.string().max(250),
//         drivingLicenses: yup.array().of(yup.string()),
//     });

const createAdditionalInfoStepValidationSchema = (yup: any) =>
    yup.object({
        additionalInfo: yup.string().max(2000),
    });

export const createEmployeeOfferFormValidationSchema = (yup: typeof Yup) =>
    yup
        .object<CreateEmployeeOfferSchema>({
            detailsStep: createDetailsStepValidationSchema(yup),
            offerDetailsStep: createOfferDetailsStepValidationSchema(yup),
            financialStep: createFinancialStepValidationSchema(yup),
            additionalInfoStep: createAdditionalInfoStepValidationSchema(yup),
            requirementsStep: createRequirementsStepValidationSchema(yup),
            amenitiesStep: getAmenitiesStepValidationSchema(yup),
        })
        .required();
