import { ReactComponent as AccommodationIcon } from "assets/icons/accommodation.svg";
import { ReactComponent as HealthCareIcon } from "assets/icons/healthCare.svg";
import { ReactComponent as InfoIcon } from "assets/icons/infoIcon.svg";
import { ReactComponent as LegalHelpIcon } from "assets/icons/legalHelp.svg";
import { ReactComponent as MealIcon } from "assets/icons/meal.svg";
import { ReactComponent as Other } from "assets/icons/otherAmenities.svg";
import { ReactComponent as TransportIcon } from "assets/icons/transport.svg";
import { ReactComponent as WorkBootsIcon } from "assets/icons/workBoots.svg";
import { ReactComponent as WorkWearIcon } from "assets/icons/workWear.svg";
import classNames from "classnames";
import { Amenity, AmenityType } from "features/offers/types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    amenities?: Amenity[];
    offersList?: boolean;
}

const getAmenityPosition = (amenity: AmenityType): number => {
    switch (amenity) {
        case AmenityType.accommodation:
            return 3;
        case AmenityType.transport:
            return 0;
        case AmenityType.catering:
            return 1;
        case AmenityType.workWear:
            return 4;
        case AmenityType.workBoots:
            return 2;
        case AmenityType.freeMedicalExaminations:
            return 6;
        case AmenityType.assistanceInLegalizingStay:
            return 5;
        case AmenityType.other:
            return 7;
        default:
            return 8;
    }
};

const AmenitiesPart = ({ "data-testid": testId, amenities, offersList }: Props) => {

    const generateAmenityItem = (amenity: Amenity) => {
        let icon;
        let value;
        let translation;

        switch (amenity.amenityType) {
            case AmenityType.accommodation:
                translation = <FormattedMessage id="common__accommodation" />;
                icon = <AccommodationIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.transport:
                translation = <FormattedMessage id="common__transportation-to-workplace" />;
                icon = <TransportIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.catering:
                translation = <FormattedMessage id="create-offer__amenities-catering" />;
                icon = <MealIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.workWear:
                translation = <FormattedMessage id="create-offer__amenities-working-clothing" />;
                icon = <WorkWearIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.workBoots:
                translation = <FormattedMessage id="create-offer__amenities-work-footwear" />;
                icon = <WorkBootsIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.freeMedicalExaminations:
                translation = <FormattedMessage id="create-offer__amenities-free-medical-examinations" />;
                icon = <HealthCareIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.assistanceInLegalizingStay:
                translation = <FormattedMessage id="create-offer__amenities-assistance-in-legalizing-stay" />;
                icon = <LegalHelpIcon className={styles["size-auto"]} />;
                break;
            case AmenityType.other:
                translation = amenity.value;
                icon = <Other className={styles["size-auto"]} />;
        }

        return (
            <>{offersList
                ? (<>
                    <div className={styles["amenities-part__amenity-item-header-fixHeight"]}>
                        {icon}<span>{value && <span>{value}</span>}<h3>{translation}</h3><div className={styles["amenities-part__amenity-item-icon"]}><InfoIcon /></div></span>
                    </div>

                </>)
                : (<>
                    <div className={styles["amenities-part__amenity-item-header"]}>
                        {icon}<span><h3>{translation}</h3><div className={styles["amenities-part__amenity-item-icon"]}><InfoIcon /></div></span>
                    </div>
                    {value && <p>{value}</p>}
                </>)}

            </>
        );
    };

    return (
        <>
            {offersList ? (
                <div className={styles["amenities-part__amenities-offers-list"]}>
                    {amenities &&
                        [...amenities]
                            ?.sort((a, b) => (getAmenityPosition(a.amenityType) > getAmenityPosition(b.amenityType) ? 1 : -1))
                            ?.map((current) => (
                                <div
                                    data-testid={`${testId}__amenity-item`}
                                    key={current.amenityType}
                                    className={classNames(styles["amenities-part__amenity-item-size"], {
                                        [styles["amenities-part__amenity-item--other-amenities"]]:
                                            current.amenityType === AmenityType.other,
                                    })}
                                >
                                    {generateAmenityItem(current)}
                                </div>
                            ))}
                </div>
            ) : (
                <div className={styles["amenities-part__amenities-list"]}>
                    {amenities &&
                        [...amenities]
                            ?.sort((a, b) => (getAmenityPosition(a.amenityType) > getAmenityPosition(b.amenityType) ? 1 : -1))
                            ?.map((current) => (
                                <div
                                    data-testid={`${testId}__amenity-item`}
                                    key={current.amenityType}
                                    className={classNames(styles["amenities-part__amenity-item"], {
                                        [styles["amenities-part__amenity-item--other-amenities"]]:
                                            current.amenityType === AmenityType.other,
                                    })}
                                >
                                    {generateAmenityItem(current)}
                                </div>
                            ))}
                </div>
            )}
        </>

    );
};

export default AmenitiesPart;
