import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

interface Props {
    ["data-testid"]?: string;
    className?: string;
    iconClassName?: string;
    icon?: JSX.Element;
    message?: string;
    isEllipsis?: boolean;
    children?: JSX.Element;
    redHatText?: boolean;
}

const DetailRow = ({ "data-testid": testId, className, iconClassName, icon, message, redHatText, children, isEllipsis = true }: Props) => {
    return (
        <div className={classNames(styles["details-row__info-with-icon"], className)}>
            <div className={classNames(styles["details-row__icon"], iconClassName)}>{icon}</div>
            {redHatText ? (<div
                data-testid={testId}
                className={isEllipsis ? styles["details-row__info-salary"] : undefined}
                title={message ?? ""}
                >
                {message}
                {children}
                </div>)
            : (<div
                data-testid={testId}
                className={isEllipsis ? styles["details-row__info"] : styles["details-row__info-start-job-date"]}
                title={message ?? ""}
                >
                {message}
                {children}
                </div>)
            }
        </div>
    );
};

export default DetailRow;
