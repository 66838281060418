import { ReactComponent as ApplicationAccepted } from "assets/icons/applicationAccepted.svg";
import { ReactComponent as ApplicationDenied } from "assets/icons/applicationDenied.svg";
import { ReactComponent as ApplicationSent } from "assets/icons/applicationSent.svg";
import { ReactComponent as ArrivalAccepted } from "assets/icons/arrivalAccepted.svg";
import { ReactComponent as BonusGiven } from "assets/icons/bonusGiven.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import classNames from "classnames";
import config from "config";
import { format } from "date-fns";
import { isDateValid } from "features/common/utils";
import { ApplicationStatus } from "features/messages/types";
import React, { useMemo } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import history from "../../../../routing/history";
import styles from "./styles.module.scss";

export interface Props {
    jobOfferId: number;
    messageId: number;
    userName: string;
    content?: string;
    timestamp: Date;
    jobApplicationId: number;
    applicationStatus?: ApplicationStatus;
    isCurrentUser: boolean;
    displayUserHeader: boolean;
    handleOpenApplicationDetailsModal: (id: number) => void;
    files: {
        originalFileName: string
        resourceFileName: string
    }[]
}

const getApplicationStatusTranslationText = (intl: IntlShape, applicationStatus: ApplicationStatus | undefined) => {
    switch (applicationStatus) {
        case ApplicationStatus.ApplicationSent:
            return intl.formatMessage({ id: "conversation-details__application-sent" });
        case ApplicationStatus.ApplicationAccepted:
            return intl.formatMessage({ id: "conversation-details__application-accepted" });
        case ApplicationStatus.ApplicationDenied:
            return intl.formatMessage({ id: "conversation-details__application-denied" });
        case ApplicationStatus.ArrivalAccepted:
            return intl.formatMessage({ id: "conversation-details__application-arrival-accepted" });
        case ApplicationStatus.BonusGiven:
            return intl.formatMessage({ id: "conversation-details__application-bonus-given" });
        case ApplicationStatus.OfferSent:
            return intl.formatMessage({ id: "conversation-details__offer-sent" });
        default:
            return null;
    }
};

const getApplicationStatusTranslationTextAddition = (
    intl: IntlShape,
    applicationStatus: ApplicationStatus | undefined,
    jobApplicationId: number,
    jobOfferId: number,
) => {
    switch (applicationStatus) {
        case ApplicationStatus.ApplicationSent:
            return intl.formatMessage({ id: "conversation-details__application-sent-addition" }, { jobApplicationId });
        case ApplicationStatus.ApplicationAccepted:
            return intl.formatMessage(
                { id: "conversation-details__application-accepted-addition" },
                { jobApplicationId }
            );
        case ApplicationStatus.ApplicationDenied:
            return intl.formatMessage(
                { id: "conversation-details__application-denied-addition" },
                { jobApplicationId }
            );
        case ApplicationStatus.ArrivalAccepted:
            return intl.formatMessage(
                { id: "conversation-details__application-arrival-accepted-addition" },
                { jobApplicationId }
            );
        case ApplicationStatus.OfferSent:
            return intl.formatMessage(
                { id: "conversation-details__offer-sent-addition" },
                { jobOfferId }
            );
        case ApplicationStatus.BonusGiven:
            return intl.formatMessage(
                { id: "conversation-details__application-bonus-given-addition" },
                { jobApplicationId }
            );
        default:
            return null;
    }
};

const getApplicationStatusIcon = (applicationStatus?: ApplicationStatus) => {
    switch (applicationStatus) {
        case ApplicationStatus.ApplicationSent:
            return <ApplicationSent />;
        case ApplicationStatus.ApplicationAccepted:
        case ApplicationStatus.OfferSent:
            return <ApplicationAccepted />;
        case ApplicationStatus.ApplicationDenied:
            return <ApplicationDenied />;
        case ApplicationStatus.ArrivalAccepted:
            return <ArrivalAccepted />;
        case ApplicationStatus.BonusGiven:
            return <BonusGiven />;
        default:
            return null;
    }
};

const SingleMessage = ({
    messageId,
    userName,
    content,
    files,
    timestamp,
    jobApplicationId,
    applicationStatus,
    isCurrentUser,
    displayUserHeader,
    handleOpenApplicationDetailsModal,
    jobOfferId
}: Props) => {
    const intl = useIntl();
    const tokenLocal = localStorage.getItem('TOKENS');
    const token: string = tokenLocal ? JSON.parse(tokenLocal)?.accessToken : '';
    const applicationStatusIcon = useMemo(() => getApplicationStatusIcon(applicationStatus), [applicationStatus]);
    const applicationStatusText = useMemo(
        () => getApplicationStatusTranslationText(intl, applicationStatus),
        [applicationStatus, intl]
    );
    const applicationStatusTextAddition = useMemo(
        () => getApplicationStatusTranslationTextAddition(intl, applicationStatus, jobApplicationId, jobOfferId),
        [applicationStatus, intl, jobApplicationId, jobOfferId]
    );

    const handleOpenApplication = () => {
        if (applicationStatus === ApplicationStatus.OfferSent) {
            history.push(`/job-offers/${jobOfferId}`)
        }
        handleOpenApplicationDetailsModal(jobApplicationId)
    }

    function downloadFile(messageId: number, resourceFileName: string, originalFileName: string, token: string) {
        fetch(`${config.API_URL}${config.apiRoutes.MESSAGES}/${config.apiRoutes.FILE}?messageId=${messageId}&resourceFileName=${resourceFileName}`,
            {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = originalFileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((e) => console.error(e));
    }
    if (content === null) {
        content = ""
    }

    return (
        <div className={styles["conversation-message"]}>
            {displayUserHeader && (
                <div
                    className={classNames(styles["conversation-message__user-info"], {
                        [styles["conversation-message__current-user-info"]]: isCurrentUser,
                    })}
                >
                    {isCurrentUser ? intl.formatMessage({ id: "conversation-details__me" }) : userName},
                    <span>{isDateValid(timestamp) ? format(timestamp, "HH:mm") : ""}</span>
                </div>
            )}
            <div
                className={classNames(styles["conversation-message__message-body"], {
                    [styles["conversation-message__positive"]]:
                        applicationStatus === ApplicationStatus.ApplicationAccepted || applicationStatus === ApplicationStatus.OfferSent,
                    [styles["conversation-message__negative"]]:
                        applicationStatus === ApplicationStatus.ApplicationDenied,
                    [styles["conversation-message__information"]]:
                        applicationStatus === ApplicationStatus.ApplicationSent ||
                        applicationStatus === ApplicationStatus.ArrivalAccepted ||
                        applicationStatus === ApplicationStatus.BonusGiven,
                    [styles["conversation-message__current-user-message"]]: isCurrentUser,
                })}
            >
                {applicationStatus ? (
                    <>
                        <span className={styles["conversation-message__icon-text"]}>
                            {applicationStatusIcon}
                            <span>{applicationStatusText} </span>
                            {applicationStatusTextAddition}
                        </span>
                        <span
                            className={styles["conversation-message__see-button"]}
                            onClick={handleOpenApplication}
                        >
                            {intl.formatMessage({ id: "conversation-details__see-application" })}
                            <ChevronRight />
                        </span>
                    </>
                ) : content === "recrutation_invitation_message" ? (
                    <FormattedMessage id="recrutation_invitation_message" />
                ) : files.length > 0 ? (
                    <div className={styles['conversation-message__files-container']}>
                        {files.map(({ originalFileName, resourceFileName }) => <div onClick={() => downloadFile(messageId, resourceFileName, originalFileName, token)} className={styles['conversation-message__file-element']}>{originalFileName}</div>)}
                    </div>
                ) : content
                }
            </div>
        </div >
    );
};

export default SingleMessage;
