import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ApiError, CancelToken } from "api/utils";
import Button from "features/common/components/Button";
import Modal from "features/common/components/Modal";
import NumberSpinnerInput from "features/common/components/NumberSpinnerInput";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TextArea from "features/common/components/TextArea";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";

import styles from "./styles.module.scss";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";

const maxMessageLength = 250;

export interface Props {
    id: number;
    acceptApplicationLoading: boolean;
    acceptApplicationSuccess?: boolean;
    acceptApplicationError?: ApiError;
    applicantsNumber: number;
    acceptApplicationAsync: (
        id: number,
        acceptedApplicantsNumber?: number,
        message?: string,
        cancelToken?: CancelToken
    ) => void;
    handleCloseModal: () => void;
}

const AcceptApplicationModal = ({
    id,
    acceptApplicationLoading,
    acceptApplicationSuccess,
    acceptApplicationError,
    applicantsNumber,
    acceptApplicationAsync,
    handleCloseModal,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();
    const [messageValue, setMessageValue] = useState("");
    const [acceptedApplicantsNumber, setAcceptedApplicantsNumber] = useState(1);

    const handleApplicantsIncrease = useCallback(() => {
        setAcceptedApplicantsNumber((number: number) => (number >= applicantsNumber ? number : number + 1));
    }, [applicantsNumber, setAcceptedApplicantsNumber]);

    const handleApplicantsDecrease = useCallback(() => {
        setAcceptedApplicantsNumber((number: number) => (number <= 1 ? number : number - 1));
    }, [setAcceptedApplicantsNumber]);

    const onMessageChange = useCallback((event) => setMessageValue(event.currentTarget.value), [setMessageValue]);
    const handleSubmit = useCallback(() => {
        acceptApplicationAsync(id, acceptedApplicantsNumber, messageValue, createCancelToken());
    }, [acceptApplicationAsync, acceptedApplicantsNumber, createCancelToken, id, messageValue]);

    useEffect(() => {
        if (acceptApplicationSuccess) {
            handleCloseModal();
        }
    }, [acceptApplicationSuccess, handleCloseModal]);

    return acceptApplicationLoading ? (
        <SpinnerWithMessage message={<FormattedMessage id="accept-application-modal__loading-accept-application" />} />
    ) : (
        <Modal className={styles["accept-application-modal"]} parentId="accept-application-modal">
            <div className={styles["accept-application-modal__container"]}>
                <div
                    className={styles["accept-application-modal__close-button"]}
                    onClick={acceptApplicationLoading ? () => {} : handleCloseModal}
                >
                    <TimesIcon />
                </div>
                <div className={styles["accept-application-modal__title"]}>
                    <FormattedMessage id="accept-application-modal__title" />
                </div>
                <div className={styles["accept-application-modal__subtitle"]}>
                    {applicantsNumber > 1 ? (
                        <FormattedMessage id="accept-application-modal__multi-subtitle" />
                    ) : (
                        <FormattedMessage id="accept-application-modal__subtitle" />
                    )}
                </div>
                {applicantsNumber > 1 && (
                    <div className={styles["accept-application-modal__applicants-number-container"]}>
                        <NumberSpinnerInput
                            value={acceptedApplicantsNumber}
                            onIncrease={handleApplicantsIncrease}
                            onDecrease={handleApplicantsDecrease}
                        />
                    </div>
                )}
                <TextArea
                    maxSize={maxMessageLength}
                    placeholder={intl.formatMessage({ id: "accept-application-modal__message-placeholder" })}
                    value={messageValue}
                    error={
                        !!acceptApplicationError
                            ? intl.formatMessage({ id: "accept-application-modal__acceptation-error" })
                            : messageValue.length > 250
                            ? intl.formatMessage({ id: "textarea__maximum-size" }, { maxSize: maxMessageLength })
                            : ""
                    }
                    onChange={onMessageChange}
                    withValidation
                    isInvalid={!!acceptApplicationError || messageValue.length > 250}
                    data-testid="accept-application-modal__message-modal"
                    className={styles["accept-application-modal__text-area"]}
                    customWarningMessage={intl.formatMessage(
                        { id: "textarea__maximum-size" },
                        { maxSize: maxMessageLength }
                    )}
                />
                <div className={styles["accept-application-modal__buttons-container"]}>
                    <Button
                        data-testid="accept-application-modal__button"
                        className={styles["accept-application-modal__button"]}
                        type="submit"
                        variant="tertiary"
                        disabled={acceptApplicationLoading}
                        onClick={handleCloseModal}
                    >
                        <FormattedMessage id="accept-application-modal__cancel" />
                    </Button>
                    <Button
                        data-testid="accept-application-modal__button"
                        className={styles["accept-application-modal__button"]}
                        type="submit"
                        disabled={messageValue.length > 250 || acceptApplicationLoading}
                        onClick={handleSubmit}
                    >
                        <FormattedMessage id="accept-application-modal__accept" />
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AcceptApplicationModal;
