import { makeApiRequest } from "api/makeApiRequest";
import { CancelToken } from "api/utils";
import ModeratorPanelAction, {
    amendOffers,
    findOffersToModerate,
    putActionOfferRequest,
} from "features/moderatorPanel/action";
import { ModeratorPanelApi } from "features/moderatorPanel/api";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";
import { OfferRequestDto } from "../dtos";
import { GetOfferFetchParams } from "../types";

export const getOffersAsync = (
    fetchParams: GetOfferFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<ModeratorPanelApi, "getOffersAsync">, ModeratorPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(findOffersToModerate.request());

        await makeApiRequest(
            api.getOffersAsync,
            [fetchParams, cancelToken],
            findOffersToModerate.success,
            findOffersToModerate.failure,
            dispatch
        );
    };
};

export const amendOffersAsync = (
    fetchParams: GetOfferFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<ModeratorPanelApi, "getOffersAsync">, ModeratorPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(amendOffers.request());

        await makeApiRequest(
            api.getOffersAsync,
            [fetchParams, cancelToken],
            amendOffers.success,
            amendOffers.failure,
            dispatch
        );
    };
};

export const putOfferAsync = (
    offerId: number,
    body: OfferRequestDto,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<ModeratorPanelApi, "putActionOfferRequestAsync">, ModeratorPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(putActionOfferRequest.request());

        await makeApiRequest(api.putActionOfferRequestAsync, [offerId, body, cancelToken], putActionOfferRequest.success, putActionOfferRequest.failure, dispatch);
    };
};

export const endOfferAsync = (
    offerId: number,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<ModeratorPanelApi, "endActionOfferRequestAsync">, ModeratorPanelAction> => {
    return async (dispatch, getState, api) => {
        dispatch(putActionOfferRequest.request());

        await makeApiRequest(api.endActionOfferRequestAsync, [offerId, cancelToken], putActionOfferRequest.success, putActionOfferRequest.failure, dispatch);
    };
};

