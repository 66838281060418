import {ApiError, CancelToken} from "api/utils";
import {ReactComponent as PenIcon} from "assets/icons/pen.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import ModalWithContent from "features/common/components/ModalWithContent";
import ErrorHeader from "features/common/components/ModalWithContent/ErrorHeader";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import {getLocalizedYup} from "features/common/validators";
import JobOffersApi from "features/jobOffers/api";
import CardStep from "features/jobOffers/components/JobOfferDetails/DetailsActionsBox/ApplicationSubmission/CardStep";
import {FenigePaymentStatus} from "features/jobOffers/models";
import {PaymentFormSchema} from "features/jobOffers/schemas";
import {EmployerType} from "features/jobOffers/types";
import {createPaymentFormSchema} from "features/jobOffers/validators";
import MyProfileApi from "features/myProfile/api";
import {MyProfileCards} from "features/myProfile/models";
import {appRoutes} from "features/routing/routes";
import {Formik} from "formik";
import pick from "lodash/pick";
/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory, useParams} from "react-router-dom";
import CardSection from "./CardSection";
import DataSection from "./DataSection";
import OrderSection from "./OrderSection";
import styles from "./styles.module.scss";
import {useFenigePayment} from "./useFenigePayment";
import {InvoiceData} from "../../api/types";

export interface Props {
    userId?: string;
    myProfileCards?: MyProfileCards;
    myProfileCardsLoading?: boolean;
    fenigePaymentStarted: boolean;
    fenigePaymentSuccess?: FenigePaymentStatus;
    fenigePaymentError?: ApiError;
    startFenigePaymentAsync: (
        offerId: number,
        cardId: string,
        cvc2: string,
        purchaseAmount: string,
        browserScreenWidth: number,
        browserScreenHeight: number,
        browserTimeZone: number,
        browserLanguage: string,
        userFVInfo: any
    ) => any;
    getFenigeIndicatorRequest: any;
    getMyProfileCardsAsync: (cancelToken?: CancelToken) => void;
    resetFenigePayment: () => void;
}

// export const getInvoiceParams = (invoiceParams: object) => {
//     setInvoiceData(invoiceParams)
// }

export interface Params {
    offerId?: string;
}

const initialPaymentFormValues = {
    employerType: EmployerType.Company,
    companyName: "",
    taxIdentificationNumber: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    location: {},
    email: "",
    country: "",
    city: "",
    street: "",
    postalCode: "",
    attachInvoice: false,
    isEditing: false,
    cardNumber: "",
    expirationDate: "",
    cvc2: "",
};

const Payment = ({
    userId,
    myProfileCards,
    myProfileCardsLoading,
    fenigePaymentStarted,
    fenigePaymentSuccess,
    fenigePaymentError,
    getFenigeIndicatorRequest,
    startFenigePaymentAsync,
    getMyProfileCardsAsync,
    resetFenigePayment,
}: Props) => {
    const fenigePlatnoscRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();
    const history = useHistory();
    const { offerId } = useParams<Params>();
    const [downloadingOfferData, setDownloadingOfferData] = useState(false);
    const [offerData, setOfferData] = useState<any>();
    const [downloadingCardData, setDownloadingCardData] = useState(false);
    const [cardData, setCardData] = useState<any>();
    const { startPayment, isSuccessPayment, isLoadingPayment, isErrorPayment, setIsErrorPayment } = useFenigePayment();

    const formikRef = useRef<any>();
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl);
        return createPaymentFormSchema(localYup, !!offerData?.financialConditions?.bonusValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const [cardStepOpen, setCardStepOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<string | undefined>();
    const openCardStep = () => setCardStepOpen(true);
    const [priceAmount, setPriceAmount] = useState<number | undefined>();
    const [currency, setCurrency] = useState("PLN");

    const [isLoadingFenige, setIsLoadingFenige] = useState(false);

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [failureModalOpen, setFailureModalOpen] = useState(false);
    const [changeCardFailureModalOpen, setChangeCardFailureModalOpen] = useState(false);

    const invoiceData = useRef<InvoiceData>({} as InvoiceData);

    const downloadCardData = useCallback(() => {
        setDownloadingCardData(true);

        MyProfileApi.getPaymentCardAsync(createCancelToken())
            .then((response: any) => {
                setDownloadingCardData(false);
                setCardData(response);
            })
            .catch((error) => {
                setDownloadingCardData(false);
            });
    }, [createCancelToken]);

    useEffect(() => {
        if (offerId) {
            setDownloadingOfferData(true);
            JobOffersApi.getJobOfferDetailsAsync(offerId, createCancelToken()).then((data) => {
                setDownloadingOfferData(false);
                setOfferData(data.data);

                if (!!data.data.isPaid) {
                    setSuccessModalOpen(true);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getMyProfileCardsAsync(createCancelToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (offerData && offerId) {
            if (offerData?.financialConditions?.bonusValue) {
                JobOffersApi.getFenigePriceAsync(offerId, createCancelToken()).then((data) => {
                    setPriceAmount(data.data.price);
                    setCurrency(data.data.currency.code);
                });
            }
        }
    }, [createCancelToken, offerData, offerId]);

    useEffect(() => {
        if (offerData?.financialConditions?.bonusValue) {
            downloadCardData();
        }
    }, [downloadCardData, offerData]);

    useEffect(() => {
        if (offerData) {
            setSelectedCard(`${offerData?.businessCardId}`);
        }
    }, [offerData]);

    useEffect(() => {
        if (fenigePaymentSuccess?.indicatorRequestIsRequired) {
            const startRequest = new Date().getTime();
            const authRequestForm = new FormData() as any;
            authRequestForm.append("threeDSMethodData", fenigePaymentSuccess.indicatorRequestData.data);
            let endRequestTime = new Date().getTime();

            fetch(fenigePaymentSuccess.indicatorRequestData.url, {
                method: "POST",
                body: new URLSearchParams(authRequestForm),
            }).then(() => {
                endRequestTime = new Date().getTime();
                getFenigeIndicatorRequest(fenigePaymentSuccess?.authenticationProcessId, endRequestTime - startRequest);
            });
            return;
        }
        if (fenigePaymentSuccess?.htmlForm && fenigePlatnoscRef?.current) {
            fenigePlatnoscRef.current.innerHTML = fenigePaymentSuccess.htmlForm;
            const formWin = fenigePlatnoscRef?.current.querySelector("form") as HTMLFormElement;
            formWin.submit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fenigePaymentSuccess]);

    useEffect(() => {
        if (fenigePaymentError) {
            setFailureModalOpen(true);
            resetFenigePayment();
        }
    }, [fenigePaymentError, resetFenigePayment]);

    const initialValues = useMemo(() => {
        const selectedCardData =
            myProfileCards?.employee?.find((item) => item.id === selectedCard) ||
            myProfileCards?.employer?.find((item) => item.id === selectedCard);
        const isPersonalCard = !!myProfileCards?.employee?.find((item) => item.id === selectedCard);
        return {
            ...initialPaymentFormValues,
            ...pick(selectedCardData || {}, [
                "companyName",
                "taxIdentificationNumber",
                "firstName",
                "lastName",
                "phoneNumber",
                "email",
                "country",
                "city",
                "street",
                "postalCode",
            ]),
            employerType: isPersonalCard ? EmployerType.Private : EmployerType.Company,
            placeId: selectedCardData?.placeId || "",
        };
    }, [myProfileCards, selectedCard]);

    const onSubmit = async (values: PaymentFormSchema) => {
        startPayment({ values, cardData, invoiceData: invoiceData.current, jobOfferId: Number(offerId) });
    };

    const goBack = () => {
        history.push(
            `${appRoutes.createJobOffer}/${offerData?.businessCardId}/${offerData?.time}?createdJobOfferId=${offerId}`
        );
    };

    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
        history.push(`${appRoutes.jobOffers}/${offerId}`);
    };

    const closeFailureModal = () => {
        setIsErrorPayment(false);
        setFailureModalOpen(false);
    };

    const closeNewCardFailureModal = () => {
        setChangeCardFailureModalOpen(false);
    };

    if (downloadingOfferData || downloadingCardData || myProfileCardsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "payment__loading-payment-data" })} />;
    }

    return (
        <div className={styles["payment"]}>
            <div className={styles["payment__title"]}>
                <FormattedMessage id="payment__title" />
            </div>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnMount
                enableReinitialize
            >
                {({ handleSubmit, setFieldValue, values }) => (
                    <>
                        <div className={classNames(styles["payment__section"], styles["payment__data-section"])}>
                            <div className={styles["payment__section--header"]}>
                                <div className={styles["payment__section--title"]}>
                                    <FormattedMessage id="payment__data-section-title" />
                                </div>
                                {!cardStepOpen && (
                                    <Button
                                        variant="no-background"
                                        className={styles["payment__header-button"]}
                                        onClick={openCardStep}
                                    >
                                        <FormattedMessage id="payment__get-data-button" />
                                    </Button>
                                )}
                            </div>
                            {cardStepOpen && (
                                <CardStep
                                    skipCardsLoading
                                    selectedCard={selectedCard}
                                    setSelectedCard={setSelectedCard}
                                />
                            )}
                            <DataSection
                                disabled={
                                    fenigePaymentStarted ||
                                    isLoadingFenige ||
                                    isLoadingPayment
                                }
                                selectedCard={selectedCard}
                                setInvoiceData={invoiceData}
                            />
                        </div>
                        <div className={styles["payment__section"]}>
                            <div className={styles["payment__section--header"]}>
                                <div className={styles["payment__section--title"]}>
                                    <FormattedMessage id="payment__order-section-title" />
                                </div>
                            </div>
                            <OrderSection
                                order={[
                                    {
                                        name: intl.formatMessage(
                                            { id: "payment__basic-offer" },
                                            { time: offerData?.time }
                                        ),
                                        amount: 1,
                                        total: priceAmount || 0,
                                        currency,
                                    },
                                ]}
                            />
                        </div>
                        <div className={styles["payment__section"]}>
                            <div className={styles["payment__section--header"]}>
                                <div className={styles["payment__section--title"]}>
                                    <FormattedMessage id="payment__payment-method-title" />
                                </div>
                                {!!offerData?.financialConditions?.bonusValue &&
                                    (values.isEditing ? (
                                        <Button
                                            variant="no-background"
                                            className={styles["payment__card-edit-button"]}
                                            onClick={() => {
                                                setFieldValue("isEditing", false);
                                                setFieldValue("cardNumber", "");
                                                setFieldValue("expirationDate", "");
                                            }}
                                        >
                                            <FormattedMessage id="common__cancel" />
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="no-background"
                                            disabled={
                                                fenigePaymentStarted ||
                                                isLoadingFenige ||
                                                isLoadingPayment
                                            }
                                            className={styles["payment__card-edit-button"]}
                                            onClick={() => setFieldValue("isEditing", true)}
                                        >
                                            <PenIcon />
                                            <FormattedMessage id="payment__edit-card-data-button" />
                                        </Button>
                                    ))}
                            </div>
                            {!!offerData?.financialConditions?.bonusValue &&
                                <>
                                    <CardSection
                                        isEditing={values.isEditing}
                                        cardData={cardData}
                                        disabled={
                                            fenigePaymentStarted ||
                                            isLoadingFenige ||
                                            isLoadingPayment
                                        }
                                    />
                                    {!cardData?.id && !values.cardNumber && !values.expirationDate && (
                                        <FieldMessages
                                            messages={[intl.formatMessage({ id: "payment__no-card-data-error" })]}
                                            mode="payment-details-error"
                                        />
                                    )}
                                </>
                            }
                            <FieldMessages
                                className={styles["payment__agreement-info-text"]}
                                mode="info"
                                messages={[intl.formatMessage({ id: "payment__warning-confirmation" })]}
                            />
                        </div>
                        <div className={styles["payment__buttons-container"]}>
                            <Button
                                className={styles["payment__button"]}
                                variant="tertiary"
                                onClick={goBack}
                                disabled={
                                    fenigePaymentStarted ||
                                    isLoadingFenige ||
                                    isLoadingPayment
                                }
                            >
                                <FormattedMessage id="common__back" />
                            </Button>
                            <Button
                                className={styles["payment__button"]}
                                onClick={() => handleSubmit()}
                                disabled={
                                    fenigePaymentStarted ||
                                    isLoadingFenige ||
                                    isLoadingPayment
                                }
                                isLoading={
                                    fenigePaymentStarted ||
                                    isLoadingFenige ||
                                    isLoadingPayment
                                }
                            >
                                <FormattedMessage id="payment__pay" />
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
            {!!successModalOpen ||
                (isSuccessPayment && (
                    <ModalWithContent
                        header={intl.formatMessage({ id: "payment__success-header" })}
                        content={intl.formatMessage({ id: "payment__success-text" })}
                        primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                        primaryAction={closeSuccessModal}
                        onClose={closeSuccessModal}
                        cancelButtonHidden
                    />
                ))}
            {!!failureModalOpen ||
                (isErrorPayment && (
                    <ModalWithContent
                        header={<ErrorHeader>{intl.formatMessage({ id: "payment__failure-header" })}</ErrorHeader>}
                        content={intl.formatMessage({ id: "payment__failure-text" })}
                        primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                        primaryAction={closeFailureModal}
                        onClose={closeFailureModal}
                        cancelButtonHidden
                    />
                ))}
            {!!changeCardFailureModalOpen && (
                <ModalWithContent
                    header={<ErrorHeader>{intl.formatMessage({ id: "payment__new-card-failure-header" })}</ErrorHeader>}
                    content={intl.formatMessage({ id: "payment__new-card-failure-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    primaryAction={closeNewCardFailureModal}
                    onClose={closeNewCardFailureModal}
                    cancelButtonHidden
                />
            )}
            <div className={styles["payment__hidden-fenige"]} ref={fenigePlatnoscRef} />
        </div>
    );
};

export default Payment;
