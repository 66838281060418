import React, { useEffect, useCallback, useState } from "react";
import { useFormik } from "formik";
import { ApiError } from "api/utils";
import { ForgottenPasswordParams } from "features/user/types";
import { useTranslationById } from "features/user/translationMessages";
import Input from "features/common/components/Input";
import Button from "features/common/components/Button";
import Spinner from "features/common/components/Spinner";
import styles from "./styles.module.scss";
import { FormattedMessage } from "react-intl";

export type Props = {
    isLoggedIn?: boolean;
    loginUserLoading?: boolean;
    loginUserLoadingError?: ApiError;
    forgotPasswordLoading?: boolean;
    forgotPasswordLoadingError?: ApiError;
    forgotPasswordLoadingSuccess?: boolean;
    forgottenPasswordAsync: (params: ForgottenPasswordParams) => void;
    forgottenPasswordreset: () => void;
};

const ForgottenPassword = ({ loginUserLoading = false, forgotPasswordLoading, forgotPasswordLoadingError, forgotPasswordLoadingSuccess, forgottenPasswordAsync, forgottenPasswordreset }: Props) => {

    const [resendDelay, setResenDelay] = useState(false)

    const t = useTranslationById();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: (values: ForgottenPasswordParams) => {
            forgottenPasswordAsync(values);
        },
    });

    useEffect(() => {
        if (forgotPasswordLoadingSuccess) {
            formik.resetForm();
            setResenDelay(true);
            
        }
    }, [forgotPasswordLoadingSuccess]);

    const handleSubmitButton = useCallback(() => {
        formik.handleSubmit();
        
        resendActivationLink();
    }, [formik]);

    const resendActivationLink = () => {
        
        setTimeout(() => {
            setResenDelay(false)
            forgottenPasswordreset()
        }, 60000);
    
    };
    
    return (
        <div className={styles["forgotten-password-form"]}>
            <div className={styles["forgotten-password-form__title"]}>{t("user-forgotten-password__title")}</div>
            <form onSubmit={formik.handleSubmit}>
                <Input
                    containerClassName={styles["forgotten-password-form__email-input"]}
                    data-testid="forgotten-password-form__email-input"
                    label={t("user-login__email-label")}
                    placeholder={t("user-login__email-label")}
                    name="email"
                    onKeyDown={formik.handleBlur}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="text"
                    isInvalid={!!formik.errors.email}
                    disabled={loginUserLoading}
                    passValue
                />
                {forgotPasswordLoadingError && !forgotPasswordLoadingSuccess &&
                    <div className={styles["forgotten-password-form__error"]}>
                        <FormattedMessage id="user-recover-password-error" />
                    </div>
                }
                {forgotPasswordLoadingSuccess && resendDelay &&
                    <div className={styles["forgotten-password-form__success"]}>
                        <FormattedMessage id="activation-info__resend-confirmation" />
                    </div>
                }
                { 

                }
                <Button
                    data-testid="forgotten-password-form__login-button"
                    as="div"
                    className={!resendDelay && !forgotPasswordLoadingSuccess ? `${styles["forgotten-password-form__login-button"]}` :`${styles["forgotten-password-form__login-button-blocked"]}` }
                    variant="login"
                    onClick={handleSubmitButton}
                    disabled={loginUserLoading}
                >
                    {forgotPasswordLoading ? (
                        <Spinner className={styles["forgotten-password-form__spinner"]} thickness="thin" />
                    ) : ( 
                        t("activation-info__resend-button")
                    )}
                </Button>
            </form>
        </div>
    );
};

export default ForgottenPassword;
