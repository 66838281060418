import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import Button from "features/common/components/Button";
import Datepicker from "features/common/components/Datepicker";
import DropzoneFileUploader from "features/common/components/DropzoneFileUploader";
import FieldMessages from "features/common/components/FieldMessages";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import FormDropdown from "features/common/components/FormDropdown";
import Input from "features/common/components/Input";
import ModalWithContent from "features/common/components/ModalWithContent";
import { allowedImageTypes, maxImageSize } from "features/common/constants";
import {
    getBirthdateMessage,
    getFirstNameMessage,
    getGenderMessage,
    getLastNameMessage,
    getPhoneNumberMessage,
} from "features/common/translationMessages";
import { Image } from "features/common/types";
import { getLocalizedYup } from "features/common/validators";
import { srcToFile } from "features/myProfile/components/MyData/component";
import PhonePrefixSelector from "features/myProfile/components/PhonePrefixSelector";
import { appRoutes } from "features/routing/routes";
import { UserDto } from "features/user/dtos";
import { useSignOut } from "features/user/hooks";
import { CreateProfileFormValues, mapCreateUserProfileFormValuesToDto } from "features/user/mappers";
import { User } from "features/user/models";
import {
    getBackMessage,
    getCancelCreateProfileMessage,
    getConfirmActionMessage,
    getConfirmMessage,
} from "features/user/translationMessages";
import { createProfileFormValidationSchema } from "features/user/validators";
import { Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Cropper from "react-cropper";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import { MyProfileUserDetailsDto } from "../../../../myProfile/dtos";

const testId = "create-profile-form";

const initialFormValues: CreateProfileFormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
};

export interface Props {
    userProfileCreated?: boolean;
    userProfileCreating?: boolean;
    userProfileCreatingError?: ApiError;
    user?: User;
    userDto?: UserDto;
    userId?: string;
    getUserAsync: (userId: string) => void;
    createUserProfileAsync: (formData: Partial<MyProfileUserDetailsDto>, userId: string) => void;
    updateProfilePictureAsync: (userPhoto: File, cancelToken?: CancelToken) => void;
    resetUserProfileCreated: () => void;
}

const createGenderDropdownOptions = (intl: IntlShape) => [
    {
        value: "FEMALE",
        display: intl.formatMessage({ id: "common__female" }),
    },
    {
        value: "MALE",
        display: intl.formatMessage({ id: "common__male" }),
    },
    {
        value: "UNKNOWN",
        display: intl.formatMessage({ id: "common__prefer-not-to-specify" }),
    },
];

const CreateProfileForm = ({
    userProfileCreated,
    userProfileCreating,
    userProfileCreatingError,
    userId,
    user,
    getUserAsync,
    createUserProfileAsync,
    updateProfilePictureAsync,
    resetUserProfileCreated,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const signOut = useSignOut();
    const [phonePrefix, setPhonePrefix] = useState<string>('+48');

    const backMessage = getBackMessage(intl);
    const confirmMessage = getConfirmMessage(intl);
    const nameIntlMessage = getFirstNameMessage(intl);
    const lastIntlMessage = getLastNameMessage(intl);
    const genderIntlMessage = getGenderMessage(intl);
    const birthdateIntlMessage = getBirthdateMessage(intl);
    const confirmActionMessage = getConfirmActionMessage(intl);
    const phoneNumberIntlMessage = getPhoneNumberMessage(intl);
    const cancelCreateProfileMessage = getCancelCreateProfileMessage(intl);
    const genderDropdownOptions = createGenderDropdownOptions(intl);

    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return createProfileFormValidationSchema(localYup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [cropper, setCropper] = useState<any>();

    const errorMessage: string | undefined = useMemo(() => {
        if (!userProfileCreatingError) {
            return;
        }

        if (userProfileCreatingError?.response?.status === 409) {
            return intl.formatMessage({ id: "my-profile__cannot-duplicate-phone-number" });
        }

        return intl.formatMessage({ id: "my-profile__details-update-common-error" });
    }, [intl, userProfileCreatingError]);

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            return cropper.getCroppedCanvas();
        }
    };

    const onSubmit = async (values: CreateProfileFormValues) => {
        if (userId) {
            const cropData = getCropData();
            const newValues = { ...values, phonePrefix }

            if (cropData) {
                srcToFile(cropData.toDataURL('image/jpeg'), "avatar.jpeg", "image/jpeg").then(async (image: File) => {
                    const mappedValues = await mapCreateUserProfileFormValuesToDto(newValues);
                    await updateProfilePictureAsync(image)
                    createUserProfileAsync(mappedValues, userId);
                });
            } else {
                const mappedValues = await mapCreateUserProfileFormValuesToDto(newValues);
                createUserProfileAsync(mappedValues, userId);
            }
        }
    };

    useEffect(() => {
        if (userProfileCreated && userId) {
            resetUserProfileCreated();
            getUserAsync(userId);
            history.push(appRoutes.jobOffers);
        }
    }, [getUserAsync, history, resetUserProfileCreated, userId, userProfileCreated]);

    const initialValues: CreateProfileFormValues = useMemo(() => {
        const values = initialFormValues;

        if (user?.firstName) {
            values.firstName = user.firstName;
        }

        if (user?.lastName) {
            values.lastName = user.lastName;
        }

        if (user?.phoneNumber) {
            values.phoneNumber = user.phoneNumber;
        }

        return values;
    }, [user]);

    return (
        <div className={styles["create-profile-form"]}>
            {isCancelModalOpen && (
                <ModalWithContent
                    data-testid={`${testId}__cancel-modal`}
                    className={styles["create-profile-form__cancel-modal"]}
                    header={confirmActionMessage}
                    content={<div>{cancelCreateProfileMessage}</div>}
                    primaryButtonContent={confirmMessage}
                    secondaryButtonContent={backMessage}
                    onClose={() => setIsCancelModalOpen(false)}
                    primaryAction={() => {
                        signOut(appRoutes.dashboard);
                    }}
                />
            )}
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnMount={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                }) => (
                    <form className={styles["create-profile-form__form"]} onSubmit={handleSubmit}>
                        <div className={styles["create-profile-form__form-wrapper"]}>
                            <div className={styles["create-profile-form__text-form"]}>
                                <h2 className={styles["create-profile-form__main-header"]}>
                                    <FormattedMessage id="create-profile__form-header" />
                                </h2>
                                <Input
                                    data-testid={`${testId}__first-name-input`}
                                    name="firstName"
                                    label={`${nameIntlMessage}*`}
                                    placeholder={`${nameIntlMessage}*`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    isInvalid={!!touched.firstName && !!errors.firstName}
                                    error={!!touched.firstName ? errors.firstName : undefined}
                                    helperMessages={[<FieldRequiredMessage />]}
                                    withValidation
                                />
                                <Input
                                    data-testid={`${testId}__last-name-input`}
                                    name="lastName"
                                    label={`${lastIntlMessage}*`}
                                    placeholder={`${lastIntlMessage}*`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    isInvalid={!!touched.lastName && !!errors.lastName}
                                    error={!!touched.lastName ? errors.lastName : undefined}
                                    helperMessages={[<FieldRequiredMessage />]}
                                    withValidation
                                />
                                <FormDropdown
                                    data-testid={`${testId}__gender-dropdown`}
                                    id="create-profile-form__gender-dropdown"
                                    options={genderDropdownOptions ?? []}
                                    selected={genderDropdownOptions?.find((option) => option.value === values.gender)}
                                    label={`${genderIntlMessage}*`}
                                    placeholder={`${genderIntlMessage}*`}
                                    onSelect={(value) => setFieldValue("gender", value)}
                                    onToggle={(expanded) =>
                                        !expanded && setTimeout(() => setFieldTouched("gender", true))
                                    }
                                    isInvalid={!!touched.gender && !!errors.gender}
                                    error={errors.gender}
                                    helperMessages={[<FieldRequiredMessage />]}
                                    withValidation
                                />
                                <Datepicker
                                    data-testid={`${testId}__birthday-input`}
                                    id="create-profile-form__birthday-input"
                                    label={`${birthdateIntlMessage}*`}
                                    placeholder={`${birthdateIntlMessage}*`}
                                    onDateSelect={(date?: Date) => setFieldValue("birthDate", date)}
                                    onBlur={() => setFieldTouched("birthDate")}
                                    date={values.birthDate}
                                    isInvalid={!!touched.birthDate && !!errors.birthDate}
                                    error={!!touched.birthDate ? errors.birthDate : undefined}
                                    helperMessages={[<FieldRequiredMessage />]}
                                    withValidation
                                    endOnCurrentDate
                                />
                                <div>
                                    <PhonePrefixSelector onChange={(newValue) => setPhonePrefix(newValue)} value={phonePrefix}>
                                        <Input
                                            data-testid={`${testId}__phone-number-input`}
                                            name="phoneNumber"
                                            label={`${phoneNumberIntlMessage}*`}
                                            placeholder={`${phoneNumberIntlMessage}*`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                            isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                                            error={!!touched.phoneNumber ? errors.phoneNumber : undefined}
                                            helperMessages={[<FieldRequiredMessage />]}
                                            withValidation
                                        />
                                    </PhonePrefixSelector>
                                </div>
                            </div>
                            <div className={styles["create-profile-form__photo-form"]}>
                                <h2 className={styles["create-profile-form__photo-header"]}>
                                    <FormattedMessage id="create-profile__photo-header" />
                                </h2>
                                {values.profilePicture ? (
                                    <>
                                        <Cropper
                                            className="oval"
                                            aspectRatio={1}
                                            viewMode={2}
                                            src={values.profilePicture.fileUrl}
                                            dragMode="move"
                                            guides={false}
                                            responsive
                                            autoCropArea={1}
                                            checkOrientation={false}
                                            highlight={false}
                                            center={false}
                                            zoomOnWheel={false}
                                            movable={false}
                                            background
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                        />
                                        <div
                                            data-testid={testId ? `${testId}__image-remove-button` : undefined}
                                            className={styles["create-profile-form__remove-image-button"]}
                                            onClick={(e) => setFieldValue("profilePicture", undefined)}
                                        >
                                            <TimesIcon />
                                        </div>
                                    </>
                                ) : (
                                    <DropzoneFileUploader
                                        data-testid={`${testId}__photo-uploader`}
                                        maxSize={maxImageSize}
                                        allowedTypes={allowedImageTypes}
                                        onUpload={(images: Image[]) => setFieldValue("profilePicture", images[0])}
                                        doublePlaceholder
                                        buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-files" />}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles["create-profile-form__control-buttons"]}>
                            <Button
                                data-testid={`${testId}__submit-button`}
                                type="submit"
                                isLoading={userProfileCreating}
                                disabled={!isValid}
                            >
                                <FormattedMessage id="common__next" />
                            </Button>
                            {!!errorMessage && <FieldMessages messages={[errorMessage]} mode="error" />}
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default CreateProfileForm;
