import { ReactComponent as MagnifierIcon } from "assets/icons/magnifier.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import Headroom from "features/common/components/Headroom";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useScroll from "features/common/hooks/useScroll";
import { DropdownOption, SearchForm, SectionName, Tag } from "features/common/types";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import { JobOffersFetchParams } from "features/jobOffers/types";
import SearchSummary from "features/offers/components/SearchBox/SearchSummary";
import { getKmIntlMessage } from "features/offers/translationMessages";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SearchBoxForm from "./SearchBoxForm";
import styles from "./styles.module.scss";

interface Props {
    asJobOffersSearch?: boolean;
    fetchParams: JobOffersFetchParams | EmployeeOffersFetchParams;
    locationsDetails?: Tag[];
    onFetchParamsChange: (params: JobOffersFetchParams | EmployeeOffersFetchParams) => void;
    resetPaginationState: () => void;
    getLocationsDetailsAsync: (locationsIds: string[]) => void;
}

const SearchBox = ({
    asJobOffersSearch,
    fetchParams,
    locationsDetails,
    onFetchParamsChange,
    resetPaginationState,
    getLocationsDetailsAsync,
}: Props) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();
    const [scrolledDown, setScrolledDown] = useState(false);
    const [mobileSummaryVisible, setMobileSummaryVisible] = useState(false);
    const [distanceDisabled, setDistanceDisabled] = useState<boolean>(false);
    const kmIntlMessage = getKmIntlMessage(intl);
    const distanceLowerBound = 50;
    const distanceUpperBound = 100;
    const lowerStep = 5;
    const upperStep = 25;
    const [initialValuesFormValues, setInitialValuesFormValues] = useState({
        term: {
            input: "",
            option: { value: "" },
            section: "",
        },
        placeId: "",
        location: { tags: [], input: "" },
        distance: null as unknown as DropdownOption<number>,
    });

    const onScroll = () => {
        const y = window.scrollY;
        if ((y > 250 && deviceClass === "smartphone") || (y > 200 && deviceClass !== "smartphone")) {
            setScrolledDown(true);
        } else if ((y <= 250 && deviceClass === "smartphone") || (y <= 200 && deviceClass !== "smartphone")) {
            setScrolledDown(false);
        }
    };

    useScroll(onScroll);

    const onSubmit = (values: SearchForm) => {
        const params = { ...fetchParams };

        params.search = {
            distance: values.distance?.value,
            location: [{ id: values.location.input, name: values.location.input, section: "cities" }],
            placeId: values.placeId,
            term: {
                rawInputText: values.term?.input,
                section: values.term?.section,
                selectedSuggestionValue: values.term?.option?.value,
            },
        };
        params.offset = 1;

        if (
            deviceClass !== "desktop" &&
            (params.search.distance || params.search.location.length > 0 || params.search.term?.rawInputText)
        ) {
            setMobileSummaryVisible(true);
        } else {
            setMobileSummaryVisible(false);
        }

        resetPaginationState();
        params.amendOffers = false;
        onFetchParamsChange(params);
    };

    const generateDistanceOptions = (): DropdownOption<number>[] => {
        const distanceOptions: DropdownOption<number>[] = [];
        for (let i = 0; i < distanceLowerBound; i += lowerStep) {
            distanceOptions.push({
                value: i,
                display: `+ ${i} ${kmIntlMessage}`,
            });
        }

        for (let i = 50; i <= distanceUpperBound; i += upperStep) {
            distanceOptions.push({
                value: i,
                display: `+ ${i} ${kmIntlMessage}`,
            });
        }
        return distanceOptions;
    };

    const distanceOptions = generateDistanceOptions();
    const scrollToTop = () => window.scrollTo(0, 0);

    const initialValues: SearchForm = {
        term: {
            input: "",
            option: { value: "" },
            section: "",
        },
        location: { tags: [], input: "" },
        distance: null as unknown as DropdownOption<number>,
    };

    const currentValues: SearchForm = {
        term: {
            input: fetchParams.search?.term?.rawInputText ?? initialValues.term.input,
            option: { value: fetchParams.search?.term?.selectedSuggestionValue ?? initialValues.term.option.value },
            section: fetchParams.search?.term?.section ?? initialValues.term.section,
        },
        location: { tags: [], input: fetchParams.search?.location[0]?.id ?? initialValues.location.input },
        placeId: fetchParams.search?.placeId,
        distance: fetchParams.search?.distance
            ? distanceOptions.find((option) => option.value === fetchParams.search?.distance) ?? initialValues.distance
            : initialValues.distance,
    };

    useEffect(() => {
        const locationsFromQuery = fetchParams.search?.location
            .filter((locationTag) => locationTag?.isWithoutDetails)
            .map((tag) => tag.id);

        if (locationsFromQuery) {
            getLocationsDetailsAsync(locationsFromQuery);
        }
    }, [fetchParams.search, getLocationsDetailsAsync]);

    return (
        <>
            <h2 className={styles["offers-search-box__main-header"]}>
                {asJobOffersSearch ? (
                    <FormattedMessage id="common__job-offers" />
                ) : (
                    <FormattedMessage id="common__employee-offers" />
                )}
            </h2>
            <Formik
                initialValues={currentValues} onSubmit={onSubmit} enableReinitialize>
                {({ values }) => (
                    <Form className={styles["offers-search-box__form"]}>
                        {deviceClass === "desktop" && scrolledDown && (
                            <div className={styles["offers-search-box__placeholder"]} />
                        )}
                        {deviceClass === "desktop" && (
                            <div
                                className={classNames({
                                    [styles["offers-search-box__form-scroll-container"]]: scrolledDown,
                                })}
                            >
                                <div
                                    className={classNames(styles["offers-search-box__form-inline"], {
                                        [styles["offers-search-box__form-scroll"]]: scrolledDown,
                                    })}
                                >
                                    <SearchBoxForm
                                        initialValues={initialValues}
                                        scrollToTop={scrollToTop}
                                        distanceOptions={distanceOptions}
                                        asJobOffersSearch={asJobOffersSearch}
                                        distanceDisabled={distanceDisabled}
                                        setDistanceDisabled={setDistanceDisabled}
                                    />
                                </div>
                            </div>
                        )}

                        {deviceClass !== "desktop" && (
                            <div
                                className={classNames({
                                    [styles["offers-search-box__form-smartphone"]]: deviceClass === "smartphone",
                                    [styles["offers-search-box__form-inline"]]: deviceClass === "tablet",
                                })}
                            >
                                <SearchBoxForm
                                    initialValues={initialValuesFormValues || initialValues}
                                    scrollToTop={scrollToTop}
                                    distanceOptions={distanceOptions}
                                    asJobOffersSearch={asJobOffersSearch}
                                    distanceDisabled={distanceDisabled}
                                    setDistanceDisabled={setDistanceDisabled}
                                />
                                <Headroom pinStart={250}>
                                    {(isVisible) => (
                                        <div
                                            className={classNames(
                                                {
                                                    [styles["offers-search-box__smartphone-headroom-mobile-box"]]:
                                                        !mobileSummaryVisible,
                                                },
                                                {
                                                    [styles[
                                                        "offers-search-box__smartphone-headroom-mobile-box--no-shadow"
                                                    ]]: !isVisible,
                                                }
                                            )}
                                            id="headroom-search-button"
                                        >
                                            {mobileSummaryVisible ? (
                                                <SearchSummary values={values} />
                                            ) : (
                                                <Button as="a" href="#top" variant="filter-mobile">
                                                    {/* <MagnifierIcon /> */}
                                                    <FormattedMessage id="common__search" />
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </Headroom>
                            </div>
                        )}
                        <hr className={styles["offers-search-box__divider"]} />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SearchBox;
