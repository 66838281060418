import {getTime} from "date-fns";
import {OfferType} from "features/common/types";
import {EmployeeOfferDetailsResponseDto} from "features/employeeOffers/dtos";
import {mapEmployeeOfferDetailsResponseDtoToOfferDetails} from "features/employeeOffers/mappers";
import {JobOfferDetailsResponseDto} from "features/jobOffers/dtos";
import {mapJobOfferDetailsDtoToJobOfferDetails} from "features/jobOffers/mappers";
import {ConversationDetailsResponseDto, ConversationsDto} from "features/messages/dtos";
import {Conversation, ConversationDetails} from "features/messages/models";
import {ApplicationStatus, GetConversationsFetchParams} from "features/messages/types";
import {appRoutes} from "features/routing/routes";
import {IntlShape} from "react-intl";

export const mapConversationsDtoToConversationsList = (dto: ConversationsDto): Conversation[] => {
    for (let i = 0; i < dto.content.length; i++) {
        if (dto.content[i].messages.length === 0) {
            dto.content.splice(i, 1)
            i--;
        }
    }
    return dto.content.map((conversationDto) => {
        const user =
            dto.currentUserId === conversationDto.initiator.userId
                ? conversationDto.recipient
                : conversationDto.initiator;

        const lastMessage = conversationDto.messages.sort(
            (a, b) => getTime(new Date(b.timestamp)) - getTime(new Date(a.timestamp))
        )[0];

        return {
            id: conversationDto.conversationId,
            offerId: conversationDto.offer.offerId,
            user: {
                name: `${user.firstname} ${user.lastname[0]}.`,
                imageUrl: user.image,
                id: user.userId,
            },
            lastMessageText: lastMessage.content,
            lastMessageStatus: lastMessage.applicationStatus,
            lastMessageTimestamp: new Date(lastMessage.timestamp),
            lastMessageApplicationId: lastMessage.jobApplicationId,
            offerTitle: conversationDto.offer.name,
            offerType: OfferType.Job,
            genusOffer: conversationDto.genusOffer,
            acknowledged: conversationDto.acknowledged,
        };
    });
};

export const mapGetConversationsFetchParamsToQueryParams = (params: GetConversationsFetchParams) =>
    `?size=${params.pageSize}&pageNumber=${params.pageNumber}&sort=${params.sort || 'asc'}&sortBy=lastMessageDate`;

export const mapApplicationStatusToText = (status: ApplicationStatus, applicationId: number, intl: IntlShape) => {
    switch (status) {
        case ApplicationStatus.ApplicationSent:
            return intl.formatMessage({ id: "messages__application-sent" }, { applicationId });
        case ApplicationStatus.ApplicationAccepted:
            return intl.formatMessage({ id: "messages__application-accepted" }, { applicationId });
        case ApplicationStatus.ArrivalAccepted:
            return intl.formatMessage({ id: "messages__arrival-accepted" }, { applicationId });
        case ApplicationStatus.BonusGiven:
            return intl.formatMessage({ id: "messages__bonus-given" }, { applicationId });
    }
};

export const mapConversationDetailsDtoToConversationDetails = (
    dto: ConversationDetailsResponseDto
): ConversationDetails => {
    const user =
        dto.currentUserId === dto.messagesDto.initiator.userId ? dto.messagesDto.recipient : dto.messagesDto.initiator;
    return {
        messages: dto.messagesDto.messages
            .map((messageDto) => ({
                ...messageDto,
                timestamp: new Date(messageDto.timestamp),
            }))
            .sort((messageA, messageB) => getTime(messageB.timestamp) - getTime(messageA.timestamp)),
        offerDetails: dto.messagesDto.genusOffer === "EMPLOYEE" ? mapEmployeeOfferDetailsResponseDtoToOfferDetails(dto.offerDto as EmployeeOfferDetailsResponseDto) : mapJobOfferDetailsDtoToJobOfferDetails(dto.offerDto as JobOfferDetailsResponseDto),
        user: {
            name: `${user.firstname} ${user.lastname}`,
            imageUrl: user.image,
            id: user.userId,
        },
        genusOffer: dto.messagesDto.genusOffer,
    };
};

// export const mapMessagesThreadDtoToMessageItems = (dto: MessagesThreadDto) =>
//     dto.messages.map((message) => {
//         const date = mapUtcStringToLocalDate(message.header.sentOn)!;

//         return {
//             ...message,
//             date,
//             from: message.header.from,
//             time: `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`,
//         };
//     });

// export const mapMessagesThreadDtoToFirstUnreadMessage = (dto: MessagesThreadDto): MessageThread | null => {
//     const unreadMessage = dto.messages.find((message) => !message.read && dto.user.id === message.header.from.id);

//     return unreadMessage
//         ? {
//               ...unreadMessage,
//               from: unreadMessage.header.from,
//               date: mapUtcStringToLocalDate(unreadMessage.header.sentOn)!,
//               time: "",
//           }
//         : null;
// };

// export const mapMessagesThreadDtoToUnreadCount = (dto: MessagesThreadDto) =>
//     dto.messages.filter((message) => !message.read && dto.user.id === message.header.from.id).length;
// intl.formatMessage({ id: 'Hello' })
export const mapNotifications = (dto: any, intl: IntlShape) => dto.map((notification: any) => {
    switch (notification.relatedResourceType) {
        case "JOB_APPLICATION":
            return {
                ...notification,
                route: appRoutes.myJobOffers,
                message: intl.formatMessage({ id: "notification_new-application" }, { name: `${notification.relatedUserFirstname} ${notification.relatedUserLastname}` })
            };
        case "MESSAGE":
            return {
                ...notification,
                route: appRoutes.myMessages,
                message: intl.formatMessage({ id: "notification_new-message-from" }, { name: `${notification.relatedUserFirstname} ${notification.relatedUserLastname}` })

            };
        case "EMPLOYEE_RATE":
            return {
                ...notification,
                route: `${appRoutes.myProfile}/card/${notification.relatedResourceId}`,
                message: intl.formatMessage({ id: "notification_new-rate" })

            };
        case "EMPLOYER_RATE":
            return {
                ...notification,
                route: `${appRoutes.myProfile}/card/${notification.relatedResourceId}`,
                message: intl.formatMessage({ id: "notification_new-rate" })
            };

        case "RATE_EMPLOYEE_INVITATION":
            return {
                ...notification,
                route: `${appRoutes.jobOffers}/${notification.relatedResourceId}/rate`,
                message: intl.formatMessage({ id: "notification_rate-invitation-employee" }, { name: `${notification.relatedUserFirstname} ${notification.relatedUserLastname}` })

            };

        case "RATE_EMPLOYER_INVITATION":
            return {
                ...notification,
                route: `${appRoutes.jobOffers}/${notification.relatedResourceId}/rate`,
                message: intl.formatMessage({ id: "notification_rate-invitation-employer" }, { name: `${notification.relatedUserFirstname} ${notification.relatedUserLastname}` })
            };

        case "EMPLOYEE_RATED":
            return {
                ...notification,
                route: `${appRoutes.jobOffers}/${notification.relatedResourceId}/rate`,
                message: intl.formatMessage({ id: "notification_rated-employee" }, { name: `${notification.relatedUserFirstname} ${notification.relatedUserLastname}` })
            };

        case "EMPLOYER_RATED":
            return {
                ...notification,
                route: `${appRoutes.jobOffers}/${notification.relatedResourceId}/rate`,
                message: intl.formatMessage({ id: "notification_rated-employer" }, { name: `${notification.relatedUserFirstname} ${notification.relatedUserLastname}` })
            };

        default:
            return notification;
    }
});
