import "cropperjs/dist/cropper.css";
import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as AccountIcon } from "assets/icons/account.svg";
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import { AxiosError } from "axios";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import DropzoneFileUploader from "features/common/components/DropzoneFileUploader";
import FieldRequiredMessage from "features/common/components/FieldRequiredMessage";
import Input from "features/common/components/Input";
import ModalWithContent from "features/common/components/ModalWithContent";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import Spinner from "features/common/components/Spinner";
import Tooltip from "features/common/components/Tooltip";
import { allowedImageTypes, maxImageSize } from "features/common/constants";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import {
    getCancelMessage,
    getFirstNameMessage,
    getLastNameMessage,
    getPhoneNumberMessage,
    getSaveMessage,
} from "features/common/translationMessages";
import { Image } from "features/common/types";
import PaymentDataSection from "features/myProfile/components/PaymentDataSection";
import { MyProfileUserDetailsDto } from "features/myProfile/dtos";
import {
    getMyProfileMinimalLengthMessage,
    getMyProfileMyDataIntlMessage,
    getMyProfilePhoneNumberLengthValidationMessage,
    getMyProfilePhoneNumberRegexMessage,
    getTooltipMessage,
} from "features/myProfile/translationMessages";
import {
    isFirstNameValid,
    isLastNameValid,
    isPhoneNumberCharactersValid,
    isPhoneNumberLengthValid,
    isUserDetailsValid,
} from "features/myProfile/validators";
import { User } from "features/user/models";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
/* eslint-disable max-lines */
import Cropper from "react-cropper";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorHeader from "../../../common/components/ModalWithContent/ErrorHeader";
import PhonePrefixSelector from "../PhonePrefixSelector";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    user?: User;
    userId?: string;
    isUserLoading?: boolean;
    userLoadingError?: ApiError;
    profilePictureUploading?: boolean;
    profilePictureUploaded?: boolean;
    profilePictureUploadingError?: ApiError;
    userDetailsUpdating?: boolean;
    userDetailsUpdated?: boolean;
    userDetailsUpdatingError?: ApiError;
    updateMyProfileDetailsAsync: (userDetails: MyProfileUserDetailsDto, cancelToken?: CancelToken) => void;
    updateProfilePictureAsync: (userPhoto: File, cancelToken?: CancelToken) => void;
    refreshUserProfileAsync: (userId: string, cancelToken?: CancelToken) => void;
    deleteMyAccountAsync: (params: any, cancelToken?: CancelToken) => void;
    deleteMyAccountError?: AxiosError;
}

export function srcToFile(src: any, fileName: any, mimeType: any) {
    return fetch(src)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], fileName, { type: mimeType });
        });
}

const MyDataComponent = ({
    "data-testid": testId,
    user,
    userId,
    isUserLoading,
    userLoadingError,
    profilePictureUploading,
    profilePictureUploaded,
    profilePictureUploadingError,
    userDetailsUpdating,
    userDetailsUpdated,
    userDetailsUpdatingError,
    updateMyProfileDetailsAsync,
    updateProfilePictureAsync,
    refreshUserProfileAsync,
    deleteMyAccountAsync,
    deleteMyAccountError,
}: Props) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();
    const createCancelToken = useCreateCancelToken();

    const myDataDescription = getMyProfileMyDataIntlMessage(intl);
    const firstNameFieldLabel = getFirstNameMessage(intl);
    const lastNameFieldLabel = getLastNameMessage(intl);
    const phoneNumberFieldLabel = getPhoneNumberMessage(intl);
    const cancelMessage = getCancelMessage(intl);
    const saveMessage = getSaveMessage(intl);
    const minimalLengthValidationMessage = getMyProfileMinimalLengthMessage(intl);
    const phoneNumberLengthValidationMessage = getMyProfilePhoneNumberLengthValidationMessage(intl);
    const phoneNumberRegexValidationMessage = getMyProfilePhoneNumberRegexMessage(intl);
    const tooltipMessage = getTooltipMessage(intl);
    const [isProfilePictureModalVisible, setIsProfilePictureModalVisible] = useState<boolean>(false);
    const [isEditPhotoButtonVisible, setEditPhotoButtonVisible] = useState<boolean>(true);
    const [isInEditMode, setEditMode] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>(user?.lastName ?? "");
    const [firstName, setFirstName] = useState<string>(user?.firstName ?? "");
    const [phoneNumber, setPhoneNumber] = useState<string>(user?.phoneNumber ?? "");
    const [phonePrefix, setPhonePrefix] = useState<string>(user?.phonePrefix ?? "");
    const [isSaveConfirmModalVisible, setSaveConfirmModalVisible] = useState<boolean>(false);
    const [isProfilePictureSaveClicked, setProfilePictureSaveClicked] = useState<boolean>(false);
    const [isSaveUserDetailsClicked, setSaveUserDetailsClicked] = useState<boolean>(false);
    const [isMyProfileDataChanged, setMyProfileDataChanged] = useState<boolean>(false);
    const [deleteMyAccountPopup, setDeleteMyAccountPopup] = useState<boolean>(false);
    const [selectedProfileImages, setSelectedProfileImages] = useState<Image[]>([]);
    const [initialProfileImageUrl, setInitialProfileImageUrl] = useState<string>();
    const [cropper, setCropper] = useState<any>();
    const [deleteError, setDeleteError] = useState<boolean>();
    const [deleteSuccess, setDeleteSuccess] = useState<boolean>();
    const [showResult, setShowResult] = useState<boolean>(false);

    const updatingErrorMessage: string | undefined = useMemo(() => {
        if (!userDetailsUpdatingError) {
            return;
        }

        if (userDetailsUpdatingError?.response?.status === 409) {
            return intl.formatMessage({ id: "my-profile__cannot-duplicate-phone-number" });
        }

        return intl.formatMessage({ id: "my-profile__details-update-common-error" });
    }, [intl, userDetailsUpdatingError]);

    const isSaveDetailsButtonSpinnerVisible =
        (userDetailsUpdating && !userDetailsUpdatingError) || (isUserLoading && !userLoadingError);

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            return cropper.getCroppedCanvas();
        }
    };

    useEffect(() => {
        if (deleteMyAccountError) {
            setDeleteError(true);
        } else if (deleteMyAccountError === false) {
            setDeleteSuccess(true);
        }
    }, [deleteMyAccountError])


    const renderChangeProfileModalDialogContent = () => {
        if (selectedProfileImages[0]) {
            return (
                <Cropper
                    className="oval"
                    style={{ width: 280, height: 280, marginLeft: "calc(50% - 140px)" }}
                    aspectRatio={1}
                    viewMode={2}
                    src={selectedProfileImages[0].fileUrl}
                    dragMode="move"
                    guides={false}
                    responsive
                    autoCropArea={1}
                    checkOrientation={false}
                    highlight={false}
                    center={false}
                    zoomOnWheel={false}
                    movable={false}
                    background
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                />
            );
        }
        return (
            <DropzoneFileUploader
                data-testid={`${testId}__photo-uploader`}
                maxSize={maxImageSize}
                files={selectedProfileImages}
                allowedTypes={allowedImageTypes}
                onUpload={(images: Image[]) => {
                    setSelectedProfileImages(images);
                }}
                buttonPlaceholder={<FormattedMessage id="dropzone-file-uploader__add-files" />}
            />
        );
    };

    const renderSaveCancelActionButtons = () => {
        return (
            <div
                className={classNames(
                    styles["my-data__action-buttons-container"],
                    styles["my-data__action-buttons-container--inline"]
                )}
            >
                {isSaveConfirmModalVisible && (
                    <ModalWithContent
                        header={<FormattedMessage id="my-profile__save-confirmation-header" />}
                        content={<FormattedMessage id="my-profile__save-confirmation-content" />}
                        primaryButtonContent={saveMessage}
                        secondaryButtonContent={cancelMessage}
                        primaryAction={() => onSaveProfileChangeClicked()}
                        onClose={() => onCancelProfileChangeClicked()}
                        primaryButtonLoading={isSaveDetailsButtonSpinnerVisible}
                        primaryButtonDisabled={isSaveDetailsButtonSpinnerVisible}
                        data-testid={`${testId}__success-modal`}
                        error={updatingErrorMessage}
                    />
                )}
                <div className={styles["my-data__action-button"]} onClick={onSaveButtonClicked}>
                    <Button
                        variant={"no-background"}
                        disabled={!isMyProfileDataChanged || !isUserDetailsValid(firstName, lastName, phoneNumber)}
                    >
                        <FormattedMessage id="common__save" />
                    </Button>
                </div>
                <div
                    className={classNames(styles["my-data__action-button"], styles["my-data__action-button-space"])}
                    onClick={() => onCancelClicked()}
                >
                    <Button as="div" variant={"no-background"}>
                        <FormattedMessage id="common__cancel" />
                    </Button>
                </div>
            </div>
        );
    };

    const renderEditMyProfileAndChangePasswordButtons = useCallback(() => {
        return (
            <div className={styles["my-data__action-buttons-container"]}>
                <div
                    className={classNames(styles["my-data__edit-my-data"], styles["my-data__action-button"])}
                    onClick={onEditMyDataClicked}
                >
                    <Button as="div" variant={"no-background"}>
                        <PenIcon className={styles["my-data__action-button-icon"]} />
                        <FormattedMessage id="my-data__edit-my-data-button" />
                    </Button>
                </div>
                {/* <div
                    className={classNames(styles["my-data__action-button"], styles["my-data__action-button-space"])}
                    onClick={onChangePasswordClicked}
                >
                    <Button as="div" variant={"no-background"}>
                        <KeyIcon className={styles["my-data__action-button-icon"]} />
                        <FormattedMessage id="my-data__change-password-button" />
                    </Button>
                </div> */}
            </div>
        );
    }, []);

    const setDefaultUserImageFromProfile = useCallback(() => {
        const images: Image[] = [];
        if (user?.imageUrl) {
            images.push({
                fileUrl: `${config.IMAGE_URL}/${user?.imageUrl}`,
                file: new File([], `${config.IMAGE_URL}/${user?.imageUrl}`),
            });
        }

        // setSelectedProfileImages(images);
    }, [user]);

    useEffect(() => {
        if (profilePictureUploaded && isProfilePictureSaveClicked && userId) {
            setIsProfilePictureModalVisible(false);
            setProfilePictureSaveClicked(false);
            setInitialProfileImageUrl(undefined);
            refreshUserProfileAsync(userId, createCancelToken());
        }
    }, [createCancelToken, isProfilePictureSaveClicked, profilePictureUploaded, refreshUserProfileAsync, userId]);

    useEffect(() => {
        setFirstName(user?.firstName || "");
        setLastName(user?.lastName || "");
        setPhoneNumber(user?.phoneNumber || "");
        setPhonePrefix(user?.phonePrefix || "");
        setMyProfileDataChanged(false);
        setDefaultUserImageFromProfile();
    }, [setDefaultUserImageFromProfile, user]);

    useEffect(() => {
        if (userDetailsUpdated && isSaveUserDetailsClicked && userId) {
            refreshUserProfileAsync(userId, createCancelToken());
            setSaveConfirmModalVisible(false);
            setSaveUserDetailsClicked(false);
            setEditPhotoButtonVisible(true);
            setEditMode(false);
            setMyProfileDataChanged(false);
        }
    }, [
        userDetailsUpdated,
        isSaveUserDetailsClicked,
        setSaveUserDetailsClicked,
        createCancelToken,
        refreshUserProfileAsync,
        isMyProfileDataChanged,
        userId,
    ]);

    useEffect(() => {
        setMyProfileDataChanged(
            !!user && (firstName !== user.firstName || lastName !== user.lastName || phoneNumber !== user.phoneNumber || phonePrefix !== user.phonePrefix)
        );
    }, [firstName, lastName, phoneNumber, phonePrefix, user]);

    const onProfilePictureDialogSaved = () => {
        if (selectedProfileImages[0].file) {
            const asd = getCropData();
            setProfilePictureSaveClicked(true);
            srcToFile(asd.toDataURL('image/jpeg'), "avatar.jpeg", "image/jpeg").then((image: File) =>
                updateProfilePictureAsync(image, createCancelToken())
            );
        }
    };

    const onProfilePictureDialogCanceled = () => {
        if (selectedProfileImages[0]) {
            return setSelectedProfileImages([]);
        }

        setIsProfilePictureModalVisible(false);
        setInitialProfileImageUrl(undefined);
        setDefaultUserImageFromProfile();
    };

    const onEditUserProfileButtonClicked = () => {
        setIsProfilePictureModalVisible(true);
        setInitialProfileImageUrl(user?.imageUrl);
    };

    const onEditMyDataClicked = () => {
        setEditMode(true);
        setEditPhotoButtonVisible(false);
    };

    const onCancelClicked = () => {
        setFirstName(user?.firstName ?? "");
        setLastName(user?.lastName ?? "");
        setPhoneNumber(user?.phoneNumber ?? "");
        setPhonePrefix(user?.phonePrefix ?? "");

        setEditMode(false);
        setEditPhotoButtonVisible(true);
        setMyProfileDataChanged(false);
    };

    const onSaveButtonClicked = () => {
        if (isUserDetailsValid(firstName, lastName, phoneNumber)) {
            setSaveConfirmModalVisible(true);
        }
    };

    const onSaveProfileChangeClicked = () => {
        if (isUserDetailsValid(firstName, lastName, phoneNumber)) {
            setSaveUserDetailsClicked(true);

            updateMyProfileDetailsAsync(
                {
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    phoneNumberPrefix: phonePrefix,
                    birthDate: user?.birthDate,
                    gender: user?.gender,
                },
                createCancelToken()
            );
        }
    };

    const onCancelProfileChangeClicked = () => {
        setFirstName(user?.firstName ?? "");
        setLastName(user?.lastName ?? "");
        setPhoneNumber(user?.phoneNumber ?? "");
        setPhonePrefix(user?.phonePrefix ?? "");

        setMyProfileDataChanged(false);
        setSaveConfirmModalVisible(false);
        setEditPhotoButtonVisible(true);
        setEditMode(false);
    };

    const onChangePasswordClicked = () => {
        // TODO: Implement action - clicking on change password button
    };

    const onDeleteMyAccount = useCallback(() => {
        setDeleteMyAccountPopup(false);
        setShowResult(true);
        try {
            deleteMyAccountAsync([]);
        }
        catch (error) {
            console.warn(error)
        }

    }, []);

    const deleteMyAccountPopupWindow = () => {
        return (
            <div className={styles["my-data__delete-my-account-popup"]}>
                <div className={styles["my-data__delete-my-account-popup__text"]}>
                    <FormattedMessage id="delete-my-account-text" />
                </div>
                <div className={styles["my-data__delete-my-account-popup__buttons"]}>
                    <div className={classNames(styles["my-data__delete-my-account-popup__buttons"], styles["my-data__delete"])} onClick={onDeleteMyAccount}>
                        <Button
                            className={styles["my-data__delete"]}
                            as="div"
                            variant={"no-background"}
                        >
                            <FormattedMessage id="single-offer-card__delete" />
                        </Button>
                    </div>
                    <div
                        className={classNames(styles["my-data__delete-my-account-popup__buttons"])}
                        onClick={() => setDeleteMyAccountPopup(false)}
                    >
                        <Button
                            className={styles["my-data__cancel"]}
                            as="div"
                            variant={"no-background"}>
                            <FormattedMessage id="common__cancel" />
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const onFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.currentTarget.value);
    }, []);

    const onLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setLastName(event.currentTarget.value);
    }, []);

    const onPhoneNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.currentTarget.value);
    }, []);

    const firstNameErrorMessage = () => (!isFirstNameValid(firstName) ? minimalLengthValidationMessage : "");

    const lastNameErrorMessage = () => (!isLastNameValid(lastName) ? minimalLengthValidationMessage : "");

    const phoneNumberErrorMessage = () => (!isPhoneNumberLengthValid(phoneNumber) ?
        phoneNumberLengthValidationMessage : !isPhoneNumberCharactersValid(phoneNumber) ?
            phoneNumberRegexValidationMessage : "");

    const tooltip = useMemo(
        () => (
            <Tooltip
                text={tooltipMessage}
                arrowPosition={deviceClass === "desktop" ? "bottom-center" : "bottom-right"}
            />
        ),
        [deviceClass, tooltipMessage]
    );
    const handleCloseErrorModal = () => {
        setShowResult(false);
    }

    return (
        <div className={styles["my-data"]}>
            {isUserLoading && <Spinner thickness="thin" />}
            {userLoadingError && (
                <div>
                    <FormattedMessage id="my-data__user-fetch-failed" />
                </div>
            )}
            {deleteError === true && showResult ? (
                <ModalWithContent
                    className={styles["my-cards__modal"]}
                    header={
                        <ErrorHeader>
                            <FormattedMessage id="delete_my_account_error" />
                        </ErrorHeader>
                    }
                    content={intl.formatMessage({ id: 'delete_my_account_error_message' })}
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseErrorModal}
                    primaryAction={handleCloseErrorModal}
                />
            ) : null
            }
            {deleteSuccess === true && deleteError !== true && showResult ? (
                <ModalWithContent
                    className={styles["my-cards__modal"]}
                    header={
                        <SuccessHeader>
                            <FormattedMessage id="delete_my_account_success" />
                        </SuccessHeader>
                    }
                    content={intl.formatMessage({ id: 'delete_my_account_success_message' })}
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseErrorModal}
                    primaryAction={handleCloseErrorModal}
                />
            ) : null}
            {user && (
                <>
                    <div className={styles["my-data__header-container"]}>
                        <h2 className={styles["my-data__main-header"]}>
                            <FormattedMessage id="my-data__header" />
                        </h2>
                    </div>
                    <div className={styles["my-data__description"]}>
                        <span>{myDataDescription}</span>
                    </div>
                    <div className={styles["my-data__user-profile-container"]}>
                        <div className={styles["my-data__image-container"]}>
                            <div className={styles["my-data__user-profile-image"]}>
                                {user?.imageUrl ? (
                                    <img src={`${config.IMAGE_URL}/${user?.imageUrl}`} alt="" />
                                ) : (
                                    <div className={styles["my-data__default-profile-image-container"]}>
                                        <AccountIcon />
                                    </div>
                                )}
                            </div>
                            {isEditPhotoButtonVisible && (
                                <Button
                                    as="div"
                                    className={styles["my-data__image-button"]}
                                    onClick={() => onEditUserProfileButtonClicked()}
                                >
                                    <PenIcon />
                                </Button>
                            )}
                            {isProfilePictureModalVisible && (
                                <ModalWithContent
                                    header={<FormattedMessage id="my-profile__change-your-picture-header" />}
                                    content={renderChangeProfileModalDialogContent()}
                                    primaryButtonContent={saveMessage}
                                    secondaryButtonContent={
                                        selectedProfileImages[0]
                                            ? intl.formatMessage({ id: "common__back" })
                                            : cancelMessage
                                    }
                                    primaryAction={() => onProfilePictureDialogSaved()}
                                    onClose={() => onProfilePictureDialogCanceled()}
                                    primaryButtonDisabled={
                                        !selectedProfileImages[0] ||
                                        profilePictureUploading ||
                                        (initialProfileImageUrl ?? "") === (selectedProfileImages[0]?.fileUrl ?? "")
                                    }
                                    primaryButtonLoading={profilePictureUploading && !profilePictureUploadingError}
                                    data-testid={`${testId}__success-modal`}
                                />
                            )}
                        </div>
                        <div className={styles["my-data__details-container"]}>
                            <div
                                className={classNames(styles["my-data__user-details"], {
                                    [styles["my-data__user-details--with-top-margin"]]: !isInEditMode,
                                })}
                            >
                                <div className={styles["my-data__user-details-row"]}>
                                    <div
                                        className={classNames(styles["my-data__field-value-column"], {
                                            [styles["my-data__input-field-container"]]: isInEditMode,
                                        })}
                                    >
                                        {isInEditMode && (
                                            <div className={styles["my-data__inputs-container"]}>
                                                <div className={styles["my-data__input-with-tooltip-container"]}>
                                                    <Input
                                                        label={`${firstNameFieldLabel}*`}
                                                        required
                                                        value={firstName}
                                                        onChange={onFirstNameChange}
                                                        error={firstNameErrorMessage()}
                                                        withValidation
                                                        data-testid={`${testId}__input-first-name`}
                                                        isInvalid={!isFirstNameValid(firstName)}
                                                        helperMessages={[<FieldRequiredMessage />]}
                                                    />
                                                    {tooltip}
                                                </div>
                                                <div className={styles["my-data__input-with-tooltip-container"]}>
                                                    <Input
                                                        label={`${lastNameFieldLabel}*`}
                                                        required
                                                        value={lastName}
                                                        onChange={onLastNameChange}
                                                        error={lastNameErrorMessage()}
                                                        withValidation
                                                        data-testid={`${testId}__input-last-name`}
                                                        isInvalid={!isLastNameValid(lastName)}
                                                        helperMessages={[<FieldRequiredMessage />]}
                                                    />
                                                    {tooltip}
                                                </div>
                                            </div>
                                        )}
                                        {!isInEditMode && (
                                            <div className={styles["my-data__field-name-column"]}>
                                                <FormattedMessage id="my-data__name-and-surname-header" />
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles["my-data__field-value-column"]}>
                                        {!isInEditMode && (
                                            <span className={styles["my-data__field-content"]}>
                                                {firstName} {lastName}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className={styles["my-data__user-details-row"]}>
                                    <div
                                        className={classNames(styles["my-data__field-value-column"], {
                                            [styles["my-data__input-field-container"]]: isInEditMode,
                                        })}
                                    >
                                        {isInEditMode && (
                                            <div className={styles["my-data__phone-input-container"]}>
                                                <div className={styles["my-data__input-with-tooltip-container"]}>
                                                    <PhonePrefixSelector onChange={(newValue) => setPhonePrefix(newValue)} value={phonePrefix}>
                                                        <Input
                                                            label={`${phoneNumberFieldLabel}*`}
                                                            required
                                                            value={phoneNumber}
                                                            onChange={onPhoneNumberChange}
                                                            error={phoneNumberErrorMessage()}
                                                            withValidation
                                                            data-testid={`${testId}__input-first-name`}
                                                            isInvalid={!isPhoneNumberLengthValid(phoneNumber)}
                                                            helperMessages={[<FieldRequiredMessage />]}
                                                            maxLength={20}
                                                        />
                                                    </PhonePrefixSelector>
                                                    {tooltip}
                                                </div>
                                            </div>
                                        )}
                                        {!isInEditMode && (
                                            <div className={styles["my-data__field-name-column"]}>
                                                <FormattedMessage id="my-data__phone-number-header" />
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles["my-data__field-value-column"]}>
                                        {!isInEditMode && (
                                            <span className={styles["my-data__field-content"]}>
                                                {phonePrefix} {phoneNumber}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className={styles["my-data__user-details-row"]}>
                                    <div className={styles["my-data__field-name-column"]}>
                                        <FormattedMessage id="common__mail" />:
                                    </div>
                                    <div className={styles["my-data__field-value-column"]}>
                                        <span className={styles["my-data__field-content"]}>{user.email}</span>
                                    </div>
                                </div>
                            </div>
                            {isInEditMode
                                ? renderSaveCancelActionButtons()
                                : renderEditMyProfileAndChangePasswordButtons()}
                            <div
                                className={classNames(styles["my-data__edit-my-data"], styles["my-data__action-button"])}

                            >
                                <Button as="div" variant={"no-background"} className={styles["my-data__delete-my-account"]} onClick={() => setDeleteMyAccountPopup(true)}>
                                    <BinIcon className={styles["my-data__action-button-icon"]} />
                                    <FormattedMessage id="admin-panel__delete-user" />
                                </Button>
                            </div>
                            {deleteMyAccountPopup ? deleteMyAccountPopupWindow() : null}
                        </div>
                    </div>
                    <PaymentDataSection />
                </>
            )}
        </div>
    );
};

export default MyDataComponent;
