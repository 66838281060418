export enum OfferSortType {
    Email,
    Title,
    Date,
}

export interface GetOfferFetchParams {
    filterValue: string;
    pageSize: number;
    offset: number;
    sortBy?: string;
    sortDirection?: string;
    globalParam?: string;
}

