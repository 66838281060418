import { ApiError } from "api/utils";
import MessagesAction, {
    amendConversations,
    getConversationDetails,
    getConversations,
    resetMessages,
    sendMessage,
} from "features/messages/actions";
import {
    mapConversationDetailsDtoToConversationDetails,
    mapConversationsDtoToConversationsList,
} from "features/messages/mappers";
import { Conversation, ConversationDetails } from "features/messages/models";
import produce from "immer";
import { getType } from "typesafe-actions";

export interface MessagesState {
    conversations?: Conversation[];
    conversationsLoading: boolean;
    conversationsLoadingError?: ApiError;
    totalConversations?: number;
    conversationDetails?: ConversationDetails;
    conversationDetailsLoading: boolean;
    conversationDetailsError?: ApiError;
    sendMessageLoading: boolean;
    sendMessageSuccess?: boolean;
    sendMessageError?: ApiError;
}

const reducer = (
    state: MessagesState = {
        conversationsLoading: false,
        conversationDetailsLoading: false,
        sendMessageLoading: false,
    },
    action: MessagesAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getConversations.request):
                draft.conversations = undefined;
                draft.conversationsLoading = true;
                draft.conversationsLoadingError = undefined;
                draft.totalConversations = undefined;
                break;
            case getType(getConversations.success):
                draft.conversations = mapConversationsDtoToConversationsList(action.payload);
                draft.conversationsLoading = false;
                draft.conversationsLoadingError = undefined;
                draft.totalConversations = action.payload.total;
                break;
            case getType(getConversations.failure):
                draft.conversations = undefined;
                draft.conversationsLoading = false;
                draft.conversationsLoadingError = action.payload;
                draft.totalConversations = undefined;
                break;
            case getType(amendConversations.request):
                draft.conversationsLoading = true;
                draft.conversationsLoadingError = undefined;
                draft.totalConversations = undefined;
                break;
            case getType(amendConversations.success):
                draft.conversations = mapConversationsDtoToConversationsList(action.payload);
                draft.conversationsLoading = false;
                draft.conversationsLoadingError = undefined;
                draft.totalConversations = action.payload.total;
                break;
            case getType(amendConversations.failure):
                draft.conversationsLoading = false;
                draft.conversationsLoadingError = action.payload;
                draft.totalConversations = undefined;
                break;
            case getType(getConversationDetails.request):
                draft.conversationDetails = undefined;
                draft.conversationDetailsLoading = true;
                draft.conversationDetailsError = undefined;
                break;
            case getType(getConversationDetails.success):
                draft.conversationDetailsLoading = false;
                draft.conversationDetails = mapConversationDetailsDtoToConversationDetails(action.payload);
                draft.conversationDetailsError = undefined;
                break;
            case getType(getConversationDetails.failure):
                draft.conversationDetailsLoading = false;
                draft.conversationDetails = undefined;
                draft.conversationDetailsError = action.payload;
                break;
            case getType(sendMessage.request):
                draft.sendMessageLoading = true;
                draft.sendMessageSuccess = false;
                draft.sendMessageError = undefined;
                break;
            case getType(sendMessage.success):
                draft.sendMessageLoading = false;
                draft.sendMessageSuccess = true;
                draft.sendMessageError = undefined;
                break;
            case getType(sendMessage.failure):
                draft.sendMessageLoading = false;
                draft.sendMessageSuccess = false;
                draft.sendMessageError = action.payload;
                break;
            case getType(resetMessages):
                draft.conversations = undefined;
                draft.conversationsLoading = false;
                draft.conversationsLoadingError = undefined;
                draft.totalConversations = undefined;
                break;
        }
    });
};

export default reducer;
