import classNames from "classnames";
import { getLanguageLevelsLabels, getLanguagesLabels } from "features/jobOffers/hooks";
import { LanguageType, RequirementLanguage } from "features/jobOffers/types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    requirementLanguages: RequirementLanguage[];
    ["data-testid"]?: string;
}

const LanguageRequirementArea = ({
    requirementLanguages,
    "data-testid": testId = "language-requirement-area",
}: Props) => {
    const intl = useIntl();
    const [languageOptions, setLanguageOptions] = useState(getLanguagesLabels(intl));
    const [languageLevelsOptions, setLanguageLevelsOptions] = useState(getLanguageLevelsLabels(intl));
    const [languageRequirementsWithLabels, setLanguageRequirementsWithLabels] = useState(
        requirementLanguages.map((requirement) => (
            {
                typeLabel: requirement.name ? languageOptions[requirement.name] : "",
                levelLabel: requirement.level ? languageLevelsOptions[requirement.level] : "",
                value: requirement,
            }
        ))
    );

    useEffect(() => {
        setLanguageLevelsOptions(getLanguageLevelsLabels(intl));
        setLanguageOptions(getLanguagesLabels(intl));

    }, [intl])

    useEffect(() => {
        setLanguageRequirementsWithLabels(
            requirementLanguages.map((requirement) => (
                {
                    typeLabel: requirement.name ? languageOptions[requirement.name] : "",
                    levelLabel: requirement.level ? languageLevelsOptions[requirement.level] : "",
                    value: requirement,
                }
            )
            ))
    }, [languageLevelsOptions, languageOptions, requirementLanguages])
    return (
        requirementLanguages && (
            <div className={styles["language-requirement-area__value-area"]}>
                {languageRequirementsWithLabels
                    .sort((a, b) => (a.value.languageType > b.value.languageType ? 1 : -1))
                    .map(
                        (requirement, index) =>
                        (
                            <div
                                data-testid={`${testId}__language-value`}
                                className={classNames({
                                    [styles["language-requirement-area__value-area--with-comma"]]:
                                        index !== requirementLanguages.length - 1,
                                })}
                                key={"value_area" + requirement.typeLabel}
                            >
                                {`${requirement.typeLabel
                                    } (${requirement.levelLabel})`}
                            </div>
                        )
                    )}
            </div>
        )
    );
};

export default LanguageRequirementArea;
