import classNames from "classnames";
import Modal from "features/common/components/Modal";
import Spinner from "features/common/components/Spinner";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    className?: string;
    message?: string | JSX.Element;
}

const SpinnerWithMessage = ({ "data-testid": testId, className, message }: Props) => (
    <Modal data-testid={`${testId}__modal`} className={classNames(styles["spinner-with-message"], className)}>
        <Spinner thickness="thin" data-testid={`${testId}__spinner`} />
        <span data-testid={`${testId}__message`}>{message}</span>
    </Modal>
);

export default SpinnerWithMessage;
