import { makeApiRequest } from "api/makeApiRequest";
import { ApiError, CancelToken } from "api/utils";
import { OfferType } from "features/common/types";
import OfferAction, {
    acceptApplication,
    acceptArrival,
    amendJobOffers,
    endOffer,
    getApplicationDetails,
    getApplications,
    getInterested,
    getJobOfferCardDetails,
    getJobOfferDetails,
    getJobOffers,
    getMyJobOffers,
    giveBonus,
    rejectApplication,
    sendDedicationJobOffer,
    sendJobOfferMessage,
    startFenigePayment,
} from "features/jobOffers/actions";
import { JobOffersApi } from "features/jobOffers/api";
import { CreateJobOfferRequestObject } from "features/jobOffers/models";
import { CreateJobOfferSchema } from "features/jobOffers/schemas";
import { JobOffersFetchParams, MyJobOffersFetchParams } from "features/jobOffers/types";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";
import { JobOfferDedicationDTO } from "../../employeeOffers/models";
import { createJobOffer } from "../actions";

export const getJobOffersAsync = (
    params: JobOffersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getJobOffersAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getJobOffers.request());

        await makeApiRequest(
            api.getJobOffersAsync,
            [params, cancelToken],
            getJobOffers.success,
            getJobOffers.failure,
            dispatch
        );
    };
};

export const getMyJobOffersAsync = (
    params: MyJobOffersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getMyJobOffersAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        const {
            offers: { myJobOffersPage, myJobOffersTotalPages },
        } = getState();

        if (myJobOffersTotalPages && myJobOffersTotalPages <= myJobOffersPage) {
            dispatch(getMyJobOffers.cancel(null, undefined));
            return;
        }
        dispatch(getMyJobOffers.request());

        await makeApiRequest(
            api.getMyJobOffersAsync,
            [{ ...params, page: myJobOffersPage, size: params.size ?? 25 }, cancelToken],
            getMyJobOffers.success,
            getMyJobOffers.failure,
            dispatch
        );
    };
};

export const amendJobOffersAsync = (
    params: JobOffersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getJobOffersAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(amendJobOffers.request());

        await makeApiRequest(
            api.getJobOffersAsync,
            [params, cancelToken],
            amendJobOffers.success,
            amendJobOffers.failure,
            dispatch
        );
    };
};

export const getInterestedAsync = (
    jobOfferId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getInterestedAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getInterested.request());

        const {
            user: { user, userId },
        } = getState();

        await makeApiRequest(
            api.getInterestedAsync,
            [jobOfferId, (userId || user?.id) as string, cancelToken],
            getInterested.success,
            getInterested.failure,
            dispatch
        );
    };
};

export const getApplicationsAsync = (
    jobOfferId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getApplicationsAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getApplications.request());

        await makeApiRequest(
            api.getApplicationsAsync,
            [jobOfferId, cancelToken],
            getApplications.success,
            getApplications.failure,
            dispatch
        );
    };
};

export const publishJobOfferAsync = (
    values: CreateJobOfferRequestObject,
    cancelToken?: CancelToken
): ThunkAction<
    void,
    RootState,
    Pick<JobOffersApi, "createJobOfferAsync" | "updateMyProfileCardAsync">,
    OfferAction
> => {
    return async (dispatch, getState, api) => {
        dispatch(createJobOffer.request());

        try {
            const response = await api.createJobOfferAsync(
                { ...values.jobOfferDetails, businessCard: values.businessCard },
                cancelToken
            );
            dispatch(createJobOffer.success(response));
        } catch (error) {
            dispatch(createJobOffer.failure(error as ApiError));
        }
    };
};

export const sendDedicationAsync = (
    data: JobOfferDedicationDTO,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "sendDedicationAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(sendDedicationJobOffer.request());

        await makeApiRequest(
            api.sendDedicationAsync,
            [data, cancelToken],
            sendDedicationJobOffer.success,
            sendDedicationJobOffer.failure,
            dispatch
        );
    };
};

export const saveJobOfferAsync = (
    schema: CreateJobOfferSchema,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "saveJobOfferAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(createJobOffer.request());

        // await makeApiRequest(
        //     api.saveJobOfferAsync,
        //     [mapCreateJobOfferSchemaToFormData(schema), cancelToken],
        //     createJobOffer.success,
        //     createJobOffer.failure,
        //     dispatch
        // );
    };
};

export const getJobOfferDetailsAsync = (
    id: number,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getJobOfferDetailsAsync" | "getCardDetailsAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getJobOfferDetails.request());

        try {
            const jobOfferDetailsData = await api.getJobOfferDetailsAsync(id, cancelToken);

            const businessCardId = jobOfferDetailsData?.data?.businessCardId;
            let businessCardDetails;
            if (businessCardId) {
                businessCardDetails = await api.getCardDetailsAsync(businessCardId);
            }

            dispatch(
                getJobOfferDetails.success({
                    ...jobOfferDetailsData,
                    businessCardDetails,
                })
            );
        } catch (error) {
            dispatch(getJobOfferDetails.failure(error as ApiError));
        }
    };
};

export const getJobOfferCardDetailsAsync = (
    id: number,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getJobOfferCardDetailsAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getJobOfferCardDetails.request());

        await makeApiRequest(
            api.getJobOfferCardDetailsAsync,
            [id, cancelToken],
            getJobOfferCardDetails.success,
            getJobOfferCardDetails.failure,
            dispatch
        );
    };
};

export const sendJobOfferMessageAsync = (
    conversationId: string,
    message: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "sendJobOfferMessageAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(sendJobOfferMessage.request());

        await makeApiRequest(
            api.sendJobOfferMessageAsync,
            [conversationId, message, cancelToken],
            sendJobOfferMessage.success,
            sendJobOfferMessage.failure,
            dispatch
        );
    };
};

export const endOfferAsync = (
    id: number,
    offerType: OfferType,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "endOfferAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(endOffer.request());

        await makeApiRequest(
            api.endOfferAsync,
            [id, offerType, cancelToken],
            endOffer.success,
            endOffer.failure,
            dispatch
        );
    };
};

export const getApplicationDetailsAsync = (
    id: number,
    isOfferOwner: boolean,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "getApplicationDetailsAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getApplicationDetails.request());

        await makeApiRequest(
            api.getApplicationDetailsAsync,
            [id, isOfferOwner, cancelToken],
            getApplicationDetails.success,
            getApplicationDetails.failure,
            dispatch
        );
    };
};

export const acceptApplicationAsync = (
    id: number,
    acceptedApplicantsNumber?: number,
    message?: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "acceptApplicationAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(acceptApplication.request());

        await makeApiRequest(
            api.acceptApplicationAsync,
            [id, acceptedApplicantsNumber, message, cancelToken],
            acceptApplication.success,
            acceptApplication.failure,
            dispatch
        );
    };
};

export const rejectApplicationAsync = (
    id: number,
    message?: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "rejectApplicationAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(rejectApplication.request());

        await makeApiRequest(
            api.rejectApplicationAsync,
            [id, message, cancelToken],
            rejectApplication.success,
            rejectApplication.failure,
            dispatch
        );
    };
};

export const acceptArrivalAsync = (
    id: number,
    message?: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "acceptArrivalAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(acceptArrival.request());

        await makeApiRequest(
            api.acceptArrivalAsync,
            [id, message, cancelToken],
            acceptArrival.success,
            acceptArrival.failure,
            dispatch
        );
    };
};

export const giveBonusAsync = (
    id: number,
    message?: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<JobOffersApi, "giveBonusAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(giveBonus.request());

        await makeApiRequest(
            api.giveBonusAsync,
            [id, message, cancelToken],
            giveBonus.success,
            giveBonus.failure,
            dispatch
        );
    };
};

export const startFenigePaymentAsync = (
    offerId: number,
    cardId: string,
    cvc2: string,
    purchaseAmount: string,
    browserScreenWidth: number,
    browserScreenHeight: number,
    browserTimeZone: number,
    browserLanguage: string,
    userFVInfo: object
): ThunkAction<void, RootState, Pick<JobOffersApi, "startFenigePaymentAsync">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(startFenigePayment.request());

        await makeApiRequest(
            api.startFenigePaymentAsync,
            [
                offerId,
                cardId,
                cvc2,
                purchaseAmount,
                browserScreenWidth,
                browserScreenHeight,
                browserTimeZone,
                browserLanguage,
                userFVInfo,
            ],
            startFenigePayment.success,
            startFenigePayment.failure,
            dispatch
        );
    };
};

export const getFenigeIndicatorRequest = (
    authenticationProcessId: string,
    duration: number
): ThunkAction<void, RootState, Pick<JobOffersApi, "getFenigeIndicatorRequest">, OfferAction> => {
    return async (dispatch, getState, api) => {
        dispatch(startFenigePayment.request());

        await makeApiRequest(
            api.getFenigeIndicatorRequest,
            [authenticationProcessId, duration],
            startFenigePayment.success,
            startFenigePayment.failure,
            dispatch
        );
    };
};
