import {
    selectBranches,
    selectBranchesLoading,
    selectBranchesLoadingError,
} from "features/common/selectors";
import { getBranchesAsync } from "features/common/thunks";
import {
    selectCardDetails,
    selectCardDetailsLoading,
    selectCardDetailsLoadingError,
    selectCountRelatedOffers,
    selectMyProfileCardDetailsUpdating,
} from "features/myProfile/selectors";
import { getCardDetailsAsync } from "features/myProfile/thunks";
import { selectUser } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import CardDetails from "./component";

interface OwnProps {
    uniqueId?: string;
}

const mapStateToProps = (state: RootState, { uniqueId }: OwnProps) => ({
    uniqueId,
    user: selectUser(state),
    cardDetails: selectCardDetails(state),
    cardDetailsLoading: selectCardDetailsLoading(state),
    cardDetailsLoadingError: selectCardDetailsLoadingError(state),
    cardDetailsUpdating: selectMyProfileCardDetailsUpdating(state),
    countRelatedOffers: selectCountRelatedOffers(state),
    branches: selectBranches(state),
    branchesLoading: selectBranchesLoading(state),
    branchesError: selectBranchesLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ getCardDetailsAsync, getBranchesAsync }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
