import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { ApiError } from "api/utils";
import { useTranslationById } from "features/user/translationMessages";
import Input from "features/common/components/Input";
import Button from "features/common/components/Button";
import Spinner from "features/common/components/Spinner";
import styles from "./styles.module.scss";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { recoverPasswordValidationSchema } from "features/user/validators";
import { useIntl } from "react-intl";
import { getLocalizedYup } from "features/common/validators";
import { FormattedMessage } from "react-intl";
import { appRoutes } from "features/routing/routes";

export interface RecoverPasswordParams {
    newPassword: string
    token: string
}

export type Props = {
    recoverPasswordLoading?: boolean;
    recoverPasswordLoadingError?: ApiError;
    recoverPasswordLoadingSuccess?: boolean;
    recoverPasswordAsync: (params: RecoverPasswordParams) => void;
};

const RecoverPassword = (props: any) => {
    const history = useHistory();
    const t = useTranslationById();
    const { recoverPasswordLoading = false, recoverPasswordAsync, recoverPasswordLoadingSuccess } = props;
    const queryToken = useMemo(() => queryString.parse(history.location.search)?.token, [history.location.search]);
    const intl = useIntl();
    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return recoverPasswordValidationSchema(localYup, intl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const toggleShowHidePassword = useCallback(() => setIsPasswordHidden((current) => !current), [setIsPasswordHidden]);
    const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
    const toggleShowHideConfirmPassword = useCallback(() => setIsConfirmPasswordHidden((current) => !current), [setIsConfirmPasswordHidden]);


    const formik = useFormik({
        initialValues: {
            newPassword: "",
            newPasswordConfirm: "",
        },
        onSubmit: (values) => {
            const params = { password: values.newPassword, token: queryToken };
            recoverPasswordAsync(params);
        },
        validationSchema
    });


    React.useEffect(() => {
        if (recoverPasswordLoadingSuccess) {
            formik.resetForm();
        }
    }, [recoverPasswordLoadingSuccess]);

    const handleSubmitButton = React.useCallback(() => formik.handleSubmit(), [formik]);

    const onLoginClick = useCallback(
        () => history.push(appRoutes.login),
        [history]
    );

    return (
        <div className={styles["recover-password-form"]}>
            <div className={styles["recover-password-form__content"]}>
                <div className={styles["recover-password-form__title"]}><FormattedMessage id="user-recover-password-new-password" /></div>
                <form onSubmit={formik.handleSubmit}>
                    <Input
                        containerClassName={styles["recover-password-form__password-input"]}
                        data-testid="recover-password-form__password-input"
                        label="Nowe hasło"
                        placeholder="Nowe hasło"
                        name="newPassword"
                        onKeyDown={(event) => {
                            formik.handleBlur(event);

                            if (event.key === "Enter") {
                                handleSubmitButton();
                            }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        type={isPasswordHidden ? "password" : "text"}
                        isInvalid={!!formik.errors.newPassword}
                        error={formik.errors.newPassword}
                        withValidation
                        disabled={recoverPasswordLoading}
                        isHidden={isPasswordHidden}
                        toggleHideShow={toggleShowHidePassword}
                        withShowHideButton
                        passValue
                    />
                    <Input
                        containerClassName={styles["recover-password-form__password-input"]}
                        data-testid="recover-password-form__password-input"
                        label="Powtórz hasło"
                        placeholder="Powtórz hasło"
                        name="newPasswordConfirm"
                        onKeyDown={(event) => {
                            formik.handleBlur(event);

                            if (event.key === "Enter") {
                                handleSubmitButton();
                            }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPasswordConfirm}
                        onChange={formik.handleChange}
                        type={isConfirmPasswordHidden ? "password" : "text"}
                        isInvalid={!!formik.errors.newPasswordConfirm}
                        error={formik.errors.newPasswordConfirm}
                        withValidation
                        disabled={recoverPasswordLoading}
                        isHidden={isConfirmPasswordHidden}
                        toggleHideShow={toggleShowHideConfirmPassword}
                        withShowHideButton
                        passValue
                    />
                    {props.recoverPasswordLoadingError && <div className={styles["recover-password-form__error"]}><FormattedMessage id="user-recover-password-error" /></div>}
                    {props.recoverPasswordLoadingSuccess && <div className={styles["recover-password-form__success"]}><FormattedMessage id="user-recover-password-change-success" /></div>}
                    <Button
                        data-testid="recover-password-form__login-button"
                        as="div"
                        className={styles["recover-password-form__login-button"]}
                        variant="login"
                        onClick={props.recoverPasswordLoadingSuccess ? onLoginClick : handleSubmitButton}
                        disabled={recoverPasswordLoading}
                    >
                        {props.recoverPasswordLoading ? (
                            <Spinner className={styles["recover-password-form__spinner"]} thickness="thin" />
                        ) : (
                            <>{props.recoverPasswordLoadingSuccess ? "Przejdź do logowania" : "Zapisz"}</>
                        )}
                    </Button>

                </form>
            </div>
        </div>
    );
};

export default RecoverPassword;
