import { ReactComponent as PenIcon } from "assets/icons/pen.svg";
import classNames from "classnames";
import { SearchForm } from "features/common/types";
import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";

export type Props = {
    values: SearchForm;
    ["data-testid"]?: string;
};

const SearchSummary = ({ values }: Props) => {
    const [expanded, setExpanded] = useState(false);

    const onEditSearchCriteria = () => {
        window.scrollTo(0, 0);
    };

    const onCriteriaClick = () => {
        setExpanded(!expanded);
    };

    const getSearchValues = useCallback(() => {
        const summary = `${values.term.input}`;
        const locations = values.location.tags.reduce((prev, curr) => `${prev}, ${curr.name}`, "").slice(2);
        const distance = `${values?.distance?.display || ""}`;

        const array = [summary, locations, distance];

        return array
            .filter((arr) => !!arr)
            .reduce((prev, curr) => `${prev}; ${curr}`, "")
            .slice(2);
    }, [values]);

    const getActiveFiltersAmount = useCallback(() => {
        let count = 0;
        if (!!values.term.input) count++;

        if (!!values.distance?.display) count++;

        count += values.location.tags.length;

        return count;
    }, [values]);

    return (
        <div className={styles["search-summary"]}>
            <div className={styles["search-summary__criteria"]} onClick={onCriteriaClick}>
                <div
                    className={classNames(styles["search-summary__summary"], {
                        [styles["search-summary__summary--expanded"]]: expanded,
                    })}
                >
                    {getSearchValues()}
                </div>
                {!expanded && (
                    <div className={styles["search-summary__activeFilters"]}>({getActiveFiltersAmount()})</div>
                )}
            </div>
            <div className={styles["search-summary__edit"]} onClick={onEditSearchCriteria}>
                <PenIcon />
            </div>
        </div>
    );
};

export default SearchSummary;
