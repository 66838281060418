import {creditCardRegex, expiryDateRegex} from "features/common/constants";
import {WorkType} from "features/common/types";
import {
    CreateJobOfferSchema,
    JobOfferApplicationSubmissionSchema,
    JobOfferDetailsStepSchema,
    JobOfferFinancialStepSchema,
    JobOfferOfferDetailsStepSchema,
    PaymentFormSchema,
    QualificationDescriptionStepSchema,
} from "features/jobOffers/schemas";
import {EducationType} from "features/jobOffers/types";
import {getAmenitiesStepValidationSchema} from "features/offers/validators";
import * as Yup from "yup";

const MIN_BONUS_VALUE = {
    PLN: 100,
    EUR: 30,
    USD: 30,
    GBP: 25
}

const createDetailsStepValidationSchema = (yup: typeof Yup) =>
    yup.object({
        offerAs: yup.string().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        businessName: yup.string().when("offerAs", {
            is: "COMPANY",
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        location: yup.object({
            placeId: yup.string().required(),
        }),
        postalCode: yup.string(),
        streetName: yup.string(),
        streetNumber: yup.string(),
        krazCertificateNumber: yup.string(),
        taxIdentificationNumber: yup.string(),
        phoneNumber: yup.string().required(),
        photos: yup.array().of(
            yup
                .object({
                    fileUrl: yup.string().required(),
                })
                .required()
        ),
    }) as Yup.Schema<JobOfferDetailsStepSchema>;

const createOfferDetailsStepValidationSchema = (yup: typeof Yup) =>
    yup.object({
        position: yup.string().required(),
        branchId: yup.string().required(),
        positionsAvailable: yup.number().min(1).required(),
        workType: yup.string().required(),
        jobStartDate: yup.date().required(),
        jobEndDate: yup.mixed().when(["workType"], {
            is: WorkType.Odd,
            then: yup.date().required(),
            otherwise: yup.date().nullable(),
        }),
        location: yup.object({
            placeId: yup.string().required(),
        }),
    }) as Yup.Schema<JobOfferOfferDetailsStepSchema>;

const createFinancialStepValidationSchema = (yup: typeof Yup) =>
    yup.object({
        salaryType: yup.string().required(),
        salaryMinValue: yup.number().min(1).required(),
        salaryMaxValue: yup.number().min(1).moreThan(yup.ref("salaryMinValue")),
        salaryCurrencyCode: yup.string().required(),
        attachBonus: yup.boolean(),
        bonusValue: yup.number().when(["attachBonus"], {
            is: true,
            then: yup.number().when(["bonusCurrencyCode"], {
                is: "PLN",
                then: yup.number().min(MIN_BONUS_VALUE.PLN).required(),
                otherwise: yup.number().when(["bonusCurrencyCode"], {
                    is: "GBP",
                    then: yup.number().min(MIN_BONUS_VALUE.GBP).required(),
                    otherwise: yup.number().when(["bonusCurrencyCode"], {
                        is: "USD",
                        then: yup.number().min(MIN_BONUS_VALUE.USD).required(),
                        otherwise: yup.number().when(["bonusCurrencyCode"], {
                            is: "EUR",
                            then: yup.number().min(MIN_BONUS_VALUE.EUR).required(),
                        }),
                    }),
                }),

            }),
            otherwise: yup.number(),
        }),
        bonusCurrencyCode: yup.string().when(["attachBonus"], {
            is: true,
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        bonusType: yup.string().when(["attachBonus"], {
            is: true,
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
    }) as Yup.Schema<JobOfferFinancialStepSchema>;

export const createRequirementsStepValidationSchema = (yup: any) =>
    yup.object({
        languages: yup.array().of(
            yup.object().shape({
                isChecked: yup.boolean().default(false),
                level: yup.string().when("isChecked", {
                    is: true,
                    then: yup.string().required(),
                }),
            })
        ),
        education: yup.array().of(
            yup.object().shape({
                isChecked: yup.boolean().default(false),
                otherValue: yup.string().when(["id", "isChecked"], {
                    is: (id: EducationType, isChecked: boolean) => {
                        return id === EducationType.Other && isChecked;
                    },
                    then: yup.string().required(),
                }),
            })
        ),
        programs: yup.string().max(250),
        other: yup.string().max(250),
        permissions: yup.string().max(250),
        certificates: yup.string().max(250),
        experience: yup.array().of(yup.string()),
        drivingLicenses: yup.array().of(yup.string()),
    });

const createAdditionalInfoStepValidationSchema = (yup: any) =>
    yup.object({
        additionalInfo: yup.string().max(2000),
    });

export const createJobOfferFormValidationSchema = (yup: typeof Yup, isCopying?: boolean) =>
    yup
        .object<CreateJobOfferSchema>({
            offerLength: !!isCopying ? yup.string().required() : yup.string(),
            detailsStep: createDetailsStepValidationSchema(yup),
            offerDetailsStep: createOfferDetailsStepValidationSchema(yup),
            financialStep: createFinancialStepValidationSchema(yup),
            additionalInfoStep: createAdditionalInfoStepValidationSchema(yup),
            requirementsStep: createRequirementsStepValidationSchema(yup),
            amenitiesStep: getAmenitiesStepValidationSchema(yup),
        })
        .required();

export const createApplicationSubmissionValidationSchema = (yup: typeof Yup) =>
    yup
        .object<JobOfferApplicationSubmissionSchema>({
            qualificationDescriptionStep: yup.object<QualificationDescriptionStepSchema>({
                description: yup.string().trim().max(2000),
            }) as Yup.Schema<QualificationDescriptionStepSchema>,
        })
        .required();

export const createPaymentFormSchema = (yup: typeof Yup, isFenigePayment: boolean) =>
    yup.object({
        employerType: yup.string().required(),
        companyName: yup.mixed().when("employerType", {
            is: "COMPANY",
            then: yup.string().required(),
            otherwise: yup.mixed(),
        }),
        taxIdentificationNumber: yup.mixed().when("employerType", {
            is: "COMPANY",
            then: yup.string().required(),
            otherwise: yup.mixed(),
        }),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phoneNumber: yup.string().required(),
        location: yup.object({
            placeId: yup.string()
        }),
        email: yup.string().required(),
        country: yup.string().required(),
        city: yup.string().required(),
        street: yup.string().required(),
        postalCode: yup.string().required(),
        attachInvoice: yup.boolean(),
        isEditing: yup.boolean(),
        cardNumber: yup.string().when("isEditing", {
            is: true,
            then: yup.string().matches(creditCardRegex).required(),
            otherwise: yup.string().matches(creditCardRegex),
        }),
        expirationDate: yup.string().when("isEditing", {
            is: true,
            then: yup.string().matches(expiryDateRegex).required(),
            otherwise: yup.string().matches(expiryDateRegex),
        }),
        cvc2: isFenigePayment ? yup.string().required() : yup.string(),
    }) as Yup.Schema<PaymentFormSchema>;
