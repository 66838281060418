import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useElementPositionY from "features/common/hooks/useElementPositionY";
import useManageBodyOverflow from "features/common/hooks/useManageBodyOverflow";
import useViewportSize from "features/common/hooks/useViewportSize";
import useWindowScrollPosition from "features/common/hooks/useWindowScrollPosition";
import { Currency } from "features/common/models";
import { DeviceClass, DictionaryItem } from "features/common/types";
import EmployeeOffersFiltersView from "features/employeeOffers/components/FiltersView";
import { EmployeeOffersFetchParams, EmployeeOffersFilterMatchCounts } from "features/employeeOffers/types";
import { countEmployeeOffersFiltersApplied } from "features/employeeOffers/utils";
import JobOffersFiltersView from "features/jobOffers/components/JobOffersFiltersView";
import { JobOffersFetchParams, JobOffersFilterMatchCounts } from "features/jobOffers/types";
import { countJobOffersFiltersApplied } from "features/jobOffers/utils";
import { footerId } from "features/layout/components/Footer/component";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const footerToOffersListBottomOffset = 52;

interface Props {
    asFavoriteEmployeeOrEmployer?: boolean;
    asJobOffersList?: boolean;
    className?: string;
    fetchParams: JobOffersFetchParams | EmployeeOffersFetchParams;
    offersCount?: number;
    totalOffers?: number;
    branches?: DictionaryItem[];
    workTypes?: DictionaryItem[];
    currencies?: Currency[];
    filterMatchCounts?: JobOffersFilterMatchCounts | EmployeeOffersFilterMatchCounts;
    isLoading: boolean;
    isLoadingError: boolean;
    deviceClass: DeviceClass;
    offersLoading?: boolean;
    onFetchParamsChange: (params: JobOffersFetchParams | EmployeeOffersFetchParams) => void;
    resetPaginationState: () => void;
}

const Filters = ({
    asJobOffersList,
    className,
    fetchParams,
    offersCount,
    totalOffers,
    branches,
    workTypes,
    currencies,
    filterMatchCounts,
    isLoading,
    isLoadingError,
    deviceClass,
    offersLoading,
    onFetchParamsChange,
    resetPaginationState,
    asFavoriteEmployeeOrEmployer
}: Props) => {
    const [expanded, setExpanded] = useState(false);
    const [filtersAppliedCount, setFiltersAppliedCount] = useState("");
    const viewportSize = useViewportSize();
    const footerPosition = useElementPositionY(footerId, [expanded, offersLoading]);
    const scrollPosition = useWindowScrollPosition();
    const stickExpandBannerToParent =
        scrollPosition >= footerPosition - viewportSize.height - footerToOffersListBottomOffset;

    const setBodyScrollDisabled = useManageBodyOverflow(!expanded);
    const handleFiltersExpandClick = () => {
        setExpanded(true);
    };

    const handleFiltersCloseClick = () => {
        setExpanded(false);
    };

    useEffect(() => {
        setBodyScrollDisabled(expanded);

        return () => setBodyScrollDisabled(false);
    });

    useEffect(() => {
        const filtersCount = asJobOffersList
            ? countJobOffersFiltersApplied(fetchParams as JobOffersFetchParams)
            : countEmployeeOffersFiltersApplied(fetchParams as EmployeeOffersFetchParams);

        if (filtersCount > 0) {
            setFiltersAppliedCount(`(${filtersCount})`);
        } else {
            setFiltersAppliedCount("");
        }
    }, [asJobOffersList, fetchParams]);

    const filtersView = useMemo(() => {
        return asJobOffersList ? (
            <JobOffersFiltersView
                asFavoriteEmployeeOrEmployer={asFavoriteEmployeeOrEmployer}
                offersCount={offersCount}
                totalOffers={totalOffers}
                branches={branches?.map((branch) => ({ ...branch, matchCount: 0 }))}
                workTypes={workTypes?.map((workType) => ({ ...workType, matchCount: 0 }))}
                currencies={currencies}
                filterMatchCounts={filterMatchCounts as JobOffersFilterMatchCounts}
                onFiltersStateChange={onFetchParamsChange}
                fetchParams={fetchParams as JobOffersFetchParams}
                isLoading={isLoading}
                isLoadingError={isLoadingError}
                offersLoading={offersLoading}
                collapse={() => handleFiltersCloseClick()}
                resetPaginationState={resetPaginationState}
            />
        ) : (
            <EmployeeOffersFiltersView
                asFavoriteEmployeeOrEmployer={asFavoriteEmployeeOrEmployer}
                offersCount={offersCount}
                totalOffers={totalOffers}
                branches={branches?.map((branch) => ({ ...branch, matchCount: 0 }))}
                workTypes={workTypes?.map((workType) => ({ ...workType, matchCount: 0 }))}
                currencies={currencies}
                filterMatchCounts={filterMatchCounts as EmployeeOffersFilterMatchCounts}
                onFiltersStateChange={onFetchParamsChange}
                fetchParams={fetchParams as EmployeeOffersFetchParams}
                isLoading={isLoading}
                isLoadingError={isLoadingError}
                offersLoading={offersLoading}
                collapse={() => handleFiltersCloseClick()}
                resetPaginationState={resetPaginationState}
            />
        );
    }, [asFavoriteEmployeeOrEmployer, asJobOffersList, branches, currencies, fetchParams, filterMatchCounts, isLoading, isLoadingError, offersCount, offersLoading, onFetchParamsChange, resetPaginationState, totalOffers, workTypes]);

    return (
        <div className={classNames(styles["filters"], className)}>
            {deviceClass !== "desktop" ? (
                <>
                    {!expanded ? (
                        <div className={stickExpandBannerToParent ? "" : styles["filters__mobile-expand-banner-fixed"]}>
                            <div
                                className={classNames(styles["filters__mobile-expand-banner"], {
                                    [styles["filters__mobile-expand-banner--at-bottom"]]: stickExpandBannerToParent,
                                })}
                            >
                                <Button
                                    className={styles["filters__mobile-expand-button"]}
                                    variant="filter-mobile"
                                    onClick={handleFiltersExpandClick}
                                >
                                    <FilterIcon />
                                    <span>
                                        <FormattedMessage
                                            id="filters__filter"
                                            values={{ filtersApplied: filtersAppliedCount }}
                                        />
                                    </span>
                                </Button>
                            </div>
                            {!stickExpandBannerToParent && (
                                <div className={styles["filters__expand-banner-placeholder"]} />
                            )}
                        </div>
                    ) : (
                        <div className={styles["filters__mobile-filters-container"]}>
                            <div className={styles["filters__mobile-filters-header"]}>
                                <span>
                                    <FormattedMessage id="filters__filters" />
                                </span>
                                <TimesIcon
                                    className={styles["filters__mobile-filters-close-button"]}
                                    onClick={handleFiltersCloseClick}
                                />
                            </div>
                            <div
                                className={classNames(styles["filters__mobile-filters-view-container"], {
                                    [styles["filters__mobile-filters-view-container--no-footer"]]:
                                        isLoading || isLoadingError,
                                })}
                            >
                                {offersLoading && (
                                    <SpinnerWithMessage
                                        message={<FormattedMessage id="filters-view__loading-offers" />}
                                    />
                                )}
                                {filtersView}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles["filters__desktop-filters-container"]}>{filtersView}</div>
            )}
        </div>
    );
};

export default Filters;
