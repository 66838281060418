import React from "react";
import ConversationComponent from "features/messages/components/ConversationDetails";
import styles from "./styles.module.scss";

const Messages = () => {
    return (
        <div className={styles["messages"]}>
            <ConversationComponent />
        </div>
    );
};

export default Messages;
