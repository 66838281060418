import { ReactComponent as CalendarCanceledIcon } from "assets/icons/calendarCanceled.svg";
import { ReactComponent as PenWithNoteIcon } from "assets/icons/penWithNote.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import classNames from "classnames";
import { OfferType } from "features/common/types";
import { OfferStatus } from "features/offers/types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    status?: OfferStatus;
    offerType?: OfferType;
    className?: string;
}

const BadgeStatus = ({ "data-testid": testId, status, offerType, className }: Props) => {
    return (
        <div
            data-testid={testId}
            className={classNames(styles["badge-status__status-container"], className, {
                [styles["badge-status__status-container--active"]]: status === OfferStatus.Active,
                [styles["badge-status__status-container--inactive"]]: status === OfferStatus.Inactive,
                [styles["badge-status__status-container--ended"]]: status === OfferStatus.Ended,
            })}
        >
            {status === OfferStatus.Active && (
                <>
                    <TickIcon />
                    <span data-testid={`${testId}__status-active`} className={styles["badge-status__status"]}>
                        <FormattedMessage id="common__status-active" values={{ offerType: offerType }} />
                    </span>
                </>
            )}
            {status === OfferStatus.Inactive && (
                <>
                    <span data-testid={`${testId}__status-inactive`} className={styles["badge-status__status"]}>
                        <FormattedMessage id="common__status-inactive" values={{ offerType: offerType }} />
                    </span>
                </>
            )}
            {status === OfferStatus.Ended && (
                <>
                    <CalendarCanceledIcon />
                    <span data-testid={`${testId}__status-ended`} className={styles["badge-status__status"]}>
                        <FormattedMessage id="common__status-ended" values={{ offerType: offerType }} />
                    </span>
                </>
            )}
        </div>
    );
};

export default BadgeStatus;
