import { IntlShape } from "react-intl";

export const getMyProfileMyDataIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-data-description",
    });

export const getMyProfileMyCardsIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-description",
    });

export const getMyProfilePhoneNumberLengthValidationMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-data-phone-number-too-long-validation-message",
    });

export const getMyProfilePhoneNumberRegexMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-data-phone-number-regex-validation-message",
    });

export const getMyProfileMinimalLengthMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-data-minimal-length-validation-message",
    });

export const getTooltipMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__tooltip-message",
    });
export const getTooltipErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__tooltip-error-card",
    });

export const getMyProfileMyCardsAddCardModelHeader = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-add-card-modal-header",
    });

export const getMyProfileMyCardsCreateNewCard = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-add-new-card-button",
    });

export const getMyProfileMyCardsEmployeeCard = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-employee-card",
    });

export const getMyProfileMyCardsEmployerCard = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-employer-card",
    });

export const getMyProfileMyCardsCompanyCard = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-company-card",
    });

export const getMyProfileMyCardsCompanyName = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-cards__add-card-modal-company-name",
    });

export const getMyProfileMyCardsPickLocationMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-pick-location-error",
    });

export const getMyProfileMyCardsCreatingCardMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "my-profile__my-cards-creating-card-message",
    });

export const getEmployerCardMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "card-details__employer-card",
    });

export const getEmployeeCardMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "card-details__employee-card",
    });
