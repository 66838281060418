import classNames from "classnames";
import config from "config";
import { format } from "date-fns";
import UserImageWithPlaceholder from "features/common/components/UserImageWithPlaceholder";
import { useLocaleInDateToUse } from "features/messages/hooks";
import { mapApplicationStatusToText } from "features/messages/mappers";
import { ConversationUser } from "features/messages/models";
import { ApplicationStatus } from "features/messages/types";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Props {
    id: string;
    user: ConversationUser;
    offerTitle: string;
    lastMessageText?: string;
    lastMessageStatus?: ApplicationStatus;
    lastMessageTimestamp: Date;
    lastMessageApplicationId?: number;
    genusOffer: string;
    acknowledged: boolean;
}

const SingleMessage = ({
    id,
    user,
    offerTitle,
    lastMessageText,
    lastMessageStatus,
    lastMessageTimestamp,
    lastMessageApplicationId,
    genusOffer,
    acknowledged,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();

    const messageText = useMemo(
        () =>
            lastMessageStatus && lastMessageApplicationId !== undefined
                ? mapApplicationStatusToText(lastMessageStatus, lastMessageApplicationId, intl)
                : lastMessageText,
        [lastMessageStatus, lastMessageApplicationId, intl, lastMessageText]
    );

    const localDate = useLocaleInDateToUse(intl.locale)

    const handleMessageClick = useCallback(() => {
        history.push(`${appRoutes.messages}/${id}`);
    }, [history, id]);

    return (
        <div className={styles["single-message"]} onClick={handleMessageClick}>
            <div className={styles["single-message__user-container"]}>
                <UserImageWithPlaceholder
                    imageUrl={`${config.IMAGE_URL}/${user?.imageUrl}`}
                    className={styles["single-message__user-image"]}
                />
                <span className={classNames(styles["single-message__user-name"], {
                    [styles["single-message__unacknowledged"]]: !acknowledged,
                })}>{user.name}</span>
            </div>
            <div className={styles["single-message__offer-container"]}>
                <div className={styles["single-message__offer-title"]}>{offerTitle}</div>
                <div className={styles["single-message__offer-type"]}>
                    {genusOffer === "JOB" ? (
                        <FormattedMessage id="common__job-offer" />
                    ) : (
                        <FormattedMessage id="common__employee-offer" />
                    )}
                </div>
            </div>
            <div className={classNames(styles["single-message__text-container"], {
                [styles["single-message__unacknowledged"]]: !acknowledged,
            })}>
                {messageText === "recrutation_invitation_message" ? <FormattedMessage id="recrutation_invitation_message" /> : messageText}
                {messageText === "" ? <FormattedMessage id="conversation_file_sent" /> : ""}
            </div>
            <div className={classNames(styles["single-message__timestamp-container"], {
                [styles["single-message__unacknowledged"]]: !acknowledged,
            })}>
                {format(lastMessageTimestamp, "d MMM yyyy", { locale: localDate })}
            </div>
        </div>
    );
};

export default SingleMessage;
