import { FenigeApi } from "features/jobOffers/api/FenigeApi";
import { PreAuthResponse } from "features/jobOffers/api/types";

export interface IndicatorRequestData {
    authenticationId: string;
    durationInMillis?: number;
}

export const indicatorRequest = async (data: PreAuthResponse): Promise<IndicatorRequestData> => {
    if (data.indicatorRequestData) {
        const startTime = performance.now();
        await FenigeApi.IndicatorRequest({
            url: data.indicatorRequestData.url,
            threeDSMethodData: data.indicatorRequestData.data,
        });
        const endTime = performance.now();

        return Promise.resolve({ authenticationId: data.authenticationId, durationInMillis: endTime - startTime });
    }

    return Promise.resolve({ authenticationId: data.authenticationId });
};
