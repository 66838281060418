import { resetJobOfferDetails } from "features/jobOffers/actions";
import {
    selectJobOfferDetails,
    selectJobOfferDetailsLoading,
    selectJobOfferDetailsLoadingError,
    selectInterested,
    selectInterestedLoading,
    selectInterestedError,
    selectApplications,
    selectApplicationsLoading,
    selectApplicationsError,
    selectAcceptApplicationSuccess,
    selectRejectApplicationSuccess,
    selectAcceptArrivalSuccess,
    selectGiveBonusSuccess,
} from "features/jobOffers/selectors";
import { getJobOfferDetailsAsync, getInterestedAsync, getApplicationsAsync } from "features/jobOffers/thunks";
import { selectIsUserLoggedInWithProfile } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import MyJobJobOfferDetails from "./component";

const mapStateToProps = (state: RootState) => ({
    jobOfferDetails: selectJobOfferDetails(state),
    jobOfferDetailsLoading: selectJobOfferDetailsLoading(state),
    jobOfferDetailsLoadingError: selectJobOfferDetailsLoadingError(state),
    interested: selectInterested(state),
    interestedLoading: selectInterestedLoading(state),
    interestedError: selectInterestedError(state),
    applications: selectApplications(state),
    applicationsLoading: selectApplicationsLoading(state),
    applicationsError: selectApplicationsError(state),
    acceptApplicationSuccess: selectAcceptApplicationSuccess(state),
    rejectApplicationSuccess: selectRejectApplicationSuccess(state),
    acceptArrivalSuccess: selectAcceptArrivalSuccess(state),
    giveBonusSuccess: selectGiveBonusSuccess(state),
    isUserLoggedInWithProfile: selectIsUserLoggedInWithProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            resetJobOfferDetails: () => resetJobOfferDetails(),
            getJobOfferDetailsAsync,
            getInterestedAsync,
            getApplicationsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyJobJobOfferDetails);
