import {
    FENIGE_AUTH_STORAGE_KEY,
    FENIGE_AUTHORIZED,
    FENIGE_ERROR_AUTHORIZED,
    FENIGE_NOT_AUTHORIZED,
} from "../../../../routing/pages/PaymentRedirect/constants";
import { Dispatch, SetStateAction, useEffect } from "react";
import { isPaymentApproved } from "../../../api/helpers/isPaymentApproved";
import { PaymentResponse } from "../../../api/types";

export const useFenigeAuthListener = ({
    paymentCallback,
    onFailure,
    setIsSuccessPayment,
}: {
    paymentCallback: () => Promise<PaymentResponse>;
    onFailure: () => unknown;
    setIsSuccessPayment: Dispatch<SetStateAction<boolean>>;
}) => {
    useEffect(() => {
        localStorage.setItem(FENIGE_AUTH_STORAGE_KEY, FENIGE_NOT_AUTHORIZED);

        window.addEventListener('storage', e => {
            onstorage(e);
        })
    }, [paymentCallback]);

    const onstorage = async (event: StorageEvent) => {
        if (event.key === FENIGE_AUTH_STORAGE_KEY && event.newValue === FENIGE_AUTHORIZED) {
            if (typeof paymentCallback === 'function') {
                paymentCallback().then((paymentRes: PaymentResponse) => {
                    isPaymentApproved(paymentRes.paymentStatus)
                    ? setIsSuccessPayment(true)
                    : onFailure();
                });
            }
        }

        if (event.key === FENIGE_AUTH_STORAGE_KEY && event.newValue === FENIGE_ERROR_AUTHORIZED) {
            onFailure();
        }
    };
};
