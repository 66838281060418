import { ReactComponent as ArrowUp } from "assets/icons/arrowUp.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import classNames from "classnames";
import Checkbox from "features/common/components/Checkbox";
import { numberToPixels } from "features/common/utils";
import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";

export interface TableFilterOption {
    text: string;
    callback: (checked: boolean) => void;
}

export interface Props {
    label: string | React.ReactElement;
    isActionColumn: boolean;
    withSort?: boolean;
    withFilter?: boolean;
    isSortActive?: boolean;
    isSortAscending?: boolean;
    filterOptions?: TableFilterOption[];
    top?: number;
    withTopTransition?: boolean;
    ["data-testid"]?: string;
    onSort?: () => void;
}

const HeaderCell = ({
    label,
    isActionColumn,
    withSort,
    withFilter,
    isSortActive,
    isSortAscending,
    filterOptions,
    top,
    withTopTransition,
    "data-testid": testId = "header-cell",
    onSort,
}: Props) => {
    const [areFiltersExpanded, setFiltersExpanded] = useState(false);
    const [filtersState, setFiltersState] = useState<boolean[]>(filterOptions?.map(() => false) ?? []);

    const onFilterToggle = (checked: boolean, index: number, callback: () => void) => {
        const newFiltersState = [...filtersState];
        newFiltersState[index] = checked;

        setFiltersState(newFiltersState);
        callback();
    };

    return (
        <div
            data-testid={testId}
            className={classNames(styles["header-cell"], {
                [styles["header-cell--with-transition"]]: withTopTransition,
            })}
            style={{ top: top ? numberToPixels(top) : "" }}
        >
            {withSort && (
                <div
                    data-testid={`${testId}__sort-button`}
                    className={classNames(
                        styles["header-cell__icon-container"],
                        {
                            [styles["header-cell__icon-container--active"]]: isSortActive,
                        },
                        {
                            [styles["header-cell__icon-container--rotated"]]: isSortActive && !isSortAscending,
                        }
                    )}
                    onClick={onSort}
                >
                    <ArrowUp />
                </div>
            )}
            {withFilter && (
                <>
                    <div
                        data-testid={`${testId}__filter-toggle`}
                        className={styles["header-cell__icon-container"]}
                        onClick={() => setFiltersExpanded((prev) => !prev)}
                    >
                        <FilterIcon />
                    </div>
                    {areFiltersExpanded && filterOptions && (
                        <ClickAwayListener onClickAway={() => setFiltersExpanded(false)}>
                            <div className={styles["header-cell__filter-options-wrapper"]}>
                                <div
                                    data-testid={`${testId}__filters-container`}
                                    className={styles["header-cell__filter-options"]}
                                >
                                    {filterOptions.map((option, index) => (
                                        <Checkbox
                                            data-testid={`${testId}__filter`}
                                            key={option.text}
                                            className={styles["header-cell__filter-checkbox"]}
                                            label={option.text}
                                            checked={filtersState[index]}
                                            onToggle={(checked) =>
                                                onFilterToggle(checked, index, () => option.callback(checked))
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </ClickAwayListener>
                    )}
                </>
            )}
            <span
                className={classNames(styles["header-cell__label"], {
                    [styles["header-cell__label--action-label"]]: isActionColumn,
                })}
            >
                {label}
            </span>
        </div>
    );
};

export default HeaderCell;
