import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import RadioButtons from "features/common/components/RadioButtons";
import { ApplicationTypes } from "features/jobOffers/types";

import styles from "./styles.module.scss";
import NumberSpinnerInput from "features/common/components/NumberSpinnerInput";

interface Props {
    ["data-testid"]?: string;
    applicationType?: ApplicationTypes;
    applicantsNumber: number;
    positionsAvailable?: number;
    setApplicationType: (value: ApplicationTypes) => void;
    setApplicantsNumber: (value: number | ((value: number) => number)) => void;
}

const GroupApplicationForm = ({
    "data-testid": testId,
    applicationType,
    applicantsNumber,
    positionsAvailable = 0,
    setApplicationType,
    setApplicantsNumber,
}: Props) => {
    const intl = useIntl();

    const typeRadioOptions = useMemo(
        () => [
            {
                value: ApplicationTypes.Individual,
                label: intl.formatMessage({ id: "group-application-submission__individual" }),
            },
            {
                value: ApplicationTypes.Group,
                label: intl.formatMessage({ id: "group-application-submission__group" }),
            },
        ],
        [intl]
    );

    const handleApplicantsIncrease = useCallback(() => {
        setApplicantsNumber((number: number) => (number >= positionsAvailable ? number : number + 1));
    }, [positionsAvailable, setApplicantsNumber]);

    const handleApplicantsDecrease = useCallback(() => {
        setApplicantsNumber((number: number) => (number <= 1 ? number : number - 1));
    }, [setApplicantsNumber]);

    return (
        <div data-testid={`${testId}__form`} className={styles["group-application-submission__container"]}>
            <div className={styles["group-application-submission__radio-buttons-container"]}>
                <RadioButtons<ApplicationTypes>
                    options={typeRadioOptions}
                    title={intl.formatMessage({ id: "group-application-submission__type-label" })}
                    wrapperClassName={styles["group-application-submission__radio-input-wrapper"]}
                    inputsContainerClassName={styles["group-application-submission__radio-inputs-container"]}
                    titleClassName={styles["group-application-submission__radio-input-title"]}
                    labelClassName={styles["group-application-submission__radio-input-label"]}
                    value={applicationType}
                    onValueChange={setApplicationType}
                />
                {applicationType === ApplicationTypes.Group && (
                    <div className={styles["group-application-submission__number-container"]}>
                        <NumberSpinnerInput
                            label={intl.formatMessage({ id: "group-application-submission__number-label" })}
                            value={applicantsNumber}
                            onIncrease={handleApplicantsIncrease}
                            onDecrease={handleApplicantsDecrease}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GroupApplicationForm;
