import {
    selectUser,
    selectRegisterUserLoading,
    selectRegisterUserLoadingError,
    selectIsUserLoggedIn,
} from "features/user/selectors";
import { registerUserAsync } from "features/user/thunks";
import RegisterComponent from "./component";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    registerUserLoading: selectRegisterUserLoading(state),
    registerUserLoadingError: selectRegisterUserLoadingError(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            registerUserAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
