import { makeApiRequest } from "api/makeApiRequest";
import RatingAction, { rateEmployee, rateEmployer, rateEmployerSummary, rateEmployeeSummary } from "features/rating/actions";
import { RatingApi } from "features/rating/api";
import { RateForm } from "features/rating/models";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";

// TODO: IWGM-718 employer rating adjust
export const rateEmployerAsync = (
    rateForm: RateForm
): ThunkAction<void, RootState, Pick<RatingApi, "rateEmployerAsync">, RatingAction> => {
    return async (dispatch, getState, api) => {
        dispatch(rateEmployer.request());

        await makeApiRequest(api.rateEmployerAsync, [rateForm], rateEmployer.success, rateEmployer.failure, dispatch);
    };
};

// TODO: IWGM-717 employee rating adjust
export const rateEmployeeAsync = (
    rateForm: RateForm
): ThunkAction<void, RootState, Pick<RatingApi, "rateEmployeeAsync">, RatingAction> => {
    return async (dispatch, getState, api) => {
        dispatch(rateEmployee.request());

        await makeApiRequest(api.rateEmployeeAsync, [rateForm], rateEmployee.success, rateEmployee.failure, dispatch);
    };
};

// TODO: IWGM-718 employer rating adjust
export const getEmployerRateAsync = (
    formId: number
): ThunkAction<void, RootState, Pick<RatingApi, "getEmployerRateAsync">, RatingAction> => {
    return async (dispatch, getState, api) => {
        dispatch(rateEmployerSummary.request());

        await makeApiRequest(api.getEmployerRateAsync, [formId], rateEmployerSummary.success, rateEmployerSummary.failure, dispatch);
    };
};

export const getEmployeeRateAsync = (
    formId: number
): ThunkAction<void, RootState, Pick<RatingApi, "getEmployeeRateAsync">, RatingAction> => {
    return async (dispatch, getState, api) => {
        dispatch(rateEmployeeSummary.request());

        await makeApiRequest(api.getEmployeeRateAsync, [formId], rateEmployeeSummary.success, rateEmployeeSummary.failure, dispatch);
    };
};
