import {
    selectBranches,
    selectBranchesLoading,
    selectBranchesLoadingError,
    selectCurrencies,
    selectCurrenciesLoading,
    selectCurrenciesLoadingError,
} from "features/common/selectors";
import { getBranchesAsync, getCurrenciesAsync } from "features/common/thunks";
import OffersList from "features/offers/components/OffersList";
import { selectIsUserLoggedIn } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    branches: selectBranches(state),
    branchesLoading: selectBranchesLoading(state),
    branchesLoadingError: selectBranchesLoadingError(state),
    currencies: selectCurrencies(state),
    currenciesLoading: selectCurrenciesLoading(state),
    currenciesLoadingError: selectCurrenciesLoadingError(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getBranchesAsync,
            getCurrenciesAsync,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
