import { selectLocationSuggestions } from "features/common/selectors";
import { getLocationSuggestionsAsync } from "features/common/thunks";
import { resetMyProfileCardCreated } from "features/myProfile/actions";
import {
    selectCreateNewCardError,
    selectCreateNewCardLoading,
    selectCreateNewCardSuccess, selectDeleteCardError,
    selectMyProfileCardLoadingError,
    selectMyProfileCards,
    selectMyProfileCardsLoading,
} from "features/myProfile/selectors";
import { createNewCardAsync, getMyProfileCardsAsync, deleteCardAsync } from "features/myProfile/thunks";
import { selectUser, selectUserLoading, selectUserLoadingError } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import MyCardsComponent from "./component";

interface Props {
    testId?: string;
}

const mapStateToProps = (state: RootState, { testId }: Props) => ({
    user: selectUser(state),
    myProfileCards: selectMyProfileCards(state),
    areMyProfileCardsLoading: selectMyProfileCardsLoading(state),
    myProfileCardsLoadingError: selectMyProfileCardLoadingError(state),
    isUserLoading: selectUserLoading(state),
    userLoadingError: selectUserLoadingError(state),
    locationSuggestion: selectLocationSuggestions(state),
    isCreateNewCardLoading: selectCreateNewCardLoading(state),
    isCreateNewCardSuccess: selectCreateNewCardSuccess(state),
    createNewCardError: selectCreateNewCardError(state),
    deleteCardError: selectDeleteCardError(state),
    "data-testid": testId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getLocationSuggestionsAsync,
            createNewCardAsync,
            getMyProfileCardsAsync,
            resetMyProfileCardCreated: () => resetMyProfileCardCreated(),
            deleteCardAsync
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyCardsComponent);
