import { ApiError } from "api/utils";
import { Dispatch } from "redux";
import { Action } from "store/rootTypes";
import { ActionType, PayloadActionCreator } from "typesafe-actions";

export const makeApiRequest = async <TArguments extends unknown[], TResponse>(
    method: (...args: TArguments) => Promise<TResponse>,
    apiArguments: TArguments,
    successAction: PayloadActionCreator<ActionType<Action>, TResponse | any>,
    errorAction: PayloadActionCreator<ActionType<Action>, ApiError>,
    dispatch: Dispatch,
    objectIdToDelete?: string
): Promise<void> => {
    try {
        const response = await method(...apiArguments);

        if (objectIdToDelete) {
            dispatch(successAction({ objectIdToDelete }))
        } else {
            dispatch(successAction(response));
        }

    } catch (apiError) {
        dispatch(errorAction(apiError as ApiError));
    }
};
