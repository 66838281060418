export const appRoutes = {
    createEmployeeOffer: "/employee-offers/create-offer",
    createJobOffer: "/job-offers/create-offer",
    createDedicatedJobOffer: "/job-offers/create-dedicated-offer",
    createProfile: "/create-profile",
    dashboard: "/",
    employeeOffers: "/employee-offers",
    employer: "/employer",
    employee: "/employee",
    employers: "/employers",
    employmentHistory: "/employment-history",
    example: "/example",
    myEmployeeOffers: "/my-employee-offers",
    myJobOffers: "/my-job-offers",
    myProfile: "/my-profile",
    notFound: "*",
    jobOffers: "/job-offers",
    notifications: "/notifications",
    paymentHistory: "/payment-history",
    privacyPolicy: "/privacy-policy",
    cookiePolicy: "/cookie-policy",
    regulations: "/regulations",
    signOut: "/sign-out",
    myMessages: "/my-messages",
    favorite: "/favorite",
    messages: "/messages",
    contactForm: "/contact-form",
    card: "/my-profile/card",
    createJobOfferCard: "/job-offers/card",
    createEmployeeOfferCard: "/employee-offer/card",
    profileCard: "/profile/card",
    adminPanel: "/administration-panel",
    moderatorPanel: "/moderator-panel",
    userProfile: "/profile",
    rate: "/rate",
    workHistory: "/work-history",
    login: "/login",
    register: "/register",
    forgottenPassword: "/forgotten-password",
    recoverPassword: "/recover-password",
    activationInfo: "/activation-info",
    myApplications: "/my-applications",
    newCard: "/my-profile/new-card",
    createJobOfferNewCard: "/my-profile/job-offers/new-card",
    createEmployeeOfferNewCard: "/my-profile/employee-offers/new-card",
    oauthLogin: "/login/oauth2",
    verify: "/verify",
    howItWorks: "/how-it-works",
    faq: "/faq",
    userJobOffers: "/user-job-offers",
    userEmployeeOffers: "/user-employee-offers",
    cardJobOffers: "/card-job-offers",
    cardEmployeeOffers: "/card-employee-offers",
    payment: "/checkout",
    paymentRedirect: "/checkout-redirect",
    paymentStatus: "/paymentStatus",
    getBonus: "/getbonus",
};
