import { ReactComponent as JobOfferBonusIcon } from "assets/getbonus/get-bonus.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as GetBonusTitle } from "assets/icons/get-bonus-title.svg";
import { ReactComponent as GoToDetailsIcon } from "assets/icons/goto.svg";
import HeartIcon from "assets/icons/heart-icon-off.svg";
import HeartFilled from "assets/icons/HeartFilled.svg";
import { ReactComponent as OffersLocationMarkerIcon } from "assets/icons/offersLocationMarker.svg";
import DefaultImgMobile from "assets/images/default-img-JobOffrs-list-mobile.png";
import DefaultImgTablet from "assets/images/default-img-JobOffrs-list-tablet.png";
import config from "config";
import SignInMessage from "features/common/components/SignInMessage";
import useDateFormat from "features/common/hooks/useDateFormat";
import { JobOfferDetails } from "features/jobOffers/models";
import { getCreateSalaryTypeMessage } from "features/jobOffers/translationMessages";
import AmenitiesPart from "features/offers/components/OfferDetails/AmenitiesPart";
import { FinancialConditionsSlimDTO, LocationSlimDTO } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import useTitleSubstring from "hooks/useTitleSubstring";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import FavoriteAPI from "../../../favorite/api";
import styles from "./styles.module.scss";

interface Props {
    jobOfferDetails?: JobOfferDetails;
    id: number;
    locationSlimDto: LocationSlimDTO;
    financialConditionsSlimDTO: FinancialConditionsSlimDTO;
    position: string;
    title: string;
    addedDate?: Date;
    expirationDate?: Date;
    bonus: string;
    businessName?: string;
    clientLogoUrl?: string;
    isUserLoggedIn?: boolean;
    mainImage?: string;
    mainBusinessCardPhoto?: string;
    filterByCompanyName: (businessName?: string) => void;
    images?: string[];
}
const JobOfferItem = ({
    jobOfferDetails,
    id,
    businessName,
    locationSlimDto,
    clientLogoUrl,
    financialConditionsSlimDTO: { bonusCurrencyCode, bonusValue, paymentRateType, rewardCurrencyCode, rewardMinimumValue },
    title,
    expirationDate,
    isUserLoggedIn,
    mainImage,
    mainBusinessCardPhoto,
    filterByCompanyName,
    images
}: Props) => {
    const [isFilled, setIsFilled] = useState(jobOfferDetails?.favorite);
    const intl = useIntl();
    const history = useHistory();
    const getFormattedDate = useDateFormat();
    const createSalaryTypeMessage = getCreateSalaryTypeMessage(intl);
    const isMatches = useMediaQuery('(min-width: 834px)');
    const { changedTitle } = useTitleSubstring(title, 40, 57, 40, 57, 39);
    const DefaultImage = isMatches ? DefaultImgTablet : DefaultImgMobile;

    const posterPath = mainImage ? `${config.IMAGE_URL}/${mainImage}` : DefaultImage;
    //(images?.length === 0 || images![0] === '') ? DefaultImage : `${config.IMAGE_URL}/${images![0]}`;

    const openOfferDetails = () => {
        history.push(`${appRoutes.jobOffers}/${id}`);
    };
    const filterByBusiness = () => {
        filterByCompanyName(businessName);
    };

    const workLocation = useMemo(
        () =>
            `${locationSlimDto.city ? `${locationSlimDto.city}, ` : ""}${locationSlimDto.country}`,
        [intl, locationSlimDto.city, locationSlimDto.country]
    );
    const salaryWithCurrency = useMemo(() => `${rewardMinimumValue} ${rewardCurrencyCode}`, [rewardMinimumValue, rewardCurrencyCode]);
    const bonusWithCurrency = useMemo(() => `${bonusValue} ${bonusCurrencyCode}`, [bonusValue, bonusCurrencyCode]);

    const onFavoriteHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (isUserLoggedIn) {
            if (jobOfferDetails?.id) {
                if (isFilled) {
                    await FavoriteAPI.removeJobOfferFromFavorite(jobOfferDetails.id);
                    setIsFilled(false);
                } else {
                    await FavoriteAPI.addJobOfferToFavorite(jobOfferDetails.id);
                    setIsFilled(true);
                }
            }
        }
        else {
            history.push(appRoutes.login);
        }
    };
    return (
        <div className={styles["offer-item"]}>
            <ImageContainer onClick={openOfferDetails}>
                <Image src={posterPath} alt="main poster" />
                {mainBusinessCardPhoto && <Image companyLogo src={`${config.IMAGE_URL}/${mainBusinessCardPhoto}`} />}
            </ImageContainer>
            <div className={styles["offer-item__offer-container"]}>
                <div className={styles["offer-item__top-part"]}>
                    <div className={styles["offer-item__company-header"]}>
                        <div className={styles["offer-item__business-name"]} onClick={isUserLoggedIn ? openOfferDetails : (() => history.push(appRoutes.login))}>{isUserLoggedIn ? businessName : <SignInMessage />}</div>
                        <div className={styles["offer-item__client-logo"]} onClick={filterByBusiness}>
                            {clientLogoUrl && isUserLoggedIn && (
                                <img src={`${config.IMAGE_URL}/${clientLogoUrl}`} alt={businessName} />
                            )}
                        </div>
                        <div className={styles["offer-item__details-entry-end"]}>
                            <CoinsIcon className={styles["offer-item__salary-icon"]} />
                            <SalaryDescr from>{intl.formatMessage({ id: 'common__from' })}</SalaryDescr>
                            {<SalaryDescr>{salaryWithCurrency}/</SalaryDescr>}
                            {<SalaryDescr>{createSalaryTypeMessage(paymentRateType)}</SalaryDescr>}
                        </div>
                    </div>
                    <div className={styles["offer-item__job-position"]} onClick={openOfferDetails}>
                        {changedTitle}
                        <StyledHeartBtn onClick={onFavoriteHandler} type="button">
                            <img src={isFilled ? HeartFilled : HeartIcon} alt="favorite" />
                        </StyledHeartBtn>
                    </div>
                    <div className={styles["offer-item__details-container"]}>
                        <div className={styles["offer-item__offer-details"]}>
                            {workLocation && (
                                <div className={styles["offer-item__details-entry"]}>
                                    <OffersLocationMarkerIcon />
                                    <LocationDescr>{workLocation}</LocationDescr>
                                </div>
                            )}
                        </div>
                        <UnderFooterContainer>
                            {!!bonusValue ?
                                (<div className={styles["offer-item__bonus-container"]}>
                                    <JobOfferBonusIcon width="1.125rem" height="1.125rem" />
                                    <GetBonusTitle />
                                    <span>{bonusWithCurrency}</span>
                                </div>) :
                                <EmptyBonusContainer />}
                            {jobOfferDetails?.amenities && jobOfferDetails.amenities.length > 0 && (
                                <AmenitiesPart offersList amenities={jobOfferDetails.amenities} />
                            )}
                        </UnderFooterContainer>
                    </div>
                </div>
                <div className={styles["offer-item__offer-footer"]}>
                    <div>
                        <FormattedMessage
                            id="offers__offer-valid-until"
                            values={{ expirationDate: getFormattedDate(expirationDate) }}
                        />
                    </div>
                    <div className={styles["offer-item__offer-footer-details"]} onClick={openOfferDetails}>
                        <FormattedMessage id="offers-item__go-to-offer" />
                        <GoToDetailsIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default JobOfferItem;
const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 4.625rem;
    min-width: 4.625rem;
    height: auto;
    margin-right: 1rem;
    cursor: pointer;
    display: flex;
    
    @media screen and (min-width: 834px) {
        width: 13.25rem;
        min-width: unset;
        max-width: unset; 
        height: unset; 
        margin-right: 1.625rem;
    }
`;
interface IImageProps {
    companyLogo?: boolean;
}
const Image = styled.img<IImageProps>`
    display: block;
    width: 100%;
    background-position: center;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    @media screen and (min-width: 834px) {
        width: 13.25rem;
        height: auto;
    }
    ${({ companyLogo }) => companyLogo && css`
        height: 3.75rem;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        border-bottom-left-radius: 0;
        @media screen and (min-width: 834px) {
            width: 4.188rem;
            height: 3.375rem;
            border-radius: 5px;
        }
    `}
`;
const StyledHeartBtn = styled.button`
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    @media screen and (min-width: 834px) {
        transform: translateY(-1.875rem);
    }
`;

const LocationDescr = styled.span`
    font-size: 0.75rem;
    letter-spacing: 0.1px;
    color: #002677;
`;

const SalaryDescr = styled.span<{ from?: boolean; }>`
    font-family: Red Hat Text,serif;
    font-weight: bold;
    font-size: 0.75rem;
    color: #002677;
    @media screen and (min-width: 834px) {
        font-size: 0.938rem;
    }
    ${({ from }) => from && `
        font-size: 0.625rem;
        line-height: 1.09;
        margin-right: 3px;
        @media screen and (min-width: 834px) {
        font-size: 0.813rem;
        }
    `} 
`;

const UnderFooterContainer = styled.div`
    @media screen and (min-width: 834px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-end;
    }
`;

const EmptyBonusContainer = styled.div`
    box-sizing: border-box;
    background: transparent;
    height: 1.688rem;
`;