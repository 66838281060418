
import React from "react";
import CreatableSelect, { SelectOption } from "features/common/components/CreatableSelect";
import RequirementBlock from "features/jobOffers/components/CreateJobOffer/RequirementsStep/RequirementBlock";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import { useIntl } from "react-intl";

export interface Props {
    header: string;
    label: string;
    valueInputProps: FieldInputProps<any>;
    metaProps: FieldMetaProps<any>;
    helperProps: FieldHelperProps<any>;
    ["data-testid"]?: string;
}

const CreatableRequirement = ({ header, valueInputProps, metaProps, helperProps }: Props) => {
    const intl = useIntl();

    const joinedValues = valueInputProps.value.length ? valueInputProps.value.map((value: any) => value.value).join(", ") : null;

    const handleChange = (values: SelectOption[]) => {
        helperProps.setValue(values);
    }

    const onCancel = () => {
        helperProps.setValue(metaProps.initialValue);
    };

    const getValues = () => {
        if (!joinedValues) return null;

        return <div>{joinedValues}</div>
    }


    return (
        <div>
            <RequirementBlock
                label={header}
                isCleanable={false}
                customPrimaryContent={intl.formatMessage({ id: "common__save" })}
                editorContent={<CreatableSelect onChange={handleChange} value={metaProps.value} />}
                value={getValues()}
                onCancel={onCancel}
            />
        </div>
    );
};

export default CreatableRequirement;