import { resetEmployeeOfferDetails } from "features/employeeOffers/actions";
import {
    selectEmployeeOfferDetails,
    selectEmployeeOfferDetailsLoading,
    selectEmployeeOfferDetailsLoadingError,
} from "features/employeeOffers/selectors";
import { getEmployeeOfferDetailsAsync } from "features/employeeOffers/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import MyEmployeeOfferDetails from "./component";

const mapStateToProps = (state: RootState) => ({
    employeeOfferDetails: selectEmployeeOfferDetails(state),
    employeeOfferDetailsLoading: selectEmployeeOfferDetailsLoading(state),
    employeeOfferDetailsLoadingError: selectEmployeeOfferDetailsLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            resetEmployeeOfferDetails: () => resetEmployeeOfferDetails(),
            getEmployeeOfferDetailsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyEmployeeOfferDetails);
