import AuthStorageService from "services/authStorageService";

class RefreshTimeoutService {
    private refreshTokenTimeout: NodeJS.Timeout | undefined;
    private refreshCallbackFn: () => void = () => {};

    public setRefreshCallback = (refreshCallback: () => void) => (this.refreshCallbackFn = refreshCallback);

    public setTimeoutRefresh = (): void => {
        if (this.refreshTokenTimeout) {
            this.removeRefreshTimeout();
        }

        const tokens = AuthStorageService.getTokens();

        if (!tokens || !tokens.accessTokenExpiredDate) {
            return;
        }

        const now = new Date();
        let timeoutDate = tokens.accessTokenExpiredDate - now.getTime();

        timeoutDate = Math.max(0, timeoutDate);

        this.refreshTokenTimeout = setTimeout(this.refreshCallbackFn, timeoutDate);
    };

    public removeRefreshTimeout = (): void => {
        if (!this.refreshTokenTimeout) {
            return;
        }

        clearTimeout(this.refreshTokenTimeout);
        this.refreshTokenTimeout = undefined;
    };
}

export default new RefreshTimeoutService();
