import { useEffect } from "react";
import {
    FENIGE_AUTH_STORAGE_KEY,
    FENIGE_AUTHORIZED,
    FENIGE_ERROR_AUTHORIZED,
    FENIGE_NOT_AUTHORIZED,
} from "./constants";

export const useFenigeAuthProducer = ({ authorized }: { authorized: boolean }) => {
    useEffect(() => {
        if (localStorage.getItem(FENIGE_AUTH_STORAGE_KEY) === FENIGE_NOT_AUTHORIZED)
            if (authorized) localStorage.setItem(FENIGE_AUTH_STORAGE_KEY, FENIGE_AUTHORIZED);
            else localStorage.setItem(FENIGE_AUTH_STORAGE_KEY, FENIGE_ERROR_AUTHORIZED);
    }, []);
};
