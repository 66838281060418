import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom"

import Button from "features/common/components/Button";
import { OfferType } from "features/common/types";
import { JobOfferDetails } from "features/jobOffers/models";
import EndOfferModal from "features/offers/components/MyOffers/EndOfferModal";
import { OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";

import SeeSimilarJobOffersButton from "../../SeeSimilarJobOffersButton";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    jobOfferDetails?: JobOfferDetails;
    offerId: number;
    isTopBox?: boolean;
    resetOfferDetails?: () => void;
}

const DetailsActionsBox = ({ "data-testid": testId, jobOfferDetails, isTopBox, resetOfferDetails }: Props) => {
    const history = useHistory();
    const [isEndOfferModalOpen, setIsEndOfferModalOpen] = useState(false);
    const [endOfferId, setEndOfferId] = useState<number>();

    return (
        <div className={styles["details-actions-box__actions-container"]}>
            <div className={classNames(styles["details-actions-box__details-container"])}>
                <div className={styles["details-actions-box__buttons-container"]}>
                    {isTopBox ? (
                        <>
                            {jobOfferDetails?.status !== OfferStatus.Ended && (
                                <Button
                                    variant="secondary"
                                    className={styles["details-actions-box__button"]}
                                    data-testid={`${testId}__edit-button`}
                                    onClick={() => history.push(`${appRoutes.myJobOffers}/edit/${jobOfferDetails?.id}`)}
                                >
                                    <FormattedMessage id="common__edit" />
                                </Button>
                            )}
                            <Button
                                className={styles["details-actions-box__button"]}
                                data-testid={`${testId}__copy-button`}
                                onClick={() => history.push(`${appRoutes.myJobOffers}/copy/${jobOfferDetails?.id}`)}
                            >
                                <FormattedMessage id="common__copy" />
                            </Button>
                        </>
                    ) : (
                        <>
                            <SeeSimilarJobOffersButton jobOfferDetails={jobOfferDetails} />
                            {jobOfferDetails?.status !== OfferStatus.Ended && (
                                <Button
                                    className={styles["details-actions-box__button"]}
                                    data-testid={`${testId}__finish-button`}
                                    onClick={() => {
                                        setEndOfferId(jobOfferDetails?.id);
                                        setIsEndOfferModalOpen(true);
                                    }}
                                >
                                    <FormattedMessage id="common__finish" />
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </div>
            {isEndOfferModalOpen && (
                <EndOfferModal
                    offerId={endOfferId}
                    offerType={OfferType.Job}
                    onCancel={() => setIsEndOfferModalOpen(false)}
                    onConfirm={() => {
                        resetOfferDetails?.();
                        setIsEndOfferModalOpen(false);
                        window.scrollTo(0, 0);
                    }}
                />
            )}
        </div>
    );
}

export default DetailsActionsBox;
