import ConversationDetails from "features/messages/components/ConversationDetails";
import { User } from "features/user/models";
import React, { useState } from "react";
import Peer from "simple-peer";
import { GetCallState } from "../reducers";
import { GetCallActionToDispatchProps } from "../types";

interface WithGetCallReceivedComponentProps extends GetCallActionToDispatchProps, GetCallState {
    user: User | undefined
}

export const withGetCall = (Component: React.FC<any>) => ({ stream, connection, call, user, socket, leaveCallAction, answerCallAction, setCallAction, ...props }: WithGetCallReceivedComponentProps) => {
    const [cancelCallMessage, setCancelCallMessage] = useState<any>()

    const answerCall = () => {
        answerCallAction()
        if (stream) {
            const peer = new Peer({ config: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] }, initiator: false, trickle: false, stream });
            peer.on('signal', (data) => {
                socket?.emit('answerCall', { signal: data, to: call.from, from: user?.id });
            });
            peer.on('stream', (currentStream) => {
                const userVideo = document.getElementById('userVideo') as HTMLVideoElement;
                if (userVideo) {
                    userVideo.srcObject = currentStream;
                }
            });

            peer.signal(call.signal);

            if (connection) {
                connection = peer;
            }
        }
    };

    const toggleStrangerVideo = () => {
        if (socket) {
            socket.emit("strangerVideo", user?.id)
        }
    }

    const toggleStrangerMicro = () => {
        if (socket) {
            socket.emit("strangerMicro", user?.id)
        }
    }

    const rejectCall = () => {
        setCallAction && setCallAction({ call: { isReceivingCall: false, from: '', name: '', signal: '' } })
    };

    const cancelCall = () => {
        socket?.emit("cancelCallUser")
    };

    const callUser = (id: string) => {
        if (stream && user) {
            const peer = new Peer({ initiator: true, trickle: false, stream });

            peer.on('signal', (data) => {
                socket?.emit('callUser', { userToCall: id, signalData: data, from: user.id, name: `${user.firstName} ${user.lastName}` });
            });

            peer.on('stream', (currentStream) => {
                const userVideo = document.getElementById('userVideo') as HTMLVideoElement;
                if (userVideo) {
                    userVideo.srcObject = currentStream;
                }
            });

            socket?.on('callAccepted', (signal) => {
                answerCallAction()
                peer.signal(signal);
            });

            if (connection) {
                connection = peer;
            }
        }
    };

    const leaveCall = () => {
        socket?.emit("rejectCallUser", user)
        setTimeout(() => {
            if (connection) {
                connection.destroy();
            }
            window.location.reload();
        }, 2500);
    };

    return (
        <Component
            {...props}
            setCallAction={setCallAction}
            socket={socket}
            stream={stream}
            call={call}
            connection={connection}
            leaveCall={leaveCall}
            callUser={callUser}
            answerCall={answerCall}
            rejectCall={rejectCall}
            cancelCall={cancelCall}
            cancelCallMessage={cancelCallMessage}
            toggleStrangerVideo={toggleStrangerVideo}
            toggleStrangerMicro={toggleStrangerMicro}
            user={user}
        />
    );
}