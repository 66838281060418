import { ApiError, ApiException } from "api/utils";
import axios, { AxiosError, AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from "axios";
import config from "config";
import AuthStorageService from "services/authStorageService";
import RefreshTimeoutService from "services/refreshTimeoutService";
import SignOutTimeoutService from "services/signOutTimeoutService";
import { appRoutes } from "features/routing/routes";
import { setTimeToSignOutInLocalStorage } from "utils";

type AxiosRequestConfigWithRetry = AxiosRequestConfig & {
    retry: boolean;
};

export const appendAuthHeaderInterceptor = (requestConfig: AxiosRequestConfig) => {
    const tokens = AuthStorageService.getTokens();

    if (tokens && tokens.accessToken) {
        return Object.assign({}, requestConfig, {
            headers: {
                ...requestConfig.headers,
                authorization: `Bearer ${tokens.accessToken}`,
            },
        });
    }

    return requestConfig;
};

export const refreshTokenInterceptor = (
    instance: AxiosInstance
): Parameters<AxiosInterceptorManager<AxiosResponse>["use"]> => [
    (response: AxiosResponse) => {
        return response;
    },
    async (error: AxiosError) => {
        if (!error) {
            return Promise.reject();
        }

        if (process.env.NODE_ENV === "development") {
            console.log(error.toJSON());
        }

        const originalRequest = error.config as AxiosRequestConfigWithRetry;
        const tokens = AuthStorageService.getTokens();

        if (originalRequest.url?.includes(config.apiRoutes.REFRESH_TOKEN)) {
            AuthStorageService.clearAuthStorage();
            SignOutTimeoutService.removeSignOutTimeout();
            RefreshTimeoutService.removeRefreshTimeout();

            // logout just in case
            const logoutUrl = appRoutes.signOut;
            window.location.href = logoutUrl;
        }

        if (error.response?.status === 401 && !originalRequest.retry) {
            originalRequest.retry = true;

            if (!tokens?.refreshToken) {
                throw error;
            }
            const result = await instance.post(config.apiRoutes.REFRESH_TOKEN, { refreshToken: tokens.refreshToken });
            if (result.status === 200) {
                AuthStorageService.setTokens(result.data);
                setTimeToSignOutInLocalStorage();
                SignOutTimeoutService.setTimeoutSignOut();

                return instance(originalRequest);
            }
        }

        SignOutTimeoutService.removeSignOutTimeout();
        return Promise.reject(error);
    },
];

export const handleCancelErrorInterceptor = (error: AxiosError<ApiException>) => {
    if (axios.isCancel(error)) {
        const cancelError: ApiError = undefined;

        return Promise.reject(cancelError);
    }

    return Promise.reject(error);
};
