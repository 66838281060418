import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import SignInMessage from "features/common/components/SignInMessage";
import { JobOfferDetails } from "features/jobOffers/models";
import { offersQueryParamNames } from "features/offers/constants";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import styles from "./styles.module.scss";
import { getLoginOfferMessage } from "features/offers/translationMessages";

export interface Props {
    offerDetails: JobOfferDetails;
    isUserLoggedIn: boolean;
    ["data-testid"]?: string;
}

// TODO: IWGM-665 employer
const EmployerPart = ({ offerDetails, isUserLoggedIn, "data-testid": testId = "employer-part" }: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const isTabletMatches = useMediaQuery('(min-width: 834px)');

    const LoginOfferMessage = getLoginOfferMessage(intl);

    const onSeeOtherOffersClick = useCallback(
        () =>
            history.push(
                `${appRoutes.jobOffers}?${offersQueryParamNames.companyName}=${encodeURIComponent(
                    offerDetails.businessCardId ?? ""
                )}`
            ),
        [history, offerDetails.businessCardId]
    );

    const goToProfile = useCallback(() => {
        history.push(`${appRoutes.profileCard}/${offerDetails.businessCardId}`);
    }, [history, offerDetails.businessCardId]);

    return (
        <div className={styles["employer-part"]}>
            {isUserLoggedIn ? (
                <>
                    <div
                        data-testid={`${testId}__employer-details`}
                        className={classNames(styles["employer-part__employer-details"], {
                            [styles["employer-part__employer-details--with-logo"]]:
                                !!offerDetails.businessCardData?.logo,
                        })}
                    >
                        {offerDetails.businessCardData?.logo ? (
                            <div className={styles["employer-part__logo-container"]}>
                                {/* TODO: IWGM-665 Add redirect on click once employer details page is implemented. */}
                                <img
                                    data-testid={`${testId}__logo`}
                                    className={styles["employer-part__logo"]}
                                    src={`${config.IMAGE_URL}/${offerDetails.businessCardData?.logo}`}
                                    alt=""
                                />
                            </div>
                        ) : !isTabletMatches && <StyledNoLogo />}
                        <span
                            data-testid={`${testId}__business-name`}
                            title={offerDetails.businessCardData?.businessName}
                            className={styles['employer-part__name']}
                        >
                            {offerDetails.businessCardData?.businessName}
                        </span>
                        {/* </div>
                    <div className={styles["employer-part__buttons"]}> */}
                        {/* TODO: IWGM-665 Add redirect on click once employer details page is implemented. */}
                        <StyledButton
                            first
                            data-testid={`${testId}__see-profile-button`}
                            variant="no-background"
                            onClick={goToProfile}
                        >
                            <FormattedMessage id="offer-details__see-profile" />
                        </StyledButton>
                        <StyledButton
                            data-testid={`${testId}__see-other-offers-button`}
                            variant="no-background"
                            onClick={onSeeOtherOffersClick}
                        >
                            <FormattedMessage id="offer-details__see-other-offers" />
                        </StyledButton>
                    </div>
                </>
            ) : (
                <SignInMessage
                    data-testid={`${testId}__sign-in-message`}
                    className={styles["employer-part__sign-in-message"]}
                    customMessage={LoginOfferMessage}
                />
            )}
        </div>
    );
};

const StyledButton = styled(Button) <{ first?: boolean }>`
    white-space: nowrap;
    padding: 0;
    justify-content: start;

    @media screen and (min-width: 834px){
        ${({ first }) => first && `
        margin-right: 1rem;
        margin-left: auto;
    `};
    }
`;

const StyledNoLogo = styled.div`
    width: 4rem;
`;

export default EmployerPart;
