import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const NotFound = () => {
    useScrollToTopOnMount();
    return (
        <div data-testid="not-found-page" className={styles["not-found"]}>
            <h1>
                <FormattedMessage id="page-not-found" />
            </h1>
        </div>
    );
};

export default NotFound;
