import TextArea from "features/common/components/TextArea";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { getDescriptionPlaceholderMessage } from "features/jobOffers/translationMessages";
import { useField } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    ["data-testid"]?: string;
}

const QualificationStep = ({ "data-testid": testId }: Props) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();

    const descriptionMessage = getDescriptionPlaceholderMessage(intl);

    const [descriptionField, descriptionFieldMeta] = useField<string>("qualificationDescriptionStep.description");

    return (
        <>
            <TextArea
                data-testid={`${testId}__qualification-description`}
                className={styles["application-submission-qualification-step__textarea"]}
                placeholder={descriptionMessage}
                rows={deviceClass === "desktop" ? 25 : 20}
                maxSize={2500}
                {...{ ...descriptionField, ...descriptionFieldMeta }}
                withValidation
                isInvalid={!!descriptionFieldMeta.error && !!descriptionFieldMeta.touched}
                error={descriptionFieldMeta.error}
            />
        </>
    );
};

export default QualificationStep;
