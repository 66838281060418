import { WorkType } from "features/common/types";
import { FinancialConditions } from "features/offers/types";
import { IntlShape } from "react-intl";

export const getMessagePlaceholderMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-details__message-text",
    });

export const getAskAboutMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-details__message-ask",
    });

export const getMessageSentSuccessMessage = (intl: IntlShape, link: JSX.Element) =>
    intl.formatMessage(
        {
            id: "offer-details__message-sent-success",
        },
        {
            link,
        }
    );

export const getPaymentTypeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offers__salary-type",
    });

export const getMyEmployeeOffersMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__my-employee-offers",
    });

export const getYourCardMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__your-card",
    });

export const getDetailsMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__details",
    });

export const getFinancialConditionsMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__financial-conditions",
    });

export const getStepperErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__stepper-error",
    });

export const getHourlySalaryFilterMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__hourly-salary-filter",
    });

export const getMonthlySalaryFilterMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "filters-view__monthly-salary-filter",
    });

export const getTermIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offers-searchbox-form__search-term",
    });

export const getKmIntlMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offers-searchbox-form__km",
    });

export const getRequirementsMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__requirements",
    });

export const getAmenitiesMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__amenities",
    });

export const getCreateJobOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offers__create-job-offer",
    });

export const getAdditionalInfoMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__additional-info",
    });

export const getDetailsStepTypeOfEmploymentLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__type-of-employment-label",
    });

export const getDetailsStepJobStartDateLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__job-start-date-label",
    });

export const getDetailsStepJobEndDateLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__job-end-date-label",
    });

export const getDetailsStepWorkLabel = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__work",
    });

export const getOtherAmenitiesPlaceholder = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__amenities-other-placeholder",
    });

export const getHourlyTypeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__salary-type-hourly",
    });

export const getMonthlyTypeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__salary-type-monthly",
    });

export const getBonusAmountLabelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__bonus-amount-label",
    });

export const getAmountRequiredMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__amount-required-error",
    });

export const getUnitRequiredMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__currency-required-error",
    });

export const getOfferJobAsMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__offer-job-as",
    });

export const getAddOfferAsMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__add-employee-offer-as",
    });

export const getCompanyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__company",
    });

export const getIndividualMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__individual",
    });

export const getSelectCompanyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__select-company-name",
    });

export const getNewCompanyNameMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__new-company-name",
    });

export const getCreateNewCardHeaderMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__create-new-card",
    });

export const getCreateNewCardBodyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__create-card-body-message",
    });

export const getUpdateCardHeaderMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__update-card",
    });

export const getUpdateCardBodyMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__update-card-body-message",
    });

export const useChangeMessage = (intl: IntlShape) => {
    return intl.formatMessage({
        id: "common__change-data",
    });
};

export const getCreateCardMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__create-card",
    });

export const getSelectFromListErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__select-from-list-error",
    });

export const getDateFromPastErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__date-from-past-error",
    });

export const getSaveJobOfferMessage = (intl: IntlShape) => intl.formatMessage({ id: "create-offer__save-job-offer" });

export const getGroupMessage = (intl: IntlShape) => intl.formatMessage({ id: "application-submission__as-group" });

export const getSaveJobOfferSuccessMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "create-offer__save-job-offer-success" });

export const getSaveEmployeeOfferSuccessMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "create-offer__save-employee-offer-success" });

export const getPublishJobOfferSuccessMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__publish-job-offer-success",
    });

export const getPublishEmployeeOfferSuccessMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__publish-employee-offer-success",
    });

export const getStepMessage = (intl: IntlShape) => intl.formatMessage({ id: "common__step" });

export const getOneErrorMessage = (intl: IntlShape) => intl.formatMessage({ id: "create-offer__one-error" });

export const getMultipleErrorsMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "create-offer__multiple-errors" });

export const getSalarySpecifiedMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__salary-specified",
    });

export const getBonusSpecifiedMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__bonus-specified",
    });

export const getChooseRateTypeMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__choose-rate-type",
    });

export const getAccommodationMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "common__accommodation",
    });

export const getCreateEmployeeOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "create-offer__create-employee-offer" });

export const getSaveEmployeeOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: "create-offer__save-employee-offer" });

export const getFinancialConditionsAndAmenitiesMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "create-offer__financial-conditions-and-amenities",
    });

export const getGroupOfferMessage = (intl: IntlShape) => intl.formatMessage({ id: "create-offer__group-offer" });

export const getWorkTypeWithDatesMessage = (
    intl: IntlShape,
    workType?: WorkType,
    startDate?: string,
    endDate?: string
) => {
    return workType
        ? intl.formatMessage(
            {
                id: "single-offer-card__work-type",
            },
            { workType, startDate, endDate }
        )
        : "";
};

export const getSalaryMessage = (
    intl: IntlShape,
    { paymentRateType, rewardCurrencyCode, rewardMinimumValue }: FinancialConditions
) => {
    return paymentRateType && rewardCurrencyCode && rewardMinimumValue
        ? intl.formatMessage(
            {
                id: "single-offer-card__salary",
            },
            {
                salary: rewardMinimumValue,
                currency: rewardCurrencyCode,
                salaryType: paymentRateType
            }
        )
        : "";
};

export const getSendMessageInformationMessage = (intl: IntlShape, maxLength: number) =>
    intl.formatMessage(
        {
            id: "offer-details__send-message-information",
        },
        {
            maxLength,
        }
    );

export const getNoPhoneNumberErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-details__no-phone-number-error",
    });

export const getNoEmailErrorMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-details__no-email-error",
    });

export const getSendMessageToEmployeeLabelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-details__send-message-to-employee-label",
    });
export const getSendMessageToEmployerLabelMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-details__send-message-to-employer-label",
    });
export const getLoginOfferMessage = (intl: IntlShape) =>
    intl.formatMessage({
        id: "offer-item__login-container-message",
    });
