import { TermSuggestionDto } from "features/common/dtos";
import { SearchTermSuggestion, SectionName } from "features/common/types";

const mapSearchTermSuggestionDtoToSearchFilter = (dto: TermSuggestionDto): SearchTermSuggestion => ({
    searchTermSuggestions: dto.items.map((item) => {
        switch (item.name) {
            case SectionName.Positions:
            case SectionName.Company:
                return {
                    section: { display: item.name, value: item.name },
                    options: item.suggestions?.map((suggestion) => ({
                        value: suggestion.name as string,
                        display: suggestion.name,
                        section: { value: item.name },
                    })),
                };
            case SectionName.Branches:
                return {
                    section: { display: item.name, value: item.name },
                    options: item.suggestions?.map((suggestion) => ({
                        value: suggestion.code as string,
                        display: suggestion.name,
                        section: { value: item.name },
                    })),
                };
            default:
                return {
                    section: { value: "" },
                    options: [],
                };
        }
    }),
});

export default mapSearchTermSuggestionDtoToSearchFilter;
