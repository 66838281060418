import {ReactComponent as CalendarIcon} from "assets/icons/calendar.svg";
import classNames from "classnames";
import {format} from "date-fns";
import FieldMessages from "features/common/components/FieldMessages";
import {DeviceClass} from "features/common/types";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    id: string;
    deviceClass: DeviceClass;
    label?: string;
    placeholder?: string;
    date?: Date;
    calendarExpanded?: boolean;
    error?: string;
    helperMessages?: (string | React.ReactElement)[];
    className?: string;
    ["data-testid"]?: string;
    onClick: () => void;
}

const DateDisplay = ({
    "data-testid": testId = "date-display",
    id,
    className,
    label,
    placeholder,
    deviceClass,
    date,
    calendarExpanded,
    error,
    helperMessages,
    onClick,
}: Props) => {
    const getFormattedDate = (date?: Date) => date ? format(date, "d/MM/yyyy") : undefined;
    const shouldShowHelperAndErrorMessage = deviceClass !== "desktop" || (deviceClass === "desktop" && !calendarExpanded);
    return (
        <div className={classNames(styles["date-display"], className)}>
            {label && date && (
                <div className={styles["date-display__label-wrapper"]}>
                    <label data-testid={`${testId}__label`} className={styles["date-display__label"]}>
                        {label}
                    </label>
                </div>
            )}
            <div
                data-testid={`${testId}__display-container`}
                id={id}
                className={classNames(
                    styles["date-display__display-container"],
                    {
                        [styles["date-display__display-container--highlighted"]]: calendarExpanded,
                    },
                    {
                        [styles["date-display__display-container--error"]]: !!error,
                    }
                )}
                onClick={onClick}
            >
                <span
                    data-testid={`${testId}__display`}
                    className={classNames(styles["date-display__display"], {
                        [styles["date-display__display--placeholder"]]: !!placeholder && !date,
                    })}
                >
                    {!!placeholder && !date ? placeholder : getFormattedDate(date)}
                </span>
                <CalendarIcon
                    data-testid={`${testId}__icon`}
                    className={styles["date-display__calendar-icon"]}
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        onClick();
                    }}
                />
            </div>
            {error && shouldShowHelperAndErrorMessage && <FieldMessages messages={[error]} mode="error" />}
            {!error && helperMessages && shouldShowHelperAndErrorMessage && (
                <FieldMessages messages={helperMessages} mode="info" />
            )}
        </div>
    );
};

export default DateDisplay;
