import { UserRole } from "features/common/types";
import UnauthorizedAccess from "features/routing/pages/UnauthorizedAccess";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

type Props = RouteProps & {
    children: React.ReactNode;
    userRole?: UserRole;
};

const PrivateRoute = ({ children, userRole, ...routeProps }: Props) => {
    return (
        <Route
            {...routeProps}
            render={() => (userRole === UserRole.Administrator ? children : <UnauthorizedAccess />)}
        />
    );
};

export default PrivateRoute;
