
import React, { useRef } from "react";
import styled from "styled-components";
import Input from "../Input";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";
import successTick from "../../../../assets/icons/success.svg";
import useOnClickOutside from "features/common/hooks/useOnClickOutside";
import useDeviceClass from "features/common/hooks/useDeviceClass";

interface ShareTooltipProps {
    setIsShareTooltip: (value: boolean) => void;
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>
    isClicked: boolean;
}

const ShareTooltip = ({ setIsShareTooltip, isClicked, setIsClicked }: ShareTooltipProps) => {
    const intl = useIntl();
    const ref = useRef(null);
    const deviceClass = useDeviceClass();
    const url = window.location.href
    const handleCopy = () => {
        navigator.clipboard.writeText(url);
        setIsClicked(true);
        if (deviceClass === 'smartphone') {
            setIsShareTooltip(false);
        }
    };

    useOnClickOutside(ref, () => setIsShareTooltip(false));

    return (
        <ShareContainer ref={ref}>
            <Input
                value={url}
                label={intl.formatMessage({ id: "share-link__link" })}
                fieldClassName={styles["share-input"]}
                containerClassName={styles["share-input-container"]}
                wrapperClassName={styles["share-input-wrapper"]}
                disabled={true} />
            {!isClicked ?
                <ShareButton onClick={handleCopy}>
                    <FormattedMessage id="common__copy" />
                </ShareButton>
                :
                <CopiedMessage>
                    <img src={successTick} alt="success" />
                    <FormattedMessage id="share-link__copied" />
                </CopiedMessage>
            }
        </ShareContainer>
    )
};

const ShareContainer = styled.div`
   display: inline-flex;
   position: absolute;
   gap: 20px;
   justify-content: center;
   align-items: center;
   width: 500px;
   height: 120px;
   color: black;
   background-color: #fff;
   box-shadow: 4px 4px 15px rgba(0, 34, 120, 0.25);
   border-radius: 5px;
   bottom: 60px;
   right: 80%;
   padding: 20px;
   z-index: 2;
    @media (max-width: 800px) {
        width: 300px;
    }
    @media (max-width: 600px) {
        right: 50%;  
    }
   &::after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      right: 0;
      top: 100px;
      border: 20px solid;
      border-radius: 10px;
      border-color: #fff #fff transparent transparent ;
   }
`;

const ShareButton = styled.button`
    background: none;
    border: none;
    color: #FF6302;
`

const CopiedMessage = styled.div`
display: flex;
color: #118146;
font-size: 16px;
& > img {
    margin-right: 10px;
}
`

export default ShareTooltip;

