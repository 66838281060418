import { ApiError } from "api/utils";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import Button from "features/common/components/Button";
import FieldMessages from "features/common/components/FieldMessages";
import Input from "features/common/components/Input";
import RadioButtons from "features/common/components/RadioButtons";
import TagInput from "features/common/components/TagInput";
import {
    getHomeMessage,
    getLocationMessage,
    getMyProfileMessage,
    getNewCardMessage,
} from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import { getSpecificLocation } from "features/common/utils";
import { getLocalizedYup } from "features/common/validators";
import PhonePrefixSelector from "features/myProfile/components/PhonePrefixSelector";
import { CreateNewCardDto } from "features/myProfile/dtos";
import { CreateNewCardValues, mapCreateNewCardValuesToNewCardDto } from "features/myProfile/mappers";
import { createNewCardValidationSchema } from "features/myProfile/validators";
import { appRoutes } from "features/routing/routes";
import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { CountriesDto } from "../../../common/dtos";

const initialFormValues: CreateNewCardValues = {
};

export interface Params {
    offerLength: string;
}

export interface Props {
    ["data-testid"]?: string;
    createNewCardLoading?: boolean;
    createNewCardSuccess?: string;
    createNewCardError?: ApiError;
    countries?: CountriesDto[];
    countriesLoading?: boolean;
    countriesError?: ApiError;
    creatingOffer?: OfferType;
    createNewCardAsync: (newCard: CreateNewCardDto) => void;
    getCountriesAsync: (lang: string) => void;
}

const NewCard = ({
    "data-testid": testId,
    createNewCardLoading,
    createNewCardSuccess,
    createNewCardError,
    countries,
    countriesLoading,
    countriesError,
    creatingOffer,
    createNewCardAsync,
    getCountriesAsync,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const { offerLength } = useParams<Params>();
    const [lastSentCardType, setLastSentCardType] = useState<string | undefined>();
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [location, setLocation] = useState("");
    const [selectedTag, setSelectedTag] = useState<any>();
    const [placeId, setPlaceId] = useState<string>();

    const homeMessage = getHomeMessage(intl);
    const myProfileMessage = getMyProfileMessage(intl);
    const newCardMessage = getNewCardMessage(intl);
    const locationIntlMessage = getLocationMessage(intl);
    const [phonePrefix, setPhonePrefix] = useState<string>('+48');

    const crumbs: BreadcrumbsItem[] = [
        {
            displayName: homeMessage,
            path: appRoutes.dashboard,
        },
        {
            displayName: myProfileMessage,
            path: appRoutes.myProfile,
        },
        {
            displayName: newCardMessage,
            path: appRoutes.newCard,
        },
    ];

    const validationSchema = useMemo(() => {
        const localYup = getLocalizedYup(intl)
        return createNewCardValidationSchema(localYup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl.locale]);

    const typeRadioOptions = useMemo(
        () => [
            { value: "EMPLOYER", label: intl.formatMessage({ id: "new-card__employer" }) },
            { value: "EMPLOYEE", label: intl.formatMessage({ id: "new-card__employee" }) },
        ],
        [intl]
    );
    const typeRadioOptionsJob = useMemo(
        () => [
            { value: "EMPLOYER", label: intl.formatMessage({ id: "new-card__employer" }) },
        ],
        [intl]
    );
    const personalRadioOptions = useMemo(
        () => [
            { value: "company", label: intl.formatMessage({ id: "new-card__company" }) },
            { value: "private", label: intl.formatMessage({ id: "new-card__private-person" }) },
        ],
        [intl]
    );

    const handleSubmit = useCallback(
        (values) => {
            if (placeId) {
                const cardValues = { ...values, phonePrefix };

                setLastSentCardType(values.type);
                createNewCardAsync(mapCreateNewCardValuesToNewCardDto(cardValues, placeId));
            }
        },
        [placeId, createNewCardAsync]
    );

    const handleCancel = useCallback(() => history.goBack(), [history]);

    useEffect(() => {
        if (createNewCardSuccess) {
            if (creatingOffer === OfferType.Job) {
                return history.push(`${appRoutes.createJobOfferCard}/${createNewCardSuccess}/${offerLength}`);
            }
            if (creatingOffer === OfferType.Employee) {
                return history.push(`${appRoutes.createEmployeeOfferCard}/${createNewCardSuccess}/${offerLength}`);
            }
            history.push(`${appRoutes.card}/${createNewCardSuccess}`);
        }
    }, [createNewCardSuccess, creatingOffer, history, offerLength]);

    useEffect(() => {
        if (!countries && !countriesLoading && !countriesError) {
            getCountriesAsync(intl.locale);
        }
    }, [countries, countriesError, countriesLoading, getCountriesAsync]);

    const {
        placePredictions,
        getPlacePredictions,
        placesService
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500,
    });

    useEffect(() => {
        getPlacePredictions({ input: location, types: ['(regions)'] });
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [location]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [location, placePredictions]);


    useEffect(() => {
        if (!selectedTag) return;

        setPlaceId(selectedTag.id);
    }, [selectedTag])


    return (
        <div className={styles["new-card"]}>
            <div className={styles["new-card__wrapper"]}
                style={{ marginBottom: "130px" }}
            >
                <div className={styles["new-card__breadcrumbs-container"]}>
                    <Breadcrumbs data-testid="new-card__breadcrumbs" crumbs={crumbs} />
                </div>
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        isValid,
                    }) => (
                        <form onSubmit={handleSubmit} className={styles["new-card__form-container"]}>
                            <h3 className={styles["new-card__form-title"]}>
                                <FormattedMessage id="new-card__title" />
                            </h3>

                            <div className={styles["new-card__radio-buttons-container"]}>
                                <RadioButtons
                                    options={creatingOffer === OfferType.Job ? typeRadioOptionsJob : typeRadioOptions}
                                    title={intl.formatMessage({ id: "new-card__type-label" })}
                                    wrapperClassName={styles["new-card__radio-input-wrapper"]}
                                    inputsContainerClassName={styles["new-card__radio-inputs-container"]}
                                    titleClassName={styles["new-card__radio-input-title"]}
                                    labelClassName={styles["new-card__radio-input-label"]}
                                    value={values.type}
                                    onValueChange={(value) => setFieldValue("type", value)}
                                    disabled={createNewCardLoading}
                                />
                            </div>
                            <div className={styles["new-card__radio-buttons-container"]}>
                                <RadioButtons
                                    options={personalRadioOptions}
                                    title={intl.formatMessage({ id: "new-card__personal-label" })}
                                    wrapperClassName={styles["new-card__radio-input-wrapper"]}
                                    inputsContainerClassName={styles["new-card__radio-inputs-container"]}
                                    titleClassName={styles["new-card__radio-input-title"]}
                                    labelClassName={styles["new-card__radio-input-label"]}
                                    value={values.personal}
                                    onValueChange={(value) => setFieldValue("personal", value)}
                                    disabled={createNewCardLoading}
                                />
                            </div>
                            {values.personal === "company" && !!values.type && (
                                <div className={styles["new-card__company-form"]}>
                                    <Input
                                        data-testid={`${testId}__business-name-input`}
                                        name="businessName"
                                        label={`${intl.formatMessage({ id: "new-card__business-name-label" })}*`}
                                        placeholder={`${intl.formatMessage({
                                            id: "new-card__business-name-label",
                                        })}*`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.businessName}
                                        isInvalid={!!touched.businessName && !!errors.businessName}
                                        error={!!touched.businessName ? errors.businessName : undefined}
                                        withValidation
                                        disabled={createNewCardLoading}
                                    />
                                    <TagInput
                                        label={`${locationIntlMessage}*`}
                                        placeholder={`${locationIntlMessage}*`}
                                        sections={locationOptions}
                                        value={{ input: values.city ? values.city.input : "", tags: [] }}
                                        onChange={(tags, input) => {
                                            setFieldTouched("city", true);
                                            setLocation(input)
                                            if (values.city?.input !== input) {
                                                setPlaceId(undefined);
                                            }
                                            if (tags.length) {
                                                const [newValue] = tags;
                                                setSelectedTag(newValue);
                                                return setFieldValue("city", { input: newValue.name, tags });
                                            }
                                            return setFieldValue("city", { tags, input })
                                        }}
                                        className={styles["new-card__location-tag"]}
                                        error={touched.city && (!placeId) && intl.formatMessage({ id: "common__field-is-required" })}
                                    />
                                </div>
                            )}
                            {values.personal === "private" && !!values.type && (
                                <div className={styles["new-card__private-form"]}>
                                    <Input
                                        data-testid={`${testId}__first-name-input`}
                                        name="firstName"
                                        label={`${intl.formatMessage({ id: "new-card__first-name-label" })}*`}
                                        placeholder={`${intl.formatMessage({
                                            id: "new-card__first-name-label",
                                        })}*`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        isInvalid={!!touched.firstName && !!errors.firstName}
                                        error={!!touched.firstName ? errors.firstName : undefined}
                                        withValidation
                                        disabled={createNewCardLoading}
                                    />
                                    <Input
                                        data-testid={`${testId}__last-name-input`}
                                        name="lastName"
                                        label={`${intl.formatMessage({ id: "new-card__last-name-label" })}*`}
                                        placeholder={`${intl.formatMessage({
                                            id: "new-card__last-name-label",
                                        })}*`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        isInvalid={!!touched.lastName && !!errors.lastName}
                                        error={!!touched.lastName ? errors.lastName : undefined}
                                        withValidation
                                        disabled={createNewCardLoading}
                                    />
                                    <PhonePrefixSelector onChange={(newValue) => setPhonePrefix(newValue)} value={phonePrefix}>
                                        <Input
                                            data-testid={`${testId}__phone-number-input`}
                                            name="phoneNumber"
                                            label={`${intl.formatMessage({ id: "new-card__phone-number-label" })}*`}
                                            placeholder={`${intl.formatMessage({
                                                id: "new-card__phone-number-label",
                                            })}*`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                            isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                                            error={!!touched.phoneNumber ? errors.phoneNumber : undefined}
                                            withValidation
                                            disabled={createNewCardLoading}
                                        />
                                    </PhonePrefixSelector>
                                    <TagInput
                                        label={`${locationIntlMessage}*`}
                                        placeholder={`${locationIntlMessage}*`}
                                        sections={locationOptions}
                                        value={{ input: values.city ? values.city.input : "", tags: [] }}
                                        onChange={(tags, input) => {
                                            setFieldTouched("city", true);
                                            setLocation(input)
                                            if (values.city?.input !== input) {
                                                setPlaceId(undefined);
                                            }
                                            if (tags.length) {
                                                const [newValue] = tags;
                                                setSelectedTag(newValue);
                                                return setFieldValue("city", { input: newValue.name, tags });
                                            }
                                            return setFieldValue("city", { tags, input })
                                        }}
                                        className={styles["new-card__location-tag"]}
                                        error={touched.city && (!placeId) && intl.formatMessage({ id: "common__field-is-required" })}
                                    />
                                </div>
                            )}


                            <div className={styles["new-card__buttons-container"]}>
                                <Button
                                    variant={"tertiary"}
                                    type="button"
                                    data-testid={`${testId}__cancel-button`}
                                    onClick={handleCancel}
                                    disabled={createNewCardLoading}
                                >
                                    <FormattedMessage id="common__cancel" />
                                </Button>
                                <Button
                                    data-testid={`${testId}__submit-button`}
                                    type="submit"
                                    isLoading={createNewCardLoading}
                                    disabled={!isValid || !values.personal || !values.type || !selectedTag || !values.city?.input}
                                >
                                    <FormattedMessage id="card-details__create-new-card" />
                                </Button>
                            </div>
                            {createNewCardError && (
                                <div className={styles["new-card__error-container"]}>
                                    <FieldMessages
                                        messages={[
                                            createNewCardError.response?.status === 400
                                                ? lastSentCardType === "EMPLOYEE"
                                                    ? intl.formatMessage({
                                                        id: "new-card__only-one-personal-employee-error-message",
                                                    })
                                                    : intl.formatMessage({
                                                        id: "new-card__only-one-personal-employer-error-message",
                                                    })
                                                : intl.formatMessage({ id: "new-card__error-message" }),
                                        ]}
                                        mode="error"
                                    />
                                </div>
                            )}
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default NewCard;
