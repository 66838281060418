import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
  ["data-testid"]?: string;
  className?: string;
  checked?: boolean;
  label?: string | React.ReactElement;
  onToggle?: (checked: boolean) => void;
  disabled?: boolean;
}

const Checkbox = ({ "data-testid": testId, className, checked, label, onToggle, disabled }: Props) => {
  const onCheck = () => {
    onToggle && onToggle(!checked);
  };

  return (
    <div className={classNames(styles["checkbox"], className)} data-testid={testId}>
      <div
        data-testid={testId ? `${testId}__field` : undefined}
        className={classNames(styles["checkbox__field"], { [styles["checkbox__field--checked"]]: checked })}
        onClick={disabled ? () => { } : onCheck}
      >
        {checked && <TickIcon />}
      </div>
      {label && (
        <label
          data-testid={testId ? `${testId}__label` : undefined}
          className={styles["checkbox__label"]}
          onClick={disabled ? () => { } : onCheck}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
