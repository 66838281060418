export interface City {
    name: string;
    region: string;
    country: string;
    locationId: string;
}

export interface Region {
    name: string;
    country: string;
    locationId: string;
}

export interface Currency {
    id: number;
    name: string;
    code: string;
}

export interface Amount {
    value?: number;
    currency?: Currency;
}

export enum SalaryType {
    Hourly = "HOURLY",
    Monthly = "MONTHLY",
}

export interface SearchFilter {
    searchTerm: string;
    location: string;
    distance: number;
}

export interface PaginationDto {
    page: number;
    size: number;
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
}
