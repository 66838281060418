import { makeApiRequest } from "api/makeApiRequest";
import { CancelToken } from "api/utils";
import { CardDto } from "features/common/dtos";
import MyProfileAction, {
    createNewCard,
    deleteCard,
    deleteMyAccount,
    getCardDetails,
    getMyApplications,
    getMyProfileCards,
    getOfferCreationCards,
    getPaymentCardData,
    getRelatedOffers,
    refreshCardDetails,
    updateMyProfileCard,
    updateUserDetails,
    updateUserPicture,
} from "features/myProfile/actions";
import { MyProfileApi } from "features/myProfile/api";
import { CreateNewCardDto, MyProfileUserDetailsDto } from "features/myProfile/dtos";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";

export const updateMyProfileDetailsAsync = (
    userDetails: MyProfileUserDetailsDto,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "updateMyProfileDetailsAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(updateUserDetails.request());

        await makeApiRequest(
            api.updateMyProfileDetailsAsync,
            [userDetails, cancelToken],
            updateUserDetails.success,
            updateUserDetails.failure,
            dispatch
        );
    };
};

export const getOfferCreationCardsAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "getOfferCreationCardsAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getOfferCreationCards.request());

        await makeApiRequest(
            api.getOfferCreationCardsAsync,
            [cancelToken],
            getOfferCreationCards.success,
            getOfferCreationCards.failure,
            dispatch
        );
    };
};

export const getMyProfileCardsAsync = (
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "getMyProfileCardsAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getMyProfileCards.request());

        const {
            user: { user, userId },
        } = getState();

        if (userId || user?.id) {
            await makeApiRequest(
                api.getMyProfileCardsAsync,
                [(userId || user?.id) as string, cancelToken],
                getMyProfileCards.success,
                getMyProfileCards.failure,
                dispatch
            );
        }
    };
};

export const updateMyProfileCardAsync = (
    cardDetails: Partial<CardDto>,
    id: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "updateMyProfileCardAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(updateMyProfileCard.request());

        await makeApiRequest(
            api.updateMyProfileCardAsync,
            [cardDetails, id, cancelToken],
            updateMyProfileCard.success,
            updateMyProfileCard.failure,
            dispatch
        );
    };
};

export const createNewCardAsync = (
    newCard: CreateNewCardDto,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "createNewCardAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(createNewCard.request());

        await makeApiRequest(
            api.createNewCardAsync,
            [newCard, cancelToken],
            createNewCard.success,
            createNewCard.failure,
            dispatch
        );
    };
};

export const updateProfilePictureAsync = (
    userPhoto: File
): ThunkAction<void, RootState, Pick<MyProfileApi, "uploadUserPhotoAsync" | "uploadUserPhotos">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(updateUserPicture.request());

        const imageForm = new FormData();
        imageForm.append("images", userPhoto);
        const uploadedPhoto = await api.uploadUserPhotos(imageForm);
        await makeApiRequest(
            api.uploadUserPhotoAsync,
            [{ profilePicture: uploadedPhoto[0].fileName }],
            updateUserPicture.success,
            updateUserPicture.failure,
            dispatch
        );
    };
};

export const getCardDetailsAsync = (
    uniqueId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "getCardDetailsAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getCardDetails.request(uniqueId));

        await makeApiRequest(
            api.getCardDetailsAsync,
            [uniqueId, cancelToken],
            getCardDetails.success,
            getCardDetails.failure,
            dispatch
        );
    };
};

export const refreshCardDetailsAsync = (
    uniqueId: string,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "getCardDetailsAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        try {
            const response = await api.getCardDetailsAsync(uniqueId, cancelToken);
            dispatch(refreshCardDetails(response));
        } catch (apiError) { }
    };
};

export const getRelatedOffersAsync = (
    cardUniqueId: string,
    cardKind: string,
    pageSize: number,
    offset: number,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "getRelatedOffersAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getRelatedOffers.request());

        await makeApiRequest(
            api.getRelatedOffersAsync,
            [cardUniqueId, cardKind, pageSize, offset, cancelToken],
            getRelatedOffers.success,
            getRelatedOffers.failure,
            dispatch
        );
    };
};

export const getMyApplicationsAsync = (
    fetchParams?: any,
    cancelToken?: CancelToken,
): ThunkAction<void, RootState, Pick<MyProfileApi, "getMyApplicationsAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getMyApplications.request());

        await makeApiRequest(
            api.getMyApplicationsAsync,
            [fetchParams, cancelToken],
            getMyApplications.success,
            getMyApplications.failure,
            dispatch
        );
    };
};

export const getPaymentCardDataAsync = (
    cancelToken?: CancelToken,
): ThunkAction<void, RootState, Pick<MyProfileApi, "getPaymentCardAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getPaymentCardData.request());

        await makeApiRequest(
            api.getPaymentCardAsync,
            [cancelToken],
            getPaymentCardData.success,
            getPaymentCardData.failure,
            dispatch
        );
    };
};

export const deleteCardAsync = (
    cardId: string,
    cancelToken?: CancelToken,
): ThunkAction<void, RootState, Pick<MyProfileApi, "deleteCardAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(deleteCard.request());

        await makeApiRequest(
            api.deleteCardAsync,
            [cardId, cancelToken],
            deleteCard.success,
            deleteCard.failure,
            dispatch,
            cardId
        );
    };
};

export const deleteMyAccountAsync = (
    params: any,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<MyProfileApi, "deleteMyAccountAsync">, MyProfileAction> => {
    return async (dispatch, getState, api) => {
        dispatch(deleteMyAccount.request());

        await makeApiRequest(
            api.deleteMyAccountAsync,
            [params, cancelToken],
            deleteMyAccount.success,
            deleteMyAccount.failure,
            dispatch,
            params
        );
    };
};
