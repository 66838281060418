import { CancelToken } from "api/utils";
import { ReactComponent as MagnifierIcon } from "assets/icons/magnifier.svg";
import Button from "features/common/components/Button";
import SearchInput from "features/common/components/SearchInput";
import TagInput from "features/common/components/TagInput";
import { searchDebounceMs } from "features/common/constants";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDebounce from "features/common/hooks/useDebounce";
import { getLocationMessage } from "features/common/translationMessages";
import {
    DropdownOption,
    LocationSuggestion,
    OfferType,
    SearchForm,
    SearchTermSuggestion,
    TranslatedSectionDropdownOption,
} from "features/common/types";
// import OffersApi from "features/offers/api";
import { getKmIntlMessage, getTermIntlMessage } from "features/offers/translationMessages";
import { Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LocationRangePicker from "./LocationRangePicker";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styles from "./styles.module.scss";

interface Props {
    initialValues: SearchForm;
    searchTermSuggestions?: SearchTermSuggestion;
    locationSuggestions?: LocationSuggestion;
    distanceOptions: DropdownOption<number>[];
    asJobOffersSearch?: boolean;
    distanceDisabled: boolean;
    setDistanceDisabled: (value: boolean) => void;
    scrollToTop: () => void;
    getSearchTermsSuggestionsAsync: (offerType: OfferType, searchPhrase: string, cancelToken?: CancelToken) => void;
    getLocationSuggestionsAsync: (searchPhrase: string, cancelToken?: CancelToken) => void;
    resetSearchTermsSuggestions: () => void;
    resetLocationSuggestions: () => void;
}

const SearchBoxForm = ({
    searchTermSuggestions,
    distanceOptions,
    asJobOffersSearch,
    distanceDisabled,
    scrollToTop,
    getSearchTermsSuggestionsAsync,
    resetSearchTermsSuggestions,
    setDistanceDisabled,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();

    const termIntlMessage = getTermIntlMessage(intl);
    const locationIntlMessage = getLocationMessage(intl);
    const kmIntlMessage = getKmIntlMessage(intl);

    const [searchTerm, setSearchTerm] = useState("");
    const [location, setLocation] = useState("");
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const debouncedSearchTerm = useDebounce(searchTerm, searchDebounceMs);
    // const debouncedLocation = useDebounce(location, searchDebounceMs);

    useEffect(() => {
        !debouncedSearchTerm && resetSearchTermsSuggestions();
        debouncedSearchTerm &&
            getSearchTermsSuggestionsAsync(
                asJobOffersSearch ? OfferType.Job : OfferType.Employee,
                debouncedSearchTerm,
                createCancelToken()
            );
    }, [
        asJobOffersSearch,
        createCancelToken,
        debouncedSearchTerm,
        getSearchTermsSuggestionsAsync,
        resetSearchTermsSuggestions,
    ]);

    const searchTermOptions = (): TranslatedSectionDropdownOption<string>[] => {
        return searchTermSuggestions ? searchTermSuggestions.searchTermSuggestions : [];
    };

    const distancePlaceholder = (): string => {
        return `+ 0 ${kmIntlMessage}`;
    };

    const { placesService, placePredictions, getPlacePredictions } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500,
    });

    useEffect(() => {
        getPlacePredictions({ input: location, types: ["(regions)"] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id,
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [location, placePredictions]);

    return (
        <>
            <Field name="term">
                {({ field, form }: FieldProps<SearchForm["term"]>) => (
                    <SearchInput
                        value={field.value}
                        onChange={(input, option, section) => {
                            form.setFieldValue("term", { input, option, section });
                            setSearchTerm(input);
                        }}
                        onFocus={() => {
                            setSearchTerm(field.value.input);
                        }}
                        data-testid="offers-searchbox-form__search-term-input"
                        label={`${termIntlMessage}`}
                        placeholder={`${termIntlMessage}`}
                        sections={searchTermOptions()}
                        className={styles["offers-searchbox-form__search-term"]}
                        shouldEmphasizeSearchTerm
                    />
                )}
            </Field>
            <div className={styles["offers-searchbox-form__input-group"]}>
                <Field name="location">
                    {({ field, form }: FieldProps<SearchForm["location"]>) => (
                        <TagInput
                            value={{ tags: [], input: field.value.input }}
                            onChange={(tags, input) => {
                                if (tags.length) {
                                    form.setFieldValue("location", { tags: [], input: tags[0].name });

                                    placesService?.getDetails({ placeId: tags[0].id }, (placeDetails) => {
                                        form.setFieldValue("placeId", `${placeDetails?.place_id}`);
                                        setDistanceDisabled(
                                            !placeDetails?.address_components?.find((item) =>
                                                item?.types?.find((type) => type === "locality")
                                            )
                                        );
                                    });
                                } else {
                                    form.setFieldValue("placeId", undefined);
                                    form.setFieldValue("location", { tags: [], input });
                                }
                                setLocation(input);
                            }}
                            label={`${locationIntlMessage}`}
                            placeholder={`${locationIntlMessage}`}
                            sections={locationOptions}
                            className={styles["offers-searchbox-form__location-tag"]}
                            shouldEmphasizeSearchTerm
                        />
                    )}
                </Field>
                <Field name="distance">
                    {({ field, form }: FieldProps<DropdownOption<number>>) => (
                        <LocationRangePicker
                            distancePlaceholder={distancePlaceholder}
                            value={field.value}
                            setFieldValue={form.setFieldValue}
                            distanceOptions={distanceOptions}
                            distanceDisabled={distanceDisabled}
                        />
                    )}
                </Field>
            </div>
            <Button
                className={styles["offers-searchbox-form__search-offers-button"]}
                onClick={() => scrollToTop()}
                type="submit"
                variant="search"
            >
                <MagnifierIcon />
                <FormattedMessage id="common__search" />
            </Button>
        </>
    );
};

export default SearchBoxForm;
