import { makeApiRequest } from "api/makeApiRequest";
import { CancelToken } from "api/utils";
import EmployeeOffersAction, {
    amendEmployeeOffers,
    getEmployeeOfferDetails,
    getEmployeeOffers,
    getMyEmployeeOffers,
    createEmployeeOffer,
    sendEmployeeOfferMessage,
} from "features/employeeOffers/actions";
import { EmployeeOffersApi } from "features/employeeOffers/api";
import { CreateEmployeeOfferRequestObject } from "features/employeeOffers/models";
import { EmployeeOffersFetchParams } from "features/employeeOffers/types";
import { MyJobOffersFetchParams } from "features/jobOffers/types";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";

export const getEmployeeOffersAsync = (
    params: EmployeeOffersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<EmployeeOffersApi, "getEmployeeOffersAsync">, EmployeeOffersAction> => {
    return async (dispatch, getState, api) => {
        dispatch(getEmployeeOffers.request());

        await makeApiRequest(
            api.getEmployeeOffersAsync,
            [params, cancelToken],
            getEmployeeOffers.success,
            getEmployeeOffers.failure,
            dispatch
        );
    };
};

export const amendEmployeeOffersAsync = (
    params: EmployeeOffersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<EmployeeOffersApi, "getEmployeeOffersAsync">, EmployeeOffersAction> => {
    return async (dispatch, getState, api) => {
        dispatch(amendEmployeeOffers.request());

        await makeApiRequest(
            api.getEmployeeOffersAsync,
            [params, cancelToken],
            amendEmployeeOffers.success,
            amendEmployeeOffers.failure,
            dispatch
        );
    };
};

export const getMyEmployeeOffersAsync = (
    params: MyJobOffersFetchParams,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<EmployeeOffersApi, "getMyEmployeeOffersAsync">, EmployeeOffersAction> => {
    return async (dispatch, getState, api) => {
        const {
            employeeOffers: { myEmployeeOffersPage, myEmployeeOffersTotalPages },
        } = getState();

        if (myEmployeeOffersTotalPages && myEmployeeOffersTotalPages <= myEmployeeOffersPage) {
            dispatch(getMyEmployeeOffers.cancel(null, undefined));
            return;
        }

        dispatch(getMyEmployeeOffers.request());

        await makeApiRequest(
            api.getMyEmployeeOffersAsync,
            [{ ...params, page: myEmployeeOffersPage, size: 25 }, cancelToken],
            getMyEmployeeOffers.success,
            getMyEmployeeOffers.failure,
            dispatch
        );
    };
};

export const getEmployeeOfferDetailsAsync = (
    id: number,
    cancelToken?: CancelToken
): ThunkAction<
    void,
    RootState,
    Pick<EmployeeOffersApi, "getEmployeeOfferDetailsAsync" | "getCardDetailsAsync">,
    EmployeeOffersAction
> => {
    return async (dispatch, getState, api) => {
        dispatch(getEmployeeOfferDetails.request());

        try {
            const employeeOfferDetailsData = await api.getEmployeeOfferDetailsAsync(id, cancelToken);

            const businessCardId = employeeOfferDetailsData?.data?.businessCardId;
            let businessCardDetails;
            if (businessCardId) {
                businessCardDetails = await api.getCardDetailsAsync(businessCardId);
            }

            dispatch(
                getEmployeeOfferDetails.success({
                    ...employeeOfferDetailsData,
                    businessCardDetails,
                })
            );
        } catch (error) {
            dispatch(getEmployeeOfferDetails.failure(error));
        }
    };
};

export const sendEmployeeOfferMessageAsync = (
    formData: FormData,
    cancelToken?: CancelToken
): ThunkAction<void, RootState, Pick<EmployeeOffersApi, "sendEmployeeOfferMessageAsync">, EmployeeOffersAction> => {
    return async (dispatch, getState, api) => {
        dispatch(sendEmployeeOfferMessage.request());

        await makeApiRequest(
            api.sendEmployeeOfferMessageAsync,
            [formData, cancelToken],
            sendEmployeeOfferMessage.success,
            sendEmployeeOfferMessage.failure,
            dispatch
        );
    };
};

// export const saveEmployeeOfferAsync = (
//     schema: CreateEmployeeOfferSchema,
//     cancelToken?: CancelToken
// ): ThunkAction<void, RootState, Pick<EmployeeOffersApi, "saveEmployeeOfferAsync">, EmployeeOffersAction> => {
//     return async (dispatch, getState, api) => {
//         dispatch(saveEmployeeOffer.request());

//         await makeApiRequest(
//             api.saveEmployeeOfferAsync,
//             [mapCreateEmployeeOfferSchemaToFormData(schema), cancelToken],
//             saveEmployeeOffer.success,
//             saveEmployeeOffer.failure,
//             dispatch
//         );
//     };
// };

export const publishEmployeeOfferAsync = (
    values: CreateEmployeeOfferRequestObject,
    cancelToken?: CancelToken
): ThunkAction<
    void,
    RootState,
    Pick<EmployeeOffersApi, "createEmployeeOfferAsync" | "updateMyProfileCardAsync">,
    EmployeeOffersAction
> => {
    return async (dispatch, getState, api) => {
        dispatch(createEmployeeOffer.request());
        try {
            const response = await api.createEmployeeOfferAsync(
                { ...values.employeeOfferDetails, businessCard: values.businessCard },
                cancelToken
            );
            dispatch(createEmployeeOffer.success(response));
        } catch (error) {
            dispatch(createEmployeeOffer.failure(error));
        }
    };
};
