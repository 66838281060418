import {ReactComponent as LocationIcon} from "assets/icons/locationMarkerBlack.svg";
import React, {useCallback, useEffect, useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import styles from "./styles.module.scss";
import {Location} from "features/offers/types";
import {FormattedMessage} from "react-intl";

interface CurrentLocationType {
  lat: number;
  lng: number;
}
interface MapWithInfoProps {
  location: Location
}

function MapWithInfo({ location }: MapWithInfoProps) {
  return (
    <div className={styles["map__wrapper"]}>
      <div className={styles['map__wrapper__info-container']}>
        <h3 className={styles["map__wrapper__header"]}><FormattedMessage id="common__location" /></h3>
        <div className={styles["map__wrapper__location-details"]}>
          <div className={styles["map__wrapper__location-details__icon"]}>
            <LocationIcon width={28} height={28} />
          </div>
          <div>
            <p className={styles["map__wrapper__location-details__location-title"]}>{location?.street ? (location.street + ', ') : ''}{!!location.city ? location.city : ''}</p>
            <p>{location?.regionLevel1 && (location.regionLevel1 + ', ')}{`${location.country}`}</p>
          </div>
        </div>
      </div>
      <MapMarker latitude={location.location?.latitude} longitude={location.location?.longitude} />
    </div>
  )
}

export { MapWithInfo };

const containerStyle = {
  width: '100%',
  height: '100%',
  borerRadius: '5px'
};

interface MapMakerProps {
  latitude?: number
  longitude?: number
}

function MapMarker({ latitude, longitude }: MapMakerProps) {
  const [map, setMap] = useState<any>(null)
  const [currentLocation, setCurrentLocation] = useState<CurrentLocationType>();
  const [zoom] = useState(14);

  const center = {
    lat: latitude || 0,
    lng: longitude || 0
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string
  })

  const handleLoadMap = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude])

  useEffect(() => {
    if (map) {
      map.setZoom(zoom)
    }
  }, [center, map, zoom])

  const onUnmount = useCallback(() => setMap(null), [])

  const getBrowserLocation = () => {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
        openMapWithCurrentLocation();
      },
      (error) => {
        console.log(error);
      }
    )
  };

  const openMapWithCurrentLocation = () => {
    if (currentLocation) {
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving&origin=${currentLocation.lat},${currentLocation.lng}`, '_blank')
    } else {
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`, '_blank')
    }
  }

  if (!isLoaded || !latitude || !longitude) {
    return <></>;
  }

  return (
    <div className={styles["map__container"]}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={handleLoadMap}
        onUnmount={onUnmount}
        onClick={getBrowserLocation}
      >
        <Marker
          position={center}
          onClick={getBrowserLocation}
        />
      </GoogleMap>
    </div>
  )
}

export default MapMarker;