import {createOfferDetailsStepFieldNames} from "features/offers/constants";

export const createEmployeeOfferFieldNames = {
    detailsStep: {
        ...createOfferDetailsStepFieldNames,
        street: "street",
        streetNumber: "streetNumber",
    },
    financialConditionsStep: {
        salaryType: "salaryType",
        salaryMinValue: "salaryMinValue",
        salaryMaxValue: "salaryMaxValue",
        salaryUnit: "salaryUnit",
        bonusValue: "bonusValue",
        bonusUnit: "bonusUnit",
        bonusDate: "bonusDate",
    },
    requirementsStep: {
        employeeExperience: "jobExperience",
        languages: "languages",
        experience: "experience",
        permissions: "permissions",
        drivingLicenses: "drivingLicenses",
        education: "education",
        certificates: "certificates",
        programs: "programs",
        other: "other",
    },
    additionalInfoStep: {
        additionalInfo: "additionalInfo",
        mainImage: "mainImage",
        additionalImages: "additionalImages",
    },
};
