import { IndicatorRequest } from "../types";

export const threeDSRequest: IndicatorRequest = (data) => {
    return new Promise((resolve) => {
        let displayBox = document.getElementById("displayBox");

        let iframe = document.createElement("iframe");
        iframe.classList.add("hidden");
        iframe.name = "threeDSMethodIframe";

        displayBox?.appendChild(iframe);

        const form = document.createElement("form");
        form.setAttribute("id", "threeDSMethodForm");
        form.setAttribute("type", "hidden");
        form.action = data.url;
        form.target = "threeDSMethodIframe";
        form.method = "post";

        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", "threeDSMethodData");
        input.setAttribute("id", "threeDSMethodData");
        input.setAttribute("value", data.threeDSMethodData);
        form.appendChild(input);

        document.body.appendChild(form);

        iframe.onload = () => {
            iframe.remove();
            form.remove();
            resolve({});
        };

        form.submit();
    });
};
