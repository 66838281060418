import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "features/common/components/Button";
import { OfferType } from "features/common/types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    offerType: OfferType;
    onClick: () => void;
}

const AddOfferButton = ({ offerType, onClick }: Props) => (
    <div className={styles["add-offer-button"]}>
        <Button data-testid="add-offer-button" onClick={onClick} className={styles["add-offer-button__button"]}>
            <PlusIcon />
            {offerType === OfferType.Job ? (
                <FormattedMessage id="offers__create-job-offer" />
            ) : (
                <FormattedMessage id="offers__add-employee-offer" />
            )}
        </Button>
    </div>
);

export default AddOfferButton;
