import useDateFormat from "features/common/hooks/useDateFormat";
import React from "react";
import { FormattedMessage } from "react-intl";

export interface Props {
    from?: Date;
    to?: Date;
    format?: string
}
const FromToText = ({ from, to, format }: Props) => {
    const getFormattedDate = useDateFormat(format);

    const formattedDateFrom = getFormattedDate(from);
    const formattedDateTo = getFormattedDate(to);

    return (
        <>
            {!formattedDateFrom && !formattedDateTo ? (
                "-"
            ) : (
                <>
                    {formattedDateFrom && (
                        <span data-testid={`from-to-text__from-part`}>
                            <FormattedMessage id="common__from" /> {formattedDateFrom}
                        </span>
                    )}
                    {formattedDateTo && (
                        <span data-testid={`from-to-text__to-part`}>
                            {formattedDateFrom && <> </>}
                            <FormattedMessage id="common__to" /> {formattedDateTo}
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export default FromToText;
