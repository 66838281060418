import React from 'react'
import styles from "./styles.module.scss";
import { FormattedMessage } from 'react-intl';

const HowItWorks = () => {
    return (
        <div className={styles["howitworks"]}>
            <div>
                <h1><FormattedMessage id="how_it_works_welcome" /></h1>
                <h2><FormattedMessage id="how_it_works_header" /></h2>
                <p><FormattedMessage id="how_it_works_explanation" /></p>
                <p><FormattedMessage id="how_it_works_getbonus" /></p>
            </div>
            <div className={styles["container__table"]}>
                <h2 className={styles["container__table--header"]}>GetMen:</h2>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><div className={styles["container__table--row--title"]}><FormattedMessage id="how_it_works_for_everyone" /></div></div>
                    <div className={styles["container__table--row--cell"]}><div className={styles["container__table--row--title"]}><FormattedMessage id="how_it_works_for_candidates" /></div></div>
                    <div className={styles["container__table--row--cell"]}><div className={styles["container__table--row--title"]}><FormattedMessage id="how_it_works_for_employers" /></div></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_quick_registration" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_profile_description" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_showing_visuality" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_simple_logging" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_checking_of_the_potential_employer" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_describing_the_business" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_adding_pictures" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_checking_of_the_potential_workspace" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_encouraging_candidates" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_sending_money" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_communicating_employers" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_describing_the_vacancy" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_internal_messenger" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_getting_extra_money" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_sourcing_permanent" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_reviewing_employers" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_work_and_travel" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_sourcing_part_time" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_reviewing_employees" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_looking_for_a_job" /></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_sourcing_weekend_employees" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_intuive_portal" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_sourcing_industry_employees" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_synchronised_with_google_maps" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_adding_unlimited_offers" /></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_favourite_employers" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_avoiding_intermediaries" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_newest_available_technology" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_adding_offers" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_recruitment_no_office_space" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_without_applying_for_an_apt_license" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_recruitment_and_remote-work" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_recruitment_of_unlimited_number" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_payment_for_the_recruitment" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
                <div className={styles["container__table--row"]}>
                    <div className={styles["container__table--row--cell"]}><FormattedMessage id="how_it_works_negotiations_of_getbonus" /></div>
                    <div className={styles["container__table--row--cell"]}></div>
                    <div className={styles["container__table--row--cell"]}></div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks