import Api from "api";
import {CancelToken} from "api/utils";
import config from "config";
import {defaultLocale} from "features/common/constants";
import {CardDto} from "features/common/dtos";
import {OfferType} from "features/common/types";
import {cookieNames} from "features/cookies/constants";
import {getCookie} from "features/cookies/utils";
import {
    ApplicationDetailsDto,
    ApplicationsListDto,
    CreateConversationDto,
    CreateJobOfferResponseDto,
    InterestedListDto,
    JobOfferCardDetailsResponseDto,
    JobOfferDetailsResponseDto,
    JobOfferResponseDto,
    MyJobOffersResponseDto,
} from "features/jobOffers/dtos";
import {
    mapJobOffersFetchParamsToQueryParameters,
    mapMyJobOffersFetchParamsToQueryParameters,
} from "features/jobOffers/mappers";
import {CreateJobOfferRequestObject, FenigePaymentStatus} from "features/jobOffers/models";
import {ApplicationFormValues, JobOffersFetchParams, MyJobOffersFetchParams} from "features/jobOffers/types";
import {CardDetailsDto} from "features/myProfile/dtos";
import {PhotoDto} from "features/user/dtos";
import {JobOfferDedicationDTO} from "../../employeeOffers/models";

export class JobOffersApi extends Api {
    getFeaturedAsync = (params: JobOffersFetchParams, cancelToken?: CancelToken): Promise<JobOfferResponseDto> => {
        return this.get<JobOfferResponseDto>(
            `${config.apiRoutes.RECOMMENDED_JOB_OFFERS}${mapJobOffersFetchParamsToQueryParameters(params, true, true, false)}&isActive=true&sort_by=addedDate&sort=desc` + "&location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale,
            cancelToken,
        );
    };

    getJobOffersAsync = (params: JobOffersFetchParams, cancelToken?: CancelToken): Promise<JobOfferResponseDto> => {
        return this.get<JobOfferResponseDto>(
            `${config.apiRoutes.OFFERS}${mapJobOffersFetchParamsToQueryParameters(params, true, true, false)}&isActive=true&sort_by=addedDate&sort=desc` + "&location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale,
            cancelToken,
        );
    };

    getMyJobOffersAsync = (
        params: MyJobOffersFetchParams,
        cancelToken?: CancelToken,
    ): Promise<MyJobOffersResponseDto> => {
        return this.get<MyJobOffersResponseDto>(
            `${config.apiRoutes.USER_JOB_OFFERS}${mapMyJobOffersFetchParamsToQueryParameters(params)}&sort_by=addedDate&sort=desc` + "&location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale,
            cancelToken,
        );
    };

    getInterestedAsync = async (
        jobOfferId: string,
        currentUserId: string,
        cancelToken?: CancelToken,
    ): Promise<InterestedListDto> => {
        return {
            ...(await this.get<InterestedListDto>(
                `${config.apiRoutes.OFFERS}/${jobOfferId}/conversations`,
                cancelToken,
            )),
            currentUserId,
        };
    };

    getApplicationsAsync = (jobOfferId: string, cancelToken?: CancelToken): Promise<ApplicationsListDto> => {
        return this.get<ApplicationsListDto>(`${config.apiRoutes.APPLICATIONS}/${jobOfferId}`, cancelToken);
    };

    createJobOfferAsync = (
        values: CreateJobOfferRequestObject["jobOfferDetails"] & { businessCard: CreateJobOfferRequestObject["businessCard"] },
        cancelToken?: CancelToken,
    ): Promise<CreateJobOfferResponseDto> => {
        return this.post<CreateJobOfferRequestObject["jobOfferDetails"] & { businessCard: CreateJobOfferRequestObject["businessCard"] }, CreateJobOfferResponseDto>(
            config.apiRoutes.OFFERS, values,
            cancelToken,
        );
    };

    saveJobOfferAsync = (formData: FormData, cancelToken?: CancelToken): Promise<void> => {
        return this.post<FormData, void>(config.apiRoutes.CREATE_PARTIAL_OFFER, formData, cancelToken);
    };

    sendDedicationAsync = (data: JobOfferDedicationDTO, cancelToken?: CancelToken): Promise<void> => {
        return this.post<JobOfferDedicationDTO, void>(config.apiRoutes.SEND_DEDICATION, data, cancelToken);
    };

    getJobOfferDetailsAsync = (id: number | string, cancelToken?: CancelToken): Promise<JobOfferDetailsResponseDto> => {
        return this.get<JobOfferDetailsResponseDto>(`${config.apiRoutes.OFFERS}/${id}` + "?location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale, cancelToken);
    };

    getJobOfferCardDetailsAsync = (id: number, cancelToken?: CancelToken): Promise<JobOfferCardDetailsResponseDto> => {
        return this.get<JobOfferCardDetailsResponseDto>(
            `${config.apiRoutes.JOB_OFFER_CARD_DETAILS}/${id}`,
            cancelToken,
        );
    };

    sendJobOfferMessageAsync = async (
        conversationId: string,
        message: string,
        cancelToken?: CancelToken,
    ): Promise<void> => {
        return this.post(
            `${config.apiRoutes.MESSAGES}`,
            { message, conversationId },
            cancelToken,
        );
    };

    sendJobOfferApplicationAsync = async (
        formData: ApplicationFormValues,
        selectedFile: File | undefined,
        offerId: number,
        cancelToken?: CancelToken,
    ): Promise<boolean> => {
        let uploadedFile = undefined;

        if (selectedFile) {
            const fileForm = new FormData();
            fileForm.append("file", selectedFile);
            uploadedFile = await this.post<FormData, { data: string }>(
                config.apiRoutes.UPLOAD_FILE,
                fileForm,
                cancelToken,
            );
        }

        if (formData.applicantsNumber && formData.applicationType) {
            return this.post<{ businessCardId: number; content?: string; file?: string }, boolean>(
                `${config.apiRoutes.OFFERS_SEND_GROUP_APPLICATION}/${offerId}/apply`,
                { ...formData, file: uploadedFile?.data },
                cancelToken,
            );
        }

        return this.post<{ businessCardId: number; content?: string; file?: string }, boolean>(
            `${config.apiRoutes.OFFERS_SEND_INDIVIDUAL_APPLICATION}/${offerId}/apply`,
            { ...formData, file: uploadedFile?.data },
            cancelToken,
        );
    };

    endOfferAsync = async (id: number, offerType: OfferType, cancelToken?: CancelToken): Promise<void> => {
        return this.put<void, void>(
            `${offerType === OfferType.Job ? config.apiRoutes.OFFERS : config.apiRoutes.EMPLOYEE_OFFERS}/${id}/${offerType === OfferType.Job ? config.apiRoutes.END_JOB_OFFER : config.apiRoutes.END_EMPLOYEE_OFFER
            }`,
            undefined,
            cancelToken,
        );
    };

    getApplicationDetailsAsync = async (
        id: number,
        isOfferOwner: boolean,
        cancelToken?: CancelToken,
    ): Promise<ApplicationDetailsDto> => {
        if (isOfferOwner) {
            return this.get<ApplicationDetailsDto>(`${config.apiRoutes.APPLICATIONS}/${id}/details`, cancelToken);
        }

        return this.get<ApplicationDetailsDto>(`${config.apiRoutes.APPLICATIONS}/my-applications/${id}/details`);
    };

    acceptApplicationAsync = async (
        id: number,
        acceptedApplicantsNumber?: number,
        message?: string,
        cancelToken?: CancelToken,
    ): Promise<void> => {
        const queryParams = [];

        if (acceptedApplicantsNumber) {
            queryParams.push({ acceptedApplicantsNumber });
        }
        if (message) {
            queryParams.push({ message });
        }

        const parsedQueryParams = queryParams.reduce((prev, curr) => {
            if (!prev) {
                prev += "?";
            } else {
                prev += "&";
            }

            prev += `${Object.keys(curr)[0]}=${Object.values(curr)[0]}`;

            return prev;
        }, "");

        return this.post<void, void>(
            `${config.apiRoutes.APPLICATIONS}/${id}/accept${parsedQueryParams}`,
            undefined,
            cancelToken,
        );
    };

    rejectApplicationAsync = async (id: number, message?: string, cancelToken?: CancelToken): Promise<void> => {
        return this.post<void, void>(
            `${config.apiRoutes.APPLICATIONS}/${id}/reject${message ? `?message=${message}` : ""}`,
            undefined,
            cancelToken,
        );
    };

    acceptArrivalAsync = async (id: number, message?: string, cancelToken?: CancelToken): Promise<void> => {
        return this.post<void, void>(
            `${config.apiRoutes.APPLICATIONS}/${id}/accept-arrival${message ? `?message=${message}` : ""}`,
            undefined,
            cancelToken,
        );
    };

    giveBonusAsync = async (id: number, message?: string, cancelToken?: CancelToken): Promise<void> => {
        return this.post<{ message: string, jobApplicationId: number }, void>(
            `${config.apiRoutes.FENIGE_SEND_MONEY}`,
            {
                message: message || "",
                jobApplicationId: id,
            },
            cancelToken,
        );
    };

    getPaidBonusStatus = (jobApplicationId: number): Promise<string> => {
        return this.get<string>(`${config.API_URL}${config.apiRoutes.GETBONUS_STATUS}/${jobApplicationId}`);
    };

    uploadUserPhotos = (params: FormData, cancelToken?: CancelToken): Promise<PhotoDto[]> => {
        return this.post<FormData, PhotoDto[]>(config.apiRoutes.IMAGE_UPLOAD, params, cancelToken);
    };

    getCardDetailsAsync = (id: string, cancelToken?: CancelToken): Promise<CardDetailsDto> => {
        return this.get<CardDetailsDto>(`${config.apiRoutes.BUSINESS_CARDS}/${id}` + "?location_language=" + getCookie(cookieNames.LOCALE) ?? defaultLocale, cancelToken);
    };

    updateMyProfileCardAsync = (
        cardDetails: Partial<CardDto>,
        id: number,
        cancelToken?: CancelToken,
    ): Promise<CardDetailsDto> => {
        return this.put(`${config.apiRoutes.BUSINESS_CARDS}/${id}`, cardDetails, cancelToken);
    };

    createConversationAsync = (offerId: number, cancelToken?: CancelToken): Promise<CreateConversationDto> => {
        return this.post(`${config.apiRoutes.CONVERSATIONS}`, { offerId }, cancelToken);
    };

    startFenigePaymentAsync = (
        offerId: number,
        cardId: string,
        cvc2: string,
        purchaseAmount: string,
        browserScreenWidth: number,
        browserScreenHeight: number,
        browserTimeZone: number,
        browserLanguage: string,
        userFVInfo: object,
    ): Promise<FenigePaymentStatus> => {
        return this.post(`${config.apiRoutes.FENIGE_AUTHENTICATION}/auth`, {
            JobOfferId: offerId,
            browserLanguage,
            browserScreenHeight,
            browserScreenWidth,
            browserTimeZone,
            cardId,
            purchaseAmount,
            purchaseCurrency: "PLN",
            terminalUuid: "82f6b6a2-c83d-46db-8878-bf211a2ed52e",
            cvc2,
            testAmount: 0,
            userFVInfo: userFVInfo,
        });
    };

    checkFenigePaymentStatusAsync = (authenticationProcessId: string): Promise<any> => {
        return this.get(`${config.apiRoutes.FENIGE_PAYMENT}/${authenticationProcessId}`);
    };

    getFenigeIndicatorRequest = (authenticationProcessId: string, duration: number): Promise<any> => {
        return this.post(`${config.apiRoutes.FENIGE_AUTHENTICATION}/indicator-request`, {
            authenticationProcessId,
            duration,
        });
    };

    getFenigePriceAsync = (offerId: string, cancelToken?: CancelToken): Promise<any> => {
        return this.get(`${config.apiRoutes.PRICE_CALCULATE}/fenige/${offerId}`, cancelToken);
    };
}

export default new JobOffersApi();
