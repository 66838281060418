export enum RoleType {
    Employee,
    Employer,
}

export enum TopicType {
    Platform,
    Bonus,
    Complaint,
    SideEffects,
    HowTo,
    Others,
}

export interface ContactFormFormValues {
    role: string;
    topic: string;
    message: string;
}
