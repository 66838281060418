import adminPanelIcon from "assets/icons/Dropdown/adminPanel.svg";
import moderatorPanelIcon from "assets/icons/Dropdown/moderatorPanel.svg";
import favouriteOffersIcon from "assets/icons/Dropdown/favouriteOffers.svg";
import myApplicationsIcon from "assets/icons/Dropdown/myApplications.svg";
import myEmployeeOffersIcon from "assets/icons/Dropdown/myEmployeeOffers.svg";
import myJobOffersIcon from "assets/icons/Dropdown/myJobOffers.svg";
import myProfileIcon from "assets/icons/Dropdown/myProfile.svg";
import signOutIcon from "assets/icons/Dropdown/signOut.svg";
// import EnvelopeIcon from "assets/icons/envelope.svg";
import { ReactComponent as EnvelopeIcon } from "assets/icons/envelope.svg";
import MessagesIcon from "assets/icons/notifications.svg";
import RedDotCounter from "features/common/components/RedDotCounter";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { appRoutes } from "features/routing/routes";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";

interface Props {
    userProfileExists: boolean;
    isUserLoggedIn?: boolean;
    isAdministrator?: boolean;
    isModerator?: boolean;
    unreadMessagesCount?: number;
    ["data-testid"]?: string;
    onClickAway: () => void;
    logoutUserAsync: () => void;
}

//to-delete
const Dropdown = (props: Props) => {
    const {
        userProfileExists,
        isUserLoggedIn,
        isAdministrator,
        isModerator,
        unreadMessagesCount,
        "data-testid": testId = "header-dropdown",
        onClickAway,
        logoutUserAsync,
    } = props;

    const deviceClass = useDeviceClass();

    const isUserLoggedInWithoutProfile = !!isUserLoggedIn && !userProfileExists;

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <ul data-testid={testId} className={styles["dropdown"]} onClick={onClickAway}>
                {!isUserLoggedIn && <p></p>}
                {isUserLoggedInWithoutProfile && (
                    <li data-testid={`${testId}__sign-out-button`} onClick={() => logoutUserAsync()}>
                        <Link to={"#"} className={styles["dropdown__item"]}>
                            <FormattedMessage id="dropdown__sign-out" />
                        </Link>
                    </li>
                )}
                {!isUserLoggedInWithoutProfile && (
                    <>
                        <DropdownItem
                            to={appRoutes.myProfile}
                            icon={myProfileIcon}
                            translationId="common__my-profile"
                            isUserLoggedIn={isUserLoggedIn}
                        />

                        <DropdownItem
                            to={appRoutes.myJobOffers}
                            icon={myJobOffersIcon}
                            translationId="common__my-job-offers"
                            isUserLoggedIn={isUserLoggedIn}
                        />

                        <DropdownItem
                            to={appRoutes.myEmployeeOffers}
                            icon={myEmployeeOffersIcon}
                            translationId="common__my-employee-offers"
                            isUserLoggedIn={isUserLoggedIn}
                        />

                        {/* {deviceClass !== "desktop" && (
                            <li data-testid={`${testId}__messages`}>
                                <NavLink
                                    to={appRoutes.myMessages}
                                    className={styles["dropdown__item"]}
                                    activeClassName={styles["dropdown__item--active"]}
                                >
                                    <EnvelopeIcon />
                                    <FormattedMessage id="common__my-messages" />
                                    <RedDotCounter count={unreadMessagesCount} />
                                </NavLink>
                            </li>
                        )} */}

                        {/* <DropdownItem
                            to={appRoutes.favouriteOffers}
                            icon={favouriteOffersIcon}
                            translationId='common__favourite-offers'
                            isUserLoggedIn={isUserLoggedIn} /> */}

                        <DropdownItem
                            to={appRoutes.myApplications}
                            icon={myApplicationsIcon}
                            translationId="common__my-applications"
                            isUserLoggedIn={isUserLoggedIn}
                        />

                        {isAdministrator && (
                            <li data-testid={`${testId}__admin-panel`}>
                                <NavLink
                                    to={appRoutes.adminPanel}
                                    className={styles["dropdown__item"]}
                                    activeClassName={styles["dropdown__item--active"]}
                                >
                                    <img src={adminPanelIcon} alt="admin panel" className={styles["dropdown__icon"]} />
                                    <FormattedMessage id="common__admin-panel" />
                                </NavLink>
                            </li>
                        )}

                        {(isAdministrator || isModerator) && (
                            <li>
                                <NavLink
                                    to={appRoutes.moderatorPanel}
                                    className={styles["dropdown__item"]}
                                    activeClassName={styles["dropdown__item--active"]}
                                >
                                    <img src={moderatorPanelIcon} alt="moderator panel" className={styles["dropdown__icon"]} />
                                    <FormattedMessage id="common__moderator-panel" />
                                </NavLink>
                            </li>
                        )}
                    </>
                )}
                {isUserLoggedIn && userProfileExists && (
                    <li data-testid={`${testId}__sign-out-button`} onClick={() => logoutUserAsync()}>
                        <Link to={"#"} className={styles["dropdown__item"]}>
                            <img src={signOutIcon} className={styles["dropdown__icon-sign-out"]} alt="signout" />
                            <FormattedMessage id="dropdown__sign-out" />
                        </Link>
                    </li>
                )}
            </ul>
        </ClickAwayListener>
    );
};

type DropdownItemProps = {
    to: string;
    icon: string;
    translationId: string;
    isUserLoggedIn?: boolean;
};

const DropdownItem = ({ to, icon, translationId, isUserLoggedIn }: DropdownItemProps) => {
    return (
        <li>
            {isUserLoggedIn ? (
                <NavLink
                    to={to}
                    className={styles["dropdown__item"]}
                    activeClassName={styles["dropdown__item--active"]}
                >
                    <img src={icon} className={styles["dropdown__icon"]} />
                    <FormattedMessage id={translationId} />
                </NavLink>
            ) : (
                <a href={appRoutes.login} className={styles["dropdown__item"]}>
                    <FormattedMessage id={translationId} />
                </a>
            )}
        </li>
    );
};

export default Dropdown;
