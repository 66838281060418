import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

import {
    selectUserOffers,
    selectUserOffersLoading,
    selectUserOffersError,
    selectTotalUserOffers,
    selectDeleteUserOfferSuccess,
    selectDeleteUserOfferLoading,
    selectDeleteUserOfferError,
} from "features/adminPanel/selectors";
import { getUserOffersAsync, deleteUserOfferAsync } from "features/adminPanel/thunks";

import UserOffers from "./component";

const mapStateToProps = (state: RootState) => ({
    userOffers: selectUserOffers(state),
    userOffersLoading: selectUserOffersLoading(state),
    userOffersError: selectUserOffersError(state),
    totalUserOffers: selectTotalUserOffers(state),
    deleteUserOfferSuccess: selectDeleteUserOfferSuccess(state),
    deleteUserOfferLoading: selectDeleteUserOfferLoading(state),
    deleteUserOfferError: selectDeleteUserOfferError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ getUserOffersAsync, deleteUserOfferAsync }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserOffers);
