import MyJobOfferDetailsComponent from "features/jobOffers/components/MyJobOfferDetails";
import { MyJobOfferDetailCategory } from "features/jobOffers/types";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface QueryParams {
    id: string;
    activeTab: string;
}
const MyJobOfferDetails = () => {
    const queryParams = useParams<QueryParams>();
    const activeTabNumber = queryParams.activeTab ? parseInt(queryParams.activeTab) : 0;
    const myJobOfferDetailCategory = MyJobOfferDetailCategory[activeTabNumber] ? activeTabNumber : 0;

    return (
        <div className={styles["my-job-offer-details"]}>
            <div className={styles["my-job-offer-details__inner-container"]}>
                {queryParams.id && (
                    <MyJobOfferDetailsComponent id={parseInt(queryParams.id!)} activeTab={myJobOfferDetailCategory} />
                )}
            </div>
        </div>
    );
};

export default MyJobOfferDetails;
