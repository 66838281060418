import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "store";
import { resetRateEmployeeState } from "features/rating/actions";
import {
    selectEmployerRatingSummaryLoading,
    selectEmployerRatingSummarySuccess,
    selectEmployerRatingSummaryError,
    selectEmployerRatingSummaryData
} from "features/rating/selectors";
import { getEmployerRateAsync } from "features/rating/thunks";

import { selectJobOfferDetails, selectJobOffersLoadingError, selectJobOfferDetailsLoading } from "features/jobOffers/selectors";
import { getJobOfferDetailsAsync } from "features/jobOffers/thunks";
import { selectUser } from 'features/user/selectors';
import RateEmployerSummary from "./component";

const mapStateToProps = (state: RootState) => ({
    jobOfferDetails: selectJobOfferDetails(state),
    jobOfferDetailsLoading: selectJobOfferDetailsLoading(state),
    jobOfferDetailsError: selectJobOffersLoadingError(state),
    employerRatingSummaryLoading: selectEmployerRatingSummaryLoading(state),
    employerRatingSummarySuccess: selectEmployerRatingSummarySuccess(state),
    employerRatingSummaryError: selectEmployerRatingSummaryError(state),
    user: selectUser(state),
    employerRatingSummaryData: selectEmployerRatingSummaryData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getJobOfferDetailsAsync,
            getEmployerRateAsync,
            resetRateEmployeeState: () => resetRateEmployeeState(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RateEmployerSummary);
