import { ApiError, CancelToken } from "api/utils";
import CallIcon from "assets/getCall/CallIcon";
import { ReactComponent as CalendarCheckIcon } from "assets/icons/calendarCheck.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import { ReactComponent as CalendarWithNumberIcon } from "assets/icons/calendarWithNumber.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as CoinsBanknoteIcon } from "assets/icons/coinsBanknote.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "assets/icons/peopleWithHand.svg";
import { ReactComponent as Send } from "assets/icons/send.svg";
import { ReactComponent as UploadFileIcon } from "assets/icons/uploadFileIcon.svg";
import config from "config";
import { format, isSameDay, isToday, isYesterday } from "date-fns";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import Button from "features/common/components/Button";
import Input from "features/common/components/Input";
import ModalWithContent from "features/common/components/ModalWithContent";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import UserImageWithPlaceholder from "features/common/components/UserImageWithPlaceholder";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDateFormat from "features/common/hooks/useDateFormat";
import {
    getBonusMessage,
    getHomeMessage,
    getMyMessagesMessage,
    getPositionsNumberMessage,
    getPublishedDateMessage,
    getValidUntilMessage,
    getWorkLocationMessage,
} from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import { isDateValid } from "features/common/utils";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { WithGetCallHOCComponentProps } from "features/getCall/types";
import ApplicationModal from "features/jobOffers/components/ApplicationModal";
import { JobOfferDetails } from "features/jobOffers/models";
import MessagesApi from "features/messages/api";
import SingleMessage from "features/messages/components/ConversationDetails/SingleMessage";
import { useLocaleInDateToUse } from "features/messages/hooks";
import { ConversationDetails } from "features/messages/models";
import CardImageWithBadge from "features/offers/components/SingleOfferCard/CardImageWithBadge";
import DetailRow from "features/offers/components/SingleOfferCard/DetailRow";
import { getSalaryMessage, getWorkTypeWithDatesMessage } from "features/offers/translationMessages";
import { appRoutes } from "features/routing/routes";
import { User } from "features/user/models";
/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import VideoPlayer from "../../../getCall/components/VideoPlayer";
import styles from "./styles.module.scss";

interface Params {
    id: string;
}

export interface ConversationDetailsProps extends WithGetCallHOCComponentProps {
    user?: User;
    conversationDetails?: ConversationDetails;
    conversationDetailsLoading: boolean;
    conversationDetailsError?: ApiError;
    sendMessageLoading: boolean;
    sendMessageSuccess?: boolean;
    sendMessageError?: ApiError;
    getConversationDetailsAsync: (id: string, cancelToken?: CancelToken) => void;
    sendMessageAsync: (id: string, message: string, cancelToken?: CancelToken) => void;
    cancelCallMessage: any;
}


const ConversationDetailsComponent = ({
    user,
    conversationDetails,
    conversationDetailsLoading,
    conversationDetailsError,
    sendMessageLoading,
    sendMessageSuccess,
    sendMessageError,
    getConversationDetailsAsync,
    sendMessageAsync,
    setStreamAction,
    setSocketAction,
    socket,
    setCallAction,
    call,
    callAccepted,
    rejectCall,
    answerCall,
    cancelCall,
    callUser,
    toggleStrangerVideo,
    toggleStrangerMicro,
}: ConversationDetailsProps) => {
    const { id } = useParams<Params>();
    const history = useHistory();
    const intl = useIntl();
    const localDate = useLocaleInDateToUse(intl.locale)
    const createCancelToken = useCreateCancelToken();
    const getFormattedDate = useDateFormat("d MMM yyyy", { locale: localDate });
    const [messageValue, setMessageValue] = useState("");
    const [selectedApplicationId, setSelectedApplicationId] = useState<number | undefined>();
    // Get call logic
    const [showGetCallModal, setShowGetCallModal] = useState<boolean>(false);
    const [showCallingModal, setShowCallingModal] = useState<boolean>(false);
    const [userOffline, setUserOffline] = useState<boolean>(false)
    const [callEndedState, setCallEndedState] = useState<boolean>(false)
    const [stopCallModal, setStopCallModal] = useState<boolean>(false)
    const [callDismissed, setCallDismissed] = useState<boolean>(false)
    const [cancelCallState, setCancelCallState] = useState(false)
    const [rememberedUsers, setRememberedUsers] = useState()
    const [userWhoDissmissed, setUserWhoDismissed] = useState()
    const { mediaDevices } = navigator;

    const streamCallback = useCallback(() => {
        mediaDevices.getUserMedia({ video: true, audio: true })
            .then((currentStream) => {
                setStreamAction && setStreamAction({ stream: currentStream })
            });
    }, []);

    useEffect(() => {
        if (user?.id && conversationDetails?.user.id) {
            setSocketAction && setSocketAction({ socket: io(config.GETCALL_URL, { transports: ["polling"], extraHeaders: { callerId: user.id, receiverId: conversationDetails.user.id, username: `${user.firstName} ${user.lastName}` } }) })
        }
    }, [user?.id, setSocketAction, conversationDetails?.user.id]);


    useEffect(() => {
        if (socket) {
            socket.on('callUser', ({ from, name: callerName, signal }) => {
                setCallAction && setCallAction({ call: { isReceivingCall: true, from, name: callerName, signal } })
                setCancelCallState(false)
            });
            socket.on('callDismiss', (name) => {
                setUserWhoDismissed(name)
                setCallDismissed(true)
                setShowCallingModal(false)
                if (!cancelCallState) {
                    setCancelCallState(true)
                }
                setTimeout(() => {
                    setCallDismissed(false)
                }, 2500)
            })
            socket.on('callAccepted', () => {
                setShowCallingModal(false);
            })
            socket.on('toggleStrangerVideo', () => {
                const userVideo = document.getElementById('userVideo') as HTMLVideoElement;
                userVideo.style.display === "none" ? userVideo.style.display = "block" : userVideo.style.display = "none"
            })
            socket.on('toggleStrangerMicro', () => {
                const userVideo = document.getElementById('userVideo') as HTMLVideoElement;
                userVideo.muted = !userVideo.muted
            })
            socket.on('callEnded', () => {
                rejectCall();
            });
            socket.on('callRejected', () => {
                rejectCall()
            })

            socket.on('callEndedUser', () => {
                setCallEndedState(true)
                setTimeout(() => {
                    rejectCall()
                    setShowCallingModal(false)
                    setCallEndedState(false)
                }, 2500)
            })
            socket.on('userOffline', () => {
                setUserOffline(true)
                setTimeout(() => {
                    setShowCallingModal(false)
                    setUserOffline(false)
                    window.location.reload()
                }, 2500)
            })

            socket.on('userStoppedCall', (nameOfOtherPerson) => {
                setRememberedUsers(nameOfOtherPerson)
                setStopCallModal(true)
                // Disable all videos and microphones
                const userVideo = document.getElementById('userVideo') as HTMLVideoElement;
                const myVideo = document.getElementById('myVideo') as HTMLVideoElement;
                userVideo.muted = true
                myVideo.style.display = "none"
                userVideo.style.display = "none"
                setTimeout(() => {
                    setStopCallModal(false) // It's about hiding <user XYZ ended the call>
                    window.location.reload();
                }, 1500)
            })
            socket.on('userCancelledCall', () => {
                setCancelCallState(true)
            })

        }
    }, [socket, setStreamAction, setCallAction, streamCallback]);

    useEffect(() => {
        if (showCallingModal || call.isReceivingCall) {
            streamCallback()
        }
    }, [showCallingModal, streamCallback, call.isReceivingCall]);

    useEffect(() => {
        if (!user) {
            history.push(`${appRoutes.login}?messageId=${id}`)
        }
    }, [user]);

    useEffect(() => {
        if (id && user) {
            getConversationDetailsAsync(id, createCancelToken());
        }
    }, [id, getConversationDetailsAsync, createCancelToken, user]);

    useEffect(() => {
        if (id && user) {
            MessagesApi.acknowledgeConversationAsync(id, createCancelToken());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, user])

    const homeMessage = getHomeMessage(intl);
    const myMessagesMessage = getMyMessagesMessage(intl);

    const salaryMessage = useMemo(
        () =>
            conversationDetails?.offerDetails.financialConditions
                ? getSalaryMessage(intl, conversationDetails?.offerDetails.financialConditions)
                : "",
        [conversationDetails, intl]
    );
    const workLocationText = useMemo(
        () =>
            `${conversationDetails?.offerDetails.location?.city ? `${conversationDetails?.offerDetails.location?.city}, ` : ""}${conversationDetails?.offerDetails.location?.country}`,
        [conversationDetails]
    );
    const workLocationMessage = getWorkLocationMessage(intl, workLocationText ?? undefined);
    const workTypeMessage = getWorkTypeWithDatesMessage(
        intl,
        conversationDetails?.offerDetails.workType,
        getFormattedDate((conversationDetails?.offerDetails as JobOfferDetails)?.startDate || (conversationDetails?.offerDetails as EmployeeOfferDetails)?.jobStartDate),
        getFormattedDate((conversationDetails?.offerDetails as JobOfferDetails)?.endDate || (conversationDetails?.offerDetails as EmployeeOfferDetails)?.jobStartDate)
    );
    const bonusMessage = getBonusMessage(
        intl,
        `${conversationDetails?.offerDetails.financialConditions.bonusValue} ${conversationDetails?.offerDetails.financialConditions.bonusCurrencyCode}`
    );
    const positionsNumberMessage = getPositionsNumberMessage(
        intl,
        conversationDetails?.offerDetails.availablePositionsNumber
    );
    const publishedDateMessage = getPublishedDateMessage(
        intl,
        getFormattedDate(conversationDetails?.offerDetails.addedDate)
    );
    const validUntilMessage = getValidUntilMessage(
        intl,
        getFormattedDate(conversationDetails?.offerDetails.expirationDate)
    );

    const handleDetailsClick = useCallback(() => {
        history.push(`${conversationDetails?.genusOffer === "EMPLOYEE" ? appRoutes.employeeOffers : appRoutes.jobOffers}/${conversationDetails?.offerDetails.id}`);
    }, [history, conversationDetails]);

    const handleMessageChange = useCallback((event) => setMessageValue(event.currentTarget.value), [setMessageValue]);

    const handleSendMessageClick = useCallback(() => {
        sendMessageAsync(id, messageValue);
    }, [sendMessageAsync, id, messageValue]);

    useEffect(() => {
        if (!sendMessageLoading && sendMessageSuccess) {
            getConversationDetailsAsync(id);
            setMessageValue("");
        }
    }, [getConversationDetailsAsync, id, sendMessageLoading, sendMessageSuccess]);

    const handleOpenApplicationDetailsModal = useCallback(
        (id) => setSelectedApplicationId(id),
        [setSelectedApplicationId]
    );
    const handleCloseApplicationDetailsModal = useCallback(
        () => setSelectedApplicationId(undefined),
        [setSelectedApplicationId]
    );

    const crumbs: BreadcrumbsItem[] = [
        {
            displayName: homeMessage,
            path: appRoutes.dashboard,
        },
        {
            displayName: myMessagesMessage,
            path: appRoutes.myMessages,
        },
        {
            displayName: conversationDetails?.user?.name || "",
            path: `${appRoutes.messages}/${id}`,
        },
    ];

    const handleInputKeyPress = useCallback(
        (event) => {
            if (event.keyCode === 13) {
                event.target.blur();
                handleSendMessageClick();
            }
        },
        [handleSendMessageClick]
    );

    const [file, setFile] = useState<undefined | FileList>(undefined);
    useEffect(() => {
        (async () => {
            if (file) {
                const { data: { resourceFilesName } } = await MessagesApi.uploadFile(file);
                setFile(undefined);
                await MessagesApi.sendMessageAsync(id, "", resourceFilesName);
                getConversationDetailsAsync(id, createCancelToken());
            }
        })();
    }, [createCancelToken, file, getConversationDetailsAsync, id])

    const checkCallHandler = () => {
        streamCallback()
        setShowGetCallModal(true);
    }

    const onCallHandler = () => {
        if (conversationDetails?.user.id) {
            callUser(conversationDetails?.user.id)
            streamCallback()
            setShowCallingModal(true);
            setShowGetCallModal(false);
            setCancelCallState(false)
        }
    }

    const onStopCallHandler = () => { // On Cancel your call
        cancelCall()
        socket && socket.emit("rejectCall", user)
        setShowCallingModal(false)
    }

    const onAcceptCallHandler = () => {
        answerCall();
    }

    const onDeclineCallHandler = () => { // Fires when Client declines OTHER Client call
        socket && socket.emit("rejectCall", user)
        rejectCall()
    }

    const onCloseModal = () => {
        setShowGetCallModal(false);
    }

    const onStopCallModal = () => {
        setStopCallModal(false)
    }

    return (
        <div className={styles["conversation-details"]}>
            {conversationDetailsLoading && (
                <SpinnerWithMessage
                    message={<FormattedMessage id="conversation-details__loading-conversation-details" />}
                />
            )}
            {conversationDetailsError ? (
                <div className={styles["conversation-details__error-container"]}>
                    <FormattedMessage id="conversation-details__fetch-failure" />
                </div>
            ) : (
                <>
                    <div className={styles["conversation-details__breadcrumbs-container"]}>
                        <Breadcrumbs data-testid="conversation-details__breadcrumbs" crumbs={crumbs} />
                    </div>
                    <div className={styles["conversation-details__user-container"]}>
                        {!!conversationDetails?.user.imageUrl && (
                            <UserImageWithPlaceholder
                                imageUrl={`${config.IMAGE_URL}/${conversationDetails?.user.imageUrl}`}
                                className={styles["conversation-details__user-image"]}
                            />
                        )}
                        <span className={styles["conversation-details__user-name"]}>
                            {conversationDetails?.user.name}
                        </span>
                    </div>
                    <div className={styles["conversation-details__data-container"]}>
                        <div className={styles["conversation-details__offer-container"]}>
                            <div className={styles["conversation-details__offer-container-title"]}>
                                {intl.formatMessage({ id: "conversation-details__offer-title" })}
                            </div>
                            <CardImageWithBadge
                                data-testid="conversation-details__card-image-with-badge"
                                photoBadge="status"
                                status={conversationDetails?.offerDetails.status}
                                imageUrl={`${config.IMAGE_URL}/${conversationDetails?.offerDetails.mainImage}`}
                                offerType={OfferType.Job}
                                className={styles["conversation-details__offer-image"]}
                            />
                            <div className={styles["conversation-details__offer-details-container"]}>
                                <div className={styles["conversation-details__offer-business-name"]}>
                                    {(conversationDetails?.offerDetails as JobOfferDetails)?.businessName || (conversationDetails?.offerDetails as EmployeeOfferDetails)?.employeeName}
                                </div>
                                <div className={styles["conversation-details__offer-position"]}>
                                    {conversationDetails?.offerDetails.position}
                                </div>
                                <DetailRow
                                    className={styles["conversation-details__offer-detail-row"]}
                                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                    data-testid="conversation-details__location"
                                    icon={<LocationMarkerIcon />}
                                    message={workLocationMessage}
                                />
                                <DetailRow
                                    className={styles["conversation-details__offer-detail-row"]}
                                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                    data-testid="conversation-details__work-type"
                                    isEllipsis={false}
                                    icon={<CalendarIcon />}
                                    message={workTypeMessage}
                                />
                                <DetailRow
                                    className={styles["conversation-details__offer-detail-row"]}
                                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                    data-testid="conversation-details__positions-number"
                                    icon={<PeopleWithHandIcon />}
                                    message={positionsNumberMessage}
                                />
                                <DetailRow
                                    className={styles["conversation-details__offer-detail-row"]}
                                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                    data-testid="conversation-details__salary"
                                    icon={<CoinsIcon />}
                                    message={salaryMessage}
                                />
                                {bonusMessage && (
                                    <DetailRow
                                        className={styles["conversation-details__offer-detail-row"]}
                                        iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                        data-testid="conversation-details__bonus"
                                        icon={<CoinsBanknoteIcon />}
                                        message={bonusMessage}
                                    />
                                )}
                                <DetailRow
                                    className={styles["conversation-details__offer-detail-row"]}
                                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                    data-testid="conversation-details__added-date"
                                    icon={<CalendarCheckIcon />}
                                    message={publishedDateMessage}
                                />
                                <DetailRow
                                    className={styles["conversation-details__offer-detail-row"]}
                                    iconClassName={styles["conversation-details__offer-detail-row-icon"]}
                                    data-testid="conversation-details__expiration-date"
                                    icon={<CalendarWithNumberIcon />}
                                    message={validUntilMessage}
                                />
                            </div>
                            <div
                                className={styles["conversation-details__details-button"]}
                                onClick={handleDetailsClick}
                            >
                                {intl.formatMessage({ id: "conversation-details__offer-details" })}
                                <ChevronRight />
                            </div>
                        </div>
                        <div className={styles["conversation-details__messages-container"]}>
                            <div className={styles["conversation-details__messages-list"]}>
                                {conversationDetails?.messages.map((message, index) => (
                                    <>
                                        {index !== 0 &&
                                            isDateValid(message.timestamp) &&
                                            isDateValid(conversationDetails?.messages[index - 1].timestamp) &&
                                            !isSameDay(
                                                conversationDetails?.messages[index - 1].timestamp,
                                                message.timestamp
                                            ) ? (
                                            isToday(conversationDetails?.messages[index - 1].timestamp) ? (
                                                <div className={styles["conversation-details__message-date"]}>
                                                    {intl.formatMessage({ id: "common__today" })}
                                                </div>
                                            ) : isYesterday(conversationDetails?.messages[index - 1].timestamp) ? (
                                                <div className={styles["conversation-details__message-date"]}>
                                                    {intl.formatMessage({ id: "common__yesterday" })}
                                                </div>
                                            ) : (
                                                <div className={styles["conversation-details__message-date"]}>
                                                    {format(
                                                        conversationDetails?.messages[index - 1].timestamp,
                                                        "d MMM yyyy",
                                                        // tutaj
                                                        { locale: localDate }
                                                    )}
                                                </div>
                                            )
                                        ) : null}
                                        <SingleMessage
                                            {...message}
                                            jobOfferId={conversationDetails?.offerDetails.id}
                                            userName={
                                                message.userId !== conversationDetails.user.id
                                                    ? `${user?.firstName} ${user?.lastName?.[0]}.`
                                                    : `${conversationDetails.user.name.split(" ")[0]} ${conversationDetails.user.name.split(" ")[1]?.[0]
                                                    }.`
                                            }
                                            isCurrentUser={message.userId !== conversationDetails.user.id}
                                            displayUserHeader={
                                                conversationDetails?.messages[index + 1]?.userId !== message.userId ||
                                                !isSameDay(
                                                    conversationDetails?.messages[index + 1]?.timestamp,
                                                    message.timestamp
                                                )
                                            }
                                            handleOpenApplicationDetailsModal={handleOpenApplicationDetailsModal}
                                        />
                                    </>
                                ))}
                                <div className={styles["conversation-details__message-date"]}>
                                    {conversationDetails?.messages[conversationDetails?.messages.length - 1]
                                        .timestamp &&
                                        isDateValid(
                                            conversationDetails?.messages[conversationDetails?.messages.length - 1]
                                                .timestamp
                                        ) &&
                                        format(
                                            conversationDetails?.messages[conversationDetails?.messages.length - 1]
                                                .timestamp,
                                            "d MMM yyyy",
                                            { locale: localDate }
                                        )}
                                </div>
                            </div>
                            <div className={styles["conversation-details__bottom-container"]}>
                                <label className={styles['conversation-details__upload-icon']} htmlFor="fileUpload">
                                    <UploadFileIcon />
                                </label>
                                <input
                                    multiple
                                    onChange={(e) => {
                                        setFile(e.currentTarget.files ? e.currentTarget.files : undefined);
                                    }}
                                    className={styles['conversation-details__upload-input']}
                                    type="file"
                                    id="fileUpload" name="fileUpload"
                                    accept="application/gzip, .tar, .tar.bz2, .rtf,application/vnd.oasis.opendocument.text, .zip,.rar,.7zi, image/gif, image/png, image/jpeg, image/png, .xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"></input>
                                <Input
                                    data-testid="conversation-details__message-input"
                                    wrapperClassName={styles["conversation-details__message-input"]}
                                    placeholder={intl.formatMessage({ id: "conversation-details__write-message" })}
                                    onChange={handleMessageChange}
                                    value={messageValue}
                                    disabled={sendMessageLoading}
                                    isInvalid={!!sendMessageError}
                                    error={intl.formatMessage({ id: "conversation-details__write-message-error" })}
                                    onKeyDown={handleInputKeyPress}
                                    withValidation
                                    spacelessMessages
                                    passValue
                                />
                                <div onClick={checkCallHandler} className={styles["conversation-details__getcall-button"]}>
                                    <CallIcon stream />
                                </div>
                                <Button
                                    data-testid="conversation-details__message-button"
                                    className={styles["conversation-details__message-button"]}
                                    type="submit"
                                    isLoading={sendMessageLoading}
                                    disabled={!messageValue || sendMessageLoading}
                                    onClick={handleSendMessageClick}
                                >
                                    <Send />
                                    <FormattedMessage id="conversation-details__send-message" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!!selectedApplicationId && (
                <ApplicationModal
                    id={selectedApplicationId}
                    handleCloseModal={handleCloseApplicationDetailsModal}
                    isOfferOwner={conversationDetails?.offerDetails?.offerOwner?.userId === user?.id}
                />
            )}

            {showGetCallModal && (
                <ModalWithContent
                    header={
                        <div className={styles["conversation-details__getcall-modal-header"]}>
                            <CallIcon />
                            <span>
                                {intl.formatMessage({ id: "conversation-details__start-getcall" }, { name: conversationDetails?.user.name })}
                            </span>
                        </div>
                    }
                    content=''
                    secondaryButtonVariant='no-background-green'
                    primaryButtonVariant='no-background-red'
                    secondaryButtonContent={intl.formatMessage({ id: "common__call" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={onCloseModal}
                    secondaryAction={onCallHandler}
                    primaryAction={onCloseModal}
                />
            )}

            {showCallingModal && (
                <ModalWithContent
                    className={styles["conversation-details__getcall-calling-modal"]}
                    content={
                        <div className={styles["conversation-details__getcall-calling-modal-content"]}>
                            {userOffline ? <> <p className={styles["conversation-details__getcall-bolder-text"]}>{conversationDetails?.user.name} </p> <p> <FormattedMessage id="conversation-details__offline" /></p> <CallIcon /> </> : <>
                                {callEndedState ? <><p className={styles["conversation-details__getcall-bolder-text"]}>
                                    {conversationDetails?.user.name}</p>
                                    <FormattedMessage id="conversation-details__call-rejected" />
                                </>
                                    : <> <p>
                                        {intl.formatMessage({ id: "conversation-details__calling-to" })}
                                    </p>
                                        <p className={styles["conversation-details__getcall-bolder-text"]}>
                                            {conversationDetails?.user.name}
                                        </p> </>}
                                <div className={callEndedState ? styles["conversation-details__padding-bottom"] : ''}>
                                    <CallIcon />
                                </div>
                            </>}
                        </div>
                    }
                    primaryButtonVariant='no-background-red'
                    primaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={onStopCallHandler}
                    primaryAction={onStopCallHandler}
                    cancelButtonHidden
                    buttonsCentered
                    noButtons={callEndedState}
                />
            )}

            {call?.isReceivingCall && !callAccepted && !cancelCallState && (
                <ModalWithContent
                    className={styles["conversation-details__getcall-calling-modal"]}
                    content={
                        <div className={styles["conversation-details__getcall-calling-modal-content"]}>
                            <p className={styles["conversation-details__getcall-bolder-text"]}>
                                {call?.name}
                            </p>
                            <p>
                                {intl.formatMessage({ id: "conversation-details__calling-from" })}
                            </p>
                            <CallIcon />
                        </div>
                    }
                    secondaryButtonVariant='no-background-green'
                    primaryButtonVariant='no-background-red'
                    secondaryButtonContent={intl.formatMessage({ id: "common__answer" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__cancel" })}
                    onClose={onDeclineCallHandler}
                    primaryAction={onDeclineCallHandler}
                    secondaryAction={onAcceptCallHandler}
                    buttonsCentered
                />
            )}
            {callAccepted && (
                //@ts-ignore
                <VideoPlayer toggleStrangerVideo={toggleStrangerVideo} toggleStrangerMicro={toggleStrangerMicro} />
            )}

            {/* TODO: Make this modal visible */}
            {stopCallModal && <ModalWithContent
                className={styles["conversation-details__getcall-stop-call-modal"]}
                content={
                    <div className={styles["conversation-details__getcall-calling-modal-content"]}>
                        <p>
                            {intl.formatMessage({ id: "conversation-details__user-ended-call1" })}
                        </p>
                        <p className={styles["conversation-details__getcall-bolder-text"]}>
                            {rememberedUsers}
                        </p>
                        <p>
                            {intl.formatMessage({ id: "conversation-details__user-ended-call2" })}
                        </p>
                    </div>
                }
                onClose={onStopCallModal}
                noButtons
            />}
            {callDismissed && <ModalWithContent
                className={styles["conversation-details__getcall-stop-call-modal"]}
                content={
                    <div className={styles["conversation-details__getcall-calling-modal-content"]}>
                        <p className={styles["conversation-details__getcall-bolder-text"]}>
                            {userWhoDissmissed}
                        </p>
                        <p>
                            {intl.formatMessage({ id: "conversation-details__call-rejected" })}
                        </p>
                    </div>
                }
                onClose={onStopCallModal}
                noButtons
            />}
        </div>
    );
};

export default ConversationDetailsComponent;
