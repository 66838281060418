import { ApiError } from "api/utils";
import UserAction, {
    cancelAuthTokens,
    createUserProfile,
    getUser,
    refreshUserProfile,
    resetUserProfileCreated,
    setUserLoggedInState,
    loginUser,
    registerUser,
    logoutUser,
    verifyUser,
    getUserProfile,
    forgottenPassword,
    recoverPassword,
    forgottenPasswordreset
} from "features/user/actions";
import { mapUserDtoToUser, mapRegisterDtoToUser } from "features/user/mappers";
import { User } from "features/user/models";
import AuthStorageService from "services/authStorageService";
import produce from "immer";
import { getType } from "typesafe-actions";
import decode from "jwt-decode";

export interface UserState {
    user?: User;
    userId?: string;
    userLoading?: boolean;
    userLoadingError?: ApiError;
    userLoadingSuccess?: boolean;
    userProfileCreated?: boolean;
    userProfileCreating?: boolean;
    userProfileCreatingError?: ApiError;
    isUserLoggedIn?: boolean;
    isTokenCancelled?: boolean;
    cancelTokenLoading?: boolean;
    cancelTokenError?: ApiError;
    userTokenValidationLoading?: boolean;
    userTokenValidationError?: ApiError;
    loginUserLoading?: boolean;
    loginUserLoadingError?: ApiError;
    registerUserLoading?: boolean;
    registerUserLoadingError?: ApiError;
    verifyUserLaoding?: boolean;
    verifyUserLoadingSuccess?: boolean;
    verifyUserLoadingError?: ApiError;
    userProfile?: User;
    userProfileLoading?: boolean;
    userProfileError?: ApiError;
    forgottenPasswordLoading?: boolean;
    forgottenPasswordLoadingSuccess?: boolean;
    forgottenPasswordLoadingError?: ApiError;
    recoverPasswordLoading?: boolean;
    recoverPasswordLoadingSuccess?: boolean;
    recoverPasswordLoadingError?: ApiError;
}

const reducer = (state: UserState = {}, action: UserAction) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getUser.request):
                draft.userLoading = true;
                draft.user = undefined;
                draft.userLoadingError = undefined;
                draft.userLoadingSuccess = undefined;
                break;
            case getType(getUser.success):
                draft.user = { ...draft.user, ...mapUserDtoToUser(action.payload) };
                draft.userId = draft.user.id;
                draft.userLoading = false;
                draft.userLoadingError = undefined;
                draft.userLoadingSuccess = true;
                break;
            case getType(getUser.failure):
                draft.userLoading = false;
                draft.userLoadingError = action.payload;
                draft.userLoadingSuccess = false;
                break;
            case getType(loginUser.request):
                draft.loginUserLoading = true;
                draft.userId = undefined;
                draft.user = undefined;
                draft.isUserLoggedIn = false;
                draft.loginUserLoadingError = undefined;
                break;
            case getType(loginUser.success):
                draft.loginUserLoading = false;
                draft.isUserLoggedIn = true;
                draft.loginUserLoadingError = undefined;
                AuthStorageService.setTokens({
                    accessToken: action.payload.token,
                    idToken: action.payload.idToken,
                    refreshToken: action.payload.refreshToken,
                    accessTokenExpiresIn: new Date().getTime() + action.payload.expiresIn * 1000,
                    refreshTokenExpiresIn: new Date().getTime() + action.payload.refreshExpiresIn * 1000,
                });
                const decodedToken: { sub: string } = decode(action.payload.token);
                draft.userId = decodedToken.sub;
                break;
            case getType(loginUser.failure):
                draft.loginUserLoading = false;
                draft.loginUserLoadingError = action.payload;
                draft.isUserLoggedIn = false;
                break;
            case getType(registerUser.request):
                draft.registerUserLoading = true;
                draft.userId = undefined;
                draft.user = undefined;
                draft.isUserLoggedIn = false;
                draft.registerUserLoadingError = undefined;
                break;
            case getType(registerUser.success):
                draft.registerUserLoading = false;
                draft.user = { ...draft.user, ...mapRegisterDtoToUser(action.payload) };
                draft.registerUserLoadingError = undefined;
                break;
            case getType(registerUser.failure):
                draft.registerUserLoading = false;
                draft.registerUserLoadingError = action.payload;
                draft.isUserLoggedIn = false;
                break;
            case getType(createUserProfile.request):
                draft.userProfileCreating = true;
                draft.userProfileCreated = false;
                break;
            case getType(createUserProfile.success):
                draft.userProfileCreating = false;
                draft.userProfileCreated = true;
                break;
            case getType(createUserProfile.failure):
                draft.userProfileCreating = false;
                draft.userProfileCreated = false;
                draft.userProfileCreatingError = action.payload;
                break;
            case getType(resetUserProfileCreated):
                draft.userProfileCreated = false;
                break;
            case getType(setUserLoggedInState):
                draft.isUserLoggedIn = action.payload;
                break;
            case getType(cancelAuthTokens.request):
                draft.cancelTokenLoading = true;
                draft.isTokenCancelled = false;
                break;
            case getType(cancelAuthTokens.success):
                draft.cancelTokenLoading = false;
                draft.isTokenCancelled = true;
                break;
            case getType(cancelAuthTokens.failure):
                draft.cancelTokenLoading = false;
                draft.isTokenCancelled = false;
                draft.cancelTokenError = action.payload;
                break;
            case getType(refreshUserProfile):
                draft.user = { ...draft.user, ...mapUserDtoToUser(action.payload) };
                break;
            // TODO: MOVE TO SEPARATE MODULE
            //FORGOT PASSWORD
            case getType(forgottenPassword.request):
                draft.forgottenPasswordLoading = true;
                break;
            case getType(forgottenPassword.success):
                draft.forgottenPasswordLoading = false;
                draft.forgottenPasswordLoadingSuccess = true;
                break;
            case getType(forgottenPassword.failure):
                draft.forgottenPasswordLoading = false;
                draft.forgottenPasswordLoadingError = action.payload;
                break;
            case getType(forgottenPasswordreset):
                draft.forgottenPasswordLoading = undefined;
                draft.forgottenPasswordLoadingSuccess = undefined;
                draft.forgottenPasswordLoadingError = undefined;
                break;
            case getType(recoverPassword.request):
                draft.recoverPasswordLoading = true;
                break;
            case getType(recoverPassword.success):
                draft.recoverPasswordLoading = false;
                draft.recoverPasswordLoadingSuccess = true;
                break;
            case getType(recoverPassword.failure):
                draft.recoverPasswordLoading = false;
                draft.recoverPasswordLoadingError = action.payload;
                break;
            case getType(logoutUser.request):
                draft.user = undefined;
                draft.userId = undefined;
                draft.isUserLoggedIn = false;
                AuthStorageService.clearAuthStorage();
                break;
            case getType(verifyUser.request):
                draft.verifyUserLaoding = true;
                draft.verifyUserLoadingSuccess = undefined;
                draft.verifyUserLoadingError = undefined;
                break;
            case getType(verifyUser.success):
                draft.verifyUserLaoding = false;
                draft.verifyUserLoadingSuccess = true;
                draft.verifyUserLoadingError = undefined;
                break;
            case getType(verifyUser.failure):
                draft.verifyUserLaoding = false;
                draft.verifyUserLoadingSuccess = false;
                draft.verifyUserLoadingError = action.payload;
                break;
            case getType(getUserProfile.request):
                draft.userProfileLoading = true;
                draft.userProfile = undefined;
                draft.userProfileError = undefined;
                break;
            case getType(getUserProfile.success):
                draft.userProfileLoading = false;
                draft.userProfile = mapUserDtoToUser(action.payload);
                draft.userProfileError = undefined;
                break;
            case getType(getUserProfile.failure):
                draft.userProfileLoading = false;
                draft.userProfile = undefined;
                draft.userProfileError = action.payload;
                break;
        }
    });
};

export default reducer;
