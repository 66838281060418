/* eslint-disable max-lines */
import config from "config";
import CheckboxFiltersGroup, { CheckboxFiltersGrouper } from "features/common/components/CheckboxFiltersGroup";
import FormDropdown from "features/common/components/FormDropdown";
import JoinedInput from "features/common/components/JoinedInput";
import { monetaryAmountRegex } from "features/common/constants";
import { Currency, SalaryType } from "features/common/models";
import { getCurrencyMessage, getPrivatePersonMessage } from "features/common/translationMessages";
import { Filter, WorkType } from "features/common/types";
import { parseInteger } from "features/common/utils";
import { employerTypes } from "features/jobOffers/constants";
import { getEmployerCompanyMessage, getMinimumAmountMessage } from "features/jobOffers/translationMessages";
import { EmployerType, JobOffersFetchParams, JobOffersFilterMatchCounts } from "features/jobOffers/types";
import ControlButtons from "features/offers/components/Filters/ControlButtons";
import { useSalaryTypeDropdownOptions } from "features/offers/hooks";
import { getPaymentTypeMessage } from "features/offers/translationMessages";
import { getCurrenciesDropdownOptions } from "features/offers/utils";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

interface Props {
    offersCount?: number;
    totalOffers?: number;
    branches?: any[];
    workTypes?: Filter[];
    currencies?: Currency[];
    filterMatchCounts?: JobOffersFilterMatchCounts;
    isLoading: boolean;
    isLoadingError: boolean;
    offersLoading?: boolean;
    fetchParams: JobOffersFetchParams;
    collapse?: () => void;
    onFiltersStateChange: (params: JobOffersFetchParams) => void;
    resetPaginationState: () => void;
    asFavoriteEmployeeOrEmployer?: boolean;
}

const JobOffersFiltersView = ({
    offersCount,
    totalOffers,
    branches,
    workTypes,
    currencies,
    filterMatchCounts,
    fetchParams,
    isLoadingError,
    offersLoading,
    collapse,
    onFiltersStateChange,
    resetPaginationState,
    asFavoriteEmployeeOrEmployer
}: Props) => {
    const intl = useIntl();

    const paymentTypeMessage = getPaymentTypeMessage(intl);
    const minimumAmountMessage = getMinimumAmountMessage(intl);
    const currencyMessage = getCurrencyMessage(intl);
    const employerCompanyMessage = getEmployerCompanyMessage(intl);
    const employerPrivateMessage = getPrivatePersonMessage(intl);

    const currenciesOptions = getCurrenciesDropdownOptions(currencies);
    const salaryTypeDropdownOptions = useSalaryTypeDropdownOptions();

    const [lastDropdownOpen, setLastDropdownOpen] = useState(false);
    const [bottomPadding, setBottomPadding] = useState(0);

    let filtersSum = fetchParams.filters.branchId.length + fetchParams.filters.employerType.length + fetchParams.filters.workType.length

    const setMorePaddingHelperTest = useCallback(
        (expanded: boolean, lastDropdownClicked: boolean) => {
            const multiplier = lastDropdownClicked ? 4 : 2;

            const bottomPadding = currencies ? currencies?.length * multiplier : 2;

            setBottomPadding(bottomPadding);
            setLastDropdownOpen(expanded);
        },
        [setBottomPadding, setLastDropdownOpen, currencies]
    );

    const getSelectedSalaryTypeOption = useCallback(() => {
        for (const option of salaryTypeDropdownOptions) {
            if (fetchParams.filters.paymentRateType === option.value) {
                return option;
            }
        }
    }, [fetchParams.filters.paymentRateType, salaryTypeDropdownOptions]);

    const changeFilters = useCallback(
        (params: JobOffersFetchParams) => {
            const paramsWithResetOffset = {
                ...params,
                pageLimit: config.offersResultsPerPage,
                offset: 1,
                amendOffers: false,
            };
            resetPaginationState();
            onFiltersStateChange(paramsWithResetOffset);
        },
        [resetPaginationState, onFiltersStateChange]
    );

    const onBranchCheckboxToggle = useCallback(
        (checked: boolean, id: number) => {
            const params = { ...fetchParams };
            if (checked) {
                params.filters.branchId.push(id);
            } else {
                const indexOfFilterToRemove = params.filters.branchId.indexOf(id);
                if (indexOfFilterToRemove >= 0) {
                    params.filters.branchId.splice(indexOfFilterToRemove, 1);
                }
            }
            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onWorkTypeCheckboxToggle = useCallback(
        (checked: boolean, id: WorkType) => {
            const params = { ...fetchParams };

            if (checked) {
                params.filters.workType.push(id);
            } else {
                const indexOfFilterToRemove = params.filters.workType.indexOf(id);
                if (indexOfFilterToRemove >= 0) {
                    params.filters.workType.splice(indexOfFilterToRemove, 1);
                }
            }
            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onEmployerTypeCheckboxToggle = useCallback(
        (checked: boolean, code: EmployerType) => {
            const params = { ...fetchParams };

            if (checked) {
                params.filters.employerType.push(code);
            } else {
                const indexOfFilterToRemove = params.filters.employerType.indexOf(code);
                if (indexOfFilterToRemove >= 0) {
                    params.filters.employerType.splice(indexOfFilterToRemove, 1);
                }
            }

            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onSalaryTypeSelect = useCallback(
        (workTypeId: SalaryType) => {
            const params = { ...fetchParams };
            params.filters.paymentRateType = workTypeId;
            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onminimalSalaryValueChange = useCallback(
        (rawValue: string) => {
            const value = parseFloat(rawValue);

            const params = { ...fetchParams };
            params.filters.minimalSalaryValue = value;
            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onMinimalBonusValueChange = useCallback(
        (rawValue: string) => {
            const value = parseInteger(rawValue);

            if (value !== null) {
                const params = { ...fetchParams };
                params.filters.minimalBonusValue = value;

                changeFilters(params);
            }
        },
        [fetchParams, changeFilters]
    );

    const onMinimalSalaryCurrencySelect = useCallback(
        (currencyName: string) => {
            const params = { ...fetchParams };

            if (currencyName === params.filters.minimalSalaryCurrencyCode) {
                params.filters.minimalSalaryCurrencyCode = undefined;
            } else {
                params.filters.minimalSalaryCurrencyCode = currencyName;
            }

            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    const onMinimalBonusCurrencyCodeSelect = useCallback(
        (currencyName: string) => {
            const params = { ...fetchParams };

            if (currencyName === params.filters.minimalBonusCurrencyCode) {
                params.filters.minimalBonusCurrencyCode = undefined;
            } else {
                params.filters.minimalBonusCurrencyCode = currencyName;
            }

            changeFilters(params);
        },
        [fetchParams, changeFilters]
    );

    // const onDateSelect = useCallback(
    //     (date?: Date) => {
    //         const params = { ...fetchParams };
    //         params.filters.startDate = date;
    //         changeFilters(params);
    //     },
    //     [fetchParams, changeFilters]
    // );

    const onClearFiltersClick = useCallback(() => {
        resetPaginationState();
        onFiltersStateChange({
            ...fetchParams,
            filters: {
                branchId: [],
                workType: [],
                employerType: [],
            },
            amendOffers: false,
            offset: 1,
        });
    }, [fetchParams, onFiltersStateChange, resetPaginationState]);

    const mappedBranches = branches?.map(branchItem => branchItem?.branches as any);

    return (
        <div className={styles["filters-view"]}>
            {isLoadingError ? (
                <div className={styles["filters-view__loading-error"]}>
                    <FormattedMessage id="common__loading-error" />
                </div>
            ) : (
                <div
                    className={styles["filters-view--filters-container"]}
                    style={lastDropdownOpen ? { paddingBottom: `${bottomPadding}em` } : {}}
                >
                    <CheckboxFiltersGrouper
                        header={<FormattedMessage id="filters__branch" />}
                        items={mappedBranches!}
                        matchCounts={filterMatchCounts?.byBranch ?? []}
                        isFilterChecked={(id) => fetchParams.filters?.branchId?.indexOf(+id) >= 0}
                        onToggle={(checked, id) => onBranchCheckboxToggle(checked, +id)}
                    />
                    {!asFavoriteEmployeeOrEmployer && (
                        <>
                            <CheckboxFiltersGroup
                                header={<FormattedMessage id="common__work-type" />}
                                filters={workTypes?.map((workType) => workType.id as WorkType) ?? []}
                                filterLabels={
                                    workTypes?.map((workType) =>
                                        intl.formatMessage({ id: `work-type__${workType.id}` })
                                    ) ?? []
                                }
                                matchCounts={filterMatchCounts?.byWorkType ?? []}
                                className={styles["filters-view__group"]}
                                isFilterChecked={(id: WorkType) => fetchParams.filters?.workType?.indexOf(id) >= 0}
                                onToggle={(checked, id) => onWorkTypeCheckboxToggle(checked, id)}
                            />
                            <CheckboxFiltersGroup
                                header={<FormattedMessage id="filters-view__employer-type" />}
                                filters={employerTypes}
                                filterLabels={employerTypes.map((type) =>
                                    type === EmployerType.Company ? employerCompanyMessage : employerPrivateMessage
                                )}
                                matchCounts={filterMatchCounts?.byEmployerType ?? []}
                                className={styles["filters-view__group"]}
                                isFilterChecked={(id) => fetchParams.filters?.employerType?.indexOf(id) >= 0}
                                onToggle={(checked, id) => onEmployerTypeCheckboxToggle(checked, id)}
                            />
                            {/* <div className={styles["filters-view__group"]}>
                        <h3 className={styles["filters-view__non-checkbox-group-header"]}>{startDateMessage}</h3>
                        <Datepicker
                            id="filters-view__start-date-datepicker"
                            date={fetchParams.filters?.startDate}
                            label={startDateMessage}
                            placeholder={startDateMessage}
                            onDateSelect={onDateSelect}
                        />
                    </div> */}
                            <div className={styles["filters-view__group"]}>
                                <h3 className={styles["filters-view__non-checkbox-group-header"]}>
                                    <FormattedMessage id="common__salary" />
                                </h3>
                                <FormDropdown
                                    id="common__salary-type-dropdown"
                                    options={salaryTypeDropdownOptions}
                                    selected={getSelectedSalaryTypeOption()}
                                    placeholder={paymentTypeMessage}
                                    label={paymentTypeMessage}
                                    onSelect={onSalaryTypeSelect}
                                />
                                <JoinedInput
                                    data-testid="filters-view__minimum-salary-input"
                                    id="filters-view__minimum-salary-input"
                                    inputType="number"
                                    className={styles["filters-view__minimum-salary-input"]}
                                    inputsContainerClassName={styles["filters-view__joined-input-container"]}
                                    valueInputLabel={minimumAmountMessage}
                                    unitInputLabel={currencyMessage}
                                    dropdownOptions={currenciesOptions}
                                    valueFieldValue={fetchParams.filters.minimalSalaryValue?.toString()}
                                    unitFieldValue={fetchParams.filters.minimalSalaryCurrencyCode}
                                    valueFieldSetValue={onminimalSalaryValueChange}
                                    unitFieldSetValue={onMinimalSalaryCurrencySelect}
                                    valueFieldPattern={`^${monetaryAmountRegex}$`}
                                    onToggle={(expanded: boolean) => setMorePaddingHelperTest(expanded, false)}
                                    helperMessages={[<FormattedMessage id="filters-view__amount-help-message" />]}
                                    inputContainerClassName={styles["filters-view__minimum-salary-input-container"]}
                                    formDropdownClassName={styles["filters-view__minimum-salary-input-dropdown"]}
                                />
                            </div>
                            <div className={styles["filters-view__group"]}>
                                <h3 className={styles["filters-view__non-checkbox-group-header"]}>
                                    <FormattedMessage id="filters-view__bonus-amount" />
                                </h3>
                                <JoinedInput
                                    data-testid="filters-view__minimum-bonus-input"
                                    id="filters-view__minimum-bonus-input"
                                    inputType="number"
                                    inputsContainerClassName={styles["filters-view__joined-input-container"]}
                                    valueInputLabel={minimumAmountMessage}
                                    unitInputLabel={currencyMessage}
                                    dropdownOptions={currenciesOptions}
                                    valueFieldValue={fetchParams.filters.minimalBonusValue?.toString()}
                                    unitFieldValue={fetchParams.filters.minimalBonusCurrencyCode}
                                    valueFieldSetValue={onMinimalBonusValueChange}
                                    unitFieldSetValue={onMinimalBonusCurrencyCodeSelect}
                                    onToggle={(expanded: boolean) => setMorePaddingHelperTest(expanded, true)}
                                    helperMessages={[<FormattedMessage id="filters-view__amount-help-message" />]}
                                    inputContainerClassName={styles["filters-view__minimum-bonus-input-container"]}
                                    formDropdownClassName={styles["filters-view__minimum-bonus-input-dropdown"]}

                                />
                            </div>
                            <ControlButtons
                                offersLoading={offersLoading}
                                offersCount={offersCount}
                                totalOffers={totalOffers}
                                collapse={collapse}
                                onClearFiltersClick={onClearFiltersClick}
                                filtersSum={filtersSum}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default JobOffersFiltersView;
