import { ReactComponent as ChevronDownIcon } from "assets/icons/chevronDown.svg";
import classNames from "classnames";
import Checkbox from "features/common/components/Checkbox";
import { FilterMatchCount } from "features/common/types";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";


const maxInitialFilterGroupSize = 5;
const maxInitialFilterGroups = 2;

export interface Props<T extends unknown> {
    header: string | React.ReactNode;
    items: any[];
    matchCounts: Array<FilterMatchCount<T>>;
    className?: string;
    isFilterChecked: (code: T) => boolean;
    onToggle: (checked: boolean, code: T) => void;
}


const CheckboxFiltersGrouper = <T extends unknown>({
    header,
    items,
    matchCounts,
    className,
    isFilterChecked,
    onToggle,
}: Props<T>) => {
    const intl = useIntl();
    const [isShowingMoreFilters, setIsShowingMoreFilters] = useState(false);

    const displayGroup = useCallback(
        (items) => {
            return items.map((item: any) => <Checkbox
                key={item.id}
                className={styles["checkbox-filters-group__checkbox"]}
                checked={isFilterChecked(item.id)}
                label={`${intl.formatMessage({id: item.name})} (${matchCounts.find((match) => match.code === item.id)?.matchCount ?? 0 })`}
                onToggle={(checked) => onToggle(checked, item.id)}
            />)
        },
        [intl, isFilterChecked, matchCounts, onToggle],
    );

    return (
        <div className={classNames(styles["checkbox-filters-group"], className)}>
            <h3
                className={styles["checkbox-filters-group__header"]}
            >
                {header}
            </h3>
            {items?.slice(0, isShowingMoreFilters ? undefined : maxInitialFilterGroups).map((filtersGroup) => (
                <div key={filtersGroup.key}>
                    {displayGroup(filtersGroup)}
                    <div style={{ marginBottom: "40px" }}></div>
                </div>
            ))}
            {(items?.length ?? 0) > maxInitialFilterGroups && (
                <div
                    className={styles["checkbox-filters-group__show-more"]}
                    onClick={() => setIsShowingMoreFilters((isShowingMore) => !isShowingMore)}
                >
                    <span>
                        {isShowingMoreFilters ? (
                            <FormattedMessage id="filters-view__show-less" />
                        ) : (
                            <FormattedMessage id="filters-view__show-more" />
                        )}
                    </span>
                    <ChevronDownIcon
                        className={classNames(styles["checkbox-filters-group__show-more-icon"], {
                            [styles["checkbox-filters-group__show-more-icon--rotated"]]: isShowingMoreFilters,
                        })}
                    />
                </div>
            )}
            <div style={{ marginBottom: "40px" }}></div>

        </div>
    );
};

export interface MainProps<T extends unknown> {
    header: string | React.ReactNode;
    filters: T[];
    filterLabels: string[];
    matchCounts: Array<FilterMatchCount<T>>;
    ["data-testid"]?: string;
    className?: string;
    isFilterChecked: (code: T) => boolean;
    onToggle: (checked: boolean, code: T) => void;
}

const CheckboxFiltersGroup = <T extends unknown>({
    header,
    filters,
    filterLabels,
    matchCounts,
    "data-testid": testId,
    className,
    isFilterChecked,
    onToggle,
}: MainProps<T>) => {
    const [isShowingMoreFilters, setIsShowingMoreFilters] = useState(false);

    return (
        <div data-testid={testId} className={classNames(styles["checkbox-filters-group"], className)}>
            <h3
                data-testid={testId ? `${testId}__header` : undefined}
                className={styles["checkbox-filters-group__header"]}
            >
                {header}
            </h3>
            {filters?.slice(0, isShowingMoreFilters ? undefined : maxInitialFilterGroupSize).map((filter, index) => (
                <Checkbox
                    data-testid={`${testId}__checkbox`}
                    key={index}
                    className={styles["checkbox-filters-group__checkbox"]}
                    checked={isFilterChecked(filter)}
                    label={`${filterLabels[index]} (${matchCounts.find((match) => match.code === filter)?.matchCount ?? 0
                        })`}
                    onToggle={(checked) => onToggle(checked, filter)}
                />
            ))}
            {(filters?.length ?? 0) > maxInitialFilterGroupSize && (
                <div
                    data-testid={`${testId}__show-more`}
                    className={styles["checkbox-filters-group__show-more"]}
                    onClick={() => setIsShowingMoreFilters((isShowingMore) => !isShowingMore)}
                >
                    <span>
                        {isShowingMoreFilters ? (
                            <FormattedMessage id="filters-view__show-less" />
                        ) : (
                            <FormattedMessage id="filters-view__show-more" />
                        )}
                    </span>
                    <ChevronDownIcon
                        className={classNames(styles["checkbox-filters-group__show-more-icon"], {
                            [styles["checkbox-filters-group__show-more-icon--rotated"]]: isShowingMoreFilters,
                        })}
                    />
                </div>
            )}
        </div>
    );
};

export { CheckboxFiltersGrouper };

export default CheckboxFiltersGroup;
