import { ReactComponent as GetBonusLogo } from "assets/images/getbonuslogo.svg";
import classNames from "classnames";
import Button from "features/common/components/Button";
import { cookieNames } from "features/cookies/constants";
import { appRoutes } from "features/routing/routes";
import { useMediaQuery } from "hooks/useMediaQuery";
import { Locale } from "lang";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import FavoriteNotification from "../FavoriteNotification";
import LanguageSwitch from "../LanguageSwitch";
import MessagesNotificationIcon from "../MessagesNotificationIcon";
import Notifications from "../Notifications";
import styles from "./styles.module.scss";
import config from "../../../../../config";

const cookies = new Cookies();

interface Props {
    closeDrawer: () => void;
    drawerOpen: boolean;
    locale: Locale;
    setLocale: (locale: Locale) => void;
    isUserLoggedIn?: boolean;
    unreadMessagesCount?: number;
}

const Drawer = ({ closeDrawer, drawerOpen, locale, setLocale, isUserLoggedIn, unreadMessagesCount }: Props) => {
    const history = useHistory();
    const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
    const toggleLanguageSelector = () => setLanguageSelectorOpen((prevState) => !prevState);
    const isMobileSize = useMediaQuery('(max-width: 834px)');



    const onRemoveCookiesOnNavigation = () => {
        cookies.remove(cookieNames.CURRENT_PAGE)
        cookies.remove(cookieNames.SCROLL_POSITION)
        cookies.remove(cookieNames.RESULTS_PER_PAGE)
    }

    const onLoginClick = useCallback(() => history.push(appRoutes.login), [history]);

    return (
        <div
            className={classNames(styles["drawer"], {
                [styles["drawer--open"]]: drawerOpen,
            })}
        >
            <ul className={styles["drawer__nav"]}>
                {isUserLoggedIn && isMobileSize && (
                    <>
                        <li>
                            <NavLink
                                onClick={() => {
                                    closeDrawer()
                                    onRemoveCookiesOnNavigation()
                                }}
                                to={appRoutes.myMessages}
                                className={classNames(styles["drawer__nav-item"])}
                                activeClassName={styles["drawer__nav-item--active"]}
                            >
                                <span className={styles["drawer__nav-item__img"]}>
                                    <MessagesNotificationIcon unreadMessagesCount={unreadMessagesCount} />
                                </span>
                                <FormattedMessage id="footer__messages" />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={() => {
                                    closeDrawer()
                                    onRemoveCookiesOnNavigation()
                                }}
                                to={appRoutes.notifications}
                                className={classNames(styles["drawer__nav-item"])}
                                activeClassName={styles["drawer__nav-item--active"]}
                            >
                                <span className={styles["drawer__nav-item__img"]}>
                                    <Notifications isMobile />
                                </span>
                                <FormattedMessage id="common__notifications" />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={() => {
                                    closeDrawer()
                                    onRemoveCookiesOnNavigation()
                                }}
                                to={appRoutes.favorite}
                                className={classNames(styles["drawer__nav-item"])}
                                activeClassName={styles["drawer__nav-item--active"]}
                            >
                                <span className={styles["drawer__nav-item__img"]}>
                                    <FavoriteNotification isMobile />
                                </span>
                                <FormattedMessage id="common__favourite" />
                            </NavLink>
                        </li>
                    </>
                )}

                <li>
                    <NavLink
                        onClick={() => {
                            closeDrawer()
                            onRemoveCookiesOnNavigation()
                        }}
                        to={appRoutes.jobOffers}
                        className={classNames(styles["drawer__nav-item"])}
                        activeClassName={styles["drawer__nav-item--active"]}
                    >
                        <FormattedMessage id="common__job-offers" />
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        onClick={() => {
                            closeDrawer()
                            onRemoveCookiesOnNavigation()
                        }}
                        to={appRoutes.employeeOffers}
                        className={classNames(styles["drawer__nav-item"])}
                        activeClassName={styles["drawer__nav-item--active"]}
                    >
                        <FormattedMessage id="common__for-employer" />
                    </NavLink>
                </li>
                <li>
                    <a
                        onClick={() => {
                            closeDrawer()
                            onRemoveCookiesOnNavigation()
                        }}
                        href={config.GETBONUS_URL}
                        className={classNames(styles["drawer__nav-item"])}
                        
                    >
                        <GetBonusLogo />
                    </a>
                </li>
                <li>
                    <LanguageSwitch
                        className={styles["drawer__language-switch"]}
                        locale={locale}
                        setLocale={setLocale}
                        variant="mobile"
                    />
                </li>
                {!isUserLoggedIn && <li>
                    <Button variant="dark" as="a" onClick={onLoginClick} className={styles["drawer__login-button"]}>
                        <p className={styles["drawer__item-description-login"]}>
                            <FormattedMessage id="common__sign-in" />
                        </p>
                    </Button>
                </li>}
            </ul>
        </div>
    );
};

export default Drawer;
