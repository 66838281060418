import { selectBranches } from "features/common/selectors";
import { getBranchesAsync } from "features/common/thunks";
import { resetJobOfferDetails } from "features/jobOffers/actions";
import {
    selectJobOfferDetails,
    selectJobOfferDetailsLoading,
    selectJobOfferDetailsLoadingError,
} from "features/jobOffers/selectors";
import { getJobOfferDetailsAsync } from "features/jobOffers/thunks";
import { selectPaymentCardData, selectPaymentCardDataError, selectPaymentCardDataLoading } from "features/myProfile/selectors";
import { getPaymentCardDataAsync } from "features/myProfile/thunks";
import { selectIsUserLoggedInWithProfile } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import JobOfferDetails from "./component";

const mapStateToProps = (state: RootState) => ({
    jobOfferDetails: selectJobOfferDetails(state),
    jobOfferDetailsLoading: selectJobOfferDetailsLoading(state),
    jobOfferDetailsLoadingError: selectJobOfferDetailsLoadingError(state),
    paymentCardData: selectPaymentCardData(state),
    paymentCardDataLoading: selectPaymentCardDataLoading(state),
    paymentCardDataError: selectPaymentCardDataError(state),
    isUserLoggedInWithProfile: selectIsUserLoggedInWithProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getJobOfferDetailsAsync,
            getPaymentCardDataAsync,
            resetJobOfferDetails: () => resetJobOfferDetails(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(JobOfferDetails);
