import useLoaded from "hooks/useLoaded";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Mute from "../../../../assets/icons/mute.svg";
import PlayAndPause from "../../../../assets/icons/playAndPause.svg";
import Replay from "../../../../assets/icons/replay.svg";
import Unmute from "../../../../assets/icons/unmute.svg";
import VideoScrDe from "./getmenDeLow.mp4";
import VideoSrcEn from "./getmenEnLow.mp4";
import VideoScrFr from "./getmenFrLow.mp4";
import VideoSrcNld from "./getmenNldLow.mp4";
import VideoSrcPl from "./getmenPlLow.mp4";
import VideoSrcRu from "./getmenRuLow.mp4";
import VideoSrcUa from "./getmenUaLow.mp4";

export default function TalkingHead() {
    const { loaded } = useLoaded();
    const videoRef = useRef<HTMLVideoElement>(null);
    const location = useLocation();
    const intl = useIntl()
    const [renderHead, setRenderHead] = useState(false);
    const [videoSrc, setVideoSrc] = useState(VideoSrcEn)

    useEffect(() => {
        switch (intl.locale) {
            case "pl":
                return setVideoSrc(VideoSrcPl);
            case "de":
                return setVideoSrc(VideoScrDe);
            case "ua":
                return setVideoSrc(VideoSrcUa);
            case "uk":
                return setVideoSrc(VideoSrcUa);
            case "ru":
                return setVideoSrc(VideoSrcRu);
            case "fr":
                return setVideoSrc(VideoScrFr);
            case "nl":
                return setVideoSrc(VideoSrcNld);
            default:
                return setVideoSrc(VideoSrcEn);
        }
    }, [intl.locale, VideoSrcPl, VideoScrDe, VideoSrcUa, VideoSrcRu, VideoScrFr, VideoSrcEn])

    useEffect(() => {
        if (location.key || loaded) {
            setRenderHead(true)
        }
    }, [loaded, location.key])

    useEffect(() => {
        const onFinish = () => {
            setVideoFinished(true)
        }
        if (videoRef?.current) {
            videoRef?.current.addEventListener('ended', onFinish, false);
        }
    }, [])

    const [videoMuted, setVideoMuted] = useState(false);
    const [videoFinished, setVideoFinished] = useState(true);
    const [showButtons, setShowButtons] = useState(false);

    return (
        <VideoWrapper onMouseEnter={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)} show={renderHead}>
            <Video
                playsInline
                muted={videoMuted}
                ref={videoRef}
                src={videoSrc}
            />
            {!videoFinished && showButtons && (
                <ButtonsWrapper>
                    <StyledPlayPauseButton onClick={() => videoRef.current?.paused ? videoRef.current.play() : videoRef.current?.pause()} src={PlayAndPause} alt='play or pause' />
                    <StyledButton
                        onClick={() => setVideoMuted((prevState) => !prevState)}
                        type="button">
                        <img src={videoMuted ? Unmute : Mute} alt={videoMuted ? 'Unmute' : 'Mute'} />
                        <span>{videoMuted ? 'unmute' : 'mute'}</span>
                    </StyledButton>
                </ButtonsWrapper>
            )}

            {videoFinished && (
                <StyledReplayContainer>
                    <StyledReplay onClick={() => {
                        videoRef?.current?.play();
                        setVideoFinished(false)
                    }} src={Replay} alt='replay' />
                </StyledReplayContainer>
            )}
        </VideoWrapper>
    )
}

const StyledReplayContainer = styled.div`
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
`;

const StyledReplay = styled.img`
    position: absolute;
    top: 48%;
    left: 52%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
`;

const StyledPlayPauseButton = styled.img`
    cursor: pointer;
    width: 50px;
    margin: 0 auto 2rem auto;
    object-fit: contain;
`;

const StyledButton = styled.button`
    background-color: #002677;
    border-radius: 20px;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    color: white;

    img {
        margin-right: 5px;
    }
`;

const showUp = keyframes`
  from {
    transform: translateY(500px);
  }

  to {
    transform: translateY(0);
  }
`;

const VideoWrapper = styled.div<{ show: boolean }>`
    position: fixed;
    height: 200px;
    width: 200px;
    margin-left: auto;
    bottom: 2rem;
    right: 2rem;
    transform: translateY(500px);
    animation-name: ${showUp};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-play-state: ${({ show }) => show ? 'running' : 'paused'};
    z-index: 15;
`;

const Video = styled.video`
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%;
`;
