import { useEffect, useState } from "react";

const useWindowScrollPosition = () => {
    const [scroll, setScroll] = useState(window.scrollY);

    useEffect(() => {
        function handleEvent() {
            setScroll(window.scrollY);
        }

        window.addEventListener("scroll", handleEvent);
        window.addEventListener("resize", handleEvent);

        handleEvent();

        return () => {
            window.removeEventListener("scroll", handleEvent);
            window.removeEventListener("resize", handleEvent);
        };
    }, []);

    return scroll;
};

export default useWindowScrollPosition;
