import { makeApiRequest } from "api/makeApiRequest";
import ContactFormAction, { sendContactFormMessage } from "features/contactForm/actions";
import { ContactFormApi } from "features/contactForm/api";
import { SupportRequestData } from "features/contactForm/models";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";

export const sendContactFormMessageAsync = (
    data: SupportRequestData
): ThunkAction<void, RootState, Pick<ContactFormApi, "sendContactFormMessageAsync">, ContactFormAction> => {
    return async (dispatch, getState, api) => {
        dispatch(sendContactFormMessage.request());

        await makeApiRequest(
            api.sendContactFormMessageAsync,
            [data],
            sendContactFormMessage.success,
            sendContactFormMessage.failure,
            dispatch
        );
    };
};
