import { OfferType } from "features/common/types";
import { resetMyEmployeeOffers } from "features/employeeOffers/actions";
import {
    selectMyEmployeeOffers,
    selectMyEmployeeOffersLoading,
    selectMyEmployeeOffersLoadingError,
} from "features/employeeOffers/selectors";
import { getMyEmployeeOffersAsync } from "features/employeeOffers/thunks";
import MyOffers from "features/offers/components/MyOffers";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

interface OwnProps {
    offerType: OfferType;
}

const mapStateToProps = (state: RootState, { offerType }: OwnProps) => ({
    offerType,
    myOffers: selectMyEmployeeOffers(state),
    myOffersLoading: selectMyEmployeeOffersLoading(state),
    myOffersLoadingError: selectMyEmployeeOffersLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        { getMyOffersAsync: getMyEmployeeOffersAsync, resetMyOffers: () => resetMyEmployeeOffers() },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyOffers);
