import useDeviceClass from "features/common/hooks/useDeviceClass";
import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import MyCardsComponent from "features/myProfile/components/MyCards";
import MyDataComponent from "features/myProfile/components/MyData";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const MyProfileComponent = () => {
    const isNotDesktop = useDeviceClass() !== "desktop";
    useScrollToTopOnMount();

    return (
        <div className={styles["my-profile"]}>
            <div className={styles["my-profile__header"]}>
                <h2 className={styles["my-profile__main-header"]}>
                    <FormattedMessage id="common__my-profile" />
                </h2>
            </div>
            <div className={styles["my-profile__my-data"]}>
                {isNotDesktop && <div className={styles["my-profile__line-breaker"]}></div>}
                <MyDataComponent />
            </div>
            <div className={styles["my-profile__my-cards"]}>
                {isNotDesktop && <div className={styles["my-profile__line-breaker"]}></div>}
                <MyCardsComponent testId={"my-profile__my-cards"} />
            </div>
        </div>
    );
};

export default MyProfileComponent;
