import React from "react";
import NewCardComponent from "features/myProfile/components/NewCard";
import styles from "./styles.module.scss";
import { OfferType } from "features/common/types";

const CreateEmployeeOfferNewCard = () => {
    return (
        <div className={styles["new-card"]}>
            <NewCardComponent creatingOffer={OfferType.Employee} />
        </div>
    );
};

export default CreateEmployeeOfferNewCard;
