import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as NoRelatedOffersStateColorIcon } from "assets/icons/myOffersEmptyStateColor.svg";
import classNames from "classnames";
import Spinner from "features/common/components/Spinner";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import { OfferType } from "features/common/types";
import { MyJobOffersCardDetails } from "features/jobOffers/models";
import { CardOwnerRole } from "features/myProfile/types";
import SingleOfferCard from "features/offers/components/SingleOfferCard";
import { OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Pagination from "./Pagination";
import styles from "./styles.module.scss";

const desktopPageSize = 3;
const tabletPageSize = 2;
const smartphonePageSize = 1;
const initialPageSize = 0;

interface Props {
    cardKind: CardOwnerRole;
    countRelatedOffers?: number;
    relatedOffersLoading: boolean;
    relatedOffers?: MyJobOffersCardDetails[];
    relatedOffersLoadingError?: ApiError;
    cardId?: string;
    displayOnly?: boolean;
    isMyCard?: boolean;
    ["data-testid"]?: string;
    getRelatedOffersAsync: (
        cardUniqueId: string,
        cardKind: string,
        pageSize: number,
        offset: number,
        cancelToken?: CancelToken
    ) => void;
}

const RelatedOffersSection = ({
    cardKind,
    countRelatedOffers,
    relatedOffersLoading,
    relatedOffers,
    relatedOffersLoadingError,
    cardId,
    displayOnly,
    isMyCard,
    "data-testid": testId = "related-offers",
    getRelatedOffersAsync,
}: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(initialPageSize);
    const [totalPages, setTotalPages] = useState(0);

    const history = useHistory();
    const deviceClass = useDeviceClass();
    const createCancelToken = useCreateCancelToken();

    const isLoading = relatedOffersLoading;
    const hasRelatedOffers = !relatedOffersLoadingError && relatedOffers && relatedOffers?.length > 0;
    const offerType = cardKind === "EMPLOYER" ? OfferType.Job : OfferType.Employee;

    const onPageChange = useCallback(
        (pageNumber: number) => {
            if (pageNumber > totalPages) {
                setCurrentPage(1);
            } else if (pageNumber < 1) {
                setCurrentPage(totalPages);
            } else {
                setCurrentPage(pageNumber);
            }
        },
        [totalPages]
    );

    useEffect(() => {
        if (!relatedOffersLoading && countRelatedOffers) {
            setTotalPages(Math.ceil(countRelatedOffers / currentPageSize));
        }
    }, [countRelatedOffers, currentPageSize, relatedOffersLoading]);

    useEffect(() => {
        setCurrentPageSize(
            deviceClass === "desktop"
                ? desktopPageSize
                : deviceClass === "tablet"
                    ? tabletPageSize
                    : deviceClass === "smartphone"
                        ? smartphonePageSize
                        : initialPageSize
        );
    }, [currentPageSize, deviceClass]);

    useEffect(() => {
        if (cardId && currentPageSize > 0) {
            getRelatedOffersAsync(cardId, cardKind, currentPageSize, currentPage, createCancelToken());
        }
    }, [cardId, cardKind, createCancelToken, currentPage, currentPageSize, getRelatedOffersAsync]);

    return (
        <div className={!isLoading && !hasRelatedOffers ? styles["related-offers-section__empty-container"] : ""}>
            {isLoading && (
                <div
                    className={classNames(styles["related-offers-section__spinner-container"], {
                        [styles["related-offers-section__spinner-container--job"]]: offerType === OfferType.Job,
                        [styles["related-offers-section__spinner-container--employee"]]:
                            offerType === OfferType.Employee,
                    })}
                >
                    <Spinner className={styles["related-offers-section__spinner"]} />
                </div>
            )}
            {!isLoading && hasRelatedOffers && (
                <>
                    <div className={styles["related-offers-section__offers-container"]}>
                        {relatedOffers?.map((offer) => (
                            <SingleOfferCard
                                data-testid={`${testId}__single-offer`}
                                key={`${offer.id}-${offer.status}`}
                                className={styles["related-offers-section__offer-card"]}
                                photoBadge={displayOnly ? "bonus" : "status"}
                                {...offer}
                                publishedDate={offer.publishDate}
                                title={offer.title}
                                previewMode="minimum"
                                onTitleImageClick={() =>
                                    offer.status !== OfferStatus.Inactive &&
                                    history.push(
                                        `${cardKind === "EMPLOYER" ? appRoutes.jobOffers : appRoutes.employeeOffers}/${offer.id
                                        }`
                                    )
                                }
                                displayEndButton={false}
                                offerType={offerType}
                                hideActionButtons={!isMyCard}
                                showTopRightButton={isMyCard}
                                financialConditions={offer.financialConditionsSlimDTO}
                            />
                        ))}
                    </div>
                    <Pagination
                        data-testid={`${testId}__pagination`}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                </>
            )}
            {!isLoading && !hasRelatedOffers && (
                <>
                    <NoRelatedOffersStateColorIcon />
                    <span data-testid={`${testId}__no-related-offers-header`}>
                        {displayOnly ? (
                            <FormattedMessage
                                id="related-offers__no-related-offers-header-display-only"
                                values={{ cardKind }}
                            />
                        ) : cardKind === "EMPLOYER" ? (
                            <FormattedMessage id="related-offers__no-related-job-offers-header" />
                        ) : (
                            <FormattedMessage id="related-offers__no-related-employee-offers-header" />
                        )}
                    </span>
                    <span data-testid={`${testId}__no-related-offers-message`}>
                        {displayOnly ? (
                            <FormattedMessage
                                id="related-offers__no-related-offers-message-display-only"
                                values={{ cardKind }}
                            />
                        ) : cardKind === "EMPLOYER" ? (
                            <FormattedMessage id="related-offers__no-related-job-offers-message" />
                        ) : (
                            <FormattedMessage id="related-offers__no-related-employee-offers-message" />
                        )}
                    </span>
                </>
            )}
        </div>
    );
};

export default RelatedOffersSection;
