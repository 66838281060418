import {ReactComponent as NotificationsIcon} from "assets/icons/notifications.svg";
import classNames from "classnames";
import config from 'config';
import RedDotCounter from "features/common/components/RedDotCounter";
import ApplicationModal from "features/jobOffers/components/ApplicationModal";
import Dropdown from "features/layout/components/Header/NotificationsDropdown";
import {MessagesApi} from "features/messages/api";
import {mapNotifications} from "features/messages/mappers";
import {User} from "features/user/models";
import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import AuthStorageService from "services/authStorageService";
import styles from "./styles.module.scss";

const SockJS = require('sockjs-client')
const Stomp = require('stompjs');
const api = new MessagesApi();

export interface Props {
    ["data-testid"]?: string;
    user?: User;
    isUserLoggedIn?: boolean;
    unreadMessagesCount?: number;
    logoutUserAsync: () => void;
    isMobile?: boolean;
}

const UserNav = ({ "data-testid": testId, user, isUserLoggedIn, unreadMessagesCount, isMobile, logoutUserAsync }: Props) => {
    const intl = useIntl();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [unacknowledgedNotifications, setUnacknowledgedNotifications] = useState<any>([]);
    const [isOfferOwner, setIsOfferOwner] = useState(false);
    const [notifications, setNotifications] = useState<any[]>([]);
    const tokens = AuthStorageService.getTokens();

    const handleDropdown = () => setDropdownOpen((prevState) => !prevState);

    const getNotifications = useCallback(async () => {
        const res = await api.getNotificationsAsync({ pageSize: 10 });
        if (res && res.length > 0) {

            setNotifications(mapNotifications(res, intl))

        }
    }, [intl])

    const handleNotificationAcknowledged = async (notificationId: string) => {
        await api.setNotificationAcknowledged(notificationId);
        getNotifications();
    }

    useEffect(() => {
        getNotifications();

    }, [getNotifications]);

    useEffect(() => {
        if (!notifications.length) {
            return;
        }
        const ackNotifications = notifications.filter((notifi: any) => !notifi.acknowledged);
        setUnacknowledgedNotifications(ackNotifications);
    }, [notifications]);

    useEffect(() => {
        const sock = new SockJS(config.WEBSOCKET_URL);
        const stompClient = Stomp.over(sock);

        const token = `Bearer ${tokens?.accessToken}`;
        const tkn = { "jwt-token": token }

        stompClient.connect(tkn, function (frame: any) {
            const userId = frame.headers['user-name'].replace(/"/g, "");
            stompClient.subscribe('/user/' + userId + '/queue/notifications', function (notification: any) {
                getNotifications()
            });
        });
    }, [])

    const [selectedApplicationId, setSelectedApplicationId] = useState<number | null>(null);

    const handleCloseApplicationDetailsModal = () => {
        setSelectedApplicationId(null);
    }

    const handleClickedNotification = (notificationId: string) => {
        setIsOfferOwner(notifications.find((el: any) => el.notificationId === notificationId)?.notificationType === 'RATE_EMPLOYEE_INVITATION')
    }

    return (
        <>
            <div
                className={classNames(styles["user-nav"], {
                    [styles["user-nav--open"]]: dropdownOpen,
                    [styles["user-nav--mobile"]]: isMobile,
                })}
                onClick={handleDropdown}
            >
                <div className={styles["notification_icon"]}>
                    <NotificationsIcon />
                    <RedDotCounter
                        className={styles["messages-notification-icon__unread-counter"]}
                        count={unacknowledgedNotifications.length}
                    />
                </div>

                {dropdownOpen && (
                    <div onClick={handleDropdown}>
                        <Dropdown
                            selectedApplicationId={selectedApplicationId}
                            setSelectedApplicationId={setSelectedApplicationId}
                            data-testid={`${testId}__dropdown`}
                            onClickAway={() => setDropdownOpen(false)}
                            userProfileExists={!!user?.firstName}
                            isUserLoggedIn={isUserLoggedIn}
                            unreadMessagesCount={unreadMessagesCount}
                            logoutUserAsync={logoutUserAsync}
                            items={notifications}
                            handleNotificationAcknowledged={handleNotificationAcknowledged}
                            setClickedNotification={handleClickedNotification}
                        />
                    </div>
                )}
            </div>

            {!!selectedApplicationId && (
                <ApplicationModal
                    id={selectedApplicationId}
                    handleCloseModal={handleCloseApplicationDetailsModal}
                    isOfferOwner={isOfferOwner}
                    setSelectedApplicationId={setSelectedApplicationId}
                />
            )}
        </>
    );
};

export default UserNav;
