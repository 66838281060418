import { setUserLoggedInState } from "features/user/actions";
import AuthenticationGateway from "features/user/components/AuthenticationGateway/component";
import {
    selectCancelTokenLoading,
    selectIsTokenCancelled,
    selectIsUserLoggedIn,
    selectIsUserLoggedInWithProfile,
    selectUser,
    selectUserId,
    selectUserLoading,
    selectUserLoadingError,
    selectUserLoadingSuccess,
} from "features/user/selectors";
import { getUserAsync } from "features/user/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    userId: selectUserId(state),
    userLoading: selectUserLoading(state),
    userLoadingError: selectUserLoadingError(state),
    userLoadingSuccess: selectUserLoadingSuccess(state),
    cancelTokenLoading: selectCancelTokenLoading(state),
    isTokenCanceled: selectIsTokenCancelled(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
    isUserLoggedInWithProfile: selectIsUserLoggedInWithProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getUserAsync,
            setUserAsLoggedIn: () => setUserLoggedInState(true),
            setUserAsUnauthenticated: () => setUserLoggedInState(false),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationGateway);
