import {
    selectCurrencies,
    selectCurrenciesLoading,
    selectCurrenciesLoadingError,
} from "features/common/selectors";
import { getCurrenciesAsync } from "features/common/thunks";
import { selectUser } from "features/user/selectors";
import {
    selectCardDetails,
    selectCardDetailsLoading,
    selectCardDetailsLoadingError,
} from "features/myProfile/selectors";
import { getCardDetailsAsync } from "features/myProfile/thunks";
import {
    selectEmployeeOfferCreatingSuccess,
    selectEmployeeOfferCreating,
    selectEmployeeOfferCreatingError,
    selectEmployeeOfferDetails,
} from "features/employeeOffers/selectors";
import { publishEmployeeOfferAsync, getEmployeeOfferDetailsAsync } from "features/employeeOffers/thunks";
import { resetEmployeeOfferDetails } from "features/employeeOffers/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import CreateOffer from "./component";

const mapStateToProps = (state: RootState) => ({
    user: selectUser(state),
    cardDetails: selectCardDetails(state),
    cardDetailsLoading: selectCardDetailsLoading(state),
    cardDetailsError: selectCardDetailsLoadingError(state),
    employeeOfferCreating: selectEmployeeOfferCreating(state),
    employeeOfferCreatingSuccess: selectEmployeeOfferCreatingSuccess(state),
    employeeOfferCreatingError: selectEmployeeOfferCreatingError(state),
    currencies: selectCurrencies(state),
    currenciesLoading: selectCurrenciesLoading(state),
    currenciesError: selectCurrenciesLoadingError(state),
    employeeOfferDetails: selectEmployeeOfferDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCardDetailsAsync,
            publishEmployeeOfferAsync,
            getCurrenciesAsync,
            getEmployeeOfferDetailsAsync,
            resetEmployeeOfferDetails: () => resetEmployeeOfferDetails(),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer);
