/* eslint-disable max-lines */
import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as NoInterested } from "assets/icons/noInteresants.svg";
import { ReactComponent as ApplicationSent } from "assets/icons/applicationSent.svg";
import { ReactComponent as ApplicationDenied } from "assets/icons/applicationDenied.svg";
import { ReactComponent as BonusGiven } from "assets/icons/bonusGiven.svg";
import { ReactComponent as ApplicationAccepted } from "assets/icons/applicationAccepted.svg";
import { ReactComponent as ArrivalAccepted } from "assets/icons/arrivalAccepted.svg";
import Breadcrumbs, { BreadcrumbsItem } from "features/common/components/Breadcrumbs";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TabNavigation from "features/common/components/TabNavigation";
import DataTable from "features/common/components/DataTable";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getHomeMessage, getMyJobOffersMessage } from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import { ApplicationStatus } from "features/messages/types";
import { JobOfferDetails, Interested, Application } from "features/jobOffers/models";
import ApplicationModal from "features/jobOffers/components/ApplicationModal";
import { MyJobOfferDetailCategory } from "features/jobOffers/types";
import BadgeStatus from "features/offers/components/BadgeStatus";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import MyJobOfferDetailsPreview from "./MyJobOfferDetailsPreview";
import styles from "./styles.module.scss";

const parseApplicationStatusTranslationId = (status: ApplicationStatus) => {
    switch (status) {
        case ApplicationStatus.ApplicationSent:
            return "common__application-status--sent";
        case ApplicationStatus.ApplicationAccepted:
            return "common__application-status--accepted";
        case ApplicationStatus.ApplicationDenied:
            return "common__application-status--denied";
        case ApplicationStatus.ArrivalAccepted:
            return "common__application-status--arrival-accepted";
        case ApplicationStatus.BonusGiven:
            return "common__application-status--bonus-given";
    }
};

const parseApplicationStatusIcon = (status: ApplicationStatus) => {
    switch (status) {
        case ApplicationStatus.ApplicationSent:
            return <ApplicationSent />;
        case ApplicationStatus.ApplicationAccepted:
            return <ApplicationAccepted />;
        case ApplicationStatus.ApplicationDenied:
            return <ApplicationDenied />;
        case ApplicationStatus.ArrivalAccepted:
            return <ArrivalAccepted />;
        case ApplicationStatus.BonusGiven:
            return <BonusGiven />;
    }
};

interface Props {
    id: number;
    activeTab: MyJobOfferDetailCategory;
    jobOfferDetails?: JobOfferDetails;
    jobOfferDetailsLoading: boolean;
    jobOfferDetailsLoadingError?: ApiError;
    interested?: Interested[];
    interestedLoading: boolean;
    interestedError?: ApiError;
    applications?: Application[];
    applicationsLoading: boolean;
    applicationsError?: ApiError;
    acceptApplicationSuccess?: boolean;
    rejectApplicationSuccess?: boolean;
    acceptArrivalSuccess?: boolean;
    giveBonusSuccess?: boolean;
    isUserLoggedInWithProfile?: boolean;
    resetJobOfferDetails: () => void;
    getJobOfferDetailsAsync?: (id: number, cancelToken?: CancelToken) => void;
    getInterestedAsync: (jobOfferId: string, cancelToken?: CancelToken) => void;
    getApplicationsAsync: (jobOfferId: string, cancelToken: CancelToken) => void;
}

const MyJobOfferDetails = ({
    id,
    activeTab,
    jobOfferDetails,
    jobOfferDetailsLoading,
    jobOfferDetailsLoadingError,
    interested,
    interestedLoading,
    interestedError,
    applications,
    applicationsLoading,
    applicationsError,
    acceptApplicationSuccess,
    rejectApplicationSuccess,
    acceptArrivalSuccess,
    giveBonusSuccess,
    isUserLoggedInWithProfile,
    resetJobOfferDetails,
    getJobOfferDetailsAsync,
    getInterestedAsync,
    getApplicationsAsync,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();
    const [selectedApplicationId, setSelectedApplicationId] = useState<number | undefined>(undefined);

    const homeMessage = getHomeMessage(intl);
    const myJobOffersMessage = getMyJobOffersMessage(intl);

    const crumbs: BreadcrumbsItem[] = [
        {
            displayName: homeMessage,
            path: appRoutes.dashboard,
        },
        {
            displayName: myJobOffersMessage,
            path: appRoutes.myJobOffers,
        },
        {
            displayName: jobOfferDetails?.position ?? "",
        },
    ];

    const changeTab = (tabNumber: MyJobOfferDetailCategory) =>
        history.push(`${appRoutes.myJobOffers}/${id}/${tabNumber}`);

    const goToConversation = (id: string) => history.push(`${appRoutes.messages}/${id}`);

    useEffect(() => {
        window.scrollTo(0, 0);

        return () => resetJobOfferDetails();
    }, [resetJobOfferDetails]);

    useEffect(() => {
        getJobOfferDetailsAsync && getJobOfferDetailsAsync(id, createCancelToken());
        getInterestedAsync && getInterestedAsync(`${id}`, createCancelToken());
        getApplicationsAsync && getApplicationsAsync(`${id}`, createCancelToken());
    }, [createCancelToken, getApplicationsAsync, getInterestedAsync, getJobOfferDetailsAsync, id, intl.locale]);

    useEffect(() => {
        if (acceptApplicationSuccess || rejectApplicationSuccess || acceptArrivalSuccess || giveBonusSuccess) {
            getApplicationsAsync(`${id}`, createCancelToken());
        }
    }, [
        acceptApplicationSuccess,
        acceptArrivalSuccess,
        createCancelToken,
        getApplicationsAsync,
        giveBonusSuccess,
        id,
        rejectApplicationSuccess,
    ]);

    const handleApplicationDetailsOpen = useCallback(
        (id: string) => setSelectedApplicationId(+id),
        [setSelectedApplicationId]
    );
    const handleCloseApplicationDetailsModal = useCallback(
        () => setSelectedApplicationId(undefined),
        [setSelectedApplicationId]
    );

    const resetOfferDetails = () => {
        getJobOfferDetailsAsync && getJobOfferDetailsAsync(id, createCancelToken());
    }

    return (
        <div className={styles["my-offer-details"]}>
            {(jobOfferDetailsLoading || interestedLoading || applicationsLoading) && (
                <SpinnerWithMessage message={<FormattedMessage id="my-offer-details__loading-offer-details" />} />
            )}
            {jobOfferDetailsLoadingError || interestedError || applicationsError ? (
                <div className={styles["my-offer-details__error-container"]}>
                    <FormattedMessage id="my-offer-details__fetch-failure" />
                </div>
            ) : (
                jobOfferDetails && (
                    <div>
                        <div className={styles["my-offer-details__breadcrumbs-container"]}>
                            <Breadcrumbs data-testid="my-offer-details__breadcrumbs" crumbs={crumbs} />
                        </div>
                        <div className={styles["my-offer-details__header-container"]}>
                            <div className={styles["my-offer-details__inner-header-container"]}>
                                <div className={styles["my-offer-details__inner-header-with-badge-container"]}>
                                    <h2>{jobOfferDetails?.position}</h2>
                                    <BadgeStatus
                                        data-testid={`${id}__status`}
                                        status={jobOfferDetails.status}
                                        className={styles["my-offer-details__badge-container"]}
                                        offerType={OfferType.Job}
                                    />
                                </div>
                                <TabNavigation
                                    className={styles["my-offer-details__tab-navigation"]}
                                    tabs={[
                                        <FormattedMessage id="my-job-offer-details__preview" />,
                                        <FormattedMessage id="my-job-offer-details__interested" />,
                                        <FormattedMessage id="my-job-offer-details__applications" />,
                                    ]}
                                    activeTabIndex={activeTab}
                                    onTabSelect={(index: MyJobOfferDetailCategory) => {
                                        changeTab(index);
                                    }}
                                />
                            </div>
                        </div>
                        {activeTab === MyJobOfferDetailCategory.Preview && (
                            <div className={styles["my-offer-details__details-container"]}>
                                <MyJobOfferDetailsPreview
                                    isUserLoggedInWithProfile={isUserLoggedInWithProfile}
                                    jobOfferDetails={jobOfferDetails}
                                    id={id}
                                    jobOfferDetailsLoading={jobOfferDetailsLoading}
                                    jobOfferDetailsLoadingError={jobOfferDetailsLoadingError}
                                    resetOfferDetails={resetOfferDetails}
                                />
                            </div>
                        )}
                        {activeTab === MyJobOfferDetailCategory.Interested && (
                            <div className={styles["my-offer-details__interested-container"]}>
                                {!!interested?.length ? (
                                    <DataTable<Interested>
                                        data={interested}
                                        columns={[
                                            {
                                                key: "userName",
                                                label: intl.formatMessage({
                                                    id: "my-job-offer-details__interested-username",
                                                }),
                                            },
                                            {
                                                key: "messagesNumber",
                                                label: intl.formatMessage({
                                                    id: "my-job-offer-details__interested-messages",
                                                }),
                                            },
                                            {
                                                key: "lastMessageTimestamp",
                                                label: intl.formatMessage({
                                                    id: "my-job-offer-details__interested-last-message",
                                                }),
                                                customRender: (field) =>
                                                    format(field, "d MMM yyyy", { locale: pl }).toUpperCase(),
                                            },
                                            {
                                                actionColumn: true,
                                                actionDisplay: (
                                                    <FormattedMessage id="my-job-offer-details__interested-go-to-conversation" />
                                                ),
                                                actionCallback: (id) => goToConversation(id),
                                            },
                                        ]}
                                        idColumn="conversationId"
                                    />
                                ) : (
                                    <div className={styles["my-offer-details__interested-empty"]}>
                                        <NoInterested />
                                        <span className={styles["my-offer-details__interested-empty-title"]}>
                                            <FormattedMessage id="my-job-offer-details__interested-empty-title" />
                                        </span>
                                        <span className={styles["my-offer-details__interested-empty-text"]}>
                                            <FormattedMessage id="my-job-offer-details__interested-empty-text" />
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        {activeTab === MyJobOfferDetailCategory.Applications && (
                            <div className={styles["my-offer-details__applications-container"]}>
                                {!!applications?.length ? (
                                    <>
                                        <div className={styles["my-offer-details__applications-count"]}>
                                            <FormattedMessage
                                                id="my-job-offer-details__applications-amount"
                                                values={{ amount: applications?.length }}
                                            />
                                        </div>
                                        <DataTable<Application>
                                            data={applications}
                                            columns={[
                                                {
                                                    key: "jobApplicant",
                                                    label: intl.formatMessage({
                                                        id: "my-job-offer-details__application-user",
                                                    }),
                                                },
                                                {
                                                    key: "applicationStatus",
                                                    label: intl.formatMessage({
                                                        id: "my-job-offer-details__application-status",
                                                    }),
                                                    customRender: (field) => (
                                                        <div className={styles["my-offer-details__application-status"]}>
                                                            {parseApplicationStatusIcon(field)}
                                                            {intl.formatMessage({
                                                                id: parseApplicationStatusTranslationId(field),
                                                            })}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: "id",
                                                    label: intl.formatMessage({
                                                        id: "my-job-offer-details__application-number",
                                                    }),
                                                },
                                                {
                                                    key: "applicationDate",
                                                    label: intl.formatMessage({
                                                        id: "my-job-offer-details__application-date",
                                                    }),
                                                    customRender: (field) =>
                                                        format(field, "d MMM yyyy", { locale: pl }).toUpperCase(),
                                                },
                                                {
                                                    key: "applicantsNumber",
                                                    label: intl.formatMessage({
                                                        id: "my-job-offer-details__application-count",
                                                    }),
                                                },
                                                {
                                                    actionColumn: true,
                                                    actionDisplay: (
                                                        <FormattedMessage id="my-job-offer-details__see-application" />
                                                    ),
                                                    actionCallback: handleApplicationDetailsOpen,
                                                },
                                            ]}
                                            idColumn="id"
                                        />
                                    </>
                                ) : (
                                    <div className={styles["my-offer-details__applications-empty"]}>
                                        <NoInterested />
                                        <span className={styles["my-offer-details__applications-empty-title"]}>
                                            <FormattedMessage id="my-job-offer-details__applications-empty-title" />
                                        </span>
                                        <span className={styles["my-offer-details__applications-empty-text"]}>
                                            <FormattedMessage id="my-job-offer-details__applications-empty-text" />
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )
            )}
            {!!selectedApplicationId && (
                <ApplicationModal
                    id={selectedApplicationId}
                    handleCloseModal={handleCloseApplicationDetailsModal}
                    isOfferOwner
                />
            )}
        </div>
    );
};

export default MyJobOfferDetails;
