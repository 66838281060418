import { ReactComponent as AccountIcon } from "assets/icons/account.svg";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import classNames from "classnames";
import config from "config";
import Button from "features/common/components/Button";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import mapStringToArrayBuffer from "features/common/mappers/mapStringToArrayBuffer";
import {
    getFileAmountMessage,
    getFileTooBigErrorMessage,
    getMultipleFilesErrorMessage,
    getWrongFileTypeErrorMessage,
} from "features/common/translationMessages";
import { FileType, Image } from "features/common/types";
import { isFileExtensionValid, isFileSizeValid } from "features/common/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    files?: Image[];
    maxSize: number; // Size in bytes
    allowedTypes?: FileType[];
    ["data-testid"]?: string;
    onUpload: (images: Image[]) => void;
    allowMultipleFiles?: boolean;
    multipleFilesAmount?: number;
    customPlaceholderImage?: React.ReactElement;
    textAsPlaceholder?: boolean;
    doublePlaceholder?: boolean;
    className?: string;
    dropzoneClassName?: string;
    imageClassName?: string;
    errorHelper?: boolean;
    uploadError?: string;
    buttonPlaceholder: string | JSX.Element;
    buttonDisabled?: boolean;
    photoUrls?: any;
    handleRemoveElement?: (url: string) => void;
}

const DropzonePhotoSectionUploader = ({
    files,
    maxSize,
    allowedTypes,
    "data-testid": testId,
    onUpload,
    allowMultipleFiles = false,
    multipleFilesAmount = 0,
    customPlaceholderImage,
    textAsPlaceholder = false,
    doublePlaceholder = false,
    className,
    dropzoneClassName,
    imageClassName,
    errorHelper,
    uploadError,
    buttonPlaceholder,
    photoUrls,
    handleRemoveElement,
    buttonDisabled
}: Props) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();

    const [error, setError] = useState<string | undefined>();
    const [loadedFiles, setLoadedFiles] = useState<Image[] | undefined>(files ?? []);
    const [isDoneLoadingFiles, setIsDoneLoadingFiles] = useState(false);

    const multipleFilesErrorMessage = getMultipleFilesErrorMessage(intl);
    const fileTooBigErrorMessage = getFileTooBigErrorMessage(intl, maxSize);
    const wrongFileTypeErrorMessage = getWrongFileTypeErrorMessage(intl, allowedTypes);
    const fileAmountErrorMessage = getFileAmountMessage(intl, multipleFilesAmount);

    useEffect(() => {
        if (photoUrls?.length && isDoneLoadingFiles) {
            setIsDoneLoadingFiles(false);
            setLoadedFiles(photoUrls);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photoUrls]);


    useEffect(() => {
        if (files === undefined) {
            setLoadedFiles([]);
        }

        const filesArray = files?.map((current) => current.fileUrl).join(",");
        const loadedArray = loadedFiles?.map((current) => current.fileUrl).join(",");
        const shouldUpdateLoadedFiles = filesArray !== loadedArray;

        if (shouldUpdateLoadedFiles) {
            setLoadedFiles(files);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    useEffect(() => {
        if (loadedFiles) {
            isDoneLoadingFiles && onUpload && onUpload(loadedFiles);
        }
    }, [loadedFiles]);

    useEffect(() => {
        setError(undefined);
    }, [errorHelper]);

    const onDrop = useCallback(
        (files: File[]) => {
            setIsDoneLoadingFiles(false);
            if (files.length > 1 && !allowMultipleFiles) {
                setError(multipleFilesErrorMessage);
            } else if (allowMultipleFiles && loadedFiles && files.length + loadedFiles?.length > multipleFilesAmount) {
                setError(fileAmountErrorMessage);
            } else if (maxSize && !isFileSizeValid(files, maxSize)) {
                setError(fileTooBigErrorMessage);
            } else if (allowedTypes && !isFileExtensionValid(files, allowedTypes)) {
                setError(wrongFileTypeErrorMessage);
            } else {
                const reader = new FileReader();
                const file = files[0];

                reader.onload = () => {
                    const imageData =
                        typeof reader.result === "string" ? mapStringToArrayBuffer(reader.result) : reader.result;

                    if (imageData) {
                        const urlCreator = window.URL || window.webkitURL;

                        files.forEach((current, index) => {
                            const imageUrl = urlCreator.createObjectURL(current);

                            const newImage = {
                                file: current,
                                fileUrl: imageUrl,
                            };

                            if (index === files.length - 1) {
                                setIsDoneLoadingFiles(true);
                            }

                            if (allowMultipleFiles) {
                                setLoadedFiles((prevState) => [...(prevState ?? []), newImage]);
                            } else {
                                setLoadedFiles([newImage]);
                            }
                            setError(undefined);
                        });
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        },
        [
            allowedTypes,
            fileTooBigErrorMessage,
            maxSize,
            multipleFilesErrorMessage,
            wrongFileTypeErrorMessage,
            allowMultipleFiles,
            fileAmountErrorMessage,
            loadedFiles,
        ]
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
    });

    const onRemove = useCallback(
        (url: string) => {
            handleRemoveElement && handleRemoveElement(url);
        },
        [handleRemoveElement, photoUrls]
    );

    return (
        <div className={className}>
            <div
                data-testid={testId}
                className={classNames(styles["dropzone-photo-section-uploader"], dropzoneClassName, {
                    [styles["dropzone-photo-section-uploader--highlighted"]]: isDragActive,
                })}
                {...getRootProps()}
            >
                <div className={styles["dropzone-photo-section-uploader__images-container"]}>
                    {photoUrls?.length ? ((photoUrls.map((url: any, index: number) => (
                        <>
                            <div
                                key={`${index}-${url.fileName}`}
                                className={styles["dropzone-photo-section-uploader__single-image-container"]}
                            >
                                <div
                                    data-testid={testId ? `${testId}__image-remove-button` : undefined}
                                    className={styles["dropzone-photo-section-uploader__remove-image-button"]}
                                    onClick={() => onRemove(url.fileName)}
                                >
                                    <TimesIcon />
                                </div>
                                <img
                                    data-testid={testId ? `${testId}__image-` : undefined}
                                    className={classNames(styles["dropzone-photo-section-uploader__preview"], imageClassName)}
                                    src={`${config.IMAGE_URL}/${url.fileName}`}
                                    alt="Profile"
                                />
                            </div>
                        </>
                    )))) : null}
                </div>

                <input
                    data-testid={testId ? `${testId}__input` : undefined}
                    {...getInputProps()}
                    accept={allowedTypes?.join(", ")}
                    multiple={allowMultipleFiles}
                />

                {!loadedFiles?.length && (!textAsPlaceholder || doublePlaceholder) && (
                    <div
                        data-testid={testId ? `${testId}__preview-placeholder` : undefined}
                        className={styles["dropzone-photo-section-uploader__preview-placeholder"]}
                    >
                        {customPlaceholderImage || <AccountIcon />}
                    </div>
                )}

                {(((textAsPlaceholder || doublePlaceholder) && allowMultipleFiles && deviceClass === "desktop") ||
                    ((textAsPlaceholder || doublePlaceholder) && deviceClass === "desktop")) &&
                    !files?.length && (
                        <div className={styles["dropzone-photo-section-uploader__placeholder"]}>
                            <span>
                                {allowMultipleFiles ? (
                                    <FormattedMessage id="dropzone-photo-section-uploader__drop-files" />
                                ) : (
                                    <FormattedMessage id="dropzone-photo-section-uploader__drop-file" />
                                )}
                            </span>
                            <span>
                                <FormattedMessage id="common__or" />
                            </span>
                        </div>
                    )}

                <Button
                    disabled={buttonDisabled}
                    className={classNames(styles["dropzone-photo-section-uploader__add-file-button"], {
                        [styles["dropzone-photo-section-uploader__big-button"]]: !textAsPlaceholder,
                    })}
                    variant="secondary"
                    onClick={open}
                >
                    {buttonPlaceholder}
                </Button>

                <div className={styles["dropzone-photo-section-uploader__file-requirements"]}>
                    {maxSize && allowedTypes && (
                        <span data-testid={testId ? `${testId}__requirements-message` : undefined}>
                            <FormattedMessage
                                id="dropzone-photo-section-uploader__file-requirements"
                                values={{
                                    size: Math.floor(maxSize / 1000000),
                                    extensions: allowedTypes.sort().join(", "),
                                }}
                            />
                        </span>
                    )}
                    {allowMultipleFiles && !!multipleFilesAmount && (
                        <span data-testid={testId ? `${testId}__file-amount-error` : undefined}>
                            {fileAmountErrorMessage}
                        </span>
                    )}
                </div>
            </div>

            {(error || uploadError) && (
                <div data-testid={`${testId}__error`} className={styles["dropzone-photo-section-uploader__error"]}>
                    {error || uploadError}
                </div>
            )}
        </div>
    );
};

export default DropzonePhotoSectionUploader;