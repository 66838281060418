import { addDays, addMonths, getTime, toDate } from "date-fns";
/* eslint-disable max-lines */
import { monetaryAmountRegex } from "features/common/constants";
import mapUtcStringToLocalDate from "features/common/mappers/mapUtcStringToLocalDate";
import { Currency, SalaryType } from "features/common/models";
import { DictionaryItem, FilterMatchCount, WorkType } from "features/common/types";
import { isDateValid } from "features/common/utils";
import { FavoriteEmployeeResponseDto } from "features/favorite/dtos";
import {
    ApplicationDetailsDto,
    ApplicationHistoryItemDto,
    ApplicationsListDto,
    InterestedListDto,
    JobOfferCardDetailsResponseDto,
    JobOfferDetailsResponseDto,
    JobOfferDto,
    JobOfferResponseDto,
    JobOffersFilterMatchCountsResponseDto,
    MyJobOffersResponseDto,
    PaymentStartedDto,
} from "features/jobOffers/dtos";
import {
    Application,
    ApplicationDetails,
    Interested,
    JobOffer,
    JobOfferCardDetails,
    JobOfferDetails,
    MyJobOffersCardDetails,
    PaymentStatus,
} from "features/jobOffers/models";
import { CreateJobOfferSchema } from "features/jobOffers/schemas";
import {
    ApplicationTypes,
    EducationType,
    EmployerType,
    JobOffersFetchParams,
    JobOffersFilterMatchCounts,
    LanguageLevelType,
    MyJobOffersFetchParams,
    PayoutDayType,
} from "features/jobOffers/types";
import { mapOffersFetchParamsToQueryParameters, mapOffersQuerySearchToFetchParams } from "features/offers/mappers";
import { AmenityType, OfferStatus } from "features/offers/types";

const employerTypeFilterName = "employerType";
const minimalRewardValueFilterName = "minimalRewardValue";
const minimalRewardCurrencyFilterName = "minimalRewardCurrencyCode";
const minimalBonusValueFilterName = "minimalBonusValue";
const minimalBonusCurrencyFilterName = "minimalBonusCurrencyCode";
const businessCardIdFilterName = "businessCardId";

export const mapJobOffersDtoToJobOffers = (dto: JobOfferDto[]): Array<JobOffer | undefined> => {
    return dto.map((jobOffer) => ({
        ...jobOffer,
        imageUrl: jobOffer.mainImage,
        status:
            jobOffer.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : jobOffer.offerStatus === "INACTIVE"
                ? OfferStatus.Inactive
                : OfferStatus.Ended,
        addedDate: mapUtcStringToLocalDate(jobOffer.addedDate),
        amenities: (Object.keys(jobOffer.amenities) as Array<keyof typeof jobOffer.amenities>)
            .filter((amenity: keyof typeof AmenityType) => {
                return !!jobOffer.amenities[amenity];
            })
            .map((amenityKey: keyof typeof AmenityType) => ({
                amenityType: amenityKey as AmenityType,
                value: `${jobOffer.amenities[amenityKey]}`,
            })),
    }));
};

export const mapJobOffersFetchParamsToQueryParameters = (
    params: JobOffersFetchParams,
    withPaginationParameters: boolean = true,
    withFilterParameters: boolean = true,
    isUrlMapper: boolean = true
) => {
    // console.log('mapJobOffersFetchParamsToQueryParameters', params);

    let queryParams = mapOffersFetchParamsToQueryParameters(params, withPaginationParameters, isUrlMapper);

    if (withFilterParameters) {
        if (params.filters.minimalBonusValue && params.filters.minimalBonusCurrencyCode) {
            queryParams += `&${minimalBonusValueFilterName}=${params.filters.minimalBonusValue}&${minimalBonusCurrencyFilterName}=${params.filters.minimalBonusCurrencyCode}`;
        }
        if (params.filters.minimalSalaryValue && params.filters.minimalSalaryCurrencyCode) {
            queryParams += `&${minimalRewardValueFilterName}=${params.filters.minimalSalaryValue}&${minimalRewardCurrencyFilterName}=${params.filters.minimalSalaryCurrencyCode}`;
        }

        if (params.filters.businessCardId) {
            queryParams += `&${businessCardIdFilterName}=${params.filters.businessCardId}`;
        }

        for (const employerType of params.filters.employerType) {
            queryParams += `&${employerTypeFilterName}=${employerType}`;
        }
    }

    if (queryParams && queryParams[0] !== "?") {
        queryParams = queryParams.replace("&", "?");
    }

    // console.log('mapJobOffersFetchParamsToQueryParameters RETURN', queryParams);

    return queryParams;
};

export const mapMyJobOffersFetchParamsToQueryParameters = (params: MyJobOffersFetchParams): string => {
    let queryParams = `?page=${params.page}&size=${params.size}`;

    if (params.sort) {
        queryParams += `&sort=${params.sort}`;
    }

    if (params.sortBy) {
        queryParams += `&sort_by=${params.sortBy}`;
    }

    if (params.status) {
        queryParams += `&status=${params.status}`;
    }

    return queryParams;
};

export const mapJobOffersQuerySearchToFetchParams = (
    querySearch: string,
    currentFetchParams: JobOffersFetchParams,
    currencies: Currency[],
    branches: DictionaryItem[],
    ignoreOffset: boolean = false
): JobOffersFetchParams => {
    const baseParams = mapOffersQuerySearchToFetchParams(querySearch, currentFetchParams, branches, ignoreOffset);
    const params: JobOffersFetchParams = { ...baseParams, filters: { ...baseParams.filters, employerType: [] } };

    const employerTypeMatches = querySearch.match(new RegExp(`${employerTypeFilterName}=[\\d]`, "g")) ?? [];
    for (const match of employerTypeMatches) {
        params.filters.employerType.push(mapStringCodeToEmployerType(match.split("=")[1]));
    }

    // const startDateMatch = querySearch.match(new RegExp(`${startDateFilterName}=${utcStringDateFormat}`));
    // params.filters.startDate = startDateMatch ? mapUtcStringToLocalDate(startDateMatch[0].split("=")[1]) : undefined;

    const minimalRewardValueMatch = querySearch.match(
        new RegExp(`${minimalRewardValueFilterName}=${monetaryAmountRegex}`)
    );
    params.filters.minimalSalaryValue = minimalRewardValueMatch
        ? parseFloat(minimalRewardValueMatch[0].split("=")[1])
        : undefined;

    const minimalRewardCurrencyMatch = querySearch.match(new RegExp(`${minimalRewardCurrencyFilterName}=[A-Z]+`));
    params.filters.minimalSalaryCurrencyCode = minimalRewardCurrencyMatch
        ? minimalRewardCurrencyMatch[0].split("=")[1]
        : undefined;

    const minimalBonusValueMatch = querySearch.match(new RegExp(`${minimalBonusValueFilterName}=[\\d]+`));
    params.filters.minimalBonusValue = minimalBonusValueMatch
        ? parseInt(minimalBonusValueMatch[0].split("=")[1])
        : undefined;

    const minimalBonusCurrencyMatch = querySearch.match(new RegExp(`${minimalBonusCurrencyFilterName}=[A-Z]+`));
    params.filters.minimalBonusCurrencyCode = minimalBonusCurrencyMatch
        ? minimalBonusCurrencyMatch[0].split("=")[1]
        : undefined;

    const companyNameMatch = querySearch.match(new RegExp(`${businessCardIdFilterName}=[\\S]+`));
    params.filters.businessCardId = companyNameMatch ? companyNameMatch[0].split("=")[1] : undefined;

    return params;
};

export const mapJobOffersFilterMatchCountsDtoToJobOffersFilterMatchCounts = (
    dto: JobOffersFilterMatchCountsResponseDto
): JobOffersFilterMatchCounts => ({
    ...dto.jobOffersFilterMatchCounts,
    byEmployerType: dto.jobOffersFilterMatchCounts.byEmployerType.map((type) => ({
        ...type,
        code: mapStringCodeToEmployerType(type.code),
    })),
});

export const mapJobOfferDetailsDtoToJobOfferDetails = (dto: JobOfferDetailsResponseDto): JobOfferDetails => {
    return {
        ...dto.data,
        addedDate: mapUtcStringToLocalDate(dto.data.addedDate),
        expirationDate: mapUtcStringToLocalDate(dto.data.expirationDate)!,
        startDate: mapUtcStringToLocalDate(dto.data.jobStartDate)!,
        endDate: dto.data.jobEndDate ? mapUtcStringToLocalDate(dto.data.jobEndDate) : undefined,
        photoUrls: dto.data.images,
        /*languageRequirements?: (dto.data.languageRequirements as any).map((language: any) => ({
            languageType: language.name,
            languageLevelType: language.level,
        })),*/
        status:
            dto.data.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : dto.data.offerStatus === "INACTIVE"
                ? OfferStatus.Inactive
                : OfferStatus.Ended,
        amenities: (Object.keys(dto.data.amenities) as Array<keyof typeof dto.data.amenities>)
            .filter((amenity: keyof typeof AmenityType) => {
                return !!dto.data.amenities[amenity];
            })
            .map((amenityKey: keyof typeof AmenityType) => ({
                amenityType: amenityKey as AmenityType,
                value: `${dto.data.amenities[amenityKey]}`,
            })),
        imageUrl: dto.data.mainImage,
        businessCardData: {
            businessName:
                dto.businessCardDetails?.data.business_name ||
                `${dto.businessCardDetails?.data.firstname} ${dto.businessCardDetails?.data.lastname}`,
            logo: dto.businessCardDetails?.data.logo,
        },
    };
};

export const mapStringCodeToEmployerType = (value: string) => {
    switch (value) {
        case "2":
            return EmployerType.Company;
        case "3":
            return EmployerType.Private;
        default:
            return EmployerType.Company;
    }
};

export const mapMyJobOffersDtoToMyJobOffersCardDetails = (dto: MyJobOffersResponseDto): MyJobOffersCardDetails[] =>
    dto.data.content.map((offer) => ({
        ...offer,
        publishDate: new Date(offer.addedDate),
        imageUrl: offer.mainImage,
        status:
            offer.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : offer.offerStatus === "INACTIVE"
                ? OfferStatus.Inactive
                : OfferStatus.Ended,
        applicationsCount: offer.applicationsWithUnacknowledgedChanges,
        messagesCount: offer.messagesNumber,
    }));

export const mapMyJobOffersDtoToMyJobOffersCardDetailsKeyValue = (
    dto: MyJobOffersResponseDto
): { key: number; value: MyJobOffersCardDetails }[] =>
    dto.data.content.map((offer) => ({
        key: offer.id,
        value: {
            ...offer,
            publishDate: new Date(offer.addedDate),
            imageUrl: offer.mainImage,
            status:
                offer.offerStatus === "ACTIVE"
                    ? OfferStatus.Active
                    : offer.offerStatus === "INACTIVE"
                    ? OfferStatus.Inactive
                    : OfferStatus.Ended,
            applicationsCount: offer.applicationsWithUnacknowledgedChanges,
            messagesCount: offer.messagesNumber,
        },
    }));

export const mapJobOfferCardDetailsDtoToOfferCardDetails = (
    dto: JobOfferCardDetailsResponseDto
): JobOfferCardDetails => {
    return {
        ...dto.jobOfferCardDetails,
        addedDate: mapUtcStringToLocalDate(dto.jobOfferCardDetails.addedDate),
        expirationDate: mapUtcStringToLocalDate(dto.jobOfferCardDetails.expirationDate)!,
        startDate: mapUtcStringToLocalDate(dto.jobOfferCardDetails.jobStartDate)!,
        endDate: dto.jobOfferCardDetails.jobEndDate
            ? mapUtcStringToLocalDate(dto.jobOfferCardDetails.jobEndDate)
            : undefined,
        status:
            dto.jobOfferCardDetails.offerStatus === "ACTIVE"
                ? OfferStatus.Active
                : dto.jobOfferCardDetails.offerStatus === "INACTIVE"
                ? OfferStatus.Inactive
                : OfferStatus.Ended,
    };
};

export const mapJobOffersResponseDtoToJobOffersFilterMatchCounts = ({
    data,
}: JobOfferResponseDto | FavoriteEmployeeResponseDto): JobOffersFilterMatchCounts => {
    if (data.employerTypeWithCount) {
        return {
            byEmployerType: (
                Object.keys(data.employerTypeWithCount) as Array<keyof typeof data.employerTypeWithCount>
            ).map(
                (employerType: EmployerType): FilterMatchCount<EmployerType> => ({
                    code: employerType,
                    matchCount: data.employerTypeWithCount[employerType],
                })
            ),
            byBranch: Object.keys(data.branchesWithCount).map(
                (id: string): FilterMatchCount<number> => ({
                    code: +id,
                    matchCount: data.branchesWithCount[id],
                })
            ),
            byWorkType: (Object.keys(data.workTypesWithCount) as Array<keyof typeof data.workTypesWithCount>).map(
                (workType: WorkType): FilterMatchCount<WorkType> => ({
                    code: workType,
                    matchCount: data.workTypesWithCount[workType],
                })
            ),
        };
    } else {
        return {
            byEmployerType: [],
            byBranch: Object.keys(data.branchesWithCount).map(
                (id: string): FilterMatchCount<number> => ({
                    code: +id,
                    matchCount: data.branchesWithCount[id],
                })
            ),
            byWorkType: [],
        };
    }
};

export const mapApplicationDtoToApplicationSubmission = (
    selectedCard: string,
    description?: string,
    applicationType?: ApplicationTypes,
    applicantsNumber?: number,
    positionsAvailable?: number
) => {
    if ((positionsAvailable || 0) > 1) {
        return {
            businessCardId: +selectedCard,
            content: description,
            applicationType,
            applicantsNumber,
        };
    }

    return {
        businessCardId: +selectedCard,
        content: description,
    };
};

export const mapInterestedListDtoToInterestedList = (listDto: InterestedListDto): Interested[] =>
    listDto.data.map(({ conversation }) => ({
        userName:
            conversation.recipient.userId === listDto.currentUserId
                ? `${conversation.initiator.firstname} ${conversation.initiator.lastname[0]}.`
                : `${conversation.recipient.firstname} ${conversation.recipient.lastname[0]}.`,
        messagesNumber: conversation.messages.length,
        lastMessageTimestamp: new Date(
            conversation.messages.sort(
                (a, b) => getTime(new Date(b.timestamp)) - getTime(new Date(a.timestamp))
            )[0]?.timestamp
        ),
        conversationId: conversation.conversationId,
    }));

export const mapApplicationsListDtoToApplicationsList = (listDto: ApplicationsListDto): Application[] =>
    listDto.data.map((dto) => ({
        ...dto,
        jobApplicant: `${dto.jobApplicant.firstname} ${dto.jobApplicant.lastname[0]}.`,
        applicationDate: new Date(dto.applicationDate),
    }));

export const mapApplicationDetailsDtoToApplicationDetails = (dto: ApplicationDetailsDto): ApplicationDetails => ({
    ...dto.data,
    applicationDate: new Date(dto.data.applicationDate),
    jobOffer: dto.data.jobOffer,
    jobApplicant: dto.data.jobApplicant
        ? {
              userId: dto.data.jobApplicant?.userId,
              firstName: dto.data.jobApplicant?.firstname,
              lastName: dto.data.jobApplicant?.lastname,
              businessCardId: dto.data.jobApplicant?.businessCardId,
              type: dto.data.jobApplicant?.type,
          }
        : undefined,
    offerOwner: dto.data.offerOwner
        ? {
              userId: dto.data.offerOwner?.userId,
              firstName: dto.data.offerOwner?.firstname,
              lastName: dto.data.offerOwner?.lastname,
              businessCardId: dto.data.offerOwner?.businessCardId,
          }
        : undefined,
    jobApplicationHistory: dto.data.jobApplicationHistory.map((historyItem: ApplicationHistoryItemDto) => ({
        ...historyItem,
        changeDate: new Date(historyItem.changeDate),
    })),
    jobOfferBusinessName: dto.data.jobOfferBusinessName,
});

export const mapCreateJobOfferSchemaToFormData = (
    values: CreateJobOfferSchema,
    offerLength: string,
    businessCardId: number,
    uploadedImages: { fileName?: string }[] = [],
    dedicatedUserId?: string
) => {
    const isDedicated = dedicatedUserId !== undefined;
    const requirements = [];

    if (values.requirementsStep.certificates.length) {
        requirements.push({
            requirementType: "CERTIFICATES_AND_LICENSES",
            valueArray: values.requirementsStep.certificates.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.programs.length) {
        requirements.push({
            requirementType: "COMPUTER_AND_SOFTWARE",
            valueArray: values.requirementsStep.programs.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.other.length) {
        requirements.push({
            requirementType: "OTHER",
            valueArray: values.requirementsStep.other.map((item: any) => item.value),
        });
    }

    if (values.requirementsStep.permissions.length) {
        requirements.push({
            requirementType: "PERMISSIONS",
            valueArray: values.requirementsStep.permissions.map((item: any) => item.value),
        });
    }

    const uploaded = uploadedImages.filter((file) => file?.fileName).map((file) => file.fileName) || [];
    const prevPhoto = values.detailsStep.photos.filter((photo) => photo.fileName).map((file) => file.fileName) || [];

    return {
        jobOfferDetails: {
            additionalInfo: values.additionalInfoStep.additionalInfo || "",
            amenities: {
                accommodation: !!values.amenitiesStep.accommodation,
                assistInLegalizingStay: !!values.amenitiesStep.assistanceInLegalizingStay,
                freeMedicalExam: !!values.amenitiesStep.freeMedicalExaminations,
                meals: !!values.amenitiesStep.catering,
                other: values.amenitiesStep.other ? values.amenitiesStep.otherAmenitiesText : undefined,
                transport: !!values.amenitiesStep.transport,
                workBoots: !!values.amenitiesStep.workBoots,
                workWear: !!values.amenitiesStep.workWear,
            },
            availablePositionsNumber: values.offerDetailsStep.positionsAvailable || 0,
            branchId: +values.offerDetailsStep.branchId,
            businessCardId,
            employerType: values.detailsStep.offerAs,
            expirationDate: toDate(addMonths(new Date(), 1)).toISOString(),
            jobEndDate: toDate(addMonths(new Date(), 1)).toISOString(),
            financialConditions: {
                bonusCurrencyCode: values.financialStep.attachBonus
                    ? values.financialStep.bonusCurrencyCode || ""
                    : "PLN",
                bonusPayoutDateType: values.financialStep.attachBonus
                    ? values.financialStep.bonusType || PayoutDayType.FirstDay
                    : PayoutDayType.FirstDay,
                bonusValue: values.financialStep.attachBonus ? values.financialStep.bonusValue || 0 : 0,
                paymentRateType: values.financialStep.salaryType || SalaryType.Hourly,
                rewardCurrencyCode: values.financialStep.salaryCurrencyCode || "",
                rewardMinimumValue: values.financialStep.salaryMinValue || 0,
                rewardMaximumValue: values.financialStep.salaryMaxValue || null,
            },
            images: [...uploaded, ...prevPhoto],
            // jobEndDate:
            //     values.offerDetailsStep.workType === WorkType.SeasonalTemporary
            //         ? isDateValid(values.offerDetailsStep.jobEndDate)
            //             ? toDate(values.offerDetailsStep.jobEndDate as Date).toISOString()
            //             : new Date().toISOString()
            //         : undefined,
            jobStartDate:
                isDateValid(values.offerDetailsStep.jobStartDate) &&
                toDate(values.offerDetailsStep.jobStartDate as Date) > new Date()
                    ? toDate(addDays(values.offerDetailsStep.jobStartDate as Date, 1)).toISOString()
                    : new Date().toISOString(),
            languageRequirements:
                values.requirementsStep.languages
                    ?.filter((language) => !!language.isChecked)
                    .map((language) => ({
                        level: language.level || LanguageLevelType.Beginner,
                        name: language.id,
                    })) || [],
            placeId: values.offerDetailsStep.location.placeId,
            mainImage:
                uploadedImages.length > 0 ? uploadedImages[0]?.fileName : prevPhoto.length > 0 ? prevPhoto[0] : "",
            position: values.offerDetailsStep.position,
            requirements: [
                ...requirements,
                ...values.requirementsStep.experience.map((value) => ({
                    requirementType: "EXPERIENCE",
                    value: value || "",
                })),
                ...values.requirementsStep.drivingLicenses.map((value) => ({
                    requirementType: "DRIVING_LICENSE",
                    value: value || "",
                })),
                ...values.requirementsStep.education
                    .filter((item) => item.isChecked)
                    .map((value) =>
                        value.id === EducationType.Other
                            ? {
                                  requirementType: "EDUCATION_CUSTOM",
                                  value: value.otherValue || "",
                              }
                            : {
                                  requirementType: "EDUCATION",
                                  value: value.id?.toUpperCase() || "",
                              }
                    ),
            ],
            title: values.offerDetailsStep.position,
            workType: values.offerDetailsStep.workType,
            dedicatedBusinessCardId: dedicatedUserId,
            isDedicated,
            offerActiveTime: +offerLength || 7,
        },
        businessCard: {
            business_name: values.detailsStep.businessName,
            placeId: values.detailsStep.location.placeId,
            kraz_certificate_number: values.detailsStep.krazCertificateNumber,
            tax_identification_number: values.detailsStep.taxIdentificationNumber,
            phone_number: values.detailsStep.phoneNumber,
            phone_number_prefix: values.detailsStep.phonePrefix,
        },
        businessCardId,
    };
};

export const mapPaymentStatusDtoToPaymentStatus = (data: PaymentStartedDto): PaymentStatus => ({
    orderId: data?.data?.order_id,
    status: data?.data?.status || data?.data?.payment_status || "SUCCESS",
    reason: data?.data?.reason,
    redirectUrl: data?.data?.redirect_url,
});
