import { ApiError } from "api/utils";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import OfferDetails from "features/employeeOffers/components/EmployeeOfferDetails/OfferDetails";
import DetailsActionsBox from "../DetailsActionsBox";
import React from "react";

interface Props {
    id: number;
    employeeOfferDetails: EmployeeOfferDetails;
    employeeOfferDetailsLoading?: boolean;
    employeeOfferDetailsLoadingError?: ApiError;
    resetOfferDetails: () => void;
}

const MyEmployeeOfferDetailsPreview = ({ id, employeeOfferDetails, employeeOfferDetailsLoading, resetOfferDetails }: Props) => {
    return (
        <OfferDetails
            offerDetailsLoading={!!employeeOfferDetailsLoading}
            isUserLoggedIn={true}
            offerDetails={employeeOfferDetails}
            actionsBox={
                <DetailsActionsBox
                    employeeOfferDetails={employeeOfferDetails}
                    data-testid="offer-details"
                    offerId={id}
                    resetOfferDetails={resetOfferDetails}
                />
            }
            myOfferActionBox={
                <DetailsActionsBox
                    employeeOfferDetails={employeeOfferDetails}
                    data-testid="offer-details"
                    offerId={id}
                    isTopBox
                />
            }
        />
    );
};

export default MyEmployeeOfferDetailsPreview;
