import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ApiError, CancelToken } from "api/utils";
import Button from "features/common/components/Button";
import Modal from "features/common/components/Modal";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import TextArea from "features/common/components/TextArea";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { getSendMessageInformationMessage } from "features/offers/translationMessages";

import styles from "./styles.module.scss";
import { ReactComponent as TimesIcon } from "assets/icons/times.svg";

const maxMessageLength = 250;

export interface Props {
    id: number;
    acceptArrivalLoading: boolean;
    acceptArrivalSuccess?: boolean;
    acceptArrivalError?: ApiError;
    acceptArrivalAsync: (id: number, message?: string, cancelToken?: CancelToken) => void;
    handleCloseModal: () => void;
}

const AcceptArrivalModal = ({
    id,
    acceptArrivalLoading,
    acceptArrivalSuccess,
    acceptArrivalError,
    acceptArrivalAsync,
    handleCloseModal,
}: Props) => {
    const intl = useIntl();
    const createCancelToken = useCreateCancelToken();
    const [messageValue, setMessageValue] = useState("");

    const onMessageChange = useCallback((event) => setMessageValue(event.currentTarget.value), [setMessageValue]);
    const handleSubmit = useCallback(() => {
        acceptArrivalAsync(id, messageValue, createCancelToken());
    }, [acceptArrivalAsync, createCancelToken, id, messageValue]);

    const sendMessageInformation = getSendMessageInformationMessage(intl, maxMessageLength);

    useEffect(() => {
        if (acceptArrivalSuccess) {
            handleCloseModal();
        }
    }, [acceptArrivalSuccess, handleCloseModal]);

    return (
        <Modal className={styles["accept-arrival-modal"]} parentId="accept-arrival-modal">
            {acceptArrivalLoading ? (
                <SpinnerWithMessage message={<FormattedMessage id="accept-arrival-modal__loading-accept-arrival" />} />
            ) : (
                <div className={styles["accept-arrival-modal__container"]}>
                    <div className={styles["accept-arrival-modal__close-button"]} onClick={handleCloseModal}>
                        <TimesIcon />
                    </div>
                    <div className={styles["accept-arrival-modal__title"]}>
                        <FormattedMessage id="accept-arrival-modal__title" />
                    </div>
                    <div className={styles["accept-arrival-modal__subtitle"]}>
                        <FormattedMessage id="accept-arrival-modal__subtitle" />
                    </div>
                    <TextArea
                        maxSize={maxMessageLength}
                        placeholder={intl.formatMessage({ id: "accept-arrival-modal__message-placeholder" })}
                        value={messageValue}
                        error={
                            !!acceptArrivalError
                                ? intl.formatMessage({ id: "accept-arrival-modal__acceptation-error" })
                                : ""
                        }
                        onChange={onMessageChange}
                        withValidation
                        isInvalid={!!acceptArrivalError}
                        data-testid="accept-arrival-modal__message-modal"
                        className={styles["accept-arrival-modal__text-area"]}
                        customWarningMessage={sendMessageInformation}
                    />
                    <div className={styles["accept-arrival-modal__buttons-container"]}>
                        <Button
                            data-testid="accept-arrival-modal__button"
                            className={styles["accept-arrival-modal__button"]}
                            type="submit"
                            variant="tertiary"
                            onClick={handleCloseModal}
                        >
                            <FormattedMessage id="accept-arrival-modal__cancel" />
                        </Button>
                        <Button
                            data-testid="accept-arrival-modal__button"
                            className={styles["accept-arrival-modal__button"]}
                            type="submit"
                            onClick={handleSubmit}
                        >
                            <FormattedMessage id="accept-arrival-modal__accept" />
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default AcceptArrivalModal;
