import { SalaryType } from "features/common/models";
import { FilterMatchCount, MessageType, Tag, WorkType } from "features/common/types";

export interface OffersFilters {
    branchId: number[];
    workType: WorkType[];
    paymentRateType?: SalaryType;
    employerType: string[];
    businessCardId?: string;
}

export interface OffersFetchParams {
    filters: OffersFilters;
    search?: OffersSearchParams;
    pageLimit: number;
    offset: number;
    amendOffers: boolean;
    isFavorite: boolean;
}

export interface OffersFilterMatchCounts {
    byBranch: Array<FilterMatchCount<number>>;
    byWorkType?: Array<FilterMatchCount<WorkType>>;
}

export interface OffersSearchParams {
    city?: string;
    location: Tag[];
    placeId?: string;
    distance: number;
    term?: { rawInputText: string; selectedSuggestionValue: string; section: string; fuzzySearch?: boolean };
}

export interface OfferMessageParams {
    offerId: number;
    message: string;
    messageType: MessageType;
}

export enum OfferKind {
    Individual = 0,
    Group,
}

export enum OfferStatus {
    Active,
    Inactive,
    Ended,
}

export enum OfferType {
    Single,
    Group,
}

export type CreateOfferStepName = "detailsStep" | "offerDetailsStep" | "amenitiesStep" | "financialStep";

export type PublishOfferModalMode = "errors" | "published" | "saved";

export type CreateOfferApiError = {
    code: string;
    value: string;
    details: string;
};

export type CreateOfferComponentError = {
    errors: string[];
};

export type Amenity = {
    amenityType: AmenityType;
    value: string | null;
};

export type Location = {
    id?: number;
    city?: string;
    country?: string;
    location?: {
        latitude?: number
        longitude?: number
    };
    northeast?: {
        latitude?: number
        longitude?: number
    };
    southwest?: {
        latitude?: number
        longitude?: number
    };
    regionLevel1?: string;
    regionLevel2?: string;
    regionLevel3?: string;
    street?: string;
    number?: string;
};
export type LocationSlimDTO = {
    city?: string;
    id?: string;
    country?: string;
    empty?: boolean;
};
export type FinancialConditions = {
    bonusCurrencyCode: string | undefined;
    bonusPayoutDateType?: any;
    bonusValue: number;
    id?: number;
    paymentRateType: SalaryType;
    rewardCurrencyCode: string;
    rewardMaximumValue?: number;
    rewardMinimumValue: number;
};

export type FinancialConditionsSlimDTO = {
    bonusCurrencyCode: string | undefined;
    bonusValue: number;
    paymentRateType: SalaryType | any;
    rewardCurrencyCode: string;
    rewardMinimumValue: number;
}

export enum AmenityType {
    accommodation = "accommodation",
    transport = "transport",
    catering = "meals",
    workWear = "workWear",
    workBoots = "workBoots",
    freeMedicalExaminations = "freeMedicalExam",
    assistanceInLegalizingStay = "assistInLegalizingStay",
    other = "other",
}

export enum MyOffersCategory {
    All,
    Active,
    Inactive,
    Ended,
}
