import { ReactComponent as ChevronDown } from "assets/icons/chevronDown.svg";
import { ReactComponent as ExclamationCircleIcon } from "assets/icons/exclamation-circle.svg";
import classNames from "classnames";
import { DropdownOption } from "features/common/types";
import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import styles from "./styles.module.scss";

export type Props<T> = {
    id: string;
    className?: string;
    selected?: DropdownOption<T>;
    toggle?: React.ReactElement;
    toggleClassName?: string;
    optionClassName?: string;
    optionsClassName?: string;
    placeholderToggleClassName?: string;
    selectedOptionClassName?: string;
    options: DropdownOption<T>[];
    placeholder?: string;
    disabled?: boolean;
    isInvalid?: boolean;
    ["data-testid"]?: string;
    onSelect?: (value: T) => void;
    onToggle?: (expanded: boolean) => void;
};

const Dropdown = <T extends any>({
    id,
    className,
    selected,
    toggle,
    toggleClassName,
    optionClassName,
    optionsClassName,
    placeholderToggleClassName,
    selectedOptionClassName,
    options,
    placeholder,
    disabled,
    isInvalid,
    "data-testid": testId,
    onSelect,
    onToggle,
}: Props<T>) => {
    const [expanded, setExpanded] = useState(false);

    const getToggleDisplay = () => {
        if (selected) {
            if (selected.display) {
                return selected.display;
            } else {
                return selected.value;
            }
        } else {
            return <span>{placeholder ?? ""}</span>;
        }
    };

    const onExpandToggle = () => {
        onToggle && onToggle(!expanded);
        setExpanded((expanded) => !expanded);
    };

    const onClickAway = () => {
        if (expanded) {
            onToggle && onToggle(false);
            setExpanded(false);
        }
    };

    const onOptionClick = (option: DropdownOption<T>) => {
        !option.disabled && onSelect && onSelect(option.value);
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div
                data-testid={testId}
                id={id}
                className={classNames(styles["dropdown"], className, {
                    [styles["dropdown--disabled"]]: disabled,
                })}
                onClick={() => onExpandToggle()}
            >
                <div
                    data-testid={`${testId}__toggle`}
                    className={classNames(
                        styles["dropdown__toggle"],
                        toggleClassName,
                        !selected ? placeholderToggleClassName : "",
                        {
                            [styles["dropdown__toggle--placeholder"]]: !selected,
                        }
                    )}
                >
                    {toggle ? (
                        toggle
                    ) : (
                        <>
                            {getToggleDisplay()}
                            <div className={styles["dropdown__icons-container"]}>
                                <ChevronDown
                                    className={classNames(styles["dropdown__icon"], {
                                        [styles["dropdown__icon--rotated"]]: expanded,
                                    })}
                                />
                                {isInvalid && (
                                    <ExclamationCircleIcon
                                        data-testid={`${testId}__error-icon`}
                                        className={styles["dropdown__error-icon"]}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
                {expanded && (
                    <div
                        data-testid={`${testId}__options`}
                        className={classNames(styles["dropdown__options"], optionsClassName)}
                    >
                        {options.map((option) => (
                            <div
                                data-testid={`${testId}__option`}
                                className={classNames(
                                    styles["dropdown__option"],
                                    { [styles["dropdown__option--disabled"]]: option.disabled },
                                    optionClassName,
                                    option.value === selected?.value ? selectedOptionClassName : ""
                                )}
                                key={option.value as any}
                                onClick={() => onOptionClick(option)}
                            >
                                {option.display ?? (option.value as any)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default Dropdown;
