import React, { useCallback, useMemo, useState } from "react";
import { useIntl, IntlShape, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { appRoutes } from "features/routing/routes";
import FormDropdown from "features/common/components/FormDropdown";
import ModalWithContent from "features/common/components/ModalWithContent";
import CardStep from "features/jobOffers/components/JobOfferDetails/DetailsActionsBox/ApplicationSubmission/CardStep";

import styles from "./styles.module.scss";

const createApplicationTypeDropdownOptions = (intl: IntlShape) => [
    {
        value: "1",
        display: intl.formatMessage({ id: "application-submission__new-card" }),
    },
];

const createCardTypeSelectedOption = (intl: IntlShape) => ({
    value: "0",
    display: intl.formatMessage({ id: "application-submission__existing-card" }),
});

const createOfferLengthDropdownOptions = (intl: IntlShape) => [
    {
        value: "7",
        display: intl.formatMessage({ id: "create-offer__7-days-length" }),
    },
    {
        value: "14",
        display: intl.formatMessage({ id: "create-offer__14-days-length" }),
    },
    {
        value: "30",
        display: intl.formatMessage({ id: "create-offer__30-days-length" }),
    },
    {
        value: "60",
        display: intl.formatMessage({ id: "create-offer__60-days-length" }),
    },
    {
        value: "90",
        display: intl.formatMessage({ id: "create-offer__90-days-length" }),
    },
];

export interface Props {
    userId?: string;
    isVisible: boolean;
    asJobOffersList?: boolean;
    handleCloseModal: () => void;
}

const CreateJobOfferModal = ({ isVisible, asJobOffersList, handleCloseModal }: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const [selectedBusinessCardId, setSelectedBusinessCardId] = useState<string | undefined>();

    const [selectedOfferLength, setSelectedOfferLength] = useState<string | undefined>();

    const handleGoToCreateJobOffer = useCallback(() => {
        if (selectedBusinessCardId) {
            history.push(
                `${asJobOffersList ? appRoutes.createJobOffer : appRoutes.createEmployeeOffer
                }/${selectedBusinessCardId}/${selectedOfferLength}`
            );
        }
    }, [selectedBusinessCardId, history, asJobOffersList, selectedOfferLength]);

    const handleChangeApplicationType = useCallback(() => {
        if (asJobOffersList) {
            history.push(`${appRoutes.createJobOfferNewCard}/${selectedOfferLength}`);
        } else {
            history.push(`${appRoutes.createEmployeeOfferNewCard}/${selectedOfferLength}`);
        }
    }, [asJobOffersList, history, selectedOfferLength]);

    const cardTypeSelectedOption = useMemo(() => createCardTypeSelectedOption(intl), [intl]);
    const applicationTypeDropdownOptions = useMemo(() => createApplicationTypeDropdownOptions(intl), [intl]);

    const modalHeader = useMemo(
        () => (
            <div className={styles["create-job-offer-modal__header"]}>
                <FormattedMessage
                    id={asJobOffersList ? "create-job-offer-modal__header" : "create-employee-offer-modal__header"}
                    values={{ stepNumber: 1 }}
                />
            </div>
        ),
        [asJobOffersList]
    );

    const offerLengthDropdownOptions = useMemo(() => createOfferLengthDropdownOptions(intl), [intl]);

    const handleChangeOfferLength = (value: string) => setSelectedOfferLength(value);

    const modalContent = useMemo(
        () => (
            <div className={styles["create-job-offer-modal__content"]}>
                <FormDropdown<string>
                    data-testid="create-job-offer-modal__offer-length-dropdown"
                    id="create-job-offer-modal__offer-length-dropdown"
                    className={styles["create-job-offer-modal__offer-length-dropdown"]}
                    optionsClassName={styles["create-job-offer-modal__offer-length-dropdown-options"]}
                    options={offerLengthDropdownOptions}
                    selected={offerLengthDropdownOptions.find((item) => item.value === selectedOfferLength)}
                    onSelect={handleChangeOfferLength}
                    label={intl.formatMessage({ id: "create-offer__offer-length" })}
                    placeholder={intl.formatMessage({ id: "create-offer__offer-length" })}
                />
                <FormDropdown
                    data-testid="create-job-offer-modal__card-type-dropdown"
                    id="create-job-offer-modal__card-type-dropdown"
                    optionsClassName={styles["create-job-offer-modal__card-type-dropdown-options"]}
                    options={applicationTypeDropdownOptions}
                    selected={cardTypeSelectedOption}
                    onSelect={handleChangeApplicationType}
                    disabled={!selectedOfferLength}
                />
                <CardStep
                    data-testid={`create-job-offer-modal__card-step`}
                    selectedCard={selectedBusinessCardId}
                    setSelectedCard={setSelectedBusinessCardId}
                    asJobOffersList={asJobOffersList}
                />
            </div>
        ),
        [applicationTypeDropdownOptions, cardTypeSelectedOption, handleChangeApplicationType, intl, offerLengthDropdownOptions, selectedBusinessCardId, selectedOfferLength]
    );

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles["create-job-offer-modal"]}>
            <ModalWithContent
                data-testid="create-job-offer-modal__modal"
                className={styles["create-job-offer-modal__modal"]}
                cancelButtonHidden
                primaryButtonContent={intl.formatMessage({ id: "common__next" })}
                onClose={handleCloseModal}
                secondaryAction={handleCloseModal}
                primaryAction={handleGoToCreateJobOffer}
                primaryButtonDisabled={!selectedBusinessCardId || !selectedOfferLength}
                isScrollable
                header={modalHeader}
                content={modalContent}
            />
        </div>
    );
};

export default CreateJobOfferModal;
