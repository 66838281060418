import { ReactComponent as ArrowNextOffers } from "assets/icons/arrowNextOffers.svg";
import { ReactComponent as ArrowPrevOffers } from "assets/icons/arrowPrevOffers.svg";
import classNames from "classnames";
import config from "config";
import DefaultImage from "features/common/components/DefaultImage";
import useDateFormat from "features/common/hooks/useDateFormat";
import { EmployeeOfferDetails } from "features/employeeOffers/models";
import { JobOfferDetails } from "features/jobOffers/models";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import useDeviceClass from "../../hooks/useDeviceClass";
import styles from "./styles.module.scss";

export interface Props {
    offerDetails?: JobOfferDetails | EmployeeOfferDetails;
    photoUrls?: any[];
    ["data-testid"]?: string;
    onClick?: () => void;
    popUpMode?: boolean;
    setCurrentPhotoIndex?: (arg0: number) => void;
    currentPhotoIndex?: React.Dispatch<React.SetStateAction<number>>;
    businessCardView?: boolean;
}

export function ImagesBreadcrumbs(props: {
    photoUrls: any[];
    currentPhotoIndex?: any;
    setCurrentPhotoIndex?: (arg0: number) => void;
    customImageStyles?: any;
    editMode?: boolean;
    handleRemoveElement?: (url: string) => any;
}) {
    const testId = "photo-browser";
    const deviceClass = useDeviceClass();

    const onRemove = useCallback(
        (url: string) => {
            props.handleRemoveElement && props.handleRemoveElement(url);
        },
        [props]
    );

    const howManyBreadCrumbsShouldDisplay = deviceClass === "smartphone" ? 2 : 3
    const amountOfLeftPhotosToDisplay = props.photoUrls.length - (howManyBreadCrumbsShouldDisplay + 1)

    return (
        <div data-testid={testId ? `${testId}__photo-list` : undefined} className={styles["photo-browser__photo-list"]}>
            {props.photoUrls.map((url: any, index: any) => {
                if (props.editMode) {
                    return (
                        <div className={classNames(styles["photo-browser__element-list"])} key={index}>
                            <img
                                key={`${index}-${url}`}
                                data-testid={testId ? `${testId}__listed-photo` : undefined}
                                className={classNames(styles["photo-browser__listed-photo--editMode"], {
                                    [styles["photo-browser__listed-photo--current"]]: index === props.currentPhotoIndex,
                                })}
                                style={props.customImageStyles || {}}
                                src={`${config.IMAGE_URL}/${url}`}
                                alt=""
                                onClick={() =>
                                    index !== props.currentPhotoIndex &&
                                    props.setCurrentPhotoIndex &&
                                    props.setCurrentPhotoIndex(index)
                                }
                            />
                            {props.editMode && props.handleRemoveElement && (
                                <button
                                    className={classNames(styles["photo-browser__listed-photo__button"])}
                                    onClick={() => onRemove(url)}
                                >
                                    Usuń
                                </button>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <>
                            {index <= howManyBreadCrumbsShouldDisplay && (
                                <img
                                    key={`${index}-${url}`}
                                    data-testid={testId ? `${testId}__listed-photo` : undefined}
                                    className={classNames(styles["photo-browser__listed-photo"], {
                                        [styles["photo-browser__listed-photo--current"]]: index === props.currentPhotoIndex,
                                    })}
                                    style={props.customImageStyles || {}}
                                    src={`${config.IMAGE_URL}/${url}`}
                                    alt=""
                                    onClick={() =>
                                        index !== props.currentPhotoIndex &&
                                        props.setCurrentPhotoIndex &&
                                        props.setCurrentPhotoIndex(index)
                                    }
                                />
                            )}
                        </>
                    );
                }
            })}
            {amountOfLeftPhotosToDisplay > 0 &&
                <div className={styles["photo-browser__listed-photo__show-more"]}>
                    <p>
                        + {amountOfLeftPhotosToDisplay}
                    </p>
                </div>
            }
        </div>
    );
}

const PhotoBrowser = ({ offerDetails, photoUrls = [], "data-testid": testId = "photo-browser", onClick, businessCardView
}: Props) => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const getFormattedDate = useDateFormat("d.MM.yyyy");

    useEffect(() => {
        setCurrentPhotoIndex(1)
        setTimeout(() => {
            setCurrentPhotoIndex(0)
        }, 1)
    }, [])

    return (
        <div data-testid={testId} className={styles["photo-browser"]}>
            {!!offerDetails && <span className={styles["photo-browser__add-date-info-text"]} ><FormattedMessage id='offers-item__offer-added' /> {getFormattedDate(offerDetails?.addedDate)} </span>}
            {photoUrls.length > 0 && photoUrls[0] !== ''  ? (
                <>
                    <div
                        data-testid={testId ? `${testId}__current-photo-container` : undefined}
                        className={classNames(styles["photo-browser__current-photo-container"],
                            {
                                [styles["photo-browser__current-photo-container--card"]]: businessCardView
                            },
                        )
                        }
                        style={{ backgroundImage: `url(${photoUrls[currentPhotoIndex]})` }}
                    >
                        <img
                            src={`${config.IMAGE_URL}/${photoUrls[currentPhotoIndex]}`}
                            alt="main photo"
                            onClick={onClick}
                        />
                        {photoUrls.length > 1 &&
                            <>
                                <div
                                    className={styles["arrow-section--left"]}
                                    onClick={() =>
                                        setCurrentPhotoIndex((prev) =>
                                            prev > 0 ? (prev - 1) % photoUrls.length : photoUrls.length - 1
                                        )
                                    }
                                >
                                    <ArrowPrevOffers />
                                </div>
                                <div
                                    className={styles["arrow-section--right"]}
                                    onClick={() => setCurrentPhotoIndex((prev) => (prev + 1) % photoUrls.length)}
                                >
                                    <ArrowNextOffers />
                                </div>
                            </>
                        }
                    </div>
                    <ImagesBreadcrumbs
                        currentPhotoIndex={currentPhotoIndex}
                        setCurrentPhotoIndex={setCurrentPhotoIndex}
                        photoUrls={photoUrls}
                    ></ImagesBreadcrumbs>
                </>
            ) : (
                <DefaultImage realImage data-testid={`${testId}__placeholder`} />
            )
            }
        </div >
    );
};

export default PhotoBrowser;
