import { ApiError } from "api/utils";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const sendContactFormMessage = createAsyncAction(
    "SEND_CONTACT_FORM_MESSAGE_REQUEST",
    "SEND_CONTACT_FORM_MESSAGE_SUCCESS",
    "SEND_CONTACT_FORM_MESSAGE_FAILURE"
)<void, void, ApiError>();

export const closeContactFormConfirmationDialog = createAction("CLOSE_CONTACT_FORM_CONFIRMATION_DIALOG")<void>();

type ContactFormAction =
    | ActionType<typeof sendContactFormMessage>
    | ActionType<typeof closeContactFormConfirmationDialog>;

export default ContactFormAction;
