import {
    selectEmployeeOfferDetails,
    selectEmployeeOfferDetailsLoading,
    selectEmployeeOfferDetailsLoadingError,
} from "features/employeeOffers/selectors";
import { getEmployeeOfferDetailsAsync } from "features/employeeOffers/thunks";
import { selectIsUserLoggedIn } from "features/user/selectors";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import EmployeeOfferDetailsComponent from "./component";

const mapStateToProps = (state: RootState) => ({
    offerDetails: selectEmployeeOfferDetails(state),
    offerDetailsLoading: selectEmployeeOfferDetailsLoading(state),
    offerDetailsLoadingError: selectEmployeeOfferDetailsLoadingError(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getEmployeeOfferDetailsAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeOfferDetailsComponent);
