import { DropdownOption } from "features/common/types";
import { MessagesFilterOption } from "features/messages/types";
import _ from "lodash";

export const getMessageFiltersDropdownOptions = (
    recentFirstMessage: string,
    oldestFirstMessage: string,
): DropdownOption<MessagesFilterOption>[] => [
        {
            display: recentFirstMessage,
            value: "recentFirst",
        },
        {
            display: oldestFirstMessage,
            value: "oldestFirst",
        },
    ];

export const getTimeIntervalInMinutes = (firstDate: Date, secondDate: Date) =>
    Math.floor(Math.abs((firstDate.getTime() - secondDate.getTime()) / 1000) / 60);

export const isToday = (date: Date) => new Date().toDateString() === date.toDateString();

export const isYesterday = (date: Date) =>
    (new Date().setHours(0, 0, 0, 0) - date.setHours(0, 0, 0, 0)) / 1000 / 86400 === 1;

export const formatDateToString = (date: Date) =>
    `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
