import classNames from "classnames";
import { ReactComponent as GoogleLogoIcon } from "assets/icons/googleLogo.svg";
import config from "config";
import Button from "features/common/components/Button";
import React from "react";
import styles from "./styles.module.scss";

export function GoogleLoginButton(loginUserLoading: boolean, googleLoginMessage: string) {
    const style = classNames(styles["login-form__google-login-button"]);

    return <Button
        data-testid="login-form__google-login-button"
        as="a"
        className={style}
        href={config.GOOGLE_LOGIN_URL}
        disabled={loginUserLoading}
    >
        <GoogleLogoIcon />
        {googleLoginMessage}
    </Button>;
}
