import { createSelector } from "reselect";
import { RootState } from "store";
import { MessagesState } from "../reducers";

const selectState = (state: RootState) => state.messages;

export const selectConversations = createSelector(selectState, (state) => state.conversations);
export const selectConversationsLoading = createSelector(selectState, (state) => state.conversationsLoading);
export const selectConversationsLoadingError = createSelector(selectState, (state) => state.conversationsLoadingError);

export const selectTotalConversations = createSelector(selectState, (state) => state.totalConversations);

export const selectConversationDetails = createSelector(selectState, (state) => state.conversationDetails);
export const selectConversationDetailsLoading = createSelector(
    selectState,
    (state) => state.conversationDetailsLoading
);
export const selectConversationDetailsError = createSelector(selectState, (state) => state.conversationDetailsError);

export const selectSendMessageLoading = createSelector(selectState, (state) => state.sendMessageLoading);
export const selectSendMessageSuccess = createSelector(selectState, (state) => state.sendMessageSuccess);
export const selectSendMessageError = createSelector(selectState, (state) => state.sendMessageError);