import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as EmptyCardsIcon } from "assets/icons/emptyCards.svg";
import classNames from "classnames";
import Accordion from "features/common/components/Accordion";
import Spinner from "features/common/components/Spinner";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { MyProfileCards } from "features/myProfile/models";
import { OfferKind } from "features/offers/types";
import { User } from "features/user/models";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SingleCard from "./SingleCard";
import styles from "./styles.module.scss";

const minApplicantsNumberValue = 2;

interface Props {
    ["data-testid"]?: string;
    user?: User;
    myProfileCards?: MyProfileCards;
    areMyProfileCardsLoading: boolean;
    myProfileCardsLoadingError?: ApiError;
    getMyProfileCardsAsync: (cancelToken?: CancelToken) => void;
    selectedCard?: string;
    dedicatedUserId?: string;
    setSelectedCard: (userId: string) => void;
    skipCardsLoading?: boolean;
    asJobOffersList?: boolean;
}

const CardStep = ({
    "data-testid": testId,
    user,
    myProfileCards,
    areMyProfileCardsLoading,
    myProfileCardsLoadingError,
    getMyProfileCardsAsync,
    selectedCard,
    setSelectedCard,
    skipCardsLoading,
    asJobOffersList
}: Props) => {
    const createCancelToken = useCreateCancelToken();

    useEffect(() => {
        if (!skipCardsLoading) {
            getMyProfileCardsAsync(createCancelToken());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (areMyProfileCardsLoading) {
        return (
            <div className={styles["application-submission-card-step__loading-container"]}>
                <Spinner thickness="thin" />
                <span>
                    <FormattedMessage id="application-submission__loading-your-cards" />
                </span>
            </div>
        );
    }

    return (
        <div className={styles["application-submission-card-step__form-container"]}>
            {myProfileCardsLoadingError && (
                <div>
                    <FormattedMessage id="my-data__user-cards-fetch-failed" />
                </div>
            )}
            <div className={styles["application-submission-card-step__cards-list"]}>
                {(myProfileCards?.employee.length === 0 || asJobOffersList) && myProfileCards?.employer.length === 0 && (
                    <>
                        <div className={styles["application-submission-card-step__empty-icon"]}>
                            <EmptyCardsIcon />
                        </div>
                        <div className={styles["application-submission-card-step__primary-footer"]}>
                            <FormattedMessage id="my-cards__you-have-no-cards" />
                        </div>
                        <div className={styles["application-submission-card-step__secondary-footer"]}>
                            <FormattedMessage id="my-cards__add-your-first-card" />
                        </div>
                    </>
                )}
                {myProfileCards?.employer.length !== 0 && (
                    <Accordion
                        data-testid={`${testId}__companies-accordion`}
                        accordionClassName={styles["application-submission-card-step__accordion"]}
                        headerContentClassName={styles["application-submission-card-step__header-content"]}
                        headerButtonClassName={styles["application-submission-card-step__header-button"]}
                        bodyContentClassName={styles["application-submission-card-step__body-content"]}
                        openOnHeaderClick
                        openOnStart
                    >
                        <div className={styles["application-submission-card-step__group-header"]}>
                            <div className={styles["application-submission-card-step__group-title-container"]}>
                                <h2>
                                    <FormattedMessage id="my-profile__my-cards-employer-card" />
                                </h2>
                            </div>
                        </div>
                        <div className={styles["application-submission-card-step__single-cards-container"]}>
                            {myProfileCards?.employer.map(
                                (card) =>
                                    card && (
                                        <SingleCard
                                            isSelectedCard={card.id === selectedCard}
                                            setSelectedCard={setSelectedCard}
                                            card={card}
                                            user={user}
                                            key={card.id}
                                            data-testid={`${testId}__single-card`}
                                            isEmployer
                                        />
                                    )
                            )}
                        </div>
                    </Accordion>
                )}
                {myProfileCards?.employee.length !== 0 && !asJobOffersList && (
                    <Accordion
                        data-testid={`${testId}__privates-accordion`}
                        accordionClassName={styles["application-submission-card-step__accordion"]}
                        headerContentClassName={styles["application-submission-card-step__header-content"]}
                        headerButtonClassName={styles["application-submission-card-step__header-button"]}
                        bodyContentClassName={styles["application-submission-card-step__body-content"]}
                        openOnHeaderClick
                        openOnStart
                    >
                        <div className={styles["application-submission-card-step__group-header"]}>
                            <div className={styles["application-submission-card-step__group-title-container"]}>
                                <h2>
                                    <FormattedMessage id="my-profile__my-cards-employee-card" />
                                </h2>
                            </div>
                        </div>
                        <div className={styles["application-submission-card-step__single-cards-container"]}>
                            {myProfileCards?.employee.map(
                                (card) =>
                                    card && (
                                        <SingleCard
                                            isSelectedCard={card.id === selectedCard}
                                            setSelectedCard={setSelectedCard}
                                            card={card}
                                            user={user}
                                            key={card.id}
                                            data-testid={`${testId}__single-card`}
                                        />
                                    )
                            )}
                        </div>
                    </Accordion>
                )}
            </div>
        </div>
    );
};

export default CardStep;
