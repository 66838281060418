import Api from "api";
import {CancelToken} from "api/utils";
import config from "config";
import {StatisticsDto, UserOffersDto, UsersDto} from "features/adminPanel/dtos";
import {mapGetUsersFetchParamsToQueryParameters} from "features/adminPanel/mappers";
import {GetUsersFetchParams} from "features/adminPanel/types";

export class AdminPanelApi extends Api {
    getStatisticsAsync = (cancelToken?: CancelToken): Promise<StatisticsDto> => {
        return this.get<StatisticsDto>(config.apiRoutes.ADMIN_PANEL_STATISTICS, cancelToken);
    };

    getUsersAsync = (fetchParams: GetUsersFetchParams, cancelToken?: CancelToken): Promise<UsersDto> => {
        return this.get<UsersDto>(
            `${config.apiRoutes.ADMIN_PANEL_USERS}${mapGetUsersFetchParamsToQueryParameters(fetchParams)}`,
            cancelToken
        );
    };

    blockUserAsync = (userId: string, cancelToken?: CancelToken): Promise<void> => {
        return this.put<void, void>(`${config.apiRoutes.BLOCK_USER}/${userId}`, undefined, cancelToken);
    };

    unblockUserAsync = (userId: string, cancelToken?: CancelToken): Promise<void> => {
        return this.put<void, void>(`${config.apiRoutes.UNBLOCK_USER}/${userId}`, undefined, cancelToken);
    };

    deleteUserAsync = (userId: string, cancelToken?: CancelToken): Promise<void> => {
        return this.delete<void>(`${config.apiRoutes.USER}/${userId}`, cancelToken);
    };

    getUserJobOffersAsync = (userId: string, pageNumber: number, cancelToken?: CancelToken): Promise<UserOffersDto> => {
        return this.get<UserOffersDto>(
            `${config.apiRoutes.OFFERS}/byUserId/${userId}?size=9&page=${pageNumber}`,
            cancelToken
        );
    };

    getUserEmployeeOffersAsync = (
        userId: string,
        pageNumber: number,
        cancelToken?: CancelToken
    ): Promise<UserOffersDto> => {
        // There is no good endpoint for this on API currently
        // TODO: Replace with correct endpoint when API is done
        return this.get<UserOffersDto>(
            `${config.apiRoutes.EMPLOYEE_OFFERS}/byUserId/${userId}?size=9&page=${pageNumber}`,
            cancelToken
        );
    };

    deleteUserJobOfferAsync = (offerId: number, cancelToken?: CancelToken): Promise<void> => {
        return this.delete<void>(`${config.apiRoutes.OFFERS}/${offerId}`, cancelToken);
    };

    deleteUserEmployeeOfferAsync = (offerId: number, cancelToken?: CancelToken): Promise<void> => {
        return this.delete<void>(`${config.apiRoutes.EMPLOYEE_OFFERS}/${offerId}`, cancelToken);
    };
}

export default new AdminPanelApi();
