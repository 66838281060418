import { ApiError, CancelToken } from "api/utils";
import classNames from "classnames";
import Button from "features/common/components/Button";
import ModalWithContent from "features/common/components/ModalWithContent";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import { MessageType } from "features/common/types";
import SeeSimilarJobOffersButton from "features/jobOffers/components/SeeSimilarJobOffersButton";
import { JobOfferDetails } from "features/jobOffers/models";
import { Conversation } from "features/messages/models";
import { GetConversationsFetchParams } from "features/messages/types";
import { PaymentCardDto } from "features/myProfile/dtos";
import { Application } from "features/myProfile/models";
import SendMessage from "features/offers/components/SendMessage";
import { OfferStatus } from "features/offers/types";
import { appRoutes } from "features/routing/routes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ApplicationSubmission from "./ApplicationSubmission";
import styles from "./styles.module.scss";

export interface Props {
    ["data-testid"]?: string;
    jobOfferDetails?: JobOfferDetails;
    isUserLoggedInWithProfile?: boolean;
    offerId: number;
    userId?: string;
    paymentCardData?: PaymentCardDto;
    conversations?: Conversation[];
    conversationsLoading: boolean;
    conversationsLoadingError?: ApiError;
    myApplications?: Application[];
    myApplicationsLoading: boolean;
    myApplicationsError?: ApiError;
    getMyApplicationsAsync: (cancelToken?: CancelToken) => void;
    getConversationsAsync: (fetchParams: GetConversationsFetchParams, cancelToken?: CancelToken) => void;
}

const DetailsActionsBox = ({
    "data-testid": testId,
    jobOfferDetails,
    isUserLoggedInWithProfile,
    offerId,
    userId,
    paymentCardData,
    conversations,
    conversationsLoading,
    conversationsLoadingError,
    myApplications,
    myApplicationsLoading,
    myApplicationsError,
    getMyApplicationsAsync,
    getConversationsAsync,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const createCancelToken = useCreateCancelToken();
    const [sendMessageModalVisible, setSendMessageModalVisible] = useState(false);
    const [applyModalVisible, setApplyModalVisible] = useState(false);
    const [conversationsRequested, setConversationsRequested] = useState(false);
    const [myApplicationsRequested, setMyApplicationsRequested] = useState(false);
    const [applySuccessModalVisible, setApplySuccessModalVisible] = useState(false);

    useEffect(() => {
        window.onpopstate = () => {
            history.push({ pathname: history.location.pathname, state: { cameBack: true } })
        }
    }, [history])

    const onApplicationSubmissionClose = () => {
        setApplyModalVisible(false);
    };

    const onSendMessageClose = () => {
        setSendMessageModalVisible(false);
    };

    const onClickSendMessage = () => {
        setSendMessageModalVisible(true);
    };

    const onClickApply = () => {
        setApplyModalVisible(true);
    };

    const handleLoginButton = useCallback(() => history.push(appRoutes.login), [history]);

    useEffect(() => {
        if (!conversationsRequested) {
            getConversationsAsync({ pageSize: 999, pageNumber: 0 }, createCancelToken());
            setConversationsRequested(true);
        }
    }, [
        createCancelToken,
        conversations,
        conversationsLoading,
        conversationsLoadingError,
        conversationsRequested,
        getConversationsAsync,
    ]);

    const handleSendMessageSuccess = () =>
        getConversationsAsync({ pageSize: 999, pageNumber: 0 }, createCancelToken());

    useEffect(() => {
        if (!myApplicationsRequested) {
            getMyApplicationsAsync(createCancelToken());
            setMyApplicationsRequested(true);
        }
    }, [
        createCancelToken,
        getMyApplicationsAsync,
        myApplications,
        myApplicationsError,
        myApplicationsLoading,
        myApplicationsRequested,
    ]);

    const handleApplicationSubmissionSuccess = () => {
        getMyApplicationsAsync(createCancelToken());
        getConversationsAsync({ pageSize: 999, pageNumber: 0 }, createCancelToken());
        setApplySuccessModalVisible(true);
    };

    const hasConversation = useMemo(
        () => conversations?.find((item) => item.offerId === `${offerId}`),
        [conversations, offerId]
    );

    const hasApplication = useMemo(
        () => myApplications?.find((item) => item.jobOfferId === offerId),
        [myApplications, offerId]
    );

    const goToConversationDetails = () => {
        history.push(`${appRoutes.messages}/${hasConversation?.id}`);
    };

    const closeApplySuccessModal = () => setApplySuccessModalVisible(false);

    if (conversationsLoading || myApplicationsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "offer-details__loading-offer-details" })} />;
    }

    return (
        <div>
            <div className={classNames(styles["details-actions-box__buttons-container"])}>
                {jobOfferDetails?.status === OfferStatus.Inactive || jobOfferDetails?.status === OfferStatus.Ended ? (
                    <>
                        {isUserLoggedInWithProfile ? (
                            !!hasConversation ? (
                                <Button
                                    onClick={goToConversationDetails}
                                    variant="secondary"
                                    className={styles["details-actions-box__send-message-button"]}
                                    data-testid={`${testId}__send-message-button`}
                                    disabled={jobOfferDetails?.offerOwner?.userId === userId}
                                >
                                    <FormattedMessage id="common__go-to-conversation" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={onClickSendMessage}
                                    variant="secondary"
                                    className={styles["details-actions-box__send-message-button"]}
                                    data-testid={`${testId}__send-message-button`}
                                    disabled={jobOfferDetails?.offerOwner?.userId === userId}
                                >
                                    <FormattedMessage id="common__send-message" />
                                </Button>
                            )
                        ) : (
                            <Button
                                onClick={handleLoginButton}
                                variant="secondary"
                                className={styles["details-actions-box__send-message-button"]}
                                data-testid={`${testId}__send-message-login-button`}
                            >
                                <FormattedMessage id="common__send-message" />
                            </Button>
                        )}
                        <SeeSimilarJobOffersButton jobOfferDetails={jobOfferDetails} variant="primary" />
                    </>
                ) : (
                    <>
                        {isUserLoggedInWithProfile ? (
                            !!hasConversation ? (
                                <Button
                                    onClick={goToConversationDetails}
                                    variant="secondary"
                                    className={styles["details-actions-box__send-message-button"]}
                                    data-testid={`${testId}__send-message-button`}
                                    disabled={jobOfferDetails?.offerOwner?.userId === userId}
                                >
                                    <FormattedMessage id="common__go-to-conversation" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={onClickSendMessage}
                                    variant="secondary"
                                    className={styles["details-actions-box__send-message-button"]}
                                    data-testid={`${testId}__send-message-button`}
                                    disabled={jobOfferDetails?.offerOwner?.userId === userId}
                                >
                                    <FormattedMessage id="common__send-message" />
                                </Button>
                            )
                        ) : (
                            <Button
                                onClick={handleLoginButton}
                                variant="secondary"
                                className={styles["details-actions-box__send-message-button"]}
                                data-testid={`${testId}__send-message-login-button`}
                            >
                                <FormattedMessage id="common__send-message" />
                            </Button>
                        )}
                        {isUserLoggedInWithProfile ? (
                            <>
                                <Button
                                    onClick={onClickApply}
                                    className={styles["details-actions-box__apply-button"]}
                                    data-testid={`${testId}__apply-button`}
                                    disabled={jobOfferDetails?.offerOwner?.userId === userId || (!!jobOfferDetails?.financialConditions?.bonusValue && !paymentCardData)}
                                >
                                    <FormattedMessage id="common__apply" />
                                </Button>
                                {hasApplication && (
                                    <div className={styles["details-actions-box__info-text"]}>
                                        <FormattedMessage id="offer-details__already-applied" />
                                    </div>
                                )}
                                {jobOfferDetails?.offerOwner?.userId === userId ? (
                                    <div className={styles["details-actions-box__info-text"]}>
                                        <FormattedMessage id="offer-details__your-offer" values={{ offerType: "EMPLOYER" }} />
                                    </div>
                                ) : (!!jobOfferDetails?.financialConditions?.bonusValue && !paymentCardData) ? (
                                    <div className={styles["details-actions-box__info-text"]}>
                                        <FormattedMessage id="offer-details__bonus-offer-no-card" />
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <Button
                                className={styles["details-actions-box__apply-button"]}
                                onClick={handleLoginButton}
                                data-testid={`${testId}__apply-login-button`}
                            >
                                <FormattedMessage id="common__apply" />
                            </Button>
                        )}
                        <SendMessage
                            isVisible={sendMessageModalVisible}
                            maxMessageLength={2000}
                            onClose={onSendMessageClose}
                            offerId={offerId}
                            messageType={MessageType.JobOffer}
                            askAboutPositionTitle={jobOfferDetails?.position}
                            askAboutPositionMainImage={jobOfferDetails?.imageUrl}
                            data-testid={`${testId}__send-message`}
                            onSuccess={handleSendMessageSuccess}
                        />
                        <ApplicationSubmission
                            isVisible={applyModalVisible}
                            onClose={onApplicationSubmissionClose}
                            positionsAvailable={jobOfferDetails?.availablePositionsNumber}
                            offerId={offerId}
                            data-testid={`${testId}__application-submission`}
                            onSuccess={handleApplicationSubmissionSuccess}
                        />
                        {applySuccessModalVisible && (
                            <ModalWithContent
                                className={styles["users__delete-user-modal"]}
                                header={
                                    <span className={styles["users__delete-user-title"]}>
                                        {intl.formatMessage({ id: "offer-details__apply-success-title" })}
                                    </span>
                                }
                                content={intl.formatMessage({ id: "offer-details__apply-success-text" })}
                                primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                                onClose={closeApplySuccessModal}
                                primaryAction={closeApplySuccessModal}
                                cancelButtonHidden
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default DetailsActionsBox;
