import CreateProfileComponent from "features/user/components/CreateProfile";
import React from "react";
import styles from "./styles.module.scss";

const CreateProfile = () => {
    return (
        <div className={styles["create-profile"]}>
            <CreateProfileComponent />
        </div>
    );
};

export default CreateProfile;
