import useDeviceClass from "features/common/hooks/useDeviceClass";
import { EnumDictionary } from "features/common/types";
import RequirementBlock from "features/jobOffers/components/CreateJobOffer/RequirementsStep/RequirementBlock";
import { getEducationLabels } from "features/jobOffers/hooks";
import { Education, EducationType, RequirementEducationTouched } from "features/jobOffers/types";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import EducationOptionBlock from "./EducationOptionBlock";
import styles from "./styles.module.scss";

export interface Props {
    header: string;
    educationsValueInputProps: FieldInputProps<Education[]>;
    educationsFieldMeta: FieldMetaProps<Education[]>;
    educationsHelperProps: FieldHelperProps<Education[]>;
    ["data-testid"]?: string;
}

const EducationRequirement = ({
    header,
    educationsValueInputProps,
    educationsFieldMeta,
    educationsHelperProps,
    "data-testid": testId,
}: Props) => {
    const intl = useIntl();
    const deviceClass = useDeviceClass();

    const [prevEducationsState, setPrevEducationsState] = useState<Education[]>(educationsValueInputProps.value);
    const [educationsTouched, setEducationsTouched] = useState<
        EnumDictionary<EducationType, RequirementEducationTouched>
    >({
        [EducationType.None]: { isOtherValueTouched: false },
        [EducationType.Primary]: { isOtherValueTouched: false },
        [EducationType.Vocational]: { isOtherValueTouched: false },
        [EducationType.Secondary]: { isOtherValueTouched: false },
        [EducationType.SecondaryTechnical]: { isOtherValueTouched: false },
        [EducationType.Higher]: { isOtherValueTouched: false },
        [EducationType.Other]: { isOtherValueTouched: false },
    });

    const [educationsLabels, setEducationLables] = useState(getEducationLabels(intl));

    useEffect(() => {
        setEducationLables(getEducationLabels(intl))
    }, [intl])

    const onCancel = () => {
        educationsHelperProps.setValue(prevEducationsState);
        cleanTouchedValues();
    };

    const onAdd = () => {
        const cloned = educationsValueInputProps.value?.map((x) => Object.assign({}, x));
        setPrevEducationsState(cloned);
    };

    const onEdit = () => {
        const cloned = educationsValueInputProps.value?.map((x) => Object.assign({}, x));
        setPrevEducationsState(cloned);
    };

    const onSave = () => {
        if (educationsFieldMeta.error) {
            const educationsTouchedPrevState = Object.assign({}, educationsTouched);
            educationsValueInputProps.value.forEach((language) => {
                if (language.isChecked) {
                    educationsTouchedPrevState[language.id].isOtherValueTouched = true;
                }
            });

            setEducationsTouched(educationsTouchedPrevState);
            return false;
        } else {
            cleanTouchedValues();
            return true;
        }
    };

    const onClean = () => {
        const newArray = educationsValueInputProps.value.map((current) => {
            const cloned = Object.assign({}, current);
            cloned.isChecked = false;
            cloned.otherValue = undefined;
            return cloned;
        });

        educationsHelperProps.setValue(newArray);
        setPrevEducationsState(newArray);
        cleanTouchedValues();
    };

    const cleanTouchedValues = () => {
        const educationsTouchedPrevState = Object.assign({}, educationsTouched);
        Object.keys(educationsTouchedPrevState).forEach((key) => {
            const educationType = key as EducationType;
            educationsTouched[educationType].isOtherValueTouched = false;
        });
        setEducationsTouched(educationsTouchedPrevState);
    };

    const onCheckboxToggle = useCallback(
        (checked: boolean, id: EducationType) => {
            const educationsTouchedPrevState = Object.assign({}, educationsTouched);
            const newArray = educationsValueInputProps.value.map((x) => {
                const clonedEducation = Object.assign({}, x);
                clonedEducation.isChecked = false;
                if (clonedEducation.id === id) {
                    clonedEducation.isChecked = checked;
                    if (!checked) {
                        clonedEducation.otherValue = undefined;
                        educationsTouchedPrevState[id].isOtherValueTouched = false;
                    }
                }

                return clonedEducation;
            });

            setEducationsTouched(educationsTouchedPrevState);
            educationsHelperProps.setValue(newArray);
        },
        [educationsHelperProps, educationsTouched, educationsValueInputProps.value]
    );

    const onOtherEducationChange = useCallback(
        (value: string) => {
            const newArray = educationsValueInputProps.value.map((x) => {
                const clonedEducation = Object.assign({}, x);
                if (clonedEducation.id === EducationType.Other) {
                    clonedEducation.otherValue = value;
                }

                return clonedEducation;
            });

            const educationsTouchedPrevState = Object.assign({}, educationsTouched);
            educationsTouchedPrevState[EducationType.Other].isOtherValueTouched = true;

            setEducationsTouched(educationsTouchedPrevState);
            educationsHelperProps.setValue(newArray);
        },
        [educationsHelperProps, educationsTouched, educationsValueInputProps.value]
    );

    const getValueArea = useCallback(() => {
        const stateToShow = educationsValueInputProps.value?.filter((l) => l.isChecked);
        if (stateToShow?.length > 0) {
            return (
                <div className={styles["education-requirement__value-area"]}>
                    {stateToShow.map((option, index) => (
                        <div
                            data-testid={testId ? `${testId}__language-value` : undefined}
                            className={
                                index !== stateToShow.length - 1
                                    ? styles["education-requirement__value-area--with-comma"]
                                    : ""
                            }
                            key={"value_area" + option.id}
                        >
                            {`${option.id === EducationType.Other ? option.otherValue : educationsLabels[option.id]}`}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    }, [educationsValueInputProps.value, testId, educationsLabels]);

    const optionContent = useCallback(
        (option: Education, index: number) => {
            return (
                <EducationOptionBlock
                    data-testid={testId ? `${testId}__option-block` : undefined}
                    education={option}
                    educationsLabels={educationsLabels}
                    educationsTouched={educationsTouched}
                    values={educationsValueInputProps.value}
                    index={index}
                    error={educationsFieldMeta.error}
                    onCheckboxToggle={onCheckboxToggle}
                    onOtherEducationChange={onOtherEducationChange}
                    key={index}
                />
            );
        },
        [
            educationsFieldMeta.error,
            educationsLabels,
            educationsTouched,
            educationsValueInputProps.value,
            onCheckboxToggle,
            onOtherEducationChange,
            testId,
        ]
    );

    const editorContent = useMemo(() => {
        return (
            <div className={styles["education-requirement__options-container"]}>
                {deviceClass !== "desktop" ? (
                    <div className={styles["education-requirement__options-container-column"]}>
                        {educationsValueInputProps.value?.map((option, index) => optionContent(option, index))}
                    </div>
                ) : (
                    <>
                        <div className={styles["education-requirement__options-container-column"]}>
                            {educationsValueInputProps?.value
                                ?.slice(0, 4)
                                .map((option, index) => optionContent(option, index))}
                        </div>
                        <div className={styles["education-requirement__options-container-column"]}>
                            {educationsValueInputProps.value
                                ?.slice(4, 7)
                                .map((option, index) => optionContent(option, index + 4))}
                        </div>
                    </>
                )}
            </div>
        );
    }, [deviceClass, educationsValueInputProps, optionContent]);

    return (
        <div>
            <RequirementBlock
                data-testid={testId ? `${testId}__requirement-block` : undefined}
                label={header}
                onAdd={onAdd}
                onSave={onSave}
                value={getValueArea()}
                onEdit={onEdit}
                onClean={onClean}
                onCancel={onCancel}
                isCleanable={true}
                editorContent={editorContent}
            />
        </div>
    );
};

export default EducationRequirement;
