import React, { useState } from "react";
import { useIntl } from "react-intl";
import FavoriteAPI from "../features/favorite/api/index";
import { ReactComponent as TranslationIcon } from "../assets/icons/translation.svg";
import loader from "../assets/icons/loading.gif";

export const LoadingIcon = () => <img src={loader} alt='loading' width={21} height={21}/>;

export const useTranslate = (translationText: string) => {
    const [translatedText, setTranslatedText] = useState<string>("")
    const [translationIcon, setTranslationIcon] = useState(<TranslationIcon/>);
    const intl = useIntl();

    const handleTranslate = async () => {
        setTranslationIcon(<LoadingIcon/>);
        const toTranslate = translationText;
        const targetLanguage = intl.locale
        const response = await FavoriteAPI.translateText({q: toTranslate, target: targetLanguage})
        setTranslatedText(response.translatedText);
        setTranslationIcon(<TranslationIcon/>)
    }

    return {
        translationIcon,
        translatedText,
        handleTranslate,
    }
}