import { modalsDivId } from "features/common/constants";
import React from "react";
import styles from "./styles.module.scss";

interface Props {
    children: React.ReactNode;
}

const Page = ({ children }: Props) => {
    return <div className={styles["page"]}>{children}</div>;
};

const Content = ({ children }: Props) => {
    return (
        <div className={styles["page__content-container"]}>
            <main className={styles["page__content"]}>{children}</main>
            <div id={modalsDivId} />
        </div>
    );
};

Page.Content = Content;

export default Page;
