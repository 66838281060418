/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { pl } from "date-fns/locale";
import { ApiError, CancelToken } from "api/utils";
import config from "config";
import Button from "features/common/components/Button";
import ModalWithContent from "features/common/components/ModalWithContent";
import ErrorHeader from "features/common/components/ModalWithContent/ErrorHeader";
import SuccessHeader from "features/common/components/ModalWithContent/SuccessHeader";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useCreateCancelToken from "features/common/hooks/useCreateCancelToken";
import useDateFormat from "features/common/hooks/useDateFormat";
import {
    getBonusMessage,
    getPositionsNumberMessage,
    getPublishedDateMessage,
    getValidUntilMessage,
    getWorkLocationMessage,
} from "features/common/translationMessages";
import { OfferType } from "features/common/types";
import { ApplicationDetails } from "features/jobOffers/models";
import { CardOwnerRole } from "features/myProfile/types";
import CardImageWithBadge from "features/offers/components/SingleOfferCard/CardImageWithBadge";
import { getSalaryMessage, getWorkTypeWithDatesMessage } from "features/offers/translationMessages";
import DetailRow from "features/offers/components/SingleOfferCard/DetailRow";
import RatingButtons from "features/rating/components/RatingButtons";
import { RateCategory, RateForm } from "features/rating/models";
import { appRoutes } from "features/routing/routes";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarWithDots.svg";
import { ReactComponent as CalendarCheckIcon } from "assets/icons/calendarCheck.svg";
import { ReactComponent as CalendarWithNumberIcon } from "assets/icons/calendarWithNumber.svg";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as CoinsBanknoteIcon } from "assets/icons/coinsBanknote.svg";
import { ReactComponent as LocationMarkerIcon } from "assets/icons/locationMarker.svg";
import { ReactComponent as PeopleWithHandIcon } from "assets/icons/peopleWithHand.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg";
import styles from "./styles.module.scss";
import { User } from "features/user/models";

interface Props {
    id: number;
    ratingType?: CardOwnerRole;
    employerRatingLoading: boolean;
    employerRatingSuccess?: boolean;
    employerRatingError?: ApiError;
    rateEmployerAsync: (rateForm: RateForm, cancelToken?: CancelToken) => void;
    resetRateEmployerState: () => void;
    applicationDetails?: ApplicationDetails;
    applicationDetailsLoading: boolean;
    applicationDetailsError?: ApiError;
    getApplicationDetailsAsync: (id: number, isOfferOwner: boolean, cancelToken?: CancelToken) => void;
    user?: User;
}

const RateEmployer = ({
    id,
    employerRatingLoading,
    employerRatingSuccess,
    employerRatingError,
    rateEmployerAsync,
    resetRateEmployerState,
    applicationDetails,
    applicationDetailsLoading,
    applicationDetailsError,
    getApplicationDetailsAsync,
    user
}: Props) => {
    const jobOfferDetails = applicationDetails?.jobOffer;
    const createCancelToken = useCreateCancelToken();

    useEffect(() => {
        if (id) {
            getApplicationDetailsAsync(id, true, createCancelToken());
        }
    }, [createCancelToken, getApplicationDetailsAsync, id]);
    const intl = useIntl();
    const history = useHistory();
    const getFormattedDate = useDateFormat("d MMM yyyy", { locale: pl });

    const [successModalDisplayed, setSuccessModalDisplayed] = useState(false);
    const [errorModalDisplayed, setErrorModalDisplayed] = useState(false);

    const [overallRating, setOverallRating] = useState<number | undefined>();
    const [onboardOrganisationRating, setOnboardOrganisationRating] = useState<number | undefined>();
    const [complianceRating, setComplianceRating] = useState<number | undefined>();
    const [timelinessRating, setTimelinessRating] = useState<number | undefined>();
    const [atmosphereRating, setAtmosphereRating] = useState<number | undefined>();
    const [transportRating, setTransportRating] = useState<number | undefined>();
    const [accomodationRating, setAccomodationRating] = useState<number | undefined>();
    const [difficultyRating, setDifficultyRating] = useState<number | undefined>();
    const [managementRating, setManagementRating] = useState<number | undefined>();

    const handleOnboardOrganisationClear = useCallback(() => setOnboardOrganisationRating(undefined), []);
    const handleComplianceClear = useCallback(() => setComplianceRating(undefined), []);
    const handleTimelinessClear = useCallback(() => setTimelinessRating(undefined), []);
    const handleAtmosphereClear = useCallback(() => setAtmosphereRating(undefined), []);
    const handleTransportClear = useCallback(() => setTransportRating(undefined), []);
    const handleAccomodationClear = useCallback(() => setAccomodationRating(undefined), []);
    const handleDifficultyClear = useCallback(() => setDifficultyRating(undefined), []);
    const handleManagementClear = useCallback(() => setManagementRating(undefined), []);

    let errorMessage;
    switch (employerRatingError?.response?.status) {
        case 400:
            errorMessage = <FormattedMessage id="rating__error-no-application" />;
            break;
        case 403:
            errorMessage = <FormattedMessage id="rating__error-state" />;
            break;
        case 404:
            errorMessage = <FormattedMessage id="rating__error-not-found" />;
            break;
        default:
            errorMessage = <FormattedMessage id="rating__error-text" />;
    }

    useEffect(() => {
        if (employerRatingSuccess) {
            setSuccessModalDisplayed(true);
        }
    }, [employerRatingSuccess]);

    useEffect(() => {
        if (employerRatingError) {
            setErrorModalDisplayed(true);
        }
    }, [employerRatingError]);

    const salaryMessage = useMemo(
        () =>
            jobOfferDetails?.financialConditions ? getSalaryMessage(intl, jobOfferDetails?.financialConditions) : "",
        [intl, jobOfferDetails]
    );
    const workLocationText = useMemo(
        () =>
            `${jobOfferDetails?.location?.city ? `${jobOfferDetails?.location?.city}, ` : ""}${intl.formatMessage({
                id: `common__country-${jobOfferDetails?.location?.country}`,
                defaultMessage: jobOfferDetails?.location?.country,
            })}`,
        [intl, jobOfferDetails]
    );
    const workLocationMessage = getWorkLocationMessage(intl, workLocationText ?? undefined);
    const workTypeMessage = getWorkTypeWithDatesMessage(
        intl,
        jobOfferDetails?.workType,
        getFormattedDate(jobOfferDetails?.startDate),
        getFormattedDate(jobOfferDetails?.endDate)
    );
    const bonusMessage = getBonusMessage(
        intl,
        `${jobOfferDetails?.financialConditions.bonusValue} ${jobOfferDetails?.financialConditions.bonusCurrencyCode}`
    );
    const positionsNumberMessage = getPositionsNumberMessage(intl, jobOfferDetails?.availablePositionsNumber);
    const publishedDateMessage = getPublishedDateMessage(intl, getFormattedDate(jobOfferDetails?.addedDate));
    const validUntilMessage = getValidUntilMessage(intl, getFormattedDate(jobOfferDetails?.expirationDate));

    const handleDetailsClick = useCallback(() => {
        history.push(`${appRoutes.jobOffers}/${jobOfferDetails?.id}`);
    }, [history, jobOfferDetails]);

    const goToProfile = useCallback(
        () => history.push(`${appRoutes.profileCard}/${jobOfferDetails?.businessCardId}`),
        [history, jobOfferDetails]
    );

    const goToDashboard = useCallback(() => history.push(appRoutes.dashboard), [history]);


    const handleSubmit = useCallback(() => {
        const rates = [
            {
                category: RateCategory.overall,
                rate: overallRating as number,
            },
        ];

        if (onboardOrganisationRating)
            rates.push({ category: RateCategory.onboardOrganisation, rate: onboardOrganisationRating });
        if (complianceRating) rates.push({ category: RateCategory.compliance, rate: complianceRating });
        if (timelinessRating) rates.push({ category: RateCategory.timeliness, rate: timelinessRating });
        if (atmosphereRating) rates.push({ category: RateCategory.atmosphere, rate: atmosphereRating });
        if (transportRating) rates.push({ category: RateCategory.transport, rate: transportRating });
        if (accomodationRating) rates.push({ category: RateCategory.accomodation, rate: accomodationRating });
        if (difficultyRating) rates.push({ category: RateCategory.difficulty, rate: difficultyRating });
        if (managementRating) rates.push({ category: RateCategory.management, rate: managementRating });

        rateEmployerAsync(
            {
                assessedBusinessCardId: (applicationDetails?.jobApplicant?.userId === user?.id) ? applicationDetails?.jobOffer.businessCardId : applicationDetails?.jobApplicant?.businessCardId,
                jobOfferId: String(applicationDetails?.jobOffer.id),
                isApplicant: applicationDetails?.jobApplicant?.userId === user?.id,
                rates,
            },
            createCancelToken()
        );
    }, [
        accomodationRating,
        atmosphereRating,
        complianceRating,
        createCancelToken,
        difficultyRating,
        id,
        managementRating,
        onboardOrganisationRating,
        overallRating,
        rateEmployerAsync,
        timelinessRating,
        transportRating,
    ]);

    const handleCloseSuccessModal = useCallback(() => {
        setSuccessModalDisplayed(false);
        resetRateEmployerState();
        goToDashboard();
    }, [goToDashboard, resetRateEmployerState]);

    const handleCloseErrorModal = useCallback(() => setErrorModalDisplayed(false), []);

    if (applicationDetailsLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "rating__loading-data" })} />;
    }

    if (employerRatingLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "rating__sending-data" })} />;
    }

    return (
        <div className={styles["rate-employer"]}>
            {applicationDetailsError ? (
                <div
                    className={classNames(styles["rate-employer__container"], styles["rate-employer__error-container"])}
                >
                    <FormattedMessage id="rating__loading-error" />
                </div>
            ) : (
                <>
                    <div className={styles["rate-employer__container"]}>
                        <div className={styles["rate-employer__title"]}>
                            <FormattedMessage id="rating__employer-title" />
                        </div>
                        <div className={styles["rate-employer__employer-container"]}>
                            {!!jobOfferDetails?.clientLogoUrl && (
                                <img
                                    src={`${config.IMAGE_URL}/${jobOfferDetails.clientLogoUrl}`}
                                    alt={`${jobOfferDetails.businessName} logo`}
                                    className={styles["rate-employer__logo"]}
                                />
                            )}
                            {!!jobOfferDetails?.businessName && (
                                <span className={styles["rate-employer__business-name"]}>
                                    {jobOfferDetails?.businessName}
                                </span>
                            )}
                            <Button
                                variant="no-background"
                                onClick={goToProfile}
                                className={styles["rate-employer__profile-button"]}
                            >
                                <FormattedMessage id="rating__go-to-profile" />
                            </Button>
                        </div>
                    </div>
                    <div className={styles["rate-employer__data-container"]}>
                        <div className={styles["rate-employer__offer-container"]}>
                            <div className={styles["rate-employer__offer-container-title"]}>
                                <FormattedMessage id="rating__offer-title" />
                            </div>
                            <CardImageWithBadge
                                data-testid="rate-employer__card-image-with-badge"
                                photoBadge="status"
                                status={jobOfferDetails?.status}
                                imageUrl={`${config.IMAGE_URL}/${jobOfferDetails?.mainImage}`}
                                offerType={OfferType.Job}
                                className={styles["rate-employer__offer-image"]}
                            />
                            <div className={styles["rate-employer__offer-details-container"]}>
                                <div className={styles["rate-employer__offer-business-name"]}>
                                    {jobOfferDetails?.businessName}
                                </div>
                                <div className={styles["rate-employer__offer-position"]}>
                                    {jobOfferDetails?.position}
                                </div>
                                <DetailRow
                                    className={styles["rate-employer__offer-detail-row"]}
                                    iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                    data-testid="rate-employer__location"
                                    icon={<LocationMarkerIcon />}
                                    message={workLocationMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employer__offer-detail-row"]}
                                    iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                    data-testid="rate-employer__work-type"
                                    isEllipsis={false}
                                    icon={<CalendarIcon />}
                                    message={workTypeMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employer__offer-detail-row"]}
                                    iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                    data-testid="rate-employer__positions-number"
                                    icon={<PeopleWithHandIcon />}
                                    message={positionsNumberMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employer__offer-detail-row"]}
                                    iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                    data-testid="rate-employer__salary"
                                    icon={<CoinsIcon />}
                                    message={salaryMessage}
                                />
                                {bonusMessage && (
                                    <DetailRow
                                        className={styles["rate-employer__offer-detail-row"]}
                                        iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                        data-testid="rate-employer__bonus"
                                        icon={<CoinsBanknoteIcon />}
                                        message={bonusMessage}
                                    />
                                )}
                                <DetailRow
                                    className={styles["rate-employer__offer-detail-row"]}
                                    iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                    data-testid="rate-employer__added-date"
                                    icon={<CalendarCheckIcon />}
                                    message={publishedDateMessage}
                                />
                                <DetailRow
                                    className={styles["rate-employer__offer-detail-row"]}
                                    iconClassName={styles["rate-employer__offer-detail-row-icon"]}
                                    data-testid="rate-employer__expiration-date"
                                    icon={<CalendarWithNumberIcon />}
                                    message={validUntilMessage}
                                />
                            </div>
                            <div className={styles["rate-employer__details-button"]} onClick={handleDetailsClick}>
                                {intl.formatMessage({ id: "rating__offer-details" })}
                                <ChevronRight />
                            </div>
                        </div>
                        <div className={styles["rate-employer__rating-form"]}>
                            <div className={styles["rate-employer__rating-title"]}>
                                <FormattedMessage id="rating__form-title" />
                            </div>
                            <div className={styles["rate-employer__rating-subtitle"]}>
                                <FormattedMessage id="rating__form-employer-subtitle" />
                            </div>
                            <div className={styles["rate-employer__rating-subtext"]}>
                                <FormattedMessage id="rating__form-subtext" />
                            </div>
                            <div className={styles["rate-employer__section"]}>
                                <div className={styles["rate-employer__section-title"]}>
                                    <FormattedMessage id="rating__overall-employer-section-title" />
                                </div>
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__overall-employer-section-text" })}
                                    isRequired
                                    value={overallRating}
                                    onChange={setOverallRating}
                                />
                            </div>
                            <div className={styles["rate-employer__section"]}>
                                <div className={styles["rate-employer__section-title"]}>
                                    <FormattedMessage id="rating__detailed-section-title" />
                                </div>
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__onboard-organisation-section-text" })}
                                    isClearable
                                    value={onboardOrganisationRating}
                                    onChange={setOnboardOrganisationRating}
                                    onClear={handleOnboardOrganisationClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__compliance-section-text" })}
                                    isClearable
                                    value={complianceRating}
                                    onChange={setComplianceRating}
                                    onClear={handleComplianceClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__timeliness-section-text" })}
                                    isClearable
                                    value={timelinessRating}
                                    onChange={setTimelinessRating}
                                    onClear={handleTimelinessClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__atmosphere-section-text" })}
                                    isClearable
                                    value={atmosphereRating}
                                    onChange={setAtmosphereRating}
                                    onClear={handleAtmosphereClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__transport-section-text" })}
                                    isClearable
                                    value={transportRating}
                                    onChange={setTransportRating}
                                    onClear={handleTransportClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__accomodation-section-text" })}
                                    isClearable
                                    value={accomodationRating}
                                    onChange={setAccomodationRating}
                                    onClear={handleAccomodationClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__difficulty-section-text" })}
                                    isClearable
                                    value={difficultyRating}
                                    onChange={setDifficultyRating}
                                    onClear={handleDifficultyClear}
                                />
                                <RatingButtons
                                    text={intl.formatMessage({ id: "rating__management-section-text" })}
                                    isClearable
                                    value={managementRating}
                                    onChange={setManagementRating}
                                    onClear={handleManagementClear}
                                />
                            </div>
                            <div className={styles["rate-employer__buttons-container"]}>
                                <Button
                                    variant="tertiary"
                                    className={styles["rate-employer__button"]}
                                    onClick={goToDashboard}
                                >
                                    <FormattedMessage id="common__cancel" />
                                </Button>
                                <Button
                                    className={styles["rate-employer__button"]}
                                    onClick={handleSubmit}
                                    disabled={!overallRating}
                                >
                                    <FormattedMessage id="common__send" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {errorModalDisplayed && (
                <ModalWithContent
                    className={styles["rate-employer__modal"]}
                    header={
                        <ErrorHeader>
                            <FormattedMessage id="rating__error-title" />
                        </ErrorHeader>
                    }
                    content={errorMessage}
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseErrorModal}
                    primaryAction={handleCloseErrorModal}
                />
            )}
            {successModalDisplayed && (
                <ModalWithContent
                    className={styles["rate-employer__modal"]}
                    header={
                        <SuccessHeader>
                            <FormattedMessage id="rating__success-title" />
                        </SuccessHeader>
                    }
                    content={
                        <span>
                            <FormattedMessage id="rating__success-text-line-1" />
                            <br />
                            <FormattedMessage id="rating__success-text-line-2" />
                        </span>
                    }
                    primaryButtonContent={<FormattedMessage id="common__ok" />}
                    cancelButtonHidden
                    onClose={handleCloseSuccessModal}
                    primaryAction={handleCloseSuccessModal}
                />
            )}
        </div>
    );
};

export default RateEmployer;
