import MyEmployeeOfferDetailsComponent from "features/employeeOffers/components/MyEmployeeOfferDetails";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface QueryParams {
    id: string;
}
const MyEmployeeOfferDetails = () => {
    const queryParams = useParams<QueryParams>();

    return (
        <div className={styles["my-employee-offer-details"]}>
            <div className={styles["my-employee-offer-details__inner-container"]}>
                {queryParams.id && <MyEmployeeOfferDetailsComponent id={parseInt(queryParams.id!)} />}
            </div>
        </div>
    );
};

export default MyEmployeeOfferDetails;
