import {useMediaQuery} from "hooks/useMediaQuery";
import React from "react";
import styled from "styled-components/macro";
import HeroCommentsContainer from "./HeroCommentsContainer";

const HeroComments = () => {

    const isMatches = useMediaQuery('(min-width: 1441px)');
    return (
        isMatches 
        ? <ContainerWrapper><HeroCommentsContainer /></ContainerWrapper> 
        : <HeroCommentsContainer />
    )
}

export default HeroComments;

const ContainerWrapper = styled.div`
  @media screen and (min-width: 1441px){
    position: relative;
    width: 90rem;
    margin: 0 auto;
    }
`;