import { de, enGB, fr, nl, pl, ru, uk } from "date-fns/locale";
import useDateFormat from "features/common/hooks/useDateFormat";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import mapUtcStringToLocalDate from "features/common/mappers/mapUtcStringToLocalDate";
import { getHomeMessage, getTodayMessage, getYesterdayMessage } from "features/common/translationMessages";
import { getMyMessagesTitle } from "features/messages/translationMessages";
import { isToday, isYesterday } from "features/messages/utils";
import { appRoutes } from "features/routing/routes";
import { UserDto } from "features/user/dtos";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

export const useBreadcrumbItems = (sender: UserDto) => {
    const intl = useIntl();

    const homeMessage = getHomeMessage(intl);
    const myMessagesTitle = getMyMessagesTitle(intl);

    return [
        {
            displayName: homeMessage,
            path: appRoutes.dashboard,
        },
        {
            displayName: myMessagesTitle,
            path: appRoutes.myMessages,
        },
        {
            displayName: `${sender.firstname} ${sender.lastname[0]}.`,
        },
    ];
};

export const useLocaleInDateToUse = (locale: string) => {
    switch (locale) {
        case 'en':
            return enGB
        case 'pl':
            return pl
        case 'de':
            return de
        case 'nl':
            return nl
        case 'uk':
            return uk
        case 'ru':
            return ru
        case 'fr':
            return fr
        default:
            return pl
    }
}

export const useDayToDisplay = (day: string) => {
    const intl = useIntl();

    const today = getTodayMessage(intl);
    const yesterday = getYesterdayMessage(intl);
    const getFormattedDate = useDateFormat();

    const date = mapUtcStringToLocalDate(day)!;
    let dayToDisplay = getFormattedDate(date);

    if (isToday(date)) {
        dayToDisplay = today;
    } else if (isYesterday(date)) {
        dayToDisplay = yesterday;
    }

    return dayToDisplay;
};

export const usePositionScroll = (
    messageListElement: HTMLDivElement | null,
    firstUnreadElement: HTMLDivElement | null
) => {
    const deviceClass = useDeviceClass();

    const sendBoxHeight = 100;

    const scrollToFirstUnread = useCallback(
        (messageListElement: HTMLDivElement, firstUnreadElement: HTMLDivElement) => {
            if (
                deviceClass !== "desktop" &&
                messageListElement.clientHeight + messageListElement.getBoundingClientRect().top - sendBoxHeight >
                window.outerHeight
            ) {
                firstUnreadElement.scrollIntoView(false);
                const currentScrollPosition = window.scrollY;
                window.scrollTo(0, currentScrollPosition + sendBoxHeight);
            } else {
                firstUnreadElement.scrollIntoView(false);
                const currentScrollPosition = messageListElement.scrollTop;

                if (currentScrollPosition > sendBoxHeight) {
                    messageListElement.scrollTop = currentScrollPosition + sendBoxHeight;
                }
                window.scrollTo(0, 0);
            }
        },
        [deviceClass]
    );

    const scrollToLastMessage = useCallback(
        (messageListElement: HTMLDivElement) => {
            if (deviceClass !== "desktop") {
                messageListElement.clientHeight + messageListElement.getBoundingClientRect().top - sendBoxHeight >
                    window.outerHeight && window.scrollTo(0, document.body.scrollHeight);
            } else {
                messageListElement.scrollTop = messageListElement.scrollHeight;
            }
        },
        [deviceClass]
    );

    const handleScrollPosition = useCallback(() => {
        if (firstUnreadElement && messageListElement) {
            scrollToFirstUnread(messageListElement, firstUnreadElement);
        } else if (messageListElement) {
            scrollToLastMessage(messageListElement);
        }
    }, [firstUnreadElement, messageListElement, scrollToFirstUnread, scrollToLastMessage]);

    useEffect(() => {
        handleScrollPosition();
    }, [handleScrollPosition]);
};

export const useScrolledToBottom = (messageListElement: HTMLDivElement | null) => {
    const deviceClass = useDeviceClass();
    const [scroll, setScroll] = useState(window.scrollY);

    const messageListElementHeight = messageListElement?.clientHeight ?? 0;
    const messageListElementScrollHeight = messageListElement?.scrollHeight ?? 0;

    useEffect(() => {
        function handleEventMobile() {
            setScroll(window.scrollY);
        }

        function handleEventDesktop() {
            if (messageListElement) {
                setScroll(messageListElement.scrollTop);
            }
        }

        if (deviceClass !== "desktop") {
            window.addEventListener("scroll", handleEventMobile);
            window.addEventListener("resize", handleEventMobile);
        } else {
            messageListElement?.addEventListener("scroll", handleEventDesktop);
            messageListElement?.addEventListener("resize", handleEventDesktop);
        }

        return () => {
            window.removeEventListener("scroll", handleEventMobile);
            messageListElement?.removeEventListener("scroll", handleEventDesktop);
        };
    }, [deviceClass, messageListElement, scroll]);

    return deviceClass !== "desktop"
        ? Math.ceil(scroll + window.innerHeight) === document.body.scrollHeight
        : Math.ceil(scroll + messageListElementHeight) === messageListElementScrollHeight;
};
