import { cookieNames } from "features/cookies/constants";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getCookie = <T extends any>(name: string): T => {
    const cookiesAccepted = cookies.get(cookieNames.COOKIES_ACCEPTED);
    return cookiesAccepted ? cookies.get(name) : undefined;
};

export const setCookie = <T extends any>(name: string, value: T, options = {}) => {
    const cookiesAccepted = cookies.get(cookieNames.COOKIES_ACCEPTED);
    (cookiesAccepted || name === cookieNames.COOKIES_ACCEPTED) && cookies.set(name, value, options);
};
