import { ReactComponent as ChevronDown } from "assets/icons/chevronDownWhite.svg";
import Button from "features/common/components/Button";
import Checkbox from "features/common/components/Checkbox";
import { cookieNames } from "features/cookies/constants";
import { getCookie, setCookie } from "features/cookies/utils";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

const CookieBanner = () => {
    const [cookiesAccepted, setCookiesAccepted] = useState(getCookie<boolean>(cookieNames.COOKIES_ACCEPTED));
    const [showAdvancedCookiesSettings, setShowAdvancedCookiesSettings] = useState<boolean>(false)

    const [enableGoogleAnalytics, setEnableGoogleAnalytics] = useState<boolean>(false);
    const [enableGoogleAds, setEnableGoogleAds] = useState<boolean>(false);

    const onAcceptClick = () => {
        setEnableGoogleAds(true);
        setEnableGoogleAnalytics(true);
        setCookie(cookieNames.COOKIES_ACCEPTED, true);
        setCookiesAccepted(true);
        turnOnGoogleCookies(true);
    };
    const onAdvancedSettingsClick = () => {
        showAdvancedCookiesSettings ? setShowAdvancedCookiesSettings(false) : setShowAdvancedCookiesSettings(true)
    }
    const acceptAdvancedCookies = () => {
        setCookie(cookieNames.COOKIES_ACCEPTED, true);
        setCookiesAccepted(true);
        turnOnGoogleCookies(false);
    }
    function activateGtag(w: any, d: any, s: string, l: string, i: string) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    }
    /*function initGtag() {
        gtag('js', new Date());
        gtag('config', 'G-ZXWSV2Q5QC');
    }*/
    const declineAdvancedCookies = () => {
        setShowAdvancedCookiesSettings(false)
        setEnableGoogleAnalytics(false);
        setEnableGoogleAds(false);
    }
    function turnOnGoogleCookies(allAccepted: boolean) {
        if (enableGoogleAds === false && !allAccepted) {
            // Disable Google Ads
            // @ts-ignore
            gtag('set', { 'allow_ad_personalization_signals': false });
        }
        if (enableGoogleAnalytics === false && !allAccepted) {
            // Disable Google Analytics
            // @ts-ignore
            window['ga-disable-UA-225030999-1'] = true
        }
        activateGtag(window, document, 'script', 'dataLayer', 'GTM-K8WMV9K');
    }
    const handleInputChange = (e: Event | any) => {
        // For Google analytics things
        // Checkbox for Google Analytics checked
        if (e.target.value === "ga") {
            setEnableGoogleAnalytics(e.target.checked);
        } // Checkbox for Google Ads checked
        else if (e.target.value === "gads") {
            setEnableGoogleAds(e.target.checked)
        }
    }
    const advancedSettingsModal = () => {
        return (
            <div className={styles["cookie-banner__advancedSettings"]}>
                <div className={styles["cookie-banner__insideScroll"]}>
                    <h1 className={styles["cookie-banner__header"]} style={{ color: "#fff" }}><FormattedMessage id="cookie_adv_header" /></h1>
                    <p className={styles["ner__textContent"]}><FormattedMessage id="cookie_adv_paragraph" /></p>
                    <ul>
                        {cookiesModalInfo.map((element: any, index: number) => {
                            return <li key={index} className={styles["cookie-banner__cookieOption"]} onClick={(e) => toggleExpand(e)}>
                                <ChevronDown />
                                <div className={styles["cookie-banner__content"]}>
                                    <FormattedMessage id={element.title} />
                                    <p className={styles["cookie-banner__paragraph"]}><FormattedMessage id={element.content} /></p>
                                </div><input className={styles["cookie-banner__checkbox"]} type="checkbox" onChange={(e) => handleInputChange(e)} value={element.value ? element.value : ""} />
                            </li>
                        })}
                    </ul>
                </div>
                <div className={styles["cookie-banner__advancedSettingsButtons"]}>
                    <Button
                        data-testid="cookie-banner__confirm-button"
                        className={styles["cookie-banner__confirm-button"]}
                        onClick={() => declineAdvancedCookies()}>
                        <FormattedMessage id="common__cancel" />
                    </Button>
                    <Button
                        data-testid="cookie-banner__confirm-button"
                        className={styles["cookie-banner__confirm-button"]}
                        onClick={() => acceptAdvancedCookies()}>
                        <FormattedMessage id="common__save" />
                    </Button>
                </div>
            </div>
        )
    }
    const toggleExpand = (e: Event | any) => {
        if (e.target.tagName === "svg" || e.target.tagName === "DIV") {
            if (e.target.parentNode.style.minHeight === "140px") {
                e.target.parentNode.style.minHeight = '18px'
                e.target.parentNode.querySelector('p').style.display = "none"
                e.target.parentNode.querySelector('svg').style.display = "block"
            }
            else {
                e.target.parentNode.style.minHeight = '140px';
                e.target.parentNode.querySelector('p').style.display = "block"
                e.target.parentNode.querySelector('svg').style.display = "none"
            }
        } else if (e.target.tagName === "LI") {
            if (e.target.style.minHeight === "140px") {
                e.target.style.minHeight = '18px'
                e.target.querySelector('p').style.display = "none"
                e.target.querySelector('svg').style.display = "block"
            } else {
                e.target.style.minHeight = '140px';
                e.target.querySelector('p').style.display = "block"
                e.target.querySelector('svg').style.display = "none"
            }
        }
    }
    const cookiesModalInfo = [{ title: 'cookie_adv_1h', content: 'cookie_adv_1p' },
    { title: 'cookie_adv_2h', content: 'cookie_adv_2p', value: "ga" },
    { title: 'cookie_adv_3h', content: 'cookie_adv_3p' },
    { title: 'cookie_adv_4h', content: 'cookie_adv_4p', value: "gads" },
    { title: 'cookie_adv_5h', content: 'cookie_adv_5p' },
    { title: 'cookie_adv_6h', content: 'cookie_adv_6p' }]

    return !cookiesAccepted ? (
        <div data-testid="cookie-banner" className={styles["cookie-banner"]}>
            {showAdvancedCookiesSettings ? (
                advancedSettingsModal()
            ) : null}
            <FormattedMessage id="cookie-banner__message" />
            <div className={styles["cookie-banner__buttons"]}>
                <Button
                    data-testid="cookie-banner__confirm-button"
                    className={styles["cookie-banner__confirm-button"]}
                    onClick={() => onAdvancedSettingsClick()}
                >
                    <FormattedMessage id="cookie_accept_chosen" />
                </Button>
                <Button
                    data-testid="cookie-banner__confirm-button"
                    className={styles["cookie-banner__confirm-button"]}
                    onClick={() => onAcceptClick()}>
                    <FormattedMessage id="cookie_accept_all" />
                </Button>
            </div>
        </div>
    ) : null;
};

export default CookieBanner;
