import {
    selectBranches,
    selectBranchesLoading,
    selectBranchesLoadingError,
} from "features/common/selectors";
import {
    getBranchesAsync,
} from "features/common/thunks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "store";
import DetailsForm from "./component";

const mapStateToProps = (state: RootState) => ({
    branches: selectBranches(state),
    branchesLoading: selectBranchesLoading(state),
    branchesLoadingError: selectBranchesLoadingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getBranchesAsync,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DetailsForm);
