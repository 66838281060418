/* eslint-disable max-lines */
import { ApiError } from "api/utils";
import config from "config";
import { amendPageToState } from "features/common/utils";
import JobOffersAction, {
    acceptApplication,
    acceptArrival,
    amendJobOffers,
    createJobOffer,
    endOffer,
    getApplicationDetails,
    getApplications,
    getInterested,
    getJobOfferCardDetails,
    getJobOfferDetails,
    getJobOffers,
    getMyJobOffers,
    giveBonus,
    rejectApplication,
    resetCreateJobOfferState,
    resetFenigePayment,
    resetJobOfferDetails,
    resetJobOffers,
    resetJobOffersFilterMatchCounts,
    resetMyJobOffers,
    sendDedicationJobOffer,
    resetSendDedicationJobOfferState,
    startFenigePayment,
} from "features/jobOffers/actions";
import {
    mapApplicationDetailsDtoToApplicationDetails,
    mapApplicationsListDtoToApplicationsList,
    mapInterestedListDtoToInterestedList,
    mapJobOfferCardDetailsDtoToOfferCardDetails,
    mapJobOfferDetailsDtoToJobOfferDetails,
    mapJobOffersDtoToJobOffers,
    mapJobOffersResponseDtoToJobOffersFilterMatchCounts,
    mapMyJobOffersDtoToMyJobOffersCardDetailsKeyValue,
} from "features/jobOffers/mappers";
import {
    Application,
    ApplicationDetails,
    FenigePaymentStatus,
    Interested,
    JobOffer,
    JobOfferCardDetails,
    JobOfferDetails,
    MyJobOffersCardDetails,
} from "features/jobOffers/models";
import { JobOffersFilterMatchCounts } from "features/jobOffers/types";
import { mapCreateOfferApiErrorsToCreateOfferComponentErrors } from "features/offers/mappers";
import { CreateOfferApiError, CreateOfferComponentError, OfferStatus } from "features/offers/types";
import produce from "immer";
import { getType } from "typesafe-actions";
import { MyJobOffersResponseDto } from "../dtos";

export interface JobOffersState {
    jobOffers?: Array<JobOffer | undefined>;
    jobOffersLoading?: boolean;
    jobOffersLoadingError?: ApiError;
    totalJobOffers?: number;
    myJobOffers: Map<Number, MyJobOffersCardDetails>;
    myJobOffersRequestedPages: Set<Number>;
    myJobOffersPage: number;
    myJobOffersTotalPages?: number;
    myJobOffersLoading: boolean;
    myJobOffersLoadingError?: ApiError;
    interested?: Interested[];
    interestedLoading: boolean;
    interestedError?: ApiError;
    applications?: Application[];
    applicationsLoading: boolean;
    applicationsError?: ApiError;
    jobOffersFilterMatchCounts?: JobOffersFilterMatchCounts;
    jobOffersFilterMatchCountsLoading?: boolean;
    jobOffersFilterMatchCountsLoadingError?: ApiError;
    jobOfferCreating?: boolean;
    jobOfferCreatingSuccess?: number;
    jobOfferCreatingError?: ApiError;
    jobOfferCreatingErrorsArray?: CreateOfferComponentError[];
    jobOfferDetails?: JobOfferDetails;
    jobOfferDetailsLoading: boolean;
    jobOfferDetailsLoadingError?: ApiError;
    jobOfferCardDetails?: JobOfferCardDetails;
    jobOfferCardDetailsLoading: boolean;
    jobOfferCardDetailsLoadingError?: ApiError;
    endingOffer: boolean;
    endOfferSuccess?: boolean;
    endingOfferError?: ApiError;
    applicationDetails?: ApplicationDetails;
    applicationDetailsLoading: boolean;
    applicationDetailsError?: ApiError;
    acceptApplicationLoading: boolean;
    acceptApplicationSuccess?: boolean;
    acceptApplicationError?: ApiError;
    rejectApplicationLoading: boolean;
    rejectApplicationSuccess?: boolean;
    rejectApplicationError?: ApiError;
    acceptArrivalLoading: boolean;
    acceptArrivalSuccess?: boolean;
    acceptArrivalError?: ApiError;
    giveBonusLoading: boolean;
    giveBonusSuccess?: boolean;
    giveBonusError?: ApiError;
    fenigePaymentStarted: boolean;
    fenigePaymentSuccess?: FenigePaymentStatus;
    fenigePaymentError?: ApiError;
    sendDedicationLoading?: boolean;
    sendDedicationSuccess?: any;
    sendDedicationError?: ApiError;
}

const reducer = (
    state: JobOffersState = {
        jobOfferDetailsLoading: false,
        myJobOffers: new Map<Number, MyJobOffersCardDetails>(),
        myJobOffersRequestedPages: new Set<Number>(),
        myJobOffersLoading: false,
        myJobOffersPage: 0,
        endingOffer: false,
        jobOfferCardDetailsLoading: false,
        interestedLoading: false,
        applicationsLoading: false,
        applicationDetailsLoading: false,
        acceptApplicationLoading: false,
        rejectApplicationLoading: false,
        acceptArrivalLoading: false,
        giveBonusLoading: false,
        fenigePaymentStarted: false,
    },
    action: JobOffersAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case getType(getJobOffers.request):
                draft.jobOffersLoading = true;
                draft.jobOffersLoadingError = undefined;
                break;
            case getType(getJobOffers.success):
                draft.jobOffersLoading = false;
                draft.jobOffers = mapJobOffersDtoToJobOffers(
                    action.payload.data.page.content || action.payload.data.content || []
                );
                draft.jobOffersFilterMatchCounts = mapJobOffersResponseDtoToJobOffersFilterMatchCounts(action.payload);
                draft.jobOffersLoadingError = undefined;
                draft.totalJobOffers = action.payload.data.page.total || action.payload.data.total;
                break;
            case getType(getJobOffers.failure):
                draft.jobOffersLoading = !action.payload || false;
                draft.jobOffers = undefined;
                draft.totalJobOffers = undefined;
                draft.jobOffersLoadingError = action.payload;
                break;
            case getType(getMyJobOffers.request):
                const setTmp = new Set(state.myJobOffersRequestedPages);
                setTmp.add(state.myJobOffersPage);
                draft.myJobOffersLoading = true;
                draft.myJobOffersLoadingError = undefined;
                draft.myJobOffersRequestedPages = setTmp;
                break;
            case getType(getMyJobOffers.success):
                const tmpMap = new Map<Number, MyJobOffersCardDetails>(state.myJobOffers);
                mapMyJobOffersDtoToMyJobOffersCardDetailsKeyValue(action.payload).forEach(({ key, value }) => {
                    tmpMap.set(key, value);
                });
                draft.myJobOffers = tmpMap;
                if (state.myJobOffersRequestedPages.has(state.myJobOffersPage)) {
                    draft.myJobOffersPage = state.myJobOffersPage + 1;
                }
                draft.myJobOffersTotalPages = action.payload.data.totalPages;
                draft.myJobOffersLoading = false;
                draft.myJobOffersLoadingError = undefined;
                break;
            case getType(getMyJobOffers.failure):
                draft.myJobOffersLoading = false;
                draft.myJobOffersLoadingError = action.payload;
                break;
            case getType(getMyJobOffers.cancel):
                break;
            case getType(amendJobOffers.request):
                draft.jobOffersLoading = true;
                draft.jobOffersLoadingError = undefined;
                break;
            case getType(amendJobOffers.success):
                draft.jobOffersLoading = false;
                draft.jobOffers = amendPageToState(
                    mapJobOffersDtoToJobOffers(action.payload.data.page.content || action.payload.data.content || []),
                    config.offersResultsPerPage,
                    action.payload.data.page.pageable.pageNumber,
                    state.jobOffers
                );
                draft.jobOffersFilterMatchCounts = mapJobOffersResponseDtoToJobOffersFilterMatchCounts(action.payload);
                draft.jobOffersLoadingError = undefined;
                draft.totalJobOffers = action.payload.data.page.total || action.payload.data.total;
                break;
            case getType(amendJobOffers.failure):
                draft.jobOffersLoading = false;
                draft.jobOffers = undefined;
                draft.totalJobOffers = undefined;
                draft.jobOffersLoadingError = action.payload;
                break;
            case getType(getInterested.request):
                draft.interestedLoading = true;
                draft.interested = undefined;
                draft.interestedError = undefined;
                break;
            case getType(getInterested.success):
                draft.interestedLoading = false;
                draft.interested = mapInterestedListDtoToInterestedList(action.payload);
                draft.interestedError = undefined;
                break;
            case getType(getInterested.failure):
                draft.interestedLoading = false;
                draft.interested = undefined;
                draft.interestedError = action.payload;
                break;
            case getType(getApplications.request):
                draft.applicationsLoading = true;
                draft.applications = undefined;
                draft.applicationsError = undefined;
                break;
            case getType(getApplications.success):
                draft.applicationsLoading = false;
                draft.applications = mapApplicationsListDtoToApplicationsList(action.payload);
                draft.applicationsError = undefined;
                break;
            case getType(getApplications.failure):
                draft.applicationsLoading = false;
                draft.applications = undefined;
                draft.applicationsError = action.payload;
                break;
            case getType(createJobOffer.request):
                draft.jobOfferCreating = true;
                draft.jobOfferCreatingSuccess = undefined;
                draft.jobOfferCreatingError = undefined;
                draft.jobOfferCreatingErrorsArray = undefined;
                break;
            case getType(createJobOffer.success):
                draft.jobOfferCreating = false;
                draft.jobOfferCreatingSuccess = action.payload.data.id;
                draft.jobOfferCreatingError = undefined;
                draft.jobOfferCreatingErrorsArray = undefined;
                break;
            case getType(createJobOffer.failure):
                draft.jobOfferCreating = false;
                draft.jobOfferCreatingSuccess = undefined;
                draft.jobOfferCreatingError = action.payload;
                draft.jobOfferCreatingErrorsArray = mapCreateOfferApiErrorsToCreateOfferComponentErrors(
                    action.payload?.response?.data as unknown as CreateOfferApiError[]
                );
                break;
            case getType(getJobOfferDetails.request):
                draft.jobOfferDetailsLoading = true;
                draft.jobOfferCreatingSuccess = undefined;
                draft.jobOfferDetailsLoadingError = undefined;
                break;
            case getType(getJobOfferDetails.success):
                draft.jobOfferDetailsLoading = false;
                draft.jobOfferDetails = mapJobOfferDetailsDtoToJobOfferDetails(action.payload);
                draft.jobOfferDetailsLoadingError = undefined;
                break;
            case getType(getJobOfferDetails.failure):
                draft.jobOfferDetailsLoading = false;
                draft.jobOfferDetails = undefined;
                draft.jobOfferDetailsLoadingError = action.payload;
                break;
            case getType(endOffer.request):
                draft.endingOffer = true;
                draft.endOfferSuccess = false;
                draft.endingOfferError = undefined;
                break;
            case getType(endOffer.success):
                draft.endingOffer = false;
                draft.endOfferSuccess = true;
                draft.endingOfferError = undefined;
                break;
            case getType(endOffer.failure):
                draft.endingOffer = false;
                draft.endOfferSuccess = false;
                draft.endingOfferError = action.payload;
                break;
            case getType(getJobOfferCardDetails.request):
                draft.jobOfferCardDetails = undefined;
                draft.jobOfferCardDetailsLoading = true;
                draft.jobOfferCardDetailsLoadingError = undefined;
                break;
            case getType(getJobOfferCardDetails.success):
                draft.jobOfferCardDetails = mapJobOfferCardDetailsDtoToOfferCardDetails(action.payload);
                draft.jobOfferCardDetailsLoading = false;
                draft.jobOfferCardDetailsLoadingError = undefined;
                break;
            case getType(getJobOfferCardDetails.failure):
                draft.jobOfferCardDetails = undefined;
                draft.jobOfferCardDetailsLoading = false;
                draft.jobOfferCardDetailsLoadingError = action.payload;
                break;
            case getType(resetJobOffers):
                draft.jobOffersLoading = false;
                draft.jobOffers = undefined;
                draft.totalJobOffers = undefined;
                draft.jobOffersLoadingError = undefined;
                break;
            case getType(resetMyJobOffers):
                draft.myJobOffers = new Map();
                draft.myJobOffersRequestedPages = new Set();
                draft.myJobOffersTotalPages = undefined;
                draft.myJobOffersPage = 0;
                draft.myJobOffersLoading = false;
                draft.myJobOffersLoadingError = undefined;
                draft.endingOffer = false;
                draft.endOfferSuccess = false;
                draft.endingOfferError = undefined;
                break;
            case getType(resetJobOfferDetails):
                draft.jobOfferDetails = undefined;
                draft.jobOfferDetailsLoading = false;
                draft.jobOfferDetailsLoadingError = undefined;
                break;
            case getType(resetCreateJobOfferState):
                draft.jobOfferCreating = false;
                draft.jobOfferCreatingSuccess = undefined;
                draft.jobOfferCreatingError = undefined;
                draft.jobOfferCreatingErrorsArray = undefined;
                break;
            case getType(resetJobOffersFilterMatchCounts):
                draft.jobOffersFilterMatchCounts = undefined;
                draft.jobOffersFilterMatchCountsLoading = undefined;
                draft.jobOffersFilterMatchCountsLoadingError = undefined;
                break;
            case getType(getApplicationDetails.request):
                draft.applicationDetailsLoading = true;
                draft.applicationDetails = undefined;
                draft.applicationDetailsError = undefined;
                break;
            case getType(getApplicationDetails.success):
                draft.applicationDetailsLoading = false;
                draft.applicationDetails = mapApplicationDetailsDtoToApplicationDetails(action.payload);
                draft.applicationDetailsError = undefined;
                draft.acceptApplicationSuccess = undefined;
                draft.rejectApplicationSuccess = undefined;
                draft.acceptArrivalSuccess = undefined;
                draft.giveBonusSuccess = undefined;
                break;
            case getType(getApplicationDetails.failure):
                draft.applicationDetailsLoading = false;
                draft.applicationDetails = undefined;
                draft.applicationDetailsError = action.payload;
                draft.acceptApplicationSuccess = undefined;
                draft.rejectApplicationSuccess = undefined;
                draft.acceptArrivalSuccess = undefined;
                draft.giveBonusSuccess = undefined;
                break;
            case getType(acceptApplication.request):
                draft.acceptApplicationLoading = true;
                draft.acceptApplicationSuccess = undefined;
                draft.acceptApplicationError = undefined;
                break;
            case getType(acceptApplication.success):
                draft.acceptApplicationLoading = false;
                draft.acceptApplicationSuccess = true;
                draft.acceptApplicationError = undefined;
                break;
            case getType(acceptApplication.failure):
                draft.acceptApplicationLoading = false;
                draft.acceptApplicationSuccess = undefined;
                draft.acceptApplicationError = action.payload;
                break;
            case getType(rejectApplication.request):
                draft.rejectApplicationLoading = true;
                draft.rejectApplicationSuccess = undefined;
                draft.rejectApplicationError = undefined;
                break;
            case getType(rejectApplication.success):
                draft.rejectApplicationLoading = false;
                draft.rejectApplicationSuccess = true;
                draft.rejectApplicationError = undefined;
                break;
            case getType(rejectApplication.failure):
                draft.rejectApplicationLoading = false;
                draft.rejectApplicationSuccess = undefined;
                draft.rejectApplicationError = action.payload;
                break;
            case getType(acceptArrival.request):
                draft.acceptArrivalLoading = true;
                draft.acceptArrivalSuccess = undefined;
                draft.acceptArrivalError = undefined;
                break;
            case getType(acceptArrival.success):
                draft.acceptArrivalLoading = false;
                draft.acceptArrivalSuccess = true;
                draft.acceptArrivalError = undefined;
                break;
            case getType(acceptArrival.failure):
                draft.acceptArrivalLoading = false;
                draft.acceptArrivalSuccess = undefined;
                draft.acceptArrivalError = action.payload;
                break;
            case getType(giveBonus.request):
                draft.giveBonusLoading = true;
                draft.giveBonusSuccess = undefined;
                draft.giveBonusError = undefined;
                break;
            case getType(giveBonus.success):
                draft.giveBonusLoading = false;
                draft.giveBonusSuccess = true;
                draft.giveBonusError = undefined;
                break;
            case getType(giveBonus.failure):
                draft.giveBonusLoading = false;
                draft.giveBonusSuccess = undefined;
                draft.giveBonusError = action.payload;
                break;
            case getType(startFenigePayment.request):
                draft.fenigePaymentStarted = true;
                draft.fenigePaymentSuccess = undefined;
                draft.fenigePaymentError = undefined;
                break;
            case getType(startFenigePayment.success):
                draft.fenigePaymentStarted = false;
                draft.fenigePaymentSuccess = action.payload;
                draft.fenigePaymentError = undefined;
                break;
            case getType(startFenigePayment.failure):
                draft.fenigePaymentStarted = false;
                draft.fenigePaymentSuccess = undefined;
                draft.fenigePaymentError = action.payload;
                break;
            case getType(resetFenigePayment):
                draft.fenigePaymentStarted = false;
                draft.fenigePaymentSuccess = undefined;
                draft.fenigePaymentError = undefined;
                break;
            case getType(sendDedicationJobOffer.request):
                draft.sendDedicationLoading = true;
                draft.sendDedicationSuccess = undefined;
                draft.sendDedicationError = undefined;
                break;
            case getType(sendDedicationJobOffer.success):
                draft.sendDedicationLoading = false;
                draft.sendDedicationSuccess = action.payload;
                draft.sendDedicationError = undefined;
                break;
            case getType(sendDedicationJobOffer.failure):
                draft.sendDedicationLoading = false;
                draft.sendDedicationSuccess = undefined;
                draft.sendDedicationError = action.payload;
                break;
            case getType(resetSendDedicationJobOfferState):
                draft.sendDedicationLoading = false;
                draft.sendDedicationSuccess = undefined;
                draft.sendDedicationError = undefined;
                break;
        }
    });
};

export default reducer;
