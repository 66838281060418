import FormDropdown from "features/common/components/FormDropdown";
import { DropdownOption } from "features/common/types";
import React from "react";
import styles from "./styles.module.scss";

function LocationRangePicker(props: { value: DropdownOption<unknown> | undefined; setFieldValue: (arg0: string, arg1: any) => void; distanceOptions: any[]; distancePlaceholder: () => string | undefined; distanceDisabled: boolean | undefined; }) {
  return (
    <FormDropdown
      selected={props.value}
      onSelect={e => props.setFieldValue("distance", props.distanceOptions.find(option => option.value === e))}
      placeholder={props.distancePlaceholder()}
      id="dropdown" disabled={props.distanceDisabled}
      options={props.distanceOptions}
      className={styles["offers-searchbox-form__distance"]}
      formDropdownClassName={styles["offers-searchbox-form__distance-dropdown"]}
    />);
}

export default LocationRangePicker;