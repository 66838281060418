import {ReactComponent as ArrowNextMobile} from "assets/icons/arrowNextMobile.svg";
import {ReactComponent as ArrowNextTablet} from "assets/icons/arrowNextTablet.svg";
import {ReactComponent as ArrowPrevMobile} from "assets/icons/arrowPrevMobile.svg";
import {ReactComponent as ArrowPrevTablet} from "assets/icons/arrowPrevTablet.svg";
import {ReactComponent as TimesIcon} from "assets/icons/times.svg";
import classNames from "classnames";
import config from "config";
import Modal from "features/common/components/Modal";
import {useMediaQuery} from "hooks/useMediaQuery";
import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import styles from "./styles.module.scss";

export interface Props {
    photoUrls: string[];
    ["data-testid"]?: string;
    onClick: () => void;
    parentId?: string;
    className?: string;
    modalContainerClassName?: string;
    ["data-testid"]?: string;
}

const PhotoModal = ({
    onClick,
    photoUrls,
    parentId,
    className,
    modalContainerClassName,
    "data-testid": testId = "photo-modal",
}: Props) => {
  const [nav1, setNav1] = useState<Slider | undefined>();
  const [nav2, setNav2] = useState<Slider | undefined>();
  const slider1 = useRef<Slider>(null);
  const slider2 = useRef<Slider>(null);
  const isMatches = useMediaQuery('(min-width: 834px)');
  const isMatchesForPagination = useMediaQuery('(min-width: 700px)');
  const isMatchesStartActiveSlide = useMediaQuery('(min-width: 481px)');
  const arrowPrev = isMatches ? <ArrowPrevTablet/> : <ArrowPrevMobile/>;
  const arrowNext = isMatches ? <ArrowNextTablet/> : <ArrowNextMobile/>;
  const arrowPaginationPrev = isMatchesForPagination ? <ArrowPrevTablet/> : <ArrowPrevMobile className={styles["arrow-section__pagination-icon-mob"]}/>;
  const arrowPaginationNext = isMatchesForPagination ? <ArrowNextTablet/> : <ArrowNextMobile className={styles["arrow-section__pagination-icon-mob"]}/>;
  const [imageIndex, setImageIndex] = useState(0);
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    slider1.current && setNav1(slider1.current);
    slider2.current && setNav2(slider2.current);
  }, []);

  useEffect(() => {
    setIsBrowser( true );
    
    window.addEventListener('keydown', onImagesClick)

    return () => {
      window.addEventListener('keydown', onImagesClick)
    }
  }, [] );

  const onImagesClick = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      onClick()
    }
  }
  
  const ifLessFourty = Math.min(photoUrls.length, 40);
  const four = 4 < ifLessFourty ? 4 : ifLessFourty; 
  const five = 5 < ifLessFourty ? 5 : ifLessFourty; 
  const seven = 7 < ifLessFourty ? 7 : ifLessFourty; 
  const nine = 9 < ifLessFourty ? 79 : ifLessFourty; 

  const MainPrevArrow = ({onClick} : { onClick: () => void }) => {
    return (
        <div 
        onClick={onClick}
        className={styles["arrow-section--left"]}
        >
            {arrowPrev}
        </div> 
      )
    };
    
    const MainNextArrow = ({onClick} : { onClick: () => void }) => {
      return (
        <div 
            onClick={onClick}
            className={styles["arrow-section--right"]}
          >
            {arrowNext}
        </div> 
      )
    };

    const PaginationPrevArrow = ({onClick} : { onClick: () => void }) => {
      return (
        <div 
            onClick={onClick}
            className={styles["arrow-section__left-pagination"]}
          >
            {arrowPaginationPrev}
        </div> 
      )
    };

    const PaginationNextArrow = ({onClick} : { onClick: () => void }) => {
      return (
        <div 
            onClick={onClick}
            className={styles["arrow-section__right-pagination"]}
          >
            {arrowPaginationNext}
        </div> 
      )
    };

    const settings = {
      className: "center",
      centerMode: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <MainPrevArrow onClick={ onClick }/>,
      nextArrow: <MainNextArrow onClick={ onClick } />,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            initialSlide: 0
          }
        },
        {
          breakpoint: 834,
          settings: {
            className: "center",
            centerMode: false,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          }
        },
        {
          breakpoint: 375,
          settings: {
            className: "center",
            centerMode: false,
            centerPadding: "0px",
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };

    const settings2 = {
      className: "center",
      centerPadding: "0px",
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: nine,
      slidesToScroll: 7,
      initialSlide: 0,
      beforeChange: (current: number, next: number) => setImageIndex(next),
      prevArrow: <PaginationPrevArrow onClick={ onClick }/>,
      nextArrow: <PaginationNextArrow onClick={ onClick } />,
      responsive: [
        {
          breakpoint: 3500,
          settings: {
            centerPadding: "0px",
            className: "center",
            centerMode: true,
            slidesToShow: nine,
            slidesToScroll: 9,
            dots: false
          }
        },
        {
          breakpoint: 2500,
          settings: {
            centerPadding: "0px",
            className: "center",
            centerMode: true,
            slidesToShow: seven,
            slidesToScroll: 7,
            dots: false
          }
        },
        {
          breakpoint: 1100,
          settings: {
            centerPadding: "0px",
            className: "center",
            centerMode: true,
            slidesToShow: five,
            slidesToScroll: 5,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: "30px",
            slidesToShow: four,
            slidesToScroll: 1,
            infinite: true,
            className: "center",
            centerMode: false,
            initialSlide: 0
          }
        }
      ]
    };

    return (
      <>
        {photoUrls.length > 0 && <Modal
            parentId={parentId}
            className={classNames(styles["photo-modal__modal"], className)}
            containerClassName={modalContainerClassName}
            data-testid={testId}
        >
            <div
                className={styles["photo-modal__close-button"]}
                data-testid={`${testId}__close-icon`}
                onClick={onClick}
            >
                <TimesIcon />
            </div>
            <div
                data-testid={`${testId}__content-container`}
                className={ styles["photo-modal__container"] }
            >
              <Slider {...settings} asNavFor={nav2} ref={slider1}>
                {photoUrls.map((url: string, index: number) => {
                    return (
                      <div className={styles["photo-modal__main-img-container"]} key={index}>
                        <img className={styles["photo-modal__img-main"]} src={`${config.IMAGE_URL}/${url}`} alt="enlarged images" />
                      </div>
                    )
                })}
              </Slider>
              <div className={styles["photo-modal__slider-container"]}>
                <Slider
                {...settings2}
                  asNavFor={nav1}
                  ref={slider2}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {photoUrls.map((url: string, index: number) => {
                    return (
                      <div className={classNames({ [styles["photo-modal__img-pagination-activeSlide"]] : index === imageIndex && isMatchesStartActiveSlide})} key={index}>
                        <img className={styles["photo-modal__img-pagination"]} src={`${config.IMAGE_URL}/${url}`} alt="small images list" />
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
        </Modal>}
        </>
    );
};

export default PhotoModal;
