import UnauthorizedAccess from "features/routing/pages/UnauthorizedAccess";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

type Props = RouteProps & {
    children: React.ReactNode;
    isAuthenticated?: boolean;
};

const PrivateRoute = ({ children, isAuthenticated, ...routeProps }: Props) => {
    return <Route {...routeProps} render={() => (isAuthenticated ? children : <UnauthorizedAccess />)} />;
};

export default PrivateRoute;
