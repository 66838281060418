import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { ApiError, CancelToken } from "api/utils";
import FormDropdown from "features/common/components/FormDropdown";
import ModalWithContent from "features/common/components/ModalWithContent";
import StepSwitch from "features/offers/components/StepSwitch";
import RadioButtons from "features/common/components/RadioButtons";
import CardStep from "features/jobOffers/components/JobOfferDetails/DetailsActionsBox/ApplicationSubmission/CardStep";
import { SelectedOfferProps, MyJobOffersCardDetails } from "features/jobOffers/models";
import { MyJobOffersFetchParams } from "features/jobOffers/types";
import { appRoutes } from "features/routing/routes";
import styles from "./styles.module.scss";
import { ReactComponent as MyOffersEmptyStateIcon } from "assets/icons/myOffersEmptyState.svg";
import { ReactComponent as MyOffersEmptyOfferIcon } from "assets/icons/myOffersEmptyStateColor.svg";
import SelectedOfferCard from "./SelectedOfferCard";
import { JobOfferDedicationDTO } from "../../../models";

enum SubmissionTypes {
    ActiveOffer = "active",
    DedicatedOffer = "dedicated",
}

export interface Props {
    myJobOffers?: MyJobOffersCardDetails[];
    myJobOffersLoading: boolean;
    myJobOffersLoadingError?: ApiError;
    dedicatedUserId: string;
    dedicatedBusinessCardId: number;
    getMyJobOffersAsync: (params: MyJobOffersFetchParams, cancelToken?: CancelToken) => void;
    sendDedicationAsync: (data: JobOfferDedicationDTO, cancelToken?: CancelToken) => void;
    onClose: () => void;
}

const OfferSubmissionModal = ({
    myJobOffers,
    myJobOffersLoading,
    myJobOffersLoadingError,
    dedicatedUserId,
    dedicatedBusinessCardId,
    getMyJobOffersAsync,
    onClose,
    sendDedicationAsync,
}: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [selectedCard, setSelectedCard] = useState<string | undefined>(undefined);
    const [submissionType, setSubmissionType] = useState<SubmissionTypes>(SubmissionTypes.ActiveOffer);
    const [selectedOffer, setSelectedOffer] = useState<number | undefined>();

    useEffect(() => {
        if (!myJobOffers?.length && !myJobOffersLoading && !myJobOffersLoadingError) {
            getMyJobOffersAsync({ page: 0, size: 9999, status: 'ACTIVE' });
        }
    }, []);
    const typesOptions = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: "employee-offer-submit__active-offer-label" }),
                value: SubmissionTypes.ActiveOffer,
            },
            {
                label: intl.formatMessage({ id: "employee-offer-submit__dedicated-offer-label" }),
                value: SubmissionTypes.DedicatedOffer,
            },
        ],
        [intl]
    );

    const availableJobOffers = myJobOffers?.filter(item => item.availablePositionsNumber! > 0 && item.status === 0)

    const offersOptions = useMemo(
        () =>
            availableJobOffers?.map((item) => ({
                display: item.title,
                value: item.id,
            })) || [],
        [myJobOffers]
    );

    const selectedOfferItem = useMemo(
        () =>
            myJobOffers?.find((item) => item.id === selectedOffer),
        [selectedOffer]
    )

    const selectedOfferOption = useMemo(
        () => offersOptions?.find((item) => item.value === selectedOffer),
        [offersOptions, selectedOffer]
    );

    const handleNextStepClick = () => {
        if (currentStepIndex === 0) {
            if (submissionType === SubmissionTypes.ActiveOffer && selectedOfferItem) {
                const sentData: JobOfferDedicationDTO = {
                    dedicatedBusinessCardId: dedicatedBusinessCardId,
                    jobOfferId: selectedOfferItem.id,
                    message: "OFFER_SENT"
                }
                sendDedicationAsync(sentData);
                onClose();

            } else {
                setCurrentStepIndex(1);
            }
        } else {
            if (selectedCard) {
                history.push(`${appRoutes.createDedicatedJobOffer}/${dedicatedBusinessCardId}/${selectedCard}`);
            }
        }
    };


    const buttonDisable = submissionType === SubmissionTypes.DedicatedOffer
        ? false : typeof selectedOfferItem == 'undefined';

    const handleSubmissionTypeChange = useCallback((value) => setSubmissionType(value), []);

    const modalContent = useMemo(
        () => (
            <StepSwitch
                currentStepIndex={currentStepIndex}
                stepContainerClassName={styles["application-submission__step-container"]}
            >
                <div className={styles["offer-submission-modal__content"]}>
                    <div className={styles["offer-submission-modal__title"]}>
                        <FormattedMessage id="employee-offer-submit__subtitle" />
                    </div>

                    <RadioButtons<SubmissionTypes>
                        wrapperClassName={styles["offer-submission-modal__types-wrapper"]}
                        options={typesOptions}
                        value={submissionType}
                        onValueChange={handleSubmissionTypeChange}
                    />
                    {submissionType === SubmissionTypes.ActiveOffer ? (
                        <div className={styles["offer-submission-modal__offer-picker-container"]}>
                            {myJobOffers?.length ? (
                                <div className={styles["offer-submission-modal__offers-container"]}>
                                    <FormDropdown<number>
                                        id="offer"
                                        options={offersOptions}
                                        selected={selectedOfferOption}
                                        onSelect={(value) => setSelectedOffer(value)}
                                        label={`${intl.formatMessage({ id: "employee-offer-submit__offer-label" })}*`}
                                        placeholder={`${intl.formatMessage({
                                            id: "employee-offer-submit__offer-label",
                                        })}*`}
                                    />
                                    <div className={styles["offer-submission-modal__active-offer-container"]}>
                                        {!selectedOffer ?
                                            <>
                                                <MyOffersEmptyOfferIcon />
                                                <div className={styles["offer-submission-modal__active-offer__title"]}>
                                                    <FormattedMessage id="employee-offer-submit__active-offer-title" />
                                                </div>
                                                <div className={styles["offer-submission-modal__empty-text"]}>
                                                    <FormattedMessage id="employee-offer-submit__active-offer-text" />
                                                </div>
                                            </>
                                            : <SelectedOfferCard offer={selectedOfferItem as SelectedOfferProps} />
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className={styles["offer-submission-modal__empty-offers-container"]}>
                                    <MyOffersEmptyStateIcon />
                                    <div className={styles["offer-submission-modal__empty-title"]}>
                                        <FormattedMessage id="employee-offer-submit__empty-title" />
                                    </div>
                                    <div>
                                        <FormattedMessage id="employee-offer-submit__empty-text" />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={styles["offer-submission-modal__dedicated-container"]}>
                            <FormattedMessage id="employee-offer-submit__dedicated-offer-info-1" />
                            <br />
                            <FormattedMessage id="employee-offer-submit__dedicated-offer-info-2" />
                        </div>
                    )}
                </div>
                <CardStep
                    data-testid="offer-submission-modal__data-step"
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    dedicatedUserId={dedicatedUserId}
                    asJobOffersList={true}
                />
            </StepSwitch>
        ),
        [
            currentStepIndex,
            dedicatedUserId,
            intl,
            myJobOffers,
            offersOptions,
            selectedCard,
            selectedOfferOption,
            submissionType,
        ]
    );

    return (
        <ModalWithContent
            className={classNames(styles["offer-submission-modal"], {
                [styles["offer-submission-modal__card-step-modal"]]: currentStepIndex === 1,
            })}
            primaryButtonContent={intl.formatMessage({ id: "common__next" })}
            cancelButtonHidden
            content={modalContent}
            onClose={onClose}
            primaryAction={handleNextStepClick}
            header={intl.formatMessage(submissionType === SubmissionTypes.ActiveOffer ? { id: "employee-offer-details__apply" } : { id: "employee-offer-submit__title" }, { stepNumber: 1 })}
            isScrollable
            primaryButtonDisabled={buttonDisable}
        />
    );
};

export default OfferSubmissionModal;
