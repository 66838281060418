import { ApiError } from "api/utils";
import Checkbox from "features/common/components/Checkbox";
import Datepicker from "features/common/components/Datepicker";
import FormDropdown from "features/common/components/FormDropdown";
import Input from "features/common/components/Input";
import RadioButtons from "features/common/components/RadioButtons";
import { DictionaryItem, WorkType } from "features/common/types";
import { EmployeeOfferType } from "features/employeeOffers/types";
import { JobOfferOfferDetailsStepSchema } from "features/jobOffers/schemas";
import { createOfferFieldNames } from "features/offers/constants";
import { useCreateOfferField } from "features/offers/hooks";
import flatten from "lodash/flatten";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styles from "./styles.module.scss";
import TagInput from "features/common/components/TagInput";
import { getLocationMessage } from "features/common/translationMessages";
import { locationFormatter } from "utils";

const createWorkTypeOptions = (intl: IntlShape) => [
    {
        label: intl.formatMessage({ id: "common__steady-job-type" }),
        value: WorkType.Steady,
    },
    {
        label: intl.formatMessage({ id: "common__seasonal-job-type" }),
        value: WorkType.SeasonalTemporary,
    },
    {
        label: intl.formatMessage({ id: "common__odd-job-type" }),
        value: WorkType.Odd,
    },
];

const createEmployeeOfferTypeOptions = (intl: IntlShape) => [
    {
        label: intl.formatMessage({ id: "create-offer__individual" }),
        value: EmployeeOfferType.Single,
    },
    {
        label: intl.formatMessage({ id: "create-offer__group-offer" }),
        value: EmployeeOfferType.Group,
    },
];

const mapDictionaryItemsToDropdownOptions = (items: DictionaryItem[], intl: IntlShape) =>
    items.map((item) => ({
        value: `${item.id}`,
        display: intl.formatMessage({ id: item.name }),
    }));

export interface Props {
    values: JobOfferOfferDetailsStepSchema;
    branches?: DictionaryItem[];
    branchesLoading?: boolean;
    branchesLoadingError?: ApiError;
    getBranchesAsync: () => void;
}

const OfferDetailsStep = ({ branches, branchesLoading, getBranchesAsync }: Props) => {
    const [location, setLocation] = useState("");
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [selectedTag, setSelectedTag] = useState<any>();
    const intl = useIntl();
    const locationIntlMessage = getLocationMessage(intl);

    useEffect(() => {
        if (!branches && !branchesLoading) {
            getBranchesAsync();
        }
    }, [branches, branchesLoading, getBranchesAsync]);

    const [positionField, positionMeta] = useCreateOfferField<string | undefined>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.position
    );

    const [branchField, branchMeta, branchHandlers] = useCreateOfferField<string | undefined>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.branchId
    );

    const [employeeOfferTypeField, employeeOfferTypeMeta, employeeOfferTypeHandlers] = useCreateOfferField<
        EmployeeOfferType | undefined
    >("offerDetailsStep", createOfferFieldNames.offerDetailsStep.employeeOfferType);

    const [positionsAvailableField, positionsAvailableMeta, positionAvailableHandlers] = useCreateOfferField<
        string | undefined
    >("offerDetailsStep", createOfferFieldNames.offerDetailsStep.positionsAvailable);

    const [workTypeField, workTypeMeta, workTypeHandlers] = useCreateOfferField<WorkType | undefined>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.workType
    );

    const [jobStartDateField, jobStartDateMeta, jobStartDateHandlers] = useCreateOfferField<Date | undefined>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.jobStartDate
    );

    const [jobEndDateField, jobEndDateMeta, jobEndDateHandlers] = useCreateOfferField<Date | undefined>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.jobEndDate
    );

    const [workDuringHolidaysField, workDuringHolidaysMeta, workDuringHolidaysHandlers] = useCreateOfferField<boolean>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.workDuringHolidays
    );

    const [locationField, locationMeta, locationHandlers] = useCreateOfferField<any>(
        "offerDetailsStep",
        createOfferFieldNames.offerDetailsStep.location
    );

    const branchesFlattened = flatten(branches?.map(branchItem => branchItem?.branches as any)) || [];
    const branchesOptions = mapDictionaryItemsToDropdownOptions(branchesFlattened, intl);
    const workTypeOptions = useMemo(() => createWorkTypeOptions(intl), [intl]);
    const employeeOfferTypeOptions = useMemo(() => createEmployeeOfferTypeOptions(intl), [intl]);

    const handleBranchChange = useCallback(
        (value) => {
            if (!branchMeta.touched) {
                branchHandlers.setTouched(true);
            }
            branchHandlers.setValue(value);
        },
        [branchMeta, branchHandlers]
    );

    const selectedBranch = useMemo(
        () => branchesOptions.find((item) => branchField.value === item.value),
        [branchField.value, branchesOptions]
    );

    const {
        placePredictions,
        getPlacePredictions,
        placesService
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500
    });

    useEffect(() => {
        console.warn({ locationField, placesService });
        if (placesService && locationField.value.placeId) {
            placesService?.getDetails({ placeId: locationField.value.placeId }, (placeDetails) => {
                const detailedAdress = {
                    placeId: placeDetails?.place_id,
                    input: placeDetails?.formatted_address || "",
                }
                locationHandlers.setValue(detailedAdress);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placesService]);

    useEffect(() => {
        getPlacePredictions({ input: location, types: ['(regions)']  });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!placePredictions.length) return;

        const options = placePredictions.map((result: any) => ({
            display: result.description,
            value: result.place_id,
        }));

        const formatedData = [
            {
                section: {
                    display: "Miasto",
                    value: "cities",
                },
                options,
            },
        ];
        setLocationOptions(formatedData);
    }, [location, placePredictions]);

    useEffect(() => {
        if (!selectedTag) return;

        // placesService?.getDetails({ placeId: selectedTag.id }, (placeDetails) => {
        //     const address = getSpecificLocation(placeDetails?.adr_address);
        //     const detailedAdress = {
        //         ...address,
        //         latitude: placeDetails?.geometry?.location?.lat?.(),
        //         longitude: placeDetails?.geometry?.location?.lng?.(),
        //         input: placeDetails?.formatted_address || "",
        //     }
        //     locationHandlers.setValue(detailedAdress)
        // });

        const detailedAddress = {
            placeId: selectedTag.id,
            input: selectedTag.name,
        }

        locationHandlers.setValue(detailedAddress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTag])

    const locationToShow = locationFormatter(locationField.value);

    const cityError = locationMeta.error as any;


    return (
        <div className={styles["offer-details-step"]} id="offerDetailsStep">
            <div className={styles["offer-details-step__column"]}>
                <div className={styles["offer-details-step__column-title"]}>
                    <FormattedMessage id="create-job-offer__position-information" />
                </div>
                <Input
                    label={`${intl.formatMessage({ id: "create-offer__position-name-label" })}*`}
                    placeholder={`${intl.formatMessage({ id: "create-offer__position-name-label" })}*`}
                    {...{ ...positionField, ...positionMeta }}
                    withValidation
                    isInvalid={!!positionMeta.error && !!positionMeta.touched}
                    wrapperClassName={styles["offer-details-step__input"]}
                    data-testid="offer-details-step__position-input"
                />
                <FormDropdown<string>
                    data-testid="offer-details-step__branch-input"
                    id="offer-details-step__branch-input"
                    className={styles["offer-details-step__input"]}
                    label={`${intl.formatMessage({ id: "common__branch" })}*`}
                    placeholder={`${intl.formatMessage({ id: "common__branch" })}*`}
                    options={branchesOptions}
                    selected={selectedBranch}
                    onSelect={handleBranchChange}
                    isInvalid={!!branchMeta.error && !!branchMeta.touched}
                    error={branchMeta.error}
                    withValidation
                />
                <RadioButtons<EmployeeOfferType>
                    data-testid="offer-details-step__employee-offer-type"
                    inputClassName={styles["offer-details-step__employee-offer-type-input"]}
                    options={employeeOfferTypeOptions}
                    wrapperClassName={styles["offer-details-step__employee-offer-type-wrapper"]}
                    titleClassName={styles["offer-details-step__employee-offer-type-title"]}
                    inputsContainerClassName={styles["offer-details-step__employee-offer-type-inputs-container"]}
                    title={`${intl.formatMessage({ id: "create-offer__offers-kind-label" })}*`}
                    value={employeeOfferTypeField.value}
                    onValueChange={(value: EmployeeOfferType) => employeeOfferTypeHandlers.setValue(value)}
                    isInvalid={!!employeeOfferTypeMeta.error && !!employeeOfferTypeMeta.touched}
                    error={employeeOfferTypeMeta.error}
                    withValidation
                />
                {employeeOfferTypeField.value === EmployeeOfferType.Group && (
                    <Input
                        label={`${intl.formatMessage({ id: "create-offer__number-of-canditates-to-work" })}*`}
                        placeholder={`${intl.formatMessage({
                            id: "create-offer__number-of-canditates-to-work",
                        })}*`}
                        {...{ ...positionsAvailableField, ...positionsAvailableMeta }}
                        withValidation
                        isInvalid={!!positionsAvailableMeta.error && !!positionsAvailableMeta.touched}
                        wrapperClassName={styles["offer-details-step__input"]}
                        data-testid="offer-details-step__positions-available-input"
                        type="number"
                    />
                )}
            </div>
            <div className={styles["offer-details-step__column"]}>
                <div className={styles["offer-details-step__column-title"]}>
                    <FormattedMessage id="create-offer__preferred-type-of-employment-label" />
                </div>
                <RadioButtons<WorkType>
                    data-testid="offer-details-step__work-type"
                    inputClassName={styles["offer-details-step__work-type-input"]}
                    options={workTypeOptions}
                    wrapperClassName={styles["offer-details-step__work-type-wrapper"]}
                    titleClassName={styles["offer-details-step__work-type-title"]}
                    inputsContainerClassName={styles["offer-details-step__work-type-inputs-container"]}
                    title={`${intl.formatMessage({ id: "common__work-type" })}*`}
                    value={workTypeField.value}
                    onValueChange={(value: WorkType) => workTypeHandlers.setValue(value)}
                    isInvalid={!!workTypeMeta.error && !!workTypeMeta.touched}
                    error={workTypeMeta.error}
                    withValidation
                />
                <Datepicker
                    startFromCurrentDate={true}
                    data-testid="offer-details-step__job-start-date"
                    id="offer-details-step__job-start-date"
                    className={styles["offer-details-step__input"]}
                    label={`${intl.formatMessage({ id: "create-offer__job-start-date-label" })}*`}
                    placeholder={`${intl.formatMessage({ id: "create-offer__job-start-date-label" })}*`}
                    onDateSelect={(date?: Date) => jobStartDateHandlers.setValue(date)}
                    onBlur={() => jobStartDateHandlers.setTouched(true)}
                    date={jobStartDateField.value}
                    isInvalid={!!jobStartDateMeta.touched && !!jobStartDateMeta.error}
                    error={!!jobStartDateMeta.touched ? jobStartDateMeta.error : undefined}
                    withValidation
                    addForwardYears
                />
                {workTypeField.value === WorkType.SeasonalTemporary && (
                    <Datepicker
                        startFromCurrentDate={true}
                        data-testid="offer-details-step__job-end-date"
                        id="offer-details-step__job-end-date"
                        className={styles["offer-details-step__input"]}
                        label={`${intl.formatMessage({ id: "create-offer__job-end-date-label" })}*`}
                        placeholder={`${intl.formatMessage({ id: "create-offer__job-end-date-label" })}*`}
                        onDateSelect={(date?: Date) => jobEndDateHandlers.setValue(date)}
                        onBlur={() => jobEndDateHandlers.setTouched(true)}
                        date={jobEndDateField.value}
                        isInvalid={!!jobEndDateMeta.touched && !!jobEndDateMeta.error}
                        error={!!jobEndDateMeta.touched ? jobEndDateMeta.error : undefined}
                        withValidation
                        addForwardYears
                    />
                )}
                <Checkbox
                    className={styles["offer-details-step__checkbox"]}
                    checked={workDuringHolidaysField.value}
                    label={intl.formatMessage({ id: "create-offer__work-during-holidays-label" })}
                    onToggle={(checked: boolean) => workDuringHolidaysHandlers.setValue(checked)}
                />
            </div>
            <div className={styles["offer-details-step__column"]}>
                <div className={styles["offer-details-step__column-title"]}>
                    <FormattedMessage id="create-job-offer__place-information" />
                </div>
                <div style={{ position: "relative" }}>
                    <TagInput
                        value={{ input: typeof locationField.value.input === 'string' ? locationField.value.input : locationToShow, tags: [] }}
                        onChange={(tags, input) => {
                            locationHandlers.setTouched(true);
                            setLocation(input)
                            if (tags.length) {
                                const [newValue] = tags;
                                setSelectedTag(newValue);
                                return locationHandlers.setValue({ input: newValue.name, tags });
                            }
                            return locationHandlers.setValue({ tags, input })
                        }}
                        sections={locationOptions}
                        label={`${locationIntlMessage}*`}
                        placeholder={`${locationIntlMessage}*`}
                        error={locationMeta.touched && (cityError?.country || cityError?.latitude || cityError?.longitude)}
                    />
                </div>
            </div>
        </div>
    );
};

export default OfferDetailsStep;
