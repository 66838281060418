import CreateJobOfferComponent from "features/jobOffers/components/CreateJobOffer";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "../CreateJobOffer/styles.module.scss";

interface QueryParams {
    id: string;
}

const CreateJobOffer = () => {
    const queryParams = useParams<QueryParams>();

    return (
        <div className={styles["create-offer"]}>
            <div className={styles["create-offer__inner-container"]}>
                <CreateJobOfferComponent offerId={queryParams.id || null} isCopying />
            </div>
        </div>
    );
};

export default CreateJobOffer;
