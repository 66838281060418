import { ApiError } from "api/utils";
import {
    BranchesDto,
    CountriesDto,
    CurrenciesDto,
    LocationsDetailsDto,
    LocationSuggestionDto,
    TermSuggestionDto,
} from "features/common/dtos";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const getBranches = createAsyncAction("GET_BRANCHES_REQUEST", "GET_BRANCHES_SUCCESS", "GET_BRANCHES_FAILURE")<
    void,
    BranchesDto,
    ApiError
>();

export const getSearchTermsSuggestions = createAsyncAction(
    "GET_SEARCH_TERMS_SUGGESTIONS_REQUEST",
    "GET_SEARCH_TERMS_SUGGESTIONS_SUCCESS",
    "GET_SEARCH_TERMS_SUGGESTIONS_FAILURE"
)<void, TermSuggestionDto, ApiError>();

export const resetSearchTermsSuggestions = createAction("RESET_SEARCH_TERMS_SUGGESTIONS")<void>();

export const getCurrencies = createAsyncAction(
    "GET_CURRENCIES_REQUEST",
    "GET_CURRENCIES_SUCCESS",
    "GET_CURRENCIES_FAILURE"
)<void, CurrenciesDto, ApiError>();

export const getLocationSuggestions = createAsyncAction(
    "GET_LOCATION_SUGGESTIONS_REQUEST",
    "GET_LOCATION_SUGGESTIONS_SUCCESS",
    "GET_LOCATION_SUGGESTIONS_FAILURE"
)<void, LocationSuggestionDto, ApiError>();

export const getCityDetails = createAsyncAction(
    "GET_CITY_DETAILS_REQUEST",
    "GET_CITY_DETAILS_SUCCESS",
    "GET_CITY_DETAILS_FAILURE"
)<void, LocationSuggestionDto, ApiError>();

export const getLocationsDetails = createAsyncAction(
    "GET_LOCATIONS_DETAILS_REQUEST",
    "GET_LOCATIONS_DETAILS_SUCCESS",
    "GET_LOCATIONS_DETAILS_FAILURE"
)<void, LocationsDetailsDto, ApiError>();

export const getCountries = createAsyncAction("GET_COUNTRIES_REQUEST", "GET_COUNTRIES_SUCCESS", "GET_COUNTRIES_ERROR")<
    void,
    CountriesDto[],
    ApiError
>();

export const resetCityDetails = createAction("RESET_CITY_DETAILS")<void>();

export const resetUserCards = createAction("RESET_USER_CARDS")<void>();

export const resetLocationSuggestions = createAction("RESET_LOCATION_SUGGESTIONS")<void>();

export const resetCountries = createAction("RESET_COUNTRIES")<void>();

type CommonAction =
    | ActionType<typeof getBranches>
    | ActionType<typeof getSearchTermsSuggestions>
    | ActionType<typeof getCurrencies>
    | ActionType<typeof getLocationSuggestions>
    | ActionType<typeof getCityDetails>
    | ActionType<typeof getLocationsDetails>
    | ActionType<typeof getCountries>
    | ActionType<typeof resetCityDetails>
    | ActionType<typeof resetUserCards>
    | ActionType<typeof resetSearchTermsSuggestions>
    | ActionType<typeof resetLocationSuggestions>
    | ActionType<typeof resetCountries>;

export default CommonAction;
