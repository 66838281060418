import { useEffect, useState } from "react";

const useElementPositionY = (id: string, refs: React.DependencyList = []) => {
    const top = document.getElementById(id)?.getBoundingClientRect()?.top;
    const [elementPositionY, setElementPositionY] = useState(top !== undefined ? top + window.screenY : 0);

    function handleResize() {
        setElementPositionY(document.getElementById(id)!.getBoundingClientRect()!.top + window.scrollY);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, ...refs]);

    return elementPositionY;
};

export default useElementPositionY;
