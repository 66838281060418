import Dropdown from "features/layout/components/Header/NotificationsDropdown";
import {MessagesApi} from "features/messages/api";
import {mapNotifications} from "features/messages/mappers";
import {User} from "features/user/models";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";

const api = new MessagesApi();


interface NotiProps {
    user?: User;
    isUserLoggedIn?: boolean;
    unreadMessagesCount?: number;
    logoutUserAsync: () => void;
    isMobile?: boolean;
}


const NotificationsComponent = ({ user, isUserLoggedIn, unreadMessagesCount, logoutUserAsync }: NotiProps) => {
    const intl = useIntl();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [unacknowledgedNotifications, setUnacknowledgedNotifications] = useState<any>([]);
    const [isOfferOwner, setIsOfferOwner] = useState(false);
    const [notifications, setNotifications] = useState<any[]>([]);

    const getNotifications = useCallback(async () => {
        const res = await api.getNotificationsAsync({ pageSize: 10 });
        console.log(res)
        if (res && res.length > 0) {
            setNotifications(mapNotifications(res, intl))
        }
    }, [intl])

    const handleNotificationAcknowledged = async (notificationId: string) => {
        await api.setNotificationAcknowledged(notificationId);
        getNotifications();
    }

    useEffect(() => {
        getNotifications();
    }, [getNotifications]);

    useEffect(() => {
        if (!notifications.length) {
            return;
        }

        const ackNotifications = notifications.filter((notifi: any) => !notifi.acknowledged);
        setUnacknowledgedNotifications(ackNotifications);
    }, [notifications]);

    const [selectedApplicationId, setSelectedApplicationId] = useState<number | null>(null);

    const handleCloseApplicationDetailsModal = () => {
        setSelectedApplicationId(null);
    }

    const handleClickedNotification = (notificationId: string) => {
        setIsOfferOwner(notifications.find((el: any) => el.notificationId === notificationId)?.notificationType === 'RATE_EMPLOYEE_INVITATION')
    }

    return (
        <div>
            <Header>
                <h2><FormattedMessage id="common__notifications" /></h2>
            </Header>
            <Dropdown
                selectedApplicationId={selectedApplicationId}
                setSelectedApplicationId={setSelectedApplicationId}
                onClickAway={() => setDropdownOpen(false)}
                userProfileExists={!!user?.firstName}
                isUserLoggedIn={isUserLoggedIn}
                unreadMessagesCount={unreadMessagesCount}
                logoutUserAsync={logoutUserAsync}
                items={notifications}
                handleNotificationAcknowledged={handleNotificationAcknowledged}
                setClickedNotification={handleClickedNotification}
                isMobile
            />
        </div>
    )
}

const Header = styled.div`
    display: flex;
    height: 72px;
    padding: 0 24px;
    align-items: center;
    background: white;
    margin: 10px 0;
    box-shadow: 4px 4px 15px rgba(0, 34, 120, 0.25);
    border-radius: 5px;
`

export default NotificationsComponent
