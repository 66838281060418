import classNames from "classnames";
import Dropdown from "features/common/components/Dropdown";
import { getCreateShortDayOfWeekMessage } from "features/common/translationMessages";
import mapMonthNumberToName from "features/common/mappers/mapMonthNumberToName";
import { DeviceClass } from "features/common/types";
import { range } from "features/common/utils";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

export interface Props {
    baseDate: Date;
    currentMonth: number;
    currentYear: number;
    yearSelectorExpanded: boolean;
    daysOfMonthArray: Array<Array<number | undefined>>;
    id?: string;
    date?: Date;
    endDate?: Date;
    selectedDate?: Date;
    hoveredDate?: Date;
    deviceClass?: DeviceClass;
    shouldBlockNextMonthSelect?: boolean;
    shouldBlockPrevMonthSelect?: boolean;
    yearsArray?: Array<Array<number>>;
    withRangePicker?: boolean;
    addForwardYears?: boolean;
    ["data-testid"]: string;
    onLocalDateSelect: (day?: number) => void;
    setYearSelectionExpandState?: (expanded: boolean) => void;
    onMonthSelect?: (month: number) => void;
    onYearSelect?: (year: number) => void;
    onHover?: (hoveredDate?: Date) => void;
    startFromCurrentDate?: boolean;
    startFromPickedDate?: boolean;
    endOnCurrentDate?: boolean;
}

const createYearOptions = (currect: boolean | undefined, addForwardYears?: boolean) => {
    const yearsOptions = [];
    let currentYear = new Date().getFullYear() + (!!addForwardYears ? 3 : 0);
    while (currentYear !== (currect ? new Date().getFullYear() - 1 : 1899)) {
        yearsOptions.push({
            value: currentYear,
            label: `${currentYear}`,
        });
        currentYear -= 1;
    }

    return yearsOptions;
};

const Calendar = ({
    "data-testid": testId,
    id,
    deviceClass,
    currentMonth,
    currentYear,
    yearSelectorExpanded,
    yearsArray,
    daysOfMonthArray,
    shouldBlockNextMonthSelect,
    shouldBlockPrevMonthSelect,
    date,
    endDate,
    selectedDate,
    hoveredDate,
    baseDate,
    withRangePicker,
    addForwardYears,
    onMonthSelect,
    onYearSelect,
    onLocalDateSelect,
    onHover,
    startFromCurrentDate,
    startFromPickedDate,
    endOnCurrentDate,
}: Props) => {
    const intl = useIntl();

    const getDayOfWeekMessage = getCreateShortDayOfWeekMessage(intl);

    const isDaySelected = useCallback(
        (day?: number) =>
            day === date?.getDate() && currentMonth === date?.getMonth() && currentYear === date?.getFullYear(),
        [currentMonth, currentYear, date]
    );

    const isBaseDay = useCallback(
        (day?: number) =>
            day === baseDate?.getDate() &&
            currentMonth === baseDate?.getMonth() &&
            currentYear === baseDate?.getFullYear(),
        [baseDate, currentMonth, currentYear]
    );

    const isEndDaySelected = useCallback(
        (day?: number) =>
            day === endDate?.getDate() &&
            currentMonth === endDate?.getMonth() &&
            currentYear === endDate?.getFullYear(),
        [currentMonth, currentYear, endDate]
    );

    const isInitialDaySelected = useCallback(
        (day?: number) =>
            day === selectedDate?.getDate() &&
            currentMonth === selectedDate?.getMonth() &&
            currentYear === selectedDate?.getFullYear(),
        [currentMonth, currentYear, endDate]
    );

    const isDayInStartEndRange = useCallback(
        (day?: number) => {
            if (date && endDate && day) {
                const areStartEndMonthsEqual = date.getMonth() === endDate.getMonth();
                const areStartEndYearsEqual = date.getFullYear() === endDate.getFullYear();

                if (
                    areStartEndMonthsEqual &&
                    areStartEndYearsEqual &&
                    date.getMonth() === currentMonth &&
                    date.getFullYear() === currentYear
                ) {
                    return day > date.getDate() && day < endDate.getDate();
                } else if (!areStartEndMonthsEqual || !areStartEndYearsEqual) {
                    const isCurrentMonthBeforeStartMonth =
                        currentYear < date.getFullYear() ||
                        (currentYear === date.getFullYear() && currentMonth < date.getMonth());

                    const isCurrentMonthAfterEndMonth =
                        currentYear > endDate.getFullYear() ||
                        (currentYear === endDate.getFullYear() && currentMonth > endDate.getMonth());

                    if (currentMonth === date.getMonth() && currentYear === date.getFullYear()) {
                        return day > date.getDate();
                    } else if (currentMonth === endDate.getMonth() && currentYear === endDate.getFullYear()) {
                        return day < endDate.getDate();
                    } else if (!isCurrentMonthBeforeStartMonth && !isCurrentMonthAfterEndMonth) {
                        return true;
                    }
                }
            }

            return false;
        },
        [date, endDate, currentMonth, currentYear]
    );

    const isDayInHoverRange = useCallback(
        (day?: number) => {
            if (day && hoveredDate && withRangePicker) {
                const dateFromDay = new Date(currentYear, currentMonth, day);

                if (date && !endDate) {
                    return dateFromDay.getTime() <= hoveredDate?.getTime() && dateFromDay.getTime() >= date.getTime();
                }
            }

            return false;
        },
        [currentMonth, currentYear, date, endDate, hoveredDate, withRangePicker]
    );

    const isDayHovered = (day?: number) =>
        new Date(currentYear, currentMonth, day).getTime() === hoveredDate?.getTime();

    const onDayClick = useCallback(
        (day?: number) => {
            onLocalDateSelect(day);
        },
        [onLocalDateSelect]
    );

    const yearOptions = useMemo(
        () => createYearOptions(startFromCurrentDate, addForwardYears),
        [addForwardYears, startFromCurrentDate]
    );

    const selectedYearOption = useMemo(
        () => yearOptions.find((year) => year.value === currentYear),
        [currentYear, yearOptions]
    );
   
    return (
        <div
            data-testid={`${testId}__calendar`}
            id={id}
            className={classNames(
                styles["calendar"],
                {
                    [styles["calendar--with-top-padding"]]: deviceClass === "desktop",
                },
                {
                    [styles["calendar--with-border"]]: deviceClass === "desktop" && withRangePicker,
                }
            )}
        >
            <div className={styles["calendar__nav"]}>
                <Dropdown
                    className={styles["calendar__year-dropdown"]}
                    toggleClassName={styles["calendar__year-dropdown-toggle"]}
                    optionsClassName={styles["calendar__year-dropdown-options"]}
                    optionClassName={styles["calendar__year-dropdown-option"]}
                    placeholderToggleClassName={styles["calendar__year-placeholder-dropdown-toggle"]}
                    selectedOptionClassName={styles["calendar__year-dropdown-option--selected"]}
                    data-testid={`${testId}__year-dropdown`}
                    options={yearOptions}
                    selected={selectedYearOption}
                    id="calendar-year-dropdown"
                    onSelect={onYearSelect}
                />

                <div className={styles["calendar__month-list"]}>
                    {new Array(12).fill("").map((a, index) => (
                        <div
                            onClick={() => {
                                if (
                                    (startFromCurrentDate &&
                                        index < baseDate.getMonth() &&
                                        selectedYearOption?.value === new Date().getFullYear()) ||
                                    (endOnCurrentDate &&
                                        index > baseDate.getMonth() &&
                                        selectedYearOption?.value === new Date().getFullYear())
                                ) {
                                    return;
                                }
                                onMonthSelect?.(index);
                            }}
                            className={classNames(styles["calendar__month-item"], {
                                [styles["calendar__month-item-selected"]]: currentMonth === index,
                                [styles["calendar__month-item-disabled"]]:
                                    (startFromPickedDate &&
                                        index < selectedDate!.getMonth() &&
                                        selectedYearOption?.value === selectedDate?.getFullYear()) ||
                                    (startFromCurrentDate &&
                                        index < baseDate.getMonth() &&
                                        selectedYearOption?.value === new Date().getFullYear()) ||
                                    (endOnCurrentDate &&
                                        index > baseDate.getMonth() &&
                                        selectedYearOption?.value === new Date().getFullYear()),
                            })}
                        >
                            {mapMonthNumberToName(index)}
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={classNames(styles["calendar__day-year-selectors-container"], {
                    [styles["calendar__day-year-selectors-container--non-fixed-height"]]:
                        withRangePicker && deviceClass !== "desktop",
                })}
            >
                <div className={styles["calendar__calendar-table"]}>
                    <div className={styles["calendar__days-of-week"]}>
                        {range(7).map((dayOfWeek) => (
                            <div key={dayOfWeek}>{getDayOfWeekMessage(dayOfWeek, true)}</div>
                        ))}
                    </div>
                    <div className={styles["calendar__days-of-month"]}>
                        {daysOfMonthArray.map((week, index) => (
                            <div key={index} className={styles["calendar__week-row"]}>
                                {week.map((day, index) => (
                                    <div
                                        data-testid={`${testId}__day-field`}
                                        key={index + (day ?? 0)}
                                        className={classNames(
                                            styles["calendar__day-field"],
                                            {
                                                [styles["calendar__day-field--in-hover-range"]]: isDayInHoverRange(day),
                                            },
                                            {
                                                [styles["calendar__day-field--in-hover-start-range"]]:
                                                    isDayInHoverRange(day) && isDaySelected(day),
                                            },
                                            {
                                                [styles["calendar__day-field--in-hover-end-range"]]:
                                                    isDayInHoverRange(day) &&
                                                    (isEndDaySelected(day) || isDayHovered(day)),
                                            },
                                            {
                                                [styles["calendar__day-field--empty"]]: !day,
                                            },
                                            {
                                                [styles["calendar__day-field--selected-and-hovered"]]:
                                                    isDaySelected(day) && isDayHovered(day),
                                            },
                                            {
                                                [styles["calendar__day-field--disabled"]]:
                                                    (startFromPickedDate &&
                                                        selectedYearOption?.value === selectedDate?.getFullYear() &&
                                                        currentMonth === selectedDate?.getMonth() &&
                                                        (day ?? 0) < selectedDate?.getDate()) ||
                                                    (startFromCurrentDate &&
                                                        selectedYearOption?.value === new Date().getFullYear() &&
                                                        currentMonth === baseDate?.getMonth() &&
                                                        (day ?? 0) < baseDate?.getDate()) ||
                                                    (endOnCurrentDate &&
                                                        selectedYearOption?.value === new Date().getFullYear() &&
                                                        currentMonth === baseDate?.getMonth() &&
                                                        (day ?? 0) > baseDate?.getDate()),
                                            }
                                        )}
                                        onClick={() => {
                                            if (
                                                startFromPickedDate &&
                                                selectedYearOption?.value === selectedDate?.getFullYear() &&
                                                currentMonth === selectedDate?.getMonth() &&
                                                (day ?? 0) < selectedDate?.getDate()
                                            ) {
                                                return;
                                            }
                                            if (
                                                startFromCurrentDate &&
                                                selectedYearOption?.value === new Date().getFullYear() &&
                                                currentMonth === baseDate.getMonth() &&
                                                (day ?? 0) < baseDate.getDate()
                                            ) {
                                                return;
                                            }
                                            if (
                                                endOnCurrentDate &&
                                                selectedYearOption?.value === new Date().getFullYear() &&
                                                currentMonth === baseDate.getMonth() &&
                                                (day ?? 0) > baseDate.getDate()
                                            ) {
                                                return;
                                            }
                                            onDayClick(day);
                                        }}
                                        onMouseOver={() => onHover && onHover(new Date(currentYear, currentMonth, day))}
                                        onMouseOut={() => onHover && onHover(undefined)}
                                    >
                                        <div
                                            className={classNames(
                                                styles["calendar__inner-day-field-container"],
                                                {
                                                    [styles["calendar__inner-day-field-container--in-range"]]:
                                                        isDayInStartEndRange(day),
                                                },
                                                {
                                                    [styles[
                                                        "calendar__inner-day-field-container--selected-start-in-range"
                                                    ]]: isDaySelected(day) && !!date && !!endDate,
                                                },
                                                {
                                                    [styles[
                                                        "calendar__inner-day-field-container--selected-end-in-range"
                                                    ]]: isEndDaySelected(day) && !!date && !!endDate,
                                                }
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    styles["calendar__inner-day-field"],
                                                    {
                                                        [styles["calendar__inner-day-field--hovered"]]:
                                                            isDayHovered(day),
                                                    },
                                                    {
                                                        [styles["calendar__inner-day-field--selected"]]:
                                                        isInitialDaySelected(day)|| isDaySelected(day) || isEndDaySelected(day),
                                                    },
                                                    {
                                                        [styles["calendar__inner-day-field--base"]]: (startFromPickedDate && isInitialDaySelected(day)) || (startFromCurrentDate && isBaseDay(day)),
                                                    }
                                                )}
                                            >
                                                {day ?? ""}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
