import React from "react";
import ActivationInfoComponent from "features/user/components/ActivationInfo";
import styles from "./styles.module.scss";

const ActivationInfo = () => {
    return (
        <div className={styles["activation-info"]}>
            <ActivationInfoComponent />
        </div>
    );
};

export default ActivationInfo;
