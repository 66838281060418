import React, { useEffect, useState } from 'react'
import ModalWithContent from "features/common/components/ModalWithContent";
import ErrorHeader from "features/common/components/ModalWithContent/ErrorHeader";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import JobOffersApi from 'features/jobOffers/api';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from './styles.module.scss'

export default function PaymentsModalPart({ fenigeId }: { fenigeId: string }) {
    const intl = useIntl()
    const [isLoadingFenige, setIsLoadingFenige] = useState(true);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [failureModalOpen, setFailureModalOpen] = useState(false);
    const closeSuccessModal = () => setSuccessModalOpen(false);
    const closeFailureModal = () => setFailureModalOpen(false);

    useEffect(() => {
        if (fenigeId) {
            const fenigeInterval = setInterval(async () => {
                try {
                    const paymentStatus = await JobOffersApi.checkFenigePaymentStatusAsync(fenigeId);
                    if (paymentStatus === "Authorization is success") {
                        clearInterval(fenigeInterval);
                        setSuccessModalOpen(true);
                    } else if (paymentStatus !== "Authorization is pending") {
                        clearInterval(fenigeInterval);
                        setFailureModalOpen(true);
                    }
                    setIsLoadingFenige(false)
                } catch (error) {
                    clearInterval(fenigeInterval);
                    setFailureModalOpen(true);
                    setIsLoadingFenige(false)
                }
            }, 1000);

            setTimeout(() => {
                clearInterval(fenigeInterval);
                setFailureModalOpen(true);
            }, 300000);
        } else {

            setIsLoadingFenige(false)
        }
        window.history.replaceState('', '', `${window.location.origin}${window.location.pathname}`);
    }, [])

    return (
        <>
            {!!successModalOpen && (
                <ModalWithContent
                    header={intl.formatMessage({ id: "payment__success-header" })}
                    content={intl.formatMessage({ id: "payment__success-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    primaryAction={closeSuccessModal}
                    onClose={closeSuccessModal}
                    cancelButtonHidden
                />
            )}
            {!!failureModalOpen && (
                <ModalWithContent
                    header={<ErrorHeader>{intl.formatMessage({ id: "payment__failure-header" })}</ErrorHeader>}
                    content={intl.formatMessage({ id: "payment__failure-text" })}
                    primaryButtonContent={intl.formatMessage({ id: "common__ok" })}
                    primaryAction={closeFailureModal}
                    onClose={closeFailureModal}
                    cancelButtonHidden
                />
            )}
            {isLoadingFenige && (
                <SpinnerWithMessage
                    className={styles['offer-details__loading-offer-details-modal']}
                    message={<FormattedMessage id="payment__loading-payment-data" />}
                />
            )}
        </>
    )
}
