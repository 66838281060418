import Box from "components/Box";
import Container from "components/Container";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { isTouchDevice } from "tests/helpers";
import { OfferStatus } from "../offers/types";
import CallAdvertisement from "./components/Advertisement/CallAdvertisement";
import TalkingHead from "./components/TalkingHead";
import Hero from "./Hero";
import Heroes from "./Heroes";
import Offer from "./Offer";
import Offers from "./Offers";
import Search from "./Search";
import useOffers from "./useOffers";

const Dashboard = () => {
    const intl = useIntl();
    const deviceClass = useDeviceClass()
    const { featuredOffers, allEmployeesOffers, newestOffers } = useOffers();
    return (
        <Box width="100%">
            <Hero />
            <Search />
            <StyledContainer>
                {featuredOffers.length > 0 && <Offers subheadline={intl.formatMessage({ id: "dashboard__featured-job-offers" })}>
                    {featuredOffers.map((offer, i) => <Offer
                        offer={offer}
                        key={offer.id}
                        last={i === allEmployeesOffers.length - 1}
                        target="/job-offers/" />)}
                </Offers>}
                <AdWrapper><Heroes /></AdWrapper>

                {newestOffers.length > 0 && (
                    <Offers subheadline={intl.formatMessage({ id: "dashboard__featured-newest-job-offers" })}>
                        {newestOffers.map((offer, i) => <Offer
                            offer={offer}
                            key={offer.id}
                            last={i === allEmployeesOffers.length - 1}
                            status={OfferStatus.Active}
                            target="/job-offers/" />)}
                    </Offers>
                )}
                <AdWrapper><CallAdvertisement /></AdWrapper>

                {allEmployeesOffers.length > 0 && (
                    <Offers style={{ color: "#FF6900" }} subheadline={intl.formatMessage({ id: "dashboard__featured-employee-offers" })}>
                        {allEmployeesOffers.map((offer, i) => <Offer
                            offer={offer}
                            key={offer.id}
                            last={i === allEmployeesOffers.length - 1}
                            status={OfferStatus.Active}
                            target="/employee-offers/" />)}
                    </Offers>
                )}
                {deviceClass === 'desktop' && !isTouchDevice() && (
                    <TalkingHead />

                )}
            </StyledContainer>

        </Box>
    );
};

const AdWrapper = styled.div`
    padding: 0 2.344rem;
    margin-bottom: 2.5rem;

    @media screen and (min-width: 834px) and (max-width: 1439px) {
    padding: 0 1.625rem;
    }

    @media screen and (min-width: 1440px) {
    padding: 0 3.5rem;
    }
`;

const StyledContainer = styled(Container)`
    margin: 0 auto;
    margin-top: -9.625rem;
    padding: 0;
    padding-top: 2.5rem;

    @media screen and (min-width: 415px) and (max-width: 833px) {
        padding-top: 0;
    }

 @media screen and (min-width: 834px) {
    margin-top: -6.563rem;
 }
`;

export default Dashboard;