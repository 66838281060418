import { ApiError, CancelToken } from "api/utils";
import { ReactComponent as AccountIcon } from "assets/icons/account.svg";
import { ReactComponent as EmptyCardsIcon } from "assets/icons/emptyCards.svg";
import config from "config";
import Accordion from "features/common/components/Accordion";
import SpinnerWithMessage from "features/common/components/SpinnerWithMessage";
import useDeviceClass from "features/common/hooks/useDeviceClass";
import useScrollToTopOnMount from "features/common/hooks/useScrollToTopOnMount";
import { Card, CardType } from "features/common/types";
import SingleCard from "features/myProfile/components/MyCards/SingleCard";
import { User } from "features/user/models";
import React, { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

export interface Params {
    userId: string;
}

export interface Props {
    userProfile?: User;
    userProfileLoading?: boolean;
    userProfileError?: ApiError;
    getUserProfileAsync: (userId: string, cancelToken?: CancelToken) => void;
}

const UserProfileComponent = ({ userProfile, userProfileLoading, userProfileError, getUserProfileAsync }: Props) => {
    const intl = useIntl();
    const { userId } = useParams<Params>();
    const isNotDesktop = useDeviceClass() !== "desktop";
    useScrollToTopOnMount();

    useEffect(() => {
        if (userId) {
            getUserProfileAsync(userId);
        }
    }, [getUserProfileAsync, userId]);

    const employerCards = useMemo(
        () =>
            userProfile?.businessCards?.filter(
                (card: Card) => card.type === CardType.EmployerCompany || card.type === CardType.EmployerPrivate
            ) || [],
        [userProfile]
    );
    const employeeCards = useMemo(
        () =>
            userProfile?.businessCards?.filter(
                (card: Card) => card.type === CardType.EmployeeCompany || card.type === CardType.EmployeePrivate
            ) || [],
        [userProfile]
    );

    if (userProfileLoading) {
        return <SpinnerWithMessage message={intl.formatMessage({ id: "user-profile__loading" })} />;
    }

    return (
        <div className={styles["user-profile"]}>
            <div className={styles["user-profile__header"]}>
                <h2 className={styles["user-profile__main-header"]}>
                    <FormattedMessage id="common__user-profile" />
                </h2>
            </div>
            {userProfileError || !userProfile ? (
                <div className={styles["user-profile__error-container"]}>
                    <FormattedMessage id="user-profile__loading-error" />
                </div>
            ) : (
                <>
                    <div className={styles["user-profile__data-container"]}>
                        {isNotDesktop && <div className={styles["user-profile__line-breaker"]}></div>}
                        <div className={styles["user-profile__header-container"]}>
                            <h2 className={styles["user-profile__main-header"]}>
                                <FormattedMessage id="user-profile__data-header" />
                            </h2>
                        </div>
                        <div className={styles["user-profile__user-profile-container"]}>
                            <div className={styles["user-profile__image-container"]}>
                                <div className={styles["user-profile__user-profile-image"]}>
                                    {userProfile?.imageUrl ? (
                                        <img src={`${config.IMAGE_URL}/${userProfile?.imageUrl}`} alt="" />
                                    ) : (
                                        <div className={styles["user-profile__default-profile-image-container"]}>
                                            <AccountIcon />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles["user-profile__details-container"]}>
                                <div className={styles["user-profile__user-details"]}>
                                    <div className={styles["user-profile__user-details-row"]}>
                                        <div className={styles["user-profile__field-value-column"]}>
                                            <div className={styles["user-profile__field-name-column"]}>
                                                <FormattedMessage id="user-profile__name-and-surname-header" />
                                            </div>
                                        </div>
                                        <div className={styles["user-profile__field-value-column"]}>
                                            <span className={styles["user-profile__field-content"]}>
                                                {userProfile.firstName} {userProfile.lastName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles["user-profile__user-details-row"]}>
                                        <div className={styles["user-profile__field-name-column"]}>
                                            <FormattedMessage id="common__mail" />:
                                        </div>
                                        <div className={styles["user-profile__field-value-column"]}>
                                            <span className={styles["user-profile__field-content"]}>
                                                {userProfile.email}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["user-profile__cards-container"]}>
                        {isNotDesktop && <div className={styles["user-profile__line-breaker"]}></div>}
                        <div>
                            <h2 className={styles["user-profile__main-header"]}>
                                <FormattedMessage id="user-profile__cards-header" />
                            </h2>
                        </div>
                        <div className={styles["user-profile__cards-list"]}>
                            {employerCards.length === 0 && employeeCards.length === 0 && (
                                <>
                                    <div className={styles["user-profile__empty-icon"]}>
                                        <EmptyCardsIcon />
                                    </div>
                                    <div className={styles["user-profile__primary-footer"]}>
                                        <FormattedMessage id="user-profile__have-no-cards" />
                                    </div>
                                </>
                            )}
                            {employerCards.length !== 0 && (
                                <Accordion
                                    accordionClassName={styles["user-profile__accordion"]}
                                    headerContentClassName={styles["user-profile__header-content"]}
                                    headerButtonClassName={styles["user-profile__header-button"]}
                                    bodyContentClassName={styles["user-profile__body-content"]}
                                >
                                    <div className={styles["user-profile__group-header"]}>
                                        <div className={styles["user-profile__group-title-container"]}>
                                            <h2>
                                                <FormattedMessage id="my-profile__my-cards-employer-card" />
                                            </h2>
                                        </div>
                                    </div>
                                    <div className={styles["user-profile__single-cards-container"]}>
                                        {employerCards.map(
                                            (card) =>
                                                card && (
                                                    <SingleCard
                                                        card={card}
                                                        user={userProfile}
                                                        key={card.id}
                                                        isEmployer
                                                        displayMode
                                                    />
                                                )
                                        )}
                                    </div>
                                </Accordion>
                            )}
                            {employeeCards.length !== 0 && (
                                <Accordion
                                    accordionClassName={styles["user-profile__accordion"]}
                                    headerContentClassName={styles["user-profile__header-content"]}
                                    headerButtonClassName={styles["user-profile__header-button"]}
                                    bodyContentClassName={styles["user-profile__body-content"]}
                                >
                                    <div className={styles["user-profile__group-header"]}>
                                        <div className={styles["user-profile__group-title-container"]}>
                                            <h2>
                                                <FormattedMessage id="my-profile__my-cards-employee-card" />
                                            </h2>
                                        </div>
                                    </div>
                                    <div className={styles["user-profile__single-cards-container"]}>
                                        {employeeCards.map(
                                            (card) =>
                                                card && (
                                                    <SingleCard
                                                        card={card}
                                                        user={userProfile}
                                                        key={card.id}
                                                        displayMode
                                                    />
                                                )
                                        )}
                                    </div>
                                </Accordion>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UserProfileComponent;
