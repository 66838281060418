import { phoneNumberRegex, passwordRegex } from "features/common/constants";

export const createProfileFormValidationSchema = (yup: any) =>
    yup.object().shape({
        firstName: yup.string().min(2).max(50).required(),
        lastName: yup.string().min(2).max(50).required(),
        phoneNumber: yup.string().matches(phoneNumberRegex).max(20).required(),
        gender: yup.string().required(),
        birthDate: yup.date().required(),
    });

export const isTokenValid = (token: string) => /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(token);

export const loginFormValidationSchema = (yup: any) =>
    yup.object().shape({
        email: yup.string().required(),
        password: yup.string().required(),
    });

export const registerFormValidationSchema = (yup: any, intl: any) =>
    yup.object().shape({
        email: yup
            .string()
            .email(intl.formatMessage({ id: "user-register__email-must-be-email" }))
            .required(),
        password: yup
            .string()
            .matches(passwordRegex, intl.formatMessage({ id: "user-register__password-not-match-regex" }))
            .required(),
    });

export const recoverPasswordValidationSchema = (yup: any, intl: any) =>
    yup.object().shape({
        newPassword: yup
            .string()
            .matches(passwordRegex, intl.formatMessage({ id: "user-register__password-not-match-regex" }))
            .required(),
        newPasswordConfirm: yup
            .string()
            .oneOf([yup.ref("newPassword")], intl.formatMessage({ id: "user-register__password-not-match" }))
            .required(),
    });