import { ReactComponent as TimesIcon } from "assets/icons/times.svg";
import React from "react";
import styles from "./styles.module.scss";

export interface Props {
    children: string | React.ReactNode;
}

const SuccessHeader = ({ children }: Props) => {
    return (
        <div className={styles["error-header"]}>
            <TimesIcon />
            {children}
        </div>
    );
};

export default SuccessHeader;
